	.mediaViewInfo {
		--web-view-name: SharpStyles Mob;
		--web-view-id: SharpStyles_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: SharpStyles_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#SharpStyles_Mob {
		position: absolute;
		width: 375px;
		height: 4402px;
        left:calc((100vw - 375px)/2); 
		background: linear-gradient(180deg, rgba(228,228,233,1) 0%, rgba(255,255,255,0.886) 100%);
		overflow: hidden;
		--web-view-name: SharpStyles Mob;
		--web-view-id: SharpStyles_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#SharpStyles_18_mobGroup_15027 {
		position: absolute;
		width: 540.858px;
		height: 726px;
		left: -82.929px;
		top: 33px;
		overflow: visible;
	}
	#SharpStyles_18_mobGroup_15021 {
		position: absolute;
		width: 540.858px;
		height: 360.612px;
		left: 0px;
		top: 100.814px;
		overflow: visible;
	}
	#SharpStyles_18_mobRectangle_724 {
		fill: rgba(123,153,166,1);
	}
	.SharpStyles_18_mobRectangle_724 {
		position: absolute;
		overflow: visible;
		width: 180.531px;
		height: 171.935px;
		left: 207.755px;
		top: 188.678px;
	}
	#SharpStyles_18_mobMy_project {
		position: absolute;
		width: 540.857px;
		height: 360.612px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobtrimmer {
		position: absolute;
		width: 37.006px;
		height: 37.006px;
		left: 384.328px;
		top: 92.735px;
		overflow: visible;
	}
	#SharpStyles_18_mobAnime_Edit {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 145.929px;
		top: 0px;
		border-radius: 50%;
		overflow: hidden;
	}
	#SharpStyles_18_mobOni_Chan_Haircut {
		left: 230.929px;
		top: 19px;
		position: absolute;
		overflow: visible;
		width: 133px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobGroup_15022 {
		position: absolute;
		width: 348px;
		height: 218px;
		left: 101.929px;
		top: 508px;
		overflow: visible;
	}
	#SharpStyles_18_mobWe_are_Desperate_to_make_you_M {
		filter: drop-shadow(0px 16px 21px rgba(0, 0, 0, 0.161));
		left: 0.189px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 344px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 38px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#SharpStyles_18_mobYou_will_leave_looking_sharp_r {
		left: 0px;
		top: 178px;
		position: absolute;
		overflow: visible;
		width: 349px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobGroup_15029 {
		position: absolute;
		width: 305px;
		height: 1845px;
		left: 35px;
		top: 819px;
		overflow: visible;
	}
	#SharpStyles_18_mobGroup_14870 {
		position: absolute;
		width: 305px;
		height: 555px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobRectangle_683 {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_mobRectangle_683 {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 323px;
		height: 456px;
		left: 0px;
		top: 117px;
	}
	#SharpStyles_18_mobagustin-fernandez-Znyjl9pbaUs- {
		position: absolute;
		width: 206px;
		height: 278px;
		left: 49px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobVISION {
		left: 48px;
		top: 313px;
		position: absolute;
		overflow: visible;
		width: 62px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobScroll_Group_58 {
		mix-blend-mode: normal;
		position: absolute;
		width: 273px;
		height: 198px;
		left: 25px;
		top: 344px;
		overflow: hidden;
	}
	#SharpStyles_18_mobLorem_ipsum_dolor_sit_amet_con {
		left: 23px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 234px;
		height: 181px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(125,125,125,1);
	}
	#SharpStyles_18_mobGroup_14877 {
		position: absolute;
		width: 305px;
		height: 555px;
		left: 0px;
		top: 645px;
		overflow: visible;
	}
	#SharpStyles_18_mobRectangle_683_dj {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_mobRectangle_683_dj {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 323px;
		height: 456px;
		left: 0px;
		top: 117px;
	}
	#SharpStyles_18_mobnathon-oski-EW_rqoSdDes-unspla {
		position: absolute;
		width: 206px;
		height: 278px;
		left: 49px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobMISSION {
		left: 48px;
		top: 313px;
		position: absolute;
		overflow: visible;
		width: 78px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobLorem_ipsum_dolor_sit_amet_con_dm {
		left: 48px;
		top: 354px;
		position: absolute;
		overflow: visible;
		width: 234px;
		height: 181px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(125,125,125,1);
	}
	#SharpStyles_18_mobGroup_14878 {
		position: absolute;
		width: 305px;
		height: 555px;
		left: 0px;
		top: 1290px;
		overflow: visible;
	}
	#SharpStyles_18_mobRectangle_683_do {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_mobRectangle_683_do {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 323px;
		height: 456px;
		left: 0px;
		top: 117px;
	}
	#SharpStyles_18_mobapothecary-87-Wg3J83R1YSQ-unsp {
		position: absolute;
		width: 206px;
		height: 278px;
		left: 49px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobPRINCIPLES {
		left: 48px;
		top: 313px;
		position: absolute;
		overflow: visible;
		width: 101px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobScroll_Group_57 {
		mix-blend-mode: normal;
		position: absolute;
		width: 305px;
		height: 201px;
		left: 0px;
		top: 354px;
		overflow: hidden;
	}
	#SharpStyles_18_mobLorem_ipsum_dolor_sit_amet_con_ds {
		left: 48px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 234px;
		height: 181px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(125,125,125,1);
	}
	#SharpStyles_18_mobScroll_services_website_6_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 360px;
		height: 287px;
		left: 15px;
		top: 2834px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#SharpStyles_18_mobmy_services_card_1_website_6_m {
		position: relative;
		width: 174px;
		height: 242px;
		left: 14px;
		top: 22px;
		margin-right: 30px;
		overflow: visible;
	}
	#SharpStyles_18_mobservices_card_bdr_website_6_mo {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_mobservices_card_bdr_website_6_mo {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 192px;
		height: 260px;
		left: 0px;
		top: 0px;
	}
	#SharpStyles_18_mobimg_offering_website_6_mob {
		position: absolute;
		width: 150px;
		height: 198px;
		left: 12px;
		top: 34px;
		overflow: visible;
	}
	#SharpStyles_18_mobcircle_deign_shadow_space_webs {
		fill: rgba(23,18,18,1);
	}
	.SharpStyles_18_mobcircle_deign_shadow_space_webs {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 12px;
		top: 16px;
	}
	#SharpStyles_18_mobMy_Offerings_lbl_website_6_mob {
		left: 23px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 140px;
		height: 17px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,0.89);
	}
	#SharpStyles_18_mobOur_Services {
		left: 97px;
		top: 2754px;
		position: absolute;
		overflow: visible;
		width: 183px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobGallery {
		left: 136px;
		top: 3191px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobGroup_15019 {
		position: absolute;
		width: 360px;
		height: 343px;
		left: 8px;
		top: 3302px;
		overflow: visible;
	}
	#SharpStyles_18_mobobi-pixel7propix--sRVfY0f2d8-u {
		position: absolute;
		width: 115px;
		height: 157px;
		left: 123px;
		top: 23px;
		overflow: visible;
	}
	#SharpStyles_18_moballef-vinicius-IvQeAVeJULw-uns {
		position: absolute;
		width: 115px;
		height: 158px;
		left: 245px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobarthur-humeau-Twd3yaqA2NM-unsp {
		position: absolute;
		width: 115px;
		height: 158px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobagustin-fernandez-Znyjl9pbaUs-_d {
		position: absolute;
		width: 115px;
		height: 157px;
		left: 123px;
		top: 186px;
		overflow: visible;
	}
	#SharpStyles_18_mobdelfina-pan-wJoB8D3hnzc-unspla {
		position: absolute;
		width: 115px;
		height: 158px;
		left: 245px;
		top: 163px;
		overflow: visible;
	}
	#SharpStyles_18_mobnathon-oski-fE42nRlBcG8-unspla {
		position: absolute;
		width: 115px;
		height: 158px;
		left: 0px;
		top: 163px;
		overflow: visible;
	}
	#SharpStyles_18_mobGroup_15025 {
		position: absolute;
		width: 525px;
		height: 650px;
		left: 17px;
		top: 3733px;
		overflow: visible;
	}
	#SharpStyles_18_mobGroup_15023 {
		position: absolute;
		width: 507px;
		height: 342px;
		left: 18px;
		top: 308px;
		overflow: visible;
	}
	#SharpStyles_18_mobsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 0px;
		top: 213px;
		overflow: visible;
	}
	#SharpStyles_18_moblinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.SharpStyles_18_mobmed_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#SharpStyles_18_mobmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.SharpStyles_18_mobmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#SharpStyles_18_moblinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.191px;
		overflow: visible;
	}
	#SharpStyles_18_mobn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.SharpStyles_18_mobn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_moblinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.SharpStyles_18_moblinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.554px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_mobtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.SharpStyles_18_mobtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.526px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_mobInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.SharpStyles_18_mobshadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#SharpStyles_18_mobshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.SharpStyles_18_mobshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#SharpStyles_18_mobinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#SharpStyles_18_mobwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobmed_shadow_space_website_7_en:hover {
		fill: rgba(50,172,158,1);
	}
	.SharpStyles_18_mobmed_shadow_space_website_7_en:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#SharpStyles_18_mobmed_shadow_space_website_7_en {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.SharpStyles_18_mobmed_shadow_space_website_7_en {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#SharpStyles_18_mobwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#SharpStyles_18_mobn_234567890_mobile_number_shad {
		left: 48px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobaffanghanioperatelive_email_sh {
		left: 40px;
		top: 61px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobaddress_med_shadow_space_websi {
		left: 35px;
		top: 120px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_moblocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 120px;
		overflow: visible;
	}
	#SharpStyles_18_mobemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 60px;
		overflow: visible;
	}
	#SharpStyles_18_mobphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 298px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 44px;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(173,173,173,1);
	}
	#SharpStyles_18_mobVisit_Us_and_Get_the_Stylist_H {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 342px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobRectangle_735 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SharpStyles_18_mobRectangle_735 {
		position: absolute;
		overflow: visible;
		width: 341px;
		height: 222px;
		left: 0px;
		top: 45px;
		border-radius: 20px;
	}