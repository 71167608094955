.mediaViewInfo {
    --web-view-name: community mem – 1;
    --web-view-id: community_mem_page;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: community_mem_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#community_mem_page {
    position: absolute;
    width: calc(100% - 136px);
    height:calc(100% - 170px);
    top: 130px;
    left: 120px;
    overflow: hidden;
    --web-view-name: community mem – 1;
    --web-view-id: community_mem_page;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bdr_mem_oveerall_grp {
    position: absolute;
    width: 1254.019px;
    height: 565px;
    left: 1.354px;
    top: 0px;
    overflow: visible;
}
#overl_all_design_com_mem_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(163,214,147,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.overl_all_design_com_mem_pc {
    overflow: visible;
    position: absolute;
    width: 3145.033px;
    height: 1646.021px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#title_grp_all_com_mem_pc {
    position: absolute;
    width: 429px;
    height: 22px;
    left: 46px;
    top: 14.686px;
    overflow: visible;
}
#Offerings_lbl_com_mem_pc {
    left: 190px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
    cursor: pointer;
}
#Requirement_lbl_com_mem_pc {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#Members_lbl_com_mem_pc {
    left: 354px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#Scroll_Group_conn_mem {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100%);
    height: calc(100% - 100px);
    left: 16px;
    top: 72.686px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#mem_card_1_com_mem_pc {
    position: relative;
    width: 199px;
    height: 193px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#card_bdr_com_mem_pc_ {
    fill: url(#card_bdr_com_mem_pc_);
}
.card_bdr_com_mem_pc_ {
    filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 205.5px;
    height: 198.957px;
    left: 0px;
    top: 0.543px;
}
#card_top_deisgn_com_mem_pc_ {
    fill: url(#card_top_deisgn_com_mem_pc_);
}
.card_top_deisgn_com_mem_pc_ {
    overflow: visible;
    position: absolute;
    width: 199px;
    height: 47.484px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#bg_name_deisgn_com_mem_pc {
    fill: rgba(219,242,231,1);
}
.bg_name_deisgn_com_mem_pc {
    position: absolute;
    overflow: visible;
    width: 169.518px;
    height: 20.793px;
    left: 14.5px;
    top: 87.543px;
}
#ai-chip-_img_com_mem_pc {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 70px;
    top: 7px;
    overflow: visible;
}
#Mohammed_Zaid_com_mem_pc_1 {
    left: 16.5px;
    top: 87.543px;
    position: absolute;
    overflow: visible;
    width: 129px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(37,37,37,1);
}
#com_mem_pc_location_img {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 19px;
    top: 156px;
    overflow: visible;
}
#com_mem_pc_duration_img {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 19px;
    top: 116px;
    overflow: visible;
}
#minus_com_ {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 177px;
    top: 2px;
    z-index: 10;
    cursor: pointer;
    overflow: visible;
}
#date_com_mem_pc {
    left: 91px;
    top: 162px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#Software_development_com_mem_p {
    left: 59px;
    top: 122px;
    position: absolute;
    overflow: visible;
    width: 131px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#member_add_btn_grp_com_mem_pc:hover {
    left: 487px;
    top: 12px;
}
#member_add_btn_grp_com_mem_pc {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 484px;
    top: 10px;
    overflow: visible;
    border-radius: 50px;
    cursor: pointer;
    transition: all .3s ease-out;
}
#bdr_member_btn_com_off_pcZ {
    fill: rgba(255,255,255,1);
}
.bdr_member_btn_com_off_pcZ {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 50px;
    height: 50px;
    left: 0px;
    top: 0px;
}
#member_img_btn_com_off_pcZ {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 8px;
    top: 8px;
    overflow: visible;
}

@keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInLeft {
    animation: fadeInLeft 1s;
  }


