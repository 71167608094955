	.FlamingoFiesta_Mob_mediaViewInfo {
		--web-view-name: web 2 mob;
		--web-view-id: web_2_mob;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: web_2_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#FlamingoFiesta_Mob_web_2_mob {
		position: absolute;
		width: 428px;
		height: 4857px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: web 2 mob;
		--web-view-id: web_2_mob;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#FlamingoFiesta_Mob_Dotted_LIne2_mob_2web {
		fill: transparent;
		stroke: rgba(209,209,209,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 7;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
.FlamingoFiesta_Mob_Dotted_LIne2_mob_2web {
		overflow: visible;
		position: absolute;
		width: 289.048px;
		height: 380.08px;
		left: -41.075px;
		top: 1405.39px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_rec_first_img_bdr {
		fill: rgba(243,123,123,1);
	}
.FlamingoFiesta_Mob_rec_first_img_bdr {
		overflow: visible;
		position: absolute;
		width: 456.324px;
		height: 403.83px;
		transform: matrix(1,0,0,1,115.1765,-71.8828) rotate(-8deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#FlamingoFiesta_Mob_top_first_img_bdr_vircle_mob_2 {
		fill: rgba(243,123,123,1);
	}
.FlamingoFiesta_Mob_top_first_img_bdr_vircle_mob_2 {
		overflow: visible;
		position: absolute;
		width: 294.028px;
		height: 244.52px;
		transform: matrix(1,0,0,1,47.0962,117.275) rotate(-5deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#FlamingoFiesta_Mob_Mohammed_Affan_web_2_mob_2 {
		left: 70px;
		top: 393px;
		position: absolute;
		overflow: visible;
		width: 204px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_Scroll_intro_mob_website_2 {
		mix-blend-mode: normal;
		position: absolute;
		width: 327px;
		height: 92px;
		left: 70px;
		top: 423px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FlamingoFiesta_Mob_Bridging_the_gap_between_desig {
		left: 0px;
		top: -1px;
		position: absolute; 
		overflow: visible;
		width: 302px;
		white-space: pre-wrap;
		line-height: 21px;
		margin-top: -4px;
		text-align: left;
		font-family: Acumin Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_view_work_button_website_mob_2 {
		position: absolute;
		width: 90px;
		height: 31px;
		left: 70px;
		top: 517px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_bdr_view_mob_2 {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_bdr_view_mob_2 {
		position: absolute;
		overflow: visible;
		width: 90px;
		height: 31px;
		left: 0px;
		top: 0px;
	}
	#FlamingoFiesta_Mob_View_Work_lbl_2_mob_2 {
		left: 15px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 48px;
		white-space: nowrap;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(255,255,255,1);
	}
	#FlamingoFiesta_Mob_Our_Objective_lbl_mob_2_websti {
		left: 138px;
		top: 641px;
		position: absolute;
		overflow: visible;
		width: 153px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_Vision_lbl_website_2_mob_2 {
		left: 64px;
		top: 1012.981px;
		position: absolute;
		overflow: visible;
		width: 49px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_Scroll_Vision_desc_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 344px;
		height: 129px;
		left: 57px;
		top: 1049.981px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FlamingoFiesta_Mob_Theres_no_style_over_substance {
		left: 7px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 328px;
		height: 71px;
		line-height: 28px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_Dotted_LIne1_mob_2 {
		fill: transparent;
		stroke: rgba(209,209,209,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 7;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
.FlamingoFiesta_Mob_Dotted_LIne1_mob_2 {
		overflow: visible;
		position: absolute;
		width: 253.365px;
		height: 322.019px;
		left: 219px;
		top: 919px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_grop_mob_2 {
		position: absolute;
		width: 368px;
		height: 163.487px;
		left: 33.5px;
		top: 1510.046px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_Principles_mob_2 {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_Scroll_principle_profile_page_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 368px;
		height: 129px;
		left: 0px;
		top: 34.487px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FlamingoFiesta_Mob_Its_about_organizing_the_conte {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 352px;
		height: 99px;
		line-height: 28px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_Mission_lbl_mob_2 {
		left: 50px;
		top: 1921.432px;
		position: absolute;
		overflow: visible;
		width: 62px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_Scroll_mission_mob_2 {
		mix-blend-mode: normal;
		position: absolute;
		width: 342px;
		height: 129px;
		left: 43px;
		top: 1960.115px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FlamingoFiesta_Mob_As_a_designer_and_developer_I_ {
		left: 7px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 336px;
		white-space: nowrap;
		line-height: 28px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_View_Work {
		position: absolute;
		width: 95px;
		height: 29px;
		left: 167px;
		top: 3937px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_Rectangle_88 {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_Rectangle_88 {
		position: absolute;
		overflow: visible;
		width: 95px;
		height: 29px;
		left: 0px;
		top: 0px;
	}
	#FlamingoFiesta_Mob_View_All {
		left: 25px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 46px;
		white-space: nowrap;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#FlamingoFiesta_Mob_My_Offerings_lbl_website_2_mob {
		left: 160px;
		top: 2374px;
		position: absolute;
		overflow: visible;
		width: 119px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 19px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_scroll_my_offerings_mob_web_2 {
        mix-blend-mode: normal;
		position: absolute;
		width: 376.352px;
		height: 1486px;
		left: 52.324px;
		top: 2427px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FlamingoFiesta_Mob_Project1_mob_2 {
		position: relative;
		width: 280px;
		height: 316.214px;
		left: 21.676px;
		top: 40px;
     
		overflow: visible;
	}
	#FlamingoFiesta_Mob_Our_businness_partner_lbl_webs {
		left: 112px;
		top: 2143px;
		position: absolute;
		overflow: visible;
		width: 206px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FlamingoFiesta_Mob_partern_bdr_website_2_mob_2 {
		fill: transparent;
	}
.FlamingoFiesta_Mob_partern_bdr_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 130px;
		left: 0px;
		top: 2203px;
		background:  rgba( 255, 255, 255, 0.5 );
		box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
		backdrop-filter:  blur( 10px );
		-webkit-backdrop-filter:  blur( 10px );
		border-radius:  10px;
		border:  1px solid rgba( 255, 255, 255, 0.18 );
	}
	#FlamingoFiesta_Mob_Group_305 {
		position: absolute;
		width: 221px;
		height: 185.586px;
		left: 113px;
		top: 4444.766px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_Start_a_new_project_grp_mob_2 {
		position: absolute;
		width: 179px;
		height: 42px;
		left: 14px;
		top: 86.691px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_bdr_start_mob_2 {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_bdr_start_mob_2 {
		position: absolute;
		overflow: visible;
		width: 179px;
		height: 42px;
		left: 0px;
		top: 0px;
	}
	#FlamingoFiesta_Mob_Start_A_New_Project_mob_2 {
		left: 28px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 130px;
		white-space: nowrap;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#FlamingoFiesta_Mob_Willing_to_Grow_by_Partnering_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 222px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_Linkedin_website_2_mob_2 {
		position: absolute;
		width: 25px;
		height: 22.728px;
		left: 142px;
		top: 162.857px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_i_line_mob_2 {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_i_line_mob_2 {
		overflow: visible;
		position: absolute;
		width: 4.873px;
		height: 15.44px;
		left: 0.631px;
		top: 7.288px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_n_mob_mob_2 {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_n_mob_mob_2 {
		overflow: visible;
		position: absolute;
		width: 16.522px;
		height: 15.588px;
		left: 8.478px;
		top: 7.141px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_n_i_dot_mob_2 {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_n_i_dot_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.915px;
		height: 5.357px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_instagram_website_2_mob_2 {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 91px;
		top: 159.234px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_whatsapp_website_2_mob {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 40px;
		top: 158.798px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_copy_website_2_mob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
.FlamingoFiesta_Mob_copy_website_2_mob {
		overflow: visible;
		position: absolute;
		width: 324px;
		height: 1px;
		left: 52px;
		top: 4754.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_n_Copyright_Operate_Live_websi_ff {
		left: 52.5px;
		top: 4778px;
		position: absolute;
		overflow: visible;
		width: 280px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#FlamingoFiesta_Mob_partner_img_and_lbl_scroll_web {
		mix-blend-mode: normal;
		position: absolute;
		width: 324px;
		height: 64.849px;
		left: 52px;
		top: 2236px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#FlamingoFiesta_Mob_bg_partner_mobwebsite_2_mob_2 {
		fill: transparent;
	}
.FlamingoFiesta_Mob_bg_partner_mobwebsite_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 130px;
		left: -89px;
		top: -50px;
		background:  rgba( 255, 255, 255, 0.5 );
		box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
		backdrop-filter:  blur( 10px );
		-webkit-backdrop-filter:  blur( 10px );
		border-radius:  10px;
		border:  1px solid rgba( 255, 255, 255, 0.18 );
	}
	#FlamingoFiesta_Mob_google_lbl_partner_mob_website {
		
		position: relative;
		overflow: visible;
		width: 146px;
		height: 27px;
		left: 34px;
		top: 9px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#FlamingoFiesta_Mob_microsoft_lbl_partnw_mobwebsit {
		left: 214px;
		top: 9px;
		position: relative;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#FlamingoFiesta_Mob_microscope_lbl_mob_website_2_m {
		left: 394px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#FlamingoFiesta_Mob_google_1_mob_website_2_mob_2 {
		position: relative;
		width: 25px;
		height: 25px;
		left: 4px;
		top: 10px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_microsoft_mobwebsite_2_mob_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 184px;
		top: 10px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_scope_mob_website_2_mob_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 364px;
		top: 10px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_google_mob_website_2_mob_2 {
		left: 576px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#FlamingoFiesta_Mob_microsoft_1_mob_website_2_mob_ {
		left: 756px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#FlamingoFiesta_Mob_micro_1_website_1_mob_website_ {
		left: 936px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#FlamingoFiesta_Mob_google_1_1_mob_website_2_mob_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 546px;
		top: 10px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_microsoft_1_mob_website_2_mob__c {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 726px;
		top: 10px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_scope_1_mob_website_2_mob_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 906px;
		top: 10px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_visin_img_website_2_mob_2 {
		position: absolute;
		width: 363.171px;
		height: 261.856px;
		left: 32px;
		top: 731.125px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_bg_deisgn {
		opacity: 0.1;
		fill: rgba(239,122,120,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_bg_deisgn {
		overflow: visible;
		position: absolute;
		width: 363.171px;
		height: 180.627px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_shadow_vision_img_website_2__m {
		opacity: 0.1;
		fill: rgba(56,211,159,1);
	}
.FlamingoFiesta_Mob_shadow_vision_img_website_2__m {
		position: absolute;
		overflow: visible;
		width: 156.849px;
		height: 11.152px;
		left: 16.144px;
		top: 250.704px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_hand_left {
		fill: rgba(251,190,190,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_hand_left {
		overflow: visible;
		position: absolute;
		width: 16.712px;
		height: 21.844px;
		left: 104.712px;
		top: 44.463px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_left_hand {
		fill: rgba(241,90,90,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_left_hand {
		overflow: visible;
		position: absolute;
		width: 42.567px;
		height: 37.488px;
		left: 71.148px;
		top: 62.485px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_cent {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_cent {
		overflow: visible;
		position: absolute;
		width: 61.732px;
		height: 61.732px;
		left: 169.646px;
		top: 86.732px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_12_m {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_12_m {
		position: absolute;
		overflow: visible;
		width: 7.231px;
		height: 7.626px;
		left: 195.944px;
		top: 79.501px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_11_m {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_11_m {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,213.4367,93.6228) rotate(-59.003deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_0_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_0_mo {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,226.1496,106.0095) rotate(-29deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_9_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_9_mo {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(0.9999,0,0,0.9999,226.517,140.5845) rotate(-57.999deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_8_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_8_mo {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,167.5016,105.8012) rotate(-61deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_7_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_7_mo {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(0.9999,0,0,0.9999,167.144,140.756) rotate(-32.001deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_6_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_6_mo {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,180.2201,93.4649) rotate(-30.997deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_5_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_5_mo {
		position: absolute;
		overflow: visible;
		width: 7.231px;
		height: 7.626px;
		left: 195.944px;
		top: 148.104px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_4_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_4_mo {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,213.6363,153.2303) rotate(-30.997deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_3_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_3_mo {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,179.9871,153.4004) rotate(-59.003deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_2_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_2_mo {
		position: absolute;
		overflow: visible;
		width: 8.778px;
		height: 6.476px;
		left: 161.66px;
		top: 114.576px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_mob_ {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_mob_ {
		position: absolute;
		overflow: visible;
		width: 8.778px;
		height: 6.476px;
		left: 230.623px;
		top: 114.576px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_cente {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_cente {
		overflow: visible;
		position: absolute;
		width: 61.732px;
		height: 61.732px;
		left: 227.169px;
		top: 42.16px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_12_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_12_mo {
		position: absolute;
		overflow: visible;
		width: 7.231px;
		height: 7.626px;
		left: 253.467px;
		top: 34.929px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi11_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi11_mob {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,270.9659,49.0555) rotate(-59.003deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_0_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_0_mob {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,283.6756,61.4423) rotate(-29deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_9_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_9_mob {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(0.9999,0,0,0.9999,284.016,96.0005) rotate(-57.999deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_8_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_8_mob {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,225.0269,61.235) rotate(-61deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_7_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_7_mob {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(0.9999,0,0,0.9999,224.6666,96.1899) rotate(-32.001deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_6_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_6_mob {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,237.7504,48.8963) rotate(-30.997deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_5_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_5_mob {
		position: absolute;
		overflow: visible;
		width: 7.231px;
		height: 7.626px;
		left: 253.467px;
		top: 103.532px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_4_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_4_mob {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,271.1654,108.662) rotate(-30.997deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_3_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_3_mob {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,237.5316,108.7988) rotate(-59.003deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_2_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_2_mob {
		position: absolute;
		overflow: visible;
		width: 8.778px;
		height: 6.476px;
		left: 219.183px;
		top: 70.003px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_1_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_1_mob {
		position: absolute;
		overflow: visible;
		width: 8.778px;
		height: 6.476px;
		left: 288.146px;
		top: 70.003px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral_ce {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral_ce {
		overflow: visible;
		position: absolute;
		width: 61.768px;
		height: 61.732px;
		left: 112.051px;
		top: 40.252px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral12_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral12_ {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,139.4155,42.7621) rotate(-88.01deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral11_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral11_ {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,156.9426,47.7603) rotate(-57.011deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral0_m {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral0_m {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,169.1891,60.5487) rotate(-27.008deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral_9_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral_9_ {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(0.9999,0,0,0.9999,168.3766,95.0967) rotate(-56.011deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral8_m {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral8_m {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,110.6039,58.3066) rotate(-59.011deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral7_m {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral7_m {
		width: 7.663px;
		height: 7.267px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,108.4876,93.8188) rotate(-29.888deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral6_m {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral6_m {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,123.7327,46.3949) rotate(-29.013deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral5_m {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral5_m {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,137.0354,111.2917) rotate(-88.01deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral4_m {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral4_m {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,155.0701,107.2981) rotate(-29.013deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral3_m {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral3_m {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,121.476,106.3242) rotate(-57.011deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral_2_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral_2_ {
		width: 6.475px;
		height: 8.778px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,105.3302,75.2627) rotate(-88.01deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral_1_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral_1_ {
		width: 6.475px;
		height: 8.778px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,174.2428,77.6421) rotate(-88.01deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_shoe_2_mo {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_shoe_2_mo {
		overflow: visible;
		position: absolute;
		width: 19.367px;
		height: 28.235px;
		left: 52.687px;
		top: 228.098px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_shoe_mob_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_shoe_mob_ {
		overflow: visible;
		position: absolute;
		width: 32.148px;
		height: 17.451px;
		left: 117.556px;
		top: 241.638px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_neck_mob_ {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_neck_mob_ {
		overflow: visible;
		position: absolute;
		width: 26.082px;
		height: 20.686px;
		left: 57.154px;
		top: 80.22px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_face_mob_ {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_face_mob_ {
		position: absolute;
		overflow: visible;
		width: 26.333px;
		height: 26.333px;
		left: 52.765px;
		top: 60.686px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_shirt_mob {
		fill: rgba(241,90,90,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_shirt_mob {
		overflow: visible;
		position: absolute;
		width: 68.139px;
		height: 56.77px;
		left: 53.662px;
		top: 92.443px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_hand_2_mo {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_hand_2_mo {
		overflow: visible;
		position: absolute;
		width: 16.712px;
		height: 21.844px;
		left: 100.395px;
		top: 44.463px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_leg_1_mob {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_leg_1_mob {
		overflow: visible;
		position: absolute;
		width: 87.024px;
		height: 103.391px;
		left: 65.788px;
		top: 138.067px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_hand_1__m {
		fill: rgba(241,90,90,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_hand_1__m {
		overflow: visible;
		position: absolute;
		width: 57.23px;
		height: 53.206px;
		left: 52.375px;
		top: 61.19px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_hair_mob_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_hair_mob_ {
		overflow: visible;
		position: absolute;
		width: 34.455px;
		height: 67.963px;
		left: 38.268px;
		top: 55.139px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_grp_mo {
		position: absolute;
		width: 315.031px;
		height: 261.855px;
		left: 56.484px;
		top: 1227.507px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_bg_des {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_bg_des {
		overflow: visible;
		position: absolute;
		width: 252.678px;
		height: 169.134px;
		left: 20.692px;
		top: 17.251px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_leaves_principle_img_website_2 {
		opacity: 0.6;
		position: absolute;
		width: 53.751px;
		height: 67.672px;
		left: 53.539px;
		top: 28.884px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_s {
		fill: transparent;
		stroke: rgba(83,84,97,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_s {
		overflow: visible;
		position: absolute;
		width: 45.202px;
		height: 41.916px;
		left: 10.332px;
		top: 26.659px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf__ {
		fill: rgba(239,174,171,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf__ {
		overflow: visible;
		position: absolute;
		width: 10.611px;
		height: 10.437px;
		left: 0px;
		top: 16.327px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_1 {
		fill: rgba(239,174,171,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_1 {
		overflow: visible;
		position: absolute;
		width: 12.928px;
		height: 8.293px;
		left: 2.286px;
		top: 29.053px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_1_et {
		fill: rgba(239,174,171,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_1_et {
		overflow: visible;
		position: absolute;
		width: 12.601px;
		height: 8.264px;
		left: 15.866px;
		top: 44.161px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_1_eu {
		fill: rgba(239,174,171,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_1_eu {
		overflow: visible;
		position: absolute;
		width: 12.962px;
		height: 8.282px;
		left: 31.059px;
		top: 52.543px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_1_ev {
		fill: rgba(239,174,171,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_1_ev {
		overflow: visible;
		position: absolute;
		width: 8.3px;
		height: 13.078px;
		left: 13.296px;
		top: 24.88px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_1_ew {
		fill: rgba(239,174,171,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_1_ew {
		overflow: visible;
		position: absolute;
		width: 8.246px;
		height: 12.682px;
		left: 30.341px;
		top: 33.891px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_1_ex {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_1_ex {
		overflow: visible;
		position: absolute;
		width: 10.611px;
		height: 10.437px;
		left: 0px;
		top: 16.327px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_1_ey {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_1_ey {
		overflow: visible;
		position: absolute;
		width: 12.928px;
		height: 8.293px;
		left: 2.286px;
		top: 29.053px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_9 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_9 {
		overflow: visible;
		position: absolute;
		width: 12.601px;
		height: 8.264px;
		left: 15.866px;
		top: 44.161px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_8 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_8 {
		overflow: visible;
		position: absolute;
		width: 12.962px;
		height: 8.282px;
		left: 31.059px;
		top: 52.543px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_7 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_7 {
		overflow: visible;
		position: absolute;
		width: 8.3px;
		height: 13.078px;
		left: 13.296px;
		top: 24.88px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_6 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_6 {
		overflow: visible;
		position: absolute;
		width: 8.246px;
		height: 12.682px;
		left: 30.341px;
		top: 33.891px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_stem_m {
		fill: transparent;
		stroke: rgba(83,84,97,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_stem_m {
		overflow: visible;
		position: absolute;
		width: 25.083px;
		height: 55.165px;
		left: 30.141px;
		top: 12.29px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_5 {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_5 {
		overflow: visible;
		position: absolute;
		width: 8.288px;
		height: 12.43px;
		left: 22.823px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_4 {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_4 {
		overflow: visible;
		position: absolute;
		width: 11.466px;
		height: 9.304px;
		left: 19.756px;
		top: 12.507px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_3 {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_3 {
		overflow: visible;
		position: absolute;
		width: 13.056px;
		height: 8.289px;
		left: 26.083px;
		top: 31.768px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_2 {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_2 {
		overflow: visible;
		position: absolute;
		width: 12.996px;
		height: 8.338px;
		left: 36.361px;
		top: 45.539px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_1_e {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_1_e {
		overflow: visible;
		position: absolute;
		width: 8.346px;
		height: 12.342px;
		left: 31.472px;
		top: 13.096px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_card__ {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_card__ {
		overflow: visible;
		position: absolute;
		width: 64.537px;
		height: 92.987px;
		left: 88.958px;
		top: 56.773px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_x_2mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_x_2mob {
		overflow: visible;
		position: absolute;
		width: 15.27px;
		height: 19.732px;
		left: 113.374px;
		top: 64.379px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_x_1_mo {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_x_1_mo {
		overflow: visible;
		position: absolute;
		width: 12.937px;
		height: 18.811px;
		left: 115.955px;
		top: 64.379px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_box_5_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_box_5_ {
		overflow: visible;
		position: absolute;
		width: 40.757px;
		height: 10.765px;
		left: 100.749px;
		top: 94.477px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2__tick_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2__tick_ {
		overflow: visible;
		position: absolute;
		width: 30.644px;
		height: 10.765px;
		left: 105.806px;
		top: 125.516px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_box_4_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_box_4_ {
		overflow: visible;
		position: absolute;
		width: 40.967px;
		height: 10.947px;
		left: 100.749px;
		top: 108.614px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2__tick2 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2__tick2 {
		overflow: visible;
		position: absolute;
		width: 6.686px;
		height: 6.293px;
		left: 117.071px;
		top: 127.085px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_design {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_design {
		overflow: visible;
		position: absolute;
		width: 64.674px;
		height: 91.627px;
		left: 182.652px;
		top: 58.555px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_box_3_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_box_3_ {
		overflow: visible;
		position: absolute;
		width: 40.757px;
		height: 6.162px;
		left: 195.261px;
		top: 96.744px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_design_fi {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_design_fi {
		overflow: visible;
		position: absolute;
		width: 41.009px;
		height: 6.26px;
		left: 195.261px;
		top: 104.19px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_mob_2 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 57.953px;
		height: 3.383px;
		left: 188.32px;
		top: 65.077px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_box_2_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_box_2_ {
		overflow: visible;
		position: absolute;
		width: 5.852px;
		height: 4.563px;
		left: 190.375px;
		top: 60.289px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_box_mo {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_box_mo {
		overflow: visible;
		position: absolute;
		width: 17.411px;
		height: 18.842px;
		left: 191.738px;
		top: 71.529px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_arrow_ {
		fill: transparent;
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_arrow_ {
		overflow: visible;
		position: absolute;
		width: 16.206px;
		height: 1.345px;
		left: 158.397px;
		top: 97.654px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_arrow__fn {
		fill: transparent;
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_arrow__fn {
		overflow: visible;
		position: absolute;
		width: 8.441px;
		height: 14.377px;
		left: 172.696px;
		top: 90.641px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_circle {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_circle {
		position: absolute;
		overflow: visible;
		width: 15.066px;
		height: 15.066px;
		left: 70.617px;
		top: 0px;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_circle_fp {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_circle_fp {
		position: absolute;
		overflow: visible;
		width: 15.066px;
		height: 15.066px;
		left: 185.391px;
		top: 10.428px;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_mob_2_fq {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_mob_2_fq {
		position: absolute;
		overflow: visible;
		width: 15.066px;
		height: 15.066px;
		left: 266.022px;
		top: 122.167px;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_cmob_2 {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_cmob_2 {
		width: 25.319px;
		height: 25.25px;
		position: absolute;
		overflow: visible;
		transform: translate(-59px, 0px) matrix(1,0,0,1,346.8815,83.4361) rotate(-80.782deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_cirlce {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_cirlce {
		position: absolute;
		overflow: visible;
		width: 25.319px;
		height: 25.319px;
		left: 261.187px;
		top: 17.974px;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_circle_ft {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_circle_ft {
		position: absolute;
		overflow: visible;
		width: 25.319px;
		height: 25.319px;
		left: 0px;
		top: 100.725px;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_body_s_fv {
		fill: url(#FlamingoFiesta_Mob_principle_img_website_2_body_s_fv);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_body_s_fv {
		overflow: visible;
		position: absolute;
		width: 69.347px;
		height: 174.298px;
		left: 189.227px;
		top: 85.567px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_pen_mo {
		fill: rgba(101,97,125,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_pen_mo {
		overflow: visible;
		position: absolute;
		width: 11.518px;
		height: 12.17px;
		left: 199.242px;
		top: 92.196px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_hand_2 {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_hand_2 {
		width: 5.222px;
		height: 14.921px;
		position: absolute;
		overflow: visible;
		transform: translate(-59px, 0px) matrix(0.9999,0,0,0.9999,263.8187,91.9697) rotate(-11.579deg);
		transform-origin: center;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_shoemo {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_shoemo {
		overflow: visible;
		position: absolute;
		width: 15.8px;
		height: 7.392px;
		left: 187.57px;
		top: 253.84px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_face_m {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_face_m {
		position: absolute;
		overflow: visible;
		width: 19.304px;
		height: 19.304px;
		left: 225.681px;
		top: 87.16px;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_hand_m {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_hand_m {
		overflow: visible;
		position: absolute;
		width: 10.063px;
		height: 23.609px;
		left: 244.248px;
		top: 150.607px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_hand_s {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_hand_s {
		overflow: visible;
		position: absolute;
		width: 10.609px;
		height: 13.306px;
		left: 204.837px;
		top: 97.914px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_shirt_ {
		fill: rgba(190,190,190,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_shirt_ {
		overflow: visible;
		position: absolute;
		width: 33.618px;
		height: 52.95px;
		left: 217.52px;
		top: 107.991px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_top_sh {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_top_sh {
		overflow: visible;
		position: absolute;
		width: 40.801px;
		height: 33.053px;
		left: 217.94px;
		top: 111.961px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_left_h {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_left_h {
		overflow: visible;
		position: absolute;
		width: 6.413px;
		height: 5.58px;
		left: 209.034px;
		top: 105.641px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_left_a {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_left_a {
		overflow: visible;
		position: absolute;
		width: 12.98px;
		height: 17.35px;
		left: 209.576px;
		top: 105.932px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_pant_m {
		fill: rgba(51,49,63,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_pant_m {
		overflow: visible;
		position: absolute;
		width: 56px;
		height: 105.415px;
		left: 193.229px;
		top: 156.441px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2arm_mob {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2arm_mob {
		overflow: visible;
		position: absolute;
		width: 7.367px;
		height: 3.465px;
		left: 246.943px;
		top: 150.561px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_should {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_should {
		overflow: visible;
		position: absolute;
		width: 11.727px;
		height: 29.102px;
		left: 246.489px;
		top: 124.364px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_hair_m {
		fill: rgba(51,49,63,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_hair_m {
		overflow: visible;
		position: absolute;
		width: 33.635px;
		height: 53.009px;
		left: 221.717px;
		top: 83.387px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_mission_all_grp_website_2_mob_ {
		position: absolute;
		width: 323.27px;
		height: 166.054px;
		left: 52px;
		top: 1735.377px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_pc_stand_2_website_2mob_2 {
		fill: rgba(188,188,188,1);
	}
	.FlamingoFiesta_Mob_pc_stand_2_website_2mob_2 {
		overflow: visible;
		position: absolute;
		width: 51.913px;
		height: 30.615px;
		left: 135.679px;
		top: 122.461px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pc_sfcreen_website_2_mob_2 {
		fill: rgba(82,83,96,1);
	}
	.FlamingoFiesta_Mob_pc_sfcreen_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 177.036px;
		height: 107.819px;
		left: 73.116px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pc_border_website_2mob_2 {
		fill: rgba(188,188,188,1);
	}
	.FlamingoFiesta_Mob_pc_border_website_2mob_2 {
		overflow: visible;
		position: absolute;
		width: 177.036px;
		height: 16.639px;
		left: 73.117px;
		top: 107.818px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pc_logo_website_2mob_2 {
		fill: rgba(82,83,96,1);
	}
	.FlamingoFiesta_Mob_pc_logo_website_2mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.99px;
		height: 5.99px;
		left: 158.639px;
		top: 113.143px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pc_stand_down_website_2mob_2 {
		fill: rgba(188,188,188,1);
	}
	.FlamingoFiesta_Mob_pc_stand_down_website_2mob_2 {
		overflow: visible;
		position: absolute;
		width: 62.562px;
		height: 6.655px;
		left: 130.354px;
		top: 144.091px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_center_screen_white_website_2m {
		fill: rgba(255,255,255,1);
	}
	.FlamingoFiesta_Mob_center_screen_white_website_2m {
		overflow: visible;
		position: absolute;
		width: 107px;
		height: 61.217px;
		left: 108.134px;
		top: 23.128px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_box_1_website_2mob_2 {
		fill: rgba(63,117,225,1);
	}
	.FlamingoFiesta_Mob_screen_box_1_website_2mob_2 {
		overflow: visible;
		position: absolute;
		width: 11.793px;
		height: 10.615px;
		left: 137.941px;
		top: 31.916px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_box_1_line_top_website_ {
		fill: rgba(223,223,223,1);
	}
	.FlamingoFiesta_Mob_screen_box_1_line_top_website_ {
		overflow: visible;
		position: absolute;
		width: 31.843px;
		height: 1.178px;
		left: 153.272px;
		top: 31.916px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_box_1_line_down_website {
		fill: rgba(223,223,223,1);
	}
	.FlamingoFiesta_Mob_screen_box_1_line_down_website {
		overflow: visible;
		position: absolute;
		width: 26.469px;
		height: 1.178px;
		left: 153.272px;
		top: 35.457px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_box_2_website_2mob_2 {
		fill: rgba(63,117,225,1);
	}
	.FlamingoFiesta_Mob_screen_box_2_website_2mob_2 {
		overflow: visible;
		position: absolute;
		width: 11.793px;
		height: 10.615px;
		left: 137.941px;
		top: 48.429px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_box_line_top_2_website_ {
		fill: rgba(223,223,223,1);
	}
	.FlamingoFiesta_Mob_screen_box_line_top_2_website_ {
		overflow: visible;
		position: absolute;
		width: 31.843px;
		height: 1.178px;
		left: 153.272px;
		top: 48.429px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_box_line_2_website_2mob {
		fill: rgba(223,223,223,1);
	}
	.FlamingoFiesta_Mob_screen_box_line_2_website_2mob {
		overflow: visible;
		position: absolute;
		width: 31.843px;
		height: 1.178px;
		left: 153.272px;
		top: 51.966px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_box_3_website_2 {
		fill: rgba(63,117,225,1);
	}
	.FlamingoFiesta_Mob_screen_box_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 11.793px;
		height: 10.615px;
		left: 137.941px;
		top: 64.94px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_section_2_line_website_ {
		fill: rgba(223,223,223,1);
	}
	.FlamingoFiesta_Mob_screen_section_2_line_website_ {
		overflow: visible;
		position: absolute;
		width: 31.843px;
		height: 1.178px;
		left: 153.272px;
		top: 64.94px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screeen_section_line_website_2 {
		fill: rgba(223,223,223,1);
	}
	.FlamingoFiesta_Mob_screeen_section_line_website_2 {
		overflow: visible;
		position: absolute;
		width: 32.056px;
		height: 1.178px;
		left: 153.272px;
		top: 68.479px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_dot_website_2_mob_2 {
		fill: rgba(56,210,159,1);
	}
	.FlamingoFiesta_Mob_screen_dot_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 2.662px;
		height: 2.662px;
		left: 160.303px;
		top: 23.96px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_top_dot_website_2_mob_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_screen_top_dot_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 3.662px;
		height: 3.662px;
		left: 160.303px;
		top: 12.978px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_top_line_2_website_mob_ {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_screen_top_line_2_website_mob_ {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 7.321px;
		left: 161.634px;
		top: 5.657px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_top_line_1_website_2_mo {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_screen_top_line_1_website_2_mo {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 9.607px;
		left: 161.634px;
		top: 15.684px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_top_down_dot_website_2_ {
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_screen_top_down_dot_website_2_ {
		overflow: visible;
		position: absolute;
		width: 2.662px;
		height: 2.662px;
		left: 160.303px;
		top: 23.96px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_scrren_top_dot_website_2_mob_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_scrren_top_dot_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 3.662px;
		height: 3.662px;
		left: 160.303px;
		top: 12.978px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_line_down_website_2_mob {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_screen_line_down_website_2_mob {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 16.048px;
		left: 161.634px;
		top: 82.528px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_dot_down_website_2_mob_ {
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_screen_dot_down_website_2_mob_ {
		overflow: visible;
		position: absolute;
		width: 2.662px;
		height: 2.662px;
		left: 160.303px;
		top: 81.197px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_line_left_website_2_mob {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_screen_line_left_website_2_mob {
		overflow: visible;
		position: absolute;
		width: 16.469px;
		height: 2px;
		left: 94.417px;
		top: 55.074px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_dot_right_website_2_mob {
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_screen_dot_right_website_2_mob {
		overflow: visible;
		position: absolute;
		width: 2.662px;
		height: 2.662px;
		left: 109.555px;
		top: 53.743px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_mid_stem_website_2_mob_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_pot_1_mid_stem_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 11.98px;
		height: 64.109px;
		left: 32.755px;
		top: 65.224px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_website_2_mob_2 {
		fill: rgba(241,90,90,1);
	}
	.FlamingoFiesta_Mob_pot_1_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 54.99px;
		height: 30.615px;
		left: 6.544px;
		top: 120.131px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_5_website_2_mob_2 {
		fill: rgba(77,182,172,1);
	}
	.FlamingoFiesta_Mob_pot_1_leaf_5_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 18.303px;
		height: 12.613px;
		left: 39.34px;
		top: 60.555px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_4_website_2_mob_2 {
		fill: rgba(77,182,172,1);
	}
	.FlamingoFiesta_Mob_pot_1_leaf_4_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 10.026px;
		height: 16.489px;
		left: 28.026px;
		top: 65.058px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_3_website_2_mob_2 {
		fill: rgba(77,182,172,1);
	}
	.FlamingoFiesta_Mob_pot_1_leaf_3_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 21.274px;
		height: 18.428px;
		left: 34.039px;
		top: 76.039px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_2_website_2_mob_2 {
		fill: rgba(77,182,172,1);
	}
	.FlamingoFiesta_Mob_pot_1_leaf_2_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 13.46px;
		height: 25.567px;
		left: 20.051px;
		top: 81.03px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_1_website_2_mob_2 {
		fill: rgba(77,182,172,1);
	}
	.FlamingoFiesta_Mob_pot_1_leaf_1_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 13.324px;
		height: 22.542px;
		left: 33.004px;
		top: 96.671px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_stem5_website_2_mob {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_pot_1_leaf_stem5_website_2_mob {
		overflow: visible;
		position: absolute;
		width: 18.422px;
		height: 12.189px;
		left: 39.507px;
		top: 60.897px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_stem_4_website_2_mo {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_pot_1_leaf_stem_4_website_2_mo {
		overflow: visible;
		position: absolute;
		width: 21.82px;
		height: 19.119px;
		left: 34.016px;
		top: 76.039px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_stem_3_website_2_mo {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_pot_1_leaf_stem_3_website_2_mo {
		overflow: visible;
		position: absolute;
		width: 9.594px;
		height: 16.368px;
		left: 28.026px;
		top: 65.057px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_stem_2_website_2_mo {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_pot_1_leaf_stem_2_website_2_mo {
		overflow: visible;
		position: absolute;
		width: 9.773px;
		height: 26.131px;
		left: 23.7px;
		top: 81.03px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_stem_1_webstite_2_m {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_pot_1_leaf_stem_1_webstite_2_m {
		overflow: visible;
		position: absolute;
		width: 13.843px;
		height: 23.295px;
		left: 33.018px;
		top: 96.671px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_tp_table__mob_2 {
		fill: rgba(223,223,223,1);
	}
	.FlamingoFiesta_Mob_tp_table__mob_2 {
		overflow: visible;
		position: absolute;
		width: 323.27px;
		height: 10.958px;
		left: 0px;
		top: 150.613px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_table_below_website_2_mob_2 {
		fill: rgba(223,223,223,1);
	}
	.FlamingoFiesta_Mob_table_below_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 295.872px;
		height: 4.483px;
		left: 13.697px;
		top: 161.571px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_line_right_website_2_mo {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_screen_line_right_website_2_mo {
		overflow: visible;
		position: absolute;
		width: 14.935px;
		height: 2px;
		left: 212.049px;
		top: 55.074px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_dot_right_website_2_mob_hf {
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_screen_dot_right_website_2_mob_hf {
		overflow: visible;
		position: absolute;
		width: 2.662px;
		height: 2.662px;
		left: 210.718px;
		top: 53.743px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_dot_website_2_mob_2_hg {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_screen_dot_website_2_mob_2_hg {
		overflow: visible;
		position: absolute;
		width: 3.662px;
		height: 3.662px;
		left: 227.024px;
		top: 53.743px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pen_3_website_2_mob_2 {
		fill: rgba(82,83,96,1);
	}
	.FlamingoFiesta_Mob_pen_3_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.324px;
		height: 33.277px;
		left: 278.105px;
		top: 105.822px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_top_cap_2_website_2_mob_2 {
		fill: rgba(57,210,159,1);
	}
	.FlamingoFiesta_Mob_top_cap_2_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.324px;
		height: 10.981px;
		left: 278.105px;
		top: 94.841px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pen_bottom_2_website_2_mob_2 {
		fill: rgba(82,83,96,1);
	}
	.FlamingoFiesta_Mob_pen_bottom_2_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.325px;
		height: 33.277px;
		left: 285.426px;
		top: 108.484px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pen_2_tcap_website_2_mob_2 {
		fill: rgba(77,137,239,1);
	}
	.FlamingoFiesta_Mob_pen_2_tcap_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.325px;
		height: 10.981px;
		left: 285.426px;
		top: 97.503px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pen_1_bottom_website_2_mob_2 {
		fill: rgba(82,83,96,1);
	}
	.FlamingoFiesta_Mob_pen_1_bottom_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.325px;
		height: 33.277px;
		left: 294.411px;
		top: 114.141px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pen_1_website_2_mob_2 {
		fill: rgba(245,95,68,1);
	}
	.FlamingoFiesta_Mob_pen_1_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.325px;
		height: 10.981px;
		left: 294.411px;
		top: 103.16px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pen_pot_website_2_mob_2 {
		fill: rgba(241,90,90,1);
	}
	.FlamingoFiesta_Mob_pen_pot_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 30.615px;
		height: 34.276px;
		left: 273.446px;
		top: 116.138px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_topest_leafe_mob_2 {
		fill: rgba(77,182,172,1);
	}
	.FlamingoFiesta_Mob_topest_leafe_mob_2 {
		overflow: visible;
		position: absolute;
		width: 9.107px;
		height: 16.489px;
		left: 31.809px;
		top: 50.065px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_3_website_2_mob_2_hp {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_pot_1_leaf_3_website_2_mob_2_hp {
		overflow: visible;
		position: absolute;
		width: 9.093px;
		height: 15.832px;
		left: 32.23px;
		top: 50.581px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_Illustration_grp_website_2_grp {
		opacity: 0.997;
		position: absolute;
		width: 274.24px;
		height: 184.898px;
		left: 62.603px;
		top: 157.181px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_floor_design_mob_2 {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_floor_design_mob_2 {
		overflow: visible;
		position: absolute;
		width: 270.381px;
		height: 56.735px;
		left: 3.093px;
		top: 128.162px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_bg_grey_deisng_mob_2 {
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_bg_grey_deisng_mob_2 {
		overflow: visible;
		position: absolute;
		width: 274.215px;
		height: 152.782px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_bg_black_design__mob_2_hu {
		fill: url(#FlamingoFiesta_Mob_bg_black_design__mob_2_hu);
	}
	.FlamingoFiesta_Mob_bg_black_design__mob_2_hu {
		overflow: visible;
		position: absolute;
		width: 274.215px;
		height: 152.782px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_top_right_rod_website_2_mob_2 {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_top_right_rod_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 3.188px;
		height: 56.626px;
		left: 245.432px;
		top: 9.486px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_rod_top_hori_star_1_website_2_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_rod_top_hori_star_1_website_2_ {
		overflow: visible;
		position: absolute;
		width: 252.569px;
		height: 3.188px;
		left: 13.782px;
		top: 31.943px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_star_9_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_9_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 1.877px;
		height: 1.877px;
		left: 58.73px;
		top: 16.799px;
	}
	#FlamingoFiesta_Mob_star_8_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_8_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 1.877px;
		height: 1.877px;
		left: 29.059px;
		top: 14.946px;
	}
	#FlamingoFiesta_Mob_star_7_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_7_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 0.791px;
		height: 0.791px;
		left: 69.725px;
		top: 17.342px;
	}
	#FlamingoFiesta_Mob_star_6_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_6_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 0.791px;
		height: 0.791px;
		left: 39.929px;
		top: 16.428px;
	}
	#FlamingoFiesta_Mob_star_5_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_5_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 0.791px;
		height: 0.791px;
		left: 39.534px;
		top: 44.197px;
	}
	#FlamingoFiesta_Mob_star_4_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_4_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 0.791px;
		height: 0.791px;
		left: 223px;
		top: 23.198px;
	}
	#FlamingoFiesta_Mob_star_3_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_3_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 0.791px;
		height: 0.791px;
		left: 88.278px;
		top: 23.568px;
	}
	#FlamingoFiesta_Mob_star_2_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_2_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 0.791px;
		height: 0.791px;
		left: 203.383px;
		top: 22.778px;
	}
	#FlamingoFiesta_Mob_star_1_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_1_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 0.791px;
		height: 0.791px;
		left: 253.462px;
		top: 23.963px;
	}
	#FlamingoFiesta_Mob_building_website_2_mob_2 {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_building_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 260.128px;
		height: 80.516px;
		left: 14.111px;
		top: 72.412px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_building_shadow__website_2_mob {
		opacity: 0.3;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_building_shadow__website_2_mob {
		overflow: visible;
		position: absolute;
		width: 260.128px;
		height: 80.516px;
		left: 14.111px;
		top: 72.412px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_win_0_website_2_mob_2 {
		opacity: 0.42;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_win_0_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 21.943px;
		top: 93.091px;
	}
	#FlamingoFiesta_Mob_left_window_0_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_0_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 33.258px;
		top: 93.091px;
	}
	#FlamingoFiesta_Mob_right_window_12_website_2_mob_ {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_12_website_2_mob_ {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 163.063px;
		top: 83.282px;
	}
	#FlamingoFiesta_Mob_right_window_11_website_2_mob_ {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_11_website_2_mob_ {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 182.136px;
		top: 83.282px;
	}
	#FlamingoFiesta_Mob_right_window_0_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_0_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 202.642px;
		top: 83.282px;
	}
	#FlamingoFiesta_Mob_right_window_9_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_9_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 163.063px;
		top: 97.858px;
	}
	#FlamingoFiesta_Mob_right_window_8_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_8_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 182.136px;
		top: 97.858px;
	}
	#FlamingoFiesta_Mob_right_window_7_website_2_mob_2 {
		fill: rgba(255,255,255,1);
	}
	.FlamingoFiesta_Mob_right_window_7_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 202.642px;
		top: 97.858px;
	}
	#FlamingoFiesta_Mob_right_window_6_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_6_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 163.063px;
		top: 112.435px;
	}
	#FlamingoFiesta_Mob_right_window_5_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_5_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 182.136px;
		top: 112.435px;
	}
	#FlamingoFiesta_Mob_right_window_4_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_4_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 202.642px;
		top: 112.435px;
	}
	#FlamingoFiesta_Mob_right_window_3_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_3_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.04px;
		height: 4.768px;
		left: 227.521px;
		top: 83.307px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_right_window_2_website_2_mob_2 {
		fill: rgba(255,255,255,1);
	}
	.FlamingoFiesta_Mob_right_window_2_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 227.545px;
		top: 97.858px;
	}
	#FlamingoFiesta_Mob_right_window_1_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_1_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 227.545px;
		top: 112.435px;
	}
	#FlamingoFiesta_Mob_left_window_9_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_9_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 61.151px;
		top: 77.723px;
	}
	#FlamingoFiesta_Mob_left_window_8_website_2_mob_2 {
		fill: rgba(255,255,255,1);
	}
	.FlamingoFiesta_Mob_left_window_8_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 61.151px;
		top: 88.421px;
	}
	#FlamingoFiesta_Mob_left_window_7_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_7_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 61.151px;
		top: 99.094px;
	}
	#FlamingoFiesta_Mob_left_window_6_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_6_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 61.151px;
		top: 109.792px;
	}
	#FlamingoFiesta_Mob_left_window_5_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_5_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 61.151px;
		top: 120.489px;
	}
	#FlamingoFiesta_Mob_left_window_4_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_4_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 21.943px;
		top: 105.542px;
	}
	#FlamingoFiesta_Mob_left_window_3_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_3_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 33.258px;
		top: 105.542px;
	}
	#FlamingoFiesta_Mob_left_window_2_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_2_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 21.943px;
		top: 118.019px;
	}
	#FlamingoFiesta_Mob_left_window_1_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_1_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 33.258px;
		top: 118.019px;
	}
	#FlamingoFiesta_Mob_verticle_left_website_2_mob_2 {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_verticle_left_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 3.187px;
		height: 149.372px;
		left: 48.329px;
		top: 0.024px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_veritical_right_website_2_mob_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_veritical_right_website_2_mob_ {
		overflow: visible;
		position: absolute;
		width: 3.187px;
		height: 128.52px;
		left: 146.881px;
		top: 24.161px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_bar_down_hori_website_2_mob_2 {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_bar_down_hori_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 269.912px;
		height: 3.188px;
		left: 0.103px;
		top: 116.19px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_leg_top_website_2_mob_2 {
		fill: rgba(101,97,125,1);
	}
	.FlamingoFiesta_Mob_left_leg_top_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 39.62px;
		height: 19.339px;
		left: 81.963px;
		top: 142.431px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_leg_down_website_2_mob_2 {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_left_leg_down_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 39.453px;
		height: 16.734px;
		left: 101.696px;
		top: 157.82px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_neck_website_2_mob_2 {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_neck_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 34.401px;
		height: 27.331px;
		left: 115.9px;
		top: 66.106px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_face_circle_website_2_mob_2 {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_face_circle_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 25.447px;
		height: 25.447px;
		left: 118.938px;
		top: 51.684px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_short_website_2_mob_2 {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_short_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 60.356px;
		height: 61.814px;
		left: 103.967px;
		top: 79.156px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_hand_behind_laptop_websit {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_left_hand_behind_laptop_websit {
		overflow: visible;
		position: absolute;
		width: 12.896px;
		height: 12.032px;
		left: 102.41px;
		top: 124.887px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_right_leg_upper_leg_website_2_ {
		fill: rgba(101,97,125,1);
	}
	.FlamingoFiesta_Mob_right_leg_upper_leg_website_2_ {
		overflow: visible;
		position: absolute;
		width: 38.422px;
		height: 20.587px;
		left: 152.563px;
		top: 134.219px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_right_leg_top_website_2_mob_2 {
		fill: rgba(101,97,125,1);
	}
	.FlamingoFiesta_Mob_right_leg_top_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 47.408px;
		height: 15.182px;
		left: 137.125px;
		top: 150.235px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_leg_1_website_2_mob_2 {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_left_leg_1_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 43.979px;
		height: 10.792px;
		left: 132.181px;
		top: 163.808px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_leg_website_2_mob_2 {
		fill: rgba(101,97,125,1);
	}
	.FlamingoFiesta_Mob_left_leg_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 53.266px;
		height: 25.066px;
		left: 87.488px;
		top: 149.099px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_hand_website_2_mob_2 {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_left_hand_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 15.89px;
		height: 42.988px;
		left: 97.119px;
		top: 91.855px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_right_hand_down_website_2_mob_ {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_right_hand_down_website_2_mob_ {
		overflow: visible;
		position: absolute;
		width: 17.22px;
		height: 19.718px;
		left: 147.721px;
		top: 122.564px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_right_hand_website_2_mob_2 {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_right_hand_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 13.574px;
		height: 37.157px;
		left: 152.612px;
		top: 93.338px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_laptop_website_2_mob_2 {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_laptop_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 55.366px;
		height: 31.451px;
		left: 105.054px;
		top: 122.071px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_hair_website_2_mob_2 {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_hair_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 43.425px;
		height: 81.407px;
		left: 107.672px;
		top: 37.562px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_leaf_1_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_left_leaf_1_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 6.413px;
		height: 8.746px;
		left: 204.16px;
		top: 138.648px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_leaf_2_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_left_leaf_2_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 6.474px;
		height: 12.155px;
		left: 206.495px;
		top: 134.72px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_leadf_3_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_left_leadf_3_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 4.619px;
		height: 15.615px;
		left: 209.421px;
		top: 131.83px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_right_leaf_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_right_leaf_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 6.985px;
		height: 8.746px;
		left: 216.502px;
		top: 138.648px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_righ_leaf_2_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_righ_leaf_2_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 7.165px;
		height: 12.155px;
		left: 214.13px;
		top: 134.72px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_leafe_3_left_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_leafe_3_left_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.117px;
		height: 15.609px;
		left: 213.059px;
		top: 131.83px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_mid_leaf_left_website_2_mob_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_mid_leaf_left_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 3.089px;
		height: 11.093px;
		left: 211.657px;
		top: 136.178px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_mid_shadow_right_website_2_mob {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_mid_shadow_right_website_2_mob {
		overflow: visible;
		position: absolute;
		width: 3.348px;
		height: 11.391px;
		left: 213.068px;
		top: 135.881px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_leaf_mid_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_leaf_mid_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 4.071px;
		height: 20.535px;
		left: 211.983px;
		top: 126.715px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_behind_new_website_2_mob_2 {
		fill: rgba(212,223,236,1);
	}
	.FlamingoFiesta_Mob_pot_behind_new_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 17.837px;
		height: 9.018px;
		left: 204.272px;
		top: 146.184px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_2_behind_website_2_mob_2 {
		opacity: 0.08;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_pot_2_behind_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 17.837px;
		height: 9.018px;
		left: 204.272px;
		top: 146.184px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_2_rec_bdr_mid_website_2_mo {
		fill: rgba(212,223,236,1);
	}
	.FlamingoFiesta_Mob_pot_2_rec_bdr_mid_website_2_mo {
		position: absolute;
		overflow: visible;
		width: 17.837px;
		height: 2.965px;
		left: 204.964px;
		top: 146.184px;
	}
	#FlamingoFiesta_Mob_over_all_half_cactus__mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_over_all_half_cactus__mob_2 {
		overflow: visible;
		position: absolute;
		width: 19.275px;
		height: 20.083px;
		left: 54.118px;
		top: 125.286px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_cactus_2_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_cactus_2_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 8.247px;
		height: 10.091px;
		left: 63.396px;
		top: 128.571px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_back_2_website2_mob_2 {
		fill: rgba(212,223,236,1);
	}
	.FlamingoFiesta_Mob_pot_1_back_2_website2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 15.169px;
		height: 10.648px;
		left: 53.468px;
		top: 143.639px;
	}
	#FlamingoFiesta_Mob_pot_1_down_2_website_2_mob_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_pot_1_down_2_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 11.612px;
		height: 3.039px;
		left: 55.321px;
		top: 153.57px;
	}
	#FlamingoFiesta_Mob_pot_1_box_2_website_2_mob_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_pot_1_box_2_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 15.169px;
		height: 10.648px;
		left: 53.468px;
		top: 143.639px;
	}
	#FlamingoFiesta_Mob_pot_1_down_1_website_2_mob_2 {
		fill: rgba(212,223,236,1);
	}
	.FlamingoFiesta_Mob_pot_1_down_1_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 11.612px;
		height: 3.039px;
		left: 56.062px;
		top: 153.57px;
	}
	#FlamingoFiesta_Mob_pot_1_cactus_3_website_2_mob_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_pot_1_cactus_3_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 2.767px;
		height: 2.322px;
		left: 60.559px;
		top: 134.794px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_cactus_2_website_2_mob_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_pot_1_cactus_2_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 3.635px;
		height: 3.176px;
		left: 63.396px;
		top: 135.486px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_cactus_1_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_pot_1_cactus_1_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 9.887px;
		height: 10.255px;
		left: 57.149px;
		top: 135.113px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot1_box_down_website_2_mob_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_pot1_box_down_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 11.612px;
		height: 1.112px;
		left: 56.062px;
		top: 153.57px;
	}
	#FlamingoFiesta_Mob_pot_1_box_1_website_2_mob_2 {
		fill: rgba(212,223,236,1);
	}
	.FlamingoFiesta_Mob_pot_1_box_1_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 15.169px;
		height: 10.648px;
		left: 54.234px;
		top: 143.639px;
	}
	#FlamingoFiesta_Mob_contact_area_grp_website_2_mob {
		position: absolute;
		width: 367.128px;
		height: 306px;
		left: 40px;
		top: 4074.879px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_bdr_contact_page_website_2mob {
		fill: rgba(255,255,255,1);
	}
	.FlamingoFiesta_Mob_bdr_contact_page_website_2mob {
		filter: drop-shadow(0px 10px 2px rgba(135, 207, 172, 0.647));
		overflow: visible;
		position: absolute;
		width: 373.128px;
		height: 319px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_n_234567890_mobile_number_webs_mob {
		left: 68px;
		top: 24px;
		position: absolute;
		overflow: visible;
		width: 83px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FlamingoFiesta_Mob_affanghanioperatelive_email_mob {
		left: 68px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 167px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FlamingoFiesta_Mob_address_website_2_mob_ff {
		left: 68px;
		top: 134px;
		position: absolute;
		overflow: visible;
		width: 292px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FlamingoFiesta_Mob_Connect_with_us_and_unlock_lim_mob {
		left: 53px;
		top: 258px;
		position: absolute;
		overflow: visible;
		width: 271px;
		height: 40px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FlamingoFiesta_Mob_location_img_website_2_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 23px;
		top: 127px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_email_address_website_2_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 23px;
		top: 73px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_phone_number_website_2_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 23px;
		top: 19px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_quote_left_webstie_2_mob {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 23px;
		top: 253px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_quote_right_website_2_mob {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 149px;
		top: 278px;
		overflow: visible;
	}


    /* Menu */
    #FlamingoFiesta_Mob_menuToggle
{
display: block;
position: fixed;
width: 40px;
height: 40px;
left: 34px;
top: 25px;

z-index: 1;

-webkit-user-select: none;
user-select: none;
}

#FlamingoFiesta_Mob_menuToggle li
{
text-decoration: none;
color: #232323;

transition: color 0.3s ease;
}

#FlamingoFiesta_Mob_menuToggle li:hover
{
color: #ff4f4f;
}


#FlamingoFiesta_Mob_menuToggle input
{
display: block;
width: 40px;
height: 32px;
position: absolute;
top: -7px;
left: -5px;

cursor: pointer;

opacity: 0; /* hide this */
z-index: 2; /* and place it over the hamburger */

-webkit-touch-callout: none;
}

/*
* Just a quick hamburger
*/
#FlamingoFiesta_Mob_menuToggle span
{
display: block;
width: 33px;
height: 4px;
margin-bottom: 5px;
position: relative;

background: #6495ED;
border-radius: 3px;

z-index: 1;

transform-origin: 4px 0px;

transition: transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
background: 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
opacity: 0.55s ease;
}

#FlamingoFiesta_Mob_menuToggle span:first-child
{
transform-origin: 0% 0%;
}

#FlamingoFiesta_Mob_menuToggle span:nth-last-child(2)
{
transform-origin: 0% 100%;
}

/* 
* Transform all the slices of hamburger
* into a crossmark.
*/
#FlamingoFiesta_Mob_menuToggle input:checked ~ span
{
opacity: 1;
transform: rotate(45deg) translate(-2px, -1px);
background: rgba(255,75,83,1);
}

/*
* But let's hide the middle one.
*/
#FlamingoFiesta_Mob_menuToggle input:checked ~ span:nth-last-child(3)
{
opacity: 0;
transform: rotate(0deg) scale(0.2, 0.2);
}

/*
* Ohyeah and the last one should go the other direction
*/
#FlamingoFiesta_Mob_menuToggle input:checked ~ span:nth-last-child(2)
{
transform: rotate(-45deg) translate(0, -1px);
}

/*
* Make this absolute positioned
* at the top left of the screen
*/
#FlamingoFiesta_Mob_menu
{
position: absolute;
width: 230px;
margin: -100px 0 0 -50px;
padding: 50px;
padding-top: 125px;
height: 100vh;
background: #ededed;
list-style-type: none;
-webkit-font-smoothing: antialiased;
/* to stop flickering of text in safari */
transform-origin: 0% 0%;
transform: translate(-100%, 0);
transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
background: rgba( 255, 255, 255, 0.25 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 10px );
-webkit-backdrop-filter: blur( 10px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}

#FlamingoFiesta_Mob_menu li
{
padding: 10px 0;
font-size: 22px;
}
#FlamingoFiesta_Mob_menuToggle input:checked ~ ul
{
transform: none;
}
