.mediaViewInfo {
    --web-view-name: requrements page pc new;
    --web-view-id: requrements_page_pc_new;
    --web-scale-on-resize: true;
    --web-center-horizontally: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: requrements_page_pc_new;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#requrements_page_pc_new {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 60px);
    top:60px;
    left:120px;
	background-color: rgba(255,255,255,1);
    overflow-x: hidden;
    overflow-y: scroll;
    --web-view-name: requrements page pc new;
    --web-view-id: requrements_page_pc_new;
    --web-scale-on-resize: true;
    --web-center-horizontally: true;
    --web-enable-deep-linking: true;
}
#heading_grp_offreqevent_req_PC {
    position: absolute;
    width: 318px;
    height: 42px;
    left: calc(50% - 170px);
    top: 22px;
    overflow: visible;
}
#req_offreqevent_req_PC {
    opacity: 0.92;
    left: 115px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 110px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
    color: rgba(255,79,4,1);
}
#selected_grp_offreqevent_req_P {
    position: absolute;
    width: 73px;
    height: 24px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#requirements_offreqevent_req_P {
    opacity: 0.92;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 74px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
    color: rgba(0,0,0,1);
}
#A_offreqevent_req_PC {
    opacity: 0.92;
    left: 267px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
    color: rgba(48,47,47,1);
}
#underline_offreqeventreq_PC {
    fill: rgba(255,79,4,1);
}
.underline_offreqeventreq_PC {
    position: absolute;
    overflow: visible;
    width: 109px;
    height: 3px;
    left: 115px;
    top: 39px;
}
#filters_area_new_events_page {
    position: absolute;
    width: 555px;
    height: 30px;
    left: calc((100% - 400px)/2);
    top: 81px;
    overflow: visible;
}
#bdr_new_events_page_pc {
    fill: rgba(173,245,206,1);
}
.bdr_new_events_page_pc {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 30px;
    left: 425px;
    top: 0px;
}
#Find_Event_new_events_page_pc {
    left: 451px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 79px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -6px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(4,4,4,1);
}
#category_grp_pc {
    position: absolute;
    width: 209px;
    height: 30px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#category_input_new_events_page {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.category_input_new_events_page {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 30px;
    left: 79px;
    top: 0px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Category_new_events_page_pc {
    left: 0px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 70px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -3.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#time_cate_gory_pc {
    position: absolute;
    width: 172px;
    height: 30px;
    left: 227px;
    top: 0px;
    overflow: visible;
}
#time_bdr_filter_new_events_pag {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.time_bdr_filter_new_events_pag {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 30px;
    left: 42px;
    top: 0px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Time_new_events_page {
    left: 0px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 38px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -3.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#swtitch_grp_event_req_PC {
    position: absolute;
    width: 365px;
    height: 21px;
    left: 6%;
    top: 85px;
    overflow: visible;
}
#My_requirements_req_pc {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 73px;
    white-space: nowrap;
    line-height: 27px;
    margin-top: -5.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(17,17,17,1);
}
#Community_requirements_req_PC {
    left: 180px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 133px;
    white-space: nowrap;
    line-height: 27px;
    margin-top: -5.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(17,17,17,1);
}
#switch_eent__req_PC {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.switch_eent_req_PC {
    position: absolute;
    overflow: visible;
    width: 44px;
    height: 17px;
    left: 125px;
    top: -8px;
}