	.Aug_PC_mediaViewInfo {
		--web-view-name: calender;
		--web-view-id: calender;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: calender;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Aug_PC_calender {
		position: relative;
    width: 306px;
    height: 276px;
    margin: 15px;
    margin-top: 80px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: calender;
    --web-view-id: calender;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
	}
	#Aug_PC_Rectangle_692 {
		fill: rgba(255,255,255,1);
	}
	.Aug_PC_Rectangle_692 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 309px;
		height: 233px;
		left: 8px;
		top: 46px;
	}
	#Aug_PC_Rectangle_691 {
		fill: rgba(255,255,255,1);
	}
	.Aug_PC_Rectangle_691 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 309px;
		height: 46px;
		left: 8px;
		top: 9px;
	}
	#Aug_PC_Group_14898 {
		position: absolute;
		width: 283px;
		height: 242px;
		left: 12px;
		top: 16px;
		overflow: visible;
	}
	#Aug_PC_August_2023 {
		left: 105px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_Path_6951 {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1.2000000476837158px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Aug_PC_Path_6951 {
		overflow: visible;
		position: absolute;
		width: 6.273px;
		height: 10.849px;
		left: 8.5px;
		top: 2.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Aug_PC_Path_6952 {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1.2000000476837158px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Aug_PC_Path_6952 {
		overflow: visible;
		position: absolute;
		width: 6.273px;
		height: 10.849px;
		left: 270.5px;
		top: 2.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Aug_PC_Mon {
		left: 0px;
		top: 34px;
		position: absolute;
		overflow: visible;
		width: 23px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_Tue {
		left: 46px;
		top: 34px;
		position: absolute;
		overflow: visible;
		width: 19px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_Wed {
		left: 87px;
		top: 34px;
		position: absolute;
		overflow: visible;
		width: 23px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_Thu {
		left: 133px;
		top: 34px;
		position: absolute;
		overflow: visible;
		width: 19px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_Fri {
		left: 179px;
		top: 34px;
		position: absolute;
		overflow: visible;
		width: 13px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_Sat {
		left: 221px;
		top: 34px;
		position: absolute;
		overflow: visible;
		width: 17px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_Sun {
		left: 263px;
		top: 34px;
		position: absolute;
		overflow: visible;
		width: 21px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_1 {
		left: 4px;
		top: 72px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(170,170,170,1);
	}
	#Aug_PC_n_ {
		left: 54px;
		top: 72px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__cs {
		left: 97px;
		top: 72px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__ct {
		left: 139px;
		top: 72px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__cu {
		left: 182px;
		top: 72px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__cv {
		left: 224px;
		top: 72px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__cw {
		left: 270px;
		top: 72px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__cx {
		left: 8px;
		top: 111px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__cy {
		left: 54px;
		top: 111px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__cz {
		left: 97px;
		top: 111px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_0 {
		left: 135px;
		top: 111px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_1_c {
		left: 179px;
		top: 111px;
		position: absolute;
		overflow: visible;
		width: 13px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_2 {
		left: 222px;
		top: 111px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_3 {
		left: 266px;
		top: 111px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_4 {
		left: 4px;
		top: 150px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_5 {
		left: 49px;
		top: 150px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_6 {
		left: 92px;
		top: 150px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_7 {
		left: 135px;
		top: 150px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_8 {
		left: 178px;
		top: 150px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_9 {
		left: 222px;
		top: 150px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_0_da {
		left: 266px;
		top: 150px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_1_db {
		left: 4px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_2_dc {
		left: 49px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_3_dd {
		left: 92px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_4_de {
		left: 135px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_5_df {
		left: 178px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_6_dg {
		left: 222px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_7_dh {
		left: 266px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_8_di {
		left: 4px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_9_dj {
		left: 49px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_0_dk {
		left: 92px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_1_dl {
		left: 135px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__dm {
		left: 182px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(170,170,170,1);
	}
	#Aug_PC_n__dn {
		left: 224px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(170,170,170,1);
	}
	#Aug_PC_n__do {
		left: 270px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(170,170,170,1);
	}
