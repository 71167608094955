.mediaViewInfo {
    --web-view-name: off feed mob;
    --web-view-id: off_feed_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: off_feed_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#off_feed_mob {
    position: relative;
    width: 100%;
    height: 623px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: off feed mob;
    --web-view-id: off_feed_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#offering_ffeed_grp_offy_feed_m {
    position: absolute;
    width: 100%;
    height: 608.008px;
    margin: 5px;
    top: 8px;
    overflow: visible;
}
#off_feed_bdr_offy_feed_mob {
    fill: rgba(255,255,255,1);
}
.off_feed_bdr_offy_feed_mob {
    filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 616px;
    left: 0.736px;
    top: 0px;
}
#Offerings_Feedoffy_feed_mob {
    left: 12.736px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#off_feed_scroll_offy_feed_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 570.576px;
    left: 0px;
    top: 35.431px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#shadow_offy_feed_mob_feed {
    fill: rgba(255,255,255,1);
}
.shadow_offy_feed_mob_feed {
    filter: drop-shadow(0px 10px 80px rgba(123, 126, 246, 0.251));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 333px;
    left: 14px;
    top: 81.244px;
}
#card_1_offy_feed_mob {
    position: relative;
    width: 50%;
    height: 130px;
    left: 9px;
    top: 58.569px;
    display: inline-block;
    overflow: visible;
}
#card_1_of_feed_1_offy_feed_mob {
    position: absolute;
    width: 175px;
    height: 91.35px;
    left: 0px;
    top: 21px;
    object-fit: contain;
    overflow: hidden;
}
#Offering_lbl_offy_feed_mob {
    top: 113px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,46,72,1);
}
#Mohammed_Affan_offy_feed_mob {
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,46,72,1);
}