	.mediaViewInfo {
		--web-view-name: Mobile Stock mob;
		--web-view-id: Mobile_Stock_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Mobile_Stock_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Mobile_Stock_mob {
		position: absolute;
		width: 375px;
		height: 3883px;
        left:calc((100vw - 375px)/2);
		background-color: rgba(255,255,255,0.886);
		overflow: hidden;
		--web-view-name: Mobile Stock mob;
		--web-view-id: Mobile_Stock_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#theme11_stock_mobBanner_Slider {
		position: absolute;
		width: 425.89px;
		height: 222.257px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobPath_2 {
		fill: rgba(60,65,223,1);
	}
	.theme11_stock_mobPath_2 {
		overflow: visible;
		position: absolute;
		width: 425.89px;
		height: 222.257px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_mobdugba-cauley-hushie-6MNmDi1hc_ {
		position: absolute;
		width: 425.89px;
		height: 207.917px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobPath_1527 {
		opacity: 0.21;
		fill: rgba(38,43,219,1);
	}
	.theme11_stock_mobPath_1527 {
		overflow: visible;
		position: absolute;
		width: 425.89px;
		height: 207.917px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_mobYour_Mobile_Paradise__Where_Dr {
		left: 170px;
		top: 83.958px;
		position: absolute;
		overflow: visible;
		width: 189px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#theme11_stock_mobBtg {
		position: absolute;
		width: 72.37px;
		height: 19.497px;
		left: 228px;
		top: 139.878px;
		overflow: visible;
	}
	#theme11_stock_mobRectangle_77 {
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobRectangle_77 {
		position: absolute;
		overflow: visible;
		width: 72.371px;
		height: 19.497px;
		left: 0px;
		top: 0px;
	}
	#theme11_stock_mobExplore_More {
		left: 6.185px;
		top: 3.248px;
		position: absolute;
		overflow: visible;
		width: 61px;
		white-space: nowrap;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#theme11_stock_mobGroup_14896 {
		position: absolute;
		width: 355px;
		height: 604.548px;
		left: 20px;
		top: 991.858px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14887 {
		position: absolute;
		width: 355px;
		height: 327.318px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobstyle {
		position: absolute;
		width: 286.669px;
		height: 286.727px;
		left: 68.332px;
		top: 14.033px;
		overflow: visible;
	}
	#theme11_stock_mobPath_28 {
		fill: rgba(13,19,215,1);
	}
	.theme11_stock_mobPath_28 {
		overflow: visible;
		position: absolute;
		width: 284.648px;
		height: 286.727px;
		left: 2.021px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_mobEllipse_6 {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobEllipse_6 {
		position: absolute;
		overflow: visible;
		width: 238.554px;
		height: 238.554px;
		left: 0px;
		top: 29.354px;
	}
	#theme11_stock_mobEllipse_5 {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobEllipse_5 {
		position: absolute;
		overflow: visible;
		width: 135.854px;
		height: 135.854px;
		left: 51.35px;
		top: 80.703px;
	}
	#theme11_stock_mobneil-soni-6wdRuK7bVTE-unsplash {
		position: absolute;
		width: 203.69px;
		height: 327.318px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14888 {
		position: absolute;
		width: 341px;
		height: 232px;
		left: 14px;
		top: 372.548px;
		overflow: visible;
	}
	#theme11_stock_mobScroll_Group_24 {
		mix-blend-mode: normal;
		position: absolute;
		width: 341px;
		height: 199px;
		left: 0px;
		top: 33px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#theme11_stock_mobIt_is_a_long_established_fact_ {
		left: 0px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 342px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#theme11_stock_mobMISSION {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_mobGroup_14895 {
		position: absolute;
		width: 361px;
		height: 646.091px;
		left: 0px;
		top: 286.84px;
		overflow: visible;
	}
	#theme11_stock_mobstyle_cx {
		position: absolute;
		width: 298.024px;
		height: 298.085px;
		left: 0px;
		top: 24.776px;
		overflow: visible;
	}
	#theme11_stock_mobPath_28_cy {
		fill: rgba(13,19,215,1);
	}
	.theme11_stock_mobPath_28_cy {
		overflow: visible;
		position: absolute;
		width: 295.924px;
		height: 298.085px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_mobEllipse_6_cz {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobEllipse_6_cz {
		position: absolute;
		overflow: visible;
		width: 248.003px;
		height: 248.003px;
		left: 50.021px;
		top: 30.516px;
	}
	#theme11_stock_mobEllipse_5_c {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobEllipse_5_c {
		position: absolute;
		overflow: visible;
		width: 141.236px;
		height: 141.235px;
		left: 103.405px;
		top: 83.899px;
	}
	#theme11_stock_mobdaniel-korpai-H0vKZjt2k1E-unsp_c {
		position: absolute;
		width: 203.692px;
		height: 327.712px;
		left: 139.583px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14886 {
		position: absolute;
		width: 341px;
		height: 233px;
		left: 20px;
		top: 413.091px;
		overflow: visible;
	}
	#theme11_stock_mobScroll_Group_24_c {
		mix-blend-mode: normal;
		position: absolute;
		width: 341px;
		height: 199px;
		left: 0px;
		top: 34px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#theme11_stock_mobIt_is_a_long_established_fact__c {
		left: 0px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 342px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#theme11_stock_mobVISION {
		left: 6px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_mobdaniel-korpai-H0vKZjt2k1E-unsp_c {
		position: absolute;
		width: 203.692px;
		height: 327.712px;
		left: 139.583px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobstyle_c {
		position: absolute;
		width: 298.024px;
		height: 298.085px;
		left: 1px;
		top: 1685.766px;
		overflow: visible;
	}
	#theme11_stock_mobPath_28_c {
		fill: rgba(13,19,215,1);
	}
	.theme11_stock_mobPath_28_c {
		overflow: visible;
		position: absolute;
		width: 295.924px;
		height: 298.085px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_mobEllipse_6_c {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobEllipse_6_c {
		position: absolute;
		overflow: visible;
		width: 248.003px;
		height: 248.003px;
		left: 50.021px;
		top: 30.516px;
	}
	#theme11_stock_mobEllipse_5_da {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobEllipse_5_da {
		position: absolute;
		overflow: visible;
		width: 141.236px;
		height: 141.235px;
		left: 103.405px;
		top: 83.899px;
	}
	#theme11_stock_mobdaniel-korpai-H0vKZjt2k1E-unsp_db {
		position: absolute;
		width: 203.692px;
		height: 327.712px;
		left: 140.583px;
		top: 1660.989px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14889 {
		position: absolute;
		width: 341px;
		height: 238px;
		left: 27px;
		top: 2074.08px;
		overflow: visible;
	}
	#theme11_stock_mobScroll_Group_24_dd {
		mix-blend-mode: normal;
		position: absolute;
		width: 341px;
		height: 199px;
		left: 0px;
		top: 39px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#theme11_stock_mobIt_is_a_long_established_fact__de {
		left: 0px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 342px;
		height: 183.419921875px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#theme11_stock_mobPRINCIPLE {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_mobjoel-rohland-MCL2xxjZJvs-unspl {
		position: absolute;
		width: 203.692px;
		height: 327.712px;
		left: 140.583px;
		top: 1660.989px;
		overflow: visible;
	}
	#theme11_stock_mobour_Services {
		left: 89px;
		top: 2417px;
		position: absolute;
		overflow: visible;
		width: 199px;
		white-space: nowrap;
		line-height: 43px;
		margin-top: -4px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#theme11_stock_mobGroup_14890 {
		position: absolute;
		width: 375px;
		height: 404.096px;
		left: 0px;
		top: 2515.229px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14885 {
		opacity: 0.74;
		position: absolute;
		width: 225.542px;
		height: 362.433px;
		left: 149.458px;
		top: 25.803px;
		overflow: visible;
	}
	#theme11_stock_mobiPhone_X_-_Light2x {
		position: absolute;
		width: 225.542px;
		height: 362.434px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobdariusz-sankowski-3OiYMgDKJ6k- {
		position: absolute;
		width: 125.849px;
		height: 224.486px;
		left: 49.859px;
		top: 69.927px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14884 {
		opacity: 0.74;
		position: absolute;
		width: 225.542px;
		height: 362.433px;
		left: 0px;
		top: 25.803px;
		overflow: visible;
	}
	#theme11_stock_mobiPhone_X_-_Light2x_dn {
		position: absolute;
		width: 225.542px;
		height: 362.434px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobdariusz-sankowski-3OiYMgDKJ6k-_do {
		position: absolute;
		width: 125.849px;
		height: 224.486px;
		left: 49.859px;
		top: 69.927px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14883 {
		position: absolute;
		width: 251.469px;
		height: 404.096px;
		left: 65.408px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobiPhone_X_-_Light2x_dq {
		position: absolute;
		width: 251.469px;
		height: 404.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobdariusz-sankowski-3OiYMgDKJ6k-_dr {
		position: absolute;
		width: 140.315px;
		height: 250.292px;
		left: 55.591px;
		top: 77.965px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14891 {
		position: absolute;
		width: 329px;
		height: 208px;
		left: 28px;
		top: 2925px;
		overflow: visible;
	}
	#theme11_stock_mobTitle {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 31px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_mobScroll_Group_25 {
		mix-blend-mode: normal;
		position: absolute;
		width: 329px;
		height: 168px;
		left: 0px;
		top: 40px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#theme11_stock_mobDescription {
		left: 0px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 330px;
		height: 165px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_mobGroup_14897 {
		position: absolute;
		width: 494px;
		height: 704px;
		left: 0px;
		top: 3223px;
		overflow: visible;
	}
	#theme11_stock_mobButtom {
		position: absolute;
		width: 375px;
		height: 704px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobRectangle_53 {
		fill: rgba(235,235,248,1);
	}
	.theme11_stock_mobRectangle_53 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 704px;
		left: 0px;
		top: 0px;
	}
	#theme11_stock_mobn_234567890_mobile_number_shad {
		left: 50px;
		top: 59px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_mobaffanghanioperatelive_email_sh {
		left: 50px;
		top: 106px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_mobaddress_med_shadow_space_websi {
		left: 50px;
		top: 156px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_moblocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 15px;
		top: 158px;
		overflow: visible;
	}
	#theme11_stock_mobemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 15px;
		top: 105px;
		overflow: visible;
	}
	#theme11_stock_mobphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 15px;
		top: 52px;
		overflow: visible;
	}
	#theme11_stock_mobsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 94px;
		top: 243px;
		overflow: visible;
	}
	#theme11_stock_moblinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.theme11_stock_mobmed_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#theme11_stock_mobmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.theme11_stock_mobmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#theme11_stock_moblinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#theme11_stock_mobn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.theme11_stock_mobn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_moblinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.theme11_stock_moblinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_mobtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.theme11_stock_mobtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_mobInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.theme11_stock_mobshadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#theme11_stock_mobshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.theme11_stock_mobshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#theme11_stock_mobinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#theme11_stock_mobwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobmed_shadow_space_website_7_ei:hover {
		fill: rgba(50,172,158,1);
	}
	.theme11_stock_mobmed_shadow_space_website_7_ei:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#theme11_stock_mobmed_shadow_space_website_7_ei {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.theme11_stock_mobmed_shadow_space_website_7_ei {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#theme11_stock_mobwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14892 {
		position: absolute;
		width: 375px;
		height: 230px;
		left: 30px;
		top: 322px;
		overflow: visible;
	}
	#theme11_stock_mobkisspng-laptop-macbook-pro-mac {
		position: absolute;
		width: 375px;
		height: 230px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobRectangle_690 {
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobRectangle_690 {
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 149px;
		left: 45px;
		top: 38px;
	}
	#theme11_stock_mobn_Copyright_Operate_Live_OPC_P {
		left: 15px;
		top: 598px;
		position: absolute;
		overflow: visible;
		width: 293px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(173,173,173,1);
	}
	#theme11_stock_mobEllipse_62 {
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobEllipse_62 {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 35px;
		left: 10px;
		top: 7px;
	}
	#theme11_stock_mobjakob-rosen-FsBbavP9YA4-unspla {
		position: absolute;
		width: 45px;
		height: 45px;
		left: 19px;
		top: 2px;
        border-radius: 50%;
		overflow: hidden;
	}