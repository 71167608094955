.mediaViewInfo {
    --web-view-name: index page;
    --web-view-id: index_page_Mob_Log;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: index_page_Mob_Log;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

#index_page_Mob_Log {
    position: absolute;
    width: 430px;
    height: 932px;
    background-color: rgba(255,255,255,1);
    /* top: 56%;
    left: 55%;
    transform: translate(-50%, -40%); */
    overflow: hidden;
    --web-view-name: index page;
    --web-view-id: index_page_Mob_Log;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#Logo_Mob_Login {
    position: absolute;
    width: 1386px;
    height: 942px;
    left: 0px;
    top: -5px;
    overflow: visible;
}

#Top_Login_Notch {
    opacity: 0.75;
    fill: rgba(33,177,73,1);
}

.Top_Login_Notch {
    position: absolute;
    overflow: visible;
    width: 472px;
    height: 207.259px;
    left: -20px;
    top: -87px;
}

#Top_Login_border_M {
    fill: rgba(255,255,255,1);
}

.Top_Login_border_M {
    position: absolute;
    overflow: visible;
    width: 349px;
    height: 316px;
    left: 40px;
    top: -87px;
    border-radius:  51px;
    background:  linear-gradient(315deg, #f0f0f0, #cacaca);
    box-shadow:   -23px -23px 43px #929292,
}

#Mob_Login_Border_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Mob_Login_Border_ {
    position: absolute;
    overflow: visible;
    width: 349px;
    height: 667px;
    left: 41px;
    top: 514px;
}
#image_login_Mob {
    position: absolute;
    width: 135px;
    height: 135px;
    left: 143px;
    top: 0px;
    overflow: visible;
}
#OPERATE_LIVE_Mob_Login {
    left: 51px;
    top: 145px;
    position: absolute;
    overflow: visible;
    width: 319px;
    white-space: nowrap;
    line-height: 132px;
    margin-top: -46px;
    text-align: left;
    font-family: Cooper;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgba(33,177,73,1);
}
#txtPassword_Mob_ {
    fill: rgba(255,255,255,1);
}
.txtPassword_Mob_ {
    filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 288px;
    height: 55px;
    left: 74px;
    top: 658px;
    font-size: 20px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius:  50px;
   
}
#txtEmail_Mob_ {
    fill: rgba(255,255,255,1);
}
.txtEmail_Mob_ {
    filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 288px;
    height: 55px;
    left: 74px;
    top: 580px;
    font-size: 20px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius:  50px;
}
#Ellipse_5_Mob_Log {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Ellipse_5_Mob_Log {
    position: absolute;
    overflow: visible;
    width: 72px;
    height: 72px;
    left: 279px;
    top: 479px;
}

#right-arrow1_M_Log {
    position: absolute;
    width: 42px;
    height: 42px;
    left: 297px;
    top: 493px;
    overflow: visible;
}
#slazzer-edit-image1_M_Log {
    position: absolute;
    width: 322px;
    height: 322px;
    left: 51px;
    top: 212px;
    overflow: visible;
}
#Login_button_Mob_Log {
    position: absolute;
    width: 155px;
    height: 43.366px;
    left: 138px;
    top: 758px;
    overflow: visible;
}
#Group_1_Mob_Log {
    position: absolute;
    width: 155px;
    height: 43.366px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#LOGIN_Mob_Lg {
    left: 29.286px;
    top: 19.366px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    line-height: 30.360000610351562px;
    margin-top: -6.680000305175781px;
    text-align: center;
    font-family: Poppins-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(255,255,255,1);
}
#Login_button_z_Mob {
    position: absolute;
    width: 155px;
    height: 39px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Rectangle_2_Mob {
    fill: rgba(33,177,73,1);
}
.Rectangle_2_Mob {
    filter: drop-shadow(3.857px 4.596px 13px rgba(0, 0, 0, 0.141));
    position: absolute;
    overflow: visible;
    width: 194px;
    height: 78px;
    left: 0px;
    top: 0px;
}
#LOGIN__ {
    left: 47.857px;
    top: 8.635px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    line-height: 30.360000610351562px;
    margin-top: -6.680000305175781px;
    text-align: center;
    font-family: Poppins-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(255,255,255,1);
}
#Forgot_Password_Mob_Log {
    left: 246px;
    top: 727px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: forgot_page.html;
    cursor: pointer;
    line-height: 35px;
    margin-top: -11px;
    text-align: center;
    font-family: Poppins-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(133,146,155,1);
}
#Dont_have_an_Account_Mob_Log {
    left: 138px;
    top: 808px;
    position: absolute;
    overflow: visible;
    width: 155px;
    white-space: nowrap;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: sign_up.html;
    cursor: pointer;
    line-height: 35px;
    margin-top: -11px;
    text-align: center;
    font-family: Poppins-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(25,140,255,1);
}