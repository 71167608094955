.mediaViewInfo {
    --web-view-name: forgot page;
    --web-view-id: forgot_page_Mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: forgot_page_Mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#forgot_page_Mob {
    position: absolute;
    width: 430px;
    height: 932px;
    background-color: rgba(255,255,255,1);
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -40%); */
    overflow: hidden;
    --web-view-name: forgot page;
    --web-view-id: forgot_page_Mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Path_5_Mob_F {
    fill: rgba(52,227,105,1);
}
.Path_5_Mob_F {
    overflow: visible;
    position: absolute;
    width: 260.616px;
    height: 160.165px;
    left: 249px;
    top: -17.06px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_6_Mob_F {
    fill: rgba(119,221,150,1);
}
.Path_6_Mob_F {
    overflow: visible;
    position: absolute;
    width: 137.074px;
    height: 83.79px;
    left: 335.073px;
    top: -36.122px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_7_Mob_ {
    fill: rgba(213,213,213,1);
}
.Path_7_Mob_ {
    overflow: visible;
    position: absolute;
    width: 95.817px;
    height: 59.109px;
    left: 403.61px;
    top: 57.944px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_1_Mob_F {
    fill: rgba(52,227,105,1);
}
.Path_1_Mob_F {
    overflow: visible;
    position: absolute;
    width: 284.045px;
    height: 160.202px;
    left: 1.908px;
    top: 626.832px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_2_Mob_F {
    fill: rgba(119,221,150,1);
}
.Path_2_Mob_F {
    overflow: visible;
    position: absolute;
    width: 149.499px;
    height: 84.597px;
    left: 0px;
    top: 591px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_3_Mob_F {
    fill: rgba(229,228,228,1);
}
.Path_3_Mob_F {
    overflow: visible;
    position: absolute;
    width: 214.177px;
    height: 131.242px;
    left: 1.31px;
    top: 694.407px;
    transform: matrix(1,0,0,1,0,0);
}

#image_Mob_F {
    position: absolute;
    width: 135px;
    height: 135px;
    left: 148px;
    top: 135px;
    overflow: visible;
}
#Forgot_Password_Mob_F {
    left: 87px;
    top: 301px;
    position: absolute;
    overflow: visible;
    width: 258px;
    white-space: nowrap;
    line-height: 75.63333129882812px;
    margin-top: -20.316665649414062px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: rgba(1,1,1,1);
}
#Enter_the_email_address_associ_Mob_F {
    left: 40px;
    top: 374px;
    position: absolute;
    overflow: visible;
    width: 351px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#ema_Mob_F {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.ema_Mob_F {
    filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 331.5px;
    height: 49.599px;
    left: 59px;
    top: 439px;
    font-size: 20px;
    padding: 15px;
    border: 1px solid darkcyan;
}
#Group_9_Mob_F {
    position: absolute;
    width: 253.519px;
    height: 37.724px;
    left: 88px;
    top: 538px;
    overflow: visible;
    border-radius: 50px;
}
#Rectangle_2_Mob_F {
    fill: rgba(119,221,150,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rectangle_2_Mob_F {
    filter: drop-shadow(10px 7px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 272.519px;
    height: 55.724px;
    left: 0px;
    top: 0px;
    cursor: pointer;
}
#Continue_Mob_F {
    left: 84.722px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 85px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
    cursor: pointer;
}
#Dont_have_an_account_Sign_up_Mob_F {
    left: 111px;
    top: 591px;
    position: absolute;
    overflow: visible;
    width: 210px;
    white-space: nowrap;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: sign_up.html;
    cursor: pointer;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Group_13_Mob_F {
    position: absolute;
    width: 60px;
    height: 27px;
    left: 325px;
    top: 497px;
    overflow: visible;
    cursor: pointer;
}
#Back_Mob_F {
    left: 19px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#back_M {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 0px;
    top: 6px;
    overflow: visible;
}
#previous_Mob_F {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 10%;
    top: 4%;
    overflow: visible;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: index_page.html;
    cursor: pointer;
}