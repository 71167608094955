	.mediaViewInfo {
		--web-view-name: web 1 mob;
		--web-view-id: web_1_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: web_1_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#partner_img_and_lbl_scroll::-webkit-scrollbar{
		display: none;
	}

    #Scroll_intro_web_1_mob::-webkit-scrollbar{
		display: none;
	}
	#web_1_mob {
		position: absolute;
		width: 375px;
		height: 3412px;
        left:calc((100vw - 375px)/2);
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: web 1 mob;
		--web-view-id: web_1_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#top_banner_color_website_1_mob {
		position: absolute;
		width: 375px;
		height: 134px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#about_name_pic_grp {
		position: absolute;
		width: 359px;
		height: 146px;
		left: 7px;
		top: 190px;
		overflow: visible;
	}
	#pfp_pic_website_1_mob {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#name_website_1_mob {
		left: 81px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 118px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Scroll_intro_web_1_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 278px;
		height: 105px;
		left: 81px;
		top: 41px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#about_me_website_1_mob {
		left: 2px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 278px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Mohammed_Affan_Ghani_lbl_Em {
		left: 90px;
		top: 685px;
		position: absolute;
		overflow: visible;
		width: 197px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#partner_img_and_lbl_scroll {
		mix-blend-mode: normal;
		position: absolute;
		width: 334px;
		height: 64.849px;
		left: 30px;
		top: 746px;
        display: inline-flex;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#bg_partner_mob {
		fill: transparent;
	}
	.bg_partner_mob {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 130px;
		left: -79px;
		top: -50px;
		background:  rgba( 255, 255, 255, 0.5 );
		box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
		backdrop-filter:  blur( 10px );
		-webkit-backdrop-filter:  blur( 10px );
		border-radius:  10px;
		border:  1px solid rgba( 255, 255, 255, 0.18 );
	}
	#partner_lbls_grp_em {
		position: relative;
		width: 175px;
		height: 27px;
		left: 14px;
		top: 9px;
		overflow: visible;
	}
	#google_lbl_partner_mob_website {
		left: 30px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#google_1_mob_website_1 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#all_my_offering_grp_website_1_ {
		position: absolute;
		width: 202px;
		height: 48.62px;
		left: 12px;
		top: 853.5px;
		overflow: visible;
	}
	#heck_out_my_offerings_website_ {
		left: 0px;
		top: 27.62px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 21px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#my_oferings_website_1_mob {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 120px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Scroll_offering_web_1_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 374.352px;
		height: 1256px;
		left: 0.824px;
		top: 941.151px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_offering_web_1_mob::-webkit-scrollbar{
		display: none;
	}
	#my_offering_images_website_1_m {
		position: relative;
		width: 280px;
		height: 333.215px;
		left: 46.676px;
		top: 8px;
		overflow: visible;
	}
	#Project1_website_1_mob {
		position: absolute;
		width: 280px;
		height: 316.214px;
		left: 0px;
		top: 17px;
		overflow: visible;
	}
	#Offer_2_web_1_mob {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 270px;
		height: 17px;
		line-height: 24px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#down_design_website_1_mob {
		position: absolute;
		width: 560.561px;
		height: 475.814px;
		left: 56.986px;
		top: 2159.328px;
		overflow: visible;
	}
	#Path_445_rectangle_website_1_m {
		fill: rgba(143,233,191,1);
	}
	.Path_445_rectangle_website_1_m {
		overflow: visible;
		position: absolute;
		width: 461.299px;
		height: 405.126px;
		transform: translate(92.19px, -2121.328px) matrix(1,0,0,1,-18.8749,2151.4571) rotate(-8deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Path_446_circle_website_1_mob {
		fill: rgba(133,231,187,1);
	}
	.Path_446_circle_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 244.52px;
		height: 244.52px;
		transform: translate(92.19px, -2121.328px) matrix(1,0,0,1,-82.0002,2342.4322) rotate(-5deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Illustration_website_1_mob {
		position: absolute;
		width: 220.314px;
		height: 126.816px;
		left: 40.029px;
		top: 268.783px;
		overflow: visible;
	}
	#table_website_1_mob {
		fill: rgba(69,74,105,1);
	}
	.table_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 220.313px;
		height: 12.862px;
		left: 0px;
		top: 113.955px;
		transform: matrix(1,0,0,1,0,0);
	}
	#hair_back_website_1_mob {
		fill: rgba(69,74,105,1);
	}
	.hair_back_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 55.379px;
		height: 65.604px;
		left: 86.584px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#neck_website_1_mob {
		fill: rgba(238,183,184,1);
	}
	.neck_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 39.55px;
		height: 53.658px;
		left: 93.546px;
		top: 36.581px;
		transform: matrix(1,0,0,1,0,0);
	}
	#shirt_colar_website_1_mob {
		fill: rgba(255,255,255,1);
	}
	.shirt_colar_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 48.83px;
		height: 49.554px;
		left: 91.027px;
		top: 50.956px;
		transform: matrix(1,0,0,1,0,0);
	}
	#shirt_website_1_mob {
		fill: rgba(223,132,126,1);
	}
	.shirt_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 74.635px;
		height: 63.36px;
		left: 77.058px;
		top: 50.468px;
		transform: matrix(1,0,0,1,0,0);
	}
	#face_website_1_mob {
		fill: rgba(238,183,184,1);
	}
	.face_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 33.2px;
		height: 35.525px;
		left: 97.61px;
		top: 12.771px;
		transform: matrix(1,0,0,1,0,0);
	}
	#front_hair_website_1_mob {
		fill: rgba(69,74,105,1);
	}
	.front_hair_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 44.063px;
		height: 58.441px;
		left: 92.347px;
		top: 4.428px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_colar_website_1_mob {
		fill: rgba(255,255,255,1);
	}
	.left_colar_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 15.7px;
		height: 14.457px;
		left: 115.248px;
		top: 102.665px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_hand_arm_website_1_mob {
		fill: rgba(223,132,126,1);
	}
	.left_hand_arm_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 38.685px;
		height: 47.484px;
		left: 126.387px;
		top: 68.631px;
		transform: matrix(1,0,0,1,0,0);
	}
	#right_hand_top_website_1_mob {
		fill: rgba(238,183,184,1);
	}
	.right_hand_top_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 19.347px;
		height: 34.819px;
		left: 80.389px;
		top: 33.964px;
		transform: matrix(1,0,0,1,0,0);
	}
	#right_arm_collar_website_1_mob {
		fill: rgba(255,255,255,1);
	}
	.right_arm_collar_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 13.651px;
		height: 9.917px;
		left: 80.315px;
		top: 63.693px;
		transform: matrix(1,0,0,1,0,0);
	}
	#right_arm_website_1_mob {
		fill: rgba(223,132,126,1);
	}
	.right_arm_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 19.976px;
		height: 47.554px;
		left: 74.944px;
		top: 69.901px;
		transform: matrix(1,0,0,1,0,0);
	}
	#down_hand_website_1_mob {
		fill: rgba(238,183,184,1);
	}
	.down_hand_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 39.676px;
		height: 14.98px;
		left: 81.446px;
		top: 105.529px;
		transform: matrix(1,0,0,1,0,0);
	}
	#steam_3_website_1_mob {
		fill: rgba(62,60,85,1);
	}
	.steam_3_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 2.238px;
		height: 3.137px;
		left: 27.381px;
		top: 108.619px;
		transform: matrix(1,0,0,1,0,0);
	}
	#stem_2_pot_website_1_mob {
		fill: rgba(62,60,85,1);
	}
	.stem_2_pot_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 2.238px;
		height: 3.137px;
		left: 27.381px;
		top: 106.7px;
		transform: matrix(1,0,0,1,0,0);
	}
	#stem_pot_1_website_1_mob {
		fill: rgba(62,60,85,1);
	}
	.stem_pot_1_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 2.238px;
		height: 3.137px;
		left: 27.381px;
		top: 104.783px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leave_design_website_1_m {
		fill: rgba(56,210,159,1);
	}
	.pot_1_leave_design_website_1_m {
		overflow: visible;
		position: absolute;
		width: 10.739px;
		height: 25.693px;
		left: 23.114px;
		top: 78.158px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_design_website_1_mob {
		fill: rgba(243,123,123,1);
		stroke: rgba(243,123,123,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.pot_1_design_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 21.514px;
		height: 12.82px;
		left: 18.369px;
		top: 109.655px;
		transform: matrix(1,0,0,1,0,0);
	}
	#laptop_website_1_mob {
		fill: rgba(62,60,85,1);
	}
	.laptop_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 51.912px;
		height: 37.189px;
		left: 149.624px;
		top: 85.796px;
		transform: matrix(1,0,0,1,0,0);
	}
	#join_now_grp_website_1_mob {
		position: absolute;
		width: 125px;
		height: 42px;
		left: 125px;
		top: 2750px;
		overflow: visible;
	}
	#join_now_bdr_website_1_mob {
		fill: rgba(143,233,191,1);
	}
	.join_now_bdr_website_1_mob {
		position: absolute;
		overflow: visible;
		width: 125px;
		height: 42px;
		left: 0px;
		top: 0px;
	}
	#Join_Now_website_1_mob {
		left: 28px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 79px;
		white-space: nowrap;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Connect__Follow_Us__website_1_mob {
		left: 93px;
		top: 2703px;
		position: absolute;
		overflow: visible;
		width: 191px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(54,54,54,1);
	}
	#top_menu_options_website_1_mob {
		position: absolute;
		width: 375px;
		height: 36px;
		left: 0px;
		top: 134px;
		overflow: visible;
	}
	#menu_bg_design {
		fill: rgba(143,233,191,1);
	}
	.menu_bg_design {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 36px;
		left: 0px;
		top: 0px;
	}
	#Intro_lbl_website_1_mob {
		left: 32px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#home-icon-silhouette_img_websi {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#Offerings_lbl_website_1_mob {
		left: 114px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 62px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#discount_img_website_1_mob {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 89px;
		top: 7px;
		overflow: visible;
	}
	#Events_lbl_website_1_mob {
		left: 225px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#events_img_website_1_mob {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 200px;
		top: 7px;
		overflow: visible;
	}
	#Contact_lbl_website_1_mob {
		left: 317px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 52px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#contact_img_website_1_mob {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 292px;
		top: 7px;
		overflow: visible;
	}
	#footer_overll_grp_web_1_mob {
		position: absolute;
		width: 381.235px;
		height: 529px;
		left: -3.118px;
		top: 2889px;
		overflow: visible;
	}
	#footer_bdr_web1_mob_dc {
		fill: url(#footer_bdr_web1_mob_dc);
	}
	.footer_bdr_web1_mob_dc {
		overflow: visible;
		position: absolute;
		width: 381.235px;
		height: 529px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#copy_line_website_1_mob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.copy_line_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 324px;
		height: 1px;
		left: 25.5px;
		top: 443.858px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_Copyright_Operate_Live_lbl_w {
		left: 26px;
		top: 469.358px;
		position: absolute;
		overflow: visible;
		width: 279px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#n_234567890_mobile_number_webs_mob {
		left: 70px;
		top: 89px;
		position: absolute;
		overflow: visible;
		width: 83px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#affanghanioperatelive_email_we_mob {
		left: 70px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 167px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#address_website_2_mob_ee {
		
		position: absolute;
		overflow: visible;
		width: 286px;
		height: 60px;
        left: 65px;
		top: 185px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Connect_with_us_and_unlock_lim_mob {
		left: 50px;
		top: 291px;
		position: absolute;
		overflow: visible;
		width: 271px;
		height: 40px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#location_img_website_2_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 25px;
		top: 185px;
		overflow: visible;
	}
	#email_address_website_2_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 25px;
		top: 134px;
		overflow: visible;
	}
	#phone_number_website_2_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 25px;
		top: 84px;
		overflow: visible;
	}
	#quote_left_webstie_2_mob {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 18px;
		top: 291px;
		overflow: visible;
	}
	#quote_right_website_2_mob {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 322px;
		top: 311px;
		overflow: visible;
	}
	#Ellipse_5_circle_mob:hover {
		fill: rgba(120,120,255,1);
	}
	.Ellipse_5_circle_mob:hover {
		width: 98px;
		height: 98px;
	}
	#Ellipse_5_circle_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_5_circle_mob {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 68px;
		height: 68px;
		left: 86px;
		top: 361.482px;
		transition: all .3s ease-out;
	}
	#linked_in_website_2_layout_mob {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 99.125px;
		top: 375.232px;
		overflow: visible;
	}
	#n_website_2_layout_mob {
		fill: rgba(63,61,86,1);
	}
	.n_website_2_layout_mob {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.954px;
		transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_down_mob {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_down_mob {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.098px;
		transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_website_2_layout_mob {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_website_2_layout_mob {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#insta_circle_mob:hover {
		fill: rgba(255,120,120,1);
	}
	.insta_circle_mob:hover {
		width: 98px;
		height: 98px;
	}
	#insta_circle_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.insta_circle_mob {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 68px;
		height: 68px;
		left: 166.019px;
		top: 361px;
		transition: all .3s ease-out;
	}
	#instagram_website_2_layout_mob {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 179.144px;
		top: 374.125px;
		overflow: visible;
	}
	#whatsapp_bdr_mob:hover {
		fill: rgba(120,255,165,1);
	}
	.whatsapp_bdr_mob:hover {
		width: 98px;
		height: 98px;
	}
	#whatsapp_bdr_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.whatsapp_bdr_mob {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 68px;
		height: 68px;
		left: 246.038px;
		top: 361.482px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_website_2_layout_ {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 258.538px;
		top: 373.982px;
		overflow: visible;
	}
	#Scroll_vision_miussion_and_pri {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 270px;
		
		top: 352px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#mission_grp_web_1_mob {
		position: absolute;
		width: 164px;
		height: 223px;
		left: 21px;
		top: 19px;
		overflow: visible;
	}
	#mission_card__mob {
		fill: rgba(143,214,233,1);
	}
	.mission_card__mob {
		position: absolute;
		overflow: visible;
		width: 164px;
		height: 223px;
		left: 0px;
		top: 0px;
	}
	#box_1_mob_website_1_op_live_th {
		position: absolute;
		width: 39px;
		height: 33px;
		left: 62px;
		top: 31px;
		overflow: visible;
	}
	#top_offerings_mob {
		left: 56px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 48px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#Scroll_missio_mobn_details_web {
		mix-blend-mode: normal;
		position: absolute;
		width: 164px;
		height: 138px;
		left: 0px;
		top: 82px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_missio_mobn_details_web::-webkit-scrollbar{
		display: none;
	}
	#Lorem_ipsum_dolor_sit_amet_em_mob {
		left: 7px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 150px;
		height: 238px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#vision_grp_web_1_mob {
		position: absolute;
		width: 163px;
		height: 223px;
		left: 204px;
		top: 19px;
		overflow: visible;
	}
	#vision_card_mob {
		fill: rgba(143,233,191,1);
	}
	.vision_card_mob {
		position: absolute;
		overflow: visible;
		width: 163px;
		height: 223px;
		left: 0px;
		top: 0px;
	}
	#box_2_website_1_o_mobp_live_th {
		position: absolute;
		width: 39px;
		height: 33px;
		left: 64px;
		top: 31px;
		overflow: visible;
	}
	#top_offerings_mob_ed {
		left: 61px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 39px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#Scroll_vision_details_mob_web_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 163px;
		height: 138px;
		left: 0px;
		top: 82px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_vision_details_mob_web_::-webkit-scrollbar{
		display: none;
	}
	#Lorem_ipsum_dolor_sit_amet_con_ef {
		left: 11px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 146px;
		height: 238px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#princi_grp_web_1_mob {
		position: absolute;
		width: 162px;
		height: 223px;
		left: 391px;
		top: 19px;
		overflow: visible;
	}
	#principle_mission_card_mob {
		fill: rgba(215,232,188,1);
	}
	.principle_mission_card_mob {
		position: absolute;
		overflow: visible;
		width: 162px;
		height: 223px;
		left: 0px;
		top: 0px;
	}
	#box_3_off_website_mob_1_op_liv {
		position: absolute;
		width: 40px;
		height: 33px;
		left: 61px;
		top: 31px;
		overflow: visible;
	}
	#top_offerings_mob_ej {
		left: 49px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 60px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#Scroll_prrinci_details_web_1_m {
		mix-blend-mode: normal;
		position: absolute;
		width: 162px;
		height: 138px;
		left: 0px;
		top: 82px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_prrinci_details_web_1_m::-webkit-scrollbar{
		display: none;
	}
	#Lorem_ipsum_dolor_sit_amet_con_el {
		left: 12px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 142px;
		height: 238px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
