	.mediaViewInfo {
		--web-view-name: BakersStop Mob;
		--web-view-id: BakersStop_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: BakersStop_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#BakersStop_Mob {
		position: absolute;
		width: 320px;
		height: 4207px;
        left:calc((100vw - 320px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: BakersStop Mob;
		--web-view-id: BakersStop_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#BCS_21_MOBRectangle_678 {
		opacity: 0.97;
		fill: rgba(50,50,50,1);
	}
	.BCS_21_MOBRectangle_678 {
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 764px;
		left: 0px;
		top: 3684px;
	}
	#BCS_21_MOBAlways_Find_The_Best_Deals {
		left: 19px;
		top: 68px;
		position: absolute;
		overflow: visible;
		width: 125px;
		white-space: nowrap;
		text-align: right;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBBEST_IN__CLASS_ {
		left: 11px;
		top: 74px;
		position: absolute;
		overflow: visible;
		width: 300px;
		white-space: nowrap;
		line-height: 83px;
		margin-top: -1px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 81px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBSell_btn {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 19px;
		top: 296px;
		overflow: visible;
	}
	#BCS_21_MOBRectangle_659 {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1.5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_MOBRectangle_659 {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_MOBGet_it_now {
		left: 33px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 67px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#BCS_21_MOBGroup_15061 {
		position: absolute;
		width: 320px;
		height: 289px;
		left: 0px;
		top: 393px;
		overflow: visible;
	}
	#BCS_21_MOBRectangle_655 {
		fill: rgba(246,246,246,1);
	}
	.BCS_21_MOBRectangle_655 {
		position: absolute;
		overflow: visible;
		width: 276px;
		height: 214px;
		left: 21px;
		top: 75px;
	}
	#BCS_21_MOBSocial {
		position: absolute;
		width: 95px;
		height: 4px;
		left: 64px;
		top: 201px;
		overflow: visible;
	}
	#BCS_21_MOBFacebook {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 16px;
		white-space: nowrap;
		text-align: right;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 3px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBInstagram {
		left: 28px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 17px;
		white-space: nowrap;
		text-align: right;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 3px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBTwitter {
		left: 57px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 3px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBSnapchat {
		left: 80px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 16px;
		white-space: nowrap;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 3px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBMask_Group_20 {
		position: absolute;
		width: 161px;
		height: 184px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_MOBMask_Group_21 {
		position: absolute;
		width: 143px;
		height: 142px;
		left: 177px;
		top: 83px;
		overflow: visible;
	}
	#BCS_21_MOBn_rd_Section {
		position: absolute;
		width: 288px;
		height: 733px;
		left: 14px;
		top: 797px;
		overflow: visible;
	}
	#BCS_21_MOBScroll_Group_72 {
		mix-blend-mode: normal;
		position: absolute;
		width: 288px;
		height: 279px;
		left: 0px;
		top: 454px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BCS_21_MOBBinQasem_provides_a_platform_o {
		left: 5px;
		top: 18px;
		position: absolute;
		overflow: visible;
		width: 284px;
		height: 473px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBVISION {
		left: 5px;
		top: 388px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#BCS_21_MOBMask_Group_23 {
		position: absolute;
		width: 283px;
		height: 324px;
		left: 5px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_MOBn_rd_Section_ {
		position: absolute;
		width: 288px;
		height: 733px;
		left: 16px;
		top: 1629px;
		overflow: visible;
	}
	#BCS_21_MOBScroll_Group_72_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 288px;
		height: 279px;
		left: 0px;
		top: 454px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BCS_21_MOBBinQasem_provides_a_platform_o_ {
		left: 5px;
		top: 18px;
		position: absolute;
		overflow: visible;
		width: 284px;
		height: 473px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBMISSION {
		left: 5px;
		top: 388px;
		position: absolute;
		overflow: visible;
		width: 129px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#BCS_21_MOBMask_Group_23_ {
		position: absolute;
		width: 283px;
		height: 324px;
		left: 5px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_MOBn_rd_Section_ba {
		position: absolute;
		width: 288px;
		height: 733px;
		left: 16px;
		top: 2461px;
		overflow: visible;
	}
	#BCS_21_MOBScroll_Group_72_ba {
		mix-blend-mode: normal;
		position: absolute;
		width: 288px;
		height: 279px;
		left: 0px;
		top: 454px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BCS_21_MOBBinQasem_provides_a_platform_o_ba {
		left: 5px;
		top: 18px;
		position: absolute;
		overflow: visible;
		width: 284px;
		height: 471px;
		line-height: 30px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBpRINCIPLES {
		left: 5px;
		top: 388px;
		position: absolute;
		overflow: visible;
		width: 167px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#BCS_21_MOBMask_Group_23_bc {
		position: absolute;
		width: 283px;
		height: 324px;
		left: 5px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_MOBour_services {
		left: 57px;
		top: 3311px;
		position: absolute;
		overflow: visible;
		width: 207px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#BCS_21_MOBCategory_Section {
		position: absolute;
		width: 860px;
		height: 273px;
		left: 8px;
		top: 3376px;
		overflow: visible;
	}
	#BCS_21_MOBRectangle_662 {
		fill: rgba(0,0,0,1);
	}
	.BCS_21_MOBRectangle_662 {
		position: absolute;
		overflow: visible;
		width: 852px;
		height: 3px;
		left: 8px;
		top: 0px;
	}
	#BCS_21_MOBScroll_Group_73 {
		mix-blend-mode: normal;
		position: absolute;
		width: 312px;
		height: 270px;
		left: 0px;
		top: 3px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#BCS_21_MOBn_ {
		position: absolute;
		width: 199px;
		height: 238px;
		left: 8px;
		top: 16px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow: visible;
	}
	#BCS_21_MOBMask_Group_22 {
		position: relative;
		width: 199px;
		height: 238px;
		left: 0px;
		top: 0px;
        margin-right: 30px;
		overflow: visible;
	}
	#BCS_21_MOBRectangle_664 {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBRectangle_664 {
		position: absolute;
		overflow: visible;
		width: 110px;
		height: 46px;
		left: 0px;
		top: 192px;
	}
	#BCS_21_MOBHyundai_Azera_2020 {
		left: 3px;
		top: 201px;
		position: absolute;
		overflow: visible;
		width: 101px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
		text-transform: uppercase;
	}
	#BCS_21_MOBGroup_10773 {
		position: absolute;
		width: 30px;
		height: 3px;
		left: 85px;
		top: 174px;
		overflow: visible;
	}
	#BCS_21_MOBEllipse_38 {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBEllipse_38 {
		position: absolute;
		overflow: visible;
		width: 3px;
		height: 3px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_MOBEllipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBEllipse_39 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 6px;
		top: 0px;
	}
	#BCS_21_MOBEllipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBEllipse_40 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 13px;
		top: 0px;
	}
	#BCS_21_MOBEllipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBEllipse_41 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 19px;
		top: 0px;
	}
	#BCS_21_MOBEllipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBEllipse_42 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 26px;
		top: 0px;
	}
	#BCS_21_MOBGroup_15063 {
		position: absolute;
		width: 291px;
		height: 187px;
		left: 13px;
		top: 3779px;
		overflow: visible;
	}
	#BCS_21_MOBGroup_15062 {
		position: absolute;
		width: 291px;
		height: 187px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_MOBn_2321397_luyk_98k2_201103 {
		position: absolute;
		width: 291px;
		height: 187px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_MOBRectangle_680 {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBRectangle_680 {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 120px;
		left: 11px;
		top: 57px;
	}
	#BCS_21_MOBRectangle_742 {
		fill: rgba(236,224,200,1);
	}
	.BCS_21_MOBRectangle_742 {
		position: absolute;
		overflow: visible;
		width: 89px;
		height: 26px;
		left: 100px;
		top: 5px;
	}
	#BCS_21_MOBBAKERY {
		left: 116px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 61px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe Script;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBGroup_15059 {
		position: absolute;
		width: 244.527px;
		height: 180px;
		left: 29.844px;
		top: 4003.943px;
		overflow: visible;
	}
	#BCS_21_MOBFor_Queries_infoandroomedanet {
		left: 26.527px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 219px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#BCS_21_MOBFor_call__920012888 {
		left: 26.527px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#BCS_21_MOBtelephone {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBtelephone {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 0px;
		top: 25.525px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_MOBsend_1 {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBsend_1 {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 0px;
		top: 4.719px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_MOBsocials_grp_med_web_4_mob {
		position: absolute;
		width: 210px;
		height: 50.001px;
		left: 0px;
		top: 129.999px;
		overflow: visible;
	}
	#BCS_21_MOBlinked_in_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 160px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_MOBEllipse_5_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBEllipse_5_med_web_4_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_MOBlinked_in_website_4_layout {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#BCS_21_MOBn_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.BCS_21_MOBn_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.953px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_MOBlinked_in_i_down {
		fill: rgba(63,61,86,1);
	}
	.BCS_21_MOBlinked_in_i_down {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.097px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_MOBtop_dot_i_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.BCS_21_MOBtop_dot_i_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_MOBInsta_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 80px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_MOBEllipse_4_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBEllipse_4_med_web_4_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_MOBinstagram_website_4_layout {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.126px;
		overflow: visible;
	}
	#BCS_21_MOBwhatsapp_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#BCS_21_MOBEllipse_3_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBEllipse_3_med_web_4_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_MOBwhatsapp_lbl_website4_layout {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#BCS_21_MOBIcon_metro-location {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBIcon_metro-location {
		overflow: visible;
		position: absolute;
		width: 11.381px;
		height: 18.209px;
		left: 0px;
		top: 46.33px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_MOBaddress {
		left: 26.527px;
		top: 45.539px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 62px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#BCS_21_MOBNAME {
		left: 19px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 70px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe Script;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBContact_us_ {
		left: 93px;
		top: 3714px;
		position: absolute;
		overflow: visible;
		width: 136px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -13.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}