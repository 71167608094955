.mediaViewInfo {
    --web-view-name: my com mob version 2.0;
    --web-view-id: my_com_mob_version_20;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: my_com_mob_version_20;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#my_com_mob_version_20 {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 140px);
    top:75px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: my com mob version 2.0;
    --web-view-id: my_com_mob_version_20;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#req_top_grp_overall {
    position: absolute;
    width: 100%;
    height: 67px;
    left: 11px;
    top: 0px;
    overflow: visible;
}
#community_scroll_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 70px);
    height: 67px;
    left: 31px;
    top: 0px;
    overflow-x: scroll;
    overflow-y: hidden;
}
#com_btn_grp_com_req_mob {
    position: absolute;
    width: 168px;
    height: 49px;
    left: 4px;
    top: 9px;
    overflow: visible;
    border-radius: 50px;
}
#bdr_com_mob_v {
    fill: url(#bdr_com_mob_v);
}
.bdr_com_mob_v {
    filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 186px;
    height: 67px;
    left: 0px;
    top: 0px;
}
#pfp_piccom_mob {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 4px;
    top: 4px;
    border-radius: 50%;
    overflow: hidden;
}
#cricketlover_lbl_com_req_mob {
    left: 49.594px;
    top: 15px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(37,37,37,1);
}
#down-arrow_for_top {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    top: 19px;
    overflow: visible;
}
#my_community_btn_com_req_mob {
    position: absolute;
    width: 29px;
    height: 40px;
    left: 0px;
    top: 16px;
    overflow: visible;
    border-radius: 50px;
}
#req_btn_rec_com_mob {
    fill: rgba(255,255,255,1);
}
.req_btn_rec_com_mob {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 47px;
    height: 58px;
    left: 0px;
    top: 0px;
}
#plus_img_btn_com_mob {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 4px;
    top: 10px;
    overflow: visible;
}


.com_top_com_req_glow {
    background: linear-gradient(145deg, rgb(48, 249, 17),#16cb34); 
    background-position: 400%;
    animation: animatecolor 8s linear ; 
    color: #fff;
}
  
  @keyframes animatecolor {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }

@keyframes backInLeft {
    0% {
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
    }
    
    80% {
    transform: translateX(0px) scale(0.7);
    opacity: 0.7;
    }
    
    100% {
    transform: scale(1);
    opacity: 1;
    }
    }
    
    .backInLeft {
    animation: backInLeft 1s;
    background-color: #b2fbb297;
    }
  