#REQM_small_4_trending_com_off {
    position: relative;
    width: 160px;
    height: 197px;
    left: 0px;
    top: 0px;
    margin: 5px;
    overflow: visible;
}
#REQM_all_bdr_small_4_trending_com {
    fill: rgba(245,245,245,1);
}
.REQM_all_bdr_small_4_trending_com {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 178px;
    height: 215px;
    left: 0px;
    top: 0px;
}
#REQM_Image_small_4_trending_com {
    position: absolute;
    width: 135px;
    height: 134px;
    left: 12px;
    top: 23px;
    overflow: visible;
}
#REQM_bdr_amount_small_4_trending_co {
    fill: rgba(255,255,255,1);
}
.REQM_bdr_amount_small_4_trending_co {
    position: absolute;
    overflow: visible;
    width: 39px;
    height: 21px;
    left: 116px;
    top: 160px;
}
#REQM_small_4_trending_com {
    left: 128px;
    top: 165px;
    position: absolute;
    overflow: visible;
    width: 17px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#REQM_NikeCrt_Air_small_4_trending_c {
    left: 2.986px;
    top: 158.311px;
    position: absolute;
    overflow: visible;
    width: 110px;
    height: 14px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#REQM_Sinr_small_4_trending_com_off {
    left: 3px;
    top: 172px;
    position: absolute;
    overflow: visible;
    width: 77px;
    white-space: nowrap;
    line-height: 14px;
    margin-top: -2px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#REQM_number_small_4_trending_com {
    fill: rgba(255,255,255,1);
}
.REQM_number_small_4_trending_com {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 13px;
    left: 26px;
    top: 6px;
}
#REQM_trohpie_bdr_small_4_tre {
    fill: rgba(255,255,255,1);
}
.REQM_trohpie_bdr_small_4_tre {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 1px;
    top: 1px;
}
#REQM_trophy_small_4_trending_c {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 3px;
    top: 3px;
    overflow: visible;
}
#REQM_small_4_trending_com_off_cz {
    left: 28px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 19px;
    white-space: nowrap;
    line-height: 16px;
    margin-top: -4px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    color: rgba(17,17,17,1);
}
#REQM_sharebrsmall_4_trending_com_of {
    fill: rgba(255,255,255,1);
}
.REQM_sharebrsmall_4_trending_com_of {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 135px;
    top: 1px;
}
#REQM_share_2_com_off {
    position: absolute;
    width: 15px;
    height: 14px;
    left: 140px;
    top: 6px;
    overflow: visible;
}