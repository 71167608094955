.mediaViewInfo {
    --web-view-name: pop up-2 Offering;
    --web-view-id: pop_up-2_Bargain_pc_bar;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pop_up-2_Bargain_pc_bar;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pop_up-2_Bargain_pc_bar {
    position: absolute;
    width: 345px;
    height: 418px;
    top: 50%;
    left: 50%;
    border-radius: 15px;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: pop up-2 Offering;
    --web-view-id: pop_up-2_Offering;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bargainBg_pc {
    position: absolute;
    width: 345px;
    height: 418px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#outerborder_pc_bar {
    fill: rgba(219,242,231,1);
}
.outerborder_pc_bar {
    width: 118px;
    height: 345px;
    position: absolute;
    overflow: visible;
    transform: matrix(1,0,0,1,113.5,-170.5) rotate(-90deg);
    transform-origin: center;
}
#Bargain_lbl_pc_ {
    left: 139px;
    top: 16px;
    position: absolute;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#Group_bargained_amount_pc {
    position: absolute;
    width: 296px;
    height: 92px;
    left: 25px;
    top: 88px;
    overflow: visible;
}
#bargained_amount_bar_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.bargained_amount_bar_pc {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 36px;
    left: 0px;
    top: 0px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#currency_barg_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.currency_barg_pc {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 36px;
    left: 158px;
    top: 0px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#estimated_time_bar_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.estimated_time_bar_pc {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 36px;
    left: 0px;
    top: 56px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#estimated_hours_pc_bar {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.estimated_hours_pc_bar {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 36px;
    left: 158px;
    top: 56px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#needToBedelivered_pc_barg {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.needToBedelivered_pc_barg {
    position: absolute;
    overflow: visible;
    width: 15px;
    height: 15px;
    left: 35px;
    top: 204px;
}
#Need_to_be_Delivered_for_Locat_pc {
    left: 56px;
    top: 200px;
    position: absolute;
    overflow: visible;
    width: 255px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#remarks_info_pc_bar {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.remarks_info_pc_bar {
    position: absolute;
    overflow: visible;
    width: 296px;
    height: 69px;
    left: 25px;
    top: 241px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#Group_update_pc_bar:hover {
    left: 58px;
    top: 347px;
}
#Group_update_pc_bar {
    position: absolute;
    width: 101px;
    height: 37px;
    left: 55px;
    top: 343px;
    overflow: visible;
    transition: all .3s ease-out;
}
#update_border_pc_bar {
    fill: rgba(219,242,231,1);
}
.update_border_pc_bar {
    position: absolute;
    overflow: visible;
    width: 101px;
    height: 37px;
    left: 0px;
    top: 0px;
}
#Update_lbl_pc_bar {
    left: 23px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 56px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_discard_pc_bar:hover {
    left: 192px;
    top: 347px;
    transition: all .3s ease-out;
}
#Group_discard_pc_bar {
    position: absolute;
    width: 101px;
    height: 37px;
    left: 189px;
    top: 343px;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_discard_pc_bar {
    fill: rgba(219,242,231,1);
}
.border_discard_pc_bar {
    position: absolute;
    overflow: visible;
    width: 101px;
    height: 37px;
    left: 0px;
    top: 0px;
}
#Discard_lbl_pc_ {
    left: 23px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#close_barg_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 317.142px;
    top: 6.5px;
    cursor: pointer;
    overflow: visible;
}