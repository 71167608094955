.mediaViewInfo {
    --web-view-name: community off – 1;
    --web-view-id: community_off__1;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: community_off__1;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#community_off__1 {
    position: absolute;
    width: calc(100% - 136px);
    height: calc(100% - 170px);
    top: 130px;
    left: 120px;
    overflow: hidden;
    --web-view-name: community off – 1;
    --web-view-id: community_off__1;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_design_grp_com_off_pc {
    position: absolute;
    width: calc(100% - 170px);
    height: 100%;
    left: 1.923px;
    top: 0px;
    overflow: visible;
}
#all_desgincom_off_pcZ {
    fill: rgba(255,255,255,1);
    stroke: rgba(163,214,147,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.all_desgincom_off_pcZ {
    overflow: visible;
    position: absolute;
    width: 3089.346px;
    height: 1908.021px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#com_off_3_tabs_lbl_com_off_pcZ {
    position: absolute;
    width: 417px;
    height: 22px;
    left: 46px;
    top: 17px;
    overflow: visible;
}
#Offerings_com_off_pcZ {
    left: 186px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#Requirement_com_off_pcZ {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#Members_com_off_pcZ {
    left: 342px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#Scroll_Group_off_ {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 170px);
    height: calc(100% - 100px);
    left: 21px;
    top: 70px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#card_offering_page_details_com {
    position: relative;
    width: 199px;
    height: 243.543px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#full_card_bdr_offering_com_off_ {
    fill: url(#full_card_bdr_offering_com_off_);
}
.full_card_bdr_offering_com_off_ {
    filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 205.5px;
    height: 249.5px;
    left: 0px;
    top: 0.543px;
}
#card_bg_design_com_off_pcZ_ {
    fill: url(#card_bg_design_com_off_pcZ_);
}
.card_bg_design_com_off_pcZ_ {
    overflow: visible;
    position: absolute;
    width: 199px;
    height: 47.484px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#lbl_website_off_rec_com_off_pc {
    fill: rgba(219,242,231,1);
}
.lbl_website_off_rec_com_off_pc {
    position: absolute;
    overflow: visible;
    width: 169.518px;
    height: 20.793px;
    left: 14.5px;
    top: 87.543px;
}
#Website_Development_com_off_pc {
    left: 16.5px;
    top: 87.543px;
    position: absolute;
    overflow: visible;
    width: 167px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(37,37,37,1);
}
#req_budget_img_com_off_pcZ {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 111px;
    top: 161px;
    overflow: visible;
}
#req_location_img_com_off_pcZ {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 14px;
    top: 161px;
    overflow: visible;
}
#dustbin {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 180px;
    top: 2px;
    overflow: visible;
}
#req_duration_img_com_off_pcZ {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 14px;
    top: 120.543px;
    overflow: visible;
}
#budget_lbl_card_com_off_pcZ {
    left: 153px;
    top: 166px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#delivery_lbl_card_com_off_pcZ {
    left: 56px;
    top: 166px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Online_Deployment_lbl_card_com {
    left: 56px;
    top: 125.3px;
    position: absolute;
    overflow: visible;
    width: 130px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#bid_btn_card_com_off_pcZ {
    position: absolute;
    width: 87px;
    height: 27px;
    left: 13px;
    top: 208px;
    cursor: pointer;
    overflow: visible;
    border-radius: 50px;
}
#bdr_btn_com_off_pcZ {
    fill: rgba(255,255,255,1);
}
.bdr_btn_com_off_pcZ {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 105px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#Bid_lbl_btn_com_off_pcZ {
    left: 25px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#bid_img_card_com_off_pcZ {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 4px;
    top: 4px;
    overflow: visible;
}
#details_btn_card_com_off_pcZ {
    position: absolute;
    width: 72px;
    height: 27px;
    left: 106px;
    top: 208px;
    cursor: pointer;
    overflow: visible;
    border-radius: 50px;
}
#details_btn_rec_com_off_pcZ {
    fill: rgba(255,255,255,1);
}
.details_btn_rec_com_off_pcZ {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#Details_lbl_btn_com_off_pcZ {
    left: 24px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 46px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#detail_img_btn_com_off_pcZ {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 3px;
    top: 4px;
    overflow: visible;
}


@keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInRight {
    animation: fadeInRight 1s;
  }