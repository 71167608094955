.mediaViewInfo {
    --web-view-name: sign up mob;
    --web-view-id: sign_up_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: sign_up_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#sign_up_mob {
    position: absolute;
    width: 430px;
    height: 932px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: sign up mob;
    --web-view-id: sign_up_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#over_all_bg_design {
    position: absolute;
    width: 1518px;
    height: 1033px;
    left: -66px;
    top: -6px;
    overflow: visible;
}
#design_circle_op_sign_up_mon {
    opacity: 0.75;
    fill: rgba(33,177,73,1);
}
.design_circle_op_sign_up_mon {
    position: absolute;
    overflow: visible;
    width: 472px;
    height: 207.259px;
    left: -20px;
    top: -87px;
}
#op_bg_design_op_sign_up_mon {
    fill: rgba(255,255,255,1);
}
.op_bg_design_op_sign_up_mon {
    position: absolute;
    overflow: visible;
    width: 349px;
    height: 316px;
    left: 37px;
    top: -87px;
    border-radius:  51px;
    background:  linear-gradient(315deg, #f0f0f0, #cacaca);
    box-shadow:   -23px -23px 43px #929292,
23px 23px 43px #ffffff;
}
#Rectangle_2_op_sign_up_mon {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rectangle_2_op_sign_up_mon {
    position: absolute;
    overflow: visible;
    width: 349px;
    height: 643px;
    left: 41px;
    top: 260px;
}
#image_op_sign_up_mon {
    position: absolute;
    width: 135px;
    height: 135px;
    left: 143px;
    top: 0px;
    overflow: visible;
}
#OPERATE_LIVE_op_sign_up_mon {
    left: 51px;
    top: 145px;
    position: absolute;
    overflow: visible;
    width: 319px;
    white-space: nowrap;
    line-height: 132px;
    margin-top: -46px;
    text-align: left;
    font-family: Cooper;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgba(33,177,73,1);
}
#txtFirst_Name_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtFirst_Name_mob {
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 48px;
    left: 77px;
    top: 385px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtEmail_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtEmail_mob {
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 48px;
    left: 77px;
    top: 540px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtMobile_No_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtMobile_No_mob {
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 48px;
    left: 77px;
    top: 617px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtEnter_Password_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtEnter_Password_mob {
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 48px;
    left: 77px;
    top: 695px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtConfirm_Password_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtConfirm_Password_mob {
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 48px;
    left: 77px;
    top: 772px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#profile_op_sign_up_mon {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 170px;
    top: 273px;
    border-radius: 50%;
    overflow: hidden;
}
#back_btn_sign_up_mob {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 56px;
    top: 273px;
    overflow: visible;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: index_page.html;
    cursor: pointer;
}
#sign_up_bdt {
    fill: rgba(33,177,73,1);
}
.sign_up_bdt {
    filter: drop-shadow(3.857px 4.596px 13px rgba(0, 0, 0, 0.141));
    position: absolute;
    overflow: visible;
    width: 135.879px;
    height: 40.968px;
    left: 148px;
    top: 842px;

}
#Sign_Up_btn_lbl {
    left: 184px;
    top: 851px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -2px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#txtNickName_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtNickName_mob {
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 48px;
    left: 76px;
    top: 462px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
}

.nickName_lbl_mob {
    position: absolute;
    overflow: visible;
    width: 100%;
    
    height: 20px;
    left: 48px;
    top: 510px;
    font-size: 15px;
    /* padding: 15px; */
    /* border: 2px solid darkcyan; */
}
