#card_grp_event_page_pc {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 400px);
    left: 38px;
    top: 300px;
    /* overflow-x: hidden;
    overflow-y: scroll; */
    overflow: visible;
}
#recent_events_all_grp_pc {
    position: absolute;
    width: calc(100% - 100px);
    height: 556px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#recent_events_new_events_page__ {
    fill: url(#recent_events_new_events_page__);
}
.recent_events_new_events_page__ {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 556px;
    left: 0px;
    top: 0px;
}
#recent_events_line_new_events_pc {
    fill: transparent;
    stroke: rgba(64,225,150,1);
    stroke-width: 5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.recent_events_line_new_events_pc {
    overflow: visible;
    position: absolute;
    width: 5px;
    height: 26px;
    left: 60.24px;
    top: 29px;
    transform: matrix(1,0,0,1,0,0);
}
#recent_events_new_events_page__ba {
    opacity: 0.92;
    left: 74.24px;
    top: 31.5px;
    position: absolute;
    overflow: visible;
    width: 111px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(4,4,4,1);
}
#Scroll_recent_events {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 500px;
    /* left: 26px; */
    top: 56px;
    display: -webkit-inline-box;
    overflow-x: scroll;
    overflow-y: hidden;
}
#recent_event_card_1_event_new_ {
    position: relative;
    width: 326.76px;
    height: 445px;
    /* left: 16px; */
    top: 13px;
    margin: 10px;
    /* display: inline-flex; */
    overflow: visible;
}
#all_card_borer_v {
    fill: rgba(255,255,255,1);
}
.all_card_borer_v {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 344.76px;
    height: 463px;
    left: 0px;
    top: 0px;
}
#By_by_vent_new_pc {
    left: 20px;
    top: 203.39px;
    position: absolute;
    overflow: visible;
    width: 15px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(208,208,208,1);
}
#Son_Goku_lbl_my_anme {
    left: 72px;
    top: 203.39px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#Comic_Con_Bangalore_-_2023_ven {
    left: 20px;
    top: 228.222px;
    position: absolute;
    overflow: visible;
    width: 241px;
    white-space: nowrap;
    line-height: 24px;
    margin-top: -3px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0,0,0,1);
}
#img_logo_vent_new_pc {
    position: absolute;
    width: 30.36px;
    height: 30.614px;
    left: 34.64px;
    top: 190.39px;
    border-radius: 50%;
    overflow: hidden;
}
#idk_vent_new_pc {
    position: absolute;
    width: 285.377px;
    height: 159px;
    left: 21.623px;
    top: 19.222px;
    overflow: visible;
}
#img_bdr_vent_new_pc {
    opacity: 0.29;
    fill: rgba(0,0,0,1);
}
.img_bdr_vent_new_pc {
    position: absolute;
    overflow: visible;
    width: 287px;
    height: 159px;
    left: 20px;
    top: 19.222px;
}
#seats_grp_event_pc {
    position: absolute;
    width: 257px;
    height: 32.832px;
    left: 35.38px;
    top: 396.39px;
    overflow: visible;
}
#Seats_lbl_vent_new_pc {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#n_0_lbl_vent_new_pc {
    left: 231px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(185,185,185,1);
}
#seats_all_bar_vent_new_pc {
    fill: rgba(243,243,243,1);
}
.seats_all_bar_vent_new_pc {
    position: absolute;
    overflow: visible;
    width: 256px;
    height: 4px;
    left: 0px;
    top: 28.832px;
}
#seats_bar_1_vent_new_pc {
    fill: rgba(245,175,25,1);
}
.seats_bar_1_vent_new_pc {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 4px;
    left: 0px;
    top: 28.832px;
}
#bdr_paid_vent_new_pc {
    fill: rgba(55,182,171,0.8);
}
.bdr_paid_vent_new_pc {
    position: absolute;
    overflow: visible;
    width: 55px;
    height: 31px;
    left: 31.76px;
    top: 135px;
}
#Paid_lbl_vent_new_pc {
    left: 45.76px;
    top: 141px;
    position: absolute;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -4.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255,255,255,1);
}
#amount_bdr_vent_new_pc {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.amount_bdr_vent_new_pc {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 224px;
    top: 28px;
}
#n_1139_vent_new_pc {
    left: 236.5px;
    top: 34px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -4.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255,255,255,1);
}
#bdr_trophie_v {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.bdr_trophie_v {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 25.5px;
    top: 28px;
}
#signal_event_new_pc {
    fill: rgba(179,212,49,1);
}
.signal_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 6.193px;
    height: 6.193px;
    left: 216px;
    top: 152px;
}
#Online_Event_lbl_event_new_pc {
    left: 228px;
    top: 148px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    line-height: 19px;
    margin-top: -4px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(255,255,255,1);
}
#trophy_img_event_new_pc {
    position: absolute;
    width: auto;
    height: 25px;
    left: 29px;
    top: 31px;
    color: white;
    cursor: pointer;
    overflow: visible;
}
#n_234_com_event_new_pc {
    left: 62px;
    top: 35px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#share_bdrevent_new_pc_reent_ev {
    fill: rgba(255,255,255,1);
}
.share_bdrevent_new_pc_reent_ev {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 269px;
    top: 185px;
}
#share_event_new_pc {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 276px;
    top: 192px;
    overflow: visible;
}
#from_to_grp_pc_event {
    position: absolute;
    width: 265.5px;
    height: 55px;
    left: 20px;
    top: 273.029px;
    overflow: visible;
}
#From_pc_event {
    left: 34.5px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#n_3th_Jan_2023_vent_pc_event {
    left: 34.5px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(51,51,51,1);
}
#cal_64_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_64_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 16.5px;
}
#cal_8h_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_8h_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 19.5px;
}
#cal_87_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_87_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 22.5px;
}
#cal_57_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_57_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 16.5px;
}
#cal_r54_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_r54_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 19.5px;
}
#cal_ourter_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_ourter_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 22.5px;
}
#cal_6_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_6_pc_event {
    overflow: visible;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0px;
    top: 10.5px;
    transform: matrix(1,0,0,1,0,0);
}
#cal_6_pc_event_b {
    fill: rgba(173,173,173,1);
}
.cal_6_pc_event_b {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 16.5px;
}
#cal_5_e_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_5_e_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 19.5px;
}
#cal_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 22.5px;
}
#cal_3_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_3_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 16.5px;
}
#cal_2_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_2_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 19.5px;
}
#cal_1_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_1_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 22.5px;
}
#To_lbl_pc_event {
    left: 173.5px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 12px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#date_pc_event {
    left: 173.5px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(51,51,51,1);
}
#location_grp_pc_event {
    position: absolute;
    width: 151.877px;
    height: 38px;
    left: 21.623px;
    top: 338px;
    overflow: visible;
}
#Location_lbl_pc_event_pc {
    left: 32.877px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#Online_Meet_-_up_lbl_pc_event_ {
    left: 32.877px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(51,51,51,1);
}
#outer_loca_pc_event_pc {
    fill: rgba(173,173,173,1);
}
.outer_loca_pc_event_pc {
    overflow: visible;
    position: absolute;
    width: 13.754px;
    height: 17px;
    left: 0px;
    top: 10.5px;
    transform: matrix(1,0,0,1,0,0);
}
#loaction_img_pc_event_pc {
    fill: rgba(173,173,173,1);
}
.loaction_img_pc_event_pc {
    overflow: visible;
    position: absolute;
    width: 6.658px;
    height: 6.657px;
    left: 3.548px;
    top: 14.026px;
    transform: matrix(1,0,0,1,0,0);
}
#red_trendding_grp_ {
    position: absolute;
    width: calc(100% - 100px);
    height: 559px;
    left: 3px;
    top: 606px;
    overflow: visible;
}
#trending_events_bdr_new_events_cj {
    fill: url(#trending_events_bdr_new_events_cj);
}
.trending_events_bdr_new_events_cj {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 559px;
    left: 0px;
    top: 0px;
}
#trwnd_line_new_events_page_pc {
    fill: transparent;
    stroke: rgba(255,38,38,1);
    stroke-width: 5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.trwnd_line_new_events_page_pc {
    overflow: visible;
    position: absolute;
    width: 5px;
    height: 26px;
    left: 57.24px;
    top: 29.5px;
    transform: matrix(1,0,0,1,0,0);
}
#trending_new_events_page_pc {
    opacity: 0.92;
    left: 71.24px;
    top: 32px;
    position: absolute;
    overflow: visible;
    width: 126px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(4,4,4,1);
}
#Scroll_trending_events {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 503px;
    /* left: 23px; */
    top: 56px;
    display: -webkit-inline-box;
    overflow-x: scroll;
    overflow-y: hidden;
}
#trending_card_grp_event_new_pc {
    position: relative;
    width: 326.76px;
    height: 448px;
    left: 16px;
    margin: 10px;
    top: 7px;
    overflow: visible;
}
#card_1_bdr_event_new_pc {
    fill: rgba(255,255,255,1);
}
.card_1_bdr_event_new_pc {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 344.76px;
    height: 466px;
    left: 0px;
    top: 0px;
}
#By_event_new_pc {
    left: 20px;
    top: 203.39px;
    position: absolute;
    overflow: visible;
    width: 15px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(208,208,208,1);
}
#Son_Goku_event_new_pc {
    left: 72px;
    top: 203.39px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#Dragon_Ball_Super_Movie_-_Rise {
    left: 20px;
    top: 228.222px;
    position: absolute;
    overflow: visible;
    width: 269px;
    white-space: nowrap;
    line-height: 24px;
    margin-top: -3px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0,0,0,1);
}
#logo_event_new_pc {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 34.64px;
    top: 192.39px;
    border-radius: 50%;
    overflow: hidden;
}
#Anime_Edit_img_event_new_pc {
    position: absolute;
    width: 287px;
    height: 159px;
    left: 20px;
    top: 19.222px;
    overflow: visible;
}
#bdr_img_1_event_new_pc {
    opacity: 0.29;
    fill: rgba(0,0,0,1);
}
.bdr_img_1_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 287px;
    height: 159px;
    left: 20px;
    top: 19.222px;
}
#seats_events_page_upcoming_eve {
    position: absolute;
    width: 257px;
    height: 32.832px;
    left: 35.38px;
    top: 396.39px;
    overflow: visible;
}
#Seats_event_new_pc {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#n_0_event_new_pc {
    left: 231px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(185,185,185,1);
}
#bdr_prog_event_new_pc {
    fill: rgba(243,243,243,1);
}
.bdr_prog_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 256px;
    height: 4px;
    left: 0px;
    top: 28.832px;
}
#prog_bar_event_new_pc {
    fill: rgba(245,175,25,1);
}
.prog_bar_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 4px;
    left: 0px;
    top: 28.832px;
}
#bdr_event_new_pc {
    fill: rgba(55,182,171,0.8);
}
.bdr_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 55px;
    height: 31px;
    left: 31.76px;
    top: 135px;
}
#Paid_lbl_event_new_pc {
    left: 45.76px;
    top: 141px;
    position: absolute;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -4.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255,255,255,1);
}
#amount_bdr_event_new_pc {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.amount_bdr_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 224px;
    top: 28px;
}
#n_1139_lbl_event_new_pc {
    left: 237px;
    top: 34px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -4.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255,255,255,1);
}
#sign_event_new_pc {
    fill: rgba(179,212,49,1);
}
.sign_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 6.193px;
    height: 6.193px;
    left: 216px;
    top: 152px;
}
#Online_Event_lbl_event_new_pc_c {
    left: 228px;
    top: 148px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    line-height: 19px;
    margin-top: -4px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(255,255,255,1);
}
#share_bdr_small_event_new_pc {
    fill: rgba(255,255,255,1);
}
.share_bdr_small_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 269px;
    top: 186px;
}
#share_img_event_new_pc {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 276px;
    top: 193px;
    overflow: visible;
}
#bdr_trophie_v_eevents {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.bdr_trophie_v_eevents {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 27px;
    top: 28px;
}
#trophy_img_event_new_pc_23 {
    position: absolute;
    width: auto;
    height: 25px;
    left: 30.5px;
    top: 31px;
    color: white;
    overflow: visible;
}
#n_234_com_event_new_pc_32 {
    left: 63.5px;
    top: 35px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#details_evnts_pc_event {
    position: absolute;
    width: 265.5px;
    height: 97px;
    left: 20px;
    top: 291px;
    overflow: visible;
}
#From_lbl_pc_event {
    left: 34.5px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#n_3th_Jan_2023_pc_event {
    left: 34.5px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(51,51,51,1);
}
#To_lbl_pc_event_de {
    left: 173.5px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 12px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#n_4th_Jan_2023_lbl_pc_event {
    left: 173.5px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(51,51,51,1);
}
#Ellipse_223_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_223_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 16.5px;
}
#Ellipse_236_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_236_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 19.5px;
}
#Ellipse_237_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_237_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 22.5px;
}
#Ellipse_224_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_224_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 16.5px;
}
#Ellipse_234_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_234_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 19.5px;
}
#Ellipse_235_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_235_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 22.5px;
}
#Path_3483_pc_event {
    fill: rgba(173,173,173,1);
}
.Path_3483_pc_event {
    overflow: visible;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0px;
    top: 10.5px;
    transform: matrix(1,0,0,1,0,0);
}
#Ellipse_226_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_226_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 16.5px;
}
#Ellipse_230_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_230_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 19.5px;
}
#Ellipse_231_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_231_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 22.5px;
}
#Ellipse_229_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_229_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 16.5px;
}
#Ellipse_232_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_232_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 19.5px;
}
#Ellipse_233_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_233_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 22.5px;
}
#Location_lbl_pc_event {
    left: 34.5px;
    top: 59px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#Online_Meet_-_up_pc_event {
    left: 34.5px;
    top: 77px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(51,51,51,1);
}
#location_2_pc_event {
    fill: rgba(173,173,173,1);
}
.location_2_pc_event {
    overflow: visible;
    position: absolute;
    width: 13.754px;
    height: 17px;
    left: 1.623px;
    top: 70px;
    transform: matrix(1,0,0,1,0,0);
}
#location_1_pc_event {
    fill: rgba(173,173,173,1);
}
.location_1_pc_event {
    overflow: visible;
    position: absolute;
    width: 6.658px;
    height: 6.657px;
    left: 5.171px;
    top: 73.027px;
    transform: matrix(1,0,0,1,0,0);
}
#completed_events_grp_all_pc {
    position: absolute;
    width: calc(100% - 100px);
    height: 561px;
    left: 3px;
    top: 1215px;
    overflow: visible;
}
#bg_of_completed_dz {
    fill: url(#bg_of_completed_dz);
}
.bg_of_completed_dz {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 561px;
    left: 0px;
    top: 0px;
}
#comple_line_new_events_page_pc {
    fill: transparent;
    stroke: rgba(38,161,255,1);
    stroke-width: 5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.comple_line_new_events_page_pc {
    overflow: visible;
    position: absolute;
    width: 5px;
    height: 26px;
    left: 57.24px;
    top: 29.5px;
    transform: matrix(1,0,0,1,0,0);
}
#events_completed_new_events_pa {
    opacity: 0.92;
    left: 71.24px;
    top: 32px;
    position: absolute;
    overflow: visible;
    width: 145px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(4,4,4,1);
}
#Scroll_completed_events {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 497px;
    /* left: 23.24px; */
    top: 64px;
    display: -webkit-inline-box;
    overflow-x: scroll;
    overflow-y: hidden;
}
#completed_vent_card_new_events {
    position: relative;
    width: 326.76px;
    height: 448px;
    left: 16px;
    margin: 10px;
    top: 0px;
    overflow: visible;
}
#overal_bg_event_new_pc {
    fill: rgba(255,255,255,1);
}
.overal_bg_event_new_pc {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 344.76px;
    height: 466px;
    left: 0px;
    top: 0px;
}
#By_event_new_pc_d {
    left: 20px;
    top: 203.39px;
    position: absolute;
    overflow: visible;
    width: 15px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(208,208,208,1);
}
#Son_Goku_event_new_pc_d {
    left: 72px;
    top: 203.39px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#Suzume_event_new_pc {
    left: 20px;
    top: 228.222px;
    position: absolute;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    line-height: 24px;
    margin-top: -3px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0,0,0,1);
}
#logo_event_new_pc_d {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 36px;
    top: 195px;
    border-radius:50%;
    overflow: hidden;
}
#img_event_new_pc {
    position: absolute;
    width: 287px;
    height: 159px;
    left: 20px;
    top: 19.222px;
    overflow: visible;
}
#shade_img_event_new_pc {
    opacity: 0.29;
    fill: rgba(0,0,0,1);
}
.shade_img_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 287px;
    height: 159px;
    left: 20px;
    top: 19.222px;
}
#seats_rpohg_bar {
    position: absolute;
    width: 257px;
    height: 32.832px;
    left: 35.38px;
    top: 396.39px;
    overflow: visible;
}
#Seats_lbl_event_new_pc {
    left: 0px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 35px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#n_0_event_new_pc_ed {
    left: 231px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(185,185,185,1);
}
#overall_b_ar_event_new_pc {
    fill: rgba(243,243,243,1);
}
.overall_b_ar_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 256px;
    height: 4px;
    left: 0px;
    top: 28.832px;
}
#bar_event_new_pc {
    fill: rgba(245,175,25,1);
}
.bar_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 4px;
    left: 0px;
    top: 28.832px;
}
#paid_bdr_event_new_pc {
    fill: rgba(55,182,171,0.8);
}
.paid_bdr_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 55px;
    height: 31px;
    left: 31.76px;
    top: 135px;
}
#Paid_lbl_event_new_pc_eh {
    left: 45.76px;
    top: 141px;
    position: absolute;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -4.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255,255,255,1);
}
#amount_bdr_event_new_pc_ei {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.amount_bdr_event_new_pc_ei {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 223.76px;
    top: 28px;
}
#n_1139_cost_event_new_pc {
    left: 236.76px;
    top: 34px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -4.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255,255,255,1);
}
#green_dot_new_events_page_pc {
    fill: rgba(179,212,49,1);
}
.green_dot_new_events_page_pc {
    position: absolute;
    overflow: visible;
    width: 6.193px;
    height: 6.193px;
    left: 216px;
    top: 152px;
}
#Online_Event_new_events_page_p {
    left: 228px;
    top: 148px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    line-height: 19px;
    margin-top: -4px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(255,255,255,1);
}
#share_bdr_new_events_page_pc {
    fill: rgba(255,255,255,1);
}
.share_bdr_new_events_page_pc {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 269.76px;
    top: 181px;
}
#sharenew_events_page_pc {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 276.76px;
    top: 188px;
    overflow: visible;
}
#bdr_trophie_hi {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.bdr_trophie_hi {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 27.76px;
    top: 28px;
}
#trophy_img_event_new_pc_312 {
    position: absolute;
    width: auto;
    height: 25px;
    left: 31.26px;
    top: 31px;
    color: white;
    overflow: visible;
}
#n_234_com_event_new_pc_pcpc {
    left: 64.26px;
    top: 35px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#de_copketed_com_pc_event {
    position: absolute;
    width: 265.5px;
    height: 98px;
    left: 20px;
    top: 288px;
    overflow: visible;
}
#From_lbl_pc_event_es {
    left: 34.5px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#n_3th_Jan_2023__pc_event {
    left: 34.5px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space:pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(51,51,51,1);
}
#To_lbl_pc_event_eu {
    left: 173.5px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 12px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#n_4th_Jan_2023_pc_event {
    left: 173.5px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(51,51,51,1);
}
#calender_dot_3_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dot_3_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 16.5px;
}
#calender_dot_2_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dot_2_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 19.5px;
}
#calender_dot_1_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dot_1_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 22.5px;
}
#calender_dor_9_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_9_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 16.5px;
}
#calender_dor_8_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_8_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 19.5px;
}
#calender_dor_7_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_7_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 22.5px;
}
#calender_outer_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_outer_com_pc_event {
    overflow: visible;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0px;
    top: 10.5px;
    transform: matrix(1,0,0,1,0,0);
}
#calender_dor_6_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_6_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 16.5px;
}
#calender_dor_5_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_5_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 19.5px;
}
#calender_dor_4_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_4_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 22.5px;
}
#calender_dor_3_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_3_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 16.5px;
}
#calender_dor_2_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_2_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 19.5px;
}
#calender_dor_1_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_1_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 22.5px;
}
#Location_lbl_new_pc_event {
    left: 34.5px;
    top: 60px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#Online_Meet_-_up_new_pc_event {
    left: 34.5px;
    top: 78px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(51,51,51,1);
}
#loca_2_pc_event {
    fill: rgba(173,173,173,1);
}
.loca_2_pc_event {
    overflow: visible;
    position: absolute;
    width: 13.754px;
    height: 17px;
    left: 1.623px;
    top: 70.5px;
    transform: matrix(1,0,0,1,0,0);
}
#loca_1_new_pc_event {
    fill: rgba(173,173,173,1);
}
.loca_1_new_pc_event {
    overflow: visible;
    position: absolute;
    width: 6.658px;
    height: 6.657px;
    left: 5.171px;
    top: 74.027px;
    transform: matrix(1,0,0,1,0,0);
}
#recent_event_card_1_event_new_ { 
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
 #Scroll_recent_events::-webkit-scrollbar{
	display: none;
 }
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 20px); }
    100%   { transform: translate(0, -0px); }   
}

	#trending_card_grp_event_new_pc { 
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
#Scroll_trending_events::-webkit-scrollbar{
	display: none;
}
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 20px); }
    100%   { transform: translate(0, -0px); }   
}

#completed_vent_card_new_events { 
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
#Scroll_completed_events::-webkit-scrollbar{
	display: none;
}
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 20px); }
    100%   { transform: translate(0, -0px); }   
}
#add_events_PC {
    position: absolute;
    width: 117px;
    height: 30px;
    right: 30px;
    top: 81px;
    cursor: pointer;
    overflow: visible;
}
#Add_events_PC_new_events_page_ {
    left: 39px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 79px;
    white-space: nowrap;
    line-height: 27px;
    margin-top: -5.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(17,17,17,1);
}
#add_events_PC_y {
    position: absolute;
    width: 29px;
    height: 30px;
    left: 0px;
    top: 0px;
    
    overflow: visible;
}