
	.mediaViewInfo {
		--web-view-name: theme small  mob;
		--web-view-id: theme_small__mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: theme_small__mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#theme_small__mob {
		position: absolute;
		width: 392px;
		height: 812px;
        border-radius: 15px;
        top:60%;
        left:50%;
        transform: translate(-50%, -50%);
        background-color: rgb(247 255 251);    
		overflow: hidden;
		--web-view-name: theme small  mob;
		--web-view-id: theme_small__mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#select_btn_grp_mob_theme_page {
		position: absolute;
		width: 149px;
		height: 54px;
		left: 120px;
		top: 734px;
		overflow: visible;
	}
	#select_btn_live_theme_mob {
		fill: rgba(143,233,191,1);
	}
	.select_btn_live_theme_mob {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 167px;
		height: 72px;
		left: 0px;
		top: 0px;
	}
	#select_website_1_op_live_theme {
		left: 47px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 56px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#close_small_theme_selector {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 359px;
		top: 14px;
		overflow: visible;
	}
	#Scroll_theme_edit_grp_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 392px;
		height: 676px;
		left: 0px;
		top: 45px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#emerald_grp_mob {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 29px;
		top: 12px;
		overflow: visible;
	}
	#layout_1_small_theme_mob_emera {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_1_small_theme_emeral {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_1_small_theme_emeral {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 167px;
	}
	#Enchanting_Emerald_small_theme_mob {
		left: 71px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_1_mob_small_theme_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#flamingo_fiesto_grp_mob {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 29px;
		top: 241px;
		overflow: visible;
	}
	#website_2_layout_small_theme_m {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_2_small_theme_fiesta {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_2_small_theme_fiesta {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 60px;
		top: 167px;
	}
	#Flamingo_Fiesta_lbl_small_them_mob {
		left: 85px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_2_mob_small_theme_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#ocean_bliss_grp_ {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 29px;
		top: 469px;
		overflow: visible;
	}
	#mob_bliss_layout_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_3_small_theme_mob_bl {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_3_small_theme_mob_bl {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 69px;
		top: 167px;
	}
	#ocean_blue_lbl_small_theme_mob {
		left: 94px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_3_pc_small_theme_mob_bliss {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#grp_new_edit_mob {
		position: absolute;
		width: 330px;
		height: 412px;
		left: 25px;
		top: 698px;
		overflow: visible;
	}
	#layout_4_small_theme_edit_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_4_small_theme_edit_m {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_4_small_theme_edit_m {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 167px;
	}
	#sahara_small_theme_edit_mob {
		left: 71px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_4_mob_small_theme_edit_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#layout_5_small_theme_edit_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 228px;
		overflow: visible;
	}
	#radio_btn_5_small_theme_edit_m {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_5_small_theme_edit_m {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 395px;
	}
	#shadow_space_small_theme_edit_mob {
		left: 74px;
		top: 392px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_5_mob_small_theme_edit_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 228px;
		overflow: visible;
	}

	#fash_21grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 4576px;
		overflow: visible;
	}
	#fash_22grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 4805px;
		overflow: visible;
	}
	#fash_23grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 5034px;
		overflow: visible;
	}
	#fash_24grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 5263px;
		overflow: visible;
	}
	#fash_25grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 5492px;
		overflow: visible;
	}
	#fash_26grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 5721px;
		overflow: visible;
	}
	#fash_27grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 5950px;
		overflow: visible;
	}
	#fash_28grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 6179px;
		overflow: visible;
	}
	#fash_29grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 6408px;
		overflow: visible;
	}
	#fash_30grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 6637px;
		overflow: visible;
	}
	#fash_31grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 6866px;
		overflow: visible;
	}
	#fash_32grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 7095px;
		overflow: visible;
	}
	#fash_33grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 7324px;
		overflow: visible;
	}
	#fash_34grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 7553px;
		overflow: visible;
	}
	#fash_35grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 7782px;
		overflow: visible;
	}
	#fash_36grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 8011px;
		overflow: visible;
	}
	#fash_37grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 8240px;
		overflow: visible;
	}
	#layout_21_small_theme_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_21_mob_small_theme_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_21small_theme_mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_21small_theme_mob {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#fashion_21_finds_small_theme_m {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#fash_20grp_theme_mob {
	
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 4347px;
		overflow: visible;
	}
	#layout_20small_theme_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_20mob_small_theme_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_20small_theme_mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_20small_theme_mob {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#fashion_20_finds_small_theme_m {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#med_19grp_dummy_theme_mob {
	
		position: absolute;
		width: 330px;
		height: 175px;
		left: 31px;
		top: 4127px;
		overflow: visible;
	}
	#layout_19small_theme_dummy_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_19small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_19small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 158px;
	}
	#sahara_19_small_theme_dummy_mo {
		left: 71px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_19mob_small_theme_dummy_mo {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#shadow_18_grp_dummy_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 175px;
		left: 31px;
		top: 3907px;
		overflow: visible;
	}
	#layout_18small_theme_dummy_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_18small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_18small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#shadow_18space_small_theme_dum {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_18mob_small_theme_dummy_mo {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#school_17grp_dummy_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 175px;
		left: 31px;
		top: 3687px;
		overflow: visible;
	}
	#layout_17small_theme_dummy_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_17small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_17small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#knowledge17_kornersmall_theme_ {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 123px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_17mob_small_theme_dummy_mo {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#car16_grp_dummy_theme_mob {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 3458px;
		overflow: visible;
	}
	#layout_16small_theme_dummy_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_16mob_small_theme_dummy_mo {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_16small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_16small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 167px;
	}
	#drive_16velocity_small_theme_d {
		left: 71px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#fash_grp_theme_mob {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 3229px;
		overflow: visible;
	}
	#layout_9_small_theme_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_9_mob_small_theme_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_9_small_theme_mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_9_small_theme_mob {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#fashion_finds_small_theme_mob {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#med_grp_dummy_theme_mob {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 31px;
		top: 3009px;
		overflow: visible;
	}
	#layout_4_small_theme_dummy_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_4_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_4_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 158px;
	}
	#sahara_small_theme_dummy_mob {
		left: 71px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_4_mob_small_theme_dummy_mo {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#shadow__grp_dummy_theme_mob {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 31px;
		top: 2789px;
		overflow: visible;
	}
	#layout_5_small_theme_dummy_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_5_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_5_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#shadow_space_small_theme_dummy {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_5_mob_small_theme_dummy_mo {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#school_grp_dummy_theme_mob {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 31px;
		top: 2569px;
		overflow: visible;
	}
	#layout_6_small_theme_dummy_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_6_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_6_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#knowledge_kornersmall_theme_du {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 123px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_6_mob_small_theme_dummy_mo {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#car_grp_dummy_theme_mob {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 2340px;
		overflow: visible;
	}
	#layout_7_small_theme_dummy_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_7_mob_small_theme_dummy_mo {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_7_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_7_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 167px;
	}
	#drive_velocity_small_theme_dum {
		left: 71px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#bike_grp_dummy_theme_mob {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 2111px;
		overflow: visible;
	}
	#layout_8_small_theme_dummy_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_8_mob_small_theme_dummy_mo {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_7_small_theme_dummy__eq {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_7_small_theme_dummy__eq {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#ride_revolutionbigtheme_dummy_ {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#fashion_finds_grp_dummy_theme_mob {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 1882px;
		overflow: visible;
	}
	#layout_9_small_theme_dummy_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_9_mob_small_theme_dummy_mo {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_9_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_9_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#fashion_finds_small_theme_dumm {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#ride_bike_theme_mob {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 29px;
		top: 1653px;
		overflow: visible;
	}
	#layout_9_small_theme_mob_ey {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_9_mob_small_theme_mob_ez {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_9_small_theme_mob_e {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_9_small_theme_mob_e {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#fashion_finds_small_theme_mob_e {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#drive_velocity_grp_mob_them_6 {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 33px;
		top: 1404px;
		overflow: visible;
	}
	#layout_8_small_theme_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_8_mob_small_theme_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_7_small_theme_mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_7_small_theme_mob {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#ride_revolutionbigtheme_mob {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#knowledge_grp_mob_them_7654 {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 33px;
		top: 1155px;
		overflow: visible;
	}
	#layout_7_small_theme_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_7_mob_small_theme_mob {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_7_small_theme_mob_fa {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_7_small_theme_mob_fa {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 167px;
	}
	#drive_velocity_small_theme_mob {
		left: 71px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}