.mediaViewInfo {
    --web-view-name: coming soon page;
    --web-view-id: coming_soon_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: coming_soon_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#coming_soon_page {
    position: absolute;
    width: calc(100vw - 136px);
    height:calc(100vh - 60px);
    top: 60px;
    left: 120px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: coming soon page;
    --web-view-id: coming_soon_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_back_some_soon {
    fill: rgba(219,242,231,1);
}
.over_all_back_some_soon {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}
#mid_grp_come {
    position: absolute;
    width: 587.094px;
    height: 487.493px;
    top: 50%;
    left: 45%;
    transform: translate(-50%,-50%);
    overflow: visible;
}
#mid_design_hex {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.mid_design_hex {
    overflow: visible;
    position: absolute;
    width: 358.413px;
    height: 408.701px;
    left: 87.915px;
    top: 28.614px;
    transform: matrix(1,0,0,1,0,0);
}
#down_like_left {
    fill: transparent;
    stroke: rgba(1,33,56,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.down_like_left {
    overflow: visible;
    position: absolute;
    width: 71.921px;
    height: 96.986px;
    left: 145.376px;
    top: 376.68px;
    transform: matrix(1,0,0,1,0,0);
}
#down_line {
    fill: transparent;
    stroke: rgba(0,31,57,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.down_line {
    overflow: visible;
    position: absolute;
    width: 36.07px;
    height: 47.472px;
    left: 245.792px;
    top: 441.8px;
    transform: matrix(1,0,0,1,0,0);
}
#top_line {
    fill: rgba(0,32,54,1);
    stroke: rgba(1,33,56,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.top_line {
    overflow: visible;
    position: absolute;
    width: 35.531px;
    height: 47.187px;
    left: 267.128px;
    top: 77.346px;
    transform: matrix(1,0,0,1,0,0);
}
#top_right_line {
    fill: transparent;
    stroke: rgba(4,32,61,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.top_right_line {
    overflow: visible;
    position: absolute;
    width: 72.424px;
    height: 97.768px;
    left: 361.049px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#C_backOMING {
    filter: blur(5px);
    transform: translate(-340.451px, -83.5px) matrix(1,0,0,1,496.9684,186.4693) rotate(-11deg);
    transform-origin: center;
    left: 10px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 414px;
    height: 224px;
    text-align: left;
    font-family: Comic Sans MS;
    font-style: normal;
    font-weight: bold;
    font-size: 95px;
    color: rgba(87,170,130,1);
    text-transform: uppercase;
}
#COMING_front {
    transform: translate(-340.451px, -83.5px) matrix(1,0,0,1,495.9684,174.4693) rotate(-11deg);
    transform-origin: center;
    left: 10px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 414px;
    height: 224px;
    text-align: left;
    font-family: Comic Sans MS;
    font-style: normal;
    font-weight: bold;
    font-size: 95px;
    color: rgba(255,255,255,1);
    text-transform: uppercase;
}
#speaker_1 {
    position: absolute;
    width: 152.187px;
    height: 129.383px;
    left: 0px;
    top: 180.222px;
    overflow: visible;
}
#back_speaker {
    fill: rgba(255,165,0,1);
}
.back_speaker {
    filter: drop-shadow(0px 5px 6px rgba(255, 165, 0, 1));
    overflow: visible;
    position: absolute;
    width: 153.607px;
    height: 138.486px;
    left: 0px;
    top: 8.896px;
    transform: matrix(1,0,0,1,0,0);
}
#front_speak {
    fill: rgba(255,165,0,1);
}
.front_speak {
    filter: drop-shadow(0px 5px 6px rgba(255, 165, 0, 1));
    overflow: visible;
    position: absolute;
    width: 52.246px;
    height: 146.485px;
    left: 117.941px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Stay_tuned__back {
    filter: blur(1px);
    transform: translate(-340.451px, -83.5px) matrix(1,0,0,1,654.6541,393.259) rotate(-11deg);
    transform-origin: center;
    left: 0px;
    top: 50px;
    position: absolute;
    overflow: visible;
    width: 135px;
    white-space: nowrap;
    line-height: 33px;
    margin-top: -7.5px;
    text-align: left;
    font-family: Comic Sans MS;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(91,222,91,1);
    text-transform: uppercase;
}
#Stay_tuned__front_ {
    transform: translate(-340.451px, -83.5px) matrix(1,0,0,1,654.6541,390.259) rotate(-11deg);
    transform-origin: center;
    left: 0px;
    top: 50px;
    position: absolute;
    overflow: visible;
    width: 135px;
    white-space: nowrap;
    line-height: 33px;
    margin-top: -7.5px;
    text-align: left;
    font-family: Comic Sans MS;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(66,178,66,1);
    text-transform: uppercase;
}