.mediaViewInfo {
    --web-view-name: top info;
    --web-view-id: top_info;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: top_info;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#top_info {
    position: relative;
    width: 100%;
    height: 428px;
    margin-top: 75px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: top info;
    --web-view-id: top_info;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
@keyframes fadein {

    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }

}
#mid_circle_grp_profile {
    position: relative;
    width: 100%;
    height: 419px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#banner_img {
    position: absolute;
    width: 100%;
    height: 160px;
    left: 2px;
    top: 0px;
    border-radius: 15px;
    overflow: hidden;
}
#profil_info_bdr {
    fill: rgba(255,255,255,1);
}
.profil_info_bdr {
    filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 259px;
    left: 0px;
    top: 168px;
}
#pfp_pic_image {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 68px;
    top: 114px;
    border-radius: 50%;
    overflow: hidden;
}
#user_details_grp_left {
    position: absolute;
    width: 300px;
    height: 169px;
    left: 25px;
    top: 235px;
    overflow: visible;
}
#UserProfileName_Pc {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 247px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#location_grp {
    position: absolute;
    width: 251px;
    height: 25px;
    left: 0px;
    top: 30px;
    overflow: visible;
}
#India_Bangalore {
    left: 33px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 219px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#india_img {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#skills_grp_ {
    position: absolute;
    width: 250px;
    height: 22px;
    left: 0px;
    top: 65px;
    overflow: visible;
}
#skill_img_ {
    position: absolute;
    width: 29px;
    height: 22px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#UIUX_designer_skill {
    left: 34px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 217px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#connections_grp {
    position: absolute;
    width: 128px;
    height: 31px;
    left: 123px;
    top: 138px;
    overflow: visible;
}
#all_con {
    fill: rgba(163,233,200,1);
}
.all_con {
    position: absolute;
    overflow: visible;
    width: 128px;
    height: 31px;
    left: 0px;
    top: 0px;
}
#Ellipse_11_circle_ {
    fill: rgba(255,255,255,1);
}
.Ellipse_11_circle_ {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 3px;
    top: 3px;
}
#n_2345432 {
    left: 5px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#Connections_lbl {
    left: 33px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#followers_grp_lbl {
    position: absolute;
    width: 113px;
    height: 31px;
    left: 0px;
    top: 138px;
    overflow: visible;
}
#overall_rec {
    fill: rgba(163,233,200,1);
}
.overall_rec {
    position: absolute;
    overflow: visible;
    width: 113px;
    height: 31px;
    left: 0px;
    top: 0px;
}
#Ellipse_1_circle {
    fill: rgba(255,255,255,1);
}
.Ellipse_1_circle {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 3px;
    top: 3px;
}
#n_23_456789 {
    left: 5px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#Followers_lbl {
    left: 33px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#skills_grp__cf {
    position: absolute;
    width: 250px;
    height: 22px;
    left: 0px;
    top: 96px;
    overflow: visible;
}
#skill_img__cg {
    position: absolute;
    width: 29px;
    height: 22px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#UIUX_designer_skill_lbl {
    left: 34px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 217px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}

#QR_btn_grp {
    position: absolute;
    width: 36px;
    height: 36px;
    left: 198px;
    top: 180px;
    overflow: visible;
    transition: all 0.30000001192092896s ease-out;
    --web-animation: fadein 0.30000001192092896s ease-out;
    --web-action-type: page;
    --web-action-target: profile_page___1.html;
    cursor: pointer;
}
#QR_circle_bdr {
    fill: rgba(255,255,255,1);
}
.QR_circle_bdr {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 54px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#QR_img {
    position: absolute;
    width: 23px;
    height: 23px;
    left: 7px;
    top: 7px;
    overflow: visible;
}
#profile_link_grp_ {
    position: absolute;
    width: 36px;
    height: 36px;
    left: 200px;
    top: 180px;
    overflow: visible;
}
#offer_circle {
    fill: rgba(255,255,255,1);
}
.offer_circle {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 54px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#web-design_ {
    position: absolute;
    width: 23px;
    height: 23px;
    left: 7px;
    top: 7px;
    cursor: pointer;
    overflow: visible;
}
#all_about_mission_vision_rpifb {
    position: absolute;
    width: calc(100% - 360px);
    height: 195px;
    right: 0px;
    top: 184px;
    overflow: visible;
}
#over_all_secter_bg {
    fill: rgba(255,255,255,1);
}
.over_all_secter_bg {
    filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.647));
    position: absolute;
    overflow: visible;
    width: 389px;
    height: 56px;
    left: 0px;
    top: 0px;
}
#mark_about_hanger {
    fill: rgba(208,222,191,1);
}
.mark_about_hanger {
    position: absolute;
    overflow: visible;
    width: 103px;
    height: 48px;
    left: 0px;
    top: 0px;
}
#Lorem_about_data_pc {
    left: 0px;
    top: 75px;
    position: absolute;
    overflow: visible;
   overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 120px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#labal_name_aoutb_grp {
    position: absolute;
    width: 338px;
    height: 22px;
    left: 28px;
    top: 13px;
    overflow: visible;
}
#About_lbl_lorem {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
    cursor: pointer;
}
#mission_lbl_selecter {
    left: 86px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 60px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(0,0,0,1);
}
#vision_lbl_selecter {
    left: 185px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 48px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
    cursor: pointer;
}
#princi_lbl_selecter {
    left: 267px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
    cursor: pointer;
}

#followers_grp_lbl {
    position: absolute;
    width: 113px;
    height: 31px;
    left: 25px;
    top: 373px;
    overflow: visible;
}
#overall_rec {
    fill: rgba(163,233,200,1);
}
.overall_rec {
    position: absolute;
    overflow: visible;
    width: 113px;
    height: 31px;
    left: 0px;
    top: 0px;
}
#Ellipse_1_circle {
    fill: rgba(255,255,255,1);
}
.Ellipse_1_circle {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 3px;
    top: 3px;
}
#n_23_456789 {
    left: 5px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#Followers_lbl {
    left: 33px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#skills_grp__bp {
    position: absolute;
    width: 226px;
    height: 22px;
    left: 25px;
    top: 331px;
    overflow: visible;
}
#skill_img__bq {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#UIUX_designer_skill_lbl {
    left: 39px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 188px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#pen_edit_banner_grp_pc {
    position: absolute;
    width: 23px;
    height: 23px;
    right: 30px;
    top: 184px;
    overflow: visible;
}
#pen_edit_banner_img_pc {
    position: absolute;
    width: 23px;
    height: 23px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#pen_edit_banner_grp_bv {
    position: absolute;
    width: 23px;
    height: 23px;
    right: 30px;
    top: 16px;
    cursor: pointer;
    overflow: visible;
}
#pen_edit_banner_img_bw {
    position: absolute;
    width: 23px;
    height: 23px;
    left: 0px;
    top: 0px;
    cursor: pointer;
    overflow: visible;
}
#pfp_pic {
    fill: rgba(255,255,255,1);
}
.pfp_pic {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 120px;
    left: 58px;
    top: 104px;
}

#all_centre_btn_grp {
    position: absolute;
    width: 130px;
    height: 250px;
    left: 360px;
    top: 168px;
    
    overflow: visible;
}
#left_half_btn_bg {
    position: absolute;
    width: 130px;
    height: 250px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#right_half_btn_bg {
    position: absolute;
    width: 69px;
    height: 250px;
    left: 61px;
    top: 0px;
    overflow: visible;
}
#Scroll_menu_options_extra {
    mix-blend-mode: normal;
    position: absolute;
    width: 130px;
    height: 173px;
    left: 0px;
    top: 39px;
    overflow: hidden;
}
#dot_menu_options {
    position: absolute;
    width: 126px;
    height: 157px;
    left: 3px;
    top: 9px;
    overflow: visible;
}
#connect_btn_grp_menu_edit_page:hover {
    left: 6px;
}
#connect_btn_grp_menu_edit_page {
    position: absolute;
    width: 120px;
    height: 32px;
    left: 0px;
    top: 0px;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#bdr_connect_menu_edit_page_c {
    fill: rgba(255,255,255,1);
}
.bdr_connect_menu_edit_page_c {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 50px;
    left: 0px;
    top: 0px;
}
#conncet_img_connect_c {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 6px;
    overflow: visible;
}
#Connect_lbl_menu_edit_page_c {
    left: 36px;
    top: 8px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#follow_link_grp_menu_edit_page:hover {
    left: 6px;
}
#follow_link_grp_menu_edit_page {
    position: absolute;
    width: 120px;
    height: 32px;
    left: 0px;
    top: 41px;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#follow_bdr_menu_edit_page_c {
    fill: rgba(255,255,255,1);
}
.follow_bdr_menu_edit_page_c {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 50px;
    left: 0px;
    top: 0px;
}
#Follow_lbl_menu_edit_page_da {
    left: 42px;
    top: 8px;
    position: absolute;
    overflow: visible;
    width: 38px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#follow_img_follow_db {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 6px;
    overflow: visible;
}
#ignore_btn_grp_menu_edit_page:hover {
    left: 6px;
}
#ignore_btn_grp_menu_edit_page {
    position: absolute;
    width: 120px;
    height: 32px;
    left: 0px;
    top: 83px;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#ignore_bdr_btn_menu_edit_page_dh {
    fill: rgba(255,255,255,1);
}
.ignore_bdr_btn_menu_edit_page_dh {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 50px;
    left: 0px;
    top: 0px;
}
#Ignore_lbl_menu_edit_page_di {
    left: 42px;
    top: 8px;
    position: absolute;
    overflow: visible;
    width: 38px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#ignore_img_unfollow_dj {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 6px;
    overflow: visible;
}
#block_btn_menu_edit_page:hover {
    left: 6px;
}
#block_btn_menu_edit_page {
    position: absolute;
    width: 120px;
    height: 32px;
    left: 0px;
    top: 125px;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#block_dbr_menu_edit_page_dp {
    fill: rgba(255,255,255,1);
}
.block_dbr_menu_edit_page_dp {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 50px;
    left: 0px;
    top: 0px;
}
#Block_lbl_menu_edit_page_dq {
    left: 45px;
    top: 8px;
    position: absolute;
    overflow: visible;
    width: 32px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#img_block_menu_edit_page_dr {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 6px;
    overflow: visible;
}