	.mediaViewInfo {
		--web-view-name: Fashion Finds mob;
		--web-view-id: Fashion_Finds_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Fashion_Finds_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Fashion_Finds_mob {
		position: absolute;
		width: 375px;
		height: 4633px;
        left:calc((100vw - 375px)/2);
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Fashion Finds mob;
		--web-view-id: Fashion_Finds_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#vision_all_grp_web_9_mob {
		position: absolute;
		width: 365px;
		height: 744px;
		left: 0px;
		top: 888px;
		overflow: visible;
	}
	#Scroll_vis_web_9_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 365px;
		height: 287px;
		left: 0px;
		top: 457px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BinQasem_vision_lbl_web_9_mob {
		left: 15px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 350px;
		height: 413px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#vision_lbl_web_9_mob {
		left: 33px;
		top: 375px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#Mask_vision_web_9_mob {
		position: absolute;
		width: 310px;
		height: 355px;
		left: 33px;
		top: 0px;
		overflow: visible;
	}
	#mision_grp_web_9_mob {
		position: absolute;
		width: 365px;
		height: 744px;
		left: 0px;
		top: 1706px;
		overflow: visible;
	}
	#Scroll_mission_web_9_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 365px;
		height: 287px;
		left: 0px;
		top: 457px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BinQasem_provid_web_mis_9_mob {
		left: 15px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 350px;
		height: 413px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#MISSION_lbl_web_9_mob {
		left: 33px;
		top: 393px;
		position: absolute;
		overflow: visible;
		width: 121px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#Mask_mission_web_9_mob {
		position: absolute;
		width: 310px;
		height: 355px;
		left: 33px;
		top: 0px;
		overflow: visible;
	}
	#princi_all_grp_web_9_mob {
		position: absolute;
		width: 365px;
		height: 744px;
		left: 0px;
		top: 2524px;
		overflow: visible;
	}
	#Scroll_princi_web_9_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 365px;
		height: 287px;
		left: 0px;
		top: 457px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BinQasem_provi_web_9_mob {
		left: 15px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 350px;
		height: 413px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#principle_lbl_web_9_mob {
		left: 33px;
		top: 393px;
		position: absolute;
		overflow: visible;
		width: 139px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#Mask_for_princi_web_9_mob {
		position: absolute;
		width: 310px;
		height: 355px;
		left: 33px;
		top: 0px;
		overflow: visible;
	}
	#our_services_web_9_mob_ {
		position: absolute;
		width: 374px;
		height: 473.001px;
		left: 21px;
		top: 3453px;
		overflow: visible;
	}
	#line_for_hanger_web_9_mob {
		fill: rgba(150,111,51,1);
	}
	.line_for_hanger_web_9_mob {
		position: absolute;
		overflow: visible;
		width: 357px;
		height: 5px;
		left: 17px;
		top: 33px;
	}
	#Scroll_card_service_mob_9_them {
		mix-blend-mode: normal;
		position: absolute;
		width: 348px;
		height: 473.001px;
		left: 6px;
		top: 0px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#card_1_services_web_9_mob {
		position: relative;
		width: 325px;
		height: 436.001px;
		left: 8px;
		top: 16px;
		overflow: visible;
	}
	#hanger_img_web_9_mob {
		position: absolute;
		width: 325px;
		height: 325px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Mask_web_9_mob_card_1 {
		position: absolute;
		width: 256px;
		height: 303px;
		left: 23px;
		top: 133px;
		overflow: visible;
	}
	#Bunda_Clothes_web_9_mob {
		left: 23px;
		top: 390px;
		position: absolute;
		overflow: visible;
		width: 100px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
		text-transform: uppercase;
	}
	#badr_path_web_9_mob {
		fill: rgba(49,49,49,1);
	}
	.badr_path_web_9_mob {
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 58px;
		left: 221.999px;
		top: 378px;
	}
	#law_6_web_9_mob {
		position: absolute;
		width: 15.368px;
		height: 16.199px;
		left: 242.504px;
		top: 399.148px;
		overflow: visible;
	}
	#Path_6945_web_9_mob {
		fill: rgba(255,255,255,1);
	}
	.Path_6945_web_9_mob {
		overflow: visible;
		position: absolute;
		width: 10.408px;
		height: 11.439px;
		left: 0.902px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_6946_web_9_mob {
		fill: rgba(255,255,255,1);
	}
	.Path_6946_web_9_mob {
		overflow: visible;
		position: absolute;
		width: 7.394px;
		height: 6.184px;
		left: 7.975px;
		top: 6.443px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_6947_web_9_mob {
		fill: rgba(255,255,255,1);
	}
	.Path_6947_web_9_mob {
		overflow: visible;
		position: absolute;
		width: 10.543px;
		height: 3.797px;
		left: 0px;
		top: 12.402px;
		transform: matrix(1,0,0,1,0,0);
	}
	#holder_knob_web_9_mob {
		fill: rgba(150,111,51,1);
	}
	.holder_knob_web_9_mob {
		position: absolute;
		overflow: visible;
		width: 27px;
		height: 27px;
		left: 0px;
		top: 22px;
	}
	#our_services_lbl_web_9_mob {
		left: 90px;
		top: 3393px;
		position: absolute;
		overflow: visible;
		width: 196px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#footer_all_grp_web_9_mob {
		position: absolute;
		width: 375px;
		height: 607px;
		left: 0px;
		top: 4026px;
		overflow: visible;
	}
	#footer_bg_web_9_mob {
		opacity: 0.93;
		fill: rgba(50,50,50,1);
	}
	.footer_bg_web_9_mob {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 607px;
		left: 0px;
		top: 0px;
	}
	#footer_all_design_web_9_mob {
		position: absolute;
		width: 349px;
		height: 225px;
		left: 13px;
		top: 30px;
		overflow: visible;
	}
	#clothing_store_remove_bg {
		position: absolute;
		width: 349px;
		height: 225px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#grp_map_ff_mob {
		fill: rgba(255,255,255,1);
	}
	.grp_map_ff_mob {
		position: absolute;
		overflow: visible;
		width: 322px;
		height: 145px;
		left: 13px;
		top: 68px;
	}
	#For_Queries_info_web_9_mob_ {
		left: 91.764px;
		top: 293px;
		position: absolute;
		overflow: visible;
		width: 189px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#For_call__920012888_web_9_mob {
		left: 91.764px;
		top: 314px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#telephone_web_9_mob {
		fill: rgba(255,255,255,1);
	}
	.telephone_web_9_mob {
		overflow: visible;
		position: absolute;
		width: 13.412px;
		height: 14.475px;
		left: 65.237px;
		top: 318.525px;
		transform: matrix(1,0,0,1,0,0);
	}
	#send_1_web_9_mob {
		fill: rgba(255,255,255,1);
	}
	.send_1_web_9_mob {
		overflow: visible;
		position: absolute;
		width: 15.446px;
		height: 14.281px;
		left: 63.203px;
		top: 293.961px;
		transform: matrix(1,0,0,1,0,0);
	}
	#socials_grp_med_web_9_mob {
		position: absolute;
		width: 210px;
		height: 50.001px;
		left: 65.237px;
		top: 423px;
		overflow: visible;
	}
	#linked_in_grp_med_web_9_mob {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 160px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_5_med_web_9_mob:hover {
		fill: rgba(120,120,255,1);
	}
	.Ellipse_5_med_web_9_mob:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_5_med_web_9_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_5_med_web_9_mob {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_website_9_layout {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#n_website_9_layout {
		fill: rgba(63,61,86,1);
	}
	.n_website_9_layout {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.953px;
		transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_down_web_9_mob {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_down_web_9_mob {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.037px;
		left: 0.614px;
		top: 7.098px;
		transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_website_9_layout {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_website_9_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.217px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_med_web_9_mob {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 80px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_4_med_web_9_mob:hover {
		fill: rgba(255,120,120,1);
	}
	.Ellipse_4_med_web_9_mob:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_4_med_web_9_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_4_med_web_9_mob {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagram_website_9_layout {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.125px;
		overflow: visible;
	}
	#whatsapp_grp_med_web_9_mob {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_3_med_web_9_mob:hover {
		fill: rgba(50,172,158,1);
	}
	.Ellipse_3_med_web_9_mob:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_3_med_web_9_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_3_med_web_9_mob {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_website9_layout {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#Icon_metro-location_web_9_mob {
		fill: rgba(255,255,255,1);
	}
	.Icon_metro-location_web_9_mob {
		overflow: visible;
		position: absolute;
		width: 11.381px;
		height: 18.209px;
		left: 65.236px;
		top: 339.33px;
		transform: matrix(1,0,0,1,0,0);
	}
	#For_call__920012888_web_9_mob_dr {
		left: 91.764px;
		top: 338.539px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 62px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#n_Copyrig_website_9_mobop_live {
		left: 29px;
		top: 540.461px;
		position: absolute;
		overflow: visible;
		width: 318px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#header_all_grp_web_9_mob {
		position: absolute;
		width: 1366px;
		height: 771px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_02307280_web_9_mob {
		position: absolute;
		width: 1366px;
		height: 766px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#shade_img_web_9_mob {
		opacity: 0.18;
		fill: rgba(0,0,0,1);
	}
	.shade_img_web_9_mob {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 771px;
		left: 0px;
		top: 0px;
	}
	#pfp_pic_web_9_mob {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 30px;
		top: 97px;
		overflow: visible;
	}
	#bdr_pfp_web_9_mob {
		fill: rgba(255,255,255,1);
	}
	.bdr_pfp_web_9_mob {
		position: absolute;
		overflow: visible;
		width: 70px;
		height: 70px;
		left: 0px;
		top: 0px;
	}
	#zane-lee-bXbi_web_9_mob {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 5px;
		top: 5px;
		border-radius: 50%;
		overflow: hidden;
	}
	#ACT_NOW__SEIZE_THE_DEAL_web_9_ {
		left: 30px;
		top: 263px;
		position: absolute;
		overflow: visible;
		width: 316px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 45px;
		color: rgba(255,255,255,1);
	}
	#Sell_btn_web_9_mob {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 30px;
		top: 441.002px;
		overflow: visible;
	}
	#bdr_bids_web_9_mob {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 1.5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.bdr_bids_web_9_mob {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: -0.002px;
	}
	#START_BIDDING_web_9_mob {
		left: 28px;
		top: 10.998px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#Scroll_card_service_mob_9_them::-webkit-scrollbar{
		display: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
#Scroll_vis_web_9_mob::-webkit-scrollbar{
		display: none;
	}
#Scroll_mission_web_9_mob::-webkit-scrollbar{
		display: none;
	}
#Scroll_princi_web_9_mob::-webkit-scrollbar{
		display: none;
	}