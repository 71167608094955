/* 
#top_bar_Border {
    fill: rgba(219,242,231,1);
}
.top_bar_Border {
    width: 67px;
    height: 375px;
    position: fixed;
    z-index: 10;
    top:0px;
    
    overflow: visible;
    transform: matrix(1,0,0,1,154,-154) rotate(-90deg);
    transform-origin: center;
}
#logo_topBorder {
    position: fixed;
    width: 52px;
    height: 52px;
    left: 17px;
    top: 8px;
    z-index: 10;
    overflow: visible;
}

#menu_top_bar {
    position: fixed;
    z-index: 10;
    width: 35px;
    height: 35px;
    left: 323px;
    top: 15px;
    overflow: visible;
    transition: all 0.6000000238418579s wind-up;
    --web-animation: fadein 0.6000000238418579s wind-up;
    --web-action-type: page;
    --web-action-target: menu.html;
    cursor: pointer;
} */



.mediaViewInfo {
    --web-view-name: top nav bar new;
    --web-view-id: top_nav_bar_new;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: top_nav_bar_new;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#top_nav_bar_new {
    position: fixed;
    width: 451px;
    height: 70px;
    background-color: rgba(255,255,255,1);
    z-index: 100;
    top:0px;
    display: flex;
    overflow: visible;
    --web-view-name: top nav bar new;
    --web-view-id: top_nav_bar_new;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#top_bar_Border {
    fill: rgba(219,242,231,1);
    width: 100%;
} 
.top_bar_Border {
    position: fixed;
    overflow: visible;
    display: flex;
    width: 100%;
    z-index: 10;
    height: 70px;
    left: 0px;
    top: 0px;
}
#menu_top_bar {
    position: fixed;
    width: 35px;
    z-index: 10;
    height: 35px;
    left: 87%;
    top: 15px;
    overflow: visible;
}
#logo_topBorder {
    position: fixed;
    width: 52px;
    height: 52px;
    z-index: 10;
    left: 17px;
    top: 8px;
    overflow: visible;
}