	.mediaViewInfo {
		--web-view-name: Chefs Kitchen;
		--web-view-id: Chefs_Kitchen;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Chefs_Kitchen;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Chefs_Kitchen {
		position: absolute;
		width: 1366px;
		height: 4377px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Chefs Kitchen;
		--web-view-id: Chefs_Kitchen;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#chefs_13_pc_lcz9nxhoslo-brooke-lark {
		opacity: 0.2;
		position: absolute;
		width: 2500px;
		height: 1871px;
		left: -686px;
		top: 798px;
		overflow: visible;
	}
	#chefs_13_pc_socials_grp_med_web_9_pc {
		position: absolute;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 3969px;
		overflow: visible;
	}
	#chefs_13_pc_footer_bg_web_9_pc {
		opacity: 0.93;
		fill: rgba(54,40,38,1);
	}
	.chefs_13_pc_footer_bg_web_9_pc {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 0px;
	}
	#chefs_13_pc_n_Copyright_Operate_website_9_ {
		left: 41.5px;
		top: 349.16px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_linked_in_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 202px;
		top: 262px;
		overflow: visible;
		z-index: 1;
	}
	#chefs_13_pc_Ellipse_5_med_web_9_pc:hover {
		fill: rgba(120,120,255,1);
	}
	.chefs_13_pc_Ellipse_5_med_web_9_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#chefs_13_pc_Ellipse_5_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.chefs_13_pc_Ellipse_5_med_web_9_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#chefs_13_pc_linked_in_website_9_layout {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#chefs_13_pc_n_website_2_layout_web_9_pc {
		fill: rgba(63,61,86,1);
	}
	.chefs_13_pc_n_website_2_layout_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.953px;
		transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_linked_in_i_down_web_9_pc {
		fill: rgba(63,61,86,1);
	}
	.chefs_13_pc_linked_in_i_down_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.098px;
		transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_top_dot_i_website_9_layout {
		fill: rgba(63,61,86,1);
	}
	.chefs_13_pc_top_dot_i_website_9_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_Insta_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 122px;
		top: 261.999px;
		overflow: visible;
		z-index: 1;
	}
	#chefs_13_pc_Ellipse_4_med_web_9_pc:hover {
		fill: rgba(255,120,120,1);
	}
	.chefs_13_pc_Ellipse_4_med_web_9_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#chefs_13_pc_Ellipse_4_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.chefs_13_pc_Ellipse_4_med_web_9_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#chefs_13_pc_instagram_website_9_layout {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.126px;
		overflow: visible;
	}
	#chefs_13_pc_whatsapp_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 42px;
		top: 262px;
		overflow: visible;
		z-index: 1;
	}
	#chefs_13_pc_Ellipse_3_med_web_9_pc:hover {
		fill: rgba(50,172,158,1);
	}
	.chefs_13_pc_Ellipse_3_med_web_9_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#chefs_13_pc_Ellipse_3_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.chefs_13_pc_Ellipse_3_med_web_9_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#chefs_13_pc_whatsapp_lbl_website9_layout {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#chefs_13_pc_Line {
		position: absolute;
		width: 1170px;
		height: 3079px;
		left: 98px;
		top: 1376px;
		overflow: visible;
	}
	#chefs_13_pc_Line_1 {
		opacity: 0.11;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.chefs_13_pc_Line_1 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 3079px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_Line_4 {
		opacity: 0.11;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.chefs_13_pc_Line_4 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 3079px;
		left: 254px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_Line_5 {
		opacity: 0.11;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.chefs_13_pc_Line_5 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 3079px;
		left: 997px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_Line_2 {
		opacity: 0.11;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.chefs_13_pc_Line_2 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 3079px;
		left: 600px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_Line_3 {
		opacity: 0.11;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.chefs_13_pc_Line_3 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 3079px;
		left: 1170px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_eaters-collective-132773-unspl {
		position: absolute;
		width: 1294.818px;
		height: 1322.129px;
		left: 494.294px;
		top: 3520.936px;
		overflow: visible;
		z-index: 1;
	}
	#chefs_13_pc_Group_14928 {
		position: absolute;
		width: 1101px;
		height: 675px;
		left: 109px;
		top: 895px;
		overflow: visible;
	}
	#chefs_13_pc_Food_unites_cultures_evokes__m {
		left: 593px;
		top: 112px;
		position: absolute;
		overflow: visible;
		width: 509px;
		white-space: nowrap;
		line-height: 46px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_pc_lidye-1Shk_PkNkNw-unsplash {
		filter: drop-shadow(0px 47px 99px rgba(197, 201, 203, 1));
		position: absolute;
		width: 485px;
		height: 675px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_pc_Rectangle_89 {
		fill: rgba(249,129,105,1);
	}
	.chefs_13_pc_Rectangle_89 {
		filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 342px;
		height: 157px;
		left: 596px;
		top: 443px;
	}
	#chefs_13_pc_Know_More_About_Us {
		left: 624px;
		top: 467px;
		position: absolute;
		overflow: visible;
		width: 190px;
		height: 23px;
		line-height: 71px;
		margin-top: -26.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_Group_14930 {
		position: absolute;
		width: 1192.5px;
		height: 577.676px;
		left: 109px;
		top: 1583.162px;
		overflow: visible;
	}
	#chefs_13_pc_Food_a_universal_language_a_ta {
		left: 0px;
		top: 210.838px;
		position: absolute;
		overflow: visible;
		width: 550px;
		height: 221px;
		line-height: 46px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_pc_brooke-lark-08bOYnH_r_E-unspla {
		position: absolute;
		width: 233.508px;
		height: 189.433px;
		left: 894.285px;
		top: 282.272px;
		overflow: visible;
	}
	#chefs_13_pc_o9kwrr26 {
		position: absolute;
		width: 233.508px;
		height: 194.121px;
		left: 644.834px;
		top: 124.726px;
		overflow: visible;
	}
	#chefs_13_pc_joseph-gonzalez-zcUgjyqEwe8-un {
		position: absolute;
		width: 310.407px;
		height: 250.389px;
		left: 574.5px;
		top: 327.287px;
		overflow: visible;
	}
	#chefs_13_pc_joseph-gonzalez-fdlZBWIP0aM-un {
		position: absolute;
		width: 307.593px;
		height: 268.206px;
		left: 884.907px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_pc_Rectangle_90 {
		fill: rgba(249,129,105,1);
	}
	.chefs_13_pc_Rectangle_90 {
		filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 342px;
		height: 157px;
		left: 3px;
		top: 465.838px;
	}
	#chefs_13_pc_Know_More_About_Us_dl {
		left: 31px;
		top: 489.838px;
		position: absolute;
		overflow: visible;
		width: 190px;
		height: 23px;
		line-height: 71px;
		margin-top: -26.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_Footer {
		position: absolute;
		width: 1188px;
		height: 305px;
		left: 81px;
		top: 4242px;
		display: none;
		overflow: visible;
	}
	#chefs_13_pc_Want_to_work_with_us {
		position: absolute;
		width: 1188px;
		height: 220px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_pc_Rectangle_81 {
		fill: rgba(11,49,75,1);
	}
	.chefs_13_pc_Rectangle_81 {
		position: absolute;
		overflow: visible;
		width: 1188px;
		height: 220px;
		left: 0px;
		top: 0px;
	}
	#chefs_13_pc_Rectangle_82 {
		fill: rgba(0,93,255,1);
	}
	.chefs_13_pc_Rectangle_82 {
		position: absolute;
		overflow: visible;
		width: 239px;
		height: 66px;
		left: 851px;
		top: 83px;
	}
	#chefs_13_pc_Contact_Now {
		left: 917px;
		top: 108px;
		position: absolute;
		overflow: hidden;
		width: 109px;
		height: 17px;
		line-height: 46px;
		margin-top: -15px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_Want_to_work_with_us_ {
		left: 112px;
		top: 52px;
		position: absolute;
		overflow: visible;
		width: 452px;
		height: 48px;
		line-height: 46px;
		margin-top: -5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: normal;
		font-size: 36px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_Building_first_evolved_out_of_ {
		opacity: 0.5;
		left: 112px;
		top: 112px;
		position: absolute;
		overflow: visible;
		width: 556px;
		height: 59px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_Text {
		left: 1036px;
		top: 277px;
		position: absolute;
		overflow: visible;
		width: 138px;
		white-space: nowrap;
		text-align: left;
		font-family: Font Awesome 5 Brands;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(11,49,75,1);
		letter-spacing: 14.29px;
	}
	#chefs_13_pc_CopyrightArifur_Rahman_2019_Al {
		opacity: 0.35;
		left: 20px;
		top: 278px;
		position: absolute;
		overflow: visible;
		width: 389px;
		height: 27px;
		line-height: 46px;
		margin-top: -15px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(11,49,75,1);
	}
	#chefs_13_pc_Group_14927 {
		position: absolute;
		width: 1366px;
		height: 798px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_pc_style_3 {
		position: absolute;
		width: 613px;
		height: 798px;
		left: 753px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_pc_Rectangle_695_dy {
		fill: url(#chefs_13_pc_Rectangle_695_dy);
	}
	.chefs_13_pc_Rectangle_695_dy {
		position: absolute;
		overflow: visible;
		width: 753px;
		height: 798px;
		left: 0px;
		top: 0px;
	}
	#chefs_13_pc_Rectangle_84 {
		fill: rgba(249,129,105,1);
	}
	.chefs_13_pc_Rectangle_84 {
		filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 291px;
		height: 157px;
		left: 98px;
		top: 553px;
	}
	#chefs_13_pc_Good_food_choices_are_good_inv {
		left: 98px;
		top: 266px;
		position: absolute;
		overflow: visible;
		width: 700px;
		height: 156px;
		line-height: 67px;
		margin-top: -5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: lighter;
		font-size: 57px;
		color: rgba(249,249,249,0.886);
	}
	#chefs_13_pc_Explore_Now_ {
		left: 141px;
		top: 579px;
		position: absolute;
		overflow: visible;
		width: 119px;
		white-space: nowrap;
		line-height: 71px;
		margin-top: -26.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_Scroll_Group_42 {
		mix-blend-mode: normal;
		position: absolute;
		width: 616px;
		height: 91px;
		left: 90px;
		top: 437px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#chefs_13_pc_Scroll_Group_42::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_pc_intro_part {
		opacity: 0.71;
		left: 8px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 601px;
		height: 80px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(249,249,249,0.886);
	}
	#chefs_13_pc_zane-lee-bXbi1jJVDf0-unsplash_ {
		position: absolute;
		width: 75px;
		height: 75px;
		left: 98px;
		top: 41px;
        border-radius: 50%;
		overflow: hidden;
	}
	#chefs_13_pc_Operate_Live {
		left: 199px;
		top: 59px;
		position: absolute;
		overflow: visible;
		width: 168px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_good-soul-shop-DlO0yz4U67A-uns {
		position: absolute;
		width: 1366px;
		height: 544px;
		left: 0px;
		top: 3054px;
		overflow: visible;
	}
	#chefs_13_pc_Explore_Our_Foods {
		left: 501px;
		top: 3064px;
		position: absolute;
		overflow: visible;
		width: 365px;
		height: 41px;
		line-height: 46px;
		margin-top: -5px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(249,129,105,1);
	}
	#chefs_13_pc_Group_14936 {
		position: absolute;
		width: 255px;
		height: 70px;
		left: 1097px;
		top: 3019px;
		overflow: visible;
	}
	#chefs_13_pc_Rectangle_107 {
		fill: rgba(249,129,105,1);
	}
	.chefs_13_pc_Rectangle_107 {
		filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 342px;
		height: 157px;
		left: 0px;
		top: 0px;
	}
	#chefs_13_pc_View_More_-_ {
		left: 68px;
		top: 25px;
		position: absolute;
		overflow: visible;
		width: 120px;
		white-space: nowrap;
		line-height: 71px;
		margin-top: -26.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_Group_14924 {
		position: absolute;
		width: 1031.027px;
		height: 433.428px;
		left: 167.366px;
		top: 2415.426px;
		overflow: visible;
	}
	#chefs_13_pc_Rectangle_696 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_pc_Rectangle_696 {
		filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		width: 321.641px;
		height: 433.428px;
		position: absolute;
		overflow: visible;
		transform: translate(-167.366px, -2415.426px) matrix(1,0,0,1,167.6065,2415.4258) rotate(180deg);
		transform-origin: center;
	}
	#chefs_13_pc_Rectangle_697 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_pc_Rectangle_697 {
		filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		width: 321.641px;
		height: 433.428px;
		position: absolute;
		overflow: visible;
		transform: translate(-167.366px, -2415.426px) matrix(1,0,0,1,876.753,2415.4258) rotate(180deg);
		transform-origin: center;
	}
	#chefs_13_pc_Rectangle_698 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_pc_Rectangle_698 {
		filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		width: 321.641px;
		height: 433.428px;
		position: absolute;
		overflow: visible;
		transform: translate(-167.366px, -2415.426px) matrix(1,0,0,1,522.1797,2415.4258) rotate(180deg);
		transform-origin: center;
	}
	#chefs_13_pc_eaters-collective-172257-unspl {
		position: absolute;
		width: 321.641px;
		height: 225.039px;
		left: 0.24px;
		top: 0.001px;
		overflow: visible;
	}
	#chefs_13_pc_n_es93lms {
		position: absolute;
		width: 321.641px;
		height: 225.039px;
		left: 709.387px;
		top: 0.001px;
		overflow: visible;
	}
	#chefs_13_pc_an_vision-1188492-unsplash {
		position: absolute;
		width: 321.641px;
		height: 225.039px;
		left: 354.813px;
		top: 0.001px;
		overflow: visible;
	}
	#chefs_13_pc_Scroll_Group_37 {
		mix-blend-mode: normal;
		position: absolute;
		width: 309px;
		height: 146px;
		left: 0px;
		top: 281.574px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#chefs_13_pc_Scroll_Group_37::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_pc_Lorem_ipsum_dolor_sit_amet_con {
		left: 20px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 269.407px;
		height: 130px;
		line-height: 22px;
		margin-top: -4px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(174,178,180,1);
	}
	#chefs_13_pc_Scroll_Group_39 {
		mix-blend-mode: normal;
		position: absolute;
		width: 306px;
		height: 145px;
		left: 714.146px;
		top: 283.574px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#chefs_13_pc_Scroll_Group_39::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_pc_Lorem_ipsum_dolor_sit_amet_con_el {
		left: 15px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 276.407px;
		height: 130px;
		line-height: 22px;
		margin-top: -4px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(174,178,180,1);
	}
	#chefs_13_pc_Scroll_Group_38 {
		mix-blend-mode: normal;
		position: absolute;
		width: 299px;
		height: 140px;
		left: 360.573px;
		top: 288.574px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#chefs_13_pc_Scroll_Group_38::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_pc_Lorem_ipsum_dolor_sit_amet_con_en {
		left: 14px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 271.407px;
		height: 125px;
		line-height: 22px;
		margin-top: -4px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(174,178,180,1);
	}
	#chefs_13_pc_Vision {
		left: 20px;
		top: 253.574px;
		position: absolute;
		overflow: visible;
		width: 221px;
		height: 21px;
		line-height: 46px;
		margin-top: -14px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_pc_Principles {
		left: 729.146px;
		top: 253.574px;
		position: absolute;
		overflow: visible;
		width: 221px;
		height: 21px;
		line-height: 46px;
		margin-top: -14px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_pc_Mission {
		left: 374.573px;
		top: 253.574px;
		position: absolute;
		overflow: visible;
		width: 221px;
		height: 21px;
		line-height: 46px;
		margin-top: -14px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_pc_Scroll_Group_40 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 455px;
		left: 10px;
		top: 3143px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#chefs_13_pc_Scroll_Group_40::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_pc_Group_14923 {
		position: relative;
		width: 367px;
		height: 381px;
		left: 0px;
		top: 37px;
		overflow: visible;
	}
	#chefs_13_pc_Group_14920 {
		position: relative;
		width: 367px;
		height: 367px;
		left: 0px;
		top: 14px;
		overflow: visible;
	}
	#chefs_13_pc_Group_14937 {
		position: absolute;
		width: 367px;
		height: 367px;
		left: 0px;
		top: 0px;
		overflow: visible;
		-webkit-animation: spin 2s linear infinite;
  	animation: spin 5s linear infinite;
	}
	@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

	#chefs_13_pc_kisspng-plate-circle-platter-t {
		position: absolute;
		width: 367px;
		height: 367px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_pc_anh-nguyen-kcA-c3f_3FE-unsplas {
		position: absolute;
		width: 245px;
		height: 244px;
		left: 61px;
		top: 57px;
		border-radius: 50%;
		overflow: hidden;
	}
	#chefs_13_pc_Salad {
		left: 32px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 304px;
		height: 28px;
		line-height: 46px;
		margin-top: -10.5px;
		text-align: center;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_pc_For_Queries_infoandroom_web_9_ {
		left: 68.526px;
		top: 4101px;
		position: absolute;
		overflow: visible;
		width: 219px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_For_call__920012888_web_9_pc {
		left: 68.526px;
		top: 4122px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_telephone_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_pc_telephone_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.521px;
		left: 42px;
		top: 4126.525px;
		transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_send_1_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_pc_send_1_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 42px;
		top: 4105.719px;
		transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_Line_web_9_pc {
		fill: transparent;
		stroke: rgba(86,86,86,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.chefs_13_pc_Line_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 1365.56px;
		height: 2px;
		left: 0.155px;
		top: 4305.113px;
		transform: matrix(1,0,0,1,0,0);
		z-index: -1;
	}
	#chefs_13_pc_Icon_metro-locatio_web_9_pc_n {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_pc_Icon_metro-locatio_web_9_pc_n {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 18.209px;
		left: 42px;
		top: 4147.33px;
		transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_address_lbl_web_9_pc {
		left: 68.526px;
		top: 4146.539px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 62px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_Group_14926 {
		position: absolute;
		width: 343px;
		height: 264.312px;
		left: 424px;
		top: 4014.001px;
		overflow: visible;
		z-index: 10;
	}
	#chefs_13_pc_kisspng-tray-glass-plastic-pla {
		position: absolute;
		width: 343px;
		height: 264.312px;
		left: 0px;
		top: -0.001px;
		overflow: visible;
	}
	#chefs_13_pc_Rectangle_701 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_pc_Rectangle_701 {
		position: absolute;
		overflow: visible;
		width: 267px;
		height: 172px;
		left: 38px;
		top: 46.999px;
	}
	#chefs_13_pc_n_Copyright_Operate_website_9__e {
		left: 544px;
		top: 3975px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(255,255,255,1);
		z-index: 1;
	}
	#chefs_13_pc_Rectangle_700 {
		opacity: 0.28;
		fill: rgba(0,0,0,1);
	}
	.chefs_13_pc_Rectangle_700 {
		position: absolute;
		overflow: visible;
		width: 617px;
		height: 798px;
		left: 749px;
		top: 0px;
	}
	body::-webkit-scrollbar{
		display: none;
	}