#card_1_commu_recent_mob {
    position: absolute;
    width: 100%;
    height: calc(100% - 35px);
    /* left: 8px; */
    top: 35px;
    overflow: visible;
}
#n_st_card_grp_mob_recent_event {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#recent_events_new_e_commu_rece_bq {
    fill: url(#recent_events_new_e_commu_rece_bq);
}
.recent_events_new_e_commu_rece_bq {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}
#recent_events_line_commu_recen {
    fill: transparent;
    stroke: rgba(64,225,150,1);
    stroke-width: 5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.recent_events_line_commu_recen {
    overflow: visible;
    position: absolute;
    width: 5px;
    height: 26px;
    left: 8px;
    top: 6px;
    transform: matrix(1,0,0,1,0,0);
}
#recent_events_newcommu_recent_ {
    opacity: 0.92;
    left: 18px;
    top: 9.5px;
    position: absolute;
    overflow: visible;
    width: 92px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -7px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#card_1_recent_com_mob {
    position: relative;
    width: 177.664px;
    height: 118.778px;
    margin: 5px;
    /* left: 20.24px;
    top: 38.222px; */
    overflow: visible;
}
#img_event_commu_recent_mo {
    position: absolute;
    width: 177.664px;
    height: 98.427px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#shade_img_commu_recent_mob {
    opacity: 0.29;
    fill: rgba(0,0,0,1);
}
.shade_img_commu_recent_mob {
    position: absolute;
    overflow: visible;
    width: 177.664px;
    height: 98.427px;
    left: 0px;
    top: 0px;
}
#date_of_event_mob {
    position: absolute;
    width: 73px;
    height: 31px;
    left: 2.26px;
    top: 63.778px;
    overflow: visible;
}
#bdr_date_commu_recent_mob {
    opacity: 0.66;
    fill: rgba(255,255,255,1);
}
.bdr_date_commu_recent_mob {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 0px;
    top: 0px;
}
#n_3th_Jan_2023_commu_recent_mo {
    left: 4.24px;
    top: 2.649px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    color: rgba(51,51,51,1);
}
#event_name_mob {
    position: absolute;
    width: 161px;
    height: 21px;
    left: 8.76px;
    top: 97.778px;
    overflow: visible;
}
#op_live_bdr_name_commu_recent_ {
    opacity: 0.75;
    fill: rgba(255,255,255,1);
}
.op_live_bdr_name_commu_recent_ {
    position: absolute;
    overflow: visible;
    width: 161px;
    height: 21px;
    left: 0px;
    top: 0px;
}
#Operate_Live_commu_recent_mob {
    left: 0px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 162px;
    height: 16px;
    line-height: 29px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(4,4,4,1);
}
#trophie_recent_mob {
    position: absolute;
    width: 73px;
    height: 24.894px;
    left: 2.26px;
    top: 2.778px;
    overflow: visible;
}
#bdr_trophie_commu_recent_mob {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.bdr_trophie_commu_recent_mob {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 24.894px;
    left: 0px;
    top: 0px;
}
#trophy_img_commu_recent_mob {
    position: absolute;
    width: auto;
    height: 17px;
    left: 1.5px;
    top: 4px;
    color: white;
    overflow: visible;
}
#n_234_commu_recent_mob {
    left: 19.5px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 53px;
    height: 16px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(255,255,255,1);
}