.mediaViewInfo {
    --web-view-name: forgot page;
    --web-view-id: forgot_page_pc;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-refresh-for-changes: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: forgot_page_pc;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

#forgot_page_pc {
    position: absolute;
    width: 1366px;
    height: 768px;
    background-color: rgba(255,255,255,1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    --web-view-name: forgot page;
    --web-view-id: forgot_page_pc;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-refresh-for-changes: true;
    --web-enable-deep-linking: true;
}
#Path_1_pc {
    fill: rgba(52,227,105,1);
}
.Path_1_pc {
    overflow: visible;
    position: absolute;
    width: 260.616px;
    height: 160.165px;
    left: 1106.239px;
    top: 30.062px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_2_pc {
    fill: rgba(119,221,150,1);
}
.Path_2_pc {
    overflow: visible;
    position: absolute;
    width: 137.074px;
    height: 83.79px;
    left: 1230.797px;
    top: -4.107px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_5_pc {
    fill: rgba(213,213,213,1);
}
.Path_5_pc {
    overflow: visible;
    position: absolute;
    width: 95.817px;
    height: 59.11px;
    left: 1276.654px;
    top: 99.067px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_1_o_pc {
    fill: rgba(52,227,105,1);
}
.Path_1_o_pc {
    overflow: visible;
    position: absolute;
    width: 284.045px;
    height: 160.202px;
    left: 1.909px;
    top: 512.549px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_2_p_pc {
    fill: rgba(119,221,150,1);
}
.Path_2_p_pc {
    overflow: visible;
    position: absolute;
    width: 149.499px;
    height: 84.597px;
    left: 0px;
    top: 476.717px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_3_pc {
    fill: rgba(229,228,228,1);
}
.Path_3_pc {
    overflow: visible;
    position: absolute;
    width: 214.177px;
    height: 131.242px;
    left: 1.31px;
    top: 578.222px;
    transform: matrix(1,0,0,1,0,0);
}
#Forgot_Password_pc_lbl_ {
    left: 555px;
    top: 307px;
    position: absolute;
    overflow: visible;
    width: 258px;
    white-space: nowrap;
    line-height: 75.63333129882812px;
    margin-top: -20.316665649414062px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    color: rgba(112,112,112,1);
}
#Enter_the_email_address_associ_pc {
    left: 508px;
    top: 380px;
    position: absolute;
    overflow: visible;
    width: 351px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#ema_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.ema_pc {
    filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 331.5px;
    height: 49.599px;
    left: 527px;
    top: 445px;
    font-size: 20px;
    padding: 15px;
    border: 1px solid darkcyan;
}
#Group_9_pc_fp {
    position: absolute;
    width: 253.519px;
    height: 37.724px;
    left: 566px;
    top: 554px;
    overflow: visible;
    border-radius: 50px;
    cursor: pointer;
}
#Rectangle_pc_fp {
    fill: rgba(119,221,150,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rectangle_pc_fp {
    filter: drop-shadow(10px 7px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 272.519px;
    height: 55.724px;
    left: 0px;
    top: 0px;
}
#Continue_pc_fp {
    left: 84.722px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 85px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#Dont_have_an_account_Sign_up_pc_fp {
    left: 579px;
    top: 617px;
    position: absolute;
    overflow: visible;
    width: 210px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
    cursor: pointer;
}
#image_forgotPw_pc {
    position: absolute;
    width: 135px;
    height: 135px;
    left: 616px;
    top: 132px;
    overflow: visible;
}
#Group_13_pc_fp {
    position: absolute;
    width: 60px;
    height: 27px;
    left: 793px;
    top: 503px;
    overflow: visible;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: index_page.html;
    cursor: pointer;
}
#Back_pc_fp {
    left: 19px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#back_fp_pc {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 0px;
    top: 6px;
    overflow: visible;
}
