
	.mediaViewInfo {
		--web-view-name: add;
		--web-view-id: add_attendies_pc;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: add;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#add_attendies_pc {
		position: absolute;
		width: 237px;
		height: 200px;
        top: 60%;
        left: 50%;
        transform: translate(-50%,-50%);
		overflow: hidden;
		--web-view-name: add;
		--web-view-id: add_attendies_pc;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#bdr_of_attendies_people {
		fill: rgba(255,255,255,1);
	}
	.bdr_of_attendies_people {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
        overflow: visible;
        width: 235px;
        height: 200px;
        left: 3px;
        top: 3px;
	}
	#Scroll_add_attendies_people {
		mix-blend-mode: normal;
		position: absolute;
		width: 231px;
		height: 165px;
		left: 4px;
		top: 33px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#card_1_grp_attendies_people {
	position: relative;
    width: 205px;
    height: 40px;
    left: 6px;
    top: 2px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid  #ADF5CE;
    overflow: visible;
	}
	#attendies_people_imreanmeeting {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
        border-radius: 50%;
		overflow: hidden;
	}
	#attendies_people_imran_fool_me {
		left: 35px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(76,193,87,1);
	}
	#OP_FSD_Lbl_ {
		left: 35px;
		top: 18px;
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 15px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#close_pop_people {
		position: absolute;
		width: 17px;
		height: 17px;
		left: 214px;
		top: 7px;
        cursor: pointer;
		overflow: visible;
	}
	#Rectangle_699 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 0.5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Rectangle_699 {
		position: absolute;
		overflow: visible;
		width: 166px;
		height: 20px;
		left: 21px;
		top: 8px;
		border: 1px solid #ADF5CE;
		border-radius: 50px;
	}
