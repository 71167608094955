.mediaViewInfo {
    --web-view-name: portfolio off;
    --web-view-id: portfolio_off;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: portfolio_off;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#portfolio_off {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 140px);
    top:75px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: portfolio off;
    --web-view-id: portfolio_off;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#heading_grp_offreqevent_off_mo {
    position: absolute;
    width: 235px;
    height: 28px;
    left: 63px;
    top: 13px;
    overflow: visible;
}
#offreqevent_off_mob_req {
    opacity: 0.92;
    left: 81px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 92px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -7px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#selected_grp_offreqevent_off_m {
    position: absolute;
    width: 61px;
    height: 20px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#offering_offreqevent_off_mob {
    opacity: 0.92;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -7px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,93,24,1);
}
#events_offreqevent_off_mob {
    opacity: 0.92;
    left: 192px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -7px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(48,47,47,1);
}
#underline_offreqevent_off_mob {
    fill: rgba(255,79,4,1);
}
.underline_offreqevent_off_mob {
    position: absolute;
    overflow: visible;
    width: 61px;
    height: 3px;
    left: 0px;
    top: 25px;
}
#settings-sliders_offreqevent_o {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 328px;
    top: 15px;
    overflow: visible;
}