	.mediaViewInfo {
		--web-view-name: calender page meeting mob;
		--web-view-id: calender_page_meeting_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: calender_page_meeting_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#calender_page_meeting_mob {
		position: absolute;
        width: 100%;
        height: calc(100% - 75px);
        top: 75px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: calender page meeting mob;
		--web-view-id: calender_page_meeting_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#calender_page_meeting_mob_all_ {
		position: absolute;
        width: 100%;
        height: calc(100% - 17px);
        left: -26px;
        top: 17px;
		overflow: visible;
	}
	#bdr_meeting_mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(78,187,87,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.bdr_meeting_mob {
		position: absolute;
		overflow: visible;
		width: calc(100% - 20px);
		height: 100%;
		left: 0px;
		top: 0px;
	}
	#right_menu_meeting_mob {
		position: absolute;
		width: 22.5px;
		height: 331.88px;
		right: -15px;
		top: 25.72px;
		overflow: visible;
	}
	#mobAlarms_lbl_meeting {
		transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,332.5,168.2197) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#Tasks_lbl_meet_mob {
		transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,337.5,259.5396) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#Events_lbl_calender_mob {
		transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,333.5,459.1001) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#selected_mob_meeting {
		position: absolute;
		width: 22.5px;
		height: 58.017px;
		left: 0px;
		top: 175.763px;
		overflow: visible;
	}
	#Meetings_lbl_headingcalendermo {
		transform: translate(-340.5px, -332.483px) matrix(1,0,0,1,324.5,351.9829) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(78,187,87,1);
	}
	#mobLine_29_meeting {
		fill: transparent;
		stroke: rgba(78,187,87,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.mobLine_29_meeting {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 58px;
		left: 0px;
		top: 0.017px;
		transform: matrix(1,0,0,1,0,0);
	}
	#meeting_mob_allscroll {
		mix-blend-mode: normal;
		position: absolute;
        width: calc(100% - 45px);
        height: 100%;
        left: 26px;
		display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-content: flex-start;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#mobmeeting_grp_calender_page {
		position: relative;
		width: 249.76px;
		height: 56.8px;
		left: 30.64px;
        margin: 10px;
		top: 0px;
		overflow: visible;
	}
	#mobArcane_Series_lbl_calender {
		left: 8.16px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 240px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#mob1025pm_-_1200am_arcane {
		left: 9.16px;
		top: 23.52px;
		position: absolute;
		overflow: visible;
		width: 121px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#mobarcane_mode_meetincalender {
		fill: rgba(252,148,148,1);
	}
	.mobarcane_mode_meetincalender {
		position: absolute;
		overflow: visible;
		width: 3.36px;
		height: 16.8px;
		left: 0.36px;
		top: 3.36px;
	}
	#mobinacvtice_status_calender {
		fill: rgba(243,46,46,1);
	}
	.mobinacvtice_status_calender {
		position: absolute;
		overflow: visible;
		width: 11.2px;
		height: 11.2px;
		left: 235.561px;
		top: 26.88px;
	}
	#top_line_calendermeetmob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.top_line_calendermeetmob {
		overflow: visible;
		position: absolute;
		width: 249.76px;
		height: 1px;
		left: 0px;
		top: 56.8px;
		transform: matrix(1,0,0,1,0,0);
	}
