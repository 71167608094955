	.mediaViewInfo {
		--web-view-name: calender page event mob;
		--web-view-id: calender_page_event_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: calender_page_event_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#calender_page_event_mob {
		position: absolute;
        width: 100%;
        height: calc(100% - 75px);
        top: 75px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: calender page event mob;
		--web-view-id: calender_page_event_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#calender_page_event_mob_grp {
		position: absolute;
        width: 100%;
        height: calc(100% - 17px);
        left: -26px;
        top: 17px;
		overflow: visible;
	}
	#bdr_calender_page_event_mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(78,187,87,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.bdr_calender_page_event_mob {
		position: absolute;
		overflow: visible;
		width: calc(100% - 20px);
		height: 100%;
		left: 0px;
		top: 0px;
	}
	#menu_right_part_evnt_mob {
		position: absolute;
		width: 22.5px;
		height: 331.88px;
		right: -15px;
		top: 25.72px;
		overflow: visible;
	}
	#Alarms_lbl_calender_mob {
		transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,332.5,168.2197) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#Tasks_lbl_mob {
		transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,337.5,259.5396) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#Meetings_lbl_headingcalender_m {
		transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,324.5,351.9829) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#events_grp_calender_page_event {
		position: absolute;
		width: 22.5px;
		height: 40.1px;
		left: 0px;
		top: 291.78px;
		overflow: visible;
	}
	#Events_lbl_calendermob {
		transform: translate(-340.5px, -448.5px) matrix(1,0,0,1,333.5,459.1001) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(78,187,87,1);
	}
	#Line_29event_mob {
		fill: transparent;
		stroke: rgba(78,187,87,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_29event_mob {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 40px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#calender_page_event_mob_scroll {
		mix-blend-mode: normal;
		position: absolute;
        width: calc(100% - 45px);
        height: 100%;
        left: 26px;
		display: flex;
	    flex-wrap: wrap;
	    flex-direction: row;
	    align-content: flex-start;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#events_page_calender {
		position: relative;
		width: 249.76px;
		height: 52.4px;
		left: 26.64px;
		top: 0px;
        margin: 10px;
		overflow: visible;
	}
	#Dragon_Ball_Super_lbl_mob {
		left: 9.16px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 237px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#KAMEHAMEHA_123 {
		left: 9.16px;
		top: 23.52px;
		position: absolute;
		overflow: visible;
		width: 113px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#dragon_ball_sup_ {
		fill: rgba(175,148,252,1);
	}
	.dragon_ball_sup_ {
		position: absolute;
		overflow: visible;
		width: 3.36px;
		height: 16.8px;
		left: 0.36px;
		top: 3.68px;
	}
	#active_events_calender_mob {
		fill: rgba(46,243,65,1);
	}
	.active_events_calender_mob {
		position: absolute;
		overflow: visible;
		width: 11.2px;
		height: 11.2px;
		left: 234.44px;
		top: 30.56px;
	}
	#arcane_eventline_calender_mob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.arcane_eventline_calender_mob {
		overflow: visible;
		position: absolute;
		width: 249.76px;
		height: 1px;
		left: 0px;
		top: 52.4px;
		transform: matrix(1,0,0,1,0,0);
	}
