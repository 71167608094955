.mediaViewInfo {
    --web-view-name: req feed mob;
    --web-view-id: req_feed_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: req_feed_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#req_feed_mob {
    position: relative;
    width: 100%;
    height: 629px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: req feed mob;
    --web-view-id: req_feed_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#req_feed_mob_ffeed_grp {
    position: absolute;
    width: 100%;
    height: 608px;
    /* left: 12.264px; */
    top: 11px;
    overflow: visible;
}
#req_feed_mobfeed_bdr {
    fill: rgba(255,255,255,1);
}
.req_feed_mobfeed_bdr {
    filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 616px;
    left: 0.736px;
    top: 0px;
}
#req_feed_mob_Feed_lbl {
    left: 11.736px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 136px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#req_feed_mob_feed_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 565.576px;
    left: 0px;
    top: 40px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#card_1_req_feed_mob {
    position: relative;
    width: 50%;
    height: 130px;
    left: 5px;
    top: 58.569px;
    
    display: inline-block;
    overflow: visible;
}
#card_1_req_feed_mob_bf {
    position: absolute;
    width: 175px;
    height: 91.35px;
    left: 0px;
    top: 21px;
    object-fit: contain;
    overflow: hidden;
}
#req_lbl__req_feed_mob {
    top: 113px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,46,72,1);
}
#Mohammed_Affan_lbl_req_feed_mo {
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,46,72,1);
}