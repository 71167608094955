#REQM_card_1_reqoffevent_off {
    position: relative;
    width: 170px;
    height: 204px;
    /* left: 10px; */
    top: 16px;
    display: inline-flex;
    flex-direction: row;
    margin: 10px;
    overflow: visible;
}
#REQM_Base_reqoffevent_off {
    fill: rgba(255,255,255,1);
}
.REQM_Base_reqoffevent_off {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 188px;
    height: 188px;
    left: 0px;
    top: 0px;
}
#REQM_bdr_1_reqoffevent_off {
    fill: rgba(255,79,4,0.09);
}
.REQM_bdr_1_reqoffevent_off {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 22px;
    left: 130px;
    top: 177px;
}
#REQM_reqoffevent_of {
    left: 140px;
    top: 183px;
    position: absolute;
    overflow: visible;
    width: 21px;
    white-space: nowrap;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#REQM_Single_Tour_offevent_off {
    left: 0px;
    top: 177px;
    position: absolute;
    overflow: visible;
    width: 51px;
    white-space: nowrap;
    line-height: 20px;
    margin-top: -5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.3333333206176758px;
}
#REQM_date_reqoffevent_off {
    left: 0px;
    top: 193px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    line-height: 18px;
    margin-top: -5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    color: rgba(17,17,17,1);
}
#REQM_online-shopping_reqoffevent_of {
    position: absolute;
    width: 170px;
    height: 170px;
    /* left: 30px; */
    /* top: 30px; */
    overflow: visible;
}
#REQM_Group_131 {
    position: absolute;
    width: 27px;
    height: 27px;
    left: 2px;
    top: 3px;
    overflow: visible;
}
#REQM_Ellipse_1 {
    fill: rgba(255,255,255,1);
}
.REQM_Ellipse_1 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 45px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#REQM_trophy_reqoffevent_off {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 3px;
    top: 4px;
    overflow: visible;
}
#REQM_Group_130 {
    position: absolute;
    width: 24px;
    height: 15px;
    left: 30px;
    top: 9px;
    overflow: visible;
}
#REQM_Rectangle_31 {
    fill: rgba(255,255,255,1);
}
.REQM_Rectangle_31 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 42px;
    height: 33px;
    left: 0px;
    top: 0px;
}
#REQM_n_234_reqoffevent_off {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 23px;
    white-space: nowrap;
    line-height: 20px;
    margin-top: -5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#REQM_Group_132 {
    position: absolute;
    width: 27px;
    height: 27px;
    left: 141px;
    top: 3px;
    overflow: visible;
}
#REQM_Ellipse_2 {
    fill: rgba(255,255,255,1);
}
.REQM_Ellipse_2 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 45px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#REQM_share_1_reqoffevent_off {
    position: absolute;
    width: 17px;
    height: 17px;
    left: 5px;
    top: 5px;
    overflow: visible;
}