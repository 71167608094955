.mediaViewInfo {
    --web-view-name: web 4 mob;
    --web-view-id: web_4_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: web_4_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#web_4_mob {
    position: absolute;
    width: 375px;
    height: 2306px;
    left:calc((100vw - 375px)/2);
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: web 4 mob;
    --web-view-id: web_4_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#design_main_page_web_4_theme_m {
    fill: rgba(32,142,121,1);
}
.design_main_page_web_4_theme_m {
    overflow: visible;
    position: absolute;
    width: 375px;
    height: 294.785px;
    left: 0px;
    top: 112.803px;
    transform: matrix(1,0,0,1,0,0);
}
#markus-wink_img_centre_med_mob {
    position: absolute;
    width: 208.687px;
    height: 106.072px;
    left: 15.365px;
    top: 260.195px;
    overflow: visible;
}
#follow_us_btn__grp_web_4mob {
    position: absolute;
    width: 76px;
    height: 16px;
    left: 148px;
    top: 350px;
    overflow: visible;
}
#bdr_med_web_4_mob {
    fill: rgba(92,181,164,1);
}
.bdr_med_web_4_mob {
    filter: drop-shadow(3px 3px 2px rgba(223, 223, 223, 1));
    position: absolute;
    overflow: visible;
    width: 82px;
    height: 22px;
    left: 0px;
    top: 0px;
}
#Follow_Us_med_web_4_mob {
    left: 14px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 20px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    color: rgba(255,255,255,1);
}
#arrow_follow_us_med_web_4_mob {
    fill: rgba(255,255,255,1);
}
.arrow_follow_us_med_web_4_mob {
    overflow: visible;
    position: absolute;
    width: 7.615px;
    height: 7.422px;
    left: 54.065px;
    top: 4.271px;
    transform: matrix(1,0,0,1,0,0);
}
#Heading_left_med_web_4_mob {
    left: 23px;
    top: 78px;
    position: absolute;
    overflow: visible;
    width: 141px;
    height: 33px;
    line-height: 73px;
    margin-top: -24px;
    text-align: right;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(0,0,0,1);
}
#Heading_med_web_4mob {
    left: 216px;
    top: 78px;
    position: absolute;
    overflow: visible;
    width: 150px;
    height: 33px;
    line-height: 73px;
    margin-top: -24px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(0,0,0,1);
}
#marijuana_img_bdr_med_web_4_mo {
    position: absolute;
    width: 37px;
    height: 38px;
    left: 169px;
    top: 75px;
    overflow: visible;
}
#Scroll_intro_web_4_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 1211px;
    height: 89px;
    left: 0px;
    top: 133px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Lorem_ipsum_dolor_sit_amet_hh_mob {
    left: 6px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 262px;
    height: 180px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#text_our_partner_bdr_med_web_4 {
    transform: matrix(1,0,0,1,266,263) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 181px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(255,255,255,1);
    letter-spacing: 0.1px;
}
#partner_scroll_web_4 {
    mix-blend-mode: normal;
    position: absolute;
    width: 216px;
    height: 213px;
    left: 304px;
    top: 184px;
    overflow-x: hidden;
    overflow-y: auto;
}
#g_and_google_grp_mob {
    position: relative;
    width: 20px;
    height: 175px;
    left: 10px;
    top: 0px;
    overflow: visible;
}
#google_lbl_partnermed_web_4_mo {
    transform: translate(-1189px, -274px) matrix(1,0,0,1,1126.5,366.5) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 146px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(233,255,251,1);
}
#google_1img_med_web_4_mob {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Scroll_ll_misvispri_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 377px;
    height: 368px;
    /* left: 34px; */
    top: 433px;
    overflow-x: scroll;
    overflow-y: hidden;
}
#mission_all_med_web_4mob {
    position: absolute;
    width: 189px;
    height: 299px;
    left: 19px;
    top: 20px;
    overflow: visible;
}
#bdr_mission_med_web_4mob {
    fill: rgba(166,210,202,0.651);
}
.bdr_mission_med_web_4mob {
    position: absolute;
    overflow: visible;
    width: 189px;
    height: 299px;
    left: 0px;
    top: 0px;
}
#box_1_website_med_web_4_mob {
    position: absolute;
    width: 52px;
    height: 45px;
    left: 73px;
    top: 44px;
    overflow: visible;
}
#mission_lbl_med_web_4_mob {
    left: 64px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 66px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0,0,0,1);
}
#Scroll_mission_med_web_4_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 182px;
    height: 193px;
    left: 4px;
    top: 103px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Scroll_mission_med_web_4_mob::-webkit-scrollbar{
    display: none;
}
#Lorem_ipsum_dolor_sit_amet_con_mob {
    left: 3px;
    top: 8px;
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#vision_grp_all_med_web_4_mob {
    position: absolute;
    width: 189px;
    height: 299px;
    left: 229px;
    top: 20px;
    overflow: visible;
}
#bdr_vision_back_med_web_4mob {
    fill: rgba(135,207,172,0.647);
}
.bdr_vision_back_med_web_4mob {
    position: absolute;
    overflow: visible;
    width: 189px;
    height: 299px;
    left: 0px;
    top: 0px;
}
#box_2_website_1_med_web_4_mob {
    position: absolute;
    width: 54px;
    height: 45px;
    left: 69px;
    top: 44px;
    overflow: visible;
}
#vision_lbl_med_web_4_mob {
    left: 67px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0,0,0,1);
}
#Scroll_vision_med_web_4_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 182px;
    height: 193px;
    left: 4px;
    top: 103px;
    overflow-x: hidden;
    overflow-y: scroll;
}

#Scroll_vision_med_web_4_mob::-webkit-scrollbar{
    display: none;
}

#Lorem_ipsum_dolor_sit_amet_con_cl {

    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#principle_grp_all_med_web_4_mo {
    position: absolute;
    width: 189px;
    height: 299px;
    left: 438px;
    top: 20px;
    overflow: visible;
}
#princi_bdr_akll_med_web_4_mob {
    fill: rgba(151,135,207,0.647);
}
.princi_bdr_akll_med_web_4_mob {
    position: absolute;
    overflow: visible;
    width: 189px;
    height: 299px;
    left: 0px;
    top: 0px;
}
#box_3_off_med_web_4_mob_op_liv {
    position: absolute;
    width: 54px;
    height: 45px;
    left: 68px;
    top: 44px;
    overflow: visible;
}
#princi_med_web_4_mob_lbl {
    left: 51px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0,0,0,1);
}
#Scroll_prrinci_details_med_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 182px;
    height: 193px;
    left: 4px;
    top: 103px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Scroll_prrinci_details_med_mob::-webkit-scrollbar{
    display: none;
}
#Lorem_ipsum_dolor_sit_amet_con_cr {
    left: 8px;
    top: 8px;
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#oofering_lbl_med_web_4_mob {
    left: 60px;
    top: 811px;
    position: absolute;
    overflow: visible;
    width: 179px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(0,0,0,1);
}
#Scroll_offering_med_web_4_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 1152px;
    height: 374px;
    left: 34px;
    top: 881px;
    display: -webkit-box;
    overflow-x: auto;
    overflow-y: hidden;
}
#Scroll_offering_med_web_4_mob::-webkit-scrollbar{
    display: none;
}
#offering_crd_1_grp_med_web_4_m {
    position: relative;
    width: 200px;
    height: 332px;
    left: 15px;
    top: 11px;
    overflow: visible;
}
#title_bdr_med_web_4_mob {
    fill: rgba(255,255,255,1);
}
.title_bdr_med_web_4_mob {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 50px;
    left: 14px;
    top: 300px;
}
#BiziBees_PVT_LTD_med_web_4_mob {
    left: 14px;
    top: 306px;
    position: absolute;
    overflow: visible;
    width: 173px;
    height: 20px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#img_border_med_web_4_mob_of {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#chuttersna_med_web_4_mob_img {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 14px;
    top: 7px;
    overflow: visible;
}
#map_grp_mob_web_4 {
    position: absolute;
    width: 375px;
    height: 376px;
    left: 0px;
    top: 1293px;
    overflow: visible;
}
#map_area_med_web_4_mob {
    fill: rgba(255,255,255,1);
}
.map_area_med_web_4_mob {
    position: absolute;
    overflow: visible;
    width: 375px;
    height: 330px;
    left: 0px;
    top: 23px;
}
#map_top_med_web_4_mob {
    fill: rgba(32,142,121,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.map_top_med_web_4_mob {
    overflow: visible;
    position: absolute;
    width: 374.902px;
    height: 23px;
    left: 0.098px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#map_below_desion_med_web_4_mob {
    fill: rgba(32,142,121,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.map_below_desion_med_web_4_mob {
    overflow: visible;
    position: absolute;
    width: 374.902px;
    height: 23px;
    left: 0.098px;
    top: 353px;
    transform: matrix(1,0,0,1,0,0);
}
#medincine_bdr_web_4_mob {
    fill: rgba(32,142,121,1);
}
.medincine_bdr_web_4_mob {
    position: absolute;
    overflow: visible;
    width: 375px;
    height: 55px;
    left: 0px;
    top: 1749px;
}
#Medicine_is_not_only_a_science_mob {
    left: 11px;
    top: 1763px;
    position: absolute;
    overflow: visible;
    width: 2383px;
    height: 28px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#footer_med_web_4_mob {
    fill: rgba(149,213,182,1);
}
.footer_med_web_4_mob {
    position: absolute;
    overflow: visible;
    width: 375px;
    height: 500px;
    left: 0px;
    top: 1804px;
}
#n_234567890_mobile_number_mob {
    left: 60px;
    top: 1834px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#affanghanioperatelive_email_mob {
    left: 60px;
    top: 1888px;
    position: absolute;
    overflow: visible;
    width: 167px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#address_med_web_4_mob {
    left: 60px;
    top: 1942px;
    position: absolute;
    overflow: visible;
    width: 280px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#location_img_med_web_4_mob {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 16px;
    top: 1936px;
    overflow: visible;
}
#email_address_med_web_4_mob {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 16px;
    top: 1882px;
    overflow: visible;
}
#phone_number_med_web_4_mob {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 16px;
    top: 1828px;
    overflow: visible;
}
#Connect_with_us_and_unlock_mob {
    left: 53px;
    top: 2128px;
    position: absolute;
    overflow: visible;
    width: 271px;
    height: 60px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#socials_grp_med_web_4_mob {
    position: absolute;
    width: 210px;
    height: 50.001px;
    left: 76px;
    top: 2036px;
    overflow: visible;
}
#linked_in_grp_med_web_4_mob {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 160px;
    top: 0px;
    overflow: visible;
}
#Ellipse_5_med_web_4_pc:hover {
    fill: rgba(120,120,255,1);
}
.Ellipse_5_med_web_4_pc:hover {
    filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
    width: 98px;
    height: 98px;
}
#Ellipse_5_med_web_4_pc {
    fill: rgba(255,255,255,1);
    transition: all .3s ease-out;
}
.Ellipse_5_med_web_4_pc {
    filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 59px;
    height: 59px;
    left: 0px;
    top: 0px;
    transition: all .3s ease-out;
}
#linked_in_website_4_layout_mob {
    position: absolute;
    width: 24.349px;
    height: 22.135px;
    left: 12.5px;
    top: 12.5px;
    overflow: visible;
}
#n_website_2_layout {
    fill: rgba(63,61,86,1);
}
.n_website_2_layout {
    overflow: visible;
    position: absolute;
    width: 16.092px;
    height: 15.182px;
    left: 8.257px;
    top: 6.953px;
    transform: matrix(1,0,0,1,0,0);
}
#linked_in_i_down {
    fill: rgba(63,61,86,1);
}
.linked_in_i_down {
    overflow: visible;
    position: absolute;
    width: 4.746px;
    height: 15.037px;
    left: 0.614px;
    top: 7.098px;
    transform: matrix(1,0,0,1,0,0);
}
#top_dot_i_website_2_layout {
    fill: rgba(63,61,86,1);
}
.top_dot_i_website_2_layout {
    overflow: visible;
    position: absolute;
    width: 5.761px;
    height: 5.217px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Insta_grp_med_web_4_mob {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 80px;
    top: 0px;
    overflow: visible;
}
#Ellipse_4_med_web_4_pc:hover {
    fill: rgba(255,120,120,1);
}
.Ellipse_4_med_web_4_pc:hover {
    filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
    width: 98px;
    height: 98px;
}
#Ellipse_4_med_web_4_pc {
    fill: rgba(255,255,255,1);
    transition: all .3s ease-out;
}
.Ellipse_4_med_web_4_pc {
    filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 59px;
    height: 59px;
    left: 0px;
    top: 0px;
    transition: all .3s ease-out;
}
#instagram_website_4_layout_mob {
    position: absolute;
    width: 24.375px;
    height: 24.375px;
    left: 13.125px;
    top: 13.125px;
    overflow: visible;
}
#whatsapp_grp_med_web_4_mob {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Ellipse_3_med_web_4_pc:hover {
    fill: rgba(50,172,158,1);
}
.Ellipse_3_med_web_4_pc:hover {
    filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
    width: 98px;
    height: 98px;
}
#Ellipse_3_med_web_4_pc {
    fill: rgba(255,255,255,1);
    transition: all .3s ease-out;
}
.Ellipse_3_med_web_4_pc {
    filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 59px;
    height: 59px;
    left: 0px;
    top: 0px;
    transition: all .3s ease-out;
}
#whatsapp_lbl_website4_layout_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 12.5px;
    top: 12.5px;
    overflow: visible;
}
#quote_left_med_web_4_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 15px;
    top: 2127px;
    overflow: visible;
}
#quote_right_med_web_4_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 112px;
    top: 2168px;
    overflow: visible;
}
#n_Copyright_Operate_Live_med_mob {
    left: 16px;
    top: 2215.25px;
    position: absolute;
    overflow: visible;
    width: 318px;
    white-space: nowrap;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#profile_pic_logo_web_4_mob {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 163px;
    top: 9px;
    border-radius: 50%;
    overflow: hidden;
}

  /* Menu */
  #menuToggle
  {
  display: block;
  position: fixed;
  width: 40px;
  height: 40px;
  left: 34px;
  top: 25px;
  
  z-index: 1;
  
  -webkit-user-select: none;
  user-select: none;
  }
  
  #menuToggle li
  {
  text-decoration: none;
  color: #232323;
  
  transition: color 0.3s ease;
  }
  
  #menuToggle li:hover
  {
  color: #ff4f4f;
  }
  
  
  #menuToggle input
  {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
  }
  
  /*
  * Just a quick hamburger
  */
  #menuToggle span
  {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: #6495ED;
  border-radius: 3px;
  
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  background: 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  opacity: 0.55s ease;
  }
  
  #menuToggle span:first-child
  {
  transform-origin: 0% 0%;
  }
  
  #menuToggle span:nth-last-child(2)
  {
  transform-origin: 0% 100%;
  }
  
  /* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
  #menuToggle input:checked ~ span
  {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: rgba(255,75,83,1);
  }
  
  /*
  * But let's hide the middle one.
  */
  #menuToggle input:checked ~ span:nth-last-child(3)
  {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
  }
  
  /*
  * Ohyeah and the last one should go the other direction
  */
  #menuToggle input:checked ~ span:nth-last-child(2)
  {
  transform: rotate(-45deg) translate(0, -1px);
  }
  
  /*
  * Make this absolute positioned
  * at the top left of the screen
  */
  #menu
  {
  position: absolute;
  width: 230px;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  height: 100vh;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 10px );
  -webkit-backdrop-filter: blur( 10px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  }
  
  #menu li
  {
  padding: 10px 0;
  font-size: 22px;
  }
  #menuToggle input:checked ~ ul
  {
  transform: none;
  }
  