	.mediaViewInfo {
		--web-view-name: offering page pc new;
		--web-view-id: offering_page_pc_new;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: offering_page_pc_new;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#offering_page_pc_new {
		position: absolute;
        width: calc(100vw - 120px);
        height: calc(100vh - 60px);
        top:60px;
        left:120px;
		background-color: rgba(255,255,255,1);
		overflow-x: hidden;
		overflow-y: scroll;
		--web-view-name: offering page pc new;
		--web-view-id: offering_page_pc_new;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#heading_grp_offreqevent_off_PC {
		position: absolute;
		width: 100%;
		height: 42px;
		left: calc(50% - 170px);
		top: 22px;
		overflow: visible;
	}
	#req_offreqevent_off_PC {
		opacity: 0.92;
		left: 117px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 110px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		cursor: pointer;
		color: rgba(48,47,47,1);
	}
	#selected_grp_offreqevent_off_P {
		position: absolute;
		width: 77px;
		height: 42px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#offeringoffreqevent_off_PC {
		opacity: 0.92;
		left: 2px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		cursor: pointer;
		color: rgba(255,79,4,1);
	}
	#underline_offreqevent_off_PC {
		fill: rgba(255,79,4,1);
	}
	.underline_offreqevent_off_PC {
		position: absolute;
		overflow: visible;
		width: 77px;
		height: 3px;
		left: 0px;
		top: 39px;
	}
	#A_transformative_offreqevent_o {
		opacity: 0.92;
		left: 269px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 52px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		cursor: pointer;
		color: rgba(48,47,47,1);
	}
	#filters_area_new_events_page_gz {
		position: absolute;
		width: 555px;
		height: 30px;
		left: calc((100% - 400px)/2);
		top: 81px;
		overflow: visible;
	}
	#bdr_new_events_page_pc_g {
		fill: rgba(173,245,206,1);
	}
	.bdr_new_events_page_pc_g {
		position: absolute;
		overflow: visible;
		width: 130px;
		height: 30px;
		left: 425px;
		top: 0px;
	}
	#Find_Event_new_events_page_pc_g {
	left: 425px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 130px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -6px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(4,4,4,1);
	}
	#category_grp_pc_g {
		position: absolute;
		width: 209px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#category_input_new_events_page_g {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.category_input_new_events_page_g {
		position: absolute;
		overflow: visible;
		width: 130px;
		height: 30px;
		left: 79px;
		top: 0px;
		border: 1px solid #ADF5CE;
		border-radius: 5px;
	}
	#Category_new_events_page_pc_g {
		left: 0px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 70px;
		white-space: nowrap;
		line-height: 23px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#time_cate_gory_pc_g {
		position: absolute;
		width: 172px;
		height: 30px;
		left: 227px;
		top: 0px;
		overflow: visible;
	}
	#time_bdr_filter_new_events_pag_g {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.time_bdr_filter_new_events_pag_g {
		position: absolute;
		overflow: visible;
		width: 130px;
		height: 30px;
		left: 42px;
		top: 0px;
		border: 1px solid #ADF5CE;
		border-radius: 5px;
	}
	#Time_new_events_page_g {
		left: 0px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		line-height: 23px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#swtitch_grp_event_g {
		position: absolute;
		width: 277px;
		height: 21px;
		left: 6%;
		top: 85px;
		overflow: visible;
	}
	#Myevents_PC_g {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 27px;
		margin-top: -5.5px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(17,17,17,1);
	}
	#Community_eventsPC_ha {
		left: 145px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 133px;
		white-space: nowrap;
		line-height: 27px;
		margin-top: -5.5px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(17,17,17,1);
	}
	#switch_eent_off_PC_hb {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.switch_eent_off_PC_hb {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 17px;
		left: 90px;
		top: -8px;
	}