	.mediaViewInfo {
		--web-view-name: Journey Junkies Mob;
		--web-view-id: Journey_Junkies_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Journey_Junkies_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Journey_Junkies_Mob {
		position: absolute;
		width: 375px;
		height: 4645px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(249,249,249,0.886);
		overflow: hidden;
		--web-view-name: Journey Junkies Mob;
		--web-view-id: Journey_Junkies_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#JJ_Mb_Rectangle_682_cb {
		fill: url(#Rectangle_682_cb);
	}
	.JJ_Mb_Rectangle_682_cb {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 786px;
		left: 0px;
		top: 0px;
	}
	#JJ_Mb_leaf {
		position: absolute;
		width: 314.239px;
		height: 314.239px;
		left: 217.881px;
		top: 69.505px;
		overflow: visible;
	}
	#JJ_Mb_explore_Unwind_Discover {
		filter: drop-shadow(0px 16px 21px rgba(0, 0, 0, 0.161));
		left: 27px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 162px;
		white-space: nowrap;
		text-align: left;
		font-family: Square721 BT;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#JJ_Mb_Group_14874 {
		position: absolute;
		width: 458.157px;
		height: 460.538px;
		left: -42px;
		top: 153.475px;
		overflow: visible;
	}
	#JJ_Mb_path_3 {
		opacity: 0.19;
		fill: rgba(55,143,50,1);
	}
	.JJ_Mb_path_3 {
		overflow: visible;
		position: absolute;
		width: 334.237px;
		height: 323.476px;
		transform: translate(-7px, -29.475px) matrix(1,0,0,1,68.9606,98.0055) rotate(54deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#JJ_Mb_path_2 {
		opacity: 0.3;
		fill: rgba(55,143,50,1);
	}
	.JJ_Mb_path_2 {
		overflow: visible;
		position: absolute;
		width: 334.237px;
		height: 323.476px;
		transform: translate(-7px, -29.475px) matrix(1,0,0,1,68.9604,98.0055) rotate(15deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#JJ_Mb_path_1 {
		position: absolute;
		width: 323.86px;
		height: 313.433px;
		left: 66.978px;
		top: 73.302px;
		overflow: visible;
	}
	#JJ_Mb_Group_14875 {
		position: absolute;
		width: 334.016px;
		height: 254.938px;
		left: 27px;
		top: 625.407px;
		overflow: visible;
	}
	#JJ_Mb_Scroll_Group_19 {
		mix-blend-mode: normal;
		position: absolute;
		width: 320px;
		height: 175.57px;
		left: 14.016px;
		top: 0.875px;
		overflow: hidden;
	}
	#JJ_Mb_Lorem_ipsum_dolor_sit_amet_con {
		left: 14px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(125,125,125,1);
	}
	#JJ_Mb_Line_1 {
		fill: transparent;
		stroke: rgba(13,146,6,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.JJ_Mb_Line_1 {
		overflow: visible;
		position: absolute;
		width: 4px;
		height: 254.938px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#JJ_Mb_Group_14876 {
		position: absolute;
		width: 713px;
		height: 120px;
		left: 27px;
		top: 936px;
		overflow: visible;
	}
	#JJ_Mb_joaquin-3VK6Urf2vE8-unsplash {
		filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 231px;
		height: 120px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_ammie-ngo-vcu-OZBxxRk-unsplash {
		filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 231px;
		height: 120px;
		left: 241px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_daniel-burka-facU72FcKBI-unspl {
		filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 231px;
		height: 120px;
		left: 482px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_Group_4 {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 496.754px;
		top: 62px;
		overflow: visible;
	}
	#JJ_Mb_Ellipse_2 {
		opacity: 0.79;
		fill: rgba(255,255,255,1);
	}
	.JJ_Mb_Ellipse_2 {
		filter: drop-shadow(5px 0px 7px rgba(0, 0, 0, 0.529));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#JJ_Mb_Icon_feather-arrow-down-right {
		transform: translate(0.411px, -0.412px) matrix(1,0,0,1,10.1247,16.2582) rotate(-45deg);
		transform-origin: center;
		position: absolute;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_Path_2_cw {
		fill: transparent;
		stroke: rgba(13,146,6,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.JJ_Mb_Path_2_cw {
		overflow: visible;
		position: absolute;
		width: 9.899px;
		height: 9.9px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#JJ_Mb_Group_3 {
		position: absolute;
		width: 128px;
		height: 38px;
		left: 357px;
		top: 62px;
		overflow: visible;
	}
	#JJ_Mb_Rectangle_3 {
		opacity: 0.79;
		fill: rgba(255,255,255,1);
	}
	.JJ_Mb_Rectangle_3 {
		filter: drop-shadow(5px 0px 7px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 149px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#JJ_Mb_See_all_photos {
		left: 16px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(13,146,6,1);
	}
	#JJ_Mb_Group_14870 {
		position: absolute;
		width: 305px;
		height: 555px;
		left: 35px;
		top: 1135px;
		overflow: visible;
	}
	#JJ_Mb_Rectangle_683 {
		fill: rgba(255,255,255,1);
	}
	.JJ_Mb_Rectangle_683 {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 323px;
		height: 456px;
		left: 0px;
		top: 117px;
	}
	#JJ_Mb_pietro-de-grandi-T7K4aEPoGGk-u {
		position: absolute;
		width: 206px;
		height: 278px;
		left: 49px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_VISION {
		left: 48px;
		top: 313px;
		position: absolute;
		overflow: visible;
		width: 62px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#JJ_Mb_Lorem_ipsum_dolor_sit_amet_con_c {
		left: 48px;
		top: 354px;
		position: absolute;
		overflow-x: hidden;
		overflow-y: scroll;
		width: 234px;
		height: 181px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#JJ_Mb_Group_14877 {
		position: absolute;
		width: 305px;
		height: 555px;
		left: 35px;
		top: 1780px;
		overflow: visible;
	}
	#JJ_Mb_Rectangle_683_c {
		fill: rgba(255,255,255,1);
	}
	.JJ_Mb_Rectangle_683_c {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 323px;
		height: 456px;
		left: 0px;
		top: 117px;
	}
	#JJ_Mb_jake-blucker-tMzCrBkM99Y-unspl {
		position: absolute;
		width: 206px;
		height: 278px;
		left: 49px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_MISSION {
		left: 48px;
		top: 313px;
		position: absolute;
		overflow: visible;
		width: 78px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#JJ_Mb_Lorem_ipsum_dolor_sit_amet_con_da {
		left: 48px;
		top: 354px;
		position: absolute;
		overflow-x: hidden;
		overflow-y: scroll;
		width: 234px;
		height: 181px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#JJ_Mb_Group_14878 {
		position: absolute;
		width: 305px;
		height: 555px;
		left: 35px;
		top: 2425px;
		overflow: visible;
	}
	#JJ_Mb_Rectangle_683_db {
		fill: rgba(255,255,255,1);
	}
	.JJ_Mb_Rectangle_683_db {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 323px;
		height: 456px;
		left: 0px;
		top: 117px;
	}
	#JJ_Mb_tom-barrett-M0AWNxnLaMw-unspla {
		position: absolute;
		width: 206px;
		height: 278px;
		left: 49px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_PRINCIPLES {
		left: 48px;
		top: 313px;
		position: absolute;
		overflow: visible;
		width: 101px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#JJ_Mb_Lorem_ipsum_dolor_sit_amet_con_de {
		left: 48px;
		top: 354px;
		position: absolute;
		overflow-x: hidden;
		overflow-y: scroll;
		width: 234px;
		height: 181px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#JJ_Mb_FIND_POPULAR__DESTINATION_ {
		left: 71px;
		top: 3080px;
		position: absolute;
		overflow: visible;
		width: 235px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}

	
	#JJ_Mb_Group_14871 {
		position: absolute;
		width: 1366px;
		height: 428px;
		left: 0px;
		top: 3233px;
		overflow: visible;
	}
	#JJ_Mb_taneli-lahtinen-2gJJrH4OnNk-un {
		position: absolute;
		width: 1366px;
		height: 428px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_Scroll_Group_30 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 316px;
		/* left: 23px; */
		top: 56px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#JJ_Mb_Group_14872 {
		position: relative;
		width: 198px;
		height: 278px;
		left: 12px;
		top: 19px;
		margin-right:50px;
		overflow: visible;
	}
	#JJ_Mb_Rectangle_688 {
		fill: rgba(255,255,255,1);
	}
	.JJ_Mb_Rectangle_688 {
		position: absolute;
		overflow: visible;
		width: 198px;
		height: 278px;
		left: 0px;
		top: 0px;
	}
	#JJ_Mb_dariusz-sankowski-3OiYMgDKJ6k- {
		position: absolute;
		width: 174px;
		height: 235px;
		left: 12px;
		top: 34px;
		overflow: visible;
	}
	#JJ_Mb_Travel_One {
		left: 12px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 176px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#JJ_Mb_Group_14879 {
		position: absolute;
		width: 479px;
		height: 180px;
		left: 19px;
		top: 3883px;
		overflow: visible;
	}
	#JJ_Mb_n_234567890_mobile_number_shad {
		left: 35px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#JJ_Mb_affanghanioperatelive_email_sh {
		left: 35px;
		top: 61px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#JJ_Mb_address_med_shadow_space_websi {
		left: 35px;
		top: 120px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#JJ_Mb_location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 120px;
		overflow: visible;
	}
	#JJ_Mb_email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 60px;
		overflow: visible;
	}
	#JJ_Mb_phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_Group_14873 {
		position: absolute;
		width: 340px;
		height: 379px;
		left: 18px;
		top: 4175px;
		overflow: visible;
	}
	#JJ_Mb_kisspng-billboard-advertising- {
		position: absolute;
		width: 340px;
		height: 379px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_Rectangle_689 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.JJ_Mb_Rectangle_689 {
		position: absolute;
		overflow: visible;
		width: 329px;
		height: 153px;
		left: 6px;
		top: 23px;
	}
	#JJ_Mb_Line_28 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.JJ_Mb_Line_28 {
		overflow: visible;
		position: absolute;
		width: 340px;
		height: 2px;
		left: 18.5px;
		top: 3859.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#JJ_Mb_n_Copyright_Operate_Live_OPC_P {
		left: 51px;
		top: 4583px;
		position: absolute;
		overflow: visible;
		width: 293px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#JJ_Mb_socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 94px;
		top: 4088px;
		overflow: visible;
	}
	#JJ_Mb_linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_med_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.JJ_Mb_med_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#JJ_Mb_med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.JJ_Mb_med_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#JJ_Mb_linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.191px;
		overflow: visible;
	}
	#JJ_Mb_n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.JJ_Mb_n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#JJ_Mb_linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.JJ_Mb_linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#JJ_Mb_top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.JJ_Mb_top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#JJ_Mb_Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_shadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.JJ_Mb_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#JJ_Mb_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.JJ_Mb_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#JJ_Mb_instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#JJ_Mb_whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_med_shadow_space_website_7_eb:hover {
		fill: rgba(50,172,158,1);
	}
	.JJ_Mb_med_shadow_space_website_7_eb:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#JJ_Mb_med_shadow_space_website_7_eb {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.JJ_Mb_med_shadow_space_website_7_eb {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#JJ_Mb_whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}