.mediaViewInfo {
    --web-view-name: Myoffering;
    --web-view-id: Myoffering;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: Myoffering;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

#Myoffering_1_pc {
    position: relative;
    overflow: visible;
    width: 391px;
    height: 231px;
    left: 10px;
    top: 10px;
}

#card_border_1_pc {
    fill: rgba(255,255,255,1);
}
.card_border_1_pc {
    position: absolute;
    overflow: visible;
    width: 391px;
    height: 231px;
    left: 27px;
    top: 77px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
#titleBorder_pc {
    fill: rgba(219,242,231,1);
}
.titleBorder_pc {
    position: absolute;
    overflow: visible;
    width: 280px;
    height: 30px;
    left: 120px;
    top: 111px;
}
#Website_Development_title_Pc {
    left: 128px;
    top: 115px;
    position: absolute;
    overflow: visible;
    width: 178px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Budget_lbl_Pc {
    left: 40px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Duration_title_PC {
    left: 40px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 126px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Location_title {
    left: 40px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 124px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#currency_value_pc {
    left: 244px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#delivery_duration_pc {
    left: 244px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#delivery_location_Pc {
    left: 244px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 130px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Group_10_border_view:hover {
    left: 303px;
    top: 262px;
}
#Group_10_border_view {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 300px;
    top: 259px;
    z-index: 1;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_view_moreBtn {
    fill: rgba(219,242,231,1);
  
}
.border_view_moreBtn {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
   
}
#View_more_btn_Pc {
    left: 5px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Rectangle_19_ {
    fill: rgba(255,255,255,1);
}
.Rectangle_19_ {
    position: absolute;
    overflow: visible;
    width: 391px;
    height: 231px;
    left: 428px;
    top: 77px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
#Budget_ {
    left: 441px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Duration_ {
    left: 441px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 126px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Location_ {
    left: 441px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 124px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#n_000_ {
    left: 645px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#n_4_HR_ba {
    left: 645px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Online_Deployment_bb {
    left: 645px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 130px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Group_8:hover {
    left: 443px;
    top: 262px;
}
#Group_8 {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 439px;
    top: 259px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Rectangle_9_bg {
    fill: rgba(219,242,231,1);
}
.Rectangle_9_bg {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#Bargains {
    left: 13px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_9:hover {
    left: 549px;
    top: 262px;
}
#Group_9 {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 546px;
    top: 259px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Rectangle_10_bm {
    fill: rgba(219,242,231,1);
}
.Rectangle_10_bm {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#Disable_bn {
    left: 18px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 56px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_10_bo:hover {
    left: 704px;
    top: 262px;
}
#Group_10_bo {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 701px;
    top: 259px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Rectangle_10_bs {
    fill: rgba(219,242,231,1);
}
.Rectangle_10_bs {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#View_more_bt {
    left: 5px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Rectangle_19_bu {
    fill: rgba(255,255,255,1);
}
.Rectangle_19_bu {
    position: absolute;
    overflow: visible;
    width: 391px;
    height: 231px;
    left: 829px;
    top: 77px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
#Budget_bv {
    left: 842px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Duration_bw {
    left: 842px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 126px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Location_bx {
    left: 842px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 124px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#n_000_by {
    left: 1046px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#n_4_HR_bz {
    left: 1046px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Online_Deployment_b {
    left: 1046px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 130px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Group_8_b:hover {
    left: 843px;
    top: 262px;
}
#Group_8_b {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 840px;
    top: 259px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Rectangle_9_ca {
    fill: rgba(219,242,231,1);
}
.Rectangle_9_ca {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#Bargains_b {
    left: 13px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_9_b:hover {
    left: 950px;
    top: 262px;
}
#Group_9_b {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 947px;
    top: 259px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Rectangle_10_cb {
    fill: rgba(219,242,231,1);
}
.Rectangle_10_cb {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#Disable_cc {
    left: 18px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 56px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_10_cd:hover {
    left: 1108px;
    top: 262px;
}
#Group_10_cd {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 1105px;
    top: 259px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Rectangle_10_ch {
    fill: rgba(219,242,231,1);
}
.Rectangle_10_ch {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#View_more_ci {
    left: 5px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}

#Group_8_ck_border_view:hover {
    left: 41px;
    top: 262px;
}
#Group_8_ck_border_view {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 38px;
    top: 259px;
    overflow: visible;
    z-index: 1;
    cursor: pointer;
    transition: all .3s ease-out;
}
#border_view_bargainBtn {
    fill: rgba(219,242,231,1);
}
.border_view_bargainBtn {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
    cursor: pointer;
}
#Bargains_cp_btn_pc {
    left: 13px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#coding_profile_pic_pc {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 40px;
    top: 91px;
    overflow: visible;
}
#app-development {
    position: absolute;
    width: 65px;
    height: 65px;
    left: 441px;
    top: 91px;
    overflow: visible;
}
#profile {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 842px;
    top: 91px;
    overflow: visible;
}
#Group_41_border_disable:hover {
    left: 148px;
    top: 262px;
}
#Group_41_border_disable {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 145px;
    top: 259px;
    z-index: 1;
    overflow: visible;
    cursor: pointer;
    transition: all .3s ease-out;
}
#border_view_disableBtn {
    fill: rgba(219,242,231,1);
}
.border_view_disableBtn {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
    cursor: pointer;
}
#Disable_dr_btn_pc {
    left: 18px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 56px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    cursor: pointer;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_43_border_lbl {
    position: absolute;
    width: 135px;
    height: 30px;
    right: 10px;
    top: 33px;
    overflow: visible;
    cursor: pointer;
}
#Add_Offerings_lbl_pc {
    left: 36px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(37,37,37,1);
}
#add_lbl_pc {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

.BargainModalDesign{

    background-color: white;
    margin: auto;
    position: relative;
    min-width: 100%;
    max-width: auto;
    height: auto;
}

.titleBargains{
    font-family: 'Times New Roman', Times, serif;
  text-align: center;
   color:black;
   background-color:#DBF2E7;
    }

    @keyframes fadeInRight {
        from {
          opacity: 0;
          transform: translate3d(100%, 0, 0);
        }
      
        to {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
      
      .fadeInRight {
        animation-name: fadeInRight;
      }