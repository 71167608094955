
	.mediaViewInfo {
		--web-view-name: PureFabrica mob;
		--web-view-id: PureFabrica_mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: PureFabrica_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#PureFabrica_mob {
		position: absolute;
		width: 375px;
		height: 3674px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: PureFabrica mob;
		--web-view-id: PureFabrica_mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#PFP_36_MoBGroup_15132 {
		position: absolute;
		width: 358px;
		height: 600.096px;
		left: 8px;
		top: 3064px;
		overflow: visible;
	}
	#PFP_36_MoBGroup_15050 {
		position: absolute;
		width: 358px;
		height: 600.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 67px;
		top: 479.096px;
		overflow: visible;
	}
	#PFP_36_MoBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PFP_36_MoBmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_MoBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#PFP_36_MoBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.PFP_36_MoBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PFP_36_MoBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.PFP_36_MoBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PFP_36_MoBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.PFP_36_MoBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PFP_36_MoBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PFP_36_MoBshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_MoBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#PFP_36_MoBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBmed_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.PFP_36_MoBmed_shadow_space_website_7_ {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_MoBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#PFP_36_MoBn_234567890_mobile_number_shad {
		left: 35px;
		top: 292.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 339.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBaddress_med_shadow_space_websi {
		left: 35px;
		top: 389.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 391.096px;
		overflow: visible;
	}
	#PFP_36_MoBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 338.096px;
		overflow: visible;
	}
	#PFP_36_MoBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 285.096px;
		overflow: visible;
	}
	#PFP_36_MoBn_Copyright_Operate_Live_OPC_P {
		left: 17px;
		top: 564.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBContact_Us {
		left: 117.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: left;
		font-family: Microsoft PhagsPa;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#PFP_36_MoBRectangle_757 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PFP_36_MoBRectangle_757 {
		position: absolute;
		overflow: visible;
		width: 274px;
		height: 183px;
		left: 41.5px;
		top: 56px;
	}
	#PFP_36_MoBGroup_15117 {
		position: absolute;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBplanetcare-23coWmkTNSg-unsplas {
		position: absolute;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBRectangle_748 {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
	}
	.PFP_36_MoBRectangle_748 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_MoBAnime_Edit {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 20px;
		top: 38px;
        border-radius: 50%;
		overflow: hidden;
	}
	#PFP_36_MoBYour_Business_Name {
		left: 91px;
		top: 55px;
		position: absolute;
		overflow: visible;
		width: 285px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PFP_36_MoBDiscover_a_World_of_Possibilit {
		left: 17px;
		top: 214px;
		position: absolute;
		overflow: visible;
		width: 343px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PFP_36_MoBGroup_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 20px;
		top: 439px;
		overflow: visible;
	}
	#PFP_36_MoBRectangle_3 {
		fill: rgba(105,139,153,1);
	}
	.PFP_36_MoBRectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_MoBAvailable_Now {
		left: 37px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#PFP_36_MoBGroup_15170 {
		position: absolute;
		width: 375px;
		height: 349px;
		left: 0px;
		top: 2615px;
		overflow: visible;
	}
	#PFP_36_MoBScroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 311px;
		left: 0px;
		top: 38px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#PFP_36_MoBGroup_15144 {
		position: relative;
        margin-right: 30px;
		width: 187px;
		height: 270px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#PFP_36_MoBRectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.PFP_36_MoBRectangle_754 {
		filter: drop-shadow(5px 5px 1px rgba(233, 221, 223, 1));
		position: absolute;
		overflow: visible;
		width: 193.5px;
		height: 276.5px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_MoBannie-spratt-aJN7zURQ1Wg-unspl {
		position: absolute;
		width: 184px;
		height: 241px;
		left: 2px;
		top: 29px;
		overflow: visible;
	}
	#PFP_36_MoBTitle {
		left: 6px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 181px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBGroup_15149 {
		position: absolute;
		width: 336px;
		height: 38px;
		left: 20px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBOur_Colours {
		left: 0px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 113px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBGroup_15145 {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 191px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBRectangle_753 {
		fill: rgba(183,177,186,1);
	}
	.PFP_36_MoBRectangle_753 {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_MoBView_More {
		left: 38px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 72px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#PFP_36_MoBGroup_15033 {
		position: absolute;
		width: 369px;
		height: 460px;
		left: 6px;
		top: 795px;
		overflow: visible;
	}
	#PFP_36_MoBengin-akyurt-yCYVV8-kQNM-unspl {
		filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		position: absolute;
		width: 280px;
		height: 210px;
		left: 42px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBGroup_15038 {
		position: absolute;
		width: 369px;
		height: 191px;
		left: 0px;
		top: 269px;
		overflow: visible;
	}
	#PFP_36_MoBScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 369px;
		height: 126px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PFP_36_MoBIt_is_a_long_established_fact__b {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 352px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PFP_36_MoBVISION_b {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBGroup_15175 {
		position: absolute;
		width: 369px;
		height: 460px;
		left: 6px;
		top: 795px;
		overflow: visible;
	}
	#PFP_36_MoBengin-akyurt-yCYVV8-kQNM-unspl_b {
		filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		position: absolute;
		width: 280px;
		height: 210px;
		left: 42px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBGroup_15038_b {
		position: absolute;
		width: 369px;
		height: 191px;
		left: 0px;
		top: 269px;
		overflow: visible;
	}
	#PFP_36_MoBScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 369px;
		height: 126px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PFP_36_MoBIt_is_a_long_established_fact__b {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 352px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PFP_36_MoBVISION_b {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBGroup_15176 {
		position: absolute;
		width: 369px;
		height: 460px;
		left: 6px;
		top: 1355px;
		overflow: visible;
	}
	#PFP_36_MoBtowfiqu-barbhuiya-998DibZwpIc- {
		filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		position: absolute;
		width: 280px;
		height: 210px;
		left: 42px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBGroup_15038_cb {
		position: absolute;
		width: 369px;
		height: 191px;
		left: 0px;
		top: 269px;
		overflow: visible;
	}
	#PFP_36_MoBScroll_Group_28_cc {
		mix-blend-mode: normal;
		position: absolute;
		width: 369px;
		height: 126px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PFP_36_MoBIt_is_a_long_established_fact__cd {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 352px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PFP_36_MoBVISION_ce {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBGroup_15177 {
		position: absolute;
		width: 369px;
		height: 460px;
		left: 6px;
		top: 1915px;
		overflow: visible;
	}
	#PFP_36_MoBraychan-vkpVPcIBU5U-unsplash {
		filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		position: absolute;
		width: 280px;
		height: 210px;
		left: 42px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBGroup_15038_ch {
		position: absolute;
		width: 369px;
		height: 191px;
		left: 0px;
		top: 269px;
		overflow: visible;
	}
	#PFP_36_MoBScroll_Group_28_ci {
		mix-blend-mode: normal;
		position: absolute;
		width: 369px;
		height: 126px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PFP_36_MoBIt_is_a_long_established_fact__cj {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 352px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PFP_36_MoBVISION_ck {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}