.wrapper{
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    width: '100%';
}

/* .appBar{
    border-radius: 15;
      margin: '30px 100px';
       display: 'flex';
       flex-direction: 'row';
       justify-content: 'center';
       align-items: 'center';
       width: '600px';
       border: '2px solid black';
} */

.appBar{
    border-radius: 15px;
    margin: 30px 100px;
    border: 2px solid black;
    width: 100px;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
[class*="appBar"] {
    width: 100%;
  }

 
.mediaViewInfo {
    --web-view-name: index page;
    --web-view-id: index_page;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: index_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#index_page {
    position: absolute;
    width: 1366px;
    height: 700px;
    background-color: rgba(255,255,255,1);
    top: 50%;
    left: 55%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    overflow: hidden;
    --web-view-name: index page;
    --web-view-id: index_page;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Ellipse_1 {
    opacity: 0.75;
    fill: rgba(106,219,143,1);
}
.Ellipse_1 {
    position: absolute;
    overflow: visible;
    width: 497px;
    height: 930px;
    left: 870px;
    top: -164px;
}
#Ellipse_2 {
    fill: rgba(106,219,143,1);
}
.Ellipse_2 {
    position: absolute;
    overflow: visible;
    width: 320px;
    height: 930px;
    left: 1046px;
    top: -164px;
}
#Ellipse_3 {
    opacity: 0.5;
    fill: rgba(1,179,153,1);
}
.Ellipse_3 {
    position: absolute;
    overflow: visible;
    width: 658px;
    height: 930px;
    left: 710px;
    top: -164px;
}
#slazzer-edit-image1 {
    position: absolute;
    width: 758px;
    height: 758px;
    left: 640px;
    top: 10px;
    overflow: visible;
}
#Forgot_Password_login {
    left: 377px;
    top: 549px;
    position: absolute;
    overflow: visible;
    width: 133px;
    white-space: nowrap;
    line-height: 35px;
    margin-top: -10px;
    text-align: center;
    font-family: Poppins-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(133,146,155,1);
    cursor: pointer;
}
#Dont_have_an_Account {
    left: 350px;
    top: 572px;
    position: absolute;
    overflow: visible;
    width: 179px;
    white-space: nowrap;
    line-height: 35px;
    margin-top: -10px;
    text-align: center;
    font-family: Poppins-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(25,140,255,1);
    cursor: pointer;
}

#Login_button_PC:hover {
    width: 145px;
    height: 33px;
    left: 108px;
    top: 555px;
}
#Login_button_PC {
    position: absolute;
    width: 155px;
    height: 39px;
    left: 102px;
    top: 552px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Login_Color_high {
    fill: rgba(33,177,73,1);
}
.Login_Color_high {
    /* filter: drop-shadow(3.857px 4.596px 13px rgba(0, 0, 0, 0.141)); */
    position: absolute;
    overflow: visible;
    width: 155px;
    height: 40px;
    left: 0px;
    top: 0px;
    transition: all .3s ease-out;
}
#LOGIN_N {
    left: 47.857px;
    top: 9.735px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    line-height: 30.360000610351562px;
    margin-top: -6.680000305175781px;
    text-align: center;
    font-family: Poppins-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(255,255,255,1);
}
#imageloginLogo {
    position: absolute;
    width: 135px;
    height: 135px;
    left: 245px;
    top: 167px;
    overflow: visible;
}
#txtPassword_PC {
    fill: rgba(255,255,255,1);
}
.txtPassword_PC {
    -webkit-filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 411px;
    height: 55px;
    left: 99px;
    top: 470px;
    font-size: 20px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius:  50px;
    /* border-shadow: 10px; */
}
#txtEmail_PC {
    fill: rgba(255,255,255,1);
}
.txtEmail_PC {
    -webkit-filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 411px;
    height: 55px;
    left: 99px;
    top: 404px;
    font-size: 20px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius:  50px;
}
#OPERATE_LIVE_Login {
    left: 154px;
    top: 316px;
    position: absolute;
    overflow: visible;
    width: 319px;
    white-space: nowrap;
    line-height: 132px;
    margin-top: -46px;
    text-align: left;
    font-family: Cooper;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgba(33,177,73,1);
}
.mediaViewInfo {
    --web-view-name: index page;
    --web-view-id: index_page_Mob_Log;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: index_page_Mob_Log;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

#index_page_Mob_Log {
    position: absolute;
    width: 430px;
    height: 932px;
    background-color: rgba(255,255,255,1);
    /* top: 56%;
    left: 55%;
    transform: translate(-50%, -40%); */
    overflow: hidden;
    --web-view-name: index page;
    --web-view-id: index_page_Mob_Log;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#Logo_Mob_Login {
    position: absolute;
    width: 1386px;
    height: 942px;
    left: 0px;
    top: -5px;
    overflow: visible;
}

#Top_Login_Notch {
    opacity: 0.75;
    fill: rgba(33,177,73,1);
}

.Top_Login_Notch {
    position: absolute;
    overflow: visible;
    width: 472px;
    height: 207.259px;
    left: -20px;
    top: -87px;
}

#Top_Login_border_M {
    fill: rgba(255,255,255,1);
}

.Top_Login_border_M {
    position: absolute;
    overflow: visible;
    width: 349px;
    height: 316px;
    left: 40px;
    top: -87px;
    border-radius:  51px;
    background:  linear-gradient(315deg, #f0f0f0, #cacaca);
    box-shadow:   -23px -23px 43px #929292,
}

#Mob_Login_Border_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Mob_Login_Border_ {
    position: absolute;
    overflow: visible;
    width: 349px;
    height: 667px;
    left: 41px;
    top: 514px;
}
#image_login_Mob {
    position: absolute;
    width: 135px;
    height: 135px;
    left: 143px;
    top: 0px;
    overflow: visible;
}
#OPERATE_LIVE_Mob_Login {
    left: 51px;
    top: 145px;
    position: absolute;
    overflow: visible;
    width: 319px;
    white-space: nowrap;
    line-height: 132px;
    margin-top: -46px;
    text-align: left;
    font-family: Cooper;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgba(33,177,73,1);
}
#txtPassword_Mob_ {
    fill: rgba(255,255,255,1);
}
.txtPassword_Mob_ {
    -webkit-filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 288px;
    height: 55px;
    left: 74px;
    top: 658px;
    font-size: 20px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius:  50px;
   
}
#txtEmail_Mob_ {
    fill: rgba(255,255,255,1);
}
.txtEmail_Mob_ {
    -webkit-filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 288px;
    height: 55px;
    left: 74px;
    top: 580px;
    font-size: 20px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius:  50px;
}
#Ellipse_5_Mob_Log {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Ellipse_5_Mob_Log {
    position: absolute;
    overflow: visible;
    width: 72px;
    height: 72px;
    left: 279px;
    top: 479px;
}

#right-arrow1_M_Log {
    position: absolute;
    width: 42px;
    height: 42px;
    left: 297px;
    top: 493px;
    overflow: visible;
}
#slazzer-edit-image1_M_Log {
    position: absolute;
    width: 322px;
    height: 322px;
    left: 51px;
    top: 212px;
    overflow: visible;
}
#Login_button_Mob_Log {
    position: absolute;
    width: 155px;
    height: 43.366px;
    left: 138px;
    top: 758px;
    overflow: visible;
}
#Group_1_Mob_Log {
    position: absolute;
    width: 155px;
    height: 43.366px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#LOGIN_Mob_Lg {
    left: 29.286px;
    top: 19.366px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    line-height: 30.360000610351562px;
    margin-top: -6.680000305175781px;
    text-align: center;
    font-family: Poppins-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(255,255,255,1);
}
#Login_button_z_Mob {
    position: absolute;
    width: 155px;
    height: 39px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Rectangle_2_Mob {
    fill: rgba(33,177,73,1);
}
.Rectangle_2_Mob {
    -webkit-filter: drop-shadow(3.857px 4.596px 13px rgba(0, 0, 0, 0.141));
            filter: drop-shadow(3.857px 4.596px 13px rgba(0, 0, 0, 0.141));
    position: absolute;
    overflow: visible;
    width: 194px;
    height: 78px;
    left: 0px;
    top: 0px;
}
#LOGIN__ {
    left: 47.857px;
    top: 8.635px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    line-height: 30.360000610351562px;
    margin-top: -6.680000305175781px;
    text-align: center;
    font-family: Poppins-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(255,255,255,1);
}
#Forgot_Password_Mob_Log {
    left: 246px;
    top: 727px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: forgot_page.html;
    cursor: pointer;
    line-height: 35px;
    margin-top: -11px;
    text-align: center;
    font-family: Poppins-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(133,146,155,1);
}
#Dont_have_an_Account_Mob_Log {
    left: 138px;
    top: 808px;
    position: absolute;
    overflow: visible;
    width: 155px;
    white-space: nowrap;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: sign_up.html;
    cursor: pointer;
    line-height: 35px;
    margin-top: -11px;
    text-align: center;
    font-family: Poppins-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(25,140,255,1);
}
	.mediaViewInfo {
		--web-view-name: side nav bar mob;
		--web-view-id: side_nav_bar_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: side_nav_bar_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#side_nav_bar_mob {
		position: absolute;
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 10;
		background-color: rgba(219,242,231,1);
        overflow-x: hidden;
        overflow-y: auto;
		--web-view-name: side nav bar mob;
		--web-view-id: side_nav_bar_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}

   
@-webkit-keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }

   
@keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInRight {
    -webkit-animation: fadeInRight 1s;
            animation: fadeInRight 1s;
  }

	#side_nav_bg__be {
		fill: url(#side_nav_bg__be);
	}
	.side_nav_bg__be {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		/* left: -5px; */
		top: 0px;
	}
	#left_comp_mob_menu {
		position: absolute;
		width: 416px;
		height: 675px;
		left: -318px;
		top: 68px;
		overflow: visible;
	}
	#side_nav_border_3 {
		fill: rgba(204,214,209,1);
	}
	.side_nav_border_3 {
		position: absolute;
		overflow: visible;
		width: 81px;
		height: 565px;
		left: 335px;
		top: 56px;
	}
	#side_nav_border_2 {
		fill: rgba(255,255,255,1);
	}
	.side_nav_border_2 {
		position: absolute;
		overflow: visible;
		width: 81px;
		height: 674px;
		left: 294px;
		top: 1px;
        transition: all 0.30000001192092896s ease-out;
        --web-animation: fadein 0.30000001192092896s ease-out;
        cursor: pointer;
	}
	#side_nav_border_1 {
		fill: rgba(219,242,231,1);
	}
	.side_nav_border_1 {
		overflow: visible;
		position: absolute;
		width: 67.056px;
		height: 376.655px;
		-webkit-transform: translate(318px, -68px) matrix(1,0,0,1,-163.2004,-86.7996) rotate(-90deg);
		        transform: translate(318px, -68px) matrix(1,0,0,1,-163.2004,-86.7996) rotate(-90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#logged_user_mob_pic {
		position: absolute;
		width: 78px;
		height: 78px;
		left: 198px;
		top: 22px;
        border-radius: 50%;
		overflow: hidden;
	}
	#dash_bdr_grp_mob {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 139px;
		top: 186px;
		overflow: visible;
	}
	#dash_mob {
		fill: rgba(255,255,255,1);
	}
	.dash_mob {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Dashboard_side_lbl_mob {
		left: 51px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#dashboard_Icon_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#metting_room_grp_mob {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 139px;
		top: 414px;
		overflow: visible;
	}
	#meet_mob {
		fill: rgba(255,255,255,1);
	}
	.meet_mob {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Meeting_Room_side_lbl_mob {
		left: 51px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#meeting_room_icon_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#portfolio_grp_mob {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 139px;
		top: 243px;
		overflow: visible;
	}
	#portfolio_mob {
		fill: rgba(255,255,255,1);
	}
	.portfolio_mob {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#portfolio_side_lbl_mob {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 76px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#portfolio_icon_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#connection_grp_mob {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 139px;
		top: 300px;
		overflow: visible;
	}
	#con_mob {
		fill: rgba(255,255,255,1);
	}
	.con_mob {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#My_Connection_side_lbl_mob {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 61px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#my_connections_icon_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#market_grp_mob {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 139px;
		top: 470px;
		overflow: visible;
	}
	#market_mob {
		fill: rgba(255,255,255,1);
	}
	.market_mob {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Market_side_lbl_mob {
		left: 51px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#market_icon_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#event_grp_mob {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 139px;
		top: 527px;
		overflow: visible;
	}
	#event_mob {
		fill: rgba(255,255,255,1);
	}
	.event_mob {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Events_side_lbl_mob {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 88px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#events_icon_ {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#logout_grp_mob {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 139px;
		top: 584px;
		overflow: visible;
	}
	#log_mob {
		fill: rgba(255,255,255,1);
	}
	.log_mob {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Logout_side_lbl_mob {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 64px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#logout_icon_I_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#com_grp_mob {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 139px;
		top: 357px;
		overflow: visible;
	}
	#com_mob {
		fill: rgba(255,255,255,1);
	}
	.com_mob {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Communities_side_lbl_mob {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 118px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#community_icon_I_1_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 7px;
		top: 6px;
		overflow: visible;
	}
	#Dashboard_side_lbl_mob_cg {
		left: 98px;
		top: 110px;
		position: absolute;
		overflow: visible;
		width: 278px;
		height: 22px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(4,4,4,1);
	}


	.mediaViewInfo {
		--web-view-name: menu – 2;
		--web-view-id: side_bar_icon;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: side_bar_icon;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#side_bar_icon {
		position: fixed;
		width: 112px;
		height: 100vh;
        z-index: 100;
		background-color: rgba(219,242,231,1);
		overflow-y: auto;
        overflow-x: hidden;
		--web-view-name: menu – 2;
		--web-view-id: side_bar_icon;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}

    #side_bar_icon::-webkit-scrollbar {
        display: none;
    }
	#side_nav_icon_border {
		fill: rgba(219,242,231,1);
	}
	.side_nav_icon_border {
		position: absolute;
		overflow: visible;
		width: 112px;
		height: 100%;
		left: 0px;
		top: 0px;
	}
	#side_logo_icon {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 22px;
		top: 28px;
		overflow: visible;
	}
	#dash_bdr_grp {
		position: absolute;
		width: 43px;
		height: 43px;
		left: 35px;
		top: 113px;
        cursor: pointer;
		overflow: visible;
	}
	#dash_bdr {
		fill: rgba(255,255,255,1);
	}
	.dash_bdr {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#dashboard_Icon_I {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#metting_room_grp {
		position: absolute;
		width: 43px;
		height: 43px;
		left: 35px;
		top: 341px;
        cursor: pointer;
		overflow: visible;
	}
	#meet_bdr {
		fill: rgba(255,255,255,1);
	}
	.meet_bdr {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#meeting_room_icon_I {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#portfolio_grp {
		position: absolute;
		width: 43px;
		height: 43px;
		left: 35px;
		top: 170px;
        cursor: pointer;
		overflow: visible;
	}
	#portfolio_bdr {
		fill: rgba(255,255,255,1);
	}
	.portfolio_bdr {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#portfolio_icon_I {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#connection_grp {
		position: absolute;
		width: 43px;
		height: 43px;
		left: 35px;
		top: 227px;
        cursor: pointer;
		overflow: visible;
	}
	#con_bdr {
		fill: rgba(255,255,255,1);
	}
	.con_bdr {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#my_connections_icon_I {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#market_grpp {
		position: absolute;
		width: 43px;
		height: 43px;
		left: 35px;
		top: 398px;
        cursor: pointer;
		overflow: visible;
	}
	#market_bdr {
		fill: rgba(255,255,255,1);
	}
	.market_bdr {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#market_icon_I {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#event_grp {
		position: absolute;
		width: 43px;
		height: 43px;
		left: 35px;
		top: 455px;
        cursor: pointer;
		overflow: visible;
	}
	#event_bdr {
		fill: rgba(255,255,255,1);
	}
	.event_bdr {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#events_icon_I {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#logout_grp {
		position: absolute;
		width: 43px;
		height: 43px;
		left: 35px;
		top: 512px;
        cursor: pointer;
		overflow: visible;
	}
	#log_bdr {
		fill: rgba(255,255,255,1);
	}
	.log_bdr {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#logout_icon_I {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#com_grp {
		position: absolute;
		width: 43px;
		height: 43px;
		left: 35px;
		top: 284px;
        cursor: pointer;
		overflow: visible;
	}
	#com_bdr {
		fill: rgba(255,255,255,1);
	}
	.com_bdr {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#community_icon_I {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 7px;
		top: 6px;
		overflow: visible;
	}


	.mediaViewInfo {
		--web-view-name: side menu bar pc;
		--web-view-id: side_menu_bar_pc;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: side_menu_bar_pc;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#side_menu_bar_pc {
		position: fixed;
		width: 246px;
		height: 100vh;
         z-index: 100;
        background-color: rgba(219,242,231,1);
       
        overflow-y: auto;
        overflow-x: hidden;
    
		--web-view-name: side menu bar pc;
		--web-view-id: side_menu_bar_pc;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}

    #side_menu_bar_pc::-webkit-scrollbar {
        display: none;
    }
	#side_nav_bar_border {
		fill: rgba(219,242,231,1);
	}
	.side_nav_bar_border {
		position: absolute;
		overflow: visible;
		width: 246px;
		height: 100%;
		left: 0px;
		top: 0px;
	}
	#side_logo_icon_expand {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 88px;
		top: 28px;
        cursor: pointer;
		overflow: visible;
	}
	#dash_bdr_grp_expand {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 26px;
		top: 113px;
        cursor: pointer;
		overflow: visible;
	}
	#dash_pc {
		fill: rgba(255,255,255,1);
	}
	.dash_pc {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Dashboard_side_lbl_pc {
		left: 51px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#dashboard_Icon_I_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#metting_room_grp_expand {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 26px;
		top: 341px;
        cursor: pointer;
		overflow: visible;
	}
	#meet_pc {
		fill: rgba(255,255,255,1);
	}
	.meet_pc {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Meeting_Room_side_lbl_pc {
		left: 51px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#meeting_room_icon_I_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#portfolio_grp_expand {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 26px;
		top: 170px;
        cursor: pointer;
		overflow: visible;
	}
	#portfolio_pc {
		fill: rgba(255,255,255,1);
	}
	.portfolio_pc {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#portfolio_side_lbl_pc {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 76px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#portfolio_icon_I_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#connection_grp_expand {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 26px;
		top: 227px;
        cursor: pointer;
		overflow: visible;
	}
	#con_pc {
		fill: rgba(255,255,255,1);
	}
	.con_pc {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#My_Connection_side_lbl_pc {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 61px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#my_connections_icon_I_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#market_grpp_expand {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 26px;
		top: 397px;
        cursor: pointer;
		overflow: visible;
	}
	#market_pc_ui {
		fill: rgba(255,255,255,1);
	}
	.market_pc_ui {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Market_side_lbl_pc {
		left: 51px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#market_icon_I_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#event_grp_expand {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 26px;
		top: 454px;
        cursor: pointer;
		overflow: visible;
	}
	#event_pc {
		fill: rgba(255,255,255,1);
	}
	.event_pc {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Events_side_lbl_pc {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 58px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#events_icon_I_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#logout_grp_expand {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 26px;
		top: 511px;
        cursor: pointer;
		overflow: visible;
	}
	#log_pc {
		fill: rgba(255,255,255,1);
	}
	.log_pc {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Logout_side_lbl_pc {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 64px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#logout_icon_I_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#com_grp_expand {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 26px;
		top: 284px;
        cursor: pointer;
		overflow: visible;
	}
	#com_pc {
		fill: rgba(255,255,255,1);
	}
	.com_pc {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Communities_side_lbl_pc {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 118px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#community_icon_I_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 7px;
		top: 6px;
		overflow: visible;
	}

    .slide-right{
        -webkit-animation:slide-right 0.4s linear both;
                animation:slide-right 0.4s linear both
    }
    
    @-webkit-keyframes slide-right{
        0% {
            -webkit-transform:translateX(-100px);
                    transform:translateX(-100px)
        }
        100% {
            -webkit-transform:translateX(0px);
                    transform:translateX(0px)
        }
    }
    
    @keyframes slide-right{
        0% {
            -webkit-transform:translateX(-100px);
                    transform:translateX(-100px)
        }
        100% {
            -webkit-transform:translateX(0px);
                    transform:translateX(0px)
        }
    }
    
/* 
#top_bar_Border {
    fill: rgba(219,242,231,1);
}
.top_bar_Border {
    width: 67px;
    height: 375px;
    position: fixed;
    z-index: 10;
    top:0px;
    
    overflow: visible;
    transform: matrix(1,0,0,1,154,-154) rotate(-90deg);
    transform-origin: center;
}
#logo_topBorder {
    position: fixed;
    width: 52px;
    height: 52px;
    left: 17px;
    top: 8px;
    z-index: 10;
    overflow: visible;
}

#menu_top_bar {
    position: fixed;
    z-index: 10;
    width: 35px;
    height: 35px;
    left: 323px;
    top: 15px;
    overflow: visible;
    transition: all 0.6000000238418579s wind-up;
    --web-animation: fadein 0.6000000238418579s wind-up;
    --web-action-type: page;
    --web-action-target: menu.html;
    cursor: pointer;
} */



.mediaViewInfo {
    --web-view-name: top nav bar new;
    --web-view-id: top_nav_bar_new;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: top_nav_bar_new;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#top_nav_bar_new {
    position: fixed;
    width: 451px;
    height: 70px;
    background-color: rgba(255,255,255,1);
    z-index: 100;
    top:0px;
    display: flex;
    overflow: visible;
    --web-view-name: top nav bar new;
    --web-view-id: top_nav_bar_new;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#top_bar_Border {
    fill: rgba(219,242,231,1);
    width: 100%;
} 
.top_bar_Border {
    position: fixed;
    overflow: visible;
    display: flex;
    width: 100%;
    z-index: 10;
    height: 70px;
    left: 0px;
    top: 0px;
}
#menu_top_bar {
    position: fixed;
    width: 35px;
    z-index: 10;
    height: 35px;
    left: 87%;
    top: 15px;
    overflow: visible;
}
#logo_topBorder {
    position: fixed;
    width: 52px;
    height: 52px;
    z-index: 10;
    left: 17px;
    top: 8px;
    overflow: visible;
}
.mediaViewInfo {
    --web-view-name: top bar;
    --web-view-id: top_bar;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: top_bar;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#top_bar {
    position: fixed;
    width: calc(100vw - 136px);
    min-width: 875px;
    height: 64px;
    left: 120px;
    z-index: 20;
    background-color: transparent;
    overflow: hidden;
    --web-view-name: top bar;
    --web-view-id: top_bar;
    --web-enable-deep-linking: true;
}
#mid_op_design {
    fill: rgba(219,242,231,1);
}
.mid_op_design {
    position: absolute;
    overflow: visible;
    width: calc(100% - 560px);
    height: 59px;
    left: 413px;
    top: 0px;
}
#left_grp_op {
    position: absolute;
    width: 363.145px;
    height: 58.662px;
    left: 49.855px;
    top: 0px;
    z-index: 10;
    overflow: visible;
}
#bg_path {
    fill: rgba(219,242,231,1);
}
.bg_path {
    overflow: visible;
    position: absolute;
    width: 363.145px;
    height: 58.662px;
    left: 0px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#seacch_input_op {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.seacch_input_op {
    position: absolute;
    overflow: visible;
    width: calc(100vh - 450px);
    height: 41px;
    left: 91.145px;
    top: 7px;
    font-size: 17px;
    border-radius: 50px;
    padding-left: 10px;
    font-style: Segeo UI;
    box-shadow: inset 10px 10px 20px #cacaca,inset -10px -10px 20px #ffffff;
}
#search_img_ {
    position: absolute;
    width: 25px;
    height: 25px;
    left: calc(100vh - 400px);
    top: 13px;
    overflow: visible;
}
#bg_deisng_2_op {
    fill: transparent;
}
.bg_deisng_2_op {
    overflow: visible;
    position: absolute;
    width: 363.145px;
    height: 58.662px;
    left: 0px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#right_grp_op {
    position: absolute;
    width: 436.784px;
    height: 58.662px;
    right: 0px;
    top: 1px;
    z-index: 10;
    background-color: transparent;
    overflow: visible;
}
#bg_design_1_op {
    fill: rgba(219,242,231,1);
}
.bg_design_1_op {
    overflow: visible;
    position: absolute;
    width: 363.145px;
    height: 58.662px;
    left: 0px;
    top: 0px;
   
    -webkit-transform: matrix(1,0,0,1,0,0);
   
            transform: matrix(1,0,0,1,0,0);
}
#Icon_material-message {
    fill: rgba(0,0,0,1);
}
.Icon_material-message {
    overflow: visible;
    position: absolute;
    width: 23.095px;
    height: 23.191px;
    left: 97.281px;
    top: 18.809px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Icon_material-notifications-ac {
    fill: rgba(0,0,0,1);
}
.Icon_material-notifications-ac {
    overflow: visible;
    position: absolute;
    width: 23.095px;
    height: 23.191px;
    left: 242.573px;
    top: 18.809px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Icon_awesome-user-friends {
    fill: rgba(0,0,0,1);
}
.Icon_awesome-user-friends {
    overflow: visible;
    position: absolute;
    width: 27.312px;
    height: 23.191px;
    left: 165.709px;
    top: 18.809px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#profile_op_pfp {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 386.784px;
    top: 7px;
    overflow: visible;
    padding: 3px;
    border-radius: 50%;
    border:1px solid;
    cursor: pointer;
    overflow: hidden;
}
.mediaViewInfo {
    --web-view-name: profile edit pop;
    --web-view-id: profile_edit_pop;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: profile_edit_pop;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#profile_edit_pop {
    position: absolute;
    width: 712px;
    height: 630px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50% , -50%);
            transform: translate(-50% , -50%);
    border-radius: 15px;    
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: profile edit pop;
    --web-view-id: profile_edit_pop;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#pop_bg_desig {
    opacity: 0.59;
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 718px;
    height: 636px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#user_pfp_pop_lbl_profile_pc {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 23px;
    top: 12px;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
}
#Edit_Profile_lbl_profile_pc {
    left: 74px;
    top: 22px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#cancel_btn_grp_profile:hover {
    left: 436px;
    top: 29px;
}
#cancel_btn_grp_profile {
    position: absolute;
    width: 109px;
    height: 36px;
    left: 432px;
    top: 24px;
    overflow: visible;
    cursor: pointer;
    transition: all .3s ease-out;
}
#cancel_bdr_btn_rpofile {
    fill: rgba(163,233,200,1);
}
.cancel_bdr_btn_rpofile {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 109px;
    height: 36px;
    left: 0px;
    top: 0px;
}
#cancel_lbl_profile {
    /* left: 36px; */
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#btn_save_changes_profile_edit_:hover {
    left: 562px;
    top: 29px;
}
#btn_save_changes_profile_edit_ {
    position: absolute;
    width: 109px;
    height: 36px;
    left: 558px;
    top: 24px;
    overflow: visible;
    cursor: pointer;
    transition: all .3s ease-out;
}
#save_update_bg_btn {
    fill: rgba(163,233,200,1);
}
.save_update_bg_btn {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 109px;
    height: 36px;
    left: 0px;
    top: 0px;
}
#save_updates_lbl_btn {
 
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#close2_edit_profile_page {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 10px;
    top: 9px;
    cursor: pointer;
    overflow: visible;
}
#scroll_profile_page_edit {
    mix-blend-mode: normal;
    position: absolute;
    width: 723px;
    height: 551px;
    left: 0px;
    top: 70px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#name_input_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.name_input_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 23px;
    top: 40px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Name_lbl_profile_pc {
    left: 23px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#number_lbl_profile_pc_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.number_lbl_profile_pc_input {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 252px;
    top: 40px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Mobile_Number_lbl_profile_pc {
    left: 252px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 106px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Gender_lbl_profile_pc {
    left: 553px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 50px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#country_drop_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.country_drop_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 23px;
    top: 387px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Country_lbl_profile_pc {
    left: 23px;
    top: 366px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#state_drop_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.state_drop_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 252px;
    top: 387px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#State_lbl_profile_pc {
    left: 252px;
    top: 366px;
    position: absolute;
    overflow: visible;
    width: 34px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#city_input_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.city_input_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 481px;
    top: 387px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#City_lbl_profile_pc {
    left: 481px;
    top: 366px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#address_input_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.address_input_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 60px;
    left: 23px;
    top: 460px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Address_lbl_profile_pc {
    left: 23px;
    top: 439px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#pincode_bdr_input_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.pincode_bdr_input_pc {
    position: absolute;
    overflow: visible;
    width: 141px;
    height: 40px;
    left: 252px;
    top: 460px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Pincode_lbl_lbl_profile_pc {
    left: 252px;
    top: 439px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
.map_lattitude_input_pc {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 40px;
    left: 405px;
    top: 460px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#map_lattitute_lbl_lbl_profile_pc {
    left: 405px;
    top: 439px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
.map_longitude_input_pc {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 40px;
    left: 555px;
    top: 460px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#map_longitude_lbl_profile_pc {
    left: 555px;
    top: 439px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#men_btn_grp_pc {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 511px;
    top: 40px;
    cursor: pointer;
    overflow: visible;
}
#btn_bdr_pop {
    fill: rgba(163,233,200,1);
}
.btn_bdr_pop {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#man_btn_img_pc {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#women_btn_grp_pc {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 565px;
    top: 40px;
    cursor: pointer;
    overflow: visible;
}
#btn_bdr_pc_pop {
    fill: rgba(163,233,200,1);
}
.btn_bdr_pc_pop {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#woman_btn_pc {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#other_btn_gender_pc {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 617px;
    top: 40px;
    cursor: pointer;
    overflow: visible;
}
#bdr_other_btn_pc {
    fill: rgba(163,233,200,1);
}
.bdr_other_btn_pc {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#gender-neutral_btn_img_pc {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#introduction_input_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.introduction_input_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 325px;
    height: 60px;
    left: 23px;
    top: 180px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Introduction_lbl_profile_pc {
    left: 23px;
    top: 160px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#email_input_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.email_input_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 23px;
    top: 110px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Email_lbl_profile_pc {
    left: 23px;
    top: 90px;
    position: absolute;
    overflow: visible;
    width: 36px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Generallbl_profile_pc {
    left: 48px;
    top: -2px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#Location_lbl_profile_pc {
    left: 48px;
    top: 340px;
    position: absolute;
    overflow: visible;
    width: 70px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#DDOB_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.DDOB_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 481px;
    top: 110px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#DOB_lbl_profile_pc {
    left: 481px;
    top: 90px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Payment_Details_lbl_profile_pc {
    left: 48px;
    top: 530px;
    position: absolute;
    overflow: visible;
    width: 128px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#HBR_input_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.HBR_input_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 252px;
    top: 712px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Hour_Billing_Rate_lbl_profile_pc {
    left: 252px;
    top: 692px;
    position: absolute;
    overflow: visible;
    width: 112px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#billing_currency_input_edit_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.billing_currency_input_edit_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 481px;
    top: 712px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Billing_Currency_lbl_profile_pop {
    left: 481px;
    top: 692px;
    position: absolute;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#profession_input_lbl_profile_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.profession_input_lbl_profile_pop {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 252px;
    top: 110px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Profession_lbl_profile_pc {
    left: 252px;
    top: 90px;
    position: absolute;
    overflow: visible;
    width: 70px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#dossier_lbl_profile_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 23px;
    top: 0px;
    overflow: visible;
}
#placeholder_lbl_profile_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 23px;
    top: 341px;
    overflow: visible;
}
#card_lbl_profile_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 23px;
    top: 531px;
    overflow: visible;
}
#Vision_bdr_bdr_input_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Vision_bdr_bdr_input_pc {
    position: absolute;
    overflow: visible;
    width: 325px;
    height: 60px;
    left: 370px;
    top: 180px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Vision_lbl_profile_pc {
    left: 370px;
    top: 160px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#mission_bdr_input_lbl_profile_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.mission_bdr_input_lbl_profile_pop {
    position: absolute;
    overflow: visible;
    width: 325px;
    height: 60px;
    left: 370px;
    top: 270px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Mission_lbl_profile_pc {
    left: 370px;
    top: 250px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#principle_bdr_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.principle_bdr_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 325px;
    height: 60px;
    left: 23px;
    top: 270px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Principles_lbl_profile_pc {
    left: 23px;
    top: 250px;
    position: absolute;
    overflow: visible;
    width: 64px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#UPI_input_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.UPI_input_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 481px;
    top: 642px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#UPI_ID_lbl_profile_pc {
    left: 481px;
    top: 622px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#business_name_input_edit_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.business_name_input_edit_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 23px;
    top: 572px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#business_name_lbl_edit_pc {
    left: 23px;
    top: 552px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#business_mobile_input_edit_pro {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.business_mobile_input_edit_pro {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 252px;
    top: 572px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#business_mobile_lbl_edit_profu {
    left: 252px;
    top: 552px;
    position: absolute;
    overflow: visible;
    width: 107px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#business_email_input_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.business_email_input_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 481px;
    top: 572px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#business_email_lbl_edit_profil {
    left: 481px;
    top: 552px;
    position: absolute;
    overflow: visible;
    width: 97px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#business_address_input_edit_pr {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.business_address_input_edit_pr {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 110px;
    left: 23px;
    top: 642px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#UPI_ID_lbl_profile_paymet_page_pc {
    left: 23px;
    top: 622px;
    position: absolute;
    overflow: visible;
    width: 114px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#web_bus_edit_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.web_bus_edit_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 252px;
    top: 642px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#website_business_edit_profile_pc {
    left: 252px;
    top: 622px;
    position: absolute;
    overflow: visible;
    width: 114px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#socials_lbl_edit_profile {
    left: 48px;
    top: 762px;
    position: absolute;
    overflow: visible;
    width: 128px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#social_img_edit_profile_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 23px;
    top: 763px;
    overflow: visible;
}
#whatsapp_input_edit_profile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.whatsapp_input_edit_profile {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 23px;
    top: 807px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#whats_app_number_lbl_edit_pc {
    left: 23px;
    top: 787px;
    position: absolute;
    overflow: visible;
    width: 129px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#linked_in_edit_profile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.linked_in_edit_profile {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 252px;
    top: 807px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#linked_in_lbl_edit_profile {
    left: 252px;
    top: 787px;
    position: absolute;
    overflow: visible;
    width: 87px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#insta_url_box_edit_profile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.insta_url_box_edit_profile {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 481px;
    top: 808px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#insta_url_lbl_edit_profile {
    left: 481px;
    top: 788px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}

#BottomBar_group {
    position: fixed;

    bottom: 0%;
    padding: 15px;
    width: 100%;
    height: 65px;
    z-index: 1;
    left: 0px;
}
#BottomBar_border {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
    width: 100%;
}
.BottomBar_border {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 65px;
    left: 0px;
    top: 0px;
}
#bell_bottombar {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 80%;
    text-align: center;
    top: 18px;
    overflow: visible;
}
#add-friend_bottombar {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 50%;
    top: 18px;
    overflow: visible;
}
#customer-support_bottombar {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 20%;
    top: 18px;
    overflow: visible;
}

.mediaViewInfo {
    --web-view-name: Connection page;
    --web-view-id: Connection_page;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: Connection_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#Connection_page {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 140px);
    top:75px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: Connection page;
    --web-view-id: Connection_page;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#con_bg_img_mob {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#connect_mob_lbl {
    left: 18px;
    top: 14px;
    position: absolute;
    overflow: visible;
    width: 144px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#connect_switch {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.connect_switch {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 17px;
    left: 309px;
    top: 10px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#box_cr_ {
    position: relative;
    width: 359px;
    height: 153px;
    left: 70px;
    top: 110px;
    overflow: visible;
}
#border_cr_ {
    fill: rgba(255,255,255,1);
}
.border_cr_ {
    position: absolute;
    overflow: visible;
    width: 359px;
    height: 153px;
    left: 0px;
    top: 0px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
#profile_img_cr {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 18.5px;
    top: 16.5px;
    border-radius: 50%;
    overflow: hidden;
}
#name_lbl_border_cr {
    fill: rgba(219,242,231,1);
}
.name_lbl_border_cr {
    position: absolute;
    overflow: visible;
    width: 220px;
    height: 30px;
    left: 123px;
    top: 20px;
}
#name_lbl_value_cr {
    left: 134.237px;
    top: 23.5px;
    position: absolute;
    overflow: visible;
    width: 137px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#Profession_lbl_value_cr {
    left: 114px;
    top: 68.5px;
    position: absolute;
    overflow: visible;
    width: 77px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Connected_On_lbl_value_cr {
    left: 87px;
    top: 88.5px;
    position: absolute;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Software_Developer_lbl_value_cr {
    left: 205px;
    top: 68.5px;
    position: absolute;
    overflow: visible;
    width: 131px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#date_new_value_cr {
    left: 205px;
    top: 88.5px;
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#border_box_cr:hover {
    left: 22px;
    top: 123px;
}
#border_box_cr {
    position: absolute;
    width: 70px;
    height: 26px;
    left: 17px;
    top: 118.5px;
    overflow: visible;
    border-radius: 15px;
    transition: all .3s ease-out;
}
#block_border_cr {
    fill: rgba(219,242,231,1);
}
.block_border_cr {
    position: absolute;
    overflow: visible;
    width: 70px;
    height: 26px;
    left: 0px;
    top: 0px;
}
#Block_value_cr {
    left: 17px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 36px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#border_ignore_cr:hover {
    left: 133px;
    top: 123px;
}
#border_ignore_cr {
    position: absolute;
    width: 70px;
    height: 26px;
    left: 128px;
    top: 118.5px;
    overflow: visible;
    border-radius: 15px;
    transition: all .3s ease-out;
}
#block_border_ignore_cr {
    fill: rgba(219,242,231,1);
}
.block_border_ignore_cr {
    position: absolute;
    overflow: visible;
    width: 70px;
    height: 26px;
    left: 0px;
    top: 0px;
}
#Ignore_value_ig {
    left: 13px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#border_disconnect_cr:hover {
    left: 245px;
    top: 123px;
}
#border_disconnect_cr {
    position: absolute;
    width: 98px;
    height: 26px;
    left: 240px;
    top: 118.5px;
    overflow: visible;
    border-radius: 15px;
    transition: all .3s ease-out;
}
#discon_border_cr {
    fill: rgba(219,242,231,1);
}
.discon_border_cr {
    position: absolute;
    overflow: visible;
    width: 98px;
    height: 26px;
    left: 0px;
    top: 0px;
}
#Disconnected_bd_value_cr {
    left: 3px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 91px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}

@-webkit-keyframes slideInRight {
    from {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
      visibility: visible;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }

@keyframes slideInRight {
    from {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
      visibility: visible;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInRight {
    -webkit-animation: slideInRight 1s;
            animation: slideInRight 1s;
  }
#Border_box_requests {
    position: relative;
    margin: 5px;
    width: 359px;
    height: 203px;
    left: 70px;
    top: 103px;
    overflow: visible;
}
#border_req_outer {
    fill: rgba(255,255,255,1);
}
.border_req_outer {
    position: absolute;
    overflow: visible;
    width: 359px;
    height: 203px;
    left: 0px;
    top: 0px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
#porfile_img_req_ {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 14.5px;
    top: 23.5px;
    border-radius: 50%;
    overflow: hidden;
}
#border_name_lbl_req {
    fill: rgba(219,242,231,1);
}
.border_name_lbl_req {
    position: absolute;
    overflow: visible;
    width: 220px;
    height: 30px;
    left: 116px;
    top: 20px;
}
#Mohammed_Zaid_lbl_req {
    left: 134.237px;
    top: 23.5px;
    position: absolute;
    overflow: visible;
    width: 137px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#Profession_lbl_req {
    left: 114px;
    top: 68.5px;
    position: absolute;
    overflow: visible;
    width: 77px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Connected_On_lbl_req {
    left: 87px;
    top: 88.5px;
    position: absolute;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Software_Developer_lbl_req {
    left: 205px;
    top: 68.5px;
    position: absolute;
    overflow: visible;
    width: 131px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#date_req_lbl {
    left: 205px;
    top: 88.5px;
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#border_cancel_req:hover {
    left: 35px;
    top: 172px;
}
#border_cancel_req {
    position: absolute;
    width: 70px;
    height: 26px;
    left: 29px;
    top: 168.5px;
    overflow: visible;
    border-radius: 15px;
    transition: all .3s ease-out;
}
#border_req_cancel {
    fill: rgba(219,242,231,1);
}
.border_req_cancel {
    position: absolute;
    overflow: visible;
    width: 70px;
    height: 26px;
    left: 0px;
    top: 0px;
}

#Cancel_lbl_req {
    left: 8px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 45px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#group_block_req:hover {
    left: 150px;
    top: 173px;
}
#group_block_req {
    position: absolute;
    width: 70px;
    height: 26px;
    left: 145px;
    top: 169.5px;
    overflow: visible;
    border-radius: 15px;
    transition: all .3s ease-out;
}
#border_block_req {
    fill: rgba(219,242,231,1);
}
.border_block_req {
    position: absolute;
    overflow: visible;
    width: 70px;
    height: 26px;
    left: 0px;
    top: 0px;
}
#Block_req_lbl {
    left: 17px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 36px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Group_ignore_req:hover {
    left: 261px;
    top: 173px;
}
#Group_ignore_req {
    position: absolute;
    width: 70px;
    height: 26px;
    left: 256px;
    top: 169.5px;
    overflow: visible;
    border-radius: 15px;
    transition: all .3s ease-out;
}
#border_ignore_req {
    fill: rgba(219,242,231,1);
}
.border_ignore_req {
    position: absolute;
    overflow: visible;
    width: 70px;
    height: 26px;
    left: 0px;
    top: 0px;
}
#Ignore_lbl_req {
    left: 13px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Scroll_intro_req {
    mix-blend-mode: normal;
    position: absolute;
    width: 248px;
    height: 49px;
    left: 87px;
    top: 109px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#intro_value_req {
    left: 4px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 213px;
    height: 180px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}

@-webkit-keyframes slideInRight {
    from {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
      visibility: visible;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }

@keyframes slideInRight {
    from {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
      visibility: visible;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInRight {
    -webkit-animation: slideInRight 1s;
            animation: slideInRight 1s;
  }
	.CononMbmediaViewInfo {
		--web-view-name: connection mob;
		--web-view-id: connection_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: connection_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#CononMbconnection_mob {
		position: absolute;
        width: 100%;
        height:calc(100% - 105px);
        left: 0px;
        top: 45px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: connection mob;
		--web-view-id: connection_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#CononMbdesgin_con_page_new_pc {
		fill: rgba(248,248,248,1);
	}
	.CononMbdesgin_con_page_new_pc {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 203px;
		left: 0px;
		top: 0px;
	}
	#CononMbScroll_Group_8 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 100%;
		left: calc((100% - 375px)/2);
		top: 0px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CononMboverrall_car_conreqsug_con_pag {
		position: relative;
		width: 159px;
		height: 246px;
		/* left: 23px; */
		top: 24px;
        margin: 10px;
		overflow: visible;
	}
	#CononMbover_all_bdr_sug_con_page_new_ {
		fill: rgba(255,255,255,1);
	}
	.CononMbover_all_bdr_sug_con_page_new_ {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 167px;
		height: 254px;
		left: 0px;
		top: 0px;
	}
	#CononMbAnime_Edit_con_page_new_pc {
		position: absolute;
		width: 129px;
		height: 122px;
		left: 15px;
		top: 30px;
		overflow: visible;
	}
	#CononMbdesgination_pc_con_page {
		position: absolute;
		width: 146px;
		height: 23px;
		left: 10px;
		top: 156px;
		overflow: visible;
	}
	#CononMbjob-seeker_con_page_new_pc {
		position: absolute;
		width: 22px;
		height: 21px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CononMbcon_page_new_pc_cor {
		left: 26px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 121px;
		height: 20px;
		
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: capitalize;
	}
	#CononMbMohammed_Affan_con_page_new_pc {
		left: 1px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 159px;
		height: 20px;
		
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
	}
	#CononMbblock_btn_grp_con_page_new_pc {
		position: absolute;
		width: 33px;
		height: 33px;
		left: 88px;
		top: 207px;
		overflow: visible;
	}
	#CononMbshare_btn_con_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.CononMbshare_btn_con_page_new_pc {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 39px;
		height: 39px;
		left: 0px;
		top: 0px;
	}
	#CononMbshare_img_con_page_new_pc {
		position: absolute;
		width: 22px;
		height: 22px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#CononMbdisconnect_btn_grp_con_page_ne {
		position: absolute;
		width: 33px;
		height: 33px;
		left: 42px;
		top: 207px;
		overflow: visible;
	}
	#CononMbbdr_no_Copines {
		fill: rgba(255,255,255,1);
	}
	.CononMbbdr_no_Copines {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 39px;
		height: 39px;
		left: 0px;
		top: 0px;
	}
	#CononMbno-connection_Copines {
		position: absolute;
		width: 21px;
		height: 22px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#CononMbcompany_anme_new_ {
		position: absolute;
		width: 146px;
		height: 22px;
		left: 10px;
		top: 182px;
		overflow: visible;
	}
	#CononMboffice-building_img_con_page_n {
		position: absolute;
		width: 22px;
		height: 21px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CononMboperate_live_lbl_con_page_new_ {
		left: 26px;
		top: 2px;
		position: absolute;
		overflow: visible;
		width: 121px;
		height: 20px;
	
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		text-transform: capitalize;
	}

	.ReqqerMbmediaViewInfo {
		--web-view-name: request mob;
		--web-view-id: request_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: request_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ReqqerMbrequest_mob {
		position: absolute;
        width: 100%;
        height: calc(100% - 105px);
        left: 0px;
        top: 45px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: request mob;
		--web-view-id: request_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#ReqqerMbdesgin_con_page_new_pc {
		fill: rgba(248,248,248,1);
	}
	.ReqqerMbdesgin_con_page_new_pc {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 203px;
		left: 0px;
		top: 0px;
	}
	#ReqqerMbScroll_Group_9 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 100%;
		left: calc((100% - 375px)/2);
		top: 0px;
		display: flex;
        flex-wrap: wrap;
        flex-direction: row;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#ReqqerMbcard_1_req_page_pc_inichan {
		position: relative;
		width: 166px;
		height: 287px;
		/* left: 11px; */
		margin: 10px;
		top: 14px;
		overflow: visible;
	}
	#ReqqerMbover_all_card_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.ReqqerMbover_all_card_erq_page_new_pc {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 174px;
		height: 295px;
		left: 0px;
		top: 0px;
	}
	#ReqqerMbAnime_Edit_goku_thats_me {
		position: absolute;
		width: 135px;
		height: 128px;
		left: 16px;
		top: 31px;
		overflow: visible;
	}
	#ReqqerMbdesignation_grp_req_page_new {
		position: absolute;
		width: 153px;
		height: 23px;
		left: 11px;
		top: 163px;
		overflow: visible;
	}
	#ReqqerMbjob-seeker_img_erq_page_new_pc {
		position: absolute;
		width: 22px;
		height: 23px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ReqqerMberq_page_new_pc_job_name {
		left: 27px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 127px;
		height: 20px;
		
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: capitalize;
	}
	#ReqqerMbMohammed_Affan_goku_v {
		left: 1px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 166px;
		height: 20px;
		line-height: 49px;
		margin-top: -17px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
	}
	#ReqqerMbreject_erq_page_new_pc {
		position: absolute;
		width: 34px;
		height: 34px;
		left: 47px;
		top: 247px;
		overflow: visible;
	}
	#ReqqerMbreqj_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.ReqqerMbreqj_erq_page_new_pc {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#ReqqerMbreject_img_erq_page_new_pc {
		position: absolute;
		width: 22px;
		height: 22px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#ReqqerMbaccept_grp_erq_page_new_pc {
		position: absolute;
		width: 34px;
		height: 34px;
		left: 7px;
		top: 247px;
		overflow: visible;
	}
	#ReqqerMbaccept_bdr_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.ReqqerMbaccept_bdr_erq_page_new_pc {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#ReqqerMbaccept_img_erq_page_new_pc {
		position: absolute;
		width: 23px;
		height: 22px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#ReqqerMbignore_grp_erq_page_new_pc {
		position: absolute;
		width: 34px;
		height: 34px;
		left: 86px;
		top: 248px;
		overflow: visible;
	}
	#ReqqerMbbg_ignore_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.ReqqerMbbg_ignore_erq_page_new_pc {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#ReqqerMbignore_u_erq_page_new_pc {
		position: absolute;
		width: 23px;
		height: 22px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#ReqqerMbblock_grp_erq_page_new_pc {
		position: absolute;
		width: 34px;
		height: 34px;
		left: 126px;
		top: 248px;
		overflow: visible;
	}
	#ReqqerMbblock_bg_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.ReqqerMbblock_bg_erq_page_new_pc {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#ReqqerMbblock_img_erq_page_new_pc {
		position: absolute;
		width: 22px;
		height: 22px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#ReqqerMbcompany_name_request_page_new_ {
		position: absolute;
		width: 153px;
		height: 23px;
		left: 11px;
		top: 190px;
		overflow: visible;
	}
	#ReqqerMboffice-building_req_page_new_p {
		position: absolute;
		width: 22px;
		height: 23px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ReqqerMbOP_conreqsug_erq_page_new_pc {
		left: 27px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 127px;
		height: 20px;
		
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		text-transform: capitalize;
	}
	#ReqqerMbmarquee_op_intro_person {
		left: 7px;
		top: 222px;
		position: absolute;
		overflow: visible;
		width: 154px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		text-transform: capitalize;
	}


#reqandcomreq_pc {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 60px);
    top:60px;
    left:120px;
    
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: requirements;
    --web-view-id: requirements;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bg_design_reqandcomreq {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#My_Requirements_lbl_1_pc_ {
    left: 80px;
    top: 39px;
    position: absolute;
    overflow: visible;
    width: 132px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Community_Requirements_lbl_1_pc {
    left: 263px;
    top: 39px;
    position: absolute;
    overflow: visible;
    width: 196px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#switch_toggle_req {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.switch_toggle_req {
    position: absolute;
    overflow: visible;
    z-index: 1;
    width: 30px;
    height: 14px;
    left: 210px;
    top: 32px;
}
#Line_req_border_cr {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_req_border_cr {
    overflow: visible;
    position: absolute;
    width: 308px;
    height: 1px;
    left: 75px;
    top: 69.5px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
.mediaViewInfo {
    --web-view-name: Myoffering;
    --web-view-id: Myoffering;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: Myoffering;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

#Myoffering_1_pc {
    position: relative;
    overflow: visible;
    width: 391px;
    height: 231px;
    left: 10px;
    top: 10px;
}

#card_border_1_pc {
    fill: rgba(255,255,255,1);
}
.card_border_1_pc {
    position: absolute;
    overflow: visible;
    width: 391px;
    height: 231px;
    left: 27px;
    top: 77px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
#titleBorder_pc {
    fill: rgba(219,242,231,1);
}
.titleBorder_pc {
    position: absolute;
    overflow: visible;
    width: 280px;
    height: 30px;
    left: 120px;
    top: 111px;
}
#Website_Development_title_Pc {
    left: 128px;
    top: 115px;
    position: absolute;
    overflow: visible;
    width: 178px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Budget_lbl_Pc {
    left: 40px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Duration_title_PC {
    left: 40px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 126px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Location_title {
    left: 40px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 124px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#currency_value_pc {
    left: 244px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#delivery_duration_pc {
    left: 244px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#delivery_location_Pc {
    left: 244px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 130px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Group_10_border_view:hover {
    left: 303px;
    top: 262px;
}
#Group_10_border_view {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 300px;
    top: 259px;
    z-index: 1;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_view_moreBtn {
    fill: rgba(219,242,231,1);
  
}
.border_view_moreBtn {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
   
}
#View_more_btn_Pc {
    left: 5px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Rectangle_19_ {
    fill: rgba(255,255,255,1);
}
.Rectangle_19_ {
    position: absolute;
    overflow: visible;
    width: 391px;
    height: 231px;
    left: 428px;
    top: 77px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
#Budget_ {
    left: 441px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Duration_ {
    left: 441px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 126px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Location_ {
    left: 441px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 124px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#n_000_ {
    left: 645px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#n_4_HR_ba {
    left: 645px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Online_Deployment_bb {
    left: 645px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 130px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Group_8:hover {
    left: 443px;
    top: 262px;
}
#Group_8 {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 439px;
    top: 259px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Rectangle_9_bg {
    fill: rgba(219,242,231,1);
}
.Rectangle_9_bg {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#Bargains {
    left: 13px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_9:hover {
    left: 549px;
    top: 262px;
}
#Group_9 {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 546px;
    top: 259px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Rectangle_10_bm {
    fill: rgba(219,242,231,1);
}
.Rectangle_10_bm {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#Disable_bn {
    left: 18px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 56px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_10_bo:hover {
    left: 704px;
    top: 262px;
}
#Group_10_bo {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 701px;
    top: 259px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Rectangle_10_bs {
    fill: rgba(219,242,231,1);
}
.Rectangle_10_bs {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#View_more_bt {
    left: 5px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Rectangle_19_bu {
    fill: rgba(255,255,255,1);
}
.Rectangle_19_bu {
    position: absolute;
    overflow: visible;
    width: 391px;
    height: 231px;
    left: 829px;
    top: 77px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
#Budget_bv {
    left: 842px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Duration_bw {
    left: 842px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 126px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Location_bx {
    left: 842px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 124px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#n_000_by {
    left: 1046px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#n_4_HR_bz {
    left: 1046px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Online_Deployment_b {
    left: 1046px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 130px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Group_8_b:hover {
    left: 843px;
    top: 262px;
}
#Group_8_b {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 840px;
    top: 259px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Rectangle_9_ca {
    fill: rgba(219,242,231,1);
}
.Rectangle_9_ca {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#Bargains_b {
    left: 13px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_9_b:hover {
    left: 950px;
    top: 262px;
}
#Group_9_b {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 947px;
    top: 259px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Rectangle_10_cb {
    fill: rgba(219,242,231,1);
}
.Rectangle_10_cb {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#Disable_cc {
    left: 18px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 56px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_10_cd:hover {
    left: 1108px;
    top: 262px;
}
#Group_10_cd {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 1105px;
    top: 259px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Rectangle_10_ch {
    fill: rgba(219,242,231,1);
}
.Rectangle_10_ch {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#View_more_ci {
    left: 5px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}

#Group_8_ck_border_view:hover {
    left: 41px;
    top: 262px;
}
#Group_8_ck_border_view {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 38px;
    top: 259px;
    overflow: visible;
    z-index: 1;
    cursor: pointer;
    transition: all .3s ease-out;
}
#border_view_bargainBtn {
    fill: rgba(219,242,231,1);
}
.border_view_bargainBtn {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
    cursor: pointer;
}
#Bargains_cp_btn_pc {
    left: 13px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#coding_profile_pic_pc {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 40px;
    top: 91px;
    overflow: visible;
}
#app-development {
    position: absolute;
    width: 65px;
    height: 65px;
    left: 441px;
    top: 91px;
    overflow: visible;
}
#profile {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 842px;
    top: 91px;
    overflow: visible;
}
#Group_41_border_disable:hover {
    left: 148px;
    top: 262px;
}
#Group_41_border_disable {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 145px;
    top: 259px;
    z-index: 1;
    overflow: visible;
    cursor: pointer;
    transition: all .3s ease-out;
}
#border_view_disableBtn {
    fill: rgba(219,242,231,1);
}
.border_view_disableBtn {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
    cursor: pointer;
}
#Disable_dr_btn_pc {
    left: 18px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 56px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    cursor: pointer;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_43_border_lbl {
    position: absolute;
    width: 135px;
    height: 30px;
    right: 10px;
    top: 33px;
    overflow: visible;
    cursor: pointer;
}
#Add_Offerings_lbl_pc {
    left: 36px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(37,37,37,1);
}
#add_lbl_pc {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

.BargainModalDesign{

    background-color: white;
    margin: auto;
    position: relative;
    min-width: 100%;
    max-width: auto;
    height: auto;
}

.titleBargains{
    font-family: 'Times New Roman', Times, serif;
  text-align: center;
   color:black;
   background-color:#DBF2E7;
    }

    @-webkit-keyframes fadeInRight {
        from {
          opacity: 0;
          -webkit-transform: translate3d(100%, 0, 0);
                  transform: translate3d(100%, 0, 0);
        }
      
        to {
          opacity: 1;
          -webkit-transform: translate3d(0, 0, 0);
                  transform: translate3d(0, 0, 0);
        }
      }

    @keyframes fadeInRight {
        from {
          opacity: 0;
          -webkit-transform: translate3d(100%, 0, 0);
                  transform: translate3d(100%, 0, 0);
        }
      
        to {
          opacity: 1;
          -webkit-transform: translate3d(0, 0, 0);
                  transform: translate3d(0, 0, 0);
        }
      }
      
      .fadeInRight {
        -webkit-animation-name: fadeInRight;
                animation-name: fadeInRight;
      }
	.mediaViewInfo {
		--web-view-name: pop up offering;
		--web-view-id: pop_up_offering;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: pop_up_offering;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#pop_up_offering {
		position: absolute;
		width: 308px;
		height: 697px;
        top: 60%;
        left: 50%;
        border-radius: 15px;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: pop up offering;
		--web-view-id: pop_up_offering;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#top_bdr_green_i {
		fill: url(#top_bdr_green_i);
	}
	.top_bdr_green_i {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 308px;
		height: 77px;
		left: 0px;
		top: 0px;
	}
	#serivs_promise_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.serivs_promise_ {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 17px;
		top: 175px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#budget_amount__offerings_pop {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.budget_amount__offerings_pop {
		position: absolute;
		overflow: visible;
		width: 148px;
		height: 41px;
		left: 17px;
		top: 226px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#idk_offerings_pop {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.idk_offerings_pop {
		position: absolute;
		overflow: visible;
		width: 109px;
		height: 41px;
		left: 176px;
		top: 226px;
		border: 2px solid darkcyan;
	}
	#estimated_duration_offerings_p {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.estimated_duration_offerings_p {
		position: absolute;
		overflow: visible;
		width: 148px;
		height: 41px;
		left: 17px;
		top: 281px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#hrs_place_offerings_pop {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.hrs_place_offerings_pop {
		position: absolute;
		overflow: visible;
		width: 109px;
		height: 41px;
		left: 176px;
		top: 281px;
		border: 2px solid darkcyan;
	}
	#del_loc_offerings_pop {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.del_loc_offerings_pop {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 17px;
		top: 386px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#min_order_quantity_offerings_p {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.min_order_quantity_offerings_p {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 17px;
		top: 441px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#desc_offerings_pop {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.desc_offerings_pop {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 63px;
		left: 17px;
		top: 551px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#Offerings_lbl_offerings_pop {
		left: 113px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(37,37,37,1);
	}
	#seb_grp_offerings_pop:hover {
		left: 107px;
		top: 641px;
	}
	#seb_grp_offerings_pop {
		position: absolute;
		width: 97px;
		height: 32px;
		left: 104px;
		top: 636px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#bdr_sub_offerings_pop {
		fill: rgba(219,242,231,1);
	}
	.bdr_sub_offerings_pop {
		position: absolute;
		overflow: visible;
		width: 97px;
		height: 32px;
		left: 0px;
		top: 0px;
	}
	#Submit_offerings_pop {
		left: 17px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 64px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(37,37,37,1);
	}
	#grp_off_upload_offerings_pop {
		position: absolute;
		width: 170px;
		height: 94.284px;
		left: 69px;
		top: 38px;
		overflow: visible;
	}
	#profile_3_offerings_pop {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 50px;
		top: 0px;
		overflow: visible;
	}
	#profile_2_offerings_pop {
		position: absolute;
		width: 42px;
		height: 42px;
		left: 128px;
		top: 14px;
		overflow: visible;
	}
	#profile_1_offerings_pop {
		position: absolute;
		width: 42px;
		height: 42px;
		left: 0px;
		top: 14px;
		overflow: visible;
	}
	#add_offerings_pop {
		position: absolute;
		width: 28.284px;
		height: 28.284px;
		left: 100px;
		top: 66px;
		overflow: visible;
	}
	#cancel_offerings_pop {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 50.142px;
		top: 70px;
		overflow: visible;
	}
	#accept_offerings_pop {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 77.142px;
		top: 70.142px;
		overflow: visible;
	}
	#close2_offerings_pop {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 280.142px;
		top: 6.5px;
        cursor: pointer;
		overflow: visible;
	}
	#pro_and_ser_grp_off {
		position: absolute;
		width: 193px;
		height: 22px;
		left: 49px;
		top: 143px;
		overflow: visible;
	}
	#pro_radio_off {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.pro_radio_off {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 0px;
		top: 4px;
	}
	#product_lbl_off {
		left: 20px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 60px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#serv_radio_ogg {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.serv_radio_ogg {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 120px;
		top: 4px;
	}
	#Service_lbl_off_ {
		left: 140px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 54px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#del_loc_offerings_pop_be {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.del_loc_offerings_pop_be {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 16px;
		top: 336px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#URKL_offerings_input {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.URKL_offerings_input {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 17px;
		top: 496px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}

.mediaViewInfo {
    --web-view-name: view more pop up;
    --web-view-id: view_more_pop_up_off;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: view_more_pop_up_off;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#view_more_pop_up_off {
    position: absolute;
    width: 255px;
    height: 353px;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    border-radius: 20px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: view more pop up;
    --web-view-id: view_more_pop_up_off;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Group_pop_off_vm {
    position: absolute;
    width: 201px;
    height: 288px;
    left: 30px;
    top: 48px;
    overflow: visible;
}
#bg_vm_pop_pff {
    position: absolute;
    width: 253px;
    height: 351px;
    left: -28px;
    top: -47px;
    overflow: visible;
}
#border_des_vm_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(182,222,134,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.border_des_vm_pop {
    position: absolute;
    overflow: visible;
    width: 167px;
    height: 52px;
    left: 0px;
    top: 224px;
}
#Description_viewPop_off_ {
    left: 2px;
    top: 93px;
    position: absolute;
    overflow: visible;
    width: 82px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: italic;
    /* font-weight: bold; */
    font-size: 15px;
    text-decoration: underline;
    color: rgba(37,37,37,1);
   
}
#border_viewpop_off_ {
    fill: rgba(219,242,231,1);
}
.border_viewpop_off_ {
    position: absolute;
    overflow: visible;
    width: 172px;
    height: 30px;
    left: 16px;
    top: 47px;
}
#Website_Development_title_off_vm {
    left: 24px;
    top: 53px;
    position: absolute;
    overflow: visible;
    width: 157px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    /* text-decoration: underline; */
    color: rgba(37,37,37,1);
}
#Scroll_description_vm_pop_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 200px;
    height: 145px;
    left: 0px;
    top: 114px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#des_a_website_off_vm {
    left: 2px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#My_Community_title_off_vm {
    left: 57px;
    top: 200px;
    position: absolute;
    overflow: visible;
    width: 89px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
    text-decoration: underline;
}
#img_view_pop_off {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 96px;
    top: 13px;
    overflow: visible;
}


#close_Offerings_view_pc {
    position: relative;
    width: 20px;
    height: 20px;
    float: right;
    margin: 7px;
    cursor: pointer;
    overflow: visible;
}
.mediaViewInfo {
    --web-view-name: pop up meeting room;
    --web-view-id: pop_up_meeting_room;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pop_up_meeting_room;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pop_up_meeting_room {
    position: absolute;
    width: 371px;
    height: 532px;
    top: 50%;
    left: 50%;
    border-radius: 15px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: pop up meeting room;
    --web-view-id: pop_up_meeting_room;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#meetingRoom_popbg {
    position: absolute;
    width: 371px;
    height: 532px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_border_meetingPop {
    position: absolute;
    width: 371px;
    height: 91.574px;
    left: 0px;
    top: -40.318px;
    overflow: visible;
}
#border_meetpop_ {
    fill: rgba(219,242,231,1);
}
.border_meetpop_ {
    width: 91.574px;
    height: 371px;
    position: absolute;
    overflow: visible;
    -webkit-transform: translate(0px, 40.318px) matrix(1,0,0,1,-44.287,-180.0308) rotate(90deg);
            transform: translate(0px, 40.318px) matrix(1,0,0,1,-44.287,-180.0308) rotate(90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
}
#Meeting_Request_lbl_mp {
    left: 111px;
    top: 52.318px;
    position: absolute;
    overflow: visible;
    width: 161px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#txtagenda_pop_val {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtagenda_pop_val {
    position: absolute;
    overflow: visible;
    width: 301.728px;
    height: 55px;
    left: 35px;
    top: 295px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius: 10px;
}
#txtMeeting_Room_pop_val {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtMeeting_Room_pop_val {
    position: absolute;
    overflow: visible;
    width: 301.728px;
    height: 45px;
    left: 35px;
    top: 81px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius: 10px;
}
#txtDate_pop_val {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtDate_pop_val {
    position: absolute;
    overflow: visible;
    width: 191.228px;
    height: 37px;
    left: 145.5px;
    top: 146px;
    font-size: 15px;
    padding: 10px;
    border: 2px solid darkcyan;
    border-radius: 10px;
}
#Meeting_On_lbl_mp {
    left: 20px;
    top: 151px;
    position: absolute;
    overflow: visible;
    width: 116px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#txtprevious_remarks_val {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtprevious_remarks_val {
    position: absolute;
    overflow: visible;
    width: 301.728px;
    height: 72px;
    left: 35px;
    top: 203px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius: 10px;
}
#txtremarks_val_mp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtremarks_val_mp {
    position: absolute;
    overflow: visible;
    width: 301.728px;
    height: 55px;
    left: 34.636px;
    top: 370px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius: 10px;
}
#Group_btn_accept_:hover {
    left: 32px;
    top: 469px;
}
#Group_btn_accept_ {
    position: absolute;
    width: 97px;
    height: 32px;
    left: 29px;
    top: 464px;
    overflow: visible;
    border-radius: 10px;
    transition: all .3s ease-out;
}
#border_grp_btn_acc {
    fill: rgba(219,242,231,1);
}
.border_grp_btn_acc {
    position: absolute;
    overflow: visible;
    width: 97px;
    height: 32px;
    left: 0px;
    top: 0px;
}
#Accept_btn_acc {
    left: 22px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_reject_btn_rej:hover {
    left: 144px;
    top: 469px;
}
#Group_reject_btn_rej {
    position: absolute;
    width: 97px;
    height: 32px;
    left: 141px;
    top: 464px;
    overflow: visible;
    border-radius: 10px;
    transition: all .3s ease-out;
}
#border_btn_rej_ {
    fill: rgba(219,242,231,1);
}
.border_btn_rej_ {
    position: absolute;
    overflow: visible;
    width: 97px;
    height: 32px;
    left: 0px;
    top: 0px;
}
#Reject_lbl_rej {
    left: 26px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 46px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_btn_propose_pr:hover {
    left: 254px;
    top: 469px;
}
#Group_btn_propose_pr {
    position: absolute;
    width: 97px;
    height: 32px;
    left: 251px;
    top: 464px;
    overflow: visible;
    border-radius: 10px;
    transition: all .3s ease-out;
}
#border_pro_btn_pr {
    fill: rgba(219,242,231,1);
}
.border_pro_btn_pr {
    position: absolute;
    overflow: visible;
    width: 97px;
    height: 32px;
    left: 0px;
    top: 0px;
}
#Propose_btn_pro__lbl {
    left: 18px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#closeiconmeetingpop {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 344px;
    top: 10px;
    cursor: pointer;
    overflow: visible;
}
.mediaViewInfo {
    --web-view-name: Community offerings;
    --web-view-id: Community_offerings_Pc_co;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: Community_offerings_Pc_co;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#Community_offerings_Pc_co {
    position: relative;
    width: 391px;
    height: 231px;
    left: 10px;
    top: 10px;
  
}
#card_border_co_pc {
    fill: rgba(255,255,255,1);
}
.card_border_co_pc {
    position: absolute;
    overflow: visible;
    width: 391px;
    height: 231px;
    left: 27px;
    top: 77px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
#title_border_co_pc {
    fill: rgba(219,242,231,1);
}
.title_border_co_pc {
    position: absolute;
    overflow: visible;
    width: 280px;
    height: 30px;
    left: 120px;
    top: 111px;
}
#Website_Development_title_co_pc {
    left: 128px;
    top: 115px;
    position: absolute;
    overflow: visible;
    width: 178px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Budget_title_co_pc {
    left: 40px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Duration_title_co_pc {
    left: 40px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 126px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Location_title_co_pc {
    left: 40px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 124px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#budget_amount_co_pc {
    left: 244px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#duration_co_pc {
    left: 244px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Online_Deployment_co_pc {
    left: 244px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 130px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}

#Group_8_border_btn:hover {
    left: 188px;
    top: 262px;
}
#Group_8_border_btn {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 185px;
    top: 259px;
    z-index: 1;
    overflow: visible;
    cursor: pointer;
    transition: all .3s ease-out;
}
#outline_border_btn_ {
    fill: rgba(219,242,231,1);
}
.outline_border_btn_ {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#Bargains_bp_btn_pc {
    left: 13px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_45_border_btn:hover {
    left: 303px;
    top: 262px;
}
#Group_45_border_btn {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 299px;
    top: 259px;
    z-index:1;
    overflow: visible;
    cursor: pointer;
    transition: all .3s ease-out;
}
#outline_border_btn {
    fill: rgba(219,242,231,1);
}
.outline_border_btn {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#View_more_by_btn {
    left: 5px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}

#coding_co_profile_pc {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 40px;
    top: 91px;
    z-index: 1;
    overflow: visible;
}


@-webkit-keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }


@keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInRight {
    -webkit-animation-name: fadeInRight;
            animation-name: fadeInRight;
  }
.mediaViewInfo {
    --web-view-name: pop up-2 Offering;
    --web-view-id: pop_up-2_Bargain_pc_bar;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pop_up-2_Bargain_pc_bar;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pop_up-2_Bargain_pc_bar {
    position: absolute;
    width: 345px;
    height: 418px;
    top: 50%;
    left: 50%;
    border-radius: 15px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: pop up-2 Offering;
    --web-view-id: pop_up-2_Offering;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bargainBg_pc {
    position: absolute;
    width: 345px;
    height: 418px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#outerborder_pc_bar {
    fill: rgba(219,242,231,1);
}
.outerborder_pc_bar {
    width: 118px;
    height: 345px;
    position: absolute;
    overflow: visible;
    -webkit-transform: matrix(1,0,0,1,113.5,-170.5) rotate(-90deg);
            transform: matrix(1,0,0,1,113.5,-170.5) rotate(-90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
}
#Bargain_lbl_pc_ {
    left: 139px;
    top: 16px;
    position: absolute;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#Group_bargained_amount_pc {
    position: absolute;
    width: 296px;
    height: 92px;
    left: 25px;
    top: 88px;
    overflow: visible;
}
#bargained_amount_bar_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.bargained_amount_bar_pc {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 36px;
    left: 0px;
    top: 0px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#currency_barg_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.currency_barg_pc {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 36px;
    left: 158px;
    top: 0px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#estimated_time_bar_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.estimated_time_bar_pc {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 36px;
    left: 0px;
    top: 56px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#estimated_hours_pc_bar {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.estimated_hours_pc_bar {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 36px;
    left: 158px;
    top: 56px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#needToBedelivered_pc_barg {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.needToBedelivered_pc_barg {
    position: absolute;
    overflow: visible;
    width: 15px;
    height: 15px;
    left: 35px;
    top: 204px;
}
#Need_to_be_Delivered_for_Locat_pc {
    left: 56px;
    top: 200px;
    position: absolute;
    overflow: visible;
    width: 255px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#remarks_info_pc_bar {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.remarks_info_pc_bar {
    position: absolute;
    overflow: visible;
    width: 296px;
    height: 69px;
    left: 25px;
    top: 241px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#Group_update_pc_bar:hover {
    left: 58px;
    top: 347px;
}
#Group_update_pc_bar {
    position: absolute;
    width: 101px;
    height: 37px;
    left: 55px;
    top: 343px;
    overflow: visible;
    transition: all .3s ease-out;
}
#update_border_pc_bar {
    fill: rgba(219,242,231,1);
}
.update_border_pc_bar {
    position: absolute;
    overflow: visible;
    width: 101px;
    height: 37px;
    left: 0px;
    top: 0px;
}
#Update_lbl_pc_bar {
    left: 23px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 56px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_discard_pc_bar:hover {
    left: 192px;
    top: 347px;
    transition: all .3s ease-out;
}
#Group_discard_pc_bar {
    position: absolute;
    width: 101px;
    height: 37px;
    left: 189px;
    top: 343px;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_discard_pc_bar {
    fill: rgba(219,242,231,1);
}
.border_discard_pc_bar {
    position: absolute;
    overflow: visible;
    width: 101px;
    height: 37px;
    left: 0px;
    top: 0px;
}
#Discard_lbl_pc_ {
    left: 23px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#close_barg_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 317.142px;
    top: 6.5px;
    cursor: pointer;
    overflow: visible;
}
.mediaViewInfo {
    --web-view-name: requirements;
    --web-view-id: requirements;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: requirements;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#My_Requirements_pc_r {
    position: relative;
    overflow: visible;
    width: 391px;
    height: 231px;
    left: 10px;
    top: 10px; 
}
#card_border_requirementPC {
    fill: rgba(255,255,255,1);
}
.card_border_requirementPC {
    position: absolute;
    overflow: visible;
    width: 391px;
    height: 231px;
    left: 27px;
    top: 77px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
#name_border_requirementPC {
    fill: rgba(219,242,231,1);
}
.name_border_requirementPC {
    position: absolute;
    overflow: visible;
    width: 280px;
    height: 30px;
    left: 120px;
    top: 111px;
}
#Website_Development_lbl_reqPC {
    left: 128px;
    top: 115px;
    position: absolute;
    overflow: visible;
    width: 178px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Budget_req_pc {
    left: 40px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Duration_lbl_reqPc {
    left: 40px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 126px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Location_lbl_reqPc {
    left: 40px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 124px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#budget_amount_req {
    left: 244px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#duration_req_pc {
    left: 244px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Online_Deployment_value_req_pc {
    left: 244px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 130px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Group_10_btn_reqpc:hover {
    left: 303px;
    top: 262px;
}
#Group_10_btn_reqpc {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 300px;
    top: 259px;
    z-index: 1;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_btn_reqpc {
    fill: rgba(219,242,231,1);
}
.border_btn_reqpc {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#View_more_pc_req_lbl {
    left: 5px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}


#Group_8_btn_reqpc:hover {
    left: 41px;
    top: 262px;
}
#Group_8_btn_reqpc {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 38px;
    top: 259px;
    z-index: 1;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_btn_reqpc {
    fill: rgba(219,242,231,1);
}
.border_btn_reqpc {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#Bids_lbl_req_pc {
    left: 30px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 32px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#coding_img_reqprofile {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 40px;
    top: 91px;
    overflow: visible;
}

#Group_41_btn_reqpc:hover {
    left: 148px;
    top: 262px;
}
#Group_41_btn_reqpc {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 145px;
    top: 259px;
    z-index: 1;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_btn_reqpc {
    fill: rgba(219,242,231,1);
}
.border_btn_reqpc {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#Disable_lbl_req_pc {
    left: 18px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 56px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_44_border_addReq {
    position: absolute;
    width: 167px;
    height: 30px;
    right: 10px;
    top: 28px;
    overflow: visible;
    cursor: pointer;
}
#Add_Requirements_lbl_btn_req {
    left: 36px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 132px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(37,37,37,1);
}
#add_req_lbl_btn_ {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

.BidsModalDesign{
    background-color:white;
    margin: auto;
    position: relative;
    min-width: 100%;
    max-width: auto;
    height: auto;
}

.titleBids{
    font-family: 'Times New Roman', Times, serif;
  text-align: center;
   color:black;
   background-color:#DBF2E7;
    }

    @-webkit-keyframes fadeInLeft {
        from {
          opacity: 0;
          -webkit-transform: translate3d(-100%, 0, 0);
                  transform: translate3d(-100%, 0, 0);
        }
      
        to {
          opacity: 1;
          -webkit-transform: translate3d(0, 0, 0);
                  transform: translate3d(0, 0, 0);
        }
      }

    @keyframes fadeInLeft {
        from {
          opacity: 0;
          -webkit-transform: translate3d(-100%, 0, 0);
                  transform: translate3d(-100%, 0, 0);
        }
      
        to {
          opacity: 1;
          -webkit-transform: translate3d(0, 0, 0);
                  transform: translate3d(0, 0, 0);
        }
      }
      
      .fadeInLeft {
        -webkit-animation-name: fadeInLeft;
                animation-name: fadeInLeft;
      }

	.mediaViewInfo {
		--web-view-name: pop up requirement;
		--web-view-id: pop_up_requirement;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: pop_up_requirement;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#pop_up_requirement {
		position: absolute;
		width: 308px;
		height: 639px;
        top: 60%;
        left: 50%;
        border-radius: 15px;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: pop up requirement;
		--web-view-id: pop_up_requirement;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#low_poly_banner_design_pop_req_i {
		fill: url(#low_poly_banner_design_pop_req_i);
	}
	.low_poly_banner_design_pop_req_i {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 307px;
		height: 77px;
		left: 0px;
		top: 0px;
	}
	#serv_pro_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.serv_pro_pop_requu {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 17px;
		top: 177px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#bud_amt_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.bud_amt_pop_requu {
		position: absolute;
		overflow: visible;
		width: 148px;
		height: 41px;
		left: 20px;
		top: 228px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#idk_drop_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.idk_drop_pop_requu {
		position: absolute;
		overflow: visible;
		width: 109px;
		height: 41px;
		left: 179px;
		top: 228px;
		border: 2px solid darkcyan;
	}
	#Dl_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Dl_pop_requu {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 20px;
		top: 338px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#cat_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.cat_pop_requu {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 20px;
		top: 393px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#desc_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.desc_pop_requu {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 63px;
		left: 20px;
		top: 498px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#Requirements_lbl_pop_requu {
		left: 93px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 122px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(37,37,37,1);
	}
	#submit_grp_pop_requu:hover {
		left: 110px;
		top: 586px;
	}
	#submit_grp_pop_requu {
		position: absolute;
		width: 97px;
		height: 32px;
		left: 107px;
		top: 581px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#bdr_sub_pop_requu {
		fill: rgba(219,242,231,1);
	}
	.bdr_sub_pop_requu {
		position: absolute;
		overflow: visible;
		width: 97px;
		height: 32px;
		left: 0px;
		top: 0px;
	}
	#Submit_pop_requu {
		left: 17px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 64px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(37,37,37,1);
	}
	#Enter_your_name_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Enter_your_name_pop_requu {
		position: absolute;
		overflow: visible;
		width: 134px;
		height: 36px;
		left: 154px;
		top: 283px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#Delivery_Deadline_lbl_pop_requ {
		left: 20px;
		top: 290px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(37,37,37,1);
	}
	#grp_req_upload_pop_requu {
		position: absolute;
		width: 167px;
		height: 94.284px;
		left: 72px;
		top: 38px;
		overflow: visible;
	}
	#profile__3_pop_requu {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 47px;
		top: 0px;
		overflow: visible;
	}
	#profile_2_pop_requu {
		position: absolute;
		width: 42px;
		height: 42px;
		left: 125px;
		top: 14px;
		overflow: visible;
	}
	#profile_1_pop_requu {
		position: absolute;
		width: 42px;
		height: 42px;
		left: 0px;
		top: 14px;
		overflow: visible;
	}
	#add_more_pop_requu {
		position: absolute;
		width: 28.284px;
		height: 28.284px;
		left: 97px;
		top: 66px;
		overflow: visible;
	}
	#close_pop_requu_ {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 47.142px;
		top: 70px;
		overflow: visible;
	}
	#accept_pop_requu {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 74.142px;
		top: 70.142px;
		overflow: visible;
	}
	#close2_pop_requu {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 280.142px;
		top: 6.5px;
		overflow: visible;
	}
	#Group_radio_pop_requu {
		position: absolute;
		width: 193px;
		height: 22px;
		left: 49px;
		top: 142px;
		overflow: visible;
	}
	#rad_pro_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.rad_pro_pop_requu {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 0px;
		top: 5px;
	}
	#Product_pop_requu {
		left: 20px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 60px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#rad_ser_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.rad_ser_pop_requu {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 120px;
		top: 5px;
	}
	#Service_pop_requu {
		left: 140px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 54px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#req_quantity_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.req_quantity_pop_requu {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 20px;
		top: 446px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}

.mediaViewInfo {
    --web-view-name: view more pop up;
    --web-view-id: view_more_pop_up_req;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: view_more_pop_up_req;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#view_more_pop_up_req {
    position: absolute;
    width: 255px;
    height: 353px;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    border-radius: 20px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: view more pop up;
    --web-view-id: view_more_pop_up_req;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#group_popup_req_view {
    position: absolute;
    width: 201px;
    height: 288px;
    left: 30px;
    top: 48px;
    overflow: visible;
}
#bg_view_req_pop {
    position: absolute;
    width: 253px;
    height: 351px;
    left: -28px;
    top: -47px;
    overflow: visible;
}
#Description_req_view_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(182,222,134,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Description_req_view_pop {
    position: absolute;
    overflow: visible;
    width: 167px;
   
    left: 0px;
    z-index: 1;
    top: 224px;
}
#Description_viewPop_req {
    left: 2px;
    top: 93px;
    position: absolute;
    overflow: visible;
    width: 82px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: italic;
    /* font-weight: bold; */
    font-size: 15px;
    text-decoration: underline;
    color: rgba(37,37,37,1);
   
}
#website_title_req_view {
    fill: rgba(219,242,231,1);
}
.website_title_req_view {
    position: absolute;
    overflow: visible;
    width: 172px;
    height: 30px;
    left: 16px;
    top: 47px;
}
#Website_Development_title_req_vm {
    left: 24px;
    top: 53px;
    position: absolute;
    overflow: visible;
    width: 157px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    /* text-decoration: underline; */
    color: rgba(37,37,37,1);
}
#Scroll_description_title_pop {
    mix-blend-mode: normal;
    position: absolute;
    width: 200px;
    height: 150px;
    left: 0px;
    top: 114px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Create_a_website_for__online_req {
    left: 2px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#My_Community_req_pop {
    left: 57px;
    top: 200px;
    position: absolute;
    overflow: visible;
    width: 89px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
    text-decoration: underline;
}
#coding_req_pop_view {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 96px;
    top: 13px;
    overflow: visible;
}


#close_Req_viewmore_pc {
    position: relative;
    width: 20px;
    height: 20px;
    float: right;
    margin: 7px;
    cursor: pointer;
    overflow: visible;
}
.mediaViewInfo {
    --web-view-name: Community requirements ;
    --web-view-id: Community_requirements_;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: Community_requirements_;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
 /* #Community_requirments_Pc_co {
    position: relative;
    width: 1254px;
    height: 637px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: Community requirements ;
    --web-view-id: Community_requirements_;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
 }  */

 #Community_requirments_Pc_co {
    position: relative;
    overflow: visible;
    width: 391px;
    height: 225px;
    left: 10px;
    top: 10px;
} 

#card_border_comreq {
    fill: rgba(255,255,255,1);
}
.card_border_comreq {
    position: absolute;
    overflow: visible;
    width: 391px;
    height: 225px;
    left: 27px;
    top: 77px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
#name_border_comreq {
    fill: rgba(219,242,231,1);
}
.name_border_comreq {
    position: absolute;
    overflow: visible;
    width: 280px;
    height: 30px;
    left: 120px;
    top: 111px;
}
#Website_Development_lbl_comreq {
    left: 128px;
    top: 115px;
    position: absolute;
    overflow: visible;
    width: 178px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Budget_lbl_comreq {
    left: 40px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Duration_lbl_comreq {
    left: 40px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 126px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Location_lbl_comreq {
    left: 40px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 124px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#budget_com {
    left: 244px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#delivery_deadline_comreq_pc {
    left: 244px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#delivery_location_comreq_pc {
    left: 244px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 130px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Group_bids_btn_comreq:hover {
    left: 192px;
    top: 262px;
}
#Group_bids_btn_comreq {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 189px;
    top: 259px;
    z-index: 1;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_btn_comreq {
    fill: rgba(219,242,231,1);
}
.border_btn_comreq {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#Bids_lbl_btn_comreq {
    left: 30px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 32px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#coding_lbl_com_req {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 40px;
    top: 91px;
    overflow: visible;
}
#Group_view_btn_req:hover {
    left: 303px;
    top: 262px;
}
#Group_view_btn_req {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 300px;
    top: 259px;
    z-index: 1;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_comreq_btn_ {
    fill: rgba(219,242,231,1);
}
.border_comreq_btn_ {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#View_more_btn_comreq {
    left: 5px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
@-webkit-keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
@keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInLeft {
    -webkit-animation-name: fadeInLeft;
            animation-name: fadeInLeft;
  }
.mediaViewInfo {
    --web-view-name: pop up-2 Req;
    --web-view-id: pop_up-2_Req;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pop_up-2_Req;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pop_up-2_Req_quo_model {
    position: absolute;
    width: 346px;
    height: 418px;
    top: 50%;
    left: 50%;
    border-radius: 15px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: pop up-2 Req;
    --web-view-id: pop_up-2_Req;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#quotation_bg_img {
    position: absolute;
    width: 346px;
    height: 418px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Quotation_border_pop {
    fill: rgba(219,242,231,1);
}
.Quotation_border_pop {
    width: 118px;
    height: 346px;
    position: absolute;
    overflow: visible;
    -webkit-transform: matrix(1,0,0,1,-56,-171) rotate(-90deg);
            transform: matrix(1,0,0,1,-56,-171) rotate(-90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
}
#Quotation_lbl_pop {
    left: 128px;
    top: 16px;
    position: absolute;
    overflow: visible;
    width: 91px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#estimated_quo_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.estimated_quo_pop {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 36px;
    left: 25px;
    top: 88px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#currency_quo_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.currency_quo_pop {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 36px;
    left: 183px;
    top: 88px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#estimated_time_quo_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.estimated_time_quo_pop {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 36px;
    left: 25px;
    top: 144px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#estimated_days_pop_quo {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.estimated_days_pop_quo {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 36px;
    left: 183px;
    top: 144px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#Group_check_can_be_delivered {
    position: absolute;
    width: 234px;
    height: 22px;
    left: 56px;
    top: 202px;
    overflow: visible;
}
#chck_pop_quo {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chck_pop_quo {
    position: absolute;
    overflow: visible;
    width: 15px;
    height: 15px;
    left: 0px;
    top: 4px;
}
#Can_be_Delivered_to_location_lbl_quo {
    left: 21px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 214px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#remarks_quo_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.remarks_quo_pop {
    position: absolute;
    overflow: visible;
    width: 296px;
    height: 69px;
    left: 25px;
    top: 241px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#close_pop_quo {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 317.142px;
    top: 6.5px;
    cursor: pointer;
    overflow: visible;
}
#Group_btn_up_pop:hover {
    left: 58px;
    top: 347px;
}
#Group_btn_up_pop {
    position: absolute;
    width: 101px;
    height: 37px;
    left: 55px;
    top: 343px;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_btn_quo_ {
    fill: rgba(219,242,231,1);
}
.border_btn_quo_ {
    position: absolute;
    overflow: visible;
    width: 101px;
    height: 37px;
    left: 0px;
    top: 0px;
}
#Update_lbl_quo_ {
    left: 23px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 56px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_btn_dis_pop:hover {
    left: 192px;
    top: 347px;
    transition: all .3s ease-out;
}
#Group_btn_dis_pop {
    position: absolute;
    width: 101px;
    height: 37px;
    left: 189px;
    top: 343px;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_btn_dis_ {
    fill: rgba(219,242,231,1);
}
.border_btn_dis_ {
    position: absolute;
    overflow: visible;
    width: 101px;
    height: 37px;
    left: 0px;
    top: 0px;
}
#Discard_lbl_pop {
    left: 23px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}

.mediaViewInfo {
    --web-view-name: req mob;
    --web-view-id: req_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: req_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#add_req_mob {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 25px;
    top: 70px;
    overflow: visible;
}
#Com_REQM_req_mob {
    position: absolute;
    width: 100%;
    height: calc(100% - 185px);
    left: 0px;
    top: 120px;
    background-color: rgba(255,255,255,1);
    overflow-x: hidden;
    overflow-y: auto;
    --web-view-name: req mob;
    --web-view-id: req_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Com_REQM_req_mob::-webkit-scrollbar {
  display: none;
}

#Com_REQM_Group_128 {
    position: absolute;
    width: 100%;
    height: 302px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Com_REQM_desgin_3_com_off {
    fill: rgba(248,248,248,1);
}
.Com_REQM_desgin_3_com_off {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 207px;
    left: 0px;
    top: 0px;
}
#Com_REQM_recently_added_com_off {
    /* left: 98px; */
    top: 36px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#Com_REQM_some_offerings_com_of {
    /* left: 93px; */
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#Com_REQM_Scroll_Group_6 {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 231px;
    left: 0px;
    top: 71px;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}

#Com_REQM_Scroll_Group_6::-webkit-scrollbar {
    display: none;
}


#Com_REQM_Group_127 {
    position: absolute;
    width: 100%;
    height: 99px;
    left: 0px;
    top: 331px;
    overflow: visible;
}
#Com_REQM_desgin_2_ocom_off {
    position: absolute;
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Com_REQM_design_1_com_of {
    fill: rgba(0,0,0,0.569);
}
.Com_REQM_design_1_com_of {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
}
#Com_REQM_And_tfreqeventcom_off {
    /* left: 20px; */
    top: 36px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;

    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#Com_REQM_trding_this_week_eqnt_com_off {
    /* left: 113px; */
    top: 467px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#Com_REQM_top_product_oent_com_off {
    /* left: 147px; */
    top: 449px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#Com_REQM_Scroll_Group_7 {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 499px;
    display: flex;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Com_REQM_n__card_grp_com_off {
    position: relative;
    width: 339px;
    height: 888px;
    left: calc((50% - 160px)/2);
    top: 7px;
    overflow: visible;
}

#REQM_card_1_reqoffevent_off {
    position: relative;
    width: 170px;
    height: 204px;
    /* left: 10px; */
    top: 16px;
    display: inline-flex;
    flex-direction: row;
    margin: 10px;
    overflow: visible;
}
#REQM_Base_reqoffevent_off {
    fill: rgba(255,255,255,1);
}
.REQM_Base_reqoffevent_off {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 188px;
    height: 188px;
    left: 0px;
    top: 0px;
}
#REQM_bdr_1_reqoffevent_off {
    fill: rgba(255,79,4,0.09);
}
.REQM_bdr_1_reqoffevent_off {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 22px;
    left: 130px;
    top: 177px;
}
#REQM_reqoffevent_of {
    left: 140px;
    top: 183px;
    position: absolute;
    overflow: visible;
    width: 21px;
    white-space: nowrap;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#REQM_Single_Tour_offevent_off {
    left: 0px;
    top: 177px;
    position: absolute;
    overflow: visible;
    width: 51px;
    white-space: nowrap;
    line-height: 20px;
    margin-top: -5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.3333333206176758px;
}
#REQM_date_reqoffevent_off {
    left: 0px;
    top: 193px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    line-height: 18px;
    margin-top: -5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    color: rgba(17,17,17,1);
}
#REQM_online-shopping_reqoffevent_of {
    position: absolute;
    width: 170px;
    height: 170px;
    /* left: 30px; */
    /* top: 30px; */
    overflow: visible;
}
#REQM_Group_131 {
    position: absolute;
    width: 27px;
    height: 27px;
    left: 2px;
    top: 3px;
    overflow: visible;
}
#REQM_Ellipse_1 {
    fill: rgba(255,255,255,1);
}
.REQM_Ellipse_1 {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 45px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#REQM_trophy_reqoffevent_off {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 3px;
    top: 4px;
    overflow: visible;
}
#REQM_Group_130 {
    position: absolute;
    width: 24px;
    height: 15px;
    left: 30px;
    top: 9px;
    overflow: visible;
}
#REQM_Rectangle_31 {
    fill: rgba(255,255,255,1);
}
.REQM_Rectangle_31 {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 42px;
    height: 33px;
    left: 0px;
    top: 0px;
}
#REQM_n_234_reqoffevent_off {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 23px;
    white-space: nowrap;
    line-height: 20px;
    margin-top: -5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#REQM_Group_132 {
    position: absolute;
    width: 27px;
    height: 27px;
    left: 141px;
    top: 3px;
    overflow: visible;
}
#REQM_Ellipse_2 {
    fill: rgba(255,255,255,1);
}
.REQM_Ellipse_2 {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 45px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#REQM_share_1_reqoffevent_off {
    position: absolute;
    width: 17px;
    height: 17px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#REQM_small_4_trending_com_off {
    position: relative;
    width: 160px;
    height: 197px;
    left: 0px;
    top: 0px;
    margin: 5px;
    overflow: visible;
}
#REQM_all_bdr_small_4_trending_com {
    fill: rgba(245,245,245,1);
}
.REQM_all_bdr_small_4_trending_com {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 178px;
    height: 215px;
    left: 0px;
    top: 0px;
}
#REQM_Image_small_4_trending_com {
    position: absolute;
    width: 135px;
    height: 134px;
    left: 12px;
    top: 23px;
    overflow: visible;
}
#REQM_bdr_amount_small_4_trending_co {
    fill: rgba(255,255,255,1);
}
.REQM_bdr_amount_small_4_trending_co {
    position: absolute;
    overflow: visible;
    width: 39px;
    height: 21px;
    left: 116px;
    top: 160px;
}
#REQM_small_4_trending_com {
    left: 128px;
    top: 165px;
    position: absolute;
    overflow: visible;
    width: 17px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#REQM_NikeCrt_Air_small_4_trending_c {
    left: 2.986px;
    top: 158.311px;
    position: absolute;
    overflow: visible;
    width: 110px;
    height: 14px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#REQM_Sinr_small_4_trending_com_off {
    left: 3px;
    top: 172px;
    position: absolute;
    overflow: visible;
    width: 77px;
    white-space: nowrap;
    line-height: 14px;
    margin-top: -2px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#REQM_number_small_4_trending_com {
    fill: rgba(255,255,255,1);
}
.REQM_number_small_4_trending_com {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 13px;
    left: 26px;
    top: 6px;
}
#REQM_trohpie_bdr_small_4_tre {
    fill: rgba(255,255,255,1);
}
.REQM_trohpie_bdr_small_4_tre {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 1px;
    top: 1px;
}
#REQM_trophy_small_4_trending_c {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 3px;
    top: 3px;
    overflow: visible;
}
#REQM_small_4_trending_com_off_cz {
    left: 28px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 19px;
    white-space: nowrap;
    line-height: 16px;
    margin-top: -4px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    color: rgba(17,17,17,1);
}
#REQM_sharebrsmall_4_trending_com_of {
    fill: rgba(255,255,255,1);
}
.REQM_sharebrsmall_4_trending_com_of {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 135px;
    top: 1px;
}
#REQM_share_2_com_off {
    position: absolute;
    width: 15px;
    height: 14px;
    left: 140px;
    top: 6px;
    overflow: visible;
}
#REQM_big_1_trending_off {
    position: relative;
    width: 339px;
    height: 427px;
    left: 0px;
    top: 0px;
    margin-top: 5px;
    overflow: visible;
}
#REQM_bdr_all_trending_off {
    fill: rgba(245,245,246,1);
}
.REQM_bdr_all_trending_off {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 357px;
    height: 445px;
    left: 0px;
    top: 0px;
}
#REQM_bdr_amount_trending_off {
    fill: rgba(255,255,255,1);
}
.REQM_bdr_amount_trending_off {
    position: absolute;
    overflow: visible;
    width: 43px;
    height: 21px;
    left: 283px;
    top: 371px;
}
#REQM_n_9_trending_off {
    left: 293px;
    top: 373px;
    position: absolute;
    overflow: visible;
    width: 23px;
    white-space: nowrap;
    text-align: center;
    font-family: Poppins-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#REQM_Image_trending__off {
    position: absolute;
    width: 335px;
    height: 355px;
    /* left: 6px; */
    /* top: 35px; */
    overflow: visible;
}
#REQM_Nike_Air_Force_1_Sha_trending_ {
    left: 18px;
    top: 366px;
    position: absolute;
    overflow: visible;
    width: 167px;
    white-space: nowrap;
    line-height: 19px;
    margin-top: -2px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.1818181800842285px;
}
#REQM_Single_Tour_trending_off {
    left: 18px;
    top: 387px;
    position: absolute;
    overflow: visible;
    width: 77px;
    white-space: nowrap;
    line-height: 18px;
    margin-top: -4px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#REQM_Group_125 {
    position: absolute;
    width: 24px;
    height: 23px;
    left: 2px;
    top: 2px;
    overflow: visible;
}
#REQM_bdr_1_trending_off_PC {
    fill: rgba(255,255,255,1);
}
.REQM_bdr_1_trending_off_PC {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 23px;
    left: 0px;
    top: 0px;
}
#REQM_trophy_trending_off_PC {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 2px;
    top: 2px;
    overflow: visible;
}
#REQM_Group_124 {
    position: absolute;
    width: 24px;
    height: 14px;
    left: 27px;
    top: 7px;
    overflow: visible;
}
#REQM_likes_trending_off_PC {
    fill: rgba(255,255,255,1);
}
.REQM_likes_trending_off_PC {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 13px;
    left: 0px;
    top: 1px;
}
#REQM_n_234_like_trending_off_PC {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 23px;
    white-space: nowrap;
    line-height: 18px;
    margin-top: -4px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#REQM_Group_133 {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 315px;
    top: 1px;
    overflow: visible;
}
#REQM_share_bdr_small_4_trending_com {
    fill: rgba(255,255,255,1);
}
.REQM_share_bdr_small_4_trending_com {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 0px;
    top: 0px;
}
#REQM_share_2_com_of {
    position: absolute;
    width: 15px;
    height: 14px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
	.SuggusMbmediaViewInfo {
		--web-view-name: suggestions mob;
		--web-view-id: suggestions_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: suggestions_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#SuggusMbsuggestions_mob {
		position: absolute;
        width: 100%;
        height:calc(100% - 105px);
        left: 0px;
        top: 45px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: suggestions mob;
		--web-view-id: suggestions_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#SuggusMbdesgin_con_page_new_pc {
		fill: rgba(248,248,248,1);
	}
	.SuggusMbdesgin_con_page_new_pc {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 203px;
		left: 0px;
		top: 0px;
	}
	#SuggusMbScroll_Group_10 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 100%;
		left: calc((100% - 375px)/2);
		top: 0px;
		display: flex;
        flex-wrap: wrap;
        flex-direction: row;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SuggusMbcard_1_suggestion_page_new {
		position: relative;
		width: 165px;
		height: 285px;
		/* left: 15px; */
        margin: 5px;
		top: 16px;
		overflow: visible;
	}
	#SuggusMbbdr_card_1_sug_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.SuggusMbbdr_card_1_sug_sug_page_pc_new {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 173px;
		height: 293px;
		left: 0px;
		top: 0px;
	}
	#SuggusMbAnime_Edit_sug_page_pc_new {
		position: absolute;
		width: 134px;
		height: 127px;
		left: 16px;
		top: 31px;
		overflow: visible;
	}
	#SuggusMbjob_work_new_layout {
		position: absolute;
		width: 151px;
		height: 23px;
		left: 11px;
		top: 162px;
		overflow: visible;
	}
	#SuggusMbjob-seeker_sug_page_pc_new {
		position: absolute;
		width: 22px;
		height: 23px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SuggusMbrecently_affreqevent_sug_page_ {
		left: 26px;
		top: 3px;
		position: absolute;
		overflow: hidden;
		width: 126px;
		height: 20px;
	
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: capitalize;
	}
	#SuggusMbMohammed_Affan_sug_page_pc_new {
		left: 1px;
		top: 7px;
		position: absolute;
		overflow: hidden;
		width: 165px;
		height: 20px;
		/* line-height: 49px;
		margin-top: -17px; */
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
	}
	#SuggusMbfollow_grp_sug_page_pc_new {
		position: absolute;
		width: 34px;
		height: 34px;
		left: 46px;
		top: 245px;
		overflow: visible;
	}
	#SuggusMbbdr_gollow_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.SuggusMbbdr_gollow_sug_page_pc_new {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#SuggusMbfolow_img_sug_page_pc_new {
		position: absolute;
		width: 22px;
		height: 22px;
		left: 7px;
		top: 6px;
		overflow: visible;
	}
	#SuggusMbconnect_grp_sug_page_pc_new {
		position: absolute;
		width: 34px;
		height: 34px;
		left: 7px;
		top: 245px;
		overflow: visible;
	}
	#SuggusMbcon_bdr_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.SuggusMbcon_bdr_sug_page_pc_new {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#SuggusMbconn_n_sug_page_pc_new {
		position: absolute;
		width: 23px;
		height: 22px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#SuggusMbignore_grp_sug_page_pc_new {
		position: absolute;
		width: 34px;
		height: 34px;
		left: 86px;
		top: 245px;
		overflow: visible;
	}
	#SuggusMbbfdr_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.SuggusMbbfdr_sug_page_pc_new {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#SuggusMbignore_sig_sug_page_pc_new {
		position: absolute;
		width: 22px;
		height: 22px;
		left: 6px;
		top: 7px;
		overflow: visible;
	}
	#SuggusMbblock_grp_sug_page_pc_new {
		position: absolute;
		width: 34px;
		height: 34px;
		left: 125px;
		top: 245px;
		overflow: visible;
	}
	#SuggusMbbdr_block_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.SuggusMbbdr_block_sug_page_pc_new {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#SuggusMbboock_img_sug_page_pc_new {
		position: absolute;
		width: 23px;
		height: 22px;
		left: 6px;
		top: 7px;
		overflow: visible;
	}
	#SuggusMbscroll_and_intro_detail_new {
		position: absolute;
		width: 145px;
		height: 55px;
		left: 11px;
		top: 189px;
		overflow: visible;
	}
	#SuggusMbpresentation_sug_page_pc_new {
		position: absolute;
		width: 22px;
		height: 23px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SuggusMbScroll_intro_pc_sug_page {
		mix-blend-mode: normal;
		position: absolute;
		width: 119px;
		height: 54px;
		left: 26px;
		top: 1px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#SuggusMbrecently_affreqevent_com_req_p {
		left: 0px;
		top: -1px;
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 100%;
		
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		text-transform: capitalize;
	}

.mediaViewInfo {
    --web-view-name: pop up intro;
    --web-view-id: pop_up_intro_suggestion;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pop_up_intro;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pop_up_intro_suggestion {
    position: absolute;
    width: 265px;
    height: 324px;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    background-color: rgba(255,255,255,1);
    border-radius: 20px;
    overflow: hidden;
    --web-view-name: pop up intro;
    --web-view-id: pop_up_intro_suggestion;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#suggestionBgImgPop {
    position: absolute;
    width: 265px;
    height: 324px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Rectangle_29 {
    fill: rgba(187,248,219,1);
}
.Rectangle_29 {
    position: absolute;
    overflow: visible;
    width: 100px;
    height: 33px;
    left: 83px;
    top: 226px;
}
#suggestions_pfp_name {
    left: 110px;
    top: 233px;
    position: absolute;
    overflow: visible;
    width: 48px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#sug_border_title {
    fill: rgba(219,242,231,1);
}
.sug_border_title {
    width: 118px;
    height: 265px;
    position: absolute;
    overflow: visible;
    -webkit-transform: matrix(1,0,0,1,73.5,-142.5) rotate(-90deg);
            transform: matrix(1,0,0,1,73.5,-142.5) rotate(-90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
}
#suggestions_pfp_name_pop {
    left: 59px;
    top: 12px;
    position: absolute;
    overflow: visible;
    width: 148px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#intro_suggestion_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.intro_suggestion_pop {
    position: absolute;
    overflow: visible;
    width: 211px;
    height: 120px;
    left: 27px;
    top: 78px;
    font-size: 13px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#mycommunities_pop_sug {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.mycommunities_pop_sug {
    position: absolute;
    overflow: visible;
   
    height: 33px;
    left: 27px;
    top: 162px;
    font-size: 13px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}

.cssbuttons-io-button {
background: #71CBA0;
color: white;
font-family: inherit;
padding: 0.35em;
padding-left: 1.2em;
font-size: 17px;
font-weight: 500;
border-radius: 0.9em;
border: none;
letter-spacing: 0.05em;
display: flex;
align-items: center;
box-shadow: inset 0 0 1.6em -0.6em #71CBA0;
overflow: hidden;
position: relative;
height: 2.8em;
padding-right: 3.3em;
width: 110px;
height: 33px;
left: 73px;
top: 226px;
cursor: pointer;
}
/* 
.cssbuttons-io-button .icon {
background: white;
margin-left: 1em;
position: absolute;
display: flex;
align-items: center;
justify-content: center;
height: 1.5em;
width: 1.5em;
border-radius: 0.7em;
box-shadow: 0.1em 0.1em 0.6em 0.2em #71CBA0;
right: 0.3em;
transition: all 0.3s;
}

.cssbuttons-io-button:hover .icon {
width: calc(100% - 0.6em);
}

.cssbuttons-io-button .icon svg {
width: 1.1em;
transition: transform 0.3s;
color: #71CBA0;
}

.cssbuttons-io-button:hover .icon svg {
transform: translateX(0.1em);
}

.cssbuttons-io-button:active .icon {
transform: scale(0.95);
} */



#close_sugesstion_PopUp_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 88%;
    top:0%;
    margin: 7px;
    cursor: pointer;
    overflow: visible
}
.mediaViewInfo {
    --web-view-name: Meeting room cam;
    --web-view-id: Meeting_room_cam_mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: Meeting_room_cam_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#Meeting_room_cam_mob {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 145px);
    background-color: rgba(255,255,255,1);
    top:80px;
    overflow-x: hidden;
    overflow-y: scroll;
    --web-view-name: Meeting room cam;
    --web-view-id: Meeting_room_cam_mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#chat_full_grp_mob {
    position: absolute;
    width: 325.408px;
    height: 219.037px;
    left: -0.408px;
    top: 514.963px;
    overflow: visible;
}
#chat_bg_mob_ {
    position: absolute;
    width: 325.408px;
    height: 219.037px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#user_2_text_grp_mob {
    position: absolute;
    width: 290.746px;
    height: 68.429px;
    left: 20.254px;
    top: 25.743px;
    overflow: visible;
}
#chat_2_design_mob {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chat_2_design_mob {
    overflow: visible;
    position: absolute;
    width: 252.03px;
    height: 42.683px;
    left: 0px;
    top: 10.462px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#chat_2_time_mob {
    left: 4.56px;
    top: 51.429px;
    position: absolute;
    overflow: visible;
    width: 25px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(255,87,87,1);
}
#chat_2_username_mob {
    left: 42.932px;
    top: 51.429px;
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(255,87,87,1);
}
#chat_2_txt_input_mob {
    left: 6.634px;
    top: 22.093px;
    position: absolute;
    overflow: visible;
    width: 220px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#online_user_pf_2_img_mob {
    position: absolute;
    width: 30px;
    height: 27.909px;
    left: 260.746px;
    top: 0px;
    overflow: visible;
}
#user_1_txt_grp_mob {
    position: absolute;
    width: 285.868px;
    height: 75.055px;
    left: 26px;
    top: 92.757px;
    overflow: visible;
}
#chat_1_design_mob {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chat_1_design_mob {
    overflow: visible;
    position: absolute;
    width: 246.788px;
    height: 42.683px;
    left: 41.004px;
    top: 15.268px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#chat_1_time_mob {
    left: 56.579px;
    top: 56.951px;
    position: absolute;
    overflow: visible;
    width: 25px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(77,242,94,1);
}
#chat_1_username_mob {
    left: 94.295px;
    top: 58.055px;
    position: absolute;
    overflow: visible;
    width: 134px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(77,242,94,1);
}
#chat_1_input_mob {
    left: 58.864px;
    top: 26.886px;
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#online_user_pf_1_img_mob {
    position: absolute;
    width: 30px;
    height: 27.502px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_input_text_mob {
    position: absolute;
    width: 285px;
    height: 34.111px;
    left: 26px;
    top: 172.522px;
    overflow: visible;
}
#chat_msg_input_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chat_msg_input_mob {
    position: absolute;
    overflow: visible;
    width: 285px;
    height: 34.111px;
    left: 0px;
    top: 0px;
    font-size: 17px;
    border-radius: 50px;
    padding-left: 15px;
    font-style: Segeo UI;
    box-shadow: inset 10px 10px 20px #cacaca,inset -10px -10px 20px #ffffff;
    border: 1px solid;
}
#send-message_input_mob {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 258px;
    top: 8.269px;
    overflow: visible;
}
#vids_mob {
    position: absolute;
    width: 325px;
    height: 443.438px;
    left: -0.408px;
    top: 75px;
    overflow: visible;
}
#vid_1_mob {
    position: absolute;
    width: 325px;
    height: 443.438px;
    left: 0px;
    overflow: visible;
}
#vid_cam_1_mob {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    border: 2px solid #000;
    overflow: visible;
}
#Group_call_ended_mob {
    position: absolute;
    width: 41px;
    height: 33.077px;
    left: 50.597px;
    top: 401.058px;
    overflow: visible;
}
#border_call_end_mob {
    fill: rgba(251,54,61,1);
}
.border_call_end_mob {
    position: absolute;
    overflow: visible;
    width: 41px;
    height: 33.077px;
    left: 0px;
    top: 0px;
}
#call-end_mob {
    position: absolute;
    width: 21.6px;
    height: 21.6px;
    left: 10.214px;
    top: 6.508px;
    overflow: visible;
}
#Group_video_off_mob {
    position: absolute;
    width: 42px;
    height: 33.077px;
    left: 185.801px;
    top: 401.058px;
    overflow: visible;
}
#border_cameraonoff {
    opacity: 0.92;
    fill: rgba(255,255,255,1);
}
.border_cameraonoff {
    position: absolute;
    overflow: hidden;
    width: 42px;
    height: 33.077px;
    left: 0px;
    top: 0px;
    border: 2px solid;
    opacity: 0.6;
    border-radius: 5px;
}
#video-camera3_mob {
    position: absolute;
    width: 21.6px;
    height: 21.6px;
    left: 10.369px;
    top: 6.508px;
    overflow: visible;
}
#Group_start_recording_mob {
    position: absolute;
    width: 42px;
    height: 33.077px;
    left: 95.199px;
    top: 401.058px;
    overflow: visible;
}

#Group_stop_recording {
    position: absolute;
    width: 42px;
    height: 33.077px;
    left: 140.199px;
    top: 401.058px;
    overflow: visible;
}
#border_start_mob {
    opacity: 0.92;
    fill: rgba(255,255,255,1);
}
.border_start_mob {
    position: absolute;
    overflow: hidden;
    border: 2px solid;
    opacity: 0.4;
    border-radius: 6px;
    width: 42px;
    height: 33.077px;
    left: 0px;
    top: 0px;
}
#rec-button_mob {
    position: absolute;
    width: 20.195px;
    height: 20.195px;
    left: 11.746px;
    top: 7.425px;
    overflow: visible;
}
#Group_micoff_mob {
    position: absolute;
    width: 42px;
    height: 33.077px;
    left: 232.403px;
    top: 401.058px;
    overflow: visible;
}
#border_micoffon_mob {
    opacity: 0.92;
    fill: rgba(255,255,255,1);
}
.border_micoffon_mob {
    position: absolute;
    overflow: hidden;
    width: 42px;
    height: 33.077px;
    left: 0px;
    top: 0px;
    border: 2px solid;
    opacity: 0.6;
    border-radius: 5px;
}
#mic-on-off-ou {
    position: absolute;
    width: 20.195px;
    height: 20.195px;
    left: 11.9px;
    top: 7.425px;
    overflow: visible;
}
#Group_outer_name_lbl {
    position: absolute;
    width: calc(100% - 39px);
    height: 25.841px;
    overflow: visible;
}
#border_name_lbl_meet {
    opacity: 0.92;
    fill: rgba(255,255,255,1);
}
.border_name_lbl_meet {
    position: absolute;
    overflow: visible;
    width: 96px;
    height: 25.841px;
    left: 0px;
    top: 0px;
}
#video_name_lbl_value {
    left: 11px;
    top: 5.921px;
    position: absolute;
    overflow: visible;
    width: 77px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(0,0,0,1);
}
#opposite_person_mob {
    position: absolute;
    width: 112px;
    height: 71.322px;
    left: 201px;
    top: 12.517px;
    overflow: visible;
}

#vid_cam_2_mob {
    position: absolute;
    /* border: 2px solid black; */
    width: 112px;
    height: 71.322px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Mohammed_Zaid_bl {
    left: 8px;
    top: -11px;
    position: absolute;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    background-color: white;
    color: black;
}
#upcome_meet_scroll_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 100vw;
    height: 74.9px;
    left: 0px;
    top: 0px;
    overflow-x: scroll;
    overflow-y: hidden;
}
#upmeet_design_mob {
    position: absolute;
    width: 375px;
    height: 74.9px;
    left: -30px;
    top: 0px;
    overflow: visible;
}
#upcome_pop_mob {
    position: absolute;
    width: 143px;
    height: 54px;
    left: 3px;
    top: 2px;
    overflow: visible;
}
#n_6170 {
    position: absolute;
    width: 143px;
    height: 54px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Web_Development {
    left: 18px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 108px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(5,5,5,1);
    text-decoration: underline;
}
#Remaining {
    left: 18px;
    top: 27px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(5,5,5,1);
}
#n_3056 {
    left: 84px;
    top: 27px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(5,5,5,1);
}
#right-arrow {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 92%;
    top: 20px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    overflow: visible;
}
#online_users_mob {
    position: absolute;
    width: 70.376px;
    height: 645px;
    right: 0px;
    top: 58px;
    overflow: visible;
}
#ou-bg-img {
    position: absolute;
    width: 70.376px;
    height: 645px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Scroll_Group_1 {
    mix-blend-mode: normal;
    position: absolute;
    width: 52px;
    height: 549px;
    left: 18.376px;
    top: 56px;
    overflow-x: hidden;
    overflow-y: auto;
}
#Online_users_mob_1 {
    position: relative;
    width: 40px;
    height: 40px;
    margin-top: 9px;
    left: 7px;
    top: -2px;
  
    overflow: visible;
}
#user_1_img_tag {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#arrow-right_ou {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 30.376px;
    /* top: 605px; */
    overflow: visible;
}
.mediaViewInfo {
    --web-view-name: onlineuser pop;
    --web-view-id: onlineuser_pop;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: onlineuser_pop;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#onlineuser_pop {
    position: relative;
    width: 214px;
    height: 643px;
    background-color: rgba(255,255,255,1);
    top:60px;
    float: right;
    overflow: hidden;
    --web-view-name: onlineuser pop;
    --web-view-id: onlineuser_pop;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_onlineuser_pop_ {
    position: absolute;
    width: 214px;
    height: 645px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#inner_overall_ {
    position: absolute;
    width: 214px;
    height: 645px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#bg_design_online_user_rec {
    position: absolute;
    width: 214px;
    height: 645px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#username_lbl_and_rec {
    position: absolute;
    width: 170px;
    height: 33px;
    left: 15px;
    top: 12px;
    overflow: visible;
}
#Online_user_bdr_mob {
    fill: rgba(219,242,231,1);
}
.Online_user_bdr_mob {
    position: absolute;
    overflow: visible;
    width: 170px;
    height: 33px;
    left: 0px;
    top: 0px;
}
#online_user_lbl_mob {
    left: 56px;
    top: 8px;
    position: absolute;
    overflow: visible;
    width: 73px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(5,5,5,1);
}
#online_user_scrooll {
    mix-blend-mode: normal;
    position: absolute;
    width: 193px;
    height: 547px;
    left: 11px;
    top: 46px;
    overflow-x: hidden;
    overflow-y: auto;
}
#card1_online_user_ {
    position: relative;
    width: 193px;
    height: 60px;
    left: 0px;
    top: 7px;
    margin-top: 5px;
    overflow: visible;
}
#card_1_bdr_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 0.5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.card_1_bdr_mob {
    position: absolute;
    overflow: visible;
    width: 193px;
    height: 60px;
    left: 0px;
    top: 0px;
}
#user_1_img_tag_mob {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 16px;
    top: 5px;
    overflow: visible;
}
#online_user_lbl_mob_u {
    left: 73px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(5,5,5,1);
}
#call_btn_over_all_mob {
    position: absolute;
    width: 45px;
    height: 22px;
    left: 100px;
    top: 33px;
    overflow: visible;
}
#Online_user_bdr_mob_w {
    fill: rgba(219,242,231,1);
}
.Online_user_bdr_mob_w {
    position: absolute;
    overflow: visible;
    width: 45px;
    height: 22px;
    left: 0px;
    top: 0px;
}
#online_user_lbl_mob_x {
    left: 12px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(5,5,5,1);
}
#arrow_right_open_close {
    position: relative;
    width: 30px;
    height: 30px;
    float: right;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    overflow: visible;
}
@-webkit-keyframes slideInRight {
    from {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
      visibility: visible;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
@keyframes slideInRight {
    from {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
      visibility: visible;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInRight {
    -webkit-animation: slideInRight 1s;
            animation: slideInRight 1s;
  }


  @-webkit-keyframes slideInLeft {
    from {
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
      visibility: visible;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }


  @keyframes slideInLeft {
    from {
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
      visibility: visible;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInLeft {
    -webkit-animation: slideInLeft 1s;
            animation: slideInLeft 1s;
  }
.mediaViewInfo {
    --web-view-name: upcomemeeting pop;
    --web-view-id: upcomemeeting_pop;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: upcomemeeting_pop;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#upcomemeeting_pop {
    position: absolute;
    width: 100vw;
    height: 287px;
    z-index: 1;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: upcomemeeting pop;
    --web-view-id: upcomemeeting_pop;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#overall_upcome_meet_pop {
    position: absolute;
    width: 375px;
    height: 287px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#bg_design_meetup {
    position: absolute;
    width: 375px;
    height: 287px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#meeting_scrrol_mob_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 100vw;
    height: 277px;
    left: 12px;
    top: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#card_1_upcome {
    position: relative;
    width: 148px;
    height: 117px;
    left: 21px;
    top: 15px;
    overflow: visible;
}
#back_desgin_meet_up_p {
    fill: url(#back_desgin_meet_up_p);
}
.back_desgin_meet_up_p {
    position: absolute;
    overflow: visible;
    width: 148px;
    height: 107px;
    left: 0px;
    top: 10px;
}
#page1_upcome {
    position: absolute;
    width: 148px;
    height: 102px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#front_design_meet_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 0.20000000298023224px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.front_design_meet_up {
    position: absolute;
    overflow: visible;
    width: 148px;
    height: 102px;
    left: 0px;
    top: 0px;
}
#Web_Development_meetup {
    left: 27px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 108px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(5,5,5,1);
    text-decoration: underline;
}
#Cancelled_meetup {
    left: 34px;
    top: 27px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(5,5,5,1);
}
#date_lbl_meetup {
    left: 33px;
    top: 75px;
    position: absolute;
    overflow: visible;
    width: 82px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#tine_lbl_meetup {
    left: 34px;
    top: 51px;
    position: absolute;
    overflow: visible;
    width: 39px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#date_img_pop_meet {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 11px;
    top: 77px;
    overflow: visible;
}
#status_img_pop_meet {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 11px;
    top: 27px;
    overflow: visible;
}
#time_img_pop_meet {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 11px;
    top: 52px;
    overflow: visible;
}
#arrow-right_open_close {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    top: 251px;
    overflow: visible;
}

@-webkit-keyframes slideInDown {
    from {
      -webkit-transform: translate3d(0, -100%, 0);
              transform: translate3d(0, -100%, 0);
      visibility: visible;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }

@keyframes slideInDown {
    from {
      -webkit-transform: translate3d(0, -100%, 0);
              transform: translate3d(0, -100%, 0);
      visibility: visible;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInDown {
    -webkit-animation: slideInDown 1s;
            animation: slideInDown 1s;
  }
.mediaViewInfo {
    --web-view-name: pop caller;
    --web-view-id: pop_caller_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pop_caller_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pop_caller_mob {
    position: relative;
    width: 331px;
    height: 42px;
    top: 100px;
    left:0px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: pop caller;
    --web-view-id: pop_caller;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bg_design_callpop_mob {
    position: absolute;
    width: 331px;
    height: 42px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#lbl_name_callpop_mob {
    left: 10px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#btn_grp_popcall_mob {
    position: absolute;
    width: 53px;
    height: 25px;
    left: 192px;
    top: 9px;
    overflow: visible;
    border-radius:  10px;
}
#rec_accept_popcall_mob {
    opacity: 0.5;
    fill: rgba(188,226,179,1);
}
.rec_accept_popcall_mob {
    position: absolute;
    overflow: visible;
    width: 53px;
    height: 25px;
    left: 0px;
    top: 0px;
}
#accept_call_popcall_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 17px;
    top: 3px;
    overflow: visible;
}
#btn_grp_decline_popcall_mob {
    position: absolute;
    width: 53px;
    height: 26px;
    left: 261px;
    top: 9px;
    overflow: visible;
    border-radius:  10px;
}
#decline_rec_popcall_mob {
    opacity: 0.5;
    fill: rgba(188,226,179,1);
}
.decline_rec_popcall_mob {
    position: absolute;
    overflow: visible;
    width: 53px;
    height: 25px;
    left: 0px;
    top: 1px;
}
#decline_rec_popcall_mob {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 14px;
    top: 0px;
    overflow: visible;
}
#decline_img_popcall_mob {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 14px;
    top: 0px;
    overflow: visible;
}
.mediaViewInfo {
    --web-view-name: my com mob version 2.0;
    --web-view-id: my_com_mob_version_20;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: my_com_mob_version_20;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#my_com_mob_version_20 {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 140px);
    top:75px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: my com mob version 2.0;
    --web-view-id: my_com_mob_version_20;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#req_top_grp_overall {
    position: absolute;
    width: 100%;
    height: 67px;
    left: 11px;
    top: 0px;
    overflow: visible;
}
#community_scroll_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 70px);
    height: 67px;
    left: 31px;
    top: 0px;
    overflow-x: scroll;
    overflow-y: hidden;
}
#com_btn_grp_com_req_mob {
    position: absolute;
    width: 168px;
    height: 49px;
    left: 4px;
    top: 9px;
    overflow: visible;
    border-radius: 50px;
}
#bdr_com_mob_v {
    fill: url(#bdr_com_mob_v);
}
.bdr_com_mob_v {
    -webkit-filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 186px;
    height: 67px;
    left: 0px;
    top: 0px;
}
#pfp_piccom_mob {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 4px;
    top: 4px;
    border-radius: 50%;
    overflow: hidden;
}
#cricketlover_lbl_com_req_mob {
    left: 49.594px;
    top: 15px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(37,37,37,1);
}
#down-arrow_for_top {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    top: 19px;
    overflow: visible;
}
#my_community_btn_com_req_mob {
    position: absolute;
    width: 29px;
    height: 40px;
    left: 0px;
    top: 16px;
    overflow: visible;
    border-radius: 50px;
}
#req_btn_rec_com_mob {
    fill: rgba(255,255,255,1);
}
.req_btn_rec_com_mob {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 47px;
    height: 58px;
    left: 0px;
    top: 0px;
}
#plus_img_btn_com_mob {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 4px;
    top: 10px;
    overflow: visible;
}


.com_top_com_req_glow {
    background: linear-gradient(145deg, rgb(48, 249, 17),#16cb34); 
    background-position: 400%;
    -webkit-animation: animatecolor 8s linear ;
            animation: animatecolor 8s linear ; 
    color: #fff;
}
  
  @-webkit-keyframes animatecolor {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }
  
  @keyframes animatecolor {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }

@-webkit-keyframes backInLeft {
    0% {
    -webkit-transform: translateX(-2000px) scale(0.7);
            transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
    }
    
    80% {
    -webkit-transform: translateX(0px) scale(0.7);
            transform: translateX(0px) scale(0.7);
    opacity: 0.7;
    }
    
    100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    }
    }

@keyframes backInLeft {
    0% {
    -webkit-transform: translateX(-2000px) scale(0.7);
            transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
    }
    
    80% {
    -webkit-transform: translateX(0px) scale(0.7);
            transform: translateX(0px) scale(0.7);
    opacity: 0.7;
    }
    
    100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    }
    }
    
    .backInLeft {
    -webkit-animation: backInLeft 1s;
            animation: backInLeft 1s;
    background-color: #b2fbb297;
    }
  
.mediaViewInfo {
    --web-view-name: pop up create;
    --web-view-id: pop_up_create;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pop_up_create;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pop_up_create {
    position: absolute;
    width: 265px;
    height: 324px;
    top: 50%;
    left: 50%;
    border-radius: 15px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: pop up create;
    --web-view-id: pop_up_create;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Group_popup_cr_com {
    position: absolute;
    width: 265px;
    height: 386px;
    left: 0px;
    top: -82px;
    overflow: visible;
}
#Path_3_com {
    fill: rgba(219,242,231,1);
}
.Path_3_com {
    overflow: visible;
    position: absolute;
    width: 118px;
    height: 265px;
    -webkit-transform: translate(0px, 82px) matrix(1,0,0,1,73.5,-155.5) rotate(-90deg);
            transform: translate(0px, 82px) matrix(1,0,0,1,73.5,-155.5) rotate(-90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 0px;
}
#Create_a_Community_lbl {
    left: 51px;
    top: 91px;
    position: absolute;
    overflow: visible;
    width: 164px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#profilebackgroundPC {
    position: absolute;
    width: 211px;
    height: 69px;
    left: 27px;
    top: 133px;
    overflow: visible;
}
#profileimgupload {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 103px;
    top: 165px;

    border-radius: 50%;
    overflow: hidden;
}
#communityName_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.communityName_pop {
    position: absolute;
    overflow: visible;
    width: 211px;
    height: 32px;
    left: 27px;
    top: 246px;
    font-size: 13px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#communityDescription_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.communityDescription_pop {
    position: absolute;
    overflow: visible;
    width: 211px;
    height: 49px;
    left: 27px;
    top: 293px;
    font-size: 13px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#Group_77 {
    position: absolute;
    width: 81px;
    height: 24px;
    left: 92px;
    top: 362px;
    overflow: visible;
}
#Rectangle_29 {
    fill: rgba(180,235,190,1);
}
.Rectangle_29 {
    position: absolute;
    overflow: visible;
    width: 81px;
    height: 24px;
    left: 0px;
    top: 0px;
}
#Submit {
    left: 21px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#cancelicon_pop {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 245px;
    top: 5px;
    cursor: pointer;
    overflow: visible;
}

.mediaViewInfo {
    --web-view-name: community mem – 1;
    --web-view-id: community_mem_mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: community_mem_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#community_mem_mob {
    position: absolute;
    width: 100vw;
    height:  calc(100vh - 220px);
    top: 150px;
    overflow: hidden;
    --web-view-name: community mem – 1;
    --web-view-id: community_mem_mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#com_mem_bg_img_mob {
    position: absolute;
    width: calc(100% - 35px);
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#mid_scroll_com_mem_mob_new {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 45px);
    height: 100%;
    left: 0px;
    top: 0px;
    overflow-x: hidden;
    overflow-y: auto;
}
#card_grp_over_all_com_mem_new {
    position: relative;
    width: 311.277px;
    height: 89px;
    left: 15px;
    top: 11px;
    z-index: 1;
    overflow: visible;
}
#community_mem_page_card_new {
    fill: rgba(255,255,255,1);
}
.community_mem_page_card_new {
    -webkit-filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 317.777px;
    height: 95.5px;
    left: 0px;
    top: 0px;
}
#community_mem_page_mob_new {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 34.702px;
    top: 11.408px;
    border-radius: 50%;
    overflow: hidden;
}
#community_mem_page_new {
    left: 13.495px;
    top: 62px;
    position: absolute;
    overflow: visible;
    width: 89px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    color: rgba(37,37,37,1);
}
#community_mem_page_card_design_new {
    fill: rgba(255,235,133,1);
}
.community_mem_page_card_design_new {
    overflow: visible;
    position: absolute;
    width: 117.404px;
    height: 28.566px;
    left: 0px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#com_mem_minus_new {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 28px;
    top: 4.988px;
    cursor: pointer;
    z-index: 1;
    overflow: visible;
}
#com_mem_dget_png_new {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 2.988px;
    overflow: visible;
}
#com_mem_delivery_png_new {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 32.397px;
    overflow: visible;
}
#com_mem_budget_txt_new {
    left: 168.5px;
    top: 7.988px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#com_mem_dep_txt_new {
    left: 168.5px;
    top: 66.012px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#com_mem_location_png_new {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 61.012px;
    overflow: visible;
}
#com_mem__timg_txt_new {
    left: 168.5px;
    top: 36.408px;
    position: absolute;
    overflow: visible;
    width: 114px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}

#community_mem_side_grp {
    position: absolute;
    width: 100%;
    height: 634px;
    overflow: hidden;
    --web-view-name: community mem – 2;
    --web-view-id: community_mem_side_grp;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#com_mem_bg_side_menu {
    position: absolute;
    width: 37.671px;
    height: 122.703px;
    right: 0px;
    top: 220.343px;
    overflow: visible;
}

#mem_page_grp_right_new {
    position: absolute;
    width: 20px;
    height: 310.976px;
    right: 10px;
    top: 15.212px;
    overflow: visible;
}
#com_mem_req_txt_new {
    -webkit-transform: translate(-340.869px, -15.212px) matrix(1,0,0,1,305.3689,50.7125) rotate(90deg);
            transform: translate(-340.869px, -15.212px) matrix(1,0,0,1,305.3689,50.7125) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(4,4,4,1);
}
#com_mem_off_txt_new {
    -webkit-transform: translate(-340.869px, -15.212px) matrix(1,0,0,1,317.8689,163.291) rotate(90deg);
            transform: translate(-340.869px, -15.212px) matrix(1,0,0,1,317.8689,163.291) rotate(90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#com_mem_mem_txt_new {
    -webkit-transform: translate(-340.869px, -15.212px) matrix(1,0,0,1,317.8689,259.2124) rotate(90deg);
            transform: translate(-340.869px, -15.212px) matrix(1,0,0,1,317.8689,259.2124) rotate(90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#add_btn_com_mem_mob_new {
    position: absolute;
    width: 18.503px;
    height: 18.503px;
    left: 0.131px;
    top: 292.474px;
    overflow: visible;
}
#bdr_plus_btn_new {
    fill: rgba(255,255,255,1);
}
.bdr_plus_btn_new {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 36.503px;
    height: 36.503px;
    left: 0px;
    top: 0px;
}
#plus_btn_mem_new {
    position: absolute;
    width: 12.282px;
    height: 12.282px;
    left: 3.111px;
    top: 3.111px;
    overflow: visible;
}
.mediaViewInfo {
    --web-view-name: req pop up;
    --web-view-id: req_pop_up;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: req_pop_up;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#req_pop_up {
    position: absolute;
    width: 265px;
    height: 573px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: req pop up;
    --web-view-id: req_pop_up;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bg_pop_req_design {
    position: absolute;
    width: 265px;
    height: 543px;
    left: 0px;
    top: 30px;
    overflow: visible;
}
#dsfndsfd_s {
    fill: url(#dsfndsfd_s);
}
.dsfndsfd_s {
    position: absolute;
    overflow: visible;
    width: 265px;
    height: 30px;
    left: 0px;
    top: 0px;
}
#Requirements_pop_na {
    left: 77px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 112px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#grp_useless_req {
    position: absolute;
    width: 179px;
    height: 22px;
    left: 43px;
    top: 45px;
    overflow: visible;
}
#req_add {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Add_title_req {
    left: 148px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 32px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#req_pop_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 265px;
    height: 573px;
    left: 0px;
    top: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Group_79 {
    position: absolute;
    width: 253px;
    height: 37px;
    left: 6px;
    top: 3px;
    overflow: visible;
}
#Rectangle_22 {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 0.5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rectangle_22 {
    position: absolute;
    overflow: visible;
    width: 253px;
    height: 37px;
    left: 0px;
    top: 0px;
}
#Testing {
    left: 20px;
    top: 9px;
    position: absolute;
    overflow: visible;
    width: 48px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#plus1 {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 10px;
    top: 9px;
    overflow: visible;
}


#close_comconmem_pc {
    position: relative;
    width: 20px;
    height: 20px;
   
    float: right;
    margin: 7px;
    cursor: pointer;
    overflow: visible;
}
#footer_app_pc_candi {
    position: fixed;
    width: 100vw;
    height: 42px;
    left: 0px;
    /* top: 524px; */
    z-index: 10;
    bottom: 0px;
    overflow: visible;
}
#footer_vacan {
    fill: rgba(255,135,135,1);
}
.footer_vacan {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 42px;
    left: 0px;
    top: 0px;
}
#All_rights_reserved__2022_Oper {
    left: 126px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 464px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(255,255,255,1);
}
#linkedin_vacan {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 1070px;
    top: 7px;
    overflow: visible;
}
#whatapp_btn_img_vacan {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 1115px;
    top: 7px;
    overflow: visible;
}
#term_and_pol_grp {
    position: absolute;
    width: 294px;
    height: 24px;
    left: 655px;
    top: 10.184px;
    overflow: visible;
}
#Terms_and_Conditions_lbl_vac {
    left: 0px;
    top: 0.816px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(255,255,255,1);
}
#Private_Policy_lbl_vac {
    left: 195px;
    top: 0.816px;
    position: absolute;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(255,255,255,1);
}
#Line_2_vacan {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_2_vacan {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    left: 178.239px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#scroll_and_plus_btn_req_com {
    position: absolute;
    width: calc(100vw - 136px);
    height: 70px;
    left: 120px;
    top: 60px; 
    overflow: visible;
}
#Scroll_req_page {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 26px);
    height: 70px;
    left: 0px;
    top: 0px;
    overflow-x: auto;
    overflow-y: hidden;
}
#com_top_com_req {
    position: relative;
    width: 231px;
    height: 58px;
    left: 11px;
    top: 0px;
    cursor: pointer;
    overflow: visible;
    border-radius: 50px;
}


.com_top_com_req_glow {
    background: linear-gradient(145deg, rgb(48, 249, 17),#16cb34); 
    background-position: 400%;
    -webkit-animation: animatecolor 8s linear ;
            animation: animatecolor 8s linear ; 
    color: #fff;
}
  
  @-webkit-keyframes animatecolor {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }
  
  @keyframes animatecolor {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }

@-webkit-keyframes backInLeft {
    0% {
    -webkit-transform: translateX(-2000px) scale(0.7);
            transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
    }
    
    80% {
    -webkit-transform: translateX(0px) scale(0.7);
            transform: translateX(0px) scale(0.7);
    opacity: 0.7;
    }
    
    100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    }
    }

@keyframes backInLeft {
    0% {
    -webkit-transform: translateX(-2000px) scale(0.7);
            transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
    }
    
    80% {
    -webkit-transform: translateX(0px) scale(0.7);
            transform: translateX(0px) scale(0.7);
    opacity: 0.7;
    }
    
    100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    }
    }
    
    .backInLeft {
    -webkit-animation: backInLeft 1s;
            animation: backInLeft 1s;
    background-color: #b2fbb297;
    }
    


#bdr_community_req_pge_ {
    fill: url(#bdr_community_req_pge_);
}
.bdr_community_req_pge_ {
    -webkit-filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 249px;
    height: 76px;
    left: 0px;
    top: 0px;
    -webkit-animation: animatecolor 8s linear infinite;
            animation: animatecolor 8s linear infinite; 
}
#photo_community_req_page {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 4px;
    top: 4px;
    border-radius: 50%;
    overflow: hidden;
}
#_page {
    left: 64.237px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 137px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}

#Mohammed_Zaid_lbl_req_page {
    left: 64.237px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 137px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#com_btn_top_right_req {
    position: absolute;
    width: 26px;
    height: 58px;
    right: 0px;
    top: 0px;
    cursor: pointer;
    overflow: visible;
}
#plus_btn_bdr {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 0.5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.plus_btn_bdr {
    -webkit-filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 32.5px;
    height: 64.5px;
    left: 0px;
    top: 0px;
}
#plus_img_btn {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 3px;
    top: 19px;
    overflow: visible;
}
.mediaViewInfo {
    --web-view-name: community mem – 1;
    --web-view-id: community_mem_page;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: community_mem_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#community_mem_page {
    position: absolute;
    width: calc(100% - 136px);
    height:calc(100% - 170px);
    top: 130px;
    left: 120px;
    overflow: hidden;
    --web-view-name: community mem – 1;
    --web-view-id: community_mem_page;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bdr_mem_oveerall_grp {
    position: absolute;
    width: 1254.019px;
    height: 565px;
    left: 1.354px;
    top: 0px;
    overflow: visible;
}
#overl_all_design_com_mem_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(163,214,147,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.overl_all_design_com_mem_pc {
    overflow: visible;
    position: absolute;
    width: 3145.033px;
    height: 1646.021px;
    left: 0px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#title_grp_all_com_mem_pc {
    position: absolute;
    width: 429px;
    height: 22px;
    left: 46px;
    top: 14.686px;
    overflow: visible;
}
#Offerings_lbl_com_mem_pc {
    left: 190px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
    cursor: pointer;
}
#Requirement_lbl_com_mem_pc {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#Members_lbl_com_mem_pc {
    left: 354px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#Scroll_Group_conn_mem {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100%);
    height: calc(100% - 100px);
    left: 16px;
    top: 72.686px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#mem_card_1_com_mem_pc {
    position: relative;
    width: 199px;
    height: 193px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#card_bdr_com_mem_pc_ {
    fill: url(#card_bdr_com_mem_pc_);
}
.card_bdr_com_mem_pc_ {
    -webkit-filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 205.5px;
    height: 198.957px;
    left: 0px;
    top: 0.543px;
}
#card_top_deisgn_com_mem_pc_ {
    fill: url(#card_top_deisgn_com_mem_pc_);
}
.card_top_deisgn_com_mem_pc_ {
    overflow: visible;
    position: absolute;
    width: 199px;
    height: 47.484px;
    left: 0px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#bg_name_deisgn_com_mem_pc {
    fill: rgba(219,242,231,1);
}
.bg_name_deisgn_com_mem_pc {
    position: absolute;
    overflow: visible;
    width: 169.518px;
    height: 20.793px;
    left: 14.5px;
    top: 87.543px;
}
#ai-chip-_img_com_mem_pc {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 70px;
    top: 7px;
    overflow: visible;
}
#Mohammed_Zaid_com_mem_pc_1 {
    left: 16.5px;
    top: 87.543px;
    position: absolute;
    overflow: visible;
    width: 129px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(37,37,37,1);
}
#com_mem_pc_location_img {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 19px;
    top: 156px;
    overflow: visible;
}
#com_mem_pc_duration_img {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 19px;
    top: 116px;
    overflow: visible;
}
#minus_com_ {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 177px;
    top: 2px;
    z-index: 10;
    cursor: pointer;
    overflow: visible;
}
#date_com_mem_pc {
    left: 91px;
    top: 162px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#Software_development_com_mem_p {
    left: 59px;
    top: 122px;
    position: absolute;
    overflow: visible;
    width: 131px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#member_add_btn_grp_com_mem_pc:hover {
    left: 487px;
    top: 12px;
}
#member_add_btn_grp_com_mem_pc {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 484px;
    top: 10px;
    overflow: visible;
    border-radius: 50px;
    cursor: pointer;
    transition: all .3s ease-out;
}
#bdr_member_btn_com_off_pcZ {
    fill: rgba(255,255,255,1);
}
.bdr_member_btn_com_off_pcZ {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 50px;
    height: 50px;
    left: 0px;
    top: 0px;
}
#member_img_btn_com_off_pcZ {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 8px;
    top: 8px;
    overflow: visible;
}

@-webkit-keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }

@keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInLeft {
    -webkit-animation: fadeInLeft 1s;
            animation: fadeInLeft 1s;
  }



.mediaViewInfo {
    --web-view-name: community off – 1;
    --web-view-id: community_off__1;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: community_off__1;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#community_off__1 {
    position: absolute;
    width: calc(100% - 136px);
    height: calc(100% - 170px);
    top: 130px;
    left: 120px;
    overflow: hidden;
    --web-view-name: community off – 1;
    --web-view-id: community_off__1;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_design_grp_com_off_pc {
    position: absolute;
    width: calc(100% - 170px);
    height: 100%;
    left: 1.923px;
    top: 0px;
    overflow: visible;
}
#all_desgincom_off_pcZ {
    fill: rgba(255,255,255,1);
    stroke: rgba(163,214,147,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.all_desgincom_off_pcZ {
    overflow: visible;
    position: absolute;
    width: 3089.346px;
    height: 1908.021px;
    left: 0px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#com_off_3_tabs_lbl_com_off_pcZ {
    position: absolute;
    width: 417px;
    height: 22px;
    left: 46px;
    top: 17px;
    overflow: visible;
}
#Offerings_com_off_pcZ {
    left: 186px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#Requirement_com_off_pcZ {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#Members_com_off_pcZ {
    left: 342px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#Scroll_Group_off_ {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 170px);
    height: calc(100% - 100px);
    left: 21px;
    top: 70px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#card_offering_page_details_com {
    position: relative;
    width: 199px;
    height: 243.543px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#full_card_bdr_offering_com_off_ {
    fill: url(#full_card_bdr_offering_com_off_);
}
.full_card_bdr_offering_com_off_ {
    -webkit-filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 205.5px;
    height: 249.5px;
    left: 0px;
    top: 0.543px;
}
#card_bg_design_com_off_pcZ_ {
    fill: url(#card_bg_design_com_off_pcZ_);
}
.card_bg_design_com_off_pcZ_ {
    overflow: visible;
    position: absolute;
    width: 199px;
    height: 47.484px;
    left: 0px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#lbl_website_off_rec_com_off_pc {
    fill: rgba(219,242,231,1);
}
.lbl_website_off_rec_com_off_pc {
    position: absolute;
    overflow: visible;
    width: 169.518px;
    height: 20.793px;
    left: 14.5px;
    top: 87.543px;
}
#Website_Development_com_off_pc {
    left: 16.5px;
    top: 87.543px;
    position: absolute;
    overflow: visible;
    width: 167px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(37,37,37,1);
}
#req_budget_img_com_off_pcZ {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 111px;
    top: 161px;
    overflow: visible;
}
#req_location_img_com_off_pcZ {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 14px;
    top: 161px;
    overflow: visible;
}
#dustbin {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 180px;
    top: 2px;
    overflow: visible;
}
#req_duration_img_com_off_pcZ {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 14px;
    top: 120.543px;
    overflow: visible;
}
#budget_lbl_card_com_off_pcZ {
    left: 153px;
    top: 166px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#delivery_lbl_card_com_off_pcZ {
    left: 56px;
    top: 166px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Online_Deployment_lbl_card_com {
    left: 56px;
    top: 125.3px;
    position: absolute;
    overflow: visible;
    width: 130px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#bid_btn_card_com_off_pcZ {
    position: absolute;
    width: 87px;
    height: 27px;
    left: 13px;
    top: 208px;
    cursor: pointer;
    overflow: visible;
    border-radius: 50px;
}
#bdr_btn_com_off_pcZ {
    fill: rgba(255,255,255,1);
}
.bdr_btn_com_off_pcZ {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 105px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#Bid_lbl_btn_com_off_pcZ {
    left: 25px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#bid_img_card_com_off_pcZ {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 4px;
    top: 4px;
    overflow: visible;
}
#details_btn_card_com_off_pcZ {
    position: absolute;
    width: 72px;
    height: 27px;
    left: 106px;
    top: 208px;
    cursor: pointer;
    overflow: visible;
    border-radius: 50px;
}
#details_btn_rec_com_off_pcZ {
    fill: rgba(255,255,255,1);
}
.details_btn_rec_com_off_pcZ {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#Details_lbl_btn_com_off_pcZ {
    left: 24px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 46px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#detail_img_btn_com_off_pcZ {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 3px;
    top: 4px;
    overflow: visible;
}


@-webkit-keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }


@keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInRight {
    -webkit-animation: fadeInRight 1s;
            animation: fadeInRight 1s;
  }
.mediaViewInfo {
    --web-view-name: offering pop up;
    --web-view-id: offering_pop_up;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: offering_pop_up;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#offering_pop_up {
    position: absolute;
    width: 265px;
    height: 573px;
    top: 50%;
    left: 50%;
    border-radius: 15px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: offering pop up;
    --web-view-id: offering_pop_up;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bg_design_pop_off {
    position: absolute;
    width: 265px;
    height: 543px;
    left: 0px;
    top: 30px;
    overflow: visible;
}
#bg_design_s {
    fill: url(#bg_design_s);
}
.bg_design_s {
    position: absolute;
    overflow: visible;
    width: 265px;
    height: 30px;
    left: 0px;
    top: 0px;
}
#heading_title_pop {
    left: 95px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#grp_useless {
    position: absolute;
    width: 179px;
    height: 22px;
    left: 43px;
    top: 45px;
    overflow: visible;
}
#off_add {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Add_title_off {
    left: 148px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 32px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#off_pop_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 265px;
    height: 573px;
    left: 0px;
    top: 73px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Group_off_com_ {
    position: absolute;
    width: 253px;
    height: 37px;
    left: 2px;
    top: 3px;
    overflow: visible;
}
#border_off_com {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 0.5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.border_off_com {
    position: absolute;
    overflow: visible;
    width: 253px;
    height: 37px;
    left: 0px;
    top: 0px;
}
#Testing_title_com_off {
    left: 20px;
    top: 9px;
    position: absolute;
    overflow: visible;
    width: 48px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#add_off_comm_ {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 191px;
    top: 9px;
    overflow: visible;
}

#close_comoff_pc {
    position: relative;
    width: 20px;
    height: 20px;
   
    float: right;
    margin: 7px;
    cursor: pointer;
    overflow: visible;
}
#right_req_com_off_grp_full {
    position: absolute;
    width: 147px;
    height: calc(100% - 60px);
    right: 0px;
    top: 55px;
    overflow: visible;
}
#rec_card_holder_com_off_pcZ_bz {
    fill: url(#rec_card_holder_com_off_pcZ_bz);
}
.rec_card_holder_com_off_pcZ_bz {
    position: absolute;
    overflow: visible;
    width: 147px;
    height: 100%; 
    left: 0px;
    top: 0px;
}
#card_right_grp_com_off_pcZ {
    position: relative;
    width: 133px;
    height: 133px;
    left: 7px;
    top: 55px;
    margin-top: 5px;
    overflow: visible;
}
#full_card_bdr_com_off_pcZ_b {
    fill: url(#full_card_bdr_com_off_pcZ_b);
}
.full_card_bdr_com_off_pcZ_b {
    -webkit-filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 151px;
    height: 151px;
    left: 0px;
    top: 0px;
}
#INR_300000__29_Sep_com_off_pcZ {
    left: 0px;
    top: 115px;
    position: absolute;
    overflow: visible;
    width: 134px;
    height: 16px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(37,37,37,1);
}
#zaid_img_pfp_com_off_pcZ {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 27px;
    top: 5px;
    overflow: visible;
}
#minus_2_close_com_off_pcZ {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 113px;
    top: 1px;
    overflow: visible;
}
#Website_Development_title_com_ {
    left: 6px;
    top: 92px;
    position: absolute;
    overflow: hidden;
    width: 122px;
    height: 20px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#plus_add_grp_com_off {
    position: absolute;
    width: 139px;
    height: 40px;
    left: 4px;
    top: 5px;
    overflow: visible;
    cursor: pointer;
    border-radius: 50px;
}
#req_btn_rec_com_off_pcZ {
    fill: rgba(255,255,255,1);
}
.req_btn_rec_com_off_pcZ {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 157px;
    height: 58px;
    left: 0px;
    top: 0px;
}
#My_Requirements_lbl_btn_com_of {
    left: 4px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 109px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(37,37,37,1);
}
#plus_img_btn_com_off_pcZ {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 114px;
    top: 10px;
    overflow: visible;
}

@-webkit-keyframes slideInDown {
    from {
      -webkit-transform: translate3d(0, -100%, 0);
              transform: translate3d(0, -100%, 0);
      visibility: visible;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }

@keyframes slideInDown {
    from {
      -webkit-transform: translate3d(0, -100%, 0);
              transform: translate3d(0, -100%, 0);
      visibility: visible;
    }
  
    to {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInDown {
    -webkit-animation: slideInDown 1.5s;
            animation: slideInDown 1.5s;
  }
#card_grp_photos_com_off_pcZ {
    position: absolute;
    width: 174px;
    height: 70px;
    left: 13px;
    top: 12px;
    overflow: visible;
}
#photo_3_com_off_pcZ {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 52px;
    top: 0px;
    overflow: visible;
}
#photo_2_com_off_pcZ {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 129px;
    top: 13px;
    overflow: visible;
}
#photo_1_com_off_pcZ {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 0px;
    top: 13px;
    overflow: visible;
}
.mediaViewInfo {
    --web-view-name: community Req – 1;
    --web-view-id: community_Req__1;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: community_Req__1;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#community_Req__1 {
    position: absolute;
    width: calc(100% - 136px);
    height:calc(100% - 150px);
    top: 130px;
    left: 120px;
    overflow: hidden;
    --web-view-name: community Req – 1;
    --web-view-id: community_Req__1;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#design_req {
    position: absolute;
    width: 1256.662px;
    height: 567px;
    left: 0.151px;
    top: 0px;
    overflow: visible;
}
#cube_green_design_req {
    fill: rgba(255,255,255,1);
    stroke: rgba(163,214,147,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.cube_green_design_req {
    overflow: visible;
    position: absolute;
    width: 2787.676px;
    height: 1898.021px;
    left: 0px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#labels_grp_req {
    position: absolute;
    width: 444px;
    height: 22px;
    left: 46px;
    top: 14.895px;
    overflow: visible;
}
#Offerings_req {
    left: 190px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#Requirements_req {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 112px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#Members_req {
    left: 369px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#Scroll_Group_req_ {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 160px);
    height: calc(100% - 100px);
    left: 11px;
    top: 60.438px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#overall_card_req_page {
    position: relative;
    width: 199px;
    height: 243px;
    left: 10px;
    top: 8px;
    overflow: visible;
}
#full_card_back_bdr_req_ {
    fill: url(#full_card_back_bdr_req_);
}
.full_card_back_bdr_req_ {
    -webkit-filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 205.5px;
    height: 249.5px;
    left: 0px;
    top: 0px;
}
#card_title_bg_bdr {
    fill: rgba(219,242,231,1);
}
.card_title_bg_bdr {
    position: absolute;
    overflow: visible;
    width: 169.518px;
    height: 20.793px;
    left: 14.5px;
    top: 87px;
}
#Website_Development_lbl_card_t {
    left: 16.5px;
    top: 87px;
    position: absolute;
    overflow: visible;
    width: 167px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(37,37,37,1);
}
#card_top_design_req_ {
    fill: url(#card_top_design_req_);
}
.card_top_design_req_ {
    overflow: visible;
    position: absolute;
    width: 199px;
    height: 47.484px;
    left: 0px;
    top: 0.516px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#n_000_inr_lbl {
    left: 153px;
    top: 165.457px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#req_1_budget_img {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 111px;
    top: 160.457px;
    overflow: visible;
}
#Delivery_Deadline_img_req {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 14px;
    top: 160.457px;
    overflow: visible;
}
#delivery_location_img_req {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 14px;
    top: 120px;
    overflow: visible;
}
#n_4_HR_time_req {
    left: 56px;
    top: 165.457px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Online_Deployment_lbl_req {
    left: 56px;
    top: 124.756px;
    position: absolute;
    overflow: visible;
    width: 130px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#dustbin {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 180px;
    top: 1.457px;
    overflow: visible;
}
#bid_btn_grp_req {
    position: absolute;
    width: 72px;
    height: 27px;
    left: 13px;
    top: 207.457px;
    cursor: pointer;
    overflow: visible;
    border-radius: 50px;
}
#bid_bdr_req {
    fill: rgba(255,255,255,1);
}
.bid_bdr_req {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#Bid_lbl_req {
    left: 37px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#bid_btn_img {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 14px;
    top: 4px;
    overflow: visible;
}
#detail_btn_grp {
    position: absolute;
    width: 72px;
    height: 27px;
    left: 106px;
    top: 207.457px;
    overflow: visible;
    cursor: pointer;
    border-radius: 50px;
}
#detail_bdr_req {
    fill: rgba(255,255,255,1);
}
.detail_bdr_req {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#Details_lbl_req {
    left: 24px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 46px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#detail_btn_req {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 3px;
    top: 4px;
    overflow: visible;
}
#card_3_image_grp {
    position: absolute;
    width: 174px;
    height: 70px;
    left: 13px;
    top: 11.457px;
    overflow: visible;
}
#photo_3_req {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 129px;
    top: 13px;
    overflow: visible;
}
#photo_2_req {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 52px;
    top: 0px;
    overflow: visible;
}
#photo_1_req {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 0px;
    top: 13px;
    overflow: visible;
}

@-webkit-keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -20%, 0);
              transform: translate3d(0, -20%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }

@keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -20%, 0);
              transform: translate3d(0, -20%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInDown {
    -webkit-animation: fadeInDown 1s;
            animation: fadeInDown 1s;
  }

.mediaViewInfo {
    --web-view-name: req pop up;
    --web-view-id: req_pop_up;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: req_pop_up;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#req_pop_up {
    position: absolute;
    width: 265px;
    height: 573px;
    top: 50%;
    left: 50%;
    border-radius: 15px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: req pop up;
    --web-view-id: req_pop_up;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bg_pop_req_design {
    position: absolute;
    width: 265px;
    height: 543px;
    left: 0px;
    top: 30px;
    overflow: visible;
}
#dsfndsfd_s {
    fill: url(#dsfndsfd_s);
}
.dsfndsfd_s {
    position: absolute;
    overflow: visible;
    width: 265px;
    height: 30px;
    left: 0px;
    top: 0px;
}
#Requirements_pop_na {
    left: 77px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 112px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#grp_useless_req {
    position: absolute;
    width: 179px;
    height: 22px;
    left: 43px;
    top: 45px;
    overflow: visible;
}
#req_add {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Add_title_req {
    left: 148px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 32px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#req_pop_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 265px;
    height: 573px;
    left: 0px;
    top: 35px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Group_req_com_ {
    position: absolute;
    width: 253px;
    height: 37px;
    left: 2px;
    top: 3px;
    overflow: visible;
}
#border_data_req_com {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 0.5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.border_data_req_com {
    position: absolute;
    overflow: visible;
    width: 253px;
    height: 37px;
    left: 0px;
    top: 0px;
}
#Testing_data_val_req {
    left: 20px;
    top: 9px;
    position: absolute;
    overflow: visible;
    width: 48px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#plus1_add_req {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 191px;
    top: 9px;
    cursor: pointer;
    overflow: visible;
}

#close_comreq_pc {
    position: relative;
    width: 20px;
    height: 20px;
   
    float: right;
    margin: 7px;
    cursor: pointer;
    overflow: visible;
}
#overall_my_offering_req_page {
    position: absolute;
    width: 147px;
    height: calc(100% - 60px);
    right: 0px;
    top: 55px;
    overflow: visible;
}
#my_offering_right_full_bdr_b {
    fill: url(#my_offering_right_full_bdr_b);
}
.my_offering_right_full_bdr_b {
    position: absolute;
    overflow: visible;
    width: 147px;
    height: 100%;
    left: 0px;
    top: 0px;
}
#card_offering_right_side {
    position: relative;
    width: 133px;
    height: 133px;
    left: 7px;
    top: 55px;
    margin-top: 5px;
    overflow: visible;
}
#card_bdr_req_page_b {
    fill: url(#card_bdr_req_page_b);
}
.card_bdr_req_page_b {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 151px;
    height: 151px;
    left: 0px;
    top: 0px;
}
#INR_300000__29_Sep_req {
    left: 0px;
    top: 115px;
    position: absolute;
    overflow: visible;
    width: 134px;
    height: 16px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(37,37,37,1);
}
#zain_pic_req {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 27px;
    top: 5px;
    object-fit: fill;
    border-radius: 5px;
    overflow: hidden;
}
#minus_2_card_req {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 113px;
    top: 1px;
    overflow: visible;
}
#Website_Development_marquee_re {
    left: 6px;
    top: 92px;
    position: absolute;
    overflow: hidden;
    width: 122px;
    height: 20px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#my_offering_btn_req_page {
    position: absolute;
    width: 139px;
    height: 40px;
    left: 4px;
    top: 4px;
    cursor: pointer;
    overflow: visible;
    border-radius: 50px;
}
#offering_req_page {
    fill: rgba(255,255,255,1);
}
.offering_req_page {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 157px;
    height: 58px;
    left: 0px;
    top: 0px;
}
#My_Offerings_lbl_req_page {
    left: 10px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(37,37,37,1);
}
#plus_img_req_page {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 108px;
    top: 10px;
    overflow: visible;
}

@-webkit-keyframes backInLeft {
    0% {
    -webkit-transform: translateX(-2000px) scale(0.7);
            transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
    }
    
    80% {
    -webkit-transform: translateX(0px) scale(0.7);
            transform: translateX(0px) scale(0.7);
    opacity: 0.7;
    }
    
    100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    }
    }

@keyframes backInLeft {
    0% {
    -webkit-transform: translateX(-2000px) scale(0.7);
            transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
    }
    
    80% {
    -webkit-transform: translateX(0px) scale(0.7);
            transform: translateX(0px) scale(0.7);
    opacity: 0.7;
    }
    
    100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    }
    }
    
    .backInLeft {
    -webkit-animation: backInLeft 1s;
            animation: backInLeft 1s;
    }
.mediaViewInfo {
    --web-view-name: community off – 2;
    --web-view-id: community_off_mob1;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: community_off_mob1;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#community_off_mob1 {
    position: absolute;
    width: 100vw;
    height:  calc(100vh - 220px);
    top: 150px;
    overflow: hidden;
    --web-view-name: community off – 2;
    --web-view-id: community_off_mob1;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#com_off_bg_img_new {
    position: absolute;
    width: calc(100% - 37px);
    height: calc(100% - 60px);
    left: 0px;
    top: 0px;
    overflow: visible;
}

#community_off_side_menu {
    position: absolute;
    width: 100%;
    height: 634px;
    overflow: hidden;
    --web-view-name: community off – 3;
    --web-view-id: community_off_side_menu;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#com_off_bg_side_menu {
    position: absolute;
    width: 38.112px;
    height: 131.234px;
    right: 0px;
    top: 105.193px;
    overflow: visible;
}
#off_com_overall {
    position: absolute;
    width: 20.869px;
    height: 299.188px;
    right: 8.56px;
    top: 9.235px;
    overflow: visible;
}
#com_off_req_txt {
    -webkit-transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,310.556,101.235) rotate(90deg);
            transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,310.556,101.235) rotate(90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#com_off_off_txt {
    -webkit-transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,326.556,210.3136) rotate(90deg);
            transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,326.556,210.3136) rotate(90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#com_off_mem_txt_1 {
    -webkit-transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,325.6871,318.4227) rotate(90deg);
            transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,325.6871,318.4227) rotate(90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#com_off_mid_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 35px);
    height: calc(100% - 65px);
    left: 0px;
    top: 0px;
    overflow-x: hidden;
    overflow-y: auto;
}
#card_grp_over_all_com_off {
    position: relative;
    width: 311.277px;
    height: 89px;
    left: 5%;
    top: 12px;
    overflow: visible;
}
#community_page_card_off {
    fill: rgba(255,255,255,1);
}
.community_page_card_off {
    -webkit-filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 317.777px;
    height: 95.5px;
    left: 0px;
    top: 0px;
}
#community_page_mob_pfp_new {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 40.702px;
    top: 11.408px;
    border-radius: 50%;
    overflow: hidden;
}
#community_page_md_zaid {
    left: 3.495px;
    top: 62px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    color: rgba(37,37,37,1);
}
#community_page_card_design {
    fill: rgba(255,235,133,1);
}
.community_page_card_design {
    overflow: visible;
    position: absolute;
    width: 117.404px;
    height: 28.566px;
    left: 0px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}

#com_off_budget_png_new {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 2.988px;
    overflow: visible;
}
#com_off_delivery_png_new {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 32.397px;
    overflow: visible;
}
#com_off_budget_txt_new {
    left: 168.5px;
    top: 7.988px;
    position: absolute;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#com_off_dep_txt_new {
    left: 168.5px;
    top: 66.012px;
    position: absolute;
    overflow: visible;
    width: 113px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#com_off_location_png_new {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 61.012px;
    overflow: visible;
}
#com_off__timg_txt {
    left: 168.5px;
    top: 36.408px;
    position: absolute;
    overflow: visible;
    width: 51px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}

#req_overll_mob_grp {
    position: absolute;
    width: 100%;
    height: 66.5px;
    left: 0px;
    bottom: 0px;
    overflow: visible;
}
#rec_card_holder_com_off_mob_bb {
    fill: url(#rec_card_holder_com_off_mob_bb);
}
.rec_card_holder_com_off_mob_bb {
    width: 66.5px;
    height: 375px;
    position: absolute;
    overflow: visible;
    -webkit-transform: translate(0px, -636.5px) matrix(1,0,0,1,154.25,482.25) rotate(90deg);
            transform: translate(0px, -636.5px) matrix(1,0,0,1,154.25,482.25) rotate(90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
}
#my_off_btn_com_req_mob {
    position: absolute;
    width: 29px;
    height: 40px;
    left: 16px;
    top: 11.5px;
    overflow: visible;
    border-radius: 50px;
}
#off_btn_rec_com_off_mob {
    fill: rgba(255,255,255,1);
}
.off_btn_rec_com_off_mob {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 47px;
    height: 58px;
    left: 0px;
    top: 0px;
}
#plus_img_btn_com_off_mob {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 4px;
    top: 10px;
    overflow: visible;
}
#com_off_scroll_down_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 100px);
    height: 66px;
    left: 54px;
    top: 0.5px;
    overflow-x: auto;
    overflow-y: hidden;
}
#off_card_com_off_mob {
    position: relative;
    width: 180px;
    height: 49px;
    left: 0px;
    top: 7px;
    overflow: visible;
}
#bdr_com_off_mob_bi {
    fill: url(#bdr_com_off_mob_bi);
}
.bdr_com_off_mob_bi {
    -webkit-filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 52px;
    left: 0px;
    top: 0px;
}
#pfp_pic_com_off_mob {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 5px;
    top: 4px;
    border-radius: 50%;
    overflow: hidden;
}
#software_developer_lbl_com_off {
    left: 46.594px;
    top: 6.5px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#inr_and_date_com_off_mob {
    left: 46.594px;
    top: 26px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#minus_2_close_comoff_mob {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 153px;
    top: 0px;
    overflow: visible;
}
#off_card_com_off_mob_1 {
    position: absolute;
    width: 180px;
    height: 49px;
    left: 187px;
    top: 7px;
    overflow: visible;
}
#bdr_com_off_mob_1_bp {
    fill: url(#bdr_com_off_mob_1_bp);
}
.bdr_com_off_mob_1_bp {
    -webkit-filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 52px;
    left: 0px;
    top: 0px;
}
#pfp_pic_comoff_mob_1 {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 4px;
    top: 4px;
    overflow: visible;
}
#Mohammed_Zaid_lbl_com_off_mob_ {
    left: 46.594px;
    top: 6.5px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#inr_and_date_com_off_mob_1 {
    left: 46.594px;
    top: 26px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#minus_2_close_com_off_mob_1 {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 153px;
    top: 0px;
    overflow: visible;
}
#up-arrow_for_bottom_off {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    top: 16.5px;
    overflow: visible;
}
.mediaViewInfo {
    --web-view-name: req down;
    --web-view-id: req_down;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: req_down;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#req_down {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 275px);
    top: 0px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: req down;
    --web-view-id: req_down;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#background_green_down_pop_down_y {
    fill: url(#background_green_down_pop_down_y);
}
.background_green_down_pop_down_y {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}
#req_pop_up_bottom_scroll_down_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#req_card_req {
    position: relative;
    width: 180px;
    height: 49px;
    left: 0px;
    top: 70px;
    z-index: 10;
    overflow: visible;
}
#bdr_com_req__ {
    fill: url(#bdr_com_req__);
}
.bdr_com_req__ {
    -webkit-filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 52px;
    left: 0px;
    top: 0px;
}
#SD_pfp_pic_com_req_mob {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 4px;
    top: 4px;
    overflow: visible;
}
#SD_lbl_com_req_ {
    left: 45.594px;
    top: 6.5px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#inr_and_com_req {
    left: 46.594px;
    top: 26px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#minuclose_com_req_mob {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 153px;
    top: 0px;
    overflow: visible;
}
#requirment_com_mob_pop {
    position: absolute;
    width: 139px;
    height: 40px;
    left: 15px;
    top: 15px;
    overflow: visible;
    border-radius: 50px;
}
#req_btncom_req {
    fill: rgba(255,255,255,1);
}
.req_btncom_req {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 157px;
    height: 58px;
    left: 0px;
    top: 0px;
}
#My_Requirs_lbl_btn_req {
    left: 4px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 109px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(37,37,37,1);
}
#plus_img_com_req {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 114px;
    top: 10px;
    overflow: visible;
}
#down-arrow_for_top_req {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    bottom: 10px;
    overflow: visible;
}
.mediaViewInfo {
    --web-view-name: community req – 1;
    --web-view-id: community_req_Mob1;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: community_req_Mob1;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#community_req_Mob1 {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 220px);
    top: 150px;
    overflow: visible;
    --web-view-name: community req – 1;
    --web-view-id: community_req_Mob1;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#community_req_side_menu {
    position: absolute;
    width: 100%;
    height: calc(100% - 60px);
    overflow: hidden;
    --web-view-name: community req – 2;
    --web-view-id: community_req__2;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#req_overall_com_req_mob {
    position: absolute;
    width: 37.873px;
    height: 310.184px;
    right: 0px;
    top: 0.239px;
    overflow: visible;
}
#com_req_mob_side_bar {
    position: absolute;
    width: 37.873px;
    height: 127.822px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#com_req_bg_img_com_req_mob {
    position: absolute;
    width: calc(100% - 35px);
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#req_off_mem_grp_1 {
    position: absolute;
    width: 20.869px;
    height: 299.188px;
    left:9.56px;
    top: 10.996px;
    overflow: visible;
}
#com_off_req_txt_com_req_mob {
    -webkit-transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,310.556,101.235) rotate(90deg);
            transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,310.556,101.235) rotate(90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#com_off_off_txt_com_req_mob {
    -webkit-transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,326.556,210.3136) rotate(90deg);
            transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,326.556,210.3136) rotate(90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#com_off_mem_txt_com_req_mob {
    -webkit-transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,325.6871,318.4227) rotate(90deg);
            transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,325.6871,318.4227) rotate(90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}

#mid_scroll_card {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 45px);
    height: calc(100% - 60px);
    left: 0px;
    top: 0px;
    overflow-x: hidden;
    overflow-y: auto;
}
#card_grp_over_all_mob {
    position: relative;
    width: 311.277px;
    height: 89px;
    left: 5%;
    top: 13px;
    overflow: visible;
}
#com_req_mob_card_1 {
    fill: rgba(255,255,255,1);
}
.com_req_mob_card_1 {
    -webkit-filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 317.777px;
    height: 95.5px;
    left: 0px;
    top: 0px;
}
#com_req_mob_mob_pfp {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 34.702px;
    top: 11.408px;
    border-radius: 50%;
    overflow: hidden;
}
#com_req_mob_md_zaid {
    left: 2.495px;
    top: 62px;
    position: absolute;
    overflow: visible;
    width: 116px;
    height: 15px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    color: rgba(37,37,37,1);
}
#com_req_mob_design_grp {
    position: absolute;
    width: 117.404px;
    height: 28.566px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#com_req_mob_page_card_design {
    fill: rgba(255,235,133,1);
}
.com_req_mob_page_card_design {
    overflow: visible;
    position: absolute;
    width: 117.404px;
    height: 28.566px;
    left: 0px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#com_req_mob_minus {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 285.777px;
    top: 4.988px;
    overflow: visible;
}
#com_req_mob_budget_png {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 2.988px;
    overflow: visible;
}
#com_req_mob_delivery_png {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 32.397px;
    overflow: visible;
}
#com_req_mob_budget_txt {
    left: 168.5px;
    top: 7.988px;
    position: absolute;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#com_req_mob_dep_txt {
    left: 168.5px;
    top: 66.012px;
    position: absolute;
    overflow: visible;
    width: 113px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#com_req_mob_location_png {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 61.012px;
    overflow: visible;
}
#com_req_mob__timg_txt {
    left: 168.5px;
    top: 36.408px;
    position: absolute;
    overflow: visible;
    width: 51px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}

#down_off_grp_overall {
    position: absolute;
    width: 100%;
    height: 66.5px;
    left: 0px;
    bottom: 0px;
    overflow: visible;
}

#my_requirment_btn_com_req_mob {
    position: absolute;
    width: 29px;
    height: 40px;
    left: 16px;
    top: 11.5px;
    overflow: visible;
    border-radius: 50px;
}
#req_btn_rec_com_req_mob {
    fill: rgba(255,255,255,1);
}
.req_btn_rec_com_req_mob {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 47px;
    height: 58px;
    left: 0px;
    top: 0px;
}
#plus_img_btn_com_req_mob {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 4px;
    top: 10px;
    overflow: visible;
}
#com_req_mob_down_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 100px);
    height: 66px;
    left: 54px;
    top: 0.5px;
    overflow-x: auto;
    overflow-y: hidden;
}
#req_card_com_req_mob {
    position: relative;
    width: 180px;
    height: 49px;
    left: 0px;
    top: 7px;
    overflow: visible;
}
#bdr_com_req_mob_bj {
    fill: url(#bdr_com_req_mob_bj);
}
.bdr_com_req_mob_bj {
    -webkit-filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 52px;
    left: 0px;
    top: 0px;
}
#pfp_pic_com_req_mob {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 4px;
    top: 4px;
    overflow: visible;
}
#software_developer_lbl_com_req {
    left: 46.594px;
    top: 6.5px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#inr_and_date_com_req_mob {
    left: 46.594px;
    top: 26px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#minus_2_close_com_req_mob {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 153px;
    top: 0px;
    overflow: visible;
}
#req_card_com_req_mob_1 {
    position: absolute;
    width: 180px;
    height: 49px;
    left: 187px;
    top: 7px;
    overflow: visible;
}
#bdr_com_req_mob1_bq {
    fill: url(#bdr_com_req_mob1_bq);
}
.bdr_com_req_mob1_bq {
    -webkit-filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 52px;
    left: 0px;
    top: 0px;
}
#pfp_pic_com_req_mob_1 {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 4px;
    top: 4px;
    overflow: visible;
}
#Mohammed_Zaid_lbl_com_req_mob_ {
    left: 46.594px;
    top: 6.5px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#inr_and_date_com_req_mob_1 {
    left: 46.594px;
    top: 26px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#minus_2_close_com_req_mob_1 {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 153px;
    top: 0px;
    overflow: visible;
}
#up-arrow_for_bottom {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    top: 16.5px;
    overflow: visible;
}
.mediaViewInfo {
    --web-view-name: off down;
    --web-view-id: off_down;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: off_down;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#off_down {
    position: absolute;
    width: 100vw;
    height:calc(100vh - 275px);
    /* top:calc(100% - 245px); */
    top:0px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: off down;
    --web-view-id: off_down;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#off_bhack_bg_y {
    fill: url(#off_bhack_bg_y);
}
.off_bhack_bg_y {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}
#Scroll_overall_off_down {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#off_card__req_ {
    position: relative;
    width: 180px;
    height: 49px;
    left: 0px;
    top: 70px;
    z-index: 1;
    overflow: visible;
}
#bdr_com_off_mob_ {
    fill: url(#bdr_com_off_mob_);
}
.bdr_com_off_mob_ {
    -webkit-filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 52px;
    left: 0px;
    top: 0px;
}
#pfp_pic_cooff_mob {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 4px;
    top: 4px;
    overflow: visible;
}
#Mohammed_SD_lbl_com_off_mob {
    left: 46.594px;
    top: 6.5px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#inr_and_date_comoffmob {
    left: 46.594px;
    top: 26px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#minus_2_close_comoff_mob {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 153px;
    top: 0px;
    overflow: visible;
}
#my_offbtn_com_req_mob {
    position: absolute;
    width: 139px;
    height: 40px;
    left: 15px;
    top: 5px;
    overflow: visible;
    border-radius: 50px;
}
#off_btn_com_req_mob {
    fill: rgba(255,255,255,1);
}
.off_btn_com_req_mob {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 157px;
    height: 58px;
    left: 0px;
    top: 0px;
}
#My_OFF_lbl_btn_com_req_mob {
    left: 17px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(37,37,37,1);
}
#plus_img_btn_comoff_mob {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 102px;
    top: 10px;
    overflow: visible;
}
#down-arrow_for_top_off {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    bottom: 10px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    overflow: visible;
}
.mediaViewInfo {
    --web-view-name: com slide;
    --web-view-id: my_com_slide_;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: my_com_slide_;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#my_com_slide_ {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 200px);
    top: 75px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: com slide;
    --web-view-id: my_com_slide_;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#top_bg_green_req_mob_y {
    fill: url(#top_bg_green_req_mob_y);
}
.top_bg_green_req_mob_y {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: linear-gradient(145deg, rgb(219, 255, 213),#ebffef); 
}
#mycom_pop_up_bottom_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#com_card_com_req_mob {
    position: relative;
    width: 180px;
    height: 49px;
    left: 10px;
    top: 50px;
    overflow: visible;
}
#bdr_com_req_mob_ {
    fill: url(#bdr_com_req_mob_);
}
.bdr_com_req_mob_ {
    -webkit-filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 52px;
    left: 0px;
    top: 0px;
}
#pfp_pic_com_req_mob {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 4px;
    top: 4px;
    border-radius: 50%;
    overflow: hidden;
}
#title_lbl_com_req_mob {
    left: 45.594px;
    top: 16px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#minus_close_com_mycom_mob {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 153px;
    top: 0px;
    overflow: visible;
}
#my_combtn_com_mob_12 {
    position: absolute;
    width: 139px;
    height: 40px;
    left: 15px;
    top: 5px;
    overflow: visible;
    border-radius: 50px;
}
#n_2_btn_com_mynew_mob {
    fill: rgba(255,255,255,1);
}
.n_2_btn_com_mynew_mob {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 157px;
    height: 58px;
    left: 0px;
    top: 0px;
}
#My_com__lbl_btn_com_req_mob {
    left: 20px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 73px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(37,37,37,1);
}
#plus_img_btn_comoff_mob_12 {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 102px;
    top: 10px;
    overflow: visible;
}
#down-arrow_for_top_mom {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    bottom: 10px;
    overflow: visible;
}

.com_top_com_mycom_glow {
    background: linear-gradient(145deg, rgb(48, 249, 17),#16cb34); 
    background-position: 400%;
    -webkit-animation: animatecolor 8s linear ;
            animation: animatecolor 8s linear ; 
    color: #fff;
}
  
  @-webkit-keyframes animatecolor {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }
  
  @keyframes animatecolor {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }

  @-webkit-keyframes backInLeft {
    0% {
    -webkit-transform: translateX(-2000px) scale(0.7);
            transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
    }
    
    80% {
    -webkit-transform: translateX(0px) scale(0.7);
            transform: translateX(0px) scale(0.7);
    opacity: 0.7;
    }
    
    100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    }
    }

  @keyframes backInLeft {
    0% {
    -webkit-transform: translateX(-2000px) scale(0.7);
            transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
    }
    
    80% {
    -webkit-transform: translateX(0px) scale(0.7);
            transform: translateX(0px) scale(0.7);
    opacity: 0.7;
    }
    
    100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    }
    }
    
    .backInLeft {
    -webkit-animation: backInLeft 1s;
            animation: backInLeft 1s;
    background-color: #b2fbb297;
    }
  
.mediaViewInfo {
    --web-view-name: coming soon mob;
    --web-view-id: coming_soon_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: coming_soon_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#coming_soon_mob {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 135px);
    top: 70px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: coming soon mob;
    --web-view-id: coming_soon_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_back_some_soon_mob_co {
    fill: rgba(219,242,231,1);
}
.over_all_back_some_soon_mob_co {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 14px;
}
#mid_overall_grp_come_mob_come {
    position: absolute;
    width: 339.743px;
    height: 381.987px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    overflow: visible;
}
#mid_design_hex_mob_come {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.mid_design_hex_mob_come {
    overflow: visible;
    position: absolute;
    width: 224.873px;
    height: 256.366px;
    left: 48.497px;
    top: 69.418px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#speaker_grp_mob_come {
    -webkit-transform: translate(0px, 0px) matrix(1,0,0,1,4.9604,191.1535) rotate(-9deg);
            transform: translate(0px, 0px) matrix(1,0,0,1,4.9604,191.1535) rotate(-9deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    position: absolute;
    width: 78.88px;
    height: 69.626px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#back_speaker_mob_come {
    fill: rgba(255,165,0,1);
}
.back_speaker_mob_come {
    -webkit-filter: drop-shadow(0px 5px 6px rgba(255, 165, 0, 1));
            filter: drop-shadow(0px 5px 6px rgba(255, 165, 0, 1));
    overflow: visible;
    position: absolute;
    width: 93.368px;
    height: 93.336px;
    left: 0.592px;
    top: 5.772px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#front_speak_mob_come {
    fill: rgba(255,165,0,1);
}
.front_speak_mob_come {
    -webkit-filter: drop-shadow(0px 5px 6px rgba(255, 165, 0, 1));
            filter: drop-shadow(0px 5px 6px rgba(255, 165, 0, 1));
    overflow: visible;
    position: absolute;
    width: 46.454px;
    height: 88.587px;
    left: 60.191px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#C_backOMING_mob_come {
    -webkit-filter: blur(5px);
            filter: blur(5px);
    -webkit-transform: translate(0px, 0px) matrix(1,0,0,1,90.8219,126.6351) rotate(-11deg);
            transform: translate(0px, 0px) matrix(1,0,0,1,90.8219,126.6351) rotate(-11deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 240px;
    height: 127px;
    text-align: left;
    font-family: Comic Sans MS;
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    color: rgba(87,170,130,1);
    text-transform: uppercase;
}
#COMING_front_mob_come {
    -webkit-transform: translate(0px, 0px) matrix(1,0,0,1,89.2162,117.6351) rotate(-11deg);
            transform: translate(0px, 0px) matrix(1,0,0,1,89.2162,117.6351) rotate(-11deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 240px;
    height: 127px;
    text-align: left;
    font-family: Comic Sans MS;
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    color: rgba(255,255,255,1);
    text-transform: uppercase;
}
#top_line_mob_come {
    fill: rgba(0,32,54,1);
    stroke: rgba(1,33,56,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.top_line_mob_come {
    overflow: visible;
    position: absolute;
    width: 35.531px;
    height: 47.187px;
    left: 141.906px;
    top: 61.291px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#top_right_line_mob_come {
    fill: transparent;
    stroke: rgba(4,32,61,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.top_right_line_mob_come {
    overflow: visible;
    position: absolute;
    width: 72.424px;
    height: 97.768px;
    left: 198.424px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Stay_tuned__front__mob_come {
    -webkit-filter: drop-shadow(0px 5px 3px rgba(66, 178, 66, 1));
            filter: drop-shadow(0px 5px 3px rgba(66, 178, 66, 1));
    -webkit-transform: translate(0px, 0px) matrix(1,0,0,1,135.5563,278.6667) rotate(-11deg);
            transform: translate(0px, 0px) matrix(1,0,0,1,135.5563,278.6667) rotate(-11deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 113px;
    white-space: nowrap;
    line-height: 33px;
    margin-top: -9px;
    text-align: left;
    font-family: Comic Sans MS;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(66,178,66,1);
    text-transform: uppercase;
}
#down_like_left_mob_come {
    fill: transparent;
    stroke: rgba(1,33,56,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.down_like_left_mob_come {
    overflow: visible;
    position: absolute;
    width: 49.674px;
    height: 66.505px;
    left: 65.176px;
    top: 280.655px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#down_line_mob_come {
    fill: transparent;
    stroke: rgba(0,31,57,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.down_line_mob_come {
    overflow: visible;
    position: absolute;
    width: 27.16px;
    height: 35.375px;
    left: 93.752px;
    top: 348.392px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
.mediaViewInfo {
    --web-view-name: Offerings page mob;
    --web-view-id: Offerings_page_mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: Offerings_page_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#Offerings_page_mob {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 185px);
    top: 45px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: Offerings page mob;
    --web-view-id: Offerings_page_mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#offering_bg_img {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 140px);
    left: 0px;
    top: 0px;
    overflow: visible;
}
#My_Offerings_name_tag {
    left: 18px;
    top: 30px;
    position: absolute;
    overflow: visible;
    width: 116px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(4,4,4,1);
}

#offering_switch_new {
    fill: rgba(255,255,255,1);
}

.offering_switch_new {
    position: absolute;
    overflow: visible;
    width: 34px;
    height: 13px;
    right: 80px;
    top: 20px;
}


	.OFFM_mediaViewInfo {
		--web-view-name: off mob;
		--web-view-id: off_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: off_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
    #add_off_mob {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 20px;
        top: 70px;
        overflow: visible;
    }
	#OFFM_off_mob {
		position: absolute;
        width: 100%;
        height: calc(100% - 185px);
        left: 0px;
        top: 120px;
		background-color: rgba(255,255,255,1);
		overflow-x: hidden;
		overflow-x: auto;
		--web-view-name: off mob;
		--web-view-id: off_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
    #OFFM_off_mob::-webkit-scrollbar {
      display: none;
    }

	#OFFM_Group_128 {
		position: absolute;
		width: 100%;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#OFFM_desgin_3_com_off {
		fill: rgba(248,248,248,1);
	}
	.OFFM_desgin_3_com_off {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 207px;
		left: 0px;
		top: 0px;
	}
	#OFFM_recently_added_com_off {
		/* left: 98px; */
		top: 36px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: uppercase;
	}
	#OFFM_explore_aws_com_off {
		/* left: 105px; */
		top: 18px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(48,47,47,1);
		letter-spacing: -0.2222222137451172px;
		text-transform: uppercase;
	}
	#OFFM_Scroll_Group_6 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 231px;
		left: 0px;
		top: 71px;
        display: flex;
		overflow-x: scroll;
		overflow-y: hidden;
	}
    #OFFM_Scroll_Group_6::-webkit-scrollbar {
        display: none;
    }


	#OFFM_Group_127 {
		position: absolute;
		width: 100%;
		height: 99px;
		left: 0px;
		top: 331px;
		overflow: visible;
	}
	#OFFM_desgin_2_ocom_off {
		position: absolute;
		width: 100%;
		height: 99px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#OFFM_design_1_co_off {
		fill: rgba(0,0,0,0.569);
	}
	.OFFM_design_1_co_off {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 99px;
		left: 0px;
		top: 0px;
	}
	#OFFM_And_then_there_was_offreq {
		/* left: 54px; */
		top: 36px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
		letter-spacing: -0.46875px;
	}
	#OFFM_trwnding_this_week_offreqevent {
		/* left: 113px; */
		top: 467px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
	
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: uppercase;
	}
	#OFFM_top_product_offreqevent {
		/* left: 147px; */
		top: 449px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(48,47,47,1);
		letter-spacing: -0.2222222137451172px;
		text-transform: uppercase;
	}
	#OFFM_Scroll_Group_7 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 499px;
        display: block;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#OFFM_n__card_grp_com_off_off {
		position: relative;
		width: 339px;
		height: 888px;
		left: calc(50% - 170px);
		top: 7px;
        display: inline-block;
		overflow: visible;
	}
	

#OFFM_card_1_reqoffevent_off {
    position: relative;
    width: 170px;
    height: 204px;
    left: 10px;
    top: 16px;
    margin: 5px;
    overflow: visible;
}
#OFFM_Base_reqoffevent_off {
    fill: rgba(255,255,255,1);
}
.OFFM_Base_reqoffevent_off {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 188px;
    height: 188px;
    left: 0px;
    top: 0px;
}
#OFFM_bdr_1_reqoffevent_off {
    fill: rgba(255,79,4,0.09);
}
.OFFM_bdr_1_reqoffevent_off {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 22px;
    left: 130px;
    top: 177px;
}
#OFFM_n_90_reqoffevent_of {
    left: 140px;
    top: 183px;
    position: absolute;
    overflow: visible;
    width: 21px;
    white-space: nowrap;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#OFFM_Single_Tour_reqoffevent_of {
    left: 0px;
    top: 177px;
    position: absolute;
    overflow: visible;
    width: 51px;
    white-space: nowrap;
    
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.3333333206176758px;
}
#OFFM_date_reqoffevent {
    left: 0px;
    top: 193px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#OFFM_online-shopping_reqoffeven {
    position: absolute;
    width: 169px;
    height: 168px;
    /* left: 30px; */
    /* top: 30px; */
    overflow: visible;
}
#OFFM_Group_131 {
    position: absolute;
    width: 27px;
    height: 27px;
    left: 2px;
    top: 3px;
    overflow: visible;
}
#OFFM_Ellipse_1 {
    fill: rgba(255,255,255,1);
}
.OFFM_Ellipse_1 {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 45px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#OFFM_trophy_reqoffevent_off {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 3px;
    top: 4px;
    overflow: visible;
}
#OFFM_Group_130 {
    position: absolute;
    width: 24px;
    height: 15px;
    left: 30px;
    top: 9px;
    overflow: visible;
}
#OFFM_Rectangle_31 {
    fill: rgba(255,255,255,1);
}
.OFFM_Rectangle_31 {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 42px;
    height: 33px;
    left: 0px;
    top: 0px;
}
#OFFM_n_234_reqoffevent_off {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 23px;
    white-space: nowrap;

    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#OFFM_Group_132 {
    position: absolute;
    width: 27px;
    height: 27px;
    left: 141px;
    top: 3px;
    overflow: visible;
}
#OFFM_Ellipse_2 {
    fill: rgba(255,255,255,1);
}
.OFFM_Ellipse_2 {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 45px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#OFFM_share_1_reqoffevent_off {
    position: absolute;
    width: 17px;
    height: 17px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
.OFFM_big_1_trending_off_mob {
    position: relative;
    width: 339px;
    height: 427px;
    left: 0px;
    /* top: 461px; */
    margin-top: 5px;
    overflow: visible;
}
#OFFM_bdr_all_trending_off {
    fill: rgba(245,245,246,1);
}
.OFFM_bdr_all_trending_off {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 357px;
    height: 445px;
    left: 0px;
    top: 0px;
}
#OFFM_bdr_amount_trending_off {
    fill: rgba(255,255,255,1);
}
.OFFM_bdr_amount_trending_off {
    position: absolute;
    overflow: visible;
    width: 65px;
    height: 21px;
    left: 270px;
    top: 370px;
}
#OFFM_n_9_trending_off {
    left: 275px;
    top: 373px;
    position: absolute;
    overflow: visible;
    width: 60px;
    white-space: nowrap;
    text-align: center;
    font-family: Poppins-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#OFFM_Image_trending__off_ {
    position: absolute;
    width: 336px;
    height: 355px;
    /* left: 6px;
    top: 35px; */
    overflow: visible;
}
#OFFM_Nike_Air_Force_1_Sha_trending_ {
    left: 5px;
    top: 366px;
    position: absolute;
    overflow: visible;
    width: 250px;
    white-space: nowrap;
    /* line-height: 19px; */
    /* margin-top: -2px; */
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.1818181800842285px;
}
#OFFM_Single_Tour_trending_off {
    left: 18px;
    top: 398px;
    position: absolute;
    overflow: visible;
    width: 280px;
    white-space: nowrap;
    /* line-height: 18px; */
    /* margin-top: -4px; */
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(17,17,17,1);
}
#OFFM_Group_125 {
    position: absolute;
    width: 24px;
    height: 23px;
    left: 2px;
    top: 2px;
    overflow: visible;
}
#OFFM_bdr_1_trending_off_ {
    fill: rgba(255,255,255,1);
}
.OFFM_bdr_1_trending_off_ {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 23px;
    left: 0px;
    top: 0px;
}
#OFFM_trophy_trending_off_ {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 2px;
    top: 2px;
    overflow: visible;
}
#OFFM_Group_124 {
    position: absolute;
    width: 24px;
    height: 14px;
    left: 27px;
    top: 7px;
    overflow: visible;
}
#OFFM_likes_trending_off_ {
    fill: rgba(255,255,255,1);
}
.OFFM_likes_trending_off_ {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 13px;
    left: 0px;
    top: 1px;
}
#OFFM_n_234_like_trending_off_ {
    left: 1px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 23px;
    white-space: nowrap;
    /* line-height: 18px; */
    /* margin-top: -4px; */
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#off_m_Group_134 {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 311px;
    top: 2px;
    overflow: visible;
}
#off_m_share_bdr_small_4_trending_com {
    fill: rgba(255,255,255,1);
}
.off_m_share_bdr_small_4_trending_com {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 0px;
    top: 0px;
}
#off_m_share_2_com_of {
    position: absolute;
    width: 15px;
    height: 14px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
.OFFM_small_4_trending_com_of {
    position: relative;
    width: 160px;
    height: 197px;
    left: 0px;
    top: 0px;
    margin-top: 5px;
    overflow: visible;
}
#OFFM_all_bdr_small_4_trending_com {
    fill: rgba(245,245,245,1);
}
.OFFM_all_bdr_small_4_trending_com {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 178px;
    height: 215px;
    left: 0px;
    top: 0px;
}
#OFFM_Image_small_4_trendin {
    position: absolute;
    width: 160px;
    height: 150px;
    /* left: 12px;
    top: 23px; */
    overflow: visible;
}
#OFFM_bdr_amount_small_4_trending_co {
    fill: rgba(255,255,255,1);
}
.OFFM_bdr_amount_small_4_trending_co {
    position: absolute;
    overflow: visible;
    width: 45px;
    height: 21px;
    left: 116px;
    top: 160px;
}
#OFFM_n_9_small_4_trending_com {
    left: 115px;
    top: 160px;
    position: absolute;
    overflow: visible;
    width: 45px;
    white-space: pre-line;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#OFFM_NikeCourt_Air_small_4_trending {
    left: 2.986px;
    top: 158.311px;
    position: absolute;
    overflow: visible;
    width: 110px;
    height: 14px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#OFFM_Single_Tour_small_4_trending {
    left: 3px;
    top: 175px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    /* line-height: 14px; */
    /* margin-top: -2px; */
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#OFFM_number_small_4_trending {
    fill: rgba(255,255,255,1);
}
.OFFM_number_small_4_trending {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 13px;
    left: 26px;
    top: 6px;
}
#OFFM_trohpie_bdr_small_4_trendi {
    fill: rgba(255,255,255,1);
}
.OFFM_trohpie_bdr_small_4_trendi {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 1px;
    top: 1px;
}
#OFFM_trophy_small_4_trending_com_of {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 3px;
    top: 3px;
    overflow: visible;
}
#OFFM_n_234_small_4_trending_com_off {
    left: 28px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 19px;
    white-space: nowrap;
    line-height: 16px;
    margin-top: -4px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    color: rgba(17,17,17,1);
}
#OFFM_share_bdr_small_4_trending_com {
    fill: rgba(255,255,255,1);
}
.OFFM_share_bdr_small_4_trending_com {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 135px;
    top: 1px;
}
#OFFM_share_2_com_of {
    position: absolute;
    width: 15px;
    height: 14px;
    left: 140px;
    top: 6px;
    overflow: visible;
}

.mediaViewInfo {
    --web-view-name: off mob;
    --web-view-id: off_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: off_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#add_off_mob {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 20px;
    top: 70px;
    overflow: visible;
}
#Com_OFFM_off_mob {
    position: absolute;
    width: 100%;
    height: calc(100% - 185px);
    left: 0px;
    top: 120px;
    background-color: rgba(255,255,255,1);
    overflow-x: hidden;
    overflow-x: auto;
    --web-view-name: off mob;
    --web-view-id: off_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Com_OFFM_off_mob::-webkit-scrollbar {
  display: none;
}

#Com_OFFM_Group_128 {
    position: absolute;
    width: 100%;
    height: 302px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Com_OFFM_desgin_3_com_off {
    fill: rgba(248,248,248,1);
}
.Com_OFFM_desgin_3_com_off {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 207px;
    left: 0px;
    top: 0px;
}
#Com_OFFM_recently_added_com_off {
    /* left: 98px; */
    top: 36px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#Com_OFFM_explore_aws_com_off {
    /* left: 105px; */
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#Com_OFFM_Scroll_Group_6 {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 231px;
    left: 0px;
    top: 71px;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}
#Com_OFFM_Scroll_Group_6::-webkit-scrollbar {
    display: none;
}


#Com_OFFM_Group_127 {
    position: absolute;
    width: 100%;
    height: 99px;
    left: 0px;
    top: 331px;
    overflow: visible;
}
#Com_OFFM_desgin_2_ocom_off {
    position: absolute;
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Com_OFFM_design_1_co_off {
    fill: rgba(0,0,0,0.569);
}
.Com_OFFM_design_1_co_off {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 99px;
    left: 0px;
    top: 0px;
}
#Com_OFFM_And_then_there_was_offreq {
    /* left: 54px; */
    top: 36px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);
    letter-spacing: -0.46875px;
}
#Com_OFFM_trwnding_this_week_offreqevent {
    /* left: 113px; */
    top: 467px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;

    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#Com_OFFM_top_product_offreqevent {
    /* left: 147px; */
    top: 449px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#Com_OFFM_Scroll_Group_7 {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 499px;
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Com_OFFM_n__card_grp_com_off_off {
    position: relative;
    width: 339px;
    height: 888px;
    left: calc(50% - 170px);
    top: 7px;
    display: inline-block;
    overflow: visible;
}


	.mediaViewInfo {
		--web-view-name: view page off mob;
		--web-view-id: view_page_off_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: view_page_off_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;

	}
	#view_page_off_mob {
		position: absolute;
		width: 100vw;
        height: calc(100vh - 215px);
        top:75px;
		background-color: rgba(255,255,255,1);
        overflow-x: hidden;
        overflow-y: auto;
		--web-view-name: view page off mob;
		--web-view-id: view_page_off_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
    #view_page_off_mob::-webkit-scrollbar {
        display: none;
    }
	#previous_back_btn_back_page_mob {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 15px;
		top: 0px;
		overflow: visible;
	}
	#all_grp_with_btn_off_mob {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 32px;
		overflow: visible;
	}
	#Scroll_cars_preview__mob_off {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 109px;
		left: 0px;
		top: 361px;
		display: flex;
		flex-wrap: wrap;
    	flex-direction: column;
		align-content: flex-start;
		overflow-x: scroll;
		overflow-y: hidden;
	}
    #Scroll_cars_preview__mob_off::-webkit-scrollbar {
        display: none;
    }
	#preview_1_view_page_mob_off {
		position: relative;
		width: 135px;
		height: 85px;
		left: 18px;
		top: 9px;
		overflow: visible;
	}
	#img_preview_1_view_page_mob_of {
		fill: rgba(245,245,246,1);
	}
	.img_preview_1_view_page_mob_of {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 153px;
		height: 103px;
		left: 0px;
		top: 0px;
	}
	#Image_preview_1_view_page_mob_ {
		position: absolute;
		width: 104px;
		height: 79.879px;
		left: 16px;
		top: 3.072px;
		overflow: visible;
	}
	#Nike_Air_Force_1_off_view_page {
		left: 16px;
		top: 483px;
		position: absolute;
		overflow: visible;
		width: 338px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(17,17,17,1);
	}
	#trophy_view_page_mob_off {
		position: absolute;
		width: auto;
		height: 23px;
		left: 18px;
		top: 521px;
		overflow: visible;
	}
	#n_234_count_view_page_mob_off {
		left: 52px;
		top: 523px;
		position: absolute;
		overflow: visible;
		width: 93px;
		height: 20px;
		line-height: 22px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
	}
	#line_before_budget_view_page_m {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 3;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_before_budget_view_page_m {
		overflow: visible;
		position: absolute;
		width: 346.003px;
		height: 2px;
		left: 15.5px;
		top: 553.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Budget_Delivery_Deadline_Dview {
		left: 16px;
		top: 568px;
		position: absolute;
		overflow: visible;
		width: 117px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Afs_10000_AFN_details_info_off {
		left: 152px;
		top: 566px;
		position: absolute;
		overflow: visible;
		width: 202px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#big_display_share_and_edit_mob {
		position: absolute;
		width: 325px;
		height: 353px;
		left: 25px;
		top: 0px;
		overflow: visible;
	}
	#img_2_big_view_page_mob_bdr {
		fill: rgba(245,245,246,1);
	}
	.img_2_big_view_page_mob_bdr {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 343px;
		height: 371px;
		left: 0px;
		top: 0px;
	}
	#Image_2_big_view_page_mob {
		position: absolute;
		width: 320px;
		height: 313px;
		left: 3px;
		top: 20px;
		overflow: visible;
	}
	#share_btn_view_page_mob_off {
		position: absolute;
		width: 19px;
		height: 19px;
		left: 298px;
		top: 10px;
		overflow: visible;
	}
	#edit_req_btn_view_page_mob_off {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 258px;
		top: 7px;
		overflow: visible;
	}
	#Scroll_description_mob_off {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 196px;
		left: 0px;
		top: 657.5px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#description_of_title_view_page_mob_off {
		/* left: 18px; */
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
        margin: 5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#line_below_location_view_page_ {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 3;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_below_location_view_page_ {
		overflow: visible;
		position: absolute;
		width: 346px;
		height: 1px;
		left: 15.5px;
		top: 637.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Button_bargain_view_page_mob_o {
		position: absolute;
		width: 140px;
		height: 45px;
		left: 23px;
		top: 890px;
		overflow: visible;
		border-radius: 5px;
	}
	#bargain_bdr_view_page_mob_off {
		fill: rgba(248,90,71,1);
	}
	.bargain_bdr_view_page_mob_off {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 140px;
		height: 45px;
		left: 0px;
		top: 0px;
	}
	#bargain_view_page__mob_off {
		/* left: 41px; */
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#Button_disable_view_page_mob_o {
		position: absolute;
		width: 140px;
		height: 45px;
		left: 208px;
		top: 890px;
		overflow: visible;
		border-radius: 5px;
	}
	#disable_bdr_view_page_moboff {
		fill: rgba(248,90,71,1);
	}
	.disable_bdr_view_page_moboff {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 140px;
		height: 45px;
		left: 0px;
		top: 0px;
	}
	#disable_view_page_mob_off {
		/* left: 46px; */
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#Scroll_timeline_view_page__mob_off {
	mix-blend-mode: normal;
    position: relative;
    width: 100%;
    height: 146px;
    left: 0px;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
	}
    #Scroll_timeline_view_page__mob_off::-webkit-scrollbar {
        display: none;
    }
	#timeline_grp_view_page_mob_off {
		position: relative;
		width: 147px;
		height: 86px;
		left: 12px;
		top: 24px;
		overflow: visible;
	}
	#behind_gren_line__view_page_mo {
		fill: rgba(255,255,255,1);
	}
	.behind_gren_line__view_page_mo {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 118px;
		height: 31px;
		left: 47px;
		top: 36px;
	}
	#bg_circle_green__view_page_mob {
		fill: rgba(255,255,255,1);
	}
	.bg_circle_green__view_page_mob {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 20px;
		top: 30px;
	}
	#first_circ__view_page_mob_off {
		fill: rgba(168,248,145,1);
	}
	.first_circ__view_page_mob_off {
		position: absolute;
		overflow: visible;
		width: 10px;
		height: 10px;
		left: 26px;
		top: 36px;
	}
	#first_green__view_page_mob_off {
		fill: rgba(168,248,145,1);
	}
	.first_green__view_page_mob_off {
		position: absolute;
		overflow: visible;
		width: 90px;
		height: 6px;
		left: 53px;
		top: 39px;
	}
	#Bargained__view_page_mob_off {
		left: 13px;
		top: 69px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#n_4__12__view_page_mob_off {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 87px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#trophie_detail_view_page__mob_ {
		position: absolute;
        width: 100%;
        height: 76px;
        left: 16px;
        display: flex;
		flex-wrap: wrap;
        top: 1210px;
        overflow: visible;
	}
	#card_1_trohpie_view_page_mob_p {
		position: relative;
		width: 185px;
		height: 35px;
		left: 0px;
		top: 41px;
		overflow: visible;
	}
	#img_who_liked_view_page_mob_pc {
		position: absolute;
		width: 35px;
		height: 35px;
		left: 0px;
		top: 0px;
		border-radius: 50%;
		overflow: hidden;
	}
	#Bargained_view_page_mob_off {
		left: 40px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 146px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Trophies_Awarded_By_view_page_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 150px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}

.mediaViewInfo {
    --web-view-name: requirement page;
    --web-view-id: my_requirement_page_mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: my_requirement_page_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#my_requirement_page_mob {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 185px);
    top: 45px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: requirement page;
    --web-view-id: my_requirement_page_mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#req__bg_img_mob {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 140px);
  
    left: 0px;
    top: 0px;
    overflow: visible;
}
#My_req_name_tag {
    left: 18px;
    top: 30px;
    position: absolute;
    overflow: visible;
    width: 156px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(4,4,4,1);
}
#requirement_mob_switch {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.requirement_mob_switch {
    position: absolute;
    overflow: visible;
    width: 34px;
    height: 13px;
    right: 80px;
    top: 20px;
}
#add_myreq_req_mob {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 329px;
    top: 15px;
    overflow: visible;
}
	.REQM_mediaViewInfo {
		--web-view-name: req mob;
		--web-view-id: req_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: req_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
    #add_req_mob {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 25px;
        top: 70px;
        overflow: visible;
    }
	#REQM_req_mob {
		position: absolute;
        width: 100%;
        height: calc(100% - 185px);
        left: 0px;
        top: 120px;
		background-color: rgba(255,255,255,1);
        overflow-x: hidden;
        overflow-y: auto;
		--web-view-name: req mob;
		--web-view-id: req_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
    #REQM_req_mob::-webkit-scrollbar {
      display: none;
    }

	#REQM_Group_128 {
		position: absolute;
		width: 100%;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#REQM_desgin_3_com_off {
		fill: rgba(248,248,248,1);
	}
	.REQM_desgin_3_com_off {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 207px;
		left: 0px;
		top: 0px;
	}
	#REQM_recently_added_com_off {
		/* left: 98px; */
		top: 36px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: uppercase;
	}
	#REQM_some_offerings_com_of {
		/* left: 93px; */
		top: 18px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(48,47,47,1);
		letter-spacing: -0.2222222137451172px;
		text-transform: uppercase;
	}
	#REQM_Scroll_Group_6 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 231px;
		left: 0px;
		top: 71px;
        display: flex;
		overflow-x: scroll;
		overflow-y: hidden;
	}

    #REQM_Scroll_Group_6::-webkit-scrollbar {
        display: none;
    }

	
	#REQM_Group_127 {
		position: absolute;
		width: 100%;
		height: 99px;
		left: 0px;
		top: 331px;
		overflow: visible;
	}
	#REQM_desgin_2_ocom_off {
		position: absolute;
		width: 100%;
		height: 99px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#REQM_design_1_com_of {
		fill: rgba(0,0,0,0.569);
	}
	.REQM_design_1_com_of {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 99px;
		left: 0px;
		top: 0px;
	}
	#REQM_And_tfreqeventcom_off {
		/* left: 20px; */
		top: 36px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
	
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#REQM_trding_this_week_eqnt_com_off {
		/* left: 113px; */
		top: 467px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: uppercase;
	}
	#REQM_top_product_oent_com_off {
		/* left: 147px; */
		top: 449px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(48,47,47,1);
		letter-spacing: -0.2222222137451172px;
		text-transform: uppercase;
	}
	#REQM_Scroll_Group_7 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 499px;
        display: flex;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#REQM_n__card_grp_com_off {
		position: relative;
		width: 339px;
		height: 888px;
		left: calc((50% - 160px)/2);
		top: 7px;
		overflow: visible;
	}
	
	.mediaViewInfo {
		--web-view-name: view page req mob;
		--web-view-id: view_page_req_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: view_page_req_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#view_page_req_mob {
		position: absolute;
		width: 100vw;
		height: calc(100vh - 215px);
        top: 75px;
		background-color: rgba(255,255,255,1);
        overflow-x: hidden;
        overflow-y: auto;
		--web-view-name: view page req mob;
		--web-view-id: view_page_req_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#view_page_req_mob::-webkit-scrollbar {
		display: none;
	}
	#previous_back_btn_back_page_vi {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 15px;
		top: 0px;
		overflow: visible;
	}
	#overall_grp_with_detail_and_bt {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 32px;
		overflow: visible;
	}
	#Scroll_cars_preview__mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 109px;
		left: 0px;
		top: 361px;
		display: flex;
		flex-wrap: wrap;
        flex-direction: column;
		align-content: flex-start;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#Scroll_cars_preview__mob::-webkit-scrollbar {
		display: none;
	}
	#preview_1_view_page_mob {
		position: relative;
		width: 135px;
		height: 85px;
		left: 18px;
		top: 9px;
		overflow: visible;
	}
	#img_preview_1_view_page__mob {
		fill: rgba(245,245,246,1);
	}
	.img_preview_1_view_page__mob {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 153px;
		height: 103px;
		left: 0px;
		top: 0px;
	}
	#Image_preview_1_view_page_mob {
		position: absolute;
		width: 104px;
		height: 79.879px;
		left: 16px;
		top: 3.072px;
		overflow: visible;
	}
	#Nike_Air_Force_req_mob_view_page {
		left: 16px;
		top: 483px;
		position: absolute;
		overflow: visible;
		width: 338px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(17,17,17,1);
	}
	#trophy_view_page_mob {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 18px;
		top: 521px;
		overflow: visible;
	}
	#n_234_count_view_page_mob {
		left: 52px;
		top: 523px;
		position: absolute;
		overflow: visible;
		width: 93px;
		height: 20px;
		line-height: 22px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
	}
	#line_before_budget_view_page_m {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 3;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_before_budget_view_page_m {
		overflow: visible;
		position: absolute;
		width: 346.003px;
		height: 2px;
		left: 15.5px;
		top: 553.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Budget_Delivery_Deadline_Deliv_mob {
		left: 16px;
		top: 568px;
		position: absolute;
		overflow: visible;
		width: 117px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Afs_10000_mob_details_info_view {
		left: 152px;
		top: 566px;
		position: absolute;
		overflow: visible;
		width: 202px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#display_img_mob_with_edit_shar {
		position: absolute;
		width: 325px;
		height: 353px;
		left: 25px;
		top: 0px;
		overflow: visible;
	}
	#img_2_big_view_page_mob_bdr {
		fill: rgba(245,245,246,1);
	}
	.img_2_big_view_page_mob_bdr {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 343px;
		height: 371px;
		left: 0px;
		top: 0px;
	}
	#Image_2_big_view_page_mob {
		position: absolute;
		width: 320px;
		height: 313px;
		left: 3px;
		top: 20px;
		overflow: visible;
	}
	#share_btn_view_page_mob {
		position: absolute;
		width: 19px;
		height: 19px;
		left: 298px;
		top: 10px;
		overflow: visible;
	}
	#edit_req_btn_view_page_mob {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 258px;
		top: 7px;
		overflow: visible;
	}
	#Scroll_description__mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 195px;
		left: 0px;
		top: 657.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#description_of_title_view_page {
		/* left: 18px; */
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		margin: 5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#line_below_location_view_page_ {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 3;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_below_location_view_page_ {
		overflow: visible;
		position: absolute;
		width: 346px;
		height: 1px;
		left: 15.5px;
		top: 637.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Button_bargain_view_page_mob {
		position: absolute;
		width: 140px;
		height: 45px;
		left: 23px;
		top: 890px;
		overflow: visible;
		border-radius: 5px;
	}
	#bargain_bdr_view_page_mob {
		fill: rgba(248,90,71,1);
	}
	.bargain_bdr_view_page_mob {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 140px;
		height: 45px;
		left: 0px;
		top: 0px;
	}
	#bargain_view_page__mob {
		left: 57px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#Button_disable_view_page_mob {
		position: absolute;
		width: 140px;
		height: 45px;
		left: 208px;
		top: 890px;
		overflow: visible;
		border-radius: 5px;
	}
	#disable_bdr_view_page_mob {
		fill: rgba(248,90,71,1);
	}
	.disable_bdr_view_page_mob {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 140px;
		height: 45px;
		left: 0px;
		top: 0px;
	}
	#disable_view_page_mob {
		left: 46px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#Scroll_timeline_view_page__mob_req {
	mix-blend-mode: normal;
    position: relative;
    width: 100%;
    height: 146px;
    left: 0px;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
	}
	#timeline_grp_view_page_mob_req {
		position: relative;
		width: 147px;
		height: 86px;
		left: 12px;
		top: 24px;
		overflow: visible;
	}
	#behind_gren_line__view_page_mo {
		fill: rgba(255,255,255,1);
	}
	.behind_gren_line__view_page_mo {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 118px;
		height: 31px;
		left: 47px;
		top: 36px;
	}
	#bg_circle_green__view_page_mob {
		fill: rgba(255,255,255,1);
	}
	.bg_circle_green__view_page_mob {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 20px;
		top: 30px;
	}
	#first_circ__view_page_mob {
		fill: rgba(168,248,145,1);
	}
	.first_circ__view_page_mob {
		position: absolute;
		overflow: visible;
		width: 10px;
		height: 10px;
		left: 26px;
		top: 36px;
	}
	#first_green__view_page_mob {
		fill: rgba(168,248,145,1);
	}
	.first_green__view_page_mob {
		position: absolute;
		overflow: visible;
		width: 90px;
		height: 6px;
		left: 53px;
		top: 39px;
	}
	#Bargained__view_page_mob {
		left: 13px;
		top: 69px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#n_4__12__view_page_mob {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 87px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#trophie_detail_view_page__mob {
		position: absolute;
		width: 100%;
		height: 76px;
		left: 16px;
		top: 1210px;
		display: flex;
		flex-wrap: wrap;
		overflow: visible;
	}
	#card_1_trohpie_view_page_mob_r {
		position: relative;
		width: 185px;
		height: 35px;
		left: 0px;
		top: 41px;
		overflow: visible;
	}
	#img_who_liked_view_page_mob_re {
		position: absolute;
		width: 35px;
		height: 35px;
		left: 0px;
		top: 0px;
		border-radius: 50%;
		overflow: hidden;
	}
	#Bargained_view_page_mob_req {
		left: 40px;
		top: 8px;
		position: relative;
		overflow: visible;
		width: 170px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Trophies_Awarded_By_view_page_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 150px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}



.mediaViewInfo {
    --web-view-name: profile page;
    --web-view-id: profile_page;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: profile_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
.main-page-mob {
    position: fixed;
    display: table;
   
    width: 100vw;
    height: calc(100vh - 75px);
}
.main-content-mob {
   position: relative;
   display: table-cell;
   width: 100%;
}
.container-row-mob{
    display: table-row;
}
.container-cell-mob
{
display: table-cell;
width: 100%;
padding-right: 10px;
}
.inner-table-mob{
position: relative;
display: table;
overflow-y: scroll;
width: 100%;

}

.right-content-mob {
   
    position: relative;
    display: table-cell;
    width: 350px;
   vertical-align: top;
   
 }
.mediaViewInfo {
    --web-view-name: top profile;
    --web-view-id: top_profile;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: top_profile;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#top_profile {
    position: relative;
    width: 100%;
    height: 664px;
    margin-top: 75px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: top profile;
    --web-view-id: top_profile;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_bg_top_profile {
    position: relative;
    width: 100%;
    height: 641px;
    /* left: 13px; */
    top: 0px;
    overflow: visible;
}
#top_bnner_mob_top_profile {
    position: absolute;
    width: 100%;
    height: 160px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#banner_img_mob_top_profile {
    position: absolute;
    width: 100%;
    height: 160px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#mid_circle_grp_profile_mob_top {
    position: absolute;
    width: 100%;
    height: 528px;
    left: 0px;
    top: 113px;
    overflow: visible;
}
#profil_info_bdr_mob_top_profil {
    fill: rgba(255,255,255,1);
}
.profil_info_bdr_mob_top_profil {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
            filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 479px;
    left: 0px;
    top: 57px;
}
#pfp_pic_mob_top_profile {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: white;
    left: 47px;
    top: 0px;
    border-radius: 50%;
    overflow: hidden;
}
#Mohammed_Affan_mob_top_profile {
    left: 15px;
    top: 124px;
    position: absolute;
    overflow: visible;
    width: 198px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#location_grp_mob_top_profile {
    position: absolute;
    width: 239px;
    height: 22px;
    left: 15px;
    top: 156px;
    overflow: visible;
}
#India_Karnataka_mob_top_profil {
    left: 39px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 201px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#india_img_mob_top_profile {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 2px;
    top: 0px;
    overflow: visible;
}
#skills_grp__mob_top_profile {
    position: absolute;
    width: 153px;
    height: 22px;
    left: 15px;
    top: 188px;
    overflow: visible;
}
#skillmob_top_profile_ {
    position: absolute;
    width: 29px;
    height: 22px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#mob_top_profile_designer_skill {
    left: 39px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#connections_grp_mob_top_profil {
    position: absolute;
    width: 128px;
    height: 31px;
    left: 138px;
    top: 262px;
    overflow: visible;
}
#all_con_mob_top_profile {
    fill: rgba(163,233,200,1);
}
.all_con_mob_top_profile {
    position: absolute;
    overflow: visible;
    width: 128px;
    height: 31px;
    left: 0px;
    top: 0px;
}
#mob_top_profile_circle_ {
    fill: rgba(255,255,255,1);
}
.mob_top_profile_circle_ {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 3px;
    top: 3px;
}
#n_23454mob_top_profile {
    left: 5px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#Connections_lbl_mob_top_profil {
    left: 33px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#QR_btn_grp_mob_top_profile {
    position: absolute;
    width: 36px;
    height: 36px;
    left: 161px;
    top: 57px;
    overflow: visible;
}
#QR_circle_bdr_mob_top_profile {
    fill: rgba(255,255,255,1);
}
.QR_circle_bdr_mob_top_profile {
    -webkit-filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 54px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#QR_img_mob_top_profile {
    position: absolute;
    width: 23px;
    height: 23px;
    left: 7px;
    top: 7px;
    overflow: visible;
}
#profile_link_grp__mob_top_prof {
    position: absolute;
    width: 36px;
    height: 36px;
    right: 50px;
    top: 60px;
    overflow: visible;
}
#offer_circle_mob_top_profile {
    fill: rgba(255,255,255,1);
}
.offer_circle_mob_top_profile {
    -webkit-filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 54px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#url_img_mob_top_profile {
    position: absolute;
    width: 23px;
    height: 23px;
    left: 7px;
    top: 7px;
    overflow: visible;
}
#About_lbl_lorem_mob_top_profil {
    left: 17px;
    top: 313px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#Scroll_Group_9_mob_top_profile {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 178px;
    left: 0px;
    top: 337px;
    overflow-x: hidden;
    overflow-y: auto;
}
#Lorem_ipsum_dolor_sit_amet_ui_mob {
    left: 17px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 352px;
    height: 140px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#followers_grp_lbl_mob_top_prof {
    position: absolute;
    width: 113px;
    height: 31px;
    left: 15px;
    top: 262px;
    overflow: visible;
}
#overall_mob_top_profile {
    fill: rgba(163,233,200,1);
}
.overall_mob_top_profile {
    position: absolute;
    overflow: visible;
    width: 113px;
    height: 31px;
    left: 0px;
    top: 0px;
}
#mob_top_profile_circle {
    fill: rgba(255,255,255,1);
}
.mob_top_profile_circle {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 3px;
    top: 3px;
}
#n_23mob_top_profile {
    left: 5px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#Followers_lbl_mob_top_profile {
    left: 33px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#skills_grp_mob_top_profile {
    position: absolute;
    width: 226px;
    height: 22px;
    left: 15px;
    top: 220px;
    overflow: visible;
}
#skill_img__mob_top_profile {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#UIUX_designer_skill_lbl_mob_to {
    left: 39px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 188px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#pen_edit_ban_img {
    position: absolute;
    width: 23px;
    height: 23px;
    left:90%;
    top: 15px;
    overflow: visible;
}
#pen_edit_banner_img {
    position: absolute;
    width: 23px;
    height: 23px;
    left:90%;
    top: 182px;
    overflow: visible;
}

	.mediaViewInfo {
		--web-view-name: theme small  mob;
		--web-view-id: theme_small__mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: theme_small__mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#theme_small__mob {
		position: absolute;
		width: 392px;
		height: 812px;
        border-radius: 15px;
        top:60%;
        left:50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: rgb(247 255 251);    
		overflow: hidden;
		--web-view-name: theme small  mob;
		--web-view-id: theme_small__mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#select_btn_grp_mob_theme_page {
		position: absolute;
		width: 149px;
		height: 54px;
		left: 120px;
		top: 734px;
		overflow: visible;
	}
	#select_btn_live_theme_mob {
		fill: rgba(143,233,191,1);
	}
	.select_btn_live_theme_mob {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 167px;
		height: 72px;
		left: 0px;
		top: 0px;
	}
	#select_website_1_op_live_theme {
		left: 47px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 56px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#close_small_theme_selector {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 359px;
		top: 14px;
		overflow: visible;
	}
	#Scroll_theme_edit_grp_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 392px;
		height: 676px;
		left: 0px;
		top: 45px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#emerald_grp_mob {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 29px;
		top: 12px;
		overflow: visible;
	}
	#layout_1_small_theme_mob_emera {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_1_small_theme_emeral {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_1_small_theme_emeral {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 167px;
	}
	#Enchanting_Emerald_small_theme_mob {
		left: 71px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_1_mob_small_theme_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#flamingo_fiesto_grp_mob {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 29px;
		top: 241px;
		overflow: visible;
	}
	#website_2_layout_small_theme_m {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_2_small_theme_fiesta {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_2_small_theme_fiesta {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 60px;
		top: 167px;
	}
	#Flamingo_Fiesta_lbl_small_them_mob {
		left: 85px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_2_mob_small_theme_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#ocean_bliss_grp_ {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 29px;
		top: 469px;
		overflow: visible;
	}
	#mob_bliss_layout_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_3_small_theme_mob_bl {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_3_small_theme_mob_bl {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 69px;
		top: 167px;
	}
	#ocean_blue_lbl_small_theme_mob {
		left: 94px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_3_pc_small_theme_mob_bliss {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#grp_new_edit_mob {
		position: absolute;
		width: 330px;
		height: 412px;
		left: 25px;
		top: 698px;
		overflow: visible;
	}
	#layout_4_small_theme_edit_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_4_small_theme_edit_m {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_4_small_theme_edit_m {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 167px;
	}
	#sahara_small_theme_edit_mob {
		left: 71px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_4_mob_small_theme_edit_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#layout_5_small_theme_edit_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 228px;
		overflow: visible;
	}
	#radio_btn_5_small_theme_edit_m {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_5_small_theme_edit_m {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 395px;
	}
	#shadow_space_small_theme_edit_mob {
		left: 74px;
		top: 392px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_5_mob_small_theme_edit_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 228px;
		overflow: visible;
	}

	#fash_21grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 4576px;
		overflow: visible;
	}
	#fash_22grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 4805px;
		overflow: visible;
	}
	#fash_23grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 5034px;
		overflow: visible;
	}
	#fash_24grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 5263px;
		overflow: visible;
	}
	#fash_25grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 5492px;
		overflow: visible;
	}
	#fash_26grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 5721px;
		overflow: visible;
	}
	#fash_27grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 5950px;
		overflow: visible;
	}
	#fash_28grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 6179px;
		overflow: visible;
	}
	#fash_29grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 6408px;
		overflow: visible;
	}
	#fash_30grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 6637px;
		overflow: visible;
	}
	#fash_31grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 6866px;
		overflow: visible;
	}
	#fash_32grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 7095px;
		overflow: visible;
	}
	#fash_33grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 7324px;
		overflow: visible;
	}
	#fash_34grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 7553px;
		overflow: visible;
	}
	#fash_35grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 7782px;
		overflow: visible;
	}
	#fash_36grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 8011px;
		overflow: visible;
	}
	#fash_37grp_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 8240px;
		overflow: visible;
	}
	#layout_21_small_theme_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_21_mob_small_theme_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_21small_theme_mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_21small_theme_mob {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#fashion_21_finds_small_theme_m {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#fash_20grp_theme_mob {
	
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 4347px;
		overflow: visible;
	}
	#layout_20small_theme_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_20mob_small_theme_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_20small_theme_mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_20small_theme_mob {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#fashion_20_finds_small_theme_m {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#med_19grp_dummy_theme_mob {
	
		position: absolute;
		width: 330px;
		height: 175px;
		left: 31px;
		top: 4127px;
		overflow: visible;
	}
	#layout_19small_theme_dummy_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_19small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_19small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 158px;
	}
	#sahara_19_small_theme_dummy_mo {
		left: 71px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_19mob_small_theme_dummy_mo {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#shadow_18_grp_dummy_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 175px;
		left: 31px;
		top: 3907px;
		overflow: visible;
	}
	#layout_18small_theme_dummy_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_18small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_18small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#shadow_18space_small_theme_dum {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_18mob_small_theme_dummy_mo {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#school_17grp_dummy_theme_mob {
		
		position: absolute;
		width: 330px;
		height: 175px;
		left: 31px;
		top: 3687px;
		overflow: visible;
	}
	#layout_17small_theme_dummy_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_17small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_17small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#knowledge17_kornersmall_theme_ {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 123px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_17mob_small_theme_dummy_mo {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#car16_grp_dummy_theme_mob {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 3458px;
		overflow: visible;
	}
	#layout_16small_theme_dummy_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_16mob_small_theme_dummy_mo {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_16small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_16small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 167px;
	}
	#drive_16velocity_small_theme_d {
		left: 71px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#fash_grp_theme_mob {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 3229px;
		overflow: visible;
	}
	#layout_9_small_theme_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_9_mob_small_theme_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_9_small_theme_mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_9_small_theme_mob {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#fashion_finds_small_theme_mob {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#med_grp_dummy_theme_mob {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 31px;
		top: 3009px;
		overflow: visible;
	}
	#layout_4_small_theme_dummy_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_4_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_4_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 158px;
	}
	#sahara_small_theme_dummy_mob {
		left: 71px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_4_mob_small_theme_dummy_mo {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#shadow__grp_dummy_theme_mob {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 31px;
		top: 2789px;
		overflow: visible;
	}
	#layout_5_small_theme_dummy_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_5_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_5_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#shadow_space_small_theme_dummy {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_5_mob_small_theme_dummy_mo {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#school_grp_dummy_theme_mob {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 31px;
		top: 2569px;
		overflow: visible;
	}
	#layout_6_small_theme_dummy_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_6_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_6_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#knowledge_kornersmall_theme_du {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 123px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_6_mob_small_theme_dummy_mo {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#car_grp_dummy_theme_mob {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 2340px;
		overflow: visible;
	}
	#layout_7_small_theme_dummy_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_7_mob_small_theme_dummy_mo {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_7_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_7_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 167px;
	}
	#drive_velocity_small_theme_dum {
		left: 71px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#bike_grp_dummy_theme_mob {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 2111px;
		overflow: visible;
	}
	#layout_8_small_theme_dummy_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_8_mob_small_theme_dummy_mo {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_7_small_theme_dummy__eq {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_7_small_theme_dummy__eq {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#ride_revolutionbigtheme_dummy_ {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#fashion_finds_grp_dummy_theme_mob {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 31px;
		top: 1882px;
		overflow: visible;
	}
	#layout_9_small_theme_dummy_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_9_mob_small_theme_dummy_mo {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_9_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_9_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#fashion_finds_small_theme_dumm {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#ride_bike_theme_mob {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 29px;
		top: 1653px;
		overflow: visible;
	}
	#layout_9_small_theme_mob_ey {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_9_mob_small_theme_mob_ez {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_9_small_theme_mob_e {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_9_small_theme_mob_e {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#fashion_finds_small_theme_mob_e {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#drive_velocity_grp_mob_them_6 {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 33px;
		top: 1404px;
		overflow: visible;
	}
	#layout_8_small_theme_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_8_mob_small_theme_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_7_small_theme_mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_7_small_theme_mob {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#ride_revolutionbigtheme_mob {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#knowledge_grp_mob_them_7654 {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 33px;
		top: 1155px;
		overflow: visible;
	}
	#layout_7_small_theme_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_7_mob_small_theme_mob {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_7_small_theme_mob_fa {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_7_small_theme_mob_fa {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 167px;
	}
	#drive_velocity_small_theme_mob {
		left: 71px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
.mediaViewInfo {
    --web-view-name: ad profile page pc;
    --web-view-id: ad_profile_page_pc;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: ad_profile_page_pc;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#ad_profile_page_pc {
    position: relative;
    width: 100%;
    height: 91px;
    /* top: calc(100% - 200px); */
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: ad profile page pc;
    --web-view-id: ad_profile_page_pc;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#ad_bdr {
    fill: rgba(177,224,201,1);
}
.ad_bdr {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 90px;
    left: 0px;
    top: 0px;
}
#AD_AREA_lbl {
    /* left: 282px; */
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    color: rgba(37,46,72,1);
}
.mediaViewInfo {
    --web-view-name: off feed;
    --web-view-id: off_feed;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: off_feed;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#off_feed {
    position: relative;
    width: 100%;
    height: 257px;
    /* top: calc(100% - 100px); */
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: off feed;
    --web-view-id: off_feed;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#offering_ffeed_grp {
    position: absolute;
    width: 100%;
    height: 251.209px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#off_feed_bdr {
    fill: rgba(255,255,255,1);
}
.off_feed_bdr {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
            filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 259px;
    left: 0px;
    top: 0px;
}
#Offerings_Feed_lbl {
    left: 20px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#off_feed_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 250.778px;
    left: 0px;
    top: 0.431px;
    display: inline-flex;
    overflow-x: scroll;
    white-space: nowrap;
    overflow-y: hidden;
}
/* #shadow_offf_feed {
    fill: rgba(255,255,255,1);
}
.shadow_offf_feed {
    filter: drop-shadow(0px 10px 80px rgba(123, 126, 246, 0.251));
    position: absolute;
    overflow: visible;
    width: 401px;
    height: 348px;
    left: 30px;
    top: 83.244px;
} */
#card_1_grp_profile_off {
    position: relative;
    width: 187px;
    height: 166px;
    left: 16.999px;
    top: 47.569px;
    margin: 5px; 
    overflow: visible;
}
#card_1_of_feed_1 {
    position: absolute;
    width: 187px;
    height: 125.35px;
    left: 0px;
    top: 18.325px;
    object-fit: contain;
    border-radius: 5px;
    overflow: hidden;
}
#Offering_lbl {
    left: 58px;
    top: 149px;
    position: absolute;
    overflow: visible;
    width: 59px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,46,72,1);
}
#Mohammed_Affan_lbl_1 {
    left: 36px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,46,72,1);
}
.mediaViewInfo {
    --web-view-name: req feeed;
    --web-view-id: req_feeed;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: req_feeed;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#req_feeed {
    position: relative;
    width: 100%;
    height: 257px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: req feeed;
    --web-view-id: req_feeed;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#req_feeed_grp {
    position: absolute;
    width: 100%;
    height: 251.209px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#req_feed_bdr {
    fill: rgba(255,255,255,1);
}
.req_feed_bdr {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
            filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 259px;
    left: 0px;
    top: 0px;
}
#Requirements_Feed_lbl {
    left: 20px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 136px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#req_feed_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 250.778px;
    left: 0px;
    top: 0.431px;
    display: inline-flex;
    overflow-x: scroll;
    white-space: nowrap;
    overflow-y: hidden;
}
#card_1_grp_profile_req {
    position: relative;
    width: 187px;
    height: 166px;
    left: 17px;
    top: 47.569px;
    overflow: visible;
}
#req_feed_1_card {
    position: absolute;
    width: 187px;
    height: 125.35px;
    left: 0px;
    top: 19.879px;
    object-fit: contain;
    overflow: hidden;
}
#Offering_1_lbl {
    left: 58px;
    top: 149px;
    position: absolute;
    overflow: visible;
    width: 59px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,46,72,1);
}
#Mohammed_Affan_lbl {
    left: 36px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,46,72,1);
}
.mediaViewInfo {
    --web-view-name: noti;
    --web-view-id: notification_Overall_pc;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: notification_Overall_pc;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#notification_Overall_pc {
    position: relative;
    width: 333px;
    height: 421px;
    margin: 15px;
    /* margin-top: 75px; */
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: noti;
    --web-view-id: notification_Overall_pc;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#notification_grp {
    position: absolute;
    width: 326px;
    height: 415px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#notification_bdr {
    fill: rgba(255,255,255,1);
}
.notification_bdr {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
            filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
    position: absolute;
    overflow: visible;
    width: 334px;
    height: 423px;
    left: 0px;
    top: 0px;
}
#Notifications_lbl {
    left: 40px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(37,46,72,1);
}
#noti_scroll_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 326px;
    height: 355px;
    left: 0px;
    top: 41px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#card_1_noti {
    position: absolute;
    width: 251px;
    height: 68px;
    left: 38px;
    top: 15px;
    overflow: visible;
}
#down_shadow {
    fill: rgba(255,255,255,1);
}
.down_shadow {
    -webkit-filter: drop-shadow(0px 10px 80px rgba(123, 126, 246, 0.322));
            filter: drop-shadow(0px 10px 80px rgba(123, 126, 246, 0.322));
    position: absolute;
    overflow: visible;
    width: 467px;
    height: 291px;
    left: 12px;
    top: 0px;
}
#bg_pfp_shadow {
    fill: rgba(255,255,255,1);
}
.bg_pfp_shadow {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 51px;
    left: 0px;
    top: 0px;
}
#Mark_Damon_lbl {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    left: 5px;
    top: 0px;
    position: absolute;
    overflow: hidden;
    width: 242px;
    height: 51px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#Mark_Damon_lbl_ {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    left: 118px;
    top: 51px;
    position: absolute;
    overflow: visible;
    width: 134px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}

	.Aug_PC_mediaViewInfo {
		--web-view-name: calender;
		--web-view-id: calender;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: calender;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Aug_PC_calender {
		position: relative;
    width: 306px;
    height: 276px;
    margin: 15px;
    margin-top: 80px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: calender;
    --web-view-id: calender;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
	}
	#Aug_PC_Rectangle_692 {
		fill: rgba(255,255,255,1);
	}
	.Aug_PC_Rectangle_692 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 309px;
		height: 233px;
		left: 8px;
		top: 46px;
	}
	#Aug_PC_Rectangle_691 {
		fill: rgba(255,255,255,1);
	}
	.Aug_PC_Rectangle_691 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 309px;
		height: 46px;
		left: 8px;
		top: 9px;
	}
	#Aug_PC_Group_14898 {
		position: absolute;
		width: 283px;
		height: 242px;
		left: 12px;
		top: 16px;
		overflow: visible;
	}
	#Aug_PC_August_2023 {
		left: 105px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_Path_6951 {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1.2000000476837158px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Aug_PC_Path_6951 {
		overflow: visible;
		position: absolute;
		width: 6.273px;
		height: 10.849px;
		left: 8.5px;
		top: 2.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Aug_PC_Path_6952 {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1.2000000476837158px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Aug_PC_Path_6952 {
		overflow: visible;
		position: absolute;
		width: 6.273px;
		height: 10.849px;
		left: 270.5px;
		top: 2.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Aug_PC_Mon {
		left: 0px;
		top: 34px;
		position: absolute;
		overflow: visible;
		width: 23px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_Tue {
		left: 46px;
		top: 34px;
		position: absolute;
		overflow: visible;
		width: 19px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_Wed {
		left: 87px;
		top: 34px;
		position: absolute;
		overflow: visible;
		width: 23px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_Thu {
		left: 133px;
		top: 34px;
		position: absolute;
		overflow: visible;
		width: 19px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_Fri {
		left: 179px;
		top: 34px;
		position: absolute;
		overflow: visible;
		width: 13px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_Sat {
		left: 221px;
		top: 34px;
		position: absolute;
		overflow: visible;
		width: 17px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_Sun {
		left: 263px;
		top: 34px;
		position: absolute;
		overflow: visible;
		width: 21px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_1 {
		left: 4px;
		top: 72px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(170,170,170,1);
	}
	#Aug_PC_n_ {
		left: 54px;
		top: 72px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__cs {
		left: 97px;
		top: 72px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__ct {
		left: 139px;
		top: 72px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__cu {
		left: 182px;
		top: 72px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__cv {
		left: 224px;
		top: 72px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__cw {
		left: 270px;
		top: 72px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__cx {
		left: 8px;
		top: 111px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__cy {
		left: 54px;
		top: 111px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__cz {
		left: 97px;
		top: 111px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_0 {
		left: 135px;
		top: 111px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_1_c {
		left: 179px;
		top: 111px;
		position: absolute;
		overflow: visible;
		width: 13px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_2 {
		left: 222px;
		top: 111px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_3 {
		left: 266px;
		top: 111px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_4 {
		left: 4px;
		top: 150px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_5 {
		left: 49px;
		top: 150px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_6 {
		left: 92px;
		top: 150px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_7 {
		left: 135px;
		top: 150px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_8 {
		left: 178px;
		top: 150px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_9 {
		left: 222px;
		top: 150px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_0_da {
		left: 266px;
		top: 150px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_1_db {
		left: 4px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_2_dc {
		left: 49px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_3_dd {
		left: 92px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_4_de {
		left: 135px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_5_df {
		left: 178px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_6_dg {
		left: 222px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_7_dh {
		left: 266px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_8_di {
		left: 4px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_9_dj {
		left: 49px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_0_dk {
		left: 92px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n_1_dl {
		left: 135px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#Aug_PC_n__dm {
		left: 182px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(170,170,170,1);
	}
	#Aug_PC_n__dn {
		left: 224px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(170,170,170,1);
	}
	#Aug_PC_n__do {
		left: 270px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(170,170,170,1);
	}

.mediaViewInfo {
    --web-view-name: event feed;
    --web-view-id: event_feed;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: event_feed;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#event_feed {
    position: relative;
    width: 100%;
    height: 167px;
    margin-top: 15px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: event feed;
    --web-view-id: event_feed;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Events_Feed_lbl_profile_page {
    left: 20px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 84px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#event_feed_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 147px;
    left: 0px;
    top: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
}
#event_feed_grp {
    position: relative;
    width: 350px;
    height: 100px;
    left: 11px;
    top: 13px;
    margin-right: 10px;
    overflow: visible;
}
#bdr_card_1 {
    fill: rgba(255,255,255,1);
}
.bdr_card_1 {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(177, 224, 201, 1));
            filter: drop-shadow(5px 5px 2px rgba(177, 224, 201, 1));
    position: absolute;
    overflow: visible;
    width: 358px;
    height: 108px;
    left: 0px;
    top: 0px;
}
#img_card_1_hmm {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 16px;
    top: 15px;
    overflow: visible;
}
#Events_name_lbl {
    left: 111px;
    top: 15px;
    position: absolute;
    overflow: hidden;
    width: 170px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#Events_Feed_lbl_profile_page_l {
    left: 111px;
    top: 55px;
    position: absolute;
    overflow: visible;
    width: 219px;
    height: 40px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#Category_-_Education_lbl {
    left: 111px;
    top: 34px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#n_50000_lbl {
    left: 293px;
    top: 15px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
.mediaViewInfo {
    --web-view-name: sugg;
    --web-view-id: sugg;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: sugg;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#sugg {
    position: absolute;
    width: 334px;
    height: 366px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: sugg;
    --web-view-id: sugg;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#suggestions_grp_profile {
    position: absolute;
    width: 326px;
    height: 359px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#suggestions_bdr {
    fill: rgba(255,255,255,1);
}
.suggestions_bdr {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
            filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
    position: absolute;
    overflow: visible;
    width: 334px;
    height: 367px;
    left: 0px;
    top: 0px;
}
#Suggestions_lbl_profile {
    left: 40px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 92px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(37,46,72,1);
}
#suggestions_scroll_profile_pag {
    mix-blend-mode: normal;
    position: absolute;
    width: 320px;
    height: 307px;
    left: 6px;
    top: 41px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#card_1_suggestion {
    position: relative;
    width: 251px;
    height: 95px;
    left: 34px;
    top: 11px;
    margin-top: 15px;
    overflow: visible;
}
/* #dow_shadow_sugg {
    fill: rgba(255,255,255,1);
}
.dow_shadow_sugg {
    filter: drop-shadow(0px 10px 80px rgba(123, 126, 246, 0.322));
    position: absolute;
    overflow: visible;
    width: 467px;
    height: 317px;
    left: 12px;
    top: 18px;
} */
#bg_shadow_sugg {
    fill: rgba(255,255,255,1);
}
.bg_shadow_sugg {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 94px;
    left: 0px;
    top: 0px;
}
#img_bdr_suggestion {
    position: absolute;
    width: 251px;
    height: 94px;
    left: 0px;
    top: 1px;
    border: 1px solid rgba(67, 205, 138, 0.651);
    border-radius: 25px;
    background-color: rgb(112, 243, 179); /* Fallback color */
    background-color: rgb(67,205,138, 0.4); /* Black w/opacity/see-through */
    object-fit: contain;
    overflow: hidden;
}
#sugg_down_bdr_ {
    fill: url(#sugg_down_bdr_);
}
.sugg_down_bdr_ {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 59px;
    left: 0px;
    top: 36px;
}
#Mark_Damon_lbl_suggestions {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    left: 5%;
    /* top: 70px; */
    bottom: 0px;
    position: absolute;
    overflow: visible;
    width: 90%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    background-color: rgba(135, 207, 172, 0.651);
    color: rgba(247,249,255,1);
}
.mediaViewInfo {
    --web-view-name: ad area;
    --web-view-id: ad_area;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: ad_area;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#ad_area {
    position: relative;
    width: 100%;
    height: 94px;
    margin-left: 5px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: ad area;
    --web-view-id: ad_area;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#ad_bdr_ad_mob {
    fill: rgba(177,224,201,1);
}
.ad_bdr_ad_mob {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 90px;
    /* left: 18px; */
    top: 2px;
}
#AD_AREA_lbl_ad_mob {
    /* left: 115px; */
    top: 22px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    color: rgba(37,46,72,1);
}
.mediaViewInfo {
    --web-view-name: off feed mob;
    --web-view-id: off_feed_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: off_feed_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#off_feed_mob {
    position: relative;
    width: 100%;
    height: 623px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: off feed mob;
    --web-view-id: off_feed_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#offering_ffeed_grp_offy_feed_m {
    position: absolute;
    width: 100%;
    height: 608.008px;
    margin: 5px;
    top: 8px;
    overflow: visible;
}
#off_feed_bdr_offy_feed_mob {
    fill: rgba(255,255,255,1);
}
.off_feed_bdr_offy_feed_mob {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
            filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 616px;
    left: 0.736px;
    top: 0px;
}
#Offerings_Feedoffy_feed_mob {
    left: 12.736px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#off_feed_scroll_offy_feed_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 570.576px;
    left: 0px;
    top: 35.431px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#shadow_offy_feed_mob_feed {
    fill: rgba(255,255,255,1);
}
.shadow_offy_feed_mob_feed {
    -webkit-filter: drop-shadow(0px 10px 80px rgba(123, 126, 246, 0.251));
            filter: drop-shadow(0px 10px 80px rgba(123, 126, 246, 0.251));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 333px;
    left: 14px;
    top: 81.244px;
}
#card_1_offy_feed_mob {
    position: relative;
    width: 50%;
    height: 130px;
    left: 9px;
    top: 58.569px;
    display: inline-block;
    overflow: visible;
}
#card_1_of_feed_1_offy_feed_mob {
    position: absolute;
    width: 175px;
    height: 91.35px;
    left: 0px;
    top: 21px;
    object-fit: contain;
    overflow: hidden;
}
#Offering_lbl_offy_feed_mob {
    top: 113px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,46,72,1);
}
#Mohammed_Affan_offy_feed_mob {
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,46,72,1);
}
.mediaViewInfo {
    --web-view-name: req feed mob;
    --web-view-id: req_feed_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: req_feed_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#req_feed_mob {
    position: relative;
    width: 100%;
    height: 629px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: req feed mob;
    --web-view-id: req_feed_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#req_feed_mob_ffeed_grp {
    position: absolute;
    width: 100%;
    height: 608px;
    /* left: 12.264px; */
    top: 11px;
    overflow: visible;
}
#req_feed_mobfeed_bdr {
    fill: rgba(255,255,255,1);
}
.req_feed_mobfeed_bdr {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
            filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 616px;
    left: 0.736px;
    top: 0px;
}
#req_feed_mob_Feed_lbl {
    left: 11.736px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 136px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#req_feed_mob_feed_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 565.576px;
    left: 0px;
    top: 40px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#card_1_req_feed_mob {
    position: relative;
    width: 50%;
    height: 130px;
    left: 5px;
    top: 58.569px;
    
    display: inline-block;
    overflow: visible;
}
#card_1_req_feed_mob_bf {
    position: absolute;
    width: 175px;
    height: 91.35px;
    left: 0px;
    top: 21px;
    object-fit: contain;
    overflow: hidden;
}
#req_lbl__req_feed_mob {
    top: 113px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,46,72,1);
}
#Mohammed_Affan_lbl_req_feed_mo {
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,46,72,1);
}
.mediaViewInfo {
    --web-view-name: event feed mob;
    --web-view-id: event_feed_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: event_feed_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#event_feed_mob {
    position: relative;
    width: 100%;
    height: 399px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: event feed mob;
    --web-view-id: event_feed_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Events_Feed_lbl_profile_page_m {
    left: 42px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 84px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#event_feed_scroll_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 371px;
    left: 0px;
    top: 28px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: auto;
    align-content: flex-start;
}
#event_feed_grp_feed_mob_eventz {
    position: relative;
    width: 100%;
    height: 100px;
    /* left: 22px; */
    margin-bottom: 10px;
    top: 11px;
    overflow: visible;
}
#bdr_card_1_feed_mob_eventz {
    fill: rgba(255,255,255,1);
}
.bdr_card_1_feed_mob_eventz {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(177, 224, 201, 1));
            filter: drop-shadow(5px 5px 2px rgba(177, 224, 201, 1));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 108px;
    left: 0px;
    top: 0px;
}
#img_card_feed_mob_eventz {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 16px;
    top: 15px;
    overflow: visible;
}
#feed_mob_eventz_name_lbl {
    left: 111px;
    top: 15px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#Eventsfeed_mob_eventz_profile_ {
    left: 111px;
    top: 55px;
    position: absolute;
    overflow: visible;
    width: 219px;
    height: 40px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#Category_-_Education_lbl_feed_ {
    left: 111px;
    top: 34px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#n_50000_lbl_feed_mob_eventz {
    left: 317px;
    top: 15px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
.mediaViewInfo {
    --web-view-name: calender mob;
    --web-view-id: calender_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: calender_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#calender_mob {
    position: relative;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-left: 5px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: calender mob;
    --web-view-id: calender_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
.mediaViewInfo {
    --web-view-name: general;
    --web-view-id: general_mob_;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: general_mob_;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#general_mob_ {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 140px);
    top: 75px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: general;
    --web-view-id: general_mob_;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#edit_profile_general_mob {
    position: absolute;
    width: 109px;
    height: 25px;
    left: 31px;
    top: 6px;
    overflow: visible;
}
#user_pfp_pop_lbl_profile_mob_1 {
    position: absolute;
    width: 35px;
    height: 35px;
    left: 0px;
    top: 0px;
    overflow: hidden;
}
#user_back_profile_mob_ {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    top: 0px;
    overflow: hidden;
  
}
#Edit_Profile_lbl_profile_mob_1 {
    left: 50px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 79px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
.mediaViewInfo {
    --web-view-name: general tab;
    --web-view-id: general_tab;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: general_tab;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#general_tab {
    position: absolute;
    width: 100%;
    height: calc(100% - 185px);
    top: 120px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: general tab;
    --web-view-id: general_tab;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#gen_down_holder_edit_page {
    position: absolute;
    width: 100%;
    height: calc(100% - 35px);
    left: 0px;
    top: 38px;
    overflow: visible;
}
#gen_holder_mob_eit_page {
    position: absolute;
    width: 127.822px;
    height: 37.873px;
    left: 0px;
    top: 1px;
    overflow: visible;
}
#top_grp_heading_general {
    position: absolute;
    width: 322px;
    height: 20px;
    left: 20px;
    top: 13px;
    overflow: visible;
}
#Generallbl_profile_mob {
    left: 25px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#dossier_lbl_profile_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Location_lbl_profile_mob {
    left: 143px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#placeholder_lbl_profile_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 117px;
    top: 0px;
    overflow: visible;
}
#Payment_Details_lbl_profile_mo {
    left: 277px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 46px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#card_lbl_profile_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 248px;
    top: 0px;
    overflow: visible;
}
#general_page_scroll_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: calc(100% - 110px);
    left: 0px;
    top: 44px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#name_input_lbl_profile_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.name_input_lbl_profile_mob {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 75px;
    top: 38px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Name_lbl_profile_mob {
    left: 75px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#number_lbl_profile_mobinput {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.number_lbl_profile_mobinput {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 75px;
    top: 108px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Mobile_Number_lbl_profile_mob {
    left: 75px;
    top: 88px;
    position: absolute;
    overflow: visible;
    width: 106px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#email_input_lbl_profile_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.email_input_lbl_profile_mob {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 75px;
    top: 248px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Email_lbl_profile_mob {
    left: 75px;
    top: 228px;
    position: absolute;
    overflow: visible;
    width: 36px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#DDOB_lbl_profile_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.DDOB_lbl_profile_mob {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 75px;
    top: 388px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#DOB_lbl_profile_mob {
    left: 75px;
    top: 368px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#profession_input_lbl_profile_m {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.profession_input_lbl_profile_m {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 75px;
    top: 318px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Profession_lbl_profile_mob {
    left: 75px;
    top: 298px;
    position: absolute;
    overflow: visible;
    width: 70px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#introduction_input_lbl_profile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.introduction_input_lbl_profile {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 80px;
    left: 75px;
    top: 458px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Introduction_lbl_profile_mob {
    left: 75px;
    top: 438px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Vision_bdr_bdr_input_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Vision_bdr_bdr_input_mob {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 80px;
    left: 75px;
    top: 568px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Vision_lbl_profile_mob {
    left: 75px;
    top: 548px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#mission_bdr_input_lbl_profile_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.mission_bdr_input_lbl_profile_ {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 80px;
    left: 75px;
    top: 678px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Mission_lbl_profile_mob {
    left: 75px;
    top: 658px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#principle_bdr_lbl_profile_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.principle_bdr_lbl_profile_mob {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 80px;
    left: 75px;
    top: 788px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Principles_lbl_profile_mob {

    position: absolute;
    overflow: visible;
    width: 64px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    left: 75px;
    top: 768px;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Gender_lbl_profile_mob {
  
    position: absolute;
    overflow: visible;
    width: 50px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    left: 163px;
    top: 158px;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Group_gender_mob {
    position: absolute;
    width: 146px;
    height: 40px;
    left: 115px;
    top: 181px;
    overflow: visible;
}
#men_btn_grp {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#btn_bdr {
    fill: rgba(163,233,200,1);
}
.btn_bdr {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#man_btn_img {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#women_btn_grp {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 54px;
    top: 0px;
    overflow: visible;
}
#btn_bdr_ {
    fill: rgba(163,233,200,1);
}
.btn_bdr_ {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#woman_btn_ {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#other_btn_gender {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 106px;
    top: 0px;
    overflow: visible;
}
#bdr_other_btn {
    fill: rgba(163,233,200,1);
}
.bdr_other_btn {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#gender-neutral_btn_img {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#add_btn_tax_authority_mob {
    position: absolute;
    width: 109px;
    height: 36px;
    left: 35%;
    top: calc(100% - 50px);
    overflow: visible;
}
#add_bg_Add_Tax_Authority {
    fill: rgba(163,233,200,1);
}
.add_bg_Add_Tax_Authority {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 127px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#Active_lbl_Add_Tax_Authority {
    left: 41px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 28px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
.mediaViewInfo {
    --web-view-name: location tab;
    --web-view-id: location_tab;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: location_tab;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#location_tab {
    position: absolute;
    width: 100%;
    height: calc(100% - 185px);
    top: 120px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: location tab;
    --web-view-id: location_tab;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#holder_mob_eit_page_location {
    position: absolute;
    width: 131.234px;
    height: 38.112px;
    left: 121.883px;
    top: 1px;
    overflow: visible;
}
#holder_down_locatiom_mob_eit_p {
    position: absolute;
    width: 100%;
    height: calc(100% - 35px);
    left: 0px;
    top: 38.112px;
    overflow: visible;
}
#top_grp_heading_location {
    position: absolute;
    width: 333px;
    height: 20px;
    left: 20px;
    top: 12.056px;
    overflow: visible;
}
#Generallbl_profile_mob_locatio {
    left: 25px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#dossier_lbl_profile_mob_locati {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Location_lbl_profile_mob_locat {
    left: 143px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#placeholder_lbl_profile_mob_lo {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 117px;
    top: 0px;
    overflow: visible;
}
#Payment_Details_lbl_profile_mo {
    left: 273px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#card_lbl_profile_mob_location {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 248px;
    top: 0px;
    overflow: visible;
}
#next_btn_mob_location {
    position: absolute;
    width: 109px;
    height: 36px;
    left: 35%;
    top: calc(100% - 50px);
    overflow: visible;
    cursor: pointer;
}
#add_bg_Add_Tax_Authority {
    fill: rgba(163,233,200,1);
}
.add_bg_Add_Tax_Authority {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 127px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#Active_lbl_Add_Tax_Authority {
    left: 41px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 28px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Scrolllocation_tab {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 50px);
    height: calc(100% - 110px);
    left: 45px;
    top: 46px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#address_grp_mob_location {
    position: absolute;
    width: 251px;
    height: 91px;
    left: 21px;
    top: 284px;
    overflow: visible;
}
#address_input_lbl_profile_mob_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.address_input_lbl_profile_mob_ {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 70px;
    left: 0px;
    top: 21px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Address_lbl_profile_mob_locati {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#pincode_grp_mob_location {
    position: absolute;
    width: 253px;
    height: 61px;
    left: 19px;
    top: 213px;
    overflow: visible;
}
#pincode_bdr_input_mob_location {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.pincode_bdr_input_mob_location {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 2px;
    top: 21px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Pincode_lbl_lbl_profile_pmob_l {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#city_grp_mob_location {
    position: absolute;
    width: 251px;
    height: 61px;
    left: 21px;
    top: 142px;
    overflow: visible;
}
#city_input_profile_mob_locatio {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.city_input_profile_mob_locatio {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 0px;
    top: 21px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#City_lbl_profile_mob_location {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#state_grp_mob_location {
    position: absolute;
    width: 251px;
    height: 61px;
    left: 21px;
    top: 71px;
    overflow: visible;
}
#state_drop_lbl_profile_mob_loc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.state_drop_lbl_profile_mob_loc {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 0px;
    top: 21px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#State_lbl_profile_mob_location {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 34px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#country_mob_location {
    position: absolute;
    width: 251px;
    height: 61px;
    left: 21px;
    top: 0px;
    overflow: visible;
}
#country_drop_lbl_profile_mob_l {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.country_drop_lbl_profile_mob_l {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 0px;
    top: 21px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Country_lbl_profile_mob_locati {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#whatsapp_input_edit_profile_mo {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.whatsapp_input_edit_profile_mo {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 21px;
    top: 578px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#whats_app_number_lbl_edit_mob {
    left: 21px;
    top: 558px;
    position: absolute;
    overflow: visible;
    width: 129px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#linked_in_edit_profile_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.linked_in_edit_profile_mob {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 21px;
    top: 648px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#linked_in_lbl_edit_profile_mob {
    left: 21px;
    top: 628px;
    position: absolute;
    overflow: visible;
    width: 87px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#insta_url_box_edit_profile_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.insta_url_box_edit_profile_mob {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 21px;
    top: 718px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#insta_url_lbl_edit_profile_mob {
    left: 21px;
    top: 698px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#socials_lbl_edit_profile_mob_e {
    left: 43px;
    top: 532px;
    position: absolute;
    overflow: visible;
    width: 128px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#social_img_edit_profile_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 21px;
    top: 533px;
    overflow: visible;
}
#longitude_grp_mob_location {
    position: absolute;
    width: 253px;
    height: 61px;
    left: 19px;
    top: 456px;
    overflow: visible;
}
#longi_bdr_input_mob_location {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.longi_bdr_input_mob_location {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 2px;
    top: 21px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#longitudelbl_lbl_profile_pmob_ {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#latitude_grp_mob_location {
    position: absolute;
    width: 251px;
    height: 61px;
    left: 21px;
    top: 385px;
    overflow: visible;
}
#latitude_input_profile_mob_loc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.latitude_input_profile_mob_loc {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 0px;
    top: 21px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#latitude_lbl_profile_mob_locat {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
.mediaViewInfo {
    --web-view-name: payment tab;
    --web-view-id: payment_tab;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: payment_tab;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#payment_tab {
    position: absolute;
    width: 100%;
    height: calc(100% - 185px);
    top: 120px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: payment tab;
    --web-view-id: payment_tab;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#billing_down_holder_mob_eit_pa {
    position: absolute;
    width: 100%;
    height: calc(100% - 35px);
    left: 0px;
    top: 37.671px;
    overflow: visible;
}
#billing_top_holder_mob_eit_pag {
    position: absolute;
    width: 122.703px;
    height: 37.671px;
    left: 252.297px;
    top: 0px;
    overflow: visible;
}
#top_grp_heading_payment {
    position: absolute;
    width: 333px;
    height: 20px;
    left: 20px;
    top: 11.615px;
    overflow: visible;
}
#Generallbl_profile_mob_payment {
    left: 25px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#dossier_lbl_profile_mob_paymen {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Location_lbl_profile_mob_payme {
    left: 143px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#placeholder_lbl_profile_mob_pa {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 117px;
    top: 0px;
    overflow: visible;
}
#Payment_Details_lbl_profile_mo {
    left: 273px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#card_lbl_profile_mob_payment {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 248px;
    top: 0px;
    overflow: visible;
}
#finish_btn_payment_paymet_page {
    position: absolute;
    width: 109px;
    height: 36px;
    left: 35%;
    top: calc(100% - 50px);
    overflow: visible;
}
#finish_bdr_paymet_page_mob {
    fill: rgba(163,233,200,1);
}
.finish_bdr_paymet_page_mob {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 127px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#finish_lbkl_paymet_page_mob {
    left: 38px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 34px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Scroll_payment_tab {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 50px);
    height: calc(100% - 110px);
    left: 45px;
    top: 46px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#payment_grp_all {
    position: absolute;
    width: 252px;
    height: 200px;
    left: 20px;
    top: 380px;
    overflow: visible;
}
#UPI_ID_grp_payment {
    position: absolute;
    width: 252px;
    height: 60px;
    left: 0px;
    top: 140px;
    overflow: visible;
}
#UPI_input_lbl_profile_paymet_p {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.UPI_input_lbl_profile_paymet_p {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 1px;
    top: 20px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#UPI_ID_lbl_profile_paymet_page {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#BC_grp_payment {
    position: absolute;
    width: 252px;
    height: 60px;
    left: 0px;
    top: 70px;
    overflow: visible;
}
#nilling_currency_input_paymet_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.nilling_currency_input_paymet_ {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 1px;
    top: 20px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Billing_Currency_lbl_profile__ {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#HBR_grp_paymen {
    position: absolute;
    width: 252px;
    height: 60px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#HBR_input_lbl_profile_paymet_p {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.HBR_input_lbl_profile_paymet_p {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 1px;
    top: 20px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Hour_Billing_Rate_lbl_profile_ {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 112px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#business_grp_details {
    position: absolute;
    width: 251px;
    height: 370px;
    left: 21px;
    top: 0px;
    overflow: visible;
}
#UPI_ID_grp_payment_cb {
    position: absolute;
    width: 251px;
    height: 60px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#UPI_input_lbl_profile_paymet_p_cc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.UPI_input_lbl_profile_paymet_p_cc {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 0px;
    top: 20px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#UPI_ID_lbl_profile_paymet_page_cd {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#UPI_ID_grp_payment_ce {
    position: absolute;
    width: 251px;
    height: 90px;
    left: 0px;
    top: 70px;
    overflow: visible;
}
#UPI_input_lbl_profile_paymet_p_cf {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.UPI_input_lbl_profile_paymet_p_cf {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 70px;
    left: 0px;
    top: 20px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#UPI_ID_lbl_profile_paymet_page_cg {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 114px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#UPI_ID_grp_payment_ch {
    position: absolute;
    width: 251px;
    height: 60px;
    left: 0px;
    top: 170px;
    overflow: visible;
}
#UPI_input_lbl_profile_paymet_p_ci {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.UPI_input_lbl_profile_paymet_p_ci {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 0px;
    top: 20px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#UPI_ID_lbl_profile_paymet_page_cj {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 107px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#UPI_ID_grp_payment_ck {
    position: absolute;
    width: 251px;
    height: 60px;
    left: 0px;
    top: 240px;
    overflow: visible;
}
#UPI_input_lbl_profile_paymet_p_cl {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.UPI_input_lbl_profile_paymet_p_cl {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 0px;
    top: 20px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#UPI_ID_lbl_profile_paymet_page_cm {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 97px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#UPI_ID_grp_payment_cn {
    position: absolute;
    width: 251px;
    height: 60px;
    left: 0px;
    top: 310px;
    overflow: visible;
}
#UPI_input_lbl_profile_paymet_p_co {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.UPI_input_lbl_profile_paymet_p_co {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 0px;
    top: 20px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#UPI_ID_lbl_profile_paymet_page_cp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 114px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}

	.mediaViewInfo {
		--web-view-name: people con;
		--web-view-id: people_con;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: people_con;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#people_con {
		position: absolute;
		width: 100vw;
		height: calc(100vh - 140px);
        top: 75px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: people con;
		--web-view-id: people_con;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#heading_grp_conreqsug_mob_con {
		position: absolute;
		width: 273px;
		height: 28px;
		left: 51px;
		top: 10px;
		overflow: visible;
	}
	#req_conreqsug_mob_con {
		opacity: 0.92;
		left: 108px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 60px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#con_grp_conreqsug_mob_con {
		position: absolute;
		width: 82px;
		height: 20px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#con_conreqsug_mob_con {
		opacity: 0.92;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 83px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,93,24,1);
	}
	#sugg_conreqsug_mob_con {
		opacity: 0.92;
		left: 192px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(48,47,47,1);
	}
	#underline_conreqsug_mob_con {
		fill: rgba(255,79,4,1);
	}
	.underline_conreqsug_mob_con {
		position: absolute;
		overflow: visible;
		width: 82px;
		height: 3px;
		left: 0px;
		top: 25px;
	}

	.mediaViewInfo {
		--web-view-name: people req;
		--web-view-id: people_req;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: people_req;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#people_req {
		position: absolute;
		width: 100vw;
		height: calc(100vh - 140px);
        top: 75px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: people req;
		--web-view-id: people_req;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#heading_grp_conreqsug_mob_req {
		position: absolute;
		width: 273px;
		height: 28px;
		left: 51px;
		top: 10px;
		overflow: visible;
	}
	#req_conreqsug_mob_req {
		opacity: 0.92;
		left: 108px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 60px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,79,4,1);
	}
	#selected_grp_conreqsug_mob_req {
		position: absolute;
		width: 82px;
		height: 20px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#con_conreqsug_mob_req {
		opacity: 0.92;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 83px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#sug_conreqsug_mob_req {
		opacity: 0.92;
		left: 192px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(48,47,47,1);
	}
	#underline_conreqsug_mob_req {
		fill: rgba(255,79,4,1);
	}
	.underline_conreqsug_mob_req {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 3px;
		left: 108px;
		top: 25px;
	}

	.mediaViewInfo {
		--web-view-name: people sugg;
		--web-view-id: people_sugg;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: people_sugg;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#people_sugg {
		position: absolute;
		width: 100vw;
		height: calc(100vh - 140px);
        top: 75px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: people sugg;
		--web-view-id: people_sugg;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#heading_grp_conreqsug_mob__sug {
		position: absolute;
		width: 273px;
		height: 28px;
		left: 51px;
		top: 10px;
		overflow: visible;
	}
	#req_conreqsug_mob__sug {
		opacity: 0.92;
		left: 108px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 60px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#selected_grp_conreqsug_mob__su {
		position: absolute;
		width: 82px;
		height: 20px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#con_conreqsug_mob__sug {
		opacity: 0.92;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 83px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#sug_conreqsug_mob__sug {
		opacity: 0.92;
		left: 192px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,79,4,1);
	}
	#underline_conreqsug_mob__sug {
		fill: rgba(255,79,4,1);
	}
	.underline_conreqsug_mob__sug {
		position: absolute;
		overflow: visible;
		width: 81px;
		height: 3px;
		left: 192px;
		top: 25px;
	}

	.mediaViewInfo {
		--web-view-name: Suggestions page;
		--web-view-id: Suggestions_page;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Suggestions_page;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Suggestions_page {
		position: absolute;
		width: calc(100vw - 120px);
		height: calc(100vh - 60px);
        top: 60px;
        left: 120px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Suggestions page;
		--web-view-id: Suggestions_page;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#heading_grp_conreqsug_sug_page {
		position: absolute;
		width: 364px;
		height: 42px;
		left: calc((100% - 364px)/2);
		top: 22px;
		overflow: visible;
	}
	#conreqsug_sug_page_pc_new_requ {
		opacity: 0.92;
		left: 151px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 65px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(0,0,0,1);
	}
	#conreqsug_sug_page_pc_new_conn {
		opacity: 0.92;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(0,0,0,1);
	}
	#conreqsug_sug_page_pc_new_sug {
		opacity: 0.92;
		left: 267px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 98px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(255,79,4,1);
	}
	#underline_conreqsug_sug_page_p {
		fill: rgba(255,79,4,1);
	}
	.underline_conreqsug_sug_page_p {
		position: absolute;
		overflow: visible;
		width: 99px;
		height: 3px;
		left: 267px;
		top: 39px;
	}
	#desgin_3_sug_page_pc_new {
		fill: rgba(248,248,248,1);
	}
	.desgin_3_sug_page_pc_new {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 203px;
		left: 0px;
		top: 94px;
	}
	#Scroll_suggestion_new_layout_c {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 625px;
		/* left: 59px; */
		top: 116px;
        display: block;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_suggestion_new_layout_c::-webkit-scrollbar{
		display: none;
	}
	#card_1_suggestion_page_new {
		position: relative;
		width: 186px;
		height: 319px;
		left: 15px;
		top: 16px;
        margin: 10px;
        display: inline-block;
		overflow: visible;
	}
	#bdr_card_1_sug_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.bdr_card_1_sug_sug_page_pc_new {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 193px;
		height: 327px;
		left: 0px;
		top: 0px;
	}
	#Anime_Edit_sug_page_pc_new {
		position: absolute;
		width: 150px;
		height: 142px;
		left: 18px;
		top: 35px;
		overflow: visible;
	}
	#job_work_new_layout {
		position: absolute;
		width: 170px;
		height: 25px;
		left: 12px;
		top: 182px;
		overflow: visible;
	}
	#job-seeker_sug_page_pc_new {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#recently_affreqevent_sug_page_ {
		left: 30px;
		top: 3px;
		position: absolute;
		overflow: hidden;
		width: 141px;
		height: 20px;
		/* line-height: 49px;
		margin-top: -17px; */
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: capitalize;
	}
	#Mohammed_Affan_sug_page_pc_new {
       
        top: 5px;
        position: absolute;
        overflow: hidden;
        width: 185px;
        height: 20px;
        /* line-height: 49px; */
        /* margin-top: -17px; */
        text-align: center;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        color: rgba(17,17,17,1);
        letter-spacing: -0.4791666793823242px;
	}
	#follow_grp_sug_page_pc_new {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 51.999px;
		top: 274px;
        cursor: pointer;
		overflow: visible;
	}
	#bdr_gollow_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.bdr_gollow_sug_page_pc_new {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#folow_img_sug_page_pc_new {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#connect_grp_sug_page_pc_new {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 8px;
		top: 274px;
        cursor: pointer;
		overflow: visible;
	}
	#con_bdr_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.con_bdr_sug_page_pc_new {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#conn_n_sug_page_pc_new {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#ignore_grp_sug_page_pc_new {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 96px;
		top: 275px;
        cursor: pointer;
		overflow: visible;
	}
	#bfdr_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.bfdr_sug_page_pc_new {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#ignore_sig_sug_page_pc_new {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#block_grp_sug_page_pc_new {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 140px;
		top: 275px;
        cursor: pointer;
		overflow: visible;
	}
	#bdr_block_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.bdr_block_sug_page_pc_new {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#boock_img_sug_page_pc_new {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#scroll_and_intro_detail_new {
		position: absolute;
		width: 163px;
		height: 61px;
		left: 12px;
		top: 212px;
		overflow: visible;
	}
	#presentation_sug_page_pc_new {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Scroll_intro_pc_sug_page {
		mix-blend-mode: normal;
		position: absolute;
		width: 133px;
		height: 60px;
		left: 30px;
		top: 1px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
    #Scroll_intro_pc_sug_page::-webkit-scrollbar {
        display: none;
    }
	#recently_event_com_suggestion_p {
		
		position: absolute;
		
		width: 135px;
		height: 59px;
		/* line-height: 49px; */
		/* margin-top: -17px; */
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
        overflow: visible;
		color: rgba(17,17,17,1);
		text-transform: capitalize;
	}
	.mediaViewInfo {
		--web-view-name: Request Page;
		--web-view-id: Request_Page;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Request_Page;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Request_Page {
		position: absolute;
		width: calc(100vw - 120px);
		height: calc(100vh - 60px);
        top: 60px;
        left: 120px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Request Page;
		--web-view-id: Request_Page;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#heading_grp_conreqsug_req_page {
		position: absolute;
		width: 364px;
		height: 42px;
		left: calc((100% - 364px)/2);
		top: 22px;
		overflow: visible;
	}
	#conreqsug_req_page_new_pc_req {
		opacity: 0.92;
		left: 151px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 65px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(255,79,4,1);
	}
	#selected_grp_conreqsug_req_pag {
		position: absolute;
		width: 99px;
		height: 24px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#conreqsug_req_page_new_pc_conn {
		opacity: 0.92;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(0,0,0,1);
	}
	#conreqsug_req_page_new_pc_sug {
		opacity: 0.92;
		left: 267px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 98px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(48,47,47,1);
	}
	#underline_conreqsug_req_page_n {
		fill: rgba(255,79,4,1);
	}
	.underline_conreqsug_req_page_n {
		position: absolute;
		overflow: visible;
		width: 64px;
		height: 3px;
		left: 151px;
		top: 39px;
	}
	#desgin_3_conreqsug_req_page_ne {
		fill: rgba(248,248,248,1);
	}
	.desgin_3_conreqsug_req_page_ne {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 203px;
		left: 0px;
		top: 94px;
	}
	#Scroll_req_new_layout {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 616px;
		/* left: 63px; */
		top: 118px;
        display: block;
		overflow-x: hidden;
		overflow-y: scroll;
	}
    #Scroll_req_new_layout::-webkit-scrollbar{
		display: none;
	}
	#card_1_req_page_pc_inichan {
		position: relative;
		width: 185px;
		height: 320px;
		left: 11px;
		top: 14px;
        display: inline-block;
        margin: 10px;
		overflow: visible;
	}
	#over_all_card_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.over_all_card_erq_page_new_pc {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 193px;
		height: 328px;
		left: 0px;
		top: 0px;
	}
	#Anime_Edit_goku_thats_me {
		position: absolute;
		width: 150px;
		height: 142px;
		left: 18px;
		top: 35px;
		overflow: visible;
	}
	#designation_grp_req_page_new {
		position: absolute;
		width: 170px;
		height: 25px;
		left: 12px;
		top: 182px;
		overflow: visible;
	}
	#job-seeker_img_erq_page_new_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#erq_page_new_pc_job_name {
		left: 30px;
		top: 3px;
		position: absolute;
		overflow: hidden;
		width: 141px;
		height: 20px;
	
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: capitalize;
	}
	#Mohammed_Affan_goku_v {
		left: 1px;
		top: 8px;
		position: absolute;
		overflow: hidden;
		width: 185px;
		height: 20px;
		/* line-height: 49px;
		margin-top: -17px; */
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
	}
	#reject_erq_page_new_pc {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 52px;
		top: 275px;
        cursor: pointer;
		overflow: visible;
	}
	#reqj_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.reqj_erq_page_new_pc {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#reject_img_erq_page_new_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#accept_grp_erq_page_new_pc {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 8px;
		top: 275px;
        cursor: pointer;
		overflow: visible;
	}
	#accept_bdr_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.accept_bdr_erq_page_new_pc {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#accept_img_erq_page_new_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#ignore_grp_erq_page_new_pc {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 96px;
		top: 276px;
        cursor: pointer;
		overflow: visible;
	}
	#bg_ignore_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.bg_ignore_erq_page_new_pc {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#ignore_u_erq_page_new_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#block_grp_erq_page_new_pc {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 140px;
		top: 276px;
        cursor: pointer;
		overflow: visible;
	}
	#block_bg_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.block_bg_erq_page_new_pc {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#block_img_erq_page_new_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#company_name_request_page_new_ {
		position: absolute;
		width: 170px;
		height: 25px;
		left: 12px;
		top: 212px;
		overflow: visible;
	}
	#office-building_req_page_new_p {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#OP_conreqsug_erq_page_new_pc {
		left: 30px;
		top: 3px;
		position: absolute;
		overflow: hidden;
		width: 141px;
		height: 20px;
	
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		text-transform: capitalize;
	}
	#marquee_op_intro_person {
		left: 8px;
		top: 247px;
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
        border-radius: 5px;
        background-color: rgba(219,242,231,1);
		color: rgba(17,17,17,1);
		text-transform: capitalize;
	}
	.mediaViewInfo {
		--web-view-name: connection page;
		--web-view-id: connection_page;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: connection_page;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#connection_page {
		position: absolute;
		width: calc(100vw - 120px);
		height: calc(100vh - 60px);
        top: 60px;
        left: 120px;
		background-color: rgba(255,255,255,1);
		overflow-x: hidden;
		overflow-y: auto;
		--web-view-name: connection page;
		--web-view-id: connection_page;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#heading_grp_conreqsug_con_page {
		position: absolute;
		width: 364px;
		height: 42px;
		left: calc((100% - 364px)/2);
		top: 22px;
		overflow: visible;
	}
	#conreqsug_con_page_new_pc_req {
		opacity: 0.92;
		left: 151px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 65px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(0,0,0,1);
	}
	#selected_grp_offreqevent_com_r {
		position: absolute;
		width: 99px;
		height: 24px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#conreqsug_con_page_new_pc_conn {
		opacity: 0.92;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(255,93,24,1);
	}
	#conreqsug_con_page_new_pc_sug {
		opacity: 0.92;
		left: 267px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 98px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(48,47,47,1);
	}
	#underline_conreqsug_con_page_n {
		fill: rgba(255,79,4,1);
	}
	.underline_conreqsug_con_page_n {
		position: absolute;
		overflow: visible;
		width: 99px;
		height: 3px;
		left: 0px;
		top: 39px;
	}
	#desgin_con_page_new_pc {
		fill: rgba(248,248,248,1);
	}
	.desgin_con_page_new_pc {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 203px;
		left: 0px;
		top: 94px;
	}
	#Scroll_connection_page_pc_new {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 636px;
		/* left: 59px; */
		top: 108px;
        display: block;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_connection_page_pc_new::-webkit-scrollbar{
		display: none;
	}
	#overrall_car_conreqsug_con_pag {
		position: relative;
		width: 185px;
		height: 287px;
		left: 15px;
		top: 24px;
        display: inline-block;
        margin: 10px;
		overflow: visible;
	}
	#over_all_bdr_sug_con_page_new_ {
		fill: rgba(255,255,255,1);
	}
	.over_all_bdr_sug_con_page_new_ {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 193px;
		height: 295px;
		left: 0px;
		top: 0px;
	}
	#Anime_Edit_con_page_new_pc {
		position: absolute;
		width: 150px;
		height: 142px;
		left: 18px;
		top: 35px;
		overflow: visible;
	}
	#desgination_pc_con_page {
		position: absolute;
		width: 170px;
		height: 25px;
		left: 12px;
		top: 182px;
		overflow: visible;
	}
	#job-seeker_con_page_new_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#con_page_new_pc_cor {
		left: 30px;
		top: 3px;
		position: absolute;
		overflow: hidden;
		width: 141px;
		height: 20px;
		
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: capitalize;
	}
	#Mohammed_Affan_con_page_new_pc {
		left: 1px;
		top: 8px;
		position: absolute;
		overflow: hidden;
		width: 185px;
		height: 20px;
	
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
	}
	#block_btn_grp_con_page_new_pc {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 103px;
		top: 242px;
        cursor: pointer;
		overflow: visible;
	}
	#share_btn_con_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.share_btn_con_page_new_pc {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#share_img_con_page_new_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#disconnect_btn_grp_con_page_ne {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 49px;
		top: 242px;
        cursor: pointer;
		overflow: visible;
	}
	#bdr_no_Copines {
		fill: rgba(255,255,255,1);
	}
	.bdr_no_Copines {
		-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#no-connection_Copines {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#company_anme_new_ {
		position: absolute;
		width: 170px;
		height: 25px;
		left: 12px;
		top: 212px;
		overflow: visible;
	}
	#office-building_img_con_page_n {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#operate_live_lbl_con_page_new_ {
		left: 30px;
		top: 3px;
		position: absolute;
		overflow: hidden;
		width: 141px;
		height: 20px;
	
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		text-transform: capitalize;
	}

.mediaViewInfo {
    --web-view-name: portfolio off;
    --web-view-id: portfolio_off;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: portfolio_off;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#portfolio_off {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 140px);
    top:75px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: portfolio off;
    --web-view-id: portfolio_off;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#heading_grp_offreqevent_off_mo {
    position: absolute;
    width: 235px;
    height: 28px;
    left: 63px;
    top: 13px;
    overflow: visible;
}
#offreqevent_off_mob_req {
    opacity: 0.92;
    left: 81px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 92px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -7px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#selected_grp_offreqevent_off_m {
    position: absolute;
    width: 61px;
    height: 20px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#offering_offreqevent_off_mob {
    opacity: 0.92;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -7px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,93,24,1);
}
#events_offreqevent_off_mob {
    opacity: 0.92;
    left: 192px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -7px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(48,47,47,1);
}
#underline_offreqevent_off_mob {
    fill: rgba(255,79,4,1);
}
.underline_offreqevent_off_mob {
    position: absolute;
    overflow: visible;
    width: 61px;
    height: 3px;
    left: 0px;
    top: 25px;
}
#settings-sliders_offreqevent_o {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 328px;
    top: 15px;
    overflow: visible;
}
	.mediaViewInfo {
		--web-view-name: portfolio req;
		--web-view-id: portfolio_req;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: portfolio_req;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#portfolio_req {
		position: absolute;
		width: 100vw;
		height: calc(100vh - 140px);
        top: 75px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: portfolio req;
		--web-view-id: portfolio_req;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#heading_grp_offreqevent_req_mo {
		position: absolute;
		width: 235px;
		height: 28px;
		left: 63px;
		top: 13px;
		overflow: visible;
	}
	#req_offreqevent_req_mob {
		opacity: 0.92;
		left: 81px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,79,4,1);
	}
	#selected_grp_offreqevent_req_m {
		position: absolute;
		width: 61px;
		height: 20px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#requirements_offreqevent_req_m {
		opacity: 0.92;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 62px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#events_offreqevent_event_mob {
		opacity: 0.92;
		left: 192px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 44px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(48,47,47,1);
	}
	#underline_offreqevent_req_mob {
		fill: rgba(255,79,4,1);
	}
	.underline_offreqevent_req_mob {
		position: absolute;
		overflow: visible;
		width: 91px;
		height: 3px;
		left: 81px;
		top: 25px;
	}
	#settings-sliders {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 328px;
		top: 15px;
		overflow: visible;
	}

	.mediaViewInfo {
		--web-view-name: offering page pc new;
		--web-view-id: offering_page_pc_new;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: offering_page_pc_new;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#offering_page_pc_new {
		position: absolute;
        width: calc(100vw - 120px);
        height: calc(100vh - 60px);
        top:60px;
        left:120px;
		background-color: rgba(255,255,255,1);
		overflow-x: hidden;
		overflow-y: scroll;
		--web-view-name: offering page pc new;
		--web-view-id: offering_page_pc_new;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#heading_grp_offreqevent_off_PC {
		position: absolute;
		width: 100%;
		height: 42px;
		left: calc(50% - 170px);
		top: 22px;
		overflow: visible;
	}
	#req_offreqevent_off_PC {
		opacity: 0.92;
		left: 117px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 110px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		cursor: pointer;
		color: rgba(48,47,47,1);
	}
	#selected_grp_offreqevent_off_P {
		position: absolute;
		width: 77px;
		height: 42px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#offeringoffreqevent_off_PC {
		opacity: 0.92;
		left: 2px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		cursor: pointer;
		color: rgba(255,79,4,1);
	}
	#underline_offreqevent_off_PC {
		fill: rgba(255,79,4,1);
	}
	.underline_offreqevent_off_PC {
		position: absolute;
		overflow: visible;
		width: 77px;
		height: 3px;
		left: 0px;
		top: 39px;
	}
	#A_transformative_offreqevent_o {
		opacity: 0.92;
		left: 269px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 52px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		cursor: pointer;
		color: rgba(48,47,47,1);
	}
	#filters_area_new_events_page_gz {
		position: absolute;
		width: 555px;
		height: 30px;
		left: calc((100% - 400px)/2);
		top: 81px;
		overflow: visible;
	}
	#bdr_new_events_page_pc_g {
		fill: rgba(173,245,206,1);
	}
	.bdr_new_events_page_pc_g {
		position: absolute;
		overflow: visible;
		width: 130px;
		height: 30px;
		left: 425px;
		top: 0px;
	}
	#Find_Event_new_events_page_pc_g {
	left: 425px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 130px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -6px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(4,4,4,1);
	}
	#category_grp_pc_g {
		position: absolute;
		width: 209px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#category_input_new_events_page_g {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.category_input_new_events_page_g {
		position: absolute;
		overflow: visible;
		width: 130px;
		height: 30px;
		left: 79px;
		top: 0px;
		border: 1px solid #ADF5CE;
		border-radius: 5px;
	}
	#Category_new_events_page_pc_g {
		left: 0px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 70px;
		white-space: nowrap;
		line-height: 23px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#time_cate_gory_pc_g {
		position: absolute;
		width: 172px;
		height: 30px;
		left: 227px;
		top: 0px;
		overflow: visible;
	}
	#time_bdr_filter_new_events_pag_g {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.time_bdr_filter_new_events_pag_g {
		position: absolute;
		overflow: visible;
		width: 130px;
		height: 30px;
		left: 42px;
		top: 0px;
		border: 1px solid #ADF5CE;
		border-radius: 5px;
	}
	#Time_new_events_page_g {
		left: 0px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		line-height: 23px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#swtitch_grp_event_g {
		position: absolute;
		width: 277px;
		height: 21px;
		left: 6%;
		top: 85px;
		overflow: visible;
	}
	#Myevents_PC_g {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 27px;
		margin-top: -5.5px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(17,17,17,1);
	}
	#Community_eventsPC_ha {
		left: 145px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 133px;
		white-space: nowrap;
		line-height: 27px;
		margin-top: -5.5px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(17,17,17,1);
	}
	#switch_eent_off_PC_hb {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.switch_eent_off_PC_hb {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 17px;
		left: 90px;
		top: -8px;
	}
#all_grp_top_to_down_comp_reqof {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 180px);
    left: 0px;
    top: 114px;
    overflow-y: auto;
    overflow-x: hidden;
}
#all_grp_top_to_down_comp_reqof::-webkit-scrollbar{
    display: none;
}
#desgin_3_offreqevent_off_PC {
    fill: rgba(248,248,248,1);
}
.desgin_3_offreqevent_off_PC {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 255px;
    left: 0px;
    top: 0px;
}
#desgin_2_offreqevent_off_PC {
    position: absolute;
    width: 100%;
    height: 227px;
    left: 0px;
    top: 528px;
    overflow: visible;
}
#design_1_offreqevent_off_PC {
    fill: rgba(0,0,0,0.569);
}
.design_1_offreqevent_off_PC {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 227px;
    left: 0px;
    top: 528px;
}
#recently_added_offreqevent_off {
    /* left: 497px; */
    top: 65px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    line-height: 49px;
    /* margin-top: -13.5px; */
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#explore_awesome_offerings_offr {
    /* left: 531px; */
    top: 47px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#And_then_there_was_offreqevent {
    /* left: 66px; */
    top: 594px;
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 47px;
    line-height: 56.630001068115234px;
    margin-top: -10.815000534057617px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: rgba(255,255,255,1);
    letter-spacing: -0.46875px;
}
#trwnding_this_week_offreqevent {
    /* left: 486px; */
    top: 833px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: pre-wrap;
    line-height: 50px;
    /* margin-top: -11.5px; */
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#top_product_offreqevent_off_PC {
    /* left: 574.606px; */
    top: 809.533px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#Scroll_recently_added_off_PC {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 356px;
    /* left: 40px; */
    top: 112px;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}
#Scroll_recently_added_off_PC::-webkit-scrollbar{
    display: none;
}

#Scroll_trending_this_week_off_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 772px;
    left: 0px;
    top: 909px;
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Scroll_trending_this_week_off_::-webkit-scrollbar{
    display: none;
}
#all_items_grp_trend_off_PC {
    position: relative;
    width: 1110px;
    height: 680px;
    left: calc((100% - 1110px)/2);
    text-align: center;
    top: 34px;
    display: inline-block;
    overflow: visible;
}
/* #n__card_grp_reqoff_off_PC {
    position: absolute;
    width: 540px;
    height: 679px;
    left: 0px;
    top: 1px;
    overflow: visible;
} */

#small_3_trending_off_PC {
    position: absolute;
    width: 255px;
    height: 313px;
    left: 285px;
    top: 0px;
    overflow: visible;
}
#bdr_small_3_trending_all_off_P {
    fill: rgba(245,245,245,1);
}
.bdr_small_3_trending_all_off_P {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 273px;
    height: 331px;
    left: 0px;
    top: 0px;
}
#amijnt_bdr_small_3_trending_of {
    fill: rgba(255,255,255,1);
}
.amijnt_bdr_small_3_trending_of {
    position: absolute;
    overflow: visible;
    width: 63px;
    height: 34px;
    left: 171px;
    top: 253px;
}
#n_9_small_3_trending_off_PC {
    left: 190px;
    top: 259px;
    position: absolute;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#Image_small_3_trending_off_PC {
    position: absolute;
    width: 214px;
    height: 214px;
    left: 21px;
    top: 37px;
    overflow: visible;
}
#NikeCourt_Air_small_3_trending {
    left: 24.5px;
    top: 260.5px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    line-height: 32px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.1818181800842285px;
}
#Single_Tour_small_3_trending_o {
    left: 24px;
    top: 282px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(17,17,17,1);
}
#no_bdr_small_3_trending_off_PC {
    fill: rgba(255,255,255,1);
}
.no_bdr_small_3_trending_off_PC {
    position: absolute;
    overflow: visible;
    width: 39px;
    height: 21px;
    left: 42px;
    top: 10px;
}
#trophie_bdr_small_3_trending_o {
    fill: rgba(255,255,255,1);
}
.trophie_bdr_small_3_trending_o {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 37px;
    left: 2px;
    top: 1px;
}
#trophy_small_3_trending_off_PC {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 6px;
    top: 5px;
    overflow: visible;
}
#n_234_small_3_trending_off_PC {
    left: 45px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17,17,17,1);
}
#share_bdr_small_3_trending_off {
    fill: rgba(255,255,255,1);
}
.share_bdr_small_3_trending_off {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 216px;
    top: 2px;
}
#share_3_reqoffevent_off_PC {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 223px;
    top: 9px;
    overflow: visible;
}
#small_2_trending_off_PC {
    position: absolute;
    width: 255px;
    height: 325px;
    left: 0px;
    top: 354px;
    overflow: visible;
}
#all_small_2_trending_off_PC {
    fill: rgba(245,245,245,1);
}
.all_small_2_trending_off_PC {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 273px;
    height: 343px;
    left: 0px;
    top: 0px;
}
#Image_small_2_trending_off_PC {
    position: absolute;
    width: 214px;
    height: 214px;
    left: 21px;
    top: 37px;
    overflow: visible;
}
#prince_bdr_small_2_trending_of {
    fill: rgba(255,255,255,1);
}
.prince_bdr_small_2_trending_of {
    position: absolute;
    overflow: visible;
    width: 63px;
    height: 34px;
    left: 171.25px;
    top: 256px;
}
#n_9_small_2_trending_off_PC {
    left: 190.25px;
    top: 262px;
    position: absolute;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#NikeCourt_Air_small_2_trending {
    left: 21.75px;
    top: 263.5px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    line-height: 32px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.1818181800842285px;
}
#Single_Tour_small_2_trending_o {
    left: 22px;
    top: 286px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(17,17,17,1);
}
#number_small_2_trending_off_PC {
    fill: rgba(255,255,255,1);
}
.number_small_2_trending_off_PC {
    position: absolute;
    overflow: visible;
    width: 39px;
    height: 21px;
    left: 41px;
    top: 11px;
}
#trophie_bdr_small_2_trending_o {
    fill: rgba(255,255,255,1);
}
.trophie_bdr_small_2_trending_o {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 37px;
    left: 1px;
    top: 2px;
}
#trophy_small_2_trending_off_PC {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 6px;
    overflow: visible;
}
#n_234_small_2_trending_off_PC {
    left: 44px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17,17,17,1);
}
#sgatre_bdr_small_4_trending_of {
    fill: rgba(255,255,255,1);
}
.sgatre_bdr_small_4_trending_of {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 216px;
    top: 2px;
}
#share_23_reqoffevent_off_PC {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 223px;
    top: 9px;
    overflow: visible;
}
#small_1_trneding_off_PC {
    position: absolute;
    width: 255px;
    height: 325px;
    left: 285px;
    top: 354px;
    overflow: visible;
}
#bdr_trending_1_small_off_PC {
    fill: rgba(245,245,245,1);
}
.bdr_trending_1_small_off_PC {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 273px;
    height: 343px;
    left: 0px;
    top: 0px;
}
#Image_trending_1_small_off_PC {
    position: absolute;
    width: 214px;
    height: 214px;
    left: 21px;
    top: 37px;
    overflow: visible;
}
#bg_trending_1_small_off_PC {
    fill: rgba(255,255,255,1);
}
.bg_trending_1_small_off_PC {
    position: absolute;
    overflow: visible;
    width: 63px;
    height: 34px;
    left: 172px;
    top: 256px;
}
#n_9_amount_trending_1_small_of {
    left: 191px;
    top: 262px;
    position: absolute;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#NikeCourt_Air_trending_1_small {
    left: 23.5px;
    top: 263.5px;
    position: absolute;
    overflow: visible;
    width: 79px;
    white-space: nowrap;
    line-height: 32px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.1818181800842285px;
}
#Single_trending_1_small_off_PC {
    left: 23px;
    top: 286px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(17,17,17,1);
}
#like_count_trending_1_small_of {
    fill: rgba(255,255,255,1);
}
.like_count_trending_1_small_of {
    position: absolute;
    overflow: visible;
    width: 39px;
    height: 21px;
    left: 42px;
    top: 11px;
}
#trohpie_bg_trending_1_small_of {
    fill: rgba(255,255,255,1);
}
.trohpie_bg_trending_1_small_of {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 37px;
    left: 2px;
    top: 2px;
}
#trophy_img_trending_1_small_of {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 6px;
    top: 6px;
    overflow: visible;
}
#n_234_count_trend_off_PC {
    left: 45px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17,17,17,1);
}
#share_bdr_small_40trending_off {
    fill: rgba(255,255,255,1);
}
.share_bdr_small_40trending_off {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 216px;
    top: 2px;
}
#share_5_reqoffevent_off_PC {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 223px;
    top: 9px;
    overflow: visible;
}

#n__card_grp_reqoff_off_PC {
    position: relative;
    width: 540px;
    height: 679px;
    left: 0px;
    top: 1px;
    display: inline-flex;
    overflow: visible;
}

#add_offering_off_PC {
    position: absolute;
    width: 134px;
    height: 30px;
    right: 30px;
    top: 81px;
    cursor: pointer;
    overflow: visible;
}
#Add_Offerings_off_PC {
    left: 36px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    line-height: 27px;
    margin-top: -5.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(17,17,17,1);
}
#add_2_img_off_PC {
    position: absolute;
    width: 29px;
    height: 30px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#small_4_trending_off_PC {
    position: relative;
    width: 255px;
    height: 313px;
    margin: 20px;
    overflow: visible;
}
#all_bdr_small_4_trending_off_P {
    fill: rgba(245,245,245,1);
}
.all_bdr_small_4_trending_off_P {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 273px;
    height: 331px;
    left: 0px;
    top: 0px;
}
#Image_small_4_trending_off_PC {
    position: absolute;
    width: 255px;
    height: 255px;
    left: 0px;
    /* top: 37px; */
    overflow: visible;
}
#bdr_amount_small_4_trending_of {
    fill: rgba(255,255,255,1);
}
.bdr_amount_small_4_trending_of {
    position: absolute;
    overflow: visible;
    width: 80px;
    height: 34px;
    left: 177px;
    top: 253px;
}
#n_9_small_4_trending_off_PC {
    /* left: 196px; */
    right: 10px;
    top: 259px;
    position: relative;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: right;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#NikeCourt_Air_small_4_trending {
    left: 25.5px;
    top: 260.5px;
    position: absolute;
    overflow: visible;
    width: 113px;
    white-space: nowrap;
    line-height: 32px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.1818181800842285px;
}
#Single_Tour_small_4_trending_o {
    left: 25px;
    top: 282px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(17,17,17,1);
}
#number_small_4_trending_off_PC {
    fill: rgba(255,255,255,1);
}
.number_small_4_trending_off_PC {
    position: absolute;
    overflow: visible;
    width: 39px;
    height: 21px;
    left: 41px;
    top: 10px;
}
#trohpie_bdr_small_4_trending_o {
    fill: rgba(255,255,255,1);
}
.trohpie_bdr_small_4_trending_o {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 37px;
    left: 1px;
    top: 1px;
}
#trophy_small_4_trending_off_PC {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#n_234_small_4_trending_off_PC {
    left: 44px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17,17,17,1);
}
#share_bdr_small_4_trending_off {
    fill: rgba(255,255,255,1);
}
.share_bdr_small_4_trending_off {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 216px;
    top: 2px;
}
#share_2_reqoffevent_off_PC {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 223px;
    top: 9px;
    overflow: visible;
}
#big_1_trending_off_PC {
    position: relative;
    width: 540px;
    height: 680px;
    /* left: 570px; */
   margin: 10px;
    overflow: visible;
}
#bdr_all_trending_off_PC {
    fill: rgba(245,245,246,1);
}
.bdr_all_trending_off_PC {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 558px;
    height: 698px;
    left: 0px;
    top: 0px;
}
#bdr_amount_trending_off_PC {
    fill: rgba(255,255,255,1);
}
.bdr_amount_trending_off_PC {
    position: absolute;
    overflow: visible;
    width: 63px;
    height: 34px;
    left: 457px;
    top: 591px;
}
#n_9_trending_off_PC {
    left: 474px;
    top: 597px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    text-align: center;
    font-family: Poppins-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#Image_trending__off_PC {
    position: absolute;
    width: 531px;
    height: 520px;
    left: 9px;
    top: 55px;
    overflow: visible;
}
#Nike_Air_Force_1_Sha_trending_ {
    left: 29px;
    top: 575px;
    position: absolute;
    overflow: visible;
    width: 400px;
    white-space: pre-wrap;
    line-height: 32px;
    /* margin-top: -3.5px; */
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.1818181800842285px;
}
#Single_Tour_trending_off_PC {
    left: 29px;
    top: 617px;
    position: absolute;
    overflow: visible;
    width: 131px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -6.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(17,17,17,1);
}
#likes_trending_off_PC {
    fill: rgba(255,255,255,1);
}
.likes_trending_off_PC {
    position: absolute;
    overflow: visible;
    width: 39px;
    height: 21px;
    left: 43px;
    top: 12px;
}
#bdr_1_trending_off_PC {
    fill: rgba(255,255,255,1);
}
.bdr_1_trending_off_PC {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 37px;
    left: 3px;
    top: 3px;
}
#trophy_trending_off_PC {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 7px;
    top: 7px;
    overflow: visible;
}
#n_234_like_trending_off_PC {
    left: 46px;
    top: 12px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17,17,17,1);
}


#trohpie_bdr_small_7_trending_o {
    fill: rgba(255,255,255,1);
}
.trohpie_bdr_small_7_trending_o {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 500px;
    top: 2px;
}
#share_1_reqoffevent_off_PC_da {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 505px;
    top: 9px;
    overflow: visible;
}
#card_1_reqoffevent_off_PC {
    position: relative;
    width: 270px;
    height: 325px;
    left: 50px;
    top: 24px;
    display: inline-flex;
    flex-direction: row;
    margin: 10px;
    overflow: visible;
}
#Base_reqoffevent_off_PC {
    fill: rgba(255,255,255,1);
}
.Base_reqoffevent_off_PC {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 270px;
    height: 270px;
    left: 0px;
    top: 0px;
}
#bdr_1_reqoffevent_off_PC {
    fill: rgba(255,79,4,0.09);
}
.bdr_1_reqoffevent_off_PC {
    position: absolute;
    overflow: visible;
    width: 56px;
    height: 31px;
    left: 189px;
    top: 285px;
}
#n_90_reqoffevent_off_PC {
    left: 202px;
    top: 291px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#Single_Tour_reqoffevent_off_PC {
    left: 17px;
    top: 284px;
    position: absolute;
    overflow: visible;
    width: 75px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.3333333206176758px;
}
#date_reqoffevent_off_PC {
    left: 17px;
    top: 306px;
    position: absolute;
    overflow: visible;
    width: 107px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(17,17,17,1);
}
#online-shopping_reqoffevent_of {
    position: absolute;
    width: 270px;
    height: 270px;
    /* left: 47px;
    top: 47px; */
    border-radius: 10px;
    overflow: hidden;
}
#trophy_reqoffevent_off_PC {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    cursor: pointer;
    overflow: visible;
}
#trohpie_bdr_small_4_trendingre_off_PC {
    fill: rgba(255,255,255,1);
}
.trohpie_bdr_small_4_trendingre_off_PC {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 37px;
    left: 0px;
    top: 0px;
}

#number_small_4_trending_offn_Pc {
    fill: rgba(255,255,255,1);
   
}
.number_small_4_trending_offn_Pc {
    position: absolute;
    overflow: visible;
    width: 39px;
    height: 21px;
    left: 41px;
    top: 10px;
   
}
#n_234_reqoffevent_off_PC {
    left: 42px;
    top: 12px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17,17,17,1);
}
#share_1_reqoffevent_off_PC {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 235px;
    top: 8px;
    overflow: visible;
}
#all_grp_top_to_down_comp_com_o {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 180px);
    left: 0px;
    top: 114px;
    overflow-y: auto;
    overflow-x: hidden;
}
#desgin_3_com_off_off_PC {
    fill: rgba(248,248,248,1);
}
.desgin_3_com_off_off_PC {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 255px;
    left: 0px;
    top: 0px;
}
#desgin_2_ocom_off_off_PC {
    position: absolute;
    width: 100%;
    height: 227px;
    left: 0px;
    top: 528px;
    overflow: visible;
}
#design_1_com_off_off_PC {
    fill: rgba(0,0,0,0.569);
}
.design_1_com_off_off_PC {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 227px;
    left: 24px;
    top: 528px;
}
#recently_added_com_off_off_PC {
    /* left: 497px; */
    top: 65px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    line-height: 49px;
    
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#explore_awesome_offerings_com_ {
    /* left: 531px; */
    top: 47px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#And_then_there_was_offreqevent_comm_off {
    left: 66px;
    top: 594px;
    position: absolute;
    overflow: visible;
    width: 471px;
    height: 47px;
    line-height: 56.630001068115234px;
    margin-top: -10.815000534057617px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: rgba(255,255,255,1);
    letter-spacing: -0.46875px;
}
#trwnding_this_week_offreqevent_comm_off {
    /* left: 486px; */
    top: 833px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    line-height: 50px;
    /* margin-top: -11.5px; */
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#top_product_offreqevent_com_of {
    /* left: 574.606px; */
    top: 809.533px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#Scroll_recently_added_com_off_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 356px;
    /* left: 40px; */
    top: 112px;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}
#Scroll_recently_added_com_off_::-webkit-scrollbar{
    display: none;
}
#card_1_com_off_off_PC {
    position: absolute;
    width: 270px;
    height: 325px;
    left: 55px;
    top: 24px;
    overflow: visible;
}
#Base_com_off_off_PC {
    fill: rgba(255,255,255,1);
}
.Base_com_off_off_PC {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 288px;
    height: 288px;
    left: 0px;
    top: 0px;
}
#bdr_1com_off_off_PC {
    fill: rgba(255,79,4,0.09);
}
.bdr_1com_off_off_PC {
    position: absolute;
    overflow: visible;
    width: 56px;
    height: 31px;
    left: 189px;
    top: 285px;
}
#number_smal_com_off_off_PC {
    fill: rgba(255,255,255,1);
}
.number_smal_com_off_off_PC {
    position: absolute;
    overflow: visible;
    width: 39px;
    height: 21px;
    left: 40px;
    top: 10px;
}
#trohpie_bdr_small_4com_off_PC {
    fill: rgba(255,255,255,1);
}
.trohpie_bdr_small_4com_off_PC {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 37px;
    left: 0px;
    top: 0px;
}
#n_90_com_off_off_PC {
    left: 202px;
    top: 291px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#Single_Tourcom_off_off_PC {
    left: 17px;
    top: 284px;
    position: absolute;
    overflow: visible;
    width: 75px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.3333333206176758px;
}
#date_com_off_off_PC {
    left: 17px;
    top: 306px;
    position: absolute;
    overflow: visible;
    width: 107px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(17,17,17,1);
}
#Scroll_trending_this_week_com_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 772px;
    left: 0px;
    top: 909px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#all_items_grp_trend_com_off_of {
    position: relative;
    width: 1110px;
    height: 680px;
    left: calc((100% - 1110px)/2);
    text-align: center;
    top: 34px;
    display: inline-block;
    overflow: visible;
}
#share_bdr_strending_com_off_of {
    fill: rgba(255,255,255,1);
}
.share_bdr_strending_com_off_of {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 228px;
    top: 1px;
}
#online-shoppingcom_off_off_PC {
    position: absolute;
    width: 177px;
    height: 177px;
    left: 47px;
    top: 47px;
    overflow: visible;
}
#trophy_com_off_off_PC {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#n_234_com_off_off_PC {
    left: 42px;
    top: 12px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17,17,17,1);
}
#share_1com_off_off_PC {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 235px;
    top: 8px;
    overflow: visible;
}
	.mediaViewInfo {
		--web-view-name: offerings view pc new;
		--web-view-id: offerings_view_pc_new;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: offerings_view_pc_new;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#offerings_view_pc_new {
		position: absolute;
		width: calc(100vw - 125px);
		height: calc(100% - 114px);
        top: 114px;
        /* left: calc((100% - 1237px)/2);  */
		background-color: rgba(255,255,255,1);
		overflow-x: hidden;
		overflow-y: auto;
		--web-view-name: offerings view pc new;
		--web-view-id: offerings_view_pc_new;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#Scroll_cars_preview_offering {
		mix-blend-mode: normal;
		position: absolute;
		width: 198px;
		height: 454px;
		left: 6px;
		top: 72px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_cars_preview_offering::-webkit-scrollbar{
		display: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#preview_1_view_page_pc_offerin {
		position: relative;
		width: 165px;
		height: 99px;
		left: 18px;
		top: 4px;
        margin: 5px;
		overflow: visible;
	}
	#img_preview_1_view_page_pc_off {
		fill: rgba(245,245,246,1);
	}
	.img_preview_1_view_page_pc_off {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 183px;
		height: 117px;
		left: 0px;
		top: 0px;
	}
	#Image_preview_1_view_page_pcof {
		position: absolute;
		width: 126px;
		height: 92px;
		left: 20px;
		top: 4px;
        object-fit: contain;
		overflow: visible;
	}
	#info_grp_view_page_pc_offering {
		position: absolute;
		width: 961px;
		height: 450px;
		left: 204px;
		top: 76px;
		overflow: visible;
	}
	#Nike_1_Sha_view_page_pc_offeri {
		left: 468px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		line-height: 33px;
		margin-top: -3.5px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 26px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.1818181800842285px;
	}
	#trophy_view_page_pc_offering {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 471px;
		top: 43px;
		overflow: visible;
	}
	#n_234_count_view_page_pc_offer {
		left: 505px;
		top: 45px;
		position: absolute;
		overflow: visible;
		width: 93px;
		height: 20px;
		line-height: 22px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
	}
	#share_btn_view_page_pc_offerin {
		position: absolute;
		width: 19px;
		height: 18px;
		left: 830px;
		top: 8px;
		overflow: visible;
		cursor: pointer;
	}
	#Scroll_descri_view_page_pc_off {
		mix-blend-mode: normal;
		position: absolute;
		width: 448px;
		height: 177px;
		left: 468px;
		top: 205px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_descri_view_page_pc_off::-webkit-scrollbar{
		display: none;
	}
	#description_view_page_pc_offer {
		left: 3px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 404px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Button_bargain_view_page_pc_of {
		position: absolute;
		width: 145px;
		height: 51px;
		left: 474px;
		top: 399px;
		overflow: visible;
		border-radius: 5px;
	}
	#bargain_bdr_view_page_pc_offer {
		fill: rgba(248,90,71,1);
	}
	.bargain_bdr_view_page_pc_offer {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 154.444px;
		height: 60.444px;
		left: 0px;
		top: 0px;
	}
	#bargain_view_page_pc_offering {
		left: 41px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 65px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#Button_disable_view_page_pc_of {
		position: absolute;
		width: 145px;
		height: 51px;
		left: 659px;
		top: 399px;
		overflow: visible;
		border-radius: 5px;
	}
	#disable_bdr_view_page_p_offeri {
		fill: rgba(248,90,71,1);
	}
	.disable_bdr_view_page_p_offeri {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 154.444px;
		height: 60.444px;
		left: 0px;
		top: 0px;
	}
	#disable_view_page_pc_offering {
		left: 46px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#line_befoget_view_page_pc_offe {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 3;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_befoget_view_page_pc_offe {
		overflow: visible;
		position: absolute;
		width: 484px;
		height: 1px;
		left: 468.5px;
		top: 76.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#basic_details_grp_view_page_pc {
		position: absolute;
		width: 492px;
		height: 66px;
		left: 469px;
		top: 96px;
		overflow: visible;
	}
	#Budget_Deliverydlview_page_pc_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 131px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Afs_10000_AFN_view_page_pc_off {
		left: 208px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 285px;
		height: 66px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#line_below_location_view_page_ {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 3;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_below_location_view_page_ {
		overflow: visible;
		position: absolute;
		width: 484px;
		height: 1px;
		left: 468.5px;
		top: 188.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#main_big_view_page_pc_offering {
		position: absolute;
		width: 415px;
		height: 450px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#img_2_big_view_page_pc_bdr_off {
		fill: rgba(245,245,246,1);
	}
	.img_2_big_view_page_pc_bdr_off {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 433px;
		height: 468px;
		left: 0px;
		top: 0px;
	}
	#Image_2_big_view_page_pc_offer {
		position: absolute;
		width: 408px;
		height: 399px;
		left: 4px;
		top: 26px;
		overflow: visible;
	}
	#edit_offering_btn_view_page_pc {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 790px;
		top: 5px;
		cursor: pointer;
		overflow: visible;
	}
	#Scroll_timeline_view_page_pc_off {
		mix-blend-mode: normal;
		position: relative;
		width: 100%;
		height: 146px;
		/* left: 197px; */
		display: flex;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#Scroll_timeline_view_page_pc_off::-webkit-scrollbar{
		display: none;
	}
	#timeline_grp_view_page_pc_off {
		position: relative;
		width: 267px;
		height: 91px;
		left: 7px;
		top: 20px;
		overflow: visible;
	}
	#first_green_bdr__view_page_pc_ {
		fill: rgba(255,255,255,1);
	}
	.first_green_bdr__view_page_pc_ {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 211px;
		height: 31px;
		left: 74px;
		top: 38px;
	}
	#firsty_bdr_genn__view_page_pc_ {
		fill: rgba(255,255,255,1);
	}
	.firsty_bdr_genn__view_page_pc_ {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 48px;
		height: 48px;
		left: 41px;
		top: 29px;
	}
	#first_circle__view_page_pc_off {
		fill: rgba(168,248,145,1);
	}
	.first_circle__view_page_pc_off {
		position: absolute;
		overflow: visible;
		width: 18px;
		height: 18px;
		left: 47px;
		top: 35px;
	}
	#first_green_line_view_page_pc_ {
		fill: rgba(168,248,145,1);
	}
	.first_green_line_view_page_pc_ {
		position: absolute;
		overflow: visible;
		width: 182px;
		height: 6px;
		left: 80px;
		top: 41px;
	}
	#Bargained__view_page_pc_offeri {
		left: 18px;
		top: 69px;
		position: absolute;
		overflow: visible;
		width: 77px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#n_4__12__view_page_pc_offering {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 113px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#trophie_detail_view_page_pc_of {
		position: absolute;
		width: 100%;
		height: 89px;
		left: 133px;
		top: 814px;
        display: flex;
		overflow: visible;
	}
	#card_1_trohpie_view_page_pc_of {
		position: relative;
		width: 180px;
		height: 45px;
		left: 0px;
		top: 44px;
		padding: 10px;
		cursor: pointer;
		overflow: visible;
	}
	#img_who_liked_view_page_pc_off {
        position: absolute;
        width: 35px;
        height: 35px;
        left: 0px;
        top: 0px;
        margin: 5px;
        border-radius: 50%;
        overflow: hidden;
	}
	
	#Bargained_view_page_pc_offerin {

		position: absolute;
		overflow: visible;
		width: 77px;
        left: 50px;
		top: 9px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Trophies_Awarded_By_view_page_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 180px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#previous_back_btn_view_page_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 24px;
		top: 17px;
        cursor: pointer;
		overflow: visible;
	}

.mediaViewInfo {
    --web-view-name: requrements page pc new;
    --web-view-id: requrements_page_pc_new;
    --web-scale-on-resize: true;
    --web-center-horizontally: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: requrements_page_pc_new;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#requrements_page_pc_new {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 60px);
    top:60px;
    left:120px;
	background-color: rgba(255,255,255,1);
    overflow-x: hidden;
    overflow-y: scroll;
    --web-view-name: requrements page pc new;
    --web-view-id: requrements_page_pc_new;
    --web-scale-on-resize: true;
    --web-center-horizontally: true;
    --web-enable-deep-linking: true;
}
#heading_grp_offreqevent_req_PC {
    position: absolute;
    width: 318px;
    height: 42px;
    left: calc(50% - 170px);
    top: 22px;
    overflow: visible;
}
#req_offreqevent_req_PC {
    opacity: 0.92;
    left: 115px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 110px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
    color: rgba(255,79,4,1);
}
#selected_grp_offreqevent_req_P {
    position: absolute;
    width: 73px;
    height: 24px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#requirements_offreqevent_req_P {
    opacity: 0.92;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 74px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
    color: rgba(0,0,0,1);
}
#A_offreqevent_req_PC {
    opacity: 0.92;
    left: 267px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
    color: rgba(48,47,47,1);
}
#underline_offreqeventreq_PC {
    fill: rgba(255,79,4,1);
}
.underline_offreqeventreq_PC {
    position: absolute;
    overflow: visible;
    width: 109px;
    height: 3px;
    left: 115px;
    top: 39px;
}
#filters_area_new_events_page {
    position: absolute;
    width: 555px;
    height: 30px;
    left: calc((100% - 400px)/2);
    top: 81px;
    overflow: visible;
}
#bdr_new_events_page_pc {
    fill: rgba(173,245,206,1);
}
.bdr_new_events_page_pc {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 30px;
    left: 425px;
    top: 0px;
}
#Find_Event_new_events_page_pc {
    left: 451px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 79px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -6px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(4,4,4,1);
}
#category_grp_pc {
    position: absolute;
    width: 209px;
    height: 30px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#category_input_new_events_page {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.category_input_new_events_page {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 30px;
    left: 79px;
    top: 0px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Category_new_events_page_pc {
    left: 0px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 70px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -3.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#time_cate_gory_pc {
    position: absolute;
    width: 172px;
    height: 30px;
    left: 227px;
    top: 0px;
    overflow: visible;
}
#time_bdr_filter_new_events_pag {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.time_bdr_filter_new_events_pag {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 30px;
    left: 42px;
    top: 0px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Time_new_events_page {
    left: 0px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 38px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -3.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#swtitch_grp_event_req_PC {
    position: absolute;
    width: 365px;
    height: 21px;
    left: 6%;
    top: 85px;
    overflow: visible;
}
#My_requirements_req_pc {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 73px;
    white-space: nowrap;
    line-height: 27px;
    margin-top: -5.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(17,17,17,1);
}
#Community_requirements_req_PC {
    left: 180px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 133px;
    white-space: nowrap;
    line-height: 27px;
    margin-top: -5.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(17,17,17,1);
}
#switch_eent__req_PC {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.switch_eent_req_PC {
    position: absolute;
    overflow: visible;
    width: 44px;
    height: 17px;
    left: 125px;
    top: -8px;
}
#all_grp_top_to_down_comp_reqof {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 180px);
    left: 0px;
    top: 114px;
    overflow-y: auto;
    overflow-x: hidden;
}
#desgin_3_offreqeventreq_PC {
    fill: rgba(248,248,248,1);
}
.desgin_3_offreqeventreq_PC {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 255px;
    left: 0px;
    top: 0px;
}
#desgin_2_offreqevent_req_PC {
    position: absolute;
    width: 100%;
    height: 227px;
    left: 0px;
    top: 528px;
    overflow: visible;
}
#design_1_offreqevent_req_PC {
    fill: rgba(0,0,0,0.569);
}
.design_1_offreqevent_req_PC {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 227px;
    left: 0px;
    top: 528px;
}
#recently_added_offreqevent_req {
    /* left: 497px; */
    top: 65px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    line-height: 49px;
    /* margin-top: -13.5px; */
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#explore_offreqevent_req_PC {
    /* left: 519px; */
    top: 47px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#And_then_there_was_offreqevent {
    /* left: 66px; */
    top: 594px;
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 104px;
    line-height: 56.630001068115234px;
   
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: rgba(255,255,255,1);
    letter-spacing: -0.46875px;
}
#trending_this_week_offreqevent {
    /* left: 486px; */
    top: 833px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    line-height: 50px;
    /* margin-top: -11.5px; */
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#top_product_offreqevent_req_PC {
    /* left: 574.606px; */
    top: 809.533px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#Scroll_recently_added_oreq_PC {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 356px;
    /* left: 40px; */
    top: 112px;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}

#Scroll_recently_added_oreq_PC::-webkit-scrollbar {
    display: none;
}

#Scroll_trending_this_week_req_Pc {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 772px;
    left: 0px;
    top: 909px;
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
}
#all_items_grp_trend_req_Pc {
    position: relative;
    width: 1110px;
    height: 680px;
    left: calc((100% - 1110px)/2);
    top: 34px;
    display: inline-block;
    overflow: visible;
}


#add_requirements_req_PC {
    position: absolute;
    width: 169px;
    height: 30px;
    right: 30px;
    top: 81px;
    cursor: pointer;
    overflow: visible;
}
#Add_requirements_label_req_PC {
    left: 39px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 131px;
    white-space: nowrap;
    line-height: 27px;
    margin-top: -5.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(17,17,17,1);
}
#add_2_img_req_PC {
    position: absolute;
    width: 29px;
    height: 30px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#card_1_reqoffevent_req_PC {
    position: relative;
    width: 270px;
    height: 325px;
    /* left: 55px; */
    top: 24px;
    display: inline-flex;
    flex-direction: row;
    margin: 10px;
    overflow: visible;
}
#Base_reqoffevent_req_PC {
    fill: rgba(255,255,255,1);
}
.Base_reqoffevent_req_PC {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 288px;
    height: 288px;
    left: 0px;
    top: 0px;
}
#bdr_1_reqoffevent_req_PC {
    fill: rgba(255,79,4,0.09);
}
.bdr_1_reqoffevent_req_PC {
    position: absolute;
    overflow: visible;
    width: 80px;
    height: 31px;
    left: 189px;
    top: 285px;
}
#trohpie_bdr_small_4_trendingrereq_PC {
    fill: rgba(255,255,255,1);
}
.trohpie_bdr_small_4_trendingrereq_PC {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 37px;
    left: 0px;
    top: 0px;
}
#share_bdr_small_4_trending_reqreq_PC {
    fill: rgba(255,255,255,1);
}
.share_bdr_small_4_trending_reqreq_PC {
    position: absolute;
    overflow: visible;
    width: 41px;
    height: 38px;
    left: 227px;
    top: 1px;
}
#n_90_reqoffevent_req_PC {
    left: 190px;
    top: 291px;
    position: absolute;
    overflow: visible;
    width: 80px;
    white-space: nowrap;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#Single_Tour_reqoffevent_req_PC {
    left: 17px;
    top: 284px;
    position: absolute;
    overflow: visible;
    width: 75px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.3333333206176758px;
}
#date_reqoffevent_req_PC {
    left: 17px;
    top: 306px;
    position: absolute;
    overflow: visible;
    width: 107px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(17,17,17,1);
}
#online-shopping_reqoffevent_req_PC {
    position: absolute;
    width: 268px;
    height: 268px;
    /* left: 47px;
    top: 47px; */
    overflow: visible;
}
#trophy_reqoffevent_req_PC {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#n_234_reqoffevent_req_PC {
    left: 42px;
    top: 12px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17,17,17,1);
}
#share_1_reqoffevent_req_PC {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 235px;
    top: 8px;
    overflow: visible;
}
/* #n__card_grp_reqoff_req_Pc {
    position: absolute;
    width: 540px;
    height: 679px;
    left: 0px;
    top: 1px;
    overflow: visible;
} */
#small_4_trending_req_Pc {
    position: relative;
    width: 255px;
    height: 313px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#all_bdr_small_4_trending_req_Pc {
    fill: rgba(245,245,245,1);
}
.all_bdr_small_4_trending_req_Pc {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 273px;
    height: 331px;
    left: 0px;
    top: 0px;
}
#Image_small_4_trending_req_Pc {
    position: absolute;
    width: 214px;
    height: 214px;
    left: 21px;
    top: 37px;
    overflow: visible;
}
#bdr_amount_small_4_trending_req_Pc {
    fill: rgba(255,255,255,1);
}
.bdr_amount_small_4_trending_req_Pc {
    position: absolute;
    overflow: visible;
    width: 75px;
    height: 34px;
    left: 177px;
    top: 253px;
}
#n_9_small_4_trending_req_Pc {
    left: 180px;
    top: 259px;
    position: absolute;
    overflow: visible;
    width: 70px;
    white-space: pre;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#NikeCourt_Air_small_4_trending_req_Pc {
    /* left: 25.5px; */
    top: 260.5px;
    position: absolute;
    overflow: visible;
    width: 170px;
    white-space: pre-wrap;
    line-height: 32px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.1818181800842285px;
}
#Single_Tour_small_4_trending_req_Pc {
    left: 5px;
    top: 282px;
    position: absolute;
    overflow: visible;
    width: 175px;
    white-space: pre;
    line-height: 30px;
    /* margin-top: -8.5px; */
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(17,17,17,1);
}
#number_small_4_trending_req_Pc {
    fill: rgba(255,255,255,1);
}
.number_small_4_trending_req_Pc {
    position: absolute;
    overflow: visible;
    width: 39px;
    height: 21px;
    left: 41px;
    top: 10px;
}
#trohpie_bdr_small_4_trending_req_Pc {
    fill: rgba(255,255,255,1);
}
.trohpie_bdr_small_4_trending_req_Pc {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 37px;
    left: 1px;
    top: 1px;
}
#trophy_small_4_trending_req_Pc {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#n_234_small_4_trending_req_Pc {
    left: 44px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17,17,17,1);
}
#share_bdr_small_4_trending_req_Pc {
    fill: rgba(255,255,255,1);
}
.share_bdr_small_4_trending_req_Pc {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 216px;
    top: 2px;
}
#share_2_reqoffevent_req_Pc {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 223px;
    top: 9px;
    overflow: visible;
}
#big_1_trending_req_Pc {
    position: relative;
    width: 540px;
    height: 680px;
    /* left: 570px; */
    top: 0px;
    margin: 10px;
    overflow: visible;
}
#bdr_all_trending_req_Pc {
    fill: rgba(245,245,246,1);
}
.bdr_all_trending_req_Pc {
    -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 558px;
    height: 698px;
    left: 0px;
    top: 0px;
}
#bdr_amount_trending_req_Pc {
    fill: rgba(255,255,255,1);
}
.bdr_amount_trending_req_Pc {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 34px;
    left: 430px;
    top: 591px;
}
#n_9_trending_req_Pc {
    left: 430px;
    top: 597px;
    position: absolute;
    overflow: visible;
    width: 90px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Poppins-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#Image_trending__req_Pc {
    position: absolute;
    width: 531px;
    height: 520px;
    left: 9px;
    top: 55px;
    overflow: visible;
}
#Nike_Air_Force_1_Sha_trending_req_Pc {
    left: 29px;
    top: 575px;
    position: absolute;
    overflow: visible;
    width: 400px;
    white-space: pre-wrap;
    line-height: 32px;
    /* margin-top: -3.5px; */
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.1818181800842285px;
}
#Single_Tour_trending_req_Pc {
    left: 29px;
    top: 617px;
    position: absolute;
    overflow: visible;
    width: 131px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -6.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(17,17,17,1);
}
#trohpie_bdr_small_7_trending_req_Pc {
    fill: rgba(255,255,255,1);
}
.trohpie_bdr_small_7_trending_req_Pc {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 499px;
    top: 2px;
}
#share_1_reqoffevent_req_Pc {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 506px;
    top: 9px;
    overflow: visible;
}
#likes_trending_req_Pc {
    fill: rgba(255,255,255,1);
}
.likes_trending_req_Pc {
    position: absolute;
    overflow: visible;
    width: 39px;
    height: 21px;
    left: 43px;
    top: 12px;
}
#bdr_1_trending_req_Pc {
    fill: rgba(255,255,255,1);
}
.bdr_1_trending_req_Pc {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 37px;
    left: 3px;
    top: 3px;
}
#trophy_trending_req_Pc {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 7px;
    top: 7px;
    overflow: visible;
}
#n_234_like_trending_req_Pc {
    left: 46px;
    top: 12px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17,17,17,1);
}
#all_grp_top_to_down_comp_reqof {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 180px);
    left: 0px;
    top: 114px;
    overflow-y: auto;
    overflow-x: hidden;
}
#desgin_3_offreqevent_com_req_p {
    fill: rgba(248,248,248,1);
}
.desgin_3_offreqevent_com_req_p {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 255px;
    left: 0px;
    top: 0px;
}
#desgin_2_offreqevent_com_req_p {
    position: absolute;
    width: 100%;
    height: 227px;
    left: 0px;
    top: 528px;
    overflow: visible;
}
#design_1_offreqevent_com_req_p {
    fill: rgba(0,0,0,0.569);
}
.design_1_offreqevent_com_req_p {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 227px;
    left: 0px;
    top: 528px;
}
#recently_affreqevent_com_req_p {
    /* left: 497px; */
    top: 65px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: pre;
    line-height: 49px;
  
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#explore_offreqevent_com_req_pc {
    /* left: 519px; */
    top: 47px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#And_offreqevent_com_req_pc {
    /* left: 66px; */
    top: 594px;
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 104px;
    line-height: 56.630001068115234px;
    
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: rgba(255,255,255,1);
    letter-spacing: -0.46875px;
}
#trending_this_week_offreqevent {
    /* left: 486px; */
    top: 833px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    line-height: 50px;
    /* margin-top: -11.5px; */
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#top_product_reqevent_com_req_p {
    /* left: 574.606px; */
    top: 809.533px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#Scroll_recently_added_com_req_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 356px;
    /* left: 40px; */
    top: 112px;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}
#Scroll_recently_added_com_req_::-webkit-scrollbar {
display: none;
}
#Scroll_trending_this_week_com_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 772px;
    left: 0px;
    top: 909px;
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
}
#all_items_grp_trend_off_PC {
    position: relative;
    width: 1110px;
    height: 680px;
    top: 34px;
    left: calc((100% - 1110px)/2);
    display: inline-block;
    overflow: visible;
}
	.mediaViewInfo {
		--web-view-name: requrements view pc new;
		--web-view-id: requrements_view_pc_new;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: requrements_view_pc_new;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
    body::-webkit-scrollbar {
        display: none;
    }
	#requrements_view_pc_new {
		position: absolute;
		width: calc(100vw - 125px);
		height: calc(100% - 114px);
        top: 114px;
		background-color: rgba(255,255,255,1);
		overflow-x: hidden;
		overflow-y: scroll;
		--web-view-name: requrements view pc new;
		--web-view-id: requrements_view_pc_new;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#Scroll_cars_preview {
		mix-blend-mode: normal;
		position: absolute;
		width: 198px;
		height: 454px;
		left: 6px;
		top: 72px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#preview_1_view_page_pc {
		position: relative;
		width: 165px;
		height: 99px;
		left: 18px;
		top: 4px;
        margin: 5px;
		overflow: visible;
	}
	#img_preview_1_view_page_pc {
		fill: rgba(245,245,246,1);
	}
	.img_preview_1_view_page_pc {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 183px;
		height: 117px;
		left: 0px;
		top: 0px;
	}
	#Image_preview_1_view_page_pc {
		position: absolute;
		width: 126px;
		height: 92px;
		left: 20px;
		top: 4px;
        object-fit: contain;
      
		overflow: visible;
	}
	#info_grp_view_page_pc {
		position: absolute;
		width: 961px;
		height: 450px;
		left: 204px;
		top: 76px;
		overflow: visible;
	}
	#Nike_Air_Force_1_view_page_pc {
		left: 468px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		line-height: 33px;
		margin-top: -3.5px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 26px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.1818181800842285px;
	}
	#trophy_view_page_pc {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 471px;
		top: 43px;
		cursor: pointer;
		overflow: visible;
	}
	#n_234_count_view_page_pc {
		left: 505px;
		top: 45px;
		position: absolute;
		overflow: visible;
		width: 93px;
		height: 20px;
		line-height: 22px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
	}
	#share_btn_view_page_pc {
		position: absolute;
		width: 19px;
		height: 19px;
		left: 829px;
		top: 8px;
		overflow: visible;
	}
	#Scroll_description_view_page_p {
		mix-blend-mode: normal;
		position: absolute;
		width: 448px;
		height: 177px;
		left: 468px;
		top: 205px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#description_of_title_view_page_req_pc {
		left: 3px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 404px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Button_bargain_view_page_pc {
		position: absolute;
		width: 145px;
		height: 51px;
		left: 474px;
		top: 399px;
		overflow: visible;
		border-radius: 5px;
	}
	#bargain_bdr_view_page_pc {
		fill: rgba(248,90,71,1);
	}
	.bargain_bdr_view_page_pc {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 154.444px;
		height: 60.444px;
		left: 0px;
		top: 0px;
	}
	#bargain_view_page_pc {
		left: 57px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#Button_disable_view_page_pc {
		position: absolute;
		width: 145px;
		height: 51px;
		left: 659px;
		top: 399px;
		overflow: visible;
		border-radius: 5px;
	}
	#disable_bdr_view_page_pc {
		fill: rgba(248,90,71,1);
	}
	.disable_bdr_view_page_pc {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 154.444px;
		height: 60.444px;
		left: 0px;
		top: 0px;
	}
	#disable_view_page_pc {
		left: 46px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#line_before_budget_view_page_p {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 3;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_before_budget_view_page_p {
		overflow: visible;
		position: absolute;
		width: 484px;
		height: 1px;
		left: 468.5px;
		top: 76.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#basic_details_grp_view_page_pc {
		position: absolute;
		width: 492px;
		height: 66px;
		left: 469px;
		top: 96px;
		overflow: visible;
	}
	#Budget_Delivery_Deadline_Deliv_pc_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Afs_pc_details_info_vie {
		left: 208px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 285px;
		height: 66px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#line_below_location_view_page_ {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 3;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_below_location_view_page_ {
		overflow: visible;
		position: absolute;
		width: 484px;
		height: 1px;
		left: 468.5px;
		top: 188.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#main_big_view_page_pc {
		position: absolute;
		width: 415px;
		height: 450px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#img_2_big_view_page_pc_bdr {
		fill: rgba(245,245,246,1);
	}
	.img_2_big_view_page_pc_bdr {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 433px;
		height: 468px;
		left: 0px;
		top: 0px;
	}
	#Image_2_big_view_page_pc {
		position: absolute;
		width: 408px;
		height: 399px;
		left: 4px;
		top: 26px;
		overflow: visible;
	}
	#edit_req_btn_view_page_pc_req {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 789px;
		top: 5px;
		cursor: pointer;
		overflow: visible;
	}
	#Scroll_timeline_view_page_pc_req {
		mix-blend-mode: normal;
		position: relative;
		width: 100%;
		height: 146px;
		/* left: 197px; */
		
		display: flex;
		overflow-x: scroll;
		overflow-y: hidden;
	}
    #Scroll_cars_preview::-webkit-scrollbar {
        display: none;
    }
	

	#timeline_grp_view_page_pc_offe {
		position: relative;
		width: 267px;
		height: 91px;
		left: 7px;
		top: 20px;
		overflow: visible;
	}
	#first_green_bdr__view_page_pc {
		fill: rgba(255,255,255,1);
	}
	.first_green_bdr__view_page_pc {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 211px;
		height: 31px;
		left: 74px;
		top: 38px;
	}
	#firsty_bdr_genn__view_page_pc_ {
		fill: rgba(255,255,255,1);
	}
	.firsty_bdr_genn__view_page_pc_ {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 48px;
		height: 48px;
		left: 41px;
		top: 29px;
	}
	#first_circle__view_page_pc_ {
		fill: rgba(168,248,145,1);
	}
	.first_circle__view_page_pc_ {
		position: absolute;
		overflow: visible;
		width: 18px;
		height: 18px;
		left: 47px;
		top: 35px;
	}
	#first_green_line_view_page_pc_ {
		fill: rgba(168,248,145,1);
	}
	.first_green_line_view_page_pc_ {
		position: absolute;
		overflow: visible;
		width: 182px;
		height: 6px;
		left: 80px;
		top: 41px;
	}
	#Bargained__view_page_pc_ {
		left: 18px;
		top: 69px;
		position: absolute;
		overflow: visible;
		width: 77px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#n_4__12__view_page_pc_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 113px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#trophie_detail_view_page_pc {
		position: absolute;
		width: 100%;
		height: 89px;
		left: 133px;
		top: 814px;
        display: flex;
		overflow: visible;
	}
	#card_1_trohpie_view_page_pc {
		position: relative;
		width: 227px;
		height: 45px;
		left: 0px;
		top: 44px;
		overflow: visible;
	}
	#img_who_liked_view_page_pc {
		position: absolute;
		width: 45px;
		height: 45px;
		left: 0px;
		top: 0px;
        border-radius: 50%;
		overflow: hidden;
	}
	#Bargained_view_page_pc {
		left: 50px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 178px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Trophies_Awarded_By_view_page_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 180px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#previous_back_btn_back_page_req {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 24px;
		top: 17px;
        cursor: pointer;
		overflow: visible;
	}

.mediaViewInfo {
    --web-view-name: events page pc new;
    --web-view-id: events_page_pc_new;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-center-horizontally: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: events_page_pc_new;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#events_page_pc_new {
    position: absolute;
    width: calc(100vw - 120px);
    height: 100vh;
    top:60px;
    left:120px;
    justify-content: center;
	background-color: rgba(255,255,255,1);
    overflow-x: hidden;
    overflow-y: scroll;
    --web-view-name: events page pc new;
    --web-view-id: events_page_pc_new;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-center-horizontally: true;
    --web-enable-deep-linking: true;
}

#top_grp_event_pc_page {
    position: absolute;
    width: 100%;
    height: 115px;
    left: calc((100% - 1000px)/2);
    top: 22px;
    overflow: visible;
}
#details_events_new_events_page {
    position: absolute;
    width: 824px;
    height: 116px;
    left: 53px;
    top: 120px;
    overflow: visible;
}
#bdr_of_details {
    fill: rgba(255,255,255,1);
    stroke: rgba(173,243,195,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.bdr_of_details {
    -webkit-filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 842px;
    height: 134px;
    left: 0px;
    top: 0px;
}
#grp_of_details {
    position: absolute;
    width: 663px;
    height: 64px;
    left: 58px;
    top: 26px;
    overflow: visible;
}
#Events_lbl_new_events_page_pc {
    left: 602px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    line-height: 25px;
    margin-top: -2.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#n_023_new_events_page_pc {
    left: 52px;
    top: 37px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    line-height: 61px;
    margin-top: -20.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#btw_2_new_events_page_pc {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.btw_2_new_events_page_pc {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 64px;
    left: 206px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Completed_Events_new_events_pa {
    left: 270px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 170px;
    white-space: nowrap;
    line-height: 25px;
    margin-top: -2.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#n_323_new_events_page_pc {
    left: 333px;
    top: 37px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    line-height: 61px;
    margin-top: -20.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#btw_1_new_events_page_pc {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.btw_1_new_events_page_pc {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 64px;
    left: 502px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Ongoing_Events_new_events_page {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 149px;
    white-space: nowrap;
    line-height: 25px;
    margin-top: -2.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#n_023_new_events_page_pc_l {
    left: 611px;
    top: 37px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    line-height: 61px;
    margin-top: -20.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#event_slecctor_new_events_page {
    position: absolute;
    width: 318px;
    height: 42px;
    left: 345px;
    top: 0px;
    overflow: visible;
}
#req_lbl_new_events_page_pc {
    opacity: 0.92;
    left: 115px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 110px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
    color: rgba(48,47,47,1);
}
#offering_lbl_new_events_page_p {
    opacity: 0.92;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 74px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
    color: rgba(4,4,4,1);
}
#event_llbl_new_events_page_pc {
    opacity: 0.92;
    left: 267px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
    color: rgba(255,79,4,1);
}
#event_underline_new_events_pag {
    fill: rgba(255,79,4,1);
}
.event_underline_new_events_pag {
    position: absolute;
    overflow: visible;
    width: 51px;
    height: 3px;
    left: 267px;
    top: 39px;
}
#filters_area_new_events_page_mc {
    position: absolute;
    width: 555px;
    height: 30px;
    left: 306px;
    top: 59px;
    overflow: visible;
}
#bdr_new_events_page_pc_md {
    fill: rgba(173,245,206,1);
}
.bdr_new_events_page_pc_md {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 30px;
    left: 425px;
    top: 0px;
}
#Find_Event_new_events_page_pc_me {
    left: 451px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 79px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -6px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(4,4,4,1);
}
#category_grp_pc_mf {
    position: absolute;
    width: 209px;
    height: 30px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#category_input_new_events_page_mg {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.category_input_new_events_page_mg {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 30px;
    left: 79px;
    top: 0px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Category_new_events_page_pc_mh {
    left: 0px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 70px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -3.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#time_cate_gory_pc_mi {
    position: absolute;
    width: 172px;
    height: 30px;
    left: 227px;
    top: 0px;
    overflow: visible;
}
#time_bdr_filter_new_events_pag_mj {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.time_bdr_filter_new_events_pag_mj {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 30px;
    left: 42px;
    top: 0px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Time_new_events_page_mk {
    left: 0px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 38px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -3.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#serch_grp_event_new_pc {
    position: absolute;
    width: 713px;
    height: 30px;
    left: 148px;
    top: 124px;
    overflow: visible;
}
#search_space_new_events_page {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.search_space_new_events_page {
    position: absolute;
    overflow: visible;
    width: 563px;
    height: 30px;
    left: 0px;
    top: 0px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#search_btn_grp_new_events {
    position: absolute;
    width: 130px;
    height: 30px;
    left: 583px;
    top: 0px;
    overflow: visible;
    border-radius: 2px;
}
#find_event_bdr_new_events_page {
    fill: rgba(173,245,206,1);
}
.find_event_bdr_new_events_page {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 30px;
    left: 0px;
    top: 0px;
}
#Find_Event_new_events_page {
    left: 40px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 51px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -6px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(4,4,4,1);
}
#swtitch_grp_event_mq {
    position: absolute;
    width: 277px;
    height: 21px;
    left: 0px;
    top: 63px;
    overflow: visible;
}
#Myevents_PC_mr {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 73px;
    white-space: nowrap;
    line-height: 27px;
    margin-top: -5.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(17,17,17,1);
}
#Community_eventsPC_ms {
    left: 145px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 133px;
    white-space: nowrap;
    line-height: 27px;
    margin-top: -5.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(17,17,17,1);
}
#switch_eent_off_PC_mt {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.switch_eent_off_PC_mt {
    position: absolute;
    overflow: visible;
    width: 43px;
    height: 17px;
    left: 87px;
    top: -10px;
}
#card_grp_event_page_pc {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 400px);
    left: 38px;
    top: 300px;
    /* overflow-x: hidden;
    overflow-y: scroll; */
    overflow: visible;
}
#recent_events_all_grp_pc {
    position: absolute;
    width: calc(100% - 100px);
    height: 556px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#recent_events_new_events_page__ {
    fill: url(#recent_events_new_events_page__);
}
.recent_events_new_events_page__ {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 556px;
    left: 0px;
    top: 0px;
}
#recent_events_line_new_events_pc {
    fill: transparent;
    stroke: rgba(64,225,150,1);
    stroke-width: 5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.recent_events_line_new_events_pc {
    overflow: visible;
    position: absolute;
    width: 5px;
    height: 26px;
    left: 60.24px;
    top: 29px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#recent_events_new_events_page__ba {
    opacity: 0.92;
    left: 74.24px;
    top: 31.5px;
    position: absolute;
    overflow: visible;
    width: 111px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(4,4,4,1);
}
#Scroll_recent_events {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 500px;
    /* left: 26px; */
    top: 56px;
    display: -webkit-inline-box;
    overflow-x: scroll;
    overflow-y: hidden;
}
#recent_event_card_1_event_new_ {
    position: relative;
    width: 326.76px;
    height: 445px;
    /* left: 16px; */
    top: 13px;
    margin: 10px;
    /* display: inline-flex; */
    overflow: visible;
}
#all_card_borer_v {
    fill: rgba(255,255,255,1);
}
.all_card_borer_v {
    -webkit-filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 344.76px;
    height: 463px;
    left: 0px;
    top: 0px;
}
#By_by_vent_new_pc {
    left: 20px;
    top: 203.39px;
    position: absolute;
    overflow: visible;
    width: 15px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(208,208,208,1);
}
#Son_Goku_lbl_my_anme {
    left: 72px;
    top: 203.39px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#Comic_Con_Bangalore_-_2023_ven {
    left: 20px;
    top: 228.222px;
    position: absolute;
    overflow: visible;
    width: 241px;
    white-space: nowrap;
    line-height: 24px;
    margin-top: -3px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0,0,0,1);
}
#img_logo_vent_new_pc {
    position: absolute;
    width: 30.36px;
    height: 30.614px;
    left: 34.64px;
    top: 190.39px;
    border-radius: 50%;
    overflow: hidden;
}
#idk_vent_new_pc {
    position: absolute;
    width: 285.377px;
    height: 159px;
    left: 21.623px;
    top: 19.222px;
    overflow: visible;
}
#img_bdr_vent_new_pc {
    opacity: 0.29;
    fill: rgba(0,0,0,1);
}
.img_bdr_vent_new_pc {
    position: absolute;
    overflow: visible;
    width: 287px;
    height: 159px;
    left: 20px;
    top: 19.222px;
}
#seats_grp_event_pc {
    position: absolute;
    width: 257px;
    height: 32.832px;
    left: 35.38px;
    top: 396.39px;
    overflow: visible;
}
#Seats_lbl_vent_new_pc {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#n_0_lbl_vent_new_pc {
    left: 231px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(185,185,185,1);
}
#seats_all_bar_vent_new_pc {
    fill: rgba(243,243,243,1);
}
.seats_all_bar_vent_new_pc {
    position: absolute;
    overflow: visible;
    width: 256px;
    height: 4px;
    left: 0px;
    top: 28.832px;
}
#seats_bar_1_vent_new_pc {
    fill: rgba(245,175,25,1);
}
.seats_bar_1_vent_new_pc {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 4px;
    left: 0px;
    top: 28.832px;
}
#bdr_paid_vent_new_pc {
    fill: rgba(55,182,171,0.8);
}
.bdr_paid_vent_new_pc {
    position: absolute;
    overflow: visible;
    width: 55px;
    height: 31px;
    left: 31.76px;
    top: 135px;
}
#Paid_lbl_vent_new_pc {
    left: 45.76px;
    top: 141px;
    position: absolute;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -4.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255,255,255,1);
}
#amount_bdr_vent_new_pc {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.amount_bdr_vent_new_pc {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 224px;
    top: 28px;
}
#n_1139_vent_new_pc {
    left: 236.5px;
    top: 34px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -4.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255,255,255,1);
}
#bdr_trophie_v {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.bdr_trophie_v {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 25.5px;
    top: 28px;
}
#signal_event_new_pc {
    fill: rgba(179,212,49,1);
}
.signal_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 6.193px;
    height: 6.193px;
    left: 216px;
    top: 152px;
}
#Online_Event_lbl_event_new_pc {
    left: 228px;
    top: 148px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    line-height: 19px;
    margin-top: -4px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(255,255,255,1);
}
#trophy_img_event_new_pc {
    position: absolute;
    width: auto;
    height: 25px;
    left: 29px;
    top: 31px;
    color: white;
    cursor: pointer;
    overflow: visible;
}
#n_234_com_event_new_pc {
    left: 62px;
    top: 35px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#share_bdrevent_new_pc_reent_ev {
    fill: rgba(255,255,255,1);
}
.share_bdrevent_new_pc_reent_ev {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 269px;
    top: 185px;
}
#share_event_new_pc {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 276px;
    top: 192px;
    overflow: visible;
}
#from_to_grp_pc_event {
    position: absolute;
    width: 265.5px;
    height: 55px;
    left: 20px;
    top: 273.029px;
    overflow: visible;
}
#From_pc_event {
    left: 34.5px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#n_3th_Jan_2023_vent_pc_event {
    left: 34.5px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(51,51,51,1);
}
#cal_64_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_64_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 16.5px;
}
#cal_8h_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_8h_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 19.5px;
}
#cal_87_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_87_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 22.5px;
}
#cal_57_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_57_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 16.5px;
}
#cal_r54_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_r54_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 19.5px;
}
#cal_ourter_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_ourter_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 22.5px;
}
#cal_6_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_6_pc_event {
    overflow: visible;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0px;
    top: 10.5px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#cal_6_pc_event_b {
    fill: rgba(173,173,173,1);
}
.cal_6_pc_event_b {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 16.5px;
}
#cal_5_e_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_5_e_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 19.5px;
}
#cal_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 22.5px;
}
#cal_3_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_3_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 16.5px;
}
#cal_2_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_2_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 19.5px;
}
#cal_1_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_1_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 22.5px;
}
#To_lbl_pc_event {
    left: 173.5px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 12px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#date_pc_event {
    left: 173.5px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(51,51,51,1);
}
#location_grp_pc_event {
    position: absolute;
    width: 151.877px;
    height: 38px;
    left: 21.623px;
    top: 338px;
    overflow: visible;
}
#Location_lbl_pc_event_pc {
    left: 32.877px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#Online_Meet_-_up_lbl_pc_event_ {
    left: 32.877px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(51,51,51,1);
}
#outer_loca_pc_event_pc {
    fill: rgba(173,173,173,1);
}
.outer_loca_pc_event_pc {
    overflow: visible;
    position: absolute;
    width: 13.754px;
    height: 17px;
    left: 0px;
    top: 10.5px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#loaction_img_pc_event_pc {
    fill: rgba(173,173,173,1);
}
.loaction_img_pc_event_pc {
    overflow: visible;
    position: absolute;
    width: 6.658px;
    height: 6.657px;
    left: 3.548px;
    top: 14.026px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#red_trendding_grp_ {
    position: absolute;
    width: calc(100% - 100px);
    height: 559px;
    left: 3px;
    top: 606px;
    overflow: visible;
}
#trending_events_bdr_new_events_cj {
    fill: url(#trending_events_bdr_new_events_cj);
}
.trending_events_bdr_new_events_cj {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 559px;
    left: 0px;
    top: 0px;
}
#trwnd_line_new_events_page_pc {
    fill: transparent;
    stroke: rgba(255,38,38,1);
    stroke-width: 5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.trwnd_line_new_events_page_pc {
    overflow: visible;
    position: absolute;
    width: 5px;
    height: 26px;
    left: 57.24px;
    top: 29.5px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#trending_new_events_page_pc {
    opacity: 0.92;
    left: 71.24px;
    top: 32px;
    position: absolute;
    overflow: visible;
    width: 126px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(4,4,4,1);
}
#Scroll_trending_events {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 503px;
    /* left: 23px; */
    top: 56px;
    display: -webkit-inline-box;
    overflow-x: scroll;
    overflow-y: hidden;
}
#trending_card_grp_event_new_pc {
    position: relative;
    width: 326.76px;
    height: 448px;
    left: 16px;
    margin: 10px;
    top: 7px;
    overflow: visible;
}
#card_1_bdr_event_new_pc {
    fill: rgba(255,255,255,1);
}
.card_1_bdr_event_new_pc {
    -webkit-filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 344.76px;
    height: 466px;
    left: 0px;
    top: 0px;
}
#By_event_new_pc {
    left: 20px;
    top: 203.39px;
    position: absolute;
    overflow: visible;
    width: 15px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(208,208,208,1);
}
#Son_Goku_event_new_pc {
    left: 72px;
    top: 203.39px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#Dragon_Ball_Super_Movie_-_Rise {
    left: 20px;
    top: 228.222px;
    position: absolute;
    overflow: visible;
    width: 269px;
    white-space: nowrap;
    line-height: 24px;
    margin-top: -3px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0,0,0,1);
}
#logo_event_new_pc {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 34.64px;
    top: 192.39px;
    border-radius: 50%;
    overflow: hidden;
}
#Anime_Edit_img_event_new_pc {
    position: absolute;
    width: 287px;
    height: 159px;
    left: 20px;
    top: 19.222px;
    overflow: visible;
}
#bdr_img_1_event_new_pc {
    opacity: 0.29;
    fill: rgba(0,0,0,1);
}
.bdr_img_1_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 287px;
    height: 159px;
    left: 20px;
    top: 19.222px;
}
#seats_events_page_upcoming_eve {
    position: absolute;
    width: 257px;
    height: 32.832px;
    left: 35.38px;
    top: 396.39px;
    overflow: visible;
}
#Seats_event_new_pc {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#n_0_event_new_pc {
    left: 231px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(185,185,185,1);
}
#bdr_prog_event_new_pc {
    fill: rgba(243,243,243,1);
}
.bdr_prog_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 256px;
    height: 4px;
    left: 0px;
    top: 28.832px;
}
#prog_bar_event_new_pc {
    fill: rgba(245,175,25,1);
}
.prog_bar_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 4px;
    left: 0px;
    top: 28.832px;
}
#bdr_event_new_pc {
    fill: rgba(55,182,171,0.8);
}
.bdr_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 55px;
    height: 31px;
    left: 31.76px;
    top: 135px;
}
#Paid_lbl_event_new_pc {
    left: 45.76px;
    top: 141px;
    position: absolute;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -4.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255,255,255,1);
}
#amount_bdr_event_new_pc {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.amount_bdr_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 224px;
    top: 28px;
}
#n_1139_lbl_event_new_pc {
    left: 237px;
    top: 34px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -4.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255,255,255,1);
}
#sign_event_new_pc {
    fill: rgba(179,212,49,1);
}
.sign_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 6.193px;
    height: 6.193px;
    left: 216px;
    top: 152px;
}
#Online_Event_lbl_event_new_pc_c {
    left: 228px;
    top: 148px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    line-height: 19px;
    margin-top: -4px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(255,255,255,1);
}
#share_bdr_small_event_new_pc {
    fill: rgba(255,255,255,1);
}
.share_bdr_small_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 269px;
    top: 186px;
}
#share_img_event_new_pc {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 276px;
    top: 193px;
    overflow: visible;
}
#bdr_trophie_v_eevents {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.bdr_trophie_v_eevents {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 27px;
    top: 28px;
}
#trophy_img_event_new_pc_23 {
    position: absolute;
    width: auto;
    height: 25px;
    left: 30.5px;
    top: 31px;
    color: white;
    overflow: visible;
}
#n_234_com_event_new_pc_32 {
    left: 63.5px;
    top: 35px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#details_evnts_pc_event {
    position: absolute;
    width: 265.5px;
    height: 97px;
    left: 20px;
    top: 291px;
    overflow: visible;
}
#From_lbl_pc_event {
    left: 34.5px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#n_3th_Jan_2023_pc_event {
    left: 34.5px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(51,51,51,1);
}
#To_lbl_pc_event_de {
    left: 173.5px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 12px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#n_4th_Jan_2023_lbl_pc_event {
    left: 173.5px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(51,51,51,1);
}
#Ellipse_223_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_223_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 16.5px;
}
#Ellipse_236_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_236_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 19.5px;
}
#Ellipse_237_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_237_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 22.5px;
}
#Ellipse_224_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_224_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 16.5px;
}
#Ellipse_234_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_234_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 19.5px;
}
#Ellipse_235_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_235_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 22.5px;
}
#Path_3483_pc_event {
    fill: rgba(173,173,173,1);
}
.Path_3483_pc_event {
    overflow: visible;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0px;
    top: 10.5px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Ellipse_226_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_226_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 16.5px;
}
#Ellipse_230_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_230_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 19.5px;
}
#Ellipse_231_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_231_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 22.5px;
}
#Ellipse_229_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_229_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 16.5px;
}
#Ellipse_232_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_232_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 19.5px;
}
#Ellipse_233_pc_event {
    fill: rgba(173,173,173,1);
}
.Ellipse_233_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 22.5px;
}
#Location_lbl_pc_event {
    left: 34.5px;
    top: 59px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#Online_Meet_-_up_pc_event {
    left: 34.5px;
    top: 77px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(51,51,51,1);
}
#location_2_pc_event {
    fill: rgba(173,173,173,1);
}
.location_2_pc_event {
    overflow: visible;
    position: absolute;
    width: 13.754px;
    height: 17px;
    left: 1.623px;
    top: 70px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#location_1_pc_event {
    fill: rgba(173,173,173,1);
}
.location_1_pc_event {
    overflow: visible;
    position: absolute;
    width: 6.658px;
    height: 6.657px;
    left: 5.171px;
    top: 73.027px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#completed_events_grp_all_pc {
    position: absolute;
    width: calc(100% - 100px);
    height: 561px;
    left: 3px;
    top: 1215px;
    overflow: visible;
}
#bg_of_completed_dz {
    fill: url(#bg_of_completed_dz);
}
.bg_of_completed_dz {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 561px;
    left: 0px;
    top: 0px;
}
#comple_line_new_events_page_pc {
    fill: transparent;
    stroke: rgba(38,161,255,1);
    stroke-width: 5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.comple_line_new_events_page_pc {
    overflow: visible;
    position: absolute;
    width: 5px;
    height: 26px;
    left: 57.24px;
    top: 29.5px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#events_completed_new_events_pa {
    opacity: 0.92;
    left: 71.24px;
    top: 32px;
    position: absolute;
    overflow: visible;
    width: 145px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(4,4,4,1);
}
#Scroll_completed_events {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 497px;
    /* left: 23.24px; */
    top: 64px;
    display: -webkit-inline-box;
    overflow-x: scroll;
    overflow-y: hidden;
}
#completed_vent_card_new_events {
    position: relative;
    width: 326.76px;
    height: 448px;
    left: 16px;
    margin: 10px;
    top: 0px;
    overflow: visible;
}
#overal_bg_event_new_pc {
    fill: rgba(255,255,255,1);
}
.overal_bg_event_new_pc {
    -webkit-filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 344.76px;
    height: 466px;
    left: 0px;
    top: 0px;
}
#By_event_new_pc_d {
    left: 20px;
    top: 203.39px;
    position: absolute;
    overflow: visible;
    width: 15px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(208,208,208,1);
}
#Son_Goku_event_new_pc_d {
    left: 72px;
    top: 203.39px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#Suzume_event_new_pc {
    left: 20px;
    top: 228.222px;
    position: absolute;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    line-height: 24px;
    margin-top: -3px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0,0,0,1);
}
#logo_event_new_pc_d {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 36px;
    top: 195px;
    border-radius:50%;
    overflow: hidden;
}
#img_event_new_pc {
    position: absolute;
    width: 287px;
    height: 159px;
    left: 20px;
    top: 19.222px;
    overflow: visible;
}
#shade_img_event_new_pc {
    opacity: 0.29;
    fill: rgba(0,0,0,1);
}
.shade_img_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 287px;
    height: 159px;
    left: 20px;
    top: 19.222px;
}
#seats_rpohg_bar {
    position: absolute;
    width: 257px;
    height: 32.832px;
    left: 35.38px;
    top: 396.39px;
    overflow: visible;
}
#Seats_lbl_event_new_pc {
    left: 0px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 35px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#n_0_event_new_pc_ed {
    left: 231px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(185,185,185,1);
}
#overall_b_ar_event_new_pc {
    fill: rgba(243,243,243,1);
}
.overall_b_ar_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 256px;
    height: 4px;
    left: 0px;
    top: 28.832px;
}
#bar_event_new_pc {
    fill: rgba(245,175,25,1);
}
.bar_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 4px;
    left: 0px;
    top: 28.832px;
}
#paid_bdr_event_new_pc {
    fill: rgba(55,182,171,0.8);
}
.paid_bdr_event_new_pc {
    position: absolute;
    overflow: visible;
    width: 55px;
    height: 31px;
    left: 31.76px;
    top: 135px;
}
#Paid_lbl_event_new_pc_eh {
    left: 45.76px;
    top: 141px;
    position: absolute;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -4.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255,255,255,1);
}
#amount_bdr_event_new_pc_ei {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.amount_bdr_event_new_pc_ei {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 223.76px;
    top: 28px;
}
#n_1139_cost_event_new_pc {
    left: 236.76px;
    top: 34px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -4.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255,255,255,1);
}
#green_dot_new_events_page_pc {
    fill: rgba(179,212,49,1);
}
.green_dot_new_events_page_pc {
    position: absolute;
    overflow: visible;
    width: 6.193px;
    height: 6.193px;
    left: 216px;
    top: 152px;
}
#Online_Event_new_events_page_p {
    left: 228px;
    top: 148px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    line-height: 19px;
    margin-top: -4px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(255,255,255,1);
}
#share_bdr_new_events_page_pc {
    fill: rgba(255,255,255,1);
}
.share_bdr_new_events_page_pc {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 269.76px;
    top: 181px;
}
#sharenew_events_page_pc {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 276.76px;
    top: 188px;
    overflow: visible;
}
#bdr_trophie_hi {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.bdr_trophie_hi {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 27.76px;
    top: 28px;
}
#trophy_img_event_new_pc_312 {
    position: absolute;
    width: auto;
    height: 25px;
    left: 31.26px;
    top: 31px;
    color: white;
    overflow: visible;
}
#n_234_com_event_new_pc_pcpc {
    left: 64.26px;
    top: 35px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#de_copketed_com_pc_event {
    position: absolute;
    width: 265.5px;
    height: 98px;
    left: 20px;
    top: 288px;
    overflow: visible;
}
#From_lbl_pc_event_es {
    left: 34.5px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#n_3th_Jan_2023__pc_event {
    left: 34.5px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space:pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(51,51,51,1);
}
#To_lbl_pc_event_eu {
    left: 173.5px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 12px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#n_4th_Jan_2023_pc_event {
    left: 173.5px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(51,51,51,1);
}
#calender_dot_3_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dot_3_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 16.5px;
}
#calender_dot_2_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dot_2_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 19.5px;
}
#calender_dot_1_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dot_1_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 22.5px;
}
#calender_dor_9_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_9_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 16.5px;
}
#calender_dor_8_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_8_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 19.5px;
}
#calender_dor_7_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_7_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 22.5px;
}
#calender_outer_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_outer_com_pc_event {
    overflow: visible;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0px;
    top: 10.5px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#calender_dor_6_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_6_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 16.5px;
}
#calender_dor_5_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_5_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 19.5px;
}
#calender_dor_4_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_4_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 22.5px;
}
#calender_dor_3_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_3_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 16.5px;
}
#calender_dor_2_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_2_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 19.5px;
}
#calender_dor_1_com_pc_event {
    fill: rgba(173,173,173,1);
}
.calender_dor_1_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 22.5px;
}
#Location_lbl_new_pc_event {
    left: 34.5px;
    top: 60px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#Online_Meet_-_up_new_pc_event {
    left: 34.5px;
    top: 78px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(51,51,51,1);
}
#loca_2_pc_event {
    fill: rgba(173,173,173,1);
}
.loca_2_pc_event {
    overflow: visible;
    position: absolute;
    width: 13.754px;
    height: 17px;
    left: 1.623px;
    top: 70.5px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#loca_1_new_pc_event {
    fill: rgba(173,173,173,1);
}
.loca_1_new_pc_event {
    overflow: visible;
    position: absolute;
    width: 6.658px;
    height: 6.657px;
    left: 5.171px;
    top: 74.027px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#recent_event_card_1_event_new_ { 
    -webkit-animation-name: floating; 
            animation-name: floating;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
 #Scroll_recent_events::-webkit-scrollbar{
	display: none;
 }
@-webkit-keyframes floating {
    0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    50%  { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
    100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}
@keyframes floating {
    0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    50%  { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
    100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}

	#trending_card_grp_event_new_pc { 
    -webkit-animation-name: floating; 
            animation-name: floating;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
#Scroll_trending_events::-webkit-scrollbar{
	display: none;
}
@keyframes floating {
    0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    50%  { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
    100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}

#completed_vent_card_new_events { 
    -webkit-animation-name: floating; 
            animation-name: floating;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
#Scroll_completed_events::-webkit-scrollbar{
	display: none;
}
@keyframes floating {
    0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    50%  { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
    100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}
#add_events_PC {
    position: absolute;
    width: 117px;
    height: 30px;
    right: 30px;
    top: 81px;
    cursor: pointer;
    overflow: visible;
}
#Add_events_PC_new_events_page_ {
    left: 39px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 79px;
    white-space: nowrap;
    line-height: 27px;
    margin-top: -5.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(17,17,17,1);
}
#add_events_PC_y {
    position: absolute;
    width: 29px;
    height: 30px;
    left: 0px;
    top: 0px;
    
    overflow: visible;
}

.mediaViewInfo {
	--web-view-name: add event;
	--web-view-id: add_event;
	--web-scale-on-resize: true;
	--web-enable-deep-linking: true;
}
:root {
	--web-view-ids: add_event;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	border: none;
}
#add_event {
	position: absolute;
	width: 321px;
	height: 774px;
	top: 60%;
	left: 50%;
	-webkit-transform: translate(-50%,-50%);
	        transform: translate(-50%,-50%);
	border-radius: 15px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;
	--web-view-name: add event;
	--web-view-id: add_event;
	--web-scale-on-resize: true;
	--web-enable-deep-linking: true;
}
#over_all_bg_of_event_pop_up {
	position: absolute;
	width: 321px;
	height: 774px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#close_btn_add_event_details {
	position: absolute;
	width: 20px;
	height: 20px;
	left: 289px;
	top: 7px;
	cursor: pointer;
	overflow: visible;
}
#Enter_Event_Details_lbl_add_ev {
	left: 72px;
	top: 10px;
	position: absolute;
	overflow: visible;
	width: 178px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	color: rgba(0,0,0,1);
}
#add_btn_grp_add_event_details {
	position: absolute;
	width: 120px;
	height: 40px;
	left: 101px;
	top: 710px;
	overflow: visible;
}
#add_btn_bdr_add_event_details {
	fill: rgba(173,245,206,1);
}
.add_btn_bdr_add_event_details {
	position: absolute;
	overflow: visible;
	width: 120px;
	height: 40px;
	left: 0px;
	top: 0px;
}
#Add_btn_add_event_details {
	left: 44px;
	top: 9px;
	position: absolute;
	overflow: visible;
	width: 32px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#Scroll_add_event_pc {
	mix-blend-mode: normal;
	position: absolute;
	width: 304px;
	height: 628px;
	left: 17px;
	top: 65px;
	overflow-x: hidden;
	overflow-y: scroll;
}
#Scroll_add_event_pc::-webkit-scrollbar {
	display: none;
}
#all_details_fill_grp_event_add {
	position: absolute;
	width: 250px;
	height: 630px;
	left: 19px;
	top: 0px;
	overflow: visible;
}
#upload_banner_grp_add_event_de {
	position: absolute;
	width: 250px;
	height: 110px;
	left: 0px;
	top: 562px;
	overflow: visible;
}
#Upload_Banner_IMG_add_event_de {
	left: 0px;
	top: 2px;
	position: absolute;
	overflow: visible;
	width: 149px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#upload_img_add_event_details {
	position: absolute;
	width: 26px;
	height: 26px;
	left: 178px;
	top: 0px;
	overflow: visible;
}
#img_preview_section_event_deta {
	position: absolute;
	width: 250px;
	height: 70px;
	left: 0px;
	top: 40px;
	overflow: visible;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#events_loca_grp_add_event_deta {
	position: absolute;
	width: 250px;
	height: 62px;
	left: 0px;
	top: 488px;
	overflow: visible;
}
#event_location_bdr_add_event_d {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.event_location_bdr_add_event_d {
	position: absolute;
	overflow: visible;
	width: 250px;
	height: 40px;
	left: 0px;
	top: 22px;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#Event_Location_lbl_add_event_d {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 111px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#event_cost_grp_add_event_detai {
	position: absolute;
	width: 115px;
	height: 62px;
	left: 135px;
	top: 414px;
	overflow: visible;
}
#event_cost_bdr_add_event_detai {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.event_cost_bdr_add_event_detai {
	position: absolute;
	overflow: visible;
	width: 115px;
	height: 40px;
	left: 0px;
	top: 22px;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#Event_Cost_lbl_add_event_detai {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 80px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#event_time_grp_add_event_detai {
	position: absolute;
	width: 250px;
	height: 63px;
	left: 0px;
	top: 338px;
	overflow: visible;
}
#event_time_bdr_add_event_detai {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.event_time_bdr_add_event_detai {
	position: absolute;
	overflow: visible;
	width: 250px;
	height: 40px;
	left: 0px;
	top: 23px;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#Event_Time_lbl_add_event_detai {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 102px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#event_seats_grp_add_event_deta {
	position: absolute;
	width: 115px;
	height: 63px;
	left: 0px;
	top: 413px;
	overflow: visible;
}
#event_seats_bdr_add_event_deta {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.event_seats_bdr_add_event_deta {
	position: absolute;
	overflow: visible;
	width: 115px;
	height: 40px;
	left: 0px;
	top: 23px;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#Event_Seats_lbl_add_event_deta {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 86px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#event_date_grp_add_event_detai {
	position: absolute;
	width: 250px;
	height: 62px;
	left: 0px;
	top: 264px;
	overflow: visible;
}
#event_date_bdr_add_event_detai {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.event_date_bdr_add_event_detai {
	position: absolute;
	overflow: visible;
	width: 250px;
	height: 40px;
	left: 0px;
	top: 22px;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#Event_Date_lbl_add_event_detai {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 108px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#event_category_grp_add_event_d {
	position: absolute;
	width: 250px;
	height: 62px;
	left: 0px;
	top: 190px;
	overflow: visible;
}
#evnet_category_bdr_add_event_d {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.evnet_category_bdr_add_event_d {
	position: absolute;
	overflow: visible;
	width: 250px;
	height: 40px;
	left: 0px;
	top: 22px;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#Event_Category_lbl_add_event_d {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 115px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#event_description_grp_add_even {
	position: absolute;
	width: 250px;
	height: 103px;
	left: 0px;
	top: 75px;
	overflow: visible;
}
#event_descp_bdr_add_event_deta {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.event_descp_bdr_add_event_deta {
	position: absolute;
	overflow: visible;
	width: 250px;
	height: 80px;
	left: 0px;
	top: 23px;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#Event_Description_lbl_add_even {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 132px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#event_name_grp_add_event_detai {
	position: absolute;
	width: 250px;
	height: 63px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#event_name_bdr_add_event_detai {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.event_name_bdr_add_event_detai {
	position: absolute;
	overflow: visible;
	width: 250px;
	height: 40px;
	left: 0px;
	top: 23px;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#Event_Name_lbl_add_event_detai {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 92px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}

#down_grp_com_pc_event {
    position: absolute;
    width: calc(100vw - 220px);
    height: 110vh;
    left: 41px;
    top: 140px;
    overflow: visible;
}
#bg_com_event_page_pc_for_cards_ {
    fill: url(#bg_com_event_page_pc_for_cards_);
}
.bg_com_event_page_pc_for_cards_ {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}
#recent_event_card_1_com_pc_eve { 
    -webkit-animation-name: floating; 
            animation-name: floating;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    margin-left: 10px;
    margin-top: 010px;
}
@-webkit-keyframes floating {
    0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    50%  { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
    100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}
@keyframes floating {
    0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    50%  { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
    100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}
#recent_com_pc_event {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 22px;
    top: 21px;
    overflow: visible;
}
#recent_events_line_com_events_pc {
    fill: transparent;
    stroke: rgba(64,225,150,1);
    stroke-width: 5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.recent_events_line_com_events_pc {
    overflow: visible;
    position: absolute;
    width: 5px;
    height: 26px;
    left: 18.24px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#recent_events_new_events_page_ {
    opacity: 0.92;
    left: 28.24px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#recent_event_card_1_com_pc_eve {
    position: relative;
    width: 326px;
    height: 349px;
    left: 0px;
    /* top: 40px; */
    /* display: flex; */
    /* flex-wrap: wrap; */
    overflow: visible;
}
#all_card_borer_v_com_pc_event {
    fill: rgba(255,255,255,1);
}
.all_card_borer_v_com_pc_event {
    -webkit-filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 344px;
    height: 367px;
    left: 0px;
    top: 0px;
}
#By_by_vent_com_pc_event {
    left: 20px;
    top: 203.39px;
    position: absolute;
    overflow: visible;
    width: 15px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(208,208,208,1);
}
#Son_Goku_lbl_com_pc_event {
    left: 71.801px;
    top: 203.39px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#Comic_Con_Bangalore_-_2023_vco {
    left: 20px;
    top: 228.222px;
    position: absolute;
    overflow: visible;
    width: 241px;
    white-space: nowrap;
    line-height: 24px;
    margin-top: -3px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0,0,0,1);
}
#img_logo_com_pc_event {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 36px;
    top: 195px;
    border-radius: 50%;
    overflow: hidden;
}
#idk_ventcom_pc_event {
    position: absolute;
    width: 284.616px;
    height: 159px;
    left: 21.623px;
    top: 19.222px;
    overflow: visible;
}
#img_bdrcom_pc_event {
    opacity: 0.29;
    fill: rgba(0,0,0,1);
}
.img_bdrcom_pc_event {
    position: absolute;
    overflow: visible;
    width: 286.239px;
    height: 159px;
    left: 20px;
    top: 19.222px;
}
#amount_bdr_com_pc_event {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.amount_bdr_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 222.239px;
    top: 28px;
}
#n_1139_vent_com_pc_event {
    left: 235.739px;
    top: 34px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -4.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255,255,255,1);
}
#bdr_trophie_v_com_pc_event {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.bdr_trophie_v_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 25.5px;
    top: 28px;
}
#from_to_grp_com_pc_event {
    position: absolute;
    width: 264.74px;
    height: 55px;
    left: 20px;
    top: 273.029px;
    overflow: visible;
}
#Fromcom_pc_event {
    left: 34.5px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#n_3th_Jan_2023_vent_com_pc_eve {
    left: 34.5px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(51,51,51,1);
}
#cal_64_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_64_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 16.5px;
}
#cal_8h_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_8h_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 19.5px;
}
#cal_87_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_87_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 22.5px;
}
#cal_57_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_57_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 16.5px;
}
#cal_r54_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_r54_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 19.5px;
}
#cal_ourter_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_ourter_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 22.5px;
}
#cal_6_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_6_com_pc_event {
    overflow: visible;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0px;
    top: 10.5px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#cal_6_com_pc_event_bt {
    fill: rgba(173,173,173,1);
}
.cal_6_com_pc_event_bt {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 16.5px;
}
#cal_5_ecom_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_5_ecom_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 19.5px;
}
#cal_4com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_4com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 22.5px;
}
#cal_3_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_3_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 16.5px;
}
#cal_2_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_2_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 19.5px;
}
#cal_1_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_1_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 22.5px;
}
#To_lbl_com_pc_event {
    left: 172.981px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 12px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#date_tocom_pc_event {
    left: 172.739px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(51,51,51,1);
}
#signal_com_pc_event {
    fill: rgba(179,212,49,1);
}
.signal_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 6.193px;
    height: 6.193px;
    left: 215.487px;
    top: 152px;
}
#Online_Event_lbl_com_pc_event {
    left: 227.239px;
    top: 148px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    line-height: 19px;
    margin-top: -4px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(255,255,255,1);
}
#trophy_img_com_pc_event {
    position: absolute;
    width: auto;
    height: 24.942px;
    left: 28.933px;
    top: 31px;
    color: white;
    overflow: visible;
}
#n_234_com_com_pc_event {
    left: 61.856px;
    top: 35px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    line-height: 28px;
    margin-top: -7px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255,255,255,1);
}
#share_bdrevent_new_pc_com_pc_e {
    fill: rgba(255,255,255,1);
}
.share_bdrevent_new_pc_com_pc_e {
    position: absolute;
    overflow: visible;
    width: 37.911px;
    height: 38px;
    left: 268.374px;
    top: 185px;
}
#share_com_pc_event {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 275.239px;
    top: 192px;
    overflow: visible;
}
	.mediaViewInfo {
		--web-view-name: portfolio event;
		--web-view-id: portfolio_event;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: portfolio_event;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#portfolio_event {
		position: absolute;
		width: 100vw;
		height: calc(100vh - 205px);
		top: 75px;
		background-color: rgba(255,255,255,1);
		overflow-x: hidden;
		overflow-y: scroll;
		--web-view-name: portfolio event;
		--web-view-id: portfolio_event;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#heading_grp_offreqevent_event_ {
		position: absolute;
		width: 235px;
		height: 28px;
		left: 63px;
		top: 13px;
		overflow: visible;
	}
	#req_off_porfolio_reqevent_req_PC {
		opacity: 0.92;
		left: 81px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#selected_grp_offreqevent_req_P {
		position: absolute;
		width: 61px;
		height: 20px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#requirements_offreqevent_req_P {
		opacity: 0.92;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 62px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#A_off_porfolio_reqevent_req_PC {
		opacity: 0.92;
		left: 192px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 44px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,79,4,1);
	}
	#underline_off_porfolio_reqeventreq_PC {
		fill: rgba(255,79,4,1);
	}
	.underline_off_porfolio_reqeventreq_PC {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 3px;
		left: 192px;
		top: 25px;
	}
	#settings-sliders_offreqevent_e {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 328px;
		top: 15px;
		overflow: visible;
	}

#with_edit_option_mob {
    position: absolute;
    width: calc(100% - 15px);
    height: calc(100% - 65px);
    left: 8px;
    top: 65px;
    overflow: visible;
}
#event_nane_offreqevent_req_PC {
    opacity: 0.92;
    left: 10px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -7px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#swicj_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.swicj_mob {
    position: absolute;
    overflow: visible;
    width: 30px;
    height: 12px;
    right: 85px;
    top: -7px;
}


#all_event_card_grp_mob {
    position: absolute;
    width: 100%;
    height: calc(100% - 35px);
    left: 0px;
    top: 35px;
    overflow: visible;
    }
    #completed_events_overall_grp_m {
        position: absolute;
        width: 100%;
        height: 300px;
        left: 0px;
        top: 620px;
        overflow: visible;
    }
    #completed_events_new_events_pa_br {
        fill: url(#completed_events_new_events_pa_br);
    }
    .completed_events_new_events_pa_br {
        position: absolute;
        overflow: visible;
        width: 100%;
        height: 300px;
        left: 0px;
        top: 0px;
    }
    #completed_events_line_new_even {
        fill: transparent;
        stroke: rgba(38,161,255,1);
        stroke-width: 5px;
        stroke-linejoin: miter;
        stroke-linecap: butt;
        stroke-miterlimit: 4;
        shape-rendering: auto;
    }
    .completed_events_line_new_even {
        overflow: visible;
        position: absolute;
        width: 5px;
        height: 26px;
        left: 8px;
        top: 8px;
        -webkit-transform: matrix(1,0,0,1,0,0);
                transform: matrix(1,0,0,1,0,0);
    }
    #completed_events_new_events_mo {
        opacity: 0.92;
        left: 18px;
        top: 11.5px;
        position: absolute;
        overflow: visible;
        width: 121px;
        white-space: nowrap;
        line-height: 29px;
        margin-top: -7px;
        text-align: left;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        color: rgba(4,4,4,1);
    }
    #Scroll_completed {
        mix-blend-mode: normal;
        position: absolute;
        width: 100%;
        height: 259.778px;
        /* left: 8.24px; */
        top: 34.223px;
        display: flex;
        flex-wrap:wrap;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    #completed_events_mob_grp {
        position: relative;
        width: 177.664px;
        height: 118.778px;
        left: 12px;
        top: 6px;
        margin: 5px;
        overflow: visible;
    }
    #op_live_name_title_mob {
        position: absolute;
        width: 161px;
        height: 21px;
        left: 8.76px;
        top: 97.777px;
        overflow: visible;
    }
    #op_live_bdr_com_mob {
        opacity: 0.75;
        fill: rgba(255,255,255,1);
    }
    .op_live_bdr_com_mob {
        position: absolute;
        overflow: visible;
        width: 161px;
        height: 21px;
        left: 0px;
        top: 0px;
    }
    #Operate_Live_Event_com_mob {
        left: 0px;
        top: 4px;
        position: absolute;
        overflow: visible;
        width: 162px;
        height: 16px;
        line-height: 29px;
        margin-top: -8.5px;
        text-align: center;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: rgba(4,4,4,1);
    }
    #img_event_new_com_mob {
        position: absolute;
        width: 177.664px;
        height: 98.427px;
        left: 0px;
        top: 0px;
        overflow: visible;
    }
    #shade_img_event_com_mob {
        opacity: 0.29;
        fill: rgba(0,0,0,1);
    }
    .shade_img_event_com_mob {
        position: absolute;
        overflow: visible;
        width: 177.664px;
        height: 98.427px;
        left: 0px;
        top: 0px;
    }
    #trohpie_completed_events {
        position: absolute;
        width: 73px;
        height: 23px;
        left: 2.26px;
        top: 2px;
        overflow: visible;
    }
    #bdr_trophie_com_mob {
        opacity: 0.4;
        fill: rgba(255,255,255,1);
    }
    .bdr_trophie_com_mob {
        position: absolute;
        overflow: visible;
        width: 73px;
        height: 23px;
        left: 0px;
        top: 0px;
    }
    #trophy_img_event_com_mob {
        position: absolute;
        width: auto;
        height: 17px;
        left: 3.5px;
        top: 4px;
        color: white;
        overflow: visible;
    }
    #n_234_com_event_com_mob {
        left: 25.5px;
        top: 4px;
        position: absolute;
        overflow: visible;
        width: 44px;
        height: 17px;
        line-height: 30px;
        margin-top: -9px;
        text-align: center;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: rgba(255,255,255,1);
    }
    #date_com_event_mob {
        position: absolute;
        width: 73px;
        height: 31px;
        left: 3.26px;
        top: 64.777px;
        overflow: visible;
    }
    #bdr_trophie_mob_com {
        opacity: 0.66;
        fill: rgba(255,255,255,1);
    }
    .bdr_trophie_mob_com {
        position: absolute;
        overflow: visible;
        width: 73px;
        height: 31px;
        left: 0px;
        top: 0px;
    }
    #n_3th_Jan_2023__mob_com_event {
        left: 4.24px;
        top: 2.648px;
        position: absolute;
        overflow: visible;
        width: 63px;
        white-space: pre-wrap;
        text-align: left;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        color: rgba(51,51,51,1);
    }
    #trending_events_overall_grp_mo {
        position: absolute;
        width: 100%;
        height: 300px;
        left: 0px;
        top: 310px;
        overflow: visible;
    }
    #trending_events_new_events_mob_ca {
        fill: url(#trending_events_new_events_mob_ca);
    }
    .trending_events_new_events_mob_ca {
        position: absolute;
        overflow: visible;
        width: 100%;
        height: 300px;
        left: 0px;
        top: 0px;
    }
    #trending_events_line_new_event {
        fill: transparent;
        stroke: rgba(255,38,38,1);
        stroke-width: 5px;
        stroke-linejoin: miter;
        stroke-linecap: butt;
        stroke-miterlimit: 4;
        shape-rendering: auto;
    }
    .trending_events_line_new_event {
        overflow: visible;
        position: absolute;
        width: 5px;
        height: 26px;
        left: 8px;
        top: 8px;
        -webkit-transform: matrix(1,0,0,1,0,0);
                transform: matrix(1,0,0,1,0,0);
    }
    #trending_events_new_events_mob_cc {
        opacity: 0.92;
        left: 18px;
        top: 11.5px;
        position: absolute;
        overflow: visible;
        width: 106px;
        white-space: nowrap;
        line-height: 29px;
        margin-top: -7px;
        text-align: left;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        color: rgba(4,4,4,1);
    }
    #Scrolltrending_mob {
        mix-blend-mode: normal;
        position: absolute;
        width: 100%;
        height: 259.778px;
        /* left: 8.24px; */
        top: 34.222px;
        display: flex;
        flex-wrap:wrap;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    #trending_events_card_1_grp_mob {
        position: relative;
        width: 177.664px;
        height: 118.778px;
        left: 12px;
        top: 6px;
        margin: 5px;
        overflow: visible;
    }
    #title_card_1_trend {
        position: absolute;
        width: 161px;
        height: 21px;
        left: 8.76px;
        top: 97.778px;
        overflow: visible;
    }
    #op_name_trend_mob {
        opacity: 0.75;
        fill: rgba(255,255,255,1);
    }
    .op_name_trend_mob {
        position: absolute;
        overflow: visible;
        width: 161px;
        height: 21px;
        left: 0px;
        top: 0px;
    }
    #Operate_Live_Event_trend_mob {
        left: 0px;
        top: 3px;
        position: absolute;
        overflow: visible;
        width: 162px;
        height: 16px;
        line-height: 29px;
        margin-top: -8.5px;
        text-align: center;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: rgba(4,4,4,1);
    }
    #img_event_trend_mob {
        position: absolute;
        width: 177.664px;
        height: 98.427px;
        left: 0px;
        top: 0px;
        overflow: visible;
    }
    #shade_img_event_trend_mob {
        opacity: 0.29;
        fill: rgba(0,0,0,1);
    }
    .shade_img_event_trend_mob {
        position: absolute;
        overflow: visible;
        width: 177.664px;
        height: 98.427px;
        left: 0px;
        top: 0px;
    }
    #trohpie_trend_mob {
        position: absolute;
        width: 73.5px;
        height: 25px;
        left: 2.26px;
        top: 2.778px;
        overflow: visible;
    }
    #bdr_trophie_trend_mob {
        opacity: 0.4;
        fill: rgba(255,255,255,1);
    }
    .bdr_trophie_trend_mob {
        position: absolute;
        overflow: visible;
        width: 73px;
        height: 25px;
        left: 0px;
        top: 0px;
    }
    #trophy_img_event_trend_mob {
        position: absolute;
        width: auto;
        height: 17px;
        left: 3.5px;
        top: 4px;
        color: white;
        overflow: visible;
    }
    #n_234_com_event_trend_mob {
        left: 22.5px;
        top: 4px;
        position: absolute;
        overflow: visible;
        width: 52px;
        height: 17px;
        line-height: 30px;
        margin-top: -9px;
        text-align: center;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: rgba(255,255,255,1);
    }
    #date_ofg_event_trend_mob {
        position: absolute;
        width: 73.24px;
        height: 31px;
        left: 2.26px;
        top: 64.778px;
        overflow: visible;
    }
    #bdr_trophie_trend_mob_cp {
        opacity: 0.66;
        fill: rgba(255,255,255,1);
    }
    .bdr_trophie_trend_mob_cp {
        position: absolute;
        overflow: visible;
        width: 73px;
        height: 31px;
        left: 0px;
        top: 0px;
    }
    #n_3th_Jan_2023_trend_mob {
        left: 0px;
        top: 0px;
        position: absolute;
        overflow: visible;
        width: 74.24px;
        height: 30.64892578125px;
        text-align: center;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        color: rgba(51,51,51,1);
    }
    #recent_evetns_overall_grp_mob {
        position: absolute;
        width: 100%;
        height: 300px;
        left: 0px;
        top: 0px;
        overflow: visible;
    }
    #recent_events_new_events_mob_ct {
        fill: url(#recent_events_new_events_mob_ct);
    }
    .recent_events_new_events_mob_ct {
        position: absolute;
        overflow: visible;
        width: 100%;
        height: 300px;
        left: 0px;
        top: 0px;
    }
    #recent_events_line_new_events_ {
        fill: transparent;
        stroke: rgba(64,225,150,1);
        stroke-width: 5px;
        stroke-linejoin: miter;
        stroke-linecap: butt;
        stroke-miterlimit: 4;
        shape-rendering: auto;
    }
    .recent_events_line_new_events_ {
        overflow: visible;
        position: absolute;
        width: 5px;
        height: 26px;
        left: 8px;
        top: 8px;
        -webkit-transform: matrix(1,0,0,1,0,0);
                transform: matrix(1,0,0,1,0,0);
    }
    #recent_events_new_events_mob_cv {
        opacity: 0.92;
        left: 18px;
        top: 11.5px;
        position: absolute;
        overflow: visible;
        width: 92px;
        white-space: nowrap;
        line-height: 29px;
        margin-top: -7px;
        text-align: left;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        color: rgba(4,4,4,1);
    }
    #Scroll_recent_mob {
        mix-blend-mode: normal;
        position: absolute;
        width: 100%;
        height: 260.778px;
        /* left: 8.24px; */
        top: 32.222px;
        display: flex;
        flex-wrap:wrap ;
        /* display: -webkit-inline-box; */
        overflow-x: hidden;
        overflow-y:scroll ;
    }
    #card_recent_event_grp_mob {
        position: relative;
        width: 177.664px;
        height: 118.778px;
        left: 12px;
        top: 8px;
        margin: 5px;
        overflow: visible;
    }
    #op_live_name_card_hodler_mob {
        position: absolute;
        width: 161px;
        height: 21px;
        left: 8.76px;
        top: 97.778px;
        overflow: visible;
    }
    #bdr_name_op_mob {
        opacity: 0.75;
        fill: rgba(255,255,255,1);
    }
    .bdr_name_op_mob {
        position: absolute;
        overflow: visible;
        width: 161px;
        height: 21px;
        left: 0px;
        top: 0px;
    }
    #Operate_Live_Event_lbl_mob {
        left: 0px;
        top: 3px;
        position: absolute;
        overflow: visible;
        width: 162px;
        height: 16px;
        line-height: 29px;
        margin-top: -8.5px;
        text-align: center;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: rgba(4,4,4,1);
    }
    #img_event_new_mob {
        position: absolute;
        width: 177.664px;
        height: 98.427px;
        left: 0px;
        top: 0px;
        overflow: visible;
    }
    #shade_img_event_new_mob {
        opacity: 0.29;
        fill: rgba(0,0,0,1);
    }
    .shade_img_event_new_mob {
        position: absolute;
        overflow: visible;
        width: 177.664px;
        height: 98.427px;
        left: 0px;
        top: 0px;
    }
    #date_of_evnet_mob {
        position: absolute;
        width: 73px;
        height: 31px;
        left: 2.26px;
        top: 63.778px;
        overflow: visible;
    }
    #bdr_date_mob {
        opacity: 0.66;
        fill: rgba(255,255,255,1);
    }
    .bdr_date_mob {
        position: absolute;
        overflow: visible;
        width: 73px;
        height: 31px;
        left: 0px;
        top: 0px;
    }
    #n_3th_Jan_2023_mob_event {
        left: 4.24px;
        top: 2.649px;
        position: absolute;
        overflow: visible;
        width: 63px;
        white-space: pre-wrap;
        text-align: left;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        color: rgba(51,51,51,1);
    }
    #recent_ceven_trophie_mob {
        position: absolute;
        width: 73.5px;
        height: 25px;
        left: 2.26px;
        top: 2.778px;
        overflow: visible;
    }
    #bdr_trophie_mob {
        opacity: 0.4;
        fill: rgba(255,255,255,1);
    }
    .bdr_trophie_mob {
        position: absolute;
        overflow: visible;
        width: 73px;
        height: 25px;
        left: 0px;
        top: 0px;
    }
    #trophy_img_event_new_mob {
        position: absolute;
        width: auto;
        height: 17px;
        left: 3.5px;
        top: 4px;
        color: white;
        overflow: visible;
    }
    #n_234_event_recent_mob {
        left: 20.5px;
        top: 5px;
        position: absolute;
        overflow: visible;
        width: 54px;
        height: 17px;
        text-align: center;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: rgba(255,255,255,1);
    }
    
    #add_event_offreqevent_event_mo {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 25px;
        top: 0px;
        overflow: visible;
    }
#card_1_commu_recent_mob {
    position: absolute;
    width: 100%;
    height: calc(100% - 35px);
    /* left: 8px; */
    top: 35px;
    overflow: visible;
}
#n_st_card_grp_mob_recent_event {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#recent_events_new_e_commu_rece_bq {
    fill: url(#recent_events_new_e_commu_rece_bq);
}
.recent_events_new_e_commu_rece_bq {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}
#recent_events_line_commu_recen {
    fill: transparent;
    stroke: rgba(64,225,150,1);
    stroke-width: 5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.recent_events_line_commu_recen {
    overflow: visible;
    position: absolute;
    width: 5px;
    height: 26px;
    left: 8px;
    top: 6px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#recent_events_newcommu_recent_ {
    opacity: 0.92;
    left: 18px;
    top: 9.5px;
    position: absolute;
    overflow: visible;
    width: 92px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -7px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#card_1_recent_com_mob {
    position: relative;
    width: 177.664px;
    height: 118.778px;
    margin: 5px;
    /* left: 20.24px;
    top: 38.222px; */
    overflow: visible;
}
#img_event_commu_recent_mo {
    position: absolute;
    width: 177.664px;
    height: 98.427px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#shade_img_commu_recent_mob {
    opacity: 0.29;
    fill: rgba(0,0,0,1);
}
.shade_img_commu_recent_mob {
    position: absolute;
    overflow: visible;
    width: 177.664px;
    height: 98.427px;
    left: 0px;
    top: 0px;
}
#date_of_event_mob {
    position: absolute;
    width: 73px;
    height: 31px;
    left: 2.26px;
    top: 63.778px;
    overflow: visible;
}
#bdr_date_commu_recent_mob {
    opacity: 0.66;
    fill: rgba(255,255,255,1);
}
.bdr_date_commu_recent_mob {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 0px;
    top: 0px;
}
#n_3th_Jan_2023_commu_recent_mo {
    left: 4.24px;
    top: 2.649px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    color: rgba(51,51,51,1);
}
#event_name_mob {
    position: absolute;
    width: 161px;
    height: 21px;
    left: 8.76px;
    top: 97.778px;
    overflow: visible;
}
#op_live_bdr_name_commu_recent_ {
    opacity: 0.75;
    fill: rgba(255,255,255,1);
}
.op_live_bdr_name_commu_recent_ {
    position: absolute;
    overflow: visible;
    width: 161px;
    height: 21px;
    left: 0px;
    top: 0px;
}
#Operate_Live_commu_recent_mob {
    left: 0px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 162px;
    height: 16px;
    line-height: 29px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(4,4,4,1);
}
#trophie_recent_mob {
    position: absolute;
    width: 73px;
    height: 24.894px;
    left: 2.26px;
    top: 2.778px;
    overflow: visible;
}
#bdr_trophie_commu_recent_mob {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.bdr_trophie_commu_recent_mob {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 24.894px;
    left: 0px;
    top: 0px;
}
#trophy_img_commu_recent_mob {
    position: absolute;
    width: auto;
    height: 17px;
    left: 1.5px;
    top: 4px;
    color: white;
    overflow: visible;
}
#n_234_commu_recent_mob {
    left: 19.5px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 53px;
    height: 16px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(255,255,255,1);
}
.mediaViewInfo {
  --web-view-name: top part calender;
  --web-view-id: top_part_calender;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: top_part_calender;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#mb_top_part_calender {
  position: absolute;
  width: 100vw;
  height: calc(100vh - 140px);
  top: 75px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  --web-view-name: top part calender;
  --web-view-id: top_part_calender;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
#mb_com_tata_next_grp_mob {
  position: absolute;
  width: 339.454px;
  height: 51.5px;
  left: 20.773px;
  top: 20.5px;
  overflow: visible;
}
#mb_completed_grp_calender_mob {
  position: absolute;
  width: 146px;
  height: 21px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#mb_Completed_lbbl_calender_mob {
  left: 12px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 85px;
  white-space: nowrap;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#mb_count_bdr_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(166, 210, 170, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.mb_count_bdr_mob {
  position: absolute;
  overflow: visible;
  width: 40px;
  height: 21px;
  left: 106px;
  top: 0px;
}
#mb_n_0_count_calender_mob {
  left: 118px;
  top: 1px;
  position: absolute;
  overflow: visible;
  width: 17px;
  white-space: nowrap;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#mb_completed_staus_calender_mob {
  fill: rgba(46, 243, 65, 1);
}
.mb_completed_staus_calender_mob {
  position: absolute;
  overflow: visible;
  width: 7px;
  height: 7px;
  left: 0px;
  top: 7px;
}
#mb_next_up_calender_mobgrp {
  position: absolute;
  width: 339.454px;
  height: 21.5px;
  left: 0px;
  top: 30px;
  overflow: visible;
}
#mb_Next_Up_lbl_calender_mob {
  left: 15.454px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 57px;
  white-space: nowrap;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#mb_n_0_bdr_next_up_calender_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(166, 210, 170, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.mb_n_0_bdr_next_up_calender_mob {
  position: absolute;
  overflow: visible;
  width: 69px;
  height: 21px;
  left: 77px;
  top: 0px;
}
#mb_arrow_next_up_mob {
  fill: rgba(155, 99, 252, 1);
}
.mb_arrow_next_up_mob {
  overflow: visible;
  position: absolute;
  width: 8px;
  height: 8px;
  -webkit-transform: translate(-0.046px, 0px) matrix(1, 0, 0, 1, 1.5, 7) rotate(90deg);
          transform: translate(-0.046px, 0px) matrix(1, 0, 0, 1, 1.5, 7) rotate(90deg);
  -webkit-transform-origin: center;
          transform-origin: center;
  left: 0px;
  top: 0px;
}
#mb_next_up_arrow_line_mob {
  fill: transparent;
  stroke: rgba(155, 99, 252, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.mb_next_up_arrow_line_mob {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 8px;
  left: 0px;
  top: 7px;
  -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
          transform: matrix(1, 0, 0, 1, 0, 0);
}
#mb_Next_Up_lbl_calender_mob_c {
  left: 82.454px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 57px;
  white-space: nowrap;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#mb_Next_Up_lbl_calender_mob_da {
  left: 146.454px;
  top: 0.5px;
  position: absolute;
  overflow: visible;
  width: 194px;
  height: 21px;
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#mb_todo_grp_calender_mob {
  position: absolute;
  width: 121px;
  height: 21px;
  left: 151px;
  top: 0px;
  overflow: visible;
}
#mb_To-Do_lbl_calender_mob {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 79px;
  white-space: nowrap;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#mb_n_0_bdr_calendemob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(166, 210, 170, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.mb_n_0_bdr_calendemob {
  position: absolute;
  overflow: visible;
  width: 40px;
  height: 21px;
  left: 81px;
  top: 0px;
}
#mb_n_0_calender_mob {
  left: 95px;
  top: 1px;
  position: absolute;
  overflow: visible;
  width: 13px;
  white-space: nowrap;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#mb_Icon_feather-menu_mob {
  position: absolute;
  width: 27px;
  height: 18px;
  right: 20px;
  top: 21.5px;
  overflow: visible;
}
#mb_Path_6953_mobcal {
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.mb_Path_6953_mobcal {
  overflow: visible;
  position: absolute;
  width: 30px;
  height: 3px;
  left: 0px;
  top: 9px;
  -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
          transform: matrix(1, 0, 0, 1, 0, 0);
}
#mb_Path_6954_mobcal {
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.mb_Path_6954_mobcal {
  overflow: visible;
  position: absolute;
  width: 30px;
  height: 3px;
  left: 0px;
  top: 0px;
  -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
          transform: matrix(1, 0, 0, 1, 0, 0);
}
#mb_Path_6955_mobcal {
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.mb_Path_6955_mobcal {
  overflow: visible;
  position: absolute;
  width: 30px;
  height: 3px;
  left: 0px;
  top: 18px;
  -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
          transform: matrix(1, 0, 0, 1, 0, 0);
}

	.mediaViewInfo {
		--web-view-name: add event;
		--web-view-id: add_event;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: add_event;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#add_calander_pop {
		position: absolute;
		width: 321px;
		height: 774px;
        top: 55%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
        border-radius: 15px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: add event;
		--web-view-id: add_event;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#over_all_bg_of_add_calender_ev {
		position: absolute;
		width: 321px;
		height: 774px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#close_btn_add_calender_event {
		position: absolute;
		width: 22px;
		height: 22px;
		left: 289px;
		top: 7px;
        cursor: pointer;
        z-index: 1;
		overflow: visible;
	}
	#add_cal_lbl_add_calender_event {
		/* left: 97px; */
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		height: 30px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#add_btn_grp_add_event_details {
		position: absolute;
		width: 120px;
		height: 40px;
		left: 101px;
		top: 710px;
		overflow: visible;
		cursor: pointer;
	}
	#add_btn_bdr_add_calender_event {
		fill: rgba(173,245,206,1);
	}
	.add_btn_bdr_add_calender_event {
		position: absolute;
		overflow: visible;
		width: 120px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#Add_btn_add_calender_event {
		left: 44px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 32px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
		#add_calender_event_grp_add_eve {
        position: absolute;
        width: 250px;
        height: 63px;
        left: 37px;
        top: 65px;
        z-index: 1;
        overflow: visible;
    
	}
	#event_name_bdr_add_calender_ev {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.event_name_bdr_add_calender_ev {
        position: absolute;
        overflow: visible;
        width: 250px;
        height: 40px;
        left: 0px;
        top: 30px;
        border: 1px solid #ADF5CE;
        border-radius: 5px;
	}
	#Event_Name_lbl_add_calender_ev {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 70px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}

#Scroll_grp_events_pc {
    mix-blend-mode: normal;
    position: absolute;
    width: 324px;
    height: 550px;
    left: 21px;
    top: 140px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#add_calender_event_grp {
    position: absolute;
    width: 250px;
    height: 672px;
    left: 15px;
    top: 3px;
    overflow: visible;
}
#upload_banner_grp_add_add_cale {
    position: absolute;
    width: 250px;
    height: 98px;
    left: 0px;
    top: 574px;
    overflow: visible;
}
#Upload_Banner_IMG_add_calender {
    left: 0px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 164px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#upload_imgadd_calender_event {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 198px;
    top: 0px;
    overflow: visible;
    cursor: pointer;
}
#img_preview_section_add_calend {
    position: absolute;
    width: 250px;
    height: 70px;
    left: 0px;
    top: 28px;
    overflow: visible;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
   
}
#add_calender_event_loca_grp {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 488px;
    overflow: visible;
}
#location_bdr_add_calender_even {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.location_bdr_add_calender_even {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Location_lbl_add_calender_even {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_calender_event_grp_end_atd {
    position: absolute;
    width: 250px;
    height: 63px;
    left: 0px;
    top: 149px;
    overflow: visible;
}
#end_bdr_add_calender_event {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.end_bdr_add_calender_event {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#ends_lbl_add_calender_event {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 58px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_calender_event_grp_cq {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 75px;
    overflow: visible;
}
#date_bdr_add_calender_event {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.date_bdr_add_calender_event {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Date_lbl_add_calender_event {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_calender_event_category_gr {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 299px;
    overflow: visible;
}
#category_bdr_add_calender_even {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.category_bdr_add_calender_even {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Category_lbl_add_calender_even {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_calender_event_description {
    position: absolute;
    width: 250px;
    height: 103px;
    left: 0px;
    top: 373px;
    overflow: visible;
}
#descp_bdr_add_calender_event {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.descp_bdr_add_calender_event {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 80px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Description_lbl_add_calender_e {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 86px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#event_name_grp_add_event_detai {
    position: absolute;
    width: 250px;
    height: 63px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#event_name_bdr_add_event_detai {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.event_name_bdr_add_event_detai {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Event_Name_lbl_add_event_detai {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 92px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#event_cost_grp_add_event_detai {
    position: absolute;
    width: 115px;
    height: 62px;
    left: 135px;
    top: 225px;
    overflow: visible;
}
#event_cost_bdr_add_event_detai {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.event_cost_bdr_add_event_detai {
    position: absolute;
    overflow: visible;
    width: 115px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Event_Cost_lbl_add_event_detai {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 80px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#event_seats_grp_add_event_deta {
    position: absolute;
    width: 115px;
    height: 63px;
    left: 0px;
    top: 224px;
    overflow: visible;
}
#event_seats_bdr_add_event_deta {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.event_seats_bdr_add_event_deta {
    position: absolute;
    overflow: visible;
    width: 115px;
    height: 40px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Event_Seats_lbl_add_event_deta {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 86px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#alarm_grp_calender_pop_alrm_po {
    position: absolute;
    width: 250px;
    height: 400px;
    left: 36px;
    top: 65px;
    overflow: visible;
}
#start_at_grp_add_alrm_pop {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 75px;
    overflow: visible;
}
#start_at_bdr_add_alrm_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.start_at_bdr_add_alrm_pop {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#start_at_lbl_add_alrm_pop {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#description_grp_add_alrm_pop {

    position: absolute;
    width: 249px;
    height: 62px;
    left: 0px;
    top: 223px;
    overflow: visible;
}
#descp_bdr_add_alrm_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.descp_bdr_add_alrm_pop {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 80px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Description_lbl_add_alrm_pop {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 86px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}

#title_grp_add_alrm_pop {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 149px;
    overflow: visible;
}
#title_bdr_add_alrm_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.title_bdr_add_alrm_pop {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#title_lbl_add_alrm_pop {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#snooze_grp_add_alrm_pop {
    position: absolute;
    width: 250px;
    height: 103px;
    left: 0px;
    top: 297px;
    overflow: visible;
}
#snooze_bdr_add_alrm_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.snooze_bdr_add_alrm_pop {
    position: absolute;
    overflow: visible;
    width: 169px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#snooze_timelbl_add_alrm_pop {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 112px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#button_snooze_bdr_add_alrm_pop {
    fill: rgba(166,210,170,1);
}
.button_snooze_bdr_add_alrm_pop {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 40px;
    left: 209px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#snooze_1 {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 217px;
    top: 30px;
    overflow: visible;
}
#task_pop_up_calender_page {
    position: absolute;
    width: 250px;
    height: 550px;
    left: 36px;
    top: 65px;
    overflow: visible;
}
#loca_grp_add_task_pop_up {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 373px;
    overflow: visible;
}
#location_bdr_add_task_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.location_bdr_add_task_pop_up {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Location_lbl_add_task_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#ends_at_grp_add_task_pop_up {
    position: absolute;
    width: 250px;
    height: 63px;
    left: 0px;
    top: 224px;
    overflow: visible;
}
#end_at_bdr_add_task_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.end_at_bdr_add_task_pop_up {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#end_at_lbl_add_task_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 58px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#start_at_grp_add_task_pop_up {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 150px;
    overflow: visible;
}
#start_at_bdr_add_event_details {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.start_at_bdr_add_event_details {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#start_at_lbl_add_task_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#category_grp_add_task_pop_up {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 299px;
    overflow: visible;
}
#category_bdr_add_task_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.category_bdr_add_task_pop_up {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Category_lbl_add_task_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#description_grp_add_task_pop_u {
    position: absolute;
    width: 250px;
    height: 103px;
    left: 0px;
    top: 447px;
    overflow: visible;
}
#descp_bdr_add_task_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.descp_bdr_add_task_pop_up {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 80px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Description_lbl_add_task_pop_u {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 86px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}

#titile_grp_add_task_pop_up {
    position: absolute;
    width: 250px;
    height: 63px;
    left: 0px;
    top: 75px;
    overflow: visible;
}
#title_bdr_add_task_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.title_bdr_add_task_pop_up {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#title_lbl_add_task_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#meeting_calender_pop_up {
    position: absolute;
    width: 250px;
    height: 594px;
    left: 36px;
    top: 65px;
    overflow: visible;
}
#attendies_grp_add_meeting_cale {
    position: absolute;
    width: 250px;
    height: 66px;
    left: 0px;
    top: 528px;
    overflow: visible;
}
#attendies_lbl_meeting_calender {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 72px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_attendies_meeting_calender {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 225px;
    top: 0px;
    cursor: pointer;
    overflow: visible;
}
#img_imreanmeeting_calender {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0px;
    top: 31px;
    border-radius: 50%;
    overflow: hidden;
}
#imran_fool_meeting_calender {
    left: 40px;
    top: 27px;
    position: absolute;
    overflow: visible;
    width: 210px;
    height: 22px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(76,193,87,1);
}
#Full_Stack_Developer_meeting_c {
    left: 40px;
    top: 49px;
    position: absolute;
    overflow: visible;
    width: 117px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#end_time_grp_add_meeting_calen {
    position: absolute;
    width: 250px;
    height: 63px;
    left: 0px;
    top: 224px;
    overflow: visible;
}
#end_time_bdr_add_meeting_calen {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.end_time_bdr_add_meeting_calen {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#end_Time_lbl_add_meeting_calen {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 58px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#sstart_at_grp_add_meeting_cale {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 150px;
    overflow: visible;
}
#start_at__bdr_add_meeting_cale {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.start_at__bdr_add_meeting_cale {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#start_at_lbl_add_meeting_calen {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#category_grp_add_meeting_calen {
    position: absolute;
    width: 250px;
    height: 102px;
    left: 0px;
    top: 414px;
    overflow: visible;
}
#category_bdr_add_meeting_calen {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.category_bdr_add_meeting_calen {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 80px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Category_lbl_add_meeting_calen {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 64px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#agenda_grp_add_meeting_calende {
    position: absolute;
    width: 250px;
    height: 103px;
    left: 0px;
    top: 299px;
    overflow: visible;
}
#event_agenda_bdr_add_event_det {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.event_agenda_bdr_add_event_det {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 80px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#agenda_lbl_add_meeting_calende {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 59px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}

#title_type_grp_add_meeting_cal {
    position: absolute;
    width: 250px;
    height: 63px;
    left: 0px;
    top: 75px;
    overflow: visible;
}
#titletype_bdr_add_meeting_cale {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.titletype_bdr_add_meeting_cale {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#titletype_lbl_add_meeting_cale {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}

	.mediaViewInfo {
		--web-view-name: add;
		--web-view-id: add_attendies_pc;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: add;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#add_attendies_pc {
		position: absolute;
		width: 237px;
		height: 200px;
        top: 60%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
		overflow: hidden;
		--web-view-name: add;
		--web-view-id: add_attendies_pc;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#bdr_of_attendies_people {
		fill: rgba(255,255,255,1);
	}
	.bdr_of_attendies_people {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
        overflow: visible;
        width: 235px;
        height: 200px;
        left: 3px;
        top: 3px;
	}
	#Scroll_add_attendies_people {
		mix-blend-mode: normal;
		position: absolute;
		width: 231px;
		height: 165px;
		left: 4px;
		top: 33px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#card_1_grp_attendies_people {
	position: relative;
    width: 205px;
    height: 40px;
    left: 6px;
    top: 2px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid  #ADF5CE;
    overflow: visible;
	}
	#attendies_people_imreanmeeting {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
        border-radius: 50%;
		overflow: hidden;
	}
	#attendies_people_imran_fool_me {
		left: 35px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(76,193,87,1);
	}
	#OP_FSD_Lbl_ {
		left: 35px;
		top: 18px;
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 15px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#close_pop_people {
		position: absolute;
		width: 17px;
		height: 17px;
		left: 214px;
		top: 7px;
        cursor: pointer;
		overflow: visible;
	}
	#Rectangle_699 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 0.5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Rectangle_699 {
		position: absolute;
		overflow: visible;
		width: 166px;
		height: 20px;
		left: 21px;
		top: 8px;
		border: 1px solid #ADF5CE;
		border-radius: 50px;
	}

	.mediaViewInfo {
		--web-view-name: calender event part;
		--web-view-id: calender_event_part;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: calender_event_part;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#calender_event_part {
		position: absolute;
        width: 100%;
        height: 60px;
        top: -45px;
        left: 65px;
		/* background-color: rgba(255,255,255,1); */
		overflow: hidden;
		--web-view-name: calender event part;
		--web-view-id: calender_event_part;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#Scroll_weekly_sync_up {
        mix-blend-mode: normal;
        position: absolute;
        width: calc(100% - 120px);
        height: 55px;
        /* left: 15px; */
        top: 0px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        overflow-x: scroll;
        overflow-y: hidden;
	}
	#wekluy_sync_up_grp {
		position: relative;
		width: 183px;
		height: 53px;
		left: 5px;
		top: 1px;
        /* margin: 10px; */
		overflow: visible;
	}
	#over_all_calender_card_bdr {
		fill: rgba(255,255,255,1);
		stroke: rgba(255,137,1,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.over_all_calender_card_bdr {
		position: absolute;
		overflow: visible;
		width: 183px;
		height: 53px;
		left: 0px;
		top: 0px;
	}
	#Weekly_Sync_Up_lbl_name {
		left: 54px;
		top: 7px;
		position: absolute;
		overflow: hidden;
		width: 130px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#n_30am_-_1030am_wekluy_sync_up {
		left: 53px;
		top: 27px;
		position: absolute;
		overflow: visible;
		width: 119px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#img_bdr_wekluy_sync_up {
		fill: rgba(253,164,61,1);
	}
	.img_bdr_wekluy_sync_up {
		position: absolute;
		overflow: visible;
		width: 34px;
		height: 34px;
		left: 9px;
		top: 9px;
	}
	#bell_wekluy_sync_up {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 14px;
		top: 14px;
		overflow: visible;
	}
	#line_below_event_card {
		position: absolute;
		width: 100%;
		height: 5px;
		/* left: 15px; */
		top: 55px;
		overflow: visible;
	}
	#calendar_line_event_part {
		fill: transparent;
		stroke: rgba(119,128,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.calendar_line_event_part {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 2.5px;
		top: 2.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#line_left_dot_caleneder {
		fill: rgba(119,128,255,1);
	}
	.line_left_dot_caleneder {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}

	.mediaViewInfo {
		--web-view-name: calender page;
		--web-view-id: calender_page;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: calender_page;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}

  #centre_card_grp_calender_pc { 
    -webkit-animation-name: floating; 
            animation-name: floating;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
@-webkit-keyframes floating {
    0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    50%  { -webkit-transform: translate(0, 15px); transform: translate(0, 15px); }
    100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}
@keyframes floating {
    0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    50%  { -webkit-transform: translate(0, 15px); transform: translate(0, 15px); }
    100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}
	#calender_page {
		position: absolute;
		width: calc(100vw - 120px);
		height: calc(100vh - 60px);
        min-width: 1000px;
        top: 60px;
        left: 120px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: calender page;
		--web-view-id: calender_page;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#com_tata_next_grp {
		position: absolute;
		width: 463px;
		height: 21px;
		left: 211px;
		top: 46px;
		overflow: visible;
	}
	#completed_grp_calender_pc {
		position: absolute;
		width: 146px;
		height: 21px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Completed_lbbl_calender_pc {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 85px;
		white-space: nowrap;
		text-align: center;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#count_bdr {
		fill: rgba(255,255,255,1);
		stroke: rgba(166,210,170,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.count_bdr {
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 21px;
		left: 106px;
		top: 0px;
	}
	#n_0_count_calender_pc {
		left: 118px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 17px;
		white-space: nowrap;
		text-align: center;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#completed_staus_calender_pc {
		fill: rgba(46,243,65,1);
	}
	.completed_staus_calender_pc {
		position: absolute;
		overflow: visible;
		width: 7px;
		height: 7px;
		left: 0px;
		top: 7px;
	}
	#next_up_calender_pc_grp {
		position: absolute;
		width: 121px;
		height: 21px;
		left: 342px;
		top: 0px;
		overflow: visible;
	}
	#Next_Up_lbl_calender_pc {
		left: 15.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#n_0_bdr_next_up_calender_pc {
		fill: rgba(255,255,255,1);
		stroke: rgba(166,210,170,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.n_0_bdr_next_up_calender_pc {
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 21px;
		left: 81px;
		top: 0px;
	}
	#n_0_calender_pc {
		left: 95px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 13px;
		white-space: nowrap;
		text-align: center;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#arrow_next_up {
		fill: rgba(155,99,252,1);
	}
	.arrow_next_up {
		overflow: visible;
		position: absolute;
		width: 8px;
		height: 8px;
		-webkit-transform: translate(-578.5px, -85px) matrix(1,0,0,1,580,92) rotate(90deg);
		        transform: translate(-578.5px, -85px) matrix(1,0,0,1,580,92) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#next_up_arrow_line {
		fill: transparent;
		stroke: rgba(155,99,252,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.next_up_arrow_line {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 8px;
		left: 0px;
		top: 7px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#todo_grp_calender_pc {
		position: absolute;
		width: 96px;
		height: 21px;
		left: 196px;
		top: 0px;
		overflow: visible;
	}
	#To-Do_lbl_calender_pc {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 47px;
		white-space: nowrap;
		text-align: center;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#n_0_bdr_calender_pc {
		fill: rgba(255,255,255,1);
		stroke: rgba(166,210,170,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.n_0_bdr_calender_pc {
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 21px;
		left: 56px;
		top: 0px;
	}
	#n_0_calender_pc_ce {
		left: 70px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 13px;
		white-space: nowrap;
		text-align: center;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#centre_card_grp_calender_pc {
		position: absolute;
		width: 588.4px;
		height: 580.26px;
		left: calc((100% - 1100px)/2);
		top: 103px;
		overflow: visible;
	}
	#meeeetings_card {
		position: absolute;
		width: 280.4px;
		height: 280px;
		left: 0px;
		top: 300px;
		overflow: visible;
	}
	#meeting_card_overall_bg_calend_ci {
		fill: url(#meeting_card_overall_bg_calend_ci);
	}
	.meeting_card_overall_bg_calend_ci {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 298px;
		left: 0px;
		top: 0px;
	}
	#Meetings_lbl_headingcalender_p {
		left: 111.88px;
		top: 5.6px;
		position: absolute;
		overflow: visible;
		width: 71px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#Scroll_meeting {
		mix-blend-mode: normal;
		position: absolute;
		width: 273.76px;
		height: 239.94px;
		left: 6.64px;
		top: 35.32px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_meeting::-webkit-scrollbar{display: none;}
	#meeting_grp_calender_page {
		position: relative;
		width: 249.76px;
		height: 55.44px;
		left: 4px;
		top: 5px;
		overflow: visible;
	}
	#line_calender_pc {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_calender_pc {
		overflow: visible;
		position: absolute;
		width: 249.76px;
		height: 1px;
		left: 0px;
		top: 55.44px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Arcane_Series_lbl_calender_pc {
		left: 9.36px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#n_025pm_-_1200am_arcane {
		left: 9.36px;
		top: 23.52px;
		position: absolute;
		overflow: visible;
		width: 121px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#rcane_mode_meetincalender_pc {
		fill: rgba(252,148,148,1);
	}
	.rcane_mode_meetincalender_pc {
		position: absolute;
		overflow: visible;
		width: 3.36px;
		height: 16.8px;
		left: 0.561px;
		top: 3.36px;
	}
	#inacvtice_status_calender_pc {
		fill: rgba(243,46,46,1);
	}
	.inacvtice_status_calender_pc {
		position: absolute;
		overflow: visible;
		width: 11.2px;
		height: 11.2px;
		left: 235.761px;
		top: 26.88px;
	}
	#meeting_img_calender_pc {
		position: absolute;
		width: 22.4px;
		height: 22.4px;
		left: 82.88px;
		top: 5.6px;
		overflow: visible;
	}
	#events_card {
		position: absolute;
		width: 280.4px;
		height: 280.26px;
		left: 308px;
		top: 300px;
		overflow: visible;
	}
	#events_verall_pg_calender_pc_cu {
		fill: url(#events_verall_pg_calender_pc_cu);
	}
	.events_verall_pg_calender_pc_cu {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 298px;
		left: 0px;
		top: 0px;
	}
	#Events_lbl_calender_pc {
		left: 119.48px;
		top: 5.6px;
		position: absolute;
		overflow: visible;
		width: 47px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#Scroll_events {
		mix-blend-mode: normal;
		position: absolute;
		width: 279.76px;
		height: 247.26px;
		left: 0.64px;
		top: 33px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_events::-webkit-scrollbar{display: none;}
	#events_page_calender {
		position: relative;
		width: 249.76px;
		height: 55.76px;
		left: 10px;
		top: 7px;
		overflow: visible;
	}
	#Dragon_Ball_Super_lbl {
		left: 9.36px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 133px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#KAMEHAMEHA {
		left: 9.36px;
		top: 23.52px;
		position: absolute;
		overflow: visible;
		width: 113px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#dragon_ball_super_calender_pc {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.dragon_ball_super_calender_pc {
		overflow: visible;
		position: absolute;
		width: 249.76px;
		height: 1px;
		left: 0px;
		top: 55.76px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#dragon_ball_super_mode_calende {
		fill: rgba(175,148,252,1);
	}
	.dragon_ball_super_mode_calende {
		position: absolute;
		overflow: visible;
		width: 3.36px;
		height: 16.8px;
		left: 0.561px;
		top: 3.68px;
	}
	#active_events_calender_pc {
		fill: rgba(46,243,65,1);
	}
	.active_events_calender_pc {
		position: absolute;
		overflow: visible;
		width: 11.2px;
		height: 11.2px;
		left: 234.641px;
		top: 30.56px;
	}
	#calendar_img_calender_pc {
		position: absolute;
		width: 22.4px;
		height: 22.4px;
		left: 88.48px;
		top: 5.6px;
		overflow: visible;
	}
	#tasks_card {
		position: absolute;
		width: 280.4px;
		height: 280px;
		left: 308px;
		top: 0px;
		overflow: visible;
	}
	#tak_bdr_calender_pc_c {
		fill: url(#tak_bdr_calender_pc_c);
	}
	.tak_bdr_calender_pc_c {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 298px;
		left: 0px;
		top: 0px;
	}
	#Tasks_lbl_ {
		left: 117.48px;
		top: 5.6px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#notes_img_taskes_calender_pc {
		position: absolute;
		width: 22.4px;
		height: 22.4px;
		left: 88.48px;
		top: 5.6px;
		overflow: visible;
	}
	#Scroll_tasks {
		mix-blend-mode: normal;
		position: absolute;
		width: 276.76px;
		height: 235.3px;
		left: 3.64px;
		top: 40.32px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_tasks::-webkit-scrollbar{display: none;}
	#Group_task_pc_new {
		position: relative;
		width: 249.76px;
		height: 58.8px;
		left: 7px;
		top: 0px;
		overflow: visible;
	}
	#calender_page_pc {
		left: 9.36px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 123px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#calender_time_ {
		left: 9.36px;
		top: 23.52px;
		position: absolute;
		overflow: visible;
		width: 113px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#arcane_top_line_calender_pc {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.arcane_top_line_calender_pc {
		overflow: visible;
		position: absolute;
		width: 249.76px;
		height: 1px;
		left: 0px;
		top: 58.8px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#caleneder_mode_calender_pc {
		fill: rgba(175,148,252,1);
	}
	.caleneder_mode_calender_pc {
		position: absolute;
		overflow: visible;
		width: 3.36px;
		height: 16.8px;
		left: 0.561px;
		top: 3.36px;
	}
	#active_calender_page_pc_calend {
		fill: rgba(46,243,65,1);
	}
	.active_calender_page_pc_calend {
		position: absolute;
		overflow: visible;
		width: 11.2px;
		height: 11.2px;
		left: 234.641px;
		top: 38.08px;
	}
	#immediate_1_calender_pc {
		position: absolute;
		width: 22.4px;
		height: 22.4px;
		left: 202.16px;
		top: 30.8px;
		overflow: visible;
	}
	#arlarm_card {
		position: absolute;
		width: 280.4px;
		height: 280px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#alarm_bdr_overall_pc_dj {
		fill: url(#alarm_bdr_overall_pc_dj);
	}
	.alarm_bdr_overall_pc_dj {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 298px;
		left: 0px;
		top: 0px;
	}
	#Alarms_lbl_calender {
		left: 110.88px;
		top: 6.6px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#clock_alarm_for_snooze {
		position: absolute;
		width: 22.4px;
		height: 22.4px;
		left: 82.88px;
		top: 5.6px;
		overflow: visible;
	}
	#Scroll_alarm {
		mix-blend-mode: normal;
		position: absolute;
		width: 275.76px;
		height: 235.3px;
		left: 4.64px;
		top: 40.32px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_alarm::-webkit-scrollbar{display: none;}
	#waking_up_all_grp_alarm {
		position: relative;
		width: 249.76px;
		height: 58.8px;
		left: 6px;
		top: 0px;
		overflow: visible;
	}
	#Waking_Up_Early_lbl_calender {
		left: 9.36px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 117px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#n_00am_time_for_wake_up {
		left: 9.36px;
		top: 29.36px;
		position: absolute;
		overflow: visible;
		width: 45px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#leaving_office_line {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.leaving_office_line {
		overflow: visible;
		position: absolute;
		width: 249.76px;
		height: 1px;
		left: 0px;
		top: 58.8px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#waking_up_early_line {
		fill: rgba(175,148,252,1);
	}
	.waking_up_early_line {
		position: absolute;
		overflow: visible;
		width: 3.36px;
		height: 16.8px;
		left: 0.561px;
		top: 3.36px;
	}
	#n_1135am_time_for_snooze {
		left: 122.641px;
		top: 31.36px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#snooze_img_calender {
		position: absolute;
		width: 22.4px;
		height: 22.4px;
		left: 100.24px;
		top: 30.24px;
		overflow: visible;
	}
	#green_ball {
		fill: rgba(164,255,96,1);
	}
	.green_ball {
		position: absolute;
		overflow: visible;
		width: 11.2px;
		height: 11.2px;
		left: 231.28px;
		top: 38.08px;
	}
	#back_btn_for_calender {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 93px;
		top: 43px;
		overflow: visible;
	}
	
	#right_side_grp_calender_pc {
		position: absolute;
		width: 390px;
		height: 768px;
		right: 0px;
		top: 0px;
		overflow: visible;
	}
	#calender_bdr_calender_pc_ei {
		fill: url(#calender_bdr_calender_pc_ei);
	}
	.calender_bdr_calender_pc_ei {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 768px;
		left: 0px;
		top: 0px;
	}
	#Scroll_date_and_event_oof {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 350px;
		left: 3px;
		top: 350px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#so_much_to_grp {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0px;
		/* top: 60px; */
		overflow: visible;
	}
	#n_am_number_calender_pc {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 80px;
		overflow: visible;
	}
	#n__am_lbl {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_1am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_1am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_1_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_1_am:hover {
       opacity: 100;
    }

	#n_2am_number_calender_pc {
		position: relative;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 0px;
		margin-top: 50px;
		overflow: visible;
	}
	#n_2_am_lbl {
		left: 22px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 42px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_12am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_12am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_0_am {
		position: relative;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_0_am:hover {
		opacity: 100;
	}
	#n_am_number_calender_pc_et {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 160px;
		overflow: visible;
	}
	#n__am_lbl_eu {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_2am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_2am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_2_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_2_am:hover {
		opacity: 100;
	}
	#n_am_number_calender_pc_ex {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 320px;
		overflow: visible;
	}
	#n__am_lbl_ey {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_4am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_4am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_4_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_4_am:hover {
		opacity: 100;
	}
	#n_am_number_calender_pc_e {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 240px;
		overflow: visible;
	}
	#n__am_lbl_e {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_3am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_3am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_3_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_3_am:hover {
		opacity: 100;
	}
	#n_am_number_calender_pc_fa {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 400px;
		overflow: visible;
	}
	#n__am_lbl_fa {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_5_am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_5_am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_5_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity:0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_5_am:hover {
		opacity: 100;
	}
	#n_am_number_calender_pc_fb {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 560px;
		overflow: visible;
	}
	#n__am_lbl_fb {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_7_am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_7_am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_7_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_7_am:hover {
		opacity: 100;
	}
	#n_am_number_calender_pc_fd {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 480px;
		overflow: visible;
	}
	#n__am_lbl_fe {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line__6_am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line__6_am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_6_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_6_am:hover {
		opacity: 100;
	}
	#n_am_number_calender_pc_fh {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 640px;
		overflow: visible;
	}
	#n__am_lbl_fi {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_8am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_8am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_8_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_8_am:hover {
		opacity: 100;
	}
	#n_0am_number_calender_pc {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 800px;
		overflow: visible;
	}
	#Line10am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line10am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_0_am_lbl {
		left: 22px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 42px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#add_btn_10_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_10_am:hover {
		opacity: 100;
	}
	#n_am_number_calender_pc_fp {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 720px;
		overflow: visible;
	}
	#Line_9am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_9am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n__am_lbl_fr {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#add_btn_9_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity:0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_9_am:hover {
		opacity: 100;
	}
	#n_1am_number_calender_pc {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 880px;
		overflow: visible;
	}
	#Line_11am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_11am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_1_am_lbl {
		left: 22px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 42px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#add_btn_11_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_11_am:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 1040px;
		overflow: visible;
	}
	#n__pm_lbl {
		left: 26px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_1pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_1pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 11.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_1_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_1_pm:hover {
		opacity: 100;
	}
	#n_2pm_number_calender_pc {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 960px;
		overflow: visible;
	}
	#Line_12am_f {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_12am_f {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_2_pm_lbl {
		left: 22px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#add_btn_12_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_12_pm:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc_f {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 1120px;
		overflow: visible;
	}
	#n__pm_lbl_f {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_2pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_2pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_2_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_2_pm:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc_ga {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 1280px;
		overflow: visible;
	}
	#n__pm_lbl_ga {
		left: 26px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_4pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_4pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 11.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_4_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_4_pm:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc_gd {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 1200px;
		overflow: visible;
	}
	#n__pm_lbl_ge {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_3am_gf {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_3am_gf {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_3_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_3_pm:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc_gh {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 1360px;
		overflow: visible;
	}
	#n__pm_lbl_gi {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_5pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_5pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_5_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_5_pm:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc_gl {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 1520px;
		overflow: visible;
	}
	#n__pm_lbl_gm {
		left: 26px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_7_pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_7_pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 11.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_7_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_7_pm:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc_gp {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 1440px;
		overflow: visible;
	}
	#n__pm_lbl_gq {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_6pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_6pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_6_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_6_pm:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc_gt {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 1600px;
		overflow: visible;
	}
	#n__pm_lbl_gu {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_8pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_8pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_8_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_8_pm:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc_gx {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 1680px;
		overflow: visible;
	}
	#Line_9pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_9pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n__pm_lbl_gz {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#add_btn_9_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_9_pm:hover {
		opacity: 100;
	}
	#n_0pm_number_calender_pc {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 1760px;
		overflow: visible;
	}
	#Line_10pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_10pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 9.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_0_pm_lbl {
		left: 22px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#add_btn_10_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_10_pm:hover {
		opacity: 100;
	}
	#n_1pm_number_calender_pc {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 1840px;
		overflow: visible;
	}
	#Line_11pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_11pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_1_pm_lbl {
		left: 22px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#add_btn_11_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_11_pm:hover {
		opacity: 100;
	}
	#august_calender_pc_side {
		position: absolute;
		width: 290px;
        height: 270px;
		left: 50px;
		top: 46px;
		overflow: visible;
	}
	#dates_bdr_calender_pc_side {
		fill: rgba(255,255,255,1);
	}
	.dates_bdr_calender_pc_side {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 309px;
		height: 233px;
		left: 0px;
		top: 37px;
	}
	#aug_bdr_calender_pc_side {
		fill: rgba(255,255,255,1);
	}
	.aug_bdr_calender_pc_side {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 309px;
		height: 46px;
		left: 0px;
		top: 0px;
	}
	#August_2023_lbl_calender_pc_si {
		left: 109px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#Path_6951_left_calender_pc_sid {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1.2000000476837158px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_6951_left_calender_pc_sid {
		overflow: visible;
		position: absolute;
		width: 6.273px;
		height: 10.849px;
		left: 12.5px;
		top: 9.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_6952_rgh_calender_pc_side {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1.2000000476837158px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_6952_rgh_calender_pc_side {
		overflow: visible;
		position: absolute;
		width: 6.273px;
		height: 10.849px;
		left: 274.5px;
		top: 9.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Moncalender_pc_side {
		left: 4px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 23px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Tuecalender_pc_side {
		left: 50px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 19px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Wedcalender_pc_side {
		left: 91px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 23px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Thucalender_pc_side {
		left: 137px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 19px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Fricalender_pc_side {
		left: 183px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 13px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Satcalender_pc_side {
		left: 225px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 17px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Suncalender_pc_side {
		left: 267px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 21px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#n_1_calender_pc_side11 {
		left: 8px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(170,170,170,1);
	}
	#n__calender_pc_side11 {
		left: 58px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n__calender_pc_side11_ho {
		left: 101px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n__calender_pc_side11_hp {
		left: 143px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n__calender_pc_side {
		left: 186px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n__calender_pc_side_hr {
		left: 228px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n__calender_pc_side_hs {
		left: 274px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n__calender_pc_side_ht {
		left: 12px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n__calender_pc_side_hu {
		left: 58px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_calender_pc_side {
		left: 101px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_0_calender_pc_side {
		left: 139px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_1calender_pc_side {
		left: 183px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 13px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_2calender_pc_side {
		left: 226px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_3calender_pc_side {
		left: 270px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_4calender_pc_side {
		left: 8px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_5calender_pc_side {
		left: 53px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_6calender_pc_side {
		left: 96px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_7calender_pc_side {
		left: 139px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_8_calender_pc_side {
		left: 182px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_9_calender_pc_side {
		left: 226px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_0calender_pc_side {
		left: 270px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_1_calender_pc_side {
		left: 8px;
		top: 196px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_2_calender_pc_side {
		left: 53px;
		top: 196px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_3_calender_pc_side {
		left: 96px;
		top: 196px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_4_calender_pc_side {
		left: 139px;
		top: 196px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_5_calender_pc_side {
		left: 182px;
		top: 196px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_6_calender_pc_side {
		left: 226px;
		top: 196px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_7_calender_pc_side {
		left: 270px;
		top: 196px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_8calender_pc_side {
		left: 8px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_9calender_pc_side {
		left: 53px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_0_calender_pc_side_ig {
		left: 96px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_1_calender_pc_side_ih {
		left: 139px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n__calender_pc_side_ii {
		left: 186px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(170,170,170,1);
	}
	#n__calender_pc_side_ij {
		left: 228px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(170,170,170,1);
	}
	#n__calender_pc_side_ik {
		left: 274px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(170,170,170,1);
	}
	.mediaViewInfo {
		--web-view-name: calender page Alarm mob;
		--web-view-id: calender_page_Alarm_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: calender_page_Alarm_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#mb_calender_page_Alarm_mob {
	position: absolute;
    width: 100%;
    height: calc(100% - 75px);
    top: 75px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: calender page Alarm mob;
    --web-view-id: calender_page_Alarm_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
	}
	#mb_calender_page_Alarm_mob_grp {
		position: absolute;
    width: 100%;
    height: calc(100% - 17px);
    left: -26px;
    top: 17px;
    overflow: visible;
	}
	#mb_alarm_mob_calender {
		fill: rgba(255,255,255,1);
		stroke: rgba(78,187,87,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.mb_alarm_mob_calender {
		position: absolute;
		overflow: visible;
		width: calc(100% - 20px);
		height: 100%;
		left: 0px;
		top: 0px;
	}
	#mb_right_atlarm_mob_ {
		position: absolute;
        width: 22.5px;
        height: 332.1px;
        right: -15px;
        top: 25.5px;
        overflow: visible;
	}
	#mb_mobTasksalarm_lbl_ {
		-webkit-transform: translate(-340.5px, -156.5px) matrix(1,0,0,1,337.5,259.5396) rotate(90deg);
		        transform: translate(-340.5px, -156.5px) matrix(1,0,0,1,337.5,259.5396) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#mb_Meetings_lbl_headingcalender_m {
		-webkit-transform: translate(-340.5px, -156.5px) matrix(1,0,0,1,324.5,351.9829) rotate(90deg);
		        transform: translate(-340.5px, -156.5px) matrix(1,0,0,1,324.5,351.9829) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#mb_Events_lbl_calender_mob {
		-webkit-transform: translate(-340.5px, -156.5px) matrix(1,0,0,1,333.5,459.1001) rotate(90deg);
		        transform: translate(-340.5px, -156.5px) matrix(1,0,0,1,333.5,459.1001) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#mb_alarm_mob_cal_mob {
		position: absolute;
		width: 22.5px;
		height: 42.22px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#mb_mobAlarms_lbl_calender {
		-webkit-transform: translate(-340.5px, -156.5px) matrix(1,0,0,1,332.5,168.2197) rotate(90deg);
		        transform: translate(-340.5px, -156.5px) matrix(1,0,0,1,332.5,168.2197) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(78,187,87,1);
	}
	#mb_Line_29_alarm_mob {
		fill: transparent;
		stroke: rgba(78,187,87,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.mb_Line_29_alarm_mob {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 42px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#mb_Scroll_alarm_mob_ {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 45px);
    height: 100%;
    left: 26px;
    /* top: 6.32px; */
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-content: flex-start;	
    overflow-x: hidden;
    overflow-y: scroll;
	}

	#mb_waking_up_all_grp_alarm_Mob {
		position: relative;
		width: 249.76px;
		height: 58.8px;
		left: 32.64px;
		top: 0px;
        margin: 10px;
		overflow: visible;
	}
	#mb_Waking_Up_Early_lbl_calender_Mob {
		left: 9.16px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 234px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#mb_n_00am_time_for_wake_up {
		left: 9.16px;
		top: 29.36px;
		position: absolute;
		overflow: visible;
		width: 45px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#mb_waking_up_early_line {
		fill: rgba(175,148,252,1);
	}
	.mb_waking_up_early_line {
		position: absolute;
		overflow: visible;
		width: 3.36px;
		height: 16.8px;
		left: 0.36px;
		top: 3.36px;
	}
	#mb_n_1135am_time_for_snooze {
		left: 122.44px;
		top: 31.36px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#mb_snooze_img_calender {
		position: absolute;
		width: 22.4px;
		height: 22.4px;
		left: 100.04px;
		top: 30.24px;
		overflow: visible;
	}
	#mb_green_ball {
		fill: rgba(164,255,96,1);
	}
	.mb_green_ball {
		position: absolute;
		overflow: visible;
		width: 11.2px;
		height: 11.2px;
		left: 231.08px;
		top: 38.08px;
	}
	#mb_arcane_top_line_calender_wa {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.mb_arcane_top_line_calender_wa {
		overflow: visible;
		position: absolute;
		width: 249.76px;
		height: 1px;
		left: 0px;
		top: 58.8px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}

	.mediaViewInfo {
		--web-view-name: calender page Task mob;
		--web-view-id: calender_page_Task_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: calender_page_Task_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#calender_page_Task_mob {
		position: absolute;
        width: 100%;
        height: calc(100% - 75px);
        top: 75px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: calender page Task mob;
		--web-view-id: calender_page_Task_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#calender_page_Task_mob_grp {
		position: absolute;
        width: 100%;
        height: calc(100% - 17px);
        left: -26px;
        top: 17px;
		overflow: visible;
	}
	#calender_page_Task_mob_bdr {
		fill: rgba(255,255,255,1);
		stroke: rgba(78,187,87,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.calender_page_Task_mob_bdr {
		position: absolute;
		overflow: visible;
		width: calc(100% - 20px);
		height: 100%;
		left: 0px;
		top: 0px;
	}
	#right_menu_calender_page_Task_ {
		position: absolute;
		width: 22.5px;
		height: 331.88px;
        right: -15px;
		top: 25.72px;
		overflow: visible;
	}
	#mobtrask_mob_lbl_calender {
		-webkit-transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,332.5,168.2197) rotate(90deg);
		        transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,332.5,168.2197) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#mobMeetings_lbl_headingcalende {
		-webkit-transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,324.5,351.9829) rotate(90deg);
		        transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,324.5,351.9829) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#Events_lbl_calender_mob {
		-webkit-transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,333.5,459.1001) rotate(90deg);
		        transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,333.5,459.1001) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#underline_task_mob {
		position: absolute;
		width: 22.5px;
		height: 32.46px;
		left: 0px;
		top: 96.32px;
		overflow: visible;
	}
	#mobTasks_lbl_ {
		-webkit-transform: translate(-340.5px, -253.04px) matrix(1,0,0,1,337.5,259.5396) rotate(90deg);
		        transform: translate(-340.5px, -253.04px) matrix(1,0,0,1,337.5,259.5396) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(78,187,87,1);
	}
	#mobLine_29 {
		fill: transparent;
		stroke: rgba(78,187,87,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.mobLine_29 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 32px;
		left: 0px;
		top: 0.46px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#task_mobc_cal_mob {
	mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 45px);
    height: 100%;
    left: 26px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-content: flex-start;
    overflow-x: hidden;
    overflow-y: scroll;
	}
	#card_1_task_mob {
		position: relative;
		width: 249.76px;
		height: 58.8px;
		left: 30.64px;
		top: 0px;
        margin: 10px;
		overflow: visible;
	}
	#calender_page_mob_lbl {
		left: 9.16px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 237px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#calender_time_mob {
		left: 9.16px;
		top: 23.52px;
		position: absolute;
		overflow: visible;
		width: 113px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#mobcaleneder_mode_calender {
		fill: rgba(175,148,252,1);
	}
	.mobcaleneder_mode_calender {
		position: absolute;
		overflow: visible;
		width: 3.36px;
		height: 16.8px;
		left: 0.36px;
		top: 3.36px;
	}
	#mobactive_calender_pagemobcale {
		fill: rgba(46,243,65,1);
	}
	.mobactive_calender_pagemobcale {
		position: absolute;
		overflow: visible;
		width: 11.2px;
		height: 11.2px;
		left: 234.44px;
		top: 29.08px;
	}
	#immediate_1_calender_mob {
		position: absolute;
		width: 22.4px;
		height: 22.4px;
		left: 201.96px;
		top: 21.8px;
		overflow: visible;
	}
	#arcane_toptaskcalender_mob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.arcane_toptaskcalender_mob {
		overflow: visible;
		position: absolute;
		width: 249.76px;
		height: 1px;
		left: 0px;
		top: 58.8px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}

	.mediaViewInfo {
		--web-view-name: calender page meeting mob;
		--web-view-id: calender_page_meeting_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: calender_page_meeting_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#calender_page_meeting_mob {
		position: absolute;
        width: 100%;
        height: calc(100% - 75px);
        top: 75px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: calender page meeting mob;
		--web-view-id: calender_page_meeting_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#calender_page_meeting_mob_all_ {
		position: absolute;
        width: 100%;
        height: calc(100% - 17px);
        left: -26px;
        top: 17px;
		overflow: visible;
	}
	#bdr_meeting_mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(78,187,87,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.bdr_meeting_mob {
		position: absolute;
		overflow: visible;
		width: calc(100% - 20px);
		height: 100%;
		left: 0px;
		top: 0px;
	}
	#right_menu_meeting_mob {
		position: absolute;
		width: 22.5px;
		height: 331.88px;
		right: -15px;
		top: 25.72px;
		overflow: visible;
	}
	#mobAlarms_lbl_meeting {
		-webkit-transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,332.5,168.2197) rotate(90deg);
		        transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,332.5,168.2197) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#Tasks_lbl_meet_mob {
		-webkit-transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,337.5,259.5396) rotate(90deg);
		        transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,337.5,259.5396) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#Events_lbl_calender_mob {
		-webkit-transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,333.5,459.1001) rotate(90deg);
		        transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,333.5,459.1001) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#selected_mob_meeting {
		position: absolute;
		width: 22.5px;
		height: 58.017px;
		left: 0px;
		top: 175.763px;
		overflow: visible;
	}
	#Meetings_lbl_headingcalendermo {
		-webkit-transform: translate(-340.5px, -332.483px) matrix(1,0,0,1,324.5,351.9829) rotate(90deg);
		        transform: translate(-340.5px, -332.483px) matrix(1,0,0,1,324.5,351.9829) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(78,187,87,1);
	}
	#mobLine_29_meeting {
		fill: transparent;
		stroke: rgba(78,187,87,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.mobLine_29_meeting {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 58px;
		left: 0px;
		top: 0.017px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#meeting_mob_allscroll {
		mix-blend-mode: normal;
		position: absolute;
        width: calc(100% - 45px);
        height: 100%;
        left: 26px;
		display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-content: flex-start;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#mobmeeting_grp_calender_page {
		position: relative;
		width: 249.76px;
		height: 56.8px;
		left: 30.64px;
        margin: 10px;
		top: 0px;
		overflow: visible;
	}
	#mobArcane_Series_lbl_calender {
		left: 8.16px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 240px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#mob1025pm_-_1200am_arcane {
		left: 9.16px;
		top: 23.52px;
		position: absolute;
		overflow: visible;
		width: 121px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#mobarcane_mode_meetincalender {
		fill: rgba(252,148,148,1);
	}
	.mobarcane_mode_meetincalender {
		position: absolute;
		overflow: visible;
		width: 3.36px;
		height: 16.8px;
		left: 0.36px;
		top: 3.36px;
	}
	#mobinacvtice_status_calender {
		fill: rgba(243,46,46,1);
	}
	.mobinacvtice_status_calender {
		position: absolute;
		overflow: visible;
		width: 11.2px;
		height: 11.2px;
		left: 235.561px;
		top: 26.88px;
	}
	#top_line_calendermeetmob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.top_line_calendermeetmob {
		overflow: visible;
		position: absolute;
		width: 249.76px;
		height: 1px;
		left: 0px;
		top: 56.8px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}

	.mediaViewInfo {
		--web-view-name: calender page event mob;
		--web-view-id: calender_page_event_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: calender_page_event_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#calender_page_event_mob {
		position: absolute;
        width: 100%;
        height: calc(100% - 75px);
        top: 75px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: calender page event mob;
		--web-view-id: calender_page_event_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#calender_page_event_mob_grp {
		position: absolute;
        width: 100%;
        height: calc(100% - 17px);
        left: -26px;
        top: 17px;
		overflow: visible;
	}
	#bdr_calender_page_event_mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(78,187,87,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.bdr_calender_page_event_mob {
		position: absolute;
		overflow: visible;
		width: calc(100% - 20px);
		height: 100%;
		left: 0px;
		top: 0px;
	}
	#menu_right_part_evnt_mob {
		position: absolute;
		width: 22.5px;
		height: 331.88px;
		right: -15px;
		top: 25.72px;
		overflow: visible;
	}
	#Alarms_lbl_calender_mob {
		-webkit-transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,332.5,168.2197) rotate(90deg);
		        transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,332.5,168.2197) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#Tasks_lbl_mob {
		-webkit-transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,337.5,259.5396) rotate(90deg);
		        transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,337.5,259.5396) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#Meetings_lbl_headingcalender_m {
		-webkit-transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,324.5,351.9829) rotate(90deg);
		        transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,324.5,351.9829) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#events_grp_calender_page_event {
		position: absolute;
		width: 22.5px;
		height: 40.1px;
		left: 0px;
		top: 291.78px;
		overflow: visible;
	}
	#Events_lbl_calendermob {
		-webkit-transform: translate(-340.5px, -448.5px) matrix(1,0,0,1,333.5,459.1001) rotate(90deg);
		        transform: translate(-340.5px, -448.5px) matrix(1,0,0,1,333.5,459.1001) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(78,187,87,1);
	}
	#Line_29event_mob {
		fill: transparent;
		stroke: rgba(78,187,87,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_29event_mob {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 40px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#calender_page_event_mob_scroll {
		mix-blend-mode: normal;
		position: absolute;
        width: calc(100% - 45px);
        height: 100%;
        left: 26px;
		display: flex;
	    flex-wrap: wrap;
	    flex-direction: row;
	    align-content: flex-start;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#events_page_calender {
		position: relative;
		width: 249.76px;
		height: 52.4px;
		left: 26.64px;
		top: 0px;
        margin: 10px;
		overflow: visible;
	}
	#Dragon_Ball_Super_lbl_mob {
		left: 9.16px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 237px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#KAMEHAMEHA_123 {
		left: 9.16px;
		top: 23.52px;
		position: absolute;
		overflow: visible;
		width: 113px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#dragon_ball_sup_ {
		fill: rgba(175,148,252,1);
	}
	.dragon_ball_sup_ {
		position: absolute;
		overflow: visible;
		width: 3.36px;
		height: 16.8px;
		left: 0.36px;
		top: 3.68px;
	}
	#active_events_calender_mob {
		fill: rgba(46,243,65,1);
	}
	.active_events_calender_mob {
		position: absolute;
		overflow: visible;
		width: 11.2px;
		height: 11.2px;
		left: 234.44px;
		top: 30.56px;
	}
	#arcane_eventline_calender_mob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.arcane_eventline_calender_mob {
		overflow: visible;
		position: absolute;
		width: 249.76px;
		height: 1px;
		left: 0px;
		top: 52.4px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}


	.mediaViewInfo {
		--web-view-name: right component;
		--web-view-id: right_component;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: right_component;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#right_component {
	    position: absolute;
        width: 309px;
        height: 770px;
        top: 75px;
        left: 100px;
        background-color: rgba(255,255,255,1);
        overflow: hidden;
        --web-view-name: right component;
        --web-view-id: right_component;
        --web-scale-on-resize: true;
        --web-enable-deep-linking: true
	}
	#right_side_grp_calender_mob {
		position: absolute;
		width: 296px;
		height: 767px;
		left: 13px;
		top: 0px;
		overflow: visible;
	}
	#calender_bdr_calendermob_cu {
		fill: url(#calender_bdr_calendermob_cu);
	}
	.calender_bdr_calendermob_cu {
		position: absolute;
		overflow: visible;
		width: 296px;
		height: 767px;
		left: 0px;
		top: 0px;
	}
	#Scroll_date_and_event_oof_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 290px;
		height: 429px;
		left: 3px;
		top: 325px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#so_much_to_grpmob {
		position: absolute;
		width: 276.611px;
		height: 936px;
		left: 0.195px;
		top: 5px;
		overflow: visible;
	}
	#n_am_number_calendemob {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 40px;
		overflow: visible;
	}
	#n__am_lbl {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_1am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_1am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.081px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_1am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_2am_number_calendemob {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_2_am_lbl {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_12am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_12am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.049px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_12_am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_am_number_calender_mob {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 80px;
		overflow: visible;
	}
	#n__am_lbl_c {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_2am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_2am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.11px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_2_am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#n_am_number_calendemob_c {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 160px;
		overflow: visible;
	}
	#n__am_lbl_da {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_4am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_4am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.171px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_4am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_am_number_calender_mob_dd {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 120px;
		overflow: visible;
	}
	#n__am_lbl_de {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_3am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_3am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.141px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_3am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#n_am_number_calender_mob_dh {
		position: absolute;
		width: 276.611px;
		height: 17.001px;
		left: 0px;
		top: 200px;
		overflow: visible;
	}
	#n__am_lbl_di {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_5_am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_5_am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.203px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_5am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#n_am_number_calender_mob_dl {
		position: absolute;
		width: 276.611px;
		height: 16.001px;
		left: 0px;
		top: 280px;
		overflow: visible;
	}
	#n__am_lbl_dm {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_7_am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_7_am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.264px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_7am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_am_number_calender_mob_dp {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 240px;
		overflow: visible;
	}
	#n__am_lbl_dq {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni__6_am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni__6_am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.232px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_6am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#n_am_number_calender_mob_dt {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 320px;
		overflow: visible;
	}
	#n__am_lbl_du {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_8am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_8am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.293px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_8am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#n_0am_number_calendermob {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 400px;
		overflow: visible;
	}
	#LineOni10am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni10am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.354px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_0_am_lbl {
		left: 18px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#add_btn_10am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_am_number_calender_mob_d {
		position: absolute;
		width: 276.611px;
		height: 17.001px;
		left: 0px;
		top: 360px;
		overflow: visible;
	}
	#LineOni_9am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_9am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.325px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n__am_lbl_d {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#add_btn_9am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#n_1am_number_calendermob {
		position: absolute;
		width: 276.611px;
		height: 17.001px;
		left: 0px;
		top: 440px;
		overflow: visible;
	}
	#LineOni_11am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_11am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.386px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_1_am_lbl {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#add_btn_11am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#n_pm_number_calender_mob {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 519px;
		overflow: visible;
	}
	#n__pm_lbl {
		left: 21px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_1pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_1pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 9.446px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_1_pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_2pm_number_calender_mob {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 480px;
		overflow: visible;
	}
	#LineOni_12am_ee {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_12am_ee {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.416px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_2_pm_lbl {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#add_btn_12_pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_pm_number_calender_mob_eh {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 560px;
		overflow: visible;
	}
	#n__pm_lbl_ei {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_2pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_2pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.477px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_2_pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_pm_number_calender_mob_el {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 639px;
		overflow: visible;
	}
	#n__pm_lbl_em {
		left: 21px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_4pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_4pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 9.538px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_4_pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_pm_number_calendermob {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 600px;
		overflow: visible;
	}
	#n__pm_lbl_eq {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_3am_er {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_3am_er {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.507px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_3_pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_pm_number_calender_mob_et {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 680px;
		overflow: visible;
	}
	#n__pm_lbl_eu {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_5pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_5pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.568px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_5pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_pm_number_calender_mob_ex {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 759px;
		overflow: visible;
	}
	#n__pm_lbl_ey {
		left: 21px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_7_pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_7_pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 9.629px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_7pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_pm_number_calender_mob_e {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 720px;
		overflow: visible;
	}
	#n__pm_lbl_e {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_6pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_6pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.599px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_6pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_pm_number_calender_mob_fa {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 800px;
		overflow: visible;
	}
	#n__pm_lbl_fa {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_8pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_8pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.66px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_8pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_pm_number_calendermob_e {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 840px;
		overflow: visible;
	}
	#LineOni_9pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_9pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.69px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n__pm_lbl_fb {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#add_btn_9pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#n_0pm_number_calender_mob {
		position: absolute;
		width: 276.611px;
		height: 16.001px;
		left: 0px;
		top: 881px;
		overflow: visible;
	}
	#LineOni_10pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_10pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 7.721px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_0_pm_lbl {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#add_btn_10pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_1pm_number_calendemob {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 920px;
		overflow: visible;
	}
	#LineOni_11pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_11pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.751px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_1_pm_lbl {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#add_btn_11pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#august_calender_mobside {
        position: absolute;
        width: 243px;
        height: 270px;
        left: 38px;
        top: 10px;
        overflow: visible;
	}
	#mobdates_bdr_calender_pc_side {
		fill: rgba(255,255,255,1);
	}
	.mobdates_bdr_calender_pc_side {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 239px;
		height: 233px;
		left: 0px;
		top: 37px;
	}
	#mobaug_bdr_calender_pc_side {
		fill: rgba(255,255,255,1);
	}
	.mobaug_bdr_calender_pc_side {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 239px;
		height: 46px;
		left: 0px;
		top: 0px;
	}
	#mobAugust_2023_lbl_calender_pc {
		left: 83px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#Pmobath_6951_left_calender_pc_ {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1.2000000476837158px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Pmobath_6951_left_calender_pc_ {
		overflow: visible;
		position: absolute;
		width: 5.02px;
		height: 10.713px;
		left: 9.807px;
		top: 9.416px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Pmobath_6952_rgh_calender_pc_s {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1.2000000476837158px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Pmobath_6952_rgh_calender_pc_s {
		overflow: visible;
		position: absolute;
		width: 5.02px;
		height: 10.713px;
		left: 208.362px;
		top: 9.416px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#mobMoncalender_pc_side {
		left: 1px;
		top: 39px;
		position: absolute;
		overflow: visible;
		width: 21px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#mobTuecalender_pc_side {
		left: 37px;
		top: 39px;
		position: absolute;
		overflow: visible;
		width: 17px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#mobWedcalender_pc_side {
		left: 67px;
		top: 39px;
		position: absolute;
		overflow: visible;
		width: 21px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#mobThucalender_pc_side {
		left: 102px;
		top: 39px;
		position: absolute;
		overflow: visible;
		width: 19px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#mobFricalender_pc_side {
		left: 137px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 13px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#mobSatcalender_pc_side {
		left: 169px;
		top: 39px;
		position: absolute;
		overflow: visible;
		width: 17px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#mobmobSuncalender_pc_side {
		left: 201px;
		top: 39px;
		position: absolute;
		overflow: visible;
		width: 19px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#mob31_calender_pc_side11 {
		left: 6px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(170,170,170,1);
	}
	#mob1_calender_pc_side11 {
		left: 44px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob2_calender_pc_side11 {
		left: 77px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob3_calender_pc_side11 {
		left: 109px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob4_calender_pc_side {
		left: 141px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob5mob_calender_pc_side {
		left: 173px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob6_calender_pc_side {
		left: 208px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob7_calender_pc_side {
		left: 9px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob8_calender_pc_side {
		left: 44px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob9calender_pc_side {
		left: 77px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob10_calender_pc_side {
		left: 106px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob11calender_pc_side {
		left: 139px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 9px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob12calender_pc_side {
		left: 172px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob13calender_pc_side {
		left: 205px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob14calender_pc_side {
		left: 6px;
		top: 156px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob15calender_pc_side {
		left: 40px;
		top: 156px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob16calender_pc_side {
		left: 73px;
		top: 156px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob17calender_pc_side {
		left: 106px;
		top: 156px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob18_calender_pc_side {
		left: 138px;
		top: 156px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob19_calender_pc_side {
		left: 172px;
		top: 156px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob20calender_pc_side {
		left: 205px;
		top: 156px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#n_mob1_calender_pc_side {
		left: 6px;
		top: 195px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob22_calender_pc_side {
		left: 40px;
		top: 195px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#n_mob3_calender_pc_side {
		left: 73px;
		top: 195px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#n_mob4_calender_pc_side {
		left: 106px;
		top: 195px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob25_calender_pc_side {
		left: 138px;
		top: 195px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob26_calender_pc_side {
		left: 172px;
		top: 195px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob27_calender_pc_side {
		left: 205px;
		top: 195px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob28calender_pc_side {
		left: 6px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob29calender_pc_side {
		left: 40px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob30_calender_pc_side {
		left: 73px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob31_calender_pc_side {
		left: 106px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob1_calender_pc_side {
		left: 141px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(170,170,170,1);
	}
	#n__calender_mobside {
		left: 173px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(170,170,170,1);
	}
	#n__calender_mobside_gw {
		left: 208px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(170,170,170,1);
	}
	#Icon_ionic-ios-arrow-back3123 {
		fill: rgba(0,0,0,1);
	}
	.Icon_ionic-ios-arrow-back3123 {
		overflow: visible;
		position: absolute;
		width: 13.503px;
		height: 23.619px;
		left: 10.95px;
		top: 10.691px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}

    
    @-webkit-keyframes fadeInRight {
        from {
          opacity: 0;
          -webkit-transform: translate3d(100%, 0, 0);
                  transform: translate3d(100%, 0, 0);
        }
      
        to {
          opacity: 1;
          -webkit-transform: translate3d(0, 0, 0);
                  transform: translate3d(0, 0, 0);
        }
      }

    
    @keyframes fadeInRight {
        from {
          opacity: 0;
          -webkit-transform: translate3d(100%, 0, 0);
                  transform: translate3d(100%, 0, 0);
        }
      
        to {
          opacity: 1;
          -webkit-transform: translate3d(0, 0, 0);
                  transform: translate3d(0, 0, 0);
        }
      }
      
      .fadeInRight {
        -webkit-animation-name: fadeInRight;
                animation-name: fadeInRight;
      }
.mediaViewInfo {
    --web-view-name: my connection-2;
    --web-view-id: my_connection-and-requests;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: my_connection-and-requests;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#my_connection-and-requests {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 60px);
    background-color: rgba(255,255,255,1);
    top:60px;
    left:120px;
    overflow: hidden;
    --web-view-name: my connection-2;
    --web-view-id: my_connection-and-requests;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#ConnectionAndRequestBg {
    opacity: 0.77;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#My_Connections_lbl {
    left: 83px;
    top: 40px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Line_1_border_cr {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_1_border_cr {
    overflow: visible;
    position: absolute;
    width: 308px;
    height: 1px;
    left: 83.5px;
    top: 69.5px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}

#My_Request_lbl_ {
    left: 296px;
    top: 40px;
    position: absolute;
    overflow: visible;
    width: 89px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}

#switch_cr_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.switch_cr_ {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 17px;
    left: 224px;
    top: 35px;
}
#offerings_pc {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 60px);
    top:60px;
    left:120px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: offerings;
    --web-view-id: offerings;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#cube_green_design_pc {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#My_Offerings_lbl_pc {
    left: 114px;
    top: 39px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Community_Offerings_lbl_pc {
    left: 263px;
    top: 39px;
    position: absolute;
    overflow: visible;
    width: 162px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
   


#switch_offandcom {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.switch_offandcom {
    position: absolute;
    overflow: visible;
     z-index: 1;
    width: 30px;
    height: 14px;
    left: 210px;
    top: 33px;
}

#Line_off_border_co {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_off_border_co {
    overflow: visible;
    position: absolute;
    width: 308px;
    height: 1px;
    left: 115px;
    top: 69.5px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
.mediaViewInfo {
    --web-view-name: suggestions;
    --web-view-id: suggestions;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: suggestions;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#suggestions_Pc_page {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 60px);
    top:60px;
    left:120px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: suggestions;
    --web-view-id: suggestions;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#suggestion_bg_image {
    opacity: 0.77;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: scroll;
}
#PC_Suggestions_heading {
    left: 62px;
    top: 40px;
    position: absolute;
    overflow: visible;
    width: 109px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#suggestions_Line_1 {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.suggestions_Line_1 {
    overflow: visible;
    position: absolute;
    width: 125px;
    height: 1px;
    left: 62.5px;
    top: 81.5px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#card_suggestion_pc {
    position: relative;
    width: 359px;
    height: 209px;
    left: 63px;
    top: 96px;
    overflow-y: scroll;
    overflow: visible;
}
#border_btn_ignore {
    fill: rgba(255,255,255,1);
}
.border_btn_ignore {
    position: absolute;
    overflow: visible;
    width: 19px;
    height: 23px;
    left: 335px;
    top: 4px;
}
#suggestions_card_ {
    fill: rgba(255,255,255,1);
}
.suggestions_card_ {
    position: absolute;
    overflow: visible;
    width: 359px;
    height: 209px;
    left: 0px;
    top: 0px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
#suggestions_pfp {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 16.5px;
    top: 23.5px;
    border-radius: 50%;
    overflow: hidden;
}
#suggestion_info_grp {
    position: absolute;
    width: 248px;
    height: 40px;
    left: 87px;
    top: 58.5px;
    overflow: visible;
}
#suggestion_profession {
    left: 27px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 77px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#suggestion_Connected_On {
  
    top: 30px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#suggestion_SD {
    left: 118px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 131px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#suggestion_11-02-2020 {
    left: 118px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#suggestion_Scroll_Group_1 {
    mix-blend-mode: normal;
    position: absolute;
    width: 258px;
    height: 49px;
    left: 87px;
    top: 107px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#scrolable_data_suggestions {
    left: 4px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 213px;
    height: 180px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#suggestions_name_box {
    fill: rgba(219,242,231,1);
}
.suggestions_name_box {
    position: absolute;
    overflow: visible;
    width: 220px;
    height: 30px;
    left: 114px;
    top: 26px;
}
#suggestions_pfp_name_pc {
    left: 125.237px;
    top: 30px;
    position: absolute;
    overflow: visible;
    width: 137px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    border-radius: 50%;
    color: rgba(112,112,112,1);
}
#suggestion_card_button_grp {
    position: absolute;
    width: 204px;
    height: 36px;
    left: 112px;
    top: 162px;
    overflow: visible;
}
#suggestion_round_1 {
    fill: rgba(219,242,231,1);
}
.suggestion_round_1 {
    position: absolute;
    overflow: visible;
    width: 36px;
    height: 36px;
    left: 0px;
    top: 0px;
    border-radius:  9px;
    cursor: pointer;
    background:  #e0e0e0;
    box-shadow:   2px 2px 5px #9f9f9f,-2px -2px 5px #ffffff;
}
#suggestion_link_img {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 5px;
    top: 5px;
    cursor: pointer;
    overflow: visible;
}
#suggestion_round_2 {
    fill: rgba(219,242,231,1);
}
.suggestion_round_2 {
    position: absolute;
    overflow: visible;
    width: 36px;
    height: 36px;
    left: 56px;
    top: 0px;
    border-radius:  9px;
    cursor: pointer;
    background:  #e0e0e0;
    box-shadow:   2px 2px 5px #9f9f9f,-2px -2px 5px #ffffff;
}
#suggestion_follow_img {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 61px;
    top: 5px;
    cursor: pointer;
    overflow: visible;
}
#suggestion_round_3 {
    fill: rgba(219,242,231,1);
}
.suggestion_round_3 {
    position: absolute;
    overflow: visible;
    width: 36px;
    height: 36px;
    left: 112px;
    top: 0px;
    border-radius:  9px;
    cursor: pointer;
    background:  #e0e0e0;
    box-shadow:   2px 2px 5px #9f9f9f,-2px -2px 5px #ffffff;
}
#suggestion_prohibition_img {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 117px;
    top: 5px;
    cursor: pointer;
    overflow: visible;
}
#suggestion_final_round {
    fill: rgba(219,242,231,1);
}
.suggestion_final_round {
    position: absolute;
    overflow: visible;
    width: 36px;
    height: 36px;
    left: 168px;
    top: 0px;
    cursor: pointer;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   2px 2px 5px #9f9f9f,-2px -2px 5px #ffffff;
}
#suggestion_user_img {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 173px;
    top: 5px;
    cursor: pointer;
    overflow: visible;
}
/* .rotate{
  border-radius: 4px;
  color: #FFFFFF;
  text-align: center;
  font-size: 30px;
  padding: 20px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  top: 50px;
  transform:rotate(90deg);
  -webkit-transform:rotate(90deg);
} */

/* .chat{
    text-align: "center";
    flex-direction:"column";
    height:"100px";
    width:100%;
    margin:"5px";
    border:"2px solid white";
    color:"black";
    overflow-y:"scroll";
    position:"relative";
    background-color:"white";
} */
.mediaViewInfo {
    --web-view-name: meeting room pc;
    --web-view-id: meeting_room_pc;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: meeting_room_pc;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#meeting_room_pc {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 60px);
    top:55px;
    left:120px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: meeting room pc;
    --web-view-id: meeting_room_pc;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#meeting_room_pc_bg_ {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#grp_vid_cam_2 {
    position: relative;
    width: auto;
    height: 151px;
    top: 0px;
    padding-left:9px;
    display: inline-block;
    overflow: visible;
}
#vid_cam_2 {
    position: absolute;
    width: 188px;
    height: 128px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#vid_cam_lbl_1 {
    left: 10px;
    top: 10px;
    z-index: 1;
    position: relative;
    overflow: visible;
    width: 189px;
    height: 22px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(5,5,5,1);
}
#vid_cam_lbl_2 {
    left: 0px;
    top: 126px;
    position: absolute;
    overflow: visible;
    width: 189px;
    height: 22px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(5,5,5,1);
}
#grp_vid_cam_3 {
    position: absolute;
    width: 188px;
    height: 151px;
    left: 235px;
    top: 29px;
    overflow: visible;
}
#vid_cam_3 {
    position: absolute;
    width: 188px;
    height: 128px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#vid_cam_lbl_3 {
    left: 0px;
    top: 129px;
    position: absolute;
    overflow: visible;
    width: 189px;
    height: 22px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(5,5,5,1);
}
#grp_vid_cam_4 {
    position: absolute;
    width: 188px;
    height: 151px;
    left: 446px;
    top: 29px;
    overflow: visible;
}
#vid_cam_4 {
    position: absolute;
    width: 188px;
    height: 128px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#vid_cam_lbl_4 {
    left: 0px;
    top: 129px;
    position: absolute;
    overflow: visible;
    width: 189px;
    height: 22px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(5,5,5,1);
}
#grp_vid_cam_5 {
    position: absolute;
    width: 188px;
    height: 151px;
    left: 657px;
    top: 29px;
    overflow: visible;
}
#vid_cam_lbl_5 {
    left: 0px;
    top: 129px;
    position: absolute;
    overflow: visible;
    width: 189px;
    height: 22px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(5,5,5,1);
}
#vid_cam_5 {
    position: absolute;
    width: 188px;
    height: 128px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#grp_chat_1_pc {
    position: absolute;
    width: 310px;
    height: 350px;
    right: 50px;
    top: 228px;
    overflow: visible;
}
#chat_pc_bg {
    position: absolute;
    width: 310px;
    height: 350px;
    left: 0px;
    top: 0px;
    overflow: auto;
}
#chat_msg_input_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chat_msg_input_ {
    position: absolute;
    overflow: visible;
    width: 288px;
    height: 38px;
    left: 11px;
    top: 304px;
    font-size: 17px;
    border-radius: 50px;
    padding-left: 15px;
    font-style: Segeo UI;
    box-shadow: inset 10px 10px 20px #cacaca,inset -10px -10px 20px #ffffff;
    border: 1px solid;
}
#user_1_txt_grp {
    position: absolute;
    width: 250.123px;
    height: 83.328px;
    left: 12px;
    top: 12.336px;
    overflow: visible;
}
#chat_1_design_ {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chat_1_design_ {
    overflow: visible;
    position: absolute;
    width: 215.955px;
    height: 46.469px;
    left: 35.877px;
    top: 16.655px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#chat_1_time {
    left: 49.504px;
    top: 63.328px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    color: rgba(77,242,94,1);
}
#chat_1_username {
    left: 82.504px;
    top: 63.328px;
    position: absolute;
    overflow: visible;
    width: 118px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(77,242,94,1);
}
#chat_1_input {
    left: 51.504px;
    top: 29.328px;
    position: absolute;
    overflow: visible;
    width: 188px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#online_user_pf_1_img {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#user_2_text_grp {
    position: absolute;
    width: 255.133px;
    height: 76.25px;
    left: 42px;
    top: 91px;
    overflow: visible;
}
#chat_2_design {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chat_2_design {
    overflow: visible;
    position: absolute;
    width: 220.985px;
    height: 46.469px;
    left: 0px;
    top: 11.562px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#chat_2_time {
    left: 3.998px;
    top: 56.25px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    color: rgba(255,87,87,1);
}
#chat_2_username {
    left: 37.64px;
    top: 56.25px;
    position: absolute;
    overflow: visible;
    width: 121px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,87,87,1);
}
#chat_2_txt_input {
    left: 5.816px;
    top: 24.25px;
    position: absolute;
    overflow: visible;
    width: 193px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#online_user_pf_2_img {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 225.133px;
    top: 0px;
    overflow: visible;
}
#send-message {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 272px;
    top: 313px;
    overflow: visible;
}
#grp_cam_1_call_acc_dec {
    position: absolute;
    width: 821px;
    height: 414px;
    left: 24px;
    top: 200px;
    overflow: visible;
}
#cam_1_grp_with_btn {
    position: absolute;
    width: calc(100% - 450px);
    height: calc(100% - 300px);
    left: 45px;
    top: 160px;
    text-align: center;
    overflow: visible;
}
#cam_2_grp_with_btn {
    position: absolute;
    width: calc(100% - 450px);
    height: calc(100% - 300px);
    left: 45px;
    top: 160px;
    text-align: center;
    overflow: visible;
}
#vid_cam_1 {
    position: absolute;
    width: 100%; 
    height: 100%;
    left: 0px;
    top: 0px;
    padding: 10px;
    border: 2px solid;
    background-color: #ffffff;
    overflow: visible;
}
/* #group_mic_pc:hover {
    left: 20%;
    top: calc(100% - 62px);
} */
#group_mic_pc {
    position: relative;
    width: 60px;
    height: 40px;
    /* left: 20%;
    top: calc(100% - 60px); */
    overflow: hidden;
    border: 2px solid;
    transition: all .3s ease-out;
}
#mic_border_pc {
    fill: rgba(255,255,255,1);
}
.mic_border_pc {
    position: absolute;
    overflow: visible;
    width: 60px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#micro_on {
    position: absolute;
    width: 26px;
    height: 27px;
    left: 17px;
    top: 5px;
    overflow: visible;
}
/* #group_cam_pc:hover {
    left: 307px;
    top: 357px;
} */
#group_cam_pc {
    position: relative;
    width: 60px;
    height: 40px;
    /* left: 303px;
    top: 352px; */
    overflow: visible;
    transition: all .3s ease-out;
}
#cam_border_pc {
    fill: rgba(255,255,255,1);
}
.cam_border_pc {
    position: absolute;
    overflow: hidden;
    width: 60px;
    height: 40px;
    left: 0px;
    top: 0px;
    border: 2px solid;
}
#cam_img_pc {
    position: absolute;
    width: 23.451px;
    height: 23.451px;
    left: 18.398px;
    top: 8.246px;
    overflow: visible;
}
/* #grp_dis_con_pc:hover {
    left: 386px;
    top: 357px;
} */
#grp_dis_con_pc {
    position: relative;
    width: 60px;
    height: 40px;
    /* left: 381px;
    top: 352px; */
    overflow: visible;
    transition: all .3s ease-out;
}
#cutcall_bdr_pc {
    fill: rgba(255,255,255,1);
}
.cutcall_bdr_pc {
    position: absolute;
    overflow: hidden;
    width: 60px;
    height: 40px;
    left: 0px;
    top: 0px;
    border: 2px solid;
}
#cutcall_img_pc {
    position: absolute;
    width: 23.451px;
    height: 23.451px;
    left: 18.567px;
    top: 8.246px;
    background-color: red;
    overflow: visible;
}
/* #grp_rec_start_pc_1:hover {
    left: 461px;
    top: 357px;
} */
#grp_rec_start_pc_1 {
    position: relative;
    width: 60px;
    height: 40px;
    /* left: 456px;
    top: 352px; */
    overflow: hidden;
    border: 2px solid;
    transition: all .3s ease-out;
}
#rec_brd_pc_1 {
    fill: rgba(255,255,255,1);
}
.rec_brd_pc_1 {
    position: absolute;
    overflow: visible;
    width: 60px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#rec_img_pc_1 {
    position: absolute;
    width: 23.451px;
    height: 23.451px;
    left: 18.567px;
    top: 8.246px;
    overflow: visible;
}
/* #grp_rec_stop_grp1:hover {
    left: 536px;
    top: 357px;
} */
#grp_rec_stop_grp1 {
    position: relative;
    width: 60px;
    height: 40px;
    /* left: 531px;
    top: 352px; */
    overflow: hidden;
    border: 2px solid;
    transition: all .3s ease-out;
}
#rec_stop_img_1 {
    fill: rgba(255,255,255,1);
}
.rec_stop_img_1 {
    position: absolute;
    overflow: visible;
    width: 60px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#rec_stop_img_1_cq {
    position: absolute;
    width: 23.451px;
    height: 23.451px;
    left: 18.567px;
    top: 8.246px;
    overflow: visible;
}
/* #grp_call_and_dec_ {
    position: absolute;
    width: 331px;
    height: 42px;
    left: 245px;
    top: 10px;
    overflow: visible;
}
#bg_design_callpop {
    position: absolute;
    width: 331px;
    height: 42px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#lbl_name_callpop {
    left: 10px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#btn_grp_popcall {
    position: absolute;
    width: 53px;
    height: 25px;
    left: 192px;
    top: 9px;
    overflow: visible;
    border-radius:  10px;
}
#rec_accept_popcall {
    opacity: 0.5;
    fill: rgba(188,226,179,1);
}
.rec_accept_popcall {
    position: absolute;
    overflow: visible;
    width: 53px;
    height: 25px;
    left: 0px;
    top: 0px;
}
#accept_call_popcall {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 17px;
    top: 3px;
    overflow: visible;
}
#btn_grp_decline_popcall {
    position: absolute;
    width: 53px;
    height: 26px;
    left: 261px;
    top: 9px;
    overflow: visible;
    border-radius:  10px;
}
#decline_rec_popcall {
    opacity: 0.5;
    fill: rgba(188,226,179,1);
}
.decline_rec_popcall {
    position: absolute;
    overflow: visible;
    width: 53px;
    height: 25px;
    left: 0px;
    top: 1px;
}
#decline_img_popcall {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 14px;
    top: 0px;
    overflow: visible;
} */
::-webkit-scrollbar {
width: 10px;
}


::-webkit-scrollbar-track {
background: #888;
}


::-webkit-scrollbar-thumb {
background: #DBF2E7;
}


::-webkit-scrollbar-thumb:hover {
background: #DBF2E7;
}


#online_user_grp_card {
    position: absolute;
    width: 310px;
    height: 214px;
    right: 50px;
    top: 5px;
    overflow: visible;
}
#online_user_bg_pc_img {
    position: absolute;
    width: 310px;
    height: 214px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_online_user_border {
    position: absolute;
    width: 267px;
    height: 33px;
    left: 22px;
    top: 7px;
    overflow: visible;
}
#Online_user_bdr {
    fill: rgba(219,242,231,1);
}
.Online_user_bdr {
    position: absolute;
    overflow: visible;
    width: 267px;
    height: 33px;
    left: 0px;
    top: 0px;
}
#online_user_lbl_pc {
    left: 86px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 96px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(5,5,5,1);
}
#online_user_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 302px;
    height: 155px;
    left: 8px;
    top: 44px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#online_user_per_1_grp {
    position: absolute;
    width: 62px;
    height: 82px;
    left: 9px;
    top: 7px;
    cursor: pointer;
    overflow: visible;
}
#user_1_img_tag {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 6px;
    top: 0px;
    overflow: visible;
}
#btn_user_grp {
    position: absolute;
    width: 62px;
    height: 21px;
    left: 0px;
    top: 61px;
    overflow: visible;
    border-radius: 10px;
}
#user_bdr_btn_call {
    fill: rgba(219,242,231,1);
}
.user_bdr_btn_call {
    position: absolute;
    overflow: visible;
    width: 62px;
    height: 21px;
    left: 0px;
    top: 0px;
}
#user_lbl_call {
    left: 0px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    cursor: pointer;
    color: rgba(5,5,5,1);
}


/* Chats New css */
#online_user_extended_pc_new {
    position: absolute;
    width: 310px;
    height: calc(100% - 160px);
    right: 50px;
    top: 29px;
    overflow: visible;
}
#online_user_bg_pc_img_new {
    position: absolute;
    width: 310px;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Online_user_bdr_new {
    fill: rgba(219,242,231,1);
}
.Online_user_bdr_new {
    position: absolute;
    overflow: visible;
    width: 267px;
    height: 33px;
    left: 22px;
    top: 7px;
}
#online_user_lbl_pc_new {
    left: 108px;
    top: 13px;
    position: absolute;
    overflow: visible;
    width: 96px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(5,5,5,1);
}
#online_user_scroll_new {
    mix-blend-mode: normal;
    position: absolute;
    width: 302px;
    height: calc(100% - 50px);
    left: 8px;
    top: 44px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#person_1_bdr_user_new {
    position: relative;
    width: 183px;
    height: 50px;
    left: 47px;
    top: 19px;
    overflow: visible;
}
#user_1_img_tag_new {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 0px;
    top: 0px;
    white-space: nowrap;
    overflow: visible;
}
#btn_grp_user_online_new {
    position: relative;
    width: 62px;
    height: 21px;
    left: 88px;
    top: 29px;
    cursor: pointer;
    overflow: visible;
}
#user_bdr_btn_call_new {
    fill: rgba(219,242,231,1);
}
.user_bdr_btn_call_new {
    position: absolute;
    overflow: visible;
    width: 62px;
    height: 21px;
    left: 0px;
    top: 0px;
}
#user_lbl_call_new {
    left: 21px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    cursor: pointer;
    color: rgba(5,5,5,1);
}
#user_lbl_call_cv_new {
    left: -20px;
    top: -30px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}

/* Flying Meeting PopUp */

#meeting_fly_pop_up_grp {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 18px;
    top: calc(100% - 120px);
    overflow: visible;
    border-radius: 50px;
}
#meeting_fly_pop_up_rec {
    fill: rgba(219,242,231,1);
    stroke: rgba(135,130,255,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.meeting_fly_pop_up_rec {
    position: absolute;
    overflow: visible;
    width: 60px;
    height: 60px;
    left: 0px;
    top: 0px;
    border-radius:  30px;
    background:  #e0e0e0;
    box-shadow:   2px 2px 5px #9f9f9f,-2px -2px 5px #ffffff;
}
#meeting_fly_pop_up {
    position: absolute;
    width: 39px;
    height: 39px;
    left: 11px;
    top: 11px;
    overflow: visible;
}
#meetings_scroll_grp {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 84px;
    top: calc(100% - 120px);
    cursor: pointer;
    overflow-x: scroll;
    overflow-y: hidden;
}
#Group_indiv_meetings_pt {
    position: absolute;
    width: 165px;
    height: 113px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#meeting_border_om {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.meeting_border_om {
    position: absolute;
    overflow: visible;
    width: 165px;
    height: 113px;
    left: 0px;
    top: 0px;
}
#Web_Development_lbl_val_om {
    left: 21px;
    top: 9px;
    position: absolute;
    overflow: visible;
    width: 124px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
    text-decoration: underline;
}
#Cancelled_lbl_val_om {
    left: 52px;
    top: 36px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#date_lbl_val_om {
    left: 51px;
    top: 59px;
    position: absolute;
    overflow: visible;
    width: 94px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#tine_lbl_val_om {
    left: 52px;
    top: 84px;
    position: absolute;
    overflow: visible;
    width: 45px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#date_img_pop_om {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 21px;
    top: 59px;
    overflow: visible;
}
#status_img_pop_om {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 21px;
    top: 35px;
    overflow: visible;
}
#time_img_pop_om {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 21px;
    top: 83px;
    overflow: visible;
}
.mediaViewInfo {
    --web-view-name: pop caller;
    --web-view-id: pop_caller;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pop_caller;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pop_caller {
    position: relative;
    width: 331px;
    height: 42px;
    top: 230px;
    left:430px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: pop caller;
    --web-view-id: pop_caller;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bg_design_callpop {
    position: absolute;
    width: 331px;
    height: 42px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#lbl_name_callpop {
    left: 10px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#btn_grp_popcall {
    position: absolute;
    width: 53px;
    height: 25px;
    left: 192px;
    top: 9px;
    overflow: visible;
    border-radius:  10px;
}
#rec_accept_popcall {
    opacity: 0.5;
    fill: rgba(188,226,179,1);
}
.rec_accept_popcall {
    position: absolute;
    overflow: visible;
    width: 53px;
    height: 25px;
    left: 0px;
    top: 0px;
}
#accept_call_popcall {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 17px;
    top: 3px;
    overflow: visible;
}
#btn_grp_decline_popcall {
    position: absolute;
    width: 53px;
    height: 26px;
    left: 261px;
    top: 9px;
    overflow: visible;
    border-radius:  10px;
}
#decline_rec_popcall {
    opacity: 0.5;
    fill: rgba(188,226,179,1);
}
.decline_rec_popcall {
    position: absolute;
    overflow: visible;
    width: 53px;
    height: 25px;
    left: 0px;
    top: 1px;
}
#decline_img_popcall {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 14px;
    top: 0px;
    overflow: visible;
}

	.mediaViewInfo {
		--web-view-name: market pc;
		--web-view-id: market_pc;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: market_pc;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#market_pc {
		position: absolute;
		width: 1237px;
		height: 2254px;
        top:60px;
        left:120px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: market pc;
		--web-view-id: market_pc;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#usama-akram-kP6knT7tjn4-unspla_ {
		fill: url(#usama-akram-kP6knT7tjn4-unspla_);
	}
	.usama-akram-kP6knT7tjn4-unspla_ {
		position: absolute;
		overflow: visible;
		width: 1137px;
		height: 285px;
		left: 50px;
		top: 408px;
	}
	#usama-akram-kP6knT7tjn4-unspla_ba {
		fill: rgba(225,187,64,1);
	}
	.usama-akram-kP6knT7tjn4-unspla_ba {
		position: absolute;
		overflow: visible;
		width: 1137px;
		height: 133px;
		left: 50px;
		top: 187px;
	}
	#Rectangle_2 {
		fill: rgba(255,255,255,1);
	}
	.Rectangle_2 {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 1145px;
		height: 125px;
		left: 50px;
		top: 40px;
	}
	#Group_6 {
		position: absolute;
		width: 80px;
		height: 73px;
		left: 110px;
		top: 66px;
		overflow: visible;
	}
	#Electronics {
		left: 0px;
		top: 51px;
		position: absolute;
		overflow: visible;
		width: 81px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#electronics {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 15px;
		top: 0px;
		overflow: visible;
	}
	#Group_5 {
		position: absolute;
		width: 58px;
		height: 73px;
		left: 324px;
		top: 66px;
		overflow: visible;
	}
	#Grocery {
		left: 0px;
		top: 51px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#shopping-bag {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 4px;
		top: 0px;
		overflow: visible;
	}
	#Group_4 {
		position: absolute;
		width: 50px;
		height: 73px;
		left: 514px;
		top: 66px;
		overflow: visible;
	}
	#Home {
		left: 2px;
		top: 51px;
		position: absolute;
		overflow: visible;
		width: 47px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#furniture {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_3 {
		position: absolute;
		width: 50px;
		height: 73px;
		left: 688px;
		top: 66px;
		overflow: visible;
	}
	#Toys {
		left: 8px;
		top: 51px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#blocks {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_2 {
		position: absolute;
		width: 52px;
		height: 73px;
		left: 863px;
		top: 66px;
		overflow: visible;
	}
	#Mobile {
		left: 0px;
		top: 51px;
		position: absolute;
		overflow: visible;
		width: 53px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#smartphone {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 1px;
		top: 0px;
		overflow: visible;
	}
	#Group_1 {
		position: absolute;
		width: 77px;
		height: 73px;
		left: 1049px;
		top: 66px;
		overflow: visible;
	}
	#Top_Offers {
		left: 0px;
		top: 51px;
		position: absolute;
		overflow: visible;
		width: 78px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#shopping-basket {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 14px;
		top: 0px;
		overflow: visible;
	}
	#Group_122 {
		position: absolute;
		width: 200px;
		height: 244px;
		left: 665px;
		top: 678px;
		overflow: visible;
	}
	#n_2188678_4907157 {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 244px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Limited_Offer {
		left: 53px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#c-d-x-PDX_a_82obo-unsplash {
		position: absolute;
		width: 162px;
		height: 80px;
		left: 19px;
		top: 34px;
		overflow: visible;
	}
	#rachit-tank-2cFZ_FB08UM-unspla {
		position: absolute;
		width: 160px;
		height: 80px;
		left: 21px;
		top: 139px;
		overflow: visible;
	}
	#Headphones_100_off {
		left: 21px;
		top: 116px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Apple_Watch_90_off {
		left: 21px;
		top: 221px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Group_19 {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 165px;
		top: 118px;
		overflow: visible;
	}
	#previous_1 {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_20 {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 165px;
		top: 222px;
		overflow: visible;
	}
	#previous_1_by {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_121 {
		position: absolute;
		width: 200px;
		height: 244px;
		left: 384px;
		top: 678px;
		overflow: visible;
	}
	#n_2188678_4907157_b {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 244px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Top_Products {
		left: 54px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#eniko-kis-KsLPTsYaqIQ-unsplash {
		position: absolute;
		width: 162px;
		height: 80px;
		left: 19px;
		top: 34px;
		overflow: visible;
	}
	#martin-katler-7wCxlBfGMdk-unsp {
		position: absolute;
		width: 160px;
		height: 80px;
		left: 21px;
		top: 139px;
		overflow: visible;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#Camera {
		left: 21px;
		top: 115px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#RTX_4090 {
		left: 21px;
		top: 220px;
		position: absolute;
		overflow: visible;
		width: 46px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Group_21 {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 165px;
		top: 118px;
		overflow: visible;
	}
	#previous_1_b {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_22 {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 165px;
		top: 222px;
		overflow: visible;
	}
	#previous_1_ca {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_123 {
		position: absolute;
		width: 200px;
		height: 244px;
		left: 945px;
		top: 678px;
		overflow: visible;
	}
	#n_2188678_4907157_cb {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 244px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#New_Launch {
		left: 57px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 87px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#tamara-bitter-JIrjoWIgFAs-unsp {
		position: absolute;
		width: 162px;
		height: 80px;
		left: 19px;
		top: 34px;
		overflow: visible;
	}
	#ales-nesetril-Im7lZjxeLhg-unsp {
		position: absolute;
		width: 160px;
		height: 80px;
		left: 21px;
		top: 139px;
		overflow: visible;
	}
	#PS_5_Delux_Edition {
		left: 21px;
		top: 114px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#Apple_MacBook {
		left: 21px;
		top: 219px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#Group_27 {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 165px;
		top: 118px;
		overflow: visible;
	}
	#previous_1_ci {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_28 {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 165px;
		top: 223px;
		overflow: visible;
	}
	#previous_1_ck {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_120 {
		position: absolute;
		width: 200px;
		height: 244px;
		left: 103px;
		top: 678px;
		overflow: visible;
	}
	#n_2188678_4907157_cm {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 244px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#domino-164_6wVEHfI-unsplash {
		position: absolute;
		width: 72px;
		height: 80px;
		left: 21px;
		top: 34px;
		overflow: visible;
	}
	#martin-katler-7wCxlBfGMdk-unsp_co {
		position: absolute;
		width: 72px;
		height: 80px;
		left: 108px;
		top: 34px;
		overflow: visible;
	}
	#paul-gaudriault-a-QH9MAAVNI-un {
		position: absolute;
		width: 72px;
		height: 80px;
		left: 21px;
		top: 139px;
		overflow: visible;
	}
	#My_project {
		position: absolute;
		width: 72px;
		height: 80px;
		left: 108px;
		top: 139px;
		overflow: visible;
	}
	#Recent_Visits {
		left: 56px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#RTX_4090_cs {
		left: 108px;
		top: 115px;
		position: absolute;
		overflow: visible;
		width: 46px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Canvas {
		left: 21px;
		top: 220px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Construction {
		left: 108px;
		top: 220px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Sport_Shoe {
		left: 21px;
		top: 115px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#previous_1_cw {
		position: absolute;
		width: 12px;
		height: 12px;
		left: 81px;
		top: 117px;
		overflow: visible;
	}
	#previous_1_cx {
		position: absolute;
		width: 12px;
		height: 12px;
		left: 170px;
		top: 117px;
		overflow: visible;
	}
	#previous_1_cy {
		position: absolute;
		width: 12px;
		height: 12px;
		left: 81px;
		top: 222px;
		overflow: visible;
	}
	#previous_1_cz {
		position: absolute;
		width: 12px;
		height: 12px;
		left: 170px;
		top: 222px;
		overflow: visible;
	}
	#AD_AREA {
		left: 403px;
		top: 187px;
		position: absolute;
		overflow: visible;
		width: 433px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 100px;
		color: rgba(255,255,255,1);
	}
	#My_project_3 {
		position: absolute;
		width: 405.092px;
		height: 437.322px;
		left: 454.437px;
		top: 293.548px;
		overflow: visible;
	}
	#Rectangle_7 {
		fill: rgba(0,0,0,1);
	}
	.Rectangle_7 {
		position: absolute;
		overflow: visible;
		width: 28px;
		height: 35px;
		left: 1137px;
		top: 436px;
	}
	#Rectangle_8 {
		fill: rgba(255,255,255,1);
	}
	.Rectangle_8 {
		position: absolute;
		overflow: visible;
		width: 28px;
		height: 35px;
		left: 1107px;
		top: 436px;
	}
	#Group_29 {
		position: absolute;
		width: 64px;
		height: 64px;
		left: 406px;
		top: 451.209px;
		overflow: visible;
	}
	#nike {
		position: absolute;
		width: 64px;
		height: 64px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SUP {
		-webkit-transform: translate(-476px, -437.209px) matrix(1,0,0,1,495,446) rotate(-14deg);
		        transform: translate(-476px, -437.209px) matrix(1,0,0,1,495,446) rotate(-14deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 29px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_1 {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_1 {
		overflow: visible;
		position: absolute;
		width: 3px;
		height: 39px;
		left: 484.5px;
		top: 461.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Superro_Air {
		left: 499px;
		top: 469.5px;
		position: absolute;
		overflow: visible;
		width: 78px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#shoe {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 827px;
		top: 418px;
		overflow: visible;
	}
	#shoe_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 872px;
		top: 418px;
		overflow: visible;
	}
	#shoe_2 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 917px;
		top: 418px;
		overflow: visible;
	}
	#Group_30 {
		position: absolute;
		width: 85px;
		height: 33px;
		left: 414px;
		top: 540px;
		overflow: visible;
	}
	#Rectangle_9 {
		fill: rgba(255,255,255,1);
	}
	.Rectangle_9 {
		position: absolute;
		overflow: visible;
		width: 85px;
		height: 33px;
		left: 0px;
		top: 0px;
	}
	#Buy_Now {
		left: 11px;
		top: 6.5px;
		position: absolute;
		overflow: visible;
		width: 65px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Level_Up_Your_Style {
		left: 88px;
		top: 436px;
		position: absolute;
		overflow: visible;
		width: 237px;
		height: 134px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		color: rgba(0,0,0,1);
	}
	#right-arrow_3 {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 1141px;
		top: 444px;
		overflow: visible;
	}
	#right-arrow_2 {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 1111px;
		top: 444px;
		overflow: visible;
	}
	#We_provide_best_customer_exper {
		left: 103px;
		top: 982px;
		position: absolute;
		overflow: visible;
		width: 297px;
		height: 78px;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#Line_2 {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_2 {
		overflow: visible;
		position: absolute;
		width: 4.038px;
		height: 81.038px;
		left: 734.5px;
		top: 982.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#We_ensure_our_customers_have_t {
		left: 749px;
		top: 998px;
		position: absolute;
		overflow: visible;
		width: 397px;
		height: 46px;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Card_03_market_pc {
		position: absolute;
		width: 260px;
		height: 236px;
		left: 905px;
		top: 1114px;
		overflow: visible;
	}
	#Moderate_children_at_of_outwei {
		left: 0px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 47px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#Track_Property {
		left: 65.5px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 131px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#Icon {
		position: absolute;
		width: 90px;
		height: 90px;
		left: 85px;
		top: 0px;
		overflow: visible;
	}
	#Fill {
		position: absolute;
		width: 90px;
		height: 90px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Fill_du {
		fill: rgba(0,0,0,0);
	}
	.Fill_du {
		-webkit-filter: drop-shadow(0px 100px 80px rgba(242, 184, 236, 0.071));
		        filter: drop-shadow(0px 100px 80px rgba(242, 184, 236, 0.071));
		position: absolute;
		overflow: visible;
		width: 330px;
		height: 330px;
		left: 0px;
		top: 0px;
	}
	#Fill_du {
		fill: rgba(0,0,0,0);
	}
	.Fill_du {
		-webkit-filter: drop-shadow(0px 100px 80px rgba(242, 184, 236, 0.071));
		        filter: drop-shadow(0px 100px 80px rgba(242, 184, 236, 0.071));
		position: absolute;
		overflow: visible;
		width: 330px;
		height: 330px;
		left: 0px;
		top: 0px;
	}
	#Fill_du {
		fill: rgba(0,0,0,0);
	}
	.Fill_du {
		-webkit-filter: drop-shadow(0px 100px 80px rgba(242, 184, 236, 0.071));
		        filter: drop-shadow(0px 100px 80px rgba(242, 184, 236, 0.071));
		position: absolute;
		overflow: visible;
		width: 330px;
		height: 330px;
		left: 0px;
		top: 0px;
	}
	#Fill_du {
		fill: rgba(0,0,0,0);
	}
	.Fill_du {
		-webkit-filter: drop-shadow(0px 100px 80px rgba(242, 184, 236, 0.071));
		        filter: drop-shadow(0px 100px 80px rgba(242, 184, 236, 0.071));
		position: absolute;
		overflow: visible;
		width: 330px;
		height: 330px;
		left: 0px;
		top: 0px;
	}
	#Fill_du {
		fill: rgba(0,0,0,0);
	}
	.Fill_du {
		-webkit-filter: drop-shadow(0px 100px 80px rgba(242, 184, 236, 0.071));
		        filter: drop-shadow(0px 100px 80px rgba(242, 184, 236, 0.071));
		position: absolute;
		overflow: visible;
		width: 330px;
		height: 330px;
		left: 0px;
		top: 0px;
	}
	#Fill_dv {
		fill: rgba(242,184,236,1);
	}
	.Fill_dv {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(242, 184, 236, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(242, 184, 236, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.444px;
		left: 0px;
		top: 0px;
	}
	#Icon_dw {
		fill: rgba(255,255,255,1);
	}
	.Icon_dw {
		overflow: visible;
		position: absolute;
		width: 21px;
		height: 25px;
		left: 35px;
		top: 33px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Card_02_market_pc {
		position: absolute;
		width: 260px;
		height: 236px;
		left: 497px;
		top: 1114px;
		overflow: visible;
	}
	#Conveying_or_northward_offendi {
		left: 0px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 47px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#Instant_Valuation {
		left: 28px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 205px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#Fill_d {
		fill: rgba(77,223,253,1);
	}
	.Fill_d {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(77, 223, 253, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(77, 223, 253, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.444px;
		left: 85px;
		top: 0px;
	}
	#Icon_d {
		fill: rgba(255,255,255,1);
	}
	.Icon_d {
		overflow: visible;
		position: absolute;
		width: 25px;
		height: 12.324px;
		left: 118px;
		top: 45.675px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_ea {
		fill: rgba(255,255,255,1);
	}
	.Icon_ea {
		overflow: visible;
		position: absolute;
		width: 8.532px;
		height: 8.305px;
		left: 128.417px;
		top: 37.918px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_eb {
		fill: rgba(255,255,255,1);
	}
	.Icon_eb {
		overflow: visible;
		position: absolute;
		width: 6.241px;
		height: 6.204px;
		left: 123.026px;
		top: 34.464px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_ec {
		fill: rgba(255,255,255,1);
	}
	.Icon_ec {
		overflow: visible;
		position: absolute;
		width: 5.365px;
		height: 5.327px;
		left: 133.91px;
		top: 33px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Card_01_market_pc {
		position: absolute;
		width: 260px;
		height: 236px;
		left: 88px;
		top: 1114px;
		overflow: visible;
	}
	#Wonder_twenty_hunted_and_put_i {
		left: 0px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 47px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#Research_Suburbs_market_pc {
		left: 51px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 159px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#Fill_bdr_market_pc {
		fill: rgba(150,114,255,1);
	}
	.Fill_bdr_market_pc {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(150, 114, 255, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(150, 114, 255, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.444px;
		left: 85px;
		top: 0px;
	}
	#Icon__2_market_pc {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.Icon__2_market_pc {
		overflow: visible;
		position: absolute;
		width: 8.742px;
		height: 8.742px;
		left: 135.672px;
		top: 50.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_1_market_pc {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.Icon_1_market_pc {
		overflow: visible;
		position: absolute;
		width: 22.689px;
		height: 22.689px;
		left: 118px;
		top: 33px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#line_design_1_grp {
		position: absolute;
		width: 210px;
		height: 11px;
		left: 305px;
		top: 1155px;
		overflow: visible;
	}
	#line_centre_2_market_pc {
		opacity: 0.2;
		fill: rgba(0,0,0,0);
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5 10;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_centre_2_market_pc {
		overflow: visible;
		position: absolute;
		width: 191.5px;
		height: 3px;
		left: 9.237px;
		top: 5.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#line_bal2_market_pc {
		fill: rgba(77,223,253,1);
	}
	.line_bal2_market_pc {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 199px;
		top: 0px;
	}
	#line_bal1_market_pc {
		fill: rgba(150,114,255,1);
	}
	.line_bal1_market_pc {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 0px;
		top: 0px;
	}
	#line_design_2_grp {
		position: absolute;
		width: 210px;
		height: 11px;
		left: 722px;
		top: 1155px;
		overflow: visible;
	}
	#centre_line_2_market_pc {
		opacity: 0.2;
		fill: rgba(0,0,0,0);
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5 10;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.centre_line_2_market_pc {
		overflow: visible;
		position: absolute;
		width: 191.5px;
		height: 1px;
		-webkit-transform: translate(0.176px, 0px) matrix(1,0,0,1,9.5,5.5) rotate(180deg);
		        transform: translate(0.176px, 0px) matrix(1,0,0,1,9.5,5.5) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#circle_1_market_pc {
		fill: rgba(77,223,253,1);
	}
	.circle_1_market_pc {
		width: 11px;
		height: 11px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(0.176px, 0px) matrix(1,0,0,1,-0.1764,0) rotate(180deg);
		        transform: translate(0.176px, 0px) matrix(1,0,0,1,-0.1764,0) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#circle_2_market_pc {
		fill: rgba(150,114,255,1);
	}
	.circle_2_market_pc {
		width: 11px;
		height: 11px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(0.176px, 0px) matrix(1,0,0,1,198.8236,0) rotate(180deg);
		        transform: translate(0.176px, 0px) matrix(1,0,0,1,198.8236,0) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#bdr_view_all_product_market_pc {
		fill: rgba(248,90,71,1);
	}
	.bdr_view_all_product_market_pc {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 209.444px;
		height: 74.444px;
		left: 945px;
		top: 1432.5px;
	}
	#View_All_Products_market_pc {
		left: 980px;
		top: 1454.5px;
		position: absolute;
		overflow: visible;
		width: 131px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#Colonel_gravity_get_thought_fa {
		left: 103px;
		top: 1490.5px;
		position: absolute;
		overflow: visible;
		width: 471px;
		height: 21px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#Best_Deals_market_pc {
		left: 103px;
		top: 1432.5px;
		position: absolute;
		overflow: visible;
		width: 141px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#phone_grp_market_pc {
		position: absolute;
		width: 200px;
		height: 244px;
		left: 103px;
		top: 1550px;
		overflow: visible;
	}
	#anh-nhat-uCqMa_s-JDg-unsplash {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 244px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#shade_white_market_pc {
		-webkit-filter: blur(30px);
		        filter: blur(30px);
		fill: rgba(255,255,255,1);
	}
	.shade_white_market_pc {
		position: absolute;
		overflow: visible;
		width: 269px;
		height: 222px;
		left: 99px;
		top: 190px;
	}
	#bdr_best_seller_market_pc {
		fill: rgba(255,255,255,1);
	}
	.bdr_best_seller_market_pc {
		position: absolute;
		overflow: visible;
		width: 85px;
		height: 33px;
		left: 101px;
		top: 194px;
	}
	#Best_Seller_market_pc {
		left: 110px;
		top: 200.5px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#clothes_grp_market_pc {
		position: absolute;
		width: 200px;
		height: 244px;
		left: 384px;
		top: 1550px;
		overflow: visible;
	}
	#mediamodifier-kJXGTOY1wLQ-unsp {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 244px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#shade_clothes_market_pc {
		-webkit-filter: blur(50px);
		        filter: blur(50px);
		fill: rgba(0,0,0,1);
	}
	.shade_clothes_market_pc {
		position: absolute;
		overflow: visible;
		width: 389px;
		height: 342px;
		left: 99px;
		top: 190px;
	}
	#clothes_bdr_top_market_pc {
		fill: rgba(255,255,255,1);
	}
	.clothes_bdr_top_market_pc {
		position: absolute;
		overflow: visible;
		width: 85px;
		height: 33px;
		left: 101px;
		top: 194px;
	}
	#Top_Brands_shirt_market_pc {
		left: 107px;
		top: 200.5px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#shoes_grp_market_pc {
		position: absolute;
		width: 200px;
		height: 244px;
		left: 665px;
		top: 1550px;
		overflow: visible;
	}
	#shoes_img_market_pc {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 244px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#shade_shoes_market_pc {
		-webkit-filter: blur(30px);
		        filter: blur(30px);
		fill: rgba(255,219,74,1);
	}
	.shade_shoes_market_pc {
		position: absolute;
		overflow: visible;
		width: 269px;
		height: 222px;
		left: 99px;
		top: 190px;
	}
	#top_brand_bdr_market_pc {
		fill: rgba(255,255,255,1);
	}
	.top_brand_bdr_market_pc {
		position: absolute;
		overflow: visible;
		width: 85px;
		height: 33px;
		left: 101px;
		top: 194px;
	}
	#Top_Brands_lbl_market_pc {
		left: 107px;
		top: 200.5px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#pips_grp_market_pc {
		position: absolute;
		width: 200px;
		height: 244px;
		left: 945px;
		top: 1550px;
		overflow: visible;
	}
	#img_pipe_market_pc {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 244px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#shade_blue_market_pc {
		-webkit-filter: blur(30px);
		        filter: blur(30px);
		fill: rgba(79,183,222,1);
	}
	.shade_blue_market_pc {
		position: absolute;
		overflow: visible;
		width: 269px;
		height: 222px;
		left: 99px;
		top: 190px;
	}
	#bdr_best_quality_market_pc {
		fill: rgba(255,255,255,1);
	}
	.bdr_best_quality_market_pc {
		position: absolute;
		overflow: visible;
		width: 85px;
		height: 33px;
		left: 101px;
		top: 194px;
	}
	#Best_Quality_lbl_market_pc {
		left: 104px;
		top: 200.5px;
		position: absolute;
		overflow: visible;
		width: 80px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#best_deals_category_market_pc {
		position: absolute;
		width: 1041.474px;
		height: 320.81px;
		left: 103.149px;
		top: 1866.5px;
		overflow: visible;
	}
	#Image_01_market_pc {
		position: absolute;
		width: 329.474px;
		height: 267.141px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#bdr_add_cart_market_pc {
		fill: rgba(5,5,5,1);
	}
	.bdr_add_cart_market_pc {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 35px;
		left: 291.851px;
		top: 281.5px;
	}
	#add-to-cart_1_market_pc {
		position: absolute;
		width: 27px;
		height: 27px;
		left: 295.851px;
		top: 285.5px;
		overflow: visible;
	}
	#air_shoes_market_pc {
		left: 13.378px;
		top: 280.81px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#n_00_market_pc {
		left: 13.378px;
		top: 300.81px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Image_02_market_pc {
		position: absolute;
		width: 329.474px;
		height: 267.141px;
		left: 356px;
		top: 0px;
		overflow: visible;
	}
	#bdr_cart_market_pc {
		fill: rgba(5,5,5,1);
	}
	.bdr_cart_market_pc {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 35px;
		left: 647.851px;
		top: 281.5px;
	}
	#add-to-cart_img_market_pc {
		position: absolute;
		width: 27px;
		height: 27px;
		left: 651.851px;
		top: 285.5px;
		overflow: visible;
	}
	#Featured_name_market_pc {
		left: 369.378px;
		top: 280.81px;
		position: absolute;
		overflow: visible;
		width: 101px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Featured_1_market_pc {
		left: 369.378px;
		top: 300.81px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Image_03_add-to-cart_1_market_ {
		position: absolute;
		width: 329.474px;
		height: 267.141px;
		left: 712px;
		top: 0px;
		overflow: visible;
	}
	#add-to-cart_1_bdr__market_pc {
		fill: rgba(5,5,5,1);
	}
	.add-to-cart_1_bdr__market_pc {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 35px;
		left: 1003.851px;
		top: 281.5px;
	}
	#add-to-cart_1_market_pc_fk {
		position: absolute;
		width: 27px;
		height: 27px;
		left: 1007.851px;
		top: 285.5px;
		overflow: visible;
	}
	#Featured_456_market_pc {
		left: 725.378px;
		top: 280.81px;
		position: absolute;
		overflow: visible;
		width: 37px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Featured_123_market_pc {
		left: 725.378px;
		top: 300.81px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	.button_market {
  --bg: #F85A47;
  --hover-bg: #ffffff;
  --hover-text: #000000;
  color: #fff;
  border: 1px solid var(--bg);
  border-radius: 4px;
  padding: 0.8em 2em;
  background: var(--bg);
  transition: 0.2s;
  position: absolute;
    left: 79%;
    top: 64%;
    width: 163px;
    height: 58.292px;
}

.button_market:hover {
  color: var(--hover-text);
  -webkit-transform: translate(-0.25rem,-0.25rem);
          transform: translate(-0.25rem,-0.25rem);
  background: var(--hover-bg);
  box-shadow: 0.25rem 0.25rem var(--bg);
}

.button_market:active {
  -webkit-transform: translate(0);
          transform: translate(0);
  box-shadow: none;
}.button1 {
  --bg: #F85A47;
  --hover-bg: #ffffff;
  --hover-text: #000000;
  color: #fff;
  border: 1px solid var(--bg);
  border-radius: 4px;
  padding: 0.8em 2em;
  background: var(--bg);
  transition: 0.2s;
  position: absolute;
    left: 79%;
    top: 45.5%;
    width: 163px;
    height: 58.292px;
}

.button1:hover {
  color: var(--hover-text);
  -webkit-transform: translate(-0.25rem,-0.25rem);
          transform: translate(-0.25rem,-0.25rem);
  background: var(--hover-bg);
  box-shadow: 0.25rem 0.25rem var(--bg);
}

.button1:active {
  -webkit-transform: translate(0);
          transform: translate(0);
  box-shadow: none;
}

.mediaViewInfo {
    --web-view-name: profile page;
    --web-view-id: profile_page;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: profile_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
.main-page {
    position: fixed;
    display: table;
    left: 120px;
    width: calc(100vw - 120px);
    height: calc(100vh - 75px);
}

.main-content {
   position: relative;
   display: table-cell;
   width: calc(100% - 480px);
}
.container-row{
    display: table-row;
}
.container-cell
{
display: table-cell;
width: 100%;
padding-right: 10px;
}
.inner-table{
    position: relative;
display: table;
overflow-y: scroll;
width: 100%;

}

.right-content {
   
    position: relative;
    display: table-cell;
    width: 350px;
   vertical-align: top;
   
 }
.mediaViewInfo {
    --web-view-name: top info;
    --web-view-id: top_info;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: top_info;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#top_info {
    position: relative;
    width: 100%;
    height: 428px;
    margin-top: 75px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: top info;
    --web-view-id: top_info;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
@-webkit-keyframes fadein {

    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }

}
@keyframes fadein {

    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }

}
#mid_circle_grp_profile {
    position: relative;
    width: 100%;
    height: 419px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#banner_img {
    position: absolute;
    width: 100%;
    height: 160px;
    left: 2px;
    top: 0px;
    border-radius: 15px;
    overflow: hidden;
}
#profil_info_bdr {
    fill: rgba(255,255,255,1);
}
.profil_info_bdr {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
            filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 259px;
    left: 0px;
    top: 168px;
}
#pfp_pic_image {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 68px;
    top: 114px;
    border-radius: 50%;
    overflow: hidden;
}
#user_details_grp_left {
    position: absolute;
    width: 300px;
    height: 169px;
    left: 25px;
    top: 235px;
    overflow: visible;
}
#UserProfileName_Pc {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 247px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#location_grp {
    position: absolute;
    width: 251px;
    height: 25px;
    left: 0px;
    top: 30px;
    overflow: visible;
}
#India_Bangalore {
    left: 33px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 219px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#india_img {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#skills_grp_ {
    position: absolute;
    width: 250px;
    height: 22px;
    left: 0px;
    top: 65px;
    overflow: visible;
}
#skill_img_ {
    position: absolute;
    width: 29px;
    height: 22px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#UIUX_designer_skill {
    left: 34px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 217px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#connections_grp {
    position: absolute;
    width: 128px;
    height: 31px;
    left: 123px;
    top: 138px;
    overflow: visible;
}
#all_con {
    fill: rgba(163,233,200,1);
}
.all_con {
    position: absolute;
    overflow: visible;
    width: 128px;
    height: 31px;
    left: 0px;
    top: 0px;
}
#Ellipse_11_circle_ {
    fill: rgba(255,255,255,1);
}
.Ellipse_11_circle_ {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 3px;
    top: 3px;
}
#n_2345432 {
    left: 5px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#Connections_lbl {
    left: 33px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#followers_grp_lbl {
    position: absolute;
    width: 113px;
    height: 31px;
    left: 0px;
    top: 138px;
    overflow: visible;
}
#overall_rec {
    fill: rgba(163,233,200,1);
}
.overall_rec {
    position: absolute;
    overflow: visible;
    width: 113px;
    height: 31px;
    left: 0px;
    top: 0px;
}
#Ellipse_1_circle {
    fill: rgba(255,255,255,1);
}
.Ellipse_1_circle {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 3px;
    top: 3px;
}
#n_23_456789 {
    left: 5px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#Followers_lbl {
    left: 33px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#skills_grp__cf {
    position: absolute;
    width: 250px;
    height: 22px;
    left: 0px;
    top: 96px;
    overflow: visible;
}
#skill_img__cg {
    position: absolute;
    width: 29px;
    height: 22px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#UIUX_designer_skill_lbl {
    left: 34px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 217px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}

#QR_btn_grp {
    position: absolute;
    width: 36px;
    height: 36px;
    left: 198px;
    top: 180px;
    overflow: visible;
    transition: all 0.30000001192092896s ease-out;
    --web-animation: fadein 0.30000001192092896s ease-out;
    --web-action-type: page;
    --web-action-target: profile_page___1.html;
    cursor: pointer;
}
#QR_circle_bdr {
    fill: rgba(255,255,255,1);
}
.QR_circle_bdr {
    -webkit-filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 54px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#QR_img {
    position: absolute;
    width: 23px;
    height: 23px;
    left: 7px;
    top: 7px;
    overflow: visible;
}
#profile_link_grp_ {
    position: absolute;
    width: 36px;
    height: 36px;
    left: 200px;
    top: 180px;
    overflow: visible;
}
#offer_circle {
    fill: rgba(255,255,255,1);
}
.offer_circle {
    -webkit-filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 54px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#web-design_ {
    position: absolute;
    width: 23px;
    height: 23px;
    left: 7px;
    top: 7px;
    cursor: pointer;
    overflow: visible;
}
#all_about_mission_vision_rpifb {
    position: absolute;
    width: calc(100% - 360px);
    height: 195px;
    right: 0px;
    top: 184px;
    overflow: visible;
}
#over_all_secter_bg {
    fill: rgba(255,255,255,1);
}
.over_all_secter_bg {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.647));
            filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.647));
    position: absolute;
    overflow: visible;
    width: 389px;
    height: 56px;
    left: 0px;
    top: 0px;
}
#mark_about_hanger {
    fill: rgba(208,222,191,1);
}
.mark_about_hanger {
    position: absolute;
    overflow: visible;
    width: 103px;
    height: 48px;
    left: 0px;
    top: 0px;
}
#Lorem_about_data_pc {
    left: 0px;
    top: 75px;
    position: absolute;
    overflow: visible;
   overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 120px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#labal_name_aoutb_grp {
    position: absolute;
    width: 338px;
    height: 22px;
    left: 28px;
    top: 13px;
    overflow: visible;
}
#About_lbl_lorem {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
    cursor: pointer;
}
#mission_lbl_selecter {
    left: 86px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 60px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(0,0,0,1);
}
#vision_lbl_selecter {
    left: 185px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 48px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
    cursor: pointer;
}
#princi_lbl_selecter {
    left: 267px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
    cursor: pointer;
}

#followers_grp_lbl {
    position: absolute;
    width: 113px;
    height: 31px;
    left: 25px;
    top: 373px;
    overflow: visible;
}
#overall_rec {
    fill: rgba(163,233,200,1);
}
.overall_rec {
    position: absolute;
    overflow: visible;
    width: 113px;
    height: 31px;
    left: 0px;
    top: 0px;
}
#Ellipse_1_circle {
    fill: rgba(255,255,255,1);
}
.Ellipse_1_circle {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 3px;
    top: 3px;
}
#n_23_456789 {
    left: 5px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#Followers_lbl {
    left: 33px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#skills_grp__bp {
    position: absolute;
    width: 226px;
    height: 22px;
    left: 25px;
    top: 331px;
    overflow: visible;
}
#skill_img__bq {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#UIUX_designer_skill_lbl {
    left: 39px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 188px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#pen_edit_banner_grp_pc {
    position: absolute;
    width: 23px;
    height: 23px;
    right: 30px;
    top: 184px;
    overflow: visible;
}
#pen_edit_banner_img_pc {
    position: absolute;
    width: 23px;
    height: 23px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#pen_edit_banner_grp_bv {
    position: absolute;
    width: 23px;
    height: 23px;
    right: 30px;
    top: 16px;
    cursor: pointer;
    overflow: visible;
}
#pen_edit_banner_img_bw {
    position: absolute;
    width: 23px;
    height: 23px;
    left: 0px;
    top: 0px;
    cursor: pointer;
    overflow: visible;
}
#pfp_pic {
    fill: rgba(255,255,255,1);
}
.pfp_pic {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 120px;
    left: 58px;
    top: 104px;
}

#all_centre_btn_grp {
    position: absolute;
    width: 130px;
    height: 250px;
    left: 360px;
    top: 168px;
    
    overflow: visible;
}
#left_half_btn_bg {
    position: absolute;
    width: 130px;
    height: 250px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#right_half_btn_bg {
    position: absolute;
    width: 69px;
    height: 250px;
    left: 61px;
    top: 0px;
    overflow: visible;
}
#Scroll_menu_options_extra {
    mix-blend-mode: normal;
    position: absolute;
    width: 130px;
    height: 173px;
    left: 0px;
    top: 39px;
    overflow: hidden;
}
#dot_menu_options {
    position: absolute;
    width: 126px;
    height: 157px;
    left: 3px;
    top: 9px;
    overflow: visible;
}
#connect_btn_grp_menu_edit_page:hover {
    left: 6px;
}
#connect_btn_grp_menu_edit_page {
    position: absolute;
    width: 120px;
    height: 32px;
    left: 0px;
    top: 0px;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#bdr_connect_menu_edit_page_c {
    fill: rgba(255,255,255,1);
}
.bdr_connect_menu_edit_page_c {
    -webkit-filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 50px;
    left: 0px;
    top: 0px;
}
#conncet_img_connect_c {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 6px;
    overflow: visible;
}
#Connect_lbl_menu_edit_page_c {
    left: 36px;
    top: 8px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#follow_link_grp_menu_edit_page:hover {
    left: 6px;
}
#follow_link_grp_menu_edit_page {
    position: absolute;
    width: 120px;
    height: 32px;
    left: 0px;
    top: 41px;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#follow_bdr_menu_edit_page_c {
    fill: rgba(255,255,255,1);
}
.follow_bdr_menu_edit_page_c {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 50px;
    left: 0px;
    top: 0px;
}
#Follow_lbl_menu_edit_page_da {
    left: 42px;
    top: 8px;
    position: absolute;
    overflow: visible;
    width: 38px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#follow_img_follow_db {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 6px;
    overflow: visible;
}
#ignore_btn_grp_menu_edit_page:hover {
    left: 6px;
}
#ignore_btn_grp_menu_edit_page {
    position: absolute;
    width: 120px;
    height: 32px;
    left: 0px;
    top: 83px;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#ignore_bdr_btn_menu_edit_page_dh {
    fill: rgba(255,255,255,1);
}
.ignore_bdr_btn_menu_edit_page_dh {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 50px;
    left: 0px;
    top: 0px;
}
#Ignore_lbl_menu_edit_page_di {
    left: 42px;
    top: 8px;
    position: absolute;
    overflow: visible;
    width: 38px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#ignore_img_unfollow_dj {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 6px;
    overflow: visible;
}
#block_btn_menu_edit_page:hover {
    left: 6px;
}
#block_btn_menu_edit_page {
    position: absolute;
    width: 120px;
    height: 32px;
    left: 0px;
    top: 125px;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#block_dbr_menu_edit_page_dp {
    fill: rgba(255,255,255,1);
}
.block_dbr_menu_edit_page_dp {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 50px;
    left: 0px;
    top: 0px;
}
#Block_lbl_menu_edit_page_dq {
    left: 45px;
    top: 8px;
    position: absolute;
    overflow: visible;
    width: 32px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#img_block_menu_edit_page_dr {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 7px;
    top: 6px;
    overflow: visible;
}

	.mediaViewInfo {
		--web-view-name: theme small;
		--web-view-id: theme_small;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: theme_small;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#theme_small {
		position: absolute;
		width: 1118px;
		height: 739px;
        border-radius: 15px;
 top:50%;
    left:50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    overflow: hidden;
    background-color: rgb(247 255 251);
		overflow: hidden;
		--web-view-name: theme small;
		--web-view-id: theme_small;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#select_btn_grp_website_1_op_li {
		position: absolute;
		width: 149px;
		height: 54px;
		left: 485px;
		top: 645px;
		overflow: visible;
	}
	#select_btn_website_1_op_live_t {
		fill: rgba(143,233,191,1);
	}
	.select_btn_website_1_op_live_t {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 167px;
		height: 72px;
		left: 0px;
		top: 0px;
	}
	#select_website_1_op_live_theme {
		left: 47px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 56px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Scroll_all_themes_scroll_for_w {
		mix-blend-mode: normal;
		position: absolute;
		width: 1118px;
		height: 556px;
		left: 0px;
		top: 67px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#enchnting_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 177px;
		left: 20px;
		top: 47px;
		overflow: visible;
	}
	#layout_1_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_1_small_theme {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_1_small_theme {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 159px;
	}
	#Enchanting_Emerald_small_theme {
		left: 71px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_1_mob_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#design_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 177px;
		left: 399px;
		top: 47px;
		overflow: visible;
	}
	#website_2_layout_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_2_small_theme {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_2_small_theme {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 60px;
		top: 159px;
	}
	#Flamingo_Fiesta_lbl_small_them {
		left: 85px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_2_mob_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#ocean_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 177px;
		left: 778px;
		top: 47px;
		overflow: visible;
	}
	#website_3_layout_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_3_small_theme {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_3_small_theme {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 69px;
		top: 159px;
	}
	#ocean_blue_lbl_small_theme {
		left: 94px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_3_pc_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#PC_1_small_theme {
		left: 130px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 22px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(148,154,153,1);
	}
	#Mob_1_small_theme {
		left: 290px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(148,154,153,1);
	}
	#PC_2_small_theme {
		left: 509px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 22px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(148,154,153,1);
	}
	#Mob_2_small_theme {
		left: 669px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(148,154,153,1);
	}
	#PC_3_small_theme {
		left: 888px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 22px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(148,154,153,1);
	}
	#Mob_3_small_theme {
		left: 1048px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(148,154,153,1);
	}
	#med_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 20px;
		top: 271px;
		overflow: visible;
	}
	#layout_4_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_4_small_theme {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_4_small_theme {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 158px;
	}
	#sahara_small_theme {
		left: 71px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_4_mob_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#dark_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 399px;
		top: 271px;
		overflow: visible;
	}
	#layout_5_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_5_small_theme {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_5_small_theme {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#shadow_space_small_theme {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_5_mob_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#school_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 778px;
		top: 271px;
		overflow: visible;
	}
	#layout_6_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_6_small_theme {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_6_small_theme {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#knowledge_kornersmall_theme {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 123px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_6_mob_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#car_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 15px;
		top: 495px;
		overflow: visible;
	}
	#layout_7_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_7_mob_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_7_small_theme {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_7_small_theme {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 167px;
	}
	#drive_velocity_small_theme {
		left: 71px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#bike_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 394px;
		top: 495px;
		overflow: visible;
	}
	#layout_8_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_8_mob_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_7_small_theme_dk {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_7_small_theme_dk {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#ride_revolutionbigtheme {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#fash_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 773px;
		top: 495px;
		overflow: visible;
	}
	#layout_9_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_9_mob_small_theme {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_9_small_theme {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_9_small_theme {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#fashion_finds_small_theme {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#med_grp_dummy_theme_pc {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 20px;
		top: 729px;
		overflow: visible;
	}
	#layout_4_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_4_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_4_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 158px;
	}
	#sahara_small_theme_dummy_pc {
		left: 71px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_4_mob_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#shadow__grp_dummy_theme_pc {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 399px;
		top: 729px;
		overflow: visible;
	}
	#layout_5_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_5_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_5_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#shadow_space_small_theme_dummy {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_5_mob_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#paw_palace_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 778px;
		top: 729px;
		overflow: visible;
	}
	#layout_6_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_6_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_6_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#paw_palace_theme_du {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 123px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_6_mob_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#car_grp_dummy_theme_pc {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 15px;
		top: 953px;
       
		overflow: visible;
	}
	#layout_7_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_7_mob_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_7_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_7_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 167px;
	}
	#drive_velocity_small_theme_dum {
		left: 71px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#bike_grp_dummy_theme_pc {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 394px;
		top: 953px;
		overflow: visible;
	}
	#layout_8_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_8_mob_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_7_small_theme_dummy__ee {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_7_small_theme_dummy__ee {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#ride_revolutionbigtheme_dummy_ {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#fashion_finds_grp_dummy_theme_ {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 773px;
		top: 953px;
        /* display: none; */
		overflow: visible;
	}
	
	#layout_9_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_9_mob_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_9_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_9_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#fashion_finds_small_theme_dumm {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#close_theme_small_pc_ {
		position: absolute;
		width: 25px;
		height: 25px;
		right: 10px;
		top: 10px;
        cursor: pointer;
		overflow: visible;
	}

	#n_6_grp_dummy_theme_pc {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 18px;
		top: 1184px;
		overflow: visible;
	}
	#layout_16_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_16_mob_small_theme_dummy_p {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_16_btn_9_small_theme_dum {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_16_btn_9_small_theme_dum {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#n_6_small_theme_dummy_pc {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#shadow_17_grp_dummy_theme_pc {

		position: absolute;
		width: 330px;
		height: 175px;
		left: 397px;
		top: 1184px;
		overflow: visible;
	}
	#layout_17small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_17small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_17small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#shadow_17space_small_theme_dum {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_17mob_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#school_18_grp_dummy_theme_pc {
		
		position: absolute;
		width: 330px;
		height: 175px;
		left: 776px;
		top: 1184px;
		overflow: visible;
	}
	#layout_18_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_18_small_theme_dummy {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_18_small_theme_dummy {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#knowledge18__kornersmall_theme {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 123px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_18_mob_small_theme_dummy_p {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#car_19grp_dummy_theme_pc {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 13px;
		top: 1408px;
		overflow: visible;
	}
	#layout_19small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_19mob_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_19small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_19small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 167px;
	}
	#drive_19velocity_small_theme_d {
		left: 71px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#bike_20grp_dummy_theme_pc {

		position: absolute;
		width: 330px;
		height: 184px;
		left: 392px;
		top: 1408px;
		overflow: visible;
	}
	#layout_20small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_20mob_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_20small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_20small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#ride_20revolutionbigtheme_dumm {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
		#bike_21grp_dummy_theme_pc {
	
		position: absolute;
		width: 330px;
		height: 184px;
		left: 773px;
		top: 1406px;
		overflow: visible;
	}
	#bike_22grp_dummy_theme_pc {

	position: absolute;
	width: 330px;
	height: 184px;
	left: 13px;
	top: 1632px;
	overflow: visible;
}
#bike_23grp_dummy_theme_pc {

position: absolute;
width: 330px;
height: 184px;
left: 392px;
top: 1632px;
overflow: visible;
}
#bike_24grp_dummy_theme_pc {

position: absolute;
width: 330px;
height: 184px;
left: 773px;
top: 1632px;
overflow: visible;
}
#bike_25grp_dummy_theme_pc {

position: absolute;
width: 330px;
height: 184px;
left: 13px;
top: 1858px;
overflow: visible;
}
#bike_26grp_dummy_theme_pc {

position: absolute;
width: 330px;
height: 184px;
left: 392px;
top: 1858px;
overflow: visible;
}
#bike_27grp_dummy_theme_pc {

position: absolute;
width: 330px;
height: 184px;
left: 773px;
top: 1858px;
overflow: visible;
}
#bike_28grp_dummy_theme_pc {

position: absolute;
width: 330px;
height: 184px;
left: 13px;
top: 2084px;
overflow: visible;
}
#bike_29grp_dummy_theme_pc {
position: absolute;
width: 330px;
height: 184px;
left: 392px;
top: 2084px;
overflow: visible;
}

#bike_30grp_dummy_theme_pc {
	position: absolute;
	width: 330px;
	height: 184px;
	left: 773px;
	top: 2084px;
	overflow: visible;
	}
	#bike_31grp_dummy_theme_pc {
	position: absolute;
	width: 330px;
	height: 184px;
	left: 13px;
	top: 2310px;
	overflow: visible;
	}
	
	#bike_32grp_dummy_theme_pc {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 392px;
		top: 2310px;
		overflow: visible;
		}
		#bike_33grp_dummy_theme_pc {
			position: absolute;
			width: 330px;
			height: 184px;
			left: 773px;
			top: 2310px;
			overflow: visible;
			}
			#bike_34grp_dummy_theme_pc {
			position: absolute;
			width: 330px;
			height: 184px;
			left: 13px;
			top: 2536px;
			overflow: visible;
			}
			
			#bike_35grp_dummy_theme_pc {
				position: absolute;
				width: 330px;
				height: 184px;
				left: 392px;
				top: 2536px;
				overflow: visible;
				}
				#bike_36grp_dummy_theme_pc {
					position: absolute;
					width: 330px;
					height: 184px;
					left: 773px;
					top: 2536px;
					overflow: visible;
					}
					#bike_37grp_dummy_theme_pc {
						position: absolute;
						width: 330px;
						height: 184px;
						left: 13px;
						top: 2762px;
						overflow: visible;
						}
	#layout_21small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_21mob_small_theme_dummy_pc {
		-webkit-filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_21small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_21small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#ride21_revolutionbigtheme_dumm {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	
.mediaViewInfo {
    --web-view-name: broadcast pc;
    --web-view-id: broadcast_pc;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: broadcast_pc;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
body::-webkit-scrollbar{
    display: none;
}
#broadcast_pc {
    position: absolute;
    width: 1237px;
    height: 2694px;
    top:60px;
    left:120px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: broadcast pc;
    --web-view-id: broadcast_pc;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#add_bdr_event_page_pc {
    position: absolute;
    width: 1137px;
    height: 118px;
    left: 51px;
    top: 542px;
    overflow: visible;
}
#bdr_ad_area_events_event_page_ {
    fill: rgba(225,64,64,0.89);
}
.bdr_ad_area_events_event_page_ {
    overflow: visible;
    position: absolute;
    width: 1137px;
    height: 116px;
    left: 0px;
    top: 2px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#AD_AREA_event_page_pc {
    left: 385px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 364px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 84px;
    color: rgba(255,255,255,1);
}
#find_the_ulti_lblevent_page_pc {
    left: 75px;
    top: 1274px;
    position: absolute;
    overflow: visible;
    width: 471px;
    height: 21px;
    line-height: 24px;
    margin-top: -4px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#recommeneded_events_lbl_event_ {
    left: 75px;
    top: 1224px;
    position: absolute;
    overflow: visible;
    width: 301px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(0,0,0,1);
}
#card_1_recomm_lbl_bdr_event_pa {
    fill: rgba(255,255,255,1);
}
.card_1_recomm_lbl_bdr_event_pa {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 73px;
    top: 1641px;
}
#card_1_recommened_lbl_event_pa {
    left: 101px;
    top: 1647px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_bdr_event_pa {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 59px;
    top: 1341px;
    overflow: visible;
}
#recommended_events_card_1_img_ {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 72px;
    top: 1352px;
    overflow: visible;
}
#top_event_category_lbl_categor {
    left: 75px;
    top: 1719px;
    position: absolute;
    overflow: visible;
    width: 287px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(0,0,0,1);
}
#product_zone_bdr_category_even_bd {
    fill: url(#product_zone_bdr_category_even_bd);
}
.product_zone_bdr_category_even_bd {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 208px;
    height: 253px;
    left: 59px;
    top: 1778px;
}
#music_zone_bdr_category_event_ {
    fill: rgba(142,183,255,1);
}
.music_zone_bdr_category_event_ {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 208px;
    height: 253px;
    left: 289px;
    top: 1778px;
}
#workshop_bdr_category_event_pa_bg {
    fill: url(#workshop_bdr_category_event_pa_bg);
}
.workshop_bdr_category_event_pa_bg {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 208px;
    height: 253px;
    left: 519px;
    top: 1778px;
}
#upskill_bdr_category_event_pag_bi {
    fill: url(#upskill_bdr_category_event_pag_bi);
}
.upskill_bdr_category_event_pag_bi {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 208px;
    height: 253px;
    left: 749px;
    top: 1778px;
}
#other_bdr_category_event_page_ {
    fill: rgba(255,146,241,1);
}
.other_bdr_category_event_page_ {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 208px;
    height: 253px;
    left: 979px;
    top: 1778px;
}
#product_zone_lbl_category_even {
    left: 74px;
    top: 1790px;
    position: absolute;
    overflow: visible;
    width: 169px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: rgba(255,255,255,1);
}
#n_0events_lbl_category_event_p {
    left: 74px;
    top: 1866px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#music_zone_lbl_category_event_ {
    left: 304px;
    top: 1790px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: rgba(255,255,255,1);
}
#n_0events_lbl_category_event_p_bn {
    left: 304px;
    top: 1866px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#workshop_zone_lbl_category_eve {
    left: 534px;
    top: 1790px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: rgba(255,255,255,1);
}
#n_0events_lbl_category_event_p_bp {
    left: 534px;
    top: 1866px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#upskill_lbl_category_event_pag {
    left: 763px;
    top: 1790px;
    position: absolute;
    overflow: visible;
    width: 159px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: rgba(255,255,255,1);
}
#n_0_events_upskill_category_ev {
    left: 765px;
    top: 1866px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#other_lbl_category_event_page_ {
    left: 1005px;
    top: 1808px;
    position: absolute;
    overflow: visible;
    width: 80px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: rgba(255,255,255,1);
}
#n_0_event_lbl_other_category_e {
    left: 1005px;
    top: 1859px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#product_img_category_event_pag {
    position: absolute;
    width: 130px;
    height: 130px;
    left: 94px;
    top: 1893px;
    overflow: visible;
}
#workshop_img_category_event_pa {
    position: absolute;
    width: 196px;
    height: 122.5px;
    left: 521px;
    top: 1900.5px;
    overflow: visible;
}
#upskill_img_category_event_pag {
    position: absolute;
    width: 152px;
    height: 152px;
    left: 773px;
    top: 1886px;
    overflow: visible;
}
#other_img_category_event_page_ {
    position: absolute;
    width: 139px;
    height: 139px;
    left: 322px;
    top: 1886px;
    overflow: visible;
}
#music_zone_img_category_event_ {
    position: absolute;
    width: 200px;
    height: 133.333px;
    left: 981px;
    top: 1903.333px;
    overflow: visible;
}
#streming_live_bdr_event_page_p {
    fill: rgba(255,192,168,1);
}
.streming_live_bdr_event_page_p {
    position: absolute;
    overflow: visible;
    width: 1282px;
    height: 491px;
    left: 0px;
    top: 703px;
}
#streaming_Live_now_lbl_event_p {
    left: 75px;
    top: 724px;
    position: absolute;
    overflow: visible;
    width: 247px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(0,0,0,1);
}
#enroll_now_bdr_event_page_pc {
    fill: rgba(255,255,255,1);
}
.enroll_now_bdr_event_page_pc {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 78px;
    left: 73px;
    top: 1088px;
}
#BiziBees_PVT_LTD_steraming_now {
    left: 101px;
    top: 1098px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Enroll_Now__lbl_streaming_live {
    left: 78px;
    top: 1120px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#streaming_live_now_bdr_event_p {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 59px;
    top: 788px;
    overflow: visible;
}
#streaming_live_now_img_card_1e {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 71px;
    top: 797px;
    overflow: visible;
}
#free_event_name_event_page_pc {
    fill: rgba(255,255,255,1);
}
.free_event_name_event_page_pc {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 73px;
    top: 2496px;
}
#BiziBees_PVT_LTD_free_events_e {
    left: 101px;
    top: 2502px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#free_events_card_1_bdr_event_p {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 59px;
    top: 2196px;
    overflow: visible;
}
#free_events_card_1_event_page_ {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 72px;
    top: 2207px;
    overflow: visible;
}
#Colonel_gravity_get_thought_fa {
    left: 75px;
    top: 2129px;
    position: absolute;
    overflow: visible;
    width: 363px;
    white-space: nowrap;
    line-height: 24px;
    margin-top: -4px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#Free_Events_lbl_event_page_pc {
    left: 75.5px;
    top: 2079px;
    position: absolute;
    overflow: visible;
    width: 155px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(0,0,0,1);
}
#event_top_banner_event_page_pc {
    position: absolute;
    width: 1332.512px;
    height: 627.306px;
    left: -48.895px;
    top: -31.312px;
    overflow: visible;
}
#Path_3 {
    fill: rgba(248,90,71,1);
}
.Path_3 {
    overflow: visible;
    position: absolute;
    width: 316.087px;
    height: 175px;
    -webkit-transform: translate(48.895px, 31.312px) matrix(1,0,0,1,956.9565,58.5) rotate(50deg);
            transform: translate(48.895px, 31.312px) matrix(1,0,0,1,956.9565,58.5) rotate(50deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 0px;
}
#Path_2 {
    fill: rgba(248,90,71,1);
}
.Path_2 {
    overflow: visible;
    position: absolute;
    width: 316.087px;
    height: 175px;
    -webkit-transform: translate(48.895px, 31.312px) matrix(1,0,0,1,-38.3206,331.182) rotate(230deg);
            transform: translate(48.895px, 31.312px) matrix(1,0,0,1,-38.3206,331.182) rotate(230deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 0px;
}
#img_banner_1_event_pagge_pc {
    position: absolute;
    width: 808px;
    height: 356px;
    left: 137.895px;
    top: 136.312px;
    overflow: visible;
}
#img_banner_3_event_page_pc {
    position: absolute;
    width: 232px;
    height: 111px;
    left: 966.895px;
    top: 136.312px;
    overflow: visible;
}
#img_banner_3_event_page_pc_ch {
    position: absolute;
    width: 232px;
    height: 110px;
    left: 966.895px;
    top: 259.312px;
    overflow: visible;
}
#all_img_banner_2_event_page_pc {
    position: absolute;
    width: 232px;
    height: 111px;
    left: 966.895px;
    top: 381.312px;
    overflow: visible;
}
#Join_us_today_event_page_pc {
    left: 814.895px;
    top: 141.312px;
    position: absolute;
    overflow: visible;
    width: 91px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#Today_at_600pm_event_page_pc {
    left: 816.895px;
    top: 161.312px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#Sat_6pm_event_4_event_page_pc {
    left: 1138.895px;
    top: 136.312px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#bell_4_bdr_event_page_pc {
    fill: rgba(255,255,255,1);
}
.bell_4_bdr_event_page_pc {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 1167.895px;
    top: 220.312px;
}
#bell_4_img_event_page_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 1169.895px;
    top: 222.312px;
    overflow: visible;
}
#cal_bdr_4_event_page_pc {
    fill: rgba(255,255,255,1);
}
.cal_bdr_4_event_page_pc {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 1140.895px;
    top: 220.312px;
}
#calendar_4_img_event_page_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 1142.895px;
    top: 222.312px;
    overflow: visible;
}
#Sat_6pm_time_3_event_page_pc {
    left: 1138.895px;
    top: 260.312px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#bell_bdr_3_event_page_pc {
    fill: rgba(255,255,255,1);
}
.bell_bdr_3_event_page_pc {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 1167.895px;
    top: 344.312px;
}
#bell_img_3_event_page_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 1169.895px;
    top: 346.312px;
    overflow: visible;
}
#calender_bdr_3_event_page_pc {
    fill: rgba(255,255,255,1);
}
.calender_bdr_3_event_page_pc {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 1140.895px;
    top: 344.312px;
}
#calendar_img_3_event_page_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 1142.895px;
    top: 346.312px;
    overflow: visible;
}
#Sat_6pm_time_2_event_page_pc {
    left: 1138.895px;
    top: 381.312px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#bdr_bell_2_event_page_pc {
    fill: rgba(255,255,255,1);
}
.bdr_bell_2_event_page_pc {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 1167.895px;
    top: 465.312px;
}
#bell_2_img_event_page_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 1169.895px;
    top: 467.312px;
    overflow: visible;
}
#calender_bdr_2_event_page_pc {
    fill: rgba(255,255,255,1);
}
.calender_bdr_2_event_page_pc {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 1140.895px;
    top: 465.312px;
}
#calendar_img_2_event_page_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 1142.895px;
    top: 467.312px;
    overflow: visible;
}
#BiziBees_PVT_LTD_1_event_page_ {
    left: 161.895px;
    top: 141.312px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#BiziBees_PVT_LTD_4_event_page_ {
    left: 982.895px;
    top: 136.312px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#BiziBees_PVT_LTD_3_event_page_ {
    left: 982.895px;
    top: 260.312px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#BiziBees_PVT_LTD_2_event_page_ {
    left: 982.895px;
    top: 381.312px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#bell_bdr_1_event_page_pc {
    fill: rgba(255,255,255,1);
}
.bell_bdr_1_event_page_pc {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 904.895px;
    top: 430.312px;
}
#bell_img_1_event_page_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 906.895px;
    top: 432.312px;
    overflow: visible;
}
#calender_bdr_1_event_page_pc {
    fill: rgba(255,255,255,1);
}
.calender_bdr_1_event_page_pc {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 873.895px;
    top: 430.312px;
}
#calendar_img_1_event_page_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 875.895px;
    top: 432.312px;
    overflow: visible;
}
#enroll_1_bdr_event_page_pc_c {
    fill: url(#enroll_1_bdr_event_page_pc_c);
}
.enroll_1_bdr_event_page_pc_c {
    position: absolute;
    overflow: visible;
    width: 79px;
    height: 24px;
    left: 849.895px;
    top: 458.312px;
}
#Enroll_lbl_1_event_page_pc {
    left: 869.895px;
    top: 460.312px;
    position: absolute;
    overflow: visible;
    width: 40px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#enroll_now_bdr_event_page_pc_db {
    fill: rgba(255,255,255,1);
}
.enroll_now_bdr_event_page_pc_db {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 78px;
    left: 303px;
    top: 1088px;
}
#BiziBees_PVT_LTD_steraming_now_dc {
    left: 331px;
    top: 1098px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Enroll_Now__lbl_streaming_live_dd {
    left: 308px;
    top: 1120px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#streaming_live_now_bdr_event_p_de {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 289px;
    top: 788px;
    overflow: visible;
}
#streaming_live_now_img_card_1e_df {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 301px;
    top: 797px;
    overflow: visible;
}
#enroll_now_bdr_event_page_pc_dg {
    fill: rgba(255,255,255,1);
}
.enroll_now_bdr_event_page_pc_dg {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 78px;
    left: 533px;
    top: 1088px;
}
#BiziBees_PVT_LTD_steraming_now_dh {
    left: 561px;
    top: 1098px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Enroll_Now__lbl_streaming_live_di {
    left: 538px;
    top: 1120px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#streaming_live_now_bdr_event_p_dj {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 519px;
    top: 788px;
    overflow: visible;
}
#streaming_live_now_img_card_1e_dk {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 531px;
    top: 797px;
    overflow: visible;
}
#enroll_now_bdr_event_page_pc_dl {
    fill: rgba(255,255,255,1);
}
.enroll_now_bdr_event_page_pc_dl {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 78px;
    left: 763px;
    top: 1088px;
}
#BiziBees_PVT_LTD_steraming_now_dm {
    left: 791px;
    top: 1098px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Enroll_Now__lbl_streaming_live_dn {
    left: 768px;
    top: 1120px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#streaming_live_now_bdr_event_p_do {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 749px;
    top: 788px;
    overflow: visible;
}
#streaming_live_now_img_card_1e_dp {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 761px;
    top: 797px;
    overflow: visible;
}
#enroll_now_bdr_event_page_pc_dq {
    fill: rgba(255,255,255,1);
}
.enroll_now_bdr_event_page_pc_dq {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 78px;
    left: 993px;
    top: 1088px;
}
#BiziBees_PVT_LTD_steraming_now_dr {
    left: 1021px;
    top: 1098px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Enroll_Now__lbl_streaming_live_ds {
    left: 998px;
    top: 1120px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#streaming_live_now_bdr_event_p_dt {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 979px;
    top: 788px;
    overflow: visible;
}
#streaming_live_now_img_card_1e_du {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 991px;
    top: 797px;
    overflow: visible;
}
#card_1_recomm_lbl_bdr_event_pa_dv {
    fill: rgba(255,255,255,1);
}
.card_1_recomm_lbl_bdr_event_pa_dv {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 303px;
    top: 1641px;
}
#card_1_recommened_lbl_event_pa_dw {
    left: 331px;
    top: 1647px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_bdr_event_pa_dx {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 289px;
    top: 1341px;
    overflow: visible;
}
#recommended_events_card_1_img__dy {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 302px;
    top: 1352px;
    overflow: visible;
}
#card_1_recomm_lbl_bdr_event_pa_dz {
    fill: rgba(255,255,255,1);
}
.card_1_recomm_lbl_bdr_event_pa_dz {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 533px;
    top: 1641px;
}
#card_1_recommened_lbl_event_pa_d {
    left: 561px;
    top: 1647px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_bdr_event_pa_d {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 519px;
    top: 1341px;
    overflow: visible;
}
#recommended_events_card_1_img__d {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 532px;
    top: 1352px;
    overflow: visible;
}
#card_1_recomm_lbl_bdr_event_pa_d {
    fill: rgba(255,255,255,1);
}
.card_1_recomm_lbl_bdr_event_pa_d {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 763px;
    top: 1641px;
}
#card_1_recommened_lbl_event_pa_ea {
    left: 791px;
    top: 1647px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_bdr_event_pa_ea {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 749px;
    top: 1341px;
    overflow: visible;
}
#recommended_events_card_1_img__ea {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 762px;
    top: 1352px;
    overflow: visible;
}
#card_1_recomm_lbl_bdr_event_pa_ea {
    fill: rgba(255,255,255,1);
}
.card_1_recomm_lbl_bdr_event_pa_ea {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 993px;
    top: 1641px;
}
#card_1_recommened_lbl_event_pa_eb {
    left: 1021px;
    top: 1647px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_bdr_event_pa_eb {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 979px;
    top: 1341px;
    overflow: visible;
}
#recommended_events_card_1_img__eb {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 992px;
    top: 1352px;
    overflow: visible;
}
#free_event_name_event_page_pc_eb {
    fill: rgba(255,255,255,1);
}
.free_event_name_event_page_pc_eb {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 303px;
    top: 2496px;
}
#BiziBees_PVT_LTD_free_events_e_ec {
    left: 331px;
    top: 2502px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#free_events_card_1_bdr_event_p_ed {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 289px;
    top: 2196px;
    overflow: visible;
}
#free_events_card_1_event_page__ee {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 302px;
    top: 2207px;
    overflow: visible;
}
#free_event_name_event_page_pc_ef {
    fill: rgba(255,255,255,1);
}
.free_event_name_event_page_pc_ef {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 533px;
    top: 2496px;
}
#BiziBees_PVT_LTD_free_events_e_eg {
    left: 561px;
    top: 2502px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#free_events_card_1_bdr_event_p_eh {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 519px;
    top: 2196px;
    overflow: visible;
}
#free_events_card_1_event_page__ei {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 532px;
    top: 2207px;
    overflow: visible;
}
#free_event_name_event_page_pc_ej {
    fill: rgba(255,255,255,1);
}
.free_event_name_event_page_pc_ej {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 763px;
    top: 2496px;
}
#BiziBees_PVT_LTD_free_events_e_ek {
    left: 791px;
    top: 2502px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#free_events_card_1_bdr_event_p_el {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 749px;
    top: 2196px;
    overflow: visible;
}
#free_events_card_1_event_page__em {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 762px;
    top: 2207px;
    overflow: visible;
}
#Group_119 {
    position: absolute;
    width: 200px;
    height: 353px;
    left: 979px;
    top: 2196px;
    overflow: visible;
}
#free_event_name_event_page_pc_eo {
    fill: rgba(255,255,255,1);
}
.free_event_name_event_page_pc_eo {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 14px;
    top: 300px;
}
#BiziBees_PVT_LTD_free_events_e_ep {
    left: 42px;
    top: 306px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#free_events_card_1_bdr_event_p_eq {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#free_events_card_1_event_page__er {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 13px;
    top: 11px;
    overflow: visible;
}
#card_1_recommened_lbl_event_pa_es {
    left: 78px;
    top: 1669px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_et {
    left: 309px;
    top: 1669px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_eu {
    left: 538px;
    top: 1669px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_ev {
    left: 769px;
    top: 1669px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_ew {
    left: 998px;
    top: 1669px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_ex {
    left: 79px;
    top: 2522px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_ey {
    left: 308px;
    top: 2522px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_ez {
    left: 538px;
    top: 2522px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_e {
    left: 768px;
    top: 2522px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_fa {
    left: 998px;
    top: 2522px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
.button {
--bg: #F85A47;
--hover-bg: #ffffff;
--hover-text: #000000;
color: #fff;
border: 1px solid var(--bg);
border-radius: 4px;
padding: 0.8em 2em;
background: var(--bg);
transition: 0.2s;
position: absolute;
left: 79%;
top: 77.2%;
width: 163px;
height: 58.292px;
}

.button:hover {
color: var(--hover-text);
-webkit-transform: translate(-0.25rem,-0.25rem);
        transform: translate(-0.25rem,-0.25rem);
background: var(--hover-bg);
box-shadow: 0.25rem 0.25rem var(--bg);
}

.button:active {
-webkit-transform: translate(0);
        transform: translate(0);
box-shadow: none;
}.button1 {
--bg: #F85A47;
--hover-bg: #ffffff;
--hover-text: #000000;
color: #fff;
border: 1px solid var(--bg);
border-radius: 4px;
padding: 0.8em 2em;
background: var(--bg);
transition: 0.2s;
position: absolute;
left: 79%;
top: 45.5%;
width: 163px;
height: 58.292px;
}

.button1:hover {
color: var(--hover-text);
-webkit-transform: translate(-0.25rem,-0.25rem);
        transform: translate(-0.25rem,-0.25rem);
background: var(--hover-bg);
box-shadow: 0.25rem 0.25rem var(--bg);
}

.button1:active {
-webkit-transform: translate(0);
        transform: translate(0);
box-shadow: none;
}
.mediaViewInfo {
    --web-view-name: sign up mob;
    --web-view-id: sign_up_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: sign_up_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#sign_up_mob {
    position: absolute;
    width: 430px;
    height: 932px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: sign up mob;
    --web-view-id: sign_up_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#over_all_bg_design {
    position: absolute;
    width: 1518px;
    height: 1033px;
    left: -66px;
    top: -6px;
    overflow: visible;
}
#design_circle_op_sign_up_mon {
    opacity: 0.75;
    fill: rgba(33,177,73,1);
}
.design_circle_op_sign_up_mon {
    position: absolute;
    overflow: visible;
    width: 472px;
    height: 207.259px;
    left: -20px;
    top: -87px;
}
#op_bg_design_op_sign_up_mon {
    fill: rgba(255,255,255,1);
}
.op_bg_design_op_sign_up_mon {
    position: absolute;
    overflow: visible;
    width: 349px;
    height: 316px;
    left: 37px;
    top: -87px;
    border-radius:  51px;
    background:  linear-gradient(315deg, #f0f0f0, #cacaca);
    box-shadow:   -23px -23px 43px #929292,
23px 23px 43px #ffffff;
}
#Rectangle_2_op_sign_up_mon {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rectangle_2_op_sign_up_mon {
    position: absolute;
    overflow: visible;
    width: 349px;
    height: 643px;
    left: 41px;
    top: 260px;
}
#image_op_sign_up_mon {
    position: absolute;
    width: 135px;
    height: 135px;
    left: 143px;
    top: 0px;
    overflow: visible;
}
#OPERATE_LIVE_op_sign_up_mon {
    left: 51px;
    top: 145px;
    position: absolute;
    overflow: visible;
    width: 319px;
    white-space: nowrap;
    line-height: 132px;
    margin-top: -46px;
    text-align: left;
    font-family: Cooper;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgba(33,177,73,1);
}
#txtFirst_Name_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtFirst_Name_mob {
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 48px;
    left: 77px;
    top: 385px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtEmail_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtEmail_mob {
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 48px;
    left: 77px;
    top: 540px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtMobile_No_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtMobile_No_mob {
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 48px;
    left: 77px;
    top: 617px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtEnter_Password_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtEnter_Password_mob {
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 48px;
    left: 77px;
    top: 695px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtConfirm_Password_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtConfirm_Password_mob {
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 48px;
    left: 77px;
    top: 772px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#profile_op_sign_up_mon {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 170px;
    top: 273px;
    border-radius: 50%;
    overflow: hidden;
}
#back_btn_sign_up_mob {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 56px;
    top: 273px;
    overflow: visible;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: index_page.html;
    cursor: pointer;
}
#sign_up_bdt {
    fill: rgba(33,177,73,1);
}
.sign_up_bdt {
    -webkit-filter: drop-shadow(3.857px 4.596px 13px rgba(0, 0, 0, 0.141));
            filter: drop-shadow(3.857px 4.596px 13px rgba(0, 0, 0, 0.141));
    position: absolute;
    overflow: visible;
    width: 135.879px;
    height: 40.968px;
    left: 148px;
    top: 842px;

}
#Sign_Up_btn_lbl {
    left: 184px;
    top: 851px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    line-height: 22px;
    margin-top: -2px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#txtNickName_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtNickName_mob {
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 48px;
    left: 76px;
    top: 462px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
}

.nickName_lbl_mob {
    position: absolute;
    overflow: visible;
    width: 100%;
    
    height: 20px;
    left: 48px;
    top: 510px;
    font-size: 15px;
    /* padding: 15px; */
    /* border: 2px solid darkcyan; */
}

.mediaViewInfo {
    --web-view-name: forgot page;
    --web-view-id: forgot_page_Mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: forgot_page_Mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#forgot_page_Mob {
    position: absolute;
    width: 430px;
    height: 932px;
    background-color: rgba(255,255,255,1);
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -40%); */
    overflow: hidden;
    --web-view-name: forgot page;
    --web-view-id: forgot_page_Mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Path_5_Mob_F {
    fill: rgba(52,227,105,1);
}
.Path_5_Mob_F {
    overflow: visible;
    position: absolute;
    width: 260.616px;
    height: 160.165px;
    left: 249px;
    top: -17.06px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Path_6_Mob_F {
    fill: rgba(119,221,150,1);
}
.Path_6_Mob_F {
    overflow: visible;
    position: absolute;
    width: 137.074px;
    height: 83.79px;
    left: 335.073px;
    top: -36.122px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Path_7_Mob_ {
    fill: rgba(213,213,213,1);
}
.Path_7_Mob_ {
    overflow: visible;
    position: absolute;
    width: 95.817px;
    height: 59.109px;
    left: 403.61px;
    top: 57.944px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Path_1_Mob_F {
    fill: rgba(52,227,105,1);
}
.Path_1_Mob_F {
    overflow: visible;
    position: absolute;
    width: 284.045px;
    height: 160.202px;
    left: 1.908px;
    top: 626.832px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Path_2_Mob_F {
    fill: rgba(119,221,150,1);
}
.Path_2_Mob_F {
    overflow: visible;
    position: absolute;
    width: 149.499px;
    height: 84.597px;
    left: 0px;
    top: 591px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Path_3_Mob_F {
    fill: rgba(229,228,228,1);
}
.Path_3_Mob_F {
    overflow: visible;
    position: absolute;
    width: 214.177px;
    height: 131.242px;
    left: 1.31px;
    top: 694.407px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}

#image_Mob_F {
    position: absolute;
    width: 135px;
    height: 135px;
    left: 148px;
    top: 135px;
    overflow: visible;
}
#Forgot_Password_Mob_F {
    left: 87px;
    top: 301px;
    position: absolute;
    overflow: visible;
    width: 258px;
    white-space: nowrap;
    line-height: 75.63333129882812px;
    margin-top: -20.316665649414062px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: rgba(1,1,1,1);
}
#Enter_the_email_address_associ_Mob_F {
    left: 40px;
    top: 374px;
    position: absolute;
    overflow: visible;
    width: 351px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#ema_Mob_F {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.ema_Mob_F {
    -webkit-filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 331.5px;
    height: 49.599px;
    left: 59px;
    top: 439px;
    font-size: 20px;
    padding: 15px;
    border: 1px solid darkcyan;
}
#Group_9_Mob_F {
    position: absolute;
    width: 253.519px;
    height: 37.724px;
    left: 88px;
    top: 538px;
    overflow: visible;
    border-radius: 50px;
}
#Rectangle_2_Mob_F {
    fill: rgba(119,221,150,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rectangle_2_Mob_F {
    -webkit-filter: drop-shadow(10px 7px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(10px 7px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 272.519px;
    height: 55.724px;
    left: 0px;
    top: 0px;
    cursor: pointer;
}
#Continue_Mob_F {
    left: 84.722px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 85px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
    cursor: pointer;
}
#Dont_have_an_account_Sign_up_Mob_F {
    left: 111px;
    top: 591px;
    position: absolute;
    overflow: visible;
    width: 210px;
    white-space: nowrap;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: sign_up.html;
    cursor: pointer;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Group_13_Mob_F {
    position: absolute;
    width: 60px;
    height: 27px;
    left: 325px;
    top: 497px;
    overflow: visible;
    cursor: pointer;
}
#Back_Mob_F {
    left: 19px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#back_M {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 0px;
    top: 6px;
    overflow: visible;
}
#previous_Mob_F {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 10%;
    top: 4%;
    overflow: visible;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: index_page.html;
    cursor: pointer;
}
.reset{
    text-align: center;
    font-family:Georgia, 'Times New Roman', Times, serif;
    color:white;
    padding-top: 5px;
    height:30px;
   background-color:#14c871;
}


.child{
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform:translate(-50%,-50%);
            transform:translate(-50%,-50%);
    background-color: rgb(247, 247, 247);
    
    border: 1px solid black;
  
   
}



	.FlamingoFiesta_Mob_mediaViewInfo {
		--web-view-name: web 2 mob;
		--web-view-id: web_2_mob;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: web_2_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#FlamingoFiesta_Mob_web_2_mob {
		position: absolute;
		width: 428px;
		height: 4857px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: web 2 mob;
		--web-view-id: web_2_mob;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#FlamingoFiesta_Mob_Dotted_LIne2_mob_2web {
		fill: transparent;
		stroke: rgba(209,209,209,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 7;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
.FlamingoFiesta_Mob_Dotted_LIne2_mob_2web {
		overflow: visible;
		position: absolute;
		width: 289.048px;
		height: 380.08px;
		left: -41.075px;
		top: 1405.39px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_rec_first_img_bdr {
		fill: rgba(243,123,123,1);
	}
.FlamingoFiesta_Mob_rec_first_img_bdr {
		overflow: visible;
		position: absolute;
		width: 456.324px;
		height: 403.83px;
		-webkit-transform: matrix(1,0,0,1,115.1765,-71.8828) rotate(-8deg);
		        transform: matrix(1,0,0,1,115.1765,-71.8828) rotate(-8deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#FlamingoFiesta_Mob_top_first_img_bdr_vircle_mob_2 {
		fill: rgba(243,123,123,1);
	}
.FlamingoFiesta_Mob_top_first_img_bdr_vircle_mob_2 {
		overflow: visible;
		position: absolute;
		width: 294.028px;
		height: 244.52px;
		-webkit-transform: matrix(1,0,0,1,47.0962,117.275) rotate(-5deg);
		        transform: matrix(1,0,0,1,47.0962,117.275) rotate(-5deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#FlamingoFiesta_Mob_Mohammed_Affan_web_2_mob_2 {
		left: 70px;
		top: 393px;
		position: absolute;
		overflow: visible;
		width: 204px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_Scroll_intro_mob_website_2 {
		mix-blend-mode: normal;
		position: absolute;
		width: 327px;
		height: 92px;
		left: 70px;
		top: 423px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FlamingoFiesta_Mob_Bridging_the_gap_between_desig {
		left: 0px;
		top: -1px;
		position: absolute; 
		overflow: visible;
		width: 302px;
		white-space: pre-wrap;
		line-height: 21px;
		margin-top: -4px;
		text-align: left;
		font-family: Acumin Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_view_work_button_website_mob_2 {
		position: absolute;
		width: 90px;
		height: 31px;
		left: 70px;
		top: 517px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_bdr_view_mob_2 {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_bdr_view_mob_2 {
		position: absolute;
		overflow: visible;
		width: 90px;
		height: 31px;
		left: 0px;
		top: 0px;
	}
	#FlamingoFiesta_Mob_View_Work_lbl_2_mob_2 {
		left: 15px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 48px;
		white-space: nowrap;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(255,255,255,1);
	}
	#FlamingoFiesta_Mob_Our_Objective_lbl_mob_2_websti {
		left: 138px;
		top: 641px;
		position: absolute;
		overflow: visible;
		width: 153px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_Vision_lbl_website_2_mob_2 {
		left: 64px;
		top: 1012.981px;
		position: absolute;
		overflow: visible;
		width: 49px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_Scroll_Vision_desc_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 344px;
		height: 129px;
		left: 57px;
		top: 1049.981px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FlamingoFiesta_Mob_Theres_no_style_over_substance {
		left: 7px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 328px;
		height: 71px;
		line-height: 28px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_Dotted_LIne1_mob_2 {
		fill: transparent;
		stroke: rgba(209,209,209,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 7;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
.FlamingoFiesta_Mob_Dotted_LIne1_mob_2 {
		overflow: visible;
		position: absolute;
		width: 253.365px;
		height: 322.019px;
		left: 219px;
		top: 919px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_grop_mob_2 {
		position: absolute;
		width: 368px;
		height: 163.487px;
		left: 33.5px;
		top: 1510.046px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_Principles_mob_2 {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_Scroll_principle_profile_page_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 368px;
		height: 129px;
		left: 0px;
		top: 34.487px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FlamingoFiesta_Mob_Its_about_organizing_the_conte {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 352px;
		height: 99px;
		line-height: 28px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_Mission_lbl_mob_2 {
		left: 50px;
		top: 1921.432px;
		position: absolute;
		overflow: visible;
		width: 62px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_Scroll_mission_mob_2 {
		mix-blend-mode: normal;
		position: absolute;
		width: 342px;
		height: 129px;
		left: 43px;
		top: 1960.115px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FlamingoFiesta_Mob_As_a_designer_and_developer_I_ {
		left: 7px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 336px;
		white-space: nowrap;
		line-height: 28px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_View_Work {
		position: absolute;
		width: 95px;
		height: 29px;
		left: 167px;
		top: 3937px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_Rectangle_88 {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_Rectangle_88 {
		position: absolute;
		overflow: visible;
		width: 95px;
		height: 29px;
		left: 0px;
		top: 0px;
	}
	#FlamingoFiesta_Mob_View_All {
		left: 25px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 46px;
		white-space: nowrap;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#FlamingoFiesta_Mob_My_Offerings_lbl_website_2_mob {
		left: 160px;
		top: 2374px;
		position: absolute;
		overflow: visible;
		width: 119px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 19px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_scroll_my_offerings_mob_web_2 {
        mix-blend-mode: normal;
		position: absolute;
		width: 376.352px;
		height: 1486px;
		left: 52.324px;
		top: 2427px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FlamingoFiesta_Mob_Project1_mob_2 {
		position: relative;
		width: 280px;
		height: 316.214px;
		left: 21.676px;
		top: 40px;
     
		overflow: visible;
	}
	#FlamingoFiesta_Mob_Our_businness_partner_lbl_webs {
		left: 112px;
		top: 2143px;
		position: absolute;
		overflow: visible;
		width: 206px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FlamingoFiesta_Mob_partern_bdr_website_2_mob_2 {
		fill: transparent;
	}
.FlamingoFiesta_Mob_partern_bdr_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 130px;
		left: 0px;
		top: 2203px;
		background:  rgba( 255, 255, 255, 0.5 );
		box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
		backdrop-filter:  blur( 10px );
		-webkit-backdrop-filter:  blur( 10px );
		border-radius:  10px;
		border:  1px solid rgba( 255, 255, 255, 0.18 );
	}
	#FlamingoFiesta_Mob_Group_305 {
		position: absolute;
		width: 221px;
		height: 185.586px;
		left: 113px;
		top: 4444.766px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_Start_a_new_project_grp_mob_2 {
		position: absolute;
		width: 179px;
		height: 42px;
		left: 14px;
		top: 86.691px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_bdr_start_mob_2 {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_bdr_start_mob_2 {
		position: absolute;
		overflow: visible;
		width: 179px;
		height: 42px;
		left: 0px;
		top: 0px;
	}
	#FlamingoFiesta_Mob_Start_A_New_Project_mob_2 {
		left: 28px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 130px;
		white-space: nowrap;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#FlamingoFiesta_Mob_Willing_to_Grow_by_Partnering_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 222px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(54,54,54,1);
	}
	#FlamingoFiesta_Mob_Linkedin_website_2_mob_2 {
		position: absolute;
		width: 25px;
		height: 22.728px;
		left: 142px;
		top: 162.857px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_i_line_mob_2 {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_i_line_mob_2 {
		overflow: visible;
		position: absolute;
		width: 4.873px;
		height: 15.44px;
		left: 0.631px;
		top: 7.288px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_n_mob_mob_2 {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_n_mob_mob_2 {
		overflow: visible;
		position: absolute;
		width: 16.522px;
		height: 15.588px;
		left: 8.478px;
		top: 7.141px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_n_i_dot_mob_2 {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_n_i_dot_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.915px;
		height: 5.357px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_instagram_website_2_mob_2 {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 91px;
		top: 159.234px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_whatsapp_website_2_mob {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 40px;
		top: 158.798px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_copy_website_2_mob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
.FlamingoFiesta_Mob_copy_website_2_mob {
		overflow: visible;
		position: absolute;
		width: 324px;
		height: 1px;
		left: 52px;
		top: 4754.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_n_Copyright_Operate_Live_websi_ff {
		left: 52.5px;
		top: 4778px;
		position: absolute;
		overflow: visible;
		width: 280px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#FlamingoFiesta_Mob_partner_img_and_lbl_scroll_web {
		mix-blend-mode: normal;
		position: absolute;
		width: 324px;
		height: 64.849px;
		left: 52px;
		top: 2236px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#FlamingoFiesta_Mob_bg_partner_mobwebsite_2_mob_2 {
		fill: transparent;
	}
.FlamingoFiesta_Mob_bg_partner_mobwebsite_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 130px;
		left: -89px;
		top: -50px;
		background:  rgba( 255, 255, 255, 0.5 );
		box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
		backdrop-filter:  blur( 10px );
		-webkit-backdrop-filter:  blur( 10px );
		border-radius:  10px;
		border:  1px solid rgba( 255, 255, 255, 0.18 );
	}
	#FlamingoFiesta_Mob_google_lbl_partner_mob_website {
		
		position: relative;
		overflow: visible;
		width: 146px;
		height: 27px;
		left: 34px;
		top: 9px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#FlamingoFiesta_Mob_microsoft_lbl_partnw_mobwebsit {
		left: 214px;
		top: 9px;
		position: relative;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#FlamingoFiesta_Mob_microscope_lbl_mob_website_2_m {
		left: 394px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#FlamingoFiesta_Mob_google_1_mob_website_2_mob_2 {
		position: relative;
		width: 25px;
		height: 25px;
		left: 4px;
		top: 10px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_microsoft_mobwebsite_2_mob_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 184px;
		top: 10px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_scope_mob_website_2_mob_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 364px;
		top: 10px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_google_mob_website_2_mob_2 {
		left: 576px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#FlamingoFiesta_Mob_microsoft_1_mob_website_2_mob_ {
		left: 756px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#FlamingoFiesta_Mob_micro_1_website_1_mob_website_ {
		left: 936px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#FlamingoFiesta_Mob_google_1_1_mob_website_2_mob_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 546px;
		top: 10px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_microsoft_1_mob_website_2_mob__c {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 726px;
		top: 10px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_scope_1_mob_website_2_mob_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 906px;
		top: 10px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_visin_img_website_2_mob_2 {
		position: absolute;
		width: 363.171px;
		height: 261.856px;
		left: 32px;
		top: 731.125px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_bg_deisgn {
		opacity: 0.1;
		fill: rgba(239,122,120,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_bg_deisgn {
		overflow: visible;
		position: absolute;
		width: 363.171px;
		height: 180.627px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_shadow_vision_img_website_2__m {
		opacity: 0.1;
		fill: rgba(56,211,159,1);
	}
.FlamingoFiesta_Mob_shadow_vision_img_website_2__m {
		position: absolute;
		overflow: visible;
		width: 156.849px;
		height: 11.152px;
		left: 16.144px;
		top: 250.704px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_hand_left {
		fill: rgba(251,190,190,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_hand_left {
		overflow: visible;
		position: absolute;
		width: 16.712px;
		height: 21.844px;
		left: 104.712px;
		top: 44.463px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_left_hand {
		fill: rgba(241,90,90,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_left_hand {
		overflow: visible;
		position: absolute;
		width: 42.567px;
		height: 37.488px;
		left: 71.148px;
		top: 62.485px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_cent {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_cent {
		overflow: visible;
		position: absolute;
		width: 61.732px;
		height: 61.732px;
		left: 169.646px;
		top: 86.732px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_12_m {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_12_m {
		position: absolute;
		overflow: visible;
		width: 7.231px;
		height: 7.626px;
		left: 195.944px;
		top: 79.501px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_11_m {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_11_m {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,213.4367,93.6228) rotate(-59.003deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,213.4367,93.6228) rotate(-59.003deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_0_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_0_mo {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,226.1496,106.0095) rotate(-29deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,226.1496,106.0095) rotate(-29deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_9_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_9_mo {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(0.9999,0,0,0.9999,226.517,140.5845) rotate(-57.999deg);
		        transform: translate(-0.026px, -9.534px) matrix(0.9999,0,0,0.9999,226.517,140.5845) rotate(-57.999deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_8_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_8_mo {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,167.5016,105.8012) rotate(-61deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,167.5016,105.8012) rotate(-61deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_7_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_7_mo {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(0.9999,0,0,0.9999,167.144,140.756) rotate(-32.001deg);
		        transform: translate(-0.026px, -9.534px) matrix(0.9999,0,0,0.9999,167.144,140.756) rotate(-32.001deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_6_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_6_mo {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,180.2201,93.4649) rotate(-30.997deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,180.2201,93.4649) rotate(-30.997deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_5_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_5_mo {
		position: absolute;
		overflow: visible;
		width: 7.231px;
		height: 7.626px;
		left: 195.944px;
		top: 148.104px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_4_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_4_mo {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,213.6363,153.2303) rotate(-30.997deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,213.6363,153.2303) rotate(-30.997deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_3_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_3_mo {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,179.9871,153.4004) rotate(-59.003deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,179.9871,153.4004) rotate(-59.003deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_2_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_2_mo {
		position: absolute;
		overflow: visible;
		width: 8.778px;
		height: 6.476px;
		left: 161.66px;
		top: 114.576px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spin_mob_ {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spin_mob_ {
		position: absolute;
		overflow: visible;
		width: 8.778px;
		height: 6.476px;
		left: 230.623px;
		top: 114.576px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_cente {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_cente {
		overflow: visible;
		position: absolute;
		width: 61.732px;
		height: 61.732px;
		left: 227.169px;
		top: 42.16px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_12_mo {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_12_mo {
		position: absolute;
		overflow: visible;
		width: 7.231px;
		height: 7.626px;
		left: 253.467px;
		top: 34.929px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi11_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi11_mob {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,270.9659,49.0555) rotate(-59.003deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,270.9659,49.0555) rotate(-59.003deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_0_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_0_mob {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,283.6756,61.4423) rotate(-29deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,283.6756,61.4423) rotate(-29deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_9_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_9_mob {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(0.9999,0,0,0.9999,284.016,96.0005) rotate(-57.999deg);
		        transform: translate(-0.026px, -9.534px) matrix(0.9999,0,0,0.9999,284.016,96.0005) rotate(-57.999deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_8_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_8_mob {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,225.0269,61.235) rotate(-61deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,225.0269,61.235) rotate(-61deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_7_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_7_mob {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(0.9999,0,0,0.9999,224.6666,96.1899) rotate(-32.001deg);
		        transform: translate(-0.026px, -9.534px) matrix(0.9999,0,0,0.9999,224.6666,96.1899) rotate(-32.001deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_6_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_6_mob {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,237.7504,48.8963) rotate(-30.997deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,237.7504,48.8963) rotate(-30.997deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_5_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_5_mob {
		position: absolute;
		overflow: visible;
		width: 7.231px;
		height: 7.626px;
		left: 253.467px;
		top: 103.532px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_4_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_4_mob {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,271.1654,108.662) rotate(-30.997deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,271.1654,108.662) rotate(-30.997deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_3_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_3_mob {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,237.5316,108.7988) rotate(-59.003deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,237.5316,108.7988) rotate(-59.003deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_2_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_2_mob {
		position: absolute;
		overflow: visible;
		width: 8.778px;
		height: 6.476px;
		left: 219.183px;
		top: 70.003px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spi_1_mob {
		fill: rgba(63,61,86,1);
	}
.FlamingoFiesta_Mob_vision_img_website_2_spi_1_mob {
		position: absolute;
		overflow: visible;
		width: 8.778px;
		height: 6.476px;
		left: 288.146px;
		top: 70.003px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral_ce {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral_ce {
		overflow: visible;
		position: absolute;
		width: 61.768px;
		height: 61.732px;
		left: 112.051px;
		top: 40.252px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral12_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral12_ {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,139.4155,42.7621) rotate(-88.01deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,139.4155,42.7621) rotate(-88.01deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral11_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral11_ {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,156.9426,47.7603) rotate(-57.011deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,156.9426,47.7603) rotate(-57.011deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral0_m {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral0_m {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,169.1891,60.5487) rotate(-27.008deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,169.1891,60.5487) rotate(-27.008deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral_9_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral_9_ {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(0.9999,0,0,0.9999,168.3766,95.0967) rotate(-56.011deg);
		        transform: translate(-0.026px, -9.534px) matrix(0.9999,0,0,0.9999,168.3766,95.0967) rotate(-56.011deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral8_m {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral8_m {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,110.6039,58.3066) rotate(-59.011deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,110.6039,58.3066) rotate(-59.011deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral7_m {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral7_m {
		width: 7.663px;
		height: 7.267px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,108.4876,93.8188) rotate(-29.888deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,108.4876,93.8188) rotate(-29.888deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral6_m {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral6_m {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,123.7327,46.3949) rotate(-29.013deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,123.7327,46.3949) rotate(-29.013deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral5_m {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral5_m {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,137.0354,111.2917) rotate(-88.01deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,137.0354,111.2917) rotate(-88.01deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral4_m {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral4_m {
		width: 7.231px;
		height: 7.627px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,155.0701,107.2981) rotate(-29.013deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,155.0701,107.2981) rotate(-29.013deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral3_m {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral3_m {
		width: 7.627px;
		height: 7.231px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,121.476,106.3242) rotate(-57.011deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,121.476,106.3242) rotate(-57.011deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral_2_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral_2_ {
		width: 6.475px;
		height: 8.778px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,105.3302,75.2627) rotate(-88.01deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,105.3302,75.2627) rotate(-88.01deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_spiral_1_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_spiral_1_ {
		width: 6.475px;
		height: 8.778px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,174.2428,77.6421) rotate(-88.01deg);
		        transform: translate(-0.026px, -9.534px) matrix(1,0,0,1,174.2428,77.6421) rotate(-88.01deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_shoe_2_mo {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_shoe_2_mo {
		overflow: visible;
		position: absolute;
		width: 19.367px;
		height: 28.235px;
		left: 52.687px;
		top: 228.098px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_shoe_mob_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_shoe_mob_ {
		overflow: visible;
		position: absolute;
		width: 32.148px;
		height: 17.451px;
		left: 117.556px;
		top: 241.638px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_neck_mob_ {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_neck_mob_ {
		overflow: visible;
		position: absolute;
		width: 26.082px;
		height: 20.686px;
		left: 57.154px;
		top: 80.22px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_face_mob_ {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_face_mob_ {
		position: absolute;
		overflow: visible;
		width: 26.333px;
		height: 26.333px;
		left: 52.765px;
		top: 60.686px;
	}
	#FlamingoFiesta_Mob_vision_img_website_2_shirt_mob {
		fill: rgba(241,90,90,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_shirt_mob {
		overflow: visible;
		position: absolute;
		width: 68.139px;
		height: 56.77px;
		left: 53.662px;
		top: 92.443px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_hand_2_mo {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_hand_2_mo {
		overflow: visible;
		position: absolute;
		width: 16.712px;
		height: 21.844px;
		left: 100.395px;
		top: 44.463px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_leg_1_mob {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_leg_1_mob {
		overflow: visible;
		position: absolute;
		width: 87.024px;
		height: 103.391px;
		left: 65.788px;
		top: 138.067px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_hand_1__m {
		fill: rgba(241,90,90,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_hand_1__m {
		overflow: visible;
		position: absolute;
		width: 57.23px;
		height: 53.206px;
		left: 52.375px;
		top: 61.19px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_vision_img_website_2_hair_mob_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_vision_img_website_2_hair_mob_ {
		overflow: visible;
		position: absolute;
		width: 34.455px;
		height: 67.963px;
		left: 38.268px;
		top: 55.139px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_grp_mo {
		position: absolute;
		width: 315.031px;
		height: 261.855px;
		left: 56.484px;
		top: 1227.507px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_bg_des {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_bg_des {
		overflow: visible;
		position: absolute;
		width: 252.678px;
		height: 169.134px;
		left: 20.692px;
		top: 17.251px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_leaves_principle_img_website_2 {
		opacity: 0.6;
		position: absolute;
		width: 53.751px;
		height: 67.672px;
		left: 53.539px;
		top: 28.884px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_s {
		fill: transparent;
		stroke: rgba(83,84,97,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_s {
		overflow: visible;
		position: absolute;
		width: 45.202px;
		height: 41.916px;
		left: 10.332px;
		top: 26.659px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf__ {
		fill: rgba(239,174,171,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf__ {
		overflow: visible;
		position: absolute;
		width: 10.611px;
		height: 10.437px;
		left: 0px;
		top: 16.327px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_1 {
		fill: rgba(239,174,171,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_1 {
		overflow: visible;
		position: absolute;
		width: 12.928px;
		height: 8.293px;
		left: 2.286px;
		top: 29.053px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_1_et {
		fill: rgba(239,174,171,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_1_et {
		overflow: visible;
		position: absolute;
		width: 12.601px;
		height: 8.264px;
		left: 15.866px;
		top: 44.161px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_1_eu {
		fill: rgba(239,174,171,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_1_eu {
		overflow: visible;
		position: absolute;
		width: 12.962px;
		height: 8.282px;
		left: 31.059px;
		top: 52.543px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_1_ev {
		fill: rgba(239,174,171,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_1_ev {
		overflow: visible;
		position: absolute;
		width: 8.3px;
		height: 13.078px;
		left: 13.296px;
		top: 24.88px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_1_ew {
		fill: rgba(239,174,171,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_1_ew {
		overflow: visible;
		position: absolute;
		width: 8.246px;
		height: 12.682px;
		left: 30.341px;
		top: 33.891px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_1_ex {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_1_ex {
		overflow: visible;
		position: absolute;
		width: 10.611px;
		height: 10.437px;
		left: 0px;
		top: 16.327px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_1_ey {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_1_ey {
		overflow: visible;
		position: absolute;
		width: 12.928px;
		height: 8.293px;
		left: 2.286px;
		top: 29.053px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_9 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_9 {
		overflow: visible;
		position: absolute;
		width: 12.601px;
		height: 8.264px;
		left: 15.866px;
		top: 44.161px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_8 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_8 {
		overflow: visible;
		position: absolute;
		width: 12.962px;
		height: 8.282px;
		left: 31.059px;
		top: 52.543px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_7 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_7 {
		overflow: visible;
		position: absolute;
		width: 8.3px;
		height: 13.078px;
		left: 13.296px;
		top: 24.88px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_6 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_6 {
		overflow: visible;
		position: absolute;
		width: 8.246px;
		height: 12.682px;
		left: 30.341px;
		top: 33.891px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_stem_m {
		fill: transparent;
		stroke: rgba(83,84,97,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_stem_m {
		overflow: visible;
		position: absolute;
		width: 25.083px;
		height: 55.165px;
		left: 30.141px;
		top: 12.29px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_5 {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_5 {
		overflow: visible;
		position: absolute;
		width: 8.288px;
		height: 12.43px;
		left: 22.823px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_4 {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_4 {
		overflow: visible;
		position: absolute;
		width: 11.466px;
		height: 9.304px;
		left: 19.756px;
		top: 12.507px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_3 {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_3 {
		overflow: visible;
		position: absolute;
		width: 13.056px;
		height: 8.289px;
		left: 26.083px;
		top: 31.768px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_2 {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_2 {
		overflow: visible;
		position: absolute;
		width: 12.996px;
		height: 8.338px;
		left: 36.361px;
		top: 45.539px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_leaf_1_e {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_leaf_1_e {
		overflow: visible;
		position: absolute;
		width: 8.346px;
		height: 12.342px;
		left: 31.472px;
		top: 13.096px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_card__ {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_card__ {
		overflow: visible;
		position: absolute;
		width: 64.537px;
		height: 92.987px;
		left: 88.958px;
		top: 56.773px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_x_2mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_x_2mob {
		overflow: visible;
		position: absolute;
		width: 15.27px;
		height: 19.732px;
		left: 113.374px;
		top: 64.379px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_x_1_mo {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_x_1_mo {
		overflow: visible;
		position: absolute;
		width: 12.937px;
		height: 18.811px;
		left: 115.955px;
		top: 64.379px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_box_5_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_box_5_ {
		overflow: visible;
		position: absolute;
		width: 40.757px;
		height: 10.765px;
		left: 100.749px;
		top: 94.477px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2__tick_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2__tick_ {
		overflow: visible;
		position: absolute;
		width: 30.644px;
		height: 10.765px;
		left: 105.806px;
		top: 125.516px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_box_4_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_box_4_ {
		overflow: visible;
		position: absolute;
		width: 40.967px;
		height: 10.947px;
		left: 100.749px;
		top: 108.614px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2__tick2 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2__tick2 {
		overflow: visible;
		position: absolute;
		width: 6.686px;
		height: 6.293px;
		left: 117.071px;
		top: 127.085px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_design {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_design {
		overflow: visible;
		position: absolute;
		width: 64.674px;
		height: 91.627px;
		left: 182.652px;
		top: 58.555px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_box_3_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_box_3_ {
		overflow: visible;
		position: absolute;
		width: 40.757px;
		height: 6.162px;
		left: 195.261px;
		top: 96.744px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_design_fi {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_design_fi {
		overflow: visible;
		position: absolute;
		width: 41.009px;
		height: 6.26px;
		left: 195.261px;
		top: 104.19px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_mob_2 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 57.953px;
		height: 3.383px;
		left: 188.32px;
		top: 65.077px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_box_2_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_box_2_ {
		overflow: visible;
		position: absolute;
		width: 5.852px;
		height: 4.563px;
		left: 190.375px;
		top: 60.289px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_box_mo {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_box_mo {
		overflow: visible;
		position: absolute;
		width: 17.411px;
		height: 18.842px;
		left: 191.738px;
		top: 71.529px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_arrow_ {
		fill: transparent;
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_arrow_ {
		overflow: visible;
		position: absolute;
		width: 16.206px;
		height: 1.345px;
		left: 158.397px;
		top: 97.654px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_arrow__fn {
		fill: transparent;
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_principle_img_website_2_arrow__fn {
		overflow: visible;
		position: absolute;
		width: 8.441px;
		height: 14.377px;
		left: 172.696px;
		top: 90.641px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_circle {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_circle {
		position: absolute;
		overflow: visible;
		width: 15.066px;
		height: 15.066px;
		left: 70.617px;
		top: 0px;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_circle_fp {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_circle_fp {
		position: absolute;
		overflow: visible;
		width: 15.066px;
		height: 15.066px;
		left: 185.391px;
		top: 10.428px;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_mob_2_fq {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_mob_2_fq {
		position: absolute;
		overflow: visible;
		width: 15.066px;
		height: 15.066px;
		left: 266.022px;
		top: 122.167px;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_cmob_2 {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_cmob_2 {
		width: 25.319px;
		height: 25.25px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-59px, 0px) matrix(1,0,0,1,346.8815,83.4361) rotate(-80.782deg);
		        transform: translate(-59px, 0px) matrix(1,0,0,1,346.8815,83.4361) rotate(-80.782deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_cirlce {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_cirlce {
		position: absolute;
		overflow: visible;
		width: 25.319px;
		height: 25.319px;
		left: 261.187px;
		top: 17.974px;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_circle_ft {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_circle_ft {
		position: absolute;
		overflow: visible;
		width: 25.319px;
		height: 25.319px;
		left: 0px;
		top: 100.725px;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_body_s_fv {
		fill: url(#FlamingoFiesta_Mob_principle_img_website_2_body_s_fv);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_body_s_fv {
		overflow: visible;
		position: absolute;
		width: 69.347px;
		height: 174.298px;
		left: 189.227px;
		top: 85.567px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_pen_mo {
		fill: rgba(101,97,125,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_pen_mo {
		overflow: visible;
		position: absolute;
		width: 11.518px;
		height: 12.17px;
		left: 199.242px;
		top: 92.196px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_hand_2 {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_hand_2 {
		width: 5.222px;
		height: 14.921px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-59px, 0px) matrix(0.9999,0,0,0.9999,263.8187,91.9697) rotate(-11.579deg);
		        transform: translate(-59px, 0px) matrix(0.9999,0,0,0.9999,263.8187,91.9697) rotate(-11.579deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_shoemo {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_shoemo {
		overflow: visible;
		position: absolute;
		width: 15.8px;
		height: 7.392px;
		left: 187.57px;
		top: 253.84px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_face_m {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_face_m {
		position: absolute;
		overflow: visible;
		width: 19.304px;
		height: 19.304px;
		left: 225.681px;
		top: 87.16px;
	}
	#FlamingoFiesta_Mob_principle_img_website_2_hand_m {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_hand_m {
		overflow: visible;
		position: absolute;
		width: 10.063px;
		height: 23.609px;
		left: 244.248px;
		top: 150.607px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_hand_s {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_hand_s {
		overflow: visible;
		position: absolute;
		width: 10.609px;
		height: 13.306px;
		left: 204.837px;
		top: 97.914px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_shirt_ {
		fill: rgba(190,190,190,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_shirt_ {
		overflow: visible;
		position: absolute;
		width: 33.618px;
		height: 52.95px;
		left: 217.52px;
		top: 107.991px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_top_sh {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_top_sh {
		overflow: visible;
		position: absolute;
		width: 40.801px;
		height: 33.053px;
		left: 217.94px;
		top: 111.961px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_left_h {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_left_h {
		overflow: visible;
		position: absolute;
		width: 6.413px;
		height: 5.58px;
		left: 209.034px;
		top: 105.641px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_left_a {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_left_a {
		overflow: visible;
		position: absolute;
		width: 12.98px;
		height: 17.35px;
		left: 209.576px;
		top: 105.932px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_pant_m {
		fill: rgba(51,49,63,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_pant_m {
		overflow: visible;
		position: absolute;
		width: 56px;
		height: 105.415px;
		left: 193.229px;
		top: 156.441px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2arm_mob {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2arm_mob {
		overflow: visible;
		position: absolute;
		width: 7.367px;
		height: 3.465px;
		left: 246.943px;
		top: 150.561px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_should {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_should {
		overflow: visible;
		position: absolute;
		width: 11.727px;
		height: 29.102px;
		left: 246.489px;
		top: 124.364px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_principle_img_website_2_hair_m {
		fill: rgba(51,49,63,1);
	}
	.FlamingoFiesta_Mob_principle_img_website_2_hair_m {
		overflow: visible;
		position: absolute;
		width: 33.635px;
		height: 53.009px;
		left: 221.717px;
		top: 83.387px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_mission_all_grp_website_2_mob_ {
		position: absolute;
		width: 323.27px;
		height: 166.054px;
		left: 52px;
		top: 1735.377px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_pc_stand_2_website_2mob_2 {
		fill: rgba(188,188,188,1);
	}
	.FlamingoFiesta_Mob_pc_stand_2_website_2mob_2 {
		overflow: visible;
		position: absolute;
		width: 51.913px;
		height: 30.615px;
		left: 135.679px;
		top: 122.461px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pc_sfcreen_website_2_mob_2 {
		fill: rgba(82,83,96,1);
	}
	.FlamingoFiesta_Mob_pc_sfcreen_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 177.036px;
		height: 107.819px;
		left: 73.116px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pc_border_website_2mob_2 {
		fill: rgba(188,188,188,1);
	}
	.FlamingoFiesta_Mob_pc_border_website_2mob_2 {
		overflow: visible;
		position: absolute;
		width: 177.036px;
		height: 16.639px;
		left: 73.117px;
		top: 107.818px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pc_logo_website_2mob_2 {
		fill: rgba(82,83,96,1);
	}
	.FlamingoFiesta_Mob_pc_logo_website_2mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.99px;
		height: 5.99px;
		left: 158.639px;
		top: 113.143px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pc_stand_down_website_2mob_2 {
		fill: rgba(188,188,188,1);
	}
	.FlamingoFiesta_Mob_pc_stand_down_website_2mob_2 {
		overflow: visible;
		position: absolute;
		width: 62.562px;
		height: 6.655px;
		left: 130.354px;
		top: 144.091px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_center_screen_white_website_2m {
		fill: rgba(255,255,255,1);
	}
	.FlamingoFiesta_Mob_center_screen_white_website_2m {
		overflow: visible;
		position: absolute;
		width: 107px;
		height: 61.217px;
		left: 108.134px;
		top: 23.128px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_box_1_website_2mob_2 {
		fill: rgba(63,117,225,1);
	}
	.FlamingoFiesta_Mob_screen_box_1_website_2mob_2 {
		overflow: visible;
		position: absolute;
		width: 11.793px;
		height: 10.615px;
		left: 137.941px;
		top: 31.916px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_box_1_line_top_website_ {
		fill: rgba(223,223,223,1);
	}
	.FlamingoFiesta_Mob_screen_box_1_line_top_website_ {
		overflow: visible;
		position: absolute;
		width: 31.843px;
		height: 1.178px;
		left: 153.272px;
		top: 31.916px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_box_1_line_down_website {
		fill: rgba(223,223,223,1);
	}
	.FlamingoFiesta_Mob_screen_box_1_line_down_website {
		overflow: visible;
		position: absolute;
		width: 26.469px;
		height: 1.178px;
		left: 153.272px;
		top: 35.457px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_box_2_website_2mob_2 {
		fill: rgba(63,117,225,1);
	}
	.FlamingoFiesta_Mob_screen_box_2_website_2mob_2 {
		overflow: visible;
		position: absolute;
		width: 11.793px;
		height: 10.615px;
		left: 137.941px;
		top: 48.429px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_box_line_top_2_website_ {
		fill: rgba(223,223,223,1);
	}
	.FlamingoFiesta_Mob_screen_box_line_top_2_website_ {
		overflow: visible;
		position: absolute;
		width: 31.843px;
		height: 1.178px;
		left: 153.272px;
		top: 48.429px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_box_line_2_website_2mob {
		fill: rgba(223,223,223,1);
	}
	.FlamingoFiesta_Mob_screen_box_line_2_website_2mob {
		overflow: visible;
		position: absolute;
		width: 31.843px;
		height: 1.178px;
		left: 153.272px;
		top: 51.966px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_box_3_website_2 {
		fill: rgba(63,117,225,1);
	}
	.FlamingoFiesta_Mob_screen_box_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 11.793px;
		height: 10.615px;
		left: 137.941px;
		top: 64.94px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_section_2_line_website_ {
		fill: rgba(223,223,223,1);
	}
	.FlamingoFiesta_Mob_screen_section_2_line_website_ {
		overflow: visible;
		position: absolute;
		width: 31.843px;
		height: 1.178px;
		left: 153.272px;
		top: 64.94px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screeen_section_line_website_2 {
		fill: rgba(223,223,223,1);
	}
	.FlamingoFiesta_Mob_screeen_section_line_website_2 {
		overflow: visible;
		position: absolute;
		width: 32.056px;
		height: 1.178px;
		left: 153.272px;
		top: 68.479px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_dot_website_2_mob_2 {
		fill: rgba(56,210,159,1);
	}
	.FlamingoFiesta_Mob_screen_dot_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 2.662px;
		height: 2.662px;
		left: 160.303px;
		top: 23.96px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_top_dot_website_2_mob_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_screen_top_dot_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 3.662px;
		height: 3.662px;
		left: 160.303px;
		top: 12.978px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_top_line_2_website_mob_ {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_screen_top_line_2_website_mob_ {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 7.321px;
		left: 161.634px;
		top: 5.657px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_top_line_1_website_2_mo {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_screen_top_line_1_website_2_mo {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 9.607px;
		left: 161.634px;
		top: 15.684px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_top_down_dot_website_2_ {
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_screen_top_down_dot_website_2_ {
		overflow: visible;
		position: absolute;
		width: 2.662px;
		height: 2.662px;
		left: 160.303px;
		top: 23.96px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_scrren_top_dot_website_2_mob_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_scrren_top_dot_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 3.662px;
		height: 3.662px;
		left: 160.303px;
		top: 12.978px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_line_down_website_2_mob {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_screen_line_down_website_2_mob {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 16.048px;
		left: 161.634px;
		top: 82.528px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_dot_down_website_2_mob_ {
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_screen_dot_down_website_2_mob_ {
		overflow: visible;
		position: absolute;
		width: 2.662px;
		height: 2.662px;
		left: 160.303px;
		top: 81.197px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_line_left_website_2_mob {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_screen_line_left_website_2_mob {
		overflow: visible;
		position: absolute;
		width: 16.469px;
		height: 2px;
		left: 94.417px;
		top: 55.074px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_dot_right_website_2_mob {
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_screen_dot_right_website_2_mob {
		overflow: visible;
		position: absolute;
		width: 2.662px;
		height: 2.662px;
		left: 109.555px;
		top: 53.743px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_mid_stem_website_2_mob_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_pot_1_mid_stem_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 11.98px;
		height: 64.109px;
		left: 32.755px;
		top: 65.224px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_website_2_mob_2 {
		fill: rgba(241,90,90,1);
	}
	.FlamingoFiesta_Mob_pot_1_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 54.99px;
		height: 30.615px;
		left: 6.544px;
		top: 120.131px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_5_website_2_mob_2 {
		fill: rgba(77,182,172,1);
	}
	.FlamingoFiesta_Mob_pot_1_leaf_5_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 18.303px;
		height: 12.613px;
		left: 39.34px;
		top: 60.555px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_4_website_2_mob_2 {
		fill: rgba(77,182,172,1);
	}
	.FlamingoFiesta_Mob_pot_1_leaf_4_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 10.026px;
		height: 16.489px;
		left: 28.026px;
		top: 65.058px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_3_website_2_mob_2 {
		fill: rgba(77,182,172,1);
	}
	.FlamingoFiesta_Mob_pot_1_leaf_3_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 21.274px;
		height: 18.428px;
		left: 34.039px;
		top: 76.039px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_2_website_2_mob_2 {
		fill: rgba(77,182,172,1);
	}
	.FlamingoFiesta_Mob_pot_1_leaf_2_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 13.46px;
		height: 25.567px;
		left: 20.051px;
		top: 81.03px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_1_website_2_mob_2 {
		fill: rgba(77,182,172,1);
	}
	.FlamingoFiesta_Mob_pot_1_leaf_1_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 13.324px;
		height: 22.542px;
		left: 33.004px;
		top: 96.671px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_stem5_website_2_mob {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_pot_1_leaf_stem5_website_2_mob {
		overflow: visible;
		position: absolute;
		width: 18.422px;
		height: 12.189px;
		left: 39.507px;
		top: 60.897px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_stem_4_website_2_mo {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_pot_1_leaf_stem_4_website_2_mo {
		overflow: visible;
		position: absolute;
		width: 21.82px;
		height: 19.119px;
		left: 34.016px;
		top: 76.039px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_stem_3_website_2_mo {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_pot_1_leaf_stem_3_website_2_mo {
		overflow: visible;
		position: absolute;
		width: 9.594px;
		height: 16.368px;
		left: 28.026px;
		top: 65.057px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_stem_2_website_2_mo {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_pot_1_leaf_stem_2_website_2_mo {
		overflow: visible;
		position: absolute;
		width: 9.773px;
		height: 26.131px;
		left: 23.7px;
		top: 81.03px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_stem_1_webstite_2_m {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_pot_1_leaf_stem_1_webstite_2_m {
		overflow: visible;
		position: absolute;
		width: 13.843px;
		height: 23.295px;
		left: 33.018px;
		top: 96.671px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_tp_table__mob_2 {
		fill: rgba(223,223,223,1);
	}
	.FlamingoFiesta_Mob_tp_table__mob_2 {
		overflow: visible;
		position: absolute;
		width: 323.27px;
		height: 10.958px;
		left: 0px;
		top: 150.613px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_table_below_website_2_mob_2 {
		fill: rgba(223,223,223,1);
	}
	.FlamingoFiesta_Mob_table_below_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 295.872px;
		height: 4.483px;
		left: 13.697px;
		top: 161.571px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_line_right_website_2_mo {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_screen_line_right_website_2_mo {
		overflow: visible;
		position: absolute;
		width: 14.935px;
		height: 2px;
		left: 212.049px;
		top: 55.074px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_dot_right_website_2_mob_hf {
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_screen_dot_right_website_2_mob_hf {
		overflow: visible;
		position: absolute;
		width: 2.662px;
		height: 2.662px;
		left: 210.718px;
		top: 53.743px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_screen_dot_website_2_mob_2_hg {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_screen_dot_website_2_mob_2_hg {
		overflow: visible;
		position: absolute;
		width: 3.662px;
		height: 3.662px;
		left: 227.024px;
		top: 53.743px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pen_3_website_2_mob_2 {
		fill: rgba(82,83,96,1);
	}
	.FlamingoFiesta_Mob_pen_3_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.324px;
		height: 33.277px;
		left: 278.105px;
		top: 105.822px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_top_cap_2_website_2_mob_2 {
		fill: rgba(57,210,159,1);
	}
	.FlamingoFiesta_Mob_top_cap_2_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.324px;
		height: 10.981px;
		left: 278.105px;
		top: 94.841px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pen_bottom_2_website_2_mob_2 {
		fill: rgba(82,83,96,1);
	}
	.FlamingoFiesta_Mob_pen_bottom_2_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.325px;
		height: 33.277px;
		left: 285.426px;
		top: 108.484px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pen_2_tcap_website_2_mob_2 {
		fill: rgba(77,137,239,1);
	}
	.FlamingoFiesta_Mob_pen_2_tcap_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.325px;
		height: 10.981px;
		left: 285.426px;
		top: 97.503px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pen_1_bottom_website_2_mob_2 {
		fill: rgba(82,83,96,1);
	}
	.FlamingoFiesta_Mob_pen_1_bottom_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.325px;
		height: 33.277px;
		left: 294.411px;
		top: 114.141px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pen_1_website_2_mob_2 {
		fill: rgba(245,95,68,1);
	}
	.FlamingoFiesta_Mob_pen_1_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.325px;
		height: 10.981px;
		left: 294.411px;
		top: 103.16px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pen_pot_website_2_mob_2 {
		fill: rgba(241,90,90,1);
	}
	.FlamingoFiesta_Mob_pen_pot_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 30.615px;
		height: 34.276px;
		left: 273.446px;
		top: 116.138px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_topest_leafe_mob_2 {
		fill: rgba(77,182,172,1);
	}
	.FlamingoFiesta_Mob_topest_leafe_mob_2 {
		overflow: visible;
		position: absolute;
		width: 9.107px;
		height: 16.489px;
		left: 31.809px;
		top: 50.065px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_leaf_3_website_2_mob_2_hp {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.FlamingoFiesta_Mob_pot_1_leaf_3_website_2_mob_2_hp {
		overflow: visible;
		position: absolute;
		width: 9.093px;
		height: 15.832px;
		left: 32.23px;
		top: 50.581px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_Illustration_grp_website_2_grp {
		opacity: 0.997;
		position: absolute;
		width: 274.24px;
		height: 184.898px;
		left: 62.603px;
		top: 157.181px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_floor_design_mob_2 {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_floor_design_mob_2 {
		overflow: visible;
		position: absolute;
		width: 270.381px;
		height: 56.735px;
		left: 3.093px;
		top: 128.162px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_bg_grey_deisng_mob_2 {
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_bg_grey_deisng_mob_2 {
		overflow: visible;
		position: absolute;
		width: 274.215px;
		height: 152.782px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_bg_black_design__mob_2_hu {
		fill: url(#FlamingoFiesta_Mob_bg_black_design__mob_2_hu);
	}
	.FlamingoFiesta_Mob_bg_black_design__mob_2_hu {
		overflow: visible;
		position: absolute;
		width: 274.215px;
		height: 152.782px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_top_right_rod_website_2_mob_2 {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_top_right_rod_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 3.188px;
		height: 56.626px;
		left: 245.432px;
		top: 9.486px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_rod_top_hori_star_1_website_2_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_rod_top_hori_star_1_website_2_ {
		overflow: visible;
		position: absolute;
		width: 252.569px;
		height: 3.188px;
		left: 13.782px;
		top: 31.943px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_star_9_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_9_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 1.877px;
		height: 1.877px;
		left: 58.73px;
		top: 16.799px;
	}
	#FlamingoFiesta_Mob_star_8_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_8_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 1.877px;
		height: 1.877px;
		left: 29.059px;
		top: 14.946px;
	}
	#FlamingoFiesta_Mob_star_7_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_7_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 0.791px;
		height: 0.791px;
		left: 69.725px;
		top: 17.342px;
	}
	#FlamingoFiesta_Mob_star_6_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_6_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 0.791px;
		height: 0.791px;
		left: 39.929px;
		top: 16.428px;
	}
	#FlamingoFiesta_Mob_star_5_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_5_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 0.791px;
		height: 0.791px;
		left: 39.534px;
		top: 44.197px;
	}
	#FlamingoFiesta_Mob_star_4_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_4_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 0.791px;
		height: 0.791px;
		left: 223px;
		top: 23.198px;
	}
	#FlamingoFiesta_Mob_star_3_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_3_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 0.791px;
		height: 0.791px;
		left: 88.278px;
		top: 23.568px;
	}
	#FlamingoFiesta_Mob_star_2_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_2_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 0.791px;
		height: 0.791px;
		left: 203.383px;
		top: 22.778px;
	}
	#FlamingoFiesta_Mob_star_1_website_2_mob_2 {
		fill: rgba(231,239,253,1);
	}
	.FlamingoFiesta_Mob_star_1_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 0.791px;
		height: 0.791px;
		left: 253.462px;
		top: 23.963px;
	}
	#FlamingoFiesta_Mob_building_website_2_mob_2 {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_building_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 260.128px;
		height: 80.516px;
		left: 14.111px;
		top: 72.412px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_building_shadow__website_2_mob {
		opacity: 0.3;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_building_shadow__website_2_mob {
		overflow: visible;
		position: absolute;
		width: 260.128px;
		height: 80.516px;
		left: 14.111px;
		top: 72.412px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_win_0_website_2_mob_2 {
		opacity: 0.42;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_win_0_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 21.943px;
		top: 93.091px;
	}
	#FlamingoFiesta_Mob_left_window_0_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_0_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 33.258px;
		top: 93.091px;
	}
	#FlamingoFiesta_Mob_right_window_12_website_2_mob_ {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_12_website_2_mob_ {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 163.063px;
		top: 83.282px;
	}
	#FlamingoFiesta_Mob_right_window_11_website_2_mob_ {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_11_website_2_mob_ {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 182.136px;
		top: 83.282px;
	}
	#FlamingoFiesta_Mob_right_window_0_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_0_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 202.642px;
		top: 83.282px;
	}
	#FlamingoFiesta_Mob_right_window_9_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_9_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 163.063px;
		top: 97.858px;
	}
	#FlamingoFiesta_Mob_right_window_8_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_8_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 182.136px;
		top: 97.858px;
	}
	#FlamingoFiesta_Mob_right_window_7_website_2_mob_2 {
		fill: rgba(255,255,255,1);
	}
	.FlamingoFiesta_Mob_right_window_7_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 202.642px;
		top: 97.858px;
	}
	#FlamingoFiesta_Mob_right_window_6_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_6_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 163.063px;
		top: 112.435px;
	}
	#FlamingoFiesta_Mob_right_window_5_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_5_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 182.136px;
		top: 112.435px;
	}
	#FlamingoFiesta_Mob_right_window_4_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_4_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 202.642px;
		top: 112.435px;
	}
	#FlamingoFiesta_Mob_right_window_3_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_3_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.04px;
		height: 4.768px;
		left: 227.521px;
		top: 83.307px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_right_window_2_website_2_mob_2 {
		fill: rgba(255,255,255,1);
	}
	.FlamingoFiesta_Mob_right_window_2_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 227.545px;
		top: 97.858px;
	}
	#FlamingoFiesta_Mob_right_window_1_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_right_window_1_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 227.545px;
		top: 112.435px;
	}
	#FlamingoFiesta_Mob_left_window_9_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_9_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 61.151px;
		top: 77.723px;
	}
	#FlamingoFiesta_Mob_left_window_8_website_2_mob_2 {
		fill: rgba(255,255,255,1);
	}
	.FlamingoFiesta_Mob_left_window_8_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 61.151px;
		top: 88.421px;
	}
	#FlamingoFiesta_Mob_left_window_7_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_7_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 61.151px;
		top: 99.094px;
	}
	#FlamingoFiesta_Mob_left_window_6_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_6_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 61.151px;
		top: 109.792px;
	}
	#FlamingoFiesta_Mob_left_window_5_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_5_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 61.151px;
		top: 120.489px;
	}
	#FlamingoFiesta_Mob_left_window_4_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_4_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 21.943px;
		top: 105.542px;
	}
	#FlamingoFiesta_Mob_left_window_3_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_3_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 33.258px;
		top: 105.542px;
	}
	#FlamingoFiesta_Mob_left_window_2_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_2_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 21.943px;
		top: 118.019px;
	}
	#FlamingoFiesta_Mob_left_window_1_website_2_mob_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_left_window_1_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 7.066px;
		height: 4.768px;
		left: 33.258px;
		top: 118.019px;
	}
	#FlamingoFiesta_Mob_verticle_left_website_2_mob_2 {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_verticle_left_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 3.187px;
		height: 149.372px;
		left: 48.329px;
		top: 0.024px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_veritical_right_website_2_mob_ {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_veritical_right_website_2_mob_ {
		overflow: visible;
		position: absolute;
		width: 3.187px;
		height: 128.52px;
		left: 146.881px;
		top: 24.161px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_bar_down_hori_website_2_mob_2 {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_bar_down_hori_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 269.912px;
		height: 3.188px;
		left: 0.103px;
		top: 116.19px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_leg_top_website_2_mob_2 {
		fill: rgba(101,97,125,1);
	}
	.FlamingoFiesta_Mob_left_leg_top_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 39.62px;
		height: 19.339px;
		left: 81.963px;
		top: 142.431px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_leg_down_website_2_mob_2 {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_left_leg_down_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 39.453px;
		height: 16.734px;
		left: 101.696px;
		top: 157.82px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_neck_website_2_mob_2 {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_neck_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 34.401px;
		height: 27.331px;
		left: 115.9px;
		top: 66.106px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_face_circle_website_2_mob_2 {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_face_circle_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 25.447px;
		height: 25.447px;
		left: 118.938px;
		top: 51.684px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_short_website_2_mob_2 {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_short_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 60.356px;
		height: 61.814px;
		left: 103.967px;
		top: 79.156px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_hand_behind_laptop_websit {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_left_hand_behind_laptop_websit {
		overflow: visible;
		position: absolute;
		width: 12.896px;
		height: 12.032px;
		left: 102.41px;
		top: 124.887px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_right_leg_upper_leg_website_2_ {
		fill: rgba(101,97,125,1);
	}
	.FlamingoFiesta_Mob_right_leg_upper_leg_website_2_ {
		overflow: visible;
		position: absolute;
		width: 38.422px;
		height: 20.587px;
		left: 152.563px;
		top: 134.219px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_right_leg_top_website_2_mob_2 {
		fill: rgba(101,97,125,1);
	}
	.FlamingoFiesta_Mob_right_leg_top_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 47.408px;
		height: 15.182px;
		left: 137.125px;
		top: 150.235px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_leg_1_website_2_mob_2 {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_left_leg_1_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 43.979px;
		height: 10.792px;
		left: 132.181px;
		top: 163.808px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_leg_website_2_mob_2 {
		fill: rgba(101,97,125,1);
	}
	.FlamingoFiesta_Mob_left_leg_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 53.266px;
		height: 25.066px;
		left: 87.488px;
		top: 149.099px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_hand_website_2_mob_2 {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_left_hand_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 15.89px;
		height: 42.988px;
		left: 97.119px;
		top: 91.855px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_right_hand_down_website_2_mob_ {
		fill: rgba(251,190,190,1);
	}
	.FlamingoFiesta_Mob_right_hand_down_website_2_mob_ {
		overflow: visible;
		position: absolute;
		width: 17.22px;
		height: 19.718px;
		left: 147.721px;
		top: 122.564px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_right_hand_website_2_mob_2 {
		fill: rgba(243,123,123,1);
	}
	.FlamingoFiesta_Mob_right_hand_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 13.574px;
		height: 37.157px;
		left: 152.612px;
		top: 93.338px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_laptop_website_2_mob_2 {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_laptop_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 55.366px;
		height: 31.451px;
		left: 105.054px;
		top: 122.071px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_hair_website_2_mob_2 {
		fill: rgba(63,61,86,1);
	}
	.FlamingoFiesta_Mob_hair_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 43.425px;
		height: 81.407px;
		left: 107.672px;
		top: 37.562px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_leaf_1_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_left_leaf_1_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 6.413px;
		height: 8.746px;
		left: 204.16px;
		top: 138.648px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_leaf_2_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_left_leaf_2_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 6.474px;
		height: 12.155px;
		left: 206.495px;
		top: 134.72px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_left_leadf_3_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_left_leadf_3_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 4.619px;
		height: 15.615px;
		left: 209.421px;
		top: 131.83px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_right_leaf_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_right_leaf_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 6.985px;
		height: 8.746px;
		left: 216.502px;
		top: 138.648px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_righ_leaf_2_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_righ_leaf_2_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 7.165px;
		height: 12.155px;
		left: 214.13px;
		top: 134.72px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_leafe_3_left_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_leafe_3_left_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 5.117px;
		height: 15.609px;
		left: 213.059px;
		top: 131.83px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_mid_leaf_left_website_2_mob_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_mid_leaf_left_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 3.089px;
		height: 11.093px;
		left: 211.657px;
		top: 136.178px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_mid_shadow_right_website_2_mob {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_mid_shadow_right_website_2_mob {
		overflow: visible;
		position: absolute;
		width: 3.348px;
		height: 11.391px;
		left: 213.068px;
		top: 135.881px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_leaf_mid_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_leaf_mid_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 4.071px;
		height: 20.535px;
		left: 211.983px;
		top: 126.715px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_behind_new_website_2_mob_2 {
		fill: rgba(212,223,236,1);
	}
	.FlamingoFiesta_Mob_pot_behind_new_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 17.837px;
		height: 9.018px;
		left: 204.272px;
		top: 146.184px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_2_behind_website_2_mob_2 {
		opacity: 0.08;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_pot_2_behind_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 17.837px;
		height: 9.018px;
		left: 204.272px;
		top: 146.184px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_2_rec_bdr_mid_website_2_mo {
		fill: rgba(212,223,236,1);
	}
	.FlamingoFiesta_Mob_pot_2_rec_bdr_mid_website_2_mo {
		position: absolute;
		overflow: visible;
		width: 17.837px;
		height: 2.965px;
		left: 204.964px;
		top: 146.184px;
	}
	#FlamingoFiesta_Mob_over_all_half_cactus__mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_over_all_half_cactus__mob_2 {
		overflow: visible;
		position: absolute;
		width: 19.275px;
		height: 20.083px;
		left: 54.118px;
		top: 125.286px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_cactus_2_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_cactus_2_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 8.247px;
		height: 10.091px;
		left: 63.396px;
		top: 128.571px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_back_2_website2_mob_2 {
		fill: rgba(212,223,236,1);
	}
	.FlamingoFiesta_Mob_pot_1_back_2_website2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 15.169px;
		height: 10.648px;
		left: 53.468px;
		top: 143.639px;
	}
	#FlamingoFiesta_Mob_pot_1_down_2_website_2_mob_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_pot_1_down_2_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 11.612px;
		height: 3.039px;
		left: 55.321px;
		top: 153.57px;
	}
	#FlamingoFiesta_Mob_pot_1_box_2_website_2_mob_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_pot_1_box_2_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 15.169px;
		height: 10.648px;
		left: 53.468px;
		top: 143.639px;
	}
	#FlamingoFiesta_Mob_pot_1_down_1_website_2_mob_2 {
		fill: rgba(212,223,236,1);
	}
	.FlamingoFiesta_Mob_pot_1_down_1_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 11.612px;
		height: 3.039px;
		left: 56.062px;
		top: 153.57px;
	}
	#FlamingoFiesta_Mob_pot_1_cactus_3_website_2_mob_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_pot_1_cactus_3_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 2.767px;
		height: 2.322px;
		left: 60.559px;
		top: 134.794px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_cactus_2_website_2_mob_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_pot_1_cactus_2_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 3.635px;
		height: 3.176px;
		left: 63.396px;
		top: 135.486px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot_1_cactus_1_website_2_mob_2 {
		fill: rgba(56,211,159,1);
	}
	.FlamingoFiesta_Mob_pot_1_cactus_1_website_2_mob_2 {
		overflow: visible;
		position: absolute;
		width: 9.887px;
		height: 10.255px;
		left: 57.149px;
		top: 135.113px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_pot1_box_down_website_2_mob_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.FlamingoFiesta_Mob_pot1_box_down_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 11.612px;
		height: 1.112px;
		left: 56.062px;
		top: 153.57px;
	}
	#FlamingoFiesta_Mob_pot_1_box_1_website_2_mob_2 {
		fill: rgba(212,223,236,1);
	}
	.FlamingoFiesta_Mob_pot_1_box_1_website_2_mob_2 {
		position: absolute;
		overflow: visible;
		width: 15.169px;
		height: 10.648px;
		left: 54.234px;
		top: 143.639px;
	}
	#FlamingoFiesta_Mob_contact_area_grp_website_2_mob {
		position: absolute;
		width: 367.128px;
		height: 306px;
		left: 40px;
		top: 4074.879px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_bdr_contact_page_website_2mob {
		fill: rgba(255,255,255,1);
	}
	.FlamingoFiesta_Mob_bdr_contact_page_website_2mob {
		-webkit-filter: drop-shadow(0px 10px 2px rgba(135, 207, 172, 0.647));
		        filter: drop-shadow(0px 10px 2px rgba(135, 207, 172, 0.647));
		overflow: visible;
		position: absolute;
		width: 373.128px;
		height: 319px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FlamingoFiesta_Mob_n_234567890_mobile_number_webs_mob {
		left: 68px;
		top: 24px;
		position: absolute;
		overflow: visible;
		width: 83px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FlamingoFiesta_Mob_affanghanioperatelive_email_mob {
		left: 68px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 167px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FlamingoFiesta_Mob_address_website_2_mob_ff {
		left: 68px;
		top: 134px;
		position: absolute;
		overflow: visible;
		width: 292px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FlamingoFiesta_Mob_Connect_with_us_and_unlock_lim_mob {
		left: 53px;
		top: 258px;
		position: absolute;
		overflow: visible;
		width: 271px;
		height: 40px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FlamingoFiesta_Mob_location_img_website_2_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 23px;
		top: 127px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_email_address_website_2_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 23px;
		top: 73px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_phone_number_website_2_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 23px;
		top: 19px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_quote_left_webstie_2_mob {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 23px;
		top: 253px;
		overflow: visible;
	}
	#FlamingoFiesta_Mob_quote_right_website_2_mob {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 149px;
		top: 278px;
		overflow: visible;
	}


    /* Menu */
    #FlamingoFiesta_Mob_menuToggle
{
display: block;
position: fixed;
width: 40px;
height: 40px;
left: 34px;
top: 25px;

z-index: 1;

-webkit-user-select: none;
user-select: none;
}

#FlamingoFiesta_Mob_menuToggle li
{
text-decoration: none;
color: #232323;

transition: color 0.3s ease;
}

#FlamingoFiesta_Mob_menuToggle li:hover
{
color: #ff4f4f;
}


#FlamingoFiesta_Mob_menuToggle input
{
display: block;
width: 40px;
height: 32px;
position: absolute;
top: -7px;
left: -5px;

cursor: pointer;

opacity: 0; /* hide this */
z-index: 2; /* and place it over the hamburger */

-webkit-touch-callout: none;
}

/*
* Just a quick hamburger
*/
#FlamingoFiesta_Mob_menuToggle span
{
display: block;
width: 33px;
height: 4px;
margin-bottom: 5px;
position: relative;

background: #6495ED;
border-radius: 3px;

z-index: 1;

-webkit-transform-origin: 4px 0px;

        transform-origin: 4px 0px;

transition: -webkit-transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);

transition: transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);

transition: transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
background: 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
opacity: 0.55s ease;
}

#FlamingoFiesta_Mob_menuToggle span:first-child
{
-webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
}

#FlamingoFiesta_Mob_menuToggle span:nth-last-child(2)
{
-webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
}

/* 
* Transform all the slices of hamburger
* into a crossmark.
*/
#FlamingoFiesta_Mob_menuToggle input:checked ~ span
{
opacity: 1;
-webkit-transform: rotate(45deg) translate(-2px, -1px);
        transform: rotate(45deg) translate(-2px, -1px);
background: rgba(255,75,83,1);
}

/*
* But let's hide the middle one.
*/
#FlamingoFiesta_Mob_menuToggle input:checked ~ span:nth-last-child(3)
{
opacity: 0;
-webkit-transform: rotate(0deg) scale(0.2, 0.2);
        transform: rotate(0deg) scale(0.2, 0.2);
}

/*
* Ohyeah and the last one should go the other direction
*/
#FlamingoFiesta_Mob_menuToggle input:checked ~ span:nth-last-child(2)
{
-webkit-transform: rotate(-45deg) translate(0, -1px);
        transform: rotate(-45deg) translate(0, -1px);
}

/*
* Make this absolute positioned
* at the top left of the screen
*/
#FlamingoFiesta_Mob_menu
{
position: absolute;
width: 230px;
margin: -100px 0 0 -50px;
padding: 50px;
padding-top: 125px;
height: 100vh;
background: #ededed;
list-style-type: none;
-webkit-font-smoothing: antialiased;
/* to stop flickering of text in safari */
-webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
-webkit-transform: translate(-100%, 0);
        transform: translate(-100%, 0);
transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
background: rgba( 255, 255, 255, 0.25 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 10px );
-webkit-backdrop-filter: blur( 10px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}

#FlamingoFiesta_Mob_menu li
{
padding: 10px 0;
font-size: 22px;
}
#FlamingoFiesta_Mob_menuToggle input:checked ~ ul
{
-webkit-transform: none;
        transform: none;
}

	.mediaViewInfo {
		--web-view-name: website 3;
		--web-view-id: website_3;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: website_3;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#website_3 {
		position: absolute;
		width: 1440px;
		height: 3720px;
        left:calc((100vw - 1440px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: website 3;
		--web-view-id: website_3;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#right_top_blue_design_website_ {
		fill: rgba(189,204,255,1);
	}
	.right_top_blue_design_website_ {
		position: absolute;
		overflow: visible;
		width: 511px;
		height: 841px;
		left: 929px;
		top: 0px;
	}
	#Lines_top_website_3 {
		position: absolute;
		width: 456px;
		height: 841px;
		left: 248px;
		top: -110px;
		overflow: visible;
	}
	#bg_3_website_3 {
		opacity: 0.1;
		fill: rgba(52,84,209,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.bg_3_website_3 {
		width: 1px;
		height: 841px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,455,0) rotate(180deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,455,0) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#bg_2_website_3 {
		opacity: 0.1;
		fill: rgba(52,84,209,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.bg_2_website_3 {
		width: 1px;
		height: 224px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,229,110) rotate(180deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,229,110) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#bg_1_website_3 {
		opacity: 0.1;
		fill: rgba(52,84,209,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.bg_1_website_3 {
		position: absolute;
		overflow: visible;
		width: 2px;
		height: 427px;
		left: 0px;
		top: 93px;
	}
	#Scroll_comp_intro_web_3 {
		mix-blend-mode: normal;
		position: absolute;
		width: 536px;
		height: 204px;
		left: 135px;
		top: 451px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#Scroll_comp_intro_web_3::-webkit-scrollbar{
		display: none;
	
	}
	#intro_lbl_website_3_ob_pc {
	
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
        left: 0px;
		
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.1px;
	}
	#Heading_website_3 {
		left: 135px;
		top: 277px;
		position: absolute;
		overflow: visible;
		width: 537px;
		height: 145px;
		line-height: 73px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 54px;
		color: rgba(0,0,0,1);
	}
	#Small_Heading_top_website_3 {
		left: 138px;
		top: 224px;
		position: absolute;
		overflow: visible;
		width: 126px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(255,153,0,1);
	}
	
	#we_stand_for_grp {
		position: absolute;
		width: 1100px;
		height: 438px;
		left: 183px;
		top: 1001px;
		overflow: visible;
	}
	#Card_03_website_3 {
		position: absolute;
		width: 273px;
		height: 309px;
		left: 827px;
		top: 125px;
		overflow: visible;
	}
	#Scroll_Group_principle_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 273px;
		height: 120px;
		left: 0px;
		top: 189px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#intro_lbl_website_3_b {
	
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		line-height: 26px;
		left: 0px;
		top: 0px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#principles_lbl_website_3 {
		left: 84.5px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#Fill_pink_website_3 {
		fill: rgba(242,184,236,1);
	}
	.Fill_pink_website_3 {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(242, 184, 236, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(242, 184, 236, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.445px;
		left: 85px;
		top: 0px;
	}
	#Icon_left_website_3 {
		fill: rgba(255,255,255,1);
	}
	.Icon_left_website_3 {
		overflow: visible;
		position: absolute;
		width: 16.479px;
		height: 20.006px;
		left: 120px;
		top: 37.994px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_top_website_3 {
		fill: rgba(255,255,255,1);
	}
	.Icon_top_website_3 {
		overflow: visible;
		position: absolute;
		width: 18.923px;
		height: 3.37px;
		left: 121.03px;
		top: 33px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_right_website_3 {
		fill: rgba(255,255,255,1);
	}
	.Icon_right_website_3 {
		overflow: visible;
		position: absolute;
		width: 2.983px;
		height: 22.591px;
		left: 138.017px;
		top: 34.193px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Card_02_website_3 {
		position: absolute;
		width: 260px;
		height: 313px;
		left: 407px;
		top: 125px;
		overflow: visible;
	}
	#Scroll_Group_11 {
		mix-blend-mode: normal;
		position: absolute;
		width: 260px;
		height: 120px;
		left: 0px;
		top: 193px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#Scroll_Group_11::-webkit-scrollbar{
		display: none;
	}
	#intro_2_website_3 {
	
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		line-height: 26px;
        left: 0px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#mission_lbl_website_3 {
		left: 93px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#Icon_2_grp_websiote_3 {
		position: absolute;
		width: 90px;
		height: 90px;
		left: 85px;
		top: 0px;
		overflow: visible;
	}
	#bg_blue_design {
		fill: rgba(77,223,253,1);
	}
	.bg_blue_design {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(77, 223, 253, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(77, 223, 253, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.445px;
		left: 0px;
		top: 0px;
	}
	#Icon_hand_website_3 {
		fill: rgba(255,255,255,1);
	}
	.Icon_hand_website_3 {
		overflow: visible;
		position: absolute;
		width: 25px;
		height: 12.324px;
		left: 33px;
		top: 45.676px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_3_website_3 {
		fill: rgba(255,255,255,1);
	}
	.Icon_3_website_3 {
		overflow: visible;
		position: absolute;
		width: 8.532px;
		height: 8.305px;
		left: 43.417px;
		top: 37.918px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_plus_2_website_3 {
		fill: rgba(255,255,255,1);
	}
	.Icon_plus_2_website_3 {
		overflow: visible;
		position: absolute;
		width: 6.241px;
		height: 6.204px;
		left: 38.026px;
		top: 34.464px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_plus_1_website_3 {
		fill: rgba(255,255,255,1);
	}
	.Icon_plus_1_website_3 {
		overflow: visible;
		position: absolute;
		width: 5.365px;
		height: 5.326px;
		left: 48.91px;
		top: 33px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Card_01_website_3 {
		position: absolute;
		width: 234px;
		height: 313px;
		left: 0px;
		top: 125px;
		overflow: visible;
	}
	#Scroll_Group_10 {
		mix-blend-mode: normal;
		position: absolute;
		width: 234px;
		height: 120px;
		left: 0px;
		top: 193px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#Scroll_Group_10::-webkit-scrollbar{
		display: none;
	}
	#intro_website_3 {
	
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		line-height: 26px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#Vision_website_3 {
		left: 88px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#Icon_grp_website_3 {
		position: absolute;
		width: 90px;
		height: 90px;
		left: 72px;
		top: 0px;
		overflow: visible;
	}
	#Fill_11_website_3 {
		fill: rgba(150,114,255,1);
	}
	.Fill_11_website_3 {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(150, 114, 255, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(150, 114, 255, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.445px;
		left: 0px;
		top: 0px;
	}
	#Icon_2_website_3 {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.Icon_2_website_3 {
		overflow: visible;
		position: absolute;
		width: 8.742px;
		height: 8.742px;
		left: 50.672px;
		top: 50.673px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_1_website_3 {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.Icon_1_website_3 {
		overflow: visible;
		position: absolute;
		width: 22.689px;
		height: 22.689px;
		left: 33px;
		top: 33px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#what_we_stand_for_website_3 {
		left: 367px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 341px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#deisgn_grp_we_stand_left {
		position: absolute;
		width: 210px;
		height: 11px;
		left: 210px;
		top: 166px;
		overflow: visible;
	}
	#centre_linewebsite_3 {
		opacity: 0.2;
		fill: rgba(0,0,0,0);
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5 10;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.centre_linewebsite_3 {
		overflow: visible;
		position: absolute;
		width: 191.5px;
		height: 3px;
		left: 9px;
		top: 5.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#right_we_stand_circle {
		fill: rgba(77,223,253,1);
	}
	.right_we_stand_circle {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 199px;
		top: 0px;
	}
	#left_we_stand_circle {
		fill: rgba(150,114,255,1);
	}
	.left_we_stand_circle {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 0px;
		top: 0px;
	}
	#what_we_shand_for_arrow_mid_le {
		position: absolute;
		width: 210px;
		height: 11px;
		left: 638px;
		top: 166px;
		overflow: visible;
	}
	#centre_line_website_3 {
		opacity: 0.2;
		fill: rgba(0,0,0,0);
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5 10;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.centre_line_website_3 {
		overflow: visible;
		position: absolute;
		width: 191.5px;
		height: 1px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,9.5,5.5) rotate(180deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,9.5,5.5) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#circle_left_website_3 {
		fill: rgba(77,223,253,1);
	}
	.circle_left_website_3 {
		width: 11px;
		height: 11px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,0,0) rotate(180deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,0,0) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#circle_right_website_3 {
		fill: rgba(150,114,255,1);
	}
	.circle_right_website_3 {
		width: 11px;
		height: 11px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,199,0) rotate(180deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,199,0) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	
	
	#Featured_Listing_of_the_Week {
		left: 339px;
		top: 2477px;
		position: absolute;
		overflow: visible;
		width: 764px;
		height: 53px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#offering_area_web_3 {
		position: absolute;
		width: 1185px;
		height: 601px;
		left: 120px;
		top: 1683px;
		overflow: visible;
	}
	#Scroll_Offering_web_3 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1185px;
		height: 433px;
		left: 0px;
		top: 168px;
        display: inline-flex;
		overflow-x: auto;
        overflow-y: hidden;

	}
	#Scroll_Offering_web_3::-webkit-scrollbar{
		display: none;
	}
	#name_anf_off_img_web_3 {
		position: relative;
      
		width: 370px;
		height: 383px;
		left: 15px;
		top: 0px;
		overflow: visible;
	}
	#Image_03_website_3 {
		position: relative;
		width: 370px;
		height: 300px;
		left: 0px;
		top: 83px;
        padding: 10px;
		overflow: visible;
	}
	#Button_3D_website_3 {
		position: absolute;
		width: 28px;
		height: 26px;
		left: 95px;
		top: 97px;
		overflow: visible;
	}
	#Fill_3D_website_3 {
		fill: rgba(0,0,0,0.4);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Fill_3D_website_3 {
		position: absolute;
		overflow: visible;
		width: 28px;
		height: 26px;
		left: 0px;
		top: 0px;
	}
	#n_D_website_3 {
		left: 6px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 16px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(255,255,255,1);
	}
	#Button_featured_website_3 {
		position: absolute;
		width: 75px;
		height: 26px;
		left: 14px;
		top: 97px;
		overflow: visible;
	}
	#Fill_box_website_3 {
		fill: rgba(0,0,0,0.4);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Fill_box_website_3 {
		position: absolute;
		overflow: visible;
		width: 75px;
		height: 26px;
		left: 0px;
		top: 0px;
	}
	#Featured_website_3 {
		left: 14.5px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 47px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(255,255,255,1);
	}
	#offering_Menu_website_3 {
		position: absolute;
		width: 168px;
		height: 26px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Menu_website_3 {
		position: absolute;
		width: 168px;
		height: 26px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Residential_Property_website_3 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 169px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(0,0,0,1);
	}
	#offering_mid_grp_website_3 {
		position: absolute;
		width: 470px;
		height: 91px;
		left: 15px;
		top: 0px;
		overflow: visible;
	}
	#slogal_website_3 {
		left: 0px;
		top: 70px;
		position: absolute;
		overflow: visible;
		width: 471px;
		height: 21px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#offerings_lbl_website_3 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 164px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#img_website_website_3 {
		position: absolute;
		width: 633.5px;
		height: 442.883px;
		left: 671px;
		top: 212px;
		overflow: visible;
	}
	#footer_grp_website_3 {
		position: absolute;
		width: 1173px;
		height: 69.5px;
		left: 134px;
		top: 3600.75px;
		overflow: visible;
	}
	#Line_2_website_3 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_2_website_3 {
		overflow: visible;
		position: absolute;
		width: 1173px;
		height: 1px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_Copyright_Operate_Live_websi_ob_pc {
		left: 39.5px;
		top: 46.5px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#home_website_3 {
		position: absolute;
		width: 79px;
		height: 27px;
		left: 714px;
		top: 55px;
		overflow: visible;
	}
	#Home_website_3_et {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#home-icon-silhouette_website_3 {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#offering_website_3 {
		position: absolute;
		width: 106px;
		height: 27px;
		left: 828px;
		top: 55px;
		overflow: visible;
	}
	#Offerings_website_3 {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#discount_website_3 {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#event_grp_website_3 {
		position: absolute;
		width: 82px;
		height: 27px;
		left: 969px;
		top: 55px;
		overflow: visible;
	}
	#Events_website_3 {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 58px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#election-event-on-a-calendar-w {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#contact_grp_website_3 {
		position: absolute;
		width: 93px;
		height: 27px;
		left: 1086px;
		top: 55px;
		overflow: visible;
	}
	#Contact_lbl_website_3 {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#contact_img_website_3 {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#Partners_grp_web_3 {
		position: absolute;
		width: 804px;
		height: 88px;
		left: 109px;
		top: 771px;
		overflow: visible;
	}
	#text_our_partners_website_3 {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 168px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.1px;
	}
	#Scroll_Group_13 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 80px;
		left: 0px;
		top: 39px;
        display: inline-flex;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#Scroll_Group_13::-webkit-scrollbar{
		display: none;
	
	}
	#partner_lbls_grp3 {
    position: relative;
    width: 200px;
    display: inline-flex;
    height: 27px;
    left: 26px;
    top: 7px;
    overflow: visible;
	}
	#google_lbl_partner_website_23 {
		left: 30px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#microsoft_lbl_partnw_website_2 {
		left: 210px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#microscope_lbl_partner_website {
		left: 390px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#google_1_website_23 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#microsoft_website_23 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 180px;
		top: 1px;
		overflow: visible;
	}
	#scope_website_23 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 360px;
		top: 1px;
		overflow: visible;
	}
	#googlewebsite_23 {
		left: 572px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#google_1_1_website_23 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 542px;
		top: 1px;
		overflow: visible;
	}
	#hiding_part_1_web_3_pc_comment {
		position: absolute;
		width: 570px;
		height: 528px;
		left: 136px;
		top: 3473px;
		display: none;
		overflow: visible;
	}
	#Card_01_testi_website_3 {
		position: absolute;
		width: 380px;
		height: 367px;
		left: 0px;
		top: 161px;
		overflow: visible;
	}
	#Base_website_3 {
		fill: rgba(255,255,255,1);
		stroke: rgba(235,235,235,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Base_website_3 {
		position: absolute;
		overflow: visible;
		width: 380px;
		height: 367px;
		left: 0px;
		top: 0px;
	}
	#Los_Angeles_CA_website_3 {
		left: 106px;
		top: 296px;
		position: absolute;
		overflow: visible;
		width: 99px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(102,102,102,1);
	}
	#Jane_Cooper_website_3 {
		left: 106px;
		top: 274px;
		position: absolute;
		overflow: visible;
		width: 89px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#Image_website_3 {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 38px;
		top: 267px;
		overflow: visible;
	}
	#Moderate_children_website_3 {
		left: 38px;
		top: 102px;
		position: absolute;
		overflow: visible;
		width: 271px;
		height: 105px;
		line-height: 28px;
		margin-top: -6px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#Review_website_3 {
		position: absolute;
		width: 94.024px;
		height: 13.907px;
		left: 38.988px;
		top: 52.514px;
		overflow: visible;
	}
	#Star_1_website_3 {
		fill: rgba(251,176,64,1);
	}
	.Star_1_website_3 {
		overflow: visible;
		position: absolute;
		width: 15.479px;
		height: 13.906px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Star_2_website_3 {
		fill: rgba(251,176,64,1);
	}
	.Star_2_website_3 {
		overflow: visible;
		position: absolute;
		width: 15.479px;
		height: 13.906px;
		left: 19.636px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Star_3_website_3 {
		fill: rgba(251,176,64,1);
	}
	.Star_3_website_3 {
		overflow: visible;
		position: absolute;
		width: 15.479px;
		height: 13.906px;
		left: 39.273px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Star_4_website_3 {
		fill: rgba(251,176,64,1);
	}
	.Star_4_website_3 {
		overflow: visible;
		position: absolute;
		width: 15.479px;
		height: 13.906px;
		left: 58.909px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Star_5_website_3 {
		fill: rgba(251,176,64,1);
	}
	.Star_5_website_3 {
		overflow: visible;
		position: absolute;
		width: 15.479px;
		height: 13.906px;
		left: 78.545px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Loved_by_businesses_and_indivi {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 571px;
		height: 106px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}

	#hidding_part_2_web_3_pc_info {
		position: absolute;
		width: 686px;
		height: 267px;
		left: 377px;
		top: 4123px;
		display: none;
		overflow: visible;
	}
	#Email_input_field {
		position: absolute;
		width: 686px;
		height: 124px;
		left: 0px;
		top: 143px;
		overflow: visible;
	}
	#bg_website_3 {
		fill: rgba(255,255,255,1);
	}
	.bg_website_3 {
		-webkit-filter: drop-shadow(0px 3.019px 3.148px rgba(0, 0, 0, 0.008));
		        filter: drop-shadow(0px 3.019px 3.148px rgba(0, 0, 0, 0.008));
		position: absolute;
		overflow: visible;
		width: 695.444px;
		height: 133.445px;
		left: 0px;
		top: 0px;
	}
	#text_website_3 {
		opacity: 0.2;
		left: 45px;
		top: 27px;
		position: absolute;
		overflow: visible;
		width: 192px;
		white-space: nowrap;
		line-height: 24px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.2px;
	}
	#Button_Send_website_3 {
		position: absolute;
		width: 167px;
		height: 72px;
		left: 489px;
		top: 169px;
		overflow: visible;
	}
	#bg_website_3_orange {
		fill: rgba(248,90,71,1);
	}
	.bg_website_3_orange {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 176.444px;
		height: 81.445px;
		left: 0px;
		top: 0px;
	}
	#subscribe_lbl__website_3 {
		left: 44px;
		top: 23px;
		position: absolute;
		overflow: visible;
		width: 81px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.2px;
	}
	#to_join_website_3 {
		left: 153px;
		top: 76px;
		position: absolute;
		overflow: visible;
		width: 353px;
		white-space: nowrap;
		line-height: 33px;
		margin-top: -5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 23px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.2px;
	}
	#sub_title_lbl_website_3 {
		left: 53px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 583px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -10px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 45px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.2px;
	}

	#Scroll_upcoming_event_web_3_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 1376px;
		height: 650px;
		left: 32px;
		top: 2648px;
		display: flex;
    overflow-x: auto;
    flex-wrap: wrap;
    flex-direction: column;
    overflow-y: hidden;
	}
	#Upcoming_evnt_grp_down_3_pc {
		position: relative;
		width: 1170px;
		height: 526px;
		/* left: 103px; */
		top: 41px;
		margin-right: 50px;
		overflow: visible;
	}
	#bgof_card_eventwebsite_3_pc {
		fill: rgba(241,255,255,1);
	}
	.bgof_card_eventwebsite_3_pc {
		position: absolute;
		overflow: visible;
		width: 1170px;
		height: 526px;
		left: 0px;
		top: 0px;
	}
	#Button_website_3_pc {
		display: none;
		position: absolute;
		width: 412px;
		height: 53px;
		left: 608px;
		top: 453px;
		overflow: visible;
	}
	#Fbdr_website_3 {
		fill: rgba(248,90,71,1);
	}
	.Fbdr_website_3 {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 421.444px;
		height: 62.445px;
		left: 0px;
		top: 0px;
	}
	#enroll_now_website_3 {
		left: 163.5px;
		top: 16px;
		position: absolute;
		overflow: visible;
		width: 87px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#organizer_grp_website_3_pc {
		position: absolute;
		width: 313px;
		height: 57px;
		left: 608px;
		top: 341px;
		overflow: visible;
	}
	#n__Room_website_3 {
		left: 0px;
		top: 35px;
		position: absolute;
		overflow: visible;
		width: 314px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#organizer_website_3 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#when_to_wen_date_grp_3_pc {
		position: absolute;
		width: 298px;
		height: 22px;
		left: 608px;
		top: 61px;
		overflow: visible;
	}
	#date_website_3_pc {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 299px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#avaliable_website_3_pc {
		position: absolute;
		width: 67px;
		height: 57px;
		left: 945px;
		top: 246px;
		overflow: visible;
	}
	#n_000_website_3_pc {
		left: 0px;
		top: 35px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#avaliablewebsite_3_pc {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 68px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#seats_grp_website_3_pc {
		position: absolute;
		width: 40px;
		height: 57px;
		left: 786px;
		top: 246px;
		overflow: visible;
	}
	#n_200_m2_website_3 {
		left: 0px;
		top: 35px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#seats_website_3 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#cost_grp_website_3_pc {
		position: absolute;
		width: 91px;
		height: 57px;
		left: 608px;
		top: 246px;
		overflow: visible;
	}
	#Confidential_website_3 {
		left: 0px;
		top: 35px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#cost_website_3 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Scroll_event_desc_3 {
		mix-blend-mode: normal;
		position: absolute;
		width: 364px;
		height: 108px;
		left: 598px;
		top: 126px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#event_info_website_3_pc {
		left: 10px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 345px;
		height: 96px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(84,90,88,1);
	}
	#event__name_website_3_pc {
		left: 608px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 379px;
		height: 41px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#Image_1_website_3_pc {
		position: absolute;
		width: 492px;
		height: 364px;
		left: 39px;
		top: 81px;
		overflow: visible;
	}

	#all_info_grp_card_below_pc_3_w {
		position: absolute;
		width: 1173px;
		height: 228px;
		left: 130.803px;
		top: 3340px;
		overflow: visible;
	}
	#bg_of_info_web_3_pc {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 1173px;
		height: 228px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Connect_Unlock_3_pc {
		left: 914px;
		top: 144.5px;
		position: absolute;
		overflow: visible;
		width: 233px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#n_234567890e_number_website_3_ {
		left: 69px;
		top: 19px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#affanghanioperatelive_website3_pc {
		left: 70px;
		top: 76px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#address_website_3_pc {
		left: 65px;
		top: 130px;
		position: absolute;
		overflow: visible;
		width: 730px;
		height: 78px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#location_img_website_3_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 21px;
		top: 130px;
		overflow: visible;
	}
	#email_address_website_3_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 21px;
		top: 74px;
		overflow: visible;
	}
	#phone_number_website_3_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 21px;
		top: 17px;
		overflow: visible;
	}
	#quote_left_webstie_3_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 874px;
		top: 144.5px;
		overflow: visible;
	}
	#quote_right_website_3_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 1115px;
		top: 171.5px;
		overflow: visible;
	}
	.mediaViewInfo {
		--web-view-name: web 3 mob;
		--web-view-id: web_3_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: web_3_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#web_3_mob {
		position: absolute;
		width: 390px;
		height: 4980px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: web 3 mob;
		--web-view-id: web_3_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#bdr_contact_page_website_2mob {
		-webkit-filter: drop-shadow(0px 10px 2px rgba(135, 207, 172, 0.647));
		        filter: drop-shadow(0px 10px 2px rgba(135, 207, 172, 0.647));
		position: absolute;
		width: 367px;
		height: 289.485px;
		left: 11.5px;
		top: 4520px;
		overflow: visible;
	}
	#bg_img_website_3_mob {
		fill: rgba(189,204,255,1);
	}
	.bg_img_website_3_mob {
		position: absolute;
		overflow: visible;
		width: 235px;
		height: 278px;
		left: 155px;
		top: 377px;
	}
	#img_centre_website_3_mob {
		position: absolute;
		width: 276px;
		height: 192.953px;
		left: 17px;
		top: 421px;
		overflow: visible;
	}
	#Scroll_company_intro_web_3 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 95px;
		left: 0px;
		top: 278px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#intro_about_website_3_mob {
		left: 22px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 349px;
		height: 99px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.1px;
	}
	#Heading_website_3_mob {
		left: 62px;
		top: 141px;
		position: absolute;
		overflow: visible;
		width: 106px;
		white-space: nowrap;
		line-height: 73px;
		margin-top: -24px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Top_heading_website_3_mob {
		left: 65px;
		top: 56px;
		position: absolute;
		overflow: visible;
		width: 126px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(255,153,0,1);
	}
	#partner_website_3_mob {
		left: 25px;
		top: 675px;
		position: absolute;
		overflow: visible;
		width: 168px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.1px;
	}
	#what_we_stand_for_lbl_website_ {
		left: 89px;
		top: 826px;
		position: absolute;
		overflow: visible;
		width: 214px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Card_01 {
		position: absolute;
		width: 390px;
		height: 313px;
		left: 0px;
		top: 934px;
		overflow: visible;
	}
	#Scroll_Group_14_mob_3 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 120px;
		left: 0px;
		top: 193px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#intro_visionwebsite_3_mob {
	
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		line-height: 26px;
		
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#vision_lbl_website_3_mob {
		left: 166px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#bdr_visionwebsite_3_mob {
		fill: rgba(150,114,255,1);
	}
	.bdr_visionwebsite_3_mob {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(150, 114, 255, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(150, 114, 255, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.445px;
		left: 150px;
		top: 0px;
	}
	#line_website_3_mob {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.line_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 8.742px;
		height: 8.742px;
		left: 200.672px;
		top: 50.673px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#circle_mag_website_3_mob {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.circle_mag_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 22.689px;
		height: 22.689px;
		left: 183px;
		top: 33px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#line_grp_11_website_3_mob {
		-webkit-transform: matrix(1,0,0,1,84.5,1349.5) rotate(90deg);
		        transform: matrix(1,0,0,1,84.5,1349.5) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		position: absolute;
		width: 210px;
		height: 11px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#line_centre_website_3_mob {
		opacity: 0.2;
		fill: rgba(0,0,0,0);
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5 10;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_centre_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 3px;
		height: 191.5px;
		left: 5.5px;
		top: 9px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#down_ball_1_website_3_mob {
		fill: rgba(77,223,253,1);
	}
	.down_ball_1_website_3_mob {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 0px;
		top: 199px;
	}
	#top_ball_1_website_3_mob {
		fill: rgba(150,114,255,1);
	}
	.top_ball_1_website_3_mob {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 0px;
		top: 0px;
	}
	#Card_02_mission_over_all {
		position: absolute;
		width: 390px;
		height: 313px;
		left: 0px;
		top: 1479px;
		overflow: visible;
	}
	#Scroll_web_3_mob1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 120px;
		left: 0px;
		top: 193px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#mssion_intro_website_3_mob {
		
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		line-height: 26px;
		
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#mission_website_3_mob {
		left: 158px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#mision_img_bdr_website_3_mob {
		fill: rgba(77,223,253,1);
	}
	.mision_img_bdr_website_3_mob {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(77, 223, 253, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(77, 223, 253, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.445px;
		left: 150px;
		top: 0px;
	}
	#hand_website_3_mob {
		fill: rgba(255,255,255,1);
	}
	.hand_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 25px;
		height: 12.324px;
		left: 183px;
		top: 45.676px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#star_down_website_3_mob {
		fill: rgba(255,255,255,1);
	}
	.star_down_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 8.532px;
		height: 8.305px;
		left: 193.417px;
		top: 37.918px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#star_left_website_3_mob {
		fill: rgba(255,255,255,1);
	}
	.star_left_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 6.241px;
		height: 6.204px;
		left: 188.026px;
		top: 34.464px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#star_top_right_website_3_mob {
		fill: rgba(255,255,255,1);
	}
	.star_top_right_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 5.365px;
		height: 5.326px;
		left: 198.91px;
		top: 33px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#down_arow_grp_website_3_mob {
		-webkit-transform: matrix(1,0,0,1,90.5,1915.8145) rotate(90deg);
		        transform: matrix(1,0,0,1,90.5,1915.8145) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		position: absolute;
		width: 210px;
		height: 11px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#line_centre_website_3_mob_ch {
		opacity: 0.2;
		fill: rgba(0,0,0,0);
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5 10;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_centre_website_3_mob_ch {
		overflow: visible;
		position: absolute;
		width: 191.5px;
		height: 1px;
		-webkit-transform: translate(11px, 0px) matrix(1,0,0,1,9.5,5.5) rotate(180deg);
		        transform: translate(11px, 0px) matrix(1,0,0,1,9.5,5.5) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#top_ball_down_website_3_mob {
		fill: rgba(77,223,253,1);
	}
	.top_ball_down_website_3_mob {
		width: 11px;
		height: 11px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(11px, 0px) matrix(1,0,0,1,0,0) rotate(180deg);
		        transform: translate(11px, 0px) matrix(1,0,0,1,0,0) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#ball_2_down_website_3_mob {
		fill: rgba(150,114,255,1);
	}
	.ball_2_down_website_3_mob {
		width: 11px;
		height: 11px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(11px, 0px) matrix(1,0,0,1,199,0) rotate(180deg);
		        transform: translate(11px, 0px) matrix(1,0,0,1,199,0) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#Card_03_princi_website_3_mob {
		position: absolute;
		width: 390px;
		height: 313px;
		left: 0px;
		top: 2045px;
		overflow: visible;
	}
	#Scroll_web_3_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 120px;
		left: 0px;
		top: 193px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#intro_website_3_mob {
		
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		line-height: 26px;
		
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#principle_website_3_mob {
		left: 149.5px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#principles_bdr_website_3_mob {
		fill: rgba(242,184,236,1);
	}
	.principles_bdr_website_3_mob {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(242, 184, 236, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(242, 184, 236, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.445px;
		left: 150px;
		top: 0px;
	}
	#front_path_website_3_mob {
		fill: rgba(255,255,255,1);
	}
	.front_path_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 16.479px;
		height: 20.006px;
		left: 185px;
		top: 37.994px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#top_website_3_mob {
		fill: rgba(255,255,255,1);
	}
	.top_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 18.923px;
		height: 3.37px;
		left: 186.03px;
		top: 33px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#right_website_3_mob {
		fill: rgba(255,255,255,1);
	}
	.right_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 2.983px;
		height: 22.591px;
		left: 203.017px;
		top: 34.193px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#offerings_view_grp_website_3_m {
		position: absolute;
		width: 202px;
		height: 49px;
		left: 19.872px;
		top: 2394.5px;
		overflow: visible;
	}
	#check_out_my_offerings_website {
		left: 0px;
		top: 28px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 21px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#oferrings_txt_website_3_mob {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#scroll_offering_imgs {
    mix-blend-mode: normal;
    position: relative;
    width: 358px;
    height: 246px;
    left: 20px;
    top: 2484px;
    display: inline-flex;
    overflow-x: auto;
    overflow-y: hidden;
	}
	#Image_03_website_3_mob {
		position: relative;
		width: 290px;
		height: 225px;
		left: 0px;
		padding: 10px;
		top: 21px;
		overflow: visible;
	}
	#check_out_my_offerings_website_cx {
		left: 10px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 21px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#upcoming_evnt_lbl_website_3_mo {
		left: 0px;
		top: 2808px;
		position: absolute;
		overflow: visible;
		width: 391px;
		height: 33px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	
	#Scroll_card_event_scroll_mob_3 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 899px;
		left: 0px;
		top: 2841px;
		display: flex;
        flex-wrap: wrap;
		flex-direction: column;
        overflow-x: auto;
        overflow-y: hidden;
	}
	#Group_14919_mob_3 {
		position: relative;
		width: 350px;
		height: 815px;
		/* left: 20px; */
		top: 42px;
		margin: 30px;
		overflow: visible;
	}
	#upcoming_event_bdr_website_3_m {
		fill: rgba(241,255,255,1);
	}
	.upcoming_event_bdr_website_3_m {
		position: absolute;
		overflow: visible;
		width: 350px;
		height: 815px;
		left: 0px;
		top: 0px;
	}
	#Image_top_website_3_mob {
		position: absolute;
		width: 300px;
		height: 222px;
		left: 25px;
		top: 20px;
		overflow: visible;
	}
	#date_and_day_website_3_mob {
		left: 19px;
		top: 361px;
		position: absolute;
		overflow: visible;
		width: 313px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#Scrollweb_3_mob_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 332px;
		height: 99px;
		left: 7px;
		top: 408px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#event_intro_website_3_mob {
		left: 13px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 325px;
		height: 96px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(84,90,88,1);
	}
	#events_name_lbl_website_3_mob {
		left: 19px;
		top: 320px;
		position: absolute;
		overflow: hidden;
		width: 320px;
		height: 33px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#avaliable_grp_website_3_mob {
		position: absolute;
		width: 67px;
		height: 57px;
		left: 264px;
		top: 520px;
		overflow: visible;
	}
	#Office_website_3_mob {
		left: 0px;
		top: 35px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#aval_website_3_mob {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 68px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#seats_website_3_mob {
		position: absolute;
		width: 40px;
		height: 57px;
		left: 146px;
		top: 520px;
		overflow: visible;
	}
	#n_200_m2_website_3_mob {
		left: 0px;
		top: 35px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#seats_website_3_mob_d {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#cost_website_3_mob {
		position: absolute;
		width: 91px;
		height: 57px;
		left: 7px;
		top: 520px;
		overflow: visible;
	}
	#Confidential_website_3_mob {
		left: 0px;
		top: 35px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#cost_website_3_mob_d {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#organizer_grp_website_3_mob {
		position: absolute;
		width: 320px;
		height: 79px;
		left: 7px;
		top: 612px;
		overflow: visible;
	}
	#n__Room_website_3_mob {
		left: 0px;
		top: 35px;
		position: absolute;
		overflow: visible;
		width: 321px;
		height: 44px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#organizer_website_3_mob {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#enroll_grp_btn_website_3_mob {
		position: absolute;
		width: 324px;
		height: 53px;
		left: 7px;
		top: 716px;
		overflow: visible;
	}
	#bdr_enroll_website_3_mob {
		fill: rgba(248,90,71,1);
	}
	.bdr_enroll_website_3_mob {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 333.444px;
		height: 62.445px;
		left: 0px;
		top: 0px;
	}
	#enroll_lbl_website_3_mob {
		left: 119px;
		top: 16px;
		position: absolute;
		overflow: visible;
		width: 87px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#Loved_by_businesses_and_indivi_mob {
		left: 33px;
		top: 3918px;
		display: none;
		position: absolute;
		overflow: visible;
		width: 338px;
		height: 66px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#testimonial_scroll_website_3_m {
		display: none;
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 355px;
		left: 11px;
		top: 4001px;
		overflow: hidden;
	}
	#Base_website_3_mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(235,235,235,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Base_website_3_mob {
		position: absolute;
		overflow: visible;
		width: 322px;
		height: 300px;
		left: 22px;
		top: 19px;
	}
	#Los_Angeles_CA_website_3_mob {
		left: 112px;
		top: 261px;
		position: absolute;
		overflow: visible;
		width: 78px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(102,102,102,1);
	}
	#Jane_Cooper_website_3_mob {
		left: 112px;
		top: 243px;
		position: absolute;
		overflow: visible;
		width: 72px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(51,51,51,1);
	}
	#Image_down_website_3_mob_ds {
		position: absolute;
		width: 41px;
		height: 40px;
		left: 54px;
		top: 237px;
		overflow: visible;
	}
	#intro_website_3_mob_dt {
		left: 54px;
		top: 102px;
		position: absolute;
		overflow: visible;
		width: 229px;
		height: 105px;
		line-height: 28px;
		margin-top: -6px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#Star_1_website_3_mob {
		fill: rgba(251,176,64,1);
	}
	.Star_1_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 13.097px;
		height: 12.277px;
		left: 54.987px;
		top: 61.698px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Star_2_website_3_mob {
		fill: rgba(251,176,64,1);
	}
	.Star_2_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 13.097px;
		height: 12.277px;
		left: 71.601px;
		top: 61.698px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Star_3_website_3_mob {
		fill: rgba(251,176,64,1);
	}
	.Star_3_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 13.097px;
		height: 12.277px;
		left: 88.215px;
		top: 61.698px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Star_4_website_3_mob {
		fill: rgba(251,176,64,1);
	}
	.Star_4_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 13.097px;
		height: 12.277px;
		left: 104.829px;
		top: 61.698px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Star_5_website_3_mob {
		fill: rgba(251,176,64,1);
	}
	.Star_5_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 13.097px;
		height: 12.277px;
		left: 121.443px;
		top: 61.698px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#small_heading_website_3_mob {
		display: none;
		left: 42px;
		top: 4971px;
		position: absolute;
		overflow: visible;
		width: 307px;
		white-space: nowrap;
		line-height: 33px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.2px;
	}
	#subscribe_lbl_website_3_mob {
		display: none;
		left: 34px;
		top: 4914px;
		position: absolute;
		overflow: visible;
		width: 324px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.2px;
	}
	#input_subscirbe_website_3_mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.input_subscirbe_website_3_mob {
		display: none;
		position: absolute;
		overflow: visible;
		width: 282px;
		height: 54px;
		left: 55px;
		top: 5030px;
		font-size: 15px;
		border: 2px solid #DF847E;
		border-radius: 7px;
		padding-left: 10px;
	}
	#subscribe_grp_website_3_mob {
		display: none;
		position: absolute;
		width: 149px;
		height: 54px;
		left: 121px;
		top: 5112px;
		overflow: visible;
	}
	#subscribe_bdr_website_3_mob {
		fill: rgba(248,90,71,1);
	}
	.subscribe_bdr_website_3_mob {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 167px;
		height: 72px;
		left: 0px;
		top: 0px;
	}
	#Subscribe_lbl_website_3_mob_d {
		left: 31px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 89px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#copyright_line_website_3_mob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.copyright_line_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 324px;
		height: 1px;
		left: 36.75px;
		top: 4900.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_Copyright_Operate_Live_websi_mob {
		left: 29.25px;
		top: 4930px;
		position: absolute;
		overflow: visible;
		width: 263px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#n_234567890_mobile_number_webs_OB {
		left: 55.5px;
		top: 4562px;
		position: absolute;
		overflow: visible;
		width: 83px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#affanghanioperatelive_email_ob_Mob {
		left: 55.5px;
		top: 4608px;
		position: absolute;
		overflow: visible;
		width: 167px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#address_website_mob_ob {
		left: 55.5px;
		top: 4654px;
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#location_img_website_Ob_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 11.5px;
		top: 4662px;
		overflow: visible;
	}
	#email_address_website_2 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 11.5px;
		top: 4604px;
		overflow: visible;
	}
	#phone_number_website_ob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 11.5px;
		top: 4554px;
		overflow: visible;
	}
	#Connect_Unlock_Unlimited__poss {
		left: 137.5px;
		top: 4747px;
		position: absolute;
		overflow: visible;
		width: 233px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#quote_left_webstie_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 112.5px;
		top: 4753px;
		overflow: visible;
	}
	#quote_right_website_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 335.5px;
		top: 4778px;
		overflow: visible;
	}
	#partner_img_and_lbl_scroll {
		mix-blend-mode: normal;
		position: absolute;
		width: 324px;
		height: 64.849px;
		left: 33px;
		top: 732px;
		display: inline-flex;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#bg_partner_mob {
		fill: transparent;
	}
	.bg_partner_mob {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 130px;
		left: -89px;
		top: -50px;
		background:  rgba( 255, 255, 255, 0.5 );
		box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
		backdrop-filter:  blur( 10px );
		-webkit-backdrop-filter:  blur( 10px );
		border-radius:  10px;
		border:  1px solid rgba( 255, 255, 255, 0.18 );
	}
	#partner_lbls_grp_web_3 {
		position: relative;
		width: 143px;
		height: 27px;
		left: 4px;
		display: inline-flex;
		top: 9px;
		overflow: visible;
	}
	#google_lbl_partner_mob_website {
		left: 30px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 114px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#google_1_mob_website_3 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}

	  /* Menu */
	  #menuToggle
	  {
	  display: block;
	  position: fixed;
	  width: 40px;
	  height: 40px;
	  left: 34px;
	  top: 25px;
	  
	  z-index: 1;
	  
	  -webkit-user-select: none;
	  user-select: none;
	  }
	  
	  #menuToggle li
	  {
	  text-decoration: none;
	  color: #232323;
	  
	  transition: color 0.3s ease;
	  }
	  
	  #menuToggle li:hover
	  {
	  color: #ff4f4f;
	  }
	  
	  
	  #menuToggle input
	  {
	  display: block;
	  width: 40px;
	  height: 32px;
	  position: absolute;
	  top: -7px;
	  left: -5px;
	  
	  cursor: pointer;
	  
	  opacity: 0; /* hide this */
	  z-index: 2; /* and place it over the hamburger */
	  
	  -webkit-touch-callout: none;
	  }
	  
	  /*
	  * Just a quick hamburger
	  */
	  #menuToggle span
	  {
	  display: block;
	  width: 33px;
	  height: 4px;
	  margin-bottom: 5px;
	  position: relative;
	  
	  background: #6495ED;
	  border-radius: 3px;
	  
	  z-index: 1;
	  
	  -webkit-transform-origin: 4px 0px;
	  
	          transform-origin: 4px 0px;
	  
	  transition: -webkit-transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	  
	  transition: transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	  
	  transition: transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	  background: 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	  opacity: 0.55s ease;
	  }
	  
	  #menuToggle span:first-child
	  {
	  -webkit-transform-origin: 0% 0%;
	          transform-origin: 0% 0%;
	  }
	  
	  #menuToggle span:nth-last-child(2)
	  {
	  -webkit-transform-origin: 0% 100%;
	          transform-origin: 0% 100%;
	  }
	  
	  /* 
	  * Transform all the slices of hamburger
	  * into a crossmark.
	  */
	  #menuToggle input:checked ~ span
	  {
	  opacity: 1;
	  -webkit-transform: rotate(45deg) translate(-2px, -1px);
	          transform: rotate(45deg) translate(-2px, -1px);
	  background: rgba(255,75,83,1);
	  }
	  
	  /*
	  * But let's hide the middle one.
	  */
	  #menuToggle input:checked ~ span:nth-last-child(3)
	  {
	  opacity: 0;
	  -webkit-transform: rotate(0deg) scale(0.2, 0.2);
	          transform: rotate(0deg) scale(0.2, 0.2);
	  }
	  
	  /*
	  * Ohyeah and the last one should go the other direction
	  */
	  #menuToggle input:checked ~ span:nth-last-child(2)
	  {
	  -webkit-transform: rotate(-45deg) translate(0, -1px);
	          transform: rotate(-45deg) translate(0, -1px);
	  }
	  
	  /*
	  * Make this absolute positioned
	  * at the top left of the screen
	  */
	  #menu
	  {
	  position: absolute;
	  width: 230px;
	  margin: -100px 0 0 -50px;
	  padding: 50px;
	  padding-top: 125px;
	  height: 100vh;
	  background: #ededed;
	  list-style-type: none;
	  -webkit-font-smoothing: antialiased;
	  /* to stop flickering of text in safari */
	  -webkit-transform-origin: 0% 0%;
	          transform-origin: 0% 0%;
	  -webkit-transform: translate(-100%, 0);
	          transform: translate(-100%, 0);
	  transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	  background: rgba( 255, 255, 255, 0.25 );
	  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
	  backdrop-filter: blur( 10px );
	  -webkit-backdrop-filter: blur( 10px );
	  border-radius: 10px;
	  border: 1px solid rgba( 255, 255, 255, 0.18 );
	  }
	  
	  #menu li
	  {
	  padding: 10px 0;
	  font-size: 22px;
	  }
	  #menuToggle input:checked ~ ul
	  {
	  -webkit-transform: none;
	          transform: none;
	  }
	  
	.mediaViewInfo {
		--web-view-name: web 1 mob;
		--web-view-id: web_1_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: web_1_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#partner_img_and_lbl_scroll::-webkit-scrollbar{
		display: none;
	}

    #Scroll_intro_web_1_mob::-webkit-scrollbar{
		display: none;
	}
	#web_1_mob {
		position: absolute;
		width: 375px;
		height: 3412px;
        left:calc((100vw - 375px)/2);
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: web 1 mob;
		--web-view-id: web_1_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#top_banner_color_website_1_mob {
		position: absolute;
		width: 375px;
		height: 134px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#about_name_pic_grp {
		position: absolute;
		width: 359px;
		height: 146px;
		left: 7px;
		top: 190px;
		overflow: visible;
	}
	#pfp_pic_website_1_mob {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#name_website_1_mob {
		left: 81px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 118px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Scroll_intro_web_1_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 278px;
		height: 105px;
		left: 81px;
		top: 41px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#about_me_website_1_mob {
		left: 2px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 278px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Mohammed_Affan_Ghani_lbl_Em {
		left: 90px;
		top: 685px;
		position: absolute;
		overflow: visible;
		width: 197px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#partner_img_and_lbl_scroll {
		mix-blend-mode: normal;
		position: absolute;
		width: 334px;
		height: 64.849px;
		left: 30px;
		top: 746px;
        display: inline-flex;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#bg_partner_mob {
		fill: transparent;
	}
	.bg_partner_mob {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 130px;
		left: -79px;
		top: -50px;
		background:  rgba( 255, 255, 255, 0.5 );
		box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
		backdrop-filter:  blur( 10px );
		-webkit-backdrop-filter:  blur( 10px );
		border-radius:  10px;
		border:  1px solid rgba( 255, 255, 255, 0.18 );
	}
	#partner_lbls_grp_em {
		position: relative;
		width: 175px;
		height: 27px;
		left: 14px;
		top: 9px;
		overflow: visible;
	}
	#google_lbl_partner_mob_website {
		left: 30px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#google_1_mob_website_1 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#all_my_offering_grp_website_1_ {
		position: absolute;
		width: 202px;
		height: 48.62px;
		left: 12px;
		top: 853.5px;
		overflow: visible;
	}
	#heck_out_my_offerings_website_ {
		left: 0px;
		top: 27.62px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 21px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#my_oferings_website_1_mob {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 120px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Scroll_offering_web_1_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 374.352px;
		height: 1256px;
		left: 0.824px;
		top: 941.151px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_offering_web_1_mob::-webkit-scrollbar{
		display: none;
	}
	#my_offering_images_website_1_m {
		position: relative;
		width: 280px;
		height: 333.215px;
		left: 46.676px;
		top: 8px;
		overflow: visible;
	}
	#Project1_website_1_mob {
		position: absolute;
		width: 280px;
		height: 316.214px;
		left: 0px;
		top: 17px;
		overflow: visible;
	}
	#Offer_2_web_1_mob {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 270px;
		height: 17px;
		line-height: 24px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#down_design_website_1_mob {
		position: absolute;
		width: 560.561px;
		height: 475.814px;
		left: 56.986px;
		top: 2159.328px;
		overflow: visible;
	}
	#Path_445_rectangle_website_1_m {
		fill: rgba(143,233,191,1);
	}
	.Path_445_rectangle_website_1_m {
		overflow: visible;
		position: absolute;
		width: 461.299px;
		height: 405.126px;
		-webkit-transform: translate(92.19px, -2121.328px) matrix(1,0,0,1,-18.8749,2151.4571) rotate(-8deg);
		        transform: translate(92.19px, -2121.328px) matrix(1,0,0,1,-18.8749,2151.4571) rotate(-8deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Path_446_circle_website_1_mob {
		fill: rgba(133,231,187,1);
	}
	.Path_446_circle_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 244.52px;
		height: 244.52px;
		-webkit-transform: translate(92.19px, -2121.328px) matrix(1,0,0,1,-82.0002,2342.4322) rotate(-5deg);
		        transform: translate(92.19px, -2121.328px) matrix(1,0,0,1,-82.0002,2342.4322) rotate(-5deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Illustration_website_1_mob {
		position: absolute;
		width: 220.314px;
		height: 126.816px;
		left: 40.029px;
		top: 268.783px;
		overflow: visible;
	}
	#table_website_1_mob {
		fill: rgba(69,74,105,1);
	}
	.table_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 220.313px;
		height: 12.862px;
		left: 0px;
		top: 113.955px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#hair_back_website_1_mob {
		fill: rgba(69,74,105,1);
	}
	.hair_back_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 55.379px;
		height: 65.604px;
		left: 86.584px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#neck_website_1_mob {
		fill: rgba(238,183,184,1);
	}
	.neck_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 39.55px;
		height: 53.658px;
		left: 93.546px;
		top: 36.581px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#shirt_colar_website_1_mob {
		fill: rgba(255,255,255,1);
	}
	.shirt_colar_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 48.83px;
		height: 49.554px;
		left: 91.027px;
		top: 50.956px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#shirt_website_1_mob {
		fill: rgba(223,132,126,1);
	}
	.shirt_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 74.635px;
		height: 63.36px;
		left: 77.058px;
		top: 50.468px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#face_website_1_mob {
		fill: rgba(238,183,184,1);
	}
	.face_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 33.2px;
		height: 35.525px;
		left: 97.61px;
		top: 12.771px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#front_hair_website_1_mob {
		fill: rgba(69,74,105,1);
	}
	.front_hair_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 44.063px;
		height: 58.441px;
		left: 92.347px;
		top: 4.428px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#left_colar_website_1_mob {
		fill: rgba(255,255,255,1);
	}
	.left_colar_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 15.7px;
		height: 14.457px;
		left: 115.248px;
		top: 102.665px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#left_hand_arm_website_1_mob {
		fill: rgba(223,132,126,1);
	}
	.left_hand_arm_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 38.685px;
		height: 47.484px;
		left: 126.387px;
		top: 68.631px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#right_hand_top_website_1_mob {
		fill: rgba(238,183,184,1);
	}
	.right_hand_top_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 19.347px;
		height: 34.819px;
		left: 80.389px;
		top: 33.964px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#right_arm_collar_website_1_mob {
		fill: rgba(255,255,255,1);
	}
	.right_arm_collar_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 13.651px;
		height: 9.917px;
		left: 80.315px;
		top: 63.693px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#right_arm_website_1_mob {
		fill: rgba(223,132,126,1);
	}
	.right_arm_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 19.976px;
		height: 47.554px;
		left: 74.944px;
		top: 69.901px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#down_hand_website_1_mob {
		fill: rgba(238,183,184,1);
	}
	.down_hand_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 39.676px;
		height: 14.98px;
		left: 81.446px;
		top: 105.529px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#steam_3_website_1_mob {
		fill: rgba(62,60,85,1);
	}
	.steam_3_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 2.238px;
		height: 3.137px;
		left: 27.381px;
		top: 108.619px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#stem_2_pot_website_1_mob {
		fill: rgba(62,60,85,1);
	}
	.stem_2_pot_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 2.238px;
		height: 3.137px;
		left: 27.381px;
		top: 106.7px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#stem_pot_1_website_1_mob {
		fill: rgba(62,60,85,1);
	}
	.stem_pot_1_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 2.238px;
		height: 3.137px;
		left: 27.381px;
		top: 104.783px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leave_design_website_1_m {
		fill: rgba(56,210,159,1);
	}
	.pot_1_leave_design_website_1_m {
		overflow: visible;
		position: absolute;
		width: 10.739px;
		height: 25.693px;
		left: 23.114px;
		top: 78.158px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_design_website_1_mob {
		fill: rgba(243,123,123,1);
		stroke: rgba(243,123,123,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.pot_1_design_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 21.514px;
		height: 12.82px;
		left: 18.369px;
		top: 109.655px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#laptop_website_1_mob {
		fill: rgba(62,60,85,1);
	}
	.laptop_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 51.912px;
		height: 37.189px;
		left: 149.624px;
		top: 85.796px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#join_now_grp_website_1_mob {
		position: absolute;
		width: 125px;
		height: 42px;
		left: 125px;
		top: 2750px;
		overflow: visible;
	}
	#join_now_bdr_website_1_mob {
		fill: rgba(143,233,191,1);
	}
	.join_now_bdr_website_1_mob {
		position: absolute;
		overflow: visible;
		width: 125px;
		height: 42px;
		left: 0px;
		top: 0px;
	}
	#Join_Now_website_1_mob {
		left: 28px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 79px;
		white-space: nowrap;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Connect__Follow_Us__website_1_mob {
		left: 93px;
		top: 2703px;
		position: absolute;
		overflow: visible;
		width: 191px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(54,54,54,1);
	}
	#top_menu_options_website_1_mob {
		position: absolute;
		width: 375px;
		height: 36px;
		left: 0px;
		top: 134px;
		overflow: visible;
	}
	#menu_bg_design {
		fill: rgba(143,233,191,1);
	}
	.menu_bg_design {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 36px;
		left: 0px;
		top: 0px;
	}
	#Intro_lbl_website_1_mob {
		left: 32px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#home-icon-silhouette_img_websi {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#Offerings_lbl_website_1_mob {
		left: 114px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 62px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#discount_img_website_1_mob {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 89px;
		top: 7px;
		overflow: visible;
	}
	#Events_lbl_website_1_mob {
		left: 225px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#events_img_website_1_mob {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 200px;
		top: 7px;
		overflow: visible;
	}
	#Contact_lbl_website_1_mob {
		left: 317px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 52px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#contact_img_website_1_mob {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 292px;
		top: 7px;
		overflow: visible;
	}
	#footer_overll_grp_web_1_mob {
		position: absolute;
		width: 381.235px;
		height: 529px;
		left: -3.118px;
		top: 2889px;
		overflow: visible;
	}
	#footer_bdr_web1_mob_dc {
		fill: url(#footer_bdr_web1_mob_dc);
	}
	.footer_bdr_web1_mob_dc {
		overflow: visible;
		position: absolute;
		width: 381.235px;
		height: 529px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#copy_line_website_1_mob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.copy_line_website_1_mob {
		overflow: visible;
		position: absolute;
		width: 324px;
		height: 1px;
		left: 25.5px;
		top: 443.858px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_Copyright_Operate_Live_lbl_w {
		left: 26px;
		top: 469.358px;
		position: absolute;
		overflow: visible;
		width: 279px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#n_234567890_mobile_number_webs_mob {
		left: 70px;
		top: 89px;
		position: absolute;
		overflow: visible;
		width: 83px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#affanghanioperatelive_email_we_mob {
		left: 70px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 167px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#address_website_2_mob_ee {
		
		position: absolute;
		overflow: visible;
		width: 286px;
		height: 60px;
        left: 65px;
		top: 185px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Connect_with_us_and_unlock_lim_mob {
		left: 50px;
		top: 291px;
		position: absolute;
		overflow: visible;
		width: 271px;
		height: 40px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#location_img_website_2_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 25px;
		top: 185px;
		overflow: visible;
	}
	#email_address_website_2_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 25px;
		top: 134px;
		overflow: visible;
	}
	#phone_number_website_2_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 25px;
		top: 84px;
		overflow: visible;
	}
	#quote_left_webstie_2_mob {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 18px;
		top: 291px;
		overflow: visible;
	}
	#quote_right_website_2_mob {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 322px;
		top: 311px;
		overflow: visible;
	}
	#Ellipse_5_circle_mob:hover {
		fill: rgba(120,120,255,1);
	}
	.Ellipse_5_circle_mob:hover {
		width: 98px;
		height: 98px;
	}
	#Ellipse_5_circle_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_5_circle_mob {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 68px;
		height: 68px;
		left: 86px;
		top: 361.482px;
		transition: all .3s ease-out;
	}
	#linked_in_website_2_layout_mob {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 99.125px;
		top: 375.232px;
		overflow: visible;
	}
	#n_website_2_layout_mob {
		fill: rgba(63,61,86,1);
	}
	.n_website_2_layout_mob {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.954px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_down_mob {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_down_mob {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.098px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_website_2_layout_mob {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_website_2_layout_mob {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#insta_circle_mob:hover {
		fill: rgba(255,120,120,1);
	}
	.insta_circle_mob:hover {
		width: 98px;
		height: 98px;
	}
	#insta_circle_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.insta_circle_mob {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 68px;
		height: 68px;
		left: 166.019px;
		top: 361px;
		transition: all .3s ease-out;
	}
	#instagram_website_2_layout_mob {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 179.144px;
		top: 374.125px;
		overflow: visible;
	}
	#whatsapp_bdr_mob:hover {
		fill: rgba(120,255,165,1);
	}
	.whatsapp_bdr_mob:hover {
		width: 98px;
		height: 98px;
	}
	#whatsapp_bdr_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.whatsapp_bdr_mob {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 68px;
		height: 68px;
		left: 246.038px;
		top: 361.482px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_website_2_layout_ {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 258.538px;
		top: 373.982px;
		overflow: visible;
	}
	#Scroll_vision_miussion_and_pri {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 270px;
		
		top: 352px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#mission_grp_web_1_mob {
		position: absolute;
		width: 164px;
		height: 223px;
		left: 21px;
		top: 19px;
		overflow: visible;
	}
	#mission_card__mob {
		fill: rgba(143,214,233,1);
	}
	.mission_card__mob {
		position: absolute;
		overflow: visible;
		width: 164px;
		height: 223px;
		left: 0px;
		top: 0px;
	}
	#box_1_mob_website_1_op_live_th {
		position: absolute;
		width: 39px;
		height: 33px;
		left: 62px;
		top: 31px;
		overflow: visible;
	}
	#top_offerings_mob {
		left: 56px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 48px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#Scroll_missio_mobn_details_web {
		mix-blend-mode: normal;
		position: absolute;
		width: 164px;
		height: 138px;
		left: 0px;
		top: 82px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_missio_mobn_details_web::-webkit-scrollbar{
		display: none;
	}
	#Lorem_ipsum_dolor_sit_amet_em_mob {
		left: 7px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 150px;
		height: 238px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#vision_grp_web_1_mob {
		position: absolute;
		width: 163px;
		height: 223px;
		left: 204px;
		top: 19px;
		overflow: visible;
	}
	#vision_card_mob {
		fill: rgba(143,233,191,1);
	}
	.vision_card_mob {
		position: absolute;
		overflow: visible;
		width: 163px;
		height: 223px;
		left: 0px;
		top: 0px;
	}
	#box_2_website_1_o_mobp_live_th {
		position: absolute;
		width: 39px;
		height: 33px;
		left: 64px;
		top: 31px;
		overflow: visible;
	}
	#top_offerings_mob_ed {
		left: 61px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 39px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#Scroll_vision_details_mob_web_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 163px;
		height: 138px;
		left: 0px;
		top: 82px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_vision_details_mob_web_::-webkit-scrollbar{
		display: none;
	}
	#Lorem_ipsum_dolor_sit_amet_con_ef {
		left: 11px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 146px;
		height: 238px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#princi_grp_web_1_mob {
		position: absolute;
		width: 162px;
		height: 223px;
		left: 391px;
		top: 19px;
		overflow: visible;
	}
	#principle_mission_card_mob {
		fill: rgba(215,232,188,1);
	}
	.principle_mission_card_mob {
		position: absolute;
		overflow: visible;
		width: 162px;
		height: 223px;
		left: 0px;
		top: 0px;
	}
	#box_3_off_website_mob_1_op_liv {
		position: absolute;
		width: 40px;
		height: 33px;
		left: 61px;
		top: 31px;
		overflow: visible;
	}
	#top_offerings_mob_ej {
		left: 49px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 60px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#Scroll_prrinci_details_web_1_m {
		mix-blend-mode: normal;
		position: absolute;
		width: 162px;
		height: 138px;
		left: 0px;
		top: 82px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_prrinci_details_web_1_m::-webkit-scrollbar{
		display: none;
	}
	#Lorem_ipsum_dolor_sit_amet_con_el {
		left: 12px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 142px;
		height: 238px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}

.mediaViewInfo {
    --web-view-name: web 4 mob;
    --web-view-id: web_4_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: web_4_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#web_4_mob {
    position: absolute;
    width: 375px;
    height: 2306px;
    left:calc((100vw - 375px)/2);
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: web 4 mob;
    --web-view-id: web_4_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#design_main_page_web_4_theme_m {
    fill: rgba(32,142,121,1);
}
.design_main_page_web_4_theme_m {
    overflow: visible;
    position: absolute;
    width: 375px;
    height: 294.785px;
    left: 0px;
    top: 112.803px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#markus-wink_img_centre_med_mob {
    position: absolute;
    width: 208.687px;
    height: 106.072px;
    left: 15.365px;
    top: 260.195px;
    overflow: visible;
}
#follow_us_btn__grp_web_4mob {
    position: absolute;
    width: 76px;
    height: 16px;
    left: 148px;
    top: 350px;
    overflow: visible;
}
#bdr_med_web_4_mob {
    fill: rgba(92,181,164,1);
}
.bdr_med_web_4_mob {
    -webkit-filter: drop-shadow(3px 3px 2px rgba(223, 223, 223, 1));
            filter: drop-shadow(3px 3px 2px rgba(223, 223, 223, 1));
    position: absolute;
    overflow: visible;
    width: 82px;
    height: 22px;
    left: 0px;
    top: 0px;
}
#Follow_Us_med_web_4_mob {
    left: 14px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 20px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    color: rgba(255,255,255,1);
}
#arrow_follow_us_med_web_4_mob {
    fill: rgba(255,255,255,1);
}
.arrow_follow_us_med_web_4_mob {
    overflow: visible;
    position: absolute;
    width: 7.615px;
    height: 7.422px;
    left: 54.065px;
    top: 4.271px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Heading_left_med_web_4_mob {
    left: 23px;
    top: 78px;
    position: absolute;
    overflow: visible;
    width: 141px;
    height: 33px;
    line-height: 73px;
    margin-top: -24px;
    text-align: right;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(0,0,0,1);
}
#Heading_med_web_4mob {
    left: 216px;
    top: 78px;
    position: absolute;
    overflow: visible;
    width: 150px;
    height: 33px;
    line-height: 73px;
    margin-top: -24px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(0,0,0,1);
}
#marijuana_img_bdr_med_web_4_mo {
    position: absolute;
    width: 37px;
    height: 38px;
    left: 169px;
    top: 75px;
    overflow: visible;
}
#Scroll_intro_web_4_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 1211px;
    height: 89px;
    left: 0px;
    top: 133px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Lorem_ipsum_dolor_sit_amet_hh_mob {
    left: 6px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 262px;
    height: 180px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#text_our_partner_bdr_med_web_4 {
    -webkit-transform: matrix(1,0,0,1,266,263) rotate(90deg);
            transform: matrix(1,0,0,1,266,263) rotate(90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 181px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(255,255,255,1);
    letter-spacing: 0.1px;
}
#partner_scroll_web_4 {
    mix-blend-mode: normal;
    position: absolute;
    width: 216px;
    height: 213px;
    left: 304px;
    top: 184px;
    overflow-x: hidden;
    overflow-y: auto;
}
#g_and_google_grp_mob {
    position: relative;
    width: 20px;
    height: 175px;
    left: 10px;
    top: 0px;
    overflow: visible;
}
#google_lbl_partnermed_web_4_mo {
    -webkit-transform: translate(-1189px, -274px) matrix(1,0,0,1,1126.5,366.5) rotate(90deg);
            transform: translate(-1189px, -274px) matrix(1,0,0,1,1126.5,366.5) rotate(90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 146px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(233,255,251,1);
}
#google_1img_med_web_4_mob {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Scroll_ll_misvispri_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 377px;
    height: 368px;
    /* left: 34px; */
    top: 433px;
    overflow-x: scroll;
    overflow-y: hidden;
}
#mission_all_med_web_4mob {
    position: absolute;
    width: 189px;
    height: 299px;
    left: 19px;
    top: 20px;
    overflow: visible;
}
#bdr_mission_med_web_4mob {
    fill: rgba(166,210,202,0.651);
}
.bdr_mission_med_web_4mob {
    position: absolute;
    overflow: visible;
    width: 189px;
    height: 299px;
    left: 0px;
    top: 0px;
}
#box_1_website_med_web_4_mob {
    position: absolute;
    width: 52px;
    height: 45px;
    left: 73px;
    top: 44px;
    overflow: visible;
}
#mission_lbl_med_web_4_mob {
    left: 64px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 66px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0,0,0,1);
}
#Scroll_mission_med_web_4_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 182px;
    height: 193px;
    left: 4px;
    top: 103px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Scroll_mission_med_web_4_mob::-webkit-scrollbar{
    display: none;
}
#Lorem_ipsum_dolor_sit_amet_con_mob {
    left: 3px;
    top: 8px;
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#vision_grp_all_med_web_4_mob {
    position: absolute;
    width: 189px;
    height: 299px;
    left: 229px;
    top: 20px;
    overflow: visible;
}
#bdr_vision_back_med_web_4mob {
    fill: rgba(135,207,172,0.647);
}
.bdr_vision_back_med_web_4mob {
    position: absolute;
    overflow: visible;
    width: 189px;
    height: 299px;
    left: 0px;
    top: 0px;
}
#box_2_website_1_med_web_4_mob {
    position: absolute;
    width: 54px;
    height: 45px;
    left: 69px;
    top: 44px;
    overflow: visible;
}
#vision_lbl_med_web_4_mob {
    left: 67px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0,0,0,1);
}
#Scroll_vision_med_web_4_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 182px;
    height: 193px;
    left: 4px;
    top: 103px;
    overflow-x: hidden;
    overflow-y: scroll;
}

#Scroll_vision_med_web_4_mob::-webkit-scrollbar{
    display: none;
}

#Lorem_ipsum_dolor_sit_amet_con_cl {

    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#principle_grp_all_med_web_4_mo {
    position: absolute;
    width: 189px;
    height: 299px;
    left: 438px;
    top: 20px;
    overflow: visible;
}
#princi_bdr_akll_med_web_4_mob {
    fill: rgba(151,135,207,0.647);
}
.princi_bdr_akll_med_web_4_mob {
    position: absolute;
    overflow: visible;
    width: 189px;
    height: 299px;
    left: 0px;
    top: 0px;
}
#box_3_off_med_web_4_mob_op_liv {
    position: absolute;
    width: 54px;
    height: 45px;
    left: 68px;
    top: 44px;
    overflow: visible;
}
#princi_med_web_4_mob_lbl {
    left: 51px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0,0,0,1);
}
#Scroll_prrinci_details_med_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 182px;
    height: 193px;
    left: 4px;
    top: 103px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Scroll_prrinci_details_med_mob::-webkit-scrollbar{
    display: none;
}
#Lorem_ipsum_dolor_sit_amet_con_cr {
    left: 8px;
    top: 8px;
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#oofering_lbl_med_web_4_mob {
    left: 60px;
    top: 811px;
    position: absolute;
    overflow: visible;
    width: 179px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(0,0,0,1);
}
#Scroll_offering_med_web_4_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 1152px;
    height: 374px;
    left: 34px;
    top: 881px;
    display: -webkit-box;
    overflow-x: auto;
    overflow-y: hidden;
}
#Scroll_offering_med_web_4_mob::-webkit-scrollbar{
    display: none;
}
#offering_crd_1_grp_med_web_4_m {
    position: relative;
    width: 200px;
    height: 332px;
    left: 15px;
    top: 11px;
    overflow: visible;
}
#title_bdr_med_web_4_mob {
    fill: rgba(255,255,255,1);
}
.title_bdr_med_web_4_mob {
    -webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 50px;
    left: 14px;
    top: 300px;
}
#BiziBees_PVT_LTD_med_web_4_mob {
    left: 14px;
    top: 306px;
    position: absolute;
    overflow: visible;
    width: 173px;
    height: 20px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#img_border_med_web_4_mob_of {
    -webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#chuttersna_med_web_4_mob_img {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 14px;
    top: 7px;
    overflow: visible;
}
#map_grp_mob_web_4 {
    position: absolute;
    width: 375px;
    height: 376px;
    left: 0px;
    top: 1293px;
    overflow: visible;
}
#map_area_med_web_4_mob {
    fill: rgba(255,255,255,1);
}
.map_area_med_web_4_mob {
    position: absolute;
    overflow: visible;
    width: 375px;
    height: 330px;
    left: 0px;
    top: 23px;
}
#map_top_med_web_4_mob {
    fill: rgba(32,142,121,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.map_top_med_web_4_mob {
    overflow: visible;
    position: absolute;
    width: 374.902px;
    height: 23px;
    left: 0.098px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#map_below_desion_med_web_4_mob {
    fill: rgba(32,142,121,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.map_below_desion_med_web_4_mob {
    overflow: visible;
    position: absolute;
    width: 374.902px;
    height: 23px;
    left: 0.098px;
    top: 353px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#medincine_bdr_web_4_mob {
    fill: rgba(32,142,121,1);
}
.medincine_bdr_web_4_mob {
    position: absolute;
    overflow: visible;
    width: 375px;
    height: 55px;
    left: 0px;
    top: 1749px;
}
#Medicine_is_not_only_a_science_mob {
    left: 11px;
    top: 1763px;
    position: absolute;
    overflow: visible;
    width: 2383px;
    height: 28px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#footer_med_web_4_mob {
    fill: rgba(149,213,182,1);
}
.footer_med_web_4_mob {
    position: absolute;
    overflow: visible;
    width: 375px;
    height: 500px;
    left: 0px;
    top: 1804px;
}
#n_234567890_mobile_number_mob {
    left: 60px;
    top: 1834px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#affanghanioperatelive_email_mob {
    left: 60px;
    top: 1888px;
    position: absolute;
    overflow: visible;
    width: 167px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#address_med_web_4_mob {
    left: 60px;
    top: 1942px;
    position: absolute;
    overflow: visible;
    width: 280px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#location_img_med_web_4_mob {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 16px;
    top: 1936px;
    overflow: visible;
}
#email_address_med_web_4_mob {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 16px;
    top: 1882px;
    overflow: visible;
}
#phone_number_med_web_4_mob {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 16px;
    top: 1828px;
    overflow: visible;
}
#Connect_with_us_and_unlock_mob {
    left: 53px;
    top: 2128px;
    position: absolute;
    overflow: visible;
    width: 271px;
    height: 60px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#socials_grp_med_web_4_mob {
    position: absolute;
    width: 210px;
    height: 50.001px;
    left: 76px;
    top: 2036px;
    overflow: visible;
}
#linked_in_grp_med_web_4_mob {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 160px;
    top: 0px;
    overflow: visible;
}
#Ellipse_5_med_web_4_pc:hover {
    fill: rgba(120,120,255,1);
}
.Ellipse_5_med_web_4_pc:hover {
    -webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
    width: 98px;
    height: 98px;
}
#Ellipse_5_med_web_4_pc {
    fill: rgba(255,255,255,1);
    transition: all .3s ease-out;
}
.Ellipse_5_med_web_4_pc {
    -webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 59px;
    height: 59px;
    left: 0px;
    top: 0px;
    transition: all .3s ease-out;
}
#linked_in_website_4_layout_mob {
    position: absolute;
    width: 24.349px;
    height: 22.135px;
    left: 12.5px;
    top: 12.5px;
    overflow: visible;
}
#n_website_2_layout {
    fill: rgba(63,61,86,1);
}
.n_website_2_layout {
    overflow: visible;
    position: absolute;
    width: 16.092px;
    height: 15.182px;
    left: 8.257px;
    top: 6.953px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#linked_in_i_down {
    fill: rgba(63,61,86,1);
}
.linked_in_i_down {
    overflow: visible;
    position: absolute;
    width: 4.746px;
    height: 15.037px;
    left: 0.614px;
    top: 7.098px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#top_dot_i_website_2_layout {
    fill: rgba(63,61,86,1);
}
.top_dot_i_website_2_layout {
    overflow: visible;
    position: absolute;
    width: 5.761px;
    height: 5.217px;
    left: 0px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Insta_grp_med_web_4_mob {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 80px;
    top: 0px;
    overflow: visible;
}
#Ellipse_4_med_web_4_pc:hover {
    fill: rgba(255,120,120,1);
}
.Ellipse_4_med_web_4_pc:hover {
    -webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
    width: 98px;
    height: 98px;
}
#Ellipse_4_med_web_4_pc {
    fill: rgba(255,255,255,1);
    transition: all .3s ease-out;
}
.Ellipse_4_med_web_4_pc {
    -webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 59px;
    height: 59px;
    left: 0px;
    top: 0px;
    transition: all .3s ease-out;
}
#instagram_website_4_layout_mob {
    position: absolute;
    width: 24.375px;
    height: 24.375px;
    left: 13.125px;
    top: 13.125px;
    overflow: visible;
}
#whatsapp_grp_med_web_4_mob {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Ellipse_3_med_web_4_pc:hover {
    fill: rgba(50,172,158,1);
}
.Ellipse_3_med_web_4_pc:hover {
    -webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
    width: 98px;
    height: 98px;
}
#Ellipse_3_med_web_4_pc {
    fill: rgba(255,255,255,1);
    transition: all .3s ease-out;
}
.Ellipse_3_med_web_4_pc {
    -webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 59px;
    height: 59px;
    left: 0px;
    top: 0px;
    transition: all .3s ease-out;
}
#whatsapp_lbl_website4_layout_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 12.5px;
    top: 12.5px;
    overflow: visible;
}
#quote_left_med_web_4_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 15px;
    top: 2127px;
    overflow: visible;
}
#quote_right_med_web_4_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 112px;
    top: 2168px;
    overflow: visible;
}
#n_Copyright_Operate_Live_med_mob {
    left: 16px;
    top: 2215.25px;
    position: absolute;
    overflow: visible;
    width: 318px;
    white-space: nowrap;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#profile_pic_logo_web_4_mob {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 163px;
    top: 9px;
    border-radius: 50%;
    overflow: hidden;
}

  /* Menu */
  #menuToggle
  {
  display: block;
  position: fixed;
  width: 40px;
  height: 40px;
  left: 34px;
  top: 25px;
  
  z-index: 1;
  
  -webkit-user-select: none;
  user-select: none;
  }
  
  #menuToggle li
  {
  text-decoration: none;
  color: #232323;
  
  transition: color 0.3s ease;
  }
  
  #menuToggle li:hover
  {
  color: #ff4f4f;
  }
  
  
  #menuToggle input
  {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
  }
  
  /*
  * Just a quick hamburger
  */
  #menuToggle span
  {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: #6495ED;
  border-radius: 3px;
  
  z-index: 1;
  
  -webkit-transform-origin: 4px 0px;
  
          transform-origin: 4px 0px;
  
  transition: -webkit-transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  
  transition: transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  
  transition: transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  background: 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  opacity: 0.55s ease;
  }
  
  #menuToggle span:first-child
  {
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  }
  
  #menuToggle span:nth-last-child(2)
  {
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  }
  
  /* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
  #menuToggle input:checked ~ span
  {
  opacity: 1;
  -webkit-transform: rotate(45deg) translate(-2px, -1px);
          transform: rotate(45deg) translate(-2px, -1px);
  background: rgba(255,75,83,1);
  }
  
  /*
  * But let's hide the middle one.
  */
  #menuToggle input:checked ~ span:nth-last-child(3)
  {
  opacity: 0;
  -webkit-transform: rotate(0deg) scale(0.2, 0.2);
          transform: rotate(0deg) scale(0.2, 0.2);
  }
  
  /*
  * Ohyeah and the last one should go the other direction
  */
  #menuToggle input:checked ~ span:nth-last-child(2)
  {
  -webkit-transform: rotate(-45deg) translate(0, -1px);
          transform: rotate(-45deg) translate(0, -1px);
  }
  
  /*
  * Make this absolute positioned
  * at the top left of the screen
  */
  #menu
  {
  position: absolute;
  width: 230px;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  height: 100vh;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
  transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 10px );
  -webkit-backdrop-filter: blur( 10px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  }
  
  #menu li
  {
  padding: 10px 0;
  font-size: 22px;
  }
  #menuToggle input:checked ~ ul
  {
  -webkit-transform: none;
          transform: none;
  }
  

.mediaViewInfo {
    --web-view-name: web 5 mob ShadowScape;
    --web-view-id: web_5_mob_ShadowScape;
    --web-scale-on-resize: true;
    --web-center-horizontally: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: web_5_mob_ShadowScape;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#web_5_mob_ShadowScape {
    position: absolute;
    width: 375px;
    height: 3174px;
    left:calc((100vw - 375px)/2);
    background-color: rgba(25,25,25,1);
    overflow: hidden;
    --web-view-name: web 5 mob ShadowScape;
    --web-view-id: web_5_mob_ShadowScape;
    --web-scale-on-resize: true;
    --web-center-horizontally: true;
    --web-enable-deep-linking: true;
}
#shadow_n_th_shadow_space_website_5_mo {
    -webkit-filter: blur(50px);
            filter: blur(50px);
    fill: rgba(255,255,255,0.149);
}
.shadow_n_th_shadow_space_website_5_mo {
    overflow: visible;
    position: absolute;
    width: 1885.334px;
    height: 1403.135px;
    left: -188.548px;
    top: 2097.434px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#shadow_n_rd_shadow_space_website_5_mo {
    -webkit-filter: blur(50px);
            filter: blur(50px);
    fill: rgba(255,255,255,0.149);
}
.shadow_n_rd_shadow_space_website_5_mo {
    overflow: visible;
    position: absolute;
    width: 1377.635px;
    height: 915.344px;
    -webkit-transform: matrix(1,0,0,1,-148.6987,733.6591) rotate(-10deg);
            transform: matrix(1,0,0,1,-148.6987,733.6591) rotate(-10deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 0px;
}
#shadow_n_nd_shadow_space_website_5_mo {
    -webkit-filter: blur(50px);
            filter: blur(50px);
    fill: rgba(255,255,255,0.149);
}
.shadow_n_nd_shadow_space_website_5_mo {
    overflow: visible;
    position: absolute;
    width: 1120.058px;
    height: 961.238px;
    left: -72.725px;
    top: 441.268px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#shadow_top_design_shadow_space_websit {
    -webkit-filter: blur(50px);
            filter: blur(50px);
    fill: rgba(255,255,255,0.149);
}
.shadow_top_design_shadow_space_websit {
    overflow: visible;
    position: absolute;
    width: 835.086px;
    height: 734.371px;
    left: -92.541px;
    top: -106.562px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#shadow_top_deafult_pic_img {
    position: absolute;
    width: 221px;
    height: 234px;
    left: 154px;
    top: 44px;
    overflow: visible;
}
#shadow_top_gold_shadow_space_website_ {
    fill: rgba(227,168,87,1);
}
.shadow_top_gold_shadow_space_website_ {
    position: absolute;
    overflow: visible;
    width: 164px;
    height: 184px;
    left: 57px;
    top: 0px;
}
#shadow_down_white_shadow_space_websit {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.shadow_down_white_shadow_space_websit {
    position: absolute;
    overflow: visible;
    width: 165px;
    height: 185px;
    left: 0px;
    top: 49px;
}
#shadow_damon-shadow_space_website_5_m {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    position: absolute;
    width: 164px;
    height: 185px;
    left: 29px;
    top: 25px;
    overflow: visible;
}
#shadow_profileshadow_space_website_5_ {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 16px;
    top: 78px;
    border-radius: 50%;
    overflow: hidden;
}
#shadow_Heading_shadow_space_website_5 {
    left: 8px;
    top: 172px;
    position: absolute;
    overflow: visible;
    width: 147px;
    height: 114px;
    line-height: 35px;
    margin-top: -7.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#shadow_Scroll_shadow_space_website_5_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 605px;
    height: 152px;
    left: 0px;
    top: 301px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#shadow_intro_lbl_website_3 {
    left: 7px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 361px;
    height: 137px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
    letter-spacing: 0.1px;
}
#shadow_what_weshadow_space_website_5_ {
    left: 97px;
    top: 646px;
    position: absolute;
    overflow: visible;
    width: 183px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#shadow_Scroll_mission_shadow_space_we {
    mix-blend-mode: normal;
    position: absolute;
    width: 646px;
    height: 127px;
    left: 102.25px;
    top: 770px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#shadow_intro_2_website_3 {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 261px;
    height: 127px;
    line-height: 26px;
    margin-top: -5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255,255,255,1);
}
#shadow_mission_lbl_shadow_space_websi {
    left: 192.25px;
    top: 721px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: rgba(255,255,255,1);
}
#shadow_Icon_2_grp_shadow_space_websit {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 21.75px;
    top: 722px;
    overflow: visible;
}
#shadow_bg_blue_design {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.shadow_bg_blue_design {
    -webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(77, 223, 253, 0.016));
            filter: drop-shadow(0px 1.852px 3.148px rgba(77, 223, 253, 0.016));
    position: absolute;
    overflow: visible;
    width: 82.444px;
    height: 82.445px;
    left: 0px;
    top: 0px;
}
#shadow_leadership_2_shadow_space_webs {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 32.25px;
    top: 732.5px;
    overflow: visible;
}
#shadow_Scroll_vision_shadow_space_web {
    mix-blend-mode: normal;
    position: absolute;
    width: 697px;
    height: 153px;
    left: 97px;
    top: 1010px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#shadow_intro_website_3 {
    left: 0px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 266px;
    height: 152px;
    line-height: 26px;
    margin-top: -5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255,255,255,1);
}
#shadow_Vision_shadow_space_website_5_ {
    left: 187px;
    top: 960px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: rgba(255,255,255,1);
}
#shadow_Icon_grp_shadow_space_website_ {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 22px;
    top: 957px;
    overflow: visible;
}
#shadow_Fill_11_website_3 {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.shadow_Fill_11_website_3 {
    -webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(150, 114, 255, 0.016));
            filter: drop-shadow(0px 1.852px 3.148px rgba(150, 114, 255, 0.016));
    position: absolute;
    overflow: visible;
    width: 82.444px;
    height: 82.445px;
    left: 0px;
    top: 0px;
}
#shadow_target_1_shadow_space_website_ {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 33.5px;
    top: 966.5px;
    overflow: visible;
}
#shadow_Scroll_princi_shadow_space_web {
    mix-blend-mode: normal;
    position: absolute;
    width: 675px;
    height: 150px;
    left: 102.75px;
    top: 1272px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#shadow_intro_lbl_website_3_cj {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 261px;
    height: 148px;
    line-height: 26px;
    margin-top: -5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255,255,255,1);
}
#shadow_principles_lbl_shadow_space_we {
    left: 183.25px;
    top: 1223px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: rgba(255,255,255,1);
}
#shadow_Fill_shadow_space_website_5_mo {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.shadow_Fill_shadow_space_website_5_mo {
    -webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(242, 184, 236, 0.016));
            filter: drop-shadow(0px 1.852px 3.148px rgba(242, 184, 236, 0.016));
    position: absolute;
    overflow: visible;
    width: 82.444px;
    height: 82.445px;
    left: 13.25px;
    top: 1223.5px;
}
#shadow_principle_2_shadow_space_websi {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 20.75px;
    top: 1234px;
    overflow: visible;
}
#shadow_oofering_lbl_shadow_space_webs {
    left: 95px;
    top: 1482px;
    position: absolute;
    overflow: visible;
    width: 187px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(255,255,255,1);
}
#shadow_Scroll_offering_space_mob_5 {
    mix-blend-mode: normal;
    position: absolute;
    width: 341.57px;
    height: 315.077px;
    left: 21.68px;
    top: 1540.17px;
    display: -webkit-box;
    overflow-y: hidden;
    overflow-x: auto;
}
#shadow_space_grp_web_mob_5 {
    position: relative;
    width: 190.07px;
    height: 268.577px;
    
    top: 13px;
    margin: 10px;
    overflow: visible;
}
#shadow_bdr_offering_mob {
    fill: rgba(255,255,255,1);
}
.shadow_bdr_offering_mob {
    -webkit-filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
            filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
    position: absolute;
    overflow: visible;
    width: 205.57px;
    height: 284.076px;
    left: 0px;
    top: 0px;
}
#shadow_half_sun_spave_mob {
    fill: rgba(46,46,46,1);
}
.shadow_half_sun_spave_mob {
    overflow: visible;
    position: absolute;
    width: 15.194px;
    height: 28.924px;
    left: 15.701px;
    top: 12.396px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#shadow_dot_design_carrd_offering {
    fill: rgba(46,46,46,1);
}
.shadow_dot_design_carrd_offering {
    position: absolute;
    overflow: visible;
    width: 5.785px;
    height: 5.785px;
    left: 6.611px;
    top: 23.965px;
}
#shadow_oofering_lbl_med_web_4_pc {
    left: 9.503px;
    top: 241.307px;
    position: absolute;
    overflow: visible;
    width: 170px;
    height: 22px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#shadow_markus-img_offerings {
    position: absolute;
    width: 169.272px;
    height: 223.857px;
    left: 9.503px;
    top: 9.23px;
    overflow: visible;
}
#shadow_oofering_lbl_shadow_space_webs_cv {
    left: 116px;
    top: 1914px;
    position: absolute;
    overflow: visible;
    width: 145px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(255,255,255,1);
}
#shadow_Scroll_event_vgrp_mob_web_5 {
    mix-blend-mode: normal;
    position: absolute;
    width: 356.5px;
    height: 554.5px;
    left: 10px;
    top: 1996px;
    display: -webkit-box;
    overflow-x: auto;
    overflow-y: hidden;
}
#shadow_event_grp_shadow_space_website {
    position: relative;
    width: 287px;
    height: 507px;
    left: 6px;
    top: 8px;
    margin: 10px;
    overflow: visible;
}
#shadow_event_bg_card_1_shadow_space_w {
    fill: rgba(255,255,255,1);
}
.shadow_event_bg_card_1_shadow_space_w {
    -webkit-filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
            filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
    width: 442px;
    height: 286px;
    position: absolute;
    overflow: visible;
    -webkit-transform: translate(-43px, -1974px) matrix(1,0,0,1,-35,2117) rotate(90deg);
            transform: translate(-43px, -1974px) matrix(1,0,0,1,-35,2117) rotate(90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
}
#shadow_Anime_Edit_shadow_space_websit {
    position: absolute;
    width: 230px;
    height: 210px;
    left: 28px;
    top: 0px;
    overflow: visible;
}
#shadow_oofering_lblshadow_space_websi {
    left: 0px;
    top: 217px;
    position: absolute;
    overflow: visible;
    width: 287px;
    height: 22px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#shadow_Scrolldesc_shadow_space_websit {
    mix-blend-mode: normal;
    position: absolute;
    width: 279px;
    height: 139px;
    left: 7px;
    top: 368px;
    overflow: hidden;
}
#shadow_oofering_lbl_shadow_space_webs_dh {
    left: 4px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 275px;
    height: 158px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#shadow_date_grp_mob_web_5 {
    position: absolute;
    width: 119px;
    height: 24px;
    left: 28px;
    top: 293px;
    overflow: visible;
}
#shadow_dateshadow_space_website_5_mob {
    left: 29px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 91px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#shadow_calendar_2_shadow_space_websit {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#shadow_time_grp_mob_web_5 {
    position: absolute;
    width: 145px;
    height: 24px;
    left: 142px;
    top: 293px;
    overflow: visible;
}
#shadow_date_shadow_space_website_5_mo {
    left: 28px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 118px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#shadow_clock_1_shadow_space_website_5 {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#shadow_name_grp_mob_web_5 {
    position: absolute;
    width: 207px;
    height: 24px;
    left: 28px;
    top: 252px;
    overflow: visible;
}
#shadow_man_2_shadow_space_website_5_m {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#shadow_date_shadow_space_website_5_mo_dq {
    left: 29px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 179px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#shadow_seats_grp_mob_web_5 {
    position: absolute;
    width: 123px;
    height: 24px;
    left: 28px;
    top: 327px;
    overflow: visible;
}
#shadow_seats_shadow_space_website_5_m {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#shadow_date_shadow_space_website_5_mo_dt {
    left: 30px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 94px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#shadow_price_grp_mob_web_5 {
    position: absolute;
    width: 112px;
    height: 24px;
    left: 142px;
    top: 327px;
    overflow: visible;
}
#shadow_price-tag_1_shadow_space_websi {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#shadow_date_shadow_space_website_5_mo_dw {
    left: 28px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 85px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#shadow_fotter_line_shadow_space_websi {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.shadow_fotter_line_shadow_space_websi {
    overflow: visible;
    position: absolute;
    width: 323px;
    height: 3px;
    left: 26.5px;
    top: 2649px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#shadow_n_234567890_shadow_space_websi {
    left: 60px;
    top: 2694px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#shadow_affanghanioperatelive_shadow_s {
    left: 60px;
    top: 2748px;
    position: absolute;
    overflow: visible;
    width: 167px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#shadow_address_shadow_space_website_5 {
    left: 60px;
    top: 2802px;
    position: absolute;
    overflow: visible;
    width: 280px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#shadow_location_img_shadow_space_webs {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 16px;
    top: 2796px;
    overflow: visible;
}
#shadow_email_address_shadow_space_web {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 16px;
    top: 2742px;
    overflow: visible;
}
#shadow_phone_number_shadow_space_webs {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 16px;
    top: 2688px;
    overflow: visible;
}
#shadow_Connect_with_us_and_unlock_lim {
    left: 53px;
    top: 2988px;
    position: absolute;
    overflow: visible;
    width: 70%;
    height: 60px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #fff;
}
#shadow_socials_grp_shadow_space_websi {
    position: absolute;
    width: 210px;
    height: 50.001px;
    left: 76px;
    top: 2896px;
    overflow: visible;
}
#shadow_linked_in_grp_shadow_space_web {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 160px;
    top: 0px;
    overflow: visible;
}
#shadow_Ellipse_5shadow_space_website_:hover {
    fill: rgba(120,120,255,1);
}
.shadow_Ellipse_5shadow_space_website_:hover {
    -webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
    width: 98px;
    height: 98px;
}
#shadow_Ellipse_5shadow_space_website_ {
    fill: rgba(255,255,255,1);
    transition: all .3s ease-out;
}
.shadow_Ellipse_5shadow_space_website_ {
    -webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 59px;
    height: 59px;
    left: 0px;
    top: 0px;
    transition: all .3s ease-out;
}
#shadow_linked_in_shadow_space_website {
    position: absolute;
    width: 24.349px;
    height: 22.135px;
    left: 13.125px;
    top: 13.75px;
    overflow: visible;
}
#shadow_n_website_2_layout {
    fill: rgba(63,61,86,1);
}
.shadow_n_website_2_layout {
    overflow: visible;
    position: absolute;
    width: 16.092px;
    height: 15.182px;
    left: 8.257px;
    top: 6.953px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#shadow_linked_in_i_down {
    fill: rgba(63,61,86,1);
}
.shadow_linked_in_i_down {
    overflow: visible;
    position: absolute;
    width: 4.746px;
    height: 15.037px;
    left: 0.614px;
    top: 7.098px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#shadow_top_dot_i_website_2_layout {
    fill: rgba(63,61,86,1);
}
.shadow_top_dot_i_website_2_layout {
    overflow: visible;
    position: absolute;
    width: 5.761px;
    height: 5.217px;
    left: 0px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#shadow_Insta_grp_med_shadow_space_web {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 80px;
    top: 0px;
    overflow: visible;
}
#shadow_Ellipseshadow_space_website_5_:hover {
    fill: rgba(255,120,120,1);
}
.shadow_Ellipseshadow_space_website_5_:hover {
    -webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
    width: 98px;
    height: 98px;
}
#shadow_Ellipseshadow_space_website_5_ {
    fill: rgba(255,255,255,1);
    transition: all .3s ease-out;
}
.shadow_Ellipseshadow_space_website_5_ {
    -webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 59px;
    height: 59px;
    left: 0px;
    top: 0px;
    transition: all .3s ease-out;
}
#shadow_instagram_shadow_space_website {
    position: absolute;
    width: 24.375px;
    height: 24.375px;
    left: 13.125px;
    top: 13.125px;
    overflow: visible;
}
#shadow_whatsapp_grpshadow_space_websi {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#shadow_Ellipse_3_shadow_space_website:hover {
    fill: rgba(50,172,158,1);
}
.shadow_Ellipse_3_shadow_space_website:hover {
    -webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
    width: 98px;
    height: 98px;
}
#shadow_Ellipse_3_shadow_space_website {
    fill: rgba(255,255,255,1);
    transition: all .3s ease-out;
}
.shadow_Ellipse_3_shadow_space_website {
    -webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 59px;
    height: 59px;
    left: 0px;
    top: 0px;
    transition: all .3s ease-out;
}
#shadow_whatsapp_lbl_shadow_space_webs {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 12.5px;
    top: 12.5px;
    overflow: visible;
}
#shadow_quote_left_shadow_space_websit {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 15px;
    top: 2987px;
    overflow: visible;
}
#shadow_n_Copyright_Operate_Live_shado {
    left: 16px;
    top: 3093.25px;
    position: absolute;
    overflow: visible;
    width: 318px;
    white-space: nowrap;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#shadow_quote_right_med_shadow_space_w_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    right: 35px;
    top: 3020px;
    overflow: visible;
}
#shadow_Partners_grp_shadow_space_webs {
    position: absolute;
    width: 338px;
    height: 118px;
    left: 12px;
    top: 496px;
    overflow: visible;
}
#shadow_text_our_partnersshadow_space_ {
    left: 12px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 181px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(255,255,255,1);
    letter-spacing: 0.1px;
}
#shadow_Scroll_partner_shadow_space_we_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 338px;
    height: 79px;
    left: 0px;
    top: 39px;
    display: inline-flex;
    overflow-x: auto;
    overflow-y: hidden;
}
#shadow_Scroll_partner_shadow_space_we_mob::-webkit-scrollbar{
    display: none;
}
#shadow_partner_lbls_shadow_space_webs_mob {
    position: relative;
    width: 175px;
    height: 27px;
    left: 12px;
    top: 7px;
    overflow: visible;
}
#shadow_google_lbl_partner_shadow_spac_mob {
    left: 30px;
    top: 0px;
    position: absolute;
    overflow: hidden;
    width: 146px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#shadow_google_1_shadow_space_website_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 0px;
    top: 1px;
    overflow: visible;
}
 
 /* Menu */
 #menuToggle
 {
 display: block;
 position: fixed;
 width: 40px;
 height: 40px;
 left: 34px;
 top: 25px;
 
 z-index: 1;
 
 -webkit-user-select: none;
 user-select: none;
 }
 
 #menuToggle li
 {
 text-decoration: none;
 color: #232323;
 
 transition: color 0.3s ease;
 }
 
 #menuToggle li:hover
 {
 color: #ff4f4f;
 }
 
 
 #menuToggle input
 {
 display: block;
 width: 40px;
 height: 32px;
 position: absolute;
 top: -7px;
 left: -5px;
 
 cursor: pointer;
 
 opacity: 0; /* hide this */
 z-index: 2; /* and place it over the hamburger */
 
 -webkit-touch-callout: none;
 }
 
 /*
 * Just a quick hamburger
 */
 #menuToggle span
 {
 display: block;
 width: 33px;
 height: 4px;
 margin-bottom: 5px;
 position: relative;
 
 background: #6495ED;
 border-radius: 3px;
 
 z-index: 1;
 
 -webkit-transform-origin: 4px 0px;
 
         transform-origin: 4px 0px;
 
 transition: -webkit-transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
 
 transition: transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
 
 transition: transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
 background: 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
 opacity: 0.55s ease;
 }
 
 #menuToggle span:first-child
 {
 -webkit-transform-origin: 0% 0%;
         transform-origin: 0% 0%;
 }
 
 #menuToggle span:nth-last-child(2)
 {
 -webkit-transform-origin: 0% 100%;
         transform-origin: 0% 100%;
 }
 
 /* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
 #menuToggle input:checked ~ span
 {
 opacity: 1;
 -webkit-transform: rotate(45deg) translate(-2px, -1px);
         transform: rotate(45deg) translate(-2px, -1px);
 background: rgba(255,75,83,1);
 }
 
 /*
 * But let's hide the middle one.
 */
 #menuToggle input:checked ~ span:nth-last-child(3)
 {
 opacity: 0;
 -webkit-transform: rotate(0deg) scale(0.2, 0.2);
         transform: rotate(0deg) scale(0.2, 0.2);
 }
 
 /*
 * Ohyeah and the last one should go the other direction
 */
 #menuToggle input:checked ~ span:nth-last-child(2)
 {
 -webkit-transform: rotate(-45deg) translate(0, -1px);
         transform: rotate(-45deg) translate(0, -1px);
 }
 
 /*
 * Make this absolute positioned
 * at the top left of the screen
 */
 #menu
 {
 position: absolute;
 width: 230px;
 margin: -100px 0 0 -50px;
 padding: 50px;
 padding-top: 125px;
 height: 100vh;
 background: #ededed;
 list-style-type: none;
 -webkit-font-smoothing: antialiased;
 /* to stop flickering of text in safari */
 -webkit-transform-origin: 0% 0%;
         transform-origin: 0% 0%;
 -webkit-transform: translate(-100%, 0);
         transform: translate(-100%, 0);
 transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
 transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
 transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
 background: rgba( 255, 255, 255, 0.25 );
 box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
 backdrop-filter: blur( 10px );
 -webkit-backdrop-filter: blur( 10px );
 border-radius: 10px;
 border: 1px solid rgba( 255, 255, 255, 0.18 );
 }
 
 #menu li
 {
 padding: 10px 0;
 font-size: 22px;
 }
 #menuToggle input:checked ~ ul
 {
 -webkit-transform: none;
         transform: none;
 }
 
	.mediaViewInfo {
		--web-view-name: KnowledgeKorner mob;
		--web-view-id: KnowledgeKorner_mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: KnowledgeKorner_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#n__card_grp_website_6_mob { 
    -webkit-animation-name: floating; 
            animation-name: floating;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    margin-left: 3px;
    margin-top: 5px;
}
@-webkit-keyframes floating {
    0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    50%  { -webkit-transform: translate(0, 7px); transform: translate(0, 7px); }
    100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}
@keyframes floating {
    0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    50%  { -webkit-transform: translate(0, 7px); transform: translate(0, 7px); }
    100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}
	body::-webkit-scrollbar{
		display: none;
	}
#Scroll_vis_mob_web_6::-webkit-scrollbar{
		display: none;
	}
#Scroll_princi_mob_web_6::-webkit-scrollbar{
		display: none;
	}
#Scroll_vis_mis_and_prin::-webkit-scrollbar{
		display: none
	}
#Scroll_services_website_6_mob::-webkit-scrollbar{
		display: none;
	}
#Scroll_mis_mob_web_6::-webkit-scrollbar{
		display: none;
	}
	#KnowledgeKorner_mob {
		position: absolute;
		width: 375px;
		height: 2515px;
        left:calc((100vw - 375px)/2);
		background-color: rgba(246,246,246,1);
		overflow: hidden;
		--web-view-name: KnowledgeKorner mob;
		--web-view-id: KnowledgeKorner_mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#circle_4_website_6_mob {
		fill: rgba(92,35,255,1);
	}
	.circle_4_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 30px;
		height: 30px;
		left: 261px;
		top: 1352px;
	}
	#circle_3_website_6_mob {
		fill: rgba(215,232,188,1);
	}
	.circle_3_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 30px;
		height: 30px;
		left: 14px;
		top: 1050px;
	}
	#circel_2_website_6_mob {
		fill: rgba(166,210,202,1);
	}
	.circel_2_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 30px;
		height: 30px;
		left: 314px;
		top: 753px;
	}
	#header_overall_grp_mob_web_6_m {
		position: absolute;
		width: 382.086px;
		height: 261.999px;
		left: -2px;
		top: 0px;
		overflow: visible;
	}
	#shadow_grass_website_6_mob {
		opacity: 0.32;
		fill: rgba(0,154,23,1);
	}
	.shadow_grass_website_6_mob {
		overflow: visible;
		position: absolute;
		width: 379.533px;
		height: 16.357px;
		left: 2.553px;
		top: 197.676px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#sky_mob_website_6_mob {
		fill: rgba(135,206,235,1);
	}
	.sky_mob_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 379px;
		height: 198px;
		left: 0px;
		top: 0px;
	}
	#Path_449_grass_website_6_mob {
		fill: rgba(0,154,23,1);
	}
	.Path_449_grass_website_6_mob {
		overflow: visible;
		position: absolute;
		width: 379.533px;
		height: 16.357px;
		left: 2px;
		top: 186.898px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Anime_Edit_213_website_6_mob {
		position: absolute;
		width: 28px;
		height: 27px;
		left: 176px;
		top: 5px;
        border-radius: 50%;
		overflow: hidden;
	}
	#LETS_LEARN_AND_GAIN_NEW__KNOWL {
		left: 75.086px;
		top: 40px;
		position: absolute;
		overflow: visible;
		width: 227px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,0.89);
	}
	#Lets_discover_new_knowledge_an {
		left: 104.086px;
		top: 98px;
		position: absolute;
		overflow: visible;
		width: 155px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 6px;
		color: rgba(0,0,0,0.89);
	}
	#n__card_grp_website_6_mob {
		position: absolute;
		width: 282px;
		height: 139.999px;
		left: 49px;
		top: 122px;
		overflow: visible;
	}
	#shadow_grp_img_website_6_mob {
		position: absolute;
		width: 282px;
		height: 33px;
		left: 0px;
		top: 107px;
		overflow: visible;
	}
	#image_4_shadow_website_6_mob {
		-webkit-filter: blur(4px);
		        filter: blur(4px);
		fill: rgba(212,212,212,1);
	}
	.image_4_shadow_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 86px;
		height: 28px;
		left: 0px;
		top: 0px;
	}
	#image_3_shadow_website_6_mob {
		-webkit-filter: blur(4px);
		        filter: blur(4px);
		fill: rgba(212,212,212,1);
	}
	.image_3_shadow_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 87px;
		height: 28px;
		left: 71px;
		top: 29px;
	}
	#image_2_shadow_website_6_mob {
		-webkit-filter: blur(4px);
		        filter: blur(4px);
		fill: rgba(212,212,212,1);
	}
	.image_2_shadow_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 86px;
		height: 28px;
		left: 148px;
		top: 29px;
	}
	#image_1_shadow_website_6_mob {
		-webkit-filter: blur(4px);
		        filter: blur(4px);
		fill: rgba(212,212,212,1);
	}
	.image_1_shadow_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 87px;
		height: 27px;
		left: 219px;
		top: 2px;
	}
	#img_4_website_6_mob {
		position: absolute;
		width: 63.273px;
		height: 103.348px;
		left: 3.001px;
		top: 0px;
		overflow: visible;
	}
	#img_3_website_6_mob {
		position: absolute;
		width: 63.273px;
		height: 103.348px;
		left: 72.293px;
		top: 19.723px;
		overflow: visible;
	}
	#img_2_website_6_mob {
		position: absolute;
		width: 63.273px;
		height: 103.348px;
		left: 146.396px;
		top: 19.723px;
		overflow: visible;
	}
	#img_1_website_6_mob {
		position: absolute;
		width: 63.273px;
		height: 103.348px;
		left: 214.725px;
		top: 0px;
		overflow: visible;
	}
	#Icon_awesome-cloud_6_website_6_mob_cloud {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_6_website_6_mob_cloud {
		overflow: visible;
		position: absolute;
		width: 22.109px;
		height: 15.477px;
		left: 22.751px;
		top: 46.152px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_5_website_6_mob_cloud {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_5_website_6_mob_cloud {
		overflow: visible;
		position: absolute;
		width: 17.134px;
		height: 11.994px;
		left: 309.613px;
		top: 67.984px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_4_website_6_mob_cloud {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_4_website_6_mob_cloud {
		overflow: visible;
		position: absolute;
		width: 22.109px;
		height: 15.477px;
		left: 74.707px;
		top: 97.555px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_3_website_6_mob_cloud {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_3_website_6_mob_cloud {
		overflow: visible;
		position: absolute;
		width: 22.109px;
		height: 15.477px;
		left: 340.565px;
		top: 128.508px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_2_website_6_mob_cloud {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_2_website_6_mob_cloud {
		overflow: visible;
		position: absolute;
		width: 22.109px;
		height: 15.477px;
		left: 249.919px;
		top: 24.596px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_1_website_6_mob_cloud {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_1_website_6_mob_cloud {
		overflow: visible;
		position: absolute;
		width: 22.109px;
		height: 15.477px;
		left: 145.178px;
		top: 24.596px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#sheep_3_website_6_mob {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 35.086px;
		top: 182px;
		overflow: visible;
	}
	#sheep_2_website_6_mob {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 25.086px;
		top: 185px;
		overflow: visible;
	}
	#sheep_1_website_6_mob {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 336.086px;
		top: 182px;
		overflow: visible;
	}
	#dove_3_website_6_mob {
		position: absolute;
		width: 11px;
		height: 11px;
		left: 307.086px;
		top: 32px;
		overflow: visible;
	}
	#dove_2_website_6_mob {
		position: absolute;
		width: 11px;
		height: 11px;
		left: 317.086px;
		top: 36px;
		overflow: visible;
	}
	#dove_1_website_6_mob {
		position: absolute;
		width: 11px;
		height: 11px;
		left: 317.086px;
		top: 24px;
		overflow: visible;
	}
	#Scroll_partners_web_6_mov {
		mix-blend-mode: normal;
		position: absolute;
		width: 360px;
		height: 48px;
		left: 5px;
		top: 339px;
        display: inline-flex;
		overflow-x: auto;
		overflow-y: hidden;
	}
    #Scroll_partners_web_6_mov::-webkit-scrollbar {
        display: none;
    }
	#partner_card_1_grp_website_6_m {
		position: relative;
		width: 211px;
		height: 30px;
		left: 9px;
		top: 7px;
		overflow: visible;
	}
	#Anime_Edit_img_website_6_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Mohammed_Affan_website_6_mob {
		left: 35px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_services_website_6_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 360px;
		height: 287px;
		left: 0px;
		top: 763px;
		display: -webkit-box;
		overflow-x: auto;
		overflow-y: hidden;
	}
    #Scroll_services_website_6_mob::-webkit-scrollbar {
        display: none;
    }
	#my_services_card_1_website_6_m {
		position: relative;
		width: 174px;
		height: 242px;
		left: 14px;
		top: 22px;
        margin: 10px;
		overflow: visible;
	}
	#services_card_bdr_website_6_mo {
		fill: rgba(255,255,255,1);
	}
	.services_card_bdr_website_6_mo {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 192px;
		height: 260px;
		left: 0px;
		top: 0px;
	}
	#img_offering_website_6_mob {
		position: absolute;
		width: 150px;
		height: 198px;
		left: 12px;
		top: 34px;
		overflow: visible;
	}
	#circle_deign_shadow_space_webs {
		fill: rgba(23,18,18,1);
	}
	.circle_deign_shadow_space_webs {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 12px;
		top: 16px;
	}
	#My_Offerings_lbl_website_6_mob {
		left: 23px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 140px;
		height: 17px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,0.89);
	}
	#circle_1_website_6_mob {
		fill: rgba(255,168,168,1);
	}
	.circle_1_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 20px;
		height: 20px;
		left: 314px;
		top: 256px;
	}
	#Trusted_by_our_partners_lbl_we {
		left: 77px;
		top: 279px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_vis_mis_and_prin {
		mix-blend-mode: normal;
		position: absolute;
		width: 355px;
		height: 298px;
		left: 5px;
		top: 396px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#vis_mis_and_prin_grp_website_6 {
		position: absolute;
		width: 562px;
		height: 242px;
		left: 9px;
		top: 30px;
		overflow: hidden;
	}
	#princi_grp_website_6_mob {
		position: absolute;
		width: 174px;
		height: 242px;
		left: 388px;
		top: 0px;
		overflow: visible;
	}
	#prini_overall_card_bdr_website {
		fill: rgba(255,255,255,1);
	}
	.prini_overall_card_bdr_website {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(135, 207, 172, 0.647));
		        filter: drop-shadow(0px 3px 6px rgba(135, 207, 172, 0.647));
		position: absolute;
		overflow: visible;
		width: 192px;
		height: 260px;
		left: 0px;
		top: 0px;
	}
	#princi_bdr_website_6_mob {
		fill: rgba(166,210,202,1);
	}
	.princi_bdr_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 38px;
		height: 38px;
		left: 5px;
		top: 5px;
	}
	#Principles_lbl_website_6_mob {
		left: 2px;
		top: 48px;
		position: absolute;
		overflow: visible;
		width: 61px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_princi_mob_web_6 {
		mix-blend-mode: normal;
		position: absolute;
		width: 174px;
		height: 160px;
		left: 0px;
		top: 69px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Kono_sekai_wa_itami_o_shirudar {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,0.89);
	}
	#leadership_2_knowledge_webs_mob {
		position: absolute;
		width: 29.786px;
		height: 29.785px;
		left: 9.169px;
		top: 8.053px;
		overflow: visible;
	}
	#vision_grp_website_6_mob {
		position: absolute;
		width: 173px;
		height: 242px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#overall_card_bdr_website_6_mob {
		fill: rgba(255,255,255,1);
	}
	.overall_card_bdr_website_6_mob {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(241, 144, 144, 1));
		        filter: drop-shadow(0px 3px 6px rgba(241, 144, 144, 1));
		position: absolute;
		overflow: visible;
		width: 191px;
		height: 260px;
		left: 0px;
		top: 0px;
	}
	#img_vision_bdr_website_6_mob {
		fill: rgba(241,144,144,1);
	}
	.img_vision_bdr_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 38px;
		height: 38px;
		left: 5px;
		top: 5px;
	}
	#Vision_website_6_mob {
		left: 5px;
		top: 48px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_vis_mob_web_6 {
		mix-blend-mode: normal;
		position: absolute;
		width: 173px;
		height: 160px;
		left: 0px;
		top: 69px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Zaido_wa_gisei_ni_natte_korosa {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,0.89);
	}
	#target_1_kk_website_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 9px;
		top: 9px;
		overflow: visible;
	}
	#mission_grp_website_6_mob {
		position: absolute;
		width: 174px;
		height: 242px;
		left: 194px;
		top: 0px;
		overflow: visible;
	}
	#whole_card_bdr_website_6_mob {
		fill: rgba(255,255,255,1);
	}
	.whole_card_bdr_website_6_mob {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(197, 196, 218, 1));
		        filter: drop-shadow(0px 3px 6px rgba(197, 196, 218, 1));
		position: absolute;
		overflow: visible;
		width: 192px;
		height: 260px;
		left: 0px;
		top: 0px;
	}
	#mision_bdr_website_6_mob {
		fill: rgba(167,166,210,1);
	}
	.mision_bdr_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 37px;
		height: 37px;
		left: 5px;
		top: 5px;
	}
	#Mission_website_6_mob {
		left: 5px;
		top: 47px;
		position: absolute;
		overflow: visible;
		width: 48px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_mis_mob_web_6 {
		mix-blend-mode: normal;
		position: absolute;
		width: 174px;
		height: 166px;
		left: 0px;
		top: 69px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Watashitachi_no_dry_no_zaido_o {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,0.89);
	}
	#principle_2_k_k_websi_mob {
		position: absolute;
		width: 31px;
		height: 31px;
		left: 8px;
		top: 8px;
		overflow: visible;
	}
	#Our_Services_lbl_website_6_mob {
		left: 143px;
		top: 718px;
		position: absolute;
		overflow: visible;
		width: 119px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_event_grp_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 375.5px;
		height: 568px;
		left: 0px;
		top: 1131px;
        display: -webkit-box;
		overflow-x: auto;
		overflow-y: hidden;
	}
    #Scroll_event_grp_mob::-webkit-scrollbar {
        display: none;
    }
	#event_grp__website_k_k_mob {
		position: relative;
		width: 287px;
		height: 526px;
		left: 9px;
		top: 24px;
        margin: 10px;
		overflow: visible;
	}
	#event_bg_card_1_k_k_space_mob {
		fill: rgba(255,255,255,1);
	}
	.event_bg_card_1_k_k_space_mob {
		-webkit-filter: drop-shadow(8px 8px 5px rgba(166, 210, 202, 1));
		        filter: drop-shadow(8px 8px 5px rgba(166, 210, 202, 1));
		width: 442px;
		height: 286px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-43px, -1974px) matrix(1,0,0,1,-35,2117) rotate(90deg);
		        transform: translate(-43px, -1974px) matrix(1,0,0,1,-35,2117) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#Anime_Edit_k_k_websit_mob {
		position: absolute;
		width: 230px;
		height: 210px;
		left: 28px;
		top: 0px;
		overflow: visible;
	}
	#oofering_lbl_k_k_websi_mob {
		left: 0px;
		top: 217px;
		position: absolute;
		overflow: visible;
		width: 287px;
		height: 22px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Scrolldesc_K_K_websit_mob {
	mix-blend-mode: normal;
    position: absolute;
    width: 272px;
    height: 135px;
    left: 7px;
    top: 368px;
    overflow: hidden;
}
	#oofering_lbl_k_k_webs_mob {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#all_details_small_web_6__mob {
		position: absolute;
		width: 259px;
		height: 99px;
		left: 28px;
		top: 252px;
		overflow: visible;
	}
	#dateshadow_space_website_6_mob {
		left: 29px;
		top: 44px;
		position: absolute;
		overflow: visible;
		width: 91px;
		height: 17px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#calendar_2_k_k_websit_mob {
		position: absolute;
		width: 24px;
		height: 24px;
		left: 0px;
		top: 41px;
		overflow: visible;
	}
	#date_k_k_website_6_mob {
		left: 142px;
		top: 44px;
		position: absolute;
		overflow: visible;
		width: 118px;
		height: 17px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#clock_1_shadow_space_website_6_k_k_mob {
		position: absolute;
		width: 24px;
		height: 24px;
		left: 114px;
		top: 41px;
		overflow: visible;
	}
	#man_2_shadow_space_website_6_m_k_k_mob {
		position: absolute;
		width: 24px;
		height: 24px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#date_shadow_space_website_6_mo_eh_k_k_mob {
		left: 29px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 179px;
		height: 17px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#seats_shadow_space_website_6_m_k_k_mob {
		position: absolute;
		width: 24px;
		height: 24px;
		left: 0px;
		top: 75px;
		overflow: visible;
	}
	#date_shadow_space_website_6_mo_ej_k_k_mob {
		left: 35px;
		top: 78px;
		position: absolute;
		overflow: visible;
		width: 94px;
		height: 17px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#price-tag_1_shadow_space_websi_k_k_mob {
		position: absolute;
		width: 24px;
		height: 24px;
		left: 114px;
		top: 75px;
		overflow: visible;
	}
	#date_shadow_space_website_6_mo_el_k_k_mob {
		left: 142px;
		top: 78px;
		position: absolute;
		overflow: visible;
		width: 85px;
		height: 17px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#oofering_lbl_med_kk_mob {
		left: 140px;
		top: 1077px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#footer_grp_website_6_mob {
		position: absolute;
		width: 380px;
		height: 755px;
		left: 0px;
		top: 1758px;
		overflow: visible;
	}
	#n_3197677_SL-030720-28740-05 {
		position: absolute;
		width: 380px;
		height: 755px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_maps_mob_kk {
		position: absolute;
		width: 323.11px;
		height: 195.998px;
		left: 28px;
		top: 23px;
		overflow: visible;
	}
	#Path_448_mob_kk {
		fill: rgba(0,0,0,1);
	}
	.Path_448_mob_kk {
		overflow: visible;
		position: absolute;
		width: 323.11px;
		height: 195.998px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_map_location_mob {
		fill: rgba(255,255,255,1);
	}
	.Path_map_location_mob {
		overflow: visible;
		position: absolute;
		width: 313.597px;
		height: 173.951px;
		left: 4.943px;
		top: 4.992px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_k_k_mobile_number_shad_mob {
		left: 56px;
		top: 287px;
		position: absolute;
		overflow: visible;
		width: 83px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#k_k_email_mob_ {
		left: 56px;
		top: 336px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#address_med_k_k_websi_mob {
		left: 56px;
		top: 385px;
		position: absolute;
		overflow: visible;
		width: 309px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#location_img_shadow_space_webs_k_k_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 21px;
		top: 385px;
		overflow: visible;
	}
	#email_address_shadow_space_web_k_k_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 21px;
		top: 335px;
		overflow: visible;
	}
	#phone_number_shadow_space_webs_k_k_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 21px;
		top: 280px;
		overflow: visible;
	}
	#Connect_with_us_and_unlock_lim_k_k_mob {
		left: 56px;
		top: 499px;
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 63px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#quote_left_med_shadow_space_we_k_k_mob {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 21px;
		top: 499px;
		overflow: visible;
	}
	#quote_right_med_shadow_space_w_k_k_mob {
		position: absolute;
		width: 25px;
		height: 25px;
		right: 21px;
		top: 541px;
		overflow: visible;
	}
	#socials_grp_shadow_space_websi_k_k_mob {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 94px;
		top: 613px;
		overflow: visible;
	}
	#linked_in_grp_shadow_space_web_k_k_mob {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_5_med_shadow_space_web_k_k_mob:hover {
		fill: rgba(120,120,255,1);
	}
	.Ellipse_5_med_shadow_space_web_k_k_mob:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_5_med_shadow_space_web_k_k_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_5_med_shadow_space_web_k_k_mob {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_shadow_space_website_k_k_mob {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.191px;
		overflow: visible;
	}
	#n_website_mob_5layout__k_k_mob {
		fill: rgba(63,61,86,1);
	}
	.n_website_mob_5layout__k_k_mob {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_shadow_space_websi_k_k_mob {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_shadow_space_websi_k_k_mob {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_shadow_space_website_k_k_mob {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_shadow_space_website_k_k_mob {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_shadow_space_website_k_k_mob {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_4_shadow_space_website_k_k_mob:hover {
		fill: rgba(255,120,120,1);
	}
	.Ellipse_4_shadow_space_website_k_k_mob:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_4_shadow_space_website_k_k_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_4_shadow_space_website_k_k_mob {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagramshadow_space_website__k_k_mob {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#whatsapp_grp_shadow_space_webs_k_k_mob {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_3_med_shadow_space_web_k_k_mob:hover {
		fill: rgba(50,172,158,1);
	}
	.Ellipse_3_med_shadow_space_web_k_k_mob:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_3_med_shadow_space_web_k_k_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_3_med_shadow_space_web_k_k_mob {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_shadow_space_webs_k_k_mob {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#n_Copyright_Operate_Live_med_s_k_k_mob {
		left: 21px;
		top: 696px;
		position: absolute;
		overflow: visible;
		width: 318px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}


    /* Menu */
  #menuToggle
  {
  display: block;
  position: fixed;
  width: 40px;
  height: 40px;
  left: 34px;
  top: 25px;
  
  z-index: 1;
  
  -webkit-user-select: none;
  user-select: none;
  }
  
  #menuToggle li
  {
  text-decoration: none;
  color: #232323;
  
  transition: color 0.3s ease;
  }
  
  #menuToggle li:hover
  {
  color: #ff4f4f;
  }
  
  
  #menuToggle input
  {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
  }
  
  /*
  * Just a quick hamburger
  */
  #menuToggle span
  {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: #6495ED;
  border-radius: 3px;
  
  z-index: 1;
  
  -webkit-transform-origin: 4px 0px;
  
          transform-origin: 4px 0px;
  
  transition: -webkit-transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  
  transition: transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  
  transition: transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  background: 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  opacity: 0.55s ease;
  }
  
  #menuToggle span:first-child
  {
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  }
  
  #menuToggle span:nth-last-child(2)
  {
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  }
  
  /* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
  #menuToggle input:checked ~ span
  {
  opacity: 1;
  -webkit-transform: rotate(45deg) translate(-2px, -1px);
          transform: rotate(45deg) translate(-2px, -1px);
  background: rgba(255,75,83,1);
  }
  
  /*
  * But let's hide the middle one.
  */
  #menuToggle input:checked ~ span:nth-last-child(3)
  {
  opacity: 0;
  -webkit-transform: rotate(0deg) scale(0.2, 0.2);
          transform: rotate(0deg) scale(0.2, 0.2);
  }
  
  /*
  * Ohyeah and the last one should go the other direction
  */
  #menuToggle input:checked ~ span:nth-last-child(2)
  {
  -webkit-transform: rotate(-45deg) translate(0, -1px);
          transform: rotate(-45deg) translate(0, -1px);
  }
  
  /*
  * Make this absolute positioned
  * at the top left of the screen
  */
  #menu
  {
  position: absolute;
  width: 230px;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  height: 100vh;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
  transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 10px );
  -webkit-backdrop-filter: blur( 10px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  }
  
  #menu li
  {
  padding: 10px 0;
  font-size: 22px;
  }
  #menuToggle input:checked ~ ul
  {
  -webkit-transform: none;
          transform: none;
  }
  
	.mediaViewInfo {
		--web-view-name: DriveVelocity_Mob;
		--web-view-id: DriveVelocity_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: DriveVelocity_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
#Scroll_prin_mob_web_7::-webkit-scrollbar{
		display: none;
	}
#Scroll_visiiion_web_7_mob::-webkit-scrollbar{
		display: none;
	}
#Scroll_mission_web_7_mob::-webkit-scrollbar{
		display: none;
	}
#Scroll_avaliable_now_web_mob_7::-webkit-scrollbar{
		display: none
	}
	#DriveVelocity_Mob {
		position: absolute;
		width: 375px;
		height: 4049px;
		background-color: rgba(249,249,249,0.886);
		overflow: hidden;
		--web-view-name: DriveVelocity_Mob;
		--web-view-id: DriveVelocity_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#header_mob_7_web {
		position: absolute;
		width: 495px;
		height: 267px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#car_bg_web_7_mob {
		position: absolute;
		width: 495px;
		height: 267px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#text_unleash_grp_web_7_mob {
		position: absolute;
		width: 166px;
		height: 59px;
		left: 15px;
		top: 72px;
		overflow: visible;
	}
	#Unleash_the_True_Power_web_7_m {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 167px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#Drive_into_the_Future__Your_Ul {
		left: 0px;
		top: 33px;
		position: absolute;
		overflow: visible;
		width: 152px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(173,173,173,1);
	}
	#pre-order_button {
		position: absolute;
		width: 97px;
		height: 14px;
		left: 22px;
		top: 147px;
		overflow: visible;
	}
	#pre_line_web_7_mob {
		fill: rgba(222,14,11,1);
	}
	.pre_line_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 16px;
		height: 1px;
		left: 40px;
		top: 0px;
	}
	#PRE-ORDER_NOW_web_7_mob {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 98px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 10px;
		color: rgba(222,14,11,1);
		letter-spacing: 0.95px;
	}
	#zane-lee-bXbi1jJVDf0-unsplash_DV_mob {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 16px;
		top: 11px;
		border-radius: 50%;
		overflow: hidden;
	}
	#prinic_grp_web_7_mob {
		position: absolute;
		width: 336px;
		height: 467.791px;
		left: 24px;
		top: 1847.209px;
		overflow: visible;
	}
	#Path_1_5_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Path_1_5_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 100.769px;
		height: 83.113px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,4.6722,56.8339) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,4.6722,56.8339) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Path_24_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Path_24_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 74.931px;
		height: 83.113px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,17.5913,101.1882) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,17.5913,101.1882) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Path_33_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Path_33_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 74.931px;
		height: 83.113px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,17.5913,132.6257) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,17.5913,132.6257) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Path_42_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Path_42_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 65.026px;
		height: 83.113px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,22.5436,159.5406) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,22.5436,159.5406) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Path_51_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Path_51_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 59.187px;
		height: 83.113px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,25.463,176.4298) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,25.463,176.4298) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#shade_princi_img_web_7_mob {
		fill: rgba(0,0,0,1);
	}
	.shade_princi_img_web_7_mob {
		-webkit-filter: drop-shadow(-70px 73px 99px rgba(0, 0, 0, 0.122));
		        filter: drop-shadow(-70px 73px 99px rgba(0, 0, 0, 0.122));
		position: absolute;
		overflow: visible;
		width: 445.037px;
		height: 493.811px;
		left: 42.463px;
		top: 0px;
	}
	#Mask_Groupn_web_7_mob {
		position: absolute;
		width: 148.037px;
		height: 196.811px;
		left: 42.463px;
		top: 0px;
		overflow: visible;
	}
	#Principle_lbl_web_7_mob {
		left: 10px;
		top: 269.791px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		line-height: 33px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#princi_top_line_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.princi_top_line_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 51px;
		height: 3px;
		left: 10px;
		top: 264.791px;
	}
	#Scroll_prin_mob_web_7 {
		mix-blend-mode: normal;
		position: absolute;
		width: 336px;
		height: 156px;
		left: 0px;
		top: 311.791px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#The_Mercedes-AMG_desc_web_7_mo {
		left: 10px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 119px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(116,116,116,1);
	}
	#vision_grp_all_web_7_mob {
		position: absolute;
		width: 662px;
		height: 697.76px;
		left: -159px;
		top: 282.209px;
		overflow: visible;
	}
	#red_lineweb_7_mob {
		fill: rgba(99,113,203,1);
	}
	.red_lineweb_7_mob {
		position: absolute;
		overflow: visible;
		width: 662px;
		height: 146px;
		left: 0px;
		top: 197.76px;
	}
	#Mask_Group_web_7_mob {
		position: absolute;
		width: 283px;
		height: 289px;
		left: 329px;
		top: 0px;
		overflow: visible;
	}
	#small_thumbnails {
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,43.7406,130.2594) rotate(-90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,43.7406,130.2594) rotate(-90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		position: absolute;
		width: 415.519px;
		height: 155px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#over_all_bdr_black_web_7_mob {
		fill: rgba(192,192,192,1);
	}
	.over_all_bdr_black_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 155px;
		height: 415.52px;
		left: 0px;
		top: 0px;
	}
	#car_grp_web_7_mob {
		position: absolute;
		width: 131.154px;
		height: 162.75px;
		left: 11.924px;
		top: 63.191px;
		overflow: visible;
	}
	#gear_Group_web_7_mob {
		position: absolute;
		width: 131.154px;
		height: 162.154px;
		left: 11.924px;
		top: 239.057px;
		overflow: visible;
	}
	#Line_1_web_7_mob {
		fill: transparent;
		stroke: rgba(222,14,11,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_1_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 24.549px;
		left: 80.92px;
		top: 21.521px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_web_7_mob_arrow {
		fill: rgba(222,14,11,1);
	}
	.n_web_7_mob_arrow {
		overflow: visible;
		position: absolute;
		width: 7.154px;
		height: 5.962px;
		-webkit-transform: translate(0px, 415.519px) matrix(1,0,0,1,391.6734,78.0957) rotate(90deg);
		        transform: translate(0px, 415.519px) matrix(1,0,0,1,391.6734,78.0957) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Vision_lbl_web_7_mob {
		left: 193px;
		top: 480.76px;
		position: absolute;
		overflow: visible;
		width: 68px;
		white-space: nowrap;
		line-height: 33px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#vison_top_line_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.vison_top_line_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 47px;
		height: 2px;
		left: 193px;
		top: 476.76px;
	}
	#Scroll_visiiion_web_7_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 341px;
		height: 177px;
		left: 187px;
		top: 520.76px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#The_cockpit_impresses_with_its {
		left: 6px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#Avaliable_Now_lbl_web_7_mob {
		left: 95px;
		top: 2424px;
		position: absolute;
		overflow: visible;
		width: 187px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#mission_all_grp_mob_web_7 {
		position: absolute;
		width: 771.5px;
		height: 724px;
		left: -381px;
		top: 1043.209px;
		overflow: visible;
	}
	#chris-nguyen-aTX_bRaOZnA-unspl {
		position: absolute;
		width: 693px;
		height: 389.813px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#wite_engine_shade_web_7_mob_da {
		fill: url(#wite_engine_shade_web_7_mob_da);
	}
	.wite_engine_shade_web_7_mob_da {
		position: absolute;
		overflow: visible;
		width: 726px;
		height: 389.879px;
		left: 45.5px;
		top: 0px;
	}
	#Union_2_engine_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Union_2_engine_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 474.888px;
		height: 197.768px;
		left: 174.311px;
		top: 192.111px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#kisspng-mercedes-benz-a_web_7_ {
		position: absolute;
		width: 159.063px;
		height: 179.133px;
		left: 443.859px;
		top: 89.303px;
		overflow: visible;
	}
	#mission_grp_web_7_mob {
		position: absolute;
		width: 352px;
		height: 233px;
		left: 404px;
		top: 491px;
		overflow: visible;
	}
	#Masterpiece_web_7_mob {
		left: 11px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 9px;
		color: rgba(99,113,203,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#Mission_lbl_web_7_mob {
		left: 11px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Scroll_mission_web_7_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 352px;
		height: 177px;
		left: 0px;
		top: 56px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#An_aggregate_of_racing-level_p {
		left: 13px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 334px;
		height: 180px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(116,116,116,1);
	}
	#Path_11_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Path_11_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.207px;
		left: 714.027px;
		top: 405.014px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_12_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Path_12_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.207px;
		left: 714.027px;
		top: 433.693px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_13_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Path_13_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.207px;
		left: 714.027px;
		top: 463.107px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#avaliable_now_design_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.avaliable_now_design_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 474.888px;
		height: 197.768px;
		left: -206.689px;
		top: 2527.111px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#off_bdr_web_7_mob {
		fill: rgba(192,192,192,1);
	}
	.off_bdr_web_7_mob {
		width: 1975.545px;
		height: 360px;
		position: absolute;
		overflow: visible;
		-webkit-transform: matrix(1,0,0,1,-269.6973,2526.3301) rotate(-6deg);
		        transform: matrix(1,0,0,1,-269.6973,2526.3301) rotate(-6deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#Scroll_avaliable_now_web_mob_7 {
		mix-blend-mode: normal;
		position: absolute;
		width: 344.457px;
		height: 360px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		left: 21.5px;
		top: 2588px;
		-webkit-transform: rotate(-6deg);
		        transform: rotate(-6deg);
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#offering_card_1_shadow_space_w7MOB {
		position: relative;
		width: 178.957px;
		height: 320px;
		left: 19px;
		top: 0px;
		margin-right: 60px;
		overflow: visible;
	}
	#card_bg_offeringshadow_space_w7MOB {
		fill: rgba(255,255,255,1);
	}
	.card_bg_offeringshadow_space_w7MOB {
		-webkit-filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		        filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 194.457px;
		height: 268.375px;
		left: 0px;
		top: 0px;
	}
	#oofering_lbl_med_shadow_space_7mob {
		left: 14.005px;
		top: 227.197px;
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#markus-shadow_space_website_7 {
		position: absolute;
		width: 159.376px;
		height: 210.766px;
		left: 8.948px;
		top: 8.691px;
		overflow: visible;
	}
	#Icon_awesome-car_web_7_mob {
		fill: rgba(0,0,0,1);
	}
	.Icon_awesome-car_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 28.01px;
		height: 21.008px;
		left: 5.835px;
		top: 18.285px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#footer_grp_mob_7_mob {
		position: absolute;
		width: 452px;
		height: 943px;
		left: -29px;
		top: 3079px;
		overflow: visible;
	}
	#footer_web_7_mob {
		position: absolute;
		width: 452px;
		height: 327px;
		left: 0px;
		top: 557px;
		overflow: visible;
	}
	#jakob-rosen-FsBbavP9YA4-unspla {
		position: absolute;
		width: 446px;
		height: 327px;
		left: 6px;
		top: 0px;
		overflow: visible;
	}
	#white_shade_footer_web_7_mob_dx {
		fill: url(#white_shade_footer_web_7_mob_dx);
	}
	.white_shade_footer_web_7_mob_dx {
		position: absolute;
		overflow: visible;
		width: 299px;
		height: 281px;
		left: 0px;
		top: 17px;
	}
	#gps_map_web_7_mob {
		position: absolute;
		width: 334px;
		height: 208px;
		left: 50px;
		top: 0px;
		overflow: visible;
	}
	#bdr_gps_web_7_mob {
		fill: rgba(0,0,0,1);
	}
	.bdr_gps_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 334px;
		height: 208px;
		left: 0px;
		top: 0px;
	}
	#screen_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.screen_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 319px;
		height: 175px;
		left: 7px;
		top: 6px;
	}
	#Icon_awesome-car_web_7_mob_d {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-car_web_7_mob_d {
		overflow: visible;
		position: absolute;
		width: 13.222px;
		height: 9.916px;
		left: 24.606px;
		top: 190.74px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesomme-music_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesomme-music_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 9.917px;
		height: 9.916px;
		left: 62.267px;
		top: 190.74px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#arrow_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.arrow_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 9.917px;
		height: 9.916px;
		left: 145.435px;
		top: 190.74px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#back_web_7_mob {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.back_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 8.198px;
		height: 11.916px;
		left: 194.678px;
		top: 190.74px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#back_line_web_7_mob {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.back_line_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 10.678px;
		left: 192.199px;
		top: 191.359px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#play_web_7_mob {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.play_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 8.198px;
		height: 11.916px;
		left: 225.406px;
		top: 190.74px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#forward_line_ {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.forward_line_ {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 10.678px;
		left: 234.083px;
		top: 191.359px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-volume-up_web_7_m {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-volume-up_web_7_m {
		overflow: visible;
		position: absolute;
		width: 10.903px;
		height: 9.086px;
		left: 302.703px;
		top: 191.57px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#dv_socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 123px;
		top: 236px;
		overflow: visible;
	}
	#linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.med_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 13.815px;
		top: 13.191px;
		overflow: visible;
	}
	#n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#shadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_7_em:hover {
		fill: rgba(50,172,158,1);
	}
	.med_shadow_space_website_7_em:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_7_em {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_7_em {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#overall_bdr_web_7_mob {
		fill: rgba(192,192,192,1);
	}
	.overall_bdr_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 345px;
		left: 29px;
		top: 381px;
	}
	#Call_us_for_questions_web_7_mo {
		left: 85px;
		top: 399px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 12px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#top_line_call_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.top_line_call_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 1px;
		left: 85px;
		top: 429px;
	}
	#bd_acllr_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.bd_acllr_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 263px;
		height: 46px;
		left: 85px;
		top: 444px;
	}
	#n_1_123-456-7890_web_7_mob {
		left: 137px;
		top: 456px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		line-height: 31px;
		margin-top: -8px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#call_bdr_img_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.call_bdr_img_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 46px;
		left: 85px;
		top: 444px;
	}
	#call_web_7_mob {
		fill: rgba(21,21,21,1);
	}
	.call_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 17.84px;
		height: 17.828px;
		left: 98.343px;
		top: 457.395px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Email_us_for_inquiries_web_7_m {
		left: 85px;
		top: 503px;
		position: absolute;
		overflow: visible;
		width: 155px;
		white-space: nowrap;
		line-height: 12px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#email_top_line_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.email_top_line_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 1px;
		left: 85px;
		top: 532px;
	}
	#bdr_email_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.bdr_email_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 263px;
		height: 46px;
		left: 85px;
		top: 548px;
	}
	#emailmailcom_web_7_mob {
		left: 138px;
		top: 561px;
		position: absolute;
		overflow: visible;
		width: 212px;
		height: 27px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
	}
	#email_bdr_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.email_bdr_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 45px;
		height: 46px;
		left: 85px;
		top: 548px;
	}
	#email_web_7_mob_2 {
		fill: rgba(21,21,21,1);
	}
	.email_web_7_mob_2 {
		overflow: visible;
		position: absolute;
		width: 22.411px;
		height: 11.205px;
		left: 96.807px;
		top: 562.236px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_email_web_7_mob_1 {
		fill: rgba(21,21,21,1);
	}
	.n_email_web_7_mob_1 {
		overflow: visible;
		position: absolute;
		width: 22.411px;
		height: 11.93px;
		left: 96.807px;
		top: 567.914px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Visit_us_for_inquiries_web_7_m {
		left: 86px;
		top: 608px;
		position: absolute;
		overflow: visible;
		width: 149px;
		white-space: nowrap;
		line-height: 12px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#emai_bdr_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.emai_bdr_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 45px;
		height: 1px;
		left: 85px;
		top: 637px;
	}
	#address_bdr_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.address_bdr_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 263px;
		height: 46px;
		left: 86px;
		top: 653px;
	}
	#address_lbl_web_7_mob {
		left: 139px;
		top: 665px;
		position: absolute;
		overflow: visible;
		width: 212px;
		height: 27px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
	}
	#loc_bdr_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.loc_bdr_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 45px;
		height: 46px;
		left: 86px;
		top: 653px;
	}
	#Icon_material-edit-location_we {
		fill: rgba(0,0,0,1);
	}
	.Icon_material-edit-location_we {
		overflow: visible;
		position: absolute;
		width: 15.974px;
		height: 22.82px;
		left: 100.501px;
		top: 664.723px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_Copyright_Operate_Live_OPC_a {
		left: 39px;
		top: 907px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(173,173,173,1);
	}
	.mediaViewInfo {
		--web-view-name: ride revolution;
		--web-view-id: ride_revolution_mob;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ride_revolution_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none
	}
	#ride_revolution_mob {
		position: absolute;
		width: 375px;
		height: 4049px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(249,249,249,0.886);
		overflow: hidden;
		--web-view-name: ride revolution;
		--web-view-id: ride_revolution_mob;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#header_web_8_bike_mob {
		position: absolute;
		width: 495px;
		height: 267px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#car_bg_web_7_mob {
		position: absolute;
		width: 495px;
		height: 267px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#text_unleash_grp_web_7_mob {
		position: absolute;
		width: 184px;
		height: 59px;
		left: 15px;
		top: 72px;
		overflow: visible;
	}
	#Unleash_the_True_Power_web_7_m {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 167px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#Drive_into_the_Future__Your_Ul {
		left: 0px;
		top: 33px;
		position: absolute;
		overflow: visible;
		width: 185px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(255,255,255,1);
	}
	#pre-order_button {
		position: absolute;
		width: 97px;
		height: 14px;
		left: 22px;
		top: 147px;
		overflow: visible;
	}
	#pre_line_web_7_mob {
		fill: rgba(222,14,11,1);
	}
	.pre_line_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 16px;
		height: 1px;
		left: 40px;
		top: 0px;
	}
	#PRE-ORDER_NOW_web_7_mob {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 98px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 10px;
		color: rgba(222,14,11,1);
		letter-spacing: 0.95px;
	}
	#zane-lee-bXbi1jJVDf0-unsplash_rr_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 16px;
		top: 11px;
		border-radius: 50%;
		overflow: hidden;
	}
	#yamede_princi_web_8pc_bike_mob {
		position: absolute;
		width: 331px;
		height: 487.791px;
		left: 25px;
		top: 1893px;
		overflow: visible;
	}
	#princi_img_web_7_mob {
		position: absolute;
		width: 177px;
		height: 247.58px;
		left: 12.5px;
		top: 0px;
		overflow: visible;
	}
	#Path_1_5_web_7_mob {
		fill: rgba(222,14,11,1);
	}
	.Path_1_5_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 100.769px;
		height: 83.113px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,-8.8281,56.8339) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,-8.8281,56.8339) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Path_24_web_7_mob {
		fill: rgba(222,14,11,1);
	}
	.Path_24_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 74.931px;
		height: 83.113px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,4.0911,101.1882) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,4.0911,101.1882) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Path_33_web_7_mob {
		fill: rgba(222,14,11,1);
	}
	.Path_33_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 74.931px;
		height: 83.113px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,4.0911,132.6257) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,4.0911,132.6257) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Path_42_web_7_mob {
		fill: rgba(222,14,11,1);
	}
	.Path_42_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 65.026px;
		height: 83.113px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,9.0434,159.5406) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,9.0434,159.5406) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Path_51_web_7_mob {
		fill: rgba(222,14,11,1);
	}
	.Path_51_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 59.187px;
		height: 83.113px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,11.9628,176.4298) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,11.9628,176.4298) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#shade_princi_img_web_7_mob {
		fill: rgba(0,0,0,1);
	}
	.shade_princi_img_web_7_mob {
		-webkit-filter: drop-shadow(-70px 73px 99px rgba(0, 0, 0, 0.122));
		        filter: drop-shadow(-70px 73px 99px rgba(0, 0, 0, 0.122));
		position: absolute;
		overflow: visible;
		width: 445.036px;
		height: 493.811px;
		left: 28.963px;
		top: 0px;
	}
	#Mask_Groupn_web_7_mob {
		position: absolute;
		width: 148.036px;
		height: 196.811px;
		left: 28.963px;
		top: 0px;
		overflow: visible;
	}
	#prinic_grp_web_7_mob_r_r {
		position: absolute;
		width: 331px;
		height: 223px;
		left: 0px;
		top: 264.791px;
		overflow: visible;
	}
	#Principle_lbl_web_7_mob_r_r {
		left: 9px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		line-height: 33px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#princi_top_line_web_7_mob_r_r {
		fill: rgba(222,14,11,1);
	}
	.princi_top_line_web_7_mob_r_r {
		position: absolute;
		overflow: visible;
		width: 51px;
		height: 3px;
		left: 9px;
		top: 0px;
	}
	#Scroll_principle_r_r {
		mix-blend-mode: normal;
		position: absolute;
		width: 331px;
		height: 176px;
		left: 0px;
		top: 47px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_principle_r_r::-webkit-scrollbar{
		display: none;
	}
	#The_Mercedes-AMG_desc_web_7_mo_r_r {
		left: 9px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 119px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(116,116,116,1);
	}
	#onichan_vision_8_web_mob {
		position: absolute;
		width: 662px;
		height: 708.76px;
		left: -159px;
		top: 284.002px;
		overflow: visible;
	}
	#red_lineweb_7_mob {
		fill: rgba(222,14,11,1);
	}
	.red_lineweb_7_mob {
		position: absolute;
		overflow: visible;
		width: 662px;
		height: 146px;
		left: 0px;
		top: 195.76px;
	}
	#Mask_Group_web_7_mob {
		position: absolute;
		width: 283px;
		height: 289px;
		left: 251px;
		top: 0px;
		overflow: visible;
	}
	#small_thumbnails_bike_7mob {
		position: absolute;
		width: 375px;
		height: 139.885px;
		left: 159px;
		top: 232.816px;
		overflow: visible;
	}
	#over_all_bdr_black_web_7_mob_b {
		fill: rgba(21,21,21,1);
	}
	.over_all_bdr_black_web_7_mob_b {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 139.885px;
		left: 0px;
		top: 0px;
	}
	#car_grp_web_7_mob_bike {
		position: absolute;
		width: 146.88px;
		height: 118.363px;
		left: 171.091px;
		top: 10.76px;
		overflow: visible;
	}
	#gear_Group_web_7_mob_bike {
		position: absolute;
		width: 146.342px;
		height: 118.365px;
		left: 12.912px;
		top: 10.76px;
		overflow: visible;
	}
	#Line_1_web_7_mob_bike {
		fill: transparent;
		stroke: rgba(222,14,11,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_1_web_7_mob_bike {
		overflow: visible;
		position: absolute;
		width: 22.155px;
		height: 2px;
		left: 333.422px;
		top: 73.027px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_web_7_mob_arrow_bike_7mob {
		fill: rgba(222,14,11,1);
	}
	.n_web_7_mob_arrow_bike_7mob {
		overflow: visible;
		position: absolute;
		width: 6.456px;
		height: 5.38px;
		-webkit-transform: translate(0px, -0.001px) matrix(1,0,0,1,353.4795,70.4801) rotate(90deg);
		        transform: translate(0px, -0.001px) matrix(1,0,0,1,353.4795,70.4801) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Vision_lbl_web_7_mob_bike {
		left: 193px;
		top: 478.76px;
		position: absolute;
		overflow: visible;
		width: 68px;
		white-space: nowrap;
		line-height: 33px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#vison_top_line_web_7_mob_bike {
		fill: rgba(222,14,11,1);
	}
	.vison_top_line_web_7_mob_bike {
		position: absolute;
		overflow: visible;
		width: 47px;
		height: 2px;
		left: 193px;
		top: 474.76px;
	}
	#Scroll_vision_8_mob_web {
		mix-blend-mode: normal;
		position: absolute;
		width: 360px;
		height: 185px;
		left: 174px;
		top: 523.76px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_vision_8_mob_web::-webkit-scrollbar{
		display: none
	}
	#The_cockbike-t_web_7_mob {
		left: 19px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#Avaliable_Now_lbl_web_7_mob_bi {
		left: 95px;
		top: 2424px;
		position: absolute;
		overflow: visible;
		width: 187px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#mision_with_img_grp_pc_8_web_b_kk_mob {
		position: absolute;
		width: 771.5px;
		height: 743px;
		left: -381px;
		top: 1060px;
		overflow: visible;
	}
	#splash_web_7_mob_bike_kk_mob {
		position: absolute;
		width: 693px;
		height: 389.813px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#wite_engine_shade_web_7_mob_bi_dc_kk_mob {
		fill: url(#wite_engine_shade_web_7_mob_bi_dc_kk_mob);
	}
	.wite_engine_shade_web_7_mob_bi_dc_kk_mob {
		position: absolute;
		overflow: visible;
		width: 726px;
		height: 389.879px;
		left: 45.5px;
		top: 0px;
	}
	#Union_2_engine_web_7_mob_bike_kk_mob {
		fill: rgba(222,14,11,1);
	}
	.Union_2_engine_web_7_mob_bike_kk_mob {
		overflow: visible;
		position: absolute;
		width: 474.888px;
		height: 197.768px;
		left: 174.311px;
		top: 192.111px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#kisspng-mercedes-benz-a_web_7__kk_mob {
		position: absolute;
		width: 193.063px;
		height: 217.422px;
		left: 409.859px;
		top: 89.303px;
		overflow: visible;
	}
	#mission_grp_web_7_mob_bike_kk_mob {
		position: absolute;
		width: 333px;
		height: 252px;
		left: 407px;
		top: 491px;
		overflow: visible;
	}
	#Masterpiece_web_7_mob_bike_kk_mob {
		left: 8px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 9px;
		color: rgba(222,14,11,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#Mission_lbl_web_7_mob_bike_kk_mob {
		left: 8px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Scroll_mission_7_mob_bike_kk_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 333px;
		height: 204px;
		left: 0px;
		top: 48px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_mission_7_mob_bike_kk_mob::-webkit-scrollbar{
		display: none;
	}	
	#An_aggregate_of_racing-level_p_kk_mob {
		left: 10px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 334px;
		height: 180px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(116,116,116,1);
	}
	#Path_11_web_7_mob_bike_kk_mob {
		fill: rgba(222,14,11,1);
	}
	.Path_11_web_7_mob_bike_kk_mob {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.207px;
		left: 714.027px;
		top: 405.014px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_12_web_7_mob_bike_kk_mob {
		fill: rgba(222,14,11,1);
	}
	.Path_12_web_7_mob_bike_kk_mob {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.207px;
		left: 714.027px;
		top: 433.693px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_13_web_7_mob_bike_kk_mob {
		fill: rgba(222,14,11,1);
	}
	.Path_13_web_7_mob_bike_kk_mob {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.207px;
		left: 714.027px;
		top: 463.107px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#avaliable_now_design_web_7_mob {
		fill: rgba(222,14,11,1);
	}
	.avaliable_now_design_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 474.888px;
		height: 197.767px;
		left: -206.689px;
		top: 2527.111px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#off_bdr_web_7_mob_bike {
		fill: rgba(13,13,13,1);
	}
	.off_bdr_web_7_mob_bike {
		width: 1975.545px;
		height: 335.723px;
		position: absolute;
		overflow: visible;
		-webkit-transform: matrix(1,0,0,1,-269.6973,2526.3301) rotate(-6deg);
		        transform: matrix(1,0,0,1,-269.6973,2526.3301) rotate(-6deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#Scroll_avali_now_card_mob_bike {
		mix-blend-mode: normal;
		position: absolute;
		width: 354.457px;
		height: 330.374px;
		left: 21.5px;
		top: 2589.996px;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-transform: rotate(-6deg);
		        transform: rotate(-6deg);
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
	}
	#Scroll_avali_now_card_mob_bike::-webkit-scrollbar{
		display: none;
	}
	#offering_card_1_shadow_space_w {
		position: relative;
		width: 178.957px;
		height: 252.874px;
		left: 19px;
		top: 36px;
        margin-right: 60px;
		overflow: visible;
		-webkit-transform: rotate(6deg);
		        transform: rotate(6deg);
	}
	#card_bg_offeringshadow_space_w {
		fill: rgba(255,255,255,1);
	}
	.card_bg_offeringshadow_space_w {
		-webkit-filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		        filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 194.457px;
		height: 268.374px;
		left: 0px;
		top: 0px;
	}
	#oofering_lbl_med_shadow_space_rr_mob {
		left: 14.005px;
		top: 227.197px;
		position: absolute;
		overflow: visible;
		width: 129px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#markus-shadow_space_website_7_ {
		position: absolute;
		width: 159.377px;
		height: 210.766px;
		left: 8.947px;
		top: 8.691px;
		overflow: visible;
	}
	#Icon_awesome-car_web_7_mob_bik {
		fill: rgba(0,0,0,1);
	}
	.Icon_awesome-car_web_7_mob_bik {
		overflow: visible;
		position: absolute;
		width: 28.011px;
		height: 21.008px;
		left: 5.835px;
		top: 18.285px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#footer_overall_bike_web_mob_8 {
		position: absolute;
		width: 452px;
		height: 943px;
		left: -29px;
		top: 3079px;
		overflow: visible;
	}
	#footer_web_7_mob {
		position: absolute;
		width: 452px;
		height: 327px;
		left: 0px;
		top: 557px;
		overflow: visible;
	}
	#jakob-rosen-FsBbavP9YA4-unspla {
		position: absolute;
		width: 446px;
		height: 327px;
		left: 6px;
		top: 0px;
		overflow: visible;
	}
	#white_shade_footer_web_7_mob_dz {
		fill: url(#white_shade_footer_web_7_mob_dz);
	}
	.white_shade_footer_web_7_mob_dz {
		position: absolute;
		overflow: visible;
		width: 299px;
		height: 281px;
		left: 0px;
		top: 17px;
	}
	#gps_map_web_7_mob_bike {
		position: absolute;
		width: 334px;
		height: 208px;
		left: 50px;
		top: 0px;
		overflow: visible;
	}
	#bdr_gps_web_7_mob_bike {
		fill: rgba(0,0,0,1);
	}
	.bdr_gps_web_7_mob_bike {
		position: absolute;
		overflow: visible;
		width: 334px;
		height: 208px;
		left: 0px;
		top: 0px;
	}
	#screen_web_7_mob_bike {
		fill: rgba(255,255,255,1);
	}
	.screen_web_7_mob_bike {
		position: absolute;
		overflow: visible;
		width: 319px;
		height: 175px;
		left: 7px;
		top: 6px;
	}
	#Icon_awesome-car_web_7_mob_bik_d {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-car_web_7_mob_bik_d {
		overflow: visible;
		position: absolute;
		width: 13.223px;
		height: 9.917px;
		left: 24.605px;
		top: 190.74px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesomme-music_web_7_mob_ {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesomme-music_web_7_mob_ {
		overflow: visible;
		position: absolute;
		width: 9.917px;
		height: 9.918px;
		left: 62.267px;
		top: 190.739px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#arrow_web_7_mob_bike {
		fill: rgba(255,255,255,1);
	}
	.arrow_web_7_mob_bike {
		overflow: visible;
		position: absolute;
		width: 9.917px;
		height: 9.917px;
		left: 145.435px;
		top: 190.74px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#back_web_7_mob_bike {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.back_web_7_mob_bike {
		overflow: visible;
		position: absolute;
		width: 8.198px;
		height: 11.917px;
		left: 194.678px;
		top: 190.74px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#back_line_web_7_mob_bike {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.back_line_web_7_mob_bike {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 10.677px;
		left: 192.199px;
		top: 191.359px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#play_web_7_mob_bike {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.play_web_7_mob_bike {
		overflow: visible;
		position: absolute;
		width: 8.198px;
		height: 11.917px;
		left: 225.406px;
		top: 190.74px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#forward_line_bike {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.forward_line_bike {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 10.677px;
		left: 234.083px;
		top: 191.359px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-volume-up_web_7_m {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-volume-up_web_7_m {
		overflow: visible;
		position: absolute;
		width: 10.903px;
		height: 9.086px;
		left: 302.703px;
		top: 191.57px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#rr_socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 123px;
		top: 236px;
		overflow: visible;
	}
	#linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_7_bik:hover {
		fill: rgba(120,120,255,1);
	}
	.med_shadow_space_website_7_bik:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_7_bik {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_7_bik {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 13.815px;
		top: 13.191px;
		overflow: visible;
	}
	#n_website_7_layout_bike_mob {
		fill: rgba(63,61,86,1);
	}
	.n_website_7_layout_bike_mob {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.554px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.526px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#shadow_space_website_7_mob_bik:hover {
		fill: rgba(255,120,120,1);
	}
	.shadow_space_website_7_mob_bik:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#shadow_space_website_7_mob_bik {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.shadow_space_website_7_mob_bik {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_7_mob:hover {
		fill: rgba(50,172,158,1);
	}
	.med_shadow_space_website_7_mob:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_7_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_7_mob {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#footer_details_grp_web_7_mobbi {
		position: absolute;
		width: 375px;
		height: 345px;
		left: 29px;
		top: 381px;
		overflow: visible;
	}
	#overall_bdr_web_7_mob_bike {
		fill: rgba(21,21,21,1);
	}
	.overall_bdr_web_7_mob_bike {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 345px;
		left: 0px;
		top: 0px;
	}
	#Call_us_for_questions_web_7_mo {
		left: 56px;
		top: 18px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 12px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#top_line_call_web_7_mob_bike {
		fill: rgba(255,255,255,1);
	}
	.top_line_call_web_7_mob_bike {
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 1px;
		left: 56px;
		top: 48px;
	}
	#bd_acllr_web_7_mob_bike {
		fill: rgba(222,14,11,1);
	}
	.bd_acllr_web_7_mob_bike {
		position: absolute;
		overflow: visible;
		width: 263px;
		height: 46px;
		left: 56px;
		top: 63px;
	}
	#n_1_123-456-7890_web_7_mob_bik {
		left: 108px;
		top: 75px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		line-height: 31px;
		margin-top: -8px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#call_bdr_img_web_7_mob_bike {
		fill: rgba(255,255,255,1);
	}
	.call_bdr_img_web_7_mob_bike {
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 46px;
		left: 56px;
		top: 63px;
	}
	#call_web_7_mob_bike {
		fill: rgba(21,21,21,1);
	}
	.call_web_7_mob_bike {
		overflow: visible;
		position: absolute;
		width: 17.84px;
		height: 17.828px;
		left: 69.344px;
		top: 76.396px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Email_us_for_inquiries_web_7_m {
		left: 56px;
		top: 122px;
		position: absolute;
		overflow: visible;
		width: 155px;
		white-space: nowrap;
		line-height: 12px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#email_top_line_web_7_mob_bike {
		fill: rgba(255,255,255,1);
	}
	.email_top_line_web_7_mob_bike {
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 1px;
		left: 56px;
		top: 151px;
	}
	#bdr_email_web_7_mob_bike {
		fill: rgba(222,14,11,1);
	}
	.bdr_email_web_7_mob_bike {
		position: absolute;
		overflow: visible;
		width: 263px;
		height: 46px;
		left: 56px;
		top: 167px;
	}
	#emailmailcom_web_7_mob_bike {
		left: 109px;
		top: 180px;
		position: absolute;
		overflow: visible;
		width: 212px;
		height: 20px;
		line-height: 41px;
		margin-top: -13px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
	}
	#email_bdr_web_7_mob_bike {
		fill: rgba(255,255,255,1);
	}
	.email_bdr_web_7_mob_bike {
		position: absolute;
		overflow: visible;
		width: 45px;
		height: 46px;
		left: 56px;
		top: 167px;
	}
	#email_web_7_mob_2_bike {
		fill: rgba(21,21,21,1);
	}
	.email_web_7_mob_2_bike {
		overflow: visible;
		position: absolute;
		width: 22.41px;
		height: 11.204px;
		left: 67.807px;
		top: 181.236px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_email_web_7_mob_1_bike {
		fill: rgba(21,21,21,1);
	}
	.n_email_web_7_mob_1_bike {
		overflow: visible;
		position: absolute;
		width: 22.41px;
		height: 11.931px;
		left: 67.807px;
		top: 186.913px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Visit_us_for_inquiries_web_7_m {
		left: 57px;
		top: 227px;
		position: absolute;
		overflow: visible;
		width: 149px;
		white-space: nowrap;
		line-height: 12px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#emai_bdr_web_7_mob_bike {
		fill: rgba(255,255,255,1);
	}
	.emai_bdr_web_7_mob_bike {
		position: absolute;
		overflow: visible;
		width: 45px;
		height: 1px;
		left: 56px;
		top: 256px;
	}
	#address_bdr_web_7_mob_bike {
		fill: rgba(222,14,11,1);
	}
	.address_bdr_web_7_mob_bike {
		position: absolute;
		overflow: visible;
		width: 263px;
		height: 70px;
		left: 57px;
		top: 272px;
	}
	#address_lbl_web_7_mob_bike {
		left: 109px;
		top: 272px;
		position: absolute;
		overflow: visible;
		width: 212px;
		height: 46px;
		line-height: 41px;
		margin-top: -13px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
	}
	#loc_bdr_web_7_mob_bike {
		fill: rgba(255,255,255,1);
	}
	.loc_bdr_web_7_mob_bike {
		position: absolute;
		overflow: visible;
		width: 45px;
		height: 70px;
		left: 57px;
		top: 272px;
	}
	#Icon_material-edit-location_we {
		fill: rgba(0,0,0,1);
	}
	.Icon_material-edit-location_we {
		overflow: visible;
		position: absolute;
		width: 15.974px;
		height: 22.819px;
		left: 71.501px;
		top: 290.723px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_Copyright_Operate_Live_bike_mob8 {
		left: 39px;
		top: 907px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(173,173,173,1);
	}
	.mediaViewInfo {
		--web-view-name: Fashion Finds mob;
		--web-view-id: Fashion_Finds_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Fashion_Finds_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Fashion_Finds_mob {
		position: absolute;
		width: 375px;
		height: 4633px;
        left:calc((100vw - 375px)/2);
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Fashion Finds mob;
		--web-view-id: Fashion_Finds_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#vision_all_grp_web_9_mob {
		position: absolute;
		width: 365px;
		height: 744px;
		left: 0px;
		top: 888px;
		overflow: visible;
	}
	#Scroll_vis_web_9_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 365px;
		height: 287px;
		left: 0px;
		top: 457px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BinQasem_vision_lbl_web_9_mob {
		left: 15px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 350px;
		height: 413px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#vision_lbl_web_9_mob {
		left: 33px;
		top: 375px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#Mask_vision_web_9_mob {
		position: absolute;
		width: 310px;
		height: 355px;
		left: 33px;
		top: 0px;
		overflow: visible;
	}
	#mision_grp_web_9_mob {
		position: absolute;
		width: 365px;
		height: 744px;
		left: 0px;
		top: 1706px;
		overflow: visible;
	}
	#Scroll_mission_web_9_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 365px;
		height: 287px;
		left: 0px;
		top: 457px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BinQasem_provid_web_mis_9_mob {
		left: 15px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 350px;
		height: 413px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#MISSION_lbl_web_9_mob {
		left: 33px;
		top: 393px;
		position: absolute;
		overflow: visible;
		width: 121px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#Mask_mission_web_9_mob {
		position: absolute;
		width: 310px;
		height: 355px;
		left: 33px;
		top: 0px;
		overflow: visible;
	}
	#princi_all_grp_web_9_mob {
		position: absolute;
		width: 365px;
		height: 744px;
		left: 0px;
		top: 2524px;
		overflow: visible;
	}
	#Scroll_princi_web_9_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 365px;
		height: 287px;
		left: 0px;
		top: 457px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BinQasem_provi_web_9_mob {
		left: 15px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 350px;
		height: 413px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#principle_lbl_web_9_mob {
		left: 33px;
		top: 393px;
		position: absolute;
		overflow: visible;
		width: 139px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#Mask_for_princi_web_9_mob {
		position: absolute;
		width: 310px;
		height: 355px;
		left: 33px;
		top: 0px;
		overflow: visible;
	}
	#our_services_web_9_mob_ {
		position: absolute;
		width: 374px;
		height: 473.001px;
		left: 21px;
		top: 3453px;
		overflow: visible;
	}
	#line_for_hanger_web_9_mob {
		fill: rgba(150,111,51,1);
	}
	.line_for_hanger_web_9_mob {
		position: absolute;
		overflow: visible;
		width: 357px;
		height: 5px;
		left: 17px;
		top: 33px;
	}
	#Scroll_card_service_mob_9_them {
		mix-blend-mode: normal;
		position: absolute;
		width: 348px;
		height: 473.001px;
		left: 6px;
		top: 0px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#card_1_services_web_9_mob {
		position: relative;
		width: 325px;
		height: 436.001px;
		left: 8px;
		top: 16px;
		overflow: visible;
	}
	#hanger_img_web_9_mob {
		position: absolute;
		width: 325px;
		height: 325px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Mask_web_9_mob_card_1 {
		position: absolute;
		width: 256px;
		height: 303px;
		left: 23px;
		top: 133px;
		overflow: visible;
	}
	#Bunda_Clothes_web_9_mob {
		left: 23px;
		top: 390px;
		position: absolute;
		overflow: visible;
		width: 100px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
		text-transform: uppercase;
	}
	#badr_path_web_9_mob {
		fill: rgba(49,49,49,1);
	}
	.badr_path_web_9_mob {
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 58px;
		left: 221.999px;
		top: 378px;
	}
	#law_6_web_9_mob {
		position: absolute;
		width: 15.368px;
		height: 16.199px;
		left: 242.504px;
		top: 399.148px;
		overflow: visible;
	}
	#Path_6945_web_9_mob {
		fill: rgba(255,255,255,1);
	}
	.Path_6945_web_9_mob {
		overflow: visible;
		position: absolute;
		width: 10.408px;
		height: 11.439px;
		left: 0.902px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_6946_web_9_mob {
		fill: rgba(255,255,255,1);
	}
	.Path_6946_web_9_mob {
		overflow: visible;
		position: absolute;
		width: 7.394px;
		height: 6.184px;
		left: 7.975px;
		top: 6.443px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_6947_web_9_mob {
		fill: rgba(255,255,255,1);
	}
	.Path_6947_web_9_mob {
		overflow: visible;
		position: absolute;
		width: 10.543px;
		height: 3.797px;
		left: 0px;
		top: 12.402px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#holder_knob_web_9_mob {
		fill: rgba(150,111,51,1);
	}
	.holder_knob_web_9_mob {
		position: absolute;
		overflow: visible;
		width: 27px;
		height: 27px;
		left: 0px;
		top: 22px;
	}
	#our_services_lbl_web_9_mob {
		left: 90px;
		top: 3393px;
		position: absolute;
		overflow: visible;
		width: 196px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#footer_all_grp_web_9_mob {
		position: absolute;
		width: 375px;
		height: 607px;
		left: 0px;
		top: 4026px;
		overflow: visible;
	}
	#footer_bg_web_9_mob {
		opacity: 0.93;
		fill: rgba(50,50,50,1);
	}
	.footer_bg_web_9_mob {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 607px;
		left: 0px;
		top: 0px;
	}
	#footer_all_design_web_9_mob {
		position: absolute;
		width: 349px;
		height: 225px;
		left: 13px;
		top: 30px;
		overflow: visible;
	}
	#clothing_store_remove_bg {
		position: absolute;
		width: 349px;
		height: 225px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#grp_map_ff_mob {
		fill: rgba(255,255,255,1);
	}
	.grp_map_ff_mob {
		position: absolute;
		overflow: visible;
		width: 322px;
		height: 145px;
		left: 13px;
		top: 68px;
	}
	#For_Queries_info_web_9_mob_ {
		left: 91.764px;
		top: 293px;
		position: absolute;
		overflow: visible;
		width: 189px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#For_call__920012888_web_9_mob {
		left: 91.764px;
		top: 314px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#telephone_web_9_mob {
		fill: rgba(255,255,255,1);
	}
	.telephone_web_9_mob {
		overflow: visible;
		position: absolute;
		width: 13.412px;
		height: 14.475px;
		left: 65.237px;
		top: 318.525px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#send_1_web_9_mob {
		fill: rgba(255,255,255,1);
	}
	.send_1_web_9_mob {
		overflow: visible;
		position: absolute;
		width: 15.446px;
		height: 14.281px;
		left: 63.203px;
		top: 293.961px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#socials_grp_med_web_9_mob {
		position: absolute;
		width: 210px;
		height: 50.001px;
		left: 65.237px;
		top: 423px;
		overflow: visible;
	}
	#linked_in_grp_med_web_9_mob {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 160px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_5_med_web_9_mob:hover {
		fill: rgba(120,120,255,1);
	}
	.Ellipse_5_med_web_9_mob:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_5_med_web_9_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_5_med_web_9_mob {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_website_9_layout {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#n_website_9_layout {
		fill: rgba(63,61,86,1);
	}
	.n_website_9_layout {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.953px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_down_web_9_mob {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_down_web_9_mob {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.037px;
		left: 0.614px;
		top: 7.098px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_website_9_layout {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_website_9_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.217px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_med_web_9_mob {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 80px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_4_med_web_9_mob:hover {
		fill: rgba(255,120,120,1);
	}
	.Ellipse_4_med_web_9_mob:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_4_med_web_9_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_4_med_web_9_mob {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagram_website_9_layout {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.125px;
		overflow: visible;
	}
	#whatsapp_grp_med_web_9_mob {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_3_med_web_9_mob:hover {
		fill: rgba(50,172,158,1);
	}
	.Ellipse_3_med_web_9_mob:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_3_med_web_9_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_3_med_web_9_mob {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_website9_layout {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#Icon_metro-location_web_9_mob {
		fill: rgba(255,255,255,1);
	}
	.Icon_metro-location_web_9_mob {
		overflow: visible;
		position: absolute;
		width: 11.381px;
		height: 18.209px;
		left: 65.236px;
		top: 339.33px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#For_call__920012888_web_9_mob_dr {
		left: 91.764px;
		top: 338.539px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 62px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#n_Copyrig_website_9_mobop_live {
		left: 29px;
		top: 540.461px;
		position: absolute;
		overflow: visible;
		width: 318px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#header_all_grp_web_9_mob {
		position: absolute;
		width: 1366px;
		height: 771px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_02307280_web_9_mob {
		position: absolute;
		width: 1366px;
		height: 766px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#shade_img_web_9_mob {
		opacity: 0.18;
		fill: rgba(0,0,0,1);
	}
	.shade_img_web_9_mob {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 771px;
		left: 0px;
		top: 0px;
	}
	#pfp_pic_web_9_mob {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 30px;
		top: 97px;
		overflow: visible;
	}
	#bdr_pfp_web_9_mob {
		fill: rgba(255,255,255,1);
	}
	.bdr_pfp_web_9_mob {
		position: absolute;
		overflow: visible;
		width: 70px;
		height: 70px;
		left: 0px;
		top: 0px;
	}
	#zane-lee-bXbi_web_9_mob {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 5px;
		top: 5px;
		border-radius: 50%;
		overflow: hidden;
	}
	#ACT_NOW__SEIZE_THE_DEAL_web_9_ {
		left: 30px;
		top: 263px;
		position: absolute;
		overflow: visible;
		width: 316px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 45px;
		color: rgba(255,255,255,1);
	}
	#Sell_btn_web_9_mob {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 30px;
		top: 441.002px;
		overflow: visible;
	}
	#bdr_bids_web_9_mob {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 1.5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.bdr_bids_web_9_mob {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: -0.002px;
	}
	#START_BIDDING_web_9_mob {
		left: 28px;
		top: 10.998px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#Scroll_card_service_mob_9_them::-webkit-scrollbar{
		display: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
#Scroll_vis_web_9_mob::-webkit-scrollbar{
		display: none;
	}
#Scroll_mission_web_9_mob::-webkit-scrollbar{
		display: none;
	}
#Scroll_princi_web_9_mob::-webkit-scrollbar{
		display: none;
	}
	.mediaViewInfo {
		--web-view-name: Journey Junkies Mob;
		--web-view-id: Journey_Junkies_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Journey_Junkies_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Journey_Junkies_Mob {
		position: absolute;
		width: 375px;
		height: 4645px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(249,249,249,0.886);
		overflow: hidden;
		--web-view-name: Journey Junkies Mob;
		--web-view-id: Journey_Junkies_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#JJ_Mb_Rectangle_682_cb {
		fill: url(#Rectangle_682_cb);
	}
	.JJ_Mb_Rectangle_682_cb {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 786px;
		left: 0px;
		top: 0px;
	}
	#JJ_Mb_leaf {
		position: absolute;
		width: 314.239px;
		height: 314.239px;
		left: 217.881px;
		top: 69.505px;
		overflow: visible;
	}
	#JJ_Mb_explore_Unwind_Discover {
		-webkit-filter: drop-shadow(0px 16px 21px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 16px 21px rgba(0, 0, 0, 0.161));
		left: 27px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 162px;
		white-space: nowrap;
		text-align: left;
		font-family: Square721 BT;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#JJ_Mb_Group_14874 {
		position: absolute;
		width: 458.157px;
		height: 460.538px;
		left: -42px;
		top: 153.475px;
		overflow: visible;
	}
	#JJ_Mb_path_3 {
		opacity: 0.19;
		fill: rgba(55,143,50,1);
	}
	.JJ_Mb_path_3 {
		overflow: visible;
		position: absolute;
		width: 334.237px;
		height: 323.476px;
		-webkit-transform: translate(-7px, -29.475px) matrix(1,0,0,1,68.9606,98.0055) rotate(54deg);
		        transform: translate(-7px, -29.475px) matrix(1,0,0,1,68.9606,98.0055) rotate(54deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#JJ_Mb_path_2 {
		opacity: 0.3;
		fill: rgba(55,143,50,1);
	}
	.JJ_Mb_path_2 {
		overflow: visible;
		position: absolute;
		width: 334.237px;
		height: 323.476px;
		-webkit-transform: translate(-7px, -29.475px) matrix(1,0,0,1,68.9604,98.0055) rotate(15deg);
		        transform: translate(-7px, -29.475px) matrix(1,0,0,1,68.9604,98.0055) rotate(15deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#JJ_Mb_path_1 {
		position: absolute;
		width: 323.86px;
		height: 313.433px;
		left: 66.978px;
		top: 73.302px;
		overflow: visible;
	}
	#JJ_Mb_Group_14875 {
		position: absolute;
		width: 334.016px;
		height: 254.938px;
		left: 27px;
		top: 625.407px;
		overflow: visible;
	}
	#JJ_Mb_Scroll_Group_19 {
		mix-blend-mode: normal;
		position: absolute;
		width: 320px;
		height: 175.57px;
		left: 14.016px;
		top: 0.875px;
		overflow: hidden;
	}
	#JJ_Mb_Lorem_ipsum_dolor_sit_amet_con {
		left: 14px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(125,125,125,1);
	}
	#JJ_Mb_Line_1 {
		fill: transparent;
		stroke: rgba(13,146,6,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.JJ_Mb_Line_1 {
		overflow: visible;
		position: absolute;
		width: 4px;
		height: 254.938px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#JJ_Mb_Group_14876 {
		position: absolute;
		width: 713px;
		height: 120px;
		left: 27px;
		top: 936px;
		overflow: visible;
	}
	#JJ_Mb_joaquin-3VK6Urf2vE8-unsplash {
		-webkit-filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 231px;
		height: 120px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_ammie-ngo-vcu-OZBxxRk-unsplash {
		-webkit-filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 231px;
		height: 120px;
		left: 241px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_daniel-burka-facU72FcKBI-unspl {
		-webkit-filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 231px;
		height: 120px;
		left: 482px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_Group_4 {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 496.754px;
		top: 62px;
		overflow: visible;
	}
	#JJ_Mb_Ellipse_2 {
		opacity: 0.79;
		fill: rgba(255,255,255,1);
	}
	.JJ_Mb_Ellipse_2 {
		-webkit-filter: drop-shadow(5px 0px 7px rgba(0, 0, 0, 0.529));
		        filter: drop-shadow(5px 0px 7px rgba(0, 0, 0, 0.529));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#JJ_Mb_Icon_feather-arrow-down-right {
		-webkit-transform: translate(0.411px, -0.412px) matrix(1,0,0,1,10.1247,16.2582) rotate(-45deg);
		        transform: translate(0.411px, -0.412px) matrix(1,0,0,1,10.1247,16.2582) rotate(-45deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		position: absolute;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_Path_2_cw {
		fill: transparent;
		stroke: rgba(13,146,6,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.JJ_Mb_Path_2_cw {
		overflow: visible;
		position: absolute;
		width: 9.899px;
		height: 9.9px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#JJ_Mb_Group_3 {
		position: absolute;
		width: 128px;
		height: 38px;
		left: 357px;
		top: 62px;
		overflow: visible;
	}
	#JJ_Mb_Rectangle_3 {
		opacity: 0.79;
		fill: rgba(255,255,255,1);
	}
	.JJ_Mb_Rectangle_3 {
		-webkit-filter: drop-shadow(5px 0px 7px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 0px 7px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 149px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#JJ_Mb_See_all_photos {
		left: 16px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(13,146,6,1);
	}
	#JJ_Mb_Group_14870 {
		position: absolute;
		width: 305px;
		height: 555px;
		left: 35px;
		top: 1135px;
		overflow: visible;
	}
	#JJ_Mb_Rectangle_683 {
		fill: rgba(255,255,255,1);
	}
	.JJ_Mb_Rectangle_683 {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 323px;
		height: 456px;
		left: 0px;
		top: 117px;
	}
	#JJ_Mb_pietro-de-grandi-T7K4aEPoGGk-u {
		position: absolute;
		width: 206px;
		height: 278px;
		left: 49px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_VISION {
		left: 48px;
		top: 313px;
		position: absolute;
		overflow: visible;
		width: 62px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#JJ_Mb_Lorem_ipsum_dolor_sit_amet_con_c {
		left: 48px;
		top: 354px;
		position: absolute;
		overflow-x: hidden;
		overflow-y: scroll;
		width: 234px;
		height: 181px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#JJ_Mb_Group_14877 {
		position: absolute;
		width: 305px;
		height: 555px;
		left: 35px;
		top: 1780px;
		overflow: visible;
	}
	#JJ_Mb_Rectangle_683_c {
		fill: rgba(255,255,255,1);
	}
	.JJ_Mb_Rectangle_683_c {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 323px;
		height: 456px;
		left: 0px;
		top: 117px;
	}
	#JJ_Mb_jake-blucker-tMzCrBkM99Y-unspl {
		position: absolute;
		width: 206px;
		height: 278px;
		left: 49px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_MISSION {
		left: 48px;
		top: 313px;
		position: absolute;
		overflow: visible;
		width: 78px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#JJ_Mb_Lorem_ipsum_dolor_sit_amet_con_da {
		left: 48px;
		top: 354px;
		position: absolute;
		overflow-x: hidden;
		overflow-y: scroll;
		width: 234px;
		height: 181px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#JJ_Mb_Group_14878 {
		position: absolute;
		width: 305px;
		height: 555px;
		left: 35px;
		top: 2425px;
		overflow: visible;
	}
	#JJ_Mb_Rectangle_683_db {
		fill: rgba(255,255,255,1);
	}
	.JJ_Mb_Rectangle_683_db {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 323px;
		height: 456px;
		left: 0px;
		top: 117px;
	}
	#JJ_Mb_tom-barrett-M0AWNxnLaMw-unspla {
		position: absolute;
		width: 206px;
		height: 278px;
		left: 49px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_PRINCIPLES {
		left: 48px;
		top: 313px;
		position: absolute;
		overflow: visible;
		width: 101px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#JJ_Mb_Lorem_ipsum_dolor_sit_amet_con_de {
		left: 48px;
		top: 354px;
		position: absolute;
		overflow-x: hidden;
		overflow-y: scroll;
		width: 234px;
		height: 181px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#JJ_Mb_FIND_POPULAR__DESTINATION_ {
		left: 71px;
		top: 3080px;
		position: absolute;
		overflow: visible;
		width: 235px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}

	
	#JJ_Mb_Group_14871 {
		position: absolute;
		width: 1366px;
		height: 428px;
		left: 0px;
		top: 3233px;
		overflow: visible;
	}
	#JJ_Mb_taneli-lahtinen-2gJJrH4OnNk-un {
		position: absolute;
		width: 1366px;
		height: 428px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_Scroll_Group_30 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 316px;
		/* left: 23px; */
		top: 56px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#JJ_Mb_Group_14872 {
		position: relative;
		width: 198px;
		height: 278px;
		left: 12px;
		top: 19px;
		margin-right:50px;
		overflow: visible;
	}
	#JJ_Mb_Rectangle_688 {
		fill: rgba(255,255,255,1);
	}
	.JJ_Mb_Rectangle_688 {
		position: absolute;
		overflow: visible;
		width: 198px;
		height: 278px;
		left: 0px;
		top: 0px;
	}
	#JJ_Mb_dariusz-sankowski-3OiYMgDKJ6k- {
		position: absolute;
		width: 174px;
		height: 235px;
		left: 12px;
		top: 34px;
		overflow: visible;
	}
	#JJ_Mb_Travel_One {
		left: 12px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 176px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#JJ_Mb_Group_14879 {
		position: absolute;
		width: 479px;
		height: 180px;
		left: 19px;
		top: 3883px;
		overflow: visible;
	}
	#JJ_Mb_n_234567890_mobile_number_shad {
		left: 35px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#JJ_Mb_affanghanioperatelive_email_sh {
		left: 35px;
		top: 61px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#JJ_Mb_address_med_shadow_space_websi {
		left: 35px;
		top: 120px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#JJ_Mb_location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 120px;
		overflow: visible;
	}
	#JJ_Mb_email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 60px;
		overflow: visible;
	}
	#JJ_Mb_phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_Group_14873 {
		position: absolute;
		width: 340px;
		height: 379px;
		left: 18px;
		top: 4175px;
		overflow: visible;
	}
	#JJ_Mb_kisspng-billboard-advertising- {
		position: absolute;
		width: 340px;
		height: 379px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_Rectangle_689 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.JJ_Mb_Rectangle_689 {
		position: absolute;
		overflow: visible;
		width: 329px;
		height: 153px;
		left: 6px;
		top: 23px;
	}
	#JJ_Mb_Line_28 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.JJ_Mb_Line_28 {
		overflow: visible;
		position: absolute;
		width: 340px;
		height: 2px;
		left: 18.5px;
		top: 3859.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#JJ_Mb_n_Copyright_Operate_Live_OPC_P {
		left: 51px;
		top: 4583px;
		position: absolute;
		overflow: visible;
		width: 293px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#JJ_Mb_socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 94px;
		top: 4088px;
		overflow: visible;
	}
	#JJ_Mb_linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_med_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.JJ_Mb_med_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#JJ_Mb_med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.JJ_Mb_med_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#JJ_Mb_linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.191px;
		overflow: visible;
	}
	#JJ_Mb_n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.JJ_Mb_n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#JJ_Mb_linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.JJ_Mb_linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#JJ_Mb_top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.JJ_Mb_top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#JJ_Mb_Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_shadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.JJ_Mb_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#JJ_Mb_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.JJ_Mb_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#JJ_Mb_instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#JJ_Mb_whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#JJ_Mb_med_shadow_space_website_7_eb:hover {
		fill: rgba(50,172,158,1);
	}
	.JJ_Mb_med_shadow_space_website_7_eb:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#JJ_Mb_med_shadow_space_website_7_eb {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.JJ_Mb_med_shadow_space_website_7_eb {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#JJ_Mb_whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	.mediaViewInfo {
		--web-view-name: Mobile Stock mob;
		--web-view-id: Mobile_Stock_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Mobile_Stock_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Mobile_Stock_mob {
		position: absolute;
		width: 375px;
		height: 3883px;
        left:calc((100vw - 375px)/2);
		background-color: rgba(255,255,255,0.886);
		overflow: hidden;
		--web-view-name: Mobile Stock mob;
		--web-view-id: Mobile_Stock_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#theme11_stock_mobBanner_Slider {
		position: absolute;
		width: 425.89px;
		height: 222.257px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobPath_2 {
		fill: rgba(60,65,223,1);
	}
	.theme11_stock_mobPath_2 {
		overflow: visible;
		position: absolute;
		width: 425.89px;
		height: 222.257px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_mobdugba-cauley-hushie-6MNmDi1hc_ {
		position: absolute;
		width: 425.89px;
		height: 207.917px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobPath_1527 {
		opacity: 0.21;
		fill: rgba(38,43,219,1);
	}
	.theme11_stock_mobPath_1527 {
		overflow: visible;
		position: absolute;
		width: 425.89px;
		height: 207.917px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_mobYour_Mobile_Paradise__Where_Dr {
		left: 170px;
		top: 83.958px;
		position: absolute;
		overflow: visible;
		width: 189px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#theme11_stock_mobBtg {
		position: absolute;
		width: 72.37px;
		height: 19.497px;
		left: 228px;
		top: 139.878px;
		overflow: visible;
	}
	#theme11_stock_mobRectangle_77 {
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobRectangle_77 {
		position: absolute;
		overflow: visible;
		width: 72.371px;
		height: 19.497px;
		left: 0px;
		top: 0px;
	}
	#theme11_stock_mobExplore_More {
		left: 6.185px;
		top: 3.248px;
		position: absolute;
		overflow: visible;
		width: 61px;
		white-space: nowrap;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#theme11_stock_mobGroup_14896 {
		position: absolute;
		width: 355px;
		height: 604.548px;
		left: 20px;
		top: 991.858px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14887 {
		position: absolute;
		width: 355px;
		height: 327.318px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobstyle {
		position: absolute;
		width: 286.669px;
		height: 286.727px;
		left: 68.332px;
		top: 14.033px;
		overflow: visible;
	}
	#theme11_stock_mobPath_28 {
		fill: rgba(13,19,215,1);
	}
	.theme11_stock_mobPath_28 {
		overflow: visible;
		position: absolute;
		width: 284.648px;
		height: 286.727px;
		left: 2.021px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_mobEllipse_6 {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobEllipse_6 {
		position: absolute;
		overflow: visible;
		width: 238.554px;
		height: 238.554px;
		left: 0px;
		top: 29.354px;
	}
	#theme11_stock_mobEllipse_5 {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobEllipse_5 {
		position: absolute;
		overflow: visible;
		width: 135.854px;
		height: 135.854px;
		left: 51.35px;
		top: 80.703px;
	}
	#theme11_stock_mobneil-soni-6wdRuK7bVTE-unsplash {
		position: absolute;
		width: 203.69px;
		height: 327.318px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14888 {
		position: absolute;
		width: 341px;
		height: 232px;
		left: 14px;
		top: 372.548px;
		overflow: visible;
	}
	#theme11_stock_mobScroll_Group_24 {
		mix-blend-mode: normal;
		position: absolute;
		width: 341px;
		height: 199px;
		left: 0px;
		top: 33px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#theme11_stock_mobIt_is_a_long_established_fact_ {
		left: 0px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 342px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#theme11_stock_mobMISSION {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_mobGroup_14895 {
		position: absolute;
		width: 361px;
		height: 646.091px;
		left: 0px;
		top: 286.84px;
		overflow: visible;
	}
	#theme11_stock_mobstyle_cx {
		position: absolute;
		width: 298.024px;
		height: 298.085px;
		left: 0px;
		top: 24.776px;
		overflow: visible;
	}
	#theme11_stock_mobPath_28_cy {
		fill: rgba(13,19,215,1);
	}
	.theme11_stock_mobPath_28_cy {
		overflow: visible;
		position: absolute;
		width: 295.924px;
		height: 298.085px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_mobEllipse_6_cz {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobEllipse_6_cz {
		position: absolute;
		overflow: visible;
		width: 248.003px;
		height: 248.003px;
		left: 50.021px;
		top: 30.516px;
	}
	#theme11_stock_mobEllipse_5_c {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobEllipse_5_c {
		position: absolute;
		overflow: visible;
		width: 141.236px;
		height: 141.235px;
		left: 103.405px;
		top: 83.899px;
	}
	#theme11_stock_mobdaniel-korpai-H0vKZjt2k1E-unsp_c {
		position: absolute;
		width: 203.692px;
		height: 327.712px;
		left: 139.583px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14886 {
		position: absolute;
		width: 341px;
		height: 233px;
		left: 20px;
		top: 413.091px;
		overflow: visible;
	}
	#theme11_stock_mobScroll_Group_24_c {
		mix-blend-mode: normal;
		position: absolute;
		width: 341px;
		height: 199px;
		left: 0px;
		top: 34px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#theme11_stock_mobIt_is_a_long_established_fact__c {
		left: 0px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 342px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#theme11_stock_mobVISION {
		left: 6px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_mobdaniel-korpai-H0vKZjt2k1E-unsp_c {
		position: absolute;
		width: 203.692px;
		height: 327.712px;
		left: 139.583px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobstyle_c {
		position: absolute;
		width: 298.024px;
		height: 298.085px;
		left: 1px;
		top: 1685.766px;
		overflow: visible;
	}
	#theme11_stock_mobPath_28_c {
		fill: rgba(13,19,215,1);
	}
	.theme11_stock_mobPath_28_c {
		overflow: visible;
		position: absolute;
		width: 295.924px;
		height: 298.085px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_mobEllipse_6_c {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobEllipse_6_c {
		position: absolute;
		overflow: visible;
		width: 248.003px;
		height: 248.003px;
		left: 50.021px;
		top: 30.516px;
	}
	#theme11_stock_mobEllipse_5_da {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobEllipse_5_da {
		position: absolute;
		overflow: visible;
		width: 141.236px;
		height: 141.235px;
		left: 103.405px;
		top: 83.899px;
	}
	#theme11_stock_mobdaniel-korpai-H0vKZjt2k1E-unsp_db {
		position: absolute;
		width: 203.692px;
		height: 327.712px;
		left: 140.583px;
		top: 1660.989px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14889 {
		position: absolute;
		width: 341px;
		height: 238px;
		left: 27px;
		top: 2074.08px;
		overflow: visible;
	}
	#theme11_stock_mobScroll_Group_24_dd {
		mix-blend-mode: normal;
		position: absolute;
		width: 341px;
		height: 199px;
		left: 0px;
		top: 39px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#theme11_stock_mobIt_is_a_long_established_fact__de {
		left: 0px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 342px;
		height: 183.419921875px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#theme11_stock_mobPRINCIPLE {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_mobjoel-rohland-MCL2xxjZJvs-unspl {
		position: absolute;
		width: 203.692px;
		height: 327.712px;
		left: 140.583px;
		top: 1660.989px;
		overflow: visible;
	}
	#theme11_stock_mobour_Services {
		left: 89px;
		top: 2417px;
		position: absolute;
		overflow: visible;
		width: 199px;
		white-space: nowrap;
		line-height: 43px;
		margin-top: -4px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#theme11_stock_mobGroup_14890 {
		position: absolute;
		width: 375px;
		height: 404.096px;
		left: 0px;
		top: 2515.229px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14885 {
		opacity: 0.74;
		position: absolute;
		width: 225.542px;
		height: 362.433px;
		left: 149.458px;
		top: 25.803px;
		overflow: visible;
	}
	#theme11_stock_mobiPhone_X_-_Light2x {
		position: absolute;
		width: 225.542px;
		height: 362.434px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobdariusz-sankowski-3OiYMgDKJ6k- {
		position: absolute;
		width: 125.849px;
		height: 224.486px;
		left: 49.859px;
		top: 69.927px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14884 {
		opacity: 0.74;
		position: absolute;
		width: 225.542px;
		height: 362.433px;
		left: 0px;
		top: 25.803px;
		overflow: visible;
	}
	#theme11_stock_mobiPhone_X_-_Light2x_dn {
		position: absolute;
		width: 225.542px;
		height: 362.434px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobdariusz-sankowski-3OiYMgDKJ6k-_do {
		position: absolute;
		width: 125.849px;
		height: 224.486px;
		left: 49.859px;
		top: 69.927px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14883 {
		position: absolute;
		width: 251.469px;
		height: 404.096px;
		left: 65.408px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobiPhone_X_-_Light2x_dq {
		position: absolute;
		width: 251.469px;
		height: 404.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobdariusz-sankowski-3OiYMgDKJ6k-_dr {
		position: absolute;
		width: 140.315px;
		height: 250.292px;
		left: 55.591px;
		top: 77.965px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14891 {
		position: absolute;
		width: 329px;
		height: 208px;
		left: 28px;
		top: 2925px;
		overflow: visible;
	}
	#theme11_stock_mobTitle {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 31px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_mobScroll_Group_25 {
		mix-blend-mode: normal;
		position: absolute;
		width: 329px;
		height: 168px;
		left: 0px;
		top: 40px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#theme11_stock_mobDescription {
		left: 0px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 330px;
		height: 165px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_mobGroup_14897 {
		position: absolute;
		width: 494px;
		height: 704px;
		left: 0px;
		top: 3223px;
		overflow: visible;
	}
	#theme11_stock_mobButtom {
		position: absolute;
		width: 375px;
		height: 704px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobRectangle_53 {
		fill: rgba(235,235,248,1);
	}
	.theme11_stock_mobRectangle_53 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 704px;
		left: 0px;
		top: 0px;
	}
	#theme11_stock_mobn_234567890_mobile_number_shad {
		left: 50px;
		top: 59px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_mobaffanghanioperatelive_email_sh {
		left: 50px;
		top: 106px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_mobaddress_med_shadow_space_websi {
		left: 50px;
		top: 156px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_moblocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 15px;
		top: 158px;
		overflow: visible;
	}
	#theme11_stock_mobemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 15px;
		top: 105px;
		overflow: visible;
	}
	#theme11_stock_mobphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 15px;
		top: 52px;
		overflow: visible;
	}
	#theme11_stock_mobsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 94px;
		top: 243px;
		overflow: visible;
	}
	#theme11_stock_moblinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.theme11_stock_mobmed_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#theme11_stock_mobmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.theme11_stock_mobmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#theme11_stock_moblinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#theme11_stock_mobn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.theme11_stock_mobn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_moblinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.theme11_stock_moblinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_mobtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.theme11_stock_mobtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_mobInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.theme11_stock_mobshadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#theme11_stock_mobshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.theme11_stock_mobshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#theme11_stock_mobinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#theme11_stock_mobwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobmed_shadow_space_website_7_ei:hover {
		fill: rgba(50,172,158,1);
	}
	.theme11_stock_mobmed_shadow_space_website_7_ei:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#theme11_stock_mobmed_shadow_space_website_7_ei {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.theme11_stock_mobmed_shadow_space_website_7_ei {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#theme11_stock_mobwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#theme11_stock_mobGroup_14892 {
		position: absolute;
		width: 375px;
		height: 230px;
		left: 30px;
		top: 322px;
		overflow: visible;
	}
	#theme11_stock_mobkisspng-laptop-macbook-pro-mac {
		position: absolute;
		width: 375px;
		height: 230px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_mobRectangle_690 {
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobRectangle_690 {
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 149px;
		left: 45px;
		top: 38px;
	}
	#theme11_stock_mobn_Copyright_Operate_Live_OPC_P {
		left: 15px;
		top: 598px;
		position: absolute;
		overflow: visible;
		width: 293px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(173,173,173,1);
	}
	#theme11_stock_mobEllipse_62 {
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_mobEllipse_62 {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 35px;
		left: 10px;
		top: 7px;
	}
	#theme11_stock_mobjakob-rosen-FsBbavP9YA4-unspla {
		position: absolute;
		width: 45px;
		height: 45px;
		left: 19px;
		top: 2px;
        border-radius: 50%;
		overflow: hidden;
	}
	.mediaViewInfo {
		--web-view-name: Paw Palace Mob;
		--web-view-id: Paw_Palace_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Paw_Palace_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar	{
		display: none;
	}
	#Paw_Palace_Mob {
		position: absolute;
		width: 375px;
		height: 3446px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(255,255,255,0.886);
		overflow: hidden;
		--web-view-name: Paw_Palace_Mob;
		--web-view-id: Paw_Palace_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#petpaw_12_mobGroup_96 {
		-webkit-transform: matrix(1,0,0,1,-175.7714,2202.3693) rotate(105deg);
		        transform: matrix(1,0,0,1,-175.7714,2202.3693) rotate(105deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		position: absolute;
		width: 283.628px;
		height: 283.992px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobEllipse_16 {
		fill: rgba(255,229,48,1);
	}
	.petpaw_12_mobEllipse_16 {
		position: absolute;
		overflow: visible;
		width: 347.724px;
		height: 347.465px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobEllipse_20_ci {
		opacity: 0.15;
		fill: url(#petpaw_12_mobEllipse_20_ci);
	}
	.petpaw_12_mobEllipse_20_ci {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 269.43px;
		height: 269.688px;
		left: 73.016px;
		top: 36.277px;
	}
	#petpaw_12_mobEllipse_21_ck {
		opacity: 0.15;
		fill: url(#petpaw_12_mobEllipse_21_ck);
	}
	.petpaw_12_mobEllipse_21_ck {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 206.461px;
		height: 206.461px;
		left: 129.295px;
		top: 74.535px;
	}
	#petpaw_12_mobGroup_85 {
		position: absolute;
		width: 944px;
		height: 946px;
		left: 187.5px;
		top: 1344.998px;
		overflow: visible;
	}
	#petpaw_12_mobEllipse_16_cm {
		fill: rgba(255,229,48,1);
	}
	.petpaw_12_mobEllipse_16_cm {
		position: absolute;
		overflow: visible;
		width: 944px;
		height: 946px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobEllipse_20_co {
		opacity: 0.15;
		fill: url(#petpaw_12_mobEllipse_20_co);
	}
	.petpaw_12_mobEllipse_20_co {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 732px;
		height: 733px;
		left: 68px;
		top: 0px;
	}
	#petpaw_12_mobEllipse_21_cq {
		opacity: 0.15;
		fill: url(#petpaw_12_mobEllipse_21_cq);
	}
	.petpaw_12_mobEllipse_21_cq {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 560px;
		height: 561px;
		left: 154px;
		top: 0px;
	}
	#petpaw_12_mobGroup_73 {
		position: absolute;
		width: 260px;
		height: 319.396px;
		left: 58px;
		top: 473.063px;
		overflow: visible;
	}
	#petpaw_12_mobEllipse_17 {
		fill: rgba(255,229,48,1);
	}
	.petpaw_12_mobEllipse_17 {
		position: absolute;
		overflow: visible;
		width: 239.043px;
		height: 239.043px;
		left: 20.957px;
		top: 80.352px;
	}
	#petpaw_12_mobMask_Group_3 {
		position: absolute;
		width: 239.043px;
		height: 239.043px;
		left: 20.957px;
		top: 80.352px;
		overflow: visible;
	}
	#petpaw_12_mobMask_Group_4 {
		position: absolute;
		width: 260px;
		height: 250.777px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14911 {
		position: absolute;
		width: 2195.199px;
		height: 1654.761px;
		left: -587.123px;
		top: -148.9px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_76 {
		position: absolute;
		width: 949.827px;
		height: 621.962px;
		left: 490.374px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobPath_261 {
		fill: rgba(255,103,82,1);
	}
	.petpaw_12_mobPath_261 {
		overflow: visible;
		position: absolute;
		width: 948.508px;
		height: 565.438px;
		left: 1.32px;
		top: 56.525px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobPath_262_cz {
		opacity: 0.15;
		fill: url(#petpaw_12_mobPath_262_cz);
	}
	.petpaw_12_mobPath_262_cz {
		mix-blend-mode: multiply;
		overflow: visible;
		position: absolute;
		width: 830.845px;
		height: 396.705px;
		left: 28.516px;
		top: 56.361px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobPath_263_c {
		opacity: 0.15;
		fill: url(#petpaw_12_mobPath_263_c);
	}
	.petpaw_12_mobPath_263_c {
		mix-blend-mode: multiply;
		overflow: visible;
		position: absolute;
		width: 861.454px;
		height: 434.934px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobGroup_56 {
		position: absolute;
		width: 278px;
		height: 203.001px;
		left: 627.123px;
		top: 378.461px;
		overflow: visible;
	}
	#petpaw_12_mobGet_A_Pet_Now {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 217px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(255,255,255,1);
	}
	#petpaw_12_mobPawsitively_Devoted__Your_Pets {
		left: 0px;
		top: 59px;
		position: absolute;
		overflow: visible;
		width: 279px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#petpaw_12_mobGroup_55 {
		position: absolute;
		width: 168px;
		height: 53px;
		left: 0px;
		top: 150px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_1 {
		position: absolute;
		width: 168px;
		height: 53px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobRectangle_21 {
		-webkit-filter: blur(50px);
		        filter: blur(50px);
		fill: rgba(68,135,255,0.341);
	}
	.petpaw_12_mobRectangle_21 {
		-webkit-filter: drop-shadow(0px 10px 10px rgba(68, 135, 255, 1));
		        filter: drop-shadow(0px 10px 10px rgba(68, 135, 255, 1));
		position: absolute;
		overflow: visible;
		width: 198px;
		height: 83px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobRectangle_1 {
		fill: rgba(68,135,255,1);
	}
	.petpaw_12_mobRectangle_1 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(44, 29, 173, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(44, 29, 173, 0.161));
		position: absolute;
		overflow: visible;
		width: 186px;
		height: 71px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobExplore_More {
		left: 38px;
		top: 17px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(248,248,248,1);
	}
	#petpaw_12_mobGroup_95 {
		position: absolute;
		width: 55px;
		height: 54px;
		left: 627.123px;
		top: 180.9px;
		overflow: visible;
	}
	#petpaw_12_mobAnime_Edit {
		position: absolute;
		width: 55px;
		height: 54px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobSHOP_NAME {
		left: 627.123px;
		top: 301.461px;
		position: absolute;
		overflow: visible;
		width: 210px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(255,255,255,1);
	}
	#petpaw_12_mobGroup_75 {
		position: absolute;
		width: 2195.199px;
		height: 1167.126px;
		left: 0px;
		top: 487.635px;
		overflow: visible;
	}
	#petpaw_12_mobPath_251 {
		fill: rgba(68,135,255,1);
	}
	.petpaw_12_mobPath_251 {
		overflow: visible;
		position: absolute;
		width: 2195.199px;
		height: 1167.125px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobMask_Group_6 {
		opacity: 0.2;
		mix-blend-mode: multiply;
		position: absolute;
		width: 2195.199px;
		height: 1167.127px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobMask_Group_5 {
		opacity: 0.15;
		mix-blend-mode: multiply;
		position: absolute;
		width: 2195.199px;
		height: 1167.127px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14899 {
		position: absolute;
		width: 421px;
		height: 661.631px;
		left: 0px;
		top: 863.926px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_43 {
		position: absolute;
		width: 317.079px;
		height: 315.896px;
		left: 25.961px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_42 {
		position: absolute;
		width: 317.079px;
		height: 315.896px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_38 {
		position: absolute;
		width: 147.3px;
		height: 147.3px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobRectangle_15 {
		fill: rgba(255,255,255,1);
	}
	.petpaw_12_mobRectangle_15 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 165.3px;
		height: 165.299px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobGroup_37 {
		position: absolute;
		width: 47.325px;
		height: 87.579px;
		left: 50.039px;
		top: 29.578px;
		overflow: visible;
	}
	#petpaw_12_mobVision {
		left: 8px;
		top: 71.58px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(112,112,112,1);
	}
	#petpaw_12_mobdog {
		opacity: 0.3;
		position: absolute;
		width: 47.325px;
		height: 47.336px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobPath_205 {
		fill: rgba(0,34,92,1);
	}
	.petpaw_12_mobPath_205 {
		overflow: visible;
		position: absolute;
		width: 1.966px;
		height: 1.967px;
		left: 25.409px;
		top: 16.6px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobPath_206 {
		fill: rgba(0,34,92,1);
	}
	.petpaw_12_mobPath_206 {
		overflow: visible;
		position: absolute;
		width: 1.966px;
		height: 1.967px;
		left: 7.262px;
		top: 10.098px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobPath_207 {
		fill: rgba(0,34,92,1);
	}
	.petpaw_12_mobPath_207 {
		overflow: visible;
		position: absolute;
		width: 47.325px;
		height: 47.336px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobGroup_40 {
		position: absolute;
		width: 147.3px;
		height: 147.3px;
		left: 86.664px;
		top: 168.596px;
		overflow: visible;
	}
	#petpaw_12_mobRectangle_15_dt {
		fill: rgba(255,255,255,1);
	}
	.petpaw_12_mobRectangle_15_dt {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 165.3px;
		height: 165.299px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobGroup_37_du {
		position: absolute;
		width: 60.931px;
		height: 94.087px;
		left: 43.185px;
		top: 23.072px;
		overflow: visible;
	}
	#petpaw_12_mobMask_Group_2 {
		position: absolute;
		width: 60.931px;
		height: 60.932px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobPrinciples {
		left: 3.19px;
		top: 78.086px;
		position: absolute;
		overflow: visible;
		width: 51px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(112,112,112,1);
	}
	#petpaw_12_mobGroup_65 {
		position: absolute;
		width: 47.325px;
		height: 47.323px;
		left: 47.375px;
		top: 30.762px;
		overflow: visible;
	}
	#petpaw_12_mobpet-house {
		opacity: 0.3;
		mix-blend-mode: normal;
		position: absolute;
		width: 47.325px;
		height: 47.323px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_60 {
		position: absolute;
		width: 47.325px;
		height: 47.323px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_59 {
		position: absolute;
		width: 47.325px;
		height: 47.323px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobPath_221 {
		fill: rgba(0,34,92,1);
	}
	.petpaw_12_mobPath_221 {
		overflow: visible;
		position: absolute;
		width: 47.325px;
		height: 47.324px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobGroup_62 {
		position: absolute;
		width: 2.773px;
		height: 2.773px;
		left: 22.276px;
		top: 16.637px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_61 {
		position: absolute;
		width: 2.773px;
		height: 2.773px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobEllipse_15 {
		fill: rgba(0,34,92,1);
	}
	.petpaw_12_mobEllipse_15 {
		position: absolute;
		overflow: visible;
		width: 2.773px;
		height: 2.773px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobGroup_39 {
		position: absolute;
		width: 147.3px;
		height: 147.3px;
		left: 169.779px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobRectangle_15_d {
		fill: rgba(255,255,255,1);
	}
	.petpaw_12_mobRectangle_15_d {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 165.3px;
		height: 165.299px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobGroup_37_d {
		position: absolute;
		width: 41.016px;
		height: 87.579px;
		left: 51.26px;
		top: 29.578px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_63 {
		opacity: 0.3;
		mix-blend-mode: normal;
		position: absolute;
		width: 41.016px;
		height: 47.325px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobkitten {
		position: absolute;
		width: 41.016px;
		height: 47.325px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_58 {
		position: absolute;
		width: 41.016px;
		height: 47.325px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobPath_208 {
		fill: rgba(0,34,92,1);
	}
	.petpaw_12_mobPath_208 {
		overflow: visible;
		position: absolute;
		width: 41.017px;
		height: 47.324px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobEllipse_12 {
		fill: rgba(0,34,92,1);
	}
	.petpaw_12_mobEllipse_12 {
		position: absolute;
		overflow: visible;
		width: 1.578px;
		height: 1.578px;
		left: 7.099px;
		top: 6.309px;
	}
	#petpaw_12_mobEllipse_13 {
		fill: rgba(0,34,92,1);
	}
	.petpaw_12_mobEllipse_13 {
		position: absolute;
		overflow: visible;
		width: 1.578px;
		height: 1.578px;
		left: 11.043px;
		top: 6.309px;
	}
	#petpaw_12_mobMission {
		left: 1px;
		top: 71.58px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(112,112,112,1);
	}
	#petpaw_12_mobVision_ef {
		left: 141.124px;
		top: 367.014px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(255,103,82,1);
	}
	#petpaw_12_mobScroll_Group_1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 421px;
		height: 239px;
		left: 0px;
		top: 422.631px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#petpaw_12_mobScroll_Group_1::-webkit-scrollbar{
		display: none;
	}
	#petpaw_12_mobn_I_like_that_I_got_to_meet_th {
		opacity: 0.8;
		left: 18px;
		top: 17px;
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 222px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#petpaw_12_mobGroup_101 {
		position: absolute;
		width: 352.5px;
		height: 870.305px;
		left: 12.5px;
		top: 1546px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_100 {
		position: absolute;
		width: 352.5px;
		height: 791.609px;
		left: 0px;
		top: 78.697px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_99 {
		-webkit-transform: translate(-386.658px, 226.736px) matrix(1,0,0,1,163.8547,146.5673) rotate(-90deg);
		        transform: translate(-386.658px, 226.736px) matrix(1,0,0,1,163.8547,146.5673) rotate(-90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		position: absolute;
		width: 791.609px;
		height: 45px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_93 {
		position: absolute;
		width: 45px;
		height: 45px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobEllipse_10 {
		fill: rgba(255,103,82,1);
	}
	.petpaw_12_mobEllipse_10 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(253, 104, 69, 0.302));
		        filter: drop-shadow(0px 3px 6px rgba(253, 104, 69, 0.302));
		position: absolute;
		overflow: visible;
		width: 63px;
		height: 63px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobGroup_46 {
		position: absolute;
		width: 20.479px;
		height: 9.239px;
		left: 17.752px;
		top: 12.436px;
		overflow: visible;
	}
	#petpaw_12_mobPath_201 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaw_12_mobPath_201 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 22.479px;
		left: 4.479px;
		top: -0.002px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobPath_202 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaw_12_mobPath_202 {
		overflow: visible;
		position: absolute;
		width: 12.067px;
		height: 7.033px;
		left: 0px;
		top: -0.002px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobGroup_94 {
		position: absolute;
		width: 45px;
		height: 45px;
		left: 0px;
		top: 746.609px;
		overflow: visible;
	}
	#petpaw_12_mobEllipse_10_er {
		fill: rgba(255,103,82,1);
	}
	.petpaw_12_mobEllipse_10_er {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(253, 104, 69, 0.302));
		        filter: drop-shadow(0px 3px 6px rgba(253, 104, 69, 0.302));
		position: absolute;
		overflow: visible;
		width: 63px;
		height: 63px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobGroup_46_es {
		position: absolute;
		width: 20.479px;
		height: 9.239px;
		left: 17.752px;
		top: 11.621px;
		overflow: visible;
	}
	#petpaw_12_mobPath_201_et {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaw_12_mobPath_201_et {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 22.479px;
		left: 4.479px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobPath_202_eu {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaw_12_mobPath_202_eu {
		overflow: visible;
		position: absolute;
		width: 12.067px;
		height: 7.033px;
		left: 0px;
		top: 15.859px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobGroup_14915 {
		position: absolute;
		width: 352.5px;
		height: 661.001px;
		left: 0px;
		top: 63.303px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14904 {
		position: absolute;
		width: 351.5px;
		height: 207.001px;
		left: 1px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobRectangle_22 {
		-webkit-filter: blur(15px);
		        filter: blur(15px);
		fill: rgba(168,158,255,1);
	}
	.petpaw_12_mobRectangle_22 {
		-webkit-filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		        filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		position: absolute;
		overflow: visible;
		width: 486px;
		height: 347px;
		left: 7px;
		top: 5px;
	}
	#petpaw_12_mobRectangle_15_ey {
		fill: rgba(253,104,69,1);
	}
	.petpaw_12_mobRectangle_15_ey {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		        filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		position: absolute;
		overflow: visible;
		width: 369px;
		height: 225px;
		left: 0.5px;
		top: 0px;
	}
	#petpaw_12_mobMask_Group_7 {
		opacity: 0.1;
		mix-blend-mode: multiply;
		position: absolute;
		width: 351px;
		height: 207px;
		left: 0px;
		top: -0.002px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14902 {
		position: absolute;
		width: 351px;
		height: 207px;
		left: 0px;
		top: -0.002px;
		overflow: visible;
	}
	#petpaw_12_mobMask_Group_8 {
		opacity: 0.15;
		mix-blend-mode: multiply;
		position: absolute;
		width: 351px;
		height: 207px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_47 {
		position: absolute;
		width: 337px;
		height: 192px;
		left: 9.5px;
		top: 10.002px;
		overflow: visible;
	}
	#petpaw_12_mobKenned_Dsouza {
		left: 122px;
		top: 73.596px;
		position: absolute;
		overflow: visible;
		width: 89px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(248,248,248,1);
	}
	#petpaw_12_mobScroll_Group_31 {
		mix-blend-mode: normal;
		position: absolute;
		width: 337px;
		height: 93px;
		left: 0px;
		top: 99px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#petpaw_12_mobScroll_Group_31::-webkit-scrollbar{
		display: none;
	}
	#petpaw_12_mobn_I_like_that_I_got_to_meet_th_e {
		opacity: 0.8;
		left: 11px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 309px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(248,248,248,1);
	}
	#petpaw_12_mobdownload_2 {
		position: absolute;
		width: 271px;
		height: 64px;
		left: 31px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14907 {
		position: absolute;
		width: 350.5px;
		height: 207.001px;
		left: 0px;
		top: 454px;
		overflow: visible;
	}
	#petpaw_12_mobRectangle_22_e {
		-webkit-filter: blur(15px);
		        filter: blur(15px);
		fill: rgba(168,158,255,1);
	}
	.petpaw_12_mobRectangle_22_e {
		-webkit-filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		        filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		position: absolute;
		overflow: visible;
		width: 486px;
		height: 347px;
		left: 7px;
		top: 5px;
	}
	#petpaw_12_mobRectangle_15_e {
		fill: rgba(253,104,69,1);
	}
	.petpaw_12_mobRectangle_15_e {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		        filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		position: absolute;
		overflow: visible;
		width: 368px;
		height: 225px;
		left: 0.5px;
		top: 0px;
	}
	#petpaw_12_mobMask_Group_7_fa {
		opacity: 0.1;
		mix-blend-mode: multiply;
		position: absolute;
		width: 350px;
		height: 207px;
		left: 0.5px;
		top: -0.002px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14903 {
		position: absolute;
		width: 350px;
		height: 207px;
		left: 0px;
		top: -0.002px;
		overflow: visible;
	}
	#petpaw_12_mobMask_Group_8_fc {
		opacity: 0.15;
		mix-blend-mode: multiply;
		position: absolute;
		width: 350px;
		height: 207px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_47_fd {
		position: absolute;
		width: 325px;
		height: 187px;
		left: 6px;
		top: 10.002px;
		overflow: visible;
	}
	#petpaw_12_mobKenned_Dsouza_fe {
		left: 122.5px;
		top: 73.424px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(248,248,248,1);
	}
	#petpaw_12_mobScroll_Group_34 {
		mix-blend-mode: normal;
		position: absolute;
		width: 325px;
		height: 96px;
		left: 0px;
		top: 91px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#petpaw_12_mobScroll_Group_34::-webkit-scrollbar{
		display: none
	}
	#petpaw_12_mobn_I_like_that_I_got_to_meet_th_fg {
		opacity: 0.8;
		left: 15px;
		top: 16px;
		position: absolute;
		overflow: visible;
		width: 295px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(248,248,248,1);
	}
	#petpaw_12_mobdownload_2_fh {
		position: absolute;
		width: 271px;
		height: 64px;
		left: 34.5px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14906 {
		position: absolute;
		width: 350px;
		height: 207px;
		left: 0px;
		top: 227px;
		overflow: visible;
	}
	#petpaw_12_mobRectangle_22_fj {
		-webkit-filter: blur(15px);
		        filter: blur(15px);
		fill: rgba(168,158,255,1);
	}
	.petpaw_12_mobRectangle_22_fj {
		-webkit-filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		        filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		position: absolute;
		overflow: visible;
		width: 484px;
		height: 347px;
		left: 8px;
		top: 5px;
	}
	#petpaw_12_mobMask_Group_7_fk {
		opacity: 0.1;
		mix-blend-mode: multiply;
		position: absolute;
		width: 350px;
		height: 207px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14905 {
		position: absolute;
		width: 350px;
		height: 207px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobMask_Group_8_fm {
		opacity: 0.15;
		mix-blend-mode: multiply;
		position: absolute;
		width: 350px;
		height: 207px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14901 {
		position: absolute;
		width: 350px;
		height: 207px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobRectangle_15_fo {
		fill: rgba(68,135,255,1);
	}
	.petpaw_12_mobRectangle_15_fo {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		        filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		position: absolute;
		overflow: visible;
		width: 368px;
		height: 225px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobGroup_47_fp {
		position: absolute;
		width: 341px;
		height: 118.811px;
		left: 3px;
		top: 83.189px;
		overflow: visible;
	}
	#petpaw_12_mobKenned_Dsouza_fq {
		left: 116.519px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(248,248,248,1);
	}
	#petpaw_12_mobScroll_Group_32 {
		mix-blend-mode: normal;
		position: absolute;
		width: 341px;
		height: 92px;
		left: 0px;
		top: 26.811px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#petpaw_12_mobScroll_Group_32::-webkit-scrollbar{
		display: none;
	}
	#petpaw_12_mobn_I_like_that_I_got_to_meet_th_fs {
		opacity: 0.8;
		left: 18px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 309px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(248,248,248,1);
	}
	#petpaw_12_mobdownload_2_ft {
		position: absolute;
		width: 271px;
		height: 64px;
		left: 35px;
		top: 13px;
		overflow: visible;
	}
	#petpaw_12_mobHeres_what_We_Provides {
		left: 24px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 303px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(255,103,82,1);
	}
	#petpaw_12_mobScroll_Group_33 {
		mix-blend-mode: normal;
		position: absolute;
		width: 370px;
		height: 55px;
		left: 0px;
		top: 2635px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#petpaw_12_mobScroll_Group_33::-webkit-scrollbar{
		display: none;
	}
	#petpaw_12_mobpartner_grp_website_6_pc {
		position: absolute;
		width: 222px;
		height: 30px;
		left: 14px;
		top: 9px;
		overflow: visible;
	}
	#petpaw_12_mobAnime_Edit_img_website_6_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobMohammed_Affan_lbl_website_6_p {
		left: 35px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 188px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,0.89);
	}
	#petpaw_12_mobAmazing_Partner {
		left: 121.111px;
		top: 2580.4px;
		position: absolute;
		overflow: visible;
		width: 135px;
		white-space: nowrap;
		text-align: center;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(177,187,205,1);
	}
	#petpaw_12_mobGroup_14909 {
		position: absolute;
		width: 2323.922px;
		height: 1100.994px;
		left: -951.461px;
		top: 2771.1px;
		overflow: visible;
	}
	#petpaw_12_mobEllipse_19 {
		fill: rgba(255,103,82,1);
	}
	.petpaw_12_mobEllipse_19 {
		position: absolute;
		overflow: visible;
		width: 2323.922px;
		height: 1100.994px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobGroup_14908 {
		position: absolute;
		width: 479px;
		height: 323px;
		left: 967.461px;
		top: 303.9px;
		overflow: visible;
	}
	#petpaw_12_mobn_234567890_mobile_number_shad {
		left: 35px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#petpaw_12_mobaffanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#petpaw_12_mobaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#petpaw_12_moblocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#petpaw_12_mobemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#petpaw_12_mobphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 79px;
		top: 191px;
		overflow: visible;
	}
	#petpaw_12_moblinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.petpaw_12_mobmed_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#petpaw_12_mobmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.petpaw_12_mobmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#petpaw_12_moblinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#petpaw_12_mobn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.petpaw_12_mobn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_moblinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.petpaw_12_moblinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.petpaw_12_mobtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.petpaw_12_mobshadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#petpaw_12_mobshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.petpaw_12_mobshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#petpaw_12_mobinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#petpaw_12_mobwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobmed_shadow_space_website_7_gm:hover {
		fill: rgba(50,172,158,1);
	}
	.petpaw_12_mobmed_shadow_space_website_7_gm:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#petpaw_12_mobmed_shadow_space_website_7_gm {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.petpaw_12_mobmed_shadow_space_website_7_gm {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#petpaw_12_mobwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#petpaw_12_mobn_Copyright_Operate_Live_OPC_P {
		left: 15px;
		top: 279px;
		position: absolute;
		overflow: visible;
		width: 293px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#petpaw_12_mobRectangle_693 {
		fill: rgba(255,255,255,1);
	}
	.petpaw_12_mobRectangle_693 {
		position: absolute;
		overflow: visible;
		width: 266px;
		height: 175px;
		left: 1006.461px;
		top: 65.9px;
		border-radius: 10px;
	}
	#petpaw_12_mobn_a21e555c63e24831805521512170 {
		position: absolute;
		width: 85px;
		height: 98px;
		left: 967.461px;
		top: 191.9px;
		overflow: visible;
	}
	#petpaw_12_mobVisit_Us_ {
		left: 1091.461px;
		top: 27.9px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -3px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	.mediaViewInfo {
		--web-view-name: Chefs Kitchen Mob;
		--web-view-id: Chefs_Kitchen_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Chefs_Kitchen_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Chefs_Kitchen_Mob {
		position: absolute;
		width: 390px;
		height: 5048px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Chefs Kitchen Mob;
		--web-view-id: Chefs_Kitchen_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#chefs_13_moblcz9nxhoslo-brooke-lark {
		opacity: 0.2;
		position: absolute;
		width: 2500px;
		height: 1871px;
		left: -1124px;
		top: 312px;
		overflow: visible;
	}
	#chefs_13_mobGroup_14943 {
		position: absolute;
		width: 308px;
		height: 656px;
		left: 41px;
		top: 471px;
		overflow: visible;
	}
	#chefs_13_moblidye-1Shk_PkNkNw-unsplash {
		position: absolute;
		width: 268px;
		height: 373px;
		left: 20px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobFood_unites_cultures_evokes__m {
		left: 0px;
		top: 441px;
		position: absolute;
		overflow: visible;
		width: 309px;
		white-space: nowrap;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_mobGroup_14929 {
		position: absolute;
		width: 204px;
		height: 48px;
		left: 52px;
		top: 608px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_89 {
		fill: rgba(249,129,105,1);
	}
	.chefs_13_mobRectangle_89 {
		-webkit-filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		        filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 291px;
		height: 135px;
		left: 0px;
		top: 0px;
	}
	#chefs_13_mobKnow_More_About_Us {
		left: 28px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 153px;
		white-space: nowrap;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobGroup_14944 {
		position: absolute;
		width: 330.323px;
		height: 569.547px;
		left: 29.838px;
		top: 1247.453px;
		overflow: visible;
	}
	#chefs_13_mobGroup_14931 {
		position: absolute;
		width: 330.323px;
		height: 308.77px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobbrooke-lark-08bOYnH_r_E-unspla {
		position: absolute;
		width: 124.812px;
		height: 101.253px;
		left: 170.927px;
		top: 150.876px;
		overflow: visible;
	}
	#chefs_13_mobo9kwrr26 {
		position: absolute;
		width: 124.812px;
		height: 103.759px;
		left: 37.593px;
		top: 66.666px;
		overflow: visible;
	}
	#chefs_13_mobjoseph-gonzalez-zcUgjyqEwe8-un {
		position: absolute;
		width: 165.914px;
		height: 133.834px;
		left: 0px;
		top: 174.937px;
		overflow: visible;
	}
	#chefs_13_mobjoseph-gonzalez-fdlZBWIP0aM-un {
		position: absolute;
		width: 164.41px;
		height: 143.357px;
		left: 165.913px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobFood_a_universal_language_a_ta {
		left: 11.162px;
		top: 376.547px;
		position: absolute;
		overflow: visible;
		width: 309px;
		height: 110px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_mobGroup_14932 {
		position: absolute;
		width: 204px;
		height: 48px;
		left: 63.162px;
		top: 521.547px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_89_cy {
		fill: rgba(249,129,105,1);
	}
	.chefs_13_mobRectangle_89_cy {
		-webkit-filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		        filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 291px;
		height: 135px;
		left: 0px;
		top: 0px;
	}
	#chefs_13_mobKnow_More_About_Us_cz {
		left: 28px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 153px;
		white-space: nowrap;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobGroup_14934 {
		position: absolute;
		width: 322px;
		height: 433.428px;
		left: 29.598px;
		top: 1922.426px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_696 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_mobRectangle_696 {
		-webkit-filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		        filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		width: 321.641px;
		height: 433.428px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-29.598px, -1922.426px) matrix(1,0,0,1,29.838,1922.4258) rotate(180deg);
		        transform: translate(-29.598px, -1922.426px) matrix(1,0,0,1,29.838,1922.4258) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#chefs_13_mobeaters-collective-172257-unspl {
		position: absolute;
		width: 321.641px;
		height: 225.039px;
		left: 0.24px;
		top: 0.001px;
		overflow: visible;
	}
	#chefs_13_mobScroll_Group_44 {
		mix-blend-mode: normal;
		position: absolute;
		width: 322px;
		height: 158px;
		left: 0px;
		top: 274.574px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#chefs_13_mobScroll_Group_44::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_mobLorem_ipsum_dolor_sit_amet_con {
		left: 20px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 269.407px;
		height: 130px;
		line-height: 22px;
		margin-top: -4px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(174,178,180,1);
	}
	#chefs_13_mobVision {
		left: 20px;
		top: 253.574px;
		position: absolute;
		overflow: visible;
		width: 221px;
		height: 21px;
		line-height: 46px;
		margin-top: -14px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_mobGroup_14933 {
		position: absolute;
		width: 322.369px;
		height: 433.574px;
		left: 34.18px;
		top: 2386.426px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_698 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_mobRectangle_698 {
		-webkit-filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		        filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		width: 321.641px;
		height: 433.428px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-8928.707px, 11605.574px) matrix(1,0,0,1,8928.7071,-11605.5742) rotate(180deg);
		        transform: translate(-8928.707px, 11605.574px) matrix(1,0,0,1,8928.7071,-11605.5742) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#chefs_13_moban_vision-1188492-unsplash {
		position: absolute;
		width: 321.641px;
		height: 225.039px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#chefs_13_mobScroll_Group_45 {
		mix-blend-mode: normal;
		position: absolute;
		width: 322px;
		height: 134px;
		left: 0.369px;
		top: 299.574px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#chefs_13_mobScroll_Group_45::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_mobLorem_ipsum_dolor_sit_amet_con_da {
		left: 19px;
		top: -6px;
		position: absolute;
		overflow: visible;
		width: 271.407px;
		height: 125px;
		line-height: 22px;
		margin-top: -4px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(174,178,180,1);
	}
	#chefs_13_mobMission {
		left: 17.821px;
		top: 253.574px;
		position: absolute;
		overflow: visible;
		width: 221px;
		height: 21px;
		line-height: 46px;
		margin-top: -14px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_mobGroup_14935 {
		position: absolute;
		width: 321.881px;
		height: 433.428px;
		left: 33.939px;
		top: 2849.854px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_697 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_mobRectangle_697 {
		-webkit-filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		        filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		width: 321.641px;
		height: 433.428px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-9308.336px, 11605.574px) matrix(1,0,0,1,9308.5762,-11605.5742) rotate(180deg);
		        transform: translate(-9308.336px, 11605.574px) matrix(1,0,0,1,9308.5762,-11605.5742) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#chefs_13_mobn_es93lms {
		position: absolute;
		width: 321.641px;
		height: 225.039px;
		left: 0.24px;
		top: 0.001px;
		overflow: visible;
	}
	#chefs_13_mobScroll_Group_46 {
		mix-blend-mode: normal;
		position: absolute;
		width: 316px;
		height: 139px;
		left: 0px;
		top: 283.574px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#chefs_13_mobScroll_Group_46::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_mobLorem_ipsum_dolor_sit_amet_con_dg {
		left: 20px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 276.407px;
		height: 130px;
		line-height: 22px;
		margin-top: -4px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(174,178,180,1);
	}
	#chefs_13_mobPrinciples {
		left: 20px;
		top: 253.574px;
		position: absolute;
		overflow: visible;
		width: 221px;
		height: 21px;
		line-height: 46px;
		margin-top: -14px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_mobgood-soul-shop-DlO0yz4U67A-uns {
		position: absolute;
		width: 1366px;
		height: 755px;
		left: 0px;
		top: 3378px;
		overflow: visible;
	}
	#chefs_13_mobExplore_Our_Foods {
		left: 13px;
		top: 3405px;
		position: absolute;
		overflow: visible;
		width: 365px;
		height: 41px;
		line-height: 46px;
		margin-top: -5px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(249,92,61,1);
	}
	#chefs_13_mobScroll_Group_41 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 604px;
		left: 13px;
		top: 3483px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#chefs_13_mobScroll_Group_41::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_mobGroup_14920 {
		position: relative;
		width: 307px;
		height: 307px;
		left: 29px;
		top: 37px;
		overflow: visible;
		-webkit-animation: spin 2s linear infinite;
  	animation: spin 5s linear infinite;
	}
	@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
	#chefs_13_mobGroup_14941 {
		position: absolute;
		width: 307px;
		height: 307px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobkisspng-plate-circle-platter-t {
		position: absolute;
		width: 307px;
		height: 307px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobanh-nguyen-kcA-c3f_3FE-unsplas {
		position: absolute;
		width: 205px;
		height: 205px;
		left: 51px;
		top: 47px;
		border-radius: 50%;
		overflow: hidden;
	}
	#chefs_13_mobSalad {
		left: 56px;
		top: 25px;
		position: relative;
		overflow: visible;
		width: 255px;
		height: 28px;
		line-height: 46px;
		margin-top: -10.5px;
		text-align: center;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_mobGroup_14936 {
		position: absolute;
		width: 255px;
		height: 70px;
		left: 68px;
		top: 4102px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_107 {
		fill: rgba(249,129,105,1);
	}
	.chefs_13_mobRectangle_107 {
		-webkit-filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		        filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 342px;
		height: 157px;
		left: 0px;
		top: 0px;
	}
	#chefs_13_mobView_More_-_ {
		left: 68px;
		top: 25px;
		position: absolute;
		overflow: visible;
		width: 120px;
		white-space: nowrap;
		line-height: 71px;
		margin-top: -26.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobGroup_14945 {
		position: absolute;
		width: 403px;
		height: 837px;
		left: 0px;
		top: 4335px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_678 {
		opacity: 0.93;
		fill: rgba(68,55,53,1);
	}
	.chefs_13_mobRectangle_678 {
		position: absolute;
		overflow: visible;
		width: 403px;
		height: 837px;
		left: 0px;
		top: 0px;
	}
	#chefs_13_mobFor_Queries_infoandroomedanet {
		left: 91.764px;
		top: 383px;
		position: absolute;
		overflow: visible;
		width: 219px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobFor_call__920012888 {
		left: 91.764px;
		top: 404px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobtelephone {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_mobtelephone {
		overflow: visible;
		position: absolute;
		width: 11.381px;
		height: 10.521px;
		left: 65.237px;
		top: 408.525px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_mobsend_1 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_mobsend_1 {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 65.237px;
		top: 387.719px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_mobsocials_grp_med_web_4_mob {
		position: absolute;
		width: 210px;
		height: 50.001px;
		left: 90px;
		top: 512.999px;
		overflow: visible;
	}
	#chefs_13_moblinked_in_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 160px;
		top: 0.001px;
		overflow: visible;
	}
	#chefs_13_mobEllipse_5_med_web_4_pc:hover {
		fill: rgba(120,120,255,1);
	}
	.chefs_13_mobEllipse_5_med_web_4_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#chefs_13_mobEllipse_5_med_web_4_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.chefs_13_mobEllipse_5_med_web_4_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#chefs_13_moblinked_in_website_4_layout {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#chefs_13_mobn_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.chefs_13_mobn_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.953px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_moblinked_in_i_down {
		fill: rgba(63,61,86,1);
	}
	.chefs_13_moblinked_in_i_down {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.098px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_mobtop_dot_i_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.chefs_13_mobtop_dot_i_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_mobInsta_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 80px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobEllipse_4_med_web_4_pc:hover {
		fill: rgba(255,120,120,1);
	}
	.chefs_13_mobEllipse_4_med_web_4_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#chefs_13_mobEllipse_4_med_web_4_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.chefs_13_mobEllipse_4_med_web_4_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#chefs_13_mobinstagram_website_4_layout {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.126px;
		overflow: visible;
	}
	#chefs_13_mobwhatsapp_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#chefs_13_mobEllipse_3_med_web_4_pc:hover {
		fill: rgba(50,172,158,1);
	}
	.chefs_13_mobEllipse_3_med_web_4_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#chefs_13_mobEllipse_3_med_web_4_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.chefs_13_mobEllipse_3_med_web_4_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#chefs_13_mobwhatsapp_lbl_website4_layout {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#chefs_13_mobIcon_metro-location {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_mobIcon_metro-location {
		overflow: visible;
		position: absolute;
		width: 11.381px;
		height: 18.209px;
		left: 65.236px;
		top: 429.33px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_mobFor_call__920012888_eg {
		left: 91.764px;
		top: 428.539px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 62px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobn_Copyright_Operate_Live_websi {
		left: 29px;
		top: 630.461px;
		position: absolute;
		overflow: visible;
		width: 318px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobGroup_14926 {
		position: absolute;
		width: 343px;
		height: 264.312px;
		left: 23.5px;
		top: 51.735px;
		overflow: visible;
	}
	#chefs_13_mobkisspng-tray-glass-plastic-pla {
		position: absolute;
		width: 343px;
		height: 264.312px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_701 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_mobRectangle_701 {
		position: absolute;
		overflow: visible;
		width: 267px;
		height: 172px;
		left: 38.5px;
		top: 46.265px;
	}
	#chefs_13_mobn_Copyright_Operate_website_9_ {
		left: 143.5px;
		top: 12.352px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobGroup_14942 {
		position: absolute;
		width: 429px;
		height: 399px;
		left: -11.5px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobGroup_14927 {
		position: absolute;
		width: 429px;
		height: 398.5px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobstyle_3 {
		position: absolute;
		width: 429px;
		height: 278px;
		left: 0px;
		top: 120.5px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_695_eq {
		fill: url(#chefs_13_mobRectangle_695_eq);
	}
	.chefs_13_mobRectangle_695_eq {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 228px;
		left: 11.5px;
		top: 0px;
	}
	#chefs_13_mobRectangle_84 {
		fill: rgba(249,129,105,1);
	}
	.chefs_13_mobRectangle_84 {
		-webkit-filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		        filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 107px;
		left: 39.5px;
		top: 185px;
	}
	#chefs_13_mobGood_food_choices_are_good_inv {
		left: 39.5px;
		top: 55px;
		position: absolute;
		overflow: visible;
		width: 294px;
		height: 50px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: lighter;
		font-size: 20px;
		color: rgba(249,249,249,0.886);
	}
	#chefs_13_mobExplore_Now_ {
		left: 51.5px;
		top: 192px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		line-height: 20px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 10px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobScroll_Group_43 {
		mix-blend-mode: normal;
		position: absolute;
		width: 359px;
		height: 58px;
		left: 32.5px;
		top: 109px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#chefs_13_mobScroll_Group_43::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_mobThere_is_a_powerful_need_for_s {
		opacity: 0.71;
		left: 7px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 48px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(249,249,249,0.886);
	}
	#chefs_13_mobzane-lee-bXbi1jJVDf0-unsplash_ {
		position: absolute;
		width: 21px;
		height: 21px;
		left: 39.5px;
		top: 12px;
		overflow: hidden;
		border-radius: 50%;
	}
	#chefs_13_mobOperate_Live {
		left: 68.5px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 85px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobRectangle_700 {
		opacity: 0.28;
		fill: rgba(0,0,0,1);
	}
	.chefs_13_mobRectangle_700 {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 177px;
		left: 11.5px;
		top: 222px;
	}
	.n_14_webmobmediaViewInfo {
		--web-view-name: FeatheredScapeMob;
		--web-view-id: FeatheredScapeMob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: FeatheredScapeMob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#FeatheredScapeMob {
		position: absolute;
		width: 390px;
		height: 3603px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: FeatheredScapeMob;
		--web-view-id: FeatheredScapeMob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#n_14_webmobGroup_14927 {
		position: absolute;
		width: 417px;
		height: 261px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_14_webmobstyle_3 {
		position: absolute;
		width: 201px;
		height: 261px;
		left: 216px;
		top: 0px;
		overflow: visible;
	}
	#n_14_webmobRectangle_84 {
		fill: rgba(249,129,105,1);
	}
	.n_14_webmobRectangle_84 {
		-webkit-filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		        filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 154px;
		height: 110px;
		left: 15px;
		top: 220px;
	}
	#n_14_webmobDiscover_a_new_world_of_birdin {
		left: 15px;
		top: 55px;
		position: absolute;
		overflow: visible;
		width: 200px;
		height: 50px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: lighter;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#n_14_webmobExplore_Now_ {
		left: 29px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 40px;
		white-space: nowrap;
		line-height: 24px;
		margin-top: -9px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 6px;
		color: rgba(255,255,255,1);
	}
	#n_14_webmobScroll_Group_42 {
		mix-blend-mode: normal;
		position: absolute;
		width: 201px;
		height: 59px;
		left: 0px;
		top: 129px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n_14_webmobintro_part {
		opacity: 0.71;
		left: 5px;
		top: 2px;
		position: absolute;
		overflow: visible;
		width: 202px;
		height: 113px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#n_14_webmobzane-lee-bXbi1jJVDf0-unsplash_ {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 17px;
		top: 13px;
		border-radius: 50%;
		overflow: hidden;
	}
	#n_14_webmobOperate_Live {
		left: 45px;
		top: 19px;
		position: absolute;
		overflow: visible;
		width: 51px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#n_14_webmobGroup_14943 {
		position: absolute;
		width: 320px;
		height: 656px;
		left: 41px;
		top: 377px;
		overflow: visible;
	}
	#n_14_webmobmark-olsen-tjZPseTxe6k-unsplas {
		position: absolute;
		width: 268px;
		height: 373px;
		left: 20px;
		top: 0px;
		overflow: visible;
	}
	#n_14_webmobEmbark_on_an_Avian_Adventure_E {
		left: 0px;
		top: 441px;
		position: absolute;
		overflow: visible;
		width: 321px;
		height: 110px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(12,54,83,1);
	}
	#n_14_webmobGroup_14929 {
		position: absolute;
		width: 204px;
		height: 48px;
		left: 52px;
		top: 608px;
		overflow: visible;
	}
	#n_14_webmobRectangle_89 {
		fill: rgba(249,129,105,1);
	}
	.n_14_webmobRectangle_89 {
		-webkit-filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		        filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 291px;
		height: 135px;
		left: 0px;
		top: 0px;
	}
	#n_14_webmobKnow_More_About_Us {
		left: 28px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 153px;
		white-space: nowrap;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#n_14_webmobGroup_14953 {
		position: absolute;
		width: 273px;
		height: 1055px;
		left: 72px;
		top: 1179px;
		overflow: visible;
	}
	#n_14_webmobCard_01_website_3 {
		position: absolute;
		width: 234px;
		height: 256px;
		left: 20px;
		top: 0px;
		overflow: visible;
	}
	#n_14_webmobScroll_Group_10 {
		mix-blend-mode: normal;
		position: absolute;
		width: 234px;
		height: 120px;
		left: 0px;
		top: 136px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n_14_webmobintro_website_3 {
		left: -13px;
		top: -4px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 77px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#n_14_webmobVision_website_3 {
		left: 88px;
		top: 83px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#n_14_webmobaarn-giri-3lGi0BXJ1W0-unsplash {
		position: absolute;
		width: 234px;
		height: 73px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_14_webmobCard_02_website_3 {
		position: absolute;
		width: 260px;
		height: 256px;
		left: 7px;
		top: 349px;
		overflow: visible;
	}
	#n_14_webmobScroll_Group_11 {
		mix-blend-mode: normal;
		position: absolute;
		width: 260px;
		height: 120px;
		left: 0px;
		top: 136px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n_14_webmobintro_2_website_3 {
		left: 0px;
		top: -4px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 73px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#n_14_webmobmission_lbl_website_3 {
		left: 93px;
		top: 83px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#n_14_webmobzdenek-machacek-cfIcTiopin4-un {
		position: absolute;
		width: 234px;
		height: 73px;
		left: 13px;
		top: 0px;
		overflow: visible;
	}
	#n_14_webmobCard_03_website_3 {
		position: absolute;
		width: 273px;
		height: 276px;
		left: 0px;
		top: 779px;
		overflow: visible;
	}
	#n_14_webmobScroll_Group_12 {
		mix-blend-mode: normal;
		position: absolute;
		width: 273px;
		height: 120px;
		left: 0px;
		top: 156px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n_14_webmobintro_lbl_website_3 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 73px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#n_14_webmobprinciples_lbl_website_3 {
		left: 84.5px;
		top: 107px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#n_14_webmobIcon_top_website_3 {
		fill: rgba(255,255,255,1);
	}
	.n_14_webmobIcon_top_website_3 {
		overflow: visible;
		position: absolute;
		width: 18.924px;
		height: 3.37px;
		left: 121.029px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_14_webmobkevin-mueller-aeNg4YA41P8-unsp {
		position: absolute;
		width: 234px;
		height: 73px;
		left: 20px;
		top: 24px;
		overflow: visible;
	}
	#n_14_webmobOur_Collection {
		left: 72px;
		top: 2327px;
		position: absolute;
		overflow: visible;
		width: 248px;
		white-space: nowrap;
		line-height: 46px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(12,54,83,1);
	}
	#n_14_webmobScroll_Group_47 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 384px;
		left: 0px;
		top: 2391px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#n_14_webmobGroup_14950 {
		position: relative;
		width: 231px;
		height: 317px;
		left: 30px;
		margin-right: 30px;
		top: 19px;
		overflow: visible;
	}
	#n_14_webmobRectangle_703 {
		fill: transparent;
	}
	.n_14_webmobRectangle_703 {
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 317px;
		left: 0px;
		top: 0px;
		background:  rgba( 255, 255, 255, 0.05 );
		box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
		backdrop-filter:  blur( 4px );
		-webkit-backdrop-filter:  blur( 4px );
		border-radius:  10px;
		border:  1px solid rgba( 255, 255, 255, 0.18 );
	}
	#n_14_webmobkevin-mueller-aeNg4YA41P8-unsp_ds {
		position: absolute;
		width: 210px;
		height: 253px;
		left: 10px;
		top: 54px;
		overflow: visible;
	}
	#n_14_webmobParrot {
		left: 17px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#n_14_webmobfooter_bg_web_9_pc {
		opacity: 0.93;
		fill: rgba(50,50,50,1);
	}
	.n_14_webmobfooter_bg_web_9_pc {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 774px;
		left: 0px;
		top: 2836px;
	}
	#n_14_webmobGroup_14951 {
		position: absolute;
		width: 374.661px;
		height: 405.895px;
		left: 0px;
		top: 2868px;
		overflow: visible;
	}
	#n_14_webmobMy_project {
		position: absolute;
		width: 374.661px;
		height: 405.895px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_14_webmobdisplay_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.n_14_webmobdisplay_web_9_pc {
		position: absolute;
		overflow: visible;
		width: 285px;
		height: 150px;
		left: 60px;
		top: 237.494px;
	}
	#n_14_webmobVISIT_US {
		left: 227.33px;
    top: 168.494px;
    position: absolute;
    overflow: visible;
    width: 155px;
    white-space: nowrap;
    line-height: 46px;
    margin-top: -6.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    color: rgba(255,255,255,1);
	}
	#n_14_webmobFor_Queries_infoandroom_web_9_ {
		left: 68.526px;
		top: 3308px;
		position: absolute;
		overflow: visible;
		width: 219px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#n_14_webmobFor_call__920012888_web_9_pc {
		left: 68.526px;
		top: 3329px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#n_14_webmobtelephone_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.n_14_webmobtelephone_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 11.381px;
		height: 10.522px;
		left: 42px;
		top: 3333.525px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_14_webmobsend_1_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.n_14_webmobsend_1_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 42px;
		top: 3312.72px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_14_webmobIcon_metro-locatio_web_9_pc_n {
		fill: rgba(255,255,255,1);
	}
	.n_14_webmobIcon_metro-locatio_web_9_pc_n {
		overflow: visible;
		position: absolute;
		width: 11.381px;
		height: 18.208px;
		left: 41.999px;
		top: 3354.331px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_14_webmobaddress_lbl_web_9_pc {
		left: 68.526px;
		top: 3353.539px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 62px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#n_14_webmobGroup_14952 {
		position: absolute;
		width: 210px;
		height: 50.001px;
		left: 90px;
		top: 3425.999px;
		overflow: visible;
	}
	#n_14_webmoblinked_in_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 160px;
		top: 0.001px;
		overflow: visible;
	}
	#n_14_webmobEllipse_5_med_web_9_pc:hover {
		fill: rgba(120,120,255,1);
	}
	.n_14_webmobEllipse_5_med_web_9_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n_14_webmobEllipse_5_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n_14_webmobEllipse_5_med_web_9_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n_14_webmoblinked_in_website_9_layout {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#n_14_webmobn_website_2_layout_web_9_pc {
		fill: rgba(63,61,86,1);
	}
	.n_14_webmobn_website_2_layout_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.954px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_14_webmoblinked_in_i_down_web_9_pc {
		fill: rgba(63,61,86,1);
	}
	.n_14_webmoblinked_in_i_down_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.097px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_14_webmobtop_dot_i_website_9_layout {
		fill: rgba(63,61,86,1);
	}
	.n_14_webmobtop_dot_i_website_9_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_14_webmobInsta_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 80px;
		top: 0px;
		overflow: visible;
	}
	#n_14_webmobEllipse_4_med_web_9_pc:hover {
		fill: rgba(255,120,120,1);
	}
	.n_14_webmobEllipse_4_med_web_9_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n_14_webmobEllipse_4_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n_14_webmobEllipse_4_med_web_9_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n_14_webmobinstagram_website_9_layout {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.126px;
		overflow: visible;
	}
	#n_14_webmobwhatsapp_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#n_14_webmobEllipse_3_med_web_9_pc:hover {
		fill: rgba(50,172,158,1);
	}
	.n_14_webmobEllipse_3_med_web_9_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n_14_webmobEllipse_3_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n_14_webmobEllipse_3_med_web_9_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n_14_webmobwhatsapp_lbl_website9_layout {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#n_14_webmobn_Copyright_Operate_website_9_ {
		left: 34.5px;
		top: 3529.46px;
		position: absolute;
		overflow: visible;
		width: 322px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	.mediaViewInfo {
		--web-view-name: FurniGem mob;
		--web-view-id: FurniGem_mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: FurniGem_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#FurniGem_mob {
		position: absolute;
		width: 390px;
		height: 4290px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: FurniGem mob;
		--web-view-id: FurniGem_mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#thm15FG_mobGroup_14967 {
		position: absolute;
		width: 380px;
		height: 1059px;
		left: 10px;
		top: 22px;
		overflow: visible;
	}
	#thm15FG_mobTransform__Your_Space {
		left: 24px;
		top: 99px;
		position: absolute;
		overflow: visible;
		width: 323px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 64px;
		color: rgba(0,0,0,1);
		letter-spacing: 0.2px;
	}
	#thm15FG_mobAnime_Edit {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 48px;
		top: 0px;
        border-radius: 50%;
		overflow: hidden;
	}
	#thm15FG_mobTransform_Your_Space_With_Our_ {
		left: 53px;
		top: 844px;
		position: absolute;
		overflow: visible;
		width: 294px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(12,54,83,1);
	}
	#thm15FG_mobdi_an_h-g_8MrEZAvyE-unsplash {
		position: absolute;
		width: 234px;
		height: 332px;
		left: 82px;
		top: 488px;
		overflow: visible;
	}
	#thm15FG_mobScroll_Group_49 {
		mix-blend-mode: normal;
		position: absolute;
		width: 371px;
		height: 154px;
		left: 9px;
		top: 285px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#thm15FG_mobScroll_Group_49::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_mobIntroduction {
		left: 14px;
		top: 17px;
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 120px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobScroll_Group_54 {
		mix-blend-mode: normal;
		position: absolute;
		width: 373px;
		height: 142px;
		left: 0px;
		top: 917px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#thm15FG_mobScroll_Group_54::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_mobGroup_14963 {
		position: absolute;
		width: 606px;
		height: 120px;
		left: 9px;
		top: 11px;
		overflow: visible;
	}
	#thm15FG_mobguzman-barquin-FkKClUPUURU-uns {
		position: absolute;
		width: 186px;
		height: 120px;
		left: 420px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_mobspacejoy-YqFz7UMm8qE-unsplash {
		position: absolute;
		width: 186px;
		height: 120px;
		left: 210px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_mobmyxa69-nZnVdtlJvbw-unsplash {
		position: absolute;
		width: 186px;
		height: 120px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_mobCompany_Name {
		left: 108px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 251px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(12,54,83,1);
	}
	#thm15FG_mobGroup_14961 {
		position: absolute;
		width: 336px;
		height: 564px;
		left: 37px;
		top: 1243px;
		overflow: visible;
	}
	#thm15FG_mobRectangle_707 {
		fill: rgba(232,232,232,1);
	}
	.thm15FG_mobRectangle_707 {
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 330px;
		left: 0px;
		top: 234px;
	}
	#thm15FG_mobkisspng-furniture-poster-couch {
		position: absolute;
		width: 277px;
		height: 255px;
		left: 26px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_mobScroll_Group_51 {
		mix-blend-mode: normal;
		position: absolute;
		width: 328px;
		height: 245px;
		left: 0px;
		top: 314px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#thm15FG_mobScroll_Group_51::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_mobElevate_Your_Space_with_Timele {
		left: 15px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 302px;
		height: 223px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobVision {
		left: 138px;
		top: 286px;
		position: absolute;
		overflow: visible;
		width: 62px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 21px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobGroup_14964 {
		position: absolute;
		width: 336px;
		height: 564px;
		left: 27px;
		top: 1857px;
		overflow: visible;
	}
	#thm15FG_mobRectangle_707_de {
		fill: rgba(232,232,232,1);
	}
	.thm15FG_mobRectangle_707_de {
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 330px;
		left: 0px;
		top: 234px;
	}
	#thm15FG_mobkisspng-window-living-room-cur {
		position: absolute;
		width: 277px;
		height: 255px;
		left: 26px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_mobScroll_Group_51_dg {
		mix-blend-mode: normal;
		position: absolute;
		width: 328px;
		height: 245px;
		left: 0px;
		top: 314px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#thm15FG_mobScroll_Group_51_dg::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_mobElevate_Your_Space_with_Timele_dh {
		left: 15px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 302px;
		height: 223px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobMission {
		left: 138px;
		top: 286px;
		position: absolute;
		overflow: visible;
		width: 77px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 21px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobGroup_14965 {
		position: absolute;
		width: 336px;
		height: 564px;
		left: 27px;
		top: 2471px;
		overflow: visible;
	}
	#thm15FG_mobRectangle_707_dk {
		fill: rgba(232,232,232,1);
	}
	.thm15FG_mobRectangle_707_dk {
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 330px;
		left: 0px;
		top: 234px;
	}
	#thm15FG_mobsuchit-poojari-ljRiZl00n18-uns {
		position: absolute;
		width: 277px;
		height: 255px;
		left: 26px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_mobScroll_Group_51_dm {
		mix-blend-mode: normal;
		position: absolute;
		width: 328px;
		height: 245px;
		left: 0px;
		top: 314px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#thm15FG_mobScroll_Group_51_dm::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_mobElevate_Your_Space_with_Timele_dn {
		left: 15px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 302px;
		height: 223px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobPrinciples {
		left: 120px;
		top: 286px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 21px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobProduct {
		left: 135px;
		top: 3091px;
		position: absolute;
		overflow: visible;
		width: 122px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobScroll_Group_50 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 386px;
		left: 0px;
		top: 3159px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#thm15FG_mobScroll_Group_50::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_mobGroup_14957 {
		position: relative;
		width: 248px;
		height: 318px;
		left: 24px;
		top: 33px;
        margin-right: 30px;
		overflow: visible;
	}
	#thm15FG_mobRectangle_708 {
		fill: rgba(255,255,255,1);
	}
	.thm15FG_mobRectangle_708 {
		position: absolute;
		overflow: visible;
		width: 248px;
		height: 318px;
		left: 0px;
		top: 0px;
	}
	#thm15FG_mobavery-klein-JaXs8Tk5Iww-unspla {
		position: absolute;
		width: 217px;
		height: 261px;
		left: 16px;
		top: 16px;
		overflow: visible;
	}
	#thm15FG_mobChair {
		left: 16px;
		top: 288px;
		position: absolute;
		overflow: visible;
		width: 218px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobRectangle_711 {
		fill: rgba(59,59,59,1);
	}
	.thm15FG_mobRectangle_711 {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 695px;
		left: 0px;
		top: 3645px;
	}
	#thm15FG_mobRectangle_712 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.thm15FG_mobRectangle_712 {
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 209px;
		left: 19px;
		top: 3715px;
	}
	#thm15FG_mobVisit_Us {
		left: 159px;
		top: 3664px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_mobn_234567890_mobile_number_shad {
		left: 54px;
		top: 3976px;
		position: absolute;
		overflow: visible;
		width: 83px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_mobaffanghanioperatelive_email_sh {
		left: 54px;
		top: 4025px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_mobaddress_med_shadow_space_websi {
		left: 54px;
		top: 4074px;
		position: absolute;
		overflow: visible;
		width: 309px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_moblocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 19px;
		top: 4074px;
		overflow: visible;
	}
	#thm15FG_mobemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 19px;
		top: 4024px;
		overflow: visible;
	}
	#thm15FG_mobphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 19px;
		top: 3969px;
		overflow: visible;
	}
	#thm15FG_mobLine_29 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.thm15FG_mobLine_29 {
		overflow: visible;
		position: absolute;
		width: 390px;
		height: 2px;
		left: 0.5px;
		top: 4182.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_mobsocials_grp_shadow_space_websi {
		position: absolute;
		width: 210px;
		height: 50.001px;
		left: 90px;
		top: 4211.999px;
		overflow: visible;
	}
	#thm15FG_moblinked_in_grp_shadow_space_web {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 160px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_mobEllipse_5shadow_space_website_:hover {
		fill: rgba(120,120,255,1);
	}
	.thm15FG_mobEllipse_5shadow_space_website_:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#thm15FG_mobEllipse_5shadow_space_website_ {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.thm15FG_mobEllipse_5shadow_space_website_ {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#thm15FG_moblinked_in_shadow_space_website {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#thm15FG_mobn_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.thm15FG_mobn_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.954px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_moblinked_in_i_down {
		fill: rgba(63,61,86,1);
	}
	.thm15FG_moblinked_in_i_down {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.097px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_mobtop_dot_i_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.thm15FG_mobtop_dot_i_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_mobInsta_grp_med_shadow_space_web {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 80px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_mobEllipseshadow_space_website_5_:hover {
		fill: rgba(255,120,120,1);
	}
	.thm15FG_mobEllipseshadow_space_website_5_:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#thm15FG_mobEllipseshadow_space_website_5_ {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.thm15FG_mobEllipseshadow_space_website_5_ {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#thm15FG_mobinstagram_shadow_space_website {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.126px;
		overflow: visible;
	}
	#thm15FG_mobwhatsapp_grpshadow_space_websi {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#thm15FG_mobEllipse_3_shadow_space_website:hover {
		fill: rgba(50,172,158,1);
	}
	.thm15FG_mobEllipse_3_shadow_space_website:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#thm15FG_mobEllipse_3_shadow_space_website {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.thm15FG_mobEllipse_3_shadow_space_website {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#thm15FG_mobwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	.mediaViewInfo {
		--web-view-name: GamersHubMob;
		--web-view-id: GamersHubMob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: GamersHubMob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#GamersHubMob {
		position: absolute;
		width: 390px;
		height: 4458px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: GamersHubMob;
		--web-view-id: GamersHubMob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#GamersHub_16_mobGroup_14998 {
		position: absolute;
		width: 373.628px;
		height: 1209.316px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobMask_Group_1 {
		position: absolute;
		width: 154px;
		height: 154px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobefeb0ad6-be8c-4667-ab83-307500 {
		position: absolute;
		width: 44px;
		height: 44px;
		left: 92px;
		top: 21px;
		border-radius: 50%;
		overflow: hidden;
	}
	#GamersHub_16_mobCompany_Name {
		left: 147px;
		top: 31px;
		position: absolute;
		overflow: visible;
		width: 129px;
		white-space: nowrap;
		text-align: left;
		font-family: Headliner No 45;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobGroup_14986 {
		position: absolute;
		width: 358.878px;
		height: 275.59px;
		left: 14.75px;
		top: 196px;
		overflow: visible;
	}
	#GamersHub_16_mobPLAY_GAME_EVERYDAY_ALWAYS_BE_W {
		opacity: 1;
		left: 45.083px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 239px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobCreate_your_own_game_platform_ {
		-webkit-transform: translate(-52.5px, -223px) matrix(1,0,0,1,-27.4341,403.8352) rotate(-90deg);
		        transform: translate(-52.5px, -223px) matrix(1,0,0,1,-27.4341,403.8352) rotate(-90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 176px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobLine_38 {
		fill: transparent;
		stroke: rgba(92,35,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_38 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 43.809px;
		left: 0px;
		top: 121.494px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobLine_39 {
		fill: transparent;
		stroke: rgba(255,12,12,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_39 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 71.827px;
		left: 0px;
		top: 203.509px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobflorian-olivo-Mf23RF8xArY-unsp {
		position: absolute;
		width: 276.1px;
		height: 180.331px;
		left: 45.083px;
		top: 95.259px;
		overflow: visible;
	}
	#GamersHub_16_mobPath_6953 {
		fill: rgba(0,0,0,0);
		stroke: rgba(92,35,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobPath_6953 {
		overflow: visible;
		position: absolute;
		width: 0px;
		height: 43.809px;
		-webkit-transform: translate(-52.5px, -223px) matrix(1,0,0,1,341.3345,282.8555) rotate(90deg);
		        transform: translate(-52.5px, -223px) matrix(1,0,0,1,341.3345,282.8555) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_mobLine_41 {
		fill: transparent;
		stroke: rgba(255,12,12,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_41 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 71.827px;
		-webkit-transform: translate(-52.5px, -223px) matrix(1,0,0,1,261.8668,268.8468) rotate(90deg);
		        transform: translate(-52.5px, -223px) matrix(1,0,0,1,261.8668,268.8468) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_mobGroup_14980 {
		position: absolute;
		width: 27.508px;
		height: 27.508px;
		left: 331.37px;
		top: 248.082px;
		overflow: visible;
	}
	#GamersHub_16_mobEllipse_2 {
		fill: rgba(35,33,38,1);
	}
	.GamersHub_16_mobEllipse_2 {
		position: absolute;
		overflow: visible;
		width: 27.508px;
		height: 27.508px;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_mobPolygon_1 {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_mobPolygon_1 {
		overflow: visible;
		position: absolute;
		width: 6.521px;
		height: 5.589px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,10.3862,10.8519) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,10.3862,10.8519) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_mobGroup_14987 {
		position: absolute;
		width: 347.25px;
		height: 667.816px;
		left: 21px;
		top: 541.5px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14969 {
		position: absolute;
		width: 142.187px;
		height: 298.629px;
		left: 1.069px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobRectangle_718 {
		-webkit-filter: blur(50px);
		        filter: blur(50px);
		fill: rgba(255,68,87,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobRectangle_718 {
		position: absolute;
		overflow: visible;
		width: 440px;
		height: 473px;
		left: 2.181px;
		top: 125.5px;
	}
	#GamersHub_16_mobRectangle_713 {
		fill: rgba(255,68,87,1);
	}
	.GamersHub_16_mobRectangle_713 {
		position: absolute;
		overflow: visible;
		width: 25px;
		height: 25px;
		left: 117.181px;
		top: 72.5px;
	}
	#GamersHub_16_mobConsole {
		left: 13.181px;
		top: 75.5px;
		position: absolute;
		overflow: visible;
		width: 53px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(236,58,74,1);
	}
	#GamersHub_16_mobLine_30 {
		fill: transparent;
		stroke: rgba(255,36,36,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_30 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 250.164px;
		left: 0.356px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobLine_31 {
		fill: transparent;
		stroke: rgba(255,36,36,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_31 {
		overflow: visible;
		position: absolute;
		width: 3.426px;
		height: 168.931px;
		left: 0.356px;
		top: 81.027px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobpexels-anthony--139038 {
		position: absolute;
		width: 142.188px;
		height: 199.561px;
		left: 0px;
		top: 99.068px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14968 {
		position: absolute;
		width: 143px;
		height: 298.5px;
		left: 204.25px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobRectangle_719 {
		-webkit-filter: blur(17px);
		        filter: blur(17px);
		fill: rgba(19,16,27,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobRectangle_719 {
		position: absolute;
		overflow: visible;
		width: 245px;
		height: 296px;
		left: 0px;
		top: 104.5px;
	}
	#GamersHub_16_mobSwitch {
		left: 15px;
		top: 75.5px;
		position: absolute;
		overflow: visible;
		width: 44px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(33,27,33,1);
	}
	#GamersHub_16_mobRectangle_714 {
		fill: rgba(25,22,33,1);
	}
	.GamersHub_16_mobRectangle_714 {
		position: absolute;
		overflow: visible;
		width: 25px;
		height: 25px;
		left: 118px;
		top: 72.5px;
	}
	#GamersHub_16_mobLine_32 {
		fill: transparent;
		stroke: rgba(36,24,34,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_32 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 250.164px;
		left: 0.834px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobLine_33 {
		fill: transparent;
		stroke: rgba(55,114,54,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_33 {
		overflow: visible;
		position: absolute;
		width: 3.426px;
		height: 168.931px;
		left: 0.834px;
		top: 81.25px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobpexels-pixabay-371924 {
		position: absolute;
		width: 143px;
		height: 199px;
		left: 0px;
		top: 99.5px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14981 {
		position: absolute;
		width: 143.256px;
		height: 324.643px;
		left: 0px;
		top: 343.173px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14970 {
		position: absolute;
		width: 1.425px;
		height: 250.164px;
		left: 0px;
		top: 74.479px;
		overflow: visible;
	}
	#GamersHub_16_mobLine_34 {
		fill: transparent;
		stroke: rgba(202,222,228,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_34 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 250.164px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobLine_35 {
		fill: transparent;
		stroke: rgba(202,222,228,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_35 {
		overflow: visible;
		position: absolute;
		width: 3.426px;
		height: 168.931px;
		left: 0px;
		top: 81.25px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobRectangle_720 {
		-webkit-filter: blur(30px);
		        filter: blur(30px);
		fill: rgba(4,104,118,1);
	}
	.GamersHub_16_mobRectangle_720 {
		position: absolute;
		overflow: visible;
		width: 323px;
		height: 371px;
		left: 0.25px;
		top: 8.327px;
	}
	#GamersHub_16_mobpexels-harsch-shivam-2007647 {
		position: absolute;
		width: 142.543px;
		height: 199.561px;
		left: 0.713px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobVR {
		left: 9.25px;
		top: 205.327px;
		position: absolute;
		overflow: visible;
		width: 20px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(4,104,118,1);
	}
	#GamersHub_16_mobRectangle_715 {
		fill: rgba(4,104,118,1);
	}
	.GamersHub_16_mobRectangle_715 {
		position: absolute;
		overflow: visible;
		width: 25px;
		height: 25px;
		left: 118.25px;
		top: 202.327px;
	}
	#GamersHub_16_mobIcon_ionic-ios-arrow-back {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_mobIcon_ionic-ios-arrow-back {
		overflow: visible;
		position: absolute;
		width: 6.684px;
		height: 11.692px;
		left: 127.62px;
		top: 209.038px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobGroup_14982 {
		position: absolute;
		width: 143px;
		height: 324.643px;
		left: 204.25px;
		top: 343.173px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14971 {
		position: absolute;
		width: 1.425px;
		height: 250.164px;
		left: 0.122px;
		top: 74.479px;
		overflow: visible;
	}
	#GamersHub_16_mobLine_36 {
		fill: transparent;
		stroke: rgba(149,179,48,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_36 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 250.164px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobLine_37 {
		fill: transparent;
		stroke: rgba(149,179,48,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_37 {
		overflow: visible;
		position: absolute;
		width: 3.426px;
		height: 168.931px;
		left: 0px;
		top: 81.25px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobRectangle_721 {
		-webkit-filter: blur(42px);
		        filter: blur(42px);
		fill: rgba(110,134,41,1);
	}
	.GamersHub_16_mobRectangle_721 {
		position: absolute;
		overflow: visible;
		width: 395px;
		height: 443px;
		left: 0px;
		top: 8.327px;
	}
	#GamersHub_16_mobpexels-karol-d-841228 {
		position: absolute;
		width: 142.544px;
		height: 199.561px;
		left: 0.122px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobKeyboard {
		left: 10px;
		top: 205.327px;
		position: absolute;
		overflow: visible;
		width: 64px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(110,134,41,1);
	}
	#GamersHub_16_mobRectangle_716 {
		fill: rgba(110,134,41,1);
	}
	.GamersHub_16_mobRectangle_716 {
		position: absolute;
		overflow: visible;
		width: 25px;
		height: 25px;
		left: 118px;
		top: 202.327px;
	}
	#GamersHub_16_mobIcon_ionic-ios-arrow-back_d {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_mobIcon_ionic-ios-arrow-back_d {
		overflow: visible;
		position: absolute;
		width: 6.684px;
		height: 11.692px;
		left: 127.207px;
		top: 209.038px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobGroup_14996 {
		position: absolute;
		width: 369px;
		height: 1929.296px;
		left: 11px;
		top: 1329.188px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14990 {
		position: absolute;
		width: 361px;
		height: 598px;
		left: 4px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14989 {
		position: absolute;
		width: 295.791px;
		height: 241.802px;
		left: 31.315px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobPath_6955 {
		fill: rgba(92,35,255,1);
	}
	.GamersHub_16_mobPath_6955 {
		overflow: visible;
		position: absolute;
		width: 295.791px;
		height: 102.415px;
		left: 0px;
		top: 120.901px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobkisspng-twisted-metal-black-pl {
		position: absolute;
		width: 241.802px;
		height: 241.802px;
		left: 26.995px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14988 {
		position: absolute;
		width: 361px;
		height: 326px;
		left: 0px;
		top: 272px;
		overflow: visible;
	}
	#GamersHub_16_mobScroll_Group_51 {
		mix-blend-mode: normal;
		position: absolute;
		width: 361px;
		height: 269px;
		left: 0px;
		top: 57px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#GamersHub_16_mobElevate_Your_Space_with_Timele {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 345px;
		height: 249px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobVision {
		left: 11px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobGroup_14992 {
		position: absolute;
		width: 369px;
		height: 577.819px;
		left: 0px;
		top: 694.727px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14991 {
		position: absolute;
		width: 295.79px;
		height: 211.863px;
		left: 26px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobPath_6957 {
		fill: rgba(78,187,87,1);
	}
	.GamersHub_16_mobPath_6957 {
		overflow: visible;
		position: absolute;
		width: 295.79px;
		height: 124.099px;
		left: 0px;
		top: 87.764px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobkisspng-computer-keyboard-cors {
		position: absolute;
		width: 242.368px;
		height: 175.528px;
		left: 26.816px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14973 {
		position: absolute;
		width: 369px;
		height: 326px;
		left: 0px;
		top: 251.819px;
		overflow: visible;
	}
	#GamersHub_16_mobScroll_Group_51_en {
		mix-blend-mode: normal;
		position: absolute;
		width: 369px;
		height: 269px;
		left: 0px;
		top: 57px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#GamersHub_16_mobElevate_Your_Space_with_Timele_eo {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 226px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobMission {
		left: 11px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobGroup_14995 {
		position: absolute;
		width: 369px;
		height: 560.022px;
		left: 0px;
		top: 1369.274px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14993 {
		position: absolute;
		width: 336.389px;
		height: 193.377px;
		left: 6px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobPath_6956 {
		fill: rgba(78,187,87,1);
	}
	.GamersHub_16_mobPath_6956 {
		overflow: visible;
		position: absolute;
		width: 336.389px;
		height: 116.471px;
		left: 0px;
		top: 76.906px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobMy_project {
		position: absolute;
		width: 285.73px;
		height: 160.723px;
		left: 25.069px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobEllipse_63 {
		-webkit-filter: blur(9px);
		        filter: blur(9px);
		fill: rgba(0,0,0,1);
	}
	.GamersHub_16_mobEllipse_63 {
		position: absolute;
		overflow: visible;
		width: 330.866px;
		height: 65.262px;
		left: 22.05px;
		top: 135.142px;
	}
	#GamersHub_16_mobGroup_14994 {
		position: absolute;
		width: 369px;
		height: 326px;
		left: 0px;
		top: 234.022px;
		overflow: visible;
	}
	#GamersHub_16_mobScroll_Group_51_ew {
		mix-blend-mode: normal;
		position: absolute;
		width: 369px;
		height: 269px;
		left: 0px;
		top: 57px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#GamersHub_16_mobElevate_Your_Space_with_Timele_ex {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 249px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobPrinciples {
		left: 11px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobGroup_14984 {
		position: absolute;
		width: 176.66px;
		height: 79.148px;
		left: 106.67px;
		top: 3378.356px;
		overflow: visible;
	}
	#GamersHub_16_mobprinci_design_img_website_7_pc {
		fill: rgba(247,33,38,1);
	}
	.GamersHub_16_mobprinci_design_img_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 175.359px;
		height: 76.1px;
		-webkit-transform: translate(-608px, -2195.97px) matrix(1,0,0,1,608.6506,2197.4942) rotate(1deg);
		        transform: translate(-608px, -2195.97px) matrix(1,0,0,1,608.6506,2197.4942) rotate(1deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_mobOur_Services_lbl_website_6_pc {
		left: 31px;
		top: 23.03px;
		position: absolute;
		overflow: visible;
		width: 115px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(255,255,255,0.89);
	}
	#GamersHub_16_mobScroll_Group_55 {
		mix-blend-mode: normal;
		position: absolute;
		width: 380.5px;
		height: 232.998px;
		left: 0px;
		top: 3497.002px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#GamersHub_16_mobGroup_14977 {
		position: relative;
		margin-right: 30px;
		width: 241.116px;
		height: 173.821px;
		left: 17px;
		top: 25px;
		overflow: visible;
	}
	#GamersHub_16_mobPath_448 {
		fill: rgba(0,0,0,1);
	}
	.GamersHub_16_mobPath_448 {
		overflow: visible;
		position: absolute;
		width: 240.468px;
		height: 145.867px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobn_71177 {
		position: absolute;
		width: 233.388px;
		height: 129.459px;
		left: 3.679px;
		top: 3.715px;
		overflow: visible;
	}
	#GamersHub_16_mobMinecraft {
		left: 1.116px;
		top: 151.821px;
		position: absolute;
		overflow: visible;
		width: 241px;
		height: 22px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobGroup_14999 {
		position: absolute;
		width: 412px;
		height: 594.5px;
		left: -11px;
		top: 3846.5px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14976 {
		position: absolute;
		width: 412px;
		height: 231.75px;
		left: 0px;
		top: 3.372px;
		overflow: visible;
	}
	#GamersHub_16_mobMy_project_e {
		position: absolute;
		width: 412px;
		height: 231.75px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobRectangle_717 {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_mobRectangle_717 {
		position: absolute;
		overflow: visible;
		width: 205.276px;
		height: 114.694px;
		left: 101.009px;
		top: 55.392px;
	}
	#GamersHub_16_mobGroup_14997 {
		position: absolute;
		width: 355px;
		height: 180px;
		left: 34px;
		top: 235.5px;
		overflow: visible;
	}
	#GamersHub_16_mobn_234567890_mobile_number_shad {
		left: 35px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobaffanghanioperatelive_email_sh {
		left: 35px;
		top: 61px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobaddress_med_shadow_space_websi {
		left: 35px;
		top: 120px;
		position: absolute;
		overflow: visible;
		width: 321px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_moblocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 120px;
		overflow: visible;
	}
	#GamersHub_16_mobemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 60px;
		overflow: visible;
	}
	#GamersHub_16_mobphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 112px;
		top: 475.5px;
		overflow: visible;
	}
	#GamersHub_16_moblinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.GamersHub_16_mobmed_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#GamersHub_16_mobmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.GamersHub_16_mobmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#GamersHub_16_moblinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.192px;
		overflow: visible;
	}
	#GamersHub_16_mobn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.GamersHub_16_mobn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.671px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_moblinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.GamersHub_16_moblinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.554px;
		height: 14.428px;
		left: 0.589px;
		top: 6.809px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.GamersHub_16_mobtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.526px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.GamersHub_16_mobshadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#GamersHub_16_mobshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.GamersHub_16_mobshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#GamersHub_16_mobinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#GamersHub_16_mobwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobmed_shadow_space_website_7_fv:hover {
		fill: rgba(50,172,158,1);
	}
	.GamersHub_16_mobmed_shadow_space_website_7_fv:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#GamersHub_16_mobmed_shadow_space_website_7_fv {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.GamersHub_16_mobmed_shadow_space_website_7_fv {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#GamersHub_16_mobwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 11.993px;
		overflow: visible;
	}
	#GamersHub_16_mobn_Copyright_Operate_Live_OPC_P {
		left: 62px;
		top: 550.5px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobLine_28 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_28 {
		overflow: visible;
		position: absolute;
		width: 370.016px;
		height: 5px;
		left: 19.5px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	.mediaViewInfo {
		--web-view-name: TeaCorner Mob;
		--web-view-id: TeaCorner_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: TeaCorner_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#TeaCorner_Mob {
		position: absolute;
		width: 375px;
		height: 3363px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(249,249,249,0.886);
		overflow: hidden;
		--web-view-name: TeaCorner Mob;
		--web-view-id: TeaCorner_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#tcm_TC_17_MOBGroup_15005 {
		position: absolute;
		width: 375px;
		height: 272px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBside-view-two-cups-coffee-cook {
		position: absolute;
		width: 375px;
		height: 272px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBRectangle_722 {
		opacity: 0.74;
		fill: rgba(92,226,104,1);
	}
	.tcm_TC_17_MOBRectangle_722 {
		position: absolute;
		overflow: visible;
		width: 100px;
		height: 212px;
		left: 41px;
		top: 0px;
	}
	#tcm_TC_17_MOBAnime_Edit {
		position: absolute;
		width: 19px;
		height: 19px;
		left: 52px;
		top: 13px;
        border-radius: 50%;
		overflow: hidden;
	}
	#tcm_TC_17_MOBTea_Corner {
		left: 78px;
		top: 19px;
		position: absolute;
		overflow: visible;
		width: 25px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 5px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBThe_Beauty_of__Science_and__Na {
		left: 52px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 76px;
		height: 30px;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBTea_A_hug_in_a_cup_that_warms_ {
		left: 51px;
		top: 73px;
		position: absolute;
		overflow: visible;
		width: 81px;
		height: 130px;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#tcm_TC_17_MOBIcon_ionic-logo-whatsapp {
		fill: rgba(255,255,255,1);
	}
	.tcm_TC_17_MOBIcon_ionic-logo-whatsapp {
		overflow: visible;
		position: absolute;
		width: 8.489px;
		height: 8.49px;
		left: 48.001px;
		top: 181.931px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#tcm_TC_17_MOBIcon_awesome-instagram {
		fill: rgba(255,255,255,1);
	}
	.tcm_TC_17_MOBIcon_awesome-instagram {
		overflow: visible;
		position: absolute;
		width: 9.489px;
		height: 9.487px;
		left: 48.001px;
		top: 195.214px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#tcm_TC_17_MOBn_856321470 {
		left: 60.39px;
		top: 180.813px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(255,255,255,1);
	}
	#tcm_TC_17_MOByour_username {
		left: 60.39px;
		top: 193.148px;
		position: absolute;
		overflow: visible;
		width: 58px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(255,255,255,1);
	}
	#tcm_TC_17_MOBGroup_15011 {
		position: absolute;
		width: 448px;
		height: 543px;
		left: 13px;
		top: 322px;
		overflow: visible;
	}
	#tcm_TC_17_MOBvision_bdr_website_7_pc {
		fill: rgba(195,191,188,1);
	}
	.tcm_TC_17_MOBvision_bdr_website_7_pc {
		width: 543px;
		height: 102px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-13px, -322px) matrix(1,0,0,1,52.5,542.5) rotate(-90deg);
		        transform: translate(-13px, -322px) matrix(1,0,0,1,52.5,542.5) rotate(-90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#tcm_TC_17_MOBGroup_15006 {
		position: absolute;
		width: 445px;
		height: 240px;
		left: 3px;
		top: 278.998px;
		overflow: visible;
	}
	#tcm_TC_17_MOBVision_lbl_website_7_pc {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 68px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -8px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBScroll_vision_web_7_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 445px;
		height: 197px;
		left: 0px;
		top: 43px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#tcm_TC_17_MOBThe_cockpit_impresses_with_its {
		left: 14px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 238px;
		height: 228px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#tcm_TC_17_MOBmask_top_visoon_img_website_7_ {
		position: absolute;
		width: 260px;
		height: 320px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBGroup_15013 {
		position: absolute;
		width: 448px;
		height: 543px;
		left: -85px;
		top: 911px;
		overflow: visible;
	}
	#tcm_TC_17_MOBvision_bdr_website_7_pc_di {
		fill: rgba(97,64,38,1);
	}
	.tcm_TC_17_MOBvision_bdr_website_7_pc_di {
		width: 543px;
		height: 102px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(85px, -911px) matrix(1,0,0,1,-219.5,1131.5) rotate(-90deg);
		        transform: translate(85px, -911px) matrix(1,0,0,1,-219.5,1131.5) rotate(-90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#tcm_TC_17_MOBGroup_15007 {
		position: absolute;
		width: 445px;
		height: 246px;
		left: 0px;
		top: 272.998px;
		overflow: visible;
	}
	#tcm_TC_17_MOBVision_lbl_website_7_pc_dk {
		left: 213px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -8px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBScroll_vision_web_7_pc_dl {
		mix-blend-mode: normal;
		position: absolute;
		width: 445px;
		height: 197px;
		left: 0px;
		top: 49px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#tcm_TC_17_MOBThe_cockpit_impresses_with_its_dm {
		left: 208px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 238px;
		height: 228px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#tcm_TC_17_MOBmask_top_visoon_img_website_7__dn {
		position: absolute;
		width: 260px;
		height: 320px;
		left: 188px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBGroup_15012 {
		position: absolute;
		width: 448px;
		height: 543px;
		left: 16px;
		top: 1500px;
		overflow: visible;
	}
	#tcm_TC_17_MOBvision_bdr_website_7_pc_dp {
		fill: rgba(207,144,100,1);
	}
	.tcm_TC_17_MOBvision_bdr_website_7_pc_dp {
		width: 543px;
		height: 102px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-16px, -1500px) matrix(1,0,0,1,55.5,1720.5) rotate(-90deg);
		        transform: translate(-16px, -1500px) matrix(1,0,0,1,55.5,1720.5) rotate(-90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#tcm_TC_17_MOBGroup_15008 {
		position: absolute;
		width: 445px;
		height: 240px;
		left: 3px;
		top: 278.998px;
		overflow: visible;
	}
	#tcm_TC_17_MOBVision_lbl_website_7_pc_dr {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 106px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -8px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBScroll_vision_web_7_pc_ds {
		mix-blend-mode: normal;
		position: absolute;
		width: 445px;
		height: 197px;
		left: 0px;
		top: 43px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#tcm_TC_17_MOBThe_cockpit_impresses_with_its_dt {
		left: 14px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 238px;
		height: 228px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#tcm_TC_17_MOBmask_top_visoon_img_website_7__du {
		position: absolute;
		width: 260px;
		height: 320px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBAvaliable_Now_lbl_website_7_pc {
		left: 133px;
		top: 2171px;
		position: absolute;
		overflow: visible;
		width: 31px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBGroup_15014 {
		position: absolute;
		width: 1294px;
		height: 354px;
		left: 0px;
		top: 2253px;
		overflow: visible;
	}
	#tcm_TC_17_MOBScroll_Group_56 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 354px;
		left: 0px;
		top: 0px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#tcm_TC_17_MOBn_667477_3386277_1 {
		position: absolute;
		width: 1376px;
		height: 354px;
		left: -1px;
		top: -5px;
		overflow: visible;
	}
	#tcm_TC_17_MOBGroup_15003 {
		position: relative;
		width: 243px;
		height: 276px;
		top: 25px;
        margin-right: 30px;
		overflow: visible;
	}
	#tcm_TC_17_MOBcoffee-porcelain-cup-wooden-ta {
		position: absolute;
		width: 243px;
		height: 244px;
		left: 0px;
		top: 32px;
		overflow: visible;
	}
	#tcm_TC_17_MOBGinger_Tea {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 244px;
		height: 22px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBGroup_15010 {
		position: absolute;
		width: 477.645px;
		height: 798.381px;
		left: -51.322px;
		top: 2634.619px;
		overflow: visible;
	}
	#tcm_TC_17_MOBvision_bdr_website_7_pc_d {
		fill: rgba(207,144,100,1);
	}
	.tcm_TC_17_MOBvision_bdr_website_7_pc_d {
		position: absolute;
		overflow: visible;
		width: 378px;
		height: 726px;
		left: 51.322px;
		top: 72.381px;
	}
	#tcm_TC_17_MOBkisspng-turkish-coffee-tea-caf {
		position: absolute;
		width: 477.645px;
		height: 494.762px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBEllipse_64 {
		fill: rgba(255,255,255,1);
	}
	.tcm_TC_17_MOBEllipse_64 {
		position: absolute;
		overflow: visible;
		width: 188px;
		height: 193px;
		left: 173.033px;
		top: 155.381px;
		border-radius: 100px;
	}
	#tcm_TC_17_MOBGroup_15004 {
		position: absolute;
		width: 345px;
		height: 242px;
		left: 70.322px;
		top: 379.381px;
		overflow: visible;
	}
	#tcm_TC_17_MOBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 75px;
		top: 194px;
		overflow: visible;
	}
	#tcm_TC_17_MOBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.tcm_TC_17_MOBmed_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#tcm_TC_17_MOBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.tcm_TC_17_MOBmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#tcm_TC_17_MOBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.672px;
		overflow: visible;
	}
	#tcm_TC_17_MOBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.tcm_TC_17_MOBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#tcm_TC_17_MOBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.tcm_TC_17_MOBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.554px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#tcm_TC_17_MOBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.tcm_TC_17_MOBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.526px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#tcm_TC_17_MOBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.tcm_TC_17_MOBshadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#tcm_TC_17_MOBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.tcm_TC_17_MOBshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#tcm_TC_17_MOBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.072px;
		overflow: visible;
	}
	#tcm_TC_17_MOBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBmed_shadow_space_website_7_ek:hover {
		fill: rgba(50,172,158,1);
	}
	.tcm_TC_17_MOBmed_shadow_space_website_7_ek:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#tcm_TC_17_MOBmed_shadow_space_website_7_ek {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.tcm_TC_17_MOBmed_shadow_space_website_7_ek {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#tcm_TC_17_MOBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.473px;
		overflow: visible;
	}
	#tcm_TC_17_MOBn_234567890_mobile_number_shad {
		left: 35px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 311px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#tcm_TC_17_MOBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#tcm_TC_17_MOBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBn_Copyright_Operate_Live_OPC_P {
		left: 93.322px;
		top: 664.381px;
		position: absolute;
		overflow: visible;
		width: 293px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	.mediaViewInfo {
		--web-view-name: SharpStyles Mob;
		--web-view-id: SharpStyles_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: SharpStyles_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#SharpStyles_Mob {
		position: absolute;
		width: 375px;
		height: 4402px;
        left:calc((100vw - 375px)/2); 
		background: linear-gradient(180deg, rgba(228,228,233,1) 0%, rgba(255,255,255,0.886) 100%);
		overflow: hidden;
		--web-view-name: SharpStyles Mob;
		--web-view-id: SharpStyles_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#SharpStyles_18_mobGroup_15027 {
		position: absolute;
		width: 540.858px;
		height: 726px;
		left: -82.929px;
		top: 33px;
		overflow: visible;
	}
	#SharpStyles_18_mobGroup_15021 {
		position: absolute;
		width: 540.858px;
		height: 360.612px;
		left: 0px;
		top: 100.814px;
		overflow: visible;
	}
	#SharpStyles_18_mobRectangle_724 {
		fill: rgba(123,153,166,1);
	}
	.SharpStyles_18_mobRectangle_724 {
		position: absolute;
		overflow: visible;
		width: 180.531px;
		height: 171.935px;
		left: 207.755px;
		top: 188.678px;
	}
	#SharpStyles_18_mobMy_project {
		position: absolute;
		width: 540.857px;
		height: 360.612px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobtrimmer {
		position: absolute;
		width: 37.006px;
		height: 37.006px;
		left: 384.328px;
		top: 92.735px;
		overflow: visible;
	}
	#SharpStyles_18_mobAnime_Edit {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 145.929px;
		top: 0px;
		border-radius: 50%;
		overflow: hidden;
	}
	#SharpStyles_18_mobOni_Chan_Haircut {
		left: 230.929px;
		top: 19px;
		position: absolute;
		overflow: visible;
		width: 133px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobGroup_15022 {
		position: absolute;
		width: 348px;
		height: 218px;
		left: 101.929px;
		top: 508px;
		overflow: visible;
	}
	#SharpStyles_18_mobWe_are_Desperate_to_make_you_M {
		-webkit-filter: drop-shadow(0px 16px 21px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 16px 21px rgba(0, 0, 0, 0.161));
		left: 0.189px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 344px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 38px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#SharpStyles_18_mobYou_will_leave_looking_sharp_r {
		left: 0px;
		top: 178px;
		position: absolute;
		overflow: visible;
		width: 349px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobGroup_15029 {
		position: absolute;
		width: 305px;
		height: 1845px;
		left: 35px;
		top: 819px;
		overflow: visible;
	}
	#SharpStyles_18_mobGroup_14870 {
		position: absolute;
		width: 305px;
		height: 555px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobRectangle_683 {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_mobRectangle_683 {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 323px;
		height: 456px;
		left: 0px;
		top: 117px;
	}
	#SharpStyles_18_mobagustin-fernandez-Znyjl9pbaUs- {
		position: absolute;
		width: 206px;
		height: 278px;
		left: 49px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobVISION {
		left: 48px;
		top: 313px;
		position: absolute;
		overflow: visible;
		width: 62px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobScroll_Group_58 {
		mix-blend-mode: normal;
		position: absolute;
		width: 273px;
		height: 198px;
		left: 25px;
		top: 344px;
		overflow: hidden;
	}
	#SharpStyles_18_mobLorem_ipsum_dolor_sit_amet_con {
		left: 23px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 234px;
		height: 181px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(125,125,125,1);
	}
	#SharpStyles_18_mobGroup_14877 {
		position: absolute;
		width: 305px;
		height: 555px;
		left: 0px;
		top: 645px;
		overflow: visible;
	}
	#SharpStyles_18_mobRectangle_683_dj {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_mobRectangle_683_dj {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 323px;
		height: 456px;
		left: 0px;
		top: 117px;
	}
	#SharpStyles_18_mobnathon-oski-EW_rqoSdDes-unspla {
		position: absolute;
		width: 206px;
		height: 278px;
		left: 49px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobMISSION {
		left: 48px;
		top: 313px;
		position: absolute;
		overflow: visible;
		width: 78px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobLorem_ipsum_dolor_sit_amet_con_dm {
		left: 48px;
		top: 354px;
		position: absolute;
		overflow: visible;
		width: 234px;
		height: 181px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(125,125,125,1);
	}
	#SharpStyles_18_mobGroup_14878 {
		position: absolute;
		width: 305px;
		height: 555px;
		left: 0px;
		top: 1290px;
		overflow: visible;
	}
	#SharpStyles_18_mobRectangle_683_do {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_mobRectangle_683_do {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 323px;
		height: 456px;
		left: 0px;
		top: 117px;
	}
	#SharpStyles_18_mobapothecary-87-Wg3J83R1YSQ-unsp {
		position: absolute;
		width: 206px;
		height: 278px;
		left: 49px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobPRINCIPLES {
		left: 48px;
		top: 313px;
		position: absolute;
		overflow: visible;
		width: 101px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobScroll_Group_57 {
		mix-blend-mode: normal;
		position: absolute;
		width: 305px;
		height: 201px;
		left: 0px;
		top: 354px;
		overflow: hidden;
	}
	#SharpStyles_18_mobLorem_ipsum_dolor_sit_amet_con_ds {
		left: 48px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 234px;
		height: 181px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(125,125,125,1);
	}
	#SharpStyles_18_mobScroll_services_website_6_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 360px;
		height: 287px;
		left: 15px;
		top: 2834px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#SharpStyles_18_mobmy_services_card_1_website_6_m {
		position: relative;
		width: 174px;
		height: 242px;
		left: 14px;
		top: 22px;
		margin-right: 30px;
		overflow: visible;
	}
	#SharpStyles_18_mobservices_card_bdr_website_6_mo {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_mobservices_card_bdr_website_6_mo {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 192px;
		height: 260px;
		left: 0px;
		top: 0px;
	}
	#SharpStyles_18_mobimg_offering_website_6_mob {
		position: absolute;
		width: 150px;
		height: 198px;
		left: 12px;
		top: 34px;
		overflow: visible;
	}
	#SharpStyles_18_mobcircle_deign_shadow_space_webs {
		fill: rgba(23,18,18,1);
	}
	.SharpStyles_18_mobcircle_deign_shadow_space_webs {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 12px;
		top: 16px;
	}
	#SharpStyles_18_mobMy_Offerings_lbl_website_6_mob {
		left: 23px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 140px;
		height: 17px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,0.89);
	}
	#SharpStyles_18_mobOur_Services {
		left: 97px;
		top: 2754px;
		position: absolute;
		overflow: visible;
		width: 183px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobGallery {
		left: 136px;
		top: 3191px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobGroup_15019 {
		position: absolute;
		width: 360px;
		height: 343px;
		left: 8px;
		top: 3302px;
		overflow: visible;
	}
	#SharpStyles_18_mobobi-pixel7propix--sRVfY0f2d8-u {
		position: absolute;
		width: 115px;
		height: 157px;
		left: 123px;
		top: 23px;
		overflow: visible;
	}
	#SharpStyles_18_moballef-vinicius-IvQeAVeJULw-uns {
		position: absolute;
		width: 115px;
		height: 158px;
		left: 245px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobarthur-humeau-Twd3yaqA2NM-unsp {
		position: absolute;
		width: 115px;
		height: 158px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobagustin-fernandez-Znyjl9pbaUs-_d {
		position: absolute;
		width: 115px;
		height: 157px;
		left: 123px;
		top: 186px;
		overflow: visible;
	}
	#SharpStyles_18_mobdelfina-pan-wJoB8D3hnzc-unspla {
		position: absolute;
		width: 115px;
		height: 158px;
		left: 245px;
		top: 163px;
		overflow: visible;
	}
	#SharpStyles_18_mobnathon-oski-fE42nRlBcG8-unspla {
		position: absolute;
		width: 115px;
		height: 158px;
		left: 0px;
		top: 163px;
		overflow: visible;
	}
	#SharpStyles_18_mobGroup_15025 {
		position: absolute;
		width: 525px;
		height: 650px;
		left: 17px;
		top: 3733px;
		overflow: visible;
	}
	#SharpStyles_18_mobGroup_15023 {
		position: absolute;
		width: 507px;
		height: 342px;
		left: 18px;
		top: 308px;
		overflow: visible;
	}
	#SharpStyles_18_mobsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 0px;
		top: 213px;
		overflow: visible;
	}
	#SharpStyles_18_moblinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.SharpStyles_18_mobmed_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#SharpStyles_18_mobmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.SharpStyles_18_mobmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#SharpStyles_18_moblinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.191px;
		overflow: visible;
	}
	#SharpStyles_18_mobn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.SharpStyles_18_mobn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_moblinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.SharpStyles_18_moblinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.554px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_mobtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.SharpStyles_18_mobtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.526px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_mobInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.SharpStyles_18_mobshadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#SharpStyles_18_mobshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.SharpStyles_18_mobshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#SharpStyles_18_mobinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#SharpStyles_18_mobwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobmed_shadow_space_website_7_en:hover {
		fill: rgba(50,172,158,1);
	}
	.SharpStyles_18_mobmed_shadow_space_website_7_en:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#SharpStyles_18_mobmed_shadow_space_website_7_en {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.SharpStyles_18_mobmed_shadow_space_website_7_en {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#SharpStyles_18_mobwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#SharpStyles_18_mobn_234567890_mobile_number_shad {
		left: 48px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobaffanghanioperatelive_email_sh {
		left: 40px;
		top: 61px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobaddress_med_shadow_space_websi {
		left: 35px;
		top: 120px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_moblocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 120px;
		overflow: visible;
	}
	#SharpStyles_18_mobemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 60px;
		overflow: visible;
	}
	#SharpStyles_18_mobphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_mobn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 298px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 44px;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(173,173,173,1);
	}
	#SharpStyles_18_mobVisit_Us_and_Get_the_Stylist_H {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 342px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_mobRectangle_735 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SharpStyles_18_mobRectangle_735 {
		position: absolute;
		overflow: visible;
		width: 341px;
		height: 222px;
		left: 0px;
		top: 45px;
		border-radius: 20px;
	}
	.mediaViewInfo {
		--web-view-name: RealtyScape Mob;
		--web-view-id: RealtyScape_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: RealtyScape_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#RealtyScape_Mob {
		position: absolute;
		width: 375px;
		height: 4786px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: RealtyScape Mob;
		--web-view-id: RealtyScape_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#n19_Mob_RSRectangle_736_c {
		fill: url(#n19_Mob_RSRectangle_736_c);
	}
	.n19_Mob_RSRectangle_736_c {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 620px;
		left: 0px;
		top: 4168px;
	}
	#n19_Mob_RSGroup_15043 {
		position: absolute;
		width: 375px;
		height: 489px;
		left: 0px;
		top: 19px;
		overflow: visible;
	}
	#n19_Mob_RSjoel-filipe-RFDP7_80v5A-unspla {
		position: absolute;
		width: 375px;
		height: 249px;
		left: 0px;
		top: 95px;
		overflow: visible;
	}
	#n19_Mob_RSGroup_15044 {
		position: absolute;
		width: 333px;
		height: 154px;
		left: 21px;
		top: 335px;
		overflow: visible;
	}
	#n19_Mob_RSRectangle_102 {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSRectangle_102 {
		-webkit-filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		        filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		position: absolute;
		overflow: visible;
		width: 414px;
		height: 235px;
		left: 0px;
		top: 0px;
	}
	#n19_Mob_RSLets_fullfill___yours_Dream {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 56px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: left;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(34,137,255,1);
	}
	#n19_Mob_RSBuilding_your_dream_house {
		left: 69px;
		top: 115px;
		position: absolute;
		overflow: visible;
		width: 133px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: bold;
		font-size: 10px;
		color: rgba(48,48,48,1);
	}
	#n19_Mob_RSAnime_Edit {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 88px;
		top: 0px;
		border-radius: 50%;
		overflow: hidden;
	}
	#n19_Mob_RSOni_Chan_Haircut {
		left: 158px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 133px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#n19_Mob_RSWhat_do_you_want_to_your__Drea {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 42px;
		top: 568.001px;
		position: absolute;
		overflow: visible;
		width: 292px;
		white-space: nowrap;
		text-align: center;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(20,57,91,1);
	}
	#n19_Mob_RSScroll_Group_61 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 327px;
		left: 0px;
		top: 635px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#n19_Mob_RSGroup_15045 {
		position: absolute;
		width: 1280px;
		height: 246px;
		left: 16px;
		top: 33px;
		overflow: visible;
	}
	#n19_Mob_RSGroup_9691 {
		position: absolute;
		width: 13.622px;
		height: 10.84px;
		left: 244.366px;
		top: 213.346px;
		overflow: visible;
	}
	#n19_Mob_RScheck {
		position: absolute;
		width: 13.622px;
		height: 10.84px;
		left: -0.001px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSPath_2923 {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSPath_2923 {
		overflow: visible;
		position: absolute;
		width: 13.622px;
		height: 10.84px;
		left: 0.001px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n19_Mob_RSGroup_15042 {
		position: absolute;
		width: 285px;
		height: 246px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSRectangle_105 {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSRectangle_105 {
		-webkit-filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		        filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 336px;
		left: 0px;
		top: 0px;
	}
	#n19_Mob_RSGroup_9756 {
		position: absolute;
		width: 234px;
		height: 204px;
		left: 26px;
		top: 26px;
		overflow: visible;
	}
	#n19_Mob_RStop_ {
		position: absolute;
		width: 234px;
		height: 166px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSDeluxe_Portion {
		opacity: 0.79;
		left: 58px;
		top: 179px;
		position: absolute;
		overflow: visible;
		width: 118px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#n19_Mob_RSGroup_9689 {
		position: absolute;
		width: 285px;
		height: 246px;
		left: 332px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSRectangle_105_do {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSRectangle_105_do {
		-webkit-filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		        filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 336px;
		left: 0px;
		top: 0px;
	}
	#n19_Mob_RSGroup_9756_dp {
		position: absolute;
		width: 234px;
		height: 204px;
		left: 26px;
		top: 26px;
		overflow: visible;
	}
	#n19_Mob_RSfrancesca-tosolini-tHkJAMcO3QE {
		position: absolute;
		width: 234px;
		height: 166px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSDouble_Height {
		opacity: 0.79;
		left: 60px;
		top: 179px;
		position: absolute;
		overflow: visible;
		width: 115px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#n19_Mob_RSGroup_9689_ds {
		position: absolute;
		width: 285px;
		height: 246px;
		left: 664px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSRectangle_105_dt {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSRectangle_105_dt {
		-webkit-filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		        filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 336px;
		left: 0px;
		top: 0px;
	}
	#n19_Mob_RSGroup_9756_du {
		position: absolute;
		width: 234px;
		height: 204px;
		left: 26px;
		top: 26px;
		overflow: visible;
	}
	#n19_Mob_RSalice-kang-Bc_uN5PeErA-unsplas {
		position: absolute;
		width: 234px;
		height: 166px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSPenthouse {
		opacity: 0.79;
		left: 73px;
		top: 179px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#n19_Mob_RSGroup_9689_dx {
		position: absolute;
		width: 285px;
		height: 246px;
		left: 995px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSRectangle_105_dy {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSRectangle_105_dy {
		-webkit-filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		        filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 336px;
		left: 0px;
		top: 0px;
	}
	#n19_Mob_RSGroup_9756_dz {
		position: absolute;
		width: 234px;
		height: 204px;
		left: 25.999px;
		top: 26px;
		overflow: visible;
	}
	#n19_Mob_RSharry-cunningham-UWblg2i6Blw-u {
		position: absolute;
		width: 234px;
		height: 166px;
		left: 0.001px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSThe_Studio {
		opacity: 0.79;
		left: 72.001px;
		top: 179px;
		position: absolute;
		overflow: visible;
		width: 87px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#n19_Mob_RSGroup_15047 {
		position: absolute;
		width: 334.272px;
		height: 289.038px;
		left: 11px;
		top: 982.001px;
		overflow: visible;
	}
	#n19_Mob_RSUnion_2 {
		fill: rgba(34,137,255,1);
	}
	.n19_Mob_RSUnion_2 {
		overflow: visible;
		position: absolute;
		width: 207.932px;
		height: 207.932px;
		left: 11.412px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n19_Mob_RSGroup_15046 {
		position: absolute;
		width: 334.272px;
		height: 231.038px;
		left: 0px;
		top: 58px;
		overflow: visible;
	}
	#n19_Mob_RSavi-werde-hHz4yrvxwlA-unsplash {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 280.298px;
		height: 180.974px;
		left: 53.975px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSjason-briscoe-UV81E0oXXWQ-unsp {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 150.344px;
		height: 100.129px;
		left: 0px;
		top: 130.909px;
		overflow: visible;
	}
	#n19_Mob_RSGroup_9722 {
		position: absolute;
		width: 32.767px;
		height: 32.767px;
		left: 58.758px;
		top: 164.835px;
		overflow: visible;
	}
	#n19_Mob_RSEllipse_17 {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSEllipse_17 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 50.768px;
		height: 50.768px;
		left: 0px;
		top: 0px;
	}
	#n19_Mob_RSPath_2939 {
		fill: rgba(34,137,255,1);
	}
	.n19_Mob_RSPath_2939 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(34, 137, 255, 0.341));
		        filter: drop-shadow(0px 3px 6px rgba(34, 137, 255, 0.341));
		overflow: visible;
		position: absolute;
		width: 24.479px;
		height: 27.576px;
		left: 13.066px;
		top: 11.702px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n19_Mob_RSBuy__Dream_Apartment_In_The_Be {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 38px;
		top: 1328.001px;
		position: absolute;
		overflow: visible;
		width: 235px;
		white-space: nowrap;
		line-height: 44px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(20,57,91,1);
	}
	#n19_Mob_RSGroup_9721 {
		position: absolute;
		width: 218px;
		height: 63px;
		left: 38px;
		top: 1493px;
		overflow: visible;
	}
	#n19_Mob_RSRectangle_107 {
		fill: rgba(24,106,165,1);
	}
	.n19_Mob_RSRectangle_107 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 236px;
		height: 81px;
		left: 0px;
		top: 0px;
	}
	#n19_Mob_RSSee_All_Apartment {
		left: 39px;
		top: 19px;
		position: absolute;
		overflow: visible;
		width: 143px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#n19_Mob_RSGroup_15033 {
		position: absolute;
		width: 364px;
		height: 618px;
		left: 11px;
		top: 1702px;
		overflow: visible;
	}
	#n19_Mob_RSavi-werde-hHz4yrvxwlA-unsplash_ef {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 299px;
		height: 224px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSGroup_15038 {
		position: absolute;
		width: 364px;
		height: 343px;
		left: 0px;
		top: 275px;
		overflow: visible;
	}
	#n19_Mob_RSScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 364px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n19_Mob_RSIt_is_a_long_established_fact_ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#n19_Mob_RSVISION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#n19_Mob_RSGroup_15048 {
		position: absolute;
		width: 364px;
		height: 618px;
		left: 11px;
		top: 2360px;
		overflow: visible;
	}
	#n19_Mob_RSsean-pollock-PhYq704ffdA-unspl {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 299px;
		height: 224px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSGroup_15038_em {
		position: absolute;
		width: 364px;
		height: 343px;
		left: 0px;
		top: 275px;
		overflow: visible;
	}
	#n19_Mob_RSScroll_Group_28_en {
		mix-blend-mode: normal;
		position: absolute;
		width: 364px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n19_Mob_RSIt_is_a_long_established_fact__eo {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#n19_Mob_RSMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#n19_Mob_RSGroup_15049 {
		position: absolute;
		width: 364px;
		height: 618px;
		left: 11px;
		top: 3018px;
		overflow: visible;
	}
	#n19_Mob_RSwebaliser-_TPTXZd9mOo-unsplash {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 299px;
		height: 224px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSGroup_15038_es {
		position: absolute;
		width: 364px;
		height: 343px;
		left: 0px;
		top: 275px;
		overflow: visible;
	}
	#n19_Mob_RSScroll_Group_28_et {
		mix-blend-mode: normal;
		position: absolute;
		width: 364px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n19_Mob_RSIt_is_a_long_established_fact__eu {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#n19_Mob_RSPRINCIPLES {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#n19_Mob_RSFeatured_Apartment_ {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 69px;
		top: 3696px;
		position: absolute;
		overflow: visible;
		width: 239px;
		white-space: nowrap;
		text-align: center;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(20,57,91,1);
	}
	#n19_Mob_RSScroll_Group_62 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 379px;
		left: 0px;
		top: 3729px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#n19_Mob_RSGroup_15031 {
		position: relative;
		width: 296px;
		height: 313px;
		left: 11px;
		top: 30px;
		margin-right: 30px;
		overflow: visible;
	}
	#n19_Mob_RSRectangle_112 {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSRectangle_112 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 314px;
		height: 331px;
		left: 0px;
		top: 0px;
	}
	#n19_Mob_RSMask_Group_4 {
		position: absolute;
		width: 272px;
		height: 164px;
		left: 12px;
		top: 12px;
		overflow: visible;
	}
	#n19_Mob_RSn_500 {
		left: 11px;
		top: 207px;
		position: absolute;
		overflow: visible;
		width: 45px;
		white-space: nowrap;
		text-align: left;
		font-family: Sen;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(30,98,232,1);
	}
	#n19_Mob_RSApartment_London {
		left: 11px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 117px;
		white-space: nowrap;
		text-align: left;
		font-family: Sen;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#n19_Mob_RSScroll_Group_59 {
		mix-blend-mode: normal;
		position: absolute;
		width: 269px;
		height: 57px;
		left: 5px;
		top: 250px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n19_Mob_RSBeautiful_Huge_1_family_House_ {
		opacity: 0.5;
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 264px;
		height: 133px;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#n19_Mob_RSGroup_9694 {
		position: absolute;
		width: 218.774px;
		height: 19px;
		left: 11.226px;
		top: 184px;
		overflow: visible;
	}
	#n19_Mob_RSBangalore {
		opacity: 0.5;
		left: 14.774px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 205px;
		height: 19px;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#n19_Mob_RSgeo-alt {
		position: absolute;
		width: 12.521px;
		height: 17.482px;
		left: 0px;
		top: 1.518px;
		overflow: visible;
	}
	#n19_Mob_RSPath_2927 {
		fill: rgba(254,120,71,1);
	}
	.n19_Mob_RSPath_2927 {
		overflow: visible;
		position: absolute;
		width: 12.521px;
		height: 17.482px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n19_Mob_RSGroup_15040 {
		position: absolute;
		width: 375px;
		height: 250px;
		left: 0px;
		top: 4168px;
		overflow: visible;
	}
	#n19_Mob_RSkisspng-loft-window-office-roo {
		position: absolute;
		width: 375px;
		height: 250px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 58px;
		top: 35px;
		border-radius: 10px;
	}
	#n19_Mob_RSGroup_15050 {
		position: absolute;
		width: 358px;
		height: 316px;
		left: 17px;
		top: 4451px;
		overflow: visible;
	}
	#n19_Mob_RSsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 194px;
		overflow: visible;
	}
	#n19_Mob_RSlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.n19_Mob_RSmed_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n19_Mob_RSmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n19_Mob_RSmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n19_Mob_RSlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#n19_Mob_RSn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.n19_Mob_RSn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n19_Mob_RSlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.n19_Mob_RSlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.554px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n19_Mob_RStop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.n19_Mob_RStop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.526px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n19_Mob_RSInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.n19_Mob_RSshadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n19_Mob_RSshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n19_Mob_RSshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n19_Mob_RSinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#n19_Mob_RSwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSmed_shadow_space_website_7_fq:hover {
		fill: rgba(50,172,158,1);
	}
	.n19_Mob_RSmed_shadow_space_website_7_fq:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n19_Mob_RSmed_shadow_space_website_7_fq {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n19_Mob_RSmed_shadow_space_website_7_fq {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n19_Mob_RSwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#n19_Mob_RSn_234567890_mobile_number_shad {
		left: 48px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#n19_Mob_RSaffanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#n19_Mob_RSaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#n19_Mob_RSlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#n19_Mob_RSemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#n19_Mob_RSphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	.mediaViewInfo {
		--web-view-name: BakersStop Mob;
		--web-view-id: BakersStop_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: BakersStop_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#BakersStop_Mob {
		position: absolute;
		width: 320px;
		height: 4207px;
        left:calc((100vw - 320px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: BakersStop Mob;
		--web-view-id: BakersStop_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#BCS_21_MOBRectangle_678 {
		opacity: 0.97;
		fill: rgba(50,50,50,1);
	}
	.BCS_21_MOBRectangle_678 {
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 764px;
		left: 0px;
		top: 3684px;
	}
	#BCS_21_MOBAlways_Find_The_Best_Deals {
		left: 19px;
		top: 68px;
		position: absolute;
		overflow: visible;
		width: 125px;
		white-space: nowrap;
		text-align: right;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBBEST_IN__CLASS_ {
		left: 11px;
		top: 74px;
		position: absolute;
		overflow: visible;
		width: 300px;
		white-space: nowrap;
		line-height: 83px;
		margin-top: -1px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 81px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBSell_btn {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 19px;
		top: 296px;
		overflow: visible;
	}
	#BCS_21_MOBRectangle_659 {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1.5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_MOBRectangle_659 {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_MOBGet_it_now {
		left: 33px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 67px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#BCS_21_MOBGroup_15061 {
		position: absolute;
		width: 320px;
		height: 289px;
		left: 0px;
		top: 393px;
		overflow: visible;
	}
	#BCS_21_MOBRectangle_655 {
		fill: rgba(246,246,246,1);
	}
	.BCS_21_MOBRectangle_655 {
		position: absolute;
		overflow: visible;
		width: 276px;
		height: 214px;
		left: 21px;
		top: 75px;
	}
	#BCS_21_MOBSocial {
		position: absolute;
		width: 95px;
		height: 4px;
		left: 64px;
		top: 201px;
		overflow: visible;
	}
	#BCS_21_MOBFacebook {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 16px;
		white-space: nowrap;
		text-align: right;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 3px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBInstagram {
		left: 28px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 17px;
		white-space: nowrap;
		text-align: right;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 3px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBTwitter {
		left: 57px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 3px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBSnapchat {
		left: 80px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 16px;
		white-space: nowrap;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 3px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBMask_Group_20 {
		position: absolute;
		width: 161px;
		height: 184px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_MOBMask_Group_21 {
		position: absolute;
		width: 143px;
		height: 142px;
		left: 177px;
		top: 83px;
		overflow: visible;
	}
	#BCS_21_MOBn_rd_Section {
		position: absolute;
		width: 288px;
		height: 733px;
		left: 14px;
		top: 797px;
		overflow: visible;
	}
	#BCS_21_MOBScroll_Group_72 {
		mix-blend-mode: normal;
		position: absolute;
		width: 288px;
		height: 279px;
		left: 0px;
		top: 454px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BCS_21_MOBBinQasem_provides_a_platform_o {
		left: 5px;
		top: 18px;
		position: absolute;
		overflow: visible;
		width: 284px;
		height: 473px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBVISION {
		left: 5px;
		top: 388px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#BCS_21_MOBMask_Group_23 {
		position: absolute;
		width: 283px;
		height: 324px;
		left: 5px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_MOBn_rd_Section_ {
		position: absolute;
		width: 288px;
		height: 733px;
		left: 16px;
		top: 1629px;
		overflow: visible;
	}
	#BCS_21_MOBScroll_Group_72_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 288px;
		height: 279px;
		left: 0px;
		top: 454px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BCS_21_MOBBinQasem_provides_a_platform_o_ {
		left: 5px;
		top: 18px;
		position: absolute;
		overflow: visible;
		width: 284px;
		height: 473px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBMISSION {
		left: 5px;
		top: 388px;
		position: absolute;
		overflow: visible;
		width: 129px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#BCS_21_MOBMask_Group_23_ {
		position: absolute;
		width: 283px;
		height: 324px;
		left: 5px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_MOBn_rd_Section_ba {
		position: absolute;
		width: 288px;
		height: 733px;
		left: 16px;
		top: 2461px;
		overflow: visible;
	}
	#BCS_21_MOBScroll_Group_72_ba {
		mix-blend-mode: normal;
		position: absolute;
		width: 288px;
		height: 279px;
		left: 0px;
		top: 454px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BCS_21_MOBBinQasem_provides_a_platform_o_ba {
		left: 5px;
		top: 18px;
		position: absolute;
		overflow: visible;
		width: 284px;
		height: 471px;
		line-height: 30px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBpRINCIPLES {
		left: 5px;
		top: 388px;
		position: absolute;
		overflow: visible;
		width: 167px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#BCS_21_MOBMask_Group_23_bc {
		position: absolute;
		width: 283px;
		height: 324px;
		left: 5px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_MOBour_services {
		left: 57px;
		top: 3311px;
		position: absolute;
		overflow: visible;
		width: 207px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#BCS_21_MOBCategory_Section {
		position: absolute;
		width: 860px;
		height: 273px;
		left: 8px;
		top: 3376px;
		overflow: visible;
	}
	#BCS_21_MOBRectangle_662 {
		fill: rgba(0,0,0,1);
	}
	.BCS_21_MOBRectangle_662 {
		position: absolute;
		overflow: visible;
		width: 852px;
		height: 3px;
		left: 8px;
		top: 0px;
	}
	#BCS_21_MOBScroll_Group_73 {
		mix-blend-mode: normal;
		position: absolute;
		width: 312px;
		height: 270px;
		left: 0px;
		top: 3px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#BCS_21_MOBn_ {
		position: absolute;
		width: 199px;
		height: 238px;
		left: 8px;
		top: 16px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow: visible;
	}
	#BCS_21_MOBMask_Group_22 {
		position: relative;
		width: 199px;
		height: 238px;
		left: 0px;
		top: 0px;
        margin-right: 30px;
		overflow: visible;
	}
	#BCS_21_MOBRectangle_664 {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBRectangle_664 {
		position: absolute;
		overflow: visible;
		width: 110px;
		height: 46px;
		left: 0px;
		top: 192px;
	}
	#BCS_21_MOBHyundai_Azera_2020 {
		left: 3px;
		top: 201px;
		position: absolute;
		overflow: visible;
		width: 101px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
		text-transform: uppercase;
	}
	#BCS_21_MOBGroup_10773 {
		position: absolute;
		width: 30px;
		height: 3px;
		left: 85px;
		top: 174px;
		overflow: visible;
	}
	#BCS_21_MOBEllipse_38 {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBEllipse_38 {
		position: absolute;
		overflow: visible;
		width: 3px;
		height: 3px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_MOBEllipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBEllipse_39 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 6px;
		top: 0px;
	}
	#BCS_21_MOBEllipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBEllipse_40 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 13px;
		top: 0px;
	}
	#BCS_21_MOBEllipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBEllipse_41 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 19px;
		top: 0px;
	}
	#BCS_21_MOBEllipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBEllipse_42 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 26px;
		top: 0px;
	}
	#BCS_21_MOBGroup_15063 {
		position: absolute;
		width: 291px;
		height: 187px;
		left: 13px;
		top: 3779px;
		overflow: visible;
	}
	#BCS_21_MOBGroup_15062 {
		position: absolute;
		width: 291px;
		height: 187px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_MOBn_2321397_luyk_98k2_201103 {
		position: absolute;
		width: 291px;
		height: 187px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_MOBRectangle_680 {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBRectangle_680 {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 120px;
		left: 11px;
		top: 57px;
	}
	#BCS_21_MOBRectangle_742 {
		fill: rgba(236,224,200,1);
	}
	.BCS_21_MOBRectangle_742 {
		position: absolute;
		overflow: visible;
		width: 89px;
		height: 26px;
		left: 100px;
		top: 5px;
	}
	#BCS_21_MOBBAKERY {
		left: 116px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 61px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe Script;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBGroup_15059 {
		position: absolute;
		width: 244.527px;
		height: 180px;
		left: 29.844px;
		top: 4003.943px;
		overflow: visible;
	}
	#BCS_21_MOBFor_Queries_infoandroomedanet {
		left: 26.527px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 219px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#BCS_21_MOBFor_call__920012888 {
		left: 26.527px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#BCS_21_MOBtelephone {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBtelephone {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 0px;
		top: 25.525px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_MOBsend_1 {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBsend_1 {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 0px;
		top: 4.719px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_MOBsocials_grp_med_web_4_mob {
		position: absolute;
		width: 210px;
		height: 50.001px;
		left: 0px;
		top: 129.999px;
		overflow: visible;
	}
	#BCS_21_MOBlinked_in_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 160px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_MOBEllipse_5_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBEllipse_5_med_web_4_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_MOBlinked_in_website_4_layout {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#BCS_21_MOBn_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.BCS_21_MOBn_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.953px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_MOBlinked_in_i_down {
		fill: rgba(63,61,86,1);
	}
	.BCS_21_MOBlinked_in_i_down {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.097px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_MOBtop_dot_i_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.BCS_21_MOBtop_dot_i_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_MOBInsta_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 80px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_MOBEllipse_4_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBEllipse_4_med_web_4_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_MOBinstagram_website_4_layout {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.126px;
		overflow: visible;
	}
	#BCS_21_MOBwhatsapp_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#BCS_21_MOBEllipse_3_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBEllipse_3_med_web_4_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_MOBwhatsapp_lbl_website4_layout {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#BCS_21_MOBIcon_metro-location {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_MOBIcon_metro-location {
		overflow: visible;
		position: absolute;
		width: 11.381px;
		height: 18.209px;
		left: 0px;
		top: 46.33px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_MOBaddress {
		left: 26.527px;
		top: 45.539px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 62px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#BCS_21_MOBNAME {
		left: 19px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 70px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe Script;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_MOBContact_us_ {
		left: 93px;
		top: 3714px;
		position: absolute;
		overflow: visible;
		width: 136px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -13.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}
	.mediaViewInfo {
		--web-view-name: KnowledgeSpace Mob;
		--web-view-id: KnowledgeSpace_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: KnowledgeSpace_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#KnowledgeSpace_Mob {
		position: absolute;
		width: 320px;
		height: 4448px;
        left:calc((100vw - 320px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: KnowledgeSpace Mob;
		--web-view-id: KnowledgeSpace_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#KS_20_mobRectangle_79 {
		position: absolute;
		width: 320px;
		height: 500px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_mobGroup_99 {
		position: absolute;
		width: 320px;
		height: 614px;
		left: 0px;
		top: 2740px;
		overflow: visible;
	}
	#KS_20_mobScroll_Group_69 {
		mix-blend-mode: normal;
		position: absolute;
		width: 320px;
		height: 579.844px;
		left: 0px;
		top: 34.156px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#KS_20_mobGroup_49 {
		position: relative;
		width: 282px;
		height: 531.844px;
		left: 19px;
		top: 24px;
        margin-right: 30px;
		overflow: visible;
	}
	#KS_20_mobRectangle_115 {
		position: absolute;
		width: 282px;
		height: 228.793px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_mobGroup_48 {
		position: absolute;
		width: 282px;
		height: 303.051px;
		left: 0px;
		top: 228.793px;
		overflow: visible;
	}
	#KS_20_mobRectangle_116 {
		fill: rgba(241,241,241,1);
	}
	.KS_20_mobRectangle_116 {
		position: absolute;
		overflow: visible;
		width: 282px;
		height: 303.051px;
		left: 0px;
		top: 0px;
	}
	#KS_20_mobRectangle_117 {
		fill: rgba(207,207,207,1);
	}
	.KS_20_mobRectangle_117 {
		position: absolute;
		overflow: visible;
		width: 282px;
		height: 2.281px;
		left: 0px;
		top: 142.9px;
	}
	#KS_20_mobRectangle_118 {
		fill: rgba(207,207,207,1);
	}
	.KS_20_mobRectangle_118 {
		position: absolute;
		overflow: visible;
		width: 186.227px;
		height: 1.52px;
		left: 47.887px;
		top: 206.748px;
	}
	#KS_20_mobDigital_Marketing {
		left: 18.563px;
		top: 21.563px;
		position: absolute;
		overflow: visible;
		width: 191px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -4px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobTime__10_am {
		/* left: 86.973px; */
		top: 173.305px;
		position: absolute;
		overflow: visible;
		width: 100%;
        text-align: center;
		white-space: nowrap;
		line-height: 24px;
		margin-top: -3px;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobLorem_ipsum_dolor_sit_amet_con {
		left: 19.563px;
		top: 72.971px;
		position: absolute;
		overflow: visible;
		width: 222.951px;
		height: 57.32614517211914px;
		line-height: 18px;
		margin-top: -2px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(72,72,72,1);
	}
	#KS_20_mobRectangle_120 {
		fill: rgba(39,174,96,1);
	}
	.KS_20_mobRectangle_120 {
		position: absolute;
		overflow: visible;
		width: 247px;
		height: 47px;
		left: 17.5px;
		top: 236.393px;
	}
	#KS_20_mobJoin_Now {
		left: 102.354px;
		top: 247.035px;
		position: absolute;
		overflow: visible;
		width: 77px;
		white-space: nowrap;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#KS_20_mobCourses {
		left: 93px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 135px;
		white-space: nowrap;
		text-align: center;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobGroup_105 {
		position: absolute;
		width: 509px;
		height: 673px;
		left: 8px;
		top: 3749px;
		overflow: visible;
	}
	#KS_20_mobContact {
		left: 87px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 130px;
		white-space: nowrap;
		line-height: 48px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(17,17,17,1);
	}
	#KS_20_mobGroup_90 {
		position: absolute;
		width: 303px;
		height: 530px;
		left: 0px;
		top: 59px;
		overflow: visible;
	}
	#KS_20_moblocation {
		position: absolute;
		width: 24px;
		height: 30px;
		left: 32px;
		top: 207px;
		overflow: visible;
	}
	#KS_20_mobmessage {
		position: absolute;
		width: 30px;
		height: 21px;
		left: 31px;
		top: 345px;
		overflow: visible;
	}
	#KS_20_mobScroll_Group_71 {
		mix-blend-mode: normal;
		position: absolute;
		width: 290px;
		height: 79px;
		left: 13px;
		top: 246px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_mobLSE_Houghton___Street_London__ {
		left: 19px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 267px;
		height: 71px;
		line-height: 18px;
		margin-top: -2px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(72,72,72,1);
	}
	#KS_20_mobhellogmailcom {
		left: 31px;
		top: 381px;
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 24px;
		line-height: 18px;
		margin-top: -2px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(72,72,72,1);
	}
	#KS_20_mobphone {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 32px;
		top: 459px;
		overflow: visible;
	}
	#KS_20_mobn_4420_74057686 {
		left: 32px;
		top: 505px;
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 25px;
		line-height: 18px;
		margin-top: -2px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(72,72,72,1);
	}
	#KS_20_mobRectangle_740 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.KS_20_mobRectangle_740 {
		position: absolute;
		overflow: visible;
		width: 303px;
		height: 196px;
		left: 0px;
		top: 0px;
	}
	#KS_20_mobn_Copyright_Live_OPC_Private_L {
		left: 2px;
		top: 629px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 44px;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(173,173,173,1);
	}
	#KS_20_mobGroup_100 {
		position: absolute;
		width: 320px;
		height: 289px;
		left: 0px;
		top: 3404px;
		overflow: visible;
	}
	#KS_20_mobRectangle_121 {
		fill: rgba(241,241,241,1);
	}
	.KS_20_mobRectangle_121 {
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 289px;
		left: 0px;
		top: 0px;
	}
	#KS_20_mobOur_Teachers {
		left: 50px;
		top: 30px;
		position: absolute;
		overflow: visible;
		width: 220px;
		white-space: nowrap;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(17,17,17,1);
	}
	#KS_20_mobScroll_Group_70 {
		mix-blend-mode: normal;
		position: absolute;
		width: 310px;
		height: 210px;
		left: 0px;
		top: 79px;
		overflow: hidden;
	}
	#KS_20_mobGroup_96 {
		position: absolute;
		width: 157px;
		height: 188px;
		left: 17px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_mobGroup_95 {
		position: absolute;
		width: 157px;
		height: 188px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_mobEllipse_17 {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 31px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_mobBenton_Colley {
		left: 2px;
		top: 125px;
		position: absolute;
		overflow: visible;
		width: 155px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -4px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobPhotography_Head {
		left: 0px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 158px;
		white-space: nowrap;
		line-height: 24px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(39,174,96,1);
	}
	#KS_20_mobGroup_107 {
		position: absolute;
		width: 320px;
		height: 500px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_mobRectangle_78 {
		opacity: 0.26;
		fill: rgba(26,26,26,1);
	}
	.KS_20_mobRectangle_78 {
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 500px;
		left: 0px;
		top: 0px;
	}
	#KS_20_mobRectangle_80 {
		fill: rgba(39,174,97,1);
	}
	.KS_20_mobRectangle_80 {
		position: absolute;
		overflow: visible;
		width: 134px;
		height: 47px;
		left: 93px;
		top: 344px;
	}
	#KS_20_mobKnowledge___is_power {
		left: 62px;
		top: 134px;
		position: absolute;
		overflow: visible;
		width: 197px;
		white-space: nowrap;
		text-align: center;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(255,255,255,1);
	}
	#KS_20_mobAny_succesfull_career_starts__ {
		left: 63px;
		top: 259px;
		position: absolute;
		overflow: visible;
		width: 195px;
		white-space: nowrap;
		line-height: 18px;
		margin-top: -2px;
		text-align: center;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#KS_20_mobLearn_more {
		left: 108px;
		top: 358px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: center;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#KS_20_moblogo {
		position: absolute;
		width: 40px;
		height: 32px;
		left: 19px;
		top: 18px;
        border-radius: 50%;
		overflow: hidden;
	}
	#KS_20_mobGroup_109 {
		position: absolute;
		width: 290px;
		height: 674px;
		left: 30px;
		top: 1286px;
		overflow: visible;
	}
	#KS_20_mobtamanna-rumee-vaTsR-ghLog-unsp {
		position: absolute;
		width: 260px;
		height: 331px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_mobGroup_3 {
		position: absolute;
		width: 290px;
		height: 305px;
		left: 0px;
		top: 369px;
		overflow: visible;
	}
	#KS_20_mobMission {
		left: 14px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 126px;
		white-space: nowrap;
		line-height: 48px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobScroll_Group_65 {
		mix-blend-mode: normal;
		position: absolute;
		width: 290px;
		height: 235px;
		left: 0px;
		top: 70px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_mobLorem_ipsum_dolor_sit_amet_con_eh {
		left: 15px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 246px;
		height: 232px;
		line-height: 24px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobGroup_15057 {
		position: absolute;
		width: 290px;
		height: 674px;
		left: 30px;
		top: 556px;
		overflow: visible;
	}
	#KS_20_mobtim-mossholder-WE_Kv_ZB1l0-uns {
		position: absolute;
		width: 260px;
		height: 331px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_mobGroup_3_ek {
		position: absolute;
		width: 290px;
		height: 305px;
		left: 0px;
		top: 369px;
		overflow: visible;
	}
	#KS_20_mobVision {
		left: 14px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		line-height: 48px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobScroll_Group_65_em {
		mix-blend-mode: normal;
		position: absolute;
		width: 290px;
		height: 235px;
		left: 0px;
		top: 70px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_mobLorem_ipsum_dolor_sit_amet_con_en {
		left: 15px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 246px;
		height: 232px;
		line-height: 24px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobGroup_15058 {
		position: absolute;
		width: 290px;
		height: 674px;
		left: 30px;
		top: 2016px;
		overflow: visible;
	}
	#KS_20_mobnguyen-khanh-ly-liiusuaISP0-un {
		position: absolute;
		width: 260px;
		height: 331px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_mobGroup_3_eq {
		position: absolute;
		width: 290px;
		height: 305px;
		left: 0px;
		top: 369px;
		overflow: visible;
	}
	#KS_20_mobPrinciples {
		left: 14px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 162px;
		white-space: nowrap;
		line-height: 48px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobScroll_Group_65_es {
		mix-blend-mode: normal;
		position: absolute;
		width: 290px;
		height: 235px;
		left: 0px;
		top: 70px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_mobLorem_ipsum_dolor_sit_amet_con_et {
		left: 15px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 246px;
		height: 232px;
		line-height: 24px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(26,26,26,1);
	}
.mediaViewInfo {
		--web-view-name: Clinic Mob;
		--web-view-id: Clinic_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Clinic_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Clinic_Mob {
		position: absolute;
		width: 375px;
		height: 4260px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: Clinic Mob;
		--web-view-id: Clinic_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#Clinic_23_mobGroup_15043 {
		position: absolute;
		width: 375px;
		height: 464px;
		left: 0px;
		top: 19px;
		overflow: visible;
	}
	#Clinic_23_mobn_0173304_8507 {
		position: absolute;
		width: 375px;
		height: 249px;
		left: 0px;
		top: 95px;
		overflow: visible;
	}
	#Clinic_23_mobGroup_15044 {
		position: absolute;
		width: 333px;
		height: 139px;
		left: 21px;
		top: 325px;
		overflow: visible;
	}
	#Clinic_23_mobRectangle_102 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_mobRectangle_102 {
		-webkit-filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		        filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		position: absolute;
		overflow: visible;
		width: 360px;
		height: 200px;
		left: 27px;
		top: 0px;
	}
	#Clinic_23_mobRectangle_746 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_mobRectangle_746 {
		-webkit-filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		        filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		position: absolute;
		overflow: visible;
		width: 414px;
		height: 200px;
		left: 0px;
		top: 20px;
	}
	#Clinic_23_mobHealing_Starts_Here_ {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 56px;
		top: 31px;
		position: absolute;
		overflow: visible;
		width: 217px;
		white-space: nowrap;
		text-align: left;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(215,113,108,1);
	}
	#Clinic_23_mobAnime_Edit {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 88px;
		top: 0px;
        border-radius: 50%;
		overflow: hidden;
	}
	#Clinic_23_mobOni_Chan_Clinic {
		left: 158px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 119px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_mobGroup_15047 {
		position: absolute;
		width: 322.86px;
		height: 238.974px;
		left: 22.412px;
		top: 555.001px;
		overflow: visible;
	}
	#Clinic_23_mobUnion_2 {
		fill: rgba(215,113,108,1);
	}
	.Clinic_23_mobUnion_2 {
		overflow: visible;
		position: absolute;
		width: 207.932px;
		height: 207.932px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_mobGroup_15046 {
		position: absolute;
		width: 280.298px;
		height: 180.974px;
		left: 42.563px;
		top: 58px;
		overflow: visible;
	}
	#Clinic_23_mobn_8706987_TaeAugust07 {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 280.298px;
		height: 180.974px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_mobGet_The_Best__Treatments_Done_ {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 38px;
		top: 901.001px;
		position: absolute;
		overflow: visible;
		width: 207px;
		white-space: nowrap;
		line-height: 44px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(20,57,91,1);
	}
	#Clinic_23_mobGroup_9721 {
		position: absolute;
		width: 218px;
		height: 63px;
		left: 38px;
		top: 1066px;
		overflow: visible;
	}
	#Clinic_23_mobRectangle_107 {
		fill: rgba(215,113,108,1);
	}
	.Clinic_23_mobRectangle_107 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 236px;
		height: 81px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_mobSee_All_Apartment {
		left: 39px;
		top: 19px;
		position: absolute;
		overflow: visible;
		width: 143px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#Clinic_23_mobGroup_15033 {
		position: absolute;
		width: 364px;
		height: 618px;
		left: 11px;
		top: 1275px;
		overflow: visible;
	}
	#Clinic_23_mobclose-up-doctor-writing {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 299px;
		height: 224px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_mobGroup_15038 {
		position: absolute;
		width: 364px;
		height: 343px;
		left: 0px;
		top: 275px;
		overflow: visible;
	}
	#Clinic_23_mobScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 364px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Clinic_23_mobIt_is_a_long_established_fact_ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#Clinic_23_mobVISION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_mobGroup_15048 {
		position: absolute;
		width: 364px;
		height: 618px;
		left: 11px;
		top: 1933px;
		overflow: visible;
	}
	#Clinic_23_mobclose-up-businessman-businessw {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 299px;
		height: 224px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_mobGroup_15038_bn {
		position: absolute;
		width: 364px;
		height: 343px;
		left: 0px;
		top: 275px;
		overflow: visible;
	}
	#Clinic_23_mobScroll_Group_28_bo {
		mix-blend-mode: normal;
		position: absolute;
		width: 364px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Clinic_23_mobIt_is_a_long_established_fact__bp {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#Clinic_23_mobMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_mobGroup_15049 {
		position: absolute;
		width: 364px;
		height: 618px;
		left: 11px;
		top: 2591px;
		overflow: visible;
	}
	#Clinic_23_mobdoctor-patient {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 299px;
		height: 224px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_mobGroup_15038_bt {
		position: absolute;
		width: 364px;
		height: 343px;
		left: 0px;
		top: 275px;
		overflow: visible;
	}
	#Clinic_23_mobScroll_Group_28_bu {
		mix-blend-mode: normal;
		position: absolute;
		width: 364px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Clinic_23_mobIt_is_a_long_established_fact__bv {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#Clinic_23_mobPRINCIPLES {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_mobOur_Teatments {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 102px;
		top: 3269px;
		position: absolute;
		overflow: visible;
		width: 173px;
		white-space: nowrap;
		text-align: center;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(215,113,108,1);
	}
	#Clinic_23_mobScroll_Group_62 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 340px;
		left: 0px;
		top: 3302px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#Clinic_23_mobGroup_15031 {
		position: relative;
		width: 296px;
		height: 237px;
		left: 11px;
		top: 30px;
        margin-right: 30px;
		overflow: visible;
	}
	#Clinic_23_mobRectangle_112 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_mobRectangle_112 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 314px;
		height: 255px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_mobMask_Group_4 {
		position: absolute;
		width: 272px;
		height: 164px;
		left: 12px;
		top: 12.001px;
		overflow: visible;
	}
	#Clinic_23_mobApartment_London {
		left: 11px;
		top: 208px;
		position: absolute;
		overflow: visible;
		width: 117px;
		white-space: nowrap;
		text-align: left;
		font-family: Sen;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#Clinic_23_mobGroup_9694 {
		position: absolute;
		width: 218.774px;
		height: 19px;
		left: 11.226px;
		top: 184px;
		overflow: visible;
	}
	#Clinic_23_mobBangalore {
		opacity: 0.5;
		left: 14.774px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 205px;
		height: 19px;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#Clinic_23_mobgeo-alt {
		position: absolute;
		width: 12.521px;
		height: 17.482px;
		left: 0px;
		top: 1.518px;
		overflow: visible;
	}
	#Clinic_23_mobPath_2927 {
		fill: rgba(254,120,71,1);
	}
	.Clinic_23_mobPath_2927 {
		overflow: visible;
		position: absolute;
		width: 12.521px;
		height: 17.482px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_mobGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 39px;
		top: 3701.999px;
		overflow: visible;
	}
	#Clinic_23_mobRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_mobRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#Clinic_23_mobGroup_15050 {
		position: absolute;
		width: 358px;
		height: 316px;
		left: 9px;
		top: 3915px;
		overflow: visible;
	}
	#Clinic_23_mobsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 194px;
		overflow: visible;
	}
	#Clinic_23_moblinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_mobmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_mobmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_moblinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#Clinic_23_mobn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.Clinic_23_mobn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_moblinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.Clinic_23_moblinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_mobtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.Clinic_23_mobtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_mobInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_mobshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_mobshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_mobinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#Clinic_23_mobwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_mobmed_shadow_space_website_7_cl {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_mobmed_shadow_space_website_7_cl {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_mobwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#Clinic_23_mobn_234567890_mobile_number_shad {
		left: 48px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_mobaffanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_mobaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_moblocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#Clinic_23_mobemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#Clinic_23_mobphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_mobn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
.mediaViewInfo {
		--web-view-name: Chaats Paradise Mob;
		--web-view-id: ChaatsParadiseMob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ChaatsParadiseMob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ChaatsParadiseMob {
		position: absolute;
		width: 320px;
		height: 3922px;
        left:calc((100vw - 320px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Chaats Paradise Mob;
		--web-view-id: ChaatsParadiseMob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#CHAAT_22_MOBpexels-vinit-jain-16171917 {
		position: absolute;
		width: 320px;
		height: 500px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBGroup_107 {
		position: absolute;
		width: 320px;
		height: 500px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBRectangle_78 {
		opacity: 0.26;
		fill: rgba(26,26,26,1);
	}
	.CHAAT_22_MOBRectangle_78 {
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 500px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_MOBTaste_the_Best_Right_Now {
		left: 61px;
		top: 129px;
		position: absolute;
		overflow: visible;
		width: 199px;
		height: 121px;
		text-align: center;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(255,255,255,1);
	}
	#CHAAT_22_MOBAny_succesfull_career_starts__ {
		left: 63px;
		top: 289px;
		position: absolute;
		overflow: visible;
		width: 195px;
		white-space: nowrap;
		line-height: 18px;
		margin-top: -2px;
		text-align: center;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#CHAAT_22_MOBlogo {
		position: absolute;
		width: 40px;
		height: 32px;
		left: 19px;
		top: 18px;
        border-radius: 50%;
		overflow: hidden;
	}
	#CHAAT_22_MOBName {
		left: 71px;
		top: 23px;
		position: absolute;
		overflow: visible;
		width: 47px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#CHAAT_22_MOBGroup_15066 {
		position: absolute;
		width: 320px;
		height: 2062px;
		left: 0px;
		top: 560px;
		overflow: visible;
	}
	#CHAAT_22_MOBRectangle_743 {
		fill: rgba(241,241,241,1);
	}
	.CHAAT_22_MOBRectangle_743 {
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 2062px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_MOBCard_01_website_14_web_pc {
		position: absolute;
		width: 301px;
		height: 614px;
		left: 10px;
		top: 45px;
		overflow: visible;
	}
	#CHAAT_22_MOBScroll_Group_10 {
		mix-blend-mode: normal;
		position: absolute;
		width: 301px;
		height: 267px;
		left: 0px;
		top: 347px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHAAT_22_MOBintro_w14_web_pc {
		left: 4px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 294px;
		height: 252px;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(51,51,51,1);
	}
	#CHAAT_22_MOBVision_14_web_pc {
		left: 115px;
		top: 272px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 27px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_MOBaarn-giri-3lGi014_web_pc {
		position: absolute;
		width: 246px;
		height: 252px;
		left: 28px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBCard_01_website_14_web_pc_z {
		position: absolute;
		width: 301px;
		height: 614px;
		left: 10px;
		top: 723px;
		overflow: visible;
	}
	#CHAAT_22_MOBScroll_Group_10_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 301px;
		height: 267px;
		left: 0px;
		top: 347px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHAAT_22_MOBintro_w14_web_pc_ {
		left: 4px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 294px;
		height: 252px;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(51,51,51,1);
	}
	#CHAAT_22_MOBVision_14_web_pc_ {
		left: 115px;
		top: 272px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 27px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_MOBaarn-giri-3lGi014_web_pc_ {
		position: absolute;
		width: 246px;
		height: 252px;
		left: 28px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBCard_01_website_14_web_pc_ {
		position: absolute;
		width: 301px;
		height: 614px;
		left: 10px;
		top: 1401px;
		overflow: visible;
	}
	#CHAAT_22_MOBScroll_Group_10_ba {
		mix-blend-mode: normal;
		position: absolute;
		width: 301px;
		height: 267px;
		left: 0px;
		top: 347px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHAAT_22_MOBintro_w14_web_pc_ba {
		left: 4px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 294px;
		height: 252px;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(51,51,51,1);
	}
	#CHAAT_22_MOBVision_14_web_pc_ba {
		left: 115px;
		top: 272px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 27px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_MOBaarn-giri-3lGi014_web_pc_ba {
		position: absolute;
		width: 246px;
		height: 252px;
		left: 28px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBOur_Chaats {
		left: 83px;
		top: 2679px;
		position: absolute;
		overflow: visible;
		width: 156px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_MOBScroll_Group_78 {
		mix-blend-mode: normal;
		position: absolute;
		width: 310px;
		height: 436px;
		left: 10px;
		top: 2719px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#CHAAT_22_MOBGroup_14872 {
		position: relative;
		width: 213px;
		height: 317px;
		left: 19px;
		top: 53px;
        margin-right: 30px;
		overflow: visible;
	}
	#CHAAT_22_MOBRectangle_688 {
		fill: rgba(255,255,255,1);
		stroke: rgba(255,255,255,0.522);
		stroke-width: 22px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHAAT_22_MOBRectangle_688 {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 273px;
		height: 377px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_MOBpexels-shahbaz-ansari-13063300 {
		position: absolute;
		width: 213px;
		height: 286px;
		left: 0px;
		top: 31px;
		overflow: visible;
	}
	#CHAAT_22_MOBPani_Puri {
		left: 3px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 211px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_MOBLine_27 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHAAT_22_MOBLine_27 {
		overflow: visible;
		position: absolute;
		width: 311px;
		height: 1px;
		left: 0px;
		top: 3218px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHAAT_22_MOBRectangle_744 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHAAT_22_MOBRectangle_744 {
		position: absolute;
		overflow: visible;
		width: 266px;
		height: 207px;
		left: 27px;
		top: 3281px;
		border-radius: 20px;
	}
	#CHAAT_22_MOBGroup_15065 {
		position: absolute;
		width: 507px;
		height: 372px;
		left: 10px;
		top: 3527px;
		overflow: visible;
	}
	#CHAAT_22_MOBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 57px;
		top: 232px;
		overflow: visible;
	}
	#CHAAT_22_MOBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CHAAT_22_MOBmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_MOBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#CHAAT_22_MOBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.CHAAT_22_MOBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.671px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHAAT_22_MOBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.CHAAT_22_MOBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHAAT_22_MOBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.CHAAT_22_MOBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHAAT_22_MOBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CHAAT_22_MOBshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_MOBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.003px;
		overflow: visible;
	}
	#CHAAT_22_MOBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBmed_shadow_space_website_7_bt {
		fill: rgba(255,255,255,1);
	}
	.CHAAT_22_MOBmed_shadow_space_website_7_bt {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_MOBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.986px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#CHAAT_22_MOBn_234567890_mobile_number_shad {
		left: 35px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_MOBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_MOBaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 80px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_MOBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#CHAAT_22_MOBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#CHAAT_22_MOBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 328px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 44px;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	.mediaViewInfo {
		--web-view-name: FlowMasters Mob;
		--web-view-id: FlowMasters_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: FlowMasters_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#FlowMasters_Mob {
		position: absolute;
		width: 390px;
		height: 3683px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: FlowMasters Mob;
		--web-view-id: FlowMasters_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#FM_24_MoBGroup_14927 {
		position: absolute;
		width: 390px;
		height: 420px;
		left: 0px;
		top: 13px;
		overflow: visible;
	}
	#FM_24_MoBstyle_3 {
		position: absolute;
		width: 390px;
		height: 165px;
		left: 0px;
		top: 255px;
		overflow: visible;
	}
	#FM_24_MoBRectangle_84 {
		fill: rgba(249,129,105,1);
	}
	.FM_24_MoBRectangle_84 {
		-webkit-filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		        filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 178px;
		height: 110px;
		left: 30px;
		top: 207px;
	}
	#FM_24_MoBFlowing_Solutions_for_Every_Pi {
		left: 22px;
		top: 52px;
		position: absolute;
		overflow: visible;
		width: 348px;
		height: 32px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: lighter;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#FM_24_MoBExplore_Now_ {
		left: 43px;
		top: 212px;
		position: absolute;
		overflow: visible;
		width: 67px;
		white-space: nowrap;
		line-height: 24px;
		margin-top: -7px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 10px;
		color: rgba(255,255,255,1);
	}
	#FM_24_MoBScroll_Group_42 {
		mix-blend-mode: normal;
		position: absolute;
		width: 369px;
		height: 59px;
		left: 11px;
		top: 116px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FM_24_MoBintro_part {
		opacity: 0.71;
		left: 5px;
		top: 2px;
		position: absolute;
		overflow: visible;
		width: 365px;
		height: 113px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#FM_24_MoBzane-lee-bXbi1jJVDf0-unsplash_ {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 22px;
		top: 0px;
        border-radius: 50%;
		overflow: hidden;
	}
	#FM_24_MoBOperate_Live {
		left: 50px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 51px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#FM_24_MoBGroup_15072 {
		position: absolute;
		width: 374px;
		height: 1849px;
		left: 16px;
		top: 512px;
		overflow: visible;
	}
	#FM_24_MoBGroup_15071 {
		position: absolute;
		width: 374px;
		height: 588px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FM_24_MoBej-strat-VjWi56AWQ9k-unsplash {
		position: absolute;
		width: 230px;
		height: 320.104px;
		left: 73px;
		top: 0px;
		overflow: visible;
	}
	#FM_24_MoBGroup_15067 {
		position: absolute;
		width: 374px;
		height: 239px;
		left: 0px;
		top: 349px;
		overflow: visible;
	}
	#FM_24_MoBScroll_Group_51 {
		mix-blend-mode: normal;
		position: absolute;
		width: 374px;
		height: 182px;
		left: 0px;
		top: 57px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FM_24_MoBElevate_Your_Space_with_Timele {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 355px;
		height: 165px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FM_24_MoBVision {
		left: 11px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FM_24_MoBGroup_15070 {
		position: absolute;
		width: 374px;
		height: 583px;
		left: 0px;
		top: 623px;
		overflow: visible;
	}
	#FM_24_MoBsamuel-sianipar-scUBcasSvbE-un {
		position: absolute;
		width: 230px;
		height: 320.104px;
		left: 64px;
		top: 0px;
		overflow: visible;
	}
	#FM_24_MoBScroll_Group_79 {
		mix-blend-mode: normal;
		position: absolute;
		width: 374px;
		height: 175px;
		left: 0px;
		top: 408px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FM_24_MoBElevate_Your_Space_with_Timele_bk {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 364px;
		height: 165px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FM_24_MoBMission {
		left: 11px;
		top: 351px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FM_24_MoBGroup_15069 {
		position: absolute;
		width: 363px;
		height: 588px;
		left: 6px;
		top: 1261px;
		overflow: visible;
	}
	#FM_24_MoBkyle-glenn-Qo4gei6NvcQ-unsplas {
		position: absolute;
		width: 230px;
		height: 320.104px;
		left: 58px;
		top: 0px;
		overflow: visible;
	}
	#FM_24_MoBGroup_15068 {
		position: absolute;
		width: 363px;
		height: 237px;
		left: 0px;
		top: 351px;
		overflow: visible;
	}
	#FM_24_MoBScroll_Group_80 {
		mix-blend-mode: normal;
		position: absolute;
		width: 363px;
		height: 175px;
		left: 0px;
		top: 62px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FM_24_MoBElevate_Your_Space_with_Timele_bq {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 165px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FM_24_MoBPrinciples {
		left: 11px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 115px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#FM_24_MoBOur_Offerings {
		left: 98px;
		top: 2421px;
		position: absolute;
		overflow: visible;
		width: 195px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#FM_24_MoBScroll_Group_81 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 371px;
		left: 0px;
		top: 2532px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#FM_24_MoBGroup_15053 {
		position: relative;
		width: 251px;
		height: 333px;
		left: 19px;
		top: 19px;
        margin-right: 30px;
		overflow: visible;
	}
	#FM_24_MoBlucas-van-oort-Cm_APC_oD-w-uns {
		position: absolute;
		width: 251px;
		height: 204px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FM_24_MoBGroup_25 {
		position: absolute;
		width: 251px;
		height: 129px;
		left: 0px;
		top: 204px;
		overflow: visible;
	}
	#FM_24_MoBRectangle_72 {
		fill: rgba(241,241,241,1);
	}
	.FM_24_MoBRectangle_72 {
		position: absolute;
		overflow: visible;
		width: 251px;
		height: 129px;
		left: 0px;
		top: 0px;
	}
	#FM_24_MoBRectangle_73 {
		fill: rgba(207,207,207,1);
	}
	.FM_24_MoBRectangle_73 {
		position: absolute;
		overflow: visible;
		width: 251px;
		height: 2px;
		left: 0px;
		top: 127px;
	}
	#FM_24_MoBPlumbing {
		left: 3px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 244px;
		height: 20px;
		line-height: 36px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(26,26,26,1);
	}
	#FM_24_MoBScroll_Group_68 {
		mix-blend-mode: normal;
		position: absolute;
		width: 242px;
		height: 81px;
		left: 3px;
		top: 39px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FM_24_MoBLorem_ipsum_dolor_sit_amet_con {
		left: 4px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 230px;
		height: 103px;
		line-height: 24px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(72,72,72,1);
	}
	#FM_24_MoBGroup_15025 {
		position: absolute;
		width: 347px;
		height: 650px;
		left: 22px;
		top: 3003px;
		overflow: visible;
	}
	#FM_24_MoBGroup_15023 {
		position: absolute;
		width: 347px;
		height: 342px;
		left: 0px;
		top: 308px;
		overflow: visible;
	}
	#FM_24_MoBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 80px;
		top: 213px;
		overflow: visible;
	}
	#FM_24_MoBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#FM_24_MoBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FM_24_MoBmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FM_24_MoBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.191px;
		overflow: visible;
	}
	#FM_24_MoBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.FM_24_MoBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FM_24_MoBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.FM_24_MoBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FM_24_MoBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.FM_24_MoBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FM_24_MoBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#FM_24_MoBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FM_24_MoBshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FM_24_MoBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#FM_24_MoBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FM_24_MoBmed_shadow_space_website_7_cf {
		fill: rgba(255,255,255,1);
	}
	.FM_24_MoBmed_shadow_space_website_7_cf {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FM_24_MoBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.986px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#FM_24_MoBn_234567890_mobile_number_shad {
		left: 48px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FM_24_MoBaffanghanioperatelive_email_sh {
		left: 40px;
		top: 61px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FM_24_MoBaddress_med_shadow_space_websi {
		left: 35px;
		top: 120px;
		position: absolute;
		overflow: visible;
		width: 313px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FM_24_MoBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 120px;
		overflow: visible;
	}
	#FM_24_MoBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 60px;
		overflow: visible;
	}
	#FM_24_MoBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FM_24_MoBn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 298px;
		position: absolute;
		overflow: visible;
		width: 348px;
		height: 44px;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(173,173,173,1);
	}
	#FM_24_MoBVisit_Us_ {
		left: 129px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FM_24_MoBRectangle_735 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FM_24_MoBRectangle_735 {
		position: absolute;
		overflow: visible;
		width: 341px;
		height: 222px;
		left: 3px;
		top: 45px;
		border-radius: 20px;
	}
	.mediaViewInfo {
		--web-view-name: CaptureSphere Mob;
		--web-view-id: CaptureSphere_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: CaptureSphere_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#CaptureSphere_Mob {
		position: absolute;
		width: 390px;
		height: 4311px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: CaptureSphere Mob;
		--web-view-id: CaptureSphere_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#capTure_25_MobGroup_15085 {
		position: absolute;
		width: 390px;
		height: 495px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_MobRectangle_749 {
		fill: rgba(255,255,255,1);
		stroke: rgba(255,255,255,1);
		stroke-width: 10px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.capTure_25_MobRectangle_749 {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 495px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_MobWe_are_Experienced {
		left: 10px;
		top: 91px;
		position: absolute;
		overflow: visible;
		width: 279px;
		white-space: nowrap;
		text-align: left;
		font-family: Museo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
		letter-spacing: 0.35px;
	}
	#capTure_25_MobPhotographers {
		left: 10px;
		top: 131px;
		position: absolute;
		overflow: visible;
		width: 262px;
		white-space: nowrap;
		line-height: 100px;
		margin-top: -30px;
		text-align: left;
		font-family: Baskerville;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_MobSubtraction_8 {
		fill: rgba(0,0,0,1);
	}
	.capTure_25_MobSubtraction_8 {
        border-radius: 50%;
		overflow: hidden;
		position: absolute;
		width: 30px;
		height: 30.01px;
		left: 31px;
		top: 25px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#capTure_25_MobOperate_Live {
		left: 76px;
		top: 27px;
		position: absolute;
		overflow: visible;
		width: 120px;
		white-space: nowrap;
		text-align: left;
		font-family: Museo;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
		letter-spacing: 0.35px;
	}
	#capTure_25_MobGroup_15077 {
		position: absolute;
		width: 390px;
		height: 336.009px;
		left: 0px;
		top: 153px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_83 {
		position: absolute;
		width: 83.208px;
		height: 142.279px;
		left: 306.792px;
		top: 79.08px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_84 {
		position: absolute;
		width: 214.69px;
		height: 109.568px;
		left: 175.309px;
		top: 226.441px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_85 {
		position: absolute;
		width: 82.573px;
		height: 84.479px;
		left: 219.137px;
		top: 38.016px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_86 {
		position: absolute;
		width: 82.573px;
		height: 90.58px;
		left: 0px;
		top: 245.43px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_87 {
		position: absolute;
		width: 126.401px;
		height: 93.885px;
		left: 175.309px;
		top: 127.475px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_88 {
		position: absolute;
		width: 82.573px;
		height: 153.396px;
		left: 87.655px;
		top: 182.613px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_89 {
		position: absolute;
		width: 83.208px;
		height: 73.998px;
		left: 306.792px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_MobGroup_15084 {
		position: absolute;
		width: 390px;
		height: 1096.896px;
		left: 0px;
		top: 595px;
		overflow: visible;
	}
	#capTure_25_MobRectangle_391 {
		fill: rgba(247,249,251,1);
	}
	.capTure_25_MobRectangle_391 {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 1096.896px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_MobGroup_57 {
		position: absolute;
		width: 321.213px;
		height: 182px;
		left: 22px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_MobWe_are__Professional {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 267px;
		white-space: nowrap;
		text-align: left;
		font-family: Baskerville;
		font-style: italic;
		font-weight: normal;
		font-size: 52px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_Mobphotographers {
		left: 98.213px;
		top: 136px;
		position: absolute;
		overflow: visible;
		width: 224px;
		white-space: nowrap;
		text-align: left;
		font-family: Baskerville;
		font-style: normal;
		font-weight: normal;
		font-size: 34px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_MobScroll_Group_85 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 308px;
		left: 0px;
		top: 239px;
		overflow: hidden;
	}
	#capTure_25_MobLorem_Ipsum_is_simply_dummy_te {
		opacity: 0.8;
		left: 10px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 381px;
		height: 308px;
		line-height: 24px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Aktiv Grotesk;
		font-style: normal;
		font-weight: lighter;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_MobScroll_Group_86 {
		mix-blend-mode: normal;
		position: absolute;
		width: 380.982px;
		height: 451.246px;
		left: 0.259px;
		top: 585px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#capTure_25_MobGroup_15078 {
		position: absolute;
		width: 935.482px;
		height: 397.746px;
		left: 18px;
		top: 19px;
		overflow: visible;
	}
	#capTure_25_Moboffering_card_1_shadow_space_w {
		position: absolute;
		width: 281.482px;
		height: 397.746px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_Mobcard_bg_offeringshadow_space_w {
		fill: rgba(255,255,255,1);
	}
	.capTure_25_Mobcard_bg_offeringshadow_space_w {
		-webkit-filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		        filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 296.982px;
		height: 413.246px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_Mobhalf_circle_shadow_space_websi {
		fill: rgba(46,46,46,1);
	}
	.capTure_25_Mobhalf_circle_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 22px;
		height: 42px;
		left: 23px;
		top: 18px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#capTure_25_Mobcircle_deign_shadow_space_webs {
		fill: rgba(46,46,46,1);
	}
	.capTure_25_Mobcircle_deign_shadow_space_webs {
		position: absolute;
		overflow: visible;
		width: 8.567px;
		height: 8.566px;
		left: 9.791px;
		top: 35.492px;
	}
	#capTure_25_Moboofering_lbl_med_shadow_space_ {
		left: 55px;
		top: 28px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_MobScroll_Group_82 {
		mix-blend-mode: normal;
		position: absolute;
		width: 270px;
		height: 316px;
		left: 6px;
		top: 75px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#capTure_25_Mobintro_lbl_14_web_pc {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 316px;
		line-height: 26px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(51,51,51,1);
	}
	#capTure_25_Moboffering_card_1_shadow_space_w_bz {
		position: absolute;
		width: 281.482px;
		height: 397.746px;
		left: 327px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_Mobcard_bg_offeringshadow_space_w_b {
		fill: rgba(255,255,255,1);
	}
	.capTure_25_Mobcard_bg_offeringshadow_space_w_b {
		-webkit-filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		        filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 296.982px;
		height: 413.246px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_Mobhalf_circle_shadow_space_websi_b {
		fill: rgba(46,46,46,1);
	}
	.capTure_25_Mobhalf_circle_shadow_space_websi_b {
		overflow: visible;
		position: absolute;
		width: 22px;
		height: 42px;
		left: 23px;
		top: 18px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#capTure_25_Mobcircle_deign_shadow_space_webs_b {
		fill: rgba(46,46,46,1);
	}
	.capTure_25_Mobcircle_deign_shadow_space_webs_b {
		position: absolute;
		overflow: visible;
		width: 8.567px;
		height: 8.566px;
		left: 9.791px;
		top: 35.492px;
	}
	#capTure_25_Moboofering_lbl_med_shadow_space__b {
		left: 55px;
		top: 28px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_MobScroll_Group_83 {
		mix-blend-mode: normal;
		position: absolute;
		width: 270px;
		height: 316px;
		left: 5px;
		top: 75px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#capTure_25_Mobintro_lbl_14_web_pc_b {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 316px;
		line-height: 26px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(51,51,51,1);
	}
	#capTure_25_Moboffering_card_1_shadow_space_w_b {
		position: absolute;
		width: 281.482px;
		height: 397.746px;
		left: 654px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_Mobcard_bg_offeringshadow_space_w_ca {
		fill: rgba(255,255,255,1);
	}
	.capTure_25_Mobcard_bg_offeringshadow_space_w_ca {
		-webkit-filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		        filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 296.982px;
		height: 413.246px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_Mobhalf_circle_shadow_space_websi_ca {
		fill: rgba(46,46,46,1);
	}
	.capTure_25_Mobhalf_circle_shadow_space_websi_ca {
		overflow: visible;
		position: absolute;
		width: 22px;
		height: 42px;
		left: 23px;
		top: 18px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#capTure_25_Mobcircle_deign_shadow_space_webs_ca {
		fill: rgba(46,46,46,1);
	}
	.capTure_25_Mobcircle_deign_shadow_space_webs_ca {
		position: absolute;
		overflow: visible;
		width: 8.567px;
		height: 8.566px;
		left: 9.791px;
		top: 35.492px;
	}
	#capTure_25_Moboofering_lbl_med_shadow_space__ca {
		left: 55px;
		top: 28px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_MobScroll_Group_84 {
		mix-blend-mode: normal;
		position: absolute;
		width: 270px;
		height: 316px;
		left: 6px;
		top: 75px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#capTure_25_Mobintro_lbl_14_web_pc_cc {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 316px;
		line-height: 26px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(51,51,51,1);
	}
	#capTure_25_MobGroup_15083 {
		position: absolute;
		width: 390px;
		height: 1754.229px;
		left: 0px;
		top: 1792.5px;
		overflow: visible;
	}
	#capTure_25_MobRectangle_489 {
		fill: rgba(247,249,251,1);
	}
	.capTure_25_MobRectangle_489 {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 1754.229px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_MobEvents_we_Cover {
		left: 55px;
		top: 61.064px;
		position: absolute;
		overflow: visible;
		width: 282px;
		white-space: nowrap;
		text-align: left;
		font-family: Baskerville;
		font-style: italic;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_MobGroup_15079 {
		position: absolute;
		width: 331.291px;
		height: 1395.615px;
		left: 32px;
		top: 204.043px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_106 {
		position: absolute;
		width: 307.353px;
		height: 272.07px;
		left: 9.323px;
		top: 528.053px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_108 {
		position: absolute;
		width: 307.353px;
		height: 181.902px;
		left: 9.323px;
		top: 837.561px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_111 {
		position: absolute;
		width: 163px;
		height: 266.172px;
		left: 0px;
		top: 252.402px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_112 {
		position: absolute;
		width: 235.219px;
		height: 338.715px;
		left: 9.323px;
		top: 1056.9px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_115 {
		position: absolute;
		width: 122.314px;
		height: 235.219px;
		left: 208.977px;
		top: 258.402px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_116 {
		position: absolute;
		width: 235.219px;
		height: 217.971px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_MobGroup_60 {
		position: absolute;
		width: 239px;
		height: 50px;
		left: 76px;
		top: 1660.5px;
		overflow: visible;
	}
	#capTure_25_MobRectangle_196 {
		fill: rgba(0,0,0,1);
	}
	.capTure_25_MobRectangle_196 {
		-webkit-filter: drop-shadow(0px 15px 20px rgba(0, 0, 0, 0.078));
		        filter: drop-shadow(0px 15px 20px rgba(0, 0, 0, 0.078));
		position: absolute;
		overflow: visible;
		width: 299px;
		height: 110px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_MobGroup_15080 {
		position: absolute;
		width: 211.049px;
		height: 22px;
		left: 14px;
		top: 14px;
		overflow: visible;
	}
	#capTure_25_MobLoad_more_Images {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 144px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Baskerville;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.15px;
	}
	#capTure_25_MobGroup_13 {
		position: absolute;
		width: 20px;
		height: 13.098px;
		left: 191.049px;
		top: 4.471px;
		overflow: visible;
	}
	#capTure_25_MobPath_13 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.capTure_25_MobPath_13 {
		overflow: visible;
		position: absolute;
		width: 13.098px;
		height: 6.549px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,10.1763,3.2744) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,10.1763,3.2744) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#capTure_25_MobLine_14 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.capTure_25_MobLine_14 {
		overflow: visible;
		position: absolute;
		width: 19.451px;
		height: 1px;
		left: 0px;
		top: 6.549px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#capTure_25_MobGroup_15082 {
		position: absolute;
		width: 390px;
		height: 664px;
		left: 0px;
		top: 3647px;
		overflow: visible;
	}
	#capTure_25_MobRectangle_747 {
		fill: rgba(255,255,255,1);
		stroke: rgba(255,255,255,1);
		stroke-width: 10px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.capTure_25_MobRectangle_747 {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 664px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_MobGroup_15081 {
		position: absolute;
		width: 390px;
		height: 279px;
		left: 0px;
		top: 33px;
		overflow: visible;
	}
	#capTure_25_Mobkisspng-paper-area-rectangle-s {
		position: absolute;
		width: 390px;
		height: 279px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_MobRectangle_748 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.capTure_25_MobRectangle_748 {
		position: absolute;
		overflow: visible;
		width: 314px;
		height: 209px;
		left: 38px;
		top: 32px;
	}
	#capTure_25_MobGroup_15023 {
		position: absolute;
		width: 347px;
		height: 342px;
		left: 22px;
		top: 312px;
		overflow: visible;
	}
	#capTure_25_Mobsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 80px;
		top: 213px;
		overflow: visible;
	}
	#capTure_25_Moblinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_Mobmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.capTure_25_Mobmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_Moblinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.191px;
		overflow: visible;
	}
	#capTure_25_Mobn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.capTure_25_Mobn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#capTure_25_Moblinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.capTure_25_Moblinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#capTure_25_Mobtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.capTure_25_Mobtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#capTure_25_MobInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_Mobshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.capTure_25_Mobshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_Mobinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#capTure_25_Mobwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_Mobmed_shadow_space_website_7_db {
		fill: rgba(255,255,255,1);
	}
	.capTure_25_Mobmed_shadow_space_website_7_db {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_Mobwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.986px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#capTure_25_Mobn_234567890_mobile_number_shad {
		left: 48px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_Mobaffanghanioperatelive_email_sh {
		left: 40px;
		top: 61px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_Mobaddress_med_shadow_space_websi {
		left: 35px;
		top: 120px;
		position: absolute;
		overflow: visible;
		width: 313px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_Moblocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 120px;
		overflow: visible;
	}
	#capTure_25_Mobemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 60px;
		overflow: visible;
	}
	#capTure_25_Mobphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_Mobn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 298px;
		position: absolute;
		overflow: visible;
		width: 348px;
		height: 44px;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(173,173,173,1);
	}
	#capTure_25_MobVisit_Us_ {
		left: 159px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Baskerville;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
		letter-spacing: 0.15px;
	}

	.mediaViewInfo {
		--web-view-name: PlantPlateDelights Mob;
		--web-view-id: PlantPlateDelights_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: PlantPlateDelights_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#PlantPlateDelights_Mob {
		position: absolute;
		width: 390px;
		height: 3455px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: PlantPlateDelights Mob;
		--web-view-id: PlantPlateDelights_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#PPD_MoB_26Group_15098 {
		position: absolute;
		width: 390px;
		height: 637px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Mask_Group_1 {
		position: absolute;
		width: 390px;
		height: 358px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Group_15092 {
		position: absolute;
		width: 127px;
		height: 35px;
		left: 24px;
		top: 602px;
		overflow: visible;
	}
	#PPD_MoB_26Rectangle_3 {
		fill: rgba(103,123,200,1);
	}
	.PPD_MoB_26Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 127px;
		height: 35px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Order_Now {
		left: 36px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 56px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		color: rgba(255,255,255,1);
	}
	#PPD_MoB_26Have_no_time_to_prepare_food {
		left: 20px;
		top: 414px;
		position: absolute;
		overflow: visible;
		width: 283px;
		white-space: nowrap;
		line-height: 49px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 38px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Choose_one_of_our_plans_enter_ {
		opacity: 0.5;
		left: 24px;
		top: 539px;
		position: absolute;
		overflow: visible;
		width: 303px;
		white-space: nowrap;
		line-height: 21px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26FOODCOURT {
		left: 20px;
		top: 358px;
		position: absolute;
		overflow: visible;
		width: 146px;
		white-space: nowrap;
		line-height: 37px;
		margin-top: -7px;
		text-align: left;
		font-family: Semplicità;
		font-style: normal;
		font-weight: bold;
		font-size: 23px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Group_15099 {
		position: absolute;
		width: 376.647px;
		height: 751.001px;
		left: 0px;
		top: 752px;
		overflow: visible;
	}
	#PPD_MoB_26Mask_Group_2 {
		position: absolute;
		width: 365.726px;
		height: 280.805px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Group_15093 {
		position: absolute;
		width: 363.647px;
		height: 433.001px;
		left: 13px;
		top: 318px;
		overflow: visible;
	}
	#PPD_MoB_26Group_4 {
		position: absolute;
		width: 151px;
		height: 42px;
		left: 0px;
		top: 391.002px;
		overflow: visible;
	}
	#PPD_MoB_26Rectangle_6 {
		fill: rgba(103,123,200,1);
	}
	.PPD_MoB_26Rectangle_6 {
		position: absolute;
		overflow: visible;
		width: 151px;
		height: 42px;
		left: 0px;
		top: -0.002px;
	}
	#PPD_MoB_26Order_Now_bi {
		left: 38px;
		top: 10.998px;
		position: absolute;
		overflow: visible;
		width: 66px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#PPD_MoB_26Group_12 {
		position: absolute;
		width: 225px;
		height: 75.048px;
		left: 2px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Line_2 {
		fill: transparent;
		stroke: rgba(103,123,200,1);
		stroke-width: 7px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Line_2 {
		overflow: visible;
		position: absolute;
		width: 171.373px;
		height: 7px;
		left: 3.09px;
		top: 75.047px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26OUR_FOOD {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 226px;
		white-space: nowrap;
		line-height: 59px;
		margin-top: -7px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 45px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Choose_one_of_our_plans_enter__bm {
		opacity: 0.5;
		left: 61.647px;
		top: 226.139px;
		position: absolute;
		overflow: visible;
		width: 303px;
		white-space: nowrap;
		line-height: 20px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Choose_one_of_our_plans_enter__bn {
		opacity: 0.5;
		left: 61.647px;
		top: 156.697px;
		position: absolute;
		overflow: visible;
		width: 258px;
		white-space: nowrap;
		line-height: 20px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Choose_one_of_our_plans_enter__bo {
		opacity: 0.5;
		left: 61.647px;
		top: 329.477px;
		position: absolute;
		overflow: visible;
		width: 258px;
		white-space: nowrap;
		line-height: 20px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Group_9 {
		position: absolute;
		width: 158.411px;
		height: 37.727px;
		left: 1.502px;
		top: 118.424px;
		overflow: visible;
	}
	#PPD_MoB_26Appetizers {
		left: 57.411px;
		top: 4.375px;
		position: absolute;
		overflow: visible;
		width: 102px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Group_6 {
		position: absolute;
		width: 37.727px;
		height: 37.727px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Ellipse_2 {
		fill: rgba(103,123,200,1);
	}
	.PPD_MoB_26Ellipse_2 {
		position: absolute;
		overflow: visible;
		width: 37.727px;
		height: 37.727px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Group_5 {
		position: absolute;
		width: 14.103px;
		height: 14.103px;
		left: 11.755px;
		top: 11.756px;
		overflow: visible;
	}
	#PPD_MoB_26Line_3 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Line_3 {
		overflow: visible;
		position: absolute;
		width: 18.104px;
		height: 4px;
		left: 0px;
		top: 7.053px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26Line_4 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Line_4 {
		overflow: visible;
		position: absolute;
		width: 14.103px;
		height: 1px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,0,7.0517) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,0,7.0517) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Group_10 {
		position: absolute;
		width: 188.145px;
		height: 37.727px;
		left: 1.502px;
		top: 187.863px;
		overflow: visible;
	}
	#PPD_MoB_26Main_Courses {
		left: 60.145px;
		top: 4.375px;
		position: absolute;
		overflow: visible;
		width: 129px;
		white-space: nowrap;
		line-height: 46px;
		margin-top: -13px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Group_7 {
		position: absolute;
		width: 37.727px;
		height: 37.727px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Ellipse_2_bz {
		fill: rgba(103,123,200,1);
	}
	.PPD_MoB_26Ellipse_2_bz {
		position: absolute;
		overflow: visible;
		width: 37.727px;
		height: 37.727px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Group_5_b {
		position: absolute;
		width: 14.103px;
		height: 14.103px;
		left: 11.755px;
		top: 11.756px;
		overflow: visible;
	}
	#PPD_MoB_26Line_3_b {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Line_3_b {
		overflow: visible;
		position: absolute;
		width: 18.104px;
		height: 4px;
		left: 0px;
		top: 7.053px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26Line_4_b {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Line_4_b {
		overflow: visible;
		position: absolute;
		width: 14.103px;
		height: 1px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,0,7.0517) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,0,7.0517) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Group_11 {
		position: absolute;
		width: 141.145px;
		height: 37.727px;
		left: 1.502px;
		top: 279.721px;
		overflow: visible;
	}
	#PPD_MoB_26Desserts {
		left: 60.145px;
		top: 4.373px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Group_8 {
		position: absolute;
		width: 37.727px;
		height: 37.727px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Ellipse_2_b {
		fill: rgba(103,123,200,1);
	}
	.PPD_MoB_26Ellipse_2_b {
		position: absolute;
		overflow: visible;
		width: 37.727px;
		height: 37.727px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Group_5_ca {
		position: absolute;
		width: 14.103px;
		height: 14.103px;
		left: 11.755px;
		top: 11.756px;
		overflow: visible;
	}
	#PPD_MoB_26Line_3_ca {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Line_3_ca {
		overflow: visible;
		position: absolute;
		width: 18.104px;
		height: 4px;
		left: 0px;
		top: 7.051px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26Line_4_ca {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Line_4_ca {
		overflow: visible;
		position: absolute;
		width: 14.103px;
		height: 1px;
		-webkit-transform: translate(0px, -0.001px) matrix(1,0,0,1,0,7.0517) rotate(90deg);
		        transform: translate(0px, -0.001px) matrix(1,0,0,1,0,7.0517) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Group_15100 {
		position: absolute;
		width: 390.253px;
		height: 877.171px;
		left: 0px;
		top: 1645px;
		overflow: visible;
	}
	#PPD_MoB_26Group_15088 {
		position: absolute;
		width: 390.253px;
		height: 877.171px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Group_15094 {
		position: absolute;
		width: 390px;
		height: 171.516px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Path_7778 {
		fill: rgba(122,147,243,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Path_7778 {
		overflow: visible;
		position: absolute;
		width: 388.588px;
		height: 171.516px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26Mask_Group_7 {
		opacity: 0.1;
		position: absolute;
		width: 388.588px;
		height: 171.516px;
		left: 1.412px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Group_13 {
		position: absolute;
		width: 94.265px;
		height: 43.509px;
		left: 147.705px;
		top: 13.002px;
		overflow: visible;
	}
	#PPD_MoB_26Line_2_cg {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 7px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Line_2_cg {
		overflow: visible;
		position: absolute;
		width: 100.939px;
		height: 7px;
		left: 0.326px;
		top: 43.508px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26PLANS {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Scroll_Group_87 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390.253px;
		height: 784.913px;
		left: 0px;
		top: 92.258px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PPD_MoB_26Group_143 {
		position: absolute;
		width: 344.069px;
		height: 267.242px;
		left: 22.965px;
		top: 27px;
		overflow: visible;
	}
	#PPD_MoB_26Group_15095 {
		position: absolute;
		width: 344.069px;
		height: 267.242px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Rectangle_8 {
		fill: rgba(255,255,255,1);
	}
	.PPD_MoB_26Rectangle_8 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 362.069px;
		height: 285.242px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Mask_Group_3 {
		position: absolute;
		width: 344.069px;
		height: 267.242px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26victoria-shes-4MEL9XS-3JQ-unsp {
		opacity: 0.2;
		fill: rgba(0,0,0,1);
	}
	.PPD_MoB_26victoria-shes-4MEL9XS-3JQ-unsp {
		position: absolute;
		overflow: visible;
		width: 344.069px;
		height: 267.242px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Order_1 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		left: 14.874px;
		top: 18.775px;
		position: absolute;
		overflow: visible;
		width: 64px;
		white-space: nowrap;
		line-height: 31px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Hobo Std;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#PPD_MoB_26Group_15097 {
		position: absolute;
		width: 390.252px;
		height: 974.211px;
		left: 0px;
		top: 2604.172px;
		overflow: visible;
	}
	#PPD_MoB_26VISIT_US_ {
		left: 127px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 137px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Text_Background {
		fill: rgba(255,255,255,1);
	}
	.PPD_MoB_26Text_Background {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 408.252px;
		height: 265.957px;
		left: 0px;
		top: 97.254px;
	}
	#PPD_MoB_26Group_15096 {
		position: absolute;
		width: 390.252px;
		height: 629px;
		left: 0px;
		top: 345.211px;
		overflow: visible;
	}
	#PPD_MoB_26sonny-mauricio-yhc4pSbl01A-uns {
		position: absolute;
		width: 390px;
		height: 629px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26sonny-mauricio-yhc4pSbl01A-uns_cu {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26sonny-mauricio-yhc4pSbl01A-uns_cu {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 629px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Border {
		fill: rgba(204,204,204,1);
	}
	.PPD_MoB_26Border {
		overflow: visible;
		position: absolute;
		width: 390.252px;
		height: 2.924px;
		left: 0px;
		top: 419.477px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26n_234567890_mobile_number_shad {
		left: 57px;
		top: 44px;
		position: absolute;
		overflow: visible;
		width: 71px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#PPD_MoB_26affanghanioperatelive_email_sh {
		left: 57px;
		top: 90px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 17px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#PPD_MoB_26address_med_shadow_space_websi {
		left: 57px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 325px;
		height: 51px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#PPD_MoB_26location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 22px;
		top: 142px;
		overflow: visible;
	}
	#PPD_MoB_26email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 22px;
		top: 89px;
		overflow: visible;
	}
	#PPD_MoB_26phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 22px;
		top: 35px;
		overflow: visible;
	}
	#PPD_MoB_26Follow_Us {
		left: 149.126px;
		top: 266.43px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		line-height: 25px;
		margin-top: -2.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PPD_MoB_26socials_grp_shadow_space_websi {
		position: absolute;
		width: 189px;
		height: 48px;
		left: 100.5px;
		top: 315px;
		overflow: visible;
	}
	#PPD_MoB_26linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 141px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PPD_MoB_26med_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26linked_in_shadow_space_website {
		position: absolute;
		width: 23.525px;
		height: 21.386px;
		left: 12.673px;
		top: 13.119px;
		overflow: visible;
	}
	#PPD_MoB_26n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.PPD_MoB_26n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.547px;
		height: 14.668px;
		left: 7.978px;
		top: 6.717px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.PPD_MoB_26linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.585px;
		height: 14.529px;
		left: 0.594px;
		top: 6.857px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.PPD_MoB_26top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.566px;
		height: 5.041px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26Insta_grp_shadow_space_website {
		position: absolute;
		width: 49px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PPD_MoB_26shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 58px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26instagramshadow_space_website_ {
		position: absolute;
		width: 23.55px;
		height: 23.551px;
		left: 13.07px;
		top: 12.516px;
		overflow: visible;
	}
	#PPD_MoB_26whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26med_shadow_space_website_7_de {
		fill: rgba(255,255,255,1);
	}
	.PPD_MoB_26med_shadow_space_website_7_de {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 24.154px;
		height: 24.154px;
		left: 11.863px;
		top: 11.91px;
		overflow: visible;
	}
	#PPD_MoB_26n_Copyright_Operate_Live_OPC_P {
		left: 8px;
		top: 444px;
		position: absolute;
		overflow: visible;
		width: 383px;
		height: 36px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	.mediaViewInfo {
		--web-view-name: ElectroTech Solutions Mob;
		--web-view-id: ElectroTech_Solutions_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ElectroTech_Solutions_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ElectroTech_Solutions_Mob {
		position: absolute;
		width: 390px;
		height: 3663px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: ElectroTech Solutions Mob;
		--web-view-id: ElectroTech_Solutions_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#ETS_27_MOBGroup_15110 {
		position: absolute;
		width: 429.281px;
		height: 646.021px;
		left: -39.281px;
		top: -39.021px;
		overflow: visible;
	}
	#ETS_27_MOBcopy-space-colorful-sewing-thr {
		position: absolute;
		width: 274.562px;
		height: 172.021px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBGroup_15102 {
		position: absolute;
		width: 292px;
		height: 292px;
		left: 137.281px;
		top: 128.021px;
		overflow: visible;
	}
	#ETS_27_MOBRectangle_747 {
		fill: rgba(237,149,75,1);
	}
	.ETS_27_MOBRectangle_747 {
		position: absolute;
		overflow: visible;
		width: 186px;
		height: 186px;
		left: 106px;
		top: 0px;
	}
	#ETS_27_MOBelectrical-shielded-cable-with {
		position: absolute;
		width: 252px;
		height: 252px;
		left: 0px;
		top: 40px;
		overflow: visible;
	}
	#ETS_27_MOBPowering_Your_World__Expert_El {
		left: 61.281px;
		top: 466.021px;
		position: absolute;
		overflow: visible;
		width: 348px;
		white-space: nowrap;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#ETS_27_MOBJoin_the_Spark_of_Excellence__ {
		opacity: 0.5;
		left: 61.281px;
		top: 606.021px;
		position: absolute;
		overflow: visible;
		width: 232px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(36,36,36,1);
	}
	#ETS_27_MOBGroup_15112 {
		position: absolute;
		width: 325px;
		height: 40px;
		left: 96.281px;
		top: 51.021px;
		overflow: visible;
	}
	#ETS_27_MOBAnime_Edit {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
        border-radius: 50%;
		overflow: hidden;
	}
	#ETS_27_MOBElectician {
		left: 52px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 274px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBGroup_15109 {
		position: absolute;
		width: 379px;
		height: 1707px;
		left: 11px;
		top: 708px;
		overflow: visible;
	}
	#ETS_27_MOBGroup_15105 {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBmika-baumeister-dp9AxaKHdVg-un {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		        filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBGroup_15104 {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#ETS_27_MOBScroll_Group_28_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#ETS_27_MOBIt_is_a_long_established_fact__ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#ETS_27_MOBVISION_ {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBGroup_15106 {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBmika-baumeister-dp9AxaKHdVg-un_ {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		        filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBGroup_15104_ {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#ETS_27_MOBScroll_Group_28_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#ETS_27_MOBIt_is_a_long_established_fact__ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#ETS_27_MOBVISION_ {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBGroup_15106_ba {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 592px;
		overflow: visible;
	}
	#ETS_27_MOBpatrick-tomasso-1NTFSnV-KLs-un {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		        filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBGroup_15104_bc {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#ETS_27_MOBScroll_Group_28_bd {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#ETS_27_MOBIt_is_a_long_established_fact__be {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#ETS_27_MOBMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBGroup_15106_bg {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 1184px;
		overflow: visible;
	}
	#ETS_27_MOBframes-for-your-heart-iOLHAIax {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		        filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBGroup_15104_bi {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#ETS_27_MOBScroll_Group_28_bj {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#ETS_27_MOBIt_is_a_long_established_fact__bk {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#ETS_27_MOBPRINCIPLES {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBGroup_15107 {
		position: absolute;
		width: 390px;
		height: 403.998px;
		left: 0px;
		top: 2526px;
		overflow: visible;
	}
	#ETS_27_MOBOur_Services {
		left: 113px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 165px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#ETS_27_MOBsteve-johnson-hokONTrHIAQ-unsp {
		position: absolute;
		width: 390px;
		height: 324px;
		left: 0px;
		top: 79.998px;
		overflow: visible;
	}
	#ETS_27_MOBScroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 377px;
		height: 324px;
		left: 13px;
		top: 79.998px;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
	}
	#ETS_27_MOBn_ {
		position: relative;
        margin-right: 30px;
		width: 247px;
		height: 275px;
		left: 25px;
		top: 21.002px;
		overflow: visible;
	}
	#ETS_27_MOBMask_Group_22 {
		position: absolute;
		width: 247px;
		height: 229px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBRectangle_664 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBRectangle_664 {
		position: absolute;
		overflow: visible;
		width: 247px;
		height: 46px;
		left: 0px;
		top: 229px;
	}
	#ETS_27_MOBSomething {
		left: 9px;
		top: 242px;
		position: absolute;
		overflow: visible;
		width: 233px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
		text-transform: uppercase;
	}
	#ETS_27_MOBGroup_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 101px;
		top: 214px;
		overflow: visible;
	}
	#ETS_27_MOBEllipse_38 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBEllipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#ETS_27_MOBEllipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBEllipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#ETS_27_MOBEllipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBEllipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#ETS_27_MOBEllipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBEllipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#ETS_27_MOBEllipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBEllipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#ETS_27_MOBGroup_15108 {
		position: absolute;
		width: 358px;
		height: 590.002px;
		left: 16px;
		top: 3062.998px;
		overflow: visible;
	}
	#ETS_27_MOBGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 30px;
		top: 61.002px;
		overflow: visible;
	}
	#ETS_27_MOBRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#ETS_27_MOBGroup_15050 {
		position: absolute;
		width: 358px;
		height: 316px;
		left: 0px;
		top: 274.002px;
		overflow: visible;
	}
	#ETS_27_MOBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 194px;
		overflow: visible;
	}
	#ETS_27_MOBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#ETS_27_MOBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#ETS_27_MOBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.ETS_27_MOBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#ETS_27_MOBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.ETS_27_MOBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#ETS_27_MOBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.ETS_27_MOBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#ETS_27_MOBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#ETS_27_MOBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#ETS_27_MOBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBmed_shadow_space_website_7_cf {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBmed_shadow_space_website_7_cf {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#ETS_27_MOBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#ETS_27_MOBn_234567890_mobile_number_shad {
		left: 48px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#ETS_27_MOBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#ETS_27_MOBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBVisit_Us_ {
		left: 118px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	.mediaViewInfo {
		--web-view-name: VerdantHarvestGrove Mob;
		--web-view-id: VerdantHarvestGrove_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: VerdantHarvestGrove_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#VerdantHarvestGrove_Mob {
		position: absolute;
		width: 390px;
		height: 3702px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: VerdantHarvestGrove Mob;
		--web-view-id: VerdantHarvestGrove_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#VHG_MOB_29mk-s-tHHFiw6GNEU-unsplash {
		position: absolute;
		width: 390px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29Rectangle_748 {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
	}
	.VHG_MOB_29Rectangle_748 {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#VHG_MOB_29amjd-rdwan-CEAPSFlMLbk-unsplas {
		position: absolute;
		width: 350px;
		height: 350px;
		left: 20px;
		top: 463px;
		overflow: visible;
	}
	#VHG_MOB_29Anime_Edit {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 61px;
		top: 45px;
        border-radius: 50%;
		overflow: hidden;
	}
	#VHG_MOB_29Your_Business_Name {
		left: 131px;
		top: 64px;
		position: absolute;
		overflow: visible;
		width: 254px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#VHG_MOB_29Cultivating_Wellness_from_Farm {
		left: 11px;
		top: 138px;
		position: absolute;
		overflow: visible;
		width: 380px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#VHG_MOB_29Group_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 18px;
		top: 283px;
		overflow: visible;
	}
	#VHG_MOB_29Rectangle_3 {
		fill: rgba(233,153,15,1);
	}
	.VHG_MOB_29Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#VHG_MOB_29Order_Now {
		left: 45px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 81px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#VHG_MOB_29Group_15123 {
		position: absolute;
		width: 390px;
		height: 1636px;
		left: 0px;
		top: 863px;
		overflow: visible;
	}
	#VHG_MOB_29Group_15114 {
		position: absolute;
		width: 390px;
		height: 512px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29tandem-x-visuals-dqZ0JREudKw-u {
		position: absolute;
		width: 250px;
		height: 250px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 174px;
		left: 0px;
		top: 338px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#VHG_MOB_29It_is_a_long_established_fact_ {
		left: 24px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 189px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#VHG_MOB_29VISION {
		left: 24px;
		top: 273px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_MOB_29Group_15120 {
		position: absolute;
		width: 390px;
		height: 512px;
		left: 0px;
		top: 562px;
		overflow: visible;
	}
	#VHG_MOB_29mk-s-7GeBVqFwOl4-unsplash {
		position: absolute;
		width: 250px;
		height: 250px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29Scroll_Group_28_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 174px;
		left: 0px;
		top: 338px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#VHG_MOB_29It_is_a_long_established_fact__ {
		left: 24px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 189px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#VHG_MOB_29MISSION {
		left: 24px;
		top: 273px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_MOB_29Group_15121 {
		position: absolute;
		width: 390px;
		height: 512px;
		left: 0px;
		top: 1124px;
		overflow: visible;
	}
	#VHG_MOB_29ricardo-gomez-angel-I5XthC605H {
		position: absolute;
		width: 250px;
		height: 250px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29Scroll_Group_28_bd {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 174px;
		left: 0px;
		top: 338px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#VHG_MOB_29It_is_a_long_established_fact__be {
		left: 24px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 189px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#VHG_MOB_29PRINCIPLES {
		left: 24px;
		top: 273px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_MOB_29Scroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 377px;
		height: 324px;
		left: 6.5px;
		top: 2675.998px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#VHG_MOB_29n_ {
		position: relative;
		width: 247px;
		height: 275px;
		left: 11px;
        margin-right: 30px;
		top: 21.001px;
		overflow: visible;
	}
	#VHG_MOB_29Mask_Group_22 {
		position: absolute;
		width: 247px;
		height: 229px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#VHG_MOB_29Rectangle_664 {
		fill: rgba(0,0,0,1);
	}
	.VHG_MOB_29Rectangle_664 {
		position: absolute;
		overflow: visible;
		width: 247px;
		height: 46px;
		left: 0px;
		top: 229.001px;
	}
	#VHG_MOB_29Something {
		left: 9px;
		top: 242.001px;
		position: absolute;
		overflow: visible;
		width: 233px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}
	#VHG_MOB_29Group_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 101px;
		top: 214.001px;
		overflow: visible;
	}
	#VHG_MOB_29Ellipse_38 {
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29Ellipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#VHG_MOB_29Ellipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29Ellipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#VHG_MOB_29Ellipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29Ellipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#VHG_MOB_29Ellipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29Ellipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#VHG_MOB_29Ellipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29Ellipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#VHG_MOB_29PRINCIPLES_br {
		left: 140px;
		top: 2599px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_MOB_29Group_15122 {
		position: absolute;
		width: 358px;
		height: 588.998px;
		left: 16px;
		top: 3099.998px;
		overflow: visible;
	}
	#VHG_MOB_29Group_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 45.5px;
		top: 59.997px;
		overflow: visible;
	}
	#VHG_MOB_29Rectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29Rectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#VHG_MOB_29Group_15050 {
		position: absolute;
		width: 358px;
		height: 316px;
		left: 0px;
		top: 272.998px;
		overflow: visible;
	}
	#VHG_MOB_29socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 194px;
		overflow: visible;
	}
	#VHG_MOB_29linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29med_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#VHG_MOB_29linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#VHG_MOB_29n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.VHG_MOB_29n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#VHG_MOB_29linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.VHG_MOB_29linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#VHG_MOB_29top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.VHG_MOB_29top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#VHG_MOB_29Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#VHG_MOB_29instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#VHG_MOB_29whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29med_shadow_space_website_7_b {
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29med_shadow_space_website_7_b {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#VHG_MOB_29whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#VHG_MOB_29n_234567890_mobile_number_shad {
		left: 48px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#VHG_MOB_29affanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#VHG_MOB_29address_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#VHG_MOB_29location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#VHG_MOB_29email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#VHG_MOB_29phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29n_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#VHG_MOB_29Contact_Us {
		left: 109.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	.mediaViewInfo {
		--web-view-name: FruitFiesta Mob;
		--web-view-id: FruitFiesta_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: FruitFiesta_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#FruitFiesta_Mob {
		position: absolute;
		width: 390px;
		height: 3643px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: FruitFiesta Mob;
		--web-view-id: FruitFiesta_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#FF_MoB_29julia-zolotova-M_xIaxQE3Ms-uns {
		position: absolute;
		width: 390px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_MoB_29Rectangle_748 {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
	}
	.FF_MoB_29Rectangle_748 {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#FF_MoB_29mae-mu-vbAEHCrvXZ0-unsplash {
		position: absolute;
		width: 389px;
		height: 389px;
		left: 75px;
		top: 365px;
		overflow: visible;
	}
	#FF_MoB_29Anime_Edit {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 61px;
		top: 45px;
        border-radius: 50%;
		overflow: hidden;
	}
	#FF_MoB_29Your_Business_Name {
		left: 131px;
		top: 64px;
		position: absolute;
		overflow: visible;
		width: 157px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#FF_MoB_29Exploring_Orchards_of_Flavor__ {
		left: 13px;
		top: 137px;
		position: absolute;
		overflow: visible;
		width: 366px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#FF_MoB_29Group_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 18px;
		top: 283px;
		overflow: visible;
	}
	#FF_MoB_29Rectangle_3 {
		fill: rgba(233,153,15,1);
	}
	.FF_MoB_29Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#FF_MoB_29Order_Now {
		left: 45px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 81px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#FF_MoB_29Group_15114 {
		position: absolute;
		width: 390px;
		height: 512px;
		left: 0px;
		top: 863px;
		overflow: visible;
	}
	#FF_MoB_29sneha-cecil-Qt6ojt3CacE-unspla {
		position: absolute;
		width: 250px;
		height: 250px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#FF_MoB_29Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 174px;
		left: 0px;
		top: 338px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FF_MoB_29It_is_a_long_established_fact_ {
		left: 24px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 174px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FF_MoB_29VISION {
		left: 24px;
		top: 277px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -12px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#FF_MoB_29Group_15120 {
		position: absolute;
		width: 390px;
		height: 512px;
		left: 0px;
		top: 1425px;
		overflow: visible;
	}
	#FF_MoB_29tom-brunberg-D4ZtZX1UeAI-unspl {
		position: absolute;
		width: 250px;
		height: 250px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#FF_MoB_29Scroll_Group_28_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 174px;
		left: 0px;
		top: 338px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FF_MoB_29It_is_a_long_established_fact__ {
		left: 24px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 172px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FF_MoB_29MISSION {
		left: 24px;
		top: 277px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -12px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#FF_MoB_29Group_15121 {
		position: absolute;
		width: 390px;
		height: 512px;
		left: 0px;
		top: 1987px;
		overflow: visible;
	}
	#FF_MoB_29brenda-godinez-_Zn_7FzoL1w-uns {
		position: absolute;
		width: 250px;
		height: 250px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#FF_MoB_29Scroll_Group_28_bc {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 174px;
		left: 0px;
		top: 338px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FF_MoB_29It_is_a_long_established_fact__bd {
		left: 24px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 174px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FF_MoB_29PRINCIPLES {
		left: 24px;
		top: 277px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -12px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#FF_MoB_29Scroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 377px;
		height: 324px;
		left: 6.5px;
		top: 2676px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#FF_MoB_29n_ {
		position: relative;
		width: 247px;
		height: 275px;
		left: 11px;
		top: 21.002px;
        margin-right: 30px;
		overflow: visible;
	}
	#FF_MoB_29Mask_Group_22 {
		position: absolute;
		width: 247px;
		height: 242px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_MoB_29Rectangle_664 {
		fill: rgba(0,0,0,1);
	}
	.FF_MoB_29Rectangle_664 {
		position: absolute;
		overflow: visible;
		width: 247px;
		height: 46px;
		left: 0px;
		top: 229px;
	}
	#FF_MoB_29Something {
		left: 9px;
		top: 242px;
		position: absolute;
		overflow: visible;
		width: 233px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}
	#FF_MoB_29Group_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 101px;
		top: 214px;
		overflow: visible;
	}
	#FF_MoB_29Ellipse_38 {
		fill: rgba(255,255,255,1);
	}
	.FF_MoB_29Ellipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#FF_MoB_29Ellipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.FF_MoB_29Ellipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#FF_MoB_29Ellipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.FF_MoB_29Ellipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#FF_MoB_29Ellipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.FF_MoB_29Ellipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#FF_MoB_29Ellipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.FF_MoB_29Ellipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#FF_MoB_29In_Stock {
		left: 157px;
		top: 2599px;
		position: absolute;
		overflow: visible;
		width: 77px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FF_MoB_29Group_15122 {
		position: absolute;
		width: 358px;
		height: 574.998px;
		left: 16px;
		top: 3059px;
		overflow: visible;
	}
	#FF_MoB_29Group_15040 {
		position: absolute;
		width: 309px;
		height: 174px;
		left: 31.5px;
		top: 44.997px;
		overflow: visible;
	}
	#FF_MoB_29Rectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.FF_MoB_29Rectangle_737 {
		position: absolute;
		overflow: visible;
		width: 309px;
		height: 174px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#FF_MoB_29Group_15050 {
		position: absolute;
		width: 358px;
		height: 316px;
		left: 0px;
		top: 258.998px;
		overflow: visible;
	}
	#FF_MoB_29socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 194px;
		overflow: visible;
	}
	#FF_MoB_29linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#FF_MoB_29med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FF_MoB_29med_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FF_MoB_29linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#FF_MoB_29n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.FF_MoB_29n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FF_MoB_29linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.FF_MoB_29linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FF_MoB_29top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.FF_MoB_29top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FF_MoB_29Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#FF_MoB_29shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FF_MoB_29shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FF_MoB_29instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#FF_MoB_29whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_MoB_29med_shadow_space_website_7_b {
		fill: rgba(255,255,255,1);
	}
	.FF_MoB_29med_shadow_space_website_7_b {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FF_MoB_29whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#FF_MoB_29n_234567890_mobile_number_shad {
		left: 48px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FF_MoB_29affanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FF_MoB_29address_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FF_MoB_29location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#FF_MoB_29email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#FF_MoB_29phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_MoB_29n_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#FF_MoB_29Contact_Us {
		left: 128px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -22.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}

	.mediaViewInfo {
		--web-view-name: FreshSipHub Mob;
		--web-view-id: FreshSipHub_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: FreshSipHub_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#FreshSipHub_Mob {
		position: absolute;
		width: 375px;
		height: 3510px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: FreshSipHub Mob;
		--web-view-id: FreshSipHub_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#FSH_MoB_30Group_15129 {
		position: absolute;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30Group_15124 {
		position: absolute;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30jugoslocos-i8JfQDc4Ha8-unsplas {
		position: absolute;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30Rectangle_749 {
		opacity: 0.24;
		fill: rgba(0,0,0,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FSH_MoB_30Rectangle_749 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
	}
	#FSH_MoB_30Anime_Edit {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 14px;
		top: 109px;
        border-radius: 50%;
		overflow: hidden;
	}
	#FSH_MoB_30Your_Business_Name {
		left: 78px;
		top: 123px;
		position: absolute;
		overflow: visible;
		width: 189px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#FSH_MoB_30Revitalize_Your_Life_with_the_ {
		left: 8px;
		top: 243px;
		position: absolute;
		overflow: visible;
		width: 358px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#FSH_MoB_30Group_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 84px;
		top: 452px;
		overflow: visible;
	}
	#FSH_MoB_30Rectangle_3 {
		fill: rgba(233,153,15,1);
	}
	.FSH_MoB_30Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#FSH_MoB_30Available_Now {
		left: 37px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#FSH_MoB_30Group_15134 {
		position: absolute;
		width: 366px;
		height: 1637px;
		left: 7px;
		top: 753px;
		overflow: visible;
	}
	#FSH_MoB_30Group_15126 {
		position: absolute;
		width: 366px;
		height: 477.079px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30tangerine-newt-hINQgaTqg7Q-uns {
		position: absolute;
		width: 220px;
		height: 220px;
		left: 70.5px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 0px;
		top: 323.079px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FSH_MoB_30It_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FSH_MoB_30VISION {
		left: 2px;
		top: 258.079px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_MoB_30Group_15130 {
		position: absolute;
		width: 366px;
		height: 477px;
		left: 0px;
		top: 580px;
		overflow: visible;
	}
	#FSH_MoB_30whitney-wright-TgQkxQc-t_U-uns {
		position: absolute;
		width: 220px;
		height: 220px;
		left: 70.5px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30Scroll_Group_28_bb {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 0px;
		top: 323px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FSH_MoB_30It_is_a_long_established_fact__bc {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FSH_MoB_30MISSION {
		left: 2px;
		top: 258px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_MoB_30Group_15131 {
		position: absolute;
		width: 366px;
		height: 477px;
		left: 0px;
		top: 1160px;
		overflow: visible;
	}
	#FSH_MoB_30alina-karpenko-jH1PBHevj38-uns {
		position: absolute;
		width: 220px;
		height: 220px;
		left: 70.5px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30Scroll_Group_28_bg {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 0px;
		top: 323px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FSH_MoB_30It_is_a_long_established_fact__bh {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FSH_MoB_30PRINCIPLES {
		left: 2px;
		top: 258px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_MoB_30Group_15133 {
		position: absolute;
		width: 375px;
		height: 332px;
		left: 0px;
		top: 2490px;
		overflow: visible;
	}
	#FSH_MoB_30Our_Greatest_Drinks {
		left: 95px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 187px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_MoB_30Scroll_Group_90 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 305px;
		left: 0px;
		top: 27px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#FSH_MoB_30Group_15128 {
		position: relative;
        margin-right: 30px;
		width: 203px;
		height: 254px;
		left: 15px;
		top: 22px;
		overflow: visible;
	}
	#FSH_MoB_30Rectangle_750_bo {
		fill: url(#FSH_MoB_30Rectangle_750_bo);
	}
	.FSH_MoB_30Rectangle_750_bo {
		-webkit-filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 208px;
		height: 259px;
		left: 0px;
		top: 0px;
	}
	#FSH_MoB_30sam-hojati-lw8GflbJwLc-unsplas {
		position: absolute;
		width: 185px;
		height: 210px;
		left: 9px;
		top: 9px;
		overflow: visible;
	}
	#FSH_MoB_30Juice_1 {
		left: 9px;
		top: 226px;
		position: absolute;
		overflow: visible;
		width: 186px;
		height: 20px;
		line-height: 41px;
		margin-top: -13px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#FSH_MoB_30Group_15132 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 8.5px;
		top: 2905.904px;
		overflow: visible;
	}
	#FSH_MoB_30Group_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 30px;
		top: 73.095px;
		overflow: visible;
	}
	#FSH_MoB_30Rectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.FSH_MoB_30Rectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#FSH_MoB_30Group_15050 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 480.096px;
		overflow: visible;
	}
	#FSH_MoB_30linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FSH_MoB_30med_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FSH_MoB_30linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#FSH_MoB_30n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.FSH_MoB_30n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FSH_MoB_30linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.FSH_MoB_30linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FSH_MoB_30top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.FSH_MoB_30top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FSH_MoB_30Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FSH_MoB_30shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FSH_MoB_30instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#FSH_MoB_30whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30med_shadow_space_website_7_b {
		fill: rgba(255,255,255,1);
	}
	.FSH_MoB_30med_shadow_space_website_7_b {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FSH_MoB_30whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#FSH_MoB_30n_234567890_mobile_number_shad {
		left: 48px;
		top: 293.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FSH_MoB_30affanghanioperatelive_email_sh {
		left: 35px;
		top: 340.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FSH_MoB_30address_med_shadow_space_websi {
		left: 35px;
		top: 390.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FSH_MoB_30location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 392.096px;
		overflow: visible;
	}
	#FSH_MoB_30email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 339.096px;
		overflow: visible;
	}
	#FSH_MoB_30phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 286.096px;
		overflow: visible;
	}
	#FSH_MoB_30n_Copyright_Operate_Live_OPC_P {
		left: 27px;
		top: 565.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#FSH_MoB_30Contact_Us {
		left: 127.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -22.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}

	.mediaViewInfo {
		--web-view-name: SoleCrafted Mob;
		--web-view-id: SoleCrafted_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: SoleCrafted_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#SoleCrafted_Mob {
		position: absolute;
		width: 375px;
		height: 3909px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: SoleCrafted Mob;
		--web-view-id: SoleCrafted_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#SCM_32_MoBGroup_15140 {
		position: absolute;
		width: 375px;
		height: 697px;
		left: 0px;
		top: 39px;
		overflow: visible;
	}
	#SCM_32_MoBYour_Name {
		left: 17px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 235px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBGroup_15152 {
		position: absolute;
		width: 375px;
		height: 376px;
		left: 0px;
		top: 321px;
		overflow: visible;
	}
	#SCM_32_MoBnoah-smith-R4xOiCIeOUs-unsplas {
		position: absolute;
		width: 187px;
		height: 187px;
		left: 188px;
		top: 0px;
		overflow: visible;
	}
	#SCM_32_MoBalexandr-sadkov-BnG4KWAzt9c-un {
		position: absolute;
		width: 188px;
		height: 188px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SCM_32_MoBlea-ochel-nsRBbE6-YLs-unsplash {
		position: absolute;
		width: 188px;
		height: 188px;
		left: 0px;
		top: 188px;
		overflow: visible;
	}
	#SCM_32_MoBrajesh-rajput-NqZ2vFqChaw-unsp {
		position: absolute;
		width: 187px;
		height: 187px;
		left: 188px;
		top: 188px;
		overflow: visible;
	}
	#SCM_32_MoBDiscover_Timeless__Craftsmansh {
		left: 16px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 344px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 28px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBGroup_15155 {
		position: absolute;
		width: 340px;
		height: 582px;
		left: 19px;
		top: 836px;
		overflow: visible;
	}
	#SCM_32_MoBRectangle_752 {
		fill: rgba(161,141,87,1);
	}
	.SCM_32_MoBRectangle_752 {
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 582px;
		left: 0px;
		top: 0px;
	}
	#SCM_32_MoBty-feague-87V27nw0sS0-unsplash {
		position: absolute;
		width: 274px;
		height: 311px;
		left: 33px;
		top: 33px;
		overflow: visible;
	}
	#SCM_32_MoBScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 326px;
		height: 154px;
		left: 14px;
		top: 409px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SCM_32_MoBIt_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 312px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SCM_32_MoBVISION {
		left: 14px;
		top: 367px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -12px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#SCM_32_MoBGroup_15154 {
		position: absolute;
		width: 340px;
		height: 582px;
		left: 16px;
		top: 1468px;
		overflow: visible;
	}
	#SCM_32_MoBRectangle_752_bf {
		fill: rgba(161,141,87,1);
	}
	.SCM_32_MoBRectangle_752_bf {
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 582px;
		left: 0px;
		top: 0px;
	}
	#SCM_32_MoBprateek-chawla-87luO7iL1mM-uns {
		position: absolute;
		width: 274px;
		height: 311px;
		left: 33px;
		top: 33px;
		overflow: visible;
	}
	#SCM_32_MoBScroll_Group_28_bh {
		mix-blend-mode: normal;
		position: absolute;
		width: 326px;
		height: 154px;
		left: 14px;
		top: 409px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SCM_32_MoBIt_is_a_long_established_fact__bi {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 312px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SCM_32_MoBMISSION {
		left: 19px;
		top: 367px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -12px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#SCM_32_MoBGroup_15153 {
		position: absolute;
		width: 340px;
		height: 582px;
		left: 16px;
		top: 2100px;
		overflow: visible;
	}
	#SCM_32_MoBRectangle_752_bl {
		fill: rgba(161,141,87,1);
	}
	.SCM_32_MoBRectangle_752_bl {
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 582px;
		left: 0px;
		top: 0px;
	}
	#SCM_32_MoBnoah-smith-1z2hBTKHdWI-unsplas {
		position: absolute;
		width: 274px;
		height: 311px;
		left: 33px;
		top: 33px;
		overflow: visible;
	}
	#SCM_32_MoBScroll_Group_28_bn {
		mix-blend-mode: normal;
		position: absolute;
		width: 326px;
		height: 154px;
		left: 14px;
		top: 409px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SCM_32_MoBIt_is_a_long_established_fact__bo {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 312px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SCM_32_MoBPRINCIPLES {
		left: 18px;
		top: 367px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -12px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#SCM_32_MoBGroup_15149 {
		position: absolute;
		width: 336px;
		height: 38px;
		left: 21px;
		top: 2782px;
		overflow: visible;
	}
	#SCM_32_MoBNEW_EDITION {
		left: 0px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 140px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBGroup_15145 {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 191px;
		top: 0px;
		overflow: visible;
	}
	#SCM_32_MoBRectangle_753 {
		fill: rgba(5,5,5,1);
	}
	.SCM_32_MoBRectangle_753 {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: 0px;
	}
	#SCM_32_MoBView_More {
		left: 38px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 71px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#SCM_32_MoBScroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 359px;
		left: 0px;
		top: 2847px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#SCM_32_MoBGroup_15144 {
		position: relative;
        margin-right: 30px;
		width: 221px;
		height: 301px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#SCM_32_MoBRectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.SCM_32_MoBRectangle_754 {
		-webkit-filter: drop-shadow(5px 5px 1px rgba(150, 75, 0, 0.388));
		        filter: drop-shadow(5px 5px 1px rgba(150, 75, 0, 0.388));
		position: absolute;
		overflow: visible;
		width: 227.5px;
		height: 307.5px;
		left: 0px;
		top: 0px;
	}
	#SCM_32_MoBmostafa-mahmoudi-3OZr-hLbsq0-u {
		position: absolute;
		width: 217px;
		height: 231px;
		left: 2px;
		top: 35px;
		overflow: visible;
	}
	#SCM_32_MoBTitle {
		left: 6px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 210px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBn_10000 {
		left: 6px;
		top: 274px;
		position: absolute;
		overflow: visible;
		width: 210px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBGroup_15132 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 8.5px;
		top: 3306px;
		overflow: visible;
	}
	#SCM_32_MoBGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 20px;
		top: 73.095px;
		overflow: visible;
	}
	#SCM_32_MoBRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.SCM_32_MoBRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#SCM_32_MoBGroup_15050 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SCM_32_MoBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 67px;
		top: 480.096px;
		overflow: visible;
	}
	#SCM_32_MoBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#SCM_32_MoBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.SCM_32_MoBmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SCM_32_MoBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#SCM_32_MoBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.SCM_32_MoBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SCM_32_MoBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.SCM_32_MoBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SCM_32_MoBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.SCM_32_MoBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SCM_32_MoBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#SCM_32_MoBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.SCM_32_MoBshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SCM_32_MoBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#SCM_32_MoBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SCM_32_MoBmed_shadow_space_website_7_cg {
		fill: rgba(255,255,255,1);
	}
	.SCM_32_MoBmed_shadow_space_website_7_cg {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SCM_32_MoBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.986px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#SCM_32_MoBn_234567890_mobile_number_shad {
		left: 35px;
		top: 293.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 340.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBaddress_med_shadow_space_websi {
		left: 35px;
		top: 390.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 392.096px;
		overflow: visible;
	}
	#SCM_32_MoBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 339.096px;
		overflow: visible;
	}
	#SCM_32_MoBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 286.096px;
		overflow: visible;
	}
	#SCM_32_MoBn_Copyright_Operate_Live_OPC_P {
		left: 17px;
		top: 565.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBContact_Us {
		left: 117.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -22.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}

	.mediaViewInfo {
		--web-view-name: ShoeStride_Mob;
		--web-view-id: ShoeStride_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ShoeStride_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ShoeStride_Mob {
		position: absolute;
		width: 375px;
		height: 3925px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: ShoeStride_Mob;
		--web-view-id: ShoeStride_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#SS_31_MoBGroup_15140 {
		position: absolute;
		width: 1217px;
		height: 561px;
		left: 0px;
		top: 39px;
		overflow: visible;
	}
	#SS_31_MoBEllipse_44 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SS_31_MoBEllipse_44 {
		position: absolute;
		overflow: visible;
		width: 64px;
		height: 1px;
		left: 1153px;
		top: 247px;
	}
	#SS_31_MoBYour_Name {
		left: 17px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 235px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBDISCOVER_LIMITED__SHOES_WITHOU {
		left: 17px;
		top: 78px;
		position: absolute;
		overflow: visible;
		width: 308px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBGroup_15146 {
		position: absolute;
		width: 375px;
		height: 375px;
		left: 0px;
		top: 186px;
		overflow: visible;
	}
	#SS_31_MoBthe-dk-photography-b9e8ffVw5XY {
		position: absolute;
		width: 187px;
		height: 188px;
		left: 188px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_MoBthibaut-burckel-n2V5MLDPE-k-un {
		position: absolute;
		width: 188px;
		height: 188px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_MoBthe-dk-photography-A8HaTLON5Gg {
		position: absolute;
		width: 186px;
		height: 185px;
		left: 2px;
		top: 188px;
		overflow: visible;
	}
	#SS_31_MoBkyle-austin-vHIv0AreyDk-unspla {
		position: absolute;
		width: 187px;
		height: 187px;
		left: 188px;
		top: 188px;
		overflow: visible;
	}
	#SS_31_MoBGroup_15139 {
		position: absolute;
		width: 375px;
		height: 228px;
		left: 0px;
		top: 650px;
		overflow: visible;
	}
	#SS_31_MoBRectangle_752 {
		fill: rgba(5,5,5,1);
	}
	.SS_31_MoBRectangle_752 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 228px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBLorem_Ipsum_is_simply_dummy_te {
		left: 79px;
		top: 17px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 195px;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#SS_31_MoBGroup_15141 {
		position: absolute;
		width: 340px;
		height: 582px;
		left: 21px;
		top: 928px;
		overflow: visible;
	}
	#SS_31_MoBRectangle_752_bd {
		fill: rgba(5,5,5,1);
	}
	.SS_31_MoBRectangle_752_bd {
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 582px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBmarten-bjork-_ccRYJWcNwk-unspl {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#SS_31_MoBScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 326px;
		height: 154px;
		left: 14px;
		top: 409px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SS_31_MoBIt_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 312px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SS_31_MoBVISION {
		left: 14px;
		top: 363px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SS_31_MoBGroup_15147 {
		position: absolute;
		width: 340px;
		height: 582px;
		left: 18px;
		top: 1560px;
		overflow: visible;
	}
	#SS_31_MoBRectangle_752_bj {
		fill: rgba(5,5,5,1);
	}
	.SS_31_MoBRectangle_752_bj {
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 582px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBxavier-teo-SxAXphIPWeg-unsplas {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#SS_31_MoBScroll_Group_28_bl {
		mix-blend-mode: normal;
		position: absolute;
		width: 326px;
		height: 154px;
		left: 14px;
		top: 409px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SS_31_MoBIt_is_a_long_established_fact__bm {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 312px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SS_31_MoBMISSION {
		left: 14px;
		top: 363px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SS_31_MoBGroup_15148 {
		position: absolute;
		width: 340px;
		height: 582px;
		left: 18px;
		top: 2192px;
		overflow: visible;
	}
	#SS_31_MoBRectangle_752_bp {
		fill: rgba(5,5,5,1);
	}
	.SS_31_MoBRectangle_752_bp {
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 582px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBhanne-van-oeckel-4R2tRB7R5uQ-u {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#SS_31_MoBScroll_Group_28_br {
		mix-blend-mode: normal;
		position: absolute;
		width: 326px;
		height: 154px;
		left: 14px;
		top: 409px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SS_31_MoBIt_is_a_long_established_fact__bs {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 312px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SS_31_MoBPRINCIPLES {
		left: 18px;
		top: 363px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SS_31_MoBGroup_15149 {
		position: absolute;
		width: 336px;
		height: 38px;
		left: 21px;
		top: 2836px;
		overflow: visible;
	}
	#SS_31_MoBNEW_EDITION {
		left: 0px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 140px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBGroup_15145 {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 191px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_MoBRectangle_753 {
		fill: rgba(5,5,5,1);
	}
	.SS_31_MoBRectangle_753 {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBView_More {
		left: 38px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 71px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#SS_31_MoBScroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 359px;
		left: 0px;
		top: 2901px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#SS_31_MoBGroup_15144 {
		position: relative;
        margin-right: 30px;
		width: 221px;
		height: 301px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#SS_31_MoBRectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.SS_31_MoBRectangle_754 {
		-webkit-filter: drop-shadow(5px 5px 1px rgba(235, 99, 99, 0.518));
		        filter: drop-shadow(5px 5px 1px rgba(235, 99, 99, 0.518));
		position: absolute;
		overflow: visible;
		width: 227.5px;
		height: 307.5px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBthibaut-burckel-n2V5MLDPE-k-un_b {
		position: absolute;
		width: 217px;
		height: 231px;
		left: 2px;
		top: 35px;
		overflow: visible;
	}
	#SS_31_MoBTitle {
		left: 6px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 210px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBn_10000 {
		left: 6px;
		top: 274px;
		position: absolute;
		overflow: visible;
		width: 210px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBGroup_15132 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 10px;
		top: 3320px;
		overflow: visible;
	}
	#SS_31_MoBGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 20px;
		top: 73.095px;
		overflow: visible;
	}
	#SS_31_MoBRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.SS_31_MoBRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#SS_31_MoBGroup_15050 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_MoBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 67px;
		top: 480.096px;
		overflow: visible;
	}
	#SS_31_MoBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_MoBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.SS_31_MoBmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#SS_31_MoBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.SS_31_MoBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SS_31_MoBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.SS_31_MoBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SS_31_MoBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.SS_31_MoBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SS_31_MoBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_MoBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.SS_31_MoBshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#SS_31_MoBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_MoBmed_shadow_space_website_7_ck {
		fill: rgba(255,255,255,1);
	}
	.SS_31_MoBmed_shadow_space_website_7_ck {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.986px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#SS_31_MoBn_234567890_mobile_number_shad {
		left: 35px;
		top: 293.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 340.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBaddress_med_shadow_space_websi {
		left: 35px;
		top: 390.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 392.096px;
		overflow: visible;
	}
	#SS_31_MoBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 339.096px;
		overflow: visible;
	}
	#SS_31_MoBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 286.096px;
		overflow: visible;
	}
	#SS_31_MoBn_Copyright_Operate_Live_OPC_P {
		left: 17px;
		top: 565.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBContact_Us {
		left: 117.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -22.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}

	.mediaViewInfo {
		--web-view-name: InkwellHaven Mob;
		--web-view-id: InkwellHaven_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: InkwellHaven_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#InkwellHaven_Mob {
		position: absolute;
		width: 375px;
		height: 3627px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: InkwellHaven Mob;
		--web-view-id: InkwellHaven_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#IWH_MOB_33Group_15132 {
		position: absolute;
		width: 358px;
		height: 600.096px;
		left: 8.5px;
		top: 3021px;
		overflow: visible;
	}
	#IWH_MOB_33Group_15050 {
		position: absolute;
		width: 358px;
		height: 600.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 67px;
		top: 479.096px;
		overflow: visible;
	}
	#IWH_MOB_33linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.IWH_MOB_33med_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#IWH_MOB_33linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#IWH_MOB_33n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.IWH_MOB_33n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#IWH_MOB_33linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.IWH_MOB_33linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#IWH_MOB_33top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.IWH_MOB_33top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#IWH_MOB_33Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.IWH_MOB_33shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#IWH_MOB_33instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#IWH_MOB_33whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33med_shadow_space_website_7_bf {
		fill: rgba(255,255,255,1);
	}
	.IWH_MOB_33med_shadow_space_website_7_bf {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#IWH_MOB_33whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#IWH_MOB_33n_234567890_mobile_number_shad {
		left: 35px;
		top: 292.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33affanghanioperatelive_email_sh {
		left: 35px;
		top: 339.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33address_med_shadow_space_websi {
		left: 35px;
		top: 389.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 391.096px;
		overflow: visible;
	}
	#IWH_MOB_33email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 338.096px;
		overflow: visible;
	}
	#IWH_MOB_33phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 285.096px;
		overflow: visible;
	}
	#IWH_MOB_33n_Copyright_Operate_Live_OPC_P {
		left: 17px;
		top: 564.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33Contact_Us {
		left: 117.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: left;
		font-family: Microsoft PhagsPa;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#IWH_MOB_33kisspng-paper-area-rectangle-s {
		position: absolute;
		width: 340px;
		height: 243.688px;
		left: 9px;
		top: 28px;
		overflow: visible;
	}
	#IWH_MOB_33Rectangle_757 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.IWH_MOB_33Rectangle_757 {
		position: absolute;
		overflow: visible;
		width: 274px;
		height: 183px;
		left: 41.5px;
		top: 56px;
	}
	#IWH_MOB_33Group_15159 {
		position: absolute;
		width: 344px;
		height: 650px;
		left: 20px;
		top: 14px;
		overflow: visible;
	}
	#IWH_MOB_33deepigoyal--TCygdpnO3g-unsplas {
		position: absolute;
		width: 344px;
		height: 315px;
		left: 0px;
		top: 335px;
		overflow: visible;
	}
	#IWH_MOB_33Essential_That_Inspires_You_to {
		left: 0px;
		top: 111px;
		position: absolute;
		overflow: visible;
		width: 295px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33Crafting_Words_One_Page_at_a_T {
		left: 0px;
		top: 203px;
		position: absolute;
		overflow: visible;
		width: 345px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33Your_Name {
		left: 0px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33Anime_Edit {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 60px;
		height: 60px;
		left: 274px;
		top: 0px;
		overflow: hidden;
        border-radius: 50%;
	}
	#IWH_MOB_33n_1994588_2111w039n0037Bp17 {
		opacity: 0.2;
		position: absolute;
		width: 375px;
		height: 1636px;
		left: 0px;
		top: 764px;
		overflow: visible;
	}
	#IWH_MOB_33Group_15164 {
		position: absolute;
		width: 349px;
		height: 467px;
		left: 16px;
		top: 799px;
		overflow: visible;
	}
	#IWH_MOB_33Group_15160 {
		position: absolute;
		width: 288.01px;
		height: 220.593px;
		left: 23.495px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33Path_7783 {
		fill: rgba(235,161,99,1);
	}
	.IWH_MOB_33Path_7783 {
		overflow: visible;
		position: absolute;
		width: 288.01px;
		height: 66.065px;
		left: 0px;
		top: 154.527px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#IWH_MOB_33kisspng-learning-education-bla {
		position: absolute;
		width: 218px;
		height: 218px;
		left: 34.819px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33Group_15163 {
		position: absolute;
		width: 349px;
		height: 195px;
		left: 0px;
		top: 272px;
		overflow: visible;
	}
	#IWH_MOB_33Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 349px;
		height: 152px;
		left: 0px;
		top: 43px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#IWH_MOB_33It_is_a_long_established_fact_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#IWH_MOB_33VISION {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		line-height: 37px;
		margin-top: -10px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33Group_15167 {
		position: absolute;
		width: 369px;
		height: 529px;
		left: 6px;
		top: 1289px;
		overflow: visible;
	}
	#IWH_MOB_33Group_15161 {
		position: absolute;
		width: 326.703px;
		height: 320.616px;
		left: 18.148px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33Path_7784 {
		fill: rgba(99,235,131,1);
	}
	.IWH_MOB_33Path_7784 {
		overflow: visible;
		position: absolute;
		width: 288.01px;
		height: 66.065px;
		left: 19.346px;
		top: 235.731px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#IWH_MOB_33kisspng-school-royalty-free-dr {
		position: absolute;
		width: 326.703px;
		height: 320.616px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33Group_15165 {
		position: absolute;
		width: 369px;
		height: 172px;
		left: 0px;
		top: 357px;
		overflow: visible;
	}
	#IWH_MOB_33Scroll_Group_28_cb {
		mix-blend-mode: normal;
		position: absolute;
		width: 369px;
		height: 129px;
		left: 0px;
		top: 43px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#IWH_MOB_33It_is_a_long_established_fact__cc {
		left: 7px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 357px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#IWH_MOB_33MISSION {
		left: 7px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 37px;
		margin-top: -10px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33Group_15168 {
		position: absolute;
		width: 362px;
		height: 488px;
		left: 10px;
		top: 1857px;
		overflow: visible;
	}
	#IWH_MOB_33Group_15162 {
		position: absolute;
		width: 288px;
		height: 262px;
		left: 30px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33Path_7785 {
		fill: rgba(99,99,235,1);
	}
	.IWH_MOB_33Path_7785 {
		overflow: visible;
		position: absolute;
		width: 288px;
		height: 66.065px;
		left: 0px;
		top: 163.438px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#IWH_MOB_33n_359207_19882 {
		position: absolute;
		width: 262px;
		height: 262px;
		left: 12.819px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33Group_15166 {
		position: absolute;
		width: 362px;
		height: 195px;
		left: 0px;
		top: 293px;
		overflow: visible;
	}
	#IWH_MOB_33Scroll_Group_28_cj {
		mix-blend-mode: normal;
		position: absolute;
		width: 362px;
		height: 152px;
		left: 0px;
		top: 43px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#IWH_MOB_33It_is_a_long_established_fact__ck {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 350px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#IWH_MOB_33PRINCIPLES {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		line-height: 37px;
		margin-top: -10px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33Group_15157 {
		position: absolute;
		width: 375px;
		height: 420px;
		left: 0px;
		top: 2500px;
		overflow: visible;
	}
	#IWH_MOB_33school-supplies-art-classes {
		position: absolute;
		width: 375px;
		height: 327px;
		left: 0px;
		top: 93px;
		overflow: visible;
	}
	#IWH_MOB_33Rectangle_755 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.IWH_MOB_33Rectangle_755 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 327px;
		left: 0px;
		top: 93px;
	}
	#IWH_MOB_33Available_Stationery {
		left: 52px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 272px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33Scroll_offering_med_web_4_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 306px;
		left: 0px;
		top: 104px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#IWH_MOB_33offering_crd_1_grp_med_web_4_p {
		position: relative;
        margin-right: 30px;
		width: 167px;
		height: 277px;
		left: 15px;
		top: 11px;
		overflow: visible;
	}
	#IWH_MOB_33title_bdr_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.IWH_MOB_33title_bdr_med_web_4_pc {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 161px;
		height: 45px;
		left: 12px;
		top: 250px;
	}
	#IWH_MOB_33BiziBees_PVT_LTD_med_web_4_pc {
		left: 12px;
		top: 255px;
		position: absolute;
		overflow: visible;
		width: 144px;
		height: 20px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33img_border_med_web_4_pc_of {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 167px;
		height: 250px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33chuttersna_med_web_4_pc_img {
		position: absolute;
		width: 143px;
		height: 231px;
		left: 12px;
		top: 6px;
		overflow: visible;
	}

	.mediaViewInfo {
		--web-view-name: TextureFusion Mob;
		--web-view-id: TextureFusion_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: TextureFusion_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#TextureFusion_Mob {
		position: absolute;
		width: 375px;
		height: 3718px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: TextureFusion Mob;
		--web-view-id: TextureFusion_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#TFM_MoB_35Group_15132 {
		position: absolute;
		width: 375px;
		height: 616px;
		left: 0px;
		top: 3102px;
		overflow: visible;
	}
	#TFM_MoB_35jj-ying-7JX0-bfiuxQ-unsplash {
		position: absolute;
		width: 375px;
		height: 572px;
		left: 0px;
		top: 44px;
		overflow: visible;
	}
	#TFM_MoB_35Group_15050 {
		position: absolute;
		width: 358px;
		height: 600.096px;
		left: 8px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 67px;
		top: 479.096px;
		overflow: visible;
	}
	#TFM_MoB_35linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TFM_MoB_35med_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#TFM_MoB_35n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.TFM_MoB_35n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TFM_MoB_35linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.TFM_MoB_35linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TFM_MoB_35top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.TFM_MoB_35top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TFM_MoB_35Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TFM_MoB_35shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#TFM_MoB_35whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35med_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.TFM_MoB_35med_shadow_space_website_7_ {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#TFM_MoB_35n_234567890_mobile_number_shad {
		left: 35px;
		top: 292.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TFM_MoB_35affanghanioperatelive_email_sh {
		left: 35px;
		top: 339.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TFM_MoB_35address_med_shadow_space_websi {
		left: 35px;
		top: 389.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TFM_MoB_35location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 391.096px;
		overflow: visible;
	}
	#TFM_MoB_35email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 338.096px;
		overflow: visible;
	}
	#TFM_MoB_35phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 285.096px;
		overflow: visible;
	}
	#TFM_MoB_35n_Copyright_Operate_Live_OPC_P {
		left: 17px;
		top: 564.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#TFM_MoB_35Contact_Us {
		left: 117.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: left;
		font-family: Microsoft PhagsPa;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#TFM_MoB_35Rectangle_757 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TFM_MoB_35Rectangle_757 {
		position: absolute;
		overflow: visible;
		width: 274px;
		height: 183px;
		left: 49.5px;
		top: 56px;
	}
	#TFM_MoB_35Group_15117 {
		position: absolute;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35christian-holzinger-CUY_YHhCFl {
		position: absolute;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35Rectangle_748 {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
	}
	.TFM_MoB_35Rectangle_748 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35Anime_Edit {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 20px;
		top: 38px;
		overflow: hidden;
        border-radius: 50%;
	}
	#TFM_MoB_35Your_Business_Name {
		left: 91px;
		top: 55px;
		position: absolute;
		overflow: visible;
		width: 285px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFM_MoB_35Elevate_Your_Space_with_Unique {
		left: 20px;
		top: 231px;
		position: absolute;
		overflow: visible;
		width: 311px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFM_MoB_35Group_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 20px;
		top: 439px;
		overflow: visible;
	}
	#TFM_MoB_35Rectangle_3 {
		fill: rgba(183,177,186,1);
	}
	.TFM_MoB_35Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35Available_Now {
		left: 37px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#TFM_MoB_35pawel-czerwinski-eBd0dPuR-bo-u {
		opacity: 0.5;
		position: absolute;
		width: 375px;
		height: 1779px;
		left: 0px;
		top: 763px;
		overflow: visible;
	}
	#TFM_MoB_35Group_15174 {
		position: absolute;
		width: 332px;
		height: 1706px;
		left: 22px;
		top: 800px;
		overflow: visible;
	}
	#TFM_MoB_35Group_15141 {
		position: absolute;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35Rectangle_752 {
		fill: rgba(223,221,219,1);
	}
	.TFM_MoB_35Rectangle_752 {
		position: absolute;
		overflow: visible;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35n_3430759_5249503 {
		position: absolute;
		width: 270px;
		height: 270px;
		left: 31px;
		top: 33px;
		overflow: visible;
	}
	#TFM_MoB_35Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 332px;
		height: 130px;
		left: 0px;
		top: 401px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TFM_MoB_35It_is_a_long_established_fact_ {
		left: 13px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 316px;
		height: 119px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#TFM_MoB_35VISION {
		left: 15px;
		top: 333px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFM_MoB_35Group_15172 {
		position: absolute;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 580px;
		overflow: visible;
	}
	#TFM_MoB_35Rectangle_752_bu {
		fill: rgba(183,177,186,1);
	}
	.TFM_MoB_35Rectangle_752_bu {
		position: absolute;
		overflow: visible;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35n_60450_OFC6C30 {
		position: absolute;
		width: 270px;
		height: 270px;
		left: 31px;
		top: 33px;
		overflow: visible;
	}
	#TFM_MoB_35Scroll_Group_28_bw {
		mix-blend-mode: normal;
		position: absolute;
		width: 332px;
		height: 130px;
		left: 0px;
		top: 401px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TFM_MoB_35It_is_a_long_established_fact__bx {
		left: 13px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 316px;
		height: 119px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#TFM_MoB_35MISSION {
		left: 15px;
		top: 333px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFM_MoB_35Group_15173 {
		position: absolute;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 1160px;
		overflow: visible;
	}
	#TFM_MoB_35Rectangle_752_b {
		fill: rgba(231,211,203,1);
	}
	.TFM_MoB_35Rectangle_752_b {
		position: absolute;
		overflow: visible;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35n_20566_OCCYZ60 {
		position: absolute;
		width: 270px;
		height: 270px;
		left: 31px;
		top: 33px;
		overflow: visible;
	}
	#TFM_MoB_35Scroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 332px;
		height: 130px;
		left: 0px;
		top: 401px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TFM_MoB_35It_is_a_long_established_fact__b {
		left: 13px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 316px;
		height: 119px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#TFM_MoB_35PRINCIPLES {
		left: 15px;
		top: 333px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFM_MoB_35Group_15170 {
		position: absolute;
		width: 375px;
		height: 387px;
		left: 0px;
		top: 2615px;
		overflow: visible;
	}
	#TFM_MoB_35Scroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 349px;
		left: 0px;
		top: 38px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#TFM_MoB_35Group_15144 {
		position: relative;
        margin-right: 30px;
		width: 219px;
		height: 298px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#TFM_MoB_35Rectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.TFM_MoB_35Rectangle_754 {
		-webkit-filter: drop-shadow(5px 5px 1px rgba(233, 221, 223, 1));
		        filter: drop-shadow(5px 5px 1px rgba(233, 221, 223, 1));
		position: absolute;
		overflow: visible;
		width: 225.5px;
		height: 304.5px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35luca-laurence-FseXc3OsIic-unsp {
		position: absolute;
		width: 215px;
		height: 241px;
		left: 2px;
		top: 29px;
		overflow: visible;
	}
	#TFM_MoB_35Title {
		left: 6px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 208px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TFM_MoB_35n_10000 {
		left: 6px;
		top: 270px;
		position: absolute;
		overflow: visible;
		width: 208px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TFM_MoB_35Group_15149 {
		position: absolute;
		width: 336px;
		height: 38px;
		left: 20px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35NEW_DESIGN {
		left: 0px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 130px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TFM_MoB_35Group_15145 {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 191px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35Rectangle_753 {
		fill: rgba(183,177,186,1);
	}
	.TFM_MoB_35Rectangle_753 {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35View_More {
		left: 38px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 71px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}

	.mediaViewInfo {
		--web-view-name: ColorFusion Mob;
		--web-view-id: ColorFusion_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ColorFusion_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ColorFusion_Mob {
		position: absolute;
		width: 375px;
		height: 3674px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: ColorFusion Mob;
		--web-view-id: ColorFusion_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#CFM_35_MobGroup_15132 {
		position: absolute;
		width: 358px;
		height: 600.096px;
		left: 8px;
		top: 3064px;
		overflow: visible;
	}
	#CFM_35_MobGroup_15050 {
		position: absolute;
		width: 358px;
		height: 600.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_Mobsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 67px;
		top: 479.096px;
		overflow: visible;
	}
	#CFM_35_Moblinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_Mobmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CFM_35_Mobmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_Moblinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#CFM_35_Mobn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.CFM_35_Mobn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CFM_35_Moblinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.CFM_35_Moblinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CFM_35_Mobtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.CFM_35_Mobtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CFM_35_MobInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_Mobshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CFM_35_Mobshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_Mobinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#CFM_35_Mobwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_Mobmed_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.CFM_35_Mobmed_shadow_space_website_7_ {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_Mobwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#CFM_35_Mobn_234567890_mobile_number_shad {
		left: 35px;
		top: 292.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#CFM_35_Mobaffanghanioperatelive_email_sh {
		left: 35px;
		top: 339.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#CFM_35_Mobaddress_med_shadow_space_websi {
		left: 35px;
		top: 389.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#CFM_35_Moblocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 391.096px;
		overflow: visible;
	}
	#CFM_35_Mobemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 338.096px;
		overflow: visible;
	}
	#CFM_35_Mobphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 285.096px;
		overflow: visible;
	}
	#CFM_35_Mobn_Copyright_Operate_Live_OPC_P {
		left: 17px;
		top: 564.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#CFM_35_MobContact_Us {
		left: 117.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: left;
		font-family: Microsoft PhagsPa;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#CFM_35_MobRectangle_757 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CFM_35_MobRectangle_757 {
		position: absolute;
		overflow: visible;
		width: 274px;
		height: 183px;
		left: 41.5px;
		top: 56px;
	}
	#CFM_35_MobGroup_15117 {
		position: absolute;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_Mobtheme-photos-Cl-OpYWFFm0-unspl {
		position: absolute;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_MobRectangle_748 {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
	}
	.CFM_35_MobRectangle_748 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_MobAnime_Edit {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 20px;
		top: 38px;
		overflow: hidden;
        border-radius: 50%;
	}
	#CFM_35_MobYour_Business_Name {
		left: 91px;
		top: 55px;
		position: absolute;
		overflow: visible;
		width: 285px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#CFM_35_MobDiscover_a_World_of_Possibilit {
		left: 17px;
		top: 214px;
		position: absolute;
		overflow: visible;
		width: 343px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#CFM_35_MobGroup_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 20px;
		top: 439px;
		overflow: visible;
	}
	#CFM_35_MobRectangle_3 {
		fill: rgba(105,139,153,1);
	}
	.CFM_35_MobRectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_MobAvailable_Now {
		left: 37px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#CFM_35_Mobdavid-pisnoy-46juD4zY1XA-unspl {
		opacity: 0.5;
		position: absolute;
		width: 375px;
		height: 1779px;
		left: 0px;
		top: 763px;
		overflow: visible;
	}
	#CFM_35_MobGroup_15174 {
		position: absolute;
		width: 332px;
		height: 1706px;
		left: 22px;
		top: 800px;
		overflow: visible;
	}
	#CFM_35_MobGroup_15141 {
		position: absolute;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_MobRectangle_752 {
		fill: rgba(252,141,133,1);
	}
	.CFM_35_MobRectangle_752 {
		position: absolute;
		overflow: visible;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_Mobyoann-siloine-LiDVw7tZPfY-unsp {
		position: absolute;
		width: 270px;
		height: 270px;
		left: 31px;
		top: 33px;
		overflow: visible;
	}
	#CFM_35_MobScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 332px;
		height: 130px;
		left: 0px;
		top: 401px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CFM_35_MobIt_is_a_long_established_fact_ {
		left: 13px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 316px;
		height: 119px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#CFM_35_MobVISION {
		left: 15px;
		top: 333px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#CFM_35_MobGroup_15172 {
		position: absolute;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 580px;
		overflow: visible;
	}
	#CFM_35_MobRectangle_752_bt {
		fill: rgba(137,196,224,1);
	}
	.CFM_35_MobRectangle_752_bt {
		position: absolute;
		overflow: visible;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_Mobtaelynn-christopher-pfSNx3Z12K {
		position: absolute;
		width: 270px;
		height: 270px;
		left: 31px;
		top: 33px;
		overflow: visible;
	}
	#CFM_35_MobScroll_Group_28_bv {
		mix-blend-mode: normal;
		position: absolute;
		width: 332px;
		height: 130px;
		left: 0px;
		top: 401px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CFM_35_MobIt_is_a_long_established_fact__bw {
		left: 13px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 316px;
		height: 119px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#CFM_35_MobMISSION {
		left: 15px;
		top: 333px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#CFM_35_MobGroup_15173 {
		position: absolute;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 1160px;
		overflow: visible;
	}
	#CFM_35_MobRectangle_752_bz {
		fill: rgba(215,234,152,1);
	}
	.CFM_35_MobRectangle_752_bz {
		position: absolute;
		overflow: visible;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_Mobdavid-pisnoy-46juD4zY1XA-unspl_b {
		position: absolute;
		width: 270px;
		height: 270px;
		left: 31px;
		top: 33px;
		overflow: visible;
	}
	#CFM_35_MobScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 332px;
		height: 130px;
		left: 0px;
		top: 401px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CFM_35_MobIt_is_a_long_established_fact__b {
		left: 13px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 316px;
		height: 119px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#CFM_35_MobPRINCIPLES {
		left: 15px;
		top: 333px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#CFM_35_MobGroup_15170 {
		position: absolute;
		width: 375px;
		height: 349px;
		left: 0px;
		top: 2615px;
		overflow: visible;
	}
	#CFM_35_MobScroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 311px;
		left: 0px;
		top: 38px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#CFM_35_MobGroup_15144 {
		position: relative;
        margin-right: 30px;
		width: 187px;
		height: 270px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#CFM_35_MobRectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.CFM_35_MobRectangle_754 {
		-webkit-filter: drop-shadow(5px 5px 1px rgba(233, 221, 223, 1));
		        filter: drop-shadow(5px 5px 1px rgba(233, 221, 223, 1));
		position: absolute;
		overflow: visible;
		width: 193.5px;
		height: 276.5px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_Mobanna-kolosyuk-D5nh6mCW52c-unsp {
		position: absolute;
		width: 184px;
		height: 241px;
		left: 2px;
		top: 29px;
		overflow: visible;
	}
	#CFM_35_MobTitle {
		left: 6px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 181px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#CFM_35_MobGroup_15149 {
		position: absolute;
		width: 336px;
		height: 38px;
		left: 20px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_MobOur_Colours {
		left: 0px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CFM_35_MobGroup_15145 {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 191px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_MobRectangle_753 {
		fill: rgba(183,177,186,1);
	}
	.CFM_35_MobRectangle_753 {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_MobView_More {
		left: 38px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 71px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}

	.mediaViewInfo {
		--web-view-name: PureFabrica mob;
		--web-view-id: PureFabrica_mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: PureFabrica_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#PureFabrica_mob {
		position: absolute;
		width: 375px;
		height: 3674px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: PureFabrica mob;
		--web-view-id: PureFabrica_mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#PFP_36_MoBGroup_15132 {
		position: absolute;
		width: 358px;
		height: 600.096px;
		left: 8px;
		top: 3064px;
		overflow: visible;
	}
	#PFP_36_MoBGroup_15050 {
		position: absolute;
		width: 358px;
		height: 600.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 67px;
		top: 479.096px;
		overflow: visible;
	}
	#PFP_36_MoBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PFP_36_MoBmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_MoBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#PFP_36_MoBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.PFP_36_MoBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PFP_36_MoBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.PFP_36_MoBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PFP_36_MoBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.PFP_36_MoBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PFP_36_MoBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PFP_36_MoBshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_MoBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#PFP_36_MoBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBmed_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.PFP_36_MoBmed_shadow_space_website_7_ {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_MoBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#PFP_36_MoBn_234567890_mobile_number_shad {
		left: 35px;
		top: 292.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 339.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBaddress_med_shadow_space_websi {
		left: 35px;
		top: 389.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 391.096px;
		overflow: visible;
	}
	#PFP_36_MoBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 338.096px;
		overflow: visible;
	}
	#PFP_36_MoBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 285.096px;
		overflow: visible;
	}
	#PFP_36_MoBn_Copyright_Operate_Live_OPC_P {
		left: 17px;
		top: 564.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBContact_Us {
		left: 117.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: left;
		font-family: Microsoft PhagsPa;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#PFP_36_MoBRectangle_757 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PFP_36_MoBRectangle_757 {
		position: absolute;
		overflow: visible;
		width: 274px;
		height: 183px;
		left: 41.5px;
		top: 56px;
	}
	#PFP_36_MoBGroup_15117 {
		position: absolute;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBplanetcare-23coWmkTNSg-unsplas {
		position: absolute;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBRectangle_748 {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
	}
	.PFP_36_MoBRectangle_748 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_MoBAnime_Edit {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 20px;
		top: 38px;
        border-radius: 50%;
		overflow: hidden;
	}
	#PFP_36_MoBYour_Business_Name {
		left: 91px;
		top: 55px;
		position: absolute;
		overflow: visible;
		width: 285px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PFP_36_MoBDiscover_a_World_of_Possibilit {
		left: 17px;
		top: 214px;
		position: absolute;
		overflow: visible;
		width: 343px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PFP_36_MoBGroup_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 20px;
		top: 439px;
		overflow: visible;
	}
	#PFP_36_MoBRectangle_3 {
		fill: rgba(105,139,153,1);
	}
	.PFP_36_MoBRectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_MoBAvailable_Now {
		left: 37px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#PFP_36_MoBGroup_15170 {
		position: absolute;
		width: 375px;
		height: 349px;
		left: 0px;
		top: 2615px;
		overflow: visible;
	}
	#PFP_36_MoBScroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 311px;
		left: 0px;
		top: 38px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#PFP_36_MoBGroup_15144 {
		position: relative;
        margin-right: 30px;
		width: 187px;
		height: 270px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#PFP_36_MoBRectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.PFP_36_MoBRectangle_754 {
		-webkit-filter: drop-shadow(5px 5px 1px rgba(233, 221, 223, 1));
		        filter: drop-shadow(5px 5px 1px rgba(233, 221, 223, 1));
		position: absolute;
		overflow: visible;
		width: 193.5px;
		height: 276.5px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_MoBannie-spratt-aJN7zURQ1Wg-unspl {
		position: absolute;
		width: 184px;
		height: 241px;
		left: 2px;
		top: 29px;
		overflow: visible;
	}
	#PFP_36_MoBTitle {
		left: 6px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 181px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBGroup_15149 {
		position: absolute;
		width: 336px;
		height: 38px;
		left: 20px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBOur_Colours {
		left: 0px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 113px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBGroup_15145 {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 191px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBRectangle_753 {
		fill: rgba(183,177,186,1);
	}
	.PFP_36_MoBRectangle_753 {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_MoBView_More {
		left: 38px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 72px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#PFP_36_MoBGroup_15033 {
		position: absolute;
		width: 369px;
		height: 460px;
		left: 6px;
		top: 795px;
		overflow: visible;
	}
	#PFP_36_MoBengin-akyurt-yCYVV8-kQNM-unspl {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		        filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		position: absolute;
		width: 280px;
		height: 210px;
		left: 42px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBGroup_15038 {
		position: absolute;
		width: 369px;
		height: 191px;
		left: 0px;
		top: 269px;
		overflow: visible;
	}
	#PFP_36_MoBScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 369px;
		height: 126px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PFP_36_MoBIt_is_a_long_established_fact__b {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 352px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PFP_36_MoBVISION_b {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBGroup_15175 {
		position: absolute;
		width: 369px;
		height: 460px;
		left: 6px;
		top: 795px;
		overflow: visible;
	}
	#PFP_36_MoBengin-akyurt-yCYVV8-kQNM-unspl_b {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		        filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		position: absolute;
		width: 280px;
		height: 210px;
		left: 42px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBGroup_15038_b {
		position: absolute;
		width: 369px;
		height: 191px;
		left: 0px;
		top: 269px;
		overflow: visible;
	}
	#PFP_36_MoBScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 369px;
		height: 126px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PFP_36_MoBIt_is_a_long_established_fact__b {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 352px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PFP_36_MoBVISION_b {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBGroup_15176 {
		position: absolute;
		width: 369px;
		height: 460px;
		left: 6px;
		top: 1355px;
		overflow: visible;
	}
	#PFP_36_MoBtowfiqu-barbhuiya-998DibZwpIc- {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		        filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		position: absolute;
		width: 280px;
		height: 210px;
		left: 42px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBGroup_15038_cb {
		position: absolute;
		width: 369px;
		height: 191px;
		left: 0px;
		top: 269px;
		overflow: visible;
	}
	#PFP_36_MoBScroll_Group_28_cc {
		mix-blend-mode: normal;
		position: absolute;
		width: 369px;
		height: 126px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PFP_36_MoBIt_is_a_long_established_fact__cd {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 352px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PFP_36_MoBVISION_ce {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_MoBGroup_15177 {
		position: absolute;
		width: 369px;
		height: 460px;
		left: 6px;
		top: 1915px;
		overflow: visible;
	}
	#PFP_36_MoBraychan-vkpVPcIBU5U-unsplash {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		        filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		position: absolute;
		width: 280px;
		height: 210px;
		left: 42px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_MoBGroup_15038_ch {
		position: absolute;
		width: 369px;
		height: 191px;
		left: 0px;
		top: 269px;
		overflow: visible;
	}
	#PFP_36_MoBScroll_Group_28_ci {
		mix-blend-mode: normal;
		position: absolute;
		width: 369px;
		height: 126px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PFP_36_MoBIt_is_a_long_established_fact__cj {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 352px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PFP_36_MoBVISION_ck {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	.mediaViewInfo {
		--web-view-name: PerfumePulse mob;
		--web-view-id: PerfumePulse_mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: PerfumePulse_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#PerfumePulse_mob {
		position: absolute;
		width: 390px;
		height: 3794px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: PerfumePulse mob;
		--web-view-id: PerfumePulse_mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#PPMOBGroup_15109 {
		position: absolute;
		width: 379px;
		height: 1707px;
		left: 11px;
		top: 849px;
		overflow: visible;
	}
	#PPMOBGroup_15105 {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPMOBmika-baumeister-dp9AxaKHdVg-un {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		        filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#PPMOBGroup_15104 {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#PPMOBScroll_Group_28_bb {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PPMOBIt_is_a_long_established_fact__bc {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PPMOBVISION_bd {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPMOBGroup_15106 {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPMOBbundo-kim-ur2zmbseUIA-unsplash {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		        filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#PPMOBGroup_15104_ba {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#PPMOBScroll_Group_28_bb {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PPMOBIt_is_a_long_established_fact__bc {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PPMOBVISION_bd {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPMOBGroup_15106_be {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 592px;
		overflow: visible;
	}
	#PPMOBhanna-balan-d1GwyeOlZDw-unspla {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		        filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#PPMOBGroup_15104_bg {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#PPMOBScroll_Group_28_bh {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PPMOBIt_is_a_long_established_fact__bi {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PPMOBMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPMOBGroup_15106_bk {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 1184px;
		overflow: visible;
	}
	#PPMOBemily-wang-a5917t2ea8I-unsplas {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		        filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#PPMOBGroup_15104_bm {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#PPMOBScroll_Group_28_bn {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PPMOBIt_is_a_long_established_fact__bo {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PPMOBPRINCIPLES {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPMOBGroup_15108 {
		position: absolute;
		width: 358px;
		height: 590.002px;
		left: 16px;
		top: 3203.998px;
		overflow: visible;
	}
	#PPMOBGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 30px;
		top: 61.002px;
		overflow: visible;
	}
	#PPMOBRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.PPMOBRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#PPMOBGroup_15050 {
		position: absolute;
		width: 358px;
		height: 316px;
		left: 0px;
		top: 274.002px;
		overflow: visible;
	}
	#PPMOBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 194px;
		overflow: visible;
	}
	#PPMOBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#PPMOBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PPMOBmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPMOBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#PPMOBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.PPMOBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPMOBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.PPMOBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPMOBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.PPMOBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPMOBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PPMOBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PPMOBshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPMOBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#PPMOBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPMOBmed_shadow_space_website_7_b {
		fill: rgba(255,255,255,1);
	}
	.PPMOBmed_shadow_space_website_7_b {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPMOBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#PPMOBn_234567890_mobile_number_shad {
		left: 48px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#PPMOBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#PPMOBaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#PPMOBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#PPMOBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#PPMOBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPMOBn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#PPMOBVisit_Us_ {
		left: 118px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#PPMOBGroup_15152 {
		position: absolute;
		width: 354px;
		height: 254px;
		left: 18px;
		top: 24px;
		overflow: visible;
	}
	#PPMOBText {
		position: absolute;
		width: 354px;
		height: 160px;
		left: 0px;
		top: 94px;
		overflow: visible;
	}
	#PPMOBExperience_the_finest_in_premi {
		left: 5px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 336px;
		white-space: nowrap;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(6,18,50,1);
	}
	#PPMOBCompany__Name {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 355px;
		height: 94px;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(6,18,50,1);
	}
	#PPMOBTop_Nav {
		position: absolute;
		width: 46.834px;
		height: 19.514px;
		left: 304.965px;
		top: 17.514px;
		overflow: visible;
	}
	#PPMOBMenu_Button {
		position: absolute;
		width: 46.834px;
		height: 19.514px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPMOBLine_1 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPMOBLine_1 {
		overflow: visible;
		position: absolute;
		width: 23.417px;
		height: 3px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPMOBLine_2 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPMOBLine_2 {
		overflow: visible;
		position: absolute;
		width: 46.834px;
		height: 3px;
		left: 0px;
		top: 9.756px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPMOBLine_3 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPMOBLine_3 {
		overflow: visible;
		position: absolute;
		width: 23.417px;
		height: 3px;
		left: 23.417px;
		top: 19.514px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPMOBAnime_Edit {
		position: absolute;
		width: 55px;
		height: 55px;
		left: 4px;
		top: 0px;
		overflow: hidden;
        border-radius: 50%;
	}
	#PPMOBScroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 377px;
		height: 324px;
		left: 6.5px;
		top: 2729px;
        display: inline-flex;
		overflow-x: auto;
        overflow-y: hidden;
	}
	#PPMOBn_ {
		position: relative;
		left: 15px;
		width: 247px;
		height: 275px;
		top: 21.002px;
		overflow: visible;
	}
	#PPMOBMask_Group_22 {
		position: absolute;
		width: 247px;
		height: 242px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPMOBRectangle_664 {
		fill: rgba(0,0,0,1);
	}
	.PPMOBRectangle_664 {
		position: absolute;
		overflow: visible;
		width: 247px;
		height: 46px;
		left: 0px;
		top: 229px;
	}
	#PPMOBSomething {
		left: 9px;
		top: 242px;
		position: absolute;
		overflow: visible;
		width: 233px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}
	#PPMOBGroup_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 101px;
		top: 214px;
		overflow: visible;
	}
	#PPMOBEllipse_38 {
		fill: rgba(255,255,255,1);
	}
	.PPMOBEllipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#PPMOBEllipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.PPMOBEllipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#PPMOBEllipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.PPMOBEllipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#PPMOBEllipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.PPMOBEllipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#PPMOBEllipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.PPMOBEllipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#PPMOBIn_Stock {
		left: 157px;
		top: 2652px;
		position: absolute;
		overflow: visible;
		width: 77px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPMOBBGs {
		position: absolute;
		width: 390px;
		height: 408px;
		left: 0px;
		top: 303px;
		overflow: visible;
	}
	#PPMOBSee_the_Collection {
		position: absolute;
		width: 195px;
		height: 204px;
		left: 195px;
		top: 204px;
		overflow: visible;
	}
	#PPMOBBG {
		fill: rgba(7,82,181,1);
	}
	.PPMOBBG {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 204px;
		left: 0px;
		top: 0px;
	}
	#PPMOBIcon_feather-arrow-right-circl {
		position: absolute;
		width: 16.69px;
		height: 16.69px;
		left: 88.505px;
		top: 134.688px;
		overflow: visible;
	}
	#PPMOBPath_1 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPMOBPath_1 {
		overflow: visible;
		position: absolute;
		width: 19.69px;
		height: 19.689px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPMOBPath_2 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPMOBPath_2 {
		overflow: visible;
		position: absolute;
		width: 6.959px;
		height: 10.918px;
		left: 8.345px;
		top: 5.006px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPMOBPath_3 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPMOBPath_3 {
		overflow: visible;
		position: absolute;
		width: 9.676px;
		height: 3px;
		left: 5.007px;
		top: 8.344px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPMOBSee_the_Collection_c {
		position: absolute;
		width: 47px;
		height: 32px;
		left: 62px;
		top: 86px;
		overflow: visible;
	}
	#PPMOBSee_the {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 37px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(255,255,255,1);
	}
	#PPMOBcollection {
		left: 0px;
		top: 17px;
		position: absolute;
		overflow: visible;
		width: 48px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(255,255,255,1);
	}
	#PPMOBmiguel-andrade-potCPE_Cw8A-uns {
		position: absolute;
		width: 195px;
		height: 204px;
		left: 0px;
		top: 204px;
		overflow: visible;
	}
	#PPMOBjeroen-den-otter-2b0JeJTEclQ-u {
		position: absolute;
		width: 390px;
		height: 204px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	.mediaViewInfo {
		--web-view-name: PunctureWizards Mob;
		--web-view-id: PunctureWizards_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: PunctureWizards_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#PunctureWizards_Mob {
		position: absolute;
		width: 390px;
		height: 3601px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: PunctureWizards Mob;
		--web-view-id: PunctureWizards_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#PWMOBGroup_15114 {
		position: absolute;
		width: 390px;
		height: 512px;
		left: 0px;
		top: 863px;
		overflow: visible;
	}
	#PWMOBstopped-white-car-with-punctur {
		position: absolute;
		width: 250px;
		height: 250px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 174px;
		left: 0px;
		top: 338px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PWMOBIt_is_a_long_established_fact_ {
		left: 24px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 174px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PWMOBVISION {
		left: 24px;
		top: 277px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -12px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#PWMOBGroup_15120 {
		position: absolute;
		width: 390px;
		height: 512px;
		left: 0px;
		top: 1425px;
		overflow: visible;
	}
	#PWMOBshane-rounce-mgt2BlBgprI-unspl {
		position: absolute;
		width: 250px;
		height: 250px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBScroll_Group_28_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 174px;
		left: 0px;
		top: 338px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PWMOBIt_is_a_long_established_fact__ba {
		left: 24px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 172px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PWMOBMISSION {
		left: 24px;
		top: 277px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -12px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#PWMOBGroup_15121 {
		position: absolute;
		width: 390px;
		height: 512px;
		left: 0px;
		top: 1987px;
		overflow: visible;
	}
	#PWMOBmason-jones-9e_ssIz4jB4-unspla {
		position: absolute;
		width: 250px;
		height: 250px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBScroll_Group_28_be {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 174px;
		left: 0px;
		top: 338px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PWMOBIt_is_a_long_established_fact__bf {
		left: 24px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 174px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PWMOBPRINCIPLES {
		left: 24px;
		top: 277px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -12px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#PWMOBGroup_15122 {
		position: absolute;
		width: 358px;
		height: 574.998px;
		left: 16px;
		top: 3011px;
		overflow: visible;
	}
	#PWMOBGroup_15040 {
		position: absolute;
		width: 309px;
		height: 174px;
		left: 31.5px;
		top: 44.997px;
		overflow: visible;
	}
	#PWMOBRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.PWMOBRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 309px;
		height: 174px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#PWMOBGroup_15050 {
		position: absolute;
		width: 358px;
		height: 316px;
		left: 0px;
		top: 258.998px;
		overflow: visible;
	}
	#PWMOBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 194px;
		overflow: visible;
	}
	#PWMOBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PWMOBmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PWMOBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#PWMOBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.PWMOBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PWMOBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.PWMOBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PWMOBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.PWMOBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PWMOBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PWMOBshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PWMOBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#PWMOBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBmed_shadow_space_website_7_bw {
		fill: rgba(255,255,255,1);
	}
	.PWMOBmed_shadow_space_website_7_bw {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PWMOBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#PWMOBn_234567890_mobile_number_shad {
		left: 48px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#PWMOBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#PWMOBaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#PWMOBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#PWMOBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#PWMOBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#PWMOBContact_Us {
		left: 128px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -22.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#PWMOBGroup_15152 {
		position: absolute;
		width: 354px;
		height: 254px;
		left: 18px;
		top: 24px;
		overflow: visible;
	}
	#PWMOBText {
		position: absolute;
		width: 354px;
		height: 160px;
		left: 0px;
		top: 94px;
		overflow: visible;
	}
	#PWMOBExperience_the_finest_in_premi {
		left: 5px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 336px;
		white-space: nowrap;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(6,18,50,1);
	}
	#PWMOBCompany__Name {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 355px;
		height: 94px;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(6,18,50,1);
	}
	#PWMOBAnime_Edit {
		position: absolute;
		width: 55px;
		height: 55px;
		left: 4px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBBGs {
		position: absolute;
		width: 390px;
		height: 501px;
		left: 0px;
		top: 278px;
		overflow: visible;
	}
	#PWMOBtechnician-is-repairing-car-fl {
		position: absolute;
		width: 390px;
		height: 501px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBGroup_15133 {
		position: absolute;
		width: 375px;
		height: 332px;
		left: 0px;
		top: 2589px;
		overflow: visible;
	}
	#PWMOBOur_Offerings {
		left: 123px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 130px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PWMOBScroll_Group_90 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 305px;
		left: 0px;
		top: 27px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#PWMOBGroup_15128 {
		position: absolute;
		width: 203px;
		height: 254px;
		left: 15px;
		top: 22px;
		overflow: visible;
	}
	#PWMOBRectangle_750_ci {
		fill: url(#PWMOBRectangle_750_ci);
	}
	.PWMOBRectangle_750_ci {
		-webkit-filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 208px;
		height: 259px;
		left: 0px;
		top: 0px;
	}
	#PWMOBtechnician-is-repairing-car-fl_cj {
		position: absolute;
		width: 185px;
		height: 210px;
		left: 9px;
		top: 9px;
		overflow: visible;
	}
	#PWMOBPUNCTURE {
		left: 9px;
		top: 226px;
		position: absolute;
		overflow: visible;
		width: 186px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}

	.mediaViewInfo {
		--web-view-name: SweetTreat Mob;
		--web-view-id: SweetTreat_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: SweetTreat_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#SweetTreat_Mob {
		position: absolute;
		width: 375px;
		height: 3510px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: SweetTreat Mob;
		--web-view-id: SweetTreat_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#STMOBGroup_15132 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 8.5px;
		top: 2905.904px;
		overflow: visible;
	}
	#STMOBGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 30px;
		top: 73.095px;
		overflow: visible;
	}
	#STMOBRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.STMOBRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#STMOBGroup_15050 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STMOBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 480.096px;
		overflow: visible;
	}
	#STMOBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#STMOBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.STMOBmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#STMOBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#STMOBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.STMOBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#STMOBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.STMOBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#STMOBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.STMOBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#STMOBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#STMOBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.STMOBshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#STMOBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#STMOBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STMOBmed_shadow_space_website_7_bl {
		fill: rgba(255,255,255,1);
	}
	.STMOBmed_shadow_space_website_7_bl {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#STMOBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#STMOBn_234567890_mobile_number_shad {
		left: 48px;
		top: 293.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#STMOBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 340.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#STMOBaddress_med_shadow_space_websi {
		left: 35px;
		top: 390.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#STMOBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 392.096px;
		overflow: visible;
	}
	#STMOBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 339.096px;
		overflow: visible;
	}
	#STMOBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 286.096px;
		overflow: visible;
	}
	#STMOBn_Copyright_Operate_Live_OPC_P {
		left: 27px;
		top: 565.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#STMOBContact_Us {
		left: 127.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -22.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#STMOBGroup_15134 {
		position: absolute;
		width: 366px;
		height: 1637px;
		left: 7px;
		top: 753px;
		overflow: visible;
	}
	#STMOBGroup_15126 {
		position: absolute;
		width: 366px;
		height: 477.079px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STMOBshree-iyer-8vzLN4oHcGQ-unsplas {
		position: absolute;
		width: 220px;
		height: 220px;
		left: 70.5px;
		top: 0px;
		overflow: visible;
	}
	#STMOBScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 0px;
		top: 323.079px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#STMOBScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#STMOBIt_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#STMOBVISION {
		left: 2px;
		top: 258.079px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STMOBGroup_15130 {
		position: absolute;
		width: 366px;
		height: 477px;
		left: 0px;
		top: 580px;
		overflow: visible;
	}
	#STMOBaditya-joshi-uWI48cpw7Vo-unspl {
		position: absolute;
		width: 220px;
		height: 220px;
		left: 70.5px;
		top: 0px;
		overflow: visible;
	}
	#STMOBScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 0px;
		top: 323px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#STMOBScroll_Group_28_b::-webkit-scrollbar{
		display: none;
	}
	#STMOBIt_is_a_long_established_fact__b {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#STMOBMISSION {
		left: 2px;
		top: 258px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STMOBGroup_15131 {
		position: absolute;
		width: 366px;
		height: 477px;
		left: 0px;
		top: 1160px;
		overflow: visible;
	}
	#STMOBsanju-m-gurung-W1qp7p_laj0-uns {
		position: absolute;
		width: 220px;
		height: 220px;
		left: 70.5px;
		top: 0px;
		overflow: visible;
	}
	#STMOBScroll_Group_28_ca {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 0px;
		top: 323px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#STMOBScroll_Group_28_ca::-webkit-scrollbar{
		display: none;
	}
	#STMOBIt_is_a_long_established_fact__ca {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#STMOBPRINCIPLES {
		left: 2px;
		top: 258px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STMOBGroup_15133 {
		position: absolute;
		width: 375px;
		height: 332px;
		left: 0px;
		top: 2490px;
		overflow: visible;
	}
	#STMOBOur_Greatest_Drinks {
		left: 95px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 187px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STMOBScroll_Group_90 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 305px;
		left: 0px;
		top: 27px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#STMOBScroll_Group_90::-webkit-scrollbar{
		display: none;
	}
	#STMOBGroup_15128 {
		position: absolute;
		width: 203px;
		height: 254px;
		left: 15px;
		top: 22px;
		overflow: visible;
	}
	#STMOBRectangle_750_cg {
		fill: url(#STMOBRectangle_750_cg);
	}
	.STMOBRectangle_750_cg {
		-webkit-filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 208px;
		height: 259px;
		left: 0px;
		top: 0px;
	}
	#STMOBprchi-palwe-wwgulilpmlg-unspla {
		position: absolute;
		width: 185px;
		height: 210px;
		left: 9px;
		top: 9px;
		overflow: visible;
	}
	#STMOBJuice_1 {
		left: 9px;
		top: 226px;
		position: absolute;
		overflow: visible;
		width: 186px;
		height: 20px;
		line-height: 41px;
		margin-top: -13px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#STMOBGroup_15158 {
		position: absolute;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STMOBGroup_15129 {
		position: absolute;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STMOBGroup_15124 {
		position: absolute;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STMOBstefano-zocca-1p-Mx-SaLT0-unsp {
		position: absolute;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STMOBRectangle_749 {
		opacity: 0.24;
		fill: rgba(0,0,0,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.STMOBRectangle_749 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
	}
	#STMOBAnime_Edit {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 13px;
		top: 52px;
		overflow: visible;
	}
	#STMOBYour_Business_Name {
		left: 77px;
		top: 66px;
		position: absolute;
		overflow: visible;
		width: 189px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#STMOBIndulge_in_a_world_of_sweetnes {
		left: 13px;
		top: 243px;
		position: absolute;
		overflow: visible;
		width: 351px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 23px;
		color: rgba(255,255,255,1);
	}
	#STMOBGroup_15157 {
		position: absolute;
		width: 51.122px;
		height: 67.188px;
		left: 161.939px;
		top: 566.094px;
		overflow: visible;
	}
	#STMOBPath_7785 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.STMOBPath_7785 {
		overflow: visible;
		position: absolute;
		width: 32.202px;
		height: 62.657px;
		left: 21.92px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#STMOBPath_7786 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.STMOBPath_7786 {
		overflow: visible;
		position: absolute;
		width: 32.202px;
		height: 60.011px;
		left: 0px;
		top: 10.178px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#STMOBIcon_awesome-arrow-down {
		fill: rgba(255,255,255,1);
	}
	.STMOBIcon_awesome-arrow-down {
		overflow: visible;
		position: absolute;
		width: 27.916px;
		height: 28.642px;
		left: 11.964px;
		top: 19.654px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	body::-webkit-scrollbar{
		display: none;	
	}

	.mediaViewInfo {
		--web-view-name: CycleHaven mob;
		--web-view-id: CycleHaven_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: CycleHaven_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#CycleHaven_mob {
		position: absolute;
		width: 390px;
		height: 3702px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(5,5,5,1);
		overflow: hidden;
		--web-view-name: CycleHaven mob;
		--web-view-id: CycleHaven_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#CHMAnime_Edit {
		position: absolute;
		width: 66px;
		height: 66px;
		left: 24px;
		top: 36px;
		overflow: visible;
	}
	#CHMGroup_15122 {
		position: absolute;
		width: 358px;
		height: 588.998px;
		left: 16px;
		top: 3099.998px;
		overflow: visible;
	}
	#CHMGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 45.5px;
		top: 59.997px;
		overflow: visible;
	}
	#CHMRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.CHMRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#CHMGroup_15050 {
		position: absolute;
		width: 358px;
		height: 316px;
		left: 0px;
		top: 272.998px;
		overflow: visible;
	}
	#CHMsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 194px;
		overflow: visible;
	}
	#CHMlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#CHMmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CHMmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHMlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#CHMn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.CHMn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHMlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.CHMlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHMtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.CHMtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHMInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#CHMshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CHMshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHMinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#CHMwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHMmed_shadow_space_website_7_bk {
		fill: rgba(255,255,255,1);
	}
	.CHMmed_shadow_space_website_7_bk {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHMwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#CHMn_234567890_mobile_number_shad {
		left: 35px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#CHMaffanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#CHMaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#CHMlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#CHMemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#CHMphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHMn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#CHMContact_Us {
		left: 109.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(255,255,255,1);
	}
	#CHMCTA {
		position: absolute;
		width: 374px;
		height: 243px;
		left: 16px;
		top: 135px;
		overflow: visible;
	}
	#CHMScroll_Group_92 {
		mix-blend-mode: normal;
		position: absolute;
		width: 374px;
		height: 91px;
		left: 0px;
		top: 152px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHMLorem_ipsum_dolor_sit_amet_con {
		opacity: 0.6;
		left: 7px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 352px;
		height: 92px;
		line-height: 24px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#CHMCompany_Name {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		color: rgba(255,255,255,1);
	}
	#CHMGroup_15155 {
		position: absolute;
		width: 426.065px;
		height: 284.043px;
		left: -18.033px;
		top: 366px;
		overflow: visible;
	}
	#CHMEllipse_45 {
		-webkit-filter: blur(22px);
		        filter: blur(22px);
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHMEllipse_45 {
		position: absolute;
		overflow: visible;
		width: 384px;
		height: 140px;
		left: 80px;
		top: 240px;
	}
	#CHMwhite-bicycle-standing-park {
		position: absolute;
		width: 426.065px;
		height: 284.043px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHMGroup_15109 {
		position: absolute;
		width: 379px;
		height: 1707px;
		left: 11px;
		top: 780px;
		overflow: visible;
	}
	#CHMGroup_15105 {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHMmika-baumeister-dp9AxaKHdVg-un {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		        filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#CHMGroup_15104 {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#CHMScroll_Group_28_cb {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHMIt_is_a_long_established_fact__cc {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#CHMVISION_cd {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHMGroup_15106 {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHMmika-baumeister-dp9AxaKHdVg-un_b {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		        filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#CHMGroup_15104_ca {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#CHMScroll_Group_28_cb {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHMIt_is_a_long_established_fact__cc {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#CHMVISION_cd {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHMGroup_15106_ce {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 592px;
		overflow: visible;
	}
	#CHMpatrick-tomasso-1NTFSnV-KLs-un {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		        filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#CHMGroup_15104_cg {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#CHMScroll_Group_28_ch {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHMIt_is_a_long_established_fact__ci {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#CHMMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHMGroup_15106_ck {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 1184px;
		overflow: visible;
	}
	#CHMframes-for-your-heart-iOLHAIax {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		        filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#CHMGroup_15104_cm {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#CHMScroll_Group_28_cn {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHMIt_is_a_long_established_fact__co {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#CHMPRINCIPLES {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHMScroll_Group_62 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 309px;
		left: 0px;
		top: 2699px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#CHMGroup_15031 {
		position: absolute;
		width: 296px;
		height: 240px;
		left: 11px;
		top: 30px;
		overflow: visible;
	}
	#CHMRectangle_112 {
		fill: rgba(255,255,255,1);
	}
	.CHMRectangle_112 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 314px;
		height: 258px;
		left: 0px;
		top: 0px;
	}
	#CHMMask_Group_4 {
		position: absolute;
		width: 272px;
		height: 164px;
		left: 12px;
		top: 12.001px;
		overflow: visible;
	}
	#CHMGroup_9694 {
		position: absolute;
		width: 218.774px;
		height: 19px;
		left: 11.226px;
		top: 184px;
		overflow: visible;
	}
	#CHMBangalore {
		opacity: 0.5;
		left: 14.774px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 205px;
		height: 19px;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#CHMgeo-alt {
		position: absolute;
		width: 12.521px;
		height: 17.482px;
		left: 0px;
		top: 1.518px;
		overflow: visible;
	}
	#CHMPath_2927 {
		fill: rgba(254,120,71,1);
	}
	.CHMPath_2927 {
		overflow: visible;
		position: absolute;
		width: 12.521px;
		height: 17.482px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHMCycle {
		left: 13px;
		top: 206.998px;
		position: absolute;
		overflow: visible;
		width: 272px;
		height: 22px;
		text-align: left;
		font-family: Sen;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#CHMOur_Collection {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 116px;
		top: 2640.999px;
		position: absolute;
		overflow: visible;
		width: 159px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(255,255,255,1);
	}

	.mediaViewInfo {
		--web-view-name: RadiantLocks Mob;
		--web-view-id: RadiantLocks_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: RadiantLocks_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#RadiantLocks_Mob {
		position: absolute;
		width: 375px;
		height: 3925px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: RadiantLocks Mob;
		--web-view-id: RadiantLocks_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#RLMOBGroup_15132 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 8.5px;
		top: 3326px;
		overflow: visible;
	}
	#RLMOBGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 30px;
		top: 73.095px;
		overflow: visible;
	}
	#RLMOBRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.RLMOBRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#RLMOBGroup_15050 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#RLMOBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 480.096px;
		overflow: visible;
	}
	#RLMOBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#RLMOBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.RLMOBmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#RLMOBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#RLMOBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.RLMOBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#RLMOBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.RLMOBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#RLMOBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.RLMOBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#RLMOBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#RLMOBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.RLMOBshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#RLMOBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#RLMOBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#RLMOBmed_shadow_space_website_7_bn {
		fill: rgba(255,255,255,1);
	}
	.RLMOBmed_shadow_space_website_7_bn {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#RLMOBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#RLMOBn_234567890_mobile_number_shad {
		left: 48px;
		top: 293.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#RLMOBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 340.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#RLMOBaddress_med_shadow_space_websi {
		left: 35px;
		top: 390.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#RLMOBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 392.096px;
		overflow: visible;
	}
	#RLMOBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 339.096px;
		overflow: visible;
	}
	#RLMOBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 286.096px;
		overflow: visible;
	}
	#RLMOBn_Copyright_Operate_Live_OPC_P {
		left: 7px;
		top: 565.096px;
		position: absolute;
		overflow: visible;
		width: 310px;
		height: 36px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#RLMOBContact_Us {
		left: 127.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -22.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#RLMOBGroup_15139 {
		position: absolute;
		width: 375px;
		height: 109px;
		left: 0px;
		top: 721px;
		overflow: visible;
	}
	#RLMOBRectangle_752 {
		fill: rgba(235,161,99,1);
	}
	.RLMOBRectangle_752 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 109px;
		left: 0px;
		top: 0px;
	}
	#RLMOBScroll_Group_93 {
		mix-blend-mode: normal;
		position: absolute;
		width: 374px;
		height: 109px;
		left: 1px;
		top: 0px;
		overflow: hidden;
	}
	#RLMOBIntroduction {
		left: 9px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 357px;
		height: 102px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#RLMOBGroup_15164 {
		position: absolute;
		width: 337px;
		height: 1842px;
		left: 19px;
		top: 900px;
		overflow: visible;
	}
	#RLMOBGroup_15141 {
		position: absolute;
		width: 337px;
		height: 594px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#RLMOBRectangle_752_b {
		fill: rgba(249,188,137,1);
	}
	.RLMOBRectangle_752_b {
		position: absolute;
		overflow: visible;
		width: 337px;
		height: 594px;
		left: 0px;
		top: 0px;
	}
	#RLMOBjamie-street-aMuq-Xz7R_M-unspl {
		position: absolute;
		width: 274px;
		height: 329px;
		left: 34px;
		top: 31px;
		overflow: visible;
	}
	#RLMOBScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 301px;
		height: 144px;
		left: 36px;
		top: 427px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#RLMOBIt_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 270px;
		height: 136px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#RLMOBVISION {
		left: 38px;
		top: 390px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#RLMOBGroup_15160 {
		position: absolute;
		width: 337px;
		height: 594px;
		left: 0px;
		top: 624px;
		overflow: visible;
	}
	#RLMOBRectangle_752_ca {
		fill: rgba(216,180,180,1);
	}
	.RLMOBRectangle_752_ca {
		position: absolute;
		overflow: visible;
		width: 337px;
		height: 594px;
		left: 0px;
		top: 0px;
	}
	#RLMOBjamie-street-aMuq-Xz7R_M-unspl_ca {
		position: absolute;
		width: 274px;
		height: 329px;
		left: 34px;
		top: 31px;
		overflow: visible;
	}
	#RLMOBScroll_Group_28_cb {
		mix-blend-mode: normal;
		position: absolute;
		width: 301px;
		height: 144px;
		left: 36px;
		top: 427px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#RLMOBIt_is_a_long_established_fact__cc {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 270px;
		height: 136px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#RLMOBMISSION {
		left: 38px;
		top: 390px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#RLMOBGroup_15161 {
		position: absolute;
		width: 337px;
		height: 594px;
		left: 0px;
		top: 1248px;
		overflow: visible;
	}
	#RLMOBRectangle_752_cf {
		fill: rgba(150,222,145,1);
	}
	.RLMOBRectangle_752_cf {
		position: absolute;
		overflow: visible;
		width: 337px;
		height: 594px;
		left: 0px;
		top: 0px;
	}
	#RLMOBjamie-street-aMuq-Xz7R_M-unspl_cg {
		position: absolute;
		width: 274px;
		height: 329px;
		left: 34px;
		top: 31px;
		overflow: visible;
	}
	#RLMOBScroll_Group_28_ch {
		mix-blend-mode: normal;
		position: absolute;
		width: 301px;
		height: 144px;
		left: 36px;
		top: 427px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#RLMOBIt_is_a_long_established_fact__ci {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 270px;
		height: 136px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#RLMOBPRINCIPLE {
		left: 38px;
		top: 390px;
		position: absolute;
		overflow: visible;
		width: 100px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#RLMOBGroup_15163 {
		position: absolute;
		width: 375px;
		height: 423px;
		left: 0px;
		top: 2843px;
		overflow: visible;
	}
	#RLMOBScroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 359px;
		left: 0px;
		top: 64px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#RLMOBGroup_15144 {
		position: absolute;
		width: 221px;
		height: 301px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#RLMOBRectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.RLMOBRectangle_754 {
		-webkit-filter: drop-shadow(5px 5px 1px rgba(235, 99, 99, 0.518));
		        filter: drop-shadow(5px 5px 1px rgba(235, 99, 99, 0.518));
		position: absolute;
		overflow: visible;
		width: 227.5px;
		height: 307.5px;
		left: 0px;
		top: 0px;
	}
	#RLMOBthibaut-burckel-n2V5MLDPE-k-un {
		position: absolute;
		width: 217px;
		height: 231px;
		left: 2px;
		top: 35px;
		overflow: visible;
	}
	#RLMOBTitle {
		left: 6px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 210px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#RLMOBn_10000 {
		left: 6px;
		top: 274px;
		position: absolute;
		overflow: visible;
		width: 210px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#RLMOBGroup_15162 {
		position: absolute;
		width: 337px;
		height: 49px;
		left: 19px;
		top: 0px;
		overflow: visible;
	}
	#RLMOBOfferings {
		left: 0px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#RLMOBGroup_15145 {
		position: absolute;
		width: 123px;
		height: 49px;
		left: 214px;
		top: 0px;
		overflow: visible;
	}
	#RLMOBRectangle_753 {
		fill: rgba(255,255,255,1);
		stroke: rgba(0,0,0,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.RLMOBRectangle_753 {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.678));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.678));
		position: absolute;
		overflow: visible;
		width: 141px;
		height: 67px;
		left: 0px;
		top: 0px;
	}
	#RLMOBView_More {
		left: 34px;
		top: 18px;
		position: absolute;
		overflow: visible;
		width: 53px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#RLMOBGroup_15098 {
		position: absolute;
		width: 390px;
		height: 661px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#RLMOBMask_Group_1 {
		position: absolute;
		width: 390px;
		height: 358px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#RLMOBGroup_15092 {
		position: absolute;
		width: 127px;
		height: 35px;
		left: 24px;
		top: 626px;
		overflow: visible;
	}
	#RLMOBRectangle_3 {
		fill: rgba(252,213,70,1);
	}
	.RLMOBRectangle_3 {
		position: absolute;
		overflow: visible;
		width: 127px;
		height: 35px;
		left: 0px;
		top: 0px;
	}
	#RLMOBOrder_Now {
		left: 36px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 56px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		color: rgba(255,255,255,1);
	}
	#RLMOBWhere_Beauty_Meets__Confidence {
		left: 18px;
		top: 410px;
		position: absolute;
		overflow: visible;
		width: 315px;
		white-space: nowrap;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 32px;
		color: rgba(36,36,36,1);
	}
	#RLMOBChoose_one_of_our_plans_enter_ {
		opacity: 0.5;
		left: 24px;
		top: 560px;
		position: absolute;
		overflow: visible;
		width: 303px;
		white-space: nowrap;
		line-height: 21px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(36,36,36,1);
	}
	#RLMOBCompany_Name {
		left: 20px;
		top: 358px;
		position: absolute;
		overflow: visible;
		width: 174px;
		white-space: nowrap;
		line-height: 37px;
		margin-top: -7px;
		text-align: left;
		font-family: Semplicità;
		font-style: normal;
		font-weight: bold;
		font-size: 23px;
		color: rgba(36,36,36,1);
	}
	@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
	@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation: fadeIn 3s;
          animation: fadeIn 3s;
}

	.mediaViewInfo {
		--web-view-name: True Vision Mob;
		--web-view-id: True_Vision_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: True_Vision_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#True_Vision_Mob {
		position: absolute;
		width: 375px;
		height: 3510px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: True Vision Mob;
		--web-view-id: True_Vision_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#TVMGroup_15132 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 8.5px;
		top: 2905.904px;
		overflow: visible;
	}
	#TVMGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 30px;
		top: 73.095px;
		overflow: visible;
	}
	#TVMRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.TVMRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#TVMGroup_15050 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVMsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 480.096px;
		overflow: visible;
	}
	#TVMlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#TVMmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TVMmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TVMlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#TVMn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.TVMn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TVMlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.TVMlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TVMtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.TVMtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TVMInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#TVMshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TVMshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TVMinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#TVMwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVMmed_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.TVMmed_shadow_space_website_7_ {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TVMwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#TVMn_234567890_mobile_number_shad {
		left: 48px;
		top: 293.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TVMaffanghanioperatelive_email_sh {
		left: 35px;
		top: 340.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TVMaddress_med_shadow_space_websi {
		left: 35px;
		top: 390.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TVMlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 392.096px;
		overflow: visible;
	}
	#TVMemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 339.096px;
		overflow: visible;
	}
	#TVMphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 286.096px;
		overflow: visible;
	}
	#TVMn_Copyright_Operate_Live_OPC_P {
		left: 27px;
		top: 565.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#TVMContact_Us {
		left: 127.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -22.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#TVMGroup_15168 {
		position: absolute;
		width: 1042px;
		height: 695px;
		left: -442px;
		top: -26px;
		overflow: visible;
	}
	#TVMvolodymyr-hryshchenko-NQQ_WKbj {
		position: absolute;
		width: 1042px;
		height: 695px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVMSee_the_world_through_a__clear {
		left: 459px;
		top: 194px;
		position: absolute;
		overflow: visible;
		width: 333px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(255,255,255,1);
	}
	#TVMAnime_Edit {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 459px;
		top: 68px;
		overflow: visible;
	}
	#TVMYour_Business_Name {
		left: 504px;
		top: 77px;
		position: absolute;
		overflow: visible;
		width: 157px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#TVMGroup_15114 {
		position: absolute;
		width: 365px;
		height: 497px;
		left: 10px;
		top: 781px;
		overflow: visible;
	}
	#TVMjoshua-newton-g4_IymCiD-k-unsp {
		position: absolute;
		width: 258px;
		height: 258px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#TVMScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 365px;
		height: 152px;
		left: 0px;
		top: 345px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TVMIt_is_a_long_established_fact_ {
		left: 10px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 348px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#TVMVISION {
		left: 10px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVMGroup_15165 {
		position: absolute;
		width: 365px;
		height: 497px;
		left: 19px;
		top: 1348px;
		overflow: visible;
	}
	#TVMfelipepelaquim-0q5Y7OFmMhM-uns {
		position: absolute;
		width: 258px;
		height: 258px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#TVMScroll_Group_28_bo {
		mix-blend-mode: normal;
		position: absolute;
		width: 365px;
		height: 152px;
		left: 0px;
		top: 345px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TVMIt_is_a_long_established_fact__bp {
		left: 10px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 348px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#TVMMISSION {
		left: 10px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVMGroup_15166 {
		position: absolute;
		width: 365px;
		height: 497px;
		left: 10px;
		top: 1915px;
		overflow: visible;
	}
	#TVMdmitry-ratushny-wpi3sDUrSEk-un {
		position: absolute;
		width: 258px;
		height: 258px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#TVMScroll_Group_28_bt {
		mix-blend-mode: normal;
		position: absolute;
		width: 365px;
		height: 152px;
		left: 0px;
		top: 345px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TVMIt_is_a_long_established_fact__bu {
		left: 10px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 348px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#TVMPRINCIPLE {
		left: 10px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 100px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVMScroll_Group_94 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 282px;
		left: 0px;
		top: 2535px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#TVMn_ {
		position: absolute;
		width: 217px;
		height: 242px;
		left: 19px;
		top: 20px;
		overflow: visible;
	}
	#TVMMask_Group_22 {
		position: absolute;
		width: 217px;
		height: 220px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVMRectangle_664 {
		fill: rgba(0,0,0,1);
	}
	.TVMRectangle_664 {
		position: absolute;
		overflow: visible;
		width: 217px;
		height: 33px;
		left: 0px;
		top: 209px;
	}
	#TVMCOSMOS {
		left: 7px;
		top: 218px;
		position: absolute;
		overflow: visible;
		width: 207px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}
	#TVMGroup_10773 {
		position: absolute;
		width: 33px;
		height: 3px;
		left: 92px;
		top: 190px;
		overflow: visible;
	}
	#TVMEllipse_38 {
		fill: rgba(255,255,255,1);
	}
	.TVMEllipse_38 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 0px;
		top: 0px;
	}
	#TVMEllipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.TVMEllipse_39 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 8px;
		top: 0px;
	}
	#TVMEllipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.TVMEllipse_40 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 15px;
		top: 0px;
	}
	#TVMEllipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.TVMEllipse_41 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 21px;
		top: 0px;
	}
	#TVMEllipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.TVMEllipse_42 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 29px;
		top: 0px;
	}
	#TVMWIDE_RANGE_OF_DESIGN {
		left: 70px;
		top: 2482px;
		position: absolute;
		overflow: visible;
		width: 236px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}

	.mediaViewInfo {
		--web-view-name: TechTrekker mob;
		--web-view-id: TechTrekker_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: TechTrekker_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#TechTrekker_mob {
		position: absolute;
		width: 375px;
		height: 4709px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: TechTrekker mob;
		--web-view-id: TechTrekker_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#TTMOBGroup_15132 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 8.5px;
		top: 4097.904px;
		overflow: visible;
	}
	#TTMOBGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 30px;
		top: 73.095px;
		overflow: visible;
	}
	#TTMOBRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#TTMOBGroup_15050 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 480.096px;
		overflow: visible;
	}
	#TTMOBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TTMOBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#TTMOBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.TTMOBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.TTMOBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.TTMOBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TTMOBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#TTMOBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBmed_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.TTMOBmed_shadow_space_website_7_ {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TTMOBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#TTMOBn_234567890_mobile_number_shad {
		left: 48px;
		top: 293.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TTMOBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 340.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TTMOBaddress_med_shadow_space_websi {
		left: 35px;
		top: 390.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TTMOBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 392.096px;
		overflow: visible;
	}
	#TTMOBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 339.096px;
		overflow: visible;
	}
	#TTMOBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 286.096px;
		overflow: visible;
	}
	#TTMOBn_Copyright_Operate_Live_OPC_P {
		left: 27px;
		top: 565.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#TTMOBContact_Us {
		left: 127.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -22.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#TTMOBScroll_Group_95 {
		mix-blend-mode: normal;
		position: absolute;
		width: 356px;
		height: 241px;
		left: 9px;
		top: 119px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#TTMOBControllers {
		position: absolute;
		width: 525px;
		height: 219px;
		left: 6px;
		top: 11px;
		overflow: visible;
	}
	#TTMOBRectangle_3 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTMOBRectangle_3 {
		position: absolute;
		overflow: visible;
		width: 149px;
		height: 213px;
		left: 364px;
		top: 0px;
	}
	#TTMOBstefen-tan-KYw1eUx1J7Y-unsplas {
		position: absolute;
		width: 165px;
		height: 219px;
		left: 360px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBGroup_15173 {
		position: absolute;
		width: 150px;
		height: 213px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBRectangle_1 {
		opacity: 0.35;
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTMOBRectangle_1 {
		position: absolute;
		overflow: visible;
		width: 150px;
		height: 213px;
		left: 0px;
		top: 0px;
	}
	#TTMOBn_a36d7d2482866597129061513543 {
		position: absolute;
		width: 112px;
		height: 169px;
		left: 19px;
		top: 22px;
		overflow: visible;
	}
	#TTMOBGroup_15172 {
		position: absolute;
		width: 150px;
		height: 213.16px;
		left: 180px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBRectangle_2 {
		opacity: 0.35;
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTMOBRectangle_2 {
		position: absolute;
		overflow: visible;
		width: 150px;
		height: 213px;
		left: 0px;
		top: 0px;
	}
	#TTMOBryan-putra-j4PqlNVZ4Bc-unsplas {
		position: absolute;
		width: 142.051px;
		height: 213.16px;
		left: 0.998px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBquaritsch-photography-m2zuB8Dq {
		position: absolute;
		width: 495px;
		height: 330.366px;
		left: -28px;
		top: 312px;
		overflow: visible;
	}
	#TTMOBCompany_Name {
		left: 100px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 206px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,28,65,1);
	}
	#TTMOBjoshua-newton-g4_IymCiD-k-unsp {
		position: absolute;
		width: 61px;
		height: 61px;
		left: 24px;
		top: 22px;
		overflow: visible;
	}
	#TTMOBBottom_right_items {
		position: absolute;
		width: 54px;
		height: 120px;
		left: 24px;
		top: 477px;
		overflow: visible;
	}
	#TTMOBRectangle_15 {
		fill: rgba(234,238,244,1);
	}
	.TTMOBRectangle_15 {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 49px;
		left: 0px;
		top: 71px;
	}
	#TTMOBRectangle_16 {
		fill: rgba(255,88,178,0.141);
	}
	.TTMOBRectangle_16 {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#TTMOBhand-heart {
		position: absolute;
		width: 19.034px;
		height: 16.73px;
		left: 17.825px;
		top: 16.862px;
		overflow: visible;
	}
	#TTMOBPath_5 {
		fill: rgba(255,88,178,0);
		stroke: rgba(255,88,178,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: square;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.TTMOBPath_5 {
		overflow: visible;
		position: absolute;
		width: 23.104px;
		height: 21.633px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBquestion-mark {
		position: absolute;
		width: 13.332px;
		height: 22.073px;
		left: 20.327px;
		top: 84.828px;
		overflow: visible;
	}
	#TTMOBPath_7 {
		fill: rgba(93,106,126,1);
	}
	.TTMOBPath_7 {
		overflow: visible;
		position: absolute;
		width: 13.332px;
		height: 16.555px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_8 {
		fill: rgba(93,106,126,1);
	}
	.TTMOBPath_8 {
		overflow: visible;
		position: absolute;
		width: 3.678px;
		height: 3.678px;
		left: 4.503px;
		top: 18.395px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBGroup_15174 {
		position: absolute;
		width: 353.442px;
		height: 73px;
		left: 10.779px;
		top: 642.366px;
		overflow: visible;
	}
	#TTMOBNEW_GADGETS {
		left: 2px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 131px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(114,124,137,1);
		letter-spacing: 2.2px;
	}
	#TTMOBBuy_Now {
		left: 0px;
		top: 31px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: lighter;
		font-size: 13px;
		color: rgba(114,124,137,1);
	}
	#TTMOBView_details {
		left: 254px;
		top: 31px;
		position: absolute;
		overflow: visible;
		width: 80px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(33,109,252,1);
	}
	#TTMOBEllipse_3 {
		fill: rgba(33,109,252,1);
	}
	.TTMOBEllipse_3 {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 2px;
		top: 62px;
	}
	#TTMOBEllipse_4 {
		fill: rgba(214,217,224,1);
	}
	.TTMOBEllipse_4 {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 37px;
		top: 62px;
	}
	#TTMOBEllipse_5 {
		fill: rgba(214,217,224,1);
	}
	.TTMOBEllipse_5 {
		position: absolute;
		overflow: visible;
		width: 10px;
		height: 11px;
		left: 73px;
		top: 62px;
	}
	#TTMOBPath_6 {
		fill: rgba(33,109,252,1);
	}
	.TTMOBPath_6 {
		overflow: visible;
		position: absolute;
		width: 8.108px;
		height: 12.012px;
		-webkit-transform: translate(-107px, -565px) matrix(1,0,0,1,452.3337,598.7474) rotate(180deg);
		        transform: translate(-107px, -565px) matrix(1,0,0,1,452.3337,598.7474) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#TTMOBLine_1 {
		fill: transparent;
		stroke: rgba(190,190,190,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTMOBLine_1 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 15.427px;
		left: 235.231px;
		top: 31.748px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBBrands {
		position: absolute;
		width: 375px;
		height: 122px;
		left: 0px;
		top: 873px;
		overflow: visible;
	}
	#TTMOBRectangle_79 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_79 {
		-webkit-filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.039));
		        filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.039));
		position: absolute;
		overflow: visible;
		width: 525px;
		height: 272px;
		left: 0px;
		top: 0px;
	}
	#TTMOBLogitech-logo-300x300 {
		opacity: 0.61;
		position: absolute;
		width: 81px;
		height: 81px;
		left: 37px;
		top: 36px;
		overflow: visible;
	}
	#TTMOBOur_Partners {
		left: 139px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 98px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,28,65,1);
	}
	#TTMOBRectangle_46 {
		fill: rgba(251,252,253,1);
	}
	.TTMOBRectangle_46 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 2352px;
		left: 0px;
		top: 1075px;
	}
	#TTMOBGroup_15175 {
		position: absolute;
		width: 292px;
		height: 65px;
		left: 42px;
		top: 1103px;
		overflow: visible;
	}
	#TTMOBOUR_SERVICES {
		left: 87px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 119px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -31.5px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(61,55,241,1);
	}
	#TTMOBWe_Provide_Best_Quality_Gadget {
		left: 0px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 293px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -30px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(90,84,84,1);
	}
	#TTMOBGroup_15176 {
		position: absolute;
		width: 330px;
		height: 403px;
		left: 23px;
		top: 1204px;
		overflow: visible;
	}
	#TTMOBRectangle_47_ci {
		fill: url(#TTMOBRectangle_47_ci);
	}
	.TTMOBRectangle_47_ci {
		-webkit-filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		        filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 450px;
		height: 523px;
		left: 0px;
		top: 0px;
	}
	#TTMOBOur_Services {
		left: 104.125px;
		top: 218.706px;
		position: absolute;
		overflow: visible;
		width: 107px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -14px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(255,255,255,1);
	}
	#TTMOBFast_Powerful__Most_Secure_Net {
		left: 18.751px;
		top: 254.52px;
		position: absolute;
		overflow: visible;
		width: 293.875px;
		height: 55.3604621887207px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#TTMOBRectangle_50 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_50 {
		-webkit-filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 100px;
		left: 106px;
		top: 320px;
	}
	#TTMOBRead_More {
		left: 133px;
		top: 330px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		line-height: 69px;
		margin-top: -28px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(75,100,126,1);
	}
	#TTMOBIcon1 {
		position: absolute;
		width: 89.9px;
		height: 90.109px;
		left: 120.543px;
		top: 75.004px;
		overflow: visible;
	}
	#TTMOBPath_1202 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBPath_1202 {
		overflow: visible;
		position: absolute;
		width: 74.035px;
		height: 76.624px;
		left: 0.001px;
		top: 13.486px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1203 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBPath_1203 {
		overflow: visible;
		position: absolute;
		width: 29.47px;
		height: 29.517px;
		left: 60.43px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBRectangle_92 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_92 {
		position: absolute;
		overflow: visible;
		width: 17.682px;
		height: 2.947px;
		left: 66.324px;
		top: 5.894px;
	}
	#TTMOBRectangle_93 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_93 {
		position: absolute;
		overflow: visible;
		width: 11.788px;
		height: 2.947px;
		left: 66.324px;
		top: 11.789px;
	}
	#TTMOBRectangle_94 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_94 {
		position: absolute;
		overflow: visible;
		width: 2.947px;
		height: 2.947px;
		left: 81.059px;
		top: 11.789px;
	}
	#TTMOBPath_1204 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBPath_1204 {
		overflow: visible;
		position: absolute;
		width: 57.693px;
		height: 37.386px;
		left: 0px;
		top: 4.421px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1205 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBPath_1205 {
		overflow: visible;
		position: absolute;
		width: 15.197px;
		height: 44.21px;
		left: 73.988px;
		top: 26.72px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBGroup_15177 {
		position: absolute;
		width: 330px;
		height: 402px;
		left: 23px;
		top: 1637px;
		overflow: visible;
	}
	#TTMOBRectangle_51 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_51 {
		-webkit-filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		        filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 450px;
		height: 522px;
		left: 0px;
		top: 0px;
	}
	#TTMOBSecurity_Solutions {
		left: 80.817px;
		top: 218.456px;
		position: absolute;
		overflow: visible;
		width: 151px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -14px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(90,84,84,1);
	}
	#TTMOBFast_Powerful__Most_Secure_Net_cy {
		left: 18.73px;
		top: 254.229px;
		position: absolute;
		overflow: visible;
		width: 293.541px;
		height: 55.297298431396484px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(116,116,116,1);
	}
	#TTMOBRectangle_52 {
		fill: transparent;
		stroke: rgba(236,227,227,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTMOBRectangle_52 {
		position: absolute;
		overflow: visible;
		width: 117px;
		height: 40px;
		left: 106px;
		top: 319px;
	}
	#TTMOBRead_More_c {
		left: 133px;
		top: 330px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		line-height: 69px;
		margin-top: -28px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(75,100,126,1);
	}
	#TTMOBIcon2 {
		position: absolute;
		width: 89.189px;
		height: 89.189px;
		left: 120.405px;
		top: 75.811px;
		overflow: visible;
	}
	#TTMOBPath_1206 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1206 {
		overflow: visible;
		position: absolute;
		width: 56.299px;
		height: 82.273px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1207 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1207 {
		overflow: visible;
		position: absolute;
		width: 56.332px;
		height: 82.273px;
		left: 32.857px;
		top: 6.916px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1208 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1208 {
		overflow: visible;
		position: absolute;
		width: 3.504px;
		height: 3.49px;
		left: 26.882px;
		top: 82.632px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1209 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1209 {
		overflow: visible;
		position: absolute;
		width: 3.513px;
		height: 3.496px;
		left: 58.864px;
		top: 3.091px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1210 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1210 {
		overflow: visible;
		position: absolute;
		width: 70.376px;
		height: 44.813px;
		left: 9.407px;
		top: 16.679px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1211 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1211 {
		overflow: visible;
		position: absolute;
		width: 29.265px;
		height: 38.247px;
		left: 29.962px;
		top: 32.477px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1212 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1212 {
		overflow: visible;
		position: absolute;
		width: 10.021px;
		height: 13.934px;
		left: 39.565px;
		top: 50.693px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBGroup_15178 {
		position: absolute;
		width: 330px;
		height: 402px;
		left: 23px;
		top: 2069px;
		overflow: visible;
	}
	#TTMOBRectangle_53 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_53 {
		-webkit-filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		        filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 450px;
		height: 522px;
		left: 0px;
		top: 0px;
	}
	#TTMOBSound_Systems {
		left: 93.303px;
		top: 218.456px;
		position: absolute;
		overflow: visible;
		width: 127px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -14px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(90,84,84,1);
	}
	#TTMOBFast_Powerful__Most_Secure_Net_dc {
		left: 18.73px;
		top: 254.229px;
		position: absolute;
		overflow: visible;
		width: 293.541px;
		height: 55.297298431396484px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(116,116,116,1);
	}
	#TTMOBRectangle_54 {
		fill: transparent;
		stroke: rgba(236,227,227,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTMOBRectangle_54 {
		position: absolute;
		overflow: visible;
		width: 117px;
		height: 40px;
		left: 106px;
		top: 319px;
	}
	#TTMOBRead_More_de {
		left: 133px;
		top: 330px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		line-height: 69px;
		margin-top: -28px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(75,100,126,1);
	}
	#TTMOBIcon3 {
		position: absolute;
		width: 89.88px;
		height: 88.297px;
		left: 120.405px;
		top: 75.811px;
		overflow: visible;
	}
	#TTMOBPath_1213 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1213 {
		overflow: visible;
		position: absolute;
		width: 38.796px;
		height: 10.123px;
		left: 25.542px;
		top: 38.94px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1214 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1214 {
		overflow: visible;
		position: absolute;
		width: 27.2px;
		height: 7.721px;
		left: 31.34px;
		top: 47.14px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBGroup_296 {
		position: absolute;
		width: 89.88px;
		height: 88.297px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBGroup_295 {
		position: absolute;
		width: 89.88px;
		height: 88.297px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBGroup_15171 {
		position: absolute;
		width: 330px;
		height: 402px;
		left: 23px;
		top: 2501px;
		overflow: visible;
	}
	#TTMOBRectangle_57 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_57 {
		-webkit-filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		        filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 450px;
		height: 522px;
		left: 0px;
		top: 0px;
	}
	#TTMOBPeripherals {
		left: 110.249px;
		top: 218.456px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -14px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(90,84,84,1);
	}
	#TTMOBFast_Powerful__Most_Secure_Net_dn {
		left: 18.73px;
		top: 254.229px;
		position: absolute;
		overflow: visible;
		width: 293.541px;
		height: 55.297298431396484px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(116,116,116,1);
	}
	#TTMOBRectangle_58 {
		fill: transparent;
		stroke: rgba(236,227,227,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTMOBRectangle_58 {
		position: absolute;
		overflow: visible;
		width: 117px;
		height: 40px;
		left: 106px;
		top: 319px;
	}
	#TTMOBRead_More_dp {
		left: 133px;
		top: 330px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		line-height: 69px;
		margin-top: -28px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(75,100,126,1);
	}
	#TTMOBIcon4 {
		position: absolute;
		width: 89.718px;
		height: 89.72px;
		left: 120.406px;
		top: 77.595px;
		overflow: visible;
	}
	#TTMOBPath_1217 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1217 {
		overflow: visible;
		position: absolute;
		width: 3.505px;
		height: 3.505px;
		left: 17.998px;
		top: 44.745px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1218 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1218 {
		overflow: visible;
		position: absolute;
		width: 5.022px;
		height: 3.505px;
		left: 26.367px;
		top: 44.745px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1219 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1219 {
		overflow: visible;
		position: absolute;
		width: 3.505px;
		height: 3.505px;
		left: 34.737px;
		top: 44.745px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1220 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1220 {
		overflow: visible;
		position: absolute;
		width: 3.505px;
		height: 3.505px;
		left: 43.107px;
		top: 44.745px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1221 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1221 {
		overflow: visible;
		position: absolute;
		width: 3.505px;
		height: 3.505px;
		left: 51.476px;
		top: 44.745px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1222 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1222 {
		overflow: visible;
		position: absolute;
		width: 3.505px;
		height: 3.505px;
		left: 59.845px;
		top: 44.745px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1223 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1223 {
		overflow: visible;
		position: absolute;
		width: 3.505px;
		height: 3.505px;
		left: 68.215px;
		top: 44.745px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1224 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1224 {
		overflow: visible;
		position: absolute;
		width: 4.889px;
		height: 3.505px;
		left: 9.462px;
		top: 53.651px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1225 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1225 {
		overflow: visible;
		position: absolute;
		width: 4.888px;
		height: 3.505px;
		left: 19.225px;
		top: 53.651px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1226 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1226 {
		overflow: visible;
		position: absolute;
		width: 3.503px;
		height: 3.505px;
		left: 27.619px;
		top: 53.651px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1227 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1227 {
		overflow: visible;
		position: absolute;
		width: 3.504px;
		height: 3.505px;
		left: 36.097px;
		top: 53.651px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1228 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1228 {
		overflow: visible;
		position: absolute;
		width: 3.505px;
		height: 3.504px;
		left: 19.458px;
		top: 62.091px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1229 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1229 {
		overflow: visible;
		position: absolute;
		width: 3.503px;
		height: 3.504px;
		left: 27.937px;
		top: 62.091px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1230 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1230 {
		overflow: visible;
		position: absolute;
		width: 7.011px;
		height: 3.505px;
		left: 46.613px;
		top: 53.651px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1231 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1231 {
		overflow: visible;
		position: absolute;
		width: 13.026px;
		height: 3.504px;
		left: 37.441px;
		top: 62.091px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1232 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1232 {
		overflow: visible;
		position: absolute;
		width: 4.91px;
		height: 3.505px;
		left: 75.345px;
		top: 53.651px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1233 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1233 {
		overflow: visible;
		position: absolute;
		width: 3.505px;
		height: 3.505px;
		left: 33.819px;
		top: 9.463px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1234 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1234 {
		overflow: visible;
		position: absolute;
		width: 89.718px;
		height: 80.256px;
		left: 0px;
		top: 9.463px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1235 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1235 {
		overflow: visible;
		position: absolute;
		width: 30.84px;
		height: 31.542px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1236 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1236 {
		overflow: visible;
		position: absolute;
		width: 15.169px;
		height: 3.505px;
		left: 7.835px;
		top: 21.025px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1237 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1237 {
		overflow: visible;
		position: absolute;
		width: 15.169px;
		height: 3.504px;
		left: 7.835px;
		top: 14.019px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1238 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1238 {
		overflow: visible;
		position: absolute;
		width: 15.169px;
		height: 3.505px;
		left: 7.835px;
		top: 7.01px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBGroup_15170 {
		position: absolute;
		width: 330px;
		height: 402px;
		left: 23px;
		top: 2982px;
		overflow: visible;
	}
	#TTMOBRectangle_55 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_55 {
		-webkit-filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		        filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 450px;
		height: 522px;
		left: 0px;
		top: 0px;
	}
	#TTMOBPrinters_Projectors__Scanners {
		left: 25.52px;
		top: 218.456px;
		position: absolute;
		overflow: visible;
		width: 259px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -14px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(90,84,84,1);
	}
	#TTMOBFast_Powerful__Most_Secure_Net_eg {
		left: 18.73px;
		top: 254.229px;
		position: absolute;
		overflow: visible;
		width: 293.541px;
		height: 55.297298431396484px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(116,116,116,1);
	}
	#TTMOBRectangle_60 {
		fill: transparent;
		stroke: rgba(236,227,227,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTMOBRectangle_60 {
		position: absolute;
		overflow: visible;
		width: 117px;
		height: 40px;
		left: 106px;
		top: 319px;
	}
	#TTMOBRead_More_ei {
		left: 133px;
		top: 330px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		line-height: 69px;
		margin-top: -28px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(75,100,126,1);
	}
	#TTMOBIcon6 {
		position: absolute;
		width: 89.535px;
		height: 89.535px;
		left: 120.405px;
		top: 77.595px;
		overflow: visible;
	}
	#TTMOBPath_1247 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1247 {
		overflow: visible;
		position: absolute;
		width: 89.534px;
		height: 89.534px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1248 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1248 {
		overflow: visible;
		position: absolute;
		width: 13.43px;
		height: 13.43px;
		left: 53.721px;
		top: 49.244px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBRectangle_95 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_95 {
		position: absolute;
		overflow: visible;
		width: 13.43px;
		height: 4.477px;
		left: 8.954px;
		top: 49.244px;
	}
	#TTMOBRectangle_96 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_96 {
		position: absolute;
		overflow: visible;
		width: 13.43px;
		height: 4.477px;
		left: 8.954px;
		top: 58.197px;
	}
	#TTMOBRectangle_97 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_97 {
		position: absolute;
		overflow: visible;
		width: 4.477px;
		height: 4.477px;
		left: 26.86px;
		top: 58.197px;
	}
	#TTMOBRectangle_98 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_98 {
		position: absolute;
		overflow: visible;
		width: 4.477px;
		height: 4.477px;
		left: 26.86px;
		top: 49.244px;
	}
	#TTMOBRectangle_99 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_99 {
		position: absolute;
		overflow: visible;
		width: 13.43px;
		height: 4.477px;
		left: 8.954px;
		top: 40.29px;
	}
	#TTMOBRectangle_100 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_100 {
		position: absolute;
		overflow: visible;
		width: 4.477px;
		height: 4.477px;
		left: 26.86px;
		top: 40.29px;
	}
	#TTMOBRectangle_101 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_101 {
		position: absolute;
		overflow: visible;
		width: 13.43px;
		height: 4.477px;
		left: 49.244px;
		top: 22.383px;
	}
	#TTMOBRectangle_102 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_102 {
		position: absolute;
		overflow: visible;
		width: 4.477px;
		height: 4.477px;
		left: 67.151px;
		top: 22.383px;
	}
	#TTMOBTitle {
		position: absolute;
		width: 228px;
		height: 75px;
		left: 74px;
		top: 3477px;
		overflow: visible;
	}
	#TTMOBTop_Picks {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 106px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -27.5px;
		text-align: left;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(90,84,84,1);
	}
	#TTMOBWe_Provide_Best_Quality {
		left: 0px;
		top: 46px;
		position: absolute;
		overflow: visible;
		width: 229px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -29px;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: lighter;
		font-size: 22px;
		color: rgba(116,116,116,1);
	}
	#TTMOBMenu {
		position: absolute;
		width: 198px;
		height: 37px;
		left: 74px;
		top: 3582px;
		overflow: visible;
	}
	#TTMOBAll_Parts {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 85px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -29px;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(61,55,241,1);
	}
	#TTMOBRectangle_115 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_115 {
		position: absolute;
		overflow: visible;
		width: 198px;
		height: 2px;
		left: 0px;
		top: 35px;
	}
	#TTMOBScroll_Group_96 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 408.793px;
		left: 0px;
		top: 3619px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#TTMOBGroup_15179 {
		position: absolute;
		width: 317.511px;
		height: 351.239px;
		left: 31px;
		top: 25px;
		overflow: visible;
	}
	#TTMOBn_ {
		position: absolute;
		width: 152.57px;
		height: 169.392px;
		left: 164.941px;
		top: 181.848px;
		overflow: visible;
	}
	#TTMOBPath_1297 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBPath_1297 {
		overflow: visible;
		position: absolute;
		width: 152.571px;
		height: 169.392px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBProduct_4 {
		left: 48.285px;
		top: 96.89px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(90,84,84,1);
	}
	#TTMOBRead_more {
		position: absolute;
		width: 54px;
		height: 18px;
		left: 47.059px;
		top: 137.153px;
		overflow: visible;
	}
	#TTMOBRectangle_117 {
		fill: rgba(241,241,255,1);
	}
	.TTMOBRectangle_117 {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 18px;
		left: 0px;
		top: 0px;
	}
	#TTMOBRead_More_e {
		left: 13px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 29px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -13px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 6px;
		color: rgba(75,100,126,1);
	}
	#TTMOBbrand-identity {
		position: absolute;
		width: 63px;
		height: 54px;
		left: 45.059px;
		top: 20.152px;
		overflow: visible;
	}
	#TTMOBn__e {
		position: absolute;
		width: 152.57px;
		height: 169.392px;
		left: 0px;
		top: 181.848px;
		overflow: visible;
	}
	#TTMOBPath_1191 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBPath_1191 {
		overflow: visible;
		position: absolute;
		width: 152.571px;
		height: 169.392px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBProduct_3 {
		left: 48.285px;
		top: 96.89px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(90,84,84,1);
	}
	#TTMOBbrand-identity_fc {
		position: absolute;
		width: 76px;
		height: 64px;
		left: 36px;
		top: 15.152px;
		overflow: visible;
	}
	#TTMOBRead_more_fd {
		position: absolute;
		width: 54px;
		height: 18px;
		left: 47px;
		top: 137.153px;
		overflow: visible;
	}
	#TTMOBRectangle_87 {
		fill: rgba(241,241,255,1);
	}
	.TTMOBRectangle_87 {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 18px;
		left: 0px;
		top: 0px;
	}
	#TTMOBRead_More_ff {
		left: 13px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 29px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -13px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 6px;
		color: rgba(75,100,126,1);
	}
	#TTMOBn__fg {
		position: absolute;
		width: 152.57px;
		height: 169.392px;
		left: 164.941px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBPath_1191_fh {
		fill: rgba(255,255,255,1);
	}
	.TTMOBPath_1191_fh {
		overflow: visible;
		position: absolute;
		width: 152.571px;
		height: 169.392px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBProduct_2 {
		left: 48.285px;
		top: 96.891px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(90,84,84,1);
	}
	#TTMOBRead_more_fj {
		position: absolute;
		width: 54px;
		height: 18px;
		left: 49.059px;
		top: 137px;
		overflow: visible;
	}
	#TTMOBRectangle_87_fk {
		fill: rgba(241,241,255,1);
	}
	.TTMOBRectangle_87_fk {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 18px;
		left: 0px;
		top: 0px;
	}
	#TTMOBRead_More_fl {
		left: 13px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 29px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -13px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 6px;
		color: rgba(75,100,126,1);
	}
	#TTMOBbrand-identity_fm {
		position: absolute;
		width: 63px;
		height: 53px;
		left: 45.059px;
		top: 21px;
		overflow: visible;
	}
	#TTMOBn__fn {
		position: absolute;
		width: 152.57px;
		height: 169.392px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBPath_1191_fo {
		fill: rgba(255,255,255,1);
	}
	.TTMOBPath_1191_fo {
		overflow: visible;
		position: absolute;
		width: 152.571px;
		height: 169.392px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBProduct_1 {
		left: 48.285px;
		top: 96.891px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(90,84,84,1);
	}
	#TTMOBRead_more_fq {
		position: absolute;
		width: 54px;
		height: 18px;
		left: 47px;
		top: 137px;
		overflow: visible;
	}
	#TTMOBRectangle_87_fr {
		fill: rgba(241,241,255,1);
	}
	.TTMOBRectangle_87_fr {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 18px;
		left: 0px;
		top: 0px;
	}
	#TTMOBRead_More_fs {
		left: 13px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 29px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -13px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 6px;
		color: rgba(75,100,126,1);
	}
	#TTMOBbrand-identity_ft {
		position: absolute;
		width: 72px;
		height: 61px;
		left: 40px;
		top: 21px;
		overflow: visible;
	}

.mediaViewInfo {
    --web-view-name: sign up;
    --web-view-id: sign_up;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: sign_up;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#sign_up {
    position: absolute;
    width: 1366px;
    height: 712px;
    background-color: rgba(215,241,222,1);
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    /* transform: scale(1.0); */
    overflow: hidden;
    --web-view-name: sign up;
    --web-view-id: sign_up;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Path_331 {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Path_331 {
    overflow: visible;
    position: absolute;
    width: 1245.816px;
    height: 660.617px;
    left: 53px;
    top: 25.415px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#right-arrow {
    position: absolute;
    width: 36px;
    height: 39px;
    background-color: white;
    border-radius: 18px;
    overflow: visible;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: index_page.html;
    cursor: pointer;
}
#Sign_Up_q {
    left: 228.29px;
    top: 78.747px;
    position: absolute;
    overflow: visible;
    width: 112px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI Symbol;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    color: rgba(5,5,5,1);
}
#contract {
    position: absolute;
    width: 52.611px;
    height: 52.611px;
    left: 357.87px;
    top: 80px;
    overflow: visible;
}
#txtMobile_No {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtMobile_No {
    -webkit-filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 178.018px;
    height: 48px;
    left: 400.792px;
    top: 346.128px;
    font-size: 16px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtEmail_signUp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtEmail_signUp {
    -webkit-filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 178.018px;
    height: 48px;
    left: 186px;
    top: 346.438px;
    font-size: 16px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtEnter_Password {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtEnter_Password {
    -webkit-filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 178.018px;
    height: 48px;
    left: 186px;
    top: 428.939px;
    font-size: 16px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtConfirm_Password {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtConfirm_Password {
    -webkit-filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 178.018px;
    height: 48px;
    left: 400.792px;
    top: 428.939px;
    font-size: 16px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtFirst_Name {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtFirst_Name {
    -webkit-filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 178.018px;
    height: 48px;
    left: 186px;
    top: 263.774px;
    font-size: 18px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtNick_Name {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtNick_Name {
    -webkit-filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 178.018px;
    height: 48px;
    left: 400.792px;
    top: 263.774px;
    font-size: 18px;
    padding: 15px;
    border: 2px solid darkcyan;
}

.nickNamelbl {
   
    position: absolute;
    overflow: visible;
    width: 385px;
    height: 25px;
    left: 186px;
    white-space: nowrap;
    text-align: right;
    top: 316.774px;
    font-size: 16px;

    
}
#profile_Pic_SignUp {
    position: absolute;
    width: 80.611px;
    height: 80.611px;
    left: 350.181px;
    top: 160.808px;
    overflow: visible;
    cursor: pointer;
}
#Already_a_Member_Sign_in {
    left: 288.745px;
    top: 580.842px;
    position: absolute;
    overflow: visible;
    width: 187px;
    white-space: nowrap;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: index_page.html;
    cursor: pointer;
    line-height: 46px;
    margin-top: -16px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#Login_button:hover {
    width: 141.307px;
    height: 31.591px;
    left: 308.092px;
    top: 522.453px;
}
#Login_button {
    position: absolute;
    width: 153.462px;
    height: 35.382px;
    left: 302.014px;
    top: 520.601px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Rectangle_2_ {
    fill: rgba(33,177,73,1);
}
.Rectangle_2_ {
    -webkit-filter: drop-shadow(3.857px 4.596px 13px rgba(0, 0, 0, 0.141));
            filter: drop-shadow(3.857px 4.596px 13px rgba(0, 0, 0, 0.141));
    position: absolute;
    overflow: visible;
    width: 153.463px;
    height: 35.382px;
    left: 0px;
    top: 0px;
}
#Sign_Up_ba {
    left: 46.265px;
    top: 9.793px;
    position: absolute;
    overflow: visible;
    width: 59px;
    white-space: nowrap;
    line-height: 18px;
    margin-top: -1.5px;
    text-align: center;
    font-family: Poppins-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255,255,255,1);
}
#Path_328 {
    fill: rgba(51,209,155,1);
}
.Path_328 {
    overflow: visible;
    position: absolute;
    width: 562.609px;
    height: 85.903px;
    left: 720.197px;
    top: 25.415px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Path_329 {
    fill: rgba(106,219,143,1);
}
.Path_329 {
    overflow: visible;
    position: absolute;
    width: 526.456px;
    height: 639.169px;
    left: 785.361px;
    top: 25.415px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Path_330 {
    fill: rgba(107,222,194,1);
}
.Path_330 {
    overflow: visible;
    position: absolute;
    width: 608.803px;
    height: 184.473px;
    left: 702.437px;
    top: 501.56px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Path_332 {
    fill: rgba(84,209,126,0.69);
}
.Path_332 {
    overflow: visible;
    position: absolute;
    width: 613.107px;
    height: 389.203px;
    left: 698.274px;
    top: 200.508px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Group_15 {
    position: absolute;
    width: 161px;
    height: 161px;
    left: 966px;
    top: 211px;
    overflow: visible;
}
#Ellipse_4 {
    fill: rgba(255,255,255,1);
}
.Ellipse_4 {
    position: absolute;
    overflow: visible;
    width: 161px;
    height: 161px;
    left: 0px;
    top: 0px;
}
#image_SignUp {
    position: absolute;
    width: 135px;
    height: 135px;
    left: 13px;
    top: 13px;
    overflow: visible;
}
#Group_14 {
    position: absolute;
    width: 341px;
    height: 50px;
    left: 878px;
    top: 399px;
    overflow: visible;
}
#Rectangle_5 {
    fill: rgba(255,255,255,1);
}
.Rectangle_5 {
    position: absolute;
    overflow: visible;
    width: 341px;
    height: 50px;
    left: 0px;
    top: 0px;
}
#OPERATE_LIVE_SignUp {
    left: 12px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 319px;
    white-space: nowrap;
    line-height: 132px;
    margin-top: -46px;
    text-align: left;
    font-family: Cooper;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgba(33,177,73,1);
}
.mediaViewInfo {
    --web-view-name: forgot page;
    --web-view-id: forgot_page_pc;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-refresh-for-changes: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: forgot_page_pc;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

#forgot_page_pc {
    position: absolute;
    width: 1366px;
    height: 768px;
    background-color: rgba(255,255,255,1);
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    overflow: hidden;
    --web-view-name: forgot page;
    --web-view-id: forgot_page_pc;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-refresh-for-changes: true;
    --web-enable-deep-linking: true;
}
#Path_1_pc {
    fill: rgba(52,227,105,1);
}
.Path_1_pc {
    overflow: visible;
    position: absolute;
    width: 260.616px;
    height: 160.165px;
    left: 1106.239px;
    top: 30.062px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Path_2_pc {
    fill: rgba(119,221,150,1);
}
.Path_2_pc {
    overflow: visible;
    position: absolute;
    width: 137.074px;
    height: 83.79px;
    left: 1230.797px;
    top: -4.107px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Path_5_pc {
    fill: rgba(213,213,213,1);
}
.Path_5_pc {
    overflow: visible;
    position: absolute;
    width: 95.817px;
    height: 59.11px;
    left: 1276.654px;
    top: 99.067px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Path_1_o_pc {
    fill: rgba(52,227,105,1);
}
.Path_1_o_pc {
    overflow: visible;
    position: absolute;
    width: 284.045px;
    height: 160.202px;
    left: 1.909px;
    top: 512.549px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Path_2_p_pc {
    fill: rgba(119,221,150,1);
}
.Path_2_p_pc {
    overflow: visible;
    position: absolute;
    width: 149.499px;
    height: 84.597px;
    left: 0px;
    top: 476.717px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Path_3_pc {
    fill: rgba(229,228,228,1);
}
.Path_3_pc {
    overflow: visible;
    position: absolute;
    width: 214.177px;
    height: 131.242px;
    left: 1.31px;
    top: 578.222px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Forgot_Password_pc_lbl_ {
    left: 555px;
    top: 307px;
    position: absolute;
    overflow: visible;
    width: 258px;
    white-space: nowrap;
    line-height: 75.63333129882812px;
    margin-top: -20.316665649414062px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    color: rgba(112,112,112,1);
}
#Enter_the_email_address_associ_pc {
    left: 508px;
    top: 380px;
    position: absolute;
    overflow: visible;
    width: 351px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#ema_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.ema_pc {
    -webkit-filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 331.5px;
    height: 49.599px;
    left: 527px;
    top: 445px;
    font-size: 20px;
    padding: 15px;
    border: 1px solid darkcyan;
}
#Group_9_pc_fp {
    position: absolute;
    width: 253.519px;
    height: 37.724px;
    left: 566px;
    top: 554px;
    overflow: visible;
    border-radius: 50px;
    cursor: pointer;
}
#Rectangle_pc_fp {
    fill: rgba(119,221,150,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rectangle_pc_fp {
    -webkit-filter: drop-shadow(10px 7px 6px rgba(0, 0, 0, 0.161));
            filter: drop-shadow(10px 7px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 272.519px;
    height: 55.724px;
    left: 0px;
    top: 0px;
}
#Continue_pc_fp {
    left: 84.722px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 85px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#Dont_have_an_account_Sign_up_pc_fp {
    left: 579px;
    top: 617px;
    position: absolute;
    overflow: visible;
    width: 210px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
    cursor: pointer;
}
#image_forgotPw_pc {
    position: absolute;
    width: 135px;
    height: 135px;
    left: 616px;
    top: 132px;
    overflow: visible;
}
#Group_13_pc_fp {
    position: absolute;
    width: 60px;
    height: 27px;
    left: 793px;
    top: 503px;
    overflow: visible;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: index_page.html;
    cursor: pointer;
}
#Back_pc_fp {
    left: 19px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#back_fp_pc {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 0px;
    top: 6px;
    overflow: visible;
}

	.mediaViewInfo {
		--web-view-name: website 2;
		--web-view-id: website_2;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: website_2;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#website_2 {
		position: absolute;
		width: 1348px;
		height: 5858px;
        left:calc((100vw - 1348px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: website 2;
		--web-view-id: website_2;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#leaves_grp_website_2 {
		position: absolute;
		width: 379.067px;
		height: 432.061px;
		left: 1155.559px;
		top: 1574.629px;
		overflow: visible;
		padding: -49px 153px 312px 115px;
	}
	#Leave_down_website_2 {
		opacity: 0.8;
		-webkit-transform: translate(-1185.559px, -1574.629px) matrix(1,0,0,1,1233.4896,1679.1857) rotate(-59deg);
		        transform: translate(-1185.559px, -1574.629px) matrix(1,0,0,1,1233.4896,1679.1857) rotate(-59deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		position: absolute;
		width: 283.54px;
		height: 271.864px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#leaf_stem_27 {
		fill: rgba(241,90,90,1);
	}
	.leaf_stem_27 {
		overflow: visible;
		position: absolute;
		width: 379.067px;
		height: 383.062px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_26 {
		fill: rgba(68,64,83,1);
	}
	.leaf_stem_26 {
		overflow: visible;
		position: absolute;
		width: 76.525px;
		height: 68.5px;
		left: 115.932px;
		top: 75.086px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_25 {
		fill: rgba(68,64,83,1);
	}
	.leaf_stem_25 {
		overflow: visible;
		position: absolute;
		width: 86.638px;
		height: 82.782px;
		left: 142.273px;
		top: 142.743px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_24 {
		fill: rgba(68,64,83,1);
	}
	.leaf_stem_24 {
		overflow: visible;
		position: absolute;
		width: 98.874px;
		height: 79.602px;
		left: 180.618px;
		top: 224.66px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_23 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_23 {
		overflow: visible;
		position: absolute;
		width: 217.719px;
		height: 253.72px;
		left: 99.207px;
		top: 80.212px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_22 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_22 {
		overflow: visible;
		position: absolute;
		width: 30.147px;
		height: 26.913px;
		left: 298.736px;
		top: 141.195px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_21 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_21 {
		overflow: visible;
		position: absolute;
		width: 52.303px;
		height: 46.538px;
		left: 275.906px;
		top: 161.714px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_20 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_20 {
		overflow: visible;
		position: absolute;
		width: 55.6px;
		height: 43.296px;
		left: 260.1px;
		top: 195.316px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_19 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_19 {
		overflow: visible;
		position: absolute;
		width: 51.087px;
		height: 35.309px;
		left: 232.005px;
		top: 230.8px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_18 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_18 {
		overflow: visible;
		position: absolute;
		width: 21.244px;
		height: 21.042px;
		left: 290.261px;
		top: 129.653px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_17 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_17 {
		overflow: visible;
		position: absolute;
		width: 48.514px;
		height: 42.8px;
		left: 246.583px;
		top: 131.361px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_16 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_16 {
		overflow: visible;
		position: absolute;
		width: 54.823px;
		height: 44.443px;
		left: 224.985px;
		top: 155.621px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_15 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_15 {
		overflow: visible;
		position: absolute;
		width: 37.998px;
		height: 33.676px;
		left: 215.132px;
		top: 193.436px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_14 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_14 {
		overflow: visible;
		position: absolute;
		width: 16.155px;
		height: 22.572px;
		left: 197.662px;
		top: 199.183px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_13 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_13 {
		overflow: visible;
		position: absolute;
		width: 17.024px;
		height: 17.628px;
		left: 177.763px;
		top: 199.693px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_12 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_12 {
		overflow: visible;
		position: absolute;
		width: 43.193px;
		height: 60.187px;
		left: 180.339px;
		top: 114.085px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_11 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_11 {
		overflow: visible;
		position: absolute;
		width: 71.427px;
		height: 65.708px;
		left: 113.983px;
		top: 149.533px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_0 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_0 {
		overflow: visible;
		position: absolute;
		width: 70.623px;
		height: 60.738px;
		left: 105.939px;
		top: 122.776px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_9 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 0.75px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_9 {
		overflow: visible;
		position: absolute;
		width: 38.862px;
		height: 57.255px;
		left: 168.101px;
		top: 81.657px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_8 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 0.75px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_8 {
		overflow: visible;
		position: absolute;
		width: 48.157px;
		height: 40.584px;
		left: 115.003px;
		top: 98.535px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_7 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 0.75px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_7 {
		overflow: visible;
		position: absolute;
		width: 19.803px;
		height: 28.369px;
		left: 148.393px;
		top: 66.773px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_6 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_6 {
		overflow: visible;
		position: absolute;
		width: 32.704px;
		height: 24.496px;
		left: 173.666px;
		top: 232.349px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_5 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_5 {
		overflow: visible;
		position: absolute;
		width: 37.563px;
		height: 48.802px;
		left: 164.931px;
		top: 261.837px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_4 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_4 {
		overflow: visible;
		position: absolute;
		width: 49.402px;
		height: 46.244px;
		left: 127.781px;
		top: 221.134px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_3 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_3 {
		overflow: visible;
		position: absolute;
		width: 35.015px;
		height: 44.787px;
		left: 128.642px;
		top: 266.755px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_2 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_2 {
		overflow: visible;
		position: absolute;
		width: 41.825px;
		height: 34.516px;
		left: 98.833px;
		top: 229.496px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_1 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_1 {
		overflow: visible;
		position: absolute;
		width: 18.299px;
		height: 24.148px;
		left: 100.59px;
		top: 265.299px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#top_leaves_website_2 {
		opacity: 0.6;
		-webkit-transform: translate(-1185.559px, -1574.629px) matrix(1,0,0,1,1310.2941,1583.0225) rotate(12deg);
		        transform: translate(-1185.559px, -1574.629px) matrix(1,0,0,1,1310.2941,1583.0225) rotate(12deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		position: absolute;
		width: 91.597px;
		height: 103.274px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#stem_red_website_2 {
		fill: transparent;
		stroke: rgba(83,84,97,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.stem_red_website_2 {
		overflow: visible;
		position: absolute;
		width: 79.759px;
		height: 76.252px;
		left: 15.422px;
		top: 43.073px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_12_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_12_website_2 {
		overflow: visible;
		position: absolute;
		width: 19.151px;
		height: 18.945px;
		left: 12.979px;
		top: 24.373px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_11_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_11_website_2 {
		overflow: visible;
		position: absolute;
		width: 21.929px;
		height: 16.481px;
		left: 13.035px;
		top: 44.094px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_0_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_0_website_2 {
		overflow: visible;
		position: absolute;
		width: 21.433px;
		height: 16.334px;
		left: 28.521px;
		top: 70.956px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_9_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_9_website_2 {
		overflow: visible;
		position: absolute;
		width: 21.978px;
		height: 16.477px;
		left: 48.535px;
		top: 88.288px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_8_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_8_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.54px;
		height: 22.156px;
		left: 29.276px;
		top: 41.359px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#green_lead_website_2_3 {
		fill: rgba(56,211,159,1);
	}
	.green_lead_website_2_3 {
		overflow: visible;
		position: absolute;
		width: 16.495px;
		height: 22.053px;
		left: 69.847px;
		top: 84.143px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_7_website_2 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.red_leaf_7_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.332px;
		height: 21.547px;
		left: 51.986px;
		top: 60.218px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#green_leaf_3_website_2 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.green_leaf_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.495px;
		height: 22.053px;
		left: 69.847px;
		top: 84.143px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#green_stem_6_website_2 {
		fill: transparent;
		stroke: rgba(83,84,97,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.green_stem_6_website_2 {
		overflow: visible;
		position: absolute;
		width: 53.908px;
		height: 90.037px;
		left: 45.092px;
		top: 27.91px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_5_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_5_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.315px;
		height: 21.184px;
		left: 51.597px;
		top: 7.242px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_4_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_4_website_2 {
		overflow: visible;
		position: absolute;
		width: 20.069px;
		height: 17.527px;
		left: 44.041px;
		top: 24.938px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_1_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 22.119px;
		height: 16.516px;
		left: 47.697px;
		top: 55.698px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_2_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 22.045px;
		height: 16.571px;
		left: 58.655px;
		top: 79.516px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_3_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.374px;
		height: 21.071px;
		left: 60.38px;
		top: 29.536px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#green_leaf_2_website_2 {
		fill: rgba(56,211,159,1);
	}
	.green_leaf_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 18.451px;
		height: 19.394px;
		left: 71.235px;
		top: 55.392px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#green_leaf_1_website_2 {
		fill: rgba(56,211,159,1);
	}
	.green_leaf_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.898px;
		height: 20.389px;
		left: 79.567px;
		top: 84.939px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}

	#design_4_off_website_2 {
		position: absolute;
		width: 410px;
		height: 458.336px;
		left: 691.927px;
		top: 4410.342px;
		overflow: visible;
	}
	#design_3_off_website_2 {
		position: absolute;
		width: 400px;
		height: 435.369px;
		left: 170px;
		top: 3853.658px;
		overflow: visible;
	}
	#design_2_off_website_2 {
		position: absolute;
		width: 410.53px;
		height: 446.829px;
		left: 691.397px;
		top: 3286px;
		overflow: visible;
	}
	#design_1_off_website_2 {
		position: absolute;
		width: 400px;
		height: 446.829px;
		left: 170px;
		top: 2718px;
		overflow: visible;
	}
	#My_Offerings_lbl_website_2 {
		left: 580px;
		top: 2568px;
		position: absolute;
		overflow: visible;
		width: 176px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(54,54,54,1);
	}
	#Mission_lbl_website_2 {
		left: 766px;
		top: 1822px;
		position: absolute;
		overflow: visible;
		width: 72px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(54,54,54,1);
	}
	#Scroll_mission_webste_2 {
		mix-blend-mode: normal;
		position: absolute;
		width: 459px;
		height: 255px;
		left: 754px;
		top: 1862px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#mission_description_website_2 {
		left: 12px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 448px;
		height: 226px;
		line-height: 32px;
		margin-top: -7px;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(54,54,54,1);
	}
	#mission_all_grp_website_2 {
		position: absolute;
		width: 443.51px;
		height: 227.818px;
		left: 133.965px;
		top: 1799.776px;
		overflow: visible;
	}
	#pc_stand_2_website_2 {
		fill: rgba(188,188,188,1);
	}
	.pc_stand_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 71.221px;
		height: 42.002px;
		left: 186.145px;
		top: 168.01px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pc_sfcreen_website_2 {
		fill: rgba(82,83,96,1);
	}
	.pc_sfcreen_website_2 {
		overflow: visible;
		position: absolute;
		width: 242.884px;
		height: 147.922px;
		left: 100.312px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pc_border_website_2 {
		fill: rgba(188,188,188,1);
	}
	.pc_border_website_2 {
		overflow: visible;
		position: absolute;
		width: 242.884px;
		height: 22.828px;
		left: 100.313px;
		top: 147.922px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pc_logo_website_2 {
		fill: rgba(82,83,96,1);
	}
	.pc_logo_website_2 {
		overflow: visible;
		position: absolute;
		width: 8.218px;
		height: 8.218px;
		left: 217.645px;
		top: 155.227px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pc_stand_down_website_2 {
		fill: rgba(188,188,188,1);
	}
	.pc_stand_down_website_2 {
		overflow: visible;
		position: absolute;
		width: 85.831px;
		height: 9.131px;
		left: 178.839px;
		top: 197.686px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#center_screen_white_website_2 {
		fill: rgba(255,255,255,1);
	}
	.center_screen_white_website_2 {
		overflow: visible;
		position: absolute;
		width: 146.799px;
		height: 83.987px;
		left: 148.355px;
		top: 31.73px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_box_1_website_2 {
		fill: rgba(63,117,225,1);
	}
	.screen_box_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.18px;
		height: 14.564px;
		left: 189.249px;
		top: 43.788px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_box_1_line_top_website_ {
		fill: rgba(223,223,223,1);
	}
	.screen_box_1_line_top_website_ {
		overflow: visible;
		position: absolute;
		width: 43.687px;
		height: 1.616px;
		left: 210.281px;
		top: 43.788px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_box_1_line_down_website {
		fill: rgba(223,223,223,1);
	}
	.screen_box_1_line_down_website {
		overflow: visible;
		position: absolute;
		width: 36.314px;
		height: 1.616px;
		left: 210.281px;
		top: 48.645px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_box_2_website_2 {
		fill: rgba(63,117,225,1);
	}
	.screen_box_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.18px;
		height: 14.564px;
		left: 189.249px;
		top: 66.441px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_box_line_top_2_website_ {
		fill: rgba(223,223,223,1);
	}
	.screen_box_line_top_2_website_ {
		overflow: visible;
		position: absolute;
		width: 43.687px;
		height: 1.616px;
		left: 210.281px;
		top: 66.441px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_box_line_2_website_2 {
		fill: rgba(223,223,223,1);
	}
	.screen_box_line_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 43.687px;
		height: 1.616px;
		left: 210.281px;
		top: 71.294px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_box_3_website_2 {
		fill: rgba(63,117,225,1);
	}
	.screen_box_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.18px;
		height: 14.564px;
		left: 189.249px;
		top: 89.095px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_section_2_line_website_ {
		fill: rgba(223,223,223,1);
	}
	.screen_section_2_line_website_ {
		overflow: visible;
		position: absolute;
		width: 43.687px;
		height: 1.616px;
		left: 210.281px;
		top: 89.095px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screeen_section_line_website_2 {
		fill: rgba(223,223,223,1);
	}
	.screeen_section_line_website_2 {
		overflow: visible;
		position: absolute;
		width: 43.979px;
		height: 1.616px;
		left: 210.282px;
		top: 93.949px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_dot_website_2 {
		fill: rgba(56,210,159,1);
	}
	.screen_dot_website_2 {
		overflow: visible;
		position: absolute;
		width: 3.652px;
		height: 3.652px;
		left: 219.928px;
		top: 32.872px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_top_dot_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.screen_top_dot_website_2 {
		overflow: visible;
		position: absolute;
		width: 4.652px;
		height: 4.652px;
		left: 219.928px;
		top: 17.805px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_top_line_2_website {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.screen_top_line_2_website {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 10.044px;
		left: 221.754px;
		top: 7.761px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_top_line_1_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.screen_top_line_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 13.181px;
		left: 221.754px;
		top: 21.517px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_top_down_dot_website_2 {
		fill: rgba(0,0,0,1);
	}
	.screen_top_down_dot_website_2 {
		overflow: visible;
		position: absolute;
		width: 3.652px;
		height: 3.652px;
		left: 219.928px;
		top: 32.872px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#scrren_top_dot_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.scrren_top_dot_website_2 {
		overflow: visible;
		position: absolute;
		width: 4.652px;
		height: 4.652px;
		left: 219.928px;
		top: 17.805px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_line_down_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.screen_line_down_website_2 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 22.018px;
		left: 221.754px;
		top: 113.224px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_dot_down_website_2 {
		fill: rgba(0,0,0,1);
	}
	.screen_dot_down_website_2 {
		overflow: visible;
		position: absolute;
		width: 3.652px;
		height: 3.652px;
		left: 219.928px;
		top: 111.398px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_line_left_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.screen_line_left_website_2 {
		overflow: visible;
		position: absolute;
		width: 22.594px;
		height: 2px;
		left: 129.536px;
		top: 75.559px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_dot_right_website_2 {
		fill: rgba(0,0,0,1);
	}
	.screen_dot_right_website_2 {
		overflow: visible;
		position: absolute;
		width: 3.652px;
		height: 3.652px;
		left: 150.304px;
		top: 73.733px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_mid_stem_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.pot_1_mid_stem_website_2 {
		overflow: visible;
		position: absolute;
		width: 15.359px;
		height: 87.627px;
		left: 44.938px;
		top: 89.484px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_website_2 {
		fill: rgba(241,90,90,1);
	}
	.pot_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 75.444px;
		height: 42.002px;
		left: 8.979px;
		top: 164.814px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_5_website_2 {
		fill: rgba(77,182,172,1);
	}
	.pot_1_leaf_5_website_2 {
		overflow: visible;
		position: absolute;
		width: 25.11px;
		height: 17.305px;
		left: 53.973px;
		top: 83.079px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_4_website_2 {
		fill: rgba(77,182,172,1);
	}
	.pot_1_leaf_4_website_2 {
		overflow: visible;
		position: absolute;
		width: 13.756px;
		height: 22.623px;
		left: 38.45px;
		top: 89.255px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_3_website_2 {
		fill: rgba(77,182,172,1);
	}
	.pot_1_leaf_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 29.187px;
		height: 25.282px;
		left: 46.7px;
		top: 104.321px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_2_website_2 {
		fill: rgba(77,182,172,1);
	}
	.pot_1_leaf_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 18.467px;
		height: 35.077px;
		left: 27.509px;
		top: 111.169px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_1_website_2 {
		fill: rgba(77,182,172,1);
	}
	.pot_1_leaf_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 18.28px;
		height: 30.927px;
		left: 45.28px;
		top: 132.627px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_stem5_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.pot_1_leaf_stem5_website_2 {
		overflow: visible;
		position: absolute;
		width: 25.106px;
		height: 16.397px;
		left: 54.201px;
		top: 83.548px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_stem_4_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.pot_1_leaf_stem_4_website_2 {
		overflow: visible;
		position: absolute;
		width: 29.742px;
		height: 25.927px;
		left: 46.668px;
		top: 104.321px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_stem_3_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.pot_1_leaf_stem_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 12.829px;
		height: 22.309px;
		left: 38.45px;
		top: 89.255px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_stem_2_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.pot_1_leaf_stem_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 13.115px;
		height: 35.662px;
		left: 32.515px;
		top: 111.17px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_stem_1_webstite_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.pot_1_leaf_stem_1_webstite_2 {
		overflow: visible;
		position: absolute;
		width: 18.793px;
		height: 31.712px;
		left: 45.298px;
		top: 132.627px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#tp_table_ {
		fill: rgba(223,223,223,1);
	}
	.tp_table_ {
		overflow: visible;
		position: absolute;
		width: 443.51px;
		height: 15.034px;
		left: 0px;
		top: 206.634px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#table_below_website_2 {
		fill: rgba(223,223,223,1);
	}
	.table_below_website_2 {
		overflow: visible;
		position: absolute;
		width: 405.922px;
		height: 6.15px;
		left: 18.792px;
		top: 221.668px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_line_right_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.screen_line_right_website_2 {
		overflow: visible;
		position: absolute;
		width: 20.49px;
		height: 2px;
		left: 290.921px;
		top: 75.559px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_dot_right_website_2_ed {
		fill: rgba(0,0,0,1);
	}
	.screen_dot_right_website_2_ed {
		overflow: visible;
		position: absolute;
		width: 3.652px;
		height: 3.652px;
		left: 289.095px;
		top: 73.733px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#screen_dot_website_2_ee {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.screen_dot_website_2_ee {
		overflow: visible;
		position: absolute;
		width: 4.652px;
		height: 4.652px;
		left: 311.466px;
		top: 73.733px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pen_3_website_2 {
		fill: rgba(82,83,96,1);
	}
	.pen_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 7.305px;
		height: 45.655px;
		left: 381.546px;
		top: 145.183px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#top_cap_2_website_2 {
		fill: rgba(57,210,159,1);
	}
	.top_cap_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 7.305px;
		height: 15.066px;
		left: 381.546px;
		top: 130.117px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pen_bottom_2_website_2 {
		fill: rgba(82,83,96,1);
	}
	.pen_bottom_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 7.305px;
		height: 45.655px;
		left: 391.59px;
		top: 148.835px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pen_2_tcap_website_2 {
		fill: rgba(77,137,239,1);
	}
	.pen_2_tcap_website_2 {
		overflow: visible;
		position: absolute;
		width: 7.305px;
		height: 15.066px;
		left: 391.59px;
		top: 133.769px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pen_1_bottom_website_2 {
		fill: rgba(82,83,96,1);
	}
	.pen_1_bottom_website_2 {
		overflow: visible;
		position: absolute;
		width: 7.305px;
		height: 45.655px;
		left: 403.917px;
		top: 156.596px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pen_1_website_2 {
		fill: rgba(245,95,68,1);
	}
	.pen_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 7.305px;
		height: 15.066px;
		left: 403.917px;
		top: 141.53px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pen_pot_website_2 {
		fill: rgba(241,90,90,1);
	}
	.pen_pot_website_2 {
		overflow: visible;
		position: absolute;
		width: 42.002px;
		height: 47.024px;
		left: 375.155px;
		top: 159.335px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_443 {
		fill: rgba(77,182,172,1);
	}
	.Path_443 {
		overflow: visible;
		position: absolute;
		width: 12.495px;
		height: 22.623px;
		left: 43.641px;
		top: 68.687px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_3_website_2_en {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.pot_1_leaf_3_website_2_en {
		overflow: visible;
		position: absolute;
		width: 12.133px;
		height: 21.585px;
		left: 44.219px;
		top: 69.395px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Dotted_LIne2_princi_to_mision {
		fill: transparent;
		stroke: rgba(209,209,209,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 7;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Dotted_LIne2_princi_to_mision {
		overflow: visible;
		position: absolute;
		width: 588.013px;
		height: 202.611px;
		left: 365px;
		top: 1597px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_all_grp_websiet_2 {
		position: absolute;
		width: 1035.795px;
		height: 391.5px;
		left: 171px;
		top: 1319.5px;
		overflow: visible;
	}
	#principle_img_website_2_grp {
		position: absolute;
		width: 470.063px;
		height: 390.718px;
		left: 565.732px;
		top: 0px;
		overflow: visible;
	}
	#principle_img_website_2_bg_des {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_bg_des {
		overflow: visible;
		position: absolute;
		width: 377.025px;
		height: 252.367px;
		left: 30.875px;
		top: 25.741px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaves_principle_img_website_2 {
		opacity: 0.6;
		position: absolute;
		width: 80.203px;
		height: 100.975px;
		left: 79.886px;
		top: 43.098px;
		overflow: visible;
	}
	#principle_img_website_2_leaf_s {
		fill: transparent;
		stroke: rgba(83,84,97,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_leaf_s {
		overflow: visible;
		position: absolute;
		width: 66.569px;
		height: 62.099px;
		left: 15.416px;
		top: 39.778px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf__ {
		fill: rgba(239,174,171,1);
	}
	.principle_img_website_2_leaf__ {
		overflow: visible;
		position: absolute;
		width: 15.833px;
		height: 15.572px;
		left: 0px;
		top: 24.362px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_1 {
		fill: rgba(239,174,171,1);
	}
	.principle_img_website_2_leaf_1 {
		overflow: visible;
		position: absolute;
		width: 19.29px;
		height: 12.374px;
		left: 3.412px;
		top: 43.35px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_1_ew {
		fill: rgba(239,174,171,1);
	}
	.principle_img_website_2_leaf_1_ew {
		overflow: visible;
		position: absolute;
		width: 18.802px;
		height: 12.332px;
		left: 23.674px;
		top: 65.893px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_1_ex {
		fill: rgba(239,174,171,1);
	}
	.principle_img_website_2_leaf_1_ex {
		overflow: visible;
		position: absolute;
		width: 19.342px;
		height: 12.359px;
		left: 46.343px;
		top: 78.4px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_1_ey {
		fill: rgba(239,174,171,1);
	}
	.principle_img_website_2_leaf_1_ey {
		overflow: visible;
		position: absolute;
		width: 12.385px;
		height: 19.514px;
		left: 19.839px;
		top: 37.125px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_1_ez {
		fill: rgba(239,174,171,1);
	}
	.principle_img_website_2_leaf_1_ez {
		overflow: visible;
		position: absolute;
		width: 12.303px;
		height: 18.922px;
		left: 45.272px;
		top: 50.569px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_1_e {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.principle_img_website_2_leaf_1_e {
		overflow: visible;
		position: absolute;
		width: 15.833px;
		height: 15.572px;
		left: 0px;
		top: 24.362px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_1_fa {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.principle_img_website_2_leaf_1_fa {
		overflow: visible;
		position: absolute;
		width: 19.29px;
		height: 12.374px;
		left: 3.412px;
		top: 43.35px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_9 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.principle_img_website_2_leaf_9 {
		overflow: visible;
		position: absolute;
		width: 18.802px;
		height: 12.332px;
		left: 23.674px;
		top: 65.893px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_8 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.principle_img_website_2_leaf_8 {
		overflow: visible;
		position: absolute;
		width: 19.342px;
		height: 12.359px;
		left: 46.343px;
		top: 78.4px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_7 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.principle_img_website_2_leaf_7 {
		overflow: visible;
		position: absolute;
		width: 12.385px;
		height: 19.514px;
		left: 19.839px;
		top: 37.125px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_6 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.principle_img_website_2_leaf_6 {
		overflow: visible;
		position: absolute;
		width: 12.303px;
		height: 18.922px;
		left: 45.272px;
		top: 50.569px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_stem {
		fill: transparent;
		stroke: rgba(83,84,97,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_stem {
		overflow: visible;
		position: absolute;
		width: 36.444px;
		height: 82.264px;
		left: 44.974px;
		top: 18.339px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_5 {
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_leaf_5 {
		overflow: visible;
		position: absolute;
		width: 12.366px;
		height: 18.547px;
		left: 34.055px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_4 {
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_leaf_4 {
		overflow: visible;
		position: absolute;
		width: 17.109px;
		height: 13.883px;
		left: 29.478px;
		top: 18.662px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_3 {
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_leaf_3 {
		overflow: visible;
		position: absolute;
		width: 19.48px;
		height: 12.368px;
		left: 38.919px;
		top: 47.402px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_2 {
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_leaf_2 {
		overflow: visible;
		position: absolute;
		width: 19.391px;
		height: 12.442px;
		left: 54.256px;
		top: 67.95px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_1_fb {
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_leaf_1_fb {
		overflow: visible;
		position: absolute;
		width: 12.452px;
		height: 18.416px;
		left: 46.96px;
		top: 19.542px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_card_1 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_card_1 {
		overflow: visible;
		position: absolute;
		width: 95.792px;
		height: 138.063px;
		left: 132.735px;
		top: 84.712px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_x_2 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_x_2 {
		overflow: visible;
		position: absolute;
		width: 22.479px;
		height: 29.086px;
		left: 169.167px;
		top: 96.062px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_x_1 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_x_1 {
		overflow: visible;
		position: absolute;
		width: 18.875px;
		height: 27.838px;
		left: 173.018px;
		top: 96.062px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_box_5 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_box_5 {
		overflow: visible;
		position: absolute;
		width: 60.323px;
		height: 15.571px;
		left: 150.329px;
		top: 140.97px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2__tick_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2__tick_ {
		overflow: visible;
		position: absolute;
		width: 45.232px;
		height: 15.571px;
		left: 157.875px;
		top: 187.284px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_box_4 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_box_4 {
		overflow: visible;
		position: absolute;
		width: 60.607px;
		height: 15.832px;
		left: 150.329px;
		top: 162.065px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2__tick2 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2__tick2 {
		overflow: visible;
		position: absolute;
		width: 9.603px;
		height: 8.867px;
		left: 174.683px;
		top: 189.625px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_design {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_design {
		overflow: visible;
		position: absolute;
		width: 95.987px;
		height: 136.014px;
		left: 272.538px;
		top: 87.371px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_box_3 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_box_3 {
		overflow: visible;
		position: absolute;
		width: 60.323px;
		height: 8.702px;
		left: 291.351px;
		top: 144.353px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_design_fl {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_design_fl {
		overflow: visible;
		position: absolute;
		width: 60.649px;
		height: 8.842px;
		left: 291.351px;
		top: 155.464px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_deisgn {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_deisgn {
		overflow: visible;
		position: absolute;
		width: 86.306px;
		height: 4.377px;
		left: 280.996px;
		top: 97.102px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_box_2 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_box_2 {
		overflow: visible;
		position: absolute;
		width: 8.239px;
		height: 6.314px;
		left: 284.062px;
		top: 89.958px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_box {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_box {
		overflow: visible;
		position: absolute;
		width: 25.557px;
		height: 27.606px;
		left: 286.095px;
		top: 106.729px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_arrow {
		fill: transparent;
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_arrow {
		overflow: visible;
		position: absolute;
		width: 24.1px;
		height: 1.516px;
		left: 236.347px;
		top: 145.711px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_arrow_ {
		fill: transparent;
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_arrow_ {
		overflow: visible;
		position: absolute;
		width: 12.216px;
		height: 21.07px;
		left: 257.683px;
		top: 135.246px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_circle {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_circle {
		position: absolute;
		overflow: visible;
		width: 22.48px;
		height: 22.48px;
		left: 105.368px;
		top: 0px;
	}
	#principle_img_website_2_circle_fs {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_circle_fs {
		position: absolute;
		overflow: visible;
		width: 22.48px;
		height: 22.48px;
		left: 276.625px;
		top: 15.56px;
	}
	#principle_img_website_2_circle_ft {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_circle_ft {
		position: absolute;
		overflow: visible;
		width: 22.48px;
		height: 22.48px;
		left: 396.936px;
		top: 182.288px;
	}
	#principle_img_website_2_circle_fu {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_circle_fu {
		width: 37.779px;
		height: 37.675px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-59px, 0px) matrix(1,0,0,1,488.5521,124.4961) rotate(-80.782deg);
		        transform: translate(-59px, 0px) matrix(1,0,0,1,488.5521,124.4961) rotate(-80.782deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#principle_img_website_2_cirlce {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_cirlce {
		position: absolute;
		overflow: visible;
		width: 37.779px;
		height: 37.779px;
		left: 389.72px;
		top: 26.819px;
	}
	#principle_img_website_2_circle_fw {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_circle_fw {
		position: absolute;
		overflow: visible;
		width: 37.779px;
		height: 37.779px;
		left: 0px;
		top: 150.293px;
	}
	#principle_img_website_2_body_s_fy {
		fill: url(#principle_img_website_2_body_s_fy);
	}
	.principle_img_website_2_body_s_fy {
		overflow: visible;
		position: absolute;
		width: 103.474px;
		height: 260.073px;
		left: 282.347px;
		top: 127.676px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_pen {
		fill: rgba(101,97,125,1);
	}
	.principle_img_website_2_pen {
		overflow: visible;
		position: absolute;
		width: 17.186px;
		height: 18.159px;
		left: 297.291px;
		top: 137.566px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_hand_2 {
		fill: rgba(251,190,190,1);
	}
	.principle_img_website_2_hand_2 {
		width: 7.792px;
		height: 22.264px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-59px, 0px) matrix(0.9999,0,0,0.9999,364.6128,137.2293) rotate(-11.579deg);
		        transform: translate(-59px, 0px) matrix(0.9999,0,0,0.9999,364.6128,137.2293) rotate(-11.579deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#principle_img_website_2_shoe {
		fill: rgba(63,61,86,1);
	}
	.principle_img_website_2_shoe {
		overflow: visible;
		position: absolute;
		width: 23.575px;
		height: 11.029px;
		left: 279.877px;
		top: 378.758px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_face {
		fill: rgba(251,190,190,1);
	}
	.principle_img_website_2_face {
		position: absolute;
		overflow: visible;
		width: 28.804px;
		height: 28.804px;
		left: 336.741px;
		top: 130.052px;
	}
	#principle_img_website_2_hand_s {
		fill: rgba(63,61,86,1);
	}
	.principle_img_website_2_hand_s {
		overflow: visible;
		position: absolute;
		width: 15.016px;
		height: 35.227px;
		left: 364.445px;
		top: 224.723px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_hand_s_f {
		fill: rgba(63,61,86,1);
	}
	.principle_img_website_2_hand_s_f {
		overflow: visible;
		position: absolute;
		width: 15.831px;
		height: 19.854px;
		left: 305.64px;
		top: 146.099px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_shirt_ {
		fill: rgba(190,190,190,1);
	}
	.principle_img_website_2_shirt_ {
		overflow: visible;
		position: absolute;
		width: 50.162px;
		height: 79.006px;
		left: 324.564px;
		top: 161.135px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_top_sh {
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_top_sh {
		overflow: visible;
		position: absolute;
		width: 60.88px;
		height: 49.319px;
		left: 325.191px;
		top: 167.06px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_left_h {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.principle_img_website_2_left_h {
		overflow: visible;
		position: absolute;
		width: 9.569px;
		height: 8.326px;
		left: 311.902px;
		top: 157.628px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_left_a {
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_left_a {
		overflow: visible;
		position: absolute;
		width: 19.368px;
		height: 25.889px;
		left: 312.711px;
		top: 158.063px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_pant {
		fill: rgba(51,49,63,1);
	}
	.principle_img_website_2_pant {
		overflow: visible;
		position: absolute;
		width: 83.558px;
		height: 157.291px;
		left: 288.318px;
		top: 233.428px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2arm {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.principle_img_website_2arm {
		overflow: visible;
		position: absolute;
		width: 10.993px;
		height: 5.17px;
		left: 368.467px;
		top: 224.654px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_should {
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_should {
		overflow: visible;
		position: absolute;
		width: 17.498px;
		height: 43.423px;
		left: 367.789px;
		top: 185.565px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_hair {
		fill: rgba(51,49,63,1);
	}
	.principle_img_website_2_hair {
		overflow: visible;
		position: absolute;
		width: 50.187px;
		height: 79.096px;
		left: 330.827px;
		top: 124.422px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Principles_lbl_website_2 {
		left: 6px;
		top: 105.5px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(54,54,54,1);
	}
	#Scroll_princi_website_2 {
		mix-blend-mode: normal;
		position: absolute;
		width: 438px;
		height: 251px;
		left: 0px;
		top: 140.5px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#principle_desicrption_2 {
		left: 5px;
		top: 16px;
		position: absolute;
		overflow: visible;
		width: 422px;
		/* white-space: nowrap; */
		line-height: 32px;
		margin-top: -7px;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(54,54,54,1);
	}
	#Dotted_LIne1_vision_to_princi {
		fill: transparent;
		stroke: rgba(209,209,209,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 7;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Dotted_LIne1_vision_to_princi {
		overflow: visible;
		position: absolute;
		width: 556.016px;
		height: 211.021px;
		left: 395.997px;
		top: 1165px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#vision_all_grp_website_2 {
		position: absolute;
		width: 1075px;
		height: 309.932px;
		left: 157px;
		top: 945.068px;
		overflow: visible;
	}
	#Vision_lbl_website_2 {
		left: 616px;
		top: 40.932px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(54,54,54,1);
	}
	#scroll_vision_website_2 {
		mix-blend-mode: normal;
		position: absolute;
		width: 468px;
		height: 228px;
		left: 607px;
		top: 81.932px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#vision_description_website_2 {
		left: 7px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 100%;
		/* white-space: nowrap; */
		line-height: 32px;
		margin-top: -7px;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(54,54,54,1);
	}
	#visin_img_website_2 {
		position: absolute;
		width: 429.523px;
		height: 309.698px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#vision_img_website_2_bg_deisgn {
		opacity: 0.1;
		fill: rgba(239,122,120,1);
	}
	.vision_img_website_2_bg_deisgn {
		overflow: visible;
		position: absolute;
		width: 429.523px;
		height: 213.629px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#shadow_vision_img_website_2_ {
		opacity: 0.1;
		fill: rgba(56,211,159,1);
	}
	.shadow_vision_img_website_2_ {
		position: absolute;
		overflow: visible;
		width: 185.505px;
		height: 13.189px;
		left: 19.093px;
		top: 296.508px;
	}
	#vision_img_website_2_hand_left {
		fill: rgba(251,190,190,1);
	}
	.vision_img_website_2_hand_left {
		overflow: visible;
		position: absolute;
		width: 19.766px;
		height: 25.835px;
		left: 123.843px;
		top: 52.587px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_left_hand {
		fill: rgba(241,90,90,1);
	}
	.vision_img_website_2_left_hand {
		overflow: visible;
		position: absolute;
		width: 50.344px;
		height: 44.337px;
		left: 84.147px;
		top: 73.901px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_spin_cent {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_cent {
		overflow: visible;
		position: absolute;
		width: 73.011px;
		height: 73.011px;
		left: 200.641px;
		top: 102.578px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_spin_12 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_12 {
		position: absolute;
		overflow: visible;
		width: 8.552px;
		height: 9.02px;
		left: 231.743px;
		top: 94.026px;
	}
	#vision_img_website_2_spin_11 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_11 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,252.4276,108.9862) rotate(-59.003deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,252.4276,108.9862) rotate(-59.003deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spin_0 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_0 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,267.4633,123.636) rotate(-29deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,267.4633,123.636) rotate(-29deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spin_9 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_9 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(0.9999,0,0,0.9999,267.8978,164.5281) rotate(-57.999deg);
		        transform: translate(-0.026px, -9.533px) matrix(0.9999,0,0,0.9999,267.8978,164.5281) rotate(-57.999deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spin_8 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_8 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,198.1,123.3897) rotate(-61deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,198.1,123.3897) rotate(-61deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spin_7 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_7 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(0.9999,0,0,0.9999,197.6771,164.7309) rotate(-32.001deg);
		        transform: translate(-0.026px, -9.533px) matrix(0.9999,0,0,0.9999,197.6771,164.7309) rotate(-32.001deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spin_6 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_6 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,213.1423,108.7995) rotate(-30.997deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,213.1423,108.7995) rotate(-30.997deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spin_5 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_5 {
		position: absolute;
		overflow: visible;
		width: 8.552px;
		height: 9.02px;
		left: 231.743px;
		top: 175.164px;
	}
	#vision_img_website_2_spin_4 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_4 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,252.6638,179.4843) rotate(-30.997deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,252.6638,179.4843) rotate(-30.997deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spin_3 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_3 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,212.8667,179.6854) rotate(-59.003deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,212.8667,179.6854) rotate(-59.003deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spin_2 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_2 {
		position: absolute;
		overflow: visible;
		width: 10.381px;
		height: 7.659px;
		left: 191.196px;
		top: 135.51px;
	}
	#vision_img_website_2_spin_ {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_ {
		position: absolute;
		overflow: visible;
		width: 10.381px;
		height: 7.659px;
		left: 272.759px;
		top: 135.51px;
	}
	#vision_img_website_2_spi_cente {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_cente {
		overflow: visible;
		position: absolute;
		width: 73.011px;
		height: 73.011px;
		left: 268.674px;
		top: 49.862px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_spi_12 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_12 {
		position: absolute;
		overflow: visible;
		width: 8.552px;
		height: 9.02px;
		left: 299.776px;
		top: 41.311px;
	}
	#vision_img_website_2_spi11 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi11 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,320.4677,56.2764) rotate(-59.003deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,320.4677,56.2764) rotate(-59.003deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spi_0 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_0 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,335.4995,70.9262) rotate(-29deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,335.4995,70.9262) rotate(-29deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spi_9 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_9 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(0.9999,0,0,0.9999,335.9021,111.7984) rotate(-57.999deg);
		        transform: translate(-0.026px, -9.533px) matrix(0.9999,0,0,0.9999,335.9021,111.7984) rotate(-57.999deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spi_8 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_8 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,266.1355,70.681) rotate(-61deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,266.1355,70.681) rotate(-61deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spi_7 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_7 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(0.9999,0,0,0.9999,265.7093,112.0224) rotate(-32.001deg);
		        transform: translate(-0.026px, -9.533px) matrix(0.9999,0,0,0.9999,265.7093,112.0224) rotate(-32.001deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spi_6 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_6 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,281.1836,56.0881) rotate(-30.997deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,281.1836,56.0881) rotate(-30.997deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spi_5 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_5 {
		position: absolute;
		overflow: visible;
		width: 8.552px;
		height: 9.02px;
		left: 299.776px;
		top: 122.448px;
	}
	#vision_img_website_2_spi_4 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_4 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,320.7036,126.7732) rotate(-30.997deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,320.7036,126.7732) rotate(-30.997deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spi_3 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_3 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,280.9248,126.935) rotate(-59.003deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,280.9248,126.935) rotate(-59.003deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spi_2 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_2 {
		position: absolute;
		overflow: visible;
		width: 10.381px;
		height: 7.659px;
		left: 259.229px;
		top: 82.794px;
	}
	#vision_img_website_2_spi_1 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_1 {
		position: absolute;
		overflow: visible;
		width: 10.381px;
		height: 7.659px;
		left: 340.792px;
		top: 82.794px;
	}
	#vision_img_website_2_spiral_ce {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral_ce {
		overflow: visible;
		position: absolute;
		width: 73.054px;
		height: 73.011px;
		left: 132.523px;
		top: 47.607px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_spiral12 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral12 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,164.8825,48.8331) rotate(-88.01deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,164.8825,48.8331) rotate(-88.01deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spiral11 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral11 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,185.6119,54.7445) rotate(-57.011deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,185.6119,54.7445) rotate(-57.011deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spiral0 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral0 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,200.0959,69.8694) rotate(-27.008deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,200.0959,69.8694) rotate(-27.008deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spiral_9 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral_9 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(0.9999,0,0,0.9999,199.1349,110.7294) rotate(-56.011deg);
		        transform: translate(-0.026px, -9.533px) matrix(0.9999,0,0,0.9999,199.1349,110.7294) rotate(-56.011deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spiral8 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral8 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,130.807,67.2176) rotate(-59.011deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,130.807,67.2176) rotate(-59.011deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spiral7 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral7 {
		width: 9.063px;
		height: 8.595px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,128.3039,109.2181) rotate(-29.888deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,128.3039,109.2181) rotate(-29.888deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spiral6 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral6 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,146.3344,53.1297) rotate(-29.013deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,146.3344,53.1297) rotate(-29.013deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spiral5 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral5 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,162.0675,129.8833) rotate(-88.01deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,162.0675,129.8833) rotate(-88.01deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spiral4 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral4 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,183.3973,125.1601) rotate(-29.013deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,183.3973,125.1601) rotate(-29.013deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spiral3 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral3 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,143.6654,124.0083) rotate(-57.011deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,143.6654,124.0083) rotate(-57.011deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spiral_2 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral_2 {
		width: 7.658px;
		height: 10.382px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,124.5697,87.2717) rotate(-88.01deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,124.5697,87.2717) rotate(-88.01deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_spiral_1 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral_1 {
		width: 7.658px;
		height: 10.382px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,206.0729,90.0859) rotate(-88.01deg);
		        transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,206.0729,90.0859) rotate(-88.01deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_img_website_2_shoe_2 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_shoe_2 {
		overflow: visible;
		position: absolute;
		width: 22.905px;
		height: 33.394px;
		left: 62.313px;
		top: 269.773px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_shoe {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_shoe {
		overflow: visible;
		position: absolute;
		width: 38.022px;
		height: 20.639px;
		left: 139.033px;
		top: 285.787px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_neck {
		fill: rgba(251,190,190,1);
	}
	.vision_img_website_2_neck {
		overflow: visible;
		position: absolute;
		width: 30.847px;
		height: 24.466px;
		left: 67.596px;
		top: 94.876px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_face {
		fill: rgba(251,190,190,1);
	}
	.vision_img_website_2_face {
		position: absolute;
		overflow: visible;
		width: 31.145px;
		height: 31.145px;
		left: 62.406px;
		top: 71.774px;
	}
	#vision_img_website_2_shirt {
		fill: rgba(241,90,90,1);
	}
	.vision_img_website_2_shirt {
		overflow: visible;
		position: absolute;
		width: 80.588px;
		height: 67.142px;
		left: 63.465px;
		top: 109.333px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_hand_2 {
		fill: rgba(251,190,190,1);
	}
	.vision_img_website_2_hand_2 {
		overflow: visible;
		position: absolute;
		width: 19.766px;
		height: 25.835px;
		left: 118.737px;
		top: 52.587px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_leg_1 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_leg_1 {
		overflow: visible;
		position: absolute;
		width: 102.924px;
		height: 122.281px;
		left: 77.808px;
		top: 163.293px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_hand_1 {
		fill: rgba(241,90,90,1);
	}
	.vision_img_website_2_hand_1 {
		overflow: visible;
		position: absolute;
		width: 67.687px;
		height: 62.927px;
		left: 61.943px;
		top: 72.37px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_hair {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_hair {
		overflow: visible;
		position: absolute;
		width: 40.75px;
		height: 80.38px;
		left: 45.259px;
		top: 65.213px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Our_Objectives_website_2 {
		left: 575px;
		top: 849px;
		position: absolute;
		overflow: visible;
		width: 199px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(54,54,54,1);
	}
	#Hello_Im__Mohammed_Affan_pc_we {
		left: 142px;
		top: 188px;
		position: absolute;
		overflow: visible;
		width: 418px;
		height: 97px;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(54,54,54,1);
	}
	#intro_scroll_websoite_21 {
		mix-blend-mode: normal;
		position: absolute;
		width: 425px;
		height: 145px;
		left: 135px;
		top: 285px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#pc_solgan__website_2 {
		left: 7px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 419px;
		height: 135px;
		line-height: 31px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Acumin Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(54,54,54,1);
	}
	#Group_302 {
		position: absolute;
		width: 1033.599px;
		height: 1012.649px;
		left: 592px;
		top: -304.258px;
		overflow: visible;
	}
	#rec_outer_website_2 {
		fill: rgba(243,123,123,1);
	}
	.rec_outer_website_2 {
		overflow: visible;
		position: absolute;
		width: 832.848px;
		height: 876.211px;
		-webkit-transform: translate(-564.666px, 273.258px) matrix(1,0,0,1,708.4976,-219.5662) rotate(-8deg);
		        transform: translate(-564.666px, 273.258px) matrix(1,0,0,1,708.4976,-219.5662) rotate(-8deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#circle_outer_webstie_2 {
		fill: rgba(243,123,123,1);
	}
	.circle_outer_webstie_2 {
		width: 530px;
		height: 522px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-564.666px, 273.258px) matrix(1,0,0,1,586.4053,195.288) rotate(-5deg);
		        transform: translate(-564.666px, 273.258px) matrix(1,0,0,1,586.4053,195.288) rotate(-5deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#view_work_button_pc {
		position: absolute;
		width: 126px;
		height: 42px;
		left: 142px;
		top: 442px;
		overflow: visible;
	}
	#our_offering_btn_bdr_website_2 {
		fill: rgba(63,61,86,1);
	}
	.our_offering_btn_bdr_website_2 {
		position: absolute;
		overflow: visible;
		width: 126px;
		height: 42px;
		left: 0px;
		top: 0px;
	}
	#Our_Offerings_website_2 {
		left: 18px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#Illustration_grp_website_2 {
		opacity: 0.997;
		position: absolute;
		width: 536.042px;
		height: 361.41px;
		left: 663.292px;
		top: 232.493px;
		overflow: visible;
	}
	#floor_design_ {
		fill: rgba(63,61,86,1);
	}
	.floor_design_ {
		overflow: visible;
		position: absolute;
		width: 528.5px;
		height: 110.897px;
		left: 6.045px;
		top: 250.512px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#bg_grey_deisng {
		fill: rgba(0,0,0,1);
	}
	.bg_grey_deisng {
		overflow: visible;
		position: absolute;
		width: 535.994px;
		height: 298.635px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#bg_black_design__ig {
		fill: url(#bg_black_design__ig);
	}
	.bg_black_design__ig {
		overflow: visible;
		position: absolute;
		width: 535.994px;
		height: 298.635px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#top_right_rod_website_2 {
		fill: rgba(63,61,86,1);
	}
	.top_right_rod_website_2 {
		overflow: visible;
		position: absolute;
		width: 6.229px;
		height: 110.684px;
		left: 479.734px;
		top: 18.542px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#rod_top_hori_star_1_website_2 {
		fill: rgba(63,61,86,1);
	}
	.rod_top_hori_star_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 493.684px;
		height: 6.23px;
		left: 26.939px;
		top: 62.438px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#star_9_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_9_website_2 {
		position: absolute;
		overflow: visible;
		width: 3.67px;
		height: 3.67px;
		left: 114.797px;
		top: 32.836px;
	}
	#star_8_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_8_website_2 {
		position: absolute;
		overflow: visible;
		width: 3.67px;
		height: 3.67px;
		left: 56.799px;
		top: 29.214px;
	}
	#star_7_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_7_website_2 {
		position: absolute;
		overflow: visible;
		width: 1.545px;
		height: 1.545px;
		left: 136.287px;
		top: 33.898px;
	}
	#star_6_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_6_website_2 {
		position: absolute;
		overflow: visible;
		width: 1.545px;
		height: 1.545px;
		left: 78.047px;
		top: 32.111px;
	}
	#star_5_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_5_website_2 {
		position: absolute;
		overflow: visible;
		width: 1.545px;
		height: 1.545px;
		left: 77.275px;
		top: 86.391px;
	}
	#star_4_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_4_website_2 {
		position: absolute;
		overflow: visible;
		width: 1.545px;
		height: 1.545px;
		left: 435.886px;
		top: 45.343px;
	}
	#star_3_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_3_website_2 {
		position: absolute;
		overflow: visible;
		width: 1.545px;
		height: 1.545px;
		left: 172.553px;
		top: 46.068px;
	}
	#star_2_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_2_website_2 {
		position: absolute;
		overflow: visible;
		width: 1.545px;
		height: 1.545px;
		left: 397.542px;
		top: 44.522px;
	}
	#star_1_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_1_website_2 {
		position: absolute;
		overflow: visible;
		width: 1.545px;
		height: 1.545px;
		left: 495.429px;
		top: 46.84px;
	}
	#building_website_2 {
		fill: rgba(243,123,123,1);
	}
	.building_website_2 {
		overflow: visible;
		position: absolute;
		width: 508.459px;
		height: 157.379px;
		left: 27.583px;
		top: 141.54px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#building_shadow__website_2 {
		opacity: 0.3;
		fill: rgba(0,0,0,1);
	}
	.building_shadow__website_2 {
		overflow: visible;
		position: absolute;
		width: 508.459px;
		height: 157.379px;
		left: 27.583px;
		top: 141.54px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#left_win_0_website_2 {
		opacity: 0.42;
		fill: rgba(0,0,0,1);
	}
	.left_win_0_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 42.891px;
		top: 181.959px;
	}
	#left_window_0_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_0_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 65.009px;
		top: 181.959px;
	}
	#right_window_12_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_12_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 318.731px;
		top: 162.788px;
	}
	#right_window_11_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_11_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 356.012px;
		top: 162.788px;
	}
	#right_window_0_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_0_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 396.094px;
		top: 162.788px;
	}
	#right_window_9_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_9_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 318.731px;
		top: 191.279px;
	}
	#right_window_8_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_8_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 356.012px;
		top: 191.279px;
	}
	#right_window_7_website_2 {
		fill: rgba(255,255,255,1);
	}
	.right_window_7_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 396.094px;
		top: 191.279px;
	}
	#right_window_6_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_6_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 318.731px;
		top: 219.771px;
	}
	#right_window_5_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_5_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 356.012px;
		top: 219.771px;
	}
	#right_window_4_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_4_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 396.094px;
		top: 219.771px;
	}
	#right_window_3_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 9.852px;
		height: 9.32px;
		left: 444.723px;
		top: 162.836px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#right_window_2_website_2 {
		fill: rgba(255,255,255,1);
	}
	.right_window_2_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 444.772px;
		top: 191.279px;
	}
	#right_window_1_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_1_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 444.772px;
		top: 219.771px;
	}
	#left_window_9_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_9_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 119.53px;
		top: 151.922px;
	}
	#left_window_8_website_2 {
		fill: rgba(255,255,255,1);
	}
	.left_window_8_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 119.53px;
		top: 172.832px;
	}
	#left_window_7_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_7_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 119.53px;
		top: 193.694px;
	}
	#left_window_6_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_6_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 119.53px;
		top: 214.604px;
	}
	#left_window_5_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_5_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 119.53px;
		top: 235.514px;
	}
	#left_window_4_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_4_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 42.891px;
		top: 206.298px;
	}
	#left_window_3_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_3_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 65.009px;
		top: 206.298px;
	}
	#left_window_2_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_2_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 42.891px;
		top: 230.685px;
	}
	#left_window_1_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_1_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 65.009px;
		top: 230.685px;
	}
	#verticle_left_website_2 {
		fill: rgba(63,61,86,1);
	}
	.verticle_left_website_2 {
		overflow: visible;
		position: absolute;
		width: 6.229px;
		height: 291.969px;
		left: 94.466px;
		top: 0.046px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#veritical_right_website_2 {
		fill: rgba(63,61,86,1);
	}
	.veritical_right_website_2 {
		overflow: visible;
		position: absolute;
		width: 6.229px;
		height: 251.211px;
		left: 287.101px;
		top: 47.227px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#bar_down_hori_website_2 {
		fill: rgba(63,61,86,1);
	}
	.bar_down_hori_website_2 {
		overflow: visible;
		position: absolute;
		width: 527.582px;
		height: 6.23px;
		left: 0.202px;
		top: 227.111px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#left_leg_top_website_2 {
		fill: rgba(101,97,125,1);
	}
	.left_leg_top_website_2 {
		overflow: visible;
		position: absolute;
		width: 77.442px;
		height: 37.801px;
		left: 160.208px;
		top: 278.403px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#left_leg_down_website_2 {
		fill: rgba(251,190,190,1);
	}
	.left_leg_down_website_2 {
		overflow: visible;
		position: absolute;
		width: 77.116px;
		height: 32.709px;
		left: 198.781px;
		top: 308.482px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#neck_website_2 {
		fill: rgba(251,190,190,1);
	}
	.neck_website_2 {
		overflow: visible;
		position: absolute;
		width: 67.242px;
		height: 53.421px;
		left: 226.543px;
		top: 129.214px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#face_circle_website_2 {
		fill: rgba(251,190,190,1);
	}
	.face_circle_website_2 {
		overflow: visible;
		position: absolute;
		width: 49.74px;
		height: 49.74px;
		left: 232.483px;
		top: 101.023px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#short_website_2 {
		fill: rgba(243,123,123,1);
	}
	.short_website_2 {
		overflow: visible;
		position: absolute;
		width: 117.976px;
		height: 120.825px;
		left: 203.218px;
		top: 154.723px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#left_hand_behind_laptop_websit {
		fill: rgba(251,190,190,1);
	}
	.left_hand_behind_laptop_websit {
		overflow: visible;
		position: absolute;
		width: 25.208px;
		height: 23.518px;
		left: 200.176px;
		top: 244.11px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#right_leg_upper_leg_website_2 {
		fill: rgba(101,97,125,1);
	}
	.right_leg_upper_leg_website_2 {
		overflow: visible;
		position: absolute;
		width: 75.101px;
		height: 40.24px;
		left: 298.207px;
		top: 262.351px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#right_leg_top_website_2 {
		fill: rgba(101,97,125,1);
	}
	.right_leg_top_website_2 {
		overflow: visible;
		position: absolute;
		width: 92.666px;
		height: 29.675px;
		left: 268.031px;
		top: 293.657px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#left_leg_1_website_2 {
		fill: rgba(251,190,190,1);
	}
	.left_leg_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 85.963px;
		height: 21.095px;
		left: 258.367px;
		top: 320.186px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#left_leg_website_2 {
		fill: rgba(101,97,125,1);
	}
	.left_leg_website_2 {
		overflow: visible;
		position: absolute;
		width: 104.116px;
		height: 48.996px;
		left: 171.008px;
		top: 291.436px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#left_hand_website_2 {
		fill: rgba(243,123,123,1);
	}
	.left_hand_website_2 {
		overflow: visible;
		position: absolute;
		width: 31.06px;
		height: 84.027px;
		left: 189.833px;
		top: 179.544px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#right_hand_down_website_2 {
		fill: rgba(251,190,190,1);
	}
	.right_hand_down_website_2 {
		overflow: visible;
		position: absolute;
		width: 33.659px;
		height: 38.542px;
		left: 288.742px;
		top: 239.571px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#right_hand_website_2 {
		fill: rgba(243,123,123,1);
	}
	.right_hand_website_2 {
		overflow: visible;
		position: absolute;
		width: 26.533px;
		height: 72.629px;
		left: 298.302px;
		top: 182.443px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#laptop_website_2 {
		fill: rgba(63,61,86,1);
	}
	.laptop_website_2 {
		overflow: visible;
		position: absolute;
		width: 108.221px;
		height: 61.475px;
		left: 205.344px;
		top: 238.605px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#hair_website_2 {
		fill: rgba(63,61,86,1);
	}
	.hair_website_2 {
		overflow: visible;
		position: absolute;
		width: 84.881px;
		height: 159.123px;
		left: 210.462px;
		top: 73.42px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#left_leaf_1_website_2 {
		fill: rgba(56,211,159,1);
	}
	.left_leaf_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 12.534px;
		height: 17.095px;
		left: 399.062px;
		top: 271.009px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#left_leaf_2_website_2 {
		fill: rgba(56,211,159,1);
	}
	.left_leaf_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 12.654px;
		height: 23.759px;
		left: 403.625px;
		top: 263.33px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#left_leadf_3_website_2 {
		fill: rgba(56,211,159,1);
	}
	.left_leadf_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 9.028px;
		height: 30.522px;
		left: 409.345px;
		top: 257.68px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#right_leaf_website_2 {
		fill: rgba(56,211,159,1);
	}
	.right_leaf_website_2 {
		overflow: visible;
		position: absolute;
		width: 13.654px;
		height: 17.095px;
		left: 423.185px;
		top: 271.009px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#righ_leaf_2_website_2 {
		fill: rgba(56,211,159,1);
	}
	.righ_leaf_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 14.005px;
		height: 23.759px;
		left: 418.55px;
		top: 263.33px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leafe_3_left_website_2 {
		fill: rgba(56,211,159,1);
	}
	.leafe_3_left_website_2 {
		overflow: visible;
		position: absolute;
		width: 10.002px;
		height: 30.511px;
		left: 416.456px;
		top: 257.68px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#mid_leaf_left_website_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.mid_leaf_left_website_2 {
		overflow: visible;
		position: absolute;
		width: 6.04px;
		height: 21.683px;
		left: 413.713px;
		top: 266.179px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#mid_shadow_right_website_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.mid_shadow_right_website_2 {
		overflow: visible;
		position: absolute;
		width: 6.543px;
		height: 22.265px;
		left: 416.473px;
		top: 265.6px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#leaf_mid_website_2 {
		fill: rgba(56,211,159,1);
	}
	.leaf_mid_website_2 {
		overflow: visible;
		position: absolute;
		width: 7.958px;
		height: 40.138px;
		left: 414.353px;
		top: 247.684px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_behind_new_website_2 {
		fill: rgba(212,223,236,1);
	}
	.pot_behind_new_website_2 {
		overflow: visible;
		position: absolute;
		width: 34.866px;
		height: 17.626px;
		left: 399.281px;
		top: 285.737px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_2_behind_website_2 {
		opacity: 0.08;
		fill: rgba(0,0,0,1);
	}
	.pot_2_behind_website_2 {
		overflow: visible;
		position: absolute;
		width: 34.866px;
		height: 17.626px;
		left: 399.281px;
		top: 285.737px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_2_rec_bdr_mid_website_2 {
		fill: rgba(212,223,236,1);
	}
	.pot_2_rec_bdr_mid_website_2 {
		position: absolute;
		overflow: visible;
		width: 34.866px;
		height: 5.795px;
		left: 400.633px;
		top: 285.737px;
	}
	#over_all_half_cactus_ {
		fill: rgba(56,211,159,1);
	}
	.over_all_half_cactus_ {
		overflow: visible;
		position: absolute;
		width: 37.677px;
		height: 39.254px;
		left: 105.782px;
		top: 244.89px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#cactus_2_website_2 {
		fill: rgba(56,211,159,1);
	}
	.cactus_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.12px;
		height: 19.724px;
		left: 123.917px;
		top: 251.311px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_back_2_website2 {
		fill: rgba(212,223,236,1);
	}
	.pot_1_back_2_website2 {
		position: absolute;
		overflow: visible;
		width: 29.651px;
		height: 20.813px;
		left: 104.511px;
		top: 280.763px;
	}
	#pot_1_down_2_website_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.pot_1_down_2_website_2 {
		position: absolute;
		overflow: visible;
		width: 22.697px;
		height: 5.94px;
		left: 108.133px;
		top: 300.176px;
	}
	#pot_1_box_2_website_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.pot_1_box_2_website_2 {
		position: absolute;
		overflow: visible;
		width: 29.651px;
		height: 20.813px;
		left: 104.511px;
		top: 280.763px;
	}
	#pot_1_down_1_website_2 {
		fill: rgba(212,223,236,1);
	}
	.pot_1_down_1_website_2 {
		position: absolute;
		overflow: visible;
		width: 22.697px;
		height: 5.94px;
		left: 109.582px;
		top: 300.176px;
	}
	#pot_1_cactus_3_website_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.pot_1_cactus_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 5.409px;
		height: 4.539px;
		left: 118.371px;
		top: 263.475px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_cactus_2_website_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.pot_1_cactus_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 7.106px;
		height: 6.208px;
		left: 123.917px;
		top: 264.827px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_cactus_1_website_2 {
		fill: rgba(56,211,159,1);
	}
	.pot_1_cactus_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 19.326px;
		height: 20.046px;
		left: 111.707px;
		top: 264.098px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot1_box_down_website_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.pot1_box_down_website_2 {
		position: absolute;
		overflow: visible;
		width: 22.697px;
		height: 2.173px;
		left: 109.582px;
		top: 300.176px;
	}
	#pot_1_box_1_website_2 {
		fill: rgba(212,223,236,1);
	}
	.pot_1_box_1_website_2 {
		position: absolute;
		overflow: visible;
		width: 29.651px;
		height: 20.813px;
		left: 106.008px;
		top: 280.763px;
	}
	#intro_grp_website_2 {
		position: absolute;
		width: 67px;
		height: 27px;
		left: 723px;
		top: 54px;
		overflow: visible;
	}
	#Intro {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#home-icon-silhouette {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#offerings_grp_website_2 {
		position: absolute;
		width: 106px;
		height: 27px;
		left: 823px;
		top: 54px;
		overflow: visible;
	}
	#Offerings {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#discount {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#partner_grp_website_2 {
		position: absolute;
		width: 97px;
		height: 27px;
		left: 962px;
		top: 54px;
		overflow: visible;
	}
	#Partners_lbl_partner {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#deal_img_ {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#copy_right_top_line_website_2_ {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.copy_right_top_line_website_2_ {
		overflow: visible;
		position: absolute;
		width: 1173px;
		height: 1px;
		left: 87.5px;
		top: 5724.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_Copyright_Operate_Live_OPC_P {
		left: 125px;
		top: 5771px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	/* #Title_1_lbl_website_2_layout {
		left: 830px;
		top: 2737px;
		position: absolute;
		overflow: visible;
		width: 79px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	} */
	/* #Description_lbl_1website_2_lay {
		left: 691px;
		top: 2816px;
		position: absolute;
		overflow: visible;
		width: 373px;
		height: 356px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#Line_1_divide_website_2_layout {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_1_divide_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 931px;
		height: 1px;
		left: 170.5px;
		top: 3225.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Line_2_divide_website_2_layout {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_2_divide_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 931px;
		height: 1px;
		left: 170.5px;
		top: 3793.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#line_3_divide_website_2_layout {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_3_divide_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 931px;
		height: 1px;
		left: 170.5px;
		top: 4349.5px;
		transform: matrix(1,0,0,1,0,0);
	} */
	/* #title_path_down1_website_2_lay {
		fill: transparent;
		stroke: rgba(245,149,149,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.title_path_down1_website_2_lay {
		overflow: visible;
		position: absolute;
		width: 335.083px;
		height: 26.866px;
		left: 718.654px;
		top: 2790px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Title_2_lbl_website_2_layout {
		left: 838px;
		top: 3854px;
		position: absolute;
		overflow: visible;
		width: 79px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#Description_lbl_website_2_layo {
		left: 699px;
		top: 3933px;
		position: absolute;
		overflow: visible;
		width: 373px;
		height: 356px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#title_underline_2website_2_lay {
		fill: transparent;
		stroke: rgba(245,149,149,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.title_underline_2website_2_lay {
		overflow: visible;
		position: absolute;
		width: 335.083px;
		height: 26.866px;
		left: 726.654px;
		top: 3907px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Title_lbl_website_2_layout {
		left: 323px;
		top: 3286px;
		position: absolute;
		overflow: visible;
		width: 79px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#Description_1_website_2_layout {
		left: 184px;
		top: 3365px;
		position: absolute;
		overflow: visible;
		width: 373px;
		height: 356px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#title_2_underline_website_2_la {
		fill: transparent;
		stroke: rgba(245,149,149,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.title_2_underline_website_2_la {
		overflow: visible;
		position: absolute;
		width: 335.083px;
		height: 26.866px;
		left: 211.654px;
		top: 3339px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Title_lbl_4ebsite_2_layout {
		left: 323px;
		top: 4422px;
		position: absolute;
		overflow: visible;
		width: 79px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#Description_lbl__website_2_lay {
		left: 184px;
		top: 4501px;
		position: absolute;
		overflow: visible;
		width: 373px;
		height: 356px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#title_3_under_linewebsite_2_la {
		fill: transparent;
		stroke: rgba(245,149,149,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.title_3_under_linewebsite_2_la {
		overflow: visible;
		position: absolute;
		width: 335.083px;
		height: 26.866px;
		left: 211.654px;
		top: 4475px;
		transform: matrix(1,0,0,1,0,0);
	} */

    #Comeplete_all_off_nameand_desc {
		position: absolute;
		width: 100%;
		height: 2404px;
		left: 170px;
		top: 2568px;
		overflow-x:hidden;
        overflow-y: auto;
	}
	#My_Offerings_lbl_website_2 {
		left: 410px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 176px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(54,54,54,1);
	}
	#offing_my_all_grp_4_cards {
		position: absolute;
		width: 931.927px;
		height: 2254px;
		left: 0px;
        text-align: center;
		top: 150px;
		overflow: visible;
	}
	#View_all_btn_grp_website_2 {
		position: relative;
		width: 126px;
		height: 42px;
        text-align: center;
        display: inline-block;
		/* left: 441px; */
		/* top: 2212px; */
		overflow: visible;
	}
	#view_all_bdr_website_2 {
		fill: rgba(63,61,86,1);
	}
	.view_all_bdr_website_2 {
		position: absolute;
		overflow: visible;
		width: 126px;
		height: 42px;
		left: 0px;
		top: 0px;
	}
	#View_All_website_2 {
		left: 37px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 53px;
		white-space: nowrap;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#off_card_45grp_desc {
		position: relative;
		width: 100%;
		height: 500px;
        margin-bottom: 10px;
        /* background-color: chartreuse ; */
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#off_card_2_grp_desc {
		position: relative;
		width: 100%;
		height: 500px;
        /* background-color: blueviolet; */
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#design_1_off_website_2 {
		position: absolute;
		width: 400px;
		height: 446.829px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Title_1_lbl_website_2_layout {
		left: 521px;
		top: 19px;
		position: absolute;
		overflow: visible;
		width: 373px;
		white-space: nowrap;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#Description_lbl_1website_2_lay {
		left: 521px;
		top: 98px;
		position: absolute;
		overflow: visible;
		width: 373px;
		height: 356px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#title_path_down1_website_2_lay {
		fill: transparent;
		stroke: rgba(245,149,149,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.title_path_down1_website_2_lay {
		overflow: visible;
		position: absolute;
		width: 335.083px;
		height: 26.866px;
		left: 548.654px;
		top: 72px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#off_card_32_grp_desc {
		position: absolute;
		width: 931.5px;
		height: 495.841px;
		left: 0px;
		top: 1135.658px;
		overflow: visible;
	}
	#design_3_off_website_2 {
		position: absolute;
		width: 400px;
		height: 435.369px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Title_2_lbl_website_2_layout {
		left: 668px;
		top: 0.342px;
		position: absolute;
		overflow: visible;
		width: 79px;
        left: 13px;
        width: 373px;
        text-align: center;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#Description_lbl_website_2_layo {
		left: 529px;
		top: 79.342px;
		position: absolute;
		overflow: visible;
		width: 373px;
		height: 356px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#title_underline_2website_2_lay {
		fill: transparent;
		stroke: rgba(245,149,149,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.title_underline_2website_2_lay {
		overflow: visible;
		position: absolute;
		width: 335.083px;
		height: 26.866px;
		left: 556.654px;
		top: 53.342px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#line_3_divide_website_2_layout {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_3_divide_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 931px;
		height: 1px;
		left: 0.5px;
		top: 495.842px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#design_2_off_website_2 {
		position: absolute;
		width: 410.53px;
		height: 446.829px;
		left: 520.897px;
		top: 0px;
		overflow: visible;
	}
	#Title_lbl_website_2_layout {
		top: 0px;
		position: absolute;
		overflow: visible;
        left: 13px;
        width: 373px;
        text-align: center;
		white-space: nowrap;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#Description_1_website_2_layout {
		left: 13.5px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 373px;
		height: 356px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#title_2_underline_website_2_la {
		fill: transparent;
		stroke: rgba(245,149,149,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.title_2_underline_website_2_la {
		overflow: visible;
		position: absolute;
		width: 335.083px;
		height: 26.866px;
		left: 41.154px;
		top: 53px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Line_2_divide_website_2_layout {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_2_divide_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 931px;
		height: 1px;
		left: 0px;
		top: 507.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#off_card_1_desc_grp {
		position: absolute;
		width: 931.427px;
		height: 1643.178px;
		left: 0.5px;
		top: 507.5px;
		overflow: visible;
	}
	#design_4_off_website_2 {
		position: absolute;
		width: 410px;
		height: 458.336px;
		left: 521.427px;
		top: 1184.842px;
		overflow: visible;
	}
	#Title_lbl_4ebsite_2_layout {
		left: 152.5px;
		top: 1196.5px;
		position: absolute;
		overflow: visible;
		width: 79px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#Description_lbl__website_2_lay {
		left: 13.5px;
		top: 1275.5px;
		position: absolute;
		overflow: visible;
		width: 373px;
		height: 356px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#title_3_under_linewebsite_2_la {
		fill: transparent;
		stroke: rgba(245,149,149,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.title_3_under_linewebsite_2_la {
		overflow: visible;
		position: absolute;
		width: 335.083px;
		height: 26.866px;
		left: 41.154px;
		top: 1249.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Line_1_divide_website_2_layout {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_1_divide_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 931px;
		height: 1px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}

	#our_success_partners_website_2 {
		left: 481px;
		top: 2196px;
		position: absolute;
		overflow: visible;
		width: 387px;
		height: 43px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		color: rgba(0,0,0,1);
	}
	#later {
		position: absolute;
		width: 1366px;
		height: 130px;
		left: 0px;
		top: 2269px;
		overflow: visible;
	}
	#Rectangle_480 {
		fill: transparent;
	}
	.Rectangle_480 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 130px;
		left: 0px;
		top: 0px;
		background:  rgba( 255, 255, 255, 0.5 );
		box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
		backdrop-filter:  blur( 10px );
		-webkit-backdrop-filter:  blur( 10px );
		border-radius:  10px;
		border:  1px solid rgba( 255, 255, 255, 0.18 );
	}
	#partner_lbls_grp {
		position: absolute;
		width: 1069px;
		height: 27px;
		left: 149px;
		display: inline-flex;
		top: 52px;
		overflow: visible;
	}
	#google_lbl_partner_website_2 {
		left: 30px;
		top: 0px;
		position: relative;
		overflow: visible;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#microsoft_lbl_partnw_website_2 {
		left: 210px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#microscope_lbl_partner_website {
		left: 390px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#google_1_website_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#microsoft_website_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 180px;
		top: 1px;
		overflow: visible;
	}
	#scope_website_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 360px;
		top: 1px;
		overflow: visible;
	}
	#googlewebsite_2 {
		left: 572px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#microsoft_1_website_2 {
		left: 752px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#micro_1_website_2 {
		left: 932px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#google_1_1_website_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 542px;
		top: 1px;
		overflow: visible;
	}
	#microsoft_1_website_2_lq {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 722px;
		top: 1px;
		overflow: visible;
	}
	#scope_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 902px;
		top: 1px;
		overflow: visible;
	}
	#Group_300 {
		position: absolute;
		width: 594px;
		height: 90px;
		left: 377px;
		top: 5552px;
		overflow: visible;
	}
	#Start_a_new_project {
		position: absolute;
		width: 179px;
		height: 42px;
		left: 415px;
		top: 3px;
		overflow: visible;
	}
	#Rectangle_89 {
		fill: rgba(63,61,86,1);
	}
	.Rectangle_89 {
		position: absolute;
		overflow: visible;
		width: 179px;
		height: 42px;
		left: 0px;
		top: 0px;
	}
	#Network_With_Us_ {
		left: 28px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 125px;
		white-space: nowrap;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#Willing_to_Grow_by_Partnering_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 301px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 34px;
		color: rgba(54,54,54,1);
	}
	#linked_in_website_2_layout {
		position: absolute;
		width: 25px;
		height: 22.727px;
		left: 541.5px;
		top: 66.442px;
		overflow: visible;
	}
	#n_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.n_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 16.522px;
		height: 15.588px;
		left: 8.478px;
		top: 7.14px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_down {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_down {
		overflow: visible;
		position: absolute;
		width: 4.873px;
		height: 15.44px;
		left: 0.631px;
		top: 7.287px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 5.915px;
		height: 5.357px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#instagram_website_2_layout {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 492.5px;
		top: 67px;
		overflow: visible;
	}
	#whatsapp_lbl_website_2_layout {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 442.5px;
		top: 66.442px;
		overflow: visible;
	}
	#Contact_grp_website_2 {
		position: absolute;
		width: 93px;
		height: 27px;
		left: 1088px;
		top: 54px;
		overflow: visible;
	}
	#Contact {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#contact {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#contact_area_grp_website_2 {
		position: absolute;
		width: 777px;
		height: 306px;
		left: 286px;
		top: 5122px;
		overflow: visible;
	}
	#bdr_contact_page_website_2 {
		fill: rgba(255,255,255,1);
	}
	.bdr_contact_page_website_2 {
		-webkit-filter: drop-shadow(0px 10px 2px rgba(135, 207, 172, 0.647));
		        filter: drop-shadow(0px 10px 2px rgba(135, 207, 172, 0.647));
		overflow: visible;
		position: absolute;
		width: 783px;
		height: 319px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_234567890_mobile_number_webs_ff_pc {
		left: 71px;
		top: 19px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#affanghanioperatelive_email_pc {
		left: 72px;
		top: 73px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#address_website_pc_ {
		left: 67px;
		top: 127px;
		position: absolute;
		overflow: visible;
		width: 373px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Connect_with_us_and_unlock_lim_pc {
		left: 499px;
		top: 238px;
		position: absolute;
		overflow: visible;
		width: 271px;
		height: 54px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#location_img_website_ff_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 23px;
		top: 127px;
		overflow: visible;
	}
	#email_address_website_ff_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 23px;
		top: 73px;
		overflow: visible;
	}
	#phone_number_website_ff {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 23px;
		top: 19px;
		overflow: visible;
	}
	#quote_left_webstie_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 469px;
		top: 238px;
		overflow: visible;
	}
	#quote_right_website_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 721px;
		top: 265px;
		overflow: visible;
	}

.mediaViewInfo {
    --web-view-name: coming soon page;
    --web-view-id: coming_soon_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: coming_soon_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#coming_soon_page {
    position: absolute;
    width: calc(100vw - 136px);
    height:calc(100vh - 60px);
    top: 60px;
    left: 120px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: coming soon page;
    --web-view-id: coming_soon_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_back_some_soon {
    fill: rgba(219,242,231,1);
}
.over_all_back_some_soon {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}
#mid_grp_come {
    position: absolute;
    width: 587.094px;
    height: 487.493px;
    top: 50%;
    left: 45%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    overflow: visible;
}
#mid_design_hex {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.mid_design_hex {
    overflow: visible;
    position: absolute;
    width: 358.413px;
    height: 408.701px;
    left: 87.915px;
    top: 28.614px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#down_like_left {
    fill: transparent;
    stroke: rgba(1,33,56,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.down_like_left {
    overflow: visible;
    position: absolute;
    width: 71.921px;
    height: 96.986px;
    left: 145.376px;
    top: 376.68px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#down_line {
    fill: transparent;
    stroke: rgba(0,31,57,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.down_line {
    overflow: visible;
    position: absolute;
    width: 36.07px;
    height: 47.472px;
    left: 245.792px;
    top: 441.8px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#top_line {
    fill: rgba(0,32,54,1);
    stroke: rgba(1,33,56,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.top_line {
    overflow: visible;
    position: absolute;
    width: 35.531px;
    height: 47.187px;
    left: 267.128px;
    top: 77.346px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#top_right_line {
    fill: transparent;
    stroke: rgba(4,32,61,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.top_right_line {
    overflow: visible;
    position: absolute;
    width: 72.424px;
    height: 97.768px;
    left: 361.049px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#C_backOMING {
    -webkit-filter: blur(5px);
            filter: blur(5px);
    -webkit-transform: translate(-340.451px, -83.5px) matrix(1,0,0,1,496.9684,186.4693) rotate(-11deg);
            transform: translate(-340.451px, -83.5px) matrix(1,0,0,1,496.9684,186.4693) rotate(-11deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 10px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 414px;
    height: 224px;
    text-align: left;
    font-family: Comic Sans MS;
    font-style: normal;
    font-weight: bold;
    font-size: 95px;
    color: rgba(87,170,130,1);
    text-transform: uppercase;
}
#COMING_front {
    -webkit-transform: translate(-340.451px, -83.5px) matrix(1,0,0,1,495.9684,174.4693) rotate(-11deg);
            transform: translate(-340.451px, -83.5px) matrix(1,0,0,1,495.9684,174.4693) rotate(-11deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 10px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 414px;
    height: 224px;
    text-align: left;
    font-family: Comic Sans MS;
    font-style: normal;
    font-weight: bold;
    font-size: 95px;
    color: rgba(255,255,255,1);
    text-transform: uppercase;
}
#speaker_1 {
    position: absolute;
    width: 152.187px;
    height: 129.383px;
    left: 0px;
    top: 180.222px;
    overflow: visible;
}
#back_speaker {
    fill: rgba(255,165,0,1);
}
.back_speaker {
    -webkit-filter: drop-shadow(0px 5px 6px rgba(255, 165, 0, 1));
            filter: drop-shadow(0px 5px 6px rgba(255, 165, 0, 1));
    overflow: visible;
    position: absolute;
    width: 153.607px;
    height: 138.486px;
    left: 0px;
    top: 8.896px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#front_speak {
    fill: rgba(255,165,0,1);
}
.front_speak {
    -webkit-filter: drop-shadow(0px 5px 6px rgba(255, 165, 0, 1));
            filter: drop-shadow(0px 5px 6px rgba(255, 165, 0, 1));
    overflow: visible;
    position: absolute;
    width: 52.246px;
    height: 146.485px;
    left: 117.941px;
    top: 0px;
    -webkit-transform: matrix(1,0,0,1,0,0);
            transform: matrix(1,0,0,1,0,0);
}
#Stay_tuned__back {
    -webkit-filter: blur(1px);
            filter: blur(1px);
    -webkit-transform: translate(-340.451px, -83.5px) matrix(1,0,0,1,654.6541,393.259) rotate(-11deg);
            transform: translate(-340.451px, -83.5px) matrix(1,0,0,1,654.6541,393.259) rotate(-11deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 50px;
    position: absolute;
    overflow: visible;
    width: 135px;
    white-space: nowrap;
    line-height: 33px;
    margin-top: -7.5px;
    text-align: left;
    font-family: Comic Sans MS;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(91,222,91,1);
    text-transform: uppercase;
}
#Stay_tuned__front_ {
    -webkit-transform: translate(-340.451px, -83.5px) matrix(1,0,0,1,654.6541,390.259) rotate(-11deg);
            transform: translate(-340.451px, -83.5px) matrix(1,0,0,1,654.6541,390.259) rotate(-11deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    left: 0px;
    top: 50px;
    position: absolute;
    overflow: visible;
    width: 135px;
    white-space: nowrap;
    line-height: 33px;
    margin-top: -7.5px;
    text-align: left;
    font-family: Comic Sans MS;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(66,178,66,1);
    text-transform: uppercase;
}
	.mediaViewInfo {
		--web-view-name: website 1 pc;
		--web-view-id: website_1_pc;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: website_1_pc;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#website_1_pc {
		position: absolute;
		width: 1366px;
		height: 3917px;
        left:calc((100vw - 1366px)/2);
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: website 1 pc;
		--web-view-id: website_1_pc;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#top_banner_bg_website_1_op_liv {
		position: absolute;
		width: 1366px;
		height: 226px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#top_menu_bg_desgin_website_1_o {
		fill: rgba(143,233,191,1);
	}
	.top_menu_bg_desgin_website_1_o {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 69px;
		left: 0px;
		top: 226px;
	}
	#top_meny_btn_website_1_op_live {
		position: absolute;
		width: 479px;
		height: 27px;
		left: 444px;
		top: 247px;
		overflow: visible;
	}
	#Intro_txt_website_1_op_live_th {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#intro_img_website_1_op_live_th {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#Offerings_txt_website_1_op_liv {
		left: 137px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#discount_img_website_1_op_live {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 112px;
		top: 4px;
		overflow: visible;
	}
	#Events_txt_website_1_op_live_t {
		left: 284px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 58px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#event_img_website_1_op_live_th {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 259px;
		top: 4px;
		overflow: visible;
	}
	#Contact_txt_website_1_op_live_ {
		left: 411px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#contact_img_website_1_op_live_ {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 386px;
		top: 4px;
		overflow: visible;
	}
	#top_overall_grp_website_1_op_l {
		position: absolute;
		width: 1033px;
		height: 623px;
		left: 121px;
		top: 337px;
		overflow: visible;
	}
	#pfp_pic_vwebsite_1_op_live_the {
		position: absolute;
		width: 233px;
		height: 233px;
		left: 0px;
		top: 0px;
		border-radius: 50%;
		overflow: hidden;
	}
	#top_name_website_1_op_live_the {
		left: 259px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 293px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		color: rgba(0,0,0,1);
	}
	#Scroll_intro_web_1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 785px;
		height: 148px;
		left: 248px;
		top: 85px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#Lorem_ipsum_dolor_sit_amet_em_pc {
		
	    position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
        left: 10px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#mission_grp_web_1 {
		position: absolute;
		width: 290px;
		height: 397px;
		left: 73px;
		top: 291px;
		overflow: visible;
	}
	#mission_card_ {
		fill: rgba(143,214,233,1);
	}
	.mission_card_ {
		position: absolute;
		overflow: visible;
		width: 290px;
		height: 397px;
		left: 0px;
		top: 0px;
	}
	#box_1_website_1_op_live_theme {
		position: absolute;
		width: 69px;
		height: 59px;
		left: 110px;
		top: 55px;
		overflow: visible;
	}
	#mission_pc_web1 {
		left: 99px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Scroll_mission_details_web_1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 270px;
		height: 252px;
		left: 20px;
		top: 145px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Lorem_ipsum_dolor_sit_amet_con_ca {
	
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#vision_grp_web_1 {
		position: absolute;
		width: 290px;
		height: 397px;
		left: 398px;
		top: 291px;
		overflow: visible;
	}
	#vision_card {
		fill: rgba(143,233,191,1);
	}
	.vision_card {
		position: absolute;
		overflow: visible;
		width: 290px;
		height: 397px;
		left: 0px;
		top: 0px;
	}
	#box_2_website_1_op_live_theme {
		position: absolute;
		width: 70px;
		height: 59px;
		left: 112px;
		top: 55px;
		overflow: visible;
	}
	#top_offerings_ce {
		left: 109px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Scroll_vision_details_web_1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 265px;
		height: 252px;
		left: 26px;
		top: 145px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Lorem_ipsum_dolor_sit_amet_con_cg {
		
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#princi_grp_web_1 {
		position: absolute;
		width: 290px;
		height: 397px;
		left: 729px;
		top: 291px;
		overflow: visible;
	}
	#principle_mission_card_ {
		fill: rgba(215,232,188,1);
	}
	.principle_mission_card_ {
		position: absolute;
		overflow: visible;
		width: 290px;
		height: 397px;
		left: 0px;
		top: 0px;
	}
	#box_3_off_website_1_op_live_th {
		position: absolute;
		width: 70px;
		height: 59px;
		left: 110px;
		top: 55px;
		overflow: visible;
	}
	#top_offerings_ck_pc {
		left: 88px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 115px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Scroll_prrinci_details_web_1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 265px;
		height: 252px;
		left: 26px;
		top: 145px;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	#Lorem_ipsum_dolor_sit_am_principle_pc {
		
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Mohammed_Affan_Ghani_lbl_websi {
		left: 527px;
		top: 1079px;
		position: absolute;
		overflow: visible;
		width: 314px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		color: rgba(0,0,0,1);
	}
	#partner_bg_website_1_op_live_t {
		fill: transparent;
	}
	.partner_bg_website_1_op_live_t {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 130px;
		left: 0px;
		top: 1152px;
		background:  rgba( 255, 255, 255, 0.5 );
		box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
		backdrop-filter:  blur( 10px );
		-webkit-backdrop-filter:  blur( 10px );
		border-radius:  10px;
		border:  1px solid rgba( 255, 255, 255, 0.18 );
	}
	#Scroll_partner_web1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1082px;
		height: 83px;
		left: 143px;
		top: 1190px;
		display: inline-flex;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#partner_lbls_grp {
		position: relative;
		width: 175px;
		height: 27px;
		left: 13px;
		top: 14px;
		overflow: visible;
	}
	#google_lbl_partner {
		left: 30px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#google_1_website_1 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}

	#Scroll_my_offering_web_1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1366px;
		height: 1249px;
		left: 0px;
		top: 1413px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Recent_Work__website_1_op_live {
		position: absolute;
		width: 1005px;
		height: 610.5px;
		left: 180px;
		top: 29.5px;
		overflow: visible;
	}
	#first_offering_grp_web_1 {
		position: relative;
		width: 475px;
		height: 555.5px;
		left: 0px;
		top: 55px;
		overflow: visible;
	}
	#Project1_website_1_op_live_the {
		position: absolute;
		width: 475px;
		height: 531px;
		left: 0px;
		top: 24.5px;
		overflow: visible;
	}
	#Offer_1_web_1 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 476px;
		height: 21px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#second_offering_grp_web_1 {
		position: relative;
		width: 475px;
		height: 540.5px;
		left: 530px;
		top: 0px;
		overflow: visible;
	}
	#Project2_website_1_op_live_the {
		position: absolute;
		width: 475px;
		height: 517px;
		left: 0px;
		top: 23.5px;
		overflow: visible;
	}
	#Offer_2_web_1 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 476px;
		height: 21px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}


	#next_to_circle_website_1_op_li {
		fill: rgba(143,233,191,1);
	}
	.next_to_circle_website_1_op_li {
		overflow: visible;
		position: absolute;
		width: 832.848px;
		height: 876.211px;
		-webkit-transform: matrix(1,0,0,1,905.4981,2388.4336) rotate(-8deg);
		        transform: matrix(1,0,0,1,905.4981,2388.4336) rotate(-8deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#disign_bg_circle_website_1_op_ {
		fill: rgba(143,233,191,1);
	}
	.disign_bg_circle_website_1_op_ {
		width: 530px;
		height: 522px;
		position: absolute;
		overflow: visible;
		-webkit-transform: matrix(1,0,0,1,783.4053,2803.288) rotate(-5deg);
		        transform: matrix(1,0,0,1,783.4053,2803.288) rotate(-5deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#Colonel_gravity_get_thought_fa {
		left: 180px;
		top: 1391.5px;
		position: absolute;
		overflow: visible;
		width: 471px;
		height: 21px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#Best_Real_Estate_Deals_website {
		left: 180px;
		top: 1333.5px;
		position: absolute;
		overflow: visible;
		width: 179px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#Illustration_website_1 {
		position: absolute;
		width: 479.938px;
		height: 276.261px;
		left: 835.849px;
		top: 2864.112px;
		overflow: visible;
	}
	#table_website_1 {
		fill: rgba(69,74,105,1);
	}
	.table_website_1 {
		overflow: visible;
		position: absolute;
		width: 479.938px;
		height: 28.019px;
		left: 0px;
		top: 248.242px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#hair_back_website_1 {
		fill: rgba(69,74,105,1);
	}
	.hair_back_website_1 {
		overflow: visible;
		position: absolute;
		width: 120.639px;
		height: 142.913px;
		left: 188.618px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#neck_website_1_ {
		fill: rgba(238,183,184,1);
	}
	.neck_website_1_ {
		overflow: visible;
		position: absolute;
		width: 86.157px;
		height: 116.892px;
		left: 203.783px;
		top: 79.69px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#shirt_colar_website_1 {
		fill: rgba(255,255,255,1);
	}
	.shirt_colar_website_1 {
		overflow: visible;
		position: absolute;
		width: 106.372px;
		height: 107.951px;
		left: 198.297px;
		top: 111.005px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#shirt_website_1_ {
		fill: rgba(223,132,126,1);
	}
	.shirt_website_1_ {
		overflow: visible;
		position: absolute;
		width: 162.586px;
		height: 138.027px;
		left: 167.865px;
		top: 109.94px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#face_website_1 {
		fill: rgba(238,183,184,1);
	}
	.face_website_1 {
		overflow: visible;
		position: absolute;
		width: 72.325px;
		height: 77.388px;
		left: 212.638px;
		top: 27.82px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#front_hair_website_1_ {
		fill: rgba(69,74,105,1);
	}
	.front_hair_website_1_ {
		overflow: visible;
		position: absolute;
		width: 95.989px;
		height: 127.309px;
		left: 201.172px;
		top: 9.646px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#left_colar_website_1 {
		fill: rgba(255,255,255,1);
	}
	.left_colar_website_1 {
		overflow: visible;
		position: absolute;
		width: 34.201px;
		height: 31.493px;
		left: 251.06px;
		top: 223.648px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#left_hand_arm_website_1 {
		fill: rgba(223,132,126,1);
	}
	.left_hand_arm_website_1 {
		overflow: visible;
		position: absolute;
		width: 84.272px;
		height: 103.44px;
		left: 275.326px;
		top: 149.509px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#right_hand_top_website_1_ {
		fill: rgba(238,183,184,1);
	}
	.right_hand_top_website_1_ {
		overflow: visible;
		position: absolute;
		width: 42.145px;
		height: 75.85px;
		left: 175.122px;
		top: 73.988px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#right_arm_collar_website_1 {
		fill: rgba(255,255,255,1);
	}
	.right_arm_collar_website_1 {
		overflow: visible;
		position: absolute;
		width: 29.737px;
		height: 21.604px;
		left: 174.961px;
		top: 138.75px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#right_arm_website_1_ {
		fill: rgba(223,132,126,1);
	}
	.right_arm_website_1_ {
		overflow: visible;
		position: absolute;
		width: 43.516px;
		height: 103.593px;
		left: 163.26px;
		top: 152.275px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#down_hand_website_1_ {
		fill: rgba(238,183,184,1);
	}
	.down_hand_website_1_ {
		overflow: visible;
		position: absolute;
		width: 86.431px;
		height: 32.634px;
		left: 177.425px;
		top: 229.887px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#steam_3_website_1_ {
		fill: rgba(62,60,85,1);
	}
	.steam_3_website_1_ {
		overflow: visible;
		position: absolute;
		width: 4.876px;
		height: 6.834px;
		left: 59.648px;
		top: 236.619px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#stem_2_pot_website_1_ {
		fill: rgba(62,60,85,1);
	}
	.stem_2_pot_website_1_ {
		overflow: visible;
		position: absolute;
		width: 4.876px;
		height: 6.834px;
		left: 59.648px;
		top: 232.438px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#stem_pot_1_website_1_ {
		fill: rgba(62,60,85,1);
	}
	.stem_pot_1_website_1_ {
		overflow: visible;
		position: absolute;
		width: 4.876px;
		height: 6.834px;
		left: 59.648px;
		top: 228.263px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leave_design_website_1_ {
		fill: rgba(56,210,159,1);
	}
	.pot_1_leave_design_website_1_ {
		overflow: visible;
		position: absolute;
		width: 23.394px;
		height: 55.971px;
		left: 50.353px;
		top: 170.261px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_design_website_1_ {
		fill: rgba(243,123,123,1);
		stroke: rgba(243,123,123,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.pot_1_design_website_1_ {
		overflow: visible;
		position: absolute;
		width: 45.688px;
		height: 26.737px;
		left: 40.015px;
		top: 238.875px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#laptop_website_1_ {
		fill: rgba(62,60,85,1);
	}
	.laptop_website_1_ {
		overflow: visible;
		position: absolute;
		width: 113.087px;
		height: 81.014px;
		left: 325.945px;
		top: 186.902px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Connect__Follow_Us__website_pc_ {
		left: 173px;
		top: 2972.677px;
		position: absolute;
		overflow: visible;
		width: 324px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 34px;
		color: rgba(54,54,54,1);
	}
	#footer_overll_grp_web_1 {
		position: absolute;
		width: 1374px;
		height: 489px;
		left: 0px;
		top: 3434px;
		overflow: visible;
	}
	#footer_bdr_web1_da {
		fill: url(#footer_bdr_web1_da);
	}
	.footer_bdr_web1_da {
		overflow: visible;
		position: absolute;
		width: 1374px;
		height: 489px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_234567890_mobile_number_webs_em_pc {
		left: 191px;
		top: 57px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#affanghanioperatelive_email_we_pc {
		left: 192px;
		top: 110.799px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#address_website_1 {
		left: 187px;
		top: 164.661px;
		position: absolute;
		overflow: visible;
		width: 449px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#location_img_website_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 143px;
		top: 164.699px;
		overflow: visible;
	}
	#email_address_website_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 143px;
		top: 110.798px;
		overflow: visible;
	}
	#phone_number_website_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 143px;
		top: 57px;
		overflow: visible;
	}
	#quote_left_webstie_1 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 813.704px;
		top: 265.495px;
		overflow: visible;
	}
	#quote_right_website_1 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 1016.993px;
		top: 293.444px;
		overflow: visible;
	}
	#Discover_a_World_of_Endless_Po {
		left: 852px;
		top: 264.995px;
		position: absolute;
		overflow: visible;
		width: 386px;
		height: 54px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#bottom_line_website_1_op_live_ {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.bottom_line_website_1_op_live_ {
		overflow: visible;
		position: absolute;
		width: 1172.981px;
		height: 1px;
		left: 100px;
		top: 372.86px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_Copyright_Operate_Live_websi_em {
		left: 139.5px;
		top: 419.161px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#socials_grp_web_1 {
		position: absolute;
		width: 255px;
		height: 65px;
		left: 207px;
		top: 3047.586px;
		overflow: visible;
	}
	#linked_in_grp {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 190px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_5_web1:hover {
		fill: rgba(120,120,255,1);
	}
	.Ellipse_5_web1:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_5_web1 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_5_web1 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_website_1_layout {
		position: absolute;
		width: 31.653px;
		height: 28.776px;
		left: 17.063px;
		top: 17.875px;
		overflow: visible;
	}
	#n_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.n_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 20.919px;
		height: 19.736px;
		left: 10.734px;
		top: 9.04px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_down {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_down {
		overflow: visible;
		position: absolute;
		width: 6.169px;
		height: 19.549px;
		left: 0.798px;
		top: 9.227px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 7.489px;
		height: 6.783px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_ {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 95px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_4_web1:hover {
		fill: rgba(255,120,120,1);
	}
	.Ellipse_4_web1:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_4_web1 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_4_web1 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagram_website_1_layout {
		position: absolute;
		width: 31.688px;
		height: 31.688px;
		left: 17.063px;
		top: 17.063px;
		overflow: visible;
	}
	#whatsapp_grp {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_3_web1:hover {
		fill: rgba(120,255,165,1);
	}
	.Ellipse_3_web1:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_3_web1 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_3_web1 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_website_1_layout {
		position: absolute;
		width: 32.5px;
		height: 32.5px;
		left: 16.25px;
		cursor: pointer;
		top: 16.25px;
		overflow: visible;
	}
	#map_em_pc_new {
		fill: rgba(255,255,255,1);
	}
	.map_em_pc_new {
		position: absolute;
		overflow: visible;
		width: 452px;
		height: 205px;
		left: 797px;
		top: 3473px;
	}

	.mediaViewInfo {
		--web-view-name: Website 4;
		--web-view-id: Website_4;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Website_4;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#Website_4 {
		position: absolute;
		width: 1366px;
		height: 2935px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Website 4;
		--web-view-id: Website_4;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#princi_desgin_bdr_med_web_4_pc {
		fill: rgba(187,177,224,1);
	}
	.princi_desgin_bdr_med_web_4_pc {
		position: absolute;
		overflow: visible;
		width: 246px;
		height: 392px;
		left: 890px;
		top: 896px;
	}
	#vision_desgin_bdr_med_web_4_pc {
		fill: rgba(135,207,172,0.647);
	}
	.vision_desgin_bdr_med_web_4_pc {
		position: absolute;
		overflow: visible;
		width: 246px;
		height: 392px;
		left: 570px;
		top: 896px;
	}
	#mission_desgin_bdr_med_web_4_p {
		fill: rgba(166,210,202,0.651);
	}
	.mission_desgin_bdr_med_web_4_p {
		position: absolute;
		overflow: visible;
		width: 246px;
		height: 392px;
		left: 250px;
		top: 896px;
	}
	#design_main_page {
		fill: rgba(32,142,121,1);
	}
	.design_main_page {
		overflow: visible;
		position: absolute;
		width: 965.786px;
		height: 759.197px;
		left: 401.037px;
		top: 1.803px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Heading_left_med_web_4_pc {
		left: 155px;
		top: 248px;
		position: absolute;
		overflow: visible;
		width: 428px;
		height: 72px;
		line-height: 73px;
		margin-top: -9.5px;
		text-align: right;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 54px;
		color: rgba(0,0,0,1);
	}
	#menu_grp_med_web_4_pc {
		position: absolute;
		width: 348px;
		height: 27px;
		left: 509px;
		top: 55px;
		overflow: visible;
	}
	#home_med_web_4_pc {
		position: absolute;
		width: 79px;
		height: 27px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Homemed_web_4_pc {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#home-icon-silhouette_med_web_4 {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#offering_med_web_4_pc {
		position: absolute;
		width: 106px;
		height: 27px;
		left: 114px;
		top: 0px;
		overflow: visible;
	}
	#Offerings_med_web_4_pc {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#discount_med_web_4_pc {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#contact_grp_med_web_4_pc {
		position: absolute;
		width: 93px;
		height: 27px;
		left: 255px;
		top: 0px;
		overflow: visible;
	}
	#Contact_lbl_med_web_4_pc {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#contact_img_med_web_4_pc {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#Heading_med_web_4_pc {
		left: 656px;
		top: 248px;
		position: absolute;
		overflow: visible;
		width: 382px;
		height: 72px;
		line-height: 73px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 54px;
		color: rgba(0,0,0,1);
	}
	#markus-wink_img_centre_med_web {
		position: absolute;
		width: 584.558px;
		height: 297.121px;
		left: 391px;
		top: 363.824px;
		overflow: visible;
	}
	#Scroll_intro_med_web_4_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 382px;
		height: 213px;
		left: 92px;
		top: 448px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_intro_med_web_4_pc::-webkit-scrollbar{
		display: none;
	}
	#Lorem_ipsum_dolor_sit_amet_hh_pc {
		left: 10px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 365px;
		height: 206px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#text_our_partner_bdr_med_web_4 {
		-webkit-transform: matrix(1,0,0,1,1168,290) rotate(90deg);
		        transform: matrix(1,0,0,1,1168,290) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 181px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.1px;
	}
	#Scroll_scroll_bdr_med_web_4_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 305px;
		height: 546px;
		left: 1174px;
		top: 205px;
      
		overflow-x: hidden;
		overflow-y: auto;
	}
	#g_and_google_grp {
		position: relative;
		width: 27px;
		height: 175px;
		left: 9px;
		top: 10px;
		overflow: visible;
	}
	#google_lbl_partnermed_web_4_pc {
		-webkit-transform: translate(-1182px, -274px) matrix(1,0,0,1,1123,363) rotate(90deg);
		        transform: translate(-1182px, -274px) matrix(1,0,0,1,1123,363) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(233,255,251,1);
	}
	#google_1img_med_web_4_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 1px;
		top: 0px;
		overflow: visible;
		-webkit-transform: rotate(90deg);
		        transform: rotate(90deg);
	}
	#marijuana_img_bdr_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 594px;
		top: 259px;
		overflow: visible;
	}
	#follow_us_btn__grp_web_4_pc:hover {
		width: 209px;
		height: 43px;
		left: 762px;
		top: 612px;
	}
	#follow_us_btn__grp_web_4_pc {
		position: absolute;
		width: 200px;
		height: 41px;
		left: 766px;
		top: 615px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#bdr_med_web_4_pc_cl {
		fill: rgba(92,181,164,1);
	}
	.bdr_med_web_4_pc_cl {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(223, 223, 223, 1));
		        filter: drop-shadow(5px 5px 2px rgba(223, 223, 223, 1));
		position: absolute;
		overflow: visible;
		width: 208px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#Follow_Us_med_web_4_pc_cm {
		left: 69px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 64px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#arrow_follow_us_med_web_4_pc_cn {
		fill: rgba(255,255,255,1);
	}
	.arrow_follow_us_med_web_4_pc_cn {
		overflow: visible;
		position: absolute;
		width: 20px;
		height: 19.494px;
		left: 142px;
		top: 11.002px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#all_grp_mission_vision_princi {
		position: absolute;
		width: 907px;
		height: 412px;
		left: 250px;
		top: 876px;
		overflow: visible;
	}
	#mission_all_med_web_4_pc:hover {
		left: 20px;
		top: 0px;
	}
	#mission_all_med_web_4_pc {
		position: absolute;
		width: 247px;
		height: 392px;
		left: 0px;
		top: 20px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#bdr_mission_med_web_4_pc {
		fill: rgba(166,210,202,0.651);
	}
	.bdr_mission_med_web_4_pc {
		position: absolute;
		overflow: visible;
		width: 247px;
		height: 392px;
		left: 0px;
		top: 0px;
	}
	#box_1_website_med_web_4_pc {
		position: absolute;
		width: 69px;
		height: 59px;
		left: 95px;
		top: 57px;
		overflow: visible;
	}
	#mission_lbl_med_web_4_pc {
		left: 84px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Scroll_mission_med_web_4_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 238px;
		height: 252px;
		left: 5px;
		top: 135px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_mission_med_web_4_pc::-webkit-scrollbar{
		display: none;
	}
	#Lorem_ipsum_dolor_sit_amet_con_c {
		left: 15px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 224px;
		height: 264px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#vision_grp_all_med_web_4_pc:hover {
		left: 340px;
		top: 0px;
	}
	#vision_grp_all_med_web_4_pc {
		position: absolute;
		width: 247px;
		height: 392px;
		left: 320px;
		top: 20px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#bdr_vision_back_med_web_4_pc {
		fill: rgba(135,207,172,0.647);
	}
	.bdr_vision_back_med_web_4_pc {
		position: absolute;
		overflow: visible;
		width: 247px;
		height: 392px;
		left: 0px;
		top: 0px;
	}
	#box_2_website_1_med_web_4_pc {
		position: absolute;
		width: 70px;
		height: 59px;
		left: 91px;
		top: 57px;
		overflow: visible;
	}
	#vision_lbl_med_web_4_pc {
		left: 88px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Scroll_vision_med_web_4_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 238px;
		height: 252px;
		left: 5px;
		top: 135px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_vision_med_web_4_pc::-webkit-scrollbar{
		display: none;
	}
	#Lorem_ipsum_dolor_sit_amet_con_dc {
		left: 15px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 224px;
		height: 264px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#principle_grp_all_med_web_4_pc:hover {
		left: 660px;
		top: 0px;
	}
	#principle_grp_all_med_web_4_pc {
		position: absolute;
		width: 247px;
		height: 392px;
		left: 640px;
		top: 20px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#princi_bdr_akll_med_web_4_pc {
		fill: rgba(151,135,207,0.647);
	}
	.princi_bdr_akll_med_web_4_pc {
		position: absolute;
		overflow: visible;
		width: 247px;
		height: 392px;
		left: 0px;
		top: 0px;
	}
	#box_3_off_med_web_4_pc_op_live {
		position: absolute;
		width: 70px;
		height: 59px;
		left: 89px;
		top: 57px;
		overflow: visible;
	}
	#princi_med_web_4_pc_lbl {
		left: 67px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 115px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Scroll_prrinci_details_med_web {
		mix-blend-mode: normal;
		position: absolute;
		width: 238px;
		height: 252px;
		left: 5px;
		top: 135px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_prrinci_details_med_web::-webkit-scrollbar{
		display: none;
	}
	#Lorem_ipsum_dolor_sit_amet_con_do {
		left: 15px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 224px;
		height: 264px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#oofering_lbl_med_web_4_pc {
		left: 155px;
		top: 1400px;
		position: absolute;
		overflow: visible;
		width: 179px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#Scroll_offering_med_web_4_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 1152px;
		height: 411px;
		left: 127px;
		top: 1484px;
        display: -webkit-box;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#Scroll_offering_med_web_4_pc::-webkit-scrollbar{
		display: none;
	}
	#offering_crd_1_grp_med_web_4_p {
		position: relative;
		width: 200px;
		height: 332px;
		margin: 20px;
		top: 11px;
		overflow: visible;
	}
	#title_bdr_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.title_bdr_med_web_4_pc {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 190px;
		height: 50px;
		left: 14px;
		top: 300px;
	}
	#BiziBees_PVT_LTD_med_web_4_pc {
		left: 14px;
		top: 306px;
		position: absolute;
		overflow: visible;
		width: 173px;
		height: 20px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#img_border_med_web_4_pc_of {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 300px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chuttersna_med_web_4_pc_img {
		position: absolute;
		width: 172px;
		height: 277px;
		left: 14px;
		top: 7px;
		overflow: visible;
	}
	#map_area_med_web_4_pc {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.map_area_med_web_4_pc {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 330px;
		left: 0px;
		top: 2127px;
	}
	#map_top_med_web_4_pc {
		fill: rgba(32,142,121,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.map_top_med_web_4_pc {
		overflow: visible;
		position: absolute;
		width: 1396.902px;
		height: 23px;
		left: 0.098px;
		top: 2104px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#map_below_desion_med_web_4_pc {
		fill: rgba(32,142,121,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.map_below_desion_med_web_4_pc {
		overflow: visible;
		position: absolute;
		width: 1366px;
		height: 23px;
		left: 0.098px;
		top: 2457px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#footer_med_web_4_pc {
		fill: rgba(149,213,182,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.footer_med_web_4_pc {
		position: absolute;
		overflow: visible;
		width: 1367px;
		height: 299px;
		left: 0px;
		top: 2641px;
	}
	#n_234567890_mobile_number_webs {
		left: 85px;
		top: 2664px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#affanghanioperatelive_email_we {
		left: 86px;
		top: 2718px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#address_med_web_4_pc {
		left: 81px;
		top: 2772px;
		position: absolute;
		overflow: visible;
		width: 373px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Connect_with_us_and_unlock_lim_hh_pc {
		left: 1034px;
		top: 2823px;
		position: absolute;
		overflow: visible;
		width: 271px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#location_img_med_web_4_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 37px;
		top: 2772px;
		overflow: visible;
	}
	#email_address_med_web_4_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 37px;
		top: 2718px;
		overflow: visible;
	}
	#phone_number_med_web_4_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 37px;
		top: 2664px;
		overflow: visible;
	}
	#quote_left_med_web_4_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 994px;
		top: 2823px;
		overflow: visible;
	}
	#quote_right_med_web_4_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 1184px;
		top: 2880px;
		overflow: visible;
	}
	#n_Copyright_Operate_Live_med_w {
		left: 37px;
		top: 2882.25px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#socials_grp_med_web_4_pc {
		position: absolute;
		width: 255px;
		height: 65px;
		left: 1042px;
		top: 2712px;
		overflow: visible;
	}
	#linked_in_grp_med_web_4_pc {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 190px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_5_med_web_4_pc:hover {
		fill: rgba(120,120,255,1);
	}
	.Ellipse_5_med_web_4_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_5_med_web_4_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_5_med_web_4_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_website_4_layout {
		position: absolute;
		width: 31.653px;
		height: 28.776px;
		left: 17.063px;
		top: 17.875px;
		overflow: visible;
	}
	#n_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.n_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 20.919px;
		height: 19.736px;
		left: 10.734px;
		top: 9.041px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_down {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_down {
		overflow: visible;
		position: absolute;
		width: 6.169px;
		height: 19.549px;
		left: 0.798px;
		top: 9.227px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 7.489px;
		height: 6.783px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_med_web_4_pc {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 95px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_4_med_web_4_pc:hover {
		fill: rgba(255,120,120,1);
	}
	.Ellipse_4_med_web_4_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_4_med_web_4_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_4_med_web_4_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagram_website_4_layout {
		position: absolute;
		width: 31.688px;
		height: 31.688px;
		left: 17.063px;
		top: 17.063px;
		overflow: visible;
	}
	#whatsapp_grp_med_web_4_pc {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_3_med_web_4_pc:hover {
		fill: rgba(50,172,158,1);
	}
	.Ellipse_3_med_web_4_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_3_med_web_4_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_3_med_web_4_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_website4_layout {
		position: absolute;
		width: 32.5px;
		height: 32.5px;
		left: 16.25px;
		top: 16.25px;
		overflow: visible;
	}
	#medincine_bdr_web_4_pc {
		fill: rgba(32,142,121,1);
	}
	.medincine_bdr_web_4_pc {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 70px;
		left: -1px;
		top: 2572px;
	}
	#Medicine_is_not_only_a_science {
		left: 0px;
		top: 2588px;
		position: absolute;
		overflow: visible;
		width: 2383px;
		height: 35px;
		text-align: center;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(255,255,255,1);
	}
	#profile_pic_logo_web_4_pc {
		position: absolute;
		width: 100px;
		height: 100px;
		left: 123px;
		top: 55px;
        border-radius: 50%;
		overflow: hidden;
	}
	@-webkit-keyframes breath {
  from {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }

  to {
    -webkit-transform: scale(1.25) translateY(-5px) perspective(1px);
            transform: scale(1.25) translateY(-5px) perspective(1px);
    text-shadow: 0 0 40px var(--glow-color);
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
}
	@keyframes breath {
  from {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }

  to {
    -webkit-transform: scale(1.25) translateY(-5px) perspective(1px);
            transform: scale(1.25) translateY(-5px) perspective(1px);
    text-shadow: 0 0 40px var(--glow-color);
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
}

@media (prefers-reduced-motion:no-preference) {
  [letter-animation] > span {
    display: inline-block;
    white-space: break-spaces;
  }

  [letter-animation=breath] {
    --glow-color: white;
  }

  [letter-animation=breath]>span {
    -webkit-animation: breath 1.2s ease calc(var(--index) * 100 * 1ms) infinite alternate;
            animation: breath 1.2s ease calc(var(--index) * 100 * 1ms) infinite alternate;
  }
}

@media (prefers-reduced-motion:no-preference) and (prefers-color-scheme: light) {
  [letter-animation=breath] {
    --glow-color: black;
  }
}

	.mediaViewInfo {
		--web-view-name: websie 5 ShadowScape;
		--web-view-id: websie_5_ShadowScape;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: websie_5_ShadowScape;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#Shadow_Space_PC_websie_5_ShadowScape {
		position: absolute;
		width: 1366px;
		height: 2701px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(0,0,0,0.89);
		overflow: hidden;
		--web-view-name: websie 5 ShadowScape;
		--web-view-id: websie_5_ShadowScape;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#Shadow_Space_PC_n_th_shaodwow_spave_web_5 {
		-webkit-filter: blur(50px);
		        filter: blur(50px);
		fill: rgba(255,255,255,0.149);
	}
	.Shadow_Space_PC_n_th_shaodwow_spave_web_5 {
		overflow: visible;
		position: absolute;
		width: 1915.849px;
		height: 1373.621px;
		left: -81.175px;
		top: 1066px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_n_rd_shaodwow_spave_web_5 {
		-webkit-filter: blur(50px);
		        filter: blur(50px);
		fill: rgba(255,255,255,0.149);
	}
	.Shadow_Space_PC_n_rd_shaodwow_spave_web_5 {
		overflow: visible;
		position: absolute;
		width: 1885.334px;
		height: 1403.135px;
		left: 229.69px;
		top: 1818.197px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_n_nshaodwow_spave_web_5 {
		-webkit-filter: blur(50px);
		        filter: blur(50px);
		fill: rgba(255,255,255,0.149);
	}
	.Shadow_Space_PC_n_nshaodwow_spave_web_5 {
		overflow: visible;
		position: absolute;
		width: 1488.57px;
		height: 1258.381px;
		left: -63.82px;
		top: 395.246px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_first_shaodwow_spave_web_5 {
		-webkit-filter: blur(50px);
		        filter: blur(50px);
		fill: rgba(255,255,255,0.149);
	}
	.Shadow_Space_PC_first_shaodwow_spave_web_5 {
		overflow: visible;
		position: absolute;
		width: 1075.541px;
		height: 929.563px;
		left: -92.541px;
		top: -106.562px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_Scroll_comp_intro_shaodwow_spa {
		mix-blend-mode: normal;
		position: absolute;
		width: 605px;
		height: 209px;
		left: 58px;
		top: 367px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Shadow_Space_PC_Scroll_comp_intro_shaodwow_spa::-webkit-scrollbar{
		display: none;
	}
	#Shadow_Space_PC_intro_lbl_shaodwow_spave_web_5 {
		left: 7px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 537px;
		height: 207px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.1px;
	}
	#Shadow_Space_PC_Heading_shaodwow_spave_web_5 {
		left: 65px;
		top: 198px;
		position: absolute;
		overflow: visible;
		width: 537px;
		height: 145px;
		line-height: 73px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 54px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_profile_pic_logo_shadow_space_ {
		position: absolute;
		width: 100px;
		height: 100px;
		left: 65px;
		top: 28px;
        border-radius: 50%;
		overflow: hidden;
	}
	#Shadow_Space_PC_Partners_grp_shadow_space_webs {
		position: absolute;
		width: 614px;
		height: 104px;
		left: 58px;
		top: 646px;
		overflow: visible;
	}
	#Shadow_Space_PC_text_our_partnersshadow_space_ {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 181px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.1px;
	}
	#Shadow_Space_PC_Scroll_partner_shadow_space_we {
		mix-blend-mode: normal;
		position: absolute;
		width: 100vw;
		height: 65px;
		left: 0px;
		top: 39px;
        display: inline-flex;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#Shadow_Space_PC_Scroll_partner_shadow_space_we::-webkit-scrollbar{
		display: none;
	}
	#Shadow_Space_PC_partner_lbls_shadow_space_webs {
		position: relative;
		width: 175px;
		height: 27px;
		left: 26px;
		top: 7px;
		overflow: visible;
	}
	#Shadow_Space_PC_google_lbl_partner_shadow_spac {
		left: 30px;
		top: 0px;
		position: absolute;
		overflow: hidden;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_google_1_shadow_space_website_ {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#Shadow_Space_PC_top_right_imgs_shadow_space_we {
		position: absolute;
		width: 527px;
		height: 559px;
		left: 839px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_right_gold_shadow_space_websit {
		fill: rgba(227,168,87,1);
	}
	.Shadow_Space_PC_right_gold_shadow_space_websit {
		position: absolute;
		overflow: visible;
		width: 392px;
		height: 441px;
		left: 135px;
		top: 0px;
	}
	#Shadow_Space_PC_down_white_shadow_space_websit {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Shadow_Space_PC_down_white_shadow_space_websit {
		position: absolute;
		overflow: visible;
		width: 392px;
		height: 441px;
		left: 0px;
		top: 118px;
	}
	#Shadow_Space_PC_damon-shadow_space_website_5 {
		-webkit-filter: blur(0px);
		        filter: blur(0px);
		position: absolute;
		width: 393px;
		height: 441px;
		left: 67px;
		top: 59px;
		overflow: visible;
	}
	#Shadow_Space_PC_what_we_stand_for_shadow_space {
		left: 501px;
		top: 913px;
		position: absolute;
		overflow: visible;
		width: 365px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_Scrollprinciple_shadow_space_w {
		mix-blend-mode: normal;
		position: absolute;
		width: 260px;
		height: 150px;
		left: 994.75px;
		top: 1090px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Shadow_Space_PC_Scrollprinciple_shadow_space_w::-webkit-scrollbar{
		display: none;
	}
	#Shadow_Space_PC_intro_lbl_website_3_ss_pc {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 148px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_principles_lbl_shadow_space_we {
		left: 1075.25px;
		top: 1041px;
		position: absolute;
		overflow: visible;
		width: 101px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_Fill_pink_shadow_space_website {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Shadow_Space_PC_Fill_pink_shadow_space_website {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(242, 184, 236, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(242, 184, 236, 0.016));
		position: absolute;
		overflow: visible;
		width: 82.444px;
		height: 82.445px;
		left: 905.25px;
		top: 1041.5px;
	}
	#Shadow_Space_PC_principle_2_shadow_space_websi_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 912.75px;
		top: 1052px;
		overflow: visible;
	}
	#Shadow_Space_PC_Scroll_mission_shadow_space_we {
		mix-blend-mode: normal;
		position: absolute;
		width: 260px;
		height: 154px;
		left: 170.75px;
		top: 1088px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Shadow_Space_PC_Scroll_mission_shadow_space_we::-webkit-scrollbar{
		display: none;
	}
	#Shadow_Space_PC_intro_2_shadow_space_website_5 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 150px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_mission_lbl_shadow_space_websi {
		left: 260.75px;
		top: 1039px;
		position: absolute;
		overflow: visible;
		width: 81px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_Icon_2_grpshadow_space_website {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 81.25px;
		top: 1040px;
		overflow: visible;
	}
	#Shadow_Space_PC_bg_blueshadow_space_website_5 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Shadow_Space_PC_bg_blueshadow_space_website_5 {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(77, 223, 253, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(77, 223, 253, 0.016));
		position: absolute;
		overflow: visible;
		width: 82.444px;
		height: 82.445px;
		left: 0px;
		top: 0px;
	}
	#Shadow_Space_PC_leadership_2_shadow_space_webs_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 91.75px;
		top: 1050.5px;
		overflow: visible;
	}
	#Shadow_Space_PC_Scroll_vision_shadow_space_web {
		mix-blend-mode: normal;
		position: absolute;
		width: 265px;
		height: 153px;
		left: 572.75px;
		top: 1090.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Shadow_Space_PC_Scroll_vision_shadow_space_web::-webkit-scrollbar{
		display: none;
	}
	#Shadow_Space_PC_introshadow_space_website_5 {
		left: 0px;
		top: 2px;
		position: absolute;
		overflow: visible;
		width: 266px;
		height: 152px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_Visionshadow_space_website_5 {
		left: 662.75px;
		top: 1040.5px;
		position: absolute;
		overflow: visible;
		width: 65px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_Icon_grp_shadow_space_website_ {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 488.75px;
		top: 1037.5px;
		overflow: visible;
	}
	#Shadow_Space_PC_Fill_11shadow_space_website_5 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Shadow_Space_PC_Fill_11shadow_space_website_5 {
		-webkit-filter: drop-shadow(0px 1.852px 3.148px rgba(150, 114, 255, 0.016));
		        filter: drop-shadow(0px 1.852px 3.148px rgba(150, 114, 255, 0.016));
		position: absolute;
		overflow: visible;
		width: 82.444px;
		height: 82.445px;
		left: 0px;
		top: 0px;
	}
	#Shadow_Space_PC_target_1_shadow_space_website_ {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 500.25px;
		top: 1047px;
		overflow: visible;
	}
	#Shadow_Space_PC_offering_lbl_shadow_space_webs {
		left: 71.25px;
		top: 1336px;
		position: absolute;
		overflow: visible;
		width: 187px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_Scroll_offering_space_shaow_we {
		mix-blend-mode: normal;
		position: absolute;
		width: 1237.5px;
		height: 419.5px;
		left: 52.25px;
		top: 1399px;
        display: -webkit-box;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#Shadow_Space_PC_Scroll_offering_space_shaow_we::-webkit-scrollbar{
		display: none;
	}
	#Shadow_Space_PC_offering_card_1_shadow_space_w {
		position: relative;
		width: 230px;
		height: 325px;
		left: 19px;
		top: 20px;
        margin: 10px;
		overflow: visible;
	}
	#Shadow_Space_PC_card_bg_offeringshadow_space_w {
		fill: rgba(255,255,255,1);
	}
	.Shadow_Space_PC_card_bg_offeringshadow_space_w {
		-webkit-filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		        filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 245.5px;
		height: 340.5px;
		left: 0px;
		top: 0px;
	}
	#Shadow_Space_PC_half_circle_shadow_space_websi {
		fill: rgba(46,46,46,1);
	}
	.Shadow_Space_PC_half_circle_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 18.386px;
		height: 35px;
		left: 19px;
		top: 15px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_circle_deign_shadow_space_webs {
		fill: rgba(46,46,46,1);
	}
	.Shadow_Space_PC_circle_deign_shadow_space_webs {
		position: absolute;
		overflow: visible;
		width: 7px;
		height: 7px;
		left: 8px;
		top: 29px;
	}
	#Shadow_Space_PC_oofering_lbl_med_shadow_space_pc {
	
		top: 292px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 22px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Shadow_Space_PC_Icon_awesome-angle-right_shado {
		fill: rgba(0,0,0,1);
	}
	.Shadow_Space_PC_Icon_awesome-angle-right_shado {
		overflow: visible;
		position: absolute;
		width: 14.526px;
		height: 22.508px;
		left: 198.192px;
		top: 292px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_markus-shadow_space_website_5 {
		position: absolute;
		width: 204.834px;
		height: 270.879px;
		left: 11.5px;
		top: 11.17px;
		overflow: visible;
	}
	#Shadow_Space_PC_n_234567890_mobile_number_shad {
		left: 85px;
		top: 2456px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_affanghanioperatelive_email_sh {
		left: 301px;
		top: 2453px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_address_med_shadow_space_websi {
		left: 81px;
		top: 2516px;
		position: absolute;
		overflow: visible;
		width: 373px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 37px;
		top: 2516px;
		overflow: visible;
	}
	#Shadow_Space_PC_email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 253px;
		top: 2453px;
		overflow: visible;
	}
	#Shadow_Space_PC_phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 37px;
		top: 2456px;
		overflow: visible;
	}
	#Shadow_Space_PC_quote_grp_shadow_space_website {
		position: absolute;
		width: 354px;
		height: 108px;
		left: 964px;
		top: 2448px;
		overflow: visible;
	}
	#Shadow_Space_PC_Connect_with_us_and_unlock_lim_ss_pc {
		left: 35px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 108px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_quote_left_med_shadow_space_we {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_quote_right_med_shadow_space_w {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 155px;
		top: 83px;
		overflow: visible;
	}
	#Shadow_Space_PC_n_Copyright_Operate_Live_med_s {
		left: 37px;
		top: 2627px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_socials_grp_shadow_space_websi {
		position: absolute;
		width: 255px;
		height: 65px;
		left: 1014px;
		top: 2585px;
		overflow: visible;
	}
	#Shadow_Space_PC_linked_in_grp_shadow_space_web {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 190px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_Ellipse_5_med_shadow_space_web:hover {
		fill: rgba(120,120,255,1);
	}
	.Shadow_Space_PC_Ellipse_5_med_shadow_space_web:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Shadow_Space_PC_Ellipse_5_med_shadow_space_web {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Shadow_Space_PC_Ellipse_5_med_shadow_space_web {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#Shadow_Space_PC_linked_in_shadow_space_website {
		position: absolute;
		width: 31.653px;
		height: 28.776px;
		left: 17.063px;
		top: 17.875px;
		overflow: visible;
	}
	#Shadow_Space_PC_n_website_5_layout_ {
		fill: rgba(63,61,86,1);
	}
	.Shadow_Space_PC_n_website_5_layout_ {
		overflow: visible;
		position: absolute;
		width: 20.919px;
		height: 19.736px;
		left: 10.734px;
		top: 9.039px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.Shadow_Space_PC_linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 6.169px;
		height: 19.549px;
		left: 0.798px;
		top: 9.227px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.Shadow_Space_PC_top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 7.489px;
		height: 6.783px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_Insta_grp_shadow_space_website {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 95px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_Ellipse_4_shadow_space_website:hover {
		fill: rgba(255,120,120,1);
	}
	.Shadow_Space_PC_Ellipse_4_shadow_space_website:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Shadow_Space_PC_Ellipse_4_shadow_space_website {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Shadow_Space_PC_Ellipse_4_shadow_space_website {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#Shadow_Space_PC_instagramshadow_space_website_ {
		position: absolute;
		width: 31.688px;
		height: 31.688px;
		left: 17.063px;
		top: 17.063px;
		overflow: visible;
	}
	#Shadow_Space_PC_whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_Ellipse_3_med_shadow_space_web:hover {
		fill: rgba(50,172,158,1);
	}
	.Shadow_Space_PC_Ellipse_3_med_shadow_space_web:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Shadow_Space_PC_Ellipse_3_med_shadow_space_web {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Shadow_Space_PC_Ellipse_3_med_shadow_space_web {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#Shadow_Space_PC_whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 32.5px;
		height: 32.5px;
		left: 16.25px;
		top: 16.25px;
		overflow: visible;
	}
	#Shadow_Space_PC_oofering_lbl_med_shadow_space__du {
		left: 71.25px;
		top: 1844px;
		position: absolute;
		overflow: visible;
		width: 145px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_Scroll_event_web_5_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 1263.5px;
		height: 333.5px;
		left: 60px;
		top: 1920px;
		display: -webkit-box;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#Shadow_Space_PC_my_event_sectionshadow_space_w {
		position: relative;
		width: 560px;
		height: 286px;
		left: 11px;
		top: 24px;
		margin: 10px;
		overflow: visible;
	}
	#Shadow_Space_PC_comic_con_bg_shadow_space_webs {
		fill: rgba(255,255,255,1);
	}
	.Shadow_Space_PC_comic_con_bg_shadow_space_webs {
		-webkit-filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		        filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 529.5px;
		height: 301.5px;
		left: 46px;
		top: 0px;
	}
	#Shadow_Space_PC_Group_banner_event_img_pc {
		position: absolute;
		width: 230px;
		height: 210px;
		left: 0px;
		top: 30px;
		overflow: visible;
	}
	#Shadow_Space_PC_Anime_Edit_shadow_space_websit {
		position: absolute;
		width: 230px;
		height: 210px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_oofering_lbl_med_shadow_space__ee {
		left: 230px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 331px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Shadow_Space_PC_Scroll_description_event_web_5 {
		mix-blend-mode: normal;
		position: absolute;
		width: 326px;
		height: 158px;
		left: 234px;
		top: 82px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Shadow_Space_PC_offering_lbl_med_web_5_pc {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 312px;
		height: 158px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Shadow_Space_PC_date_web_5_pc_event {
		position: absolute;
		width: 125px;
		height: 30px;
		left: 246px;
		top: 38px;
		overflow: visible;
	}
	#Shadow_Space_PC_date_shadow_space_website_5 {
		left: 35px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 91px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Shadow_Space_PC_calendar_2_shadow_space_websit {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_time_web_5_pc_event {
		position: absolute;
		width: 152px;
		height: 30px;
		left: 391px;
		top: 38px;
		overflow: visible;
	}
	#Shadow_Space_PC_date_shadow_space_website_5_el {
		left: 35px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 118px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Shadow_Space_PC_clock_1_shadow_space_website_5 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_person_web_5_pc_event {
		position: absolute;
		width: 213px;
		height: 30px;
		left: 68px;
		top: 248px;
		overflow: visible;
	}
	#Shadow_Space_PC_man_2_shadow_space_website_5 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_date_shadow_space_website_5_ep {
		left: 35px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 179px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Shadow_Space_PC_seats_web_5_pc_event {
		position: absolute;
		width: 128px;
		height: 30px;
		left: 303px;
		top: 248px;
		overflow: visible;
	}
	#Shadow_Space_PC_seats_shadow_space_website_5 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_dateshadow_space_website_5 {
		left: 35px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 94px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Shadow_Space_PC_price_web_5_pc_event {
		position: absolute;
		width: 119px;
		height: 30px;
		left: 437px;
		top: 248px;
		overflow: visible;
	}
	#Shadow_Space_PC_price-tag_shadow_space_website {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_date_shadow_space_website_5_ev {
		left: 35px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 85px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Shadow_Space_PC_Line_shadow_space_fotter_line_ {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Shadow_Space_PC_Line_shadow_space_fotter_line_ {
		overflow: visible;
		position: absolute;
		width: 1280px;
		height: 3px;
		left: 43px;
		top: 2377px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_home_shadow_space_website_5 {
		position: absolute;
		width: 79px;
		height: 27px;
		left: 322px;
		top: 26px;
		overflow: visible;
	}
	#Shadow_Space_PC_Home_shadow_space_website_5_eg {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_home-icon-shadow_space_website {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#Shadow_Space_PC_offering_shadow_space_website_ {
		position: absolute;
		width: 106px;
		height: 27px;
		left: 448px;
		top: 26px;
		overflow: visible;
	}
	#Shadow_Space_PC_Offerings_shadow_space_website {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_discount_shadow_space_website_ {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#Shadow_Space_PC_event_grp_shadow_space_website_pc {
		position: absolute;
		width: 82px;
		height: 27px;
		left: 600px;
		top: 26px;
		overflow: visible;
	}
	#Shadow_Space_PC_Events_shadow_space_website_5 {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 58px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_election-event-on-a-calendar-s {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#Shadow_Space_PC_contact_grpshadow_space_websit {
		position: absolute;
		width: 93px;
		height: 27px;
		left: 727px;
		top: 26px;
		overflow: visible;
	}
	#Shadow_Space_PC_Contact_lbl_shadow_space_websi {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_contact_img_shadow_space_websi {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}

.mediaViewInfo {
    --web-view-name: KnowledgeKorner;
    --web-view-id: KnowledgeKorner;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-center-horizontally: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: KnowledgeKorner;
}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#KnowledgeKorner {
		position: absolute;
		width: 1366px;
		height: 3064px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(249,249,249,0.89);
		overflow: hidden;
		--web-view-name: KnowledgeKorner;
		--web-view-id: KnowledgeKorner;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#grass_shadow_website_6_pc {
		opacity: 0.32;
		fill: rgba(0,154,23,1);
	}
	.grass_shadow_website_6_pc {
		overflow: visible;
		position: absolute;
		width: 1373.327px;
		height: 59.191px;
		left: -3.086px;
		top: 715.283px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#sky_img_website_6_pc {
		fill: rgba(135,206,235,1);
	}
	.sky_img_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 1373px;
		height: 716px;
		left: -5px;
		top: 0px;
	}
	#grass_design_website_6_pc {
		fill: rgba(0,154,23,1);
	}
	.grass_design_website_6_pc {
		overflow: visible;
		position: absolute;
		width: 1373.327px;
		height: 59.191px;
		left: -5.086px;
		top: 676.283px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#footer_bg_img_website_6_pc {
		position: absolute;
		width: 1366px;
		height: 561px;
		left: 0px;
		top: 2616px;
		overflow: visible;
	}
	#home_shadow_space_website_6 {
		position: absolute;
		width: 79px;
		height: 27px;
		left: 434px;
		top: 26px;
		overflow: visible;
	}
	#Home_shadow_space_website_6_cd {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#home-icon-shadow_space_website {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#offering_shadow_space_website_ {
		position: absolute;
		width: 106px;
		height: 27px;
		left: 560px;
		top: 26px;
		overflow: visible;
	}
	#Offerings_shadow_space_website {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#discount_shadow_space_website_ {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#event_grp_shadow_space_website_kk_pc {
		position: absolute;
		width: 82px;
		height: 27px;
		left: 712px;
		top: 26px;
		overflow: visible;
	}
	#Events_shadow_space_website_6 {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 58px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#election-event-on-a-calendar-s {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#contact_grpshadow_space_websit {
		position: absolute;
		width: 93px;
		height: 27px;
		left: 839px;
		top: 26px;
		overflow: visible;
	}
	

#n__imgs_grp_website_6_pc { 
    -webkit-animation-name: floating; 
            animation-name: floating;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
@-webkit-keyframes floating {
    0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    50%  { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
    100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}
@keyframes floating {
    0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    50%  { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
    100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}
body::-webkit-scrollbar{
	display: none;
}
	#Contact_lbl_shadow_space_websi {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#contact_img_shadow_space_websi {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#Anime_Edit_1_website_6_pc {
		position: absolute;
		width: 100px;
		height: 100px;
		left: 126px;
		top: 17px;
        border-radius: 50%;
		overflow: hidden;
	}
	#LETS_LEARN_AND_GAIN__website_6 {
		left: 258px;
		top: 143px;
		position: absolute;
		overflow: visible;
		width: 851px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 60px;
		color: rgba(0,0,0,0.89);
	}
	#Lets_discover_new_lbl_website_ {
		left: 364px;
		top: 353px;
		position: absolute;
		overflow: visible;
		width: 639px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,0.89);
	}
	#Trusted_by_our_partners_lbl_we {
		left: 572px;
		top: 920px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_web_sug_pc_6 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1205px;
		height: 80px;
		left: 95px;
		top: 993px;
        display: inline-flex;
		overflow-x: auto;
		overflow-y: hidden;
	}
    #Scroll_web_sug_pc_6::-webkit-scrollbar{
		display: none;
	}
	#partner_grp_website_6_pc {
		position: relative;
		width: 252px;
		height: 50px;
		left: 29px;
		top: 14px;
		overflow: visible;
	}
	#Anime_Edit_img_website_6_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Mohammed_Affan_lbl_website_6_p {
		left: 65px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 188px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#Our_Services_lbl_website_6_pc {
		left: 621px;
		top: 1582px;
		position: absolute;
		overflow: visible;
		width: 119px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#mission_card_grp_website_6_pc {
		position: absolute;
		width: 233px;
		height: 325px;
		left: 567px;
		top: 1157px;
		overflow: visible;
	}
	#mission_overall_bdr_website_6_ {
		fill: rgba(255,255,255,1);
	}
	.mission_overall_bdr_website_6_ {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 251px;
		height: 343px;
		left: 0px;
		top: 0px;
	}
	#mission_img_bdr_website_6_pc {
		fill: rgba(167,166,210,1);
	}
	.mission_img_bdr_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 50px;
		height: 50px;
		left: 10px;
		top: 10px;
	}
	#Mission_lbl_website_6_pc {
		left: 10px;
		top: 65px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_mision_pc_web_6 {
		mix-blend-mode: normal;
		position: absolute;
		width: 233px;
		height: 211px;
		left: 0px;
		top: 92px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
    #Scroll_mision_pc_web_6::-webkit-scrollbar{
		display: none;
	}
	#Watashitachi_no_dry_no_zaido_o {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,0.89);
	}

    #principle_2_k_k_websi_pc {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 15px;
		top: 15px;
		overflow: visible;
	}
    #principle_card_grp_website_6_p {
		position: absolute;
		width: 233px;
		height: 325px;
		left: 850px;
		top: 1157px;
		overflow: visible;
	}
	#overall_princi_card_website_6_ {
		fill: rgba(255,255,255,1);
	}
	.overall_princi_card_website_6_ {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 251px;
		height: 343px;
		left: 0px;
		top: 0px;
	}
	#principle_img_bdr_website_6_pc {
		fill: rgba(166,210,202,1);
	}
	.principle_img_bdr_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 50px;
		height: 50px;
		left: 10px;
		top: 10px;
	}
	#Principle_lbl_website_6_pc {
		left: 10px;
		top: 65px;
		position: absolute;
		overflow: visible;
		width: 71px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_princi_web_6_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 233px;
		height: 214px;
		left: 0px;
		top: 92px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
    #Scroll_princi_web_6_pc::-webkit-scrollbar{
		display: none;
	}
	#Kono_sekai_wa_itami_o_shirudar {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,0.89);
	}
	#leadership_2_knowledge_webs_pc {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 15px;
		top: 13.5px;
		overflow: visible;
	}
	#vision_card_website_6_pc_grp {
		position: absolute;
		width: 233px;
		height: 325px;
		left: 284px;
		top: 1157px;
		overflow: visible;
	}
	#vision_card_overall_website_6_ {
		fill: rgba(255,255,255,1);
	}
	.vision_card_overall_website_6_ {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 251px;
		height: 343px;
		left: 0px;
		top: 0px;
	}
	#bdr_img_visionwebsite_6_pc {
		fill: rgba(241,144,144,1);
	}
	.bdr_img_visionwebsite_6_pc {
		position: absolute;
		overflow: visible;
		width: 50px;
		height: 50px;
		left: 10px;
		top: 10px;
	}
	#Vision_lbl_website_6_pc {
		left: 10px;
		top: 65px;
		position: absolute;
		overflow: visible;
		width: 51px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_vision_web_6_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 233px;
		height: 215px;
		left: 0px;
		top: 92px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
    
#Scroll_vision_web_6_pc::-webkit-scrollbar{
    display: none;
}
	#Zaido_wa_gisei_ni_natte_korosa {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,0.89);
	}
	#target_1_shadow_space_website_ {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 15px;
		top: 15px;
		overflow: visible;
	}
	#Scroll_my_services_pc_6_wev {
		mix-blend-mode: normal;
		position: absolute;
		width: 1303px;
		height: 375px;
		left: 29px;
		top: 1622px;
		display: -webkit-box;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#Scroll_my_services_pc_6_wev::-webkit-scrollbar{
		display: none;
	}
	#our_servicces_card_1_website_6 {
		position: relative;
		width: 233px;
		height: 325px;
		left: 22px;
		top: 17px;
        margin: 10px;
		overflow: visible;
	}
	#services_card_1_bdr_website_6_ {
		fill: rgba(255,255,255,1);
	}
	.services_card_1_bdr_website_6_ {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 251px;
		height: 343px;
		left: 0px;
		top: 0px;
	}
	#img_of_services_website_6_pc {
		position: absolute;
		width: 201px;
		height: 265px;
		left: 16px;
		top: 46px;
		overflow: visible;
	}
	#circle_deign_shadow_space_webs {
		fill: rgba(23,18,18,1);
	}
	.circle_deign_shadow_space_webs {
		position: absolute;
		overflow: visible;
		width: 10px;
		height: 10px;
		left: 16px;
		top: 20px;
	}
	#My_Offerings_website_6_pc {
		left: 31px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 187px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,0.89);
	}
	#circle_3_website_6_pc {
		fill: rgba(166,210,202,1);
	}
	.circle_3_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 50px;
		height: 50px;
		left: 1140px;
		top: 1433px;
	}
	#circle_2_website_6_pc {
		fill: rgba(255,168,168,1);
	}
	.circle_2_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 391px;
		top: 1483px;
	}
	#oofering_lbl_med_knowledge_pc {
		left: 635px;
		top: 2085px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
    #Scroll_event_web_6_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 1269.5px;
		height: 353px;
		left: 63px;
		top: 2148px;
        display: -webkit-box;
		overflow-x: auto;
		overflow-y: hidden;
	}

    #Scroll_event_web_6_pc::-webkit-scrollbar {
        display: none;
    }
	#my_event_sectionshadow_space_w {
		position: relative;
		width: 560px;
		height: 323px;
		left: 9px;
		top: 8px;
        margin: 10px;
		overflow: visible;
	}
	#comic_con_bg_shadow_space_webs_dq {
		fill: url(#comic_con_bg_shadow_space_webs_dq);
	}
	.comic_con_bg_shadow_space_webs_dq {
		-webkit-filter: drop-shadow(8px 8px 5px rgba(219, 219, 219, 1));
		        filter: drop-shadow(8px 8px 5px rgba(219, 219, 219, 1));
		position: absolute;
		overflow: visible;
		width: 529.5px;
		height: 301.5px;
		left: 46px;
		top: 18px;
	}
	#Anime_Edit_shadow_space_websit {
		position: absolute;
		width: 230px;
		height: 323px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#oofering_lbl_med_shadow_space__ds {
		left: 230px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 331px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Scroll_description_event_web_6 {
		mix-blend-mode: normal;
		position: absolute;
		width: 326px;
		height: 158px;
		left: 234px;
		top: 100px;
		overflow: hidden;
	}
	#offering_lbl_med_web_6_pc {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 312px;
		height: 158px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#person_name_event_1_website_6_ {
		-webkit-filter: blur(44px);
		        filter: blur(44px);
		fill: rgba(255,255,255,1);
	}
	.person_name_event_1_website_6_ {
		position: absolute;
		overflow: visible;
		width: 230px;
		height: 28px;
		left: 0px;
		top: 0px;
	}
	#grp_1_top_pc_web_6 {
		position: absolute;
		width: 297px;
		height: 30px;
		left: 246px;
		top: 56px;
		overflow: visible;
	}
	#date_shadow_space_website_6 {
		left: 36px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 91px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#calendar_2_shadow_space_websit {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#date_shadow_space_website_6_dz {
		left: 180px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 118px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#clock_1_shadow_space_website_6 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 145px;
		top: 0px;
		overflow: visible;
	}
	#grp_2_top_pc_web_6 {
		position: absolute;
		width: 267px;
		height: 30px;
		left: 247px;
		top: 266px;
		overflow: visible;
	}
	#seats_shadow_space_website_6 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#dateshadow_space_website_6 {
		left: 34px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 94px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#price-tag_shadow_space_website {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 148px;
		top: 0px;
		overflow: visible;
	}
	#date_shadow_space_website_6_d {
		left: 183px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 85px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#man_2_shadow_space_website_6 {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 6px;
		top: 4px;
		overflow: visible;
	}
	#date_shadow_space_website_6_ea {
		left: 26px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 198px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#footer_grp_web_6_pc {
		position: absolute;
		width: 1366px;
		height: 446px;
		left: 0px;
		top: 2616px;
		overflow: visible;
	}
	#footer_bg_img_website_6_pc {
		position: absolute;
		width: 1366px;
		height: 446px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_234567890_mobile_number_shad_k_k_pc {
		left: 826px;
		top: 47px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#affanghanioperatelive_email_sh_k_k_pc {
		left: 1037px;
		top: 47px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#address_med_shadow_space_websi_k_k_pc {
		left: 826px;
		top: 107px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#location_img_shadow_space_webs_k_k_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 791px;
		top: 107px;
		overflow: visible;
	}
	#email_address_shadow_space_web_k_k_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 997px;
		top: 46px;
		overflow: visible;
	}
	#phone_number_shadow_space_webs_k_k_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 791px;
		top: 46px;
		overflow: visible;
	}
	#Connect_with_us_and_unlock_lim_k_k_pc {
		left: 826px;
		top: 221px;
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#quote_left_med_shadow_space_we_k_k_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 791px;
		top: 221px;
		overflow: visible;
	}
	#quote_right_med_shadow_space_w_k_k_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 1055px;
		top: 277px;
		overflow: visible;
	}
	#n_Copyright_Operate_Live_med_s_k_k_pc {
		left: 97px;
		top: 399px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#socials_grp_shadow_space_websi_k_k_pc {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 826px;
		top: 335px;
		overflow: visible;
	}
	#linked_in_grp_shadow_space_web_k_k_pc {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_6_k_k_pc:hover {
		fill: rgba(120,120,255,1);
	}
	.med_shadow_space_website_6_k_k_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_6_k_k_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_6_k_k_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_shadow_space_website_k_k_pc {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.191px;
		overflow: visible;
	}
	#n_website_6_layout__k_k_pc {
		fill: rgba(63,61,86,1);
	}
	.n_website_6_layout__k_k_pc {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_shadow_space_websi_k_k_pc {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_shadow_space_websi_k_k_pc {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_shadow_space_website_k_k_pc {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_shadow_space_website_k_k_pc {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_shadow_space_website_k_k_pc {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#shadow_space_website_6_k_k_pc:hover {
		fill: rgba(255,120,120,1);
	}
	.shadow_space_website_6_k_k_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#shadow_space_website_6_k_k_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.shadow_space_website_6_k_k_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagramshadow_space_website__k_k_pc {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#whatsapp_grp_shadow_space_webs_k_k_pc {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_6_ex_k_k_pc:hover {
		fill: rgba(50,172,158,1);
	}
	.med_shadow_space_website_6_ex_k_k_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_6_ex_k_k_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_6_ex_k_k_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_shadow_space_webs_k_k_pc {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#tv_part_21_website_6_pc {
		fill: rgba(0,0,0,1);
	}
	.tv_part_21_website_6_pc {
		overflow: visible;
		position: absolute;
		width: 512.559px;
		height: 310.918px;
		left: 96.628px;
		top: 61.477px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#tv_part_1_website_6_pc {
		fill: rgba(255,255,255,1);
	}
	.tv_part_1_website_6_pc {
		overflow: visible;
		position: absolute;
		width: 497.467px;
		height: 275.943px;
		left: 104.469px;
		top: 69.395px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_6_website_6 {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_6_website_6 {
		overflow: visible;
		position: absolute;
		width: 80px;
		height: 56px;
		left: 70px;
		top: 167px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_5_website_6 {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_5_website_6 {
		overflow: visible;
		position: absolute;
		width: 62px;
		height: 43.4px;
		left: 1108px;
		top: 246px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_4_website_6 {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_4_website_6 {
		overflow: visible;
		position: absolute;
		width: 80px;
		height: 56px;
		left: 258px;
		top: 353px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_3_website_6 {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_3_website_6 {
		overflow: visible;
		position: absolute;
		width: 80px;
		height: 56px;
		left: 1220px;
		top: 465px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_2_website_6 {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_2_website_6 {
		overflow: visible;
		position: absolute;
		width: 80px;
		height: 56px;
		left: 892px;
		top: 89px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_1_website_6 {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_1_website_6 {
		overflow: visible;
		position: absolute;
		width: 80px;
		height: 56px;
		left: 513px;
		top: 89px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#sheep3_website_6_pc {
		position: absolute;
		width: 58px;
		height: 58px;
		left: 116px;
		top: 658px;
		overflow: visible;
	}
	#sheep_2_website_6_pc {
		position: absolute;
		width: 58px;
		height: 58px;
		left: 79px;
		top: 670px;
		overflow: visible;
	}
	#sheep_1_website_6_pc {
		position: absolute;
		width: 58px;
		height: 58px;
		left: 1202px;
		top: 658px;
		overflow: visible;
	}
	#dove_3_website_6_pc {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 1099px;
		top: 117px;
		overflow: visible;
	}
	#dove_2_website_6_pc {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 1135px;
		top: 129px;
		overflow: visible;
	}
	#dove_1_website_6_pc {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 1135px;
		top: 86px;
		overflow: visible;
	}
	#n__imgs_grp_website_6_pc {
		position: absolute;
		width: 1013px;
		height: 437px;
		left: 177px;
		top: 445px;
		overflow: visible;
	}
	#image_4_shadow_website_6_pc {
		-webkit-filter: blur(4px);
		        filter: blur(4px);
		fill: rgba(212,212,212,1);
	}
	.image_4_shadow_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 174px;
		height: 33px;
		left: 37px;
		top: 356px;
	}
	#image_3_shadow_website_6_pc {
		-webkit-filter: blur(4px);
		        filter: blur(4px);
		fill: rgba(212,212,212,1);
	}
	.image_3_shadow_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 174px;
		height: 33px;
		left: 295px;
		top: 428px;
	}
	#image_2_shadow_website_6_pc {
		-webkit-filter: blur(4px);
		        filter: blur(4px);
		fill: rgba(212,212,212,1);
	}
	.image_2_shadow_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 174px;
		height: 33px;
		left: 569px;
		top: 428px;
	}
	#image_1_shadow_website_6_pc {
		-webkit-filter: blur(4px);
		        filter: blur(4px);
		fill: rgba(212,212,212,1);
	}
	.image_1_shadow_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 174px;
		height: 33px;
		left: 827px;
		top: 361px;
	}
	#img_4_website_6_pc {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 218px;
		height: 355px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#img_3_website_6_pc {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 218px;
		height: 355px;
		left: 258px;
		top: 65px;
		overflow: visible;
	}
	#img_2_website_6_pc {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 218px;
		height: 355px;
		left: 537px;
		top: 65px;
		overflow: visible;
	}
	#img_1_website_6_pc {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 218px;
		height: 355px;
		left: 795px;
		top: 0px;
		overflow: visible;
	}
	#circle_1_website_6_pc {
		fill: rgba(166,210,170,1);
	}
	.circle_1_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 50px;
		height: 50px;
		left: 1242px;
		top: 1106px;
	}

	.mediaViewInfo {
		--web-view-name: DriveVelocity;
		--web-view-id: DriveVelocity;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: DriveVelocity;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}

	#DriveVelocity {
		position: absolute;
		width: 1366px;
		height: 4346px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(249,249,249,0.89);
		overflow: hidden;
		--web-view-name: DriveVelocity;
		--web-view-id: DriveVelocity;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#avaliable_behind_bdr_website_7 {
		fill: rgba(99,113,203,1);
	}
	.avaliable_behind_bdr_website_7 {
		overflow: visible;
		position: absolute;
		width: 685.604px;
		height: 404.5px;
		left: -169.578px;
		top: 3247.357px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#princi_img_and_evertyhng_grp_w {
		position: absolute;
		width: 942.695px;
		height: 578.661px;
		left: 157.305px;
		top: 794px;
		overflow: visible;
	}
	#princi_design_img_website_7_pc {
		fill: rgba(99,113,203,1);
	}
	.princi_design_img_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 466.46px;
		height: 194.257px;
		-webkit-transform: translate(-157.305px, -794px) matrix(1,0,0,1,21.2035,1042.3028) rotate(90deg);
		        transform: translate(-157.305px, -794px) matrix(1,0,0,1,21.2035,1042.3028) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#shade_princi_img_website_7_pc {
		fill: rgba(0,0,0,1);
	}
	.shade_princi_img_website_7_pc {
		-webkit-filter: drop-shadow(-70px 73px 99px rgba(0, 0, 0, 0.122));
		        filter: drop-shadow(-70px 73px 99px rgba(0, 0, 0, 0.122));
		position: absolute;
		overflow: visible;
		width: 643px;
		height: 757px;
		left: 67.695px;
		top: 0px;
	}
	#princi_Group_website_7_pc {
		position: absolute;
		width: 346px;
		height: 460px;
		left: 67.695px;
		top: 0px;
		overflow: visible;
	}
	#princi_text_grp_website_7_pc {
		position: absolute;
		width: 442px;
		height: 293px;
		left: 500.695px;
		top: 112px;
		overflow: visible;
	}
	#Principle_lbl_website_7_pc {
		left: 9px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 152px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#top_princi_bdr_website_7_pc {
		fill: rgba(222,14,11,1);
	}
	.top_princi_bdr_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 0px;
		left: 8px;
		top: 0px;
	}
	#Scroll_principle_web_7_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 442px;
		height: 199px;
		left: 0px;
		top: 94px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_principle_web_7_pc::-webkit-scrollbar{
		display: none;
	}
	#The_Mercedes-AMG_GT_combines_t {
		left: 9px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 120px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(116,116,116,1);
	}
	#avaliable_card_bg_website_7_pc {
		fill: rgba(192,192,192,1);
	}
	.avaliable_card_bg_website_7_pc {
		width: 1975.545px;
		height: 405.723px;
		position: absolute;
		overflow: visible;
		-webkit-transform: matrix(1,0,0,1,-272.0387,3260.6196) rotate(-6deg);
		        transform: matrix(1,0,0,1,-272.0387,3260.6196) rotate(-6deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#vision_top_line_website_7_pc {
		fill: rgba(99,113,203,1);
	}
	.vision_top_line_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 225px;
		top: 1507px;
	}
	#vision_img_and_evertyhng_grp_w {
		position: absolute;
		width: 1155px;
		height: 727px;
		left: 211px;
		top: 1400px;
		overflow: visible;
	}
	#vision_bdr_website_7_pc {
		fill: rgba(99,113,203,1);
	}
	.vision_bdr_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 662px;
		height: 146px;
		left: 14px;
		top: 413px;
	}
	#mask_top_visoon_img_website_7_ {
		position: absolute;
		width: 583px;
		height: 574px;
		left: 572px;
		top: 0px;
		overflow: visible;
	}
	#small_thumbnails_website_7_pc {
		position: absolute;
		width: 697px;
		height: 260px;
		left: 242px;
		top: 467px;
		overflow: visible;
	}
	#overall_bdr_thumb_website_7_pc {
		fill: rgba(192,192,192,1);
	}
	.overall_bdr_thumb_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 697px;
		height: 260px;
		left: 0px;
		top: 0px;
	}
	#img_2_website_7_pc_Group_3 {
		position: absolute;
		width: 273px;
		height: 220px;
		left: 318px;
		top: 20.002px;
		overflow: visible;
	}
	#website_7_pc_Group_4 {
		position: absolute;
		width: 272px;
		height: 220px;
		left: 24px;
		top: 20.002px;
		overflow: visible;
	}
	#arrow_website_7_pc {
		position: absolute;
		width: 48.28px;
		height: 12px;
		left: 619.72px;
		top: 130px;
		overflow: visible;
	}
	#Line_1_website_7_pc {
		fill: transparent;
		stroke: rgba(222,14,11,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_1_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 41.18px;
		height: 2px;
		left: 0px;
		top: 5.736px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Polygon_2_website_7_pc {
		fill: rgba(222,14,11,1);
	}
	.Polygon_2_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 12px;
		height: 10px;
		-webkit-transform: translate(0px, 0.246px) matrix(1,0,0,1,37.2803,0.7539) rotate(90deg);
		        transform: translate(0px, 0.246px) matrix(1,0,0,1,37.2803,0.7539) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Vision_lbl_website_7_pc {
		left: 14px;
		top: 118.998px;
		position: absolute;
		overflow: visible;
		width: 111px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 41px;
		color: rgba(0,0,0,1);
	}
	#Scroll_vision_web_7_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 445px;
		height: 197px;
		left: 0px;
		top: 188.998px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_vision_web_7_pc::-webkit-scrollbar{
		display: none;
	}
	#The_cockpit_impresses_with_its {
		left: 14px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 133px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#line_3_missionwebsite_7_pc {
		fill: rgba(99,113,203,1);
	}
	.line_3_missionwebsite_7_pc {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.207px;
		left: 666.437px;
		top: 2782.014px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#line_2_mission_website_7_pc {
		fill: rgba(99,113,203,1);
	}
	.line_2_mission_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.207px;
		left: 666.437px;
		top: 2810.693px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#line1_mission_website_7_pc {
		fill: rgba(99,113,203,1);
	}
	.line1_mission_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.207px;
		left: 666.437px;
		top: 2840.107px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#mision_img_and_evertyhng_grp_w {
		position: absolute;
		width: 1475px;
		height: 563px;
		left: -366px;
		top: 2279.002px;
		overflow: visible;
	}
	#chris-nguyen-aTX_bRaOZnA-unspl {
		position: absolute;
		width: 1000px;
		height: 563px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#white_shade_website_7_pc_c {
		fill: url(#white_shade_website_7_pc_c);
	}
	.white_shade_website_7_pc_c {
		position: absolute;
		overflow: visible;
		width: 695px;
		height: 563px;
		left: 322px;
		top: 0px;
	}
	#mision_img_lines_grp_website_7 {
		fill: rgba(99,113,203,1);
	}
	.mision_img_lines_grp_website_7 {
		overflow: visible;
		position: absolute;
		width: 685.604px;
		height: 285.52px;
		left: 251.158px;
		top: 277.355px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Masterpiece_lbl_website_7_pc {
		left: 1033px;
		top: 112.998px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 9px;
		color: rgba(99,113,203,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#Mission_lbl_website_7_pc {
		left: 1033px;
		top: 157.998px;
		position: absolute;
		overflow: visible;
		width: 136px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#mision_like_website_7_pc {
		fill: rgba(99,113,203,1);
	}
	.mision_like_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 1032px;
		top: 146.998px;
	}
	#Scroll_mision_web_7_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 442px;
		height: 216px;
		left: 1033px;
		top: 226.998px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_mision_web_7_pc::-webkit-scrollbar{
		display: none;
	}
	#An_aggregate_of_racing-lev_web {
		left: 2px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(116,116,116,1);
	}
	#kisspng-mercedes-ben_website_7 {
		position: absolute;
		width: 278.729px;
		height: 313.898px;
		left: 591.225px;
		top: 128.928px;
		overflow: visible;
	}
	#kisspng-mercedes-ben_website_7 { 
    -webkit-animation-name: floating; 
            animation-name: floating;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 0px;
}
@-webkit-keyframes floating {
    0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    50%  { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
    100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}
@keyframes floating {
    0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    50%  { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
    100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}
	#Scroll_off_website_7_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 1345.944px;
		height: 557.5px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		left: 0px;
		top: 3184.5px;
		overflow-x: scroll;
		overflow-y: hidden;
		-webkit-transform: rotate(-6deg);
		        transform: rotate(-6deg);
	}
	#Scroll_off_website_7_pc::-webkit-scrollbar{
		display: none;
	}
	#offering_card_1_shadow_space_w7 {
		position: relative;
		width: 230px;
		height: 325px;
		/* left: 66px; */
		top: 120px;
		margin-left: 15px;
		overflow: visible;
	}
	#card_bg_offeringshadow_space_w7 {
		fill: rgba(255,255,255,1);
	}
	.card_bg_offeringshadow_space_w7 {
		-webkit-filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		        filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 245.5px;
		height: 340.5px;
		left: 0px;
		top: 0px;
	}
	#oofering_lbl_med_shadow_space_7mob {
		left: 18px;
		top: 288px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#markus-shadow_space_website_7 {
		position: absolute;
		width: 204.834px;
		height: 270.879px;
		left: 11.5px;
		top: 11.17px;
		overflow: visible;
	}
	#Icon_awesome-car_website_7_pc {
		fill: rgba(0,0,0,1);
	}
	.Icon_awesome-car_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 36px;
		height: 27px;
		left: 7.5px;
		top: 23.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Avaliable_Now_lbl_website_7_pc {
		left: 535px;
		top: 3113px;
		position: absolute;
		overflow: visible;
		width: 298px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#header_all_grp_web_7_pc {
		position: absolute;
		width: 1369px;
		height: 770px;
		left: -4px;
		top: 0px;
		overflow: visible;
	}
	#peter-broomfield-m3m-lnR90uM-u {
		position: absolute;
		width: 1369px;
		height: 770px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#text_unlessh_website_7_pc {
		position: absolute;
		width: 379px;
		height: 129px;
		left: 166px;
		top: 359px;
		overflow: visible;
	}
	#Unleash_the_True_Powe_website_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 228px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 41px;
		color: rgba(255,255,255,1);
	}
	#Drive_into_the_Future_Your_Ult {
		left: 0px;
		top: 108px;
		position: absolute;
		overflow: visible;
		width: 380px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(173,173,173,1);
	}
	#pre-order_button_website_7_pc {
		position: absolute;
		width: 163px;
		height: 13px;
		left: 229px;
		top: 524px;
		overflow: visible;
	}
	#line_pre_website_7_pc {
		fill: rgba(222,14,11,1);
	}
	.line_pre_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 0px;
		top: 7px;
	}
	#PRE-ORDER_NOW_website_7_pc {
		left: 76px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 88px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 9px;
		color: rgba(222,14,11,1);
		letter-spacing: 0.95px;
	}
	#zane-lee-bXbi1jJVDf0-unsplash {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 94px;
		top: 21px;
		overflow: visible;
	}
	#footer_all_grp_web_7_pc {
		position: absolute;
		width: 1555px;
		height: 571px;
		left: 39px;
		top: 3774px;
		overflow: visible;
	}
	#fotter_img_website_7_pc {
		position: absolute;
		width: 677px;
		height: 497px;
		left: 878px;
		top: 0px;
		overflow: visible;
	}
	#shade_footer_design_website_7__du {
		fill: url(#shade_footer_design_website_7__du);
	}
	.shade_footer_design_website_7__du {
		position: absolute;
		overflow: visible;
		width: 454px;
		height: 427px;
		left: 869px;
		top: 26px;
	}
	#bdr_info_website_7_pc {
		fill: rgba(192,192,192,1);
	}
	.bdr_info_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 493px;
		height: 454px;
		left: 554px;
		top: 117px;
	}
	#Call_us_for_questions_website_ {
		left: 628px;
		top: 141px;
		position: absolute;
		overflow: visible;
		width: 209px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#call_below_line_website_7_pc {
		fill: rgba(255,255,255,1);
	}
	.call_below_line_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 627px;
		top: 180px;
	}
	#call_web_7_pc {
		position: absolute;
		width: 346px;
		height: 60px;
		left: 627px;
		top: 200px;
		overflow: visible;
	}
	#no_bdr_website_7_pc {
		fill: rgba(99,113,203,1);
	}
	.no_bdr_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 60px;
		left: 0px;
		top: 0px;
	}
	#n_1_123-456-7890_website_7_pc {
		left: 69px;
		top: 16px;
		position: absolute;
		overflow: visible;
		width: 166px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#phone-receiver_bdr_website_7_p7 {
		fill: rgba(255,255,255,1);
	}
	.phone-receiver_bdr_website_7_p7 {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 60px;
		left: 0px;
		top: 0px;
	}
	#phone-receiver_website_7_pc {
		fill: rgba(21,21,21,1);
	}
	.phone-receiver_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 23.454px;
		height: 23.439px;
		left: 18.163px;
		top: 17.434px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Email_us_for_inquiries_website {
		left: 628px;
		top: 277px;
		position: absolute;
		overflow: visible;
		width: 211px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#email_bdr_website_7_pc {
		fill: rgba(255,255,255,1);
	}
	.email_bdr_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 627px;
		top: 316px;
	}
	#email_web_7_pc {
		position: absolute;
		width: 346px;
		height: 61px;
		left: 628px;
		top: 336px;
		overflow: visible;
	}
	#email_bdr_website_7_pc_d {
		fill: rgba(99,113,203,1);
	}
	.email_bdr_website_7_pc_d {
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 61px;
		left: 0px;
		top: 0px;
	}
	#emailmailcom_website_7_pc {
		left: 69px;
		top: 17px;
		position: absolute;
		overflow: visible;
		width: 278px;
		height: 27px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
	}
	#mail_bdr_website_7_pc {
		fill: rgba(255,255,255,1);
	}
	.mail_bdr_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 61px;
		left: 0px;
		top: 0px;
	}
	#envelope_website_7_pc {
		position: absolute;
		width: 29.462px;
		height: 23.147px;
		left: 15.144px;
		top: 19.268px;
		overflow: visible;
	}
	#Path_15_website_7_pc {
		fill: rgba(21,21,21,1);
	}
	.Path_15_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 29.462px;
		height: 14.73px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_16_website_7_pc {
		fill: rgba(21,21,21,1);
	}
	.Path_16_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 29.462px;
		height: 15.684px;
		left: 0px;
		top: 7.463px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 528px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(173,173,173,1);
	}
	#dv_socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 160px;
		top: 437px;
		overflow: visible;
	}
	#linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.med_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.191px;
		overflow: visible;
	}
	#n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#shadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_7_eq:hover {
		fill: rgba(50,172,158,1);
	}
	.med_shadow_space_website_7_eq:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_7_eq {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_7_eq {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#gps_footer_website_7_pc {
		position: absolute;
		width: 402px;
		height: 251px;
		left: 53px;
		top: 143px;
		overflow: visible;
	}
	#bdr_screen_website_7_pc {
		fill: rgba(0,0,0,1);
	}
	.bdr_screen_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 402px;
		height: 251px;
		left: 0px;
		top: 0px;
	}
	#screen_map_website_7_pc {
		fill: rgba(255,255,255,1);
	}
	.screen_map_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 385px;
		height: 211px;
		left: 8px;
		top: 7px;
	}
	#Icon_awesome-car_website_7_pc_ew {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-car_website_7_pc_ew {
		overflow: visible;
		position: absolute;
		width: 15.924px;
		height: 11.943px;
		left: 29.635px;
		top: 229.723px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-musicwebsite_7_pc {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-musicwebsite_7_pc {
		overflow: visible;
		position: absolute;
		width: 11.944px;
		height: 11.943px;
		left: 74.993px;
		top: 229.723px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-location-arrow_we {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-location-arrow_we {
		overflow: visible;
		position: absolute;
		width: 11.943px;
		height: 11.943px;
		left: 175.159px;
		top: 229.723px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_459321__website_7_pc {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_459321__website_7_pc {
		overflow: visible;
		position: absolute;
		width: 9.465px;
		height: 13.943px;
		left: 234.466px;
		top: 229.723px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_4601_website_7_pc {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_4601_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 12.451px;
		left: 231.48px;
		top: 230.469px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_459_website_7_pc {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_459_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 9.465px;
		height: 13.943px;
		left: 271.474px;
		top: 229.723px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_460_website_7_pc {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_460_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 12.451px;
		left: 281.924px;
		top: 230.469px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-volume-up_website {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-volume-up_website {
		overflow: visible;
		position: absolute;
		width: 13.132px;
		height: 10.943px;
		left: 364.569px;
		top: 230.723px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Email_us_for_inquiries_website_e {
		left: 629px;
		top: 415px;
		position: absolute;
		overflow: visible;
		width: 211px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#line1_website_7_pc {
		fill: rgba(255,255,255,1);
	}
	.line1_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 628px;
		top: 454px;
	}
	#location_web_7_pc {
		position: absolute;
		width: 346px;
		height: 61px;
		left: 629px;
		top: 474px;
		overflow: visible;
	}
	#address_bdr_lbl_website_7_pc {
		fill: rgba(99,113,203,1);
	}
	.address_bdr_lbl_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 61px;
		left: 0px;
		top: 0px;
	}
	#address_website_7_pc {
		left: 69px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 278px;
		height: 51px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
	}
	#address_icon_bdr_website_7_pc {
		fill: rgba(255,255,255,1);
	}
	.address_icon_bdr_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 61px;
		left: 0px;
		top: 0px;
	}
	#Icon_material-edit-location_we7 {
		fill: rgba(0,0,0,1);
	}
	.Icon_material-edit-location_we7 {
		overflow: visible;
		position: absolute;
		width: 21px;
		height: 30px;
		left: 19px;
		top: 16px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	.mediaViewInfo {
		--web-view-name: ride revolution;
		--web-view-id: ride_revolution;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ride_revolution;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ride_revolution {
		position: absolute;
		width: 1366px;
		height: 4346px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(249,249,249,0.89);
		overflow: hidden;
		--web-view-name: ride revolution;
		--web-view-id: ride_revolution;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#avaliable_behind_bdr_website_7 {
		fill: rgba(222,14,11,1);
	}
	.avaliable_behind_bdr_website_7 {
		overflow: visible;
		position: absolute;
		width: 685.604px;
		height: 404.5px;
		left: -169.578px;
		top: 3247.357px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#yamede_princi_web_8pc_bike {
		position: absolute;
		width: 949.695px;
		height: 578.723px;
		left: 157.305px;
		top: 2367px;
		overflow: visible;
	}
	#princi_design_img_website_7_pc {
		fill: rgba(222,14,11,1);
	}
	.princi_design_img_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 466.46px;
		height: 194.257px;
		-webkit-transform: translate(0px, 0.062px) matrix(1,0,0,1,-136.1016,248.3028) rotate(90deg);
		        transform: translate(0px, 0.062px) matrix(1,0,0,1,-136.1016,248.3028) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#shade_princi_img_website_7_pc_ {
		fill: rgba(0,0,0,1);
	}
	.shade_princi_img_website_7_pc_ {
		-webkit-filter: drop-shadow(-70px 73px 99px rgba(0, 0, 0, 0.122));
		        filter: drop-shadow(-70px 73px 99px rgba(0, 0, 0, 0.122));
		position: absolute;
		overflow: visible;
		width: 643px;
		height: 757px;
		left: 67.695px;
		top: 0px;
	}
	#princi_Group_website_7_pc_bike {
		position: absolute;
		width: 346px;
		height: 460px;
		left: 67.695px;
		top: 0px;
		overflow: visible;
	}
	#princi_text_grp_website_7_pc_b {
		position: absolute;
		width: 457px;
		height: 308px;
		left: 492.695px;
		top: 112px;
		overflow: visible;
	}
	#Principle_lbl_website_7_pc_bik {
		left: 17px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 152px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#top_princi_bdr_website_7_pc_bi {
		fill: rgba(222,14,11,1);
	}
	.top_princi_bdr_website_7_pc_bi {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 0px;
		left: 16px;
		top: 0px;
	}
	#Scroll_prinic_web_8_pc_bike {
		mix-blend-mode: normal;
		position: absolute;
		width: 457px;
		height: 220px;
		left: 0px;
		top: 88px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#mh_in_40_seconds__bike {
		left: 17px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 120px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(116,116,116,1);
	}
	#onichan_vision_8_pc_web {
		position: absolute;
		width: 1149px;
		height: 727px;
		left: 217px;
		top: 782px;
		overflow: visible;
	}
	#vision_bdr_website_7_pc_bike {
		fill: rgba(222,14,11,1);
	}
	.vision_bdr_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 662px;
		height: 146px;
		left: 8px;
		top: 413px;
	}
	#mask_top_visoon_img_website_7_ {
		position: absolute;
		width: 583px;
		height: 574px;
		left: 566px;
		top: 0px;
		overflow: visible;
	}
	#small_thumbnails_website_7_pc_ {
		position: absolute;
		width: 697px;
		height: 260px;
		left: 236px;
		top: 467px;
		overflow: visible;
	}
	#overall_bdr_thumb_website_7_pc {
		fill: rgba(21,21,21,1);
	}
	.overall_bdr_thumb_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 697px;
		height: 260px;
		left: 0px;
		top: 0px;
	}
	#img_2_website_7_pc_Group_3_bik {
		position: absolute;
		width: 273px;
		height: 220px;
		left: 318px;
		top: 20px;
		overflow: visible;
	}
	#website_7_pc_Group_4_bike {
		position: absolute;
		width: 272px;
		height: 220px;
		left: 24px;
		top: 20px;
		overflow: visible;
	}
	#arrow_website_7_pcbike {
		position: absolute;
		width: 48.28px;
		height: 12px;
		left: 619.72px;
		top: 130px;
		overflow: visible;
	}
	#Line_1_website_7_pc_bike {
		fill: transparent;
		stroke: rgba(222,14,11,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_1_website_7_pc_bike {
		overflow: visible;
		position: absolute;
		width: 41.18px;
		height: 2px;
		left: 0px;
		top: 5.737px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Polygon_2_website_7_pc_bike {
		fill: rgba(222,14,11,1);
	}
	.Polygon_2_website_7_pc_bike {
		overflow: visible;
		position: absolute;
		width: 12px;
		height: 10px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,37.2803,1.0005) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,37.2803,1.0005) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Vision_lbl_website_7_pc_bike {
		left: 8px;
		top: 119px;
		position: absolute;
		overflow: visible;
		width: 111px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 41px;
		color: rgba(0,0,0,1);
	}
	#vision_top_line_website_7_pcbi {
		fill: rgba(222,14,11,1);
	}
	.vision_top_line_website_7_pcbi {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 8px;
		top: 107px;
	}
	#Scroll_vision_intro_web_8_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 449px;
		height: 184px;
		left: 0px;
		top: 188px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#oncbike_struments_and_a_1_bike {
		left: 8px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 133px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#mision_with_img_grp_pc_8_web_b {
		position: absolute;
		width: 1472px;
		height: 563.371px;
		left: -366px;
		top: 1668px;
		overflow: visible;
	}
	#massimiliano-mor_bike_ISY-unsp {
		position: absolute;
		width: 1000px;
		height: 562.5px;
		left: 0px;
		top: 0.059px;
		overflow: visible;
	}
	#white_shade_website_7_pc_bike_cz {
		fill: url(#white_shade_website_7_pc_bike_cz);
	}
	.white_shade_website_7_pc_bike_cz {
		position: absolute;
		overflow: visible;
		width: 695px;
		height: 563px;
		left: 322px;
		top: 0px;
	}
	#mision_img_lines_grp_website_7 {
		fill: rgba(222,14,11,1);
	}
	.mision_img_lines_grp_website_7 {
		overflow: visible;
		position: absolute;
		width: 685.604px;
		height: 285.52px;
		left: 251.158px;
		top: 277.415px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Masterpiece_lbl_website_7_pc_b {
		left: 1033px;
		top: 113px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 9px;
		color: rgba(222,14,11,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#Mission_lbl_website_7_pcbike {
		left: 1033px;
		top: 158px;
		position: absolute;
		overflow: visible;
		width: 136px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#mision_like_website_7_pc_bike {
		fill: rgba(222,14,11,1);
	}
	.mision_like_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 1032px;
		top: 147px;
	}
	#Scroll_mission_web_8_pc_bike {
		mix-blend-mode: normal;
		position: absolute;
		width: 440px;
		height: 183px;
		left: 1032px;
		top: 227px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#An_aggregate_of_racing-lev_web {
		left: 3px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(116,116,116,1);
	}
	#line_3_missionwebsite_7_pc_bik {
		fill: rgba(222,14,11,1);
	}
	.line_3_missionwebsite_7_pc_bik {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.206px;
		left: 1032.437px;
		top: 503.071px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#line_2_mission_website_7_pc_bi {
		fill: rgba(222,14,11,1);
	}
	.line_2_mission_website_7_pc_bi {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.206px;
		left: 1032.437px;
		top: 531.751px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#line1_mission_website_7_pc_bik {
		fill: rgba(222,14,11,1);
	}
	.line1_mission_website_7_pc_bik {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.206px;
		left: 1032.437px;
		top: 561.165px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#kisspng-mercedes-ben_website_7 {
		position: absolute;
		width: 278.729px;
		height: 313.897px;
		left: 540.271px;
		top: 130.558px;
		overflow: visible;
	}
	#avaliable_card_bg_website_7_pc {
		fill: rgba(13,13,13,1);
	}
	.avaliable_card_bg_website_7_pc {
		width: 1975.545px;
		height: 405.723px;
		position: absolute;
		overflow: visible;
		-webkit-transform: matrix(1,0,0,1,-272.0388,3260.6196) rotate(-6deg);
		        transform: matrix(1,0,0,1,-272.0388,3260.6196) rotate(-6deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#Scroll_off_website_7_pc_bike {
		mix-blend-mode: normal;
		position: absolute;
		width: 1365.944px;
		height: 557.5px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		left: 0.5px;
		-webkit-transform: rotate(-6deg);
		        transform: rotate(-6deg);
		top: 3184.5px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#offering_card_1_shadow_space_w8 {
		position: relative;
		width: 230px;
		height: 325px;
		left: 66px;
		top: 120px;
		margin-left: 15px;
		overflow: visible;
	}
	#card_bg_offeringshadow_space_w8 {
		fill: rgba(255,255,255,1);
	}
	.card_bg_offeringshadow_space_w8 {
		-webkit-filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		        filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 245.5px;
		height: 340.5px;
		left: 0px;
		top: 0px;
	}
	#oofering_lbl_med_shadow_space_rr_pc {
		left: 18px;
		top: 292px;
		position: absolute;
		overflow: visible;
		width: 166px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#markus-shadow_space_website_7 {
		position: absolute;
		width: 204.834px;
		height: 270.879px;
		left: 11.5px;
		top: 11.17px;
		overflow: visible;
	}
	#Icon_awesome-car_website_7_pc {
		fill: rgba(0,0,0,1);
	}
	.Icon_awesome-car_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 36px;
		height: 27px;
		left: 7.5px;
		top: 23.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Avaliable_Now_lbl_website_7_pc {
		left: 535px;
		top: 3113px;
		position: absolute;
		overflow: visible;
		width: 298px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#header_all_grp_web_mob_8 {
		position: absolute;
		width: 1412px;
		height: 794px;
		left: -47px;
		top: -12px;
		overflow: visible;
	}
	#harley-davidson-eeTJKC_wz34-un {
		position: absolute;
		width: 1412px;
		height: 794px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#text_unlessh_website_7_pc_bike {
		position: absolute;
		width: 422px;
		height: 129px;
		left: 84px;
		top: 325px;
		overflow: visible;
	}
	#Unleash_the_True_Powe_website_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 228px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 41px;
		color: rgba(255,255,255,1);
	}
	#Ride_into_the_Future_Your_Ulti {
		left: 0px;
		top: 108px;
		position: absolute;
		overflow: visible;
		width: 423px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#pre-order_button_website_7_pc_ {
		position: absolute;
		width: 209px;
		height: 20px;
		left: 96px;
		top: 478px;
		overflow: visible;
	}
	#line_pre_website_7_pc_bike {
		fill: rgba(222,14,11,1);
	}
	.line_pre_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 3px;
		left: 0px;
		top: 9px;
	}
	#PRE-ORDER_NOW_website_7_pc_bik {
		left: 64px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 146px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(222,14,11,1);
		letter-spacing: 0.95px;
	}
	#zane-lee-bXbi1jJVDf0-unsplash_PC {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 137px;
		top: 33px;
		border-radius: 50%;
		overflow: hidden;
	}
	#footer_all_grp_web_mob_8 {
		position: absolute;
		width: 1555px;
		height: 571px;
		left: 39px;
		top: 3774px;
		overflow: visible;
	}
	#fotter_img_website_7_pc_bike {
		position: absolute;
		width: 677px;
		height: 497px;
		left: 878px;
		top: 0px;
		overflow: visible;
	}
	#shade_footer_design_website_7__du {
		fill: url(#shade_footer_design_website_7__du);
	}
	.shade_footer_design_website_7__du {
		position: absolute;
		overflow: visible;
		width: 454px;
		height: 427px;
		left: 869px;
		top: 26px;
	}
	#bdr_info_website_7_pc_bike {
		fill: rgba(21,21,21,1);
	}
	.bdr_info_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 493px;
		height: 454px;
		left: 554px;
		top: 117px;
	}
	#Call_us_for_questions_website_ {
		left: 628px;
		top: 141px;
		position: absolute;
		overflow: visible;
		width: 209px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#call_below_line_website_7_pc_b {
		fill: rgba(255,255,255,1);
	}
	.call_below_line_website_7_pc_b {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 627px;
		top: 180px;
	}
	#no_bdr_website_7_pc_bike {
		fill: rgba(222,14,11,1);
	}
	.no_bdr_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 60px;
		left: 627px;
		top: 200px;
	}
	#n_1_123-456-7890_website_7_pc_ {
		left: 696px;
		top: 216px;
		position: absolute;
		overflow: visible;
		width: 166px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#phone-receiver_bdr_website_7_p {
		fill: rgba(255,255,255,1);
	}
	.phone-receiver_bdr_website_7_p {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 60px;
		left: 627px;
		top: 200px;
	}
	#phone-receiver_website_7_pc_bi {
		fill: rgba(21,21,21,1);
	}
	.phone-receiver_website_7_pc_bi {
		overflow: visible;
		position: absolute;
		width: 23.454px;
		height: 23.438px;
		left: 645.163px;
		top: 217.434px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Email_us_for_inquiries_website {
		left: 628px;
		top: 276.999px;
		position: absolute;
		overflow: visible;
		width: 211px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#email_bdr_website_7_pc_bike {
		fill: rgba(255,255,255,1);
	}
	.email_bdr_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 627px;
		top: 316.001px;
	}
	#email_bdr_website_7_pc_bike_d {
		fill: rgba(222,14,11,1);
	}
	.email_bdr_website_7_pc_bike_d {
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 61px;
		left: 628px;
		top: 336.001px;
	}
	#emailmailcom_website_7_pc_bike {
		left: 697px;
		top: 352.999px;
		position: absolute;
		overflow: visible;
		width: 278px;
		height: 27px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
	}
	#mail_bdr_website_7_pc_bike {
		fill: rgba(255,255,255,1);
	}
	.mail_bdr_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 61px;
		left: 628px;
		top: 336px;
	}
	#envelope_website_7_pc_bike {
		position: absolute;
		width: 29.462px;
		height: 23.147px;
		left: 643.144px;
		top: 355.268px;
		overflow: visible;
	}
	#Path_15_website_7_pc_bike {
		fill: rgba(21,21,21,1);
	}
	.Path_15_website_7_pc_bike {
		overflow: visible;
		position: absolute;
		width: 29.462px;
		height: 14.73px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_16_website_7_pc_bike {
		fill: rgba(21,21,21,1);
	}
	.Path_16_website_7_pc_bike {
		overflow: visible;
		position: absolute;
		width: 29.462px;
		height: 15.685px;
		left: 0px;
		top: 7.463px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 528px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(173,173,173,1);
	}
	#rr_socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 160px;
		top: 437px;
		overflow: visible;
	}
	#linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.med_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.192px;
		overflow: visible;
	}
	#n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.671px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.809px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#shadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_7_eo:hover {
		fill: rgba(50,172,158,1);
	}
	.med_shadow_space_website_7_eo:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_7_eo {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_7_eo {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 11.993px;
		overflow: visible;
	}
	#gps_footer_website_7_pc_bike {
		position: absolute;
		width: 402px;
		height: 251px;
		left: 53px;
		top: 143px;
		overflow: visible;
	}
	#bdr_screen_website_7_pc_bike {
		fill: rgba(0,0,0,1);
	}
	.bdr_screen_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 402px;
		height: 251px;
		left: 0px;
		top: 0px;
	}
	#screen_map_website_7_pc_bike {
		fill: rgba(255,255,255,1);
	}
	.screen_map_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 385px;
		height: 211px;
		left: 8px;
		top: 7px;
	}
	#Icon_awesome-car_website_7_pc_ {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-car_website_7_pc_ {
		overflow: visible;
		position: absolute;
		width: 15.924px;
		height: 11.943px;
		left: 29.635px;
		top: 229.723px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-musicwebsite_7_pc {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-musicwebsite_7_pc {
		overflow: visible;
		position: absolute;
		width: 11.944px;
		height: 11.944px;
		left: 74.993px;
		top: 229.722px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-lbikewebsite_7_pc {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-lbikewebsite_7_pc {
		overflow: visible;
		position: absolute;
		width: 11.943px;
		height: 11.943px;
		left: 175.159px;
		top: 229.723px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_459321__website_7_pc_bike {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_459321__website_7_pc_bike {
		overflow: visible;
		position: absolute;
		width: 9.465px;
		height: 13.943px;
		left: 234.466px;
		top: 229.723px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_4601_website_7_pc_bike {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_4601_website_7_pc_bike {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 12.45px;
		left: 231.48px;
		top: 230.469px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_459_website_7_pc_bike {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_459_website_7_pc_bike {
		overflow: visible;
		position: absolute;
		width: 9.465px;
		height: 13.943px;
		left: 271.474px;
		top: 229.723px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_460_website_7_pc_bike {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_460_website_7_pc_bike {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 12.45px;
		left: 281.924px;
		top: 230.469px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-volume-up_website {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-volume-up_website {
		overflow: visible;
		position: absolute;
		width: 13.132px;
		height: 10.944px;
		left: 364.569px;
		top: 230.722px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Email_us_for_inquiries_website_e {
		left: 629px;
		top: 415px;
		position: absolute;
		overflow: visible;
		width: 211px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#line1_website_7_pc_bike {
		fill: rgba(255,255,255,1);
	}
	.line1_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 628px;
		top: 454px;
	}
	#address_bdr_lbl_website_7_pc_b {
		fill: rgba(222,14,11,1);
	}
	.address_bdr_lbl_website_7_pc_b {
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 61px;
		left: 629px;
		top: 474px;
	}
	#address_website_7_pc_bike {
		left: 698px;
		top: 474px;
		position: absolute;
		overflow: visible;
		width: 278px;
		height: 61px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
	}
	#address_icon_bdr_website_7_pc_ {
		fill: rgba(255,255,255,1);
	}
	.address_icon_bdr_website_7_pc_ {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 61px;
		left: 629px;
		top: 474px;
	}
	#Icon_material-edit-location_we8 {
		fill: rgba(0,0,0,1);
	}
	.Icon_material-edit-location_we8 {
		overflow: visible;
		position: absolute;
		width: 21px;
		height: 30px;
		left: 648px;
		top: 490px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Scroll_vision_intro_web_8_pc::-webkit-scrollbar{
		display: none
	}
#Scroll_mission_web_8_pc_bike::-webkit-scrollbar{
		display: none;
	}
#Scroll_prinic_web_8_pc_bike::-webkit-scrollbar{
		display: none;
	}
#Scroll_off_website_7_pc_bike::-webkit-scrollbar{
		display: none;
	}
#kisspng-mercedes-ben_website_7 { 
    -webkit-animation-name: floating; 
            animation-name: floating;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
@-webkit-keyframes floating {
    0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    50%  { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
    100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}
@keyframes floating {
    0% { -webkit-transform: translate(0,  0px); transform: translate(0,  0px); }
    50%  { -webkit-transform: translate(0, 20px); transform: translate(0, 20px); }
    100%   { -webkit-transform: translate(0, -0px); transform: translate(0, -0px); }   
}



	.mediaViewInfo {
		--web-view-name: Fashion Finds;
		--web-view-id: Fashion_Finds;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Fashion_Finds;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none
	}
	#Fashion_Finds {
		position: absolute;
		width: 1366px;
		height: 4075px;
        left:calc((100vw - 1366px)/2);
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Fashion Finds;
		--web-view-id: Fashion_Finds;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#footer_grp_web_9_pc {
		position: absolute;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 3664px;
		overflow: visible;
	}
	#socials_grp_med_web_9_pc {
		position: absolute;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#footer_web9pc {
		opacity: 0.93;
		fill: rgba(50,50,50,1);
	}
	.footer_web9pc {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 0px;
	}
	#n_Copyright_Operate_Live_websi_9_web_pc {
		left: 41.5px;
		top: 349.16px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#linked_in_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 202px;
		top: 262px;
		overflow: visible;
	}
	#Ellipse_5_med_web_9_pc:hover {
		fill: rgba(120,120,255,1);
	}
	.Ellipse_5_med_web_9_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_5_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_5_med_web_9_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_website_9_layout {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#n_website_2_layout_web_9_pc {
		fill: rgba(63,61,86,1);
	}
	.n_website_2_layout_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.953px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_down_web_9_pc {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_down_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.037px;
		left: 0.614px;
		top: 7.098px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_website_9_layout {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_website_9_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.217px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 122px;
		top: 262px;
		overflow: visible;
	}
	#Ellipse_4_med_web_9_pc:hover {
		fill: rgba(255,120,120,1);
	}
	.Ellipse_4_med_web_9_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_4_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_4_med_web_9_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagram_website_9_layout {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.125px;
		overflow: visible;
	}
	#whatsapp_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 42px;
		top: 262px;
		overflow: visible;
	}
	#Ellipse_3_med_web_9_pc:hover {
		fill: rgba(50,172,158,1);
	}
	.Ellipse_3_med_web_9_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_3_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_3_med_web_9_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_website9_layout {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#For_Queries_infoandroom_web_9_ {
		left: 68.526px;
		top: 132px;
		position: absolute;
		overflow: visible;
		width: 219px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#For_call__920012888_web_9_pc {
		left: 68.526px;
		top: 153px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#telephone_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.telephone_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.521px;
		left: 42px;
		top: 157.525px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#send_1_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.send_1_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.521px;
		left: 42px;
		top: 136.719px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Line_web_9_pc {
		fill: transparent;
		stroke: rgba(86,86,86,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 1365.56px;
		height: 2px;
		left: 0.155px;
		top: 336.113px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Icon_metro-locatio_web_9_pc_n {
		fill: rgba(255,255,255,1);
	}
	.Icon_metro-locatio_web_9_pc_n {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 18.209px;
		left: 42px;
		top: 178.33px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#address_lbl_web_9_pc {
		left: 68.526px;
		top: 177.539px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 62px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#background_img_web_9_pc {
		position: absolute;
		width: 566px;
		height: 365px;
		left: 780px;
		top: 26px;
		overflow: visible;
	}
	#display_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.display_web_9_pc {
		position: absolute;
		overflow: visible;
		width: 522px;
		height: 235px;
		left: 801px;
		top: 136px;
	}
	#Group_14856_web_9_pc {
		position: absolute;
		width: 1366px;
		height: 771px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_0230728085642_fpdlin_colorfu {
		position: absolute;
		width: 1366px;
		height: 766px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Rectangle_681 {
		opacity: 0.18;
		fill: rgba(0,0,0,1);
	}
	.Rectangle_681 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 771px;
		left: 0px;
		top: 0px;
	}
	#pfp_pic_web_9_pc {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 115px;
		top: 19px;
		overflow: visible;
	}
	#bdr_pfp_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.bdr_pfp_web_9_pc {
		position: absolute;
		overflow: visible;
		width: 70px;
		height: 70px;
		left: 0px;
		top: 0px;
	}
	#zane-lee-_web_9_pc {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 5px;
		top: 5px;
		border-radius: 50%;
		overflow: hidden;
	}
	#ACT_NOW__SEIZE_THE_DEAL_lbl_we {
		left: 120px;
		top: 240px;
		position: absolute;
		overflow: visible;
		width: 525px;
		white-space: nowrap;
		line-height: 83px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 75px;
		color: rgba(255,255,255,1);
	}
	#Sell_btn_web_9_pc {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 162px;
		top: 460.002px;
		overflow: visible;
	}
	#start_bidding_web_9_pc {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 1.5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.start_bidding_web_9_pc {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: -0.002px;
	}
	#START_BIDDING_web_9_pc_c {
		left: 28px;
		top: 10.998px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#vision_grp_web_9_pc {
		position: absolute;
		width: 1045px;
		height: 489px;
		left: 171px;
		top: 980px;
		overflow: visible;
	}
	#Scroll_Groupvision_web_9_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 550px;
		height: 287px;
		left: 495px;
		top: 150px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_Groupvision_web_9_pc::-webkit-scrollbar{
		display: none;
	}
	#BinQasem_provides_a_web_9_pc {
		left: 15px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 520px;
		height: 293px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#VISIon_ {
		left: 510px;
		top: 57px;
		position: absolute;
		overflow: visible;
		width: 148px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 46px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#Mask_Group_web_9_pc {
		position: absolute;
		width: 427px;
		height: 489px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#mission_grp_web_9_pc {
		position: absolute;
		width: 1029px;
		height: 489px;
		left: 171px;
		top: 1554px;
		overflow: visible;
	}
	#Scroll_Group_misiinweb_9_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 531px;
		height: 292px;
		left: 0px;
		top: 145px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_Group_misiinweb_9_pc::-webkit-scrollbar{
		display: none;
	}
	#BinQasem_provide_mission_web_9 {
		left: 0px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 520px;
		height: 293px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#Mission_lbl_web_9_pc {
		left: 0px;
		top: 57px;
		position: absolute;
		overflow: visible;
		width: 185px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 46px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#Mask_Group_web_9_pc_dj {
		position: absolute;
		width: 427px;
		height: 489px;
		left: 602px;
		top: 0px;
		overflow: visible;
	}
	#principle_group_web_9_pc {
		position: absolute;
		width: 1039px;
		height: 489px;
		left: 171px;
		top: 2128px;
		overflow: visible;
	}
	#Scroll_Group_prini_web_9_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 542px;
		height: 293px;
		left: 497px;
		top: 144px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_Group_prini_web_9_pc::-webkit-scrollbar{
		display: none;
	}
	#BinQasem_provi_web_9_pc {
		left: 13px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 520px;
		height: 293px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#PRINCIPLE_web_9_pc {
		left: 510px;
		top: 57px;
		position: absolute;
		overflow: visible;
		width: 212px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 46px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#Mask_Group_web_9_pc_do {
		position: absolute;
		width: 427px;
		height: 489px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Category_Section_web_9_pc {
		position: absolute;
		width: 1369.5px;
		height: 106px;
		left: 8px;
		top: 2813px;
		overflow: visible;
	}
	#Line_27 {
		fill: transparent;
		stroke: rgba(150,111,51,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 10;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_27 {
		overflow: visible;
		position: absolute;
		width: 1348.008px;
		height: 7px;
		left: 21.5px;
		top: 91.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Ellipse_44 {
		fill: rgba(150,111,51,1);
	}
	.Ellipse_44 {
		position: absolute;
		overflow: visible;
		width: 27px;
		height: 27px;
		left: 0px;
		top: 79px;
	}
	#our_services {
		left: 526px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 316px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 46px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#Scroll_our_services_web_9_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 1315px;
		height: 566px;
		left: 51px;
		top: 2882px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#hanger_cloth_grp_web_9_pc {
		position: relative;
		width: 325px;
		height: 519px;
		left: 29px;
		top: 0px;
		margin-right: 15px;
		overflow: visible;
	}
	#hanger_our_services_web_9_pc {
		position: absolute;
		width: 325px;
		height: 325px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Mask_Groupoff_img_web_9_pc {
		position: absolute;
		width: 307px;
		height: 366px;
		left: 9px;
		top: 153px;
		overflow: visible;
	}
	#Bunda_Clothes_lbl_web_9_pc {
		left: 14px;
		top: 463px;
		position: absolute;
		overflow: visible;
		width: 123px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
		text-transform: uppercase;
	}
	#bdr_for_bid_web_9_pc {
		fill: rgba(49,49,49,1);
	}
	.bdr_for_bid_web_9_pc {
		position: absolute;
		overflow: visible;
		width: 68px;
		height: 70px;
		left: 248px;
		top: 449px;
	}
	#law_6_bid_path_web_9_pc {
		position: absolute;
		width: 18.413px;
		height: 19.599px;
		left: 272.578px;
		top: 474.455px;
		overflow: visible;
	}
	#Path_6945_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.Path_6945_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 12.47px;
		height: 13.84px;
		left: 1.082px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_6946_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.Path_6946_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 8.859px;
		height: 7.48px;
		left: 9.554px;
		top: 7.797px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Path_6947_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.Path_6947_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 12.632px;
		height: 4.594px;
		left: 0px;
		top: 15.006px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	.mediaViewInfo {
		--web-view-name: Journey_Junkies;
		--web-view-id: Journey_Junkies;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Journey_Junkies;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Journey_Junkies {
		position: absolute;
		width: 1366px;
		height: 4086px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Journey_Junkies;
		--web-view-id: Journey_Junkies;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#Jrny_Jnks_Rectangle_682_cb {
		fill: url(#Rectangle_682_cb);
	}
	.Jrny_Jnks_Rectangle_682_cb {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 786px;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_path_3 {
		opacity: 0.19;
		fill: rgba(55,143,50,1);
	}
	.Jrny_Jnks_path_3 {
		overflow: visible;
		position: absolute;
		width: 477.724px;
		height: 462.343px;
		-webkit-transform: matrix(1,0,0,1,95.5598,127.4256) rotate(54deg);
		        transform: matrix(1,0,0,1,95.5598,127.4256) rotate(54deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_path_2 {
		opacity: 0.3;
		fill: rgba(55,143,50,1);
	}
	.Jrny_Jnks_path_2 {
		overflow: visible;
		position: absolute;
		width: 477.724px;
		height: 462.343px;
		-webkit-transform: matrix(1,0,0,1,95.5596,127.4255) rotate(15deg);
		        transform: matrix(1,0,0,1,95.5596,127.4255) rotate(15deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_path_1 {
		position: absolute;
		width: 462.891px;
		height: 447.988px;
		left: 102.731px;
		top: 134.244px;
		overflow: visible;
	}
	#Jrny_Jnks_explore_Unwind_Discover {
		-webkit-filter: drop-shadow(0px 16px 21px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 16px 21px rgba(0, 0, 0, 0.161));
		left: 652.246px;
		top: 101px;
		position: absolute;
		overflow: visible;
		width: 202px;
		white-space: nowrap;
		text-align: left;
		font-family: Square721 BT;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#Jrny_Jnks_Scroll_Group_19 {
		mix-blend-mode: normal;
		position: absolute;
		width: 502px;
		height: 119px;
		left: 639.246px;
		top: 332px;
		overflow: hidden;
	}
	#Jrny_Jnks_Lorem_ipsum_dolor_sit_amet_con {
		left: 14px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 487px;
		height: 167px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(125,125,125,1);
	}
	#Jrny_Jnks_Ellipse_1 {
		fill: rgba(255,136,0,1);
	}
	.Jrny_Jnks_Ellipse_1 {
		position: absolute;
		overflow: visible;
		width: 22px;
		height: 21px;
		left: 104.246px;
		top: 210px;
	}
	#Jrny_Jnks_Line_1 {
		fill: transparent;
		stroke: rgba(13,146,6,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Jrny_Jnks_Line_1 {
		overflow: visible;
		position: absolute;
		width: 4px;
		height: 172.795px;
		left: 625.23px;
		top: 331.407px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_joaquin-3VK6Urf2vE8-unsplash {
		-webkit-filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 231px;
		height: 120px;
		left: 625.246px;
		top: 548px;
		overflow: visible;
	}
	#Jrny_Jnks_ammie-ngo-vcu-OZBxxRk-unsplash {
		-webkit-filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 231px;
		height: 120px;
		left: 866.246px;
		top: 548px;
		overflow: visible;
	}
	#Jrny_Jnks_daniel-burka-facU72FcKBI-unspl {
		-webkit-filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 231px;
		height: 120px;
		left: 1107.246px;
		top: 548px;
		overflow: visible;
	}
	#Jrny_Jnks_Group_4 {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 1122px;
		top: 610px;
		overflow: visible;
	}
	#Jrny_Jnks_Ellipse_2 {
		opacity: 0.79;
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Ellipse_2 {
		-webkit-filter: drop-shadow(5px 0px 7px rgba(0, 0, 0, 0.529));
		        filter: drop-shadow(5px 0px 7px rgba(0, 0, 0, 0.529));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_Icon_feather-arrow-down-right {
		-webkit-transform: translate(0.411px, -0.412px) matrix(1,0,0,1,10.1247,16.2582) rotate(-45deg);
		        transform: translate(0.411px, -0.412px) matrix(1,0,0,1,10.1247,16.2582) rotate(-45deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		position: absolute;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_Path_2_cq {
		fill: transparent;
		stroke: rgba(13,146,6,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Jrny_Jnks_Path_2_cq {
		overflow: visible;
		position: absolute;
		width: 9.899px;
		height: 9.9px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_Group_5 {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 7.246px;
		top: 610px;
		overflow: visible;
	}
	#Jrny_Jnks_Ellipse_3 {
		opacity: 0.79;
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Ellipse_3 {
		-webkit-filter: drop-shadow(0px 7px 20px rgba(0, 0, 0, 0.271));
		        filter: drop-shadow(0px 7px 20px rgba(0, 0, 0, 0.271));
		width: 38px;
		height: 38px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-0.09px, -0.412px) matrix(1,0,0,1,0.0903,0.4121) rotate(90deg);
		        transform: translate(-0.09px, -0.412px) matrix(1,0,0,1,0.0903,0.4121) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#Jrny_Jnks_Group_1 {
		position: absolute;
		width: 16.469px;
		height: 16.469px;
		left: 10.67px;
		top: 10.348px;
		overflow: visible;
	}
	#Jrny_Jnks_Path_1_cu {
		fill: transparent;
		stroke: rgba(120,147,118,1);
		stroke-width: 3px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Jrny_Jnks_Path_1_cu {
		overflow: visible;
		position: absolute;
		width: 11.645px;
		height: 11.645px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,2.4118,2.4118) rotate(45deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,2.4118,2.4118) rotate(45deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_Path_2_cv {
		fill: transparent;
		stroke: rgba(120,147,118,1);
		stroke-width: 3px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Jrny_Jnks_Path_2_cv {
		overflow: visible;
		position: absolute;
		width: 7.793px;
		height: 7.793px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,4.3382,7.0624) rotate(45deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,4.3382,7.0624) rotate(45deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_Group_14866:hover {
		width: 135px;
		height: 42px;
		top: 462px;
	}
	#Jrny_Jnks_Group_14866 {
		position: absolute;
		width: 129px;
		height: 39px;
		left: 648px;
		top: 466px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#Jrny_Jnks_Rectangle_2_c {
		fill: rgba(255,136,0,1);
	}
	.Jrny_Jnks_Rectangle_2_c {
		position: absolute;
		overflow: visible;
		width: 129px;
		height: 39px;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_Check_Out {
		left: 23px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 84px;
		white-space: nowrap;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#Jrny_Jnks_Group_3 {
		position: absolute;
		width: 128px;
		height: 38px;
		left: 982.247px;
		top: 610px;
		overflow: visible;
	}
	#Jrny_Jnks_Rectangle_3 {
		opacity: 0.79;
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Rectangle_3 {
		-webkit-filter: drop-shadow(5px 0px 7px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 0px 7px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 149px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_See_all_photos {
		left: 16px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(13,146,6,1);
	}
	#Jrny_Jnks_Ellipse_4 {
		opacity: 0.79;
		fill: rgba(255,255,255,1);
		stroke: rgba(13,146,6,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Jrny_Jnks_Ellipse_4 {
		width: 38px;
		height: 38px;
		position: absolute;
		overflow: visible;
		-webkit-transform: matrix(1,0,0,1,7.2461,251) rotate(90deg);
		        transform: matrix(1,0,0,1,7.2461,251) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#Jrny_Jnks_Ellipse_5 {
		opacity: 0.79;
		fill: rgba(255,255,255,1);
		stroke: rgba(13,146,6,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Jrny_Jnks_Ellipse_5 {
		width: 38px;
		height: 38px;
		position: absolute;
		overflow: visible;
		-webkit-transform: matrix(1,0,0,1,7.2461,308) rotate(90deg);
		        transform: matrix(1,0,0,1,7.2461,308) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#Jrny_Jnks_Icon_awesome-facebook-f {
		fill: rgba(0,0,0,1);
	}
	.Jrny_Jnks_Icon_awesome-facebook-f {
		overflow: visible;
		position: absolute;
		width: 10.87px;
		height: 20.295px;
		left: 20.448px;
		top: 260.2px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_Icon_metro-twitter {
		fill: rgba(0,0,0,1);
	}
	.Jrny_Jnks_Icon_metro-twitter {
		overflow: visible;
		position: absolute;
		width: 19.525px;
		height: 15.868px;
		left: 16.65px;
		top: 319.064px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_Rectangle_5 {
		fill: rgba(120,147,118,1);
	}
	.Jrny_Jnks_Rectangle_5 {
		-webkit-filter: drop-shadow(5px 14px 15px rgba(0, 0, 0, 0.18));
		        filter: drop-shadow(5px 14px 15px rgba(0, 0, 0, 0.18));
		position: absolute;
		overflow: visible;
		width: 83px;
		height: 83px;
		left: 7.246px;
		top: 373px;
	}
	#Jrny_Jnks_Icon_simple-whatsapp {
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Icon_simple-whatsapp {
		overflow: visible;
		position: absolute;
		width: 22.786px;
		height: 22.786px;
		left: 15.02px;
		top: 381.094px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_leaf {
		position: absolute;
		width: 383.232px;
		height: 383.232px;
		left: 1013.884px;
		top: -24.635px;
		overflow: visible;
	}
	#Jrny_Jnks_Group_14870 {
		position: absolute;
		width: 820px;
		height: 451px;
		left: 273px;
		top: 786px;
		overflow: visible;
	}
	#Jrny_Jnks_Rectangle_683 {
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Rectangle_683 {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 746px;
		height: 358px;
		left: 92px;
		top: 56px;
	}
	#Jrny_Jnks_pietro-de-grandi-T7K4aEPoGGk-u {
		position: absolute;
		width: 334px;
		height: 451px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_VISION {
		left: 389px;
		top: 89px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#Jrny_Jnks_Lorem_ipsum_dolor_sit_amet_con_dj {
		left: 389px;
		top: 156px;
		position: absolute;
		overflow-x: hidden;
		overflow-y: scroll;

		width: 380px;
		height: 197px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#Jrny_Jnks_Group_14869 {
		position: absolute;
		width: 100%;
		height: 464px;
		/* left: 262px; */
		top: 1337px;
		overflow: visible;
	}
	#Jrny_Jnks_Rectangle_684 {
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Rectangle_684 {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 766px;
		height: 367px;
		left: 0px;
		top: 58px;
	}
	#Jrny_Jnks_jake-blucker-tMzCrBkM99Y-unspl_dq {
		position: absolute;
		width: 344px;
		height: 464px;
		left: 499px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_MISSION {
		left: 54px;
		top: 83px;
		position: absolute;
		overflow: visible;
		width: 167px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 39px;
		color: rgba(0,0,0,1);
	}
	#Jrny_Jnks_Lorem_ipsum_dolor_sit_amet_con_ds {
		left: 54px;
		top: 160px;
		position: absolute;
		overflow-x: hidden;
		overflow-y: scroll;
		width: 391px;
		height: 203px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#Jrny_Jnks_Rectangle_685 {
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Rectangle_685 {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 766px;
		height: 367px;
		left: 0px;
		top: 58px;
	}
	#Jrny_Jnks_jake-blucker-tMzCrBkM99Y-unspl_dq {
		position: absolute;
		width: 344px;
		height: 464px;
		left: 499px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_MISSION_dr {
		left: 54px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 133px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#Jrny_Jnks_Lorem_ipsum_dolor_sit_amet_con_ds {
		left: 54px;
		top: 160px;
		position: absolute;
		overflow-x: hidden;
		overflow-y: scroll;
		width: 391px;
		height: 203px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#Jrny_Jnks_Group_14868 {
		position: absolute;
		width: 843px;
		height: 464px;
		left: 262px;
		top: 1901px;
		overflow: visible;
	}
	#Jrny_Jnks_Rectangle_686 {
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Rectangle_686 {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 766px;
		height: 368px;
		left: 95px;
		top: 58px;
	}
	#Jrny_Jnks_tom-barrett-M0AWNxnLaMw-unspla {
		position: absolute;
		width: 344px;
		height: 464px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_PRINCIPLES {
		left: 399px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 173px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#Jrny_Jnks_Lorem_ipsum_dolor_sit_amet_con_dx {
		left: 399px;
		top: 160px;
		position: absolute;
		overflow-x : hidden;
		overflow-y : scroll;
		width: 391px;
		height: 203px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}

	#Jrny_Jnks_Group_14871 {
		position: absolute;
		width: 100%;
		height: 525px;
		left: 0px;
		top: 1460px;
		overflow: visible;
	}
	#Jrny_Jnks_taneli-lahtinen-2gJJrH4OnNk-un {
		position: absolute;
		width: 100%;
		height: 525px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_Scroll_Group_23 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 427px;
		/* left: 71px; */
		top: 60px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#Jrny_Jnks_Group_14872 {
		position: relative;
		width: 250px;
		height: 350px;
		left: 41px;
		top: 28px;
		margin-right: 50px;
		overflow: visible;
	}
	#Jrny_Jnks_Rectangle_688 {
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Rectangle_688 {
		position: absolute;
		overflow: visible;
		width: 250px;
		height: 350px;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_dariusz-sankowski-3OiYMgDKJ6k- {
		position: absolute;
		width: 220px;
		height: 296px;
		left: 15px;
		top: 43px;
		overflow: visible;
	}
	#Jrny_Jnks_Travel_One {
		left: 15px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 221px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}

	#Jrny_Jnks_FIND_POPULAR_DESTINATION_ {
		left: 450px;
		top: 2619px;
		position: absolute;
		overflow: visible;
		width: 468px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#Jrny_Jnks_Line_28 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Jrny_Jnks_Line_28 {
		overflow: visible;
		position: absolute;
		width: 1269px;
		height: 2px;
		left: 48.5px;
		top: 3524.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 791px;
		top: 3899px;
		overflow: visible;
	}
	#Jrny_Jnks_linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_med_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.Jrny_Jnks_med_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Jrny_Jnks_med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Jrny_Jnks_med_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#Jrny_Jnks_linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.191px;
		overflow: visible;
	}
	#Jrny_Jnks_n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.Jrny_Jnks_n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.Jrny_Jnks_linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.Jrny_Jnks_top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_shadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.Jrny_Jnks_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Jrny_Jnks_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Jrny_Jnks_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#Jrny_Jnks_instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#Jrny_Jnks_whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_med_shadow_space_website_7_ej:hover {
		fill: rgba(50,172,158,1);
	}
	.Jrny_Jnks_med_shadow_space_website_7_ej:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Jrny_Jnks_med_shadow_space_website_7_ej {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Jrny_Jnks_med_shadow_space_website_7_ej {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#Jrny_Jnks_whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#Jrny_Jnks_Group_14873 {
		position: absolute;
		width: 638px;
		height: 712px;
		left: 49px;
		top: 3639px;
		overflow: visible;
	}
	#Jrny_Jnks_kisspng-billboard-advertising- {
		position: absolute;
		width: 638px;
		height: 712px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_Rectangle_689 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Jrny_Jnks_Rectangle_689 {
		position: absolute;
		overflow: visible;
		width: 617px;
		height: 286px;
		left: 11px;
		top: 44px;
	}
	#Jrny_Jnks_n_234567890_mobile_number_shad {
		left: 826px;
		top: 3640px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Jrny_Jnks_affanghanioperatelive_email_sh {
		left: 831px;
		top: 3700px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Jrny_Jnks_address_med_shadow_space_websi {
		left: 826px;
		top: 3759px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Jrny_Jnks_location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 791px;
		top: 3759px;
		overflow: visible;
	}
	#Jrny_Jnks_email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 791px;
		top: 3699px;
		overflow: visible;
	}
	#Jrny_Jnks_phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 791px;
		top: 3639px;
		overflow: visible;
	}
	#Jrny_Jnks_n_Copyright_Operate_Live_OPC_P {
		left: 791px;
		top: 3984px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(173,173,173,1);
	}
	.mediaViewInfo {
		--web-view-name: MobileStock;
		--web-view-id: MobileStock;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: MobileStock;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#MobileStock {
		position: absolute;
		width: 1366px;
		height: 4235.064px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(249,249,255,1);
		overflow: hidden;
		--web-view-name: MobileStock;
		--web-view-id: MobileStock;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#theme11_stock_pcstyle {
		position: absolute;
		width: 508.51px;
		height: 508.613px;
		left: 0px;
		top: 3089.662px;
		overflow: visible;
	}
	#theme11_stock_pcPath_28 {
		fill: rgba(13,19,215,1);
	}
	.theme11_stock_pcPath_28 {
		overflow: visible;
		position: absolute;
		width: 504.926px;
		height: 508.612px;
		left: 0px;
		top: 0.001px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pcEllipse_6 {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_6 {
		position: absolute;
		overflow: visible;
		width: 423.161px;
		height: 423.16px;
		left: 85.349px;
		top: 52.068px;
	}
	#theme11_stock_pcEllipse_5 {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_5 {
		position: absolute;
		overflow: visible;
		width: 240.986px;
		height: 240.987px;
		left: 176.437px;
		top: 143.155px;
	}
	#theme11_stock_pcGroup_14880 {
		position: absolute;
		width: 1264px;
		height: 559.17px;
		left: 0px;
		top: 2175.053px;
		overflow: visible;
	}
	#theme11_stock_pcstyle_ci {
		position: absolute;
		width: 508.51px;
		height: 508.613px;
		left: 0px;
		top: 24.894px;
		overflow: visible;
	}
	#theme11_stock_pcPath_28_cj {
		fill: rgba(13,19,215,1);
	}
	.theme11_stock_pcPath_28_cj {
		overflow: visible;
		position: absolute;
		width: 504.926px;
		height: 508.612px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pcEllipse_6_ck {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_6_ck {
		position: absolute;
		overflow: visible;
		width: 423.161px;
		height: 423.16px;
		left: 85.349px;
		top: 52.067px;
	}
	#theme11_stock_pcEllipse_5_cl {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_5_cl {
		position: absolute;
		overflow: visible;
		width: 240.986px;
		height: 240.986px;
		left: 176.437px;
		top: 143.155px;
	}
	#theme11_stock_pcjoel-rohland-MCL2xxjZJvs-unspl {
		position: absolute;
		width: 347.971px;
		height: 559.17px;
		left: 215.167px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcScroll_Group_26 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 291px;
		left: 671px;
		top: 176.208px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#theme11_stock_pcScroll_Group_26::-webkit-scrollbar{
		display: none;
	}
	#theme11_stock_pcIt_is_a_long_established_fact_ {
		left: 12px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#theme11_stock_pcPRINCIPLES {
		left: 683px;
		top: 124.208px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_pcButtom {
		position: absolute;
		width: 1377px;
		height: 400px;
		left: 0px;
		top: 3847px;
		overflow: visible;
	}
	#theme11_stock_pcRectangle_53 {
		fill: rgba(235,235,248,1);
	}
	.theme11_stock_pcRectangle_53 {
		position: absolute;
		overflow: visible;
		width: 1377px;
		height: 400px;
		left: 0px;
		top: 0px;
	}
	#theme11_stock_pcour_Services {
		left: 584px;
		top: 2892px;
		position: absolute;
		overflow: visible;
		width: 199px;
		white-space: nowrap;
		line-height: 43px;
		margin-top: -4px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#theme11_stock_pcBanner_Slider {
		position: absolute;
		width: 1377.065px;
		height: 718.641px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcPath_2 {
		fill: rgba(60,65,223,1);
	}
	.theme11_stock_pcPath_2 {
		overflow: visible;
		position: absolute;
		width: 1377.064px;
		height: 718.641px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pcdugba-cauley-hushie-6MNmDi1hc_ {
		position: absolute;
		width: 1377.064px;
		height: 672.275px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcPath_1527 {
		opacity: 0.21;
		fill: rgba(38,43,219,1);
	}
	.theme11_stock_pcPath_1527 {
		overflow: visible;
		position: absolute;
		width: 1377.064px;
		height: 672.275px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pcYour_Mobile_Paradise__Where_Dr {
		left: 290px;
		top: 255px;
		position: absolute;
		overflow: visible;
		width: 491px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 39px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#theme11_stock_pcBtg {
		position: absolute;
		width: 179px;
		height: 63px;
		left: 290px;
		top: 412.001px;
		overflow: visible;
	}
	#theme11_stock_pcRectangle_77 {
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcRectangle_77 {
		position: absolute;
		overflow: visible;
		width: 179px;
		height: 63px;
		left: 0px;
		top: -0.001px;
	}
	#theme11_stock_pcExplore_More {
		left: 40px;
		top: 19.999px;
		position: absolute;
		overflow: visible;
		width: 101px;
		white-space: nowrap;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#theme11_stock_pcGroup_14881 {
		position: absolute;
		width: 1276px;
		height: 559.17px;
		left: 90px;
		top: 1508.888px;
		overflow: visible;
	}
	#theme11_stock_pcstyle_c {
		position: absolute;
		width: 508.51px;
		height: 508.613px;
		left: 767.49px;
		top: 24.894px;
		overflow: visible;
	}
	#theme11_stock_pcPath_28_c {
		fill: rgba(13,19,215,1);
	}
	.theme11_stock_pcPath_28_c {
		overflow: visible;
		position: absolute;
		width: 504.926px;
		height: 508.613px;
		left: 3.584px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pcEllipse_6_c {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_6_c {
		position: absolute;
		overflow: visible;
		width: 423.161px;
		height: 423.16px;
		left: 0px;
		top: 52.068px;
	}
	#theme11_stock_pcEllipse_5_c {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_5_c {
		position: absolute;
		overflow: visible;
		width: 240.986px;
		height: 240.986px;
		left: 91.087px;
		top: 143.155px;
	}
	#theme11_stock_pcneil-soni-6wdRuK7bVTE-unsplash {
		position: absolute;
		width: 347.971px;
		height: 559.17px;
		left: 646.28px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcScroll_Group_27 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 189.208px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#theme11_stock_pcScroll_Group_27::-webkit-scrollbar{
		display: none;
	}
	#theme11_stock_pcIt_is_a_long_established_fact__c {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#theme11_stock_pcMISSION {
		left: 18px;
		top: 124.208px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_pcGroup_14882 {
		position: absolute;
		width: 1264px;
		height: 559.165px;
		left: 0px;
		top: 842.84px;
		overflow: visible;
	}
	#theme11_stock_pcstyle_db {
		position: absolute;
		width: 508.51px;
		height: 508.613px;
		left: 0px;
		top: 24.776px;
		overflow: visible;
	}
	#theme11_stock_pcPath_28_dc {
		fill: rgba(13,19,215,1);
	}
	.theme11_stock_pcPath_28_dc {
		overflow: visible;
		position: absolute;
		width: 504.926px;
		height: 508.612px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pcEllipse_6_dd {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_6_dd {
		position: absolute;
		overflow: visible;
		width: 423.161px;
		height: 423.16px;
		left: 85.349px;
		top: 52.067px;
	}
	#theme11_stock_pcEllipse_5_de {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_5_de {
		position: absolute;
		overflow: visible;
		width: 240.986px;
		height: 240.986px;
		left: 176.437px;
		top: 143.155px;
	}
	#theme11_stock_pcdaniel-korpai-H0vKZjt2k1E-unsp {
		position: absolute;
		width: 347.554px;
		height: 559.165px;
		left: 238.167px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 671px;
		top: 189.091px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#theme11_stock_pcScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#theme11_stock_pcIt_is_a_long_established_fact__dh {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#theme11_stock_pcVISION {
		left: 689px;
		top: 124.091px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_pcsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 821px;
		top: 4112px;
		overflow: visible;
	}
	#theme11_stock_pclinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.theme11_stock_pcmed_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#theme11_stock_pcmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.theme11_stock_pcmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#theme11_stock_pclinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#theme11_stock_pcn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.theme11_stock_pcn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pclinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.theme11_stock_pclinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.809px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pctop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.theme11_stock_pctop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pcInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.theme11_stock_pcshadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#theme11_stock_pcshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.theme11_stock_pcshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#theme11_stock_pcinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#theme11_stock_pcwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcmed_shadow_space_website_7_dw:hover {
		fill: rgba(50,172,158,1);
	}
	.theme11_stock_pcmed_shadow_space_website_7_dw:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#theme11_stock_pcmed_shadow_space_website_7_dw {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.theme11_stock_pcmed_shadow_space_website_7_dw {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#theme11_stock_pcwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#theme11_stock_pcn_234567890_mobile_number_shad {
		left: 856px;
		top: 3881px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_pcaffanghanioperatelive_email_sh {
		left: 861px;
		top: 3934px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_pcaddress_med_shadow_space_websi {
		left: 856px;
		top: 3984px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_pclocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 821px;
		top: 3986px;
		overflow: visible;
	}
	#theme11_stock_pcemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 821px;
		top: 3933px;
		overflow: visible;
	}
	#theme11_stock_pcphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 821px;
		top: 3880px;
		overflow: visible;
	}
	#theme11_stock_pcn_Copyright_Operate_Live_OPC_P {
		left: 821px;
		top: 4188px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(173,173,173,1);
	}
	#theme11_stock_pcTitle {
		left: 722px;
		top: 3186px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_pcScroll_Group_29 {
		mix-blend-mode: normal;
		position: absolute;
		width: 553px;
		height: 260px;
		left: 711px;
		top: 3229px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#theme11_stock_pcScroll_Group_29::-webkit-scrollbar{
		display: none
	}
	#theme11_stock_pcDescription {
		left: 10px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 520px;
		height: 235px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_pckisspng-laptop-macbook-pro-mac {
		position: absolute;
		width: 568px;
		height: 348px;
		left: 115px;
		top: 3873px;
		overflow: visible;
	}
	#theme11_stock_pcRectangle_690 {
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcRectangle_690 {
		position: absolute;
		overflow: visible;
		width: 349px;
		height: 225px;
		left: 184px;
		top: 3931px;
	}
	#theme11_stock_pcEllipse_62 {
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_62 {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 78px;
		left: 10px;
		top: 7px;
	}
	#theme11_stock_pcjakob-rosen-FsBbavP9YA4-unspla {
		position: absolute;
		width: 88px;
		height: 88px;
		left: 19px;
		top: 2px;
        border-radius: 50%;
		overflow: hidden;
	}
	#theme11_stock_pcGroup_14894 {
		position: absolute;
		width: 685.665px;
		height: 738.866px;
		left: 25.225px;
		top: 2974.536px;
		overflow: visible;
	}
	#theme11_stock_pcGroup_14893 {
		position: absolute;
		width: 685.665px;
		height: 738.866px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcGroup_14885 {
		opacity: 0.74;
		position: absolute;
		width: 412.39px;
		height: 662.688px;
		left: 273.275px;
		top: 47.179px;
		overflow: visible;
	}
	#theme11_stock_pciPhone_X_-_Light2x {
		position: absolute;
		width: 412.39px;
		height: 662.688px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcdariusz-sankowski-3OiYMgDKJ6k- {
		position: absolute;
		width: 230.106px;
		height: 410.461px;
		left: 91.165px;
		top: 127.857px;
		overflow: visible;
	}
	#theme11_stock_pcGroup_14884 {
		opacity: 0.74;
		position: absolute;
		width: 412.39px;
		height: 662.688px;
		left: 0px;
		top: 47.179px;
		overflow: visible;
	}
	#theme11_stock_pciPhone_X_-_Light2x_ej {
		position: absolute;
		width: 412.39px;
		height: 662.688px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcdariusz-sankowski-3OiYMgDKJ6k-_ek {
		position: absolute;
		width: 230.106px;
		height: 410.461px;
		left: 91.165px;
		top: 127.857px;
		overflow: visible;
	}
	#theme11_stock_pcGroup_14883 {
		position: absolute;
		width: 459.795px;
		height: 738.866px;
		left: 119.594px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pciPhone_X_-_Light2x_em {
		position: absolute;
		width: 459.795px;
		height: 738.866px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcdariusz-sankowski-3OiYMgDKJ6k-_en {
		position: absolute;
		width: 256.558px;
		height: 457.644px;
		left: 101.645px;
		top: 142.555px;
		overflow: visible;
	}
	#theme11_stock_pcPolygon_1 {
		fill: rgba(0,0,0,0.886);
	}
	.theme11_stock_pcPolygon_1 {
		overflow: visible;
		position: absolute;
		width: 40px;
		height: 37.6px;
		-webkit-transform: translate(-25.225px, -2974.536px) matrix(1,0,0,1,35.3,3325.2) rotate(-90deg);
		        transform: translate(-25.225px, -2974.536px) matrix(1,0,0,1,35.3,3325.2) rotate(-90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#theme11_stock_pcPolygon_2 {
		fill: rgba(0,0,0,0.886);
	}
	.theme11_stock_pcPolygon_2 {
		overflow: visible;
		position: absolute;
		width: 40px;
		height: 37.6px;
		-webkit-transform: translate(-25.225px, -2974.536px) matrix(1,0,0,1,654.7,3325.2) rotate(90deg);
		        transform: translate(-25.225px, -2974.536px) matrix(1,0,0,1,654.7,3325.2) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	.mediaViewInfo {
		--web-view-name: Paw Palace;
		--web-view-id: Paw_Palace;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Paw_Palace;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#Paw_Palace {
		position: absolute;
		width: 1366px;
		height: 3223.5px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(243,246,251,1);
		overflow: hidden;
		--web-view-name: Paw_Palace;
		--web-view-id: Paw_Palace;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#petpaws_12_pcGroup_76 {
		position: absolute;
		width: 1876.009px;
		height: 1030.802px;
		left: -140.268px;
		top: -346.32px;
		overflow: visible;
	}
	#petpaws_12_pcPath_261 {
		fill: rgba(255,103,82,1);
	}
	.petpaws_12_pcPath_261 {
		overflow: visible;
		position: absolute;
		width: 1876.009px;
		height: 912.367px;
		left: 0px;
		top: 118.436px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcPath_262_ci {
		opacity: 0.15;
		fill: url(#petpaws_12_pcPath_262_ci);
	}
	.petpaws_12_pcPath_262_ci {
		mix-blend-mode: multiply;
		overflow: visible;
		position: absolute;
		width: 1740.856px;
		height: 831.209px;
		left: 113.22px;
		top: 118.094px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcPath_263_ck {
		opacity: 0.15;
		fill: url(#petpaws_12_pcPath_263_ck);
	}
	.petpaws_12_pcPath_263_ck {
		mix-blend-mode: multiply;
		overflow: visible;
		position: absolute;
		width: 1804.992px;
		height: 911.309px;
		left: 53.47px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcGroup_75 {
		position: absolute;
		width: 2195.199px;
		height: 1167.126px;
		left: -312.123px;
		top: 603.555px;
		overflow: visible;
	}
	#petpaws_12_pcPath_251 {
		fill: rgba(68,135,255,1);
	}
	.petpaws_12_pcPath_251 {
		overflow: visible;
		position: absolute;
		width: 2195.199px;
		height: 1167.125px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcMask_Group_6 {
		opacity: 0.2;
		mix-blend-mode: multiply;
		position: absolute;
		width: 2195.199px;
		height: 1167.127px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcMask_Group_5 {
		opacity: 0.15;
		mix-blend-mode: multiply;
		position: absolute;
		width: 2195.199px;
		height: 1167.127px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_85 {
		position: absolute;
		width: 944px;
		height: 946px;
		left: 1020.983px;
		top: 1734.998px;
		overflow: visible;
	}
	#petpaws_12_pcEllipse_16 {
		fill: rgba(255,229,48,1);
	}
	.petpaws_12_pcEllipse_16 {
		position: absolute;
		overflow: visible;
		width: 944px;
		height: 946px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcEllipse_20_cs {
		opacity: 0.15;
		fill: url(#petpaws_12_pcEllipse_20_cs);
	}
	.petpaws_12_pcEllipse_20_cs {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 732px;
		height: 733px;
		left: 68px;
		top: 0px;
	}
	#petpaws_12_pcEllipse_21_cu {
		opacity: 0.15;
		fill: url(#petpaws_12_pcEllipse_21_cu);
	}
	.petpaws_12_pcEllipse_21_cu {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 560px;
		height: 561px;
		left: 154px;
		top: 0px;
	}
	#petpaws_12_pcGroup_96 {
		-webkit-transform: matrix(1,0,0,1,-39.7714,2406.1368) rotate(105deg);
		        transform: matrix(1,0,0,1,-39.7714,2406.1368) rotate(105deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		position: absolute;
		width: 283.628px;
		height: 283.992px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcEllipse_16_cw {
		fill: rgba(255,229,48,1);
	}
	.petpaws_12_pcEllipse_16_cw {
		position: absolute;
		overflow: visible;
		width: 347.724px;
		height: 347.465px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcEllipse_20_cy {
		opacity: 0.15;
		fill: url(#petpaws_12_pcEllipse_20_cy);
	}
	.petpaws_12_pcEllipse_20_cy {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 269.43px;
		height: 269.688px;
		left: 73.016px;
		top: 36.279px;
	}
	#petpaws_12_pcEllipse_21_c {
		opacity: 0.15;
		fill: url(#petpaws_12_pcEllipse_21_c);
	}
	.petpaws_12_pcEllipse_21_c {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 206.461px;
		height: 206.461px;
		left: 129.295px;
		top: 74.535px;
	}
	#petpaws_12_pcGroup_56 {
		position: absolute;
		width: 288px;
		height: 220.001px;
		left: 163.073px;
		top: 245.561px;
		overflow: visible;
	}
	#petpaws_12_pcGet_A_Pet_Now {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(255,255,255,1);
	}
	#petpaws_12_pcPawsitively_Devoted__Your_Pets {
		left: 0px;
		top: 82px;
		position: absolute;
		overflow: visible;
		width: 263px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#petpaws_12_pcGroup_55 {
		position: absolute;
		width: 168px;
		height: 53px;
		left: 0px;
		top: 167px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_1 {
		position: absolute;
		width: 168px;
		height: 53px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcRectangle_21 {
		-webkit-filter: blur(50px);
		        filter: blur(50px);
		fill: rgba(68,135,255,0.341);
	}
	.petpaws_12_pcRectangle_21 {
		-webkit-filter: drop-shadow(0px 10px 10px rgba(68, 135, 255, 1));
		        filter: drop-shadow(0px 10px 10px rgba(68, 135, 255, 1));
		position: absolute;
		overflow: visible;
		width: 198px;
		height: 83px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcRectangle_1 {
		fill: rgba(68,135,255,1);
	}
	.petpaws_12_pcRectangle_1 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(44, 29, 173, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(44, 29, 173, 0.161));
		position: absolute;
		overflow: visible;
		width: 186px;
		height: 71px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcExplore_More {
		left: 38px;
		top: 17px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(248,248,248,1);
	}
	#petpaws_12_pcGroup_101 {
		position: absolute;
		width: 1120px;
		height: 438.001px;
		left: 123px;
		top: 1683px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_100 {
		position: absolute;
		width: 1120px;
		height: 289.001px;
		left: 0px;
		top: 149px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_99 {
		position: absolute;
		width: 1120px;
		height: 45px;
		left: 0px;
		top: 131.002px;
		overflow: visible;
	}
	#petpaws_12_pcEllipse_10 {
		fill: rgba(255,103,82,1);
	}
	.petpaws_12_pcEllipse_10 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(253, 104, 69, 0.302));
		        filter: drop-shadow(0px 3px 6px rgba(253, 104, 69, 0.302));
		position: absolute;
		overflow: visible;
		width: 63px;
		height: 63px;
		left: 1075px;
		top: 0px;
	}
	#petpaws_12_pcPath_201 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaws_12_pcPath_201 {
		overflow: visible;
		position: absolute;
		width: 22.479px;
		height: 2px;
		left: 1087.087px;
		top: 22.23px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcPath_202 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaws_12_pcPath_202 {
		overflow: visible;
		position: absolute;
		width: 7.033px;
		height: 12.066px;
		left: 1102.947px;
		top: 17.752px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcEllipse_10_df {
		fill: rgba(255,103,82,1);
	}
	.petpaws_12_pcEllipse_10_df {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(253, 104, 69, 0.302));
		        filter: drop-shadow(0px 3px 6px rgba(253, 104, 69, 0.302));
		position: absolute;
		overflow: visible;
		width: 63px;
		height: 63px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcPath_201_dg {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaws_12_pcPath_201_dg {
		overflow: visible;
		position: absolute;
		width: 22.479px;
		height: 2px;
		left: 12.9px;
		top: 22.23px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcPath_202_dh {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaws_12_pcPath_202_dh {
		overflow: visible;
		position: absolute;
		width: 7.034px;
		height: 12.066px;
		left: 12.9px;
		top: 17.752px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcGroup_14918 {
		position: absolute;
		width: 388px;
		height: 229.001px;
		left: 88px;
		top: 39px;
		overflow: visible;
	}
	#petpaws_12_pcRectangle_22 {
		-webkit-filter: blur(15px);
		        filter: blur(15px);
		fill: rgba(168,158,255,1);
	}
	.petpaws_12_pcRectangle_22 {
		-webkit-filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		        filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		position: absolute;
		overflow: visible;
		width: 522px;
		height: 369px;
		left: 8px;
		top: 5px;
	}
	#petpaws_12_pcRectangle_15 {
		fill: rgba(253,104,69,1);
	}
	.petpaws_12_pcRectangle_15 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		        filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		position: absolute;
		overflow: visible;
		width: 406px;
		height: 247px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcMask_Group_7 {
		opacity: 0.1;
		mix-blend-mode: multiply;
		position: absolute;
		width: 388px;
		height: 229px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcMask_Group_8 {
		opacity: 0.15;
		mix-blend-mode: multiply;
		position: absolute;
		width: 388px;
		height: 229px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_47 {
		position: absolute;
		width: 290px;
		height: 182.001px;
		left: 18px;
		top: 11px;
		overflow: visible;
	}
	#petpaws_12_pcKenned_Dsouza {
		left: 115.817px;
		top: 81.48px;
		position: absolute;
		overflow: visible;
		width: 111px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(248,248,248,1);
	}
	#petpaws_12_pcn_I_like_that_I_got_to_meet_th {
		opacity: 0.8;
		left: 0px;
		top: 122px;
		position: absolute;
		overflow: visible;
		width: 291px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		color: rgba(248,248,248,1);
	}
	#petpaws_12_pcdownload_2 {
		position: absolute;
		width: 170px;
		height: 71px;
		left: 47px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_14917 {
		position: absolute;
		width: 388px;
		height: 229.001px;
		left: 645px;
		top: 39px;
		overflow: visible;
	}
	#petpaws_12_pcRectangle_22_ds {
		-webkit-filter: blur(15px);
		        filter: blur(15px);
		fill: rgba(168,158,255,1);
	}
	.petpaws_12_pcRectangle_22_ds {
		-webkit-filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		        filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		position: absolute;
		overflow: visible;
		width: 522px;
		height: 369px;
		left: 8px;
		top: 5px;
	}
	#petpaws_12_pcRectangle_15_dt {
		fill: rgba(253,104,69,1);
	}
	.petpaws_12_pcRectangle_15_dt {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		        filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		position: absolute;
		overflow: visible;
		width: 406px;
		height: 247px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcMask_Group_7_du {
		opacity: 0.1;
		mix-blend-mode: multiply;
		position: absolute;
		width: 388px;
		height: 229px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcMask_Group_8_dv {
		opacity: 0.15;
		mix-blend-mode: multiply;
		position: absolute;
		width: 388px;
		height: 229px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_47_dw {
		position: absolute;
		width: 318px;
		height: 182.001px;
		left: 18px;
		top: 11px;
		overflow: visible;
	}
	#petpaws_12_pcKenned_Dsouza_dx {
		left: 116.249px;
		top: 81.48px;
		position: absolute;
		overflow: visible;
		width: 111px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(248,248,248,1);
	}
	#petpaws_12_pcn_I_like_that_I_got_to_meet_th_dy {
		opacity: 0.8;
		left: 0px;
		top: 122px;
		position: absolute;
		overflow: visible;
		width: 291px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		color: rgba(248,248,248,1);
	}
	#petpaws_12_pcdownload_2_dz {
		position: absolute;
		width: 170px;
		height: 71px;
		left: 148px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_14916 {
		position: absolute;
		width: 490px;
		height: 289.001px;
		left: 322px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcRectangle_22_d {
		-webkit-filter: blur(15px);
		        filter: blur(15px);
		fill: rgba(168,158,255,1);
	}
	.petpaws_12_pcRectangle_22_d {
		-webkit-filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		        filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		position: absolute;
		overflow: visible;
		width: 618px;
		height: 426px;
		left: 11px;
		top: 7px;
	}
	#petpaws_12_pcRectangle_15_d {
		fill: rgba(68,135,255,1);
	}
	.petpaws_12_pcRectangle_15_d {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		        filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 307px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcMask_Group_7_d {
		opacity: 0.1;
		mix-blend-mode: multiply;
		position: absolute;
		width: 490px;
		height: 289px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcMask_Group_8_d {
		opacity: 0.15;
		mix-blend-mode: multiply;
		position: absolute;
		width: 490px;
		height: 289px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_47_d {
		position: absolute;
		width: 450px;
		height: 169.582px;
		left: 8px;
		top: 116.418px;
		overflow: visible;
	}
	#petpaws_12_pcKenned_Dsouza_d {
		left: 163.455px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 151px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(248,248,248,1);
	}
	#petpaws_12_pcScroll_Group_35 {
		mix-blend-mode: normal;
		position: absolute;
		width: 450px;
		height: 131px;
		left: 0px;
		top: 38.582px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#petpaws_12_pcScroll_Group_35::-webkit-scrollbar{
		display: none;
	}
	#petpaws_12_pcn_I_like_that_I_got_to_meet_th_d {
		opacity: 0.8;
		left: 29px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 393px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(248,248,248,1);
	}
	#petpaws_12_pcdownload_2_d {
		position: absolute;
		width: 379px;
		height: 89px;
		left: 49px;
		top: 17px;
		overflow: visible;
	}
	#petpaws_12_pcHeres_what_We_Provides {
		left: 351px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 409px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(255,103,82,1);
	}
	#petpaws_12_pcPath_249 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaws_12_pcPath_249 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 1px;
		left: 911.52px;
		top: 455.561px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcGroup_73 {
		position: absolute;
		width: 417.453px;
		height: 512.819px;
		left: 825.547px;
		top: 94px;
		overflow: visible;
	}
	#petpaws_12_pcEllipse_17 {
		fill: rgba(255,229,48,1);
	}
	.petpaws_12_pcEllipse_17 {
		position: absolute;
		overflow: visible;
		width: 383.806px;
		height: 383.805px;
		left: 33.648px;
		top: 129.014px;
	}
	#petpaws_12_pcMask_Group_3 {
		position: absolute;
		width: 383.806px;
		height: 383.805px;
		left: 33.648px;
		top: 129.014px;
		overflow: visible;
	}
	#petpaws_12_pcMask_Group_4 {
		position: absolute;
		width: 417.453px;
		height: 402.645px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_95 {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 163.073px;
		top: 40px;
		overflow: visible;
	}
	#petpaws_12_pcAnime_Edit {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 0px;
		top: 0px;
		overflow: hidden;
        border-radius: 50%;
	}
	#petpaws_12_pcGroup_107 {
		position: absolute;
		width: 1134px;
		height: 123.6px;
		left: 111px;
		top: 2374.4px;
		overflow: visible;
	}
	#petpaws_12_pcAmazing_Partner {
		left: 502.111px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 135px;
		white-space: nowrap;
		text-align: center;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(177,187,205,1);
	}
	#petpaws_12_pcScroll_Group_36 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1134px;
		height: 76px;
		left: 0px;
		top: 47.6px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#petpaws_12_pcScroll_Group_36::-webkit-scrollbar{
		display: none;
	}
	#petpaws_12_pcpartner_grp_website_6_pc {
		position: absolute;
		width: 252px;
		height: 50px;
		left: 34px;
		top: 13px;
		overflow: visible;
	}
	#petpaws_12_pcAnime_Edit_img_website_6_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcMohammed_Affan_lbl_website_6_p {
		left: 65px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 188px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#petpaws_12_pcGroup_14899 {
		position: absolute;
		width: 976.535px;
		height: 437.266px;
		left: 204px;
		top: 804.383px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_43 {
		position: absolute;
		width: 395.023px;
		height: 392.279px;
		left: 581.512px;
		top: 44.986px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_42 {
		position: absolute;
		width: 395.023px;
		height: 392.279px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_38:hover {
		width: 186.279px;
		height: 186.279px;
		left: 6px;
		top: 5px;
	}
	#petpaws_12_pcGroup_38 {
		position: absolute;
		width: 175.032px;
		height: 175.032px;
		left: 0px;
		top: 0px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#petpaws_12_pcRectangle_15_e {
		fill: rgba(255,255,255,1);
	}
	.petpaws_12_pcRectangle_15_e {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 193.032px;
		height: 193.031px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcGroup_37_e {
		position: absolute;
		width: 56.235px;
		height: 106.056px;
		left: 59.75px;
		top: 35.146px;
		overflow: visible;
	}
	#petpaws_12_pcVision_e {
		left: 3.514px;
		top: 85.057px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(112,112,112,1);
	}
	#petpaws_12_pcdog_e {
		opacity: 0.3;
		position: absolute;
		width: 56.235px;
		height: 56.249px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcPath_205_e {
		fill: rgba(0,34,92,1);
	}
	.petpaws_12_pcPath_205_e {
		overflow: visible;
		position: absolute;
		width: 2.336px;
		height: 2.336px;
		left: 30.193px;
		top: 19.725px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcPath_206_e {
		fill: rgba(0,34,92,1);
	}
	.petpaws_12_pcPath_206_e {
		overflow: visible;
		position: absolute;
		width: 2.336px;
		height: 2.336px;
		left: 8.628px;
		top: 12px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcPath_207_e {
		fill: rgba(0,34,92,1);
	}
	.petpaws_12_pcPath_207_e {
		overflow: visible;
		position: absolute;
		width: 56.235px;
		height: 56.248px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcGroup_40:hover {
		width: 186.279px;
		height: 186.279px;
		left: 108.604px;
		top: 206px;
	}
	#petpaws_12_pcGroup_40 {
		position: absolute;
		width: 175.032px;
		height: 175.032px;
		left: 102.981px;
		top: 200.338px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#petpaws_12_pcRectangle_15_fl {
		fill: rgba(255,255,255,1);
	}
	.petpaws_12_pcRectangle_15_fl {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 193.032px;
		height: 193.031px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcGroup_37_fm {
		position: absolute;
		width: 72.403px;
		height: 113.788px;
		left: 51.314px;
		top: 27.414px;
		overflow: visible;
	}
	#petpaws_12_pcMask_Group_2_fn {
		position: absolute;
		width: 72.403px;
		height: 72.402px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcPrinciples_fo {
		left: 1.406px;
		top: 92.789px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(112,112,112,1);
	}
	#petpaws_12_pcGroup_65_fp {
		position: absolute;
		width: 56.235px;
		height: 56.233px;
		left: 59.75px;
		top: 36.553px;
		overflow: visible;
	}
	#petpaws_12_pcpet-house_fq {
		opacity: 0.3;
		mix-blend-mode: normal;
		position: absolute;
		width: 56.235px;
		height: 56.233px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_60_fr {
		position: absolute;
		width: 56.235px;
		height: 56.233px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_59_fs {
		position: absolute;
		width: 56.235px;
		height: 56.233px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcPath_221_ft {
		fill: rgba(0,34,92,1);
	}
	.petpaws_12_pcPath_221_ft {
		overflow: visible;
		position: absolute;
		width: 56.235px;
		height: 56.232px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcGroup_62_fu {
		position: absolute;
		width: 3.295px;
		height: 3.295px;
		left: 26.47px;
		top: 19.77px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_61_fv {
		position: absolute;
		width: 3.295px;
		height: 3.295px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcEllipse_15_fw {
		fill: rgba(0,34,92,1);
	}
	.petpaws_12_pcEllipse_15_fw {
		position: absolute;
		overflow: visible;
		width: 3.295px;
		height: 3.295px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcGroup_39:hover {
		width: 186.279px;
		height: 186.279px;
		left: 208.744px;
		top: 5px;
	}
	#petpaws_12_pcGroup_39 {
		position: absolute;
		width: 175.032px;
		height: 175.032px;
		left: 201.744px;
		top: 0px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#petpaws_12_pcRectangle_15_f {
		fill: rgba(255,255,255,1);
	}
	.petpaws_12_pcRectangle_15_f {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 193.032px;
		height: 193.031px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcGroup_37_ga {
		position: absolute;
		width: 54px;
		height: 106.056px;
		left: 59.047px;
		top: 35.146px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_63_ga {
		opacity: 0.3;
		mix-blend-mode: normal;
		position: absolute;
		width: 48.739px;
		height: 56.235px;
		left: 4.218px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pckitten_gb {
		position: absolute;
		width: 48.739px;
		height: 56.235px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_58_gc {
		position: absolute;
		width: 48.739px;
		height: 56.235px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcPath_208_gd {
		fill: rgba(0,34,92,1);
	}
	.petpaws_12_pcPath_208_gd {
		overflow: visible;
		position: absolute;
		width: 48.739px;
		height: 56.234px;
		left: 0px;
		top: 0.002px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcEllipse_12_ge {
		fill: rgba(0,34,92,1);
	}
	.petpaws_12_pcEllipse_12_ge {
		position: absolute;
		overflow: visible;
		width: 1.875px;
		height: 1.875px;
		left: 8.436px;
		top: 7.496px;
	}
	#petpaws_12_pcEllipse_13_gf {
		fill: rgba(0,34,92,1);
	}
	.petpaws_12_pcEllipse_13_gf {
		position: absolute;
		overflow: visible;
		width: 1.875px;
		height: 1.875px;
		left: 13.122px;
		top: 7.496px;
	}
	#petpaws_12_pcMission_gg {
		left: 0px;
		top: 85.057px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(112,112,112,1);
	}
	#petpaws_12_pcGroup_57 {
		position: absolute;
		width: 96px;
		height: 44px;
		left: 154.124px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcVision_hd {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(255,103,82,1);
	}
	#petpaws_12_pcScroll_Group_1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 463px;
		height: 180px;
		left: 0px;
		top: 64.617px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#petpaws_12_pcScroll_Group_1::-webkit-scrollbar{
		display: none;
	}
	#petpaws_12_pcn_I_like_that_I_got_to_meet_th_hf {
		opacity: 0.8;
		left: 8px;
		top: 2px;
		position: absolute;
		overflow: visible;
		width: 455px;
		height: 176px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#petpaws_12_pcSHOP_NAME {
		left: 163.073px;
		top: 154.561px;
		position: absolute;
		overflow: visible;
		width: 352px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 59px;
		color: rgba(255,255,255,1);
	}
	#petpaws_12_pcGroup_14910 {
		position: absolute;
		width: 2323.922px;
		height: 619.994px;
		left: -478.961px;
		top: 2766.1px;
		overflow: visible;
	}
	#petpaws_12_pcEllipse_19 {
		fill: rgba(255,103,82,1);
	}
	.petpaws_12_pcEllipse_19 {
		position: absolute;
		overflow: visible;
		width: 2323.922px;
		height: 619.994px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 1310px;
		top: 311.9px;
		overflow: visible;
	}
	#petpaws_12_pclinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.petpaws_12_pcmed_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#petpaws_12_pcmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.petpaws_12_pcmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#petpaws_12_pclinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#petpaws_12_pcn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.petpaws_12_pcn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pclinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.petpaws_12_pclinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pctop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.petpaws_12_pctop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.petpaws_12_pcshadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#petpaws_12_pcshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.petpaws_12_pcshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#petpaws_12_pcinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#petpaws_12_pcwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcmed_shadow_space_website_7_hw:hover {
		fill: rgba(50,172,158,1);
	}
	.petpaws_12_pcmed_shadow_space_website_7_hw:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#petpaws_12_pcmed_shadow_space_website_7_hw {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.petpaws_12_pcmed_shadow_space_website_7_hw {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#petpaws_12_pcwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#petpaws_12_pcn_234567890_mobile_number_shad {
		left: 1345px;
		top: 80.9px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#petpaws_12_pcaffanghanioperatelive_email_sh {
		left: 1350px;
		top: 133.9px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#petpaws_12_pcaddress_med_shadow_space_websi {
		left: 1345px;
		top: 183.9px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#petpaws_12_pclocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 1310px;
		top: 185.9px;
		overflow: visible;
	}
	#petpaws_12_pcemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 1310px;
		top: 132.9px;
		overflow: visible;
	}
	#petpaws_12_pcphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 1310px;
		top: 79.9px;
		overflow: visible;
	}
	#petpaws_12_pcn_Copyright_Operate_Live_OPC_P {
		left: 1310px;
		top: 383.9px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#petpaws_12_pcRectangle_694 {
		fill: rgba(255,255,255,1);
	}
	.petpaws_12_pcRectangle_694 {
		position: absolute;
		overflow: visible;
		width: 491px;
		height: 311px;
		left: 652px;
		top: 94.9px;
		border-radius: 10px;
	}
	#petpaws_12_pcn_a21e555c63e24831805521512170 {
		position: absolute;
		width: 141px;
		height: 164px;
		left: 515px;
		top: 250.9px;
		overflow: visible;
	}
	#petpaws_12_pcVisit_Us_ {
		left: 837px;
		top: 46.9px;
		position: absolute;
		overflow: visible;
		width: 122px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	.mediaViewInfo {
		--web-view-name: Chefs Kitchen;
		--web-view-id: Chefs_Kitchen;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Chefs_Kitchen;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Chefs_Kitchen {
		position: absolute;
		width: 1366px;
		height: 4377px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Chefs Kitchen;
		--web-view-id: Chefs_Kitchen;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#chefs_13_pc_lcz9nxhoslo-brooke-lark {
		opacity: 0.2;
		position: absolute;
		width: 2500px;
		height: 1871px;
		left: -686px;
		top: 798px;
		overflow: visible;
	}
	#chefs_13_pc_socials_grp_med_web_9_pc {
		position: absolute;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 3969px;
		overflow: visible;
	}
	#chefs_13_pc_footer_bg_web_9_pc {
		opacity: 0.93;
		fill: rgba(54,40,38,1);
	}
	.chefs_13_pc_footer_bg_web_9_pc {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 0px;
	}
	#chefs_13_pc_n_Copyright_Operate_website_9_ {
		left: 41.5px;
		top: 349.16px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_linked_in_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 202px;
		top: 262px;
		overflow: visible;
		z-index: 1;
	}
	#chefs_13_pc_Ellipse_5_med_web_9_pc:hover {
		fill: rgba(120,120,255,1);
	}
	.chefs_13_pc_Ellipse_5_med_web_9_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#chefs_13_pc_Ellipse_5_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.chefs_13_pc_Ellipse_5_med_web_9_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#chefs_13_pc_linked_in_website_9_layout {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#chefs_13_pc_n_website_2_layout_web_9_pc {
		fill: rgba(63,61,86,1);
	}
	.chefs_13_pc_n_website_2_layout_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.953px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_linked_in_i_down_web_9_pc {
		fill: rgba(63,61,86,1);
	}
	.chefs_13_pc_linked_in_i_down_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.098px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_top_dot_i_website_9_layout {
		fill: rgba(63,61,86,1);
	}
	.chefs_13_pc_top_dot_i_website_9_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_Insta_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 122px;
		top: 261.999px;
		overflow: visible;
		z-index: 1;
	}
	#chefs_13_pc_Ellipse_4_med_web_9_pc:hover {
		fill: rgba(255,120,120,1);
	}
	.chefs_13_pc_Ellipse_4_med_web_9_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#chefs_13_pc_Ellipse_4_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.chefs_13_pc_Ellipse_4_med_web_9_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#chefs_13_pc_instagram_website_9_layout {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.126px;
		overflow: visible;
	}
	#chefs_13_pc_whatsapp_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 42px;
		top: 262px;
		overflow: visible;
		z-index: 1;
	}
	#chefs_13_pc_Ellipse_3_med_web_9_pc:hover {
		fill: rgba(50,172,158,1);
	}
	.chefs_13_pc_Ellipse_3_med_web_9_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#chefs_13_pc_Ellipse_3_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.chefs_13_pc_Ellipse_3_med_web_9_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#chefs_13_pc_whatsapp_lbl_website9_layout {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#chefs_13_pc_Line {
		position: absolute;
		width: 1170px;
		height: 3079px;
		left: 98px;
		top: 1376px;
		overflow: visible;
	}
	#chefs_13_pc_Line_1 {
		opacity: 0.11;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.chefs_13_pc_Line_1 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 3079px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_Line_4 {
		opacity: 0.11;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.chefs_13_pc_Line_4 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 3079px;
		left: 254px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_Line_5 {
		opacity: 0.11;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.chefs_13_pc_Line_5 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 3079px;
		left: 997px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_Line_2 {
		opacity: 0.11;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.chefs_13_pc_Line_2 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 3079px;
		left: 600px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_Line_3 {
		opacity: 0.11;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.chefs_13_pc_Line_3 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 3079px;
		left: 1170px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_eaters-collective-132773-unspl {
		position: absolute;
		width: 1294.818px;
		height: 1322.129px;
		left: 494.294px;
		top: 3520.936px;
		overflow: visible;
		z-index: 1;
	}
	#chefs_13_pc_Group_14928 {
		position: absolute;
		width: 1101px;
		height: 675px;
		left: 109px;
		top: 895px;
		overflow: visible;
	}
	#chefs_13_pc_Food_unites_cultures_evokes__m {
		left: 593px;
		top: 112px;
		position: absolute;
		overflow: visible;
		width: 509px;
		white-space: nowrap;
		line-height: 46px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_pc_lidye-1Shk_PkNkNw-unsplash {
		-webkit-filter: drop-shadow(0px 47px 99px rgba(197, 201, 203, 1));
		        filter: drop-shadow(0px 47px 99px rgba(197, 201, 203, 1));
		position: absolute;
		width: 485px;
		height: 675px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_pc_Rectangle_89 {
		fill: rgba(249,129,105,1);
	}
	.chefs_13_pc_Rectangle_89 {
		-webkit-filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		        filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 342px;
		height: 157px;
		left: 596px;
		top: 443px;
	}
	#chefs_13_pc_Know_More_About_Us {
		left: 624px;
		top: 467px;
		position: absolute;
		overflow: visible;
		width: 190px;
		height: 23px;
		line-height: 71px;
		margin-top: -26.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_Group_14930 {
		position: absolute;
		width: 1192.5px;
		height: 577.676px;
		left: 109px;
		top: 1583.162px;
		overflow: visible;
	}
	#chefs_13_pc_Food_a_universal_language_a_ta {
		left: 0px;
		top: 210.838px;
		position: absolute;
		overflow: visible;
		width: 550px;
		height: 221px;
		line-height: 46px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_pc_brooke-lark-08bOYnH_r_E-unspla {
		position: absolute;
		width: 233.508px;
		height: 189.433px;
		left: 894.285px;
		top: 282.272px;
		overflow: visible;
	}
	#chefs_13_pc_o9kwrr26 {
		position: absolute;
		width: 233.508px;
		height: 194.121px;
		left: 644.834px;
		top: 124.726px;
		overflow: visible;
	}
	#chefs_13_pc_joseph-gonzalez-zcUgjyqEwe8-un {
		position: absolute;
		width: 310.407px;
		height: 250.389px;
		left: 574.5px;
		top: 327.287px;
		overflow: visible;
	}
	#chefs_13_pc_joseph-gonzalez-fdlZBWIP0aM-un {
		position: absolute;
		width: 307.593px;
		height: 268.206px;
		left: 884.907px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_pc_Rectangle_90 {
		fill: rgba(249,129,105,1);
	}
	.chefs_13_pc_Rectangle_90 {
		-webkit-filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		        filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 342px;
		height: 157px;
		left: 3px;
		top: 465.838px;
	}
	#chefs_13_pc_Know_More_About_Us_dl {
		left: 31px;
		top: 489.838px;
		position: absolute;
		overflow: visible;
		width: 190px;
		height: 23px;
		line-height: 71px;
		margin-top: -26.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_Footer {
		position: absolute;
		width: 1188px;
		height: 305px;
		left: 81px;
		top: 4242px;
		display: none;
		overflow: visible;
	}
	#chefs_13_pc_Want_to_work_with_us {
		position: absolute;
		width: 1188px;
		height: 220px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_pc_Rectangle_81 {
		fill: rgba(11,49,75,1);
	}
	.chefs_13_pc_Rectangle_81 {
		position: absolute;
		overflow: visible;
		width: 1188px;
		height: 220px;
		left: 0px;
		top: 0px;
	}
	#chefs_13_pc_Rectangle_82 {
		fill: rgba(0,93,255,1);
	}
	.chefs_13_pc_Rectangle_82 {
		position: absolute;
		overflow: visible;
		width: 239px;
		height: 66px;
		left: 851px;
		top: 83px;
	}
	#chefs_13_pc_Contact_Now {
		left: 917px;
		top: 108px;
		position: absolute;
		overflow: hidden;
		width: 109px;
		height: 17px;
		line-height: 46px;
		margin-top: -15px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_Want_to_work_with_us_ {
		left: 112px;
		top: 52px;
		position: absolute;
		overflow: visible;
		width: 452px;
		height: 48px;
		line-height: 46px;
		margin-top: -5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: normal;
		font-size: 36px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_Building_first_evolved_out_of_ {
		opacity: 0.5;
		left: 112px;
		top: 112px;
		position: absolute;
		overflow: visible;
		width: 556px;
		height: 59px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_Text {
		left: 1036px;
		top: 277px;
		position: absolute;
		overflow: visible;
		width: 138px;
		white-space: nowrap;
		text-align: left;
		font-family: Font Awesome 5 Brands;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(11,49,75,1);
		letter-spacing: 14.29px;
	}
	#chefs_13_pc_CopyrightArifur_Rahman_2019_Al {
		opacity: 0.35;
		left: 20px;
		top: 278px;
		position: absolute;
		overflow: visible;
		width: 389px;
		height: 27px;
		line-height: 46px;
		margin-top: -15px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(11,49,75,1);
	}
	#chefs_13_pc_Group_14927 {
		position: absolute;
		width: 1366px;
		height: 798px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_pc_style_3 {
		position: absolute;
		width: 613px;
		height: 798px;
		left: 753px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_pc_Rectangle_695_dy {
		fill: url(#chefs_13_pc_Rectangle_695_dy);
	}
	.chefs_13_pc_Rectangle_695_dy {
		position: absolute;
		overflow: visible;
		width: 753px;
		height: 798px;
		left: 0px;
		top: 0px;
	}
	#chefs_13_pc_Rectangle_84 {
		fill: rgba(249,129,105,1);
	}
	.chefs_13_pc_Rectangle_84 {
		-webkit-filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		        filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 291px;
		height: 157px;
		left: 98px;
		top: 553px;
	}
	#chefs_13_pc_Good_food_choices_are_good_inv {
		left: 98px;
		top: 266px;
		position: absolute;
		overflow: visible;
		width: 700px;
		height: 156px;
		line-height: 67px;
		margin-top: -5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: lighter;
		font-size: 57px;
		color: rgba(249,249,249,0.886);
	}
	#chefs_13_pc_Explore_Now_ {
		left: 141px;
		top: 579px;
		position: absolute;
		overflow: visible;
		width: 119px;
		white-space: nowrap;
		line-height: 71px;
		margin-top: -26.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_Scroll_Group_42 {
		mix-blend-mode: normal;
		position: absolute;
		width: 616px;
		height: 91px;
		left: 90px;
		top: 437px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#chefs_13_pc_Scroll_Group_42::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_pc_intro_part {
		opacity: 0.71;
		left: 8px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 601px;
		height: 80px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(249,249,249,0.886);
	}
	#chefs_13_pc_zane-lee-bXbi1jJVDf0-unsplash_ {
		position: absolute;
		width: 75px;
		height: 75px;
		left: 98px;
		top: 41px;
        border-radius: 50%;
		overflow: hidden;
	}
	#chefs_13_pc_Operate_Live {
		left: 199px;
		top: 59px;
		position: absolute;
		overflow: visible;
		width: 168px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_good-soul-shop-DlO0yz4U67A-uns {
		position: absolute;
		width: 1366px;
		height: 544px;
		left: 0px;
		top: 3054px;
		overflow: visible;
	}
	#chefs_13_pc_Explore_Our_Foods {
		left: 501px;
		top: 3064px;
		position: absolute;
		overflow: visible;
		width: 365px;
		height: 41px;
		line-height: 46px;
		margin-top: -5px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(249,129,105,1);
	}
	#chefs_13_pc_Group_14936 {
		position: absolute;
		width: 255px;
		height: 70px;
		left: 1097px;
		top: 3019px;
		overflow: visible;
	}
	#chefs_13_pc_Rectangle_107 {
		fill: rgba(249,129,105,1);
	}
	.chefs_13_pc_Rectangle_107 {
		-webkit-filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		        filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 342px;
		height: 157px;
		left: 0px;
		top: 0px;
	}
	#chefs_13_pc_View_More_-_ {
		left: 68px;
		top: 25px;
		position: absolute;
		overflow: visible;
		width: 120px;
		white-space: nowrap;
		line-height: 71px;
		margin-top: -26.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_Group_14924 {
		position: absolute;
		width: 1031.027px;
		height: 433.428px;
		left: 167.366px;
		top: 2415.426px;
		overflow: visible;
	}
	#chefs_13_pc_Rectangle_696 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_pc_Rectangle_696 {
		-webkit-filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		        filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		width: 321.641px;
		height: 433.428px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-167.366px, -2415.426px) matrix(1,0,0,1,167.6065,2415.4258) rotate(180deg);
		        transform: translate(-167.366px, -2415.426px) matrix(1,0,0,1,167.6065,2415.4258) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#chefs_13_pc_Rectangle_697 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_pc_Rectangle_697 {
		-webkit-filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		        filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		width: 321.641px;
		height: 433.428px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-167.366px, -2415.426px) matrix(1,0,0,1,876.753,2415.4258) rotate(180deg);
		        transform: translate(-167.366px, -2415.426px) matrix(1,0,0,1,876.753,2415.4258) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#chefs_13_pc_Rectangle_698 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_pc_Rectangle_698 {
		-webkit-filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		        filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		width: 321.641px;
		height: 433.428px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-167.366px, -2415.426px) matrix(1,0,0,1,522.1797,2415.4258) rotate(180deg);
		        transform: translate(-167.366px, -2415.426px) matrix(1,0,0,1,522.1797,2415.4258) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#chefs_13_pc_eaters-collective-172257-unspl {
		position: absolute;
		width: 321.641px;
		height: 225.039px;
		left: 0.24px;
		top: 0.001px;
		overflow: visible;
	}
	#chefs_13_pc_n_es93lms {
		position: absolute;
		width: 321.641px;
		height: 225.039px;
		left: 709.387px;
		top: 0.001px;
		overflow: visible;
	}
	#chefs_13_pc_an_vision-1188492-unsplash {
		position: absolute;
		width: 321.641px;
		height: 225.039px;
		left: 354.813px;
		top: 0.001px;
		overflow: visible;
	}
	#chefs_13_pc_Scroll_Group_37 {
		mix-blend-mode: normal;
		position: absolute;
		width: 309px;
		height: 146px;
		left: 0px;
		top: 281.574px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#chefs_13_pc_Scroll_Group_37::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_pc_Lorem_ipsum_dolor_sit_amet_con {
		left: 20px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 269.407px;
		height: 130px;
		line-height: 22px;
		margin-top: -4px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(174,178,180,1);
	}
	#chefs_13_pc_Scroll_Group_39 {
		mix-blend-mode: normal;
		position: absolute;
		width: 306px;
		height: 145px;
		left: 714.146px;
		top: 283.574px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#chefs_13_pc_Scroll_Group_39::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_pc_Lorem_ipsum_dolor_sit_amet_con_el {
		left: 15px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 276.407px;
		height: 130px;
		line-height: 22px;
		margin-top: -4px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(174,178,180,1);
	}
	#chefs_13_pc_Scroll_Group_38 {
		mix-blend-mode: normal;
		position: absolute;
		width: 299px;
		height: 140px;
		left: 360.573px;
		top: 288.574px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#chefs_13_pc_Scroll_Group_38::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_pc_Lorem_ipsum_dolor_sit_amet_con_en {
		left: 14px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 271.407px;
		height: 125px;
		line-height: 22px;
		margin-top: -4px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(174,178,180,1);
	}
	#chefs_13_pc_Vision {
		left: 20px;
		top: 253.574px;
		position: absolute;
		overflow: visible;
		width: 221px;
		height: 21px;
		line-height: 46px;
		margin-top: -14px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_pc_Principles {
		left: 729.146px;
		top: 253.574px;
		position: absolute;
		overflow: visible;
		width: 221px;
		height: 21px;
		line-height: 46px;
		margin-top: -14px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_pc_Mission {
		left: 374.573px;
		top: 253.574px;
		position: absolute;
		overflow: visible;
		width: 221px;
		height: 21px;
		line-height: 46px;
		margin-top: -14px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_pc_Scroll_Group_40 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 455px;
		left: 10px;
		top: 3143px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#chefs_13_pc_Scroll_Group_40::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_pc_Group_14923 {
		position: relative;
		width: 367px;
		height: 381px;
		left: 0px;
		top: 37px;
		overflow: visible;
	}
	#chefs_13_pc_Group_14920 {
		position: relative;
		width: 367px;
		height: 367px;
		left: 0px;
		top: 14px;
		overflow: visible;
	}
	#chefs_13_pc_Group_14937 {
		position: absolute;
		width: 367px;
		height: 367px;
		left: 0px;
		top: 0px;
		overflow: visible;
		-webkit-animation: spin 2s linear infinite;
  	animation: spin 5s linear infinite;
	}
	@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

	#chefs_13_pc_kisspng-plate-circle-platter-t {
		position: absolute;
		width: 367px;
		height: 367px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_pc_anh-nguyen-kcA-c3f_3FE-unsplas {
		position: absolute;
		width: 245px;
		height: 244px;
		left: 61px;
		top: 57px;
		border-radius: 50%;
		overflow: hidden;
	}
	#chefs_13_pc_Salad {
		left: 32px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 304px;
		height: 28px;
		line-height: 46px;
		margin-top: -10.5px;
		text-align: center;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_pc_For_Queries_infoandroom_web_9_ {
		left: 68.526px;
		top: 4101px;
		position: absolute;
		overflow: visible;
		width: 219px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_For_call__920012888_web_9_pc {
		left: 68.526px;
		top: 4122px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_telephone_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_pc_telephone_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.521px;
		left: 42px;
		top: 4126.525px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_send_1_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_pc_send_1_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 42px;
		top: 4105.719px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_Line_web_9_pc {
		fill: transparent;
		stroke: rgba(86,86,86,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.chefs_13_pc_Line_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 1365.56px;
		height: 2px;
		left: 0.155px;
		top: 4305.113px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
		z-index: -1;
	}
	#chefs_13_pc_Icon_metro-locatio_web_9_pc_n {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_pc_Icon_metro-locatio_web_9_pc_n {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 18.209px;
		left: 42px;
		top: 4147.33px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_pc_address_lbl_web_9_pc {
		left: 68.526px;
		top: 4146.539px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 62px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_pc_Group_14926 {
		position: absolute;
		width: 343px;
		height: 264.312px;
		left: 424px;
		top: 4014.001px;
		overflow: visible;
		z-index: 10;
	}
	#chefs_13_pc_kisspng-tray-glass-plastic-pla {
		position: absolute;
		width: 343px;
		height: 264.312px;
		left: 0px;
		top: -0.001px;
		overflow: visible;
	}
	#chefs_13_pc_Rectangle_701 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_pc_Rectangle_701 {
		position: absolute;
		overflow: visible;
		width: 267px;
		height: 172px;
		left: 38px;
		top: 46.999px;
	}
	#chefs_13_pc_n_Copyright_Operate_website_9__e {
		left: 544px;
		top: 3975px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(255,255,255,1);
		z-index: 1;
	}
	#chefs_13_pc_Rectangle_700 {
		opacity: 0.28;
		fill: rgba(0,0,0,1);
	}
	.chefs_13_pc_Rectangle_700 {
		position: absolute;
		overflow: visible;
		width: 617px;
		height: 798px;
		left: 749px;
		top: 0px;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	.mediaViewInfo {
		--web-view-name: FeatheredScape;
		--web-view-id: FeatheredScape;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: FeatheredScape;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#FeatheredScape {
		position: absolute;
		width: 1366px;
		height: 3247px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: FeatheredScape;
		--web-view-id: FeatheredScape;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#n_14_web_pcGroup_14928 {
		position: absolute;
		width: 1179px;
		height: 675px;
		left: 94px;
		top: 971px;
		overflow: visible;
	}
	#n_14_web_pcmark-olsen-tjZPseTxe6k-unsplas {
		-webkit-filter: drop-shadow(0px 47px 99px rgba(197, 201, 203, 1));
		        filter: drop-shadow(0px 47px 99px rgba(197, 201, 203, 1));
		position: absolute;
		width: 485px;
		height: 675px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcGroup_14949 {
		position: absolute;
		width: 586px;
		height: 335px;
		left: 593px;
		top: 171px;
		overflow: visible;
	}
	#n_14_web_pcEmbark_on_an_Avian_Adventure_E {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 587px;
		height: 175px;
		line-height: 46px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(12,54,83,1);
	}
	#n_14_web_pcRectangle_89 {
		fill: rgba(249,129,105,1);
	}
	.n_14_web_pcRectangle_89 {
		-webkit-filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		        filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 342px;
		height: 157px;
		left: 0px;
		top: 265px;
	}
	#n_14_web_pcKnow_More_About_Us {
		left: 28px;
		top: 288px;
		position: absolute;
		overflow: visible;
		width: 190px;
		height: 23px;
		line-height: 71px;
		margin-top: -26.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#n_14_web_pcGroup_14927 {
		position: absolute;
		width: 1275px;
		height: 798px;
		left: 91px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcstyle_3 {
		position: absolute;
		width: 613px;
		height: 798px;
		left: 662px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcRectangle_84 {
		fill: rgba(249,129,105,1);
	}
	.n_14_web_pcRectangle_84 {
		-webkit-filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		        filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 291px;
		height: 157px;
		left: 7px;
		top: 553px;
	}
	#n_14_web_pcDiscover_a_new_world_of_birdin {
		left: 7px;
		top: 232px;
		position: absolute;
		overflow: visible;
		width: 636px;
		height: 138px;
		line-height: 67px;
		margin-top: -5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: lighter;
		font-size: 57px;
		color: rgba(0,0,0,1);
	}
	#n_14_web_pcExplore_Now_ {
		left: 50px;
		top: 579px;
		position: absolute;
		overflow: visible;
		width: 119px;
		white-space: nowrap;
		line-height: 71px;
		margin-top: -26.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#n_14_web_pcScroll_Group_42 {
		mix-blend-mode: normal;
		position: absolute;
		width: 616px;
		height: 127px;
		left: 0px;
		top: 408px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n_14_web_pcScroll_Group_42::-webkit-scrollbar{
		display: none;
	}
	#n_14_web_pcintro_part {
		opacity: 0.71;
		left: 8px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 601px;
		height: 113px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#n_14_web_pczane-lee-bXbi1jJVDf0-unsplash_ {
		position: absolute;
		width: 75px;
		height: 75px;
		left: 7px;
		top: 41px;
		border-radius: 50%;
		overflow: hidden;
	}
	#n_14_web_pcOperate_Live {
		left: 108px;
		top: 59px;
		position: absolute;
		overflow: visible;
		width: 168px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#n_14_web_pcwe_stand_for_grp_14_web_pc {
		position: absolute;
		width: 1087px;
		height: 280px;
		left: 140px;
		top: 1855px;
		overflow: visible;
	}
	#n_14_web_pcCard_03_website_14_web_pc {
		position: absolute;
		width: 273px;
		height: 276px;
		left: 814px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcScroll_Group_12 {
		mix-blend-mode: normal;
		position: absolute;
		width: 273px;
		height: 120px;
		left: 0px;
		top: 156px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n_14_web_pcScroll_Group_12::-webkit-scrollbar{
		display: none;
	}
	#n_14_web_pcintro_lbl_14_web_pc {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 73px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#n_14_web_pcprinciples_lbl_14_web_pc {
		left: 84.5px;
		top: 107px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#n_14_web_pcIcon_top_14_web_pc {
		fill: rgba(255,255,255,1);
	}
	.n_14_web_pcIcon_top_14_web_pc {
		overflow: visible;
		position: absolute;
		width: 18.923px;
		height: 3.37px;
		left: 121.03px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_14_web_pckevin-mu14_web_pc {
		position: absolute;
		width: 234px;
		height: 73px;
		left: 20px;
		top: 24px;
		overflow: visible;
	}
	#n_14_web_pcCard_02_website_14_web_pc {
		position: absolute;
		width: 260px;
		height: 256px;
		left: 394px;
		top: 24px;
		overflow: visible;
	}
	#n_14_web_pcScroll_Group_11 {
		mix-blend-mode: normal;
		position: absolute;
		width: 260px;
		height: 120px;
		left: 0px;
		top: 136px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n_14_web_pcScroll_Group_11::-webkit-scrollbar{
		display: none;
	}
	#n_14_web_pcintro_2_14_web_pc {
		left: 0px;
		top: -4px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 73px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#n_14_web_pcmission_lbl_we14_web_pc {
		left: 93px;
		top: 83px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#n_14_web_pczden14_web_pc {
		position: absolute;
		width: 234px;
		height: 73px;
		left: 13px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcCard_01_website_14_web_pc {
		position: absolute;
		width: 234px;
		height: 256px;
		left: 0px;
		top: 24px;
		overflow: visible;
	}
	#n_14_web_pcScroll_Group_10 {
		mix-blend-mode: normal;
		position: absolute;
		width: 234px;
		height: 120px;
		left: 0px;
		top: 136px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n_14_web_pcScroll_Group_10::-webkit-scrollbar{
		display: none;
	}
	#n_14_web_pcintro_w14_web_pc {
		left: -13px;
		top: -4px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 77px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#n_14_web_pcVision_14_web_pc {
		left: 88px;
		top: 83px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#n_14_web_pcaarn-giri-3lGi014_web_pc {
		position: absolute;
		width: 234px;
		height: 73px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcScroll_Group_48 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1285px;
		height: 393px;
		left: 56px;
		top: 2306px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#n_14_web_pcScroll_Group_48::-webkit-scrollbar{
		display: none;
	}
	#n_14_web_pcGroup_14950 {
		position: relative;
		width: 231px;
		height: 317px;
		left: 26px;
		top: 36px;
		margin-right: 30px;
		overflow: visible;
	}
	#n_14_web_pcRectangle_703 {
		fill: transparent;
	}
	.n_14_web_pcRectangle_703 {
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 317px;
		left: 0px;
		top: 0px;
		background:  rgba( 255, 255, 255, 0.05 );
		box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
		backdrop-filter:  blur( 4px );
		-webkit-backdrop-filter:  blur( 4px );
		border-radius:  10px;
		border:  1px solid rgba( 255, 255, 255, 0.18 );
	}
	#n_14_web_pckevin-mueller-aeNg4YA41P8-unsp {
		position: absolute;
		width: 210px;
		height: 253px;
		left: 10px;
		top: 54px;
		overflow: visible;
	}
	#n_14_web_pcParrot {
		left: 17px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#n_14_web_pcOur_Collection {
		left: 560px;
		top: 2243px;
		position: absolute;
		overflow: visible;
		width: 248px;
		white-space: nowrap;
		line-height: 46px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(12,54,83,1);
	}
	#n_14_web_pcfooter_grp_web_14_pc {
		position: absolute;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 2836px;
		overflow: visible;
	}
	#n_14_web_pcsocials_grp_med_web_14_pc {
		position: absolute;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcfooter_bg_web_14__pc {
		opacity: 0.93;
		fill: rgba(50,50,50,1);
	}
	.n_14_web_pcfooter_bg_web_14__pc {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 0px;
	}
	#n_14_web_pcn_Copyrighte_14_op_live_theme {
		left: 41.5px;
		top: 349.16px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#n_14_web_pcGroup_14952 {
		position: absolute;
		width: 210px;
		height: 50.001px;
		left: 42px;
		top: 261.999px;
		overflow: visible;
	}
	#n_14_web_pclinked_in_grp_med_web_14_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 160px;
		top: 0.001px;
		overflow: visible;
	}
	#n_14_web_pcEllipse_5_med_web_14__pc:hover {
		fill: rgba(120,120,255,1);
	}
	.n_14_web_pcEllipse_5_med_web_14__pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n_14_web_pcEllipse_5_med_web_14__pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n_14_web_pcEllipse_5_med_web_14__pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n_14_web_pclinked_in_website_14__layout {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#n_14_web_pcn_website_2_layout_web14__pc {
		fill: rgba(63,61,86,1);
	}
	.n_14_web_pcn_website_2_layout_web14__pc {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.954px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_14_web_pclinked_in_i_down_web14__pc {
		fill: rgba(63,61,86,1);
	}
	.n_14_web_pclinked_in_i_down_web14__pc {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.097px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_14_web_pctop_dot_i_website_14__layout {
		fill: rgba(63,61,86,1);
	}
	.n_14_web_pctop_dot_i_website_14__layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_14_web_pcInsta_grp_med_web_14_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 80px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcEllipse_4_med_web14__pc:hover {
		fill: rgba(255,120,120,1);
	}
	.n_14_web_pcEllipse_4_med_web14__pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n_14_web_pcEllipse_4_med_web14__pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n_14_web_pcEllipse_4_med_web14__pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n_14_web_pcinstagram_website_14__layout {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.126px;
		overflow: visible;
	}
	#n_14_web_pcwhatsapp_grp_med_web_14_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#n_14_web_pcEllipse_3_med_web_14_pc:hover {
		fill: rgba(50,172,158,1);
	}
	.n_14_web_pcEllipse_3_med_web_14_pc:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n_14_web_pcEllipse_3_med_web_14_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n_14_web_pcEllipse_3_med_web_14_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n_14_web_pcwhatsapp_lbl_website14__layout {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#n_14_web_pcFor_Queries_infoandroom_web_14 {
		left: 68.526px;
		top: 132px;
		position: absolute;
		overflow: visible;
		width: 219px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#n_14_web_pcFor_call__920012888_web_14_pc {
		left: 68.526px;
		top: 153px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#n_14_web_pctelephone_web_14_pc {
		fill: rgba(255,255,255,1);
	}
	.n_14_web_pctelephone_web_14_pc {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 42px;
		top: 157.525px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_14_web_pcsend_1_web_14_pc {
		fill: rgba(255,255,255,1);
	}
	.n_14_web_pcsend_1_web_14_pc {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 42px;
		top: 136.72px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_14_web_pcLine_web_14_pc {
		fill: transparent;
		stroke: rgba(86,86,86,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.n_14_web_pcLine_web_14_pc {
		overflow: visible;
		position: absolute;
		width: 1365.56px;
		height: 2px;
		left: 0.155px;
		top: 336.113px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_14_web_pcIcon_metro-locatio_web_14_pc_n {
		fill: rgba(255,255,255,1);
	}
	.n_14_web_pcIcon_metro-locatio_web_14_pc_n {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 18.208px;
		left: 42px;
		top: 178.331px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#n_14_web_pcaddress_lbl_web_14_pc {
		left: 68.526px;
		top: 177.539px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 62px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#n_14_web_pcGroup_14951 {
		position: absolute;
		width: 422px;
		height: 405.895px;
		left: 800px;
		top: 2836.506px;
		overflow: visible;
	}
	#n_14_web_pcMy_project {
		position: absolute;
		width: 374.661px;
		height: 405.895px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcdisplay_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.n_14_web_pcdisplay_web_9_pc {
		position: absolute;
		overflow: visible;
		width: 285px;
		height: 150px;
		left: 60px;
		top: 237.494px;
	}
	#n_14_web_pcVISIT_US {
		left: 268px;
		top: 25.494px;
		position: absolute;
		overflow: visible;
		width: 155px;
		white-space: nowrap;
		line-height: 46px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(255,255,255,1);
	}

	.mediaViewInfo {
		--web-view-name: FurniGem;
		--web-view-id: FurniGem;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: FurniGem;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#FurniGem {
		position: absolute;
		width: 1366px;
		height: 3999px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: FurniGem;
		--web-view-id: FurniGem;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#thm15FG_pcGroup_14956 {
		position: absolute;
		width: 208px;
		height: 59px;
		left: 1102px;
		top: 2779px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_705 {
		fill: rgba(0,0,0,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.thm15FG_pcRectangle_705 {
		position: absolute;
		overflow: visible;
		width: 208px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#thm15FG_pcGroup_14954 {
		position: absolute;
		width: 155.504px;
		height: 30.705px;
		left: 27px;
		top: 14px;
		overflow: visible;
	}
	#thm15FG_pcLearn_More {
		left: 0px;
		top: 2px;
		position: absolute;
		overflow: visible;
		width: 102px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_pcIcon_awesome-arrow-right {
		fill: rgba(255,153,0,1);
	}
	.thm15FG_pcIcon_awesome-arrow-right {
		overflow: visible;
		position: absolute;
		width: 31.504px;
		height: 30.705px;
		left: 124px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_pcGroup_14966 {
		position: absolute;
		width: 1347px;
		height: 804px;
		left: 19px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_704 {
		fill: rgba(232,232,232,1);
	}
	.thm15FG_pcRectangle_704 {
		position: absolute;
		overflow: visible;
		width: 406px;
		height: 480px;
		left: 941px;
		top: 0px;
	}
	#thm15FG_pcdi_an_h-g_8MrEZAvyE-unsplash {
		position: absolute;
		width: 406px;
		height: 566px;
		left: 625px;
		top: 238px;
		overflow: visible;
	}
	#thm15FG_pcAnime_Edit {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 1211px;
		top: 81px;
        border-radius: 50%;
		overflow: hidden;
	}
	#thm15FG_pcTransform__Your_Space {
		left: 21px;
		top: 99px;
		position: absolute;
		overflow: visible;
		width: 504px;
		white-space: nowrap;
		line-height: 118px;
		margin-top: -9px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 100px;
		color: rgba(0,0,0,1);
		letter-spacing: 0.2px;
	}
	#thm15FG_pcTransform_Your_Space_With_Our {
		left: 951px;
		top: 211px;
		position: absolute;
		overflow: visible;
		width: 277px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(12,54,83,1);
	}
	#thm15FG_pcGroup_14955 {
		position: absolute;
		width: 160px;
		height: 46px;
		left: 12px;
		top: 570px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_705_c {
		fill: rgba(0,0,0,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.thm15FG_pcRectangle_705_c {
		position: absolute;
		overflow: visible;
		width: 160px;
		height: 46px;
		left: 0px;
		top: 0px;
	}
	#thm15FG_pcGroup_14954_c {
		position: absolute;
		width: 119.812px;
		height: 23.691px;
		left: 21px;
		top: 10.802px;
		overflow: visible;
	}
	#thm15FG_pcLearn_More_c {
		left: 0px;
		top: 1.198px;
		position: absolute;
		overflow: visible;
		width: 77px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_pcIcon_awesome-arrow-right_c {
		fill: rgba(255,153,0,1);
	}
	.thm15FG_pcIcon_awesome-arrow-right_c {
		overflow: visible;
		position: absolute;
		width: 24.307px;
		height: 23.691px;
		left: 95.505px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_pcScroll_Group_49 {
		mix-blend-mode: normal;
		position: absolute;
		width: 521px;
		height: 183px;
		left: 12px;
		top: 376px;
		overflow: hidden;
	}
	#thm15FG_pcIntroduction {
		left: 14px;
		top: 17px;
		position: absolute;
		overflow: visible;
		width: 494px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcGroup_14958 {
		position: absolute;
		width: 44px;
		height: 77px;
		left: 1269px;
		top: 727px;
		overflow: hidden;
	}
	#thm15FG_pcRectangle_706 {
		fill: rgba(255,141,12,1);
	}
	.thm15FG_pcRectangle_706 {
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 77px;
		left: 0px;
		top: 0px;
	}
	#thm15FG_pcIcon_awesome-arrow-down {
		fill: rgba(255,255,255,1);
	}
	.thm15FG_pcIcon_awesome-arrow-down {
		overflow: visible;
		position: absolute;
		width: 30.705px;
		height: 31.503px;
		left: 6.647px;
		top: 22.748px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_pcGroup_14963 {
		position: absolute;
		width: 606px;
		height: 120px;
		left: 0px;
		top: 684px;
		overflow: visible;
	}
	#thm15FG_pcguzman-barquin-FkKClUPUURU-uns {
		position: absolute;
		width: 186px;
		height: 120px;
		left: 420px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_pcspacejoy-YqFz7UMm8qE-unsplash {
		position: absolute;
		width: 186px;
		height: 120px;
		left: 210px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_pcmyxa69-nZnVdtlJvbw-unsplash {
		position: absolute;
		width: 186px;
		height: 120px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_pcCompany_Name {
		left: 951px;
		top: 102px;
		position: absolute;
		overflow: visible;
		width: 251px;
		height: 27px;
		text-align: right;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(12,54,83,1);
	}
	#thm15FG_pcStunning_Furniture_Collection {
		left: 1034px;
		top: 238px;
		position: absolute;
		overflow: visible;
		width: 258px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(12,54,83,1);
	}
	#thm15FG_pcProduct {
		left: 623px;
		top: 2785px;
		position: absolute;
		overflow: visible;
		width: 122px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcScroll_Group_50 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1290px;
		height: 428px;
		left: 38px;
		top: 2869px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#thm15FG_pcScroll_Group_50::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_pcGroup_14957 {
		position: relative;
		width: 283px;
		height: 362px;
		left: 24px;
		top: 33px;
        margin-right: 30px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_708 {
		fill: rgba(255,255,255,1);
	}
	.thm15FG_pcRectangle_708 {
		position: absolute;
		overflow: visible;
		width: 283px;
		height: 362px;
		left: 0px;
		top: 0px;
	}
	#thm15FG_pcavery-klein-JaXs8Tk5Iww-unspla {
		position: absolute;
		width: 247px;
		height: 298px;
		left: 18px;
		top: 18px;
		overflow: visible;
	}
	#thm15FG_pcChair {
		left: 18px;
		top: 328px;
		position: absolute;
		overflow: visible;
		width: 248px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcGroup_14961 {
		position: absolute;
		width: 1220px;
		height: 473px;
		left: 146px;
		top: 973px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_707 {
		fill: rgba(232,232,232,1);
	}
	.thm15FG_pcRectangle_707 {
		position: absolute;
		overflow: visible;
		width: 876px;
		height: 473px;
		left: 344px;
		top: 0px;
	}
	#thm15FG_pckisspng-furniture-poster-couch {
		position: absolute;
		width: 397px;
		height: 366px;
		left: 0px;
		top: 54px;
		overflow: visible;
	}
	#thm15FG_pcScroll_Group_51 {
		mix-blend-mode: normal;
		position: absolute;
		width: 698px;
		height: 269px;
		left: 465px;
		top: 141px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#thm15FG_pcScroll_Group_51::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_pcElevate_Your_Space_with_Timele {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 677px;
		height: 249px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcVision {
		left: 476px;
		top: 74px;
		position: absolute;
		overflow: visible;
		width: 88px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcGroup_14960 {
		position: absolute;
		width: 1220px;
		height: 473px;
		left: 0px;
		top: 1552px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_709 {
		fill: rgba(232,232,232,1);
	}
	.thm15FG_pcRectangle_709 {
		position: absolute;
		overflow: visible;
		width: 876px;
		height: 473px;
		left: 0px;
		top: 0px;
	}
	#thm15FG_pckisspng-window-living-room-cur {
		position: absolute;
		width: 397px;
		height: 366px;
		left: 823px;
		top: 54px;
		overflow: visible;
	}
	#thm15FG_pcScroll_Group_52 {
		mix-blend-mode: normal;
		position: absolute;
		width: 698px;
		height: 269px;
		left: 57px;
		top: 141px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#thm15FG_pcScroll_Group_52::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_pcElevate_Your_Space_with_Timele_d {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 677px;
		height: 249px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcMission {
		left: 57px;
		top: 74px;
		position: absolute;
		overflow: visible;
		width: 110px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcGroup_14959 {
		position: absolute;
		width: 1220px;
		height: 473px;
		left: 146px;
		top: 2131px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_710 {
		fill: rgba(232,232,232,1);
	}
	.thm15FG_pcRectangle_710 {
		position: absolute;
		overflow: visible;
		width: 876px;
		height: 473px;
		left: 344px;
		top: 0px;
	}
	#thm15FG_pcsuchit-poojari-ljRiZl00n18-uns {
		position: absolute;
		width: 397px;
		height: 366px;
		left: 0px;
		top: 54px;
		overflow: visible;
	}
	#thm15FG_pcScroll_Group_53 {
		mix-blend-mode: normal;
		position: absolute;
		width: 698px;
		height: 269px;
		left: 465px;
		top: 141px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#thm15FG_pcScroll_Group_53::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_pcElevate_Your_Space_with_Timele_ea {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 677px;
		height: 249px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcPrinciples {
		left: 476px;
		top: 74px;
		position: absolute;
		overflow: visible;
		width: 138px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcGroup_14962 {
		position: absolute;
		width: 1366px;
		height: 442px;
		left: 0px;
		top: 3562px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_711 {
		fill: rgba(59,59,59,1);
	}
	.thm15FG_pcRectangle_711 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 442px;
		left: 0px;
		top: 0px;
	}
	#thm15FG_pcLine_29 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.thm15FG_pcLine_29 {
		overflow: visible;
		position: absolute;
		width: 1290px;
		height: 2px;
		left: 38.5px;
		top: 336.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_pcsocials_grp_shadow_space_websi {
		position: absolute;
		width: 210px;
		height: 50.001px;
		left: 578px;
		top: 363.999px;
		overflow: visible;
	}
	#thm15FG_pclinked_in_grp_shadow_space_web {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 160px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_pcEllipse_5shadow_space_website_:hover {
		fill: rgba(120,120,255,1);
	}
	.thm15FG_pcEllipse_5shadow_space_website_:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#thm15FG_pcEllipse_5shadow_space_website_ {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.thm15FG_pcEllipse_5shadow_space_website_ {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#thm15FG_pclinked_in_shadow_space_website {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#thm15FG_pcn_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.thm15FG_pcn_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.954px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_pclinked_in_i_down {
		fill: rgba(63,61,86,1);
	}
	.thm15FG_pclinked_in_i_down {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.097px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_pctop_dot_i_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.thm15FG_pctop_dot_i_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_pcInsta_grp_med_shadow_space_web {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 80px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_pcEllipseshadow_space_website_5_:hover {
		fill: rgba(255,120,120,1);
	}
	.thm15FG_pcEllipseshadow_space_website_5_:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#thm15FG_pcEllipseshadow_space_website_5_ {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.thm15FG_pcEllipseshadow_space_website_5_ {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#thm15FG_pcinstagram_shadow_space_website {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.126px;
		overflow: visible;
	}
	#thm15FG_pcwhatsapp_grpshadow_space_websi {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#thm15FG_pcEllipse_3_shadow_space_website:hover {
		fill: rgba(50,172,158,1);
	}
	.thm15FG_pcEllipse_3_shadow_space_website:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#thm15FG_pcEllipse_3_shadow_space_website {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.thm15FG_pcEllipse_3_shadow_space_website {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#thm15FG_pcwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_712 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.thm15FG_pcRectangle_712 {
		position: absolute;
		overflow: visible;
		width: 547px;
		height: 244px;
		left: 748px;
		top: 52px;
	}
	#thm15FG_pcn_234567890_mobile_number_shad {
		left: 74px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_pcaffanghanioperatelive_email_sh {
		left: 74px;
		top: 103px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_pcaddress_med_shadow_space_websi {
		left: 74px;
		top: 162px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_pclocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 39px;
		top: 162px;
		overflow: visible;
	}
	#thm15FG_pcemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 39px;
		top: 102px;
		overflow: visible;
	}
	#thm15FG_pcphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 39px;
		top: 42px;
		overflow: visible;
	}
	#thm15FG_pcVisit_Us {
		left: 748px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	.mediaViewInfo {
		--web-view-name: GamersHub;
		--web-view-id: GamersHub;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: GamersHub;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#GamersHub {
		position: absolute;
		width: 1366px;
		height: 3199px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: GamersHub;
		--web-view-id: GamersHub;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#GamersHub_16_pcGroup_14979 {
		position: absolute;
		width: 1126.71px;
		height: 1199.296px;
		left: 137.506px;
		top: 961.188px;
		overflow: visible;
	}
	#GamersHub_16_pcGroup_14978 {
		position: absolute;
		width: 1093.71px;
		height: 376.812px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcGroup_14972 {
		position: absolute;
		width: 1093.71px;
		height: 376.812px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcScroll_Group_51 {
		mix-blend-mode: normal;
		position: absolute;
		width: 698px;
		height: 269px;
		left: 395.71px;
		top: 107.812px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#GamersHub_16_pcScroll_Group_51::-webkit-scrollbar{
		display: none;
	}
	#GamersHub_16_pcElevate_Your_Space_with_Timele {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 677px;
		height: 249px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcVision {
		left: 406.71px;
		top: 40.812px;
		position: absolute;
		overflow: visible;
		width: 88px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcPath_6955 {
		fill: rgba(92,35,255,1);
	}
	.GamersHub_16_pcPath_6955 {
		overflow: visible;
		position: absolute;
		width: 358.421px;
		height: 124.099px;
		left: 0px;
		top: 146.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pckisspng-twisted-metal-black-pl {
		position: absolute;
		width: 293px;
		height: 293px;
		left: 32.71px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcGroup_14974 {
		position: absolute;
		width: 1056.421px;
		height: 359.756px;
		left: 7.494px;
		top: 438.791px;
		overflow: visible;
	}
	#GamersHub_16_pcGroup_14973 {
		position: absolute;
		width: 698px;
		height: 336px;
		left: 0px;
		top: 23.756px;
		overflow: visible;
	}
	#GamersHub_16_pcScroll_Group_51_c {
		mix-blend-mode: normal;
		position: absolute;
		width: 698px;
		height: 269px;
		left: 0px;
		top: 67px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#GamersHub_16_pcScroll_Group_51_c::-webkit-scrollbar{
		display: none;
	}
	#GamersHub_16_pcElevate_Your_Space_with_Timele_c {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 677px;
		height: 226px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcMission {
		left: 11px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 110px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcPath_6957 {
		fill: rgba(78,187,87,1);
	}
	.GamersHub_16_pcPath_6957 {
		overflow: visible;
		position: absolute;
		width: 358.421px;
		height: 124.099px;
		left: 698px;
		top: 110.443px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pckisspng-computer-keyboard-cors {
		position: absolute;
		width: 305px;
		height: 220.886px;
		left: 739.217px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcGroup_14975 {
		position: absolute;
		width: 1095.71px;
		height: 336px;
		left: 31px;
		top: 863.296px;
		overflow: visible;
	}
	#GamersHub_16_pcScroll_Group_51_dd {
		mix-blend-mode: normal;
		position: absolute;
		width: 698px;
		height: 269px;
		left: 397.71px;
		top: 67px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#GamersHub_16_pcScroll_Group_51_dd::-webkit-scrollbar{
		display: none;
	}
	#GamersHub_16_pcElevate_Your_Space_with_Timele_de {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 677px;
		height: 249px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcPrinciples {
		left: 408.71px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 138px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcPath_6956 {
		fill: rgba(78,187,87,1);
	}
	.GamersHub_16_pcPath_6956 {
		overflow: visible;
		position: absolute;
		width: 358.421px;
		height: 124.099px;
		left: 0px;
		top: 112.194px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcMy_project {
		position: absolute;
		width: 305px;
		height: 171.25px;
		left: 26.71px;
		top: 30.25px;
		overflow: visible;
	}
	#GamersHub_16_pcEllipse_63 {
		-webkit-filter: blur(9px);
		        filter: blur(9px);
		fill: rgba(0,0,0,1);
	}
	.GamersHub_16_pcEllipse_63 {
		position: absolute;
		overflow: visible;
		width: 349px;
		height: 66px;
		left: 23.494px;
		top: 174.243px;
	}
	#GamersHub_16_pcGroup_14984 {
		position: absolute;
		width: 176.66px;
		height: 79.148px;
		left: 608px;
		top: 2195.97px;
		overflow: visible;
	}
	#GamersHub_16_pcprinci_design_img_website_7_pc {
		fill: rgba(247,33,38,1);
	}
	.GamersHub_16_pcprinci_design_img_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 175.359px;
		height: 76.1px;
		-webkit-transform: translate(-608px, -2195.97px) matrix(1,0,0,1,608.6506,2197.4942) rotate(1deg);
		        transform: translate(-608px, -2195.97px) matrix(1,0,0,1,608.6506,2197.4942) rotate(1deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_pcOur_Services_lbl_website_6_pc {
		left: 31px;
		top: 23.03px;
		position: absolute;
		overflow: visible;
		width: 115px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(255,255,255,0.89);
	}
	#GamersHub_16_pcGroup_14985 {
		position: absolute;
		width: 1269px;
		height: 451.5px;
		left: 48.5px;
		top: 2676.5px;
		overflow: visible;
	}
	#GamersHub_16_pcLine_28 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_28 {
		overflow: visible;
		position: absolute;
		width: 1269px;
		height: 2px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 742.5px;
		top: 344.5px;
		overflow: visible;
	}
	#GamersHub_16_pclinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.GamersHub_16_pcmed_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#GamersHub_16_pcmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.GamersHub_16_pcmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#GamersHub_16_pclinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.192px;
		overflow: visible;
	}
	#GamersHub_16_pcn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.GamersHub_16_pcn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pclinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.GamersHub_16_pclinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pctop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.GamersHub_16_pctop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.GamersHub_16_pcshadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#GamersHub_16_pcshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.GamersHub_16_pcshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#GamersHub_16_pcinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#GamersHub_16_pcwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcmed_shadow_space_website_7_d:hover {
		fill: rgba(50,172,158,1);
	}
	.GamersHub_16_pcmed_shadow_space_website_7_d:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#GamersHub_16_pcmed_shadow_space_website_7_d {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.GamersHub_16_pcmed_shadow_space_website_7_d {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#GamersHub_16_pcwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 11.993px;
		overflow: visible;
	}
	#GamersHub_16_pcn_234567890_mobile_number_shad {
		left: 777.5px;
		top: 85.5px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcaffanghanioperatelive_email_sh {
		left: 782.5px;
		top: 145.5px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcaddress_med_shadow_space_websi {
		left: 777.5px;
		top: 204.5px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pclocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 742.5px;
		top: 204.5px;
		overflow: visible;
	}
	#GamersHub_16_pcemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 742.5px;
		top: 144.5px;
		overflow: visible;
	}
	#GamersHub_16_pcphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 742.5px;
		top: 84.5px;
		overflow: visible;
	}
	#GamersHub_16_pcn_Copyright_Operate_Live_OPC_P {
		left: 742.5px;
		top: 429.5px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(173,173,173,1);
	}
	#GamersHub_16_pcGroup_14976 {
		position: absolute;
		width: 717.556px;
		height: 403.625px;
		left: 2.278px;
		top: 36.875px;
		overflow: visible;
	}
	#GamersHub_16_pcMy_project_ec {
		position: absolute;
		width: 717.556px;
		height: 403.625px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcRectangle_717 {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_pcRectangle_717 {
		position: absolute;
		overflow: visible;
		width: 357.517px;
		height: 199.755px;
		left: 175.921px;
		top: 96.473px;
	}
	#GamersHub_16_pcScroll_Group_55 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1330.5px;
		height: 270.998px;
		left: 10.5px;
		top: 2315.002px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#GamersHub_16_pcScroll_Group_55::-webkit-scrollbar{
		display: none;
	}
	#GamersHub_16_pcGroup_14977 {
		position: relative;
		margin-right: 30px;
		width: 323.5px;
		height: 230.998px;
		left: 38px;
		top: 20px;
		overflow: visible;
	}
	#GamersHub_16_pcPath_448 {
		fill: rgba(0,0,0,1);
	}
	.GamersHub_16_pcPath_448 {
		overflow: visible;
		position: absolute;
		width: 323.11px;
		height: 195.998px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcn_71177 {
		position: absolute;
		width: 313.597px;
		height: 173.951px;
		left: 4.943px;
		top: 4.992px;
		overflow: visible;
	}
	#GamersHub_16_pcMinecraft {
		left: 1.5px;
		top: 203.998px;
		position: absolute;
		overflow: visible;
		width: 323px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcGroup_14983 {
		position: absolute;
		width: 1304px;
		height: 937px;
		left: 0px;
		top: -46.5px;
		overflow: visible;
	}
	#GamersHub_16_pcMask_Group_1 {
		position: absolute;
		width: 210px;
		height: 210px;
		left: 0px;
		top: 46.5px;
		overflow: visible;
	}
	#GamersHub_16_pcPLAY_GAME_EVERYDAY_ALWAYS_BE_W {
		opacity: 1;
		left: 141px;
		top: 269.5px;
		position: absolute;
		overflow: visible;
		width: 476px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcGroup_14969 {
		position: absolute;
		width: 199.75px;
		height: 419.5px;
		left: 818.25px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcRectangle_718 {
		-webkit-filter: blur(50px);
		        filter: blur(50px);
		fill: rgba(255,68,87,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcRectangle_718 {
		position: absolute;
		overflow: visible;
		width: 497px;
		height: 544px;
		left: 2.75px;
		top: 175.5px;
	}
	#GamersHub_16_pcRectangle_713 {
		fill: rgba(255,68,87,1);
	}
	.GamersHub_16_pcRectangle_713 {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 35px;
		left: 164.75px;
		top: 101.5px;
	}
	#GamersHub_16_pcConsole {
		left: 18.75px;
		top: 105.5px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(236,58,74,1);
	}
	#GamersHub_16_pcLine_30 {
		fill: transparent;
		stroke: rgba(255,36,36,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_30 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 351px;
		left: 0.5px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcLine_31 {
		fill: transparent;
		stroke: rgba(255,36,36,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_31 {
		overflow: visible;
		position: absolute;
		width: 4px;
		height: 237.017px;
		left: 0.5px;
		top: 113.688px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcpexels-anthony--139038 {
		position: absolute;
		width: 199.5px;
		height: 280px;
		left: 0px;
		top: 139px;
		overflow: visible;
	}
	#GamersHub_16_pcGroup_14968 {
		position: absolute;
		width: 200px;
		height: 419.5px;
		left: 1104px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcRectangle_719 {
		-webkit-filter: blur(17px);
		        filter: blur(17px);
		fill: rgba(19,16,27,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcRectangle_719 {
		position: absolute;
		overflow: visible;
		width: 302px;
		height: 375px;
		left: 0px;
		top: 146.5px;
	}
	#GamersHub_16_pcSwitch {
		left: 21px;
		top: 105.5px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(33,27,33,1);
	}
	#GamersHub_16_pcRectangle_714 {
		fill: rgba(25,22,33,1);
	}
	.GamersHub_16_pcRectangle_714 {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 35px;
		left: 165px;
		top: 101.5px;
	}
	#GamersHub_16_pcLine_32 {
		fill: transparent;
		stroke: rgba(36,24,34,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_32 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 351px;
		left: 0.5px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcLine_33 {
		fill: transparent;
		stroke: rgba(55,114,54,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_33 {
		overflow: visible;
		position: absolute;
		width: 4px;
		height: 237.017px;
		left: 0.5px;
		top: 114px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcpexels-pixabay-371924 {
		position: absolute;
		width: 200px;
		height: 280px;
		left: 0px;
		top: 139.5px;
		overflow: visible;
	}
	#GamersHub_16_pcefeb0ad6-be8c-4667-ab83-307500 {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 126px;
		top: 75.5px;
		border-radius: 50%;
		overflow: hidden;
	}
	#GamersHub_16_pcCompany_Name {
		left: 201px;
		top: 89.5px;
		position: absolute;
		overflow: visible;
		width: 179px;
		white-space: nowrap;
		text-align: left;
		font-family: Headliner No 45;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcCreate_your_own_game_platform_ {
		-webkit-transform: translate(0px, 46.5px) matrix(1,0,0,1,-61.5,622.5) rotate(-90deg);
		        transform: translate(0px, 46.5px) matrix(1,0,0,1,-61.5,622.5) rotate(-90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 263px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcLine_38 {
		fill: transparent;
		stroke: rgba(92,35,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_38 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 86px;
		left: 52.5px;
		top: 508px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcLine_39 {
		fill: transparent;
		stroke: rgba(255,12,12,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_39 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 141px;
		left: 52.5px;
		top: 669px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcflorian-olivo-Mf23RF8xArY-unsp {
		position: absolute;
		width: 542px;
		height: 354px;
		left: 141px;
		top: 456.5px;
		overflow: visible;
	}
	#GamersHub_16_pcIcon_ionic-ios-arrow-back {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_pcIcon_ionic-ios-arrow-back {
		overflow: visible;
		position: absolute;
		width: 9.378px;
		height: 16.405px;
		left: 995.811px;
		top: 110.798px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcIcon_ionic-ios-arrow-back_e {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_pcIcon_ionic-ios-arrow-back_e {
		overflow: visible;
		position: absolute;
		width: 9.378px;
		height: 16.405px;
		left: 1281.812px;
		top: 110.798px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcGroup_14981 {
		position: absolute;
		width: 201.25px;
		height: 455.5px;
		left: 816.75px;
		top: 481.5px;
		overflow: visible;
	}
	#GamersHub_16_pcGroup_14970 {
		position: absolute;
		width: 2px;
		height: 351px;
		left: 0px;
		top: 104.5px;
		overflow: visible;
	}
	#GamersHub_16_pcLine_34 {
		fill: transparent;
		stroke: rgba(202,222,228,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_34 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 351px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcLine_35 {
		fill: transparent;
		stroke: rgba(202,222,228,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_35 {
		overflow: visible;
		position: absolute;
		width: 4px;
		height: 237.017px;
		left: 0px;
		top: 114px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcRectangle_720 {
		-webkit-filter: blur(30px);
		        filter: blur(30px);
		fill: rgba(4,104,118,1);
	}
	.GamersHub_16_pcRectangle_720 {
		position: absolute;
		overflow: visible;
		width: 381px;
		height: 448px;
		left: 0.25px;
		top: 12px;
	}
	#GamersHub_16_pcpexels-harsch-shivam-2007647 {
		position: absolute;
		width: 200px;
		height: 280px;
		left: 1px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcVR {
		left: 13.25px;
		top: 288px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(4,104,118,1);
	}
	#GamersHub_16_pcRectangle_715 {
		fill: rgba(4,104,118,1);
	}
	.GamersHub_16_pcRectangle_715 {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 35px;
		left: 166.25px;
		top: 284px;
	}
	#GamersHub_16_pcIcon_ionic-ios-arrow-back_fg {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_pcIcon_ionic-ios-arrow-back_fg {
		overflow: visible;
		position: absolute;
		width: 9.378px;
		height: 16.405px;
		left: 179.061px;
		top: 293.298px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcGroup_14982 {
		position: absolute;
		width: 200.5px;
		height: 455.5px;
		left: 1103.5px;
		top: 481.5px;
		overflow: visible;
	}
	#GamersHub_16_pcGroup_14971 {
		position: absolute;
		width: 2px;
		height: 351px;
		left: 0px;
		top: 104.5px;
		overflow: visible;
	}
	#GamersHub_16_pcLine_36 {
		fill: transparent;
		stroke: rgba(149,179,48,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_36 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 351px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcLine_37 {
		fill: transparent;
		stroke: rgba(149,179,48,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_37 {
		overflow: visible;
		position: absolute;
		width: 4px;
		height: 237.017px;
		left: 0px;
		top: 114px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcRectangle_721 {
		-webkit-filter: blur(42px);
		        filter: blur(42px);
		fill: rgba(110,134,41,1);
	}
	.GamersHub_16_pcRectangle_721 {
		position: absolute;
		overflow: visible;
		width: 452px;
		height: 520px;
		left: 0.5px;
		top: 12px;
	}
	#GamersHub_16_pcpexels-karol-d-841228 {
		position: absolute;
		width: 200px;
		height: 280px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcKeybaord {
		left: 13.5px;
		top: 288px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(110,134,41,1);
	}
	#GamersHub_16_pcRectangle_716 {
		fill: rgba(110,134,41,1);
	}
	.GamersHub_16_pcRectangle_716 {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 35px;
		left: 165.5px;
		top: 284px;
	}
	#GamersHub_16_pcIcon_ionic-ios-arrow-back_fp {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_pcIcon_ionic-ios-arrow-back_fp {
		overflow: visible;
		position: absolute;
		width: 9.378px;
		height: 16.405px;
		left: 178.312px;
		top: 293.298px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcPath_6953 {
		fill: rgba(0,0,0,0);
		stroke: rgba(92,35,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcPath_6953 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 86px;
		-webkit-transform: translate(0px, 46.5px) matrix(1,0,0,1,619.5,340.5) rotate(90deg);
		        transform: translate(0px, 46.5px) matrix(1,0,0,1,619.5,340.5) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_pcLine_41 {
		fill: transparent;
		stroke: rgba(255,12,12,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_41 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 141px;
		-webkit-transform: translate(0px, 46.5px) matrix(1,0,0,1,463.5,313) rotate(90deg);
		        transform: translate(0px, 46.5px) matrix(1,0,0,1,463.5,313) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_pcGroup_14980 {
		position: absolute;
		width: 54px;
		height: 54px;
		left: 703px;
		top: 756.5px;
		overflow: visible;
	}
	#GamersHub_16_pcEllipse_2 {
		fill: rgba(35,33,38,1);
	}
	.GamersHub_16_pcEllipse_2 {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 54px;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_pcPolygon_1 {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_pcPolygon_1 {
		overflow: visible;
		position: absolute;
		width: 12.801px;
		height: 10.972px;
		-webkit-transform: translate(1666.247px, -297.754px) matrix(1,0,0,1,-1645.8578,319.0566) rotate(90deg);
		        transform: translate(1666.247px, -297.754px) matrix(1,0,0,1,-1645.8578,319.0566) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	.tcp_mediaViewInfo {
		--web-view-name: TeaCorner;
		--web-view-id: TeaCorner;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: TeaCorner;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#TeaCorner {
		position: absolute;
		width: 1366px;
		height: 3747px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: TeaCorner;
		--web-view-id: TeaCorner;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#tcp_TC_17_pcGroup_15005 {
		position: absolute;
		width: 1366px;
		height: 568px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcside-view-two-cups-coffee-cook {
		position: absolute;
		width: 1366px;
		height: 568px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcRectangle_722 {
		opacity: 0.74;
		fill: rgba(92,226,104,1);
	}
	.tcp_TC_17_pcRectangle_722 {
		position: absolute;
		overflow: visible;
		width: 299px;
		height: 421px;
		left: 148px;
		top: 0px;
	}
	#tcp_TC_17_pcAnime_Edit {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 188px;
		top: 47px;
        border-radius: 50%;
		overflow: hidden;
	}
	#tcp_TC_17_pcTea_Corner {
		left: 283px;
		top: 68px;
		position: absolute;
		overflow: visible;
		width: 98px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcThe_Beauty_of__Science_and__Na {
		left: 187px;
		top: 145px;
		position: absolute;
		overflow: visible;
		width: 254px;
		height: 95px;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: normal;
		font-size: 28px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcTea_A_hug_in_a_cup_that_warms_ {
		left: 188px;
		top: 268px;
		position: absolute;
		overflow: visible;
		width: 226px;
		height: 50px;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#tcp_TC_17_pcIcon_ionic-logo-whatsapp {
		fill: rgba(255,255,255,1);
	}
	.tcp_TC_17_pcIcon_ionic-logo-whatsapp {
		overflow: visible;
		position: absolute;
		width: 20px;
		height: 20px;
		left: 188px;
		top: 340px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#tcp_TC_17_pcIcon_awesome-instagram {
		fill: rgba(255,255,255,1);
	}
	.tcp_TC_17_pcIcon_awesome-instagram {
		overflow: visible;
		position: absolute;
		width: 20px;
		height: 19.996px;
		left: 188px;
		top: 371px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#tcp_TC_17_pcn_856321470 {
		left: 223px;
		top: 342px;
		position: absolute;
		overflow: visible;
		width: 76px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#tcp_TC_17_pcyour_username {
		left: 223px;
		top: 373px;
		position: absolute;
		overflow: visible;
		width: 107px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#tcp_TC_17_pcGroup_15002 {
		position: absolute;
		width: 1141px;
		height: 574px;
		left: 148px;
		top: 668px;
		overflow: visible;
	}
	#tcp_TC_17_pcvision_bdr_website_7_pc {
		fill: rgba(195,191,188,1);
	}
	.tcp_TC_17_pcvision_bdr_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 662px;
		height: 146px;
		left: 0px;
		top: 422px;
	}
	#tcp_TC_17_pcmask_top_visoon_img_website_7_ {
		position: absolute;
		width: 583px;
		height: 574px;
		left: 558px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcVision_lbl_website_7_pc {
		left: 14px;
		top: 88.998px;
		position: absolute;
		overflow: visible;
		width: 111px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 41px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcScroll_vision_web_7_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 445px;
		height: 197px;
		left: 0px;
		top: 158.998px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#tcp_TC_17_pcScroll_vision_web_7_pc::-webkit-scrollbar{
		display: none;
	}
	#tcp_TC_17_pcThe_cockpit_impresses_with_its {
		left: 14px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 133px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#tcp_TC_17_pcGroup_15001 {
		position: absolute;
		width: 1141px;
		height: 574px;
		left: 148px;
		top: 1394px;
		overflow: visible;
	}
	#tcp_TC_17_pcvision_bdr_website_7_pc_dh {
		fill: rgba(97,64,38,1);
	}
	.tcp_TC_17_pcvision_bdr_website_7_pc_dh {
		position: absolute;
		overflow: visible;
		width: 662px;
		height: 146px;
		left: 475px;
		top: 422px;
	}
	#tcp_TC_17_pcmask_top_visoon_img_website_7__di {
		position: absolute;
		width: 583px;
		height: 574px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcVision_lbl_website_7_pc_dj {
		left: 710px;
		top: 88.998px;
		position: absolute;
		overflow: visible;
		width: 140px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 41px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcScroll_vision_web_7_pc_dk {
		mix-blend-mode: normal;
		position: absolute;
		width: 445px;
		height: 197px;
		left: 696px;
		top: 158.998px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#tcp_TC_17_pcScroll_vision_web_7_pc_dk::-webkit-scrollbar{
		display: none;
	}
	#tcp_TC_17_pcThe_cockpit_impresses_with_its_dl {
		left: 14px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 133px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#tcp_TC_17_pcGroup_15000 {
		position: absolute;
		width: 1141px;
		height: 574px;
		left: 148px;
		top: 2120px;
		overflow: visible;
	}
	#tcp_TC_17_pcvision_bdr_website_7_pc_dn {
		fill: rgba(207,144,100,1);
	}
	.tcp_TC_17_pcvision_bdr_website_7_pc_dn {
		position: absolute;
		overflow: visible;
		width: 662px;
		height: 146px;
		left: 0px;
		top: 422px;
	}
	#tcp_TC_17_pcmask_top_visoon_img_website_7__do {
		position: absolute;
		width: 583px;
		height: 574px;
		left: 558px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcVision_lbl_website_7_pc_dp {
		left: 14px;
		top: 88.998px;
		position: absolute;
		overflow: visible;
		width: 173px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 41px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcScroll_vision_web_7_pc_dq {
		mix-blend-mode: normal;
		position: absolute;
		width: 445px;
		height: 197px;
		left: 0px;
		top: 158.998px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#tcp_TC_17_pcScroll_vision_web_7_pc_dq::-webkit-scrollbar{
		display: none;
	}
	#tcp_TC_17_pcThe_cockpit_impresses_with_its_dr {
		left: 14px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 133px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#tcp_TC_17_pcAvaliable_Now_lbl_website_7_pc {
		left: 535px;
		top: 2794px;
		position: absolute;
		overflow: visible;
		width: 298px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcn_667477_3386277_1 {
		position: absolute;
		width: 1376px;
		height: 354px;
		left: 0px;
		top: 2912px;
		overflow: visible;
	}
	#tcp_TC_17_pcScroll_Group_56 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 354px;
		top: 2912px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#tcp_TC_17_pcScroll_Group_56::-webkit-scrollbar{
		display: none;
	}
	#tcp_TC_17_pcGroup_15003 {
		position: relative;
		width: 270px;
		height: 308px;
		left: 48px;
		top: 23px;
        margin-right: 30px;
		overflow: visible;
	}
	#tcp_TC_17_pccoffee-porcelain-cup-wooden-ta {
		position: absolute;
		width: 270px;
		height: 270px;
		left: 0px;
		top: 38px;
		overflow: visible;
	}
	#tcp_TC_17_pcGinger_Tea {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 271px;
		height: 33px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcGroup_15009 {
		position: absolute;
		width: 1376px;
		height: 494.762px;
		left: 0px;
		top: 3335.619px;
		overflow: visible;
	}
	#tcp_TC_17_pcvision_bdr_website_7_pc_dz {
		fill: rgba(207,144,100,1);
	}
	.tcp_TC_17_pcvision_bdr_website_7_pc_dz {
		position: absolute;
		overflow: visible;
		width: 1376px;
		height: 339px;
		left: 0px;
		top: 82.381px;
	}
	#tcp_TC_17_pcGroup_15004 {
		position: absolute;
		width: 479px;
		height: 280px;
		left: 832px;
		top: 107.381px;
		overflow: visible;
	}
	#tcp_TC_17_pcsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 0px;
		top: 232px;
		overflow: visible;
	}
	#tcp_TC_17_pclinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.tcp_TC_17_pcmed_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#tcp_TC_17_pcmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.tcp_TC_17_pcmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#tcp_TC_17_pclinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.672px;
		overflow: visible;
	}
	#tcp_TC_17_pcn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.tcp_TC_17_pcn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#tcp_TC_17_pclinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.tcp_TC_17_pclinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#tcp_TC_17_pctop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.tcp_TC_17_pctop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#tcp_TC_17_pcInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.tcp_TC_17_pcshadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#tcp_TC_17_pcshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.tcp_TC_17_pcshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#tcp_TC_17_pcinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.072px;
		overflow: visible;
	}
	#tcp_TC_17_pcwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcmed_shadow_space_website_7_ee:hover {
		fill: rgba(50,172,158,1);
	}
	.tcp_TC_17_pcmed_shadow_space_website_7_ee:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#tcp_TC_17_pcmed_shadow_space_website_7_ee {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.tcp_TC_17_pcmed_shadow_space_website_7_ee {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#tcp_TC_17_pcwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.473px;
		overflow: visible;
	}
	#tcp_TC_17_pcn_234567890_mobile_number_shad {
		left: 35px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcaffanghanioperatelive_email_sh {
		left: 40px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pclocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#tcp_TC_17_pcemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#tcp_TC_17_pcphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcn_Copyright_Operate_Live_OPC_P {
		left: 176px;
		top: 365.381px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pckisspng-turkish-coffee-tea-caf {
		position: absolute;
		width: 477.645px;
		height: 494.762px;
		left: 128.967px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcEllipse_64 {
		fill: rgba(255,255,255,1);
	}
	.tcp_TC_17_pcEllipse_64 {
		position: absolute;
		overflow: visible;
		width: 188px;
		height: 193px;
		left: 302px;
		top: 155.381px;
		border-radius: 100px;
	}
	.mediaViewInfo {
		--web-view-name: SharpStyles;
		--web-view-id: SharpStyles;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: SharpStyles;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#SharpStyles {
		position: absolute;
		width: 1366px;
		height: 4427px;
        left:calc((100vw - 1366px)/2); 
		background: linear-gradient(180deg, rgba(228,228,233,1) 0%, rgba(255,255,255,1) 100%);
		overflow: hidden;
		--web-view-name: SharpStyles;
		--web-view-id: SharpStyles;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#SharpStyles_18_pcGroup_15028 {
		position: absolute;
		width: 843px;
		height: 1579px;
		left: 262px;
		top: 786px;
		overflow: visible;
	}
	#SharpStyles_18_pcGroup_14870 {
		position: absolute;
		width: 820px;
		height: 451px;
		left: 11px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcRectangle_683 {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_pcRectangle_683 {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 746px;
		height: 358px;
		left: 92px;
		top: 56px;
	}
	#SharpStyles_18_pcagustin-fernandez-Znyjl9pbaUs- {
		position: absolute;
		width: 334px;
		height: 451px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcVISION {
		left: 389px;
		top: 89px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcScroll_Group_22 {
		mix-blend-mode: normal;
		position: absolute;
		width: 437px;
		height: 187px;
		left: 360px;
		top: 156px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SharpStyles_18_pcScroll_Group_22::-webkit-scrollbar{
		display: none;
	}
	#SharpStyles_18_pcLorem_ipsum_dolor_sit_amet_con {
		left: 29px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 380px;
		height: 197px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#SharpStyles_18_pcGroup_14869 {
		position: absolute;
		width: 843px;
		height: 464px;
		left: 0px;
		top: 551px;
		overflow: visible;
	}
	#SharpStyles_18_pcRectangle_684 {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_pcRectangle_684 {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 766px;
		height: 367px;
		left: 0px;
		top: 58px;
	}
	#SharpStyles_18_pcjake-blucker-tMzCrBkM99Y-unspl {
		position: absolute;
		width: 344px;
		height: 464px;
		left: 499px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcMISSION {
		left: 54px;
		top: 83px;
		position: absolute;
		overflow: visible;
		width: 167px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 39px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcLorem_ipsum_dolor_sit_amet_con_dc {
		left: 54px;
		top: 160px;
		position: absolute;
		overflow: visible;
		width: 391px;
		height: 203px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(125,125,125,1);
	}
	#SharpStyles_18_pcRectangle_685 {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_pcRectangle_685 {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 766px;
		height: 367px;
		left: 0px;
		top: 58px;
	}
	#SharpStyles_18_pcnathon-oski-EW_rqoSdDes-unspla {
		position: absolute;
		width: 344px;
		height: 464px;
		left: 499px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcMISSION_df {
		left: 54px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 133px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcScroll_Group_21 {
		mix-blend-mode: normal;
		position: absolute;
		width: 392px;
		height: 193px;
		left: 32px;
		top: 160px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SharpStyles_18_pcScroll_Group_21::-webkit-scrollbar{

	display: none;
	}
	#SharpStyles_18_pcLorem_ipsum_dolor_sit_amet_con_dh {
		left: 22px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 391px;
		height: 203px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#SharpStyles_18_pcGroup_14868 {
		position: absolute;
		width: 843px;
		height: 464px;
		left: 0px;
		top: 1115px;
		overflow: visible;
	}
	#SharpStyles_18_pcRectangle_686 {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_pcRectangle_686 {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 766px;
		height: 368px;
		left: 95px;
		top: 58px;
	}
	#SharpStyles_18_pcapothecary-87-Wg3J83R1YSQ-unsp {
		position: absolute;
		width: 344px;
		height: 464px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcPRINCIPLES {
		left: 399px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 173px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcScroll_Group_20 {
		mix-blend-mode: normal;
		position: absolute;
		width: 384px;
		height: 211px;
		left: 383px;
		top: 142px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SharpStyles_18_pcScroll_Group_20::-webkit-scrollbar{
		display: none;
	}
	#SharpStyles_18_pcLorem_ipsum_dolor_sit_amet_con_dn {
		left: 16px;
		top: 18px;
		position: absolute;
		overflow: visible;
		width: 391px;
		height: 203px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#SharpStyles_18_pcGroup_15026 {
		position: absolute;
		width: 1109px;
		height: 531px;
		left: 188px;
		top: 47px;
		overflow: visible;
	}
	#SharpStyles_18_pcWe_are_Desperate_to_make_you_M {
		-webkit-filter: drop-shadow(0px 16px 21px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 16px 21px rgba(0, 0, 0, 0.161));
		left: 5.246px;
		top: 119px;
		position: absolute;
		overflow: visible;
		width: 452px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#SharpStyles_18_pcAnime_Edit {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 0px;
		top: 0px;
		border-radius: 50%;
		overflow: hidden;
	}
	#SharpStyles_18_pcOni_Chan_Haircut {
		left: 95px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 156px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcYou_will_leave_looking_sharp_r {
		left: 5px;
		top: 350px;
		position: absolute;
		overflow: visible;
		width: 464px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcRectangle_723 {
		fill: rgba(123,153,166,1);
	}
	.SharpStyles_18_pcRectangle_723 {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 69px;
		left: 5px;
		top: 462px;
	}
	#SharpStyles_18_pcGet_Started {
		left: 51px;
		top: 483px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SharpStyles_18_pcGroup_15020 {
		position: absolute;
		width: 755px;
		height: 503.371px;
		left: 354px;
		top: 6.629px;
		overflow: visible;
	}
	#SharpStyles_18_pcRectangle_724 {
		fill: rgba(123,153,166,1);
	}
	.SharpStyles_18_pcRectangle_724 {
		position: absolute;
		overflow: visible;
		width: 252px;
		height: 240px;
		left: 290px;
		top: 263.371px;
	}
	#SharpStyles_18_pcMy_project {
		position: absolute;
		width: 755px;
		height: 503.37px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pctrimmer {
		position: absolute;
		width: 51.655px;
		height: 51.655px;
		left: 536.476px;
		top: 129.447px;
		overflow: visible;
	}
	#SharpStyles_18_pcGroup_15018 {
		position: absolute;
		width: 175.934px;
		height: 7.2px;
		left: 555.566px;
		top: 523.8px;
		overflow: visible;
	}
	#SharpStyles_18_pcLine_42 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SharpStyles_18_pcLine_42 {
		overflow: visible;
		position: absolute;
		width: 40px;
		height: 1px;
		left: 0px;
		top: 3.535px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_pcRectangle_725 {
		fill: rgba(99,113,203,1);
	}
	.SharpStyles_18_pcRectangle_725 {
		width: 5px;
		height: 5px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-666.5px, -49.965px) matrix(1,0,0,1,706,51.0004) rotate(45deg);
		        transform: translate(-666.5px, -49.965px) matrix(1,0,0,1,706,51.0004) rotate(45deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#SharpStyles_18_pcGroup_15015 {
		position: absolute;
		width: 43.699px;
		height: 7.071px;
		left: 44.536px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcLine_43 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SharpStyles_18_pcLine_43 {
		overflow: visible;
		position: absolute;
		width: 40px;
		height: 1px;
		left: 0px;
		top: 3.535px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_pcRectangle_726 {
		fill: rgba(255,168,168,1);
	}
	.SharpStyles_18_pcRectangle_726 {
		width: 5px;
		height: 5px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-670.5px, -47.965px) matrix(1,0,0,1,708.1637,49.0004) rotate(45deg);
		        transform: translate(-670.5px, -47.965px) matrix(1,0,0,1,708.1637,49.0004) rotate(45deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#SharpStyles_18_pcGroup_15016 {
		position: absolute;
		width: 43.699px;
		height: 7.071px;
		left: 88.536px;
		top: 0.129px;
		overflow: visible;
	}
	#SharpStyles_18_pcLine_43_d {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SharpStyles_18_pcLine_43_d {
		overflow: visible;
		position: absolute;
		width: 40px;
		height: 1px;
		left: 0px;
		top: 3.535px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_pcRectangle_726_d {
		fill: rgba(255,44,44,1);
	}
	.SharpStyles_18_pcRectangle_726_d {
		width: 5px;
		height: 5px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-670.5px, -47.965px) matrix(1,0,0,1,708.1637,49.0004) rotate(45deg);
		        transform: translate(-670.5px, -47.965px) matrix(1,0,0,1,708.1637,49.0004) rotate(45deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#SharpStyles_18_pcGroup_15017 {
		position: absolute;
		width: 43.699px;
		height: 7.071px;
		left: 132.235px;
		top: 0.129px;
		overflow: visible;
	}
	#SharpStyles_18_pcLine_43_ea {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SharpStyles_18_pcLine_43_ea {
		overflow: visible;
		position: absolute;
		width: 40px;
		height: 1px;
		left: 0px;
		top: 3.535px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_pcRectangle_726_ea {
		fill: rgba(255,255,17,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SharpStyles_18_pcRectangle_726_ea {
		width: 5px;
		height: 5px;
		position: absolute;
		overflow: visible;
		-webkit-transform: translate(-670.5px, -47.965px) matrix(1,0,0,1,708.1637,49.0004) rotate(45deg);
		        transform: translate(-670.5px, -47.965px) matrix(1,0,0,1,708.1637,49.0004) rotate(45deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
	}
	#SharpStyles_18_pcOur_Services {
		left: 592px;
		top: 2465px;
		position: absolute;
		overflow: visible;
		width: 183px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcScroll_my_services_pc_6_wev {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 375px;
		/* left: 32px; */
		top: 2556px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#SharpStyles_18_pcScroll_my_services_pc_6_wev::-webkit-scrollbar{
		display: none;
	}
	#SharpStyles_18_pcour_servicces_card_1_website_6 {
		position: relative;
		width: 233px;
		height: 325px;
		left: 22px;
		top: 17px;
		margin-right: 30px;
		overflow: visible;
	}
	#SharpStyles_18_pcservices_card_1_bdr_website_6_ {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_pcservices_card_1_bdr_website_6_ {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 251px;
		height: 343px;
		left: 0px;
		top: 0px;
	}
	#SharpStyles_18_pcimg_of_services_website_6_pc {
		position: absolute;
		width: 201px;
		height: 265px;
		left: 16px;
		top: 46px;
		overflow: visible;
	}
	#SharpStyles_18_pccircle_deign_shadow_space_webs {
		fill: rgba(23,18,18,1);
	}
	.SharpStyles_18_pccircle_deign_shadow_space_webs {
		position: absolute;
		overflow: visible;
		width: 10px;
		height: 10px;
		left: 16px;
		top: 20px;
	}
	#SharpStyles_18_pcMy_Offerings_website_6_pc {
		left: 31px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 187px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,0.89);
	}
	#SharpStyles_18_pcGallery {
		left: 632px;
		top: 2981px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcGroup_15019 {
		position: absolute;
		width: 720px;
		height: 686px;
		left: 323px;
		top: 3115px;
		overflow: visible;
	}
	#SharpStyles_18_pcobi-pixel7propix--sRVfY0f2d8-u {
		position: absolute;
		width: 230px;
		height: 315px;
		left: 245px;
		top: 45px;
		overflow: visible;
	}
	#SharpStyles_18_pcallef-vinicius-IvQeAVeJULw-uns {
		position: absolute;
		width: 230px;
		height: 315px;
		left: 490px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcarthur-humeau-Twd3yaqA2NM-unsp {
		position: absolute;
		width: 230px;
		height: 315px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcagustin-fernandez-Znyjl9pbaUs-_en {
		position: absolute;
		width: 230px;
		height: 315px;
		left: 245px;
		top: 371px;
		overflow: visible;
	}
	#SharpStyles_18_pcdelfina-pan-wJoB8D3hnzc-unspla {
		position: absolute;
		width: 230px;
		height: 315px;
		left: 490px;
		top: 326px;
		overflow: visible;
	}
	#SharpStyles_18_pcnathon-oski-fE42nRlBcG8-unspla {
		position: absolute;
		width: 230px;
		height: 315px;
		left: 0px;
		top: 326px;
		overflow: visible;
	}
	#SharpStyles_18_pcGroup_15024 {
		position: absolute;
		width: 1269px;
		height: 435.5px;
		left: 48.5px;
		top: 3893.5px;
		overflow: visible;
	}
	#SharpStyles_18_pcLine_28 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SharpStyles_18_pcLine_28 {
		overflow: visible;
		position: absolute;
		width: 1269px;
		height: 2px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_pcsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 742.5px;
		top: 328.5px;
		overflow: visible;
	}
	#SharpStyles_18_pclinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.SharpStyles_18_pcmed_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#SharpStyles_18_pcmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.SharpStyles_18_pcmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#SharpStyles_18_pclinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.191px;
		overflow: visible;
	}
	#SharpStyles_18_pcn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.SharpStyles_18_pcn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_pclinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.SharpStyles_18_pclinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_pctop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.SharpStyles_18_pctop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_pcInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.SharpStyles_18_pcshadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#SharpStyles_18_pcshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.SharpStyles_18_pcshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#SharpStyles_18_pcinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#SharpStyles_18_pcwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcmed_shadow_space_website_7_e:hover {
		fill: rgba(50,172,158,1);
	}
	.SharpStyles_18_pcmed_shadow_space_website_7_e:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#SharpStyles_18_pcmed_shadow_space_website_7_e {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.SharpStyles_18_pcmed_shadow_space_website_7_e {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#SharpStyles_18_pcwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#SharpStyles_18_pcn_234567890_mobile_number_shad {
		left: 777.5px;
		top: 69.5px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcaffanghanioperatelive_email_sh {
		left: 782.5px;
		top: 129.5px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcaddress_med_shadow_space_websi {
		left: 777.5px;
		top: 188.5px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pclocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 742.5px;
		top: 188.5px;
		overflow: visible;
	}
	#SharpStyles_18_pcemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 742.5px;
		top: 128.5px;
		overflow: visible;
	}
	#SharpStyles_18_pcphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 742.5px;
		top: 68.5px;
		overflow: visible;
	}
	#SharpStyles_18_pcn_Copyright_Operate_Live_OPC_P {
		left: 742.5px;
		top: 413.5px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(173,173,173,1);
	}
	#SharpStyles_18_pcVisit_Us_and_Get_the_Stylist_H {
		left: 77.5px;
		top: 42.5px;
		position: absolute;
		overflow: visible;
		width: 530px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcRectangle_735 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SharpStyles_18_pcRectangle_735 {
		position: absolute;
		overflow: visible;
		width: 586px;
		height: 337px;
		left: 48.5px;
		top: 98.5px;
		border-radius: 20px;
	}
	.mediaViewInfo {
		--web-view-name: RealtyScape;
		--web-view-id: RealtyScape;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: RealtyScape;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#RealtyScape {
		position: absolute;
		width: 1366px;
		height: 5325px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: RealtyScape;
		--web-view-id: RealtyScape;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#m19_pc_RSWhat_do_you_want_to_your__Drea {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 494px;
		top: 912.001px;
		position: absolute;
		overflow: visible;
		width: 409px;
		white-space: nowrap;
		text-align: center;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(20,57,91,1);
	}
	#m19_pc_RSGroup_9691 {
		position: absolute;
		width: 13.622px;
		height: 10.84px;
		left: 287.366px;
		top: 1279.346px;
		overflow: visible;
	}
	#m19_pc_RScheck {
		position: absolute;
		width: 13.622px;
		height: 10.84px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSPath_2923 {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSPath_2923 {
		overflow: visible;
		position: absolute;
		width: 13.623px;
		height: 10.84px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#m19_pc_RSGroup_15032 {
		position: absolute;
		width: 1300px;
		height: 691px;
		left: 33px;
		top: 4012px;
		overflow: visible;
	}
	#m19_pc_RSScroll_Group_60 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 572.001px;
		left: 0px;
		top: 118.998px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#m19_pc_RSGroup_15031 {
		position: relative;
		width: 501px;
		height: 530px;
		left: 20px;
		top: 18px;
		margin-right: 30px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_112 {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSRectangle_112 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 519px;
		height: 548px;
		left: 0px;
		top: 0px;
	}
	#m19_pc_RSMask_Group_4 {
		position: absolute;
		width: 460px;
		height: 277px;
		left: 21px;
		top: 21px;
		overflow: visible;
	}
	#m19_pc_RSn_500 {
		left: 21px;
		top: 345px;
		position: absolute;
		overflow: visible;
		width: 80px;
		white-space: nowrap;
		text-align: left;
		font-family: Sen;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(30,98,232,1);
	}
	#m19_pc_RSApartment_London {
		left: 19px;
		top: 382px;
		position: absolute;
		overflow: visible;
		width: 198px;
		white-space: nowrap;
		text-align: left;
		font-family: Sen;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#m19_pc_RSScroll_Group_59 {
		mix-blend-mode: normal;
		position: absolute;
		width: 455px;
		height: 97px;
		left: 9px;
		top: 423px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#m19_pc_RSBeautiful_Huge_1_family_House_ {
		opacity: 0.5;
		left: 10px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 436px;
		height: 44px;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#m19_pc_RSGroup_9694 {
		position: absolute;
		width: 370px;
		height: 26px;
		left: 19px;
		top: 312px;
		overflow: visible;
	}
	#m19_pc_RSBangalore {
		opacity: 0.5;
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 26px;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#m19_pc_RSgeo-alt {
		position: absolute;
		width: 17px;
		height: 22.667px;
		left: 0px;
		top: 2px;
		overflow: visible;
	}
	#m19_pc_RSPath_2927 {
		fill: rgba(254,120,71,1);
	}
	.m19_pc_RSPath_2927 {
		overflow: visible;
		position: absolute;
		width: 17px;
		height: 22.666px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#m19_pc_RSFeatured_Apartment_ {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 488px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 333px;
		white-space: nowrap;
		text-align: center;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(20,57,91,1);
	}
	#m19_pc_RSThe_Most_frequently_searched_a {
		opacity: 0.79;
		left: 283px;
		top: 68px;
		position: absolute;
		overflow: visible;
		width: 742px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#m19_pc_RSGroup_15042 {
		position: absolute;
		width: 285px;
		height: 246px;
		left: 43px;
		top: 1066px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_105 {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSRectangle_105 {
		-webkit-filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		        filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 336px;
		left: 0px;
		top: 0px;
	}
	#m19_pc_RSGroup_9756 {
		position: absolute;
		width: 234px;
		height: 204px;
		left: 26px;
		top: 26px;
		overflow: visible;
	}
	#m19_pc_RStop_ {
		position: absolute;
		width: 234px;
		height: 166px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSDeluxe_Portion {
		opacity: 0.79;
		left: 58px;
		top: 179px;
		position: absolute;
		overflow: visible;
		width: 118px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#m19_pc_RSGroup_9689 {
		position: absolute;
		width: 285px;
		height: 246px;
		left: 375px;
		top: 1066px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_105_dr {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSRectangle_105_dr {
		-webkit-filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		        filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 336px;
		left: 0px;
		top: 0px;
	}
	#m19_pc_RSGroup_9756_ds {
		position: absolute;
		width: 234px;
		height: 204px;
		left: 26px;
		top: 26px;
		overflow: visible;
	}
	#m19_pc_RSfrancesca-tosolini-tHkJAMcO3QE {
		position: absolute;
		width: 234px;
		height: 166px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSDouble_Height {
		opacity: 0.79;
		left: 60px;
		top: 179px;
		position: absolute;
		overflow: visible;
		width: 115px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#m19_pc_RSGroup_9689_dv {
		position: absolute;
		width: 285px;
		height: 246px;
		left: 707px;
		top: 1066px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_105_dw {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSRectangle_105_dw {
		-webkit-filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		        filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 336px;
		left: 0px;
		top: 0px;
	}
	#m19_pc_RSGroup_9756_dx {
		position: absolute;
		width: 234px;
		height: 204px;
		left: 26px;
		top: 26px;
		overflow: visible;
	}
	#m19_pc_RSalice-kang-Bc_uN5PeErA-unsplas {
		position: absolute;
		width: 234px;
		height: 166px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSPenthouse {
		opacity: 0.79;
		left: 73px;
		top: 179px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#m19_pc_RSGroup_9689_d {
		position: absolute;
		width: 285px;
		height: 246px;
		left: 1038px;
		top: 1066px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_105_d {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSRectangle_105_d {
		-webkit-filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		        filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 336px;
		left: 0px;
		top: 0px;
	}
	#m19_pc_RSGroup_9756_d {
		position: absolute;
		width: 234px;
		height: 204px;
		left: 26px;
		top: 26px;
		overflow: visible;
	}
	#m19_pc_RSharry-cunningham-UWblg2i6Blw-u {
		position: absolute;
		width: 234px;
		height: 166px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSThe_Studio {
		opacity: 0.79;
		left: 72px;
		top: 179px;
		position: absolute;
		overflow: visible;
		width: 87px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#m19_pc_RSGroup_15030 {
		position: absolute;
		width: 1200px;
		height: 605px;
		left: 83px;
		top: 1412.001px;
		overflow: visible;
	}
	#m19_pc_RSUnion_2 {
		fill: rgba(34,137,255,1);
	}
	.m19_pc_RSUnion_2 {
		overflow: visible;
		position: absolute;
		width: 207.932px;
		height: 207.932px;
		left: 387.412px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#m19_pc_RSBuy__Dream_Apartment_In_The_Be {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 0px;
		top: 115px;
		position: absolute;
		overflow: visible;
		width: 395px;
		white-space: nowrap;
		line-height: 79px;
		margin-top: -18.5px;
		text-align: left;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 42px;
		color: rgba(20,57,91,1);
	}
	#m19_pc_RSGroup_9721 {
		position: absolute;
		width: 241px;
		height: 77px;
		left: 0px;
		top: 383px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_107 {
		fill: rgba(24,106,165,1);
	}
	.m19_pc_RSRectangle_107 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 259px;
		height: 95px;
		left: 0px;
		top: 0px;
	}
	#m19_pc_RSSee_All_Apartment {
		left: 39px;
		top: 24px;
		position: absolute;
		overflow: visible;
		width: 159px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(255,255,255,1);
	}
	#m19_pc_RSavi-werde-hHz4yrvxwlA-unsplash {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 759px;
		height: 568px;
		left: 441px;
		top: 37px;
		overflow: visible;
	}
	#m19_pc_RSjason-briscoe-UV81E0oXXWQ-unsp {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 376.233px;
		height: 250.571px;
		left: 335.958px;
		top: 334.1px;
		overflow: visible;
	}
	#m19_pc_RSGroup_9722 {
		position: absolute;
		width: 82px;
		height: 82px;
		left: 483px;
		top: 419px;
		overflow: visible;
	}
	#m19_pc_RSEllipse_17 {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSEllipse_17 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 100px;
		height: 100px;
		left: 0px;
		top: 0px;
	}
	#m19_pc_RSPath_2939 {
		fill: rgba(34,137,255,1);
	}
	.m19_pc_RSPath_2939 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(34, 137, 255, 0.341));
		        filter: drop-shadow(0px 3px 6px rgba(34, 137, 255, 0.341));
		overflow: visible;
		position: absolute;
		width: 34.211px;
		height: 41.965px;
		left: 32.695px;
		top: 29.285px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#m19_pc_RSGroup_15039 {
		position: absolute;
		width: 1193px;
		height: 1695px;
		left: 87px;
		top: 2117px;
		overflow: visible;
	}
	#m19_pc_RSGroup_15033 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSavi-werde-hHz4yrvxwlA-unsplash_ei {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSGroup_15038 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 600px;
		top: 38px;
		overflow: visible;
	}
	#m19_pc_RSScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#m19_pc_RSIt_is_a_long_established_fact_ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#m19_pc_RSVISION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#m19_pc_RSGroup_15034 {
		position: absolute;
		width: 1193px;
		height: 419.078px;
		left: 0px;
		top: 638px;
		overflow: visible;
	}
	#m19_pc_RSsean-pollock-PhYq704ffdA-unspl {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 560px;
		height: 419.078px;
		left: 633px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSGroup_15037 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 0px;
		top: 39px;
		overflow: visible;
	}
	#m19_pc_RSScroll_Group_28_eq {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#m19_pc_RSIt_is_a_long_established_fact__er {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#m19_pc_RSMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#m19_pc_RSGroup_15035 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 1276px;
		overflow: visible;
	}
	#m19_pc_RSwebaliser-_TPTXZd9mOo-unsplash {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSScroll_Group_28_ev {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 600px;
		top: 103px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#m19_pc_RSIt_is_a_long_established_fact__ew {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#m19_pc_RSPRINCIPLES {
		left: 618px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#m19_pc_RSGroup_15043 {
		position: absolute;
		width: 1456px;
		height: 789px;
		left: -90px;
		top: 22px;
		overflow: visible;
	}
	#m19_pc_RSjoel-filipe-RFDP7_80v5A-unspla {
		position: absolute;
		width: 1041px;
		height: 690px;
		left: 415px;
		top: 99px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_102 {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSRectangle_102 {
		-webkit-filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		        filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		position: absolute;
		overflow: visible;
		width: 721px;
		height: 378px;
		left: 0px;
		top: 180px;
	}
	#m19_pc_RSLets_fullfill___yours_Dream {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 121px;
		top: 200px;
		position: absolute;
		overflow: visible;
		width: 439px;
		white-space: nowrap;
		text-align: left;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 65px;
		color: rgba(34,137,255,1);
	}
	#m19_pc_RSBuilding_your_dream_house {
		left: 133px;
		top: 402px;
		position: absolute;
		overflow: visible;
		width: 266px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(48,48,48,1);
	}
	#m19_pc_RSAnime_Edit {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 434px;
		top: 0px;
		border-radius: 50%;
		overflow: hidden;
	}
	#m19_pc_RSOni_Chan_Haircut {
		left: 529px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 156px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#m19_pc_RSGroup_15041 {
		position: absolute;
		width: 1366px;
		height: 517px;
		left: 0px;
		top: 4813px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_736_e {
		fill: url(#m19_pc_RSRectangle_736_e);
	}
	.m19_pc_RSRectangle_736_e {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 517px;
		left: 0px;
		top: 0px;
	}
	#m19_pc_RSGroup_15040 {
		position: absolute;
		width: 657px;
		height: 438px;
		left: 56px;
		top: 39px;
		overflow: visible;
	}
	#m19_pc_RSkisspng-loft-window-office-roo {
		position: absolute;
		width: 657px;
		height: 438px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 492px;
		height: 278px;
		left: 102px;
		top: 61px;
		border-radius: 10px;
	}
	#m19_pc_RSsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 826px;
		top: 355px;
		overflow: visible;
	}
	#m19_pc_RSlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.m19_pc_RSmed_shadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#m19_pc_RSmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.m19_pc_RSmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#m19_pc_RSlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#m19_pc_RSn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.m19_pc_RSn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#m19_pc_RSlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.m19_pc_RSlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#m19_pc_RStop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.m19_pc_RStop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#m19_pc_RSInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.m19_pc_RSshadow_space_website_7:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#m19_pc_RSshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.m19_pc_RSshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#m19_pc_RSinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#m19_pc_RSwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSmed_shadow_space_website_7_fo:hover {
		fill: rgba(50,172,158,1);
	}
	.m19_pc_RSmed_shadow_space_website_7_fo:hover {
		-webkit-filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#m19_pc_RSmed_shadow_space_website_7_fo {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.m19_pc_RSmed_shadow_space_website_7_fo {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#m19_pc_RSwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#m19_pc_RSn_234567890_mobile_number_shad {
		left: 861px;
		top: 124px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#m19_pc_RSaffanghanioperatelive_email_sh {
		left: 866px;
		top: 177px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#m19_pc_RSaddress_med_shadow_space_websi {
		left: 861px;
		top: 227px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#m19_pc_RSlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 826px;
		top: 229px;
		overflow: visible;
	}
	#m19_pc_RSemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 826px;
		top: 176px;
		overflow: visible;
	}
	#m19_pc_RSphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 826px;
		top: 123px;
		overflow: visible;
	}
	#m19_pc_RSn_Copyright_Operate_Live_OPC_P {
		left: 826px;
		top: 451px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	.mediaViewInfo {
		--web-view-name: KnowledgeSpace;
		--web-view-id: KnowledgeSpace;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: KnowledgeSpace;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#KnowledgeSpace {
		position: absolute;
		width: 1440px;
		height: 4790px;
        left:calc((100vw - 1440px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: KnowledgeSpace;
		--web-view-id: KnowledgeSpace;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#KS_20_PCGroup_117 {
		position: absolute;
		width: 752px;
		height: 428px;
		left: 344px;
		top: 4331px;
		overflow: visible;
	}
	#KS_20_PClocation {
		position: absolute;
		width: 24px;
		height: 30px;
		left: 576px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCn_Copyright_Live_OPC_Private_L {
		left: 120px;
		top: 406px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(173,173,173,1);
	}
	#KS_20_PCmessage {
		position: absolute;
		width: 30px;
		height: 21px;
		left: 576px;
		top: 157px;
		overflow: visible;
	}
	#KS_20_PCphone {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 576px;
		top: 295px;
		overflow: visible;
	}
	#KS_20_PCScroll_Group_66 {
		mix-blend-mode: normal;
		position: absolute;
		width: 182px;
		height: 81px;
		left: 570px;
		top: 41px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_PCScroll_Group_66::-webkit-scrollbar{
		display: none;
	}
	#KS_20_PCLSE_Houghton_Street_London_WC2 {
		left: 6px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 76px;
		line-height: 24px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(72,72,72,1);
	}
	#KS_20_PChellogmailcom {
		left: 576px;
		top: 203px;
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 76px;
		line-height: 24px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(72,72,72,1);
	}
	#KS_20_PCn_4057686 {
		left: 576px;
		top: 341px;
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 76px;
		line-height: 24px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(72,72,72,1);
	}
	#KS_20_PCRectangle_740 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.KS_20_PCRectangle_740 {
		position: absolute;
		overflow: visible;
		width: 476px;
		height: 314px;
		left: 0px;
		top: 0px;
	}
	#KS_20_PCGroup_111 {
		position: absolute;
		width: 1326px;
		height: 852px;
		left: 75px;
		top: 2422px;
		overflow: visible;
	}
	#KS_20_PCCourses {
		left: 556px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 179px;
		white-space: nowrap;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 48px;
		color: rgba(17,17,17,1);
	}
	#KS_20_PCScroll_Group_67 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1326px;
		height: 786px;
		left: 0px;
		top: 66px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#KS_20_PCScroll_Group_67::-webkit-scrollbar{
		display: none;
	}
	#KS_20_PCGroup_15053 {
		position: relative;
		width: 371px;
		height: 700px;
		left: 58px;
		top: 43px;
        margin-right: 30px;
		overflow: visible;
	}
	#KS_20_PCRectangle_71 {
		position: absolute;
		width: 371px;
		height: 301px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_25 {
		position: absolute;
		width: 371px;
		height: 399px;
		left: 0px;
		top: 301px;
		overflow: visible;
	}
	#KS_20_PCRectangle_72 {
		fill: rgba(241,241,241,1);
	}
	.KS_20_PCRectangle_72 {
		position: absolute;
		overflow: visible;
		width: 371px;
		height: 399px;
		left: 0px;
		top: 0px;
	}
	#KS_20_PCRectangle_73 {
		fill: rgba(207,207,207,1);
	}
	.KS_20_PCRectangle_73 {
		position: absolute;
		overflow: visible;
		width: 371px;
		height: 3px;
		left: 0px;
		top: 188px;
	}
	#KS_20_PCRectangle_74 {
		fill: rgba(207,207,207,1);
	}
	.KS_20_PCRectangle_74 {
		position: absolute;
		overflow: visible;
		width: 245px;
		height: 2px;
		left: 63px;
		top: 272px;
	}
	#KS_20_PCWeb_Design {
		left: 31px;
		top: 31px;
		position: absolute;
		overflow: visible;
		width: 171px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCTime__3_pm {
		/* left: 112px; */
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 100%;
        text-align: center;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -4px;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCScroll_Group_68 {
		mix-blend-mode: normal;
		position: absolute;
		width: 312px;
		height: 84px;
		left: 21px;
		top: 89px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_PCLorem_ipsum_dolor_sit_amet_con {
		left: 10px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 293px;
		height: 70px;
		line-height: 24px;
		margin-top: -3px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(72,72,72,1);
	}
	#KS_20_PCRectangle_76 {
		fill: rgba(39,174,96,1);
	}
	.KS_20_PCRectangle_76 {
		position: absolute;
		overflow: visible;
		width: 325px;
		height: 60px;
		left: 24px;
		top: 311px;
	}
	#KS_20_PCJoin_Now {
		left: 136px;
		top: 325px;
		position: absolute;
		overflow: visible;
		width: 102px;
		white-space: nowrap;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(255,255,255,1);
	}
	#KS_20_PCGroup_112 {
		position: absolute;
		width: 1440px;
		height: 664px;
		left: 0px;
		top: 3441px;
		overflow: visible;
	}
	#KS_20_PCPath_2 {
		fill: rgba(241,241,241,1);
	}
	.KS_20_PCPath_2 {
		overflow: visible;
		position: absolute;
		width: 1440px;
		height: 664px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#KS_20_PCOur_Teachers {
		left: 574px;
		top: 67px;
		position: absolute;
		overflow: visible;
		width: 293px;
		white-space: nowrap;
		line-height: 60px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 48px;
		color: rgba(17,17,17,1);
	}
	#KS_20_PCGroup_27 {
		position: absolute;
		width: 329px;
		height: 96px;
		left: 134px;
		top: 173px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6 {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26 {
		position: absolute;
		width: 197px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCVelvet_Vachon {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 198px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCDesign_Head {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 143px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_27_d {
		position: absolute;
		width: 319px;
		height: 96px;
		left: 534px;
		top: 173px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6_d {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26_d {
		position: absolute;
		width: 187px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCArlene_Anello {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 188px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCSEO_Head {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 114px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_27_ea {
		position: absolute;
		width: 341px;
		height: 96px;
		left: 934px;
		top: 173px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6_ea {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26_ea {
		position: absolute;
		width: 209px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCBenton_Colley {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 193px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCPhotography_Head {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 210px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_27_ec {
		position: absolute;
		width: 317px;
		height: 96px;
		left: 134px;
		top: 329px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6_ed {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26_ee {
		position: absolute;
		width: 185px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCFloyd_Fukuda {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 186px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCMarketing_HOD {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 174px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_27_eh {
		position: absolute;
		width: 284px;
		height: 96px;
		left: 534px;
		top: 329px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6_ei {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26_ej {
		position: absolute;
		width: 152px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCElena_Cully {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 153px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCDesign_Head_el {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 143px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_27_em {
		position: absolute;
		width: 335px;
		height: 96px;
		left: 934px;
		top: 329px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6_en {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26_eo {
		position: absolute;
		width: 203px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCBurton_Brooke {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 204px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCWeb_Technologist {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 200px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_27_er {
		position: absolute;
		width: 349px;
		height: 96px;
		left: 134px;
		top: 485px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6_es {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26_et {
		position: absolute;
		width: 217px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCRessie_Rottman {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 218px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCDesign_Head_ev {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 143px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_27_ew {
		position: absolute;
		width: 341px;
		height: 96px;
		left: 534px;
		top: 485px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6_ex {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26_ey {
		position: absolute;
		width: 209px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCReed_Recio {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 156px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCPhotography_Head_e {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 210px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_27_e {
		position: absolute;
		width: 345px;
		height: 96px;
		left: 934px;
		top: 485px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6_e {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26_e {
		position: absolute;
		width: 213px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCNancee_Bluford {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 214px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCSEO_Head_e {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 114px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_108 {
		position: absolute;
		width: 1440px;
		height: 700px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCRectangle_1 {
		fill: rgba(26,26,26,1);
	}
	.KS_20_PCRectangle_1 {
		position: absolute;
		overflow: visible;
		width: 1440px;
		height: 700px;
		left: 0px;
		top: 0px;
	}
	#KS_20_PCPath_1 {
		opacity: 0.7;
		position: absolute;
		width: 1440px;
		height: 700px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCKnowledge_is_power {
		left: 381px;
		top: 271px;
		position: absolute;
		overflow: visible;
		width: 678px;
		white-space: nowrap;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 72px;
		color: rgba(255,255,255,1);
	}
	#KS_20_PCAny_succesfull_career_starts_w {
		left: 451px;
		top: 377px;
		position: absolute;
		overflow: visible;
		width: 540px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -4px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(255,255,255,1);
	}
	#KS_20_PClogo {
		position: absolute;
		width: 40px;
		height: 32px;
		left: 135px;
		top: 48px;
        border-radius: 50%;
		overflow: hidden;
	}
	#KS_20_PCRectangle_148 {
		fill: rgba(39,174,97,1);
	}
	.KS_20_PCRectangle_148 {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 56px;
		left: 623px;
		top: 462px;
	}
	#KS_20_PCLearn_more {
		left: 652px;
		top: 477px;
		position: absolute;
		overflow: visible;
		width: 139px;
		white-space: nowrap;
		text-align: center;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
		color: rgba(255,255,255,1);
	}
	#KS_20_PCName {
		left: 192px;
		top: 53px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#KS_20_PCGroup_15056 {
		position: absolute;
		width: 1167px;
		height: 1527px;
		left: 136px;
		top: 760px;
		overflow: visible;
	}
	#KS_20_PCGroup_109 {
		position: absolute;
		width: 1162px;
		height: 440px;
		left: 0px;
		top: 543px;
		overflow: visible;
	}
	#KS_20_PCtamanna-rumee-vaTsR-ghLog-unsp {
		position: absolute;
		width: 440px;
		height: 440px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_3 {
		position: absolute;
		width: 480px;
		height: 305px;
		left: 682px;
		top: 64px;
		overflow: visible;
	}
	#KS_20_PCMission {
		left: 14px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 126px;
		white-space: nowrap;
		line-height: 48px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCScroll_Group_65 {
		mix-blend-mode: normal;
		position: absolute;
		width: 480px;
		height: 235px;
		left: 0px;
		top: 70px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_PCScroll_Group_65::-webkit-scrollbar{
		display: none;
	}
	#KS_20_PCLorem_ipsum_dolor_sit_amet_con_fl {
		left: 15px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 451px;
		height: 232px;
		line-height: 24px;
		margin-top: -3px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCGroup_15051 {
		position: absolute;
		width: 1164px;
		height: 440px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCtim-mossholder-WE_Kv_ZB1l0-uns {
		position: absolute;
		width: 440px;
		height: 440px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_3_fo {
		position: absolute;
		width: 484px;
		height: 326px;
		left: 680px;
		top: 64px;
		overflow: visible;
	}
	#KS_20_PCVision {
		left: 16px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		line-height: 48px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCScroll_Group_64 {
		mix-blend-mode: normal;
		position: absolute;
		width: 484px;
		height: 267px;
		left: 0px;
		top: 59px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_PCScroll_Group_64::-webkit-scrollbar{
		display: none;
	}
	#KS_20_PCLorem_ipsum_dolor_sit_amet_con_fr {
		left: 17px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 451px;
		height: 249px;
		line-height: 24px;
		margin-top: -3px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCGroup_15052 {
		position: absolute;
		width: 1167px;
		height: 440px;
		left: 0px;
		top: 1087px;
		overflow: visible;
	}
	#KS_20_PCnguyen-khanh-ly-liiusuaISP0-un {
		position: absolute;
		width: 440px;
		height: 440px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_3_fu {
		position: absolute;
		width: 490px;
		height: 335px;
		left: 677px;
		top: 64px;
		overflow: visible;
	}
	#KS_20_PCPrinciples {
		left: 19px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 162px;
		white-space: nowrap;
		line-height: 48px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCScroll_Group_63 {
		mix-blend-mode: normal;
		position: absolute;
		width: 490px;
		height: 286px;
		left: 0px;
		top: 49px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_PCScroll_Group_63::-webkit-scrollbar{
		display: none;
	}
	#KS_20_PCLorem_ipsum_dolor_sit_amet_con_fx {
		left: 20px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 451px;
		height: 258px;
		line-height: 24px;
		margin-top: -3px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCContact {
		left: 634px;
		top: 4205px;
		position: absolute;
		overflow: visible;
		width: 174px;
		white-space: nowrap;
		line-height: 60px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 48px;
		color: rgba(17,17,17,1);
	}
	.mediaViewInfo {
		--web-view-name: BakersStop;
		--web-view-id: BakersStop;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: BakersStop;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#BakersStop {
		position: absolute;
		width: 1366px;
		height: 3880px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: BakersStop;
		--web-view-id: BakersStop;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#BCS_21_PCLine_20 {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_20 {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 309.618px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_21 {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_21 {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 323.87px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_22 {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_22 {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 338.121px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_23 {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_23 {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 352.373px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_24 {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_24 {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 366.625px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_25 {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_25 {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 380.876px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_20_p {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_20_p {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 305.917px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_21_q {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_21_q {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 319.998px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_22_r {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_22_r {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 334.08px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_23_s {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_23_s {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 348.161px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_24_t {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_24_t {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 362.242px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_25_u {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_25_u {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 376.324px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCCategory_Section {
		position: absolute;
		width: 1341px;
		height: 396px;
		left: 0px;
		top: 2903px;
		overflow: visible;
	}
	#BCS_21_PCRectangle_662 {
		fill: rgba(0,0,0,1);
	}
	.BCS_21_PCRectangle_662 {
		position: absolute;
		overflow: visible;
		width: 1316px;
		height: 5px;
		left: 25px;
		top: 0px;
	}
	#BCS_21_PCScroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1341px;
		height: 391px;
		left: 0px;
		top: 5px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow: hidden;
	}
	#BCS_21_PCn_ {
		position: absolute;
		width: 307px;
		height: 366px;
		left: 25px;
		top: 25px;
		overflow: visible;
	}
	#BCS_21_PCMask_Group_22 {
		position: relative;
		width: 307px;
		height: 366px;
		left: 0px;
		top: 0px;
        margin-right: 30px;
		overflow: visible;
	}
	#BCS_21_PCRectangle_664 {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCRectangle_664 {
		position: absolute;
		overflow: visible;
		width: 170px;
		height: 70px;
		left: 0px;
		top: 296px;
	}
	#BCS_21_PCHyundai_Azera_2020 {
		left: 5px;
		top: 310px;
		position: absolute;
		overflow: visible;
		width: 161px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
		text-transform: uppercase;
	}
	#BCS_21_PCGroup_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 131px;
		top: 268px;
		overflow: visible;
	}
	#BCS_21_PCEllipse_38 {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCEllipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_PCEllipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCEllipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#BCS_21_PCEllipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCEllipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#BCS_21_PCEllipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCEllipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#BCS_21_PCEllipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCEllipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#BCS_21_PCn_rd_Section {
		position: absolute;
		width: 1057px;
		height: 489px;
		left: 132px;
		top: 2128px;
		overflow: visible;
	}
	#BCS_21_PCScroll_Group_76 {
		mix-blend-mode: normal;
		position: absolute;
		width: 575px;
		height: 343px;
		left: 482px;
		top: 134px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BCS_21_PCScroll_Group_76::-webkit-scrollbar{
		display: none;
	}
	#BCS_21_PCBinQasem_provides_a_platform_o {
		left: 28px;
		top: 25px;
		position: absolute;
		overflow: visible;
		width: 520px;
		height: 293px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCPrinciple {
		left: 510px;
		top: 57px;
		position: absolute;
		overflow: visible;
		width: 230px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 46px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#BCS_21_PCMask_Group_23 {
		position: absolute;
		width: 427px;
		height: 489px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCn_rd_Section_bd {
		position: absolute;
		width: 1050px;
		height: 489px;
		left: 111px;
		top: 1554px;
		overflow: visible;
	}
	#BCS_21_PCScroll_Group_75 {
		mix-blend-mode: normal;
		position: absolute;
		width: 561px;
		height: 327px;
		left: 0px;
		top: 142px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BCS_21_PCScroll_Group_75::-webkit-scrollbar{
		display: none;
	}
	#BCS_21_PCBinQasem_provides_a_platform_o_bf {
		left: 21px;
		top: 17px;
		position: absolute;
		overflow: visible;
		width: 520px;
		height: 293px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCMission {
		left: 21px;
		top: 57px;
		position: absolute;
		overflow: visible;
		width: 197px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 46px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#BCS_21_PCMask_Group_23_bh {
		position: absolute;
		width: 427px;
		height: 489px;
		left: 623px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCn_rd_Section_bi {
		position: absolute;
		width: 1075px;
		height: 489px;
		left: 132px;
		top: 980px;
		overflow: visible;
	}
	#BCS_21_PCScroll_Group_74 {
		mix-blend-mode: normal;
		position: absolute;
		width: 588px;
		height: 315px;
		left: 487px;
		top: 148px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BCS_21_PCScroll_Group_74::-webkit-scrollbar{
		display: none;
	}
	#BCS_21_PCBinQasem_provides_a_platform_o_bk {
		left: 23px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 520px;
		height: 293px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCVISION {
		left: 510px;
		top: 57px;
		position: absolute;
		overflow: visible;
		width: 158px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 46px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#BCS_21_PCMask_Group_23_bm {
		position: absolute;
		width: 427px;
		height: 489px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCour_services {
		left: 534px;
		top: 2813px;
		position: absolute;
		overflow: visible;
		width: 316px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 46px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#BCS_21_PCGroup_15060 {
		position: absolute;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 3479px;
		overflow: visible;
	}
	#BCS_21_PCGroup_14807 {
		position: absolute;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCMask_Group_25 {
		position: absolute;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCRectangle_678 {
		opacity: 0.97;
		fill: rgba(50,50,50,1);
	}
	.BCS_21_PCRectangle_678 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_PCGroup_14850 {
		position: absolute;
		width: 566px;
		height: 365px;
		left: 744px;
		top: 25px;
		overflow: visible;
	}
	#BCS_21_PCn_2321397_luyk_98k2_201103 {
		position: absolute;
		width: 566px;
		height: 365px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCRectangle_680 {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCRectangle_680 {
		position: absolute;
		overflow: visible;
		width: 522px;
		height: 235px;
		left: 21px;
		top: 110px;
	}
	#BCS_21_PCRectangle_741 {
		fill: rgba(236,224,200,1);
	}
	.BCS_21_PCRectangle_741 {
		position: absolute;
		overflow: visible;
		width: 173px;
		height: 51px;
		left: 939px;
		top: 35px;
	}
	#BCS_21_PCBAKERY {
		left: 969px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 117px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe Script;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCGroup_15059 {
		position: absolute;
		width: 1365.56px;
		height: 204.113px;
		left: 0px;
		top: 172px;
		overflow: visible;
	}
	#BCS_21_PCFor_Queries_infoandroomedanet {
		left: 68.371px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 219px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#BCS_21_PCFor_call__920012888 {
		left: 68.371px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#BCS_21_PCtelephone {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCtelephone {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 41.845px;
		top: 25.525px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCsend_1 {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCsend_1 {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 41.845px;
		top: 4.72px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_26 {
		fill: transparent;
		stroke: rgba(86,86,86,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_26 {
		overflow: visible;
		position: absolute;
		width: 1365.56px;
		height: 2px;
		left: 0px;
		top: 204.113px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCsocials_grp_med_web_4_mob {
		position: absolute;
		width: 210px;
		height: 50.001px;
		left: 41.845px;
		top: 129.999px;
		overflow: visible;
	}
	#BCS_21_PClinked_in_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 160px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCEllipse_5_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCEllipse_5_med_web_4_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_PClinked_in_website_4_layout {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#BCS_21_PCn_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.BCS_21_PCn_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.954px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PClinked_in_i_down {
		fill: rgba(63,61,86,1);
	}
	.BCS_21_PClinked_in_i_down {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.097px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCtop_dot_i_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.BCS_21_PCtop_dot_i_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCInsta_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 80px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCEllipse_4_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCEllipse_4_med_web_4_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_PCinstagram_website_4_layout {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.126px;
		overflow: visible;
	}
	#BCS_21_PCwhatsapp_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#BCS_21_PCEllipse_3_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCEllipse_3_med_web_4_pc {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_PCwhatsapp_lbl_website4_layout {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#BCS_21_PCIcon_metro-location {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCIcon_metro-location {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 18.209px;
		left: 41.845px;
		top: 46.33px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCaddress {
		left: 68.371px;
		top: 45.539px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 62px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#BCS_21_PCGroup_14851 {
		position: absolute;
		width: 1299px;
		height: 802px;
		left: 34px;
		top: 70px;
		overflow: visible;
	}
	#BCS_21_PCRectangle_655 {
		fill: rgba(246,246,246,1);
	}
	.BCS_21_PCRectangle_655 {
		position: absolute;
		overflow: visible;
		width: 764px;
		height: 595px;
		left: 470px;
		top: 207px;
	}
	#BCS_21_PCAlways_Find_The_Best_Deals {
		left: 8px;
		top: 128px;
		position: absolute;
		overflow: visible;
		width: 125px;
		white-space: nowrap;
		text-align: right;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCSocial {
		position: absolute;
		width: 266px;
		height: 12px;
		left: 588px;
		top: 559px;
		overflow: visible;
	}
	#BCS_21_PCFacebook {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 45px;
		white-space: nowrap;
		text-align: right;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCInstagram {
		left: 78px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 48px;
		white-space: nowrap;
		text-align: right;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCTwitter {
		left: 159px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 32px;
		white-space: nowrap;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCSnapchat {
		left: 222px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 45px;
		white-space: nowrap;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCBEST_IN__CLASS_ {
		left: 0px;
		top: 158px;
		position: absolute;
		overflow: visible;
		width: 300px;
		white-space: nowrap;
		line-height: 83px;
		margin-top: -1px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 81px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCMask_Group_20 {
		position: absolute;
		width: 447px;
		height: 510px;
		left: 411px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCMask_Group_21 {
		position: absolute;
		width: 398px;
		height: 395px;
		left: 901px;
		top: 229px;
		overflow: visible;
	}
	#BCS_21_PCSell_btn {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 8px;
		top: 380px;
		overflow: visible;
	}
	#BCS_21_PCRectangle_659 {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1.5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCRectangle_659 {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_PCGet_it_now {
		left: 33px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 67px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#BCS_21_PCNAME {
		left: 8px;
		top: 36px;
		position: absolute;
		overflow: visible;
		width: 70px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe Script;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCContact_us_ {
		left: 209px;
		top: 3526px;
		position: absolute;
		overflow: visible;
		width: 203px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}
.mediaViewInfo {
		--web-view-name: Clinic;
		--web-view-id: Clinic;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Clinic;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Clinic {
		position: absolute;
		width: 1366px;
		height: 4811px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: Clinic;
		--web-view-id: Clinic;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#Clinic_23_pcGroup_9691 {
		position: absolute;
		width: 13.622px;
		height: 10.84px;
		left: 287.366px;
		top: 1279.346px;
		overflow: visible;
	}
	#Clinic_23_pccheck {
		position: absolute;
		width: 13.622px;
		height: 10.84px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcPath_2923 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_pcPath_2923 {
		overflow: visible;
		position: absolute;
		width: 13.622px;
		height: 10.84px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_pcGroup_15032 {
		position: absolute;
		width: 1300px;
		height: 660px;
		left: 33px;
		top: 3632.999px;
		overflow: visible;
	}
	#Clinic_23_pcScroll_Group_60 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1300px;
		height: 572.001px;
		left: 0px;
		top: 87.999px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#Clinic_23_pcScroll_Group_60::-webkit-scrollbar{
		display: none;
	}
	#Clinic_23_pcGroup_15031 {
		position: relative;
		width: 501px;
		height: 394px;
		left: 20px;
		top: 18px;
        margin-right: 30px;
		overflow: visible;
	}
	#Clinic_23_pcRectangle_112 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_pcRectangle_112 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 519px;
		height: 412px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_pcMask_Group_4 {
		position: absolute;
		width: 460px;
		height: 277px;
		left: 21px;
		top: 21px;
		overflow: visible;
	}
	#Clinic_23_pcDental_Clinic {
		left: 19px;
		top: 352px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Sen;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#Clinic_23_pcGroup_9694 {
		position: absolute;
		width: 370px;
		height: 26px;
		left: 19px;
		top: 312px;
		overflow: visible;
	}
	#Clinic_23_pcBangalore {
		opacity: 0.5;
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 26px;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#Clinic_23_pcgeo-alt {
		position: absolute;
		width: 17px;
		height: 22.667px;
		left: 0px;
		top: 2px;
		overflow: visible;
	}
	#Clinic_23_pcPath_2927 {
		fill: rgba(254,120,71,1);
	}
	.Clinic_23_pcPath_2927 {
		overflow: visible;
		position: absolute;
		width: 17px;
		height: 22.667px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_pcOur_Treatments {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 525px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 259px;
		white-space: nowrap;
		text-align: center;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(215,113,108,1);
	}
	#Clinic_23_pcGroup_15030 {
		position: absolute;
		width: 1200px;
		height: 605px;
		left: 83px;
		top: 977.001px;
		overflow: visible;
	}
	#Clinic_23_pcUnion_2 {
		fill: rgba(215,113,108,1);
	}
	.Clinic_23_pcUnion_2 {
		overflow: visible;
		position: absolute;
		width: 207.932px;
		height: 207.932px;
		left: 387.412px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_pcGet_The_Best__Treatments_Done_ {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 0px;
		top: 115px;
		position: absolute;
		overflow: visible;
		width: 348px;
		white-space: nowrap;
		line-height: 79px;
		margin-top: -18.5px;
		text-align: left;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 42px;
		color: rgba(20,57,91,1);
	}
	#Clinic_23_pcGroup_9721 {
		position: absolute;
		width: 241px;
		height: 77px;
		left: 0px;
		top: 383px;
		overflow: visible;
	}
	#Clinic_23_pcRectangle_107 {
		fill: rgba(215,113,108,1);
	}
	.Clinic_23_pcRectangle_107 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 259px;
		height: 95px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_pcBook_An_Appointment {
		left: 23px;
		top: 24px;
		position: absolute;
		overflow: visible;
		width: 196px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(255,255,255,1);
	}
	#Clinic_23_pcn_8706987_TaeAugust07 {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 759px;
		height: 568px;
		left: 441px;
		top: 37px;
		overflow: visible;
	}
	#Clinic_23_pcGroup_15039 {
		position: absolute;
		width: 1193px;
		height: 1695px;
		left: 87px;
		top: 1779px;
		overflow: visible;
	}
	#Clinic_23_pcGroup_15033 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcclose-up-doctor-writing {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcGroup_15038 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 600px;
		top: 38px;
		overflow: visible;
	}
	#Clinic_23_pcScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Clinic_23_pcScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#Clinic_23_pcIt_is_a_long_established_fact_ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#Clinic_23_pcVISION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_pcGroup_15034 {
		position: absolute;
		width: 1193px;
		height: 419.078px;
		left: 0px;
		top: 638px;
		overflow: visible;
	}
	#Clinic_23_pcclose-up-businessman-businessw {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 560px;
		height: 419.078px;
		left: 633px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcGroup_15037 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 0px;
		top: 39px;
		overflow: visible;
	}
	#Clinic_23_pcScroll_Group_28_bu {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Clinic_23_pcScroll_Group_28_bu::-webkit-scrollbar{
		display: none;
	}
	#Clinic_23_pcIt_is_a_long_established_fact__bv {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#Clinic_23_pcMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_pcGroup_15035 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 1276px;
		overflow: visible;
	}
	#Clinic_23_pcdoctor-patient {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcScroll_Group_28_bz {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 600px;
		top: 103px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Clinic_23_pcScroll_Group_28_bz::-webkit-scrollbar{
		display: none;
	}
	#Clinic_23_pcIt_is_a_long_established_fact__b {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#Clinic_23_pcPRINCIPLES {
		left: 618px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_pcGroup_15043 {
		position: absolute;
		width: 1491px;
		height: 789px;
		left: -125px;
		top: 22px;
		overflow: visible;
	}
	#Clinic_23_pcn_0173304_8507 {
		position: absolute;
		width: 1041px;
		height: 690px;
		left: 450px;
		top: 99px;
		overflow: visible;
	}
	#Clinic_23_pcRectangle_745 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_pcRectangle_745 {
		-webkit-filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		        filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		position: absolute;
		overflow: visible;
		width: 471px;
		height: 433px;
		left: 125px;
		top: 252px;
	}
	#Clinic_23_pcRectangle_102 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_pcRectangle_102 {
		-webkit-filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		        filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		position: absolute;
		overflow: visible;
		width: 550px;
		height: 532px;
		left: 0px;
		top: 202px;
	}
	#Clinic_23_pcHealing__Starts_Here_ {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 167px;
		top: 299px;
		position: absolute;
		overflow: visible;
		width: 249px;
		white-space: nowrap;
		text-align: left;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 65px;
		color: rgba(215,113,108,1);
	}
	#Clinic_23_pcAnime_Edit {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 469px;
		top: 0px;
        border-radius: 50%;
		overflow: hidden;
	}
	#Clinic_23_pcOni_Chan_Clinic {
		left: 564px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 139px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_pcGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 96px;
		top: 4392.999px;
		overflow: visible;
	}
	#Clinic_23_pcGroup_15040 {
		position: absolute;
		width: 492px;
		height: 278px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_pcRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 492px;
		height: 278px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#Clinic_23_pcsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#Clinic_23_pclinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_pcmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_pclinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#Clinic_23_pcn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.Clinic_23_pcn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_pclinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.Clinic_23_pclinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_pctop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.Clinic_23_pctop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_pcInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_pcshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_pcinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#Clinic_23_pcwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcmed_shadow_space_website_7_cn {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_pcmed_shadow_space_website_7_cn {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_pcwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#Clinic_23_pcn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_pcaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_pcaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_pclocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#Clinic_23_pcemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#Clinic_23_pcphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#Clinic_23_pcn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	.mediaViewInfo {
		--web-view-name: ChaatsParadise;
		--web-view-id: ChaatsParadise;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ChaatsParadise;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ChaatsParadise {
		position: absolute;
		width: 1366px;
		height: 3470px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: ChaatsParadise;
		--web-view-id: ChaatsParadise;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#CHAAT_22_PCRectangle_743 {
		fill: rgba(241,241,241,1);
	}
	.CHAAT_22_PCRectangle_743 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 1400px;
		left: 0px;
		top: 850px;
	}
	#CHAAT_22_PCGroup_108 {
		position: absolute;
		width: 1366px;
		height: 700px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_PCRectangle_1 {
		fill: rgba(26,26,26,1);
	}
	.CHAAT_22_PCRectangle_1 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 700px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_PCpexels-vinit-jain-16171917 {
		opacity: 0.7;
		position: absolute;
		width: 1366px;
		height: 700px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_PCTaste_the_Best_Right_Now {
		left: 251px;
		top: 271px;
		position: absolute;
		overflow: visible;
		width: 866px;
		white-space: nowrap;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 72px;
		color: rgba(255,255,255,1);
	}
	#CHAAT_22_PCAny_succesfull_career_starts_w {
		left: 414px;
		top: 377px;
		position: absolute;
		overflow: visible;
		width: 540px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -4px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(255,255,255,1);
	}
	#CHAAT_22_PClogo {
		position: absolute;
		width: 40px;
		height: 32px;
		left: 135px;
		top: 48px;
        border-radius: 50%;
		overflow: hidden;
	}
	#CHAAT_22_PCName {
		left: 192px;
		top: 53px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#CHAAT_22_PCGroup_15064 {
		position: absolute;
		width: 712px;
		height: 1150px;
		left: 327px;
		top: 975px;
		overflow: visible;
	}
	#CHAAT_22_PCCard_03_website_14_web_pc {
		position: absolute;
		width: 712px;
		height: 299.362px;
		left: 0px;
		top: 850.638px;
		overflow: visible;
	}
	#CHAAT_22_PCScroll_Group_12 {
		mix-blend-mode: normal;
		position: absolute;
		width: 305px;
		height: 250px;
		left: 407px;
		top: 49.362px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHAAT_22_PCintro_lbl_14_web_pc {
		left: 2px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 306px;
		height: 246px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#CHAAT_22_PCprinciples_lbl_14_web_pc {
		left: 505px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 115px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 27px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PCIcon_top_14_web_pc {
		fill: rgba(255,255,255,1);
	}
	.CHAAT_22_PCIcon_top_14_web_pc {
		overflow: visible;
		position: absolute;
		width: 23.555px;
		height: 4.195px;
		left: 129.491px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHAAT_22_PCkevin-mu14_web_pc {
		position: absolute;
		width: 305px;
		height: 299px;
		left: 0px;
		top: 0.362px;
		overflow: visible;
	}
	#CHAAT_22_PCCard_02_website_14_web_pc {
		position: absolute;
		width: 712px;
		height: 307.82px;
		left: 0px;
		top: 440.058px;
		overflow: visible;
	}
	#CHAAT_22_PCScroll_Group_11 {
		mix-blend-mode: normal;
		position: absolute;
		width: 347.97px;
		height: 252.947px;
		left: 364.03px;
		top: 54.872px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHAAT_22_PCintro_2_14_web_pc {
		left: 10.97px;
		top: -4px;
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 248px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#CHAAT_22_PCmission_lbl_we14_web_pc {
		left: 499px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 27px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PCzden14_web_pc {
		position: absolute;
		width: 330.572px;
		height: 307.82px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_PCCard_01_website_14_web_pc {
		position: absolute;
		width: 712px;
		height: 342px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_PCScroll_Group_10 {
		mix-blend-mode: normal;
		position: absolute;
		width: 301px;
		height: 267px;
		left: 411px;
		top: 75px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHAAT_22_PCintro_w14_web_pc {
		left: 4px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 294px;
		height: 307px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#CHAAT_22_PCVision_14_web_pc {
		left: 526px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 27px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PCaarn-giri-3lGi014_web_pc {
		position: absolute;
		width: 316px;
		height: 342px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_PCOur_Chaats {
		left: 606px;
		top: 2400px;
		position: absolute;
		overflow: visible;
		width: 156px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PCScroll_Group_78 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1302px;
		height: 436px;
		left: 43px;
		top: 2440px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#CHAAT_22_PCGroup_14872 {
		position: relative;
		width: 220px;
		height: 328px;
		left: 29px;
		top: 51px;
        margin-right: 30px;
		overflow: visible;
	}
	#CHAAT_22_PCRectangle_688 {
		fill: rgba(255,255,255,1);
		stroke: rgba(255,255,255,0.522);
		stroke-width: 22px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHAAT_22_PCRectangle_688 {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 280px;
		height: 388px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_PCpexels-shahbaz-ansari-13063300 {
		position: absolute;
		width: 220px;
		height: 296px;
		left: 0px;
		top: 32px;
		overflow: visible;
	}
	#CHAAT_22_PCPani_Puri {
		left: 3px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 218px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PCsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 786px;
		top: 3316px;
		overflow: visible;
	}
	#CHAAT_22_PClinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_PCmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CHAAT_22_PCmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_PClinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#CHAAT_22_PCn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.CHAAT_22_PCn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.671px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHAAT_22_PClinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.CHAAT_22_PClinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHAAT_22_PCtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.CHAAT_22_PCtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHAAT_22_PCInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_PCshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CHAAT_22_PCshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_PCinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.003px;
		overflow: visible;
	}
	#CHAAT_22_PCwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_PCmed_shadow_space_website_7_br {
		fill: rgba(255,255,255,1);
	}
	.CHAAT_22_PCmed_shadow_space_website_7_br {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_PCwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#CHAAT_22_PCn_234567890_mobile_number_shad {
		left: 821px;
		top: 3085px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PCaffanghanioperatelive_email_sh {
		left: 826px;
		top: 3138px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PCaddress_med_shadow_space_websi {
		left: 821px;
		top: 3188px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PClocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 786px;
		top: 3190px;
		overflow: visible;
	}
	#CHAAT_22_PCemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 786px;
		top: 3137px;
		overflow: visible;
	}
	#CHAAT_22_PCphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 786px;
		top: 3084px;
		overflow: visible;
	}
	#CHAAT_22_PCn_Copyright_Operate_Live_OPC_P {
		left: 786px;
		top: 3412px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PCRectangle_744 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHAAT_22_PCRectangle_744 {
		position: absolute;
		overflow: visible;
		width: 480px;
		height: 270px;
		left: 149px;
		top: 3094px;
		border-radius: 20px;
	}
	#CHAAT_22_PCLine_27 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHAAT_22_PCLine_27 {
		overflow: visible;
		position: absolute;
		width: 1281px;
		height: 1px;
		left: 30.5px;
		top: 3033.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	.mediaViewInfo {
		--web-view-name: FlowMasters;
		--web-view-id: FlowMasters;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: FlowMasters;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#FlowMasters {
		position: absolute;
		width: 1366px;
		height: 4080px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: FlowMasters;
		--web-view-id: FlowMasters;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#FM_24_PCGroup_15041 {
		position: absolute;
		width: 1195px;
		height: 455px;
		left: 76px;
		top: 3551px;
		overflow: visible;
	}
	#FM_24_PCGroup_15040 {
		position: absolute;
		width: 516px;
		height: 307px;
		left: 0px;
		top: 119.999px;
		overflow: visible;
	}
	#FM_24_PCRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.FM_24_PCRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 516px;
		height: 307px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#FM_24_PCsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 688px;
		top: 337px;
		overflow: visible;
	}
	#FM_24_PClinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#FM_24_PCmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FM_24_PCmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FM_24_PClinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#FM_24_PCn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.FM_24_PCn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FM_24_PClinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.FM_24_PClinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FM_24_PCtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.FM_24_PCtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FM_24_PCInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#FM_24_PCshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FM_24_PCshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FM_24_PCinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#FM_24_PCwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FM_24_PCmed_shadow_space_website_7_bf {
		fill: rgba(255,255,255,1);
	}
	.FM_24_PCmed_shadow_space_website_7_bf {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FM_24_PCwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#FM_24_PCn_234567890_mobile_number_shad {
		left: 723px;
		top: 106px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FM_24_PCaffanghanioperatelive_email_sh {
		left: 728px;
		top: 159px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FM_24_PCaddress_med_shadow_space_websi {
		left: 723px;
		top: 209px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FM_24_PClocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 688px;
		top: 211px;
		overflow: visible;
	}
	#FM_24_PCemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 688px;
		top: 158px;
		overflow: visible;
	}
	#FM_24_PCphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 688px;
		top: 105px;
		overflow: visible;
	}
	#FM_24_PCn_Copyright_Operate_Live_OPC_P {
		left: 688px;
		top: 433px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#FM_24_PCVisit_Us_ {
		left: 196px;
		top: 78px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FM_24_PCContact_Us {
		left: 489px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FM_24_PCGroup_14927 {
		position: absolute;
		width: 1275px;
		height: 737px;
		left: 91px;
		top: 0px;
		overflow: visible;
	}
	#FM_24_PCstyle_3 {
		position: absolute;
		width: 613px;
		height: 737px;
		left: 662px;
		top: 0px;
		overflow: visible;
	}
	#FM_24_PCRectangle_84 {
		fill: rgba(249,129,105,1);
	}
	.FM_24_PCRectangle_84 {
		-webkit-filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		        filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 291px;
		height: 157px;
		left: 7px;
		top: 553px;
	}
	#FM_24_PCFlowing_Solutions_for_Every_Pi {
		left: 0px;
		top: 193px;
		position: absolute;
		overflow: visible;
		width: 636px;
		height: 197px;
		line-height: 67px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: lighter;
		font-size: 50px;
		color: rgba(0,0,0,1);
	}
	#FM_24_PCExplore_Now_ {
		left: 50px;
		top: 579px;
		position: absolute;
		overflow: visible;
		width: 119px;
		white-space: nowrap;
		line-height: 71px;
		margin-top: -26.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#FM_24_PCScroll_Group_42 {
		mix-blend-mode: normal;
		position: absolute;
		width: 616px;
		height: 127px;
		left: 0px;
		top: 408px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FM_24_PCintro_part {
		opacity: 0.71;
		left: 8px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 601px;
		height: 113px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#FM_24_PCzane-lee-bXbi1jJVDf0-unsplash_ {
		position: absolute;
		width: 75px;
		height: 75px;
		left: 7px;
		top: 41px;
        border-radius: 50%;
		overflow: hidden;
	}
	#FM_24_PCOperate_Live {
		left: 108px;
		top: 59px;
		position: absolute;
		overflow: visible;
		width: 168px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#FM_24_PCOur_Offerings {
		left: 586px;
		top: 2732px;
		position: absolute;
		overflow: visible;
		width: 195px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#FM_24_PCScroll_Group_67 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 561px;
  		top: 2872px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#FM_24_PCScroll_Group_67::-webkit-scrollbar{
		display: none;
	}
	#FM_24_PCGroup_15053 {
		position: relative;
		width: 371px;
		height: 492px;
		left: 58px;
		top: 43px;
        margin-right: 30px;
		overflow: visible;
	}
	#FM_24_PClucas-van-oort-Cm_APC_oD-w-uns {
		position: absolute;
		width: 371px;
		height: 301px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FM_24_PCGroup_25 {
		position: absolute;
		width: 371px;
		height: 191px;
		left: 0px;
		top: 301px;
		overflow: visible;
	}
	#FM_24_PCRectangle_72 {
		fill: rgba(241,241,241,1);
	}
	.FM_24_PCRectangle_72 {
		position: absolute;
		overflow: visible;
		width: 371px;
		height: 191px;
		left: 0px;
		top: 0px;
	}
	#FM_24_PCRectangle_73 {
		fill: rgba(207,207,207,1);
	}
	.FM_24_PCRectangle_73 {
		position: absolute;
		overflow: visible;
		width: 371px;
		height: 3px;
		left: 0px;
		top: 188px;
	}
	#FM_24_PCPlumbing {
		left: 4px;
		top: 16px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 27px;
		line-height: 36px;
		margin-top: -8px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(26,26,26,1);
	}
	#FM_24_PCScroll_Group_68 {
		mix-blend-mode: normal;
		position: absolute;
		width: 358px;
		height: 121px;
		left: 4px;
		top: 57px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FM_24_PCLorem_ipsum_dolor_sit_amet_con {
		left: 10px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 342px;
		height: 103px;
		line-height: 24px;
		margin-top: -3px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(72,72,72,1);
	}
	#FM_24_PCGroup_15073 {
		position: absolute;
		width: 1165px;
		height: 566px;
		left: 91px;
		top: 866px;
		overflow: visible;
	}
	#FM_24_PCScroll_Group_51 {
		mix-blend-mode: normal;
		position: absolute;
		width: 698px;
		height: 269px;
		left: 467px;
		top: 203px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FM_24_PCScroll_Group_51::-webkit-scrollbar{
		display: none;
	}
	#FM_24_PCElevate_Your_Space_with_Timele {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 677px;
		height: 249px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FM_24_PCVision {
		left: 478px;
		top: 136px;
		position: absolute;
		overflow: visible;
		width: 88px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#FM_24_PCej-strat-VjWi56AWQ9k-unsplash {
		-webkit-filter: drop-shadow(0px 47px 99px rgba(197, 201, 203, 1));
		        filter: drop-shadow(0px 47px 99px rgba(197, 201, 203, 1));
		position: absolute;
		width: 407px;
		height: 566px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FM_24_PCGroup_15074 {
		position: absolute;
		width: 1053px;
		height: 566px;
		left: 203px;
		top: 1448px;
		overflow: visible;
	}
	#FM_24_PCScroll_Group_79 {
		mix-blend-mode: normal;
		position: absolute;
		width: 644px;
		height: 269px;
		left: 0px;
		top: 207px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FM_24_PCScroll_Group_79::-webkit-scrollbar{
		display: none;
	}
	#FM_24_PCElevate_Your_Space_with_Timele_cg {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 634px;
		height: 249px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FM_24_PCMission {
		left: 11px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 110px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#FM_24_PCsamuel-sianipar-scUBcasSvbE-un {
		-webkit-filter: drop-shadow(0px 47px 99px rgba(197, 201, 203, 1));
		        filter: drop-shadow(0px 47px 99px rgba(197, 201, 203, 1));
		position: absolute;
		width: 407px;
		height: 566px;
		left: 646px;
		top: 0px;
		overflow: visible;
	}
	#FM_24_PCGroup_15075 {
		position: absolute;
		width: 1165px;
		height: 566px;
		left: 91px;
		top: 2053px;
		overflow: visible;
	}
	#FM_24_PCScroll_Group_80 {
		mix-blend-mode: normal;
		position: absolute;
		width: 698px;
		height: 269px;
		left: 467px;
		top: 146px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FM_24_PCScroll_Group_80::-webkit-scrollbar{
		display: none;
	}
	#FM_24_PCElevate_Your_Space_with_Timele_cl {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 677px;
		height: 249px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FM_24_PCPrinciples {
		left: 478px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 138px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#FM_24_PCkyle-glenn-Qo4gei6NvcQ-unsplas {
		-webkit-filter: drop-shadow(0px 47px 99px rgba(197, 201, 203, 1));
		        filter: drop-shadow(0px 47px 99px rgba(197, 201, 203, 1));
		position: absolute;
		width: 407px;
		height: 566px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	.mediaViewInfo {
		--web-view-name: CaptureSphere;
		--web-view-id: CaptureSphere;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: CaptureSphere;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#CaptureSphere {
		position: absolute;
		width: 1366px;
		height: 3603px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: CaptureSphere;
		--web-view-id: CaptureSphere;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#cApTure_25_PCGallery {
		position: absolute;
		width: 1366px;
		height: 1193.229px;
		left: 0px;
		top: 1917.5px;
		overflow: visible;
	}
	#cApTure_25_PCRectangle_489 {
		fill: rgba(247,249,251,1);
	}
	.cApTure_25_PCRectangle_489 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 1193.229px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCMask_Group_106 {
		position: absolute;
		width: 392px;
		height: 347px;
		left: 494px;
		top: 188.631px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_108 {
		position: absolute;
		width: 392px;
		height: 232px;
		left: 494px;
		top: 583.379px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_111 {
		position: absolute;
		width: 300px;
		height: 429.727px;
		left: 136px;
		top: 511.375px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_112 {
		position: absolute;
		width: 300px;
		height: 432px;
		left: 944px;
		top: 187.529px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_115 {
		position: absolute;
		width: 300px;
		height: 156px;
		left: 944px;
		top: 667.238px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_116 {
		position: absolute;
		width: 300px;
		height: 278px;
		left: 136px;
		top: 189.461px;
		overflow: visible;
	}
	#cApTure_25_PCGroup_60 {
		position: absolute;
		width: 317px;
		height: 77px;
		left: 524.5px;
		top: 1029.229px;
		overflow: visible;
	}
	#cApTure_25_PCRectangle_196 {
		fill: rgba(0,0,0,1);
	}
	.cApTure_25_PCRectangle_196 {
		-webkit-filter: drop-shadow(0px 15px 20px rgba(0, 0, 0, 0.078));
		        filter: drop-shadow(0px 15px 20px rgba(0, 0, 0, 0.078));
		position: absolute;
		overflow: visible;
		width: 377px;
		height: 137px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCLoad_more_Images {
		left: 45px;
    top: 28px;
		position: absolute;
		overflow: visible;
		width: 169px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -3px;
		text-align: left;
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.15px;
	}
	#cApTure_25_PCGroup_13 {
		position: absolute;
		width: 20px;
		height: 13.098px;
		left: 263.049px;
		top: 31.951px;
		overflow: visible;
	}
	#cApTure_25_PCPath_13 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.cApTure_25_PCPath_13 {
		overflow: visible;
		position: absolute;
		width: 13.098px;
		height: 6.549px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,10.1763,3.2742) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,10.1763,3.2742) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCLine_14 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.cApTure_25_PCLine_14 {
		overflow: visible;
		position: absolute;
		width: 19.451px;
		height: 1px;
		left: 0px;
		top: 6.549px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PCEvents_we_Cover {
		left: 473px;
		top: 54.064px;
		position: absolute;
		overflow: visible;
		width: 422px;
		white-space: nowrap;
		text-align: left;
		font-family: Baskerville;
		font-style: italic;
		font-weight: normal;
		font-size: 60px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCIntro {
		position: absolute;
		width: 1366px;
		height: 710px;
		left: 0px;
		top: 6px;
		overflow: visible;
	}
	#cApTure_25_PCRectangle_375 {
		fill: rgba(255,255,255,1);
		stroke: rgba(255,255,255,1);
		stroke-width: 10px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.cApTure_25_PCRectangle_375 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 710px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCMask_Group_83 {
		position: absolute;
		width: 155.642px;
		height: 266.135px;
		left: 1107.855px;
		top: 208.92px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_84 {
		position: absolute;
		width: 401.58px;
		height: 204.947px;
		left: 861.917px;
		top: 484.559px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_85 {
		position: absolute;
		width: 154.454px;
		height: 158.018px;
		left: 943.896px;
		top: 132.107px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_86 {
		position: absolute;
		width: 154.454px;
		height: 169.432px;
		left: 534px;
		top: 520.076px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_87 {
		position: absolute;
		width: 236.433px;
		height: 175.611px;
		left: 861.917px;
		top: 299.443px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_88 {
		position: absolute;
		width: 154.454px;
		height: 286.928px;
		left: 697.958px;
		top: 402.58px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_89 {
		position: absolute;
		width: 155.642px;
		height: 138.414px;
		left: 1107.855px;
		top: 61px;
		overflow: visible;
	}
	#cApTure_25_PCWe_are_Experienced {
		left: 329px;
		top: 237px;
		position: absolute;
		overflow: visible;
		width: 279px;
		white-space: nowrap;
		text-align: left;
		font-family: Museo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
		letter-spacing: 0.35px;
	}
	#cApTure_25_PCPhotographers {
		left: 169px;
		top: 269px;
		position: absolute;
		overflow: visible;
		width: 425px;
		white-space: nowrap;
		line-height: 100px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baskerville;
		font-style: normal;
		font-weight: normal;
		font-size: 65px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCSubtraction_8 {
		fill: rgba(0,0,0,1);
	}
	.cApTure_25_PCSubtraction_8 {
        border-radius: 50%;
		overflow: hidden;
		position: absolute;
		width: 30px;
		height: 30.01px;
		left: 125px;
		top: 45px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PCGroup_37 {
		position: absolute;
		width: 5px;
		height: 64.95px;
		left: 166px;
		top: 585px;
		overflow: visible;
	}
	#cApTure_25_PCRectangle_337 {
		fill: rgba(234,234,234,1);
	}
	.cApTure_25_PCRectangle_337 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 64.951px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCRectangle_338 {
		fill: rgba(0,0,0,1);
	}
	.cApTure_25_PCRectangle_338 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 18.318px;
		left: 0px;
		top: 13px;
	}
	#cApTure_25_PCOperate_Live {
		left: 170px;
		top: 47px;
		position: absolute;
		overflow: visible;
		width: 120px;
		white-space: nowrap;
		text-align: left;
		font-family: Museo;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
		letter-spacing: 0.35px;
	}
	#cApTure_25_PCWe_Are_Exp {
		position: absolute;
		width: 1440.5px;
		height: 1000.896px;
		left: -37.25px;
		top: 816px;
		overflow: visible;
	}
	#cApTure_25_PCRectangle_391 {
		fill: rgba(247,249,251,1);
	}
	.cApTure_25_PCRectangle_391 {
		position: absolute;
		overflow: visible;
		width: 1440px;
		height: 1000.896px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCLine_51 {
		opacity: 0.5;
		fill: transparent;
		stroke: rgba(210,221,232,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.cApTure_25_PCLine_51 {
		overflow: visible;
		position: absolute;
		width: 1440px;
		height: 1px;
		left: 0.5px;
		top: 0.5px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PCLorem_Ipsum_is_simply_dummy_te {
		opacity: 0.8;
		left: 633px;
		top: 66px;
		position: absolute;
		overflow: visible;
		width: 641px;
		height: 285px;
		line-height: 24px;
		margin-top: -3px;
		text-align: left;
		font-family: Aktiv Grotesk;
		font-style: normal;
		font-weight: lighter;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCGroup_57 {
		position: absolute;
		width: 431px;
		height: 217px;
		left: 144px;
		top: 42px;
		overflow: visible;
	}
	#cApTure_25_PCWe_are__Professional {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 427px;
		white-space: nowrap;
		text-align: left;
		font-family: Baskerville;
		font-style: italic;
		font-weight: normal;
		font-size: 60px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCphotographers {
		left: 169px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 263px;
		white-space: nowrap;
		text-align: left;
		font-family: Baskerville;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCoffering_card_1_shadow_space_w {
		position: absolute;
		width: 281.482px;
		height: 397.746px;
		left: 130.509px;
		top: 446px;
		overflow: visible;
	}
	#cApTure_25_PCcard_bg_offeringshadow_space_w {
		fill: rgba(255,255,255,1);
	}
	.cApTure_25_PCcard_bg_offeringshadow_space_w {
		-webkit-filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		        filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 296.982px;
		height: 413.246px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PChalf_circle_shadow_space_websi {
		fill: rgba(46,46,46,1);
	}
	.cApTure_25_PChalf_circle_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 22px;
		height: 42px;
		left: 23px;
		top: 18px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PCcircle_deign_shadow_space_webs {
		fill: rgba(46,46,46,1);
	}
	.cApTure_25_PCcircle_deign_shadow_space_webs {
		position: absolute;
		overflow: visible;
		width: 8.567px;
		height: 8.566px;
		left: 9.791px;
		top: 35.492px;
	}
	#cApTure_25_PCoofering_lbl_med_shadow_space_ {
		left: 55px;
		top: 28px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCScroll_Group_82 {
		mix-blend-mode: normal;
		position: absolute;
		width: 270px;
		height: 316px;
		left: 6px;
		top: 75px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#cApTure_25_PCScroll_Group_82::-webkit-scrollbar{
		display: none;
	}
	#cApTure_25_PCintro_lbl_14_web_pc {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 316px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#cApTure_25_PCoffering_card_1_shadow_space_w_ce {
		position: absolute;
		width: 281.482px;
		height: 397.746px;
		left: 579.509px;
		top: 446px;
		overflow: visible;
	}
	#cApTure_25_PCcard_bg_offeringshadow_space_w_cf {
		fill: rgba(255,255,255,1);
	}
	.cApTure_25_PCcard_bg_offeringshadow_space_w_cf {
		-webkit-filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		        filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 296.982px;
		height: 413.246px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PChalf_circle_shadow_space_websi_cg {
		fill: rgba(46,46,46,1);
	}
	.cApTure_25_PChalf_circle_shadow_space_websi_cg {
		overflow: visible;
		position: absolute;
		width: 22px;
		height: 42px;
		left: 23px;
		top: 18px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PCcircle_deign_shadow_space_webs_ch {
		fill: rgba(46,46,46,1);
	}
	.cApTure_25_PCcircle_deign_shadow_space_webs_ch {
		position: absolute;
		overflow: visible;
		width: 8.567px;
		height: 8.566px;
		left: 9.791px;
		top: 35.492px;
	}
	#cApTure_25_PCoofering_lbl_med_shadow_space__ci {
		left: 55px;
		top: 28px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCScroll_Group_83 {
		mix-blend-mode: normal;
		position: absolute;
		width: 270px;
		height: 316px;
		left: 5px;
		top: 75px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#cApTure_25_PCScroll_Group_83::-webkit-scrollbar{
		display: none;
	}
	#cApTure_25_PCintro_lbl_14_web_pc_ck {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 316px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#cApTure_25_PCoffering_card_1_shadow_space_w_cl {
		position: absolute;
		width: 281.482px;
		height: 397.746px;
		left: 1028.509px;
		top: 446px;
		overflow: visible;
	}
	#cApTure_25_PCcard_bg_offeringshadow_space_w_cm {
		fill: rgba(255,255,255,1);
	}
	.cApTure_25_PCcard_bg_offeringshadow_space_w_cm {
		-webkit-filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		        filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 296.982px;
		height: 413.246px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PChalf_circle_shadow_space_websi_cn {
		fill: rgba(46,46,46,1);
	}
	.cApTure_25_PChalf_circle_shadow_space_websi_cn {
		overflow: visible;
		position: absolute;
		width: 22px;
		height: 42px;
		left: 23px;
		top: 18px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PCcircle_deign_shadow_space_webs_co {
		fill: rgba(46,46,46,1);
	}
	.cApTure_25_PCcircle_deign_shadow_space_webs_co {
		position: absolute;
		overflow: visible;
		width: 8.567px;
		height: 8.566px;
		left: 9.791px;
		top: 35.492px;
	}
	#cApTure_25_PCoofering_lbl_med_shadow_space__cp {
		left: 55px;
		top: 28px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCScroll_Group_84 {
		mix-blend-mode: normal;
		position: absolute;
		width: 270px;
		height: 316px;
		left: 6px;
		top: 75px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#cApTure_25_PCScroll_Group_84::-webkit-scrollbar{
		display: none;
	}
	
	#cApTure_25_PCintro_lbl_14_web_pc_cr {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 316px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#cApTure_25_PCGroup_15076 {
		position: absolute;
		width: 1366px;
		height: 392px;
		left: 0px;
		top: 3211px;
		overflow: visible;
	}
	#cApTure_25_PCRectangle_747 {
		fill: rgba(255,255,255,1);
		stroke: rgba(255,255,255,1);
		stroke-width: 10px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.cApTure_25_PCRectangle_747 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 392px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCGroup_15081 {
		position: absolute;
		width: 509px;
		height: 364px;
		left: 59px;
		top: 28px;
		overflow: visible;
	}
	#cApTure_25_PCkisspng-paper-area-rectangle-s {
		position: absolute;
		width: 509px;
		height: 364px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#cApTure_25_PCRectangle_748 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.cApTure_25_PCRectangle_748 {
		position: absolute;
		overflow: visible;
		width: 410px;
		height: 272px;
		left: 50px;
		top: 42px;
	}
	#cApTure_25_PCsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 683px;
		top: 253px;
		overflow: visible;
	}
	#cApTure_25_PClinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#cApTure_25_PCmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.cApTure_25_PCmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PClinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#cApTure_25_PCn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.cApTure_25_PCn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PClinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.cApTure_25_PClinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PCtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.cApTure_25_PCtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PCInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#cApTure_25_PCshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.cApTure_25_PCshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#cApTure_25_PCwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#cApTure_25_PCmed_shadow_space_website_7_c {
		fill: rgba(255,255,255,1);
	}
	.cApTure_25_PCmed_shadow_space_website_7_c {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#cApTure_25_PCn_234567890_mobile_number_shad {
		left: 718px;
		top: 22px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCaffanghanioperatelive_email_sh {
		left: 723px;
		top: 75px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCaddress_med_shadow_space_websi {
		left: 718px;
		top: 125px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PClocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 683px;
		top: 127px;
		overflow: visible;
	}
	#cApTure_25_PCemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 683px;
		top: 74px;
		overflow: visible;
	}
	#cApTure_25_PCphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 683px;
		top: 21px;
		overflow: visible;
	}
	#cApTure_25_PCn_Copyright_Operate_Live_OPC_P {
		left: 683px;
		top: 349px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCVisit_Us_ {
		left: 271px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -3px;
		text-align: left;
		font-family: Baskerville;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
		letter-spacing: 0.15px;
	}
	.mediaViewInfo {
		--web-view-name: PlantPlateDelights;
		--web-view-id: PlantPlateDelights;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: PlantPlateDelights;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#PlantPlateDelights {
		position: absolute;
		width: 1366px;
		height: 3284px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: PlantPlateDelights;
		--web-view-id: PlantPlateDelights;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#PPD_26_PCGroup_15086 {
		position: absolute;
		width: 1335px;
		height: 826px;
		left: 31px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCMask_Group_1 {
		position: absolute;
		width: 901px;
		height: 826px;
		left: 434px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCRectangle_3 {
		fill: rgba(103,123,200,1);
	}
	.PPD_26_PCRectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 4px;
		top: 549px;
	}
	#PPD_26_PCOrder_Now {
		left: 49px;
		top: 562px;
		position: absolute;
		overflow: visible;
		width: 81px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PCHave_no_time_to_prepare_food {
		left: 0px;
		top: 256px;
		position: absolute;
		overflow: visible;
		width: 402px;
		white-space: nowrap;
		line-height: 70px;
		margin-top: -8px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 54px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCChoose_one_of_our_plans_enter_ {
		opacity: 0.5;
		left: 4px;
		top: 430px;
		position: absolute;
		overflow: visible;
		width: 443px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCFOODCOURT {
		left: 4px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 203px;
		white-space: nowrap;
		line-height: 51px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Semplicità;
		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCGroup_15087 {
		position: absolute;
		width: 1362.349px;
		height: 620.792px;
		left: 0px;
		top: 926px;
		overflow: visible;
	}
	#PPD_26_PCRectangle_7 {
		fill: rgba(255,255,255,1);
	}
	.PPD_26_PCRectangle_7 {
		position: absolute;
		overflow: visible;
		width: 750.61px;
		height: 576.32px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCMask_Group_2 {
		position: absolute;
		width: 808.531px;
		height: 620.791px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCGroup_4 {
		position: absolute;
		width: 185px;
		height: 51px;
		left: 915px;
		top: 523px;
		overflow: visible;
	}
	#PPD_26_PCRectangle_6 {
		fill: rgba(103,123,200,1);
	}
	.PPD_26_PCRectangle_6 {
		position: absolute;
		overflow: visible;
		width: 185px;
		height: 51px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCOrder_Now_bh {
		left: 46px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PCGroup_12 {
		position: absolute;
		width: 281px;
		height: 91.728px;
		left: 917px;
		top: 45px;
		overflow: visible;
	}
	#PPD_26_PCLine_2 {
		fill: transparent;
		stroke: rgba(103,123,200,1);
		stroke-width: 7px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCLine_2 {
		overflow: visible;
		position: absolute;
		width: 207.907px;
		height: 7px;
		left: 4.221px;
		top: 91.729px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCOUR_FOOD {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 282px;
		white-space: nowrap;
		line-height: 73px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 56px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCChoose_one_of_our_plans_enter__bl {
		opacity: 0.5;
		left: 990.349px;
		top: 321.4px;
		position: absolute;
		overflow: visible;
		width: 373px;
		white-space: nowrap;
		line-height: 25px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCChoose_one_of_our_plans_enter__bm {
		opacity: 0.5;
		left: 990.349px;
		top: 236.525px;
		position: absolute;
		overflow: visible;
		width: 317px;
		white-space: nowrap;
		line-height: 25px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCChoose_one_of_our_plans_enter__bn {
		opacity: 0.5;
		left: 990.349px;
		top: 447.709px;
		position: absolute;
		overflow: visible;
		width: 317px;
		white-space: nowrap;
		line-height: 25px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCGroup_9 {
		position: absolute;
		width: 197.171px;
		height: 46.113px;
		left: 916.836px;
		top: 189.746px;
		overflow: visible;
	}
	#PPD_26_PCAppetizers {
		left: 70.171px;
		top: 5.346px;
		position: absolute;
		overflow: visible;
		width: 128px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCGroup_6 {
		position: absolute;
		width: 46.113px;
		height: 46.113px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCEllipse_2 {
		fill: rgba(103,123,200,1);
	}
	.PPD_26_PCEllipse_2 {
		position: absolute;
		overflow: visible;
		width: 46.113px;
		height: 46.113px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCGroup_5 {
		position: absolute;
		width: 17.238px;
		height: 17.238px;
		left: 14.368px;
		top: 14.367px;
		overflow: visible;
	}
	#PPD_26_PCLine_3 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCLine_3 {
		overflow: visible;
		position: absolute;
		width: 21.238px;
		height: 4px;
		left: 0px;
		top: 8.619px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCLine_4 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCLine_4 {
		overflow: visible;
		position: absolute;
		width: 17.238px;
		height: 1px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,0,8.6191) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,0,8.6191) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCGroup_10 {
		position: absolute;
		width: 232.513px;
		height: 46.113px;
		left: 916.836px;
		top: 274.619px;
		overflow: visible;
	}
	#PPD_26_PCMain_Courses {
		left: 73.513px;
		top: 5.348px;
		position: absolute;
		overflow: visible;
		width: 160px;
		white-space: nowrap;
		line-height: 58px;
		margin-top: -16.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCGroup_7 {
		position: absolute;
		width: 46.113px;
		height: 46.113px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCEllipse_2_by {
		fill: rgba(103,123,200,1);
	}
	.PPD_26_PCEllipse_2_by {
		position: absolute;
		overflow: visible;
		width: 46.113px;
		height: 46.113px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCGroup_5_bz {
		position: absolute;
		width: 17.238px;
		height: 17.238px;
		left: 14.368px;
		top: 14.369px;
		overflow: visible;
	}
	#PPD_26_PCLine_3_b {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCLine_3_b {
		overflow: visible;
		position: absolute;
		width: 21.238px;
		height: 4px;
		left: 0px;
		top: 8.619px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCLine_4_b {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCLine_4_b {
		overflow: visible;
		position: absolute;
		width: 17.238px;
		height: 1px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,0,8.6191) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,0,8.6191) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCGroup_11 {
		position: absolute;
		width: 175.513px;
		height: 46.113px;
		left: 916.836px;
		top: 386.893px;
		overflow: visible;
	}
	#PPD_26_PCDesserts {
		left: 73.513px;
		top: 5.348px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCGroup_8 {
		position: absolute;
		width: 46.113px;
		height: 46.113px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCEllipse_2_b {
		fill: rgba(103,123,200,1);
	}
	.PPD_26_PCEllipse_2_b {
		position: absolute;
		overflow: visible;
		width: 46.113px;
		height: 46.113px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCGroup_5_b {
		position: absolute;
		width: 17.238px;
		height: 17.238px;
		left: 14.368px;
		top: 14.369px;
		overflow: visible;
	}
	#PPD_26_PCLine_3_ca {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCLine_3_ca {
		overflow: visible;
		position: absolute;
		width: 21.238px;
		height: 4px;
		left: 0px;
		top: 8.619px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCLine_4_ca {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCLine_4_ca {
		overflow: visible;
		position: absolute;
		width: 17.238px;
		height: 1px;
		-webkit-transform: translate(0px, 0px) matrix(1,0,0,1,0,8.6191) rotate(90deg);
		        transform: translate(0px, 0px) matrix(1,0,0,1,0,8.6191) rotate(90deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCGroup_15088 {
		position: absolute;
		width: 1366px;
		height: 708.171px;
		left: 0px;
		top: 1697px;
		overflow: visible;
	}
	#PPD_26_PCPath_7778 {
		fill: rgba(122,147,243,1);
	}
	.PPD_26_PCPath_7778 {
		overflow: visible;
		position: absolute;
		width: 1361.056px;
		height: 600.746px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCMask_Group_7 {
		opacity: 0.1;
		position: absolute;
		width: 1361.056px;
		height: 600.746px;
		left: 4.944px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCGroup_13 {
		position: absolute;
		width: 149px;
		height: 80.509px;
		left: 606px;
		top: 83.002px;
		overflow: visible;
	}
	#PPD_26_PCLine_2_cd {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 7px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCLine_2_cd {
		overflow: visible;
		position: absolute;
		width: 100.939px;
		height: 7px;
		left: 27.205px;
		top: 80.508px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCPLANS {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 150px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 50px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCScroll_Group_87 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1296.253px;
		height: 491.913px;
		left: 32.92px;
		top: 216.258px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#PPD_26_PCGroup_142 {
		position: absolute;
		width: 555.54px;
		height: 431.494px;
		left: 673.713px;
		top: 27.418px;
		overflow: visible;
	}
	#PPD_26_PCRectangle_9 {
		fill: rgba(255,255,255,1);
	}
	.PPD_26_PCRectangle_9 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 573.54px;
		height: 449.494px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCMask_Group_4 {
		position: absolute;
		width: 555.54px;
		height: 431.494px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCRectangle_12 {
		opacity: 0.2;
		fill: rgba(36,36,36,1);
	}
	.PPD_26_PCRectangle_12 {
		position: absolute;
		overflow: visible;
		width: 555.54px;
		height: 431.494px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCOrder_1 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		left: 40.234px;
		top: 27.346px;
		position: absolute;
		overflow: visible;
		width: 106px;
		white-space: nowrap;
		line-height: 52px;
		margin-top: -11px;
		text-align: left;
		font-family: Hobo Std;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PCGroup_143 {
		position: absolute;
		width: 556.08px;
		height: 431.913px;
		left: 67px;
		top: 27px;
		overflow: visible;
	}
	#PPD_26_PCRectangle_8 {
		fill: rgba(255,255,255,1);
	}
	.PPD_26_PCRectangle_8 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 574.08px;
		height: 449.912px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCMask_Group_3 {
		position: absolute;
		width: 556.08px;
		height: 431.912px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCvictoria-shes-4MEL9XS-3JQ-unsp {
		opacity: 0.2;
		fill: rgba(0,0,0,1);
	}
	.PPD_26_PCvictoria-shes-4MEL9XS-3JQ-unsp {
		position: absolute;
		overflow: visible;
		width: 556.08px;
		height: 431.912px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCOrder_1_cp {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		left: 24.04px;
		top: 30.346px;
		position: absolute;
		overflow: visible;
		width: 106px;
		white-space: nowrap;
		line-height: 52px;
		margin-top: -11px;
		text-align: left;
		font-family: Hobo Std;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PCGroup_15091 {
		position: absolute;
		width: 1366px;
		height: 625.824px;
		left: 0px;
		top: 2658.176px;
		overflow: visible;
	}
	#PPD_26_PCText_Background {
		fill: rgba(255,255,255,1);
	}
	.PPD_26_PCText_Background {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 1384px;
		height: 353.641px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCGroup_15089 {
		position: absolute;
		width: 1366px;
		height: 287px;
		left: 0px;
		top: 338.824px;
		overflow: visible;
	}
	#PPD_26_PCsonny-mauricio-yhc4pSbl01A-uns {
		position: absolute;
		width: 1366px;
		height: 287px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCsonny-mauricio-yhc4pSbl01A-uns_cu {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCsonny-mauricio-yhc4pSbl01A-uns_cu {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 287px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCBorder {
		fill: rgba(204,204,204,1);
	}
	.PPD_26_PCBorder {
		overflow: visible;
		position: absolute;
		width: 1366px;
		height: 0.91px;
		left: 0px;
		top: 243.092px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCFollow_Us {
		left: 1022.626px;
		top: 71.43px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		line-height: 25px;
		margin-top: -2.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PCn_Copyright_Operate_Live_OPC_P {
		left: 71px;
		top: 254px;
		position: absolute;
		overflow: visible;
		width: 512px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PCn_234567890_mobile_number_shad {
		left: 155px;
		top: 30px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PCaffanghanioperatelive_email_sh {
		left: 160px;
		top: 84px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PCaddress_med_shadow_space_websi {
		left: 155px;
		top: 134px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PClocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 120px;
		top: 136px;
		overflow: visible;
	}
	#PPD_26_PCemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 120px;
		top: 83px;
		overflow: visible;
	}
	#PPD_26_PCphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 120px;
		top: 29px;
		overflow: visible;
	}
	#PPD_26_PCsocials_grp_shadow_space_websi {
		position: absolute;
		width: 189px;
		height: 48px;
		left: 974px;
		top: 120px;
		overflow: visible;
	}
	#PPD_26_PClinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 141px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PPD_26_PCmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PClinked_in_shadow_space_website {
		position: absolute;
		width: 23.525px;
		height: 21.386px;
		left: 12.674px;
		top: 13.119px;
		overflow: visible;
	}
	#PPD_26_PCn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.PPD_26_PCn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.547px;
		height: 14.668px;
		left: 7.978px;
		top: 6.719px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PClinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.PPD_26_PClinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.585px;
		height: 14.529px;
		left: 0.593px;
		top: 6.857px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.PPD_26_PCtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.566px;
		height: 5.041px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCInsta_grp_shadow_space_website {
		position: absolute;
		width: 49px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PPD_26_PCshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 58px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCinstagramshadow_space_website_ {
		position: absolute;
		width: 23.55px;
		height: 23.551px;
		left: 13.07px;
		top: 12.516px;
		overflow: visible;
	}
	#PPD_26_PCwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCmed_shadow_space_website_7_df {
		fill: rgba(255,255,255,1);
	}
	.PPD_26_PCmed_shadow_space_website_7_df {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 24.154px;
		height: 24.154px;
		left: 11.863px;
		top: 11.912px;
		overflow: visible;
	}
	#PPD_26_PCVISIT_US_ {
		left: 570px;
		top: 2571.002px;
		position: absolute;
		overflow: visible;
		width: 228px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 50px;
		color: rgba(36,36,36,1);
	}
	.mediaViewInfo {
		--web-view-name: ElectroTech Solutions;
		--web-view-id: ElectroTech_Solutions;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ElectroTech_Solutions;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ElectroTech_Solutions {
		position: absolute;
		width: 1366px;
		height: 3938px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: ElectroTech Solutions;
		--web-view-id: ElectroTech_Solutions;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#ETS_27_PCGroup_15103 {
		position: absolute;
		width: 1418.769px;
		height: 602.967px;
		left: -52.769px;
		top: -14.967px;
		overflow: visible;
	}
	#ETS_27_PCcopy-space-colorful-sewing-thr {
		position: absolute;
		width: 469.61px;
		height: 294.371px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCPowering_Your_World__Expert_El {
		left: 167.769px;
		top: 293.967px;
		position: absolute;
		overflow: visible;
		width: 625px;
		white-space: nowrap;
		line-height: 70px;
		margin-top: -8px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 54px;
		color: rgba(36,36,36,1);
	}
	#ETS_27_PCJoin_the_Spark_of_Excellence_I {
		opacity: 0.5;
		left: 167.769px;
		top: 528.967px;
		position: absolute;
		overflow: visible;
		width: 541px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(36,36,36,1);
	}
	#ETS_27_PCGroup_15102 {
		position: absolute;
		width: 588px;
		height: 588px;
		left: 830.769px;
		top: 14.967px;
		overflow: visible;
	}
	#ETS_27_PCRectangle_747 {
		fill: rgba(237,149,75,1);
	}
	.ETS_27_PCRectangle_747 {
		position: absolute;
		overflow: visible;
		width: 374px;
		height: 374px;
		left: 214px;
		top: 0px;
	}
	#ETS_27_PCelectrical-shielded-cable-with {
		position: absolute;
		width: 507px;
		height: 507px;
		left: 0px;
		top: 81px;
		overflow: visible;
	}
	#ETS_27_PCGroup_15111 {
		position: absolute;
		width: 393px;
		height: 70px;
		left: 167.769px;
		top: 102.967px;
		overflow: visible;
	}
	#ETS_27_PCAnime_Edit {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 0px;
		top: 0px;
        border-radius: 50%;
		overflow: hidden;
	}
	#ETS_27_PCElectician {
		left: 80px;
		top: 22px;
		position: absolute;
		overflow: visible;
		width: 314px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_PCGroup_15039 {
		position: absolute;
		width: 1193px;
		height: 1695px;
		left: 87px;
		top: 795px;
		overflow: visible;
	}
	#ETS_27_PCGroup_15033 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCmika-baumeister-dp9AxaKHdVg-un {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		        filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCGroup_15038 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 600px;
		top: 38px;
		overflow: visible;
	}
	#ETS_27_PCScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#ETS_27_PCScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#ETS_27_PCIt_is_a_long_established_fact_ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#ETS_27_PCVISION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_PCGroup_15034 {
		position: absolute;
		width: 1193px;
		height: 419.078px;
		left: 0px;
		top: 638px;
		overflow: visible;
	}
	#ETS_27_PCpatrick-tomasso-1NTFSnV-KLs-un {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		        filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 560px;
		height: 419.078px;
		left: 633px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCGroup_15037 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 0px;
		top: 39px;
		overflow: visible;
	}
	#ETS_27_PCScroll_Group_28_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#ETS_27_PCScroll_Group_28_::-webkit-scrollbar{
		display: none;
	}
	#ETS_27_PCIt_is_a_long_established_fact__ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#ETS_27_PCMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_PCGroup_15035 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 1276px;
		overflow: visible;
	}
	#ETS_27_PCframes-for-your-heart-iOLHAIax {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		        filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCScroll_Group_28_bc {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 600px;
		top: 103px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#ETS_27_PCScroll_Group_28_bc::-webkit-scrollbar{
		display: none;
	}
	
	#ETS_27_PCIt_is_a_long_established_fact__bd {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#ETS_27_PCPRINCIPLES {
		left: 618px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_PCGroup_15107 {
		position: absolute;
		width: 1366px;
		height: 564.998px;
		left: 0px;
		top: 2640.002px;
		overflow: visible;
	}
	#ETS_27_PCOur_Services {
		left: 546px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 275px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 50px;
		color: rgba(36,36,36,1);
	}
	#ETS_27_PCsteve-johnson-hokONTrHIAQ-unsp {
		position: absolute;
		width: 1366px;
		height: 387px;
		left: 0px;
		top: 177.998px;
		overflow: visible;
	}
	#ETS_27_PCScroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1341px;
		height: 387px;
		left: 13px;
		top: 177.998px;
		overflow-x: scroll;
		overflow-y: hidden;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
	}
	#ETS_27_PCScroll_Group_77::-webkit-scrollbar{
		display: none;
	}
	#ETS_27_PCn_ {
		position: relative;
        margin-right: 30px;
		width: 307px;
		height: 342px;
		left: 25px;
		top: 21px;
		overflow: visible;
	}
	#ETS_27_PCMask_Group_22 {
		position: absolute;
		width: 307px;
		height: 296px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCRectangle_664 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCRectangle_664 {
		position: absolute;
		overflow: visible;
		width: 307px;
		height: 46px;
		left: 0px;
		top: 296px;
	}
	#ETS_27_PCSomething {
		left: 9px;
		top: 309px;
		position: absolute;
		overflow: visible;
		width: 293px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
		text-transform: uppercase;
	}
	#ETS_27_PCGroup_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 131px;
		top: 268px;
		overflow: visible;
	}
	#ETS_27_PCEllipse_38 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCEllipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#ETS_27_PCEllipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCEllipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#ETS_27_PCEllipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCEllipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#ETS_27_PCEllipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCEllipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#ETS_27_PCEllipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCEllipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#ETS_27_PCGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 509.998px;
		left: 96px;
		top: 3413.002px;
		overflow: visible;
	}
	#ETS_27_PCGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 136.998px;
		overflow: visible;
	}
	#ETS_27_PCGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#ETS_27_PCsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255px;
		overflow: visible;
	}
	#ETS_27_PClinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#ETS_27_PClinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#ETS_27_PCn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.ETS_27_PCn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#ETS_27_PClinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.ETS_27_PClinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#ETS_27_PCtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.ETS_27_PCtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#ETS_27_PCInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#ETS_27_PCinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#ETS_27_PCwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCmed_shadow_space_website_7_b {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCmed_shadow_space_website_7_b {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#ETS_27_PCwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#ETS_27_PCn_234567890_mobile_number_shad {
		left: 703px;
		top: 24px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_PCaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_PCaddress_med_shadow_space_websi {
		left: 703px;
		top: 127px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_PClocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129px;
		overflow: visible;
	}
	#ETS_27_PCemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76px;
		overflow: visible;
	}
	#ETS_27_PCphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23px;
		overflow: visible;
	}
	#ETS_27_PCn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_PCContact_Us {
		left: 450px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 255px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 50px;
		color: rgba(36,36,36,1);
	}

	.mediaViewInfo {
		--web-view-name: VerdantHarvestGrove;
		--web-view-id: VerdantHarvestGrove;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: VerdantHarvestGrove;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#VerdantHarvestGrove {
		position: absolute;
		width: 1366px;
		height: 3640px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: VerdantHarvestGrove;
		--web-view-id: VerdantHarvestGrove;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#VHG_PC_28Group_15101 {
		position: absolute;
		width: 1175px;
		height: 487.998px;
		left: 96px;
		top: 3133.002px;
		overflow: visible;
	}
	#VHG_PC_28Group_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 114.997px;
		overflow: visible;
	}
	#VHG_PC_28Group_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28Rectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28Rectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#VHG_PC_28socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#VHG_PC_28linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28med_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#VHG_PC_28linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#VHG_PC_28n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.VHG_PC_28n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#VHG_PC_28linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.VHG_PC_28linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#VHG_PC_28top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.VHG_PC_28top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#VHG_PC_28Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#VHG_PC_28instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#VHG_PC_28whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28med_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28med_shadow_space_website_7_ {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#VHG_PC_28whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#VHG_PC_28n_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_PC_28affanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_PC_28address_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_PC_28location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#VHG_PC_28email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#VHG_PC_28phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#VHG_PC_28n_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#VHG_PC_28Contact_Us {
		left: 510.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#VHG_PC_28Group_15117 {
		position: absolute;
		width: 1366px;
		height: 898px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28mk-s-tHHFiw6GNEU-unsplash {
		position: absolute;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28Rectangle_748 {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
	}
	.VHG_PC_28Rectangle_748 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#VHG_PC_28amjd-rdwan-CEAPSFlMLbk-unsplas {
		position: absolute;
		width: 500px;
		height: 500px;
		left: 433px;
		top: 398px;
		overflow: visible;
	}
	#VHG_PC_28Anime_Edit {
		position: absolute;
		width: 80px;
		height: 80px;
		left: 61px;
		top: 45px;
        border-radius: 50%;
		overflow: hidden;
	}
	#VHG_PC_28Your_Business_Name {
		left: 151px;
		top: 71px;
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#VHG_PC_28Cultivating_Wellness_from_Farm {
		left: 61px;
		top: 190px;
		position: absolute;
		overflow: visible;
		width: 807px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(255,255,255,1);
	}
	#VHG_PC_28Group_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 61px;
		top: 452px;
		overflow: visible;
	}
	#VHG_PC_28Rectangle_3 {
		fill: rgba(233,153,15,1);
	}
	.VHG_PC_28Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#VHG_PC_28Available_Now {
		left: 37px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#VHG_PC_28Group_15118 {
		position: absolute;
		width: 1055px;
		height: 1400px;
		left: 134px;
		top: 998px;
		overflow: visible;
	}
	#VHG_PC_28Group_15114 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28tandem-x-visuals-dqZ0JREudKw-u {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 462px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#VHG_PC_28Scroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#VHG_PC_28It_is_a_long_established_fact_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#VHG_PC_28VISION {
		left: 462px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_PC_28Group_15115 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 500px;
		overflow: visible;
	}
	#VHG_PC_28mk-s-7GeBVqFwOl4-unsplash {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 655px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28Scroll_Group_28_by {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 0px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#VHG_PC_28Scroll_Group_28_by::-webkit-scrollbar{
		display: none;
	}
	#VHG_PC_28It_is_a_long_established_fact__bz {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#VHG_PC_28MISSION {
		left: 0px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_PC_28Group_15116 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 1000px;
		overflow: visible;
	}
	#VHG_PC_28ricardo-gomez-angel-I5XthC605H {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28Scroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 462px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#VHG_PC_28Scroll_Group_28_b::-webkit-scrollbar{
		display: none;
	}
	#VHG_PC_28It_is_a_long_established_fact__b {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#VHG_PC_28PRINCIPLES {
		left: 462px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_PC_28Group_15119 {
		position: absolute;
		width: 1341px;
		height: 463px;
		left: 13px;
		top: 2498px;
		overflow: visible;
	}
	#VHG_PC_28Scroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1341px;
		height: 386px;
		left: 0px;
		top: 77px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#VHG_PC_28Scroll_Group_77::-webkit-scrollbar{
		display: none;
	}
	#VHG_PC_28n_ {
		position: relative;
		width: 307px;
		height: 342px;
		left: 25px;
		top: 21px;
        margin-right: 30px;
		overflow: visible;
	}
	#VHG_PC_28Mask_Group_22 {
		position: absolute;
		width: 307px;
		height: 296px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28Rectangle_664 {
		fill: rgba(0,0,0,1);
	}
	.VHG_PC_28Rectangle_664 {
		position: absolute;
		overflow: visible;
		width: 307px;
		height: 46px;
		left: 0px;
		top: 296px;
	}
	#VHG_PC_28Something {
		left: 9px;
		top: 309px;
		position: absolute;
		overflow: visible;
		width: 293px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}
	#VHG_PC_28Group_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 131px;
		top: 268px;
		overflow: visible;
	}
	#VHG_PC_28Ellipse_38 {
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28Ellipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#VHG_PC_28Ellipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28Ellipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#VHG_PC_28Ellipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28Ellipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#VHG_PC_28Ellipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28Ellipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#VHG_PC_28Ellipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28Ellipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#VHG_PC_28Available_Now_ci {
		left: 603px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 136px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}

	.mediaViewInfo {
		--web-view-name: FruitFiesta;
		--web-view-id: FruitFiesta;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: FruitFiesta;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#FruitFiesta {
		position: absolute;
		width: 1366px;
		height: 3512px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: FruitFiesta;
		--web-view-id: FruitFiesta;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#FF_PC_29Group_15101 {
		position: absolute;
		width: 1175px;
		height: 481.998px;
		left: 96px;
		top: 3010.002px;
		overflow: visible;
	}
	#FF_PC_29Group_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 108.997px;
		overflow: visible;
	}
	#FF_PC_29Group_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29Rectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29Rectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#FF_PC_29socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#FF_PC_29linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29med_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FF_PC_29linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#FF_PC_29n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.FF_PC_29n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FF_PC_29linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.FF_PC_29linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FF_PC_29top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.FF_PC_29top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FF_PC_29Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FF_PC_29instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#FF_PC_29whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29med_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29med_shadow_space_website_7_ {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FF_PC_29whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#FF_PC_29n_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FF_PC_29affanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FF_PC_29address_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FF_PC_29location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#FF_PC_29email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#FF_PC_29phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#FF_PC_29n_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#FF_PC_29Contact_Us {
		left: 523.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 128px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -20px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(36,36,36,1);
	}
	#FF_PC_29Group_15117 {
		position: absolute;
		width: 1399px;
		height: 789px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29julia-zolotova-M_xIaxQE3Ms-uns {
		position: absolute;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29Rectangle_748 {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
	}
	.FF_PC_29Rectangle_748 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#FF_PC_29mae-mu-vbAEHCrvXZ0-unsplash {
		position: absolute;
		width: 514px;
		height: 514px;
		left: 885px;
		top: 275px;
		overflow: visible;
	}
	#FF_PC_29Anime_Edit {
		position: absolute;
		width: 80px;
		height: 80px;
		left: 61px;
		top: 45px;
        border-radius: 50%;
		overflow: hidden;
	}
	#FF_PC_29Your_Business_Name {
		left: 151px;
		top: 71px;
		position: absolute;
		overflow: visible;
		width: 184px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#FF_PC_29Exploring_Orchards_of_Flavor__ {
		left: 61px;
		top: 169px;
		position: absolute;
		overflow: visible;
		width: 865px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(255,255,255,1);
	}
	#FF_PC_29Group_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 61px;
		top: 452px;
		overflow: visible;
	}
	#FF_PC_29Rectangle_3 {
		fill: rgba(233,153,15,1);
	}
	.FF_PC_29Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#FF_PC_29Order_Now {
		left: 45px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 81px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#FF_PC_29Group_15118 {
		position: absolute;
		width: 1055px;
		height: 1400px;
		left: 156px;
		top: 819px;
		overflow: visible;
	}
	#FF_PC_29Group_15114 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29sneha-cecil-Qt6ojt3CacE-unspla {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 462px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FF_PC_29Scroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#FF_PC_29It_is_a_long_established_fact_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FF_PC_29VISION {
		left: 462px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FF_PC_29Group_15115 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 500px;
		overflow: visible;
	}
	#FF_PC_29tom-brunberg-D4ZtZX1UeAI-unspl {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 655px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29Scroll_Group_28_by {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 0px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FF_PC_29Scroll_Group_28_by::-webkit-scrollbar{
		display: none;
	}
	#FF_PC_29It_is_a_long_established_fact__bz {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FF_PC_29MISSION {
		left: 0px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FF_PC_29Group_15116 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 1000px;
		overflow: visible;
	}
	#FF_PC_29brenda-godinez-_Zn_7FzoL1w-uns {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29Scroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 462px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FF_PC_29Scroll_Group_28_b::-webkit-scrollbar{
		display: none;
	}
	#FF_PC_29It_is_a_long_established_fact__b {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FF_PC_29PRINCIPLES {
		left: 462px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FF_PC_29Group_15119 {
		position: absolute;
		width: 1341px;
		height: 463px;
		left: 13px;
		top: 2369px;
		overflow: visible;
	}
	#FF_PC_29Scroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1341px;
		height: 386px;
		left: 0px;
		top: 77px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#FF_PC_29Scroll_Group_77::-webkit-scrollbar{
		display: none;
	}
	#FF_PC_29n_ {
		position: relative;
		width: 307px;
		height: 342px;
		left: 25px;
        margin-right: 30px;
		top: 21px;
		overflow: visible;
	}
	#FF_PC_29Mask_Group_22 {
		position: absolute;
		width: 307px;
		height: 311px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29Rectangle_664 {
		fill: rgba(0,0,0,1);
	}
	.FF_PC_29Rectangle_664 {
		position: absolute;
		overflow: visible;
		width: 307px;
		height: 46px;
		left: 0px;
		top: 296px;
	}
	#FF_PC_29Something {
		left: 9px;
		top: 309px;
		position: absolute;
		overflow: visible;
		width: 293px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}
	#FF_PC_29Group_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 131px;
		top: 268px;
		overflow: visible;
	}
	#FF_PC_29Ellipse_38 {
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29Ellipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#FF_PC_29Ellipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29Ellipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#FF_PC_29Ellipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29Ellipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#FF_PC_29Ellipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29Ellipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#FF_PC_29Ellipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29Ellipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#FF_PC_29In_Stock {
		left: 632px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 77px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}

	.mediaViewInfo {
		--web-view-name: FreshSipHub;
		--web-view-id: FreshSipHub;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: FreshSipHub;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#FreshSipHub {
		position: absolute;
		width: 1366px;
		height: 3277px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: FreshSipHub;
		--web-view-id: FreshSipHub;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#FSH_PC_30Group_15101 {
		position: absolute;
		width: 1175px;
		height: 487.998px;
		left: 95.5px;
		top: 2768.904px;
		overflow: visible;
	}
	#FSH_PC_30Group_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 114.997px;
		overflow: visible;
	}
	#FSH_PC_30Group_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30Rectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.FSH_PC_30Rectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#FSH_PC_30socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#FSH_PC_30linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FSH_PC_30med_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FSH_PC_30linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#FSH_PC_30n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.FSH_PC_30n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FSH_PC_30linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.FSH_PC_30linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FSH_PC_30top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.FSH_PC_30top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#FSH_PC_30Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FSH_PC_30shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FSH_PC_30instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#FSH_PC_30whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30med_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.FSH_PC_30med_shadow_space_website_7_ {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FSH_PC_30whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#FSH_PC_30n_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_PC_30affanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_PC_30address_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_PC_30location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#FSH_PC_30email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#FSH_PC_30phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#FSH_PC_30n_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#FSH_PC_30Contact_Us {
		left: 510.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#FSH_PC_30Ellipse_44 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FSH_PC_30Ellipse_44 {
		position: absolute;
		overflow: visible;
		width: 64px;
		height: 1px;
		left: 1153px;
		top: 286px;
	}
	#FSH_PC_30Group_15129 {
		position: absolute;
		width: 1366px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30Group_15124 {
		position: absolute;
		width: 1366px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30jugoslocos-i8JfQDc4Ha8-unsplas {
		position: absolute;
		width: 1366px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30Rectangle_749 {
		opacity: 0.24;
		fill: rgba(0,0,0,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FSH_PC_30Rectangle_749 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 684px;
		left: 0px;
		top: 0px;
	}
	#FSH_PC_30Anime_Edit {
		position: absolute;
		width: 80px;
		height: 80px;
		left: 59px;
		top: 88px;
        border-radius: 50%;
		overflow: hidden;
	}
	#FSH_PC_30Your_Business_Name {
		left: 149px;
		top: 114px;
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#FSH_PC_30Revitalize_Your_Life_with_the_ {
		left: 59px;
		top: 257px;
		position: absolute;
		overflow: visible;
		width: 1095px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(255,255,255,1);
	}
	#FSH_PC_30Group_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 61px;
		top: 452px;
		overflow: visible;
	}
	#FSH_PC_30Rectangle_3 {
		fill: rgba(233,153,15,1);
	}
	.FSH_PC_30Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#FSH_PC_30Available_Now {
		left: 37px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#FSH_PC_30Group_15135 {
		position: absolute;
		width: 1078px;
		height: 1370.174px;
		left: 144px;
		top: 770.922px;
		overflow: visible;
	}
	#FSH_PC_30Group_15126 {
		position: absolute;
		width: 1078px;
		height: 423.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30tangerine-newt-hINQgaTqg7Q-uns {
		position: absolute;
		width: 423.096px;
		height: 423.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 485px;
		top: 149.078px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FSH_PC_30It_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FSH_PC_30VISION {
		left: 487px;
		top: 84.078px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_PC_30Group_15125 {
		position: absolute;
		width: 1078px;
		height: 423.096px;
		left: 0px;
		top: 474px;
		overflow: visible;
	}
	#FSH_PC_30whitney-wright-TgQkxQc-t_U-uns {
		position: absolute;
		width: 423.096px;
		height: 423.096px;
		left: 654.904px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30Scroll_Group_88 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 0px;
		top: 149.078px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FSH_PC_30It_is_a_long_established_fact__b {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FSH_PC_30MISSION {
		left: 5px;
		top: 84.078px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_PC_30Group_15127 {
		position: absolute;
		width: 1078px;
		height: 423.096px;
		left: 0px;
		top: 947.078px;
		overflow: visible;
	}
	#FSH_PC_30alina-karpenko-jH1PBHevj38-uns {
		position: absolute;
		width: 423.096px;
		height: 423.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30Scroll_Group_89 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 485px;
		top: 149.078px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FSH_PC_30It_is_a_long_established_fact__ca {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FSH_PC_30PRINCIPLES {
		left: 490px;
		top: 84.078px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_PC_30Our_Greatest_Drinks {
		left: 590px;
		top: 2241px;
		position: absolute;
		overflow: visible;
		width: 187px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_PC_30Scroll_Group_90 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1286px;
		height: 380px;
		left: 40px;
		top: 2289px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#FSH_PC_30Group_15128 {
		position: relative;
        margin-right: 30px;
		width: 253px;
		height: 317px;
		left: 50px;
		top: 29px;
		overflow: visible;
	}
	#FSH_PC_30Rectangle_750_cd {
		fill: url(#FSH_PC_30Rectangle_750_cd);
	}
	.FSH_PC_30Rectangle_750_cd {
		-webkit-filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 258px;
		height: 322px;
		left: 0px;
		top: 0px;
	}
	#FSH_PC_30sam-hojati-lw8GflbJwLc-unsplas {
		position: absolute;
		width: 231px;
		height: 262px;
		left: 11px;
		top: 11px;
		overflow: visible;
	}
	#FSH_PC_30Juice_1 {
		left: 11px;
		top: 281px;
		position: absolute;
		overflow: visible;
		width: 232px;
		height: 27px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}

	.mediaViewInfo {
		--web-view-name: ShoeStride;
		--web-view-id: ShoeStride;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ShoeStride;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ShoeStride {
		position: absolute;
		width: 1366px;
		height: 3478px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: ShoeStride;
		--web-view-id: ShoeStride;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#SS_31_PCGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 472.998px;
		left: 95.5px;
		top: 2998px;
		overflow: visible;
	}
	#SS_31_PCGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 99.997px;
		overflow: visible;
	}
	#SS_31_PCGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_PCRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.SS_31_PCRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#SS_31_PCsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#SS_31_PClinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_PCmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.SS_31_PCmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PClinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#SS_31_PCn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.SS_31_PCn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SS_31_PClinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.SS_31_PClinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SS_31_PCtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.SS_31_PCtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SS_31_PCInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_PCshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.SS_31_PCshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PCinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#SS_31_PCwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_PCmed_shadow_space_website_7_bf {
		fill: rgba(255,255,255,1);
	}
	.SS_31_PCmed_shadow_space_website_7_bf {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PCwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#SS_31_PCn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SS_31_PCaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SS_31_PCaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SS_31_PClocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#SS_31_PCemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#SS_31_PCphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#SS_31_PCn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#SS_31_PCContact_Us {
		left: 510.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#SS_31_PCGroup_15140 {
		position: absolute;
		width: 1366px;
		height: 690px;
		left: 0px;
		top: 37px;
		overflow: visible;
	}
	#SS_31_PCEllipse_44 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SS_31_PCEllipse_44 {
		position: absolute;
		overflow: visible;
		width: 64px;
		height: 1px;
		left: 1153px;
		top: 249px;
	}
	#SS_31_PCYour_Name {
		left: 66px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 235px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SS_31_PCDISCOVER_LIMITED_SHOES__WITHOU {
		left: 69px;
		top: 87px;
		position: absolute;
		overflow: visible;
		width: 1244px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 90px;
		color: rgba(0,0,0,1);
	}
	#SS_31_PCthe-dk-photography-b9e8ffVw5XY {
		position: absolute;
		width: 341px;
		height: 342px;
		left: 342px;
		top: 348px;
		overflow: visible;
	}
	#SS_31_PCthibaut-burckel-n2V5MLDPE-k-un {
		position: absolute;
		width: 342px;
		height: 342px;
		left: 0px;
		top: 348px;
		overflow: visible;
	}
	#SS_31_PCthe-dk-photography-A8HaTLON5Gg {
		position: absolute;
		width: 342px;
		height: 342px;
		left: 683px;
		top: 348px;
		overflow: visible;
	}
	#SS_31_PCkyle-austin-vHIv0AreyDk-unspla {
		position: absolute;
		width: 341px;
		height: 342px;
		left: 1025px;
		top: 348px;
		overflow: visible;
	}
	#SS_31_PCGroup_15139 {
		position: absolute;
		width: 1366px;
		height: 157px;
		left: 0px;
		top: 767px;
		overflow: visible;
	}
	#SS_31_PCRectangle_752 {
		fill: rgba(5,5,5,1);
	}
	.SS_31_PCRectangle_752 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 157px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PCLorem_Ipsum_is_simply_dummy_te {
		left: 79px;
		top: 22px;
		position: absolute;
		overflow: visible;
		width: 1210px;
		height: 114px;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SS_31_PCGroup_15151 {
		position: absolute;
		width: 915px;
		height: 1248px;
		left: 226px;
		top: 1044px;
		overflow: visible;
	}
	#SS_31_PCGroup_15141 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_PCRectangle_752_b {
		fill: rgba(5,5,5,1);
	}
	.SS_31_PCRectangle_752_b {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PCmarten-bjork-_ccRYJWcNwk-unspl {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#SS_31_PCScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SS_31_PCIt_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SS_31_PCVISION {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SS_31_PCGroup_15142 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 436px;
		overflow: visible;
	}
	#SS_31_PCRectangle_752_ca {
		fill: rgba(5,5,5,1);
	}
	.SS_31_PCRectangle_752_ca {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PCxavier-teo-SxAXphIPWeg-unsplas {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 609px;
		top: 33px;
		overflow: visible;
	}
	#SS_31_PCScroll_Group_28_ca {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 107px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SS_31_PCScroll_Group_28_ca::-webkit-scrollbar{
		display: none;
	}
	#SS_31_PCIt_is_a_long_established_fact__cb {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SS_31_PCMISSION {
		left: 109px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SS_31_PCGroup_15143 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 872px;
		overflow: visible;
	}
	#SS_31_PCRectangle_752_ce {
		fill: rgba(5,5,5,1);
	}
	.SS_31_PCRectangle_752_ce {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PChanne-van-oeckel-4R2tRB7R5uQ-u {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#SS_31_PCScroll_Group_28_cg {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SS_31_PCScroll_Group_28_cg::-webkit-scrollbar{
		display: none;
	}
	#SS_31_PCScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#SS_31_PCIt_is_a_long_established_fact__ch {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SS_31_PCPRINCIPLES {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SS_31_PCGroup_15150 {
		position: absolute;
		width: 1286px;
		height: 457px;
		left: 40px;
		top: 2441px;
		overflow: visible;
	}
	#SS_31_PCNEW_EDITION {
		left: 65px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 244px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(0,0,0,1);
	}
	#SS_31_PCGroup_15145 {
		position: absolute;
		width: 185px;
		height: 61px;
		left: 1009px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_PCRectangle_753 {
		fill: rgba(5,5,5,1);
	}
	.SS_31_PCRectangle_753 {
		position: absolute;
		overflow: visible;
		width: 185px;
		height: 61px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PCView_More {
		left: 46px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SS_31_PCScroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1286px;
		height: 396px;
		left: 0px;
		top: 61px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#SS_31_PCScroll_Group_91::-webkit-scrollbar{
		display: none;
	}
	#SS_31_PCGroup_15144 {
		position: relative;
        margin-right: 30px;
		width: 243px;
		height: 331px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#SS_31_PCRectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.SS_31_PCRectangle_754 {
		-webkit-filter: drop-shadow(5px 5px 1px rgba(235, 99, 99, 0.518));
		        filter: drop-shadow(5px 5px 1px rgba(235, 99, 99, 0.518));
		position: absolute;
		overflow: visible;
		width: 249.5px;
		height: 337.5px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PCthibaut-burckel-n2V5MLDPE-k-un_cr {
		position: absolute;
		width: 239px;
		height: 255px;
		left: 2px;
		top: 38px;
		overflow: visible;
	}
	#SS_31_PCTitle {
		left: 7px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SS_31_PCn_10000 {
		left: 7px;
		top: 299px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}

	.mediaViewInfo {
		--web-view-name: SoleCrafted;
		--web-view-id: SoleCrafted;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: SoleCrafted;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#SoleCrafted {
		position: absolute;
		width: 1366px;
		height: 3478px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: SoleCrafted;
		--web-view-id: SoleCrafted;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#SC_32_PcGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 472.998px;
		left: 95.5px;
		top: 2998px;
		overflow: visible;
	}
	#SC_32_PcGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 99.997px;
		overflow: visible;
	}
	#SC_32_PcGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SC_32_PcRectangle_737 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SC_32_PcRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#SC_32_Pcsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#SC_32_Pclinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#SC_32_Pcmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.SC_32_Pcmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SC_32_Pclinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#SC_32_Pcn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.SC_32_Pcn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SC_32_Pclinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.SC_32_Pclinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SC_32_Pctop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.SC_32_Pctop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#SC_32_PcInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#SC_32_Pcshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.SC_32_Pcshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SC_32_Pcinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#SC_32_Pcwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SC_32_Pcmed_shadow_space_website_7_bf {
		fill: rgba(255,255,255,1);
	}
	.SC_32_Pcmed_shadow_space_website_7_bf {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SC_32_Pcwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#SC_32_Pcn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SC_32_Pcaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SC_32_Pcaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SC_32_Pclocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#SC_32_Pcemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#SC_32_Pcphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#SC_32_Pcn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#SC_32_PcContact_Us {
		left: 510.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#SC_32_PcGroup_15140 {
		position: absolute;
		width: 1336px;
		height: 684px;
		left: 30px;
		top: 0px;
		overflow: visible;
	}
	#SC_32_PcEllipse_44 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SC_32_PcEllipse_44 {
		position: absolute;
		overflow: visible;
		width: 64px;
		height: 1px;
		left: 1123px;
		top: 286px;
	}
	#SC_32_PcYour_Name {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 235px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SC_32_PcDiscover_Timeless__Craftsmansh {
		left: 0px;
		top: 180px;
		position: absolute;
		overflow: visible;
		width: 613px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		color: rgba(0,0,0,1);
	}
	#SC_32_Pcnoah-smith-R4xOiCIeOUs-unsplas {
		position: absolute;
		width: 341px;
		height: 342px;
		left: 995px;
		top: 0px;
		overflow: visible;
	}
	#SC_32_Pcalexandr-sadkov-BnG4KWAzt9c-un {
		position: absolute;
		width: 342px;
		height: 342px;
		left: 653px;
		top: 0px;
		overflow: visible;
	}
	#SC_32_Pclea-ochel-nsRBbE6-YLs-unsplash {
		position: absolute;
		width: 342px;
		height: 342px;
		left: 653px;
		top: 342px;
		overflow: visible;
	}
	#SC_32_Pcrajesh-rajput-NqZ2vFqChaw-unsp {
		position: absolute;
		width: 341px;
		height: 342px;
		left: 995px;
		top: 342px;
		overflow: visible;
	}
	#SC_32_PcGroup_15141 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 226px;
		top: 834px;
		overflow: visible;
	}
	#SC_32_PcRectangle_752 {
		fill: rgba(161,141,87,1);
	}
	.SC_32_PcRectangle_752 {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#SC_32_Pcty-feague-87V27nw0sS0-unsplash {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#SC_32_PcScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SC_32_PcScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#SC_32_PcIt_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SC_32_PcVISION {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SC_32_PcGroup_15142 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 226px;
		top: 1270px;
		overflow: visible;
	}
	#SC_32_PcRectangle_752_b {
		fill: rgba(161,141,87,1);
	}
	.SC_32_PcRectangle_752_b {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#SC_32_Pcprateek-chawla-87luO7iL1mM-uns {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 609px;
		top: 33px;
		overflow: visible;
	}
	#SC_32_PcScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 107px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SC_32_PcScroll_Group_28_b::-webkit-scrollbar{
		display: none;
	}
	#SC_32_PcIt_is_a_long_established_fact__b {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SC_32_PcMISSION {
		left: 109px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SC_32_PcGroup_15143 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 226px;
		top: 1706px;
		overflow: visible;
	}
	#SC_32_PcRectangle_752_ca {
		fill: rgba(161,141,87,1);
	}
	.SC_32_PcRectangle_752_ca {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#SC_32_Pcnoah-smith-1z2hBTKHdWI-unsplas {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#SC_32_PcScroll_Group_28_cc {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SC_32_PcScroll_Group_28_cc::-webkit-scrollbar{
		display: none;
	}
	#SC_32_PcIt_is_a_long_established_fact__cd {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SC_32_PcPRINCIPLES {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SC_32_PcNEW_EDITION {
		left: 105px;
		top: 2452px;
		position: absolute;
		overflow: visible;
		width: 244px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(0,0,0,1);
	}
	#SC_32_PcGroup_15145 {
		position: absolute;
		width: 185px;
		height: 61px;
		left: 1049px;
		top: 2441px;
		overflow: visible;
	}
	#SC_32_PcRectangle_753 {
		fill: rgba(161,141,87,1);
	}
	.SC_32_PcRectangle_753 {
		position: absolute;
		overflow: visible;
		width: 185px;
		height: 61px;
		left: 0px;
		top: 0px;
	}
	#SC_32_PcView_More {
		left: 46px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SC_32_PcScroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1286px;
		height: 396px;
		left: 40px;
		top: 2502px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#SC_32_PcScroll_Group_91::-webkit-scrollbar{
		display: none;
	}
	#SC_32_PcGroup_15144 {
		position: relative;
        margin-right: 30px;
		width: 243px;
		height: 331px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#SC_32_PcRectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.SC_32_PcRectangle_754 {
		-webkit-filter: drop-shadow(5px 5px 1px rgba(150, 75, 0, 0.388));
		        filter: drop-shadow(5px 5px 1px rgba(150, 75, 0, 0.388));
		position: absolute;
		overflow: visible;
		width: 249.5px;
		height: 337.5px;
		left: 0px;
		top: 0px;
	}
	#SC_32_Pcmostafa-mahmoudi-3OZr-hLbsq0-u {
		position: absolute;
		width: 239px;
		height: 255px;
		left: 2px;
		top: 38px;
		overflow: visible;
	}
	#SC_32_PcTitle {
		left: 7px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SC_32_Pcn_10000 {
		left: 7px;
		top: 299px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}

	.mediaViewInfo {
		--web-view-name: InkwellHaven;
		--web-view-id: InkwellHaven;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: InkwellHaven;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#InkwellHaven {
		position: absolute;
		width: 1366px;
		height: 3358px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: InkwellHaven;
		--web-view-id: InkwellHaven;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#IWH_PC_33Group_15156 {
		position: absolute;
		width: 1366px;
		height: 1251px;
		left: 0px;
		top: 826px;
		overflow: visible;
	}
	#IWH_PC_33n_1994588_2111w039n0037Bp17 {
		opacity: 0.2;
		position: absolute;
		width: 1366px;
		height: 1251px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 605px;
		top: 141px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#IWH_PC_33Scroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#IWH_PC_33It_is_a_long_established_fact_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#IWH_PC_33VISION {
		left: 605px;
		top: 87px;
		position: absolute;
		overflow: visible;
		width: 62px;
		white-space: nowrap;
		line-height: 37px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33Scroll_Group_28_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 129px;
		left: 153px;
		top: 566px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#IWH_PC_33Scroll_Group_28_::-webkit-scrollbar{
		display: none;
	}
	#IWH_PC_33It_is_a_long_established_fact__ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 126px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#IWH_PC_33MISSION {
		left: 153px;
		top: 512px;
		position: absolute;
		overflow: visible;
		width: 78px;
		white-space: nowrap;
		line-height: 37px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33Scroll_Group_28_ba {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 605px;
		top: 974px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#IWH_PC_33Scroll_Group_28_ba::-webkit-scrollbar{
		display: none;
	}
	#IWH_PC_33It_is_a_long_established_fact__bb {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#IWH_PC_33PRINCIPLES {
		left: 605px;
		top: 920px;
		position: absolute;
		overflow: visible;
		width: 101px;
		white-space: nowrap;
		line-height: 37px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33Group_15160 {
		position: absolute;
		width: 288.01px;
		height: 220.593px;
		left: 161.181px;
		top: 41px;
		overflow: visible;
	}
	#IWH_PC_33Path_7783 {
		fill: rgba(235,161,99,1);
	}
	.IWH_PC_33Path_7783 {
		overflow: visible;
		position: absolute;
		width: 288.01px;
		height: 66.065px;
		left: 0px;
		top: 154.527px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#IWH_PC_33kisspng-learning-education-bla {
		position: absolute;
		width: 218px;
		height: 218px;
		left: 34.819px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33Group_15161 {
		position: absolute;
		width: 326.703px;
		height: 320.616px;
		left: 792.463px;
		top: 373.828px;
		overflow: visible;
	}
	#IWH_PC_33Path_7784 {
		fill: rgba(99,235,131,1);
	}
	.IWH_PC_33Path_7784 {
		overflow: visible;
		position: absolute;
		width: 288.01px;
		height: 66.065px;
		left: 19.346px;
		top: 235.731px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#IWH_PC_33kisspng-school-royalty-free-dr {
		position: absolute;
		width: 326.703px;
		height: 320.616px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33Group_15162 {
		position: absolute;
		width: 288.01px;
		height: 263px;
		left: 161.181px;
		top: 865px;
		overflow: visible;
	}
	#IWH_PC_33Path_7785 {
		fill: rgba(99,99,235,1);
	}
	.IWH_PC_33Path_7785 {
		overflow: visible;
		position: absolute;
		width: 288.01px;
		height: 66.065px;
		left: 0px;
		top: 163.428px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#IWH_PC_33n_359207_19882 {
		position: absolute;
		width: 263px;
		height: 263px;
		left: 12.819px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33Group_15158 {
		position: absolute;
		width: 1131.5px;
		height: 466.813px;
		left: 117.25px;
		top: 2884.186px;
		overflow: visible;
	}
	#IWH_PC_33socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 624.5px;
		top: 348.813px;
		overflow: visible;
	}
	#IWH_PC_33linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.IWH_PC_33med_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#IWH_PC_33linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#IWH_PC_33n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.IWH_PC_33n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.671px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#IWH_PC_33linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.IWH_PC_33linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#IWH_PC_33top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.IWH_PC_33top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#IWH_PC_33Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.IWH_PC_33shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#IWH_PC_33instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.003px;
		overflow: visible;
	}
	#IWH_PC_33whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33med_shadow_space_website_7_by {
		fill: rgba(255,255,255,1);
	}
	.IWH_PC_33med_shadow_space_website_7_by {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#IWH_PC_33whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#IWH_PC_33n_234567890_mobile_number_shad {
		left: 659.5px;
		top: 117.813px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33affanghanioperatelive_email_sh {
		left: 664.5px;
		top: 170.813px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33address_med_shadow_space_websi {
		left: 659.5px;
		top: 220.813px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 624.5px;
		top: 222.813px;
		overflow: visible;
	}
	#IWH_PC_33email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 624.5px;
		top: 169.813px;
		overflow: visible;
	}
	#IWH_PC_33phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 624.5px;
		top: 116.813px;
		overflow: visible;
	}
	#IWH_PC_33n_Copyright_Operate_Live_OPC_P {
		left: 624.5px;
		top: 444.813px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33Contact_Us {
		left: 489.25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 163px;
		white-space: nowrap;
		text-align: left;
		font-family: Microsoft PhagsPa;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#IWH_PC_33kisspng-paper-area-rectangle-s {
		position: absolute;
		width: 500px;
		height: 357.813px;
		left: 0px;
		top: 87px;
		overflow: visible;
	}
	#IWH_PC_33Rectangle_756 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.IWH_PC_33Rectangle_756 {
		position: absolute;
		overflow: visible;
		width: 405px;
		height: 266px;
		left: 46px;
		top: 130.814px;
	}
	#IWH_PC_33Group_15159 {
		position: absolute;
		width: 1325px;
		height: 595px;
		left: 20px;
		top: 20px;
		overflow: visible;
	}
	#IWH_PC_33deepigoyal--TCygdpnO3g-unsplas {
		position: absolute;
		width: 650px;
		height: 595px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33Essential_That_Inspires_You_to {
		left: 690px;
		top: 211px;
		position: absolute;
		overflow: visible;
		width: 491px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33Crafting_Words_One_Page_at_a_T {
		left: 690px;
		top: 365px;
		position: absolute;
		overflow: visible;
		width: 636px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33Your_Name {
		left: 690px;
		top: 27px;
		position: absolute;
		overflow: visible;
		width: 378px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33Anime_Edit {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 80px;
		height: 80px;
		left: 1245px;
		top: 0px;
		overflow: hidden;
        border-radius: 50%;
	}
	#IWH_PC_33Group_15157 {
		position: absolute;
		width: 1366px;
		height: 488px;
		left: 0px;
		top: 2234px;
		overflow: visible;
	}
	#IWH_PC_33school-supplies-art-classes {
		position: absolute;
		width: 1366px;
		height: 395px;
		left: 0px;
		top: 93px;
		overflow: visible;
	}
	#IWH_PC_33Rectangle_755 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.IWH_PC_33Rectangle_755 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 395px;
		left: 0px;
		top: 93px;
	}
	#IWH_PC_33Available_Stationery {
		left: 548px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 272px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33Scroll_offering_med_web_4_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 1336px;
		height: 357px;
		left: 15px;
		top: 113px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#IWH_PC_33Scroll_offering_med_web_4_pc::-webkit-scrollbar{
		display: none;
	}
	#IWH_PC_33offering_crd_1_grp_med_web_4_p {
		position: relative;
        margin-right: 30px;
		width: 200px;
		height: 332px;
		left: 15px;
		top: 11px;
		overflow: visible;
	}
	#IWH_PC_33title_bdr_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.IWH_PC_33title_bdr_med_web_4_pc {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 190px;
		height: 50px;
		left: 14px;
		top: 300px;
	}
	#IWH_PC_33BiziBees_PVT_LTD_med_web_4_pc {
		left: 14px;
		top: 306px;
		position: absolute;
		overflow: visible;
		width: 173px;
		height: 20px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33img_border_med_web_4_pc_of {
		-webkit-filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 300px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33chuttersna_med_web_4_pc_img {
		position: absolute;
		width: 172px;
		height: 277px;
		left: 14px;
		top: 7px;
		overflow: visible;
	}

	.mediaViewInfo {
		--web-view-name: TextureFusion;
		--web-view-id: TextureFusion;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: TextureFusion;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#TextureFusion {
		position: absolute;
		width: 1366px;
		height: 3287px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: TextureFusion;
		--web-view-id: TextureFusion;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#TFP_PC_34Group_15171 {
		position: absolute;
		width: 1366px;
		height: 483.998px;
		left: 0px;
		top: 2808.002px;
		overflow: visible;
	}
	#TFP_PC_34jj-ying-7JX0-bfiuxQ-unsplash {
		position: absolute;
		width: 1366px;
		height: 425px;
		left: 0px;
		top: 58.998px;
		overflow: visible;
	}
	#TFP_PC_34Group_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 96px;
		top: 120.997px;
		overflow: visible;
	}
	#TFP_PC_34Rectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.TFP_PC_34Rectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#TFP_PC_34socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 764px;
		top: 346.998px;
		overflow: visible;
	}
	#TFP_PC_34linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#TFP_PC_34med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TFP_PC_34med_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.603px;
		overflow: visible;
	}
	#TFP_PC_34n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.TFP_PC_34n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.671px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TFP_PC_34linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.TFP_PC_34linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.809px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TFP_PC_34top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.TFP_PC_34top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TFP_PC_34Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#TFP_PC_34shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TFP_PC_34shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.003px;
		overflow: visible;
	}
	#TFP_PC_34whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TFP_PC_34med_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.TFP_PC_34med_shadow_space_website_7_ {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#TFP_PC_34n_234567890_mobile_number_shad {
		left: 799px;
		top: 115.998px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TFP_PC_34affanghanioperatelive_email_sh {
		left: 804px;
		top: 168.998px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TFP_PC_34address_med_shadow_space_websi {
		left: 799px;
		top: 218.998px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TFP_PC_34location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 764px;
		top: 220.998px;
		overflow: visible;
	}
	#TFP_PC_34email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 764px;
		top: 167.998px;
		overflow: visible;
	}
	#TFP_PC_34phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 764px;
		top: 114.998px;
		overflow: visible;
	}
	#TFP_PC_34n_Copyright_Operate_Live_OPC_P {
		left: 764px;
		top: 442.998px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#TFP_PC_34Contact_Us {
		left: 606.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#TFP_PC_34Group_15117 {
		position: absolute;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TFP_PC_34christian-holzinger-CUY_YHhCFl {
		position: absolute;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TFP_PC_34Rectangle_748 {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
	}
	.TFP_PC_34Rectangle_748 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34Anime_Edit {
		position: absolute;
		width: 80px;
		height: 80px;
		left: 61px;
		top: 45px;
		overflow: hidden;
        border-radius: 50%;
	}
	#TFP_PC_34Your_Business_Name {
		left: 151px;
		top: 71px;
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFP_PC_34Elevate_Your_Space_with_Unique {
		left: 61px;
		top: 209px;
		position: absolute;
		overflow: visible;
		width: 906px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(255,255,255,1);
	}
	#TFP_PC_34Group_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 61px;
		top: 452px;
		overflow: visible;
	}
	#TFP_PC_34Rectangle_3 {
		fill: rgba(183,177,186,1);
	}
	.TFP_PC_34Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34Available_Now {
		left: 37px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#TFP_PC_34Group_15169 {
		position: absolute;
		width: 1366px;
		height: 1388px;
		left: 0px;
		top: 763px;
		overflow: visible;
	}
	#TFP_PC_34pawel-czerwinski-eBd0dPuR-bo-u {
		opacity: 0.5;
		position: absolute;
		width: 1366px;
		height: 1388px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TFP_PC_34Group_15141 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 226px;
		top: 70px;
		overflow: visible;
	}
	#TFP_PC_34Rectangle_752 {
		fill: rgba(223,221,219,1);
	}
	.TFP_PC_34Rectangle_752 {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34n_3430759_5249503 {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#TFP_PC_34Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TFP_PC_34Scroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#TFP_PC_34It_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#TFP_PC_34VISION {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFP_PC_34Group_15142 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 226px;
		top: 506px;
		overflow: visible;
	}
	#TFP_PC_34Rectangle_752_bu {
		fill: rgba(183,177,186,1);
	}
	.TFP_PC_34Rectangle_752_bu {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34n_60450_OFC6C30 {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 609px;
		top: 33px;
		overflow: visible;
	}
	#TFP_PC_34Scroll_Group_28_bw {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 107px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TFP_PC_34Scroll_Group_28_bw::-webkit-scrollbar{
		display: none;
	}
	#TFP_PC_34It_is_a_long_established_fact__bx {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#TFP_PC_34MISSION {
		left: 109px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFP_PC_34Group_15143 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 226px;
		top: 942px;
		overflow: visible;
	}
	#TFP_PC_34Rectangle_752_b {
		fill: rgba(230,209,200,1);
	}
	.TFP_PC_34Rectangle_752_b {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34n_20566_OCCYZ60 {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#TFP_PC_34Scroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TFP_PC_34Scroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#TFP_PC_34It_is_a_long_established_fact__b {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#TFP_PC_34PRINCIPLES {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFP_PC_34Group_15170 {
		position: absolute;
		width: 1286px;
		height: 457px;
		left: 40px;
		top: 2251px;
		overflow: visible;
	}
	#TFP_PC_34FRESH_DESIGNS {
		left: 19px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 280px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(0,0,0,1);
	}
	#TFP_PC_34Group_15145 {
		position: absolute;
		width: 185px;
		height: 61px;
		left: 1009px;
		top: 0px;
		overflow: visible;
	}
	#TFP_PC_34Rectangle_753 {
		fill: rgba(183,177,186,1);
	}
	.TFP_PC_34Rectangle_753 {
		position: absolute;
		overflow: visible;
		width: 185px;
		height: 61px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34View_More {
		left: 46px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFP_PC_34Scroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1286px;
		height: 396px;
		left: 0px;
		top: 61px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#TFP_PC_34Scroll_Group_91::-webkit-scrollbar{
		display: none;
	}
	#TFP_PC_34Group_15144 {
		position: relative;
        margin-right: 30px;
		width: 243px;
		height: 331px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#TFP_PC_34Rectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.TFP_PC_34Rectangle_754 {
		-webkit-filter: drop-shadow(5px 5px 1px rgba(233, 221, 223, 1));
		        filter: drop-shadow(5px 5px 1px rgba(233, 221, 223, 1));
		position: absolute;
		overflow: visible;
		width: 249.5px;
		height: 337.5px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34luca-laurence-FseXc3OsIic-unsp {
		position: absolute;
		width: 239px;
		height: 267px;
		left: 2px;
		top: 32px;
		overflow: visible;
	}
	#TFP_PC_34Title {
		left: 7px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TFP_PC_34n_10000 {
		left: 7px;
		top: 299px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}

	.mediaViewInfo {
		--web-view-name: ColorFusion;
		--web-view-id: ColorFusion;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ColorFusion;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ColorFusion {
		position: absolute;
		width: 1366px;
		height: 3287px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: ColorFusion;
		--web-view-id: ColorFusion;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#CFP_35_PCGroup_15171 {
		position: absolute;
		width: 1366px;
		height: 483.998px;
		left: 0px;
		top: 2808.002px;
		overflow: visible;
	}
	#CFP_35_PCjj-ying-7JX0-bfiuxQ-unsplash {
		fill: rgba(255,255,255,1);
	}
	.CFP_35_PCjj-ying-7JX0-bfiuxQ-unsplash {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 425px;
		left: 0px;
		top: 58.998px;
	}
	#CFP_35_PCGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 96px;
		top: 120.997px;
		overflow: visible;
	}
	#CFP_35_PCRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.CFP_35_PCRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#CFP_35_PCsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 764px;
		top: 346.998px;
		overflow: visible;
	}
	#CFP_35_PClinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#CFP_35_PCmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CFP_35_PCmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CFP_35_PClinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.603px;
		overflow: visible;
	}
	#CFP_35_PCn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.CFP_35_PCn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.671px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CFP_35_PClinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.CFP_35_PClinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.809px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CFP_35_PCtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.CFP_35_PCtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CFP_35_PCInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#CFP_35_PCshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CFP_35_PCshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CFP_35_PCinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.003px;
		overflow: visible;
	}
	#CFP_35_PCwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CFP_35_PCmed_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.CFP_35_PCmed_shadow_space_website_7_ {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CFP_35_PCwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#CFP_35_PCn_234567890_mobile_number_shad {
		left: 799px;
		top: 115.998px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CFP_35_PCaffanghanioperatelive_email_sh {
		left: 804px;
		top: 168.998px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CFP_35_PCaddress_med_shadow_space_websi {
		left: 799px;
		top: 218.998px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CFP_35_PClocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 764px;
		top: 220.998px;
		overflow: visible;
	}
	#CFP_35_PCemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 764px;
		top: 167.998px;
		overflow: visible;
	}
	#CFP_35_PCphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 764px;
		top: 114.998px;
		overflow: visible;
	}
	#CFP_35_PCn_Copyright_Operate_Live_OPC_P {
		left: 764px;
		top: 442.998px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#CFP_35_PCContact_Us {
		left: 606.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#CFP_35_PCGroup_15117 {
		position: absolute;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CFP_35_PCtheme-photos-Cl-OpYWFFm0-unspl {
		position: absolute;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CFP_35_PCRectangle_748 {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
	}
	.CFP_35_PCRectangle_748 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#CFP_35_PCAnime_Edit {
		position: absolute;
		width: 80px;
		height: 80px;
		left: 61px;
		top: 45px;
		overflow: hidden;
        border-radius: 50%;
	}
	#CFP_35_PCYour_Business_Name {
		left: 151px;
		top: 71px;
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#CFP_35_PCDiscover_a_World_of_Possibilit {
		left: 61px;
		top: 209px;
		position: absolute;
		overflow: visible;
		width: 986px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(255,255,255,1);
	}
	#CFP_35_PCGroup_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 61px;
		top: 452px;
		overflow: visible;
	}
	#CFP_35_PCRectangle_3 {
		fill: rgba(123,149,162,1);
	}
	.CFP_35_PCRectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#CFP_35_PCAvailable_Now {
		left: 37px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#CFP_35_PCGroup_15169 {
		position: absolute;
		width: 1366px;
		height: 1388px;
		left: 0px;
		top: 763px;
		overflow: visible;
	}
	#CFP_35_PCdavid-pisnoy-46juD4zY1XA-unspl {
		opacity: 0.5;
		position: absolute;
		width: 1366px;
		height: 1388px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CFP_35_PCGroup_15141 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 226px;
		top: 70px;
		overflow: visible;
	}
	#CFP_35_PCRectangle_752 {
		fill: rgba(252,141,133,1);
	}
	.CFP_35_PCRectangle_752 {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#CFP_35_PCyoann-siloine-LiDVw7tZPfY-unsp {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#CFP_35_PCScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CFP_35_PCIt_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#CFP_35_PCVISION {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#CFP_35_PCGroup_15142 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 226px;
		top: 506px;
		overflow: visible;
	}
	#CFP_35_PCRectangle_752_bu {
		fill: rgba(137,196,224,1);
	}
	.CFP_35_PCRectangle_752_bu {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#CFP_35_PCtaelynn-christopher-pfSNx3Z12K {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 609px;
		top: 33px;
		overflow: visible;
	}
	#CFP_35_PCScroll_Group_28_bw {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 107px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CFP_35_PCIt_is_a_long_established_fact__bx {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#CFP_35_PCMISSION {
		left: 109px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#CFP_35_PCGroup_15143 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 226px;
		top: 942px;
		overflow: visible;
	}
	#CFP_35_PCRectangle_752_b {
		fill: rgba(215,234,152,1);
	}
	.CFP_35_PCRectangle_752_b {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#CFP_35_PCdavid-pisnoy-46juD4zY1XA-unspl_b {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#CFP_35_PCScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CFP_35_PCIt_is_a_long_established_fact__b {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#CFP_35_PCPRINCIPLES {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#CFP_35_PCGroup_15170 {
		position: absolute;
		width: 1286px;
		height: 410px;
		left: 40px;
		top: 2251px;
		overflow: visible;
	}
	#CFP_35_PCOur_Colours {
		left: 19px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 190px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(0,0,0,1);
	}
	#CFP_35_PCGroup_15145 {
		position: absolute;
		width: 185px;
		height: 61px;
		left: 1009px;
		top: 0px;
		overflow: visible;
	}
	#CFP_35_PCRectangle_753 {
		fill: rgba(183,177,186,1);
	}
	.CFP_35_PCRectangle_753 {
		position: absolute;
		overflow: visible;
		width: 185px;
		height: 61px;
		left: 0px;
		top: 0px;
	}
	#CFP_35_PCView_More {
		left: 46px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#CFP_35_PCScroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1286px;
		height: 349px;
		left: 0px;
		top: 61px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#CFP_35_PCGroup_15144 {
		position: relative;
        margin-right: 30px;
		width: 213px;
		height: 299px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#CFP_35_PCRectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.CFP_35_PCRectangle_754 {
		-webkit-filter: drop-shadow(5px 5px 1px rgba(233, 221, 223, 1));
		        filter: drop-shadow(5px 5px 1px rgba(233, 221, 223, 1));
		position: absolute;
		overflow: visible;
		width: 219.5px;
		height: 305.5px;
		left: 0px;
		top: 0px;
	}
	#CFP_35_PCanna-kolosyuk-D5nh6mCW52c-unsp {
		position: absolute;
		width: 209px;
		height: 267px;
		left: 2px;
		top: 32px;
		overflow: visible;
	}
	#CFP_35_PCTitle {
		left: 7px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 201px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	.mediaViewInfo {
		--web-view-name: PureFabrica;
		--web-view-id: PureFabrica;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: PureFabrica;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#PureFabrica {
		position: absolute;
		width: 1366px;
		height: 3674px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: PureFabrica;
		--web-view-id: PureFabrica;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#PFP_36_PCGroup_15171 {
		position: absolute;
		width: 1366px;
		height: 483.998px;
		left: 0px;
		top: 3190.002px;
		overflow: visible;
	}
	#PFP_36_PCjj-ying-7JX0-bfiuxQ-unsplash {
		fill: rgba(255,255,255,1);
	}
	.PFP_36_PCjj-ying-7JX0-bfiuxQ-unsplash {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 425px;
		left: 0px;
		top: 58.998px;
	}
	#PFP_36_PCGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 96px;
		top: 120.997px;
		overflow: visible;
	}
	#PFP_36_PCRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.PFP_36_PCRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#PFP_36_PCsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 764px;
		top: 346.998px;
		overflow: visible;
	}
	#PFP_36_PClinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_PCmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PFP_36_PCmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_PClinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.603px;
		overflow: visible;
	}
	#PFP_36_PCn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.PFP_36_PCn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.671px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PFP_36_PClinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.PFP_36_PClinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PFP_36_PCtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.PFP_36_PCtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PFP_36_PCInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_PCshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PFP_36_PCshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_PCinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.003px;
		overflow: visible;
	}
	#PFP_36_PCwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_PCmed_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.PFP_36_PCmed_shadow_space_website_7_ {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_PCwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#PFP_36_PCn_234567890_mobile_number_shad {
		left: 799px;
		top: 115.998px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_PCaffanghanioperatelive_email_sh {
		left: 804px;
		top: 168.998px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_PCaddress_med_shadow_space_websi {
		left: 799px;
		top: 218.998px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_PClocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 764px;
		top: 220.998px;
		overflow: visible;
	}
	#PFP_36_PCemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 764px;
		top: 167.998px;
		overflow: visible;
	}
	#PFP_36_PCphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 764px;
		top: 114.998px;
		overflow: visible;
	}
	#PFP_36_PCn_Copyright_Operate_Live_OPC_P {
		left: 764px;
		top: 442.998px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_PCContact_Us {
		left: 606.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#PFP_36_PCGroup_15117 {
		position: absolute;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_PCplanetcare-23coWmkTNSg-unsplas {
		position: absolute;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_PCRectangle_748 {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
	}
	.PFP_36_PCRectangle_748 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_PCAnime_Edit {
		position: absolute;
		width: 80px;
		height: 80px;
		left: 61px;
		top: 45px;
        border-radius: 50%;
		overflow: hidden;
	}
	#PFP_36_PCYour_Business_Name {
		left: 151px;
		top: 71px;
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PFP_36_PCElevate_Your_Laundry_Experienc {
		left: 61px;
		top: 209px;
		position: absolute;
		overflow: visible;
		width: 812px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(255,255,255,1);
	}
	#PFP_36_PCGroup_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 61px;
		top: 452px;
		overflow: visible;
	}
	#PFP_36_PCRectangle_3 {
		fill: rgba(123,149,162,1);
	}
	.PFP_36_PCRectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_PCAvailable_Now {
		left: 37px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#PFP_36_PCGroup_15039 {
		position: absolute;
		width: 1193px;
		height: 1695px;
		left: 87px;
		top: 795px;
		overflow: visible;
	}
	#PFP_36_PCGroup_15033 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_PCengin-akyurt-yCYVV8-kQNM-unspl {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		        filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_PCGroup_15038 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 600px;
		top: 38px;
		overflow: visible;
	}
	#PFP_36_PCScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PFP_36_PCIt_is_a_long_established_fact_ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PFP_36_PCVISION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_PCGroup_15034 {
		position: absolute;
		width: 1193px;
		height: 419.078px;
		left: 0px;
		top: 638px;
		overflow: visible;
	}
	#PFP_36_PCtowfiqu-barbhuiya-998DibZwpIc- {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		        filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		position: absolute;
		width: 560px;
		height: 419.078px;
		left: 633px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_PCGroup_15037 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 0px;
		top: 39px;
		overflow: visible;
	}
	#PFP_36_PCScroll_Group_28_bx {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PFP_36_PCScroll_Group_28_bx::-webkit-scrollbar{
		display: none;
	}
	#PFP_36_PCIt_is_a_long_established_fact__by {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PFP_36_PCMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_PCGroup_15035 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 1276px;
		overflow: visible;
	}
	#PFP_36_PCraychan-vkpVPcIBU5U-unsplash {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		        filter: drop-shadow(0px 3px 20px rgba(99, 235, 184, 1));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_PCScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 600px;
		top: 103px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PFP_36_PCScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#PFP_36_PCScroll_Group_28_b::-webkit-scrollbar{
		display: none;
	}
	#PFP_36_PCIt_is_a_long_established_fact__b {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PFP_36_PCPRINCIPLES {
		left: 618px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_PCGroup_15170 {
		position: absolute;
		width: 1286px;
		height: 457px;
		left: 40px;
		top: 2707px;
		overflow: visible;
	}
	#PFP_36_PCFresh_Clothes {
		left: 18px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 220px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_PCGroup_15145 {
		position: absolute;
		width: 185px;
		height: 61px;
		left: 1009px;
		top: 0px;
		overflow: visible;
	}
	#PFP_36_PCRectangle_753 {
		fill: rgba(183,177,186,1);
	}
	.PFP_36_PCRectangle_753 {
		position: absolute;
		overflow: visible;
		width: 185px;
		height: 61px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_PCView_More {
		left: 46px;
		top: 16px;
		position: absolute;
		overflow: visible;
		width: 96px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PFP_36_PCScroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1286px;
		height: 396px;
		left: 0px;
		top: 61px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#PFP_36_PCScroll_Group_91::-webkit-scrollbar{
		display: none;
	}
	#PFP_36_PCGroup_15144 {
		position: relative;
        margin-right: 30px;
		width: 243px;
		height: 331px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#PFP_36_PCRectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.PFP_36_PCRectangle_754 {
		-webkit-filter: drop-shadow(5px 5px 1px rgba(233, 221, 223, 1));
		        filter: drop-shadow(5px 5px 1px rgba(233, 221, 223, 1));
		position: absolute;
		overflow: visible;
		width: 249.5px;
		height: 337.5px;
		left: 0px;
		top: 0px;
	}
	#PFP_36_PCannie-spratt-aJN7zURQ1Wg-unspl {
		position: absolute;
		width: 239px;
		height: 267px;
		left: 2px;
		top: 32px;
		overflow: visible;
	}
	#PFP_36_PCTitle {
		left: 7px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PFP_36_PCn_10000 {
		left: 7px;
		top: 299px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}

	.mediaViewInfo {
		--web-view-name: PerfumePulse;
		--web-view-id: PerfumePulse;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: PerfumePulse;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#PerfumePulse {
		position: absolute;
		width: 1366px;
		height: 3794px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: PerfumePulse;
		--web-view-id: PerfumePulse;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#PPText {
		position: absolute;
		width: 509px;
		height: 209px;
		left: 53px;
		top: 228px;
		overflow: visible;
	}
	#PPExperience_the_finest_in_premi {
		left: 0px;
		top: 187px;
		position: absolute;
		overflow: visible;
		width: 375px;
		white-space: nowrap;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(6,18,50,1);
	}
	#PPCompany__Name {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 510px;
		height: 134px;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: bold;
		font-size: 57px;
		color: rgba(6,18,50,1);
	}
	#PPBGs {
		position: absolute;
		width: 683px;
		height: 716px;
		left: 683px;
		top: 0px;
		overflow: visible;
	}
	#PPSee_the_Collection {
		position: absolute;
		width: 341px;
		height: 358px;
		left: 342px;
		top: 358px;
		overflow: visible;
	}
	#PPBG {
		fill: rgba(7,82,181,1);
	}
	.PPBG {
		position: absolute;
		overflow: visible;
		width: 341px;
		height: 358px;
		left: 0px;
		top: 0px;
	}
	#PPIcon_feather-arrow-right-circl {
		position: absolute;
		width: 29.271px;
		height: 29.271px;
		left: 155.224px;
		top: 236.219px;
		overflow: visible;
	}
	#PPPath_1 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPPath_1 {
		overflow: visible;
		position: absolute;
		width: 32.271px;
		height: 32.271px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPPath_2 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPPath_2 {
		overflow: visible;
		position: absolute;
		width: 9.476px;
		height: 15.951px;
		left: 14.636px;
		top: 8.781px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPPath_3 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPPath_3 {
		overflow: visible;
		position: absolute;
		width: 14.709px;
		height: 3px;
		left: 8.781px;
		top: 14.637px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPSee_the_Collection_bb {
		position: absolute;
		width: 85px;
		height: 55px;
		left: 108px;
		top: 151px;
		overflow: visible;
	}
	#PPSee_the {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 67px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PPcollection {
		left: 0px;
		top: 28px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PPmiguel-andrade-potCPE_Cw8A-uns {
		position: absolute;
		width: 342px;
		height: 358px;
		left: 0px;
		top: 358px;
		overflow: visible;
	}
	#PPjeroen-den-otter-2b0JeJTEclQ-u {
		position: absolute;
		width: 683px;
		height: 358px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPTop_Nav {
		position: absolute;
		width: 46.834px;
		height: 19.514px;
		left: 583.965px;
		top: 45.514px;
		overflow: visible;
	}
	#PPMenu_Button {
		position: absolute;
		width: 46.834px;
		height: 19.514px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPLine_1 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPLine_1 {
		overflow: visible;
		position: absolute;
		width: 23.417px;
		height: 3px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPLine_2 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPLine_2 {
		overflow: visible;
		position: absolute;
		width: 46.834px;
		height: 3px;
		left: 0px;
		top: 9.756px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPLine_3 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPLine_3 {
		overflow: visible;
		position: absolute;
		width: 23.417px;
		height: 3px;
		left: 23.417px;
		top: 19.514px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPAnime_Edit {
		position: absolute;
		width: 55px;
		height: 55px;
		left: 53px;
		top: 28px;
		overflow: hidden;
        border-radius: 50%;
	}
	#PPGroup_15039 {
		position: absolute;
		width: 1193px;
		height: 1695px;
		left: 87px;
		top: 795px;
		overflow: visible;
	}
	#PPGroup_15033 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPbundo-kim-ur2zmbseUIA-unsplash {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		        filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPGroup_15038 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 600px;
		top: 38px;
		overflow: visible;
	}
	#PPScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PPIt_is_a_long_established_fact_ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 265px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PPVISION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPGroup_15034 {
		position: absolute;
		width: 1193px;
		height: 419.078px;
		left: 0px;
		top: 638px;
		overflow: visible;
	}
	#PPhanna-balan-d1GwyeOlZDw-unspla {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		        filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 560px;
		height: 419.078px;
		left: 633px;
		top: 0px;
		overflow: visible;
	}
	#PPGroup_15037 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 0px;
		top: 39px;
		overflow: visible;
	}
	#PPScroll_Group_28_bw {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PPScroll_Group_28_bw::-webkit-scrollbar{
		display: none;
	}
	#PPIt_is_a_long_established_fact__bx {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PPMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPGroup_15035 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 1276px;
		overflow: visible;
	}
	#PPemily-wang-a5917t2ea8I-unsplas {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		        filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 600px;
		top: 103px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PPScroll_Group_28_b::-webkit-scrollbar{
		display: none;
	}
	#PPScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#PPIt_is_a_long_established_fact__b {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PPPRINCIPLES {
		left: 618px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPGroup_15119 {
		position: absolute;
		width: 1341px;
		height: 463px;
		left: 0px;
		top: 2640px;
		overflow: visible;
	}
	#PPScroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1341px;
		height: 386px;
		left: 0px;
		top: 77px;
        display: inline-flex;
		overflow-x: auto;
        overflow-y: hidden;
	}
	#PPScroll_Group_77::-webkit-scrollbar{
		display: none;
	}
	#PPn_ {
		position: relative;
		width: 307px;
		height: 342px;
		left: 15px;
		top: 21px;
		overflow: visible;
	}
	#PPMask_Group_22 {
		position: absolute;
		width: 307px;
		height: 311px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPRectangle_664 {
		fill: rgba(0,0,0,1);
	}
	.PPRectangle_664 {
		position: absolute;
		overflow: visible;
		width: 307px;
		height: 46px;
		left: 0px;
		top: 296px;
	}
	#PPPERFUME {
		left: 9px;
		top: 309px;
		position: absolute;
		overflow: visible;
		width: 293px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}
	#PPGroup_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 131px;
		top: 268px;
		overflow: visible;
	}
	#PPEllipse_38 {
		fill: rgba(255,255,255,1);
	}
	.PPEllipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#PPEllipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.PPEllipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#PPEllipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.PPEllipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#PPEllipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.PPEllipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#PPEllipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.PPEllipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#PPIn_Stock {
		left: 632px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 77px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 509.998px;
		left: 95.5px;
		top: 3253px;
		overflow: visible;
	}
	#PPGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 136.996px;
		overflow: visible;
	}
	#PPGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.PPRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#PPsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.002px;
		overflow: visible;
	}
	#PPlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#PPmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PPmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#PPn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.PPn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.PPlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.PPtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PPInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PPshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PPshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#PPwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPmed_shadow_space_website_7_cw {
		fill: rgba(255,255,255,1);
	}
	.PPmed_shadow_space_website_7_cw {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#PPn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.002px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.002px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.002px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.002px;
		overflow: visible;
	}
	#PPemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.002px;
		overflow: visible;
	}
	#PPphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.002px;
		overflow: visible;
	}
	#PPn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.002px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#PPContact_Us {
		left: 450px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 255px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 50px;
		color: rgba(36,36,36,1);
	}
	.mediaViewInfo {
		--web-view-name: PunctureWizards;
		--web-view-id: PunctureWizards;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: PunctureWizards;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#PunctureWizards {
		position: absolute;
		width: 1366px;
		height: 3287px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: PunctureWizards;
		--web-view-id: PunctureWizards;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#PWText {
		position: absolute;
		width: 509px;
		height: 209px;
		left: 53px;
		top: 228px;
		overflow: visible;
	}
	#PWExperience_the_finest_in_premi {
		left: 0px;
		top: 187px;
		position: absolute;
		overflow: visible;
		width: 375px;
		white-space: nowrap;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(6,18,50,1);
	}
	#PWCompany__Name {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 510px;
		height: 134px;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: bold;
		font-size: 57px;
		color: rgba(6,18,50,1);
	}
	#PWBGs {
		position: absolute;
		width: 683px;
		height: 599px;
		left: 683px;
		top: -71px;
		overflow: visible;
	}
	#PWtechnician-is-repairing-car-fl {
		position: absolute;
		width: 683px;
		height: 599px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWAnime_Edit {
		position: absolute;
		width: 55px;
		height: 55px;
		left: 53px;
		top: 28px;
		overflow: visible;
	}
	#PWGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 498.998px;
		left: 95.5px;
		top: 2769px;
		overflow: visible;
	}
	#PWGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 125.997px;
		overflow: visible;
	}
	#PWGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.PWRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#PWsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#PWlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#PWmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PWmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PWlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#PWn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.PWn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PWlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.PWlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PWtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.PWtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#PWInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PWshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PWshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PWinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#PWwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWmed_shadow_space_website_7_bn {
		fill: rgba(255,255,255,1);
	}
	.PWmed_shadow_space_website_7_bn {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PWwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#PWn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PWaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PWaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PWlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#PWemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#PWphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#PWn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#PWContact_Us {
		left: 485px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 205px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -12.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(36,36,36,1);
	}
	#PWGroup_15118 {
		position: absolute;
		width: 1055px;
		height: 1400px;
		left: 156px;
		top: 648px;
		overflow: visible;
	}
	#PWGroup_15114 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWstopped-white-car-with-punctur {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 462px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PWScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#PWIt_is_a_long_established_fact_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PWVISION {
		left: 462px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PWGroup_15115 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 500px;
		overflow: visible;
	}
	#PWshane-rounce-mgt2BlBgprI-unspl {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 655px;
		top: 0px;
		overflow: visible;
	}
	#PWScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 0px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PWScroll_Group_28_b::-webkit-scrollbar{
		display: none;
	}
	#PWIt_is_a_long_established_fact__b {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PWMISSION {
		left: 0px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PWGroup_15116 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 1000px;
		overflow: visible;
	}
	#PWmason-jones-9e_ssIz4jB4-unspla {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWScroll_Group_28_ca {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 462px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PWScroll_Group_28_ca::-webkit-scrollbar{
		display: none;
	}
	#PWIt_is_a_long_established_fact__cb {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PWPRINCIPLES {
		left: 462px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PWGroup_15153 {
		position: absolute;
		width: 1286px;
		height: 428px;
		left: 40px;
		top: 2241px;
		overflow: visible;
	}
	#PWOur_Offerings {
		left: 579px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 130px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PWScroll_Group_90 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1286px;
		height: 380px;
		left: 0px;
		top: 48px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#PWScroll_Group_90::-webkit-scrollbar{
		display: none;
	}
	#PWGroup_15128 {
		position: absolute;
		width: 253px;
		height: 317px;
		left: 50px;
		top: 29px;
		overflow: visible;
	}
	#PWRectangle_750_ci {
		fill: url(#PWRectangle_750_ci);
	}
	.PWRectangle_750_ci {
		-webkit-filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 258px;
		height: 322px;
		left: 0px;
		top: 0px;
	}
	#PWtechnician-is-repairing-car-fl_cj {
		position: absolute;
		width: 231px;
		height: 262px;
		left: 11px;
		top: 11px;
		overflow: visible;
	}
	#PWPuncture {
		left: 11px;
		top: 281px;
		position: absolute;
		overflow: visible;
		width: 232px;
		height: 27px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}

	.mediaViewInfo {
		--web-view-name: CycleHaven;
		--web-view-id: CycleHaven;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: CycleHaven;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#CycleHaven {
		position: absolute;
		width: 1366px;
		height: 3741px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(5,5,5,1);
		overflow: hidden;
		--web-view-name: CycleHaven;
		--web-view-id: CycleHaven;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#CHEllipse_45 {
		-webkit-filter: blur(22px);
		        filter: blur(22px);
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHEllipse_45 {
		position: absolute;
		overflow: visible;
		width: 700px;
		height: 150px;
		left: 624px;
		top: 571px;
	}
	#CHAnime_Edit {
		position: absolute;
		width: 55px;
		height: 55px;
		left: 53px;
		top: 28px;
		overflow: visible;
	}
	#CHGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 498.998px;
		left: 95.5px;
		top: 3242.002px;
		overflow: visible;
	}
	#CHGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 125.997px;
		overflow: visible;
	}
	#CHGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.CHRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#CHsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#CHlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#CHmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CHmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#CHn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.CHn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.CHlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.CHtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#CHshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CHshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#CHwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHmed_shadow_space_website_7_bk {
		fill: rgba(255,255,255,1);
	}
	.CHmed_shadow_space_website_7_bk {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#CHn_234567890_mobile_number_shad {
		left: 703.5px;
		top: 28.001px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#CHaffanghanioperatelive_email_sh {
		left: 703.5px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#CHaddress_med_shadow_space_websi {
		left: 703.5px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 66px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#CHlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#CHemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#CHphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#CHn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#CHContact_Us {
		left: 485px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 205px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -12.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(255,255,255,1);
	}
	#CHCTA {
		position: absolute;
		width: 432px;
		height: 295px;
		left: 75px;
		top: 188px;
		overflow: visible;
	}
	#CHScroll_Group_92 {
		mix-blend-mode: normal;
		position: absolute;
		width: 432px;
		height: 91px;
		left: 0px;
		top: 204px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHScroll_Group_92::-webkit-scrollbar{
		display: none;
	}
	#CHLorem_ipsum_dolor_sit_amet_con {
		opacity: 0.6;
		left: 7px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 415px;
		height: 69px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#CHCompany_Name {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 286px;
		white-space: nowrap;
		line-height: 100px;
		margin-top: -18px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 64px;
		color: rgba(255,255,255,1);
	}
	#CHGroup_15154 {
		position: absolute;
		width: 168.915px;
		height: 45.743px;
		left: 64px;
		top: 516.5px;
		overflow: visible;
	}
	#CHPath_7783 {
		fill: transparent;
		stroke: rgba(81,232,239,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHPath_7783 {
		overflow: visible;
		position: absolute;
		width: 119.314px;
		height: 19.371px;
		left: 50.085px;
		top: 27px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHPath_7784 {
		fill: transparent;
		stroke: rgba(81,232,239,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHPath_7784 {
		overflow: visible;
		position: absolute;
		width: 119.314px;
		height: 19.371px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHBuy_Now {
		left: 47px;
		top: 12.5px;
		position: absolute;
		overflow: visible;
		width: 76px;
		white-space: nowrap;
		line-height: 100px;
		margin-top: -42.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		letter-spacing: 1.71px;
	}
	#CHwhite-bicycle-standing-park {
		position: absolute;
		width: 964px;
		height: 642.333px;
		left: 442px;
		top: 28px;
		overflow: visible;
	}
	#CHGroup_15039 {
		position: absolute;
		width: 1193px;
		height: 1695px;
		left: 87px;
		top: 796px;
		overflow: visible;
	}
	#CHGroup_15033 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHalvan-nee-EHILKZNmTuA-unsplash {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		        filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHGroup_15038 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 600px;
		top: 38px;
		overflow: visible;
	}
	#CHScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#CHIt_is_a_long_established_fact_ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 265px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#CHVISION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHGroup_15034 {
		position: absolute;
		width: 1193px;
		height: 419.078px;
		left: 0px;
		top: 638px;
		overflow: visible;
	}
	#CHkaur-kristjan-miUC0b1IVYU-unsp {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		        filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		position: absolute;
		width: 560px;
		height: 419.078px;
		left: 633px;
		top: 0px;
		overflow: visible;
	}
	#CHGroup_15037 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 0px;
		top: 39px;
		overflow: visible;
	}
	#CHScroll_Group_28_cd {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHScroll_Group_28_cd::-webkit-scrollbar{
		display: none;
	}
	#CHIt_is_a_long_established_fact__ce {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#CHMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHGroup_15035 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 1276px;
		overflow: visible;
	}
	#CHsaurav-kundu-H8QttyFgroY-unspl {
		-webkit-filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		        filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHScroll_Group_28_ci {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 600px;
		top: 103px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHScroll_Group_28_ci::-webkit-scrollbar{
		display: none;
	}
	#CHIt_is_a_long_established_fact__cj {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#CHPRINCIPLES {
		left: 618px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHGroup_15032 {
		position: absolute;
		width: 1300px;
		height: 660px;
		left: 0px;
		top: 2640.999px;
		overflow: visible;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#CHScroll_Group_60 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1300px;
		height: 572.001px;
		left: 0px;
		top: 87.999px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#CHScroll_Group_60::-webkit-scrollbar{
		display: none;
	}
	#CHGroup_15031 {
		position: absolute;
		width: 485px;
		height: 385px;
		left: 28px;
		top: 27px;
		overflow: visible;
	}
	#CHRectangle_112 {
		fill: rgba(255,255,255,1);
	}
	.CHRectangle_112 {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 503px;
		height: 403px;
		left: 0px;
		top: 0px;
	}
	#CHMask_Group_4 {
		position: absolute;
		width: 460px;
		height: 277px;
		left: 13px;
		top: 12px;
		overflow: visible;
	}
	#CHCycle {
		left: 11px;
		top: 343px;
		position: absolute;
		overflow: visible;
		width: 463px;
		height: 30px;
		text-align: left;
		font-family: Sen;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#CHGroup_9694 {
		position: absolute;
		width: 370px;
		height: 26px;
		left: 11px;
		top: 303px;
		overflow: visible;
	}
	#CHBangalore {
		opacity: 0.5;
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 26px;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#CHgeo-alt {
		position: absolute;
		width: 17px;
		height: 22.667px;
		left: 0px;
		top: 2px;
		overflow: visible;
	}
	#CHPath_2927 {
		fill: rgba(254,120,71,1);
	}
	.CHPath_2927 {
		overflow: visible;
		position: absolute;
		width: 17px;
		height: 22.667px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#CHOur_Collection {
		-webkit-filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		        filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 543px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		color: rgba(255,255,255,1);
	}

	.mediaViewInfo {
		--web-view-name: SweetTreat;
		--web-view-id: SweetTreat;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: SweetTreat;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#SweetTreat {
		position: absolute;
		width: 1366px;
		height: 3277px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: SweetTreat;
		--web-view-id: SweetTreat;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#STCGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 487.998px;
		left: 95.5px;
		top: 2768.904px;
		overflow: visible;
	}
	#STCGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 114.997px;
		overflow: visible;
	}
	#STCGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STCRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.STCRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#STCsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#STClinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#STCmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.STCmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#STClinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#STCn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.STCn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#STClinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.STClinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#STCtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.STCtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#STCInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#STCshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.STCshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#STCinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#STCwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STCmed_shadow_space_website_7_bl {
		fill: rgba(255,255,255,1);
	}
	.STCmed_shadow_space_website_7_bl {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#STCwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#STCn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STCaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STCaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STClocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#STCemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#STCphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#STCn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#STCContact_Us {
		left: 510.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#STCEllipse_44 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.STCEllipse_44 {
		position: absolute;
		overflow: visible;
		width: 64px;
		height: 1px;
		left: 1153px;
		top: 286px;
	}
	#STCGroup_15135 {
		position: absolute;
		width: 1078px;
		height: 1370.174px;
		left: 144px;
		top: 770.922px;
		overflow: visible;
	}
	#STCGroup_15126 {
		position: absolute;
		width: 1078px;
		height: 423.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STCshree-iyer-8vzLN4oHcGQ-unsplas {
		position: absolute;
		width: 423.096px;
		height: 423.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STCScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 485px;
		top: 149.078px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#STCIt_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#STCVISION {
		left: 487px;
		top: 84.078px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STCGroup_15125 {
		position: absolute;
		width: 1078px;
		height: 423.096px;
		left: 0px;
		top: 474px;
		overflow: visible;
	}
	#STCaditya-joshi-uWI48cpw7Vo-unspl {
		position: absolute;
		width: 423.096px;
		height: 423.096px;
		left: 654.904px;
		top: 0px;
		overflow: visible;
	}
	#STCScroll_Group_88 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 0px;
		top: 149.078px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#STCIt_is_a_long_established_fact__b {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#STCMISSION {
		left: 5px;
		top: 84.078px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STCGroup_15127 {
		position: absolute;
		width: 1078px;
		height: 423.096px;
		left: 0px;
		top: 947.078px;
		overflow: visible;
	}
	#STCsanju-m-gurung-W1qp7p_laj0-uns {
		position: absolute;
		width: 423.096px;
		height: 423.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STCScroll_Group_89 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 485px;
		top: 149.078px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#STCIt_is_a_long_established_fact__ca {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#STCPRINCIPLES {
		left: 490px;
		top: 84.078px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STCGroup_15156 {
		position: absolute;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STCstefano-zocca-1p-Mx-SaLT0-unsp {
		position: absolute;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STCRectangle_755 {
		opacity: 0.29;
		fill: rgba(0,0,0,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.STCRectangle_755 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#STCIndulge_in_a_world_of_sweetnes {
		left: 295px;
		top: 201px;
		position: absolute;
		overflow: visible;
		width: 778px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 49px;
		color: rgba(255,255,255,1);
	}
	#STCAnime_Edit {
		position: absolute;
		width: 80px;
		height: 80px;
		left: 41px;
		top: 44px;
		overflow: visible;
	}
	#STCYour_Business_Name {
		left: 131px;
		top: 70px;
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#STCGroup_15157 {
		position: absolute;
		width: 51.122px;
		height: 67.188px;
		left: 657px;
		top: 542.094px;
		overflow: visible;
	}
	#STCPath_7785 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.STCPath_7785 {
		overflow: visible;
		position: absolute;
		width: 32.202px;
		height: 62.657px;
		left: 21.92px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#STCPath_7786 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.STCPath_7786 {
		overflow: visible;
		position: absolute;
		width: 32.202px;
		height: 60.011px;
		left: 0px;
		top: 10.178px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#STCIcon_awesome-arrow-down {
		fill: rgba(255,255,255,1);
	}
	.STCIcon_awesome-arrow-down {
		overflow: visible;
		position: absolute;
		width: 27.916px;
		height: 28.642px;
		left: 11.964px;
		top: 19.654px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#STCOur_Greatest_Drinks {
		left: 590px;
		top: 2241px;
		position: absolute;
		overflow: visible;
		width: 187px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STCScroll_Group_90 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1286px;
		height: 380px;
		left: 40px;
		top: 2289px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#STCGroup_15128 {
		position: absolute;
		width: 253px;
		height: 317px;
		left: 50px;
		top: 29px;
		overflow: visible;
	}
	#STCRectangle_750_cq {
		fill: url(#STCRectangle_750_cq);
	}
	.STCRectangle_750_cq {
		-webkit-filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 258px;
		height: 322px;
		left: 0px;
		top: 0px;
	}
	#STCprchi-palwe-wwgulilpmlg-unspla {
		position: absolute;
		width: 231px;
		height: 262px;
		left: 11px;
		top: 11px;
		overflow: visible;
	}
	#STCJuice_1 {
		left: 11px;
		top: 281px;
		position: absolute;
		overflow: visible;
		width: 232px;
		height: 27px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	body::-webkit-scrollbar{
		display: none;	
	}
	#STCScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#STCScroll_Group_88::-webkit-scrollbar{
		display: none;
	}
	#STCScroll_Group_89::-webkit-scrollbar{
		display: none;
	}
	#STCScroll_Group_90::-webkit-scrollbar{
		display: none;
	}

	.mediaViewInfo {
		--web-view-name: RadiantLocks;
		--web-view-id: RadiantLocks;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: RadiantLocks;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#RadiantLocks {
		position: absolute;
		width: 1366px;
		height: 3503px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: RadiantLocks;
		--web-view-id: RadiantLocks;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#RLGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 472.998px;
		left: 95.5px;
		top: 3017px;
		overflow: visible;
	}
	#RLGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 99.997px;
		overflow: visible;
	}
	#RLGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#RLRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.RLRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#RLsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#RLlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#RLmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.RLmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#RLlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#RLn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.RLn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#RLlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.RLlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#RLtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.RLtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#RLInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#RLshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.RLshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#RLinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#RLwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#RLmed_shadow_space_website_7_bn {
		fill: rgba(255,255,255,1);
	}
	.RLmed_shadow_space_website_7_bn {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#RLwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#RLn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#RLaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#RLaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#RLlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#RLemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#RLphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#RLn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#RLContact_Us {
		left: 510.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#RLGroup_15151 {
		position: absolute;
		width: 915px;
		height: 1248px;
		left: 226px;
		top: 1112px;
		overflow: visible;
	}
	#RLGroup_15141 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#RLRectangle_752 {
		fill: rgba(249,188,137,1);
	}
	.RLRectangle_752 {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#RLjamie-street-aMuq-Xz7R_M-unspl {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#RLScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#RLScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#RLIt_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#RLVISION {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#RLGroup_15142 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 436px;
		overflow: visible;
	}
	#RLRectangle_752_b {
		fill: rgba(216,180,180,1);
	}
	.RLRectangle_752_b {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#RLshari-sirotnak-ajzbkcRy-wU-uns {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 609px;
		top: 33px;
		overflow: visible;
	}
	#RLScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 107px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#RLScroll_Group_28_b::-webkit-scrollbar{
		display: none;
	}
	#RLIt_is_a_long_established_fact__b {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#RLMISSION {
		left: 109px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#RLGroup_15143 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 872px;
		overflow: visible;
	}
	#RLRectangle_752_cb {
		fill: rgba(150,222,145,1);
	}
	.RLRectangle_752_cb {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#RLjamie-street-aMuq-Xz7R_M-unspl_cc {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#RLScroll_Group_28_cd {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#RLScroll_Group_28_cd::-webkit-scrollbar{
		display: none;
	}
	#RLIt_is_a_long_established_fact__ce {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#RLPRINCIPLES {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#RLGroup_15150 {
		position: absolute;
		width: 1286px;
		height: 457px;
		left: 40px;
		top: 2460px;
		overflow: visible;
	}
	#RLOfferings {
		left: 65px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 143px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(0,0,0,1);
	}
	#RLGroup_15145 {
		position: absolute;
		width: 185px;
		height: 61px;
		left: 1009px;
		top: 0px;
		overflow: visible;
	}
	#RLRectangle_753 {
		fill: rgba(255,255,255,1);
		stroke: rgba(0,0,0,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.RLRectangle_753 {
		-webkit-filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.678));
		        filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.678));
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 79px;
		left: 0px;
		top: 0px;
	}
	#RLView_More {
		left: 46px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#RLScroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1286px;
		height: 396px;
		left: 0px;
		top: 61px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#RLScroll_Group_91::-webkit-scrollbar{
		display: none;
	}
	#RLGroup_15144 {
		position: absolute;
		width: 243px;
		height: 331px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#RLRectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.RLRectangle_754 {
		-webkit-filter: drop-shadow(5px 5px 1px rgba(235, 99, 99, 0.518));
		        filter: drop-shadow(5px 5px 1px rgba(235, 99, 99, 0.518));
		position: absolute;
		overflow: visible;
		width: 249.5px;
		height: 337.5px;
		left: 0px;
		top: 0px;
	}
	#RLbaylee-gramling-a3xr2mVjT5M-un {
		position: absolute;
		width: 239px;
		height: 255px;
		left: 2px;
		top: 38px;
		overflow: visible;
	}
	#RLTitle {
		left: 7px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#RLn_10000 {
		left: 7px;
		top: 299px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#RLGroup_15139 {
		position: absolute;
		width: 1366px;
		height: 157px;
		left: 0px;
		top: 855px;
		overflow: visible;
	}
	#RLRectangle_752_cs {
		fill: rgba(235,161,99,1);
	}
	.RLRectangle_752_cs {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 157px;
		left: 0px;
		top: 0px;
	}
	#RLLorem_Ipsum_is_simply_dummy_te {
		left: 79px;
		top: 22px;
		position: absolute;
		overflow: visible;
		width: 1210px;
		height: 114px;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#RLGroup_15086 {
		position: absolute;
		width: 1335px;
		height: 826px;
		left: 31px;
		top: 0px;
		overflow: visible;
	}
	#RLMask_Group_1 {
		position: absolute;
		width: 901px;
		height: 826px;
		left: 434px;
		top: 0px;
		overflow: visible;
	}
	#RLRectangle_3 {
		fill: rgba(252,213,70,1);
	}
	.RLRectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 595px;
	}
	#RLOrder_Now {
		left: 45px;
		top: 608px;
		position: absolute;
		overflow: visible;
		width: 81px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#RLWhere_Beauty_Meets__Confidence {
		left: 0px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 531px;
		white-space: nowrap;
		line-height: 70px;
		margin-top: -8px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 54px;
		color: rgba(36,36,36,1);
	}
	#RLChoose_one_of_our_plans_enter_ {
		opacity: 0.5;
		left: 4px;
		top: 493px;
		position: absolute;
		overflow: visible;
		width: 443px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(36,36,36,1);
	}
	#RLCompany_Name {
		left: 4px;
		top: 49px;
		position: absolute;
		overflow: visible;
		width: 189px;
		white-space: nowrap;
		line-height: 51px;
		margin-top: -13px;
		text-align: left;
		font-family: Semplicità;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(36,36,36,1);
	}
	body::-webkit-scrollbar{
		display: none;
	}
	@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
	@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation: fadeIn 3s;
          animation: fadeIn 3s;
}

	.mediaViewInfo {
		--web-view-name: True Vision;
		--web-view-id: True_Vision;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: True_Vision;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#True_Vision {
		position: absolute;
		width: 1366px;
		height: 3496px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: True Vision;
		--web-view-id: True_Vision;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#TVRectangle_760 {
		fill: rgba(242,209,192,1);
	}
	.TVRectangle_760 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 40px;
		left: 0px;
		top: 2121px;
	}
	#TVRectangle_761 {
		fill: rgba(242,209,192,1);
	}
	.TVRectangle_761 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 40px;
		left: 0px;
		top: 2179px;
	}
	#TVRectangle_756 {
		fill: rgba(242,209,192,1);
	}
	.TVRectangle_756 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 40px;
		left: 0px;
		top: 1123px;
	}
	#TVRectangle_759 {
		fill: rgba(242,209,192,1);
	}
	.TVRectangle_759 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 40px;
		left: 0px;
		top: 1622px;
	}
	#TVRectangle_757 {
		fill: rgba(242,209,192,1);
	}
	.TVRectangle_757 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 40px;
		left: 0px;
		top: 1181px;
	}
	#TVRectangle_758 {
		fill: rgba(242,209,192,1);
	}
	.TVRectangle_758 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 40px;
		left: 0px;
		top: 1680px;
	}
	#TVGroup_15167 {
		position: absolute;
		width: 1366px;
		height: 911px;
		left: 0px;
		top: -216px;
		overflow: visible;
	}
	#TVvolodymyr-hryshchenko-NQQ_WKbj {
		position: absolute;
		width: 1366px;
		height: 911px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVAnime_Edit {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 61px;
		top: 261px;
		overflow: visible;
	}
	#TVYour_Business_Name {
		left: 146px;
		top: 278px;
		position: absolute;
		overflow: visible;
		width: 184px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TVSee_the_world_through_a_cleare {
		left: 61px;
		top: 381px;
		position: absolute;
		overflow: visible;
		width: 1066px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 56px;
		color: rgba(255,255,255,1);
	}
	#TVGroup_15118 {
		position: absolute;
		width: 1055px;
		height: 1400px;
		left: 156px;
		top: 820px;
		overflow: visible;
	}
	#TVGroup_15114 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVjoshua-newton-g4_IymCiD-k-unsp {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 462px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TVIt_is_a_long_established_fact_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#TVVISION {
		left: 462px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVGroup_15115 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 500px;
		overflow: visible;
	}
	#TVfelipepelaquim-0q5Y7OFmMhM-uns {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 655px;
		top: 0px;
		overflow: visible;
	}
	#TVScroll_Group_28_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 0px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TVIt_is_a_long_established_fact__ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#TVMISSION {
		left: 0px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVGroup_15116 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 1000px;
		overflow: visible;
	}
	#TVdmitry-ratushny-wpi3sDUrSEk-un {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVScroll_Group_28_bb {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 462px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TVIt_is_a_long_established_fact__bc {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#TVPRINCIPLES {
		left: 462px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVGroup_15119 {
		position: absolute;
		width: 1341px;
		height: 463px;
		left: 13px;
		top: 2369px;
		overflow: visible;
	}
	#TVScroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1341px;
		height: 386px;
		left: 0px;
		top: 77px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#TVn_ {
		position: absolute;
		width: 307px;
		height: 342px;
		left: 25px;
		top: 21px;
		overflow: visible;
	}
	#TVMask_Group_22 {
		position: absolute;
		width: 307px;
		height: 311px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVRectangle_664 {
		fill: rgba(0,0,0,1);
	}
	.TVRectangle_664 {
		position: absolute;
		overflow: visible;
		width: 307px;
		height: 46px;
		left: 0px;
		top: 296px;
	}
	#TVCOSMOS {
		left: 9px;
		top: 309px;
		position: absolute;
		overflow: visible;
		width: 293px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}
	#TVGroup_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 131px;
		top: 268px;
		overflow: visible;
	}
	#TVEllipse_38 {
		fill: rgba(255,255,255,1);
	}
	.TVEllipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#TVEllipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.TVEllipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#TVEllipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.TVEllipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#TVEllipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.TVEllipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#TVEllipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.TVEllipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#TVWIDE_RANGE_OF_DESIGN {
		left: 553px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 236px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 487.998px;
		left: 95.5px;
		top: 2981px;
		overflow: visible;
	}
	#TVGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 114.997px;
		overflow: visible;
	}
	#TVGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.TVRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#TVsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#TVlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#TVmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TVmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TVlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#TVn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.TVn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TVlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.TVlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TVtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.TVtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TVInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#TVshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TVshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TVinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#TVwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVmed_shadow_space_website_7_b {
		fill: rgba(255,255,255,1);
	}
	.TVmed_shadow_space_website_7_b {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TVwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#TVn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#TVemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#TVphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#TVn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#TVContact_Us {
		left: 510.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
    
	.mediaViewInfo {
		--web-view-name: TechTrekker;
		--web-view-id: TechTrekker;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: TechTrekker;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#TechTrekker {
		position: absolute;
		width: 1366px;
		height: 4273px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: TechTrekker;
		--web-view-id: TechTrekker;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#TTGroup_15169 {
		position: absolute;
		width: 1545px;
		height: 775.366px;
		left: 17px;
		top: -42px;
		overflow: visible;
	}
	#TTquaritsch-photography-m2zuB8Dq {
		position: absolute;
		width: 1162px;
		height: 775.366px;
		left: 383px;
		top: 0px;
		overflow: visible;
	}
	#TTBottom_left_text {
		position: absolute;
		width: 377px;
		height: 180px;
		left: 87px;
		top: 540px;
		overflow: visible;
	}
	#TTNEW_GADGETS {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 131px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(114,124,137,1);
		letter-spacing: 2.2px;
	}
	#TTBuy_Now {
		left: 3px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: lighter;
		font-size: 13px;
		color: rgba(114,124,137,1);
	}
	#TTView_details {
		left: 257px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 80px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(33,109,252,1);
	}
	#TTCompany_Name {
		left: 0px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 378px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 46px;
		color: rgba(0,28,65,1);
	}
	#TTEllipse_3 {
		fill: rgba(33,109,252,1);
	}
	.TTEllipse_3 {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 5px;
		top: 169px;
	}
	#TTEllipse_4 {
		fill: rgba(214,217,224,1);
	}
	.TTEllipse_4 {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 40px;
		top: 169px;
	}
	#TTEllipse_5 {
		fill: rgba(214,217,224,1);
	}
	.TTEllipse_5 {
		position: absolute;
		overflow: visible;
		width: 10px;
		height: 11px;
		left: 76px;
		top: 169px;
	}
	#TTPath_6 {
		fill: rgba(33,109,252,1);
	}
	.TTPath_6 {
		overflow: visible;
		position: absolute;
		width: 8.108px;
		height: 12.012px;
		-webkit-transform: translate(0.453px, 0.362px) matrix(1,0,0,1,347.8805,120.3858) rotate(180deg);
		        transform: translate(0.453px, 0.362px) matrix(1,0,0,1,347.8805,120.3858) rotate(180deg);
		-webkit-transform-origin: center;
		        transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#TTLine_1 {
		fill: transparent;
		stroke: rgba(190,190,190,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTLine_1 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 15.427px;
		left: 238.231px;
		top: 118.748px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTBottom_right_items {
		position: absolute;
		width: 54px;
		height: 120px;
		left: 1260px;
		top: 639px;
		overflow: visible;
	}
	#TTRectangle_15 {
		fill: rgba(234,238,244,1);
	}
	.TTRectangle_15 {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 49px;
		left: 0px;
		top: 71px;
	}
	#TTRectangle_16 {
		fill: rgba(255,88,178,0.141);
	}
	.TTRectangle_16 {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#TThand-heart {
		position: absolute;
		width: 19.034px;
		height: 16.73px;
		left: 17.826px;
		top: 16.862px;
		overflow: visible;
	}
	#TTPath_5 {
		fill: rgba(255,88,178,0);
		stroke: rgba(255,88,178,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: square;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.TTPath_5 {
		overflow: visible;
		position: absolute;
		width: 23.104px;
		height: 21.633px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTquestion-mark {
		position: absolute;
		width: 13.332px;
		height: 22.073px;
		left: 20.327px;
		top: 84.828px;
		overflow: visible;
	}
	#TTPath_7 {
		fill: rgba(93,106,126,1);
	}
	.TTPath_7 {
		overflow: visible;
		position: absolute;
		width: 13.332px;
		height: 16.555px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_8 {
		fill: rgba(93,106,126,1);
	}
	.TTPath_8 {
		overflow: visible;
		position: absolute;
		width: 3.678px;
		height: 3.678px;
		left: 4.503px;
		top: 18.395px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTControllers {
		position: absolute;
		width: 675px;
		height: 255px;
		left: 0px;
		top: 212px;
		overflow: visible;
	}
	#TTRectangle_1 {
		opacity: 0.35;
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTRectangle_1 {
		position: absolute;
		overflow: visible;
		width: 175px;
		height: 248px;
		left: 0px;
		top: 0px;
	}
	#TTRectangle_2 {
		opacity: 0.35;
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTRectangle_2 {
		position: absolute;
		overflow: visible;
		width: 174px;
		height: 248px;
		left: 246px;
		top: 0px;
	}
	#TTRectangle_3 {
		opacity: 0.35;
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTRectangle_3 {
		position: absolute;
		overflow: visible;
		width: 174px;
		height: 248px;
		left: 492px;
		top: 0px;
	}
	#TTstefen-tan-KYw1eUx1J7Y-unsplas {
		position: absolute;
		width: 192px;
		height: 255px;
		left: 483px;
		top: 0px;
		overflow: visible;
	}
	#TTn_a36d7d2482866597129061513543 {
		position: absolute;
		width: 131px;
		height: 196px;
		left: 22px;
		top: 26px;
		overflow: visible;
	}
	#TTryan-putra-j4PqlNVZ4Bc-unsplas {
		position: absolute;
		width: 165.269px;
		height: 248px;
		left: 246.648px;
		top: 0px;
		overflow: visible;
	}
	#TTjoshua-newton-g4_IymCiD-k-unsp {
		position: absolute;
		width: 61px;
		height: 61px;
		left: 75px;
		top: 78px;
		overflow: visible;
	}
	#TTService {
		position: absolute;
		width: 1366px;
		height: 1413px;
		left: 0px;
		top: 1260px;
		overflow: visible;
	}
	#TTRectangle_46 {
		fill: rgba(251,252,253,1);
	}
	.TTRectangle_46 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 1413px;
		left: 0px;
		top: 0px;
	}
	#TTOUR_SERVICES {
		left: 613px;
		top: 64px;
		position: absolute;
		overflow: visible;
		width: 141px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -30px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(61,55,241,1);
	}
	#TTWe_Provide_Best_Quality_Gadget {
		left: 391px;
		top: 105px;
		position: absolute;
		overflow: visible;
		width: 585px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -20px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(90,84,84,1);
	}
	#TTGroup_15176 {
		position: absolute;
		width: 370px;
		height: 451px;
		left: 98px;
		top: 349px;
		overflow: visible;
	}
	#TTRectangle_47_bn {
		fill: url(#TTRectangle_47_bn);
	}
	.TTRectangle_47_bn {
		-webkit-filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		        filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 490px;
		height: 571px;
		left: 0px;
		top: 0px;
	}
	#TTOur_Services {
		left: 116.613px;
		top: 244.936px;
		position: absolute;
		overflow: visible;
		width: 123px;
		white-space: nowrap;
		line-height: 54px;
		margin-top: -16px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(255,255,255,1);
	}
	#TTFast_Powerful__Most_Secure_Net {
		left: 21px;
		top: 285.045px;
		position: absolute;
		overflow: visible;
		width: 329px;
		height: 62px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#TTRectangle_50 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_50 {
		-webkit-filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 191px;
		height: 105px;
		left: 119px;
		top: 358px;
	}
	#TTRead_More {
		left: 149px;
		top: 370px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTIcon1 {
		position: absolute;
		width: 100.682px;
		height: 100.916px;
		left: 135px;
		top: 84px;
		overflow: visible;
	}
	#TTPath_1202 {
		fill: rgba(255,255,255,1);
	}
	.TTPath_1202 {
		overflow: visible;
		position: absolute;
		width: 82.914px;
		height: 85.813px;
		left: 0.001px;
		top: 15.103px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1203 {
		fill: rgba(255,255,255,1);
	}
	.TTPath_1203 {
		overflow: visible;
		position: absolute;
		width: 33.005px;
		height: 33.057px;
		left: 67.677px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTRectangle_92 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_92 {
		position: absolute;
		overflow: visible;
		width: 19.803px;
		height: 3.301px;
		left: 74.278px;
		top: 6.601px;
	}
	#TTRectangle_93 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_93 {
		position: absolute;
		overflow: visible;
		width: 13.202px;
		height: 3.301px;
		left: 74.278px;
		top: 13.202px;
	}
	#TTRectangle_94 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_94 {
		position: absolute;
		overflow: visible;
		width: 3.3px;
		height: 3.301px;
		left: 90.781px;
		top: 13.202px;
	}
	#TTPath_1204 {
		fill: rgba(255,255,255,1);
	}
	.TTPath_1204 {
		overflow: visible;
		position: absolute;
		width: 64.612px;
		height: 41.87px;
		left: 0px;
		top: 4.951px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1205 {
		fill: rgba(255,255,255,1);
	}
	.TTPath_1205 {
		overflow: visible;
		position: absolute;
		width: 17.019px;
		height: 49.513px;
		left: 82.862px;
		top: 29.925px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTGroup_15177 {
		position: absolute;
		width: 370px;
		height: 451px;
		left: 497px;
		top: 349px;
		overflow: visible;
	}
	#TTRectangle_51 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_51 {
		-webkit-filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		        filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 490px;
		height: 571px;
		left: 0px;
		top: 0px;
	}
	#TTSecurity_Solutions {
		left: 90.613px;
		top: 244.936px;
		position: absolute;
		overflow: visible;
		width: 175px;
		white-space: nowrap;
		line-height: 54px;
		margin-top: -16px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(90,84,84,1);
	}
	#TTFast_Powerful__Most_Secure_Net_b {
		left: 21px;
		top: 285.045px;
		position: absolute;
		overflow: visible;
		width: 329px;
		height: 62px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(116,116,116,1);
	}
	#TTRectangle_52 {
		fill: transparent;
		stroke: rgba(236,227,227,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTRectangle_52 {
		position: absolute;
		overflow: visible;
		width: 131px;
		height: 45px;
		left: 119px;
		top: 358px;
	}
	#TTRead_More_b {
		left: 149px;
		top: 370px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTIcon2 {
		position: absolute;
		width: 100px;
		height: 100px;
		left: 135px;
		top: 85px;
		overflow: visible;
	}
	#TTPath_1206 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1206 {
		overflow: visible;
		position: absolute;
		width: 63.124px;
		height: 92.246px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1207 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1207 {
		overflow: visible;
		position: absolute;
		width: 63.16px;
		height: 92.245px;
		left: 36.84px;
		top: 7.755px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1208 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1208 {
		overflow: visible;
		position: absolute;
		width: 3.928px;
		height: 3.913px;
		left: 30.141px;
		top: 92.648px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1209 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1209 {
		overflow: visible;
		position: absolute;
		width: 3.939px;
		height: 3.92px;
		left: 65.999px;
		top: 3.466px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1210 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1210 {
		overflow: visible;
		position: absolute;
		width: 78.906px;
		height: 50.245px;
		left: 10.547px;
		top: 18.7px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1211 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1211 {
		overflow: visible;
		position: absolute;
		width: 32.813px;
		height: 42.883px;
		left: 33.594px;
		top: 36.414px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1212 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1212 {
		overflow: visible;
		position: absolute;
		width: 11.236px;
		height: 15.623px;
		left: 44.36px;
		top: 56.838px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTGroup_15178 {
		position: absolute;
		width: 370px;
		height: 451px;
		left: 897px;
		top: 349px;
		overflow: visible;
	}
	#TTRectangle_53 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_53 {
		-webkit-filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		        filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 490px;
		height: 571px;
		left: 0px;
		top: 0px;
	}
	#TTSound_Systems {
		left: 104.613px;
		top: 244.936px;
		position: absolute;
		overflow: visible;
		width: 147px;
		white-space: nowrap;
		line-height: 54px;
		margin-top: -16px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(90,84,84,1);
	}
	#TTFast_Powerful__Most_Secure_Net_ch {
		left: 21px;
		top: 285.045px;
		position: absolute;
		overflow: visible;
		width: 329px;
		height: 62px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(116,116,116,1);
	}
	#TTRectangle_54 {
		fill: transparent;
		stroke: rgba(236,227,227,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTRectangle_54 {
		position: absolute;
		overflow: visible;
		width: 131px;
		height: 45px;
		left: 119px;
		top: 358px;
	}
	#TTRead_More_cj {
		left: 149px;
		top: 370px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTIcon3 {
		position: absolute;
		width: 100.775px;
		height: 99px;
		left: 135px;
		top: 85px;
		overflow: visible;
	}
	#TTPath_1213 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1213 {
		overflow: visible;
		position: absolute;
		width: 43.499px;
		height: 11.35px;
		left: 28.638px;
		top: 43.661px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1214 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1214 {
		overflow: visible;
		position: absolute;
		width: 30.497px;
		height: 8.657px;
		left: 35.139px;
		top: 52.853px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTGroup_296 {
		position: absolute;
		width: 100.775px;
		height: 99px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTGroup_295 {
		position: absolute;
		width: 100.775px;
		height: 99px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTGroup_15171 {
		position: absolute;
		width: 370px;
		height: 451px;
		left: 299px;
		top: 850px;
		overflow: visible;
	}
	#TTRectangle_57 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_57 {
		-webkit-filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		        filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 490px;
		height: 571px;
		left: 0px;
		top: 0px;
	}
	#TTPeripherals {
		left: 123.613px;
		top: 244.936px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		line-height: 54px;
		margin-top: -16px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(90,84,84,1);
	}
	#TTFast_Powerful__Most_Secure_Net_cs {
		left: 21px;
		top: 285.045px;
		position: absolute;
		overflow: visible;
		width: 329px;
		height: 62px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(116,116,116,1);
	}
	#TTRectangle_58 {
		fill: transparent;
		stroke: rgba(236,227,227,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTRectangle_58 {
		position: absolute;
		overflow: visible;
		width: 131px;
		height: 45px;
		left: 119px;
		top: 358px;
	}
	#TTRead_More_cu {
		left: 149px;
		top: 370px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTIcon4 {
		position: absolute;
		width: 100.593px;
		height: 100.595px;
		left: 135px;
		top: 87px;
		overflow: visible;
	}
	#TTPath_1217 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1217 {
		overflow: visible;
		position: absolute;
		width: 3.929px;
		height: 3.93px;
		left: 20.18px;
		top: 50.168px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1218 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1218 {
		overflow: visible;
		position: absolute;
		width: 5.631px;
		height: 3.93px;
		left: 29.563px;
		top: 50.168px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1219 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1219 {
		overflow: visible;
		position: absolute;
		width: 3.929px;
		height: 3.93px;
		left: 38.948px;
		top: 50.168px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1220 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1220 {
		overflow: visible;
		position: absolute;
		width: 3.93px;
		height: 3.93px;
		left: 48.332px;
		top: 50.168px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1221 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1221 {
		overflow: visible;
		position: absolute;
		width: 3.93px;
		height: 3.93px;
		left: 57.715px;
		top: 50.168px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1222 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1222 {
		overflow: visible;
		position: absolute;
		width: 3.929px;
		height: 3.93px;
		left: 67.1px;
		top: 50.168px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1223 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1223 {
		overflow: visible;
		position: absolute;
		width: 3.93px;
		height: 3.93px;
		left: 76.484px;
		top: 50.168px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1224 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1224 {
		overflow: visible;
		position: absolute;
		width: 5.482px;
		height: 3.93px;
		left: 10.609px;
		top: 60.155px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1225 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1225 {
		overflow: visible;
		position: absolute;
		width: 5.481px;
		height: 3.93px;
		left: 21.556px;
		top: 60.155px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1226 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1226 {
		overflow: visible;
		position: absolute;
		width: 3.928px;
		height: 3.93px;
		left: 30.967px;
		top: 60.155px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1227 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1227 {
		overflow: visible;
		position: absolute;
		width: 3.929px;
		height: 3.93px;
		left: 40.473px;
		top: 60.155px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1228 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1228 {
		overflow: visible;
		position: absolute;
		width: 3.93px;
		height: 3.929px;
		left: 21.815px;
		top: 69.618px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1229 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1229 {
		overflow: visible;
		position: absolute;
		width: 3.928px;
		height: 3.929px;
		left: 31.323px;
		top: 69.618px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1230 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1230 {
		overflow: visible;
		position: absolute;
		width: 7.861px;
		height: 3.93px;
		left: 52.263px;
		top: 60.155px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1231 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1231 {
		overflow: visible;
		position: absolute;
		width: 14.605px;
		height: 3.929px;
		left: 41.979px;
		top: 69.618px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1232 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1232 {
		overflow: visible;
		position: absolute;
		width: 5.505px;
		height: 3.93px;
		left: 84.478px;
		top: 60.155px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1233 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1233 {
		overflow: visible;
		position: absolute;
		width: 3.929px;
		height: 3.93px;
		left: 37.919px;
		top: 10.61px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1234 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1234 {
		overflow: visible;
		position: absolute;
		width: 100.593px;
		height: 89.985px;
		left: 0px;
		top: 10.61px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1235 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1235 {
		overflow: visible;
		position: absolute;
		width: 34.579px;
		height: 35.365px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1236 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1236 {
		overflow: visible;
		position: absolute;
		width: 17.008px;
		height: 3.93px;
		left: 8.785px;
		top: 23.574px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1237 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1237 {
		overflow: visible;
		position: absolute;
		width: 17.008px;
		height: 3.93px;
		left: 8.785px;
		top: 15.718px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1238 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1238 {
		overflow: visible;
		position: absolute;
		width: 17.008px;
		height: 3.93px;
		left: 8.785px;
		top: 7.86px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTGroup_15170 {
		position: absolute;
		width: 370px;
		height: 451px;
		left: 698px;
		top: 850px;
		overflow: visible;
	}
	#TTRectangle_55 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_55 {
		-webkit-filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		        filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 490px;
		height: 571px;
		left: 0px;
		top: 0px;
	}
	#TTPrinters_Projectors__Scanners {
		left: 28.613px;
		top: 244.936px;
		position: absolute;
		overflow: visible;
		width: 299px;
		white-space: nowrap;
		line-height: 54px;
		margin-top: -16px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(90,84,84,1);
	}
	#TTFast_Powerful__Most_Secure_Net_dl {
		left: 21px;
		top: 285.045px;
		position: absolute;
		overflow: visible;
		width: 329px;
		height: 62px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(116,116,116,1);
	}
	#TTRectangle_60 {
		fill: transparent;
		stroke: rgba(236,227,227,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTRectangle_60 {
		position: absolute;
		overflow: visible;
		width: 131px;
		height: 45px;
		left: 119px;
		top: 358px;
	}
	#TTRead_More_dn {
		left: 149px;
		top: 370px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTIcon6 {
		position: absolute;
		width: 100.387px;
		height: 100.387px;
		left: 135px;
		top: 87px;
		overflow: visible;
	}
	#TTPath_1247 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1247 {
		overflow: visible;
		position: absolute;
		width: 100.387px;
		height: 100.387px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1248 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1248 {
		overflow: visible;
		position: absolute;
		width: 15.058px;
		height: 15.058px;
		left: 60.232px;
		top: 55.213px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTRectangle_95 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_95 {
		position: absolute;
		overflow: visible;
		width: 15.058px;
		height: 5.02px;
		left: 10.039px;
		top: 55.213px;
	}
	#TTRectangle_96 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_96 {
		position: absolute;
		overflow: visible;
		width: 15.058px;
		height: 5.02px;
		left: 10.039px;
		top: 65.251px;
	}
	#TTRectangle_97 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_97 {
		position: absolute;
		overflow: visible;
		width: 5.02px;
		height: 5.02px;
		left: 30.116px;
		top: 65.251px;
	}
	#TTRectangle_98 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_98 {
		position: absolute;
		overflow: visible;
		width: 5.02px;
		height: 5.02px;
		left: 30.116px;
		top: 55.213px;
	}
	#TTRectangle_99 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_99 {
		position: absolute;
		overflow: visible;
		width: 15.058px;
		height: 5.02px;
		left: 10.039px;
		top: 45.174px;
	}
	#TTRectangle_100 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_100 {
		position: absolute;
		overflow: visible;
		width: 5.02px;
		height: 5.02px;
		left: 30.116px;
		top: 45.174px;
	}
	#TTRectangle_101 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_101 {
		position: absolute;
		overflow: visible;
		width: 15.058px;
		height: 5.02px;
		left: 55.213px;
		top: 25.097px;
	}
	#TTRectangle_102 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_102 {
		position: absolute;
		overflow: visible;
		width: 5.02px;
		height: 5.02px;
		left: 75.291px;
		top: 25.097px;
	}
	#TTBrands {
		position: absolute;
		width: 1366px;
		height: 283px;
		left: 0px;
		top: 873px;
		overflow: visible;
	}
	#TTRectangle_79 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_79 {
		-webkit-filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.039));
		        filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.039));
		position: absolute;
		overflow: visible;
		width: 1516px;
		height: 433px;
		left: 0px;
		top: 0px;
	}
	#TTScroll_Group_97 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1295px;
		height: 212px;
		left: 30px;
		top: 71px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#TTLogitech-logo-300x300 {
		opacity: 0.61;
		position: absolute;
		width: 188px;
		height: 188px;
		left: 56px;
		top: 12px;
		overflow: visible;
	}
	#TTOur_Partners {
		left: 570px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 227px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		color: rgba(0,28,65,1);
	}
	#TTTop_pick {
		position: absolute;
		width: 1209px;
		height: 927.793px;
		left: 98px;
		top: 2752px;
		overflow: visible;
	}
	#TTTitle {
		position: absolute;
		width: 228px;
		height: 87px;
		left: 0px;
		top: 85px;
		overflow: visible;
	}
	#TTTop_Picks {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 169px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -20px;
		text-align: left;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(90,84,84,1);
	}
	#TTWe_Provide_Best_Quality {
		left: 0px;
		top: 58px;
		position: absolute;
		overflow: visible;
		width: 229px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -29px;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: lighter;
		font-size: 22px;
		color: rgba(116,116,116,1);
	}
	#TTMenu {
		position: absolute;
		width: 198px;
		height: 37px;
		left: 0px;
		top: 264px;
		overflow: visible;
	}
	#TTAll_Parts {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 85px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -29px;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(61,55,241,1);
	}
	#TTRectangle_115 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_115 {
		position: absolute;
		overflow: visible;
		width: 198px;
		height: 2px;
		left: 0px;
		top: 35px;
	}
	#TTScroll_Group_96 {
		mix-blend-mode: normal;
		position: absolute;
		width: 840px;
		height: 927.793px;
		left: 369px;
		top: 0px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#TTn_ {
		position: absolute;
		width: 370px;
		height: 410.793px;
		left: 431px;
		top: 466px;
		overflow: visible;
	}
	#TTPath_1297 {
		fill: rgba(255,255,255,1);
	}
	.TTPath_1297 {
		overflow: visible;
		position: absolute;
		width: 370px;
		height: 410.793px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTProduct_4 {
		left: 30px;
		top: 234.97px;
		position: absolute;
		overflow: visible;
		width: 311px;
		height: 62px;
		line-height: 26px;
		margin-top: -2px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(90,84,84,1);
	}
	#TTRead_more {
		position: absolute;
		width: 131px;
		height: 45px;
		left: 115px;
		top: 305px;
		overflow: visible;
	}
	#TTRectangle_117 {
		fill: rgba(241,241,255,1);
	}
	.TTRectangle_117 {
		position: absolute;
		overflow: visible;
		width: 131px;
		height: 45px;
		left: 0px;
		top: 0px;
	}
	#TTRead_More_eh {
		left: 30px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTbrand-identity {
		position: absolute;
		width: 152px;
		height: 131px;
		left: 109px;
		top: 50px;
		overflow: visible;
	}
	#TTn__ej {
		position: absolute;
		width: 370px;
		height: 410.793px;
		left: 31px;
		top: 466px;
		overflow: visible;
	}
	#TTPath_1191 {
		fill: rgba(255,255,255,1);
	}
	.TTPath_1191 {
		overflow: visible;
		position: absolute;
		width: 370px;
		height: 410.793px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTProduct_3 {
		left: 30px;
		top: 234.97px;
		position: absolute;
		overflow: visible;
		width: 311px;
		height: 62px;
		line-height: 26px;
		margin-top: -2px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(90,84,84,1);
	}
	#TTbrand-identity_em {
		position: absolute;
		width: 184px;
		height: 157px;
		left: 88px;
		top: 36px;
		overflow: visible;
	}
	#TTRead_more_en {
		position: absolute;
		width: 131px;
		height: 45px;
		left: 115px;
		top: 305px;
		overflow: visible;
	}
	#TTRectangle_87 {
		fill: rgba(241,241,255,1);
	}
	.TTRectangle_87 {
		position: absolute;
		overflow: visible;
		width: 131px;
		height: 45px;
		left: 0px;
		top: 0px;
	}
	#TTRead_More_ep {
		left: 30px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTn__eq {
		position: absolute;
		width: 370px;
		height: 410.793px;
		left: 431px;
		top: 25px;
		overflow: visible;
	}
	#TTPath_1191_er {
		fill: rgba(255,255,255,1);
	}
	.TTPath_1191_er {
		overflow: visible;
		position: absolute;
		width: 370px;
		height: 410.793px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTProduct_2 {
		left: 30px;
		top: 234.97px;
		position: absolute;
		overflow: visible;
		width: 311px;
		height: 62px;
		line-height: 26px;
		margin-top: -2px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(90,84,84,1);
	}
	#TTRead_more_et {
		position: absolute;
		width: 131px;
		height: 45px;
		left: 115px;
		top: 305px;
		overflow: visible;
	}
	#TTRectangle_87_eu {
		fill: rgba(241,241,255,1);
	}
	.TTRectangle_87_eu {
		position: absolute;
		overflow: visible;
		width: 131px;
		height: 45px;
		left: 0px;
		top: 0px;
	}
	#TTRead_More_ev {
		left: 30px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTbrand-identity_ew {
		position: absolute;
		width: 152px;
		height: 131px;
		left: 109px;
		top: 50px;
		overflow: visible;
	}
	#TTn__ex {
		position: absolute;
		width: 370px;
		height: 410.793px;
		left: 31px;
		top: 25px;
		overflow: visible;
	}
	#TTPath_1191_ey {
		fill: rgba(255,255,255,1);
	}
	.TTPath_1191_ey {
		overflow: visible;
		position: absolute;
		width: 370px;
		height: 410.793px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTProduct_1 {
		left: 30px;
		top: 234.97px;
		position: absolute;
		overflow: visible;
		width: 311px;
		height: 62px;
		line-height: 26px;
		margin-top: -2px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(90,84,84,1);
	}
	#TTRead_more_e {
		position: absolute;
		width: 131px;
		height: 45px;
		left: 115px;
		top: 305px;
		overflow: visible;
	}
	#TTRectangle_87_e {
		fill: rgba(241,241,255,1);
	}
	.TTRectangle_87_e {
		position: absolute;
		overflow: visible;
		width: 131px;
		height: 45px;
		left: 0px;
		top: 0px;
	}
	#TTRead_More_e {
		left: 30px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTbrand-identity_e {
		position: absolute;
		width: 174px;
		height: 149px;
		left: 98px;
		top: 50px;
		overflow: visible;
	}
	#TTGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 487.998px;
		left: 135.5px;
		top: 3768.427px;
		overflow: visible;
	}
	#TTGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 114.997px;
		overflow: visible;
	}
	#TTGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#TTsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#TTlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#TTmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TTmed_shadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TTlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#TTn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.TTn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.TTlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.TTtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		-webkit-transform: matrix(1,0,0,1,0,0);
		        transform: matrix(1,0,0,1,0,0);
	}
	#TTInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#TTshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TTshadow_space_website_7 {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TTinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#TTwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTmed_shadow_space_website_7_fj {
		fill: rgba(255,255,255,1);
	}
	.TTmed_shadow_space_website_7_fj {
		-webkit-filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		        filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TTwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#TTn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TTaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TTaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TTlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#TTemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#TTphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#TTn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#TTContact_Us {
		left: 510.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}

body {
    background:
    linear-gradient(
      rgba(0, 0, 0, 0.3), 
      rgba(0, 0, 0, 0.3), 
    );
  
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

* {
    margin: 0;
    padding: 0;
}
