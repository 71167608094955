	.mediaViewInfo {
		--web-view-name: SharpStyles;
		--web-view-id: SharpStyles;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: SharpStyles;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#SharpStyles {
		position: absolute;
		width: 1366px;
		height: 4427px;
        left:calc((100vw - 1366px)/2); 
		background: linear-gradient(180deg, rgba(228,228,233,1) 0%, rgba(255,255,255,1) 100%);
		overflow: hidden;
		--web-view-name: SharpStyles;
		--web-view-id: SharpStyles;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#SharpStyles_18_pcGroup_15028 {
		position: absolute;
		width: 843px;
		height: 1579px;
		left: 262px;
		top: 786px;
		overflow: visible;
	}
	#SharpStyles_18_pcGroup_14870 {
		position: absolute;
		width: 820px;
		height: 451px;
		left: 11px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcRectangle_683 {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_pcRectangle_683 {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 746px;
		height: 358px;
		left: 92px;
		top: 56px;
	}
	#SharpStyles_18_pcagustin-fernandez-Znyjl9pbaUs- {
		position: absolute;
		width: 334px;
		height: 451px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcVISION {
		left: 389px;
		top: 89px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcScroll_Group_22 {
		mix-blend-mode: normal;
		position: absolute;
		width: 437px;
		height: 187px;
		left: 360px;
		top: 156px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SharpStyles_18_pcScroll_Group_22::-webkit-scrollbar{
		display: none;
	}
	#SharpStyles_18_pcLorem_ipsum_dolor_sit_amet_con {
		left: 29px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 380px;
		height: 197px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#SharpStyles_18_pcGroup_14869 {
		position: absolute;
		width: 843px;
		height: 464px;
		left: 0px;
		top: 551px;
		overflow: visible;
	}
	#SharpStyles_18_pcRectangle_684 {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_pcRectangle_684 {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 766px;
		height: 367px;
		left: 0px;
		top: 58px;
	}
	#SharpStyles_18_pcjake-blucker-tMzCrBkM99Y-unspl {
		position: absolute;
		width: 344px;
		height: 464px;
		left: 499px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcMISSION {
		left: 54px;
		top: 83px;
		position: absolute;
		overflow: visible;
		width: 167px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 39px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcLorem_ipsum_dolor_sit_amet_con_dc {
		left: 54px;
		top: 160px;
		position: absolute;
		overflow: visible;
		width: 391px;
		height: 203px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(125,125,125,1);
	}
	#SharpStyles_18_pcRectangle_685 {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_pcRectangle_685 {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 766px;
		height: 367px;
		left: 0px;
		top: 58px;
	}
	#SharpStyles_18_pcnathon-oski-EW_rqoSdDes-unspla {
		position: absolute;
		width: 344px;
		height: 464px;
		left: 499px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcMISSION_df {
		left: 54px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 133px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcScroll_Group_21 {
		mix-blend-mode: normal;
		position: absolute;
		width: 392px;
		height: 193px;
		left: 32px;
		top: 160px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SharpStyles_18_pcScroll_Group_21::-webkit-scrollbar{

	display: none;
	}
	#SharpStyles_18_pcLorem_ipsum_dolor_sit_amet_con_dh {
		left: 22px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 391px;
		height: 203px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#SharpStyles_18_pcGroup_14868 {
		position: absolute;
		width: 843px;
		height: 464px;
		left: 0px;
		top: 1115px;
		overflow: visible;
	}
	#SharpStyles_18_pcRectangle_686 {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_pcRectangle_686 {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 766px;
		height: 368px;
		left: 95px;
		top: 58px;
	}
	#SharpStyles_18_pcapothecary-87-Wg3J83R1YSQ-unsp {
		position: absolute;
		width: 344px;
		height: 464px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcPRINCIPLES {
		left: 399px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 173px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcScroll_Group_20 {
		mix-blend-mode: normal;
		position: absolute;
		width: 384px;
		height: 211px;
		left: 383px;
		top: 142px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SharpStyles_18_pcScroll_Group_20::-webkit-scrollbar{
		display: none;
	}
	#SharpStyles_18_pcLorem_ipsum_dolor_sit_amet_con_dn {
		left: 16px;
		top: 18px;
		position: absolute;
		overflow: visible;
		width: 391px;
		height: 203px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#SharpStyles_18_pcGroup_15026 {
		position: absolute;
		width: 1109px;
		height: 531px;
		left: 188px;
		top: 47px;
		overflow: visible;
	}
	#SharpStyles_18_pcWe_are_Desperate_to_make_you_M {
		filter: drop-shadow(0px 16px 21px rgba(0, 0, 0, 0.161));
		left: 5.246px;
		top: 119px;
		position: absolute;
		overflow: visible;
		width: 452px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#SharpStyles_18_pcAnime_Edit {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 0px;
		top: 0px;
		border-radius: 50%;
		overflow: hidden;
	}
	#SharpStyles_18_pcOni_Chan_Haircut {
		left: 95px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 156px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcYou_will_leave_looking_sharp_r {
		left: 5px;
		top: 350px;
		position: absolute;
		overflow: visible;
		width: 464px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcRectangle_723 {
		fill: rgba(123,153,166,1);
	}
	.SharpStyles_18_pcRectangle_723 {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 69px;
		left: 5px;
		top: 462px;
	}
	#SharpStyles_18_pcGet_Started {
		left: 51px;
		top: 483px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SharpStyles_18_pcGroup_15020 {
		position: absolute;
		width: 755px;
		height: 503.371px;
		left: 354px;
		top: 6.629px;
		overflow: visible;
	}
	#SharpStyles_18_pcRectangle_724 {
		fill: rgba(123,153,166,1);
	}
	.SharpStyles_18_pcRectangle_724 {
		position: absolute;
		overflow: visible;
		width: 252px;
		height: 240px;
		left: 290px;
		top: 263.371px;
	}
	#SharpStyles_18_pcMy_project {
		position: absolute;
		width: 755px;
		height: 503.37px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pctrimmer {
		position: absolute;
		width: 51.655px;
		height: 51.655px;
		left: 536.476px;
		top: 129.447px;
		overflow: visible;
	}
	#SharpStyles_18_pcGroup_15018 {
		position: absolute;
		width: 175.934px;
		height: 7.2px;
		left: 555.566px;
		top: 523.8px;
		overflow: visible;
	}
	#SharpStyles_18_pcLine_42 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SharpStyles_18_pcLine_42 {
		overflow: visible;
		position: absolute;
		width: 40px;
		height: 1px;
		left: 0px;
		top: 3.535px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_pcRectangle_725 {
		fill: rgba(99,113,203,1);
	}
	.SharpStyles_18_pcRectangle_725 {
		width: 5px;
		height: 5px;
		position: absolute;
		overflow: visible;
		transform: translate(-666.5px, -49.965px) matrix(1,0,0,1,706,51.0004) rotate(45deg);
		transform-origin: center;
	}
	#SharpStyles_18_pcGroup_15015 {
		position: absolute;
		width: 43.699px;
		height: 7.071px;
		left: 44.536px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcLine_43 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SharpStyles_18_pcLine_43 {
		overflow: visible;
		position: absolute;
		width: 40px;
		height: 1px;
		left: 0px;
		top: 3.535px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_pcRectangle_726 {
		fill: rgba(255,168,168,1);
	}
	.SharpStyles_18_pcRectangle_726 {
		width: 5px;
		height: 5px;
		position: absolute;
		overflow: visible;
		transform: translate(-670.5px, -47.965px) matrix(1,0,0,1,708.1637,49.0004) rotate(45deg);
		transform-origin: center;
	}
	#SharpStyles_18_pcGroup_15016 {
		position: absolute;
		width: 43.699px;
		height: 7.071px;
		left: 88.536px;
		top: 0.129px;
		overflow: visible;
	}
	#SharpStyles_18_pcLine_43_d {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SharpStyles_18_pcLine_43_d {
		overflow: visible;
		position: absolute;
		width: 40px;
		height: 1px;
		left: 0px;
		top: 3.535px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_pcRectangle_726_d {
		fill: rgba(255,44,44,1);
	}
	.SharpStyles_18_pcRectangle_726_d {
		width: 5px;
		height: 5px;
		position: absolute;
		overflow: visible;
		transform: translate(-670.5px, -47.965px) matrix(1,0,0,1,708.1637,49.0004) rotate(45deg);
		transform-origin: center;
	}
	#SharpStyles_18_pcGroup_15017 {
		position: absolute;
		width: 43.699px;
		height: 7.071px;
		left: 132.235px;
		top: 0.129px;
		overflow: visible;
	}
	#SharpStyles_18_pcLine_43_ea {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SharpStyles_18_pcLine_43_ea {
		overflow: visible;
		position: absolute;
		width: 40px;
		height: 1px;
		left: 0px;
		top: 3.535px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_pcRectangle_726_ea {
		fill: rgba(255,255,17,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SharpStyles_18_pcRectangle_726_ea {
		width: 5px;
		height: 5px;
		position: absolute;
		overflow: visible;
		transform: translate(-670.5px, -47.965px) matrix(1,0,0,1,708.1637,49.0004) rotate(45deg);
		transform-origin: center;
	}
	#SharpStyles_18_pcOur_Services {
		left: 592px;
		top: 2465px;
		position: absolute;
		overflow: visible;
		width: 183px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcScroll_my_services_pc_6_wev {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 375px;
		/* left: 32px; */
		top: 2556px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#SharpStyles_18_pcScroll_my_services_pc_6_wev::-webkit-scrollbar{
		display: none;
	}
	#SharpStyles_18_pcour_servicces_card_1_website_6 {
		position: relative;
		width: 233px;
		height: 325px;
		left: 22px;
		top: 17px;
		margin-right: 30px;
		overflow: visible;
	}
	#SharpStyles_18_pcservices_card_1_bdr_website_6_ {
		fill: rgba(255,255,255,1);
	}
	.SharpStyles_18_pcservices_card_1_bdr_website_6_ {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 251px;
		height: 343px;
		left: 0px;
		top: 0px;
	}
	#SharpStyles_18_pcimg_of_services_website_6_pc {
		position: absolute;
		width: 201px;
		height: 265px;
		left: 16px;
		top: 46px;
		overflow: visible;
	}
	#SharpStyles_18_pccircle_deign_shadow_space_webs {
		fill: rgba(23,18,18,1);
	}
	.SharpStyles_18_pccircle_deign_shadow_space_webs {
		position: absolute;
		overflow: visible;
		width: 10px;
		height: 10px;
		left: 16px;
		top: 20px;
	}
	#SharpStyles_18_pcMy_Offerings_website_6_pc {
		left: 31px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 187px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,0.89);
	}
	#SharpStyles_18_pcGallery {
		left: 632px;
		top: 2981px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcGroup_15019 {
		position: absolute;
		width: 720px;
		height: 686px;
		left: 323px;
		top: 3115px;
		overflow: visible;
	}
	#SharpStyles_18_pcobi-pixel7propix--sRVfY0f2d8-u {
		position: absolute;
		width: 230px;
		height: 315px;
		left: 245px;
		top: 45px;
		overflow: visible;
	}
	#SharpStyles_18_pcallef-vinicius-IvQeAVeJULw-uns {
		position: absolute;
		width: 230px;
		height: 315px;
		left: 490px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcarthur-humeau-Twd3yaqA2NM-unsp {
		position: absolute;
		width: 230px;
		height: 315px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcagustin-fernandez-Znyjl9pbaUs-_en {
		position: absolute;
		width: 230px;
		height: 315px;
		left: 245px;
		top: 371px;
		overflow: visible;
	}
	#SharpStyles_18_pcdelfina-pan-wJoB8D3hnzc-unspla {
		position: absolute;
		width: 230px;
		height: 315px;
		left: 490px;
		top: 326px;
		overflow: visible;
	}
	#SharpStyles_18_pcnathon-oski-fE42nRlBcG8-unspla {
		position: absolute;
		width: 230px;
		height: 315px;
		left: 0px;
		top: 326px;
		overflow: visible;
	}
	#SharpStyles_18_pcGroup_15024 {
		position: absolute;
		width: 1269px;
		height: 435.5px;
		left: 48.5px;
		top: 3893.5px;
		overflow: visible;
	}
	#SharpStyles_18_pcLine_28 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SharpStyles_18_pcLine_28 {
		overflow: visible;
		position: absolute;
		width: 1269px;
		height: 2px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_pcsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 742.5px;
		top: 328.5px;
		overflow: visible;
	}
	#SharpStyles_18_pclinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.SharpStyles_18_pcmed_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#SharpStyles_18_pcmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.SharpStyles_18_pcmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#SharpStyles_18_pclinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.191px;
		overflow: visible;
	}
	#SharpStyles_18_pcn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.SharpStyles_18_pcn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_pclinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.SharpStyles_18_pclinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_pctop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.SharpStyles_18_pctop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SharpStyles_18_pcInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.SharpStyles_18_pcshadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#SharpStyles_18_pcshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.SharpStyles_18_pcshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#SharpStyles_18_pcinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#SharpStyles_18_pcwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SharpStyles_18_pcmed_shadow_space_website_7_e:hover {
		fill: rgba(50,172,158,1);
	}
	.SharpStyles_18_pcmed_shadow_space_website_7_e:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#SharpStyles_18_pcmed_shadow_space_website_7_e {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.SharpStyles_18_pcmed_shadow_space_website_7_e {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#SharpStyles_18_pcwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#SharpStyles_18_pcn_234567890_mobile_number_shad {
		left: 777.5px;
		top: 69.5px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcaffanghanioperatelive_email_sh {
		left: 782.5px;
		top: 129.5px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcaddress_med_shadow_space_websi {
		left: 777.5px;
		top: 188.5px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pclocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 742.5px;
		top: 188.5px;
		overflow: visible;
	}
	#SharpStyles_18_pcemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 742.5px;
		top: 128.5px;
		overflow: visible;
	}
	#SharpStyles_18_pcphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 742.5px;
		top: 68.5px;
		overflow: visible;
	}
	#SharpStyles_18_pcn_Copyright_Operate_Live_OPC_P {
		left: 742.5px;
		top: 413.5px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(173,173,173,1);
	}
	#SharpStyles_18_pcVisit_Us_and_Get_the_Stylist_H {
		left: 77.5px;
		top: 42.5px;
		position: absolute;
		overflow: visible;
		width: 530px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#SharpStyles_18_pcRectangle_735 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SharpStyles_18_pcRectangle_735 {
		position: absolute;
		overflow: visible;
		width: 586px;
		height: 337px;
		left: 48.5px;
		top: 98.5px;
		border-radius: 20px;
	}