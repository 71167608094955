.mediaViewInfo {
    --web-view-name: event feed mob;
    --web-view-id: event_feed_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: event_feed_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#event_feed_mob {
    position: relative;
    width: 100%;
    height: 399px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: event feed mob;
    --web-view-id: event_feed_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Events_Feed_lbl_profile_page_m {
    left: 42px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 84px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#event_feed_scroll_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 371px;
    left: 0px;
    top: 28px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: auto;
    align-content: flex-start;
}
#event_feed_grp_feed_mob_eventz {
    position: relative;
    width: 100%;
    height: 100px;
    /* left: 22px; */
    margin-bottom: 10px;
    top: 11px;
    overflow: visible;
}
#bdr_card_1_feed_mob_eventz {
    fill: rgba(255,255,255,1);
}
.bdr_card_1_feed_mob_eventz {
    filter: drop-shadow(5px 5px 2px rgba(177, 224, 201, 1));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 108px;
    left: 0px;
    top: 0px;
}
#img_card_feed_mob_eventz {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 16px;
    top: 15px;
    overflow: visible;
}
#feed_mob_eventz_name_lbl {
    left: 111px;
    top: 15px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#Eventsfeed_mob_eventz_profile_ {
    left: 111px;
    top: 55px;
    position: absolute;
    overflow: visible;
    width: 219px;
    height: 40px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#Category_-_Education_lbl_feed_ {
    left: 111px;
    top: 34px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#n_50000_lbl_feed_mob_eventz {
    left: 317px;
    top: 15px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}