	.REQM_mediaViewInfo {
		--web-view-name: req mob;
		--web-view-id: req_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: req_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
    #add_req_mob {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 25px;
        top: 70px;
        overflow: visible;
    }
	#REQM_req_mob {
		position: absolute;
        width: 100%;
        height: calc(100% - 185px);
        left: 0px;
        top: 120px;
		background-color: rgba(255,255,255,1);
        overflow-x: hidden;
        overflow-y: auto;
		--web-view-name: req mob;
		--web-view-id: req_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
    #REQM_req_mob::-webkit-scrollbar {
      display: none;
    }

	#REQM_Group_128 {
		position: absolute;
		width: 100%;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#REQM_desgin_3_com_off {
		fill: rgba(248,248,248,1);
	}
	.REQM_desgin_3_com_off {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 207px;
		left: 0px;
		top: 0px;
	}
	#REQM_recently_added_com_off {
		/* left: 98px; */
		top: 36px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: uppercase;
	}
	#REQM_some_offerings_com_of {
		/* left: 93px; */
		top: 18px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(48,47,47,1);
		letter-spacing: -0.2222222137451172px;
		text-transform: uppercase;
	}
	#REQM_Scroll_Group_6 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 231px;
		left: 0px;
		top: 71px;
        display: flex;
		overflow-x: scroll;
		overflow-y: hidden;
	}

    #REQM_Scroll_Group_6::-webkit-scrollbar {
        display: none;
    }

	
	#REQM_Group_127 {
		position: absolute;
		width: 100%;
		height: 99px;
		left: 0px;
		top: 331px;
		overflow: visible;
	}
	#REQM_desgin_2_ocom_off {
		position: absolute;
		width: 100%;
		height: 99px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#REQM_design_1_com_of {
		fill: rgba(0,0,0,0.569);
	}
	.REQM_design_1_com_of {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 99px;
		left: 0px;
		top: 0px;
	}
	#REQM_And_tfreqeventcom_off {
		/* left: 20px; */
		top: 36px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
	
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#REQM_trding_this_week_eqnt_com_off {
		/* left: 113px; */
		top: 467px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: uppercase;
	}
	#REQM_top_product_oent_com_off {
		/* left: 147px; */
		top: 449px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(48,47,47,1);
		letter-spacing: -0.2222222137451172px;
		text-transform: uppercase;
	}
	#REQM_Scroll_Group_7 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 499px;
        display: flex;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#REQM_n__card_grp_com_off {
		position: relative;
		width: 339px;
		height: 888px;
		left: calc((50% - 160px)/2);
		top: 7px;
		overflow: visible;
	}
	