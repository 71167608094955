	.mediaViewInfo {
		--web-view-name: Fashion Finds;
		--web-view-id: Fashion_Finds;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Fashion_Finds;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none
	}
	#Fashion_Finds {
		position: absolute;
		width: 1366px;
		height: 4075px;
        left:calc((100vw - 1366px)/2);
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Fashion Finds;
		--web-view-id: Fashion_Finds;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#footer_grp_web_9_pc {
		position: absolute;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 3664px;
		overflow: visible;
	}
	#socials_grp_med_web_9_pc {
		position: absolute;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#footer_web9pc {
		opacity: 0.93;
		fill: rgba(50,50,50,1);
	}
	.footer_web9pc {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 0px;
	}
	#n_Copyright_Operate_Live_websi_9_web_pc {
		left: 41.5px;
		top: 349.16px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#linked_in_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 202px;
		top: 262px;
		overflow: visible;
	}
	#Ellipse_5_med_web_9_pc:hover {
		fill: rgba(120,120,255,1);
	}
	.Ellipse_5_med_web_9_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_5_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_5_med_web_9_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_website_9_layout {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#n_website_2_layout_web_9_pc {
		fill: rgba(63,61,86,1);
	}
	.n_website_2_layout_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.953px;
		transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_down_web_9_pc {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_down_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.037px;
		left: 0.614px;
		top: 7.098px;
		transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_website_9_layout {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_website_9_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.217px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 122px;
		top: 262px;
		overflow: visible;
	}
	#Ellipse_4_med_web_9_pc:hover {
		fill: rgba(255,120,120,1);
	}
	.Ellipse_4_med_web_9_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_4_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_4_med_web_9_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagram_website_9_layout {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.125px;
		overflow: visible;
	}
	#whatsapp_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 42px;
		top: 262px;
		overflow: visible;
	}
	#Ellipse_3_med_web_9_pc:hover {
		fill: rgba(50,172,158,1);
	}
	.Ellipse_3_med_web_9_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_3_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_3_med_web_9_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_website9_layout {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#For_Queries_infoandroom_web_9_ {
		left: 68.526px;
		top: 132px;
		position: absolute;
		overflow: visible;
		width: 219px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#For_call__920012888_web_9_pc {
		left: 68.526px;
		top: 153px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#telephone_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.telephone_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.521px;
		left: 42px;
		top: 157.525px;
		transform: matrix(1,0,0,1,0,0);
	}
	#send_1_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.send_1_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.521px;
		left: 42px;
		top: 136.719px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Line_web_9_pc {
		fill: transparent;
		stroke: rgba(86,86,86,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 1365.56px;
		height: 2px;
		left: 0.155px;
		top: 336.113px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_metro-locatio_web_9_pc_n {
		fill: rgba(255,255,255,1);
	}
	.Icon_metro-locatio_web_9_pc_n {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 18.209px;
		left: 42px;
		top: 178.33px;
		transform: matrix(1,0,0,1,0,0);
	}
	#address_lbl_web_9_pc {
		left: 68.526px;
		top: 177.539px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 62px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#background_img_web_9_pc {
		position: absolute;
		width: 566px;
		height: 365px;
		left: 780px;
		top: 26px;
		overflow: visible;
	}
	#display_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.display_web_9_pc {
		position: absolute;
		overflow: visible;
		width: 522px;
		height: 235px;
		left: 801px;
		top: 136px;
	}
	#Group_14856_web_9_pc {
		position: absolute;
		width: 1366px;
		height: 771px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_0230728085642_fpdlin_colorfu {
		position: absolute;
		width: 1366px;
		height: 766px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Rectangle_681 {
		opacity: 0.18;
		fill: rgba(0,0,0,1);
	}
	.Rectangle_681 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 771px;
		left: 0px;
		top: 0px;
	}
	#pfp_pic_web_9_pc {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 115px;
		top: 19px;
		overflow: visible;
	}
	#bdr_pfp_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.bdr_pfp_web_9_pc {
		position: absolute;
		overflow: visible;
		width: 70px;
		height: 70px;
		left: 0px;
		top: 0px;
	}
	#zane-lee-_web_9_pc {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 5px;
		top: 5px;
		border-radius: 50%;
		overflow: hidden;
	}
	#ACT_NOW__SEIZE_THE_DEAL_lbl_we {
		left: 120px;
		top: 240px;
		position: absolute;
		overflow: visible;
		width: 525px;
		white-space: nowrap;
		line-height: 83px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 75px;
		color: rgba(255,255,255,1);
	}
	#Sell_btn_web_9_pc {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 162px;
		top: 460.002px;
		overflow: visible;
	}
	#start_bidding_web_9_pc {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 1.5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.start_bidding_web_9_pc {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: -0.002px;
	}
	#START_BIDDING_web_9_pc_c {
		left: 28px;
		top: 10.998px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#vision_grp_web_9_pc {
		position: absolute;
		width: 1045px;
		height: 489px;
		left: 171px;
		top: 980px;
		overflow: visible;
	}
	#Scroll_Groupvision_web_9_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 550px;
		height: 287px;
		left: 495px;
		top: 150px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_Groupvision_web_9_pc::-webkit-scrollbar{
		display: none;
	}
	#BinQasem_provides_a_web_9_pc {
		left: 15px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 520px;
		height: 293px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#VISIon_ {
		left: 510px;
		top: 57px;
		position: absolute;
		overflow: visible;
		width: 148px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 46px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#Mask_Group_web_9_pc {
		position: absolute;
		width: 427px;
		height: 489px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#mission_grp_web_9_pc {
		position: absolute;
		width: 1029px;
		height: 489px;
		left: 171px;
		top: 1554px;
		overflow: visible;
	}
	#Scroll_Group_misiinweb_9_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 531px;
		height: 292px;
		left: 0px;
		top: 145px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_Group_misiinweb_9_pc::-webkit-scrollbar{
		display: none;
	}
	#BinQasem_provide_mission_web_9 {
		left: 0px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 520px;
		height: 293px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#Mission_lbl_web_9_pc {
		left: 0px;
		top: 57px;
		position: absolute;
		overflow: visible;
		width: 185px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 46px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#Mask_Group_web_9_pc_dj {
		position: absolute;
		width: 427px;
		height: 489px;
		left: 602px;
		top: 0px;
		overflow: visible;
	}
	#principle_group_web_9_pc {
		position: absolute;
		width: 1039px;
		height: 489px;
		left: 171px;
		top: 2128px;
		overflow: visible;
	}
	#Scroll_Group_prini_web_9_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 542px;
		height: 293px;
		left: 497px;
		top: 144px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_Group_prini_web_9_pc::-webkit-scrollbar{
		display: none;
	}
	#BinQasem_provi_web_9_pc {
		left: 13px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 520px;
		height: 293px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#PRINCIPLE_web_9_pc {
		left: 510px;
		top: 57px;
		position: absolute;
		overflow: visible;
		width: 212px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 46px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#Mask_Group_web_9_pc_do {
		position: absolute;
		width: 427px;
		height: 489px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Category_Section_web_9_pc {
		position: absolute;
		width: 1369.5px;
		height: 106px;
		left: 8px;
		top: 2813px;
		overflow: visible;
	}
	#Line_27 {
		fill: transparent;
		stroke: rgba(150,111,51,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 10;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_27 {
		overflow: visible;
		position: absolute;
		width: 1348.008px;
		height: 7px;
		left: 21.5px;
		top: 91.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Ellipse_44 {
		fill: rgba(150,111,51,1);
	}
	.Ellipse_44 {
		position: absolute;
		overflow: visible;
		width: 27px;
		height: 27px;
		left: 0px;
		top: 79px;
	}
	#our_services {
		left: 526px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 316px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 46px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#Scroll_our_services_web_9_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 1315px;
		height: 566px;
		left: 51px;
		top: 2882px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#hanger_cloth_grp_web_9_pc {
		position: relative;
		width: 325px;
		height: 519px;
		left: 29px;
		top: 0px;
		margin-right: 15px;
		overflow: visible;
	}
	#hanger_our_services_web_9_pc {
		position: absolute;
		width: 325px;
		height: 325px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Mask_Groupoff_img_web_9_pc {
		position: absolute;
		width: 307px;
		height: 366px;
		left: 9px;
		top: 153px;
		overflow: visible;
	}
	#Bunda_Clothes_lbl_web_9_pc {
		left: 14px;
		top: 463px;
		position: absolute;
		overflow: visible;
		width: 123px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
		text-transform: uppercase;
	}
	#bdr_for_bid_web_9_pc {
		fill: rgba(49,49,49,1);
	}
	.bdr_for_bid_web_9_pc {
		position: absolute;
		overflow: visible;
		width: 68px;
		height: 70px;
		left: 248px;
		top: 449px;
	}
	#law_6_bid_path_web_9_pc {
		position: absolute;
		width: 18.413px;
		height: 19.599px;
		left: 272.578px;
		top: 474.455px;
		overflow: visible;
	}
	#Path_6945_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.Path_6945_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 12.47px;
		height: 13.84px;
		left: 1.082px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_6946_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.Path_6946_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 8.859px;
		height: 7.48px;
		left: 9.554px;
		top: 7.797px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_6947_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.Path_6947_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 12.632px;
		height: 4.594px;
		left: 0px;
		top: 15.006px;
		transform: matrix(1,0,0,1,0,0);
	}