	.mediaViewInfo {
		--web-view-name: RealtyScape;
		--web-view-id: RealtyScape;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: RealtyScape;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#RealtyScape {
		position: absolute;
		width: 1366px;
		height: 5325px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: RealtyScape;
		--web-view-id: RealtyScape;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#m19_pc_RSWhat_do_you_want_to_your__Drea {
		filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 494px;
		top: 912.001px;
		position: absolute;
		overflow: visible;
		width: 409px;
		white-space: nowrap;
		text-align: center;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(20,57,91,1);
	}
	#m19_pc_RSGroup_9691 {
		position: absolute;
		width: 13.622px;
		height: 10.84px;
		left: 287.366px;
		top: 1279.346px;
		overflow: visible;
	}
	#m19_pc_RScheck {
		position: absolute;
		width: 13.622px;
		height: 10.84px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSPath_2923 {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSPath_2923 {
		overflow: visible;
		position: absolute;
		width: 13.623px;
		height: 10.84px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#m19_pc_RSGroup_15032 {
		position: absolute;
		width: 1300px;
		height: 691px;
		left: 33px;
		top: 4012px;
		overflow: visible;
	}
	#m19_pc_RSScroll_Group_60 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 572.001px;
		left: 0px;
		top: 118.998px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#m19_pc_RSGroup_15031 {
		position: relative;
		width: 501px;
		height: 530px;
		left: 20px;
		top: 18px;
		margin-right: 30px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_112 {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSRectangle_112 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 519px;
		height: 548px;
		left: 0px;
		top: 0px;
	}
	#m19_pc_RSMask_Group_4 {
		position: absolute;
		width: 460px;
		height: 277px;
		left: 21px;
		top: 21px;
		overflow: visible;
	}
	#m19_pc_RSn_500 {
		left: 21px;
		top: 345px;
		position: absolute;
		overflow: visible;
		width: 80px;
		white-space: nowrap;
		text-align: left;
		font-family: Sen;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(30,98,232,1);
	}
	#m19_pc_RSApartment_London {
		left: 19px;
		top: 382px;
		position: absolute;
		overflow: visible;
		width: 198px;
		white-space: nowrap;
		text-align: left;
		font-family: Sen;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#m19_pc_RSScroll_Group_59 {
		mix-blend-mode: normal;
		position: absolute;
		width: 455px;
		height: 97px;
		left: 9px;
		top: 423px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#m19_pc_RSBeautiful_Huge_1_family_House_ {
		opacity: 0.5;
		left: 10px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 436px;
		height: 44px;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#m19_pc_RSGroup_9694 {
		position: absolute;
		width: 370px;
		height: 26px;
		left: 19px;
		top: 312px;
		overflow: visible;
	}
	#m19_pc_RSBangalore {
		opacity: 0.5;
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 26px;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#m19_pc_RSgeo-alt {
		position: absolute;
		width: 17px;
		height: 22.667px;
		left: 0px;
		top: 2px;
		overflow: visible;
	}
	#m19_pc_RSPath_2927 {
		fill: rgba(254,120,71,1);
	}
	.m19_pc_RSPath_2927 {
		overflow: visible;
		position: absolute;
		width: 17px;
		height: 22.666px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#m19_pc_RSFeatured_Apartment_ {
		filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 488px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 333px;
		white-space: nowrap;
		text-align: center;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(20,57,91,1);
	}
	#m19_pc_RSThe_Most_frequently_searched_a {
		opacity: 0.79;
		left: 283px;
		top: 68px;
		position: absolute;
		overflow: visible;
		width: 742px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#m19_pc_RSGroup_15042 {
		position: absolute;
		width: 285px;
		height: 246px;
		left: 43px;
		top: 1066px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_105 {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSRectangle_105 {
		filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 336px;
		left: 0px;
		top: 0px;
	}
	#m19_pc_RSGroup_9756 {
		position: absolute;
		width: 234px;
		height: 204px;
		left: 26px;
		top: 26px;
		overflow: visible;
	}
	#m19_pc_RStop_ {
		position: absolute;
		width: 234px;
		height: 166px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSDeluxe_Portion {
		opacity: 0.79;
		left: 58px;
		top: 179px;
		position: absolute;
		overflow: visible;
		width: 118px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#m19_pc_RSGroup_9689 {
		position: absolute;
		width: 285px;
		height: 246px;
		left: 375px;
		top: 1066px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_105_dr {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSRectangle_105_dr {
		filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 336px;
		left: 0px;
		top: 0px;
	}
	#m19_pc_RSGroup_9756_ds {
		position: absolute;
		width: 234px;
		height: 204px;
		left: 26px;
		top: 26px;
		overflow: visible;
	}
	#m19_pc_RSfrancesca-tosolini-tHkJAMcO3QE {
		position: absolute;
		width: 234px;
		height: 166px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSDouble_Height {
		opacity: 0.79;
		left: 60px;
		top: 179px;
		position: absolute;
		overflow: visible;
		width: 115px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#m19_pc_RSGroup_9689_dv {
		position: absolute;
		width: 285px;
		height: 246px;
		left: 707px;
		top: 1066px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_105_dw {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSRectangle_105_dw {
		filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 336px;
		left: 0px;
		top: 0px;
	}
	#m19_pc_RSGroup_9756_dx {
		position: absolute;
		width: 234px;
		height: 204px;
		left: 26px;
		top: 26px;
		overflow: visible;
	}
	#m19_pc_RSalice-kang-Bc_uN5PeErA-unsplas {
		position: absolute;
		width: 234px;
		height: 166px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSPenthouse {
		opacity: 0.79;
		left: 73px;
		top: 179px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#m19_pc_RSGroup_9689_d {
		position: absolute;
		width: 285px;
		height: 246px;
		left: 1038px;
		top: 1066px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_105_d {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSRectangle_105_d {
		filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 336px;
		left: 0px;
		top: 0px;
	}
	#m19_pc_RSGroup_9756_d {
		position: absolute;
		width: 234px;
		height: 204px;
		left: 26px;
		top: 26px;
		overflow: visible;
	}
	#m19_pc_RSharry-cunningham-UWblg2i6Blw-u {
		position: absolute;
		width: 234px;
		height: 166px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSThe_Studio {
		opacity: 0.79;
		left: 72px;
		top: 179px;
		position: absolute;
		overflow: visible;
		width: 87px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#m19_pc_RSGroup_15030 {
		position: absolute;
		width: 1200px;
		height: 605px;
		left: 83px;
		top: 1412.001px;
		overflow: visible;
	}
	#m19_pc_RSUnion_2 {
		fill: rgba(34,137,255,1);
	}
	.m19_pc_RSUnion_2 {
		overflow: visible;
		position: absolute;
		width: 207.932px;
		height: 207.932px;
		left: 387.412px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#m19_pc_RSBuy__Dream_Apartment_In_The_Be {
		filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 0px;
		top: 115px;
		position: absolute;
		overflow: visible;
		width: 395px;
		white-space: nowrap;
		line-height: 79px;
		margin-top: -18.5px;
		text-align: left;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 42px;
		color: rgba(20,57,91,1);
	}
	#m19_pc_RSGroup_9721 {
		position: absolute;
		width: 241px;
		height: 77px;
		left: 0px;
		top: 383px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_107 {
		fill: rgba(24,106,165,1);
	}
	.m19_pc_RSRectangle_107 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 259px;
		height: 95px;
		left: 0px;
		top: 0px;
	}
	#m19_pc_RSSee_All_Apartment {
		left: 39px;
		top: 24px;
		position: absolute;
		overflow: visible;
		width: 159px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(255,255,255,1);
	}
	#m19_pc_RSavi-werde-hHz4yrvxwlA-unsplash {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 759px;
		height: 568px;
		left: 441px;
		top: 37px;
		overflow: visible;
	}
	#m19_pc_RSjason-briscoe-UV81E0oXXWQ-unsp {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 376.233px;
		height: 250.571px;
		left: 335.958px;
		top: 334.1px;
		overflow: visible;
	}
	#m19_pc_RSGroup_9722 {
		position: absolute;
		width: 82px;
		height: 82px;
		left: 483px;
		top: 419px;
		overflow: visible;
	}
	#m19_pc_RSEllipse_17 {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSEllipse_17 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 100px;
		height: 100px;
		left: 0px;
		top: 0px;
	}
	#m19_pc_RSPath_2939 {
		fill: rgba(34,137,255,1);
	}
	.m19_pc_RSPath_2939 {
		filter: drop-shadow(0px 3px 6px rgba(34, 137, 255, 0.341));
		overflow: visible;
		position: absolute;
		width: 34.211px;
		height: 41.965px;
		left: 32.695px;
		top: 29.285px;
		transform: matrix(1,0,0,1,0,0);
	}
	#m19_pc_RSGroup_15039 {
		position: absolute;
		width: 1193px;
		height: 1695px;
		left: 87px;
		top: 2117px;
		overflow: visible;
	}
	#m19_pc_RSGroup_15033 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSavi-werde-hHz4yrvxwlA-unsplash_ei {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSGroup_15038 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 600px;
		top: 38px;
		overflow: visible;
	}
	#m19_pc_RSScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#m19_pc_RSIt_is_a_long_established_fact_ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#m19_pc_RSVISION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#m19_pc_RSGroup_15034 {
		position: absolute;
		width: 1193px;
		height: 419.078px;
		left: 0px;
		top: 638px;
		overflow: visible;
	}
	#m19_pc_RSsean-pollock-PhYq704ffdA-unspl {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 560px;
		height: 419.078px;
		left: 633px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSGroup_15037 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 0px;
		top: 39px;
		overflow: visible;
	}
	#m19_pc_RSScroll_Group_28_eq {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#m19_pc_RSIt_is_a_long_established_fact__er {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#m19_pc_RSMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#m19_pc_RSGroup_15035 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 1276px;
		overflow: visible;
	}
	#m19_pc_RSwebaliser-_TPTXZd9mOo-unsplash {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSScroll_Group_28_ev {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 600px;
		top: 103px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#m19_pc_RSIt_is_a_long_established_fact__ew {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#m19_pc_RSPRINCIPLES {
		left: 618px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#m19_pc_RSGroup_15043 {
		position: absolute;
		width: 1456px;
		height: 789px;
		left: -90px;
		top: 22px;
		overflow: visible;
	}
	#m19_pc_RSjoel-filipe-RFDP7_80v5A-unspla {
		position: absolute;
		width: 1041px;
		height: 690px;
		left: 415px;
		top: 99px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_102 {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSRectangle_102 {
		filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		position: absolute;
		overflow: visible;
		width: 721px;
		height: 378px;
		left: 0px;
		top: 180px;
	}
	#m19_pc_RSLets_fullfill___yours_Dream {
		filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 121px;
		top: 200px;
		position: absolute;
		overflow: visible;
		width: 439px;
		white-space: nowrap;
		text-align: left;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 65px;
		color: rgba(34,137,255,1);
	}
	#m19_pc_RSBuilding_your_dream_house {
		left: 133px;
		top: 402px;
		position: absolute;
		overflow: visible;
		width: 266px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(48,48,48,1);
	}
	#m19_pc_RSAnime_Edit {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 434px;
		top: 0px;
		border-radius: 50%;
		overflow: hidden;
	}
	#m19_pc_RSOni_Chan_Haircut {
		left: 529px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 156px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#m19_pc_RSGroup_15041 {
		position: absolute;
		width: 1366px;
		height: 517px;
		left: 0px;
		top: 4813px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_736_e {
		fill: url(#m19_pc_RSRectangle_736_e);
	}
	.m19_pc_RSRectangle_736_e {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 517px;
		left: 0px;
		top: 0px;
	}
	#m19_pc_RSGroup_15040 {
		position: absolute;
		width: 657px;
		height: 438px;
		left: 56px;
		top: 39px;
		overflow: visible;
	}
	#m19_pc_RSkisspng-loft-window-office-roo {
		position: absolute;
		width: 657px;
		height: 438px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.m19_pc_RSRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 492px;
		height: 278px;
		left: 102px;
		top: 61px;
		border-radius: 10px;
	}
	#m19_pc_RSsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 826px;
		top: 355px;
		overflow: visible;
	}
	#m19_pc_RSlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.m19_pc_RSmed_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#m19_pc_RSmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.m19_pc_RSmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#m19_pc_RSlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#m19_pc_RSn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.m19_pc_RSn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#m19_pc_RSlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.m19_pc_RSlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#m19_pc_RStop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.m19_pc_RStop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#m19_pc_RSInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.m19_pc_RSshadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#m19_pc_RSshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.m19_pc_RSshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#m19_pc_RSinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#m19_pc_RSwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#m19_pc_RSmed_shadow_space_website_7_fo:hover {
		fill: rgba(50,172,158,1);
	}
	.m19_pc_RSmed_shadow_space_website_7_fo:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#m19_pc_RSmed_shadow_space_website_7_fo {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.m19_pc_RSmed_shadow_space_website_7_fo {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#m19_pc_RSwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#m19_pc_RSn_234567890_mobile_number_shad {
		left: 861px;
		top: 124px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#m19_pc_RSaffanghanioperatelive_email_sh {
		left: 866px;
		top: 177px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#m19_pc_RSaddress_med_shadow_space_websi {
		left: 861px;
		top: 227px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#m19_pc_RSlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 826px;
		top: 229px;
		overflow: visible;
	}
	#m19_pc_RSemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 826px;
		top: 176px;
		overflow: visible;
	}
	#m19_pc_RSphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 826px;
		top: 123px;
		overflow: visible;
	}
	#m19_pc_RSn_Copyright_Operate_Live_OPC_P {
		left: 826px;
		top: 451px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}