	.SuggusMbmediaViewInfo {
		--web-view-name: suggestions mob;
		--web-view-id: suggestions_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: suggestions_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#SuggusMbsuggestions_mob {
		position: absolute;
        width: 100%;
        height:calc(100% - 105px);
        left: 0px;
        top: 45px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: suggestions mob;
		--web-view-id: suggestions_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#SuggusMbdesgin_con_page_new_pc {
		fill: rgba(248,248,248,1);
	}
	.SuggusMbdesgin_con_page_new_pc {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 203px;
		left: 0px;
		top: 0px;
	}
	#SuggusMbScroll_Group_10 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 100%;
		left: calc((100% - 375px)/2);
		top: 0px;
		display: flex;
        flex-wrap: wrap;
        flex-direction: row;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SuggusMbcard_1_suggestion_page_new {
		position: relative;
		width: 165px;
		height: 285px;
		/* left: 15px; */
        margin: 5px;
		top: 16px;
		overflow: visible;
	}
	#SuggusMbbdr_card_1_sug_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.SuggusMbbdr_card_1_sug_sug_page_pc_new {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 173px;
		height: 293px;
		left: 0px;
		top: 0px;
	}
	#SuggusMbAnime_Edit_sug_page_pc_new {
		position: absolute;
		width: 134px;
		height: 127px;
		left: 16px;
		top: 31px;
		overflow: visible;
	}
	#SuggusMbjob_work_new_layout {
		position: absolute;
		width: 151px;
		height: 23px;
		left: 11px;
		top: 162px;
		overflow: visible;
	}
	#SuggusMbjob-seeker_sug_page_pc_new {
		position: absolute;
		width: 22px;
		height: 23px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SuggusMbrecently_affreqevent_sug_page_ {
		left: 26px;
		top: 3px;
		position: absolute;
		overflow: hidden;
		width: 126px;
		height: 20px;
	
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: capitalize;
	}
	#SuggusMbMohammed_Affan_sug_page_pc_new {
		left: 1px;
		top: 7px;
		position: absolute;
		overflow: hidden;
		width: 165px;
		height: 20px;
		/* line-height: 49px;
		margin-top: -17px; */
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
	}
	#SuggusMbfollow_grp_sug_page_pc_new {
		position: absolute;
		width: 34px;
		height: 34px;
		left: 46px;
		top: 245px;
		overflow: visible;
	}
	#SuggusMbbdr_gollow_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.SuggusMbbdr_gollow_sug_page_pc_new {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#SuggusMbfolow_img_sug_page_pc_new {
		position: absolute;
		width: 22px;
		height: 22px;
		left: 7px;
		top: 6px;
		overflow: visible;
	}
	#SuggusMbconnect_grp_sug_page_pc_new {
		position: absolute;
		width: 34px;
		height: 34px;
		left: 7px;
		top: 245px;
		overflow: visible;
	}
	#SuggusMbcon_bdr_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.SuggusMbcon_bdr_sug_page_pc_new {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#SuggusMbconn_n_sug_page_pc_new {
		position: absolute;
		width: 23px;
		height: 22px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#SuggusMbignore_grp_sug_page_pc_new {
		position: absolute;
		width: 34px;
		height: 34px;
		left: 86px;
		top: 245px;
		overflow: visible;
	}
	#SuggusMbbfdr_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.SuggusMbbfdr_sug_page_pc_new {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#SuggusMbignore_sig_sug_page_pc_new {
		position: absolute;
		width: 22px;
		height: 22px;
		left: 6px;
		top: 7px;
		overflow: visible;
	}
	#SuggusMbblock_grp_sug_page_pc_new {
		position: absolute;
		width: 34px;
		height: 34px;
		left: 125px;
		top: 245px;
		overflow: visible;
	}
	#SuggusMbbdr_block_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.SuggusMbbdr_block_sug_page_pc_new {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#SuggusMbboock_img_sug_page_pc_new {
		position: absolute;
		width: 23px;
		height: 22px;
		left: 6px;
		top: 7px;
		overflow: visible;
	}
	#SuggusMbscroll_and_intro_detail_new {
		position: absolute;
		width: 145px;
		height: 55px;
		left: 11px;
		top: 189px;
		overflow: visible;
	}
	#SuggusMbpresentation_sug_page_pc_new {
		position: absolute;
		width: 22px;
		height: 23px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SuggusMbScroll_intro_pc_sug_page {
		mix-blend-mode: normal;
		position: absolute;
		width: 119px;
		height: 54px;
		left: 26px;
		top: 1px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#SuggusMbrecently_affreqevent_com_req_p {
		left: 0px;
		top: -1px;
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 100%;
		
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		text-transform: capitalize;
	}
