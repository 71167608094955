
	.mediaViewInfo {
		--web-view-name: ShoeStride;
		--web-view-id: ShoeStride;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ShoeStride;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ShoeStride {
		position: absolute;
		width: 1366px;
		height: 3478px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: ShoeStride;
		--web-view-id: ShoeStride;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#SS_31_PCGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 472.998px;
		left: 95.5px;
		top: 2998px;
		overflow: visible;
	}
	#SS_31_PCGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 99.997px;
		overflow: visible;
	}
	#SS_31_PCGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_PCRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.SS_31_PCRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#SS_31_PCsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#SS_31_PClinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_PCmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.SS_31_PCmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PClinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#SS_31_PCn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.SS_31_PCn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SS_31_PClinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.SS_31_PClinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SS_31_PCtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.SS_31_PCtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SS_31_PCInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_PCshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.SS_31_PCshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PCinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#SS_31_PCwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_PCmed_shadow_space_website_7_bf {
		fill: rgba(255,255,255,1);
	}
	.SS_31_PCmed_shadow_space_website_7_bf {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PCwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#SS_31_PCn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SS_31_PCaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SS_31_PCaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SS_31_PClocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#SS_31_PCemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#SS_31_PCphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#SS_31_PCn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#SS_31_PCContact_Us {
		left: 510.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#SS_31_PCGroup_15140 {
		position: absolute;
		width: 1366px;
		height: 690px;
		left: 0px;
		top: 37px;
		overflow: visible;
	}
	#SS_31_PCEllipse_44 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SS_31_PCEllipse_44 {
		position: absolute;
		overflow: visible;
		width: 64px;
		height: 1px;
		left: 1153px;
		top: 249px;
	}
	#SS_31_PCYour_Name {
		left: 66px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 235px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SS_31_PCDISCOVER_LIMITED_SHOES__WITHOU {
		left: 69px;
		top: 87px;
		position: absolute;
		overflow: visible;
		width: 1244px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 90px;
		color: rgba(0,0,0,1);
	}
	#SS_31_PCthe-dk-photography-b9e8ffVw5XY {
		position: absolute;
		width: 341px;
		height: 342px;
		left: 342px;
		top: 348px;
		overflow: visible;
	}
	#SS_31_PCthibaut-burckel-n2V5MLDPE-k-un {
		position: absolute;
		width: 342px;
		height: 342px;
		left: 0px;
		top: 348px;
		overflow: visible;
	}
	#SS_31_PCthe-dk-photography-A8HaTLON5Gg {
		position: absolute;
		width: 342px;
		height: 342px;
		left: 683px;
		top: 348px;
		overflow: visible;
	}
	#SS_31_PCkyle-austin-vHIv0AreyDk-unspla {
		position: absolute;
		width: 341px;
		height: 342px;
		left: 1025px;
		top: 348px;
		overflow: visible;
	}
	#SS_31_PCGroup_15139 {
		position: absolute;
		width: 1366px;
		height: 157px;
		left: 0px;
		top: 767px;
		overflow: visible;
	}
	#SS_31_PCRectangle_752 {
		fill: rgba(5,5,5,1);
	}
	.SS_31_PCRectangle_752 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 157px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PCLorem_Ipsum_is_simply_dummy_te {
		left: 79px;
		top: 22px;
		position: absolute;
		overflow: visible;
		width: 1210px;
		height: 114px;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SS_31_PCGroup_15151 {
		position: absolute;
		width: 915px;
		height: 1248px;
		left: 226px;
		top: 1044px;
		overflow: visible;
	}
	#SS_31_PCGroup_15141 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_PCRectangle_752_b {
		fill: rgba(5,5,5,1);
	}
	.SS_31_PCRectangle_752_b {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PCmarten-bjork-_ccRYJWcNwk-unspl {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#SS_31_PCScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SS_31_PCIt_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SS_31_PCVISION {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SS_31_PCGroup_15142 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 436px;
		overflow: visible;
	}
	#SS_31_PCRectangle_752_ca {
		fill: rgba(5,5,5,1);
	}
	.SS_31_PCRectangle_752_ca {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PCxavier-teo-SxAXphIPWeg-unsplas {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 609px;
		top: 33px;
		overflow: visible;
	}
	#SS_31_PCScroll_Group_28_ca {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 107px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SS_31_PCScroll_Group_28_ca::-webkit-scrollbar{
		display: none;
	}
	#SS_31_PCIt_is_a_long_established_fact__cb {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SS_31_PCMISSION {
		left: 109px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SS_31_PCGroup_15143 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 872px;
		overflow: visible;
	}
	#SS_31_PCRectangle_752_ce {
		fill: rgba(5,5,5,1);
	}
	.SS_31_PCRectangle_752_ce {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PChanne-van-oeckel-4R2tRB7R5uQ-u {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#SS_31_PCScroll_Group_28_cg {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SS_31_PCScroll_Group_28_cg::-webkit-scrollbar{
		display: none;
	}
	#SS_31_PCScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#SS_31_PCIt_is_a_long_established_fact__ch {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SS_31_PCPRINCIPLES {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SS_31_PCGroup_15150 {
		position: absolute;
		width: 1286px;
		height: 457px;
		left: 40px;
		top: 2441px;
		overflow: visible;
	}
	#SS_31_PCNEW_EDITION {
		left: 65px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 244px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(0,0,0,1);
	}
	#SS_31_PCGroup_15145 {
		position: absolute;
		width: 185px;
		height: 61px;
		left: 1009px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_PCRectangle_753 {
		fill: rgba(5,5,5,1);
	}
	.SS_31_PCRectangle_753 {
		position: absolute;
		overflow: visible;
		width: 185px;
		height: 61px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PCView_More {
		left: 46px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SS_31_PCScroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1286px;
		height: 396px;
		left: 0px;
		top: 61px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#SS_31_PCScroll_Group_91::-webkit-scrollbar{
		display: none;
	}
	#SS_31_PCGroup_15144 {
		position: relative;
        margin-right: 30px;
		width: 243px;
		height: 331px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#SS_31_PCRectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.SS_31_PCRectangle_754 {
		filter: drop-shadow(5px 5px 1px rgba(235, 99, 99, 0.518));
		position: absolute;
		overflow: visible;
		width: 249.5px;
		height: 337.5px;
		left: 0px;
		top: 0px;
	}
	#SS_31_PCthibaut-burckel-n2V5MLDPE-k-un_cr {
		position: absolute;
		width: 239px;
		height: 255px;
		left: 2px;
		top: 38px;
		overflow: visible;
	}
	#SS_31_PCTitle {
		left: 7px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SS_31_PCn_10000 {
		left: 7px;
		top: 299px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}