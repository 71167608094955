
	.mediaViewInfo {
		--web-view-name: SoleCrafted Mob;
		--web-view-id: SoleCrafted_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: SoleCrafted_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#SoleCrafted_Mob {
		position: absolute;
		width: 375px;
		height: 3909px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: SoleCrafted Mob;
		--web-view-id: SoleCrafted_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#SCM_32_MoBGroup_15140 {
		position: absolute;
		width: 375px;
		height: 697px;
		left: 0px;
		top: 39px;
		overflow: visible;
	}
	#SCM_32_MoBYour_Name {
		left: 17px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 235px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBGroup_15152 {
		position: absolute;
		width: 375px;
		height: 376px;
		left: 0px;
		top: 321px;
		overflow: visible;
	}
	#SCM_32_MoBnoah-smith-R4xOiCIeOUs-unsplas {
		position: absolute;
		width: 187px;
		height: 187px;
		left: 188px;
		top: 0px;
		overflow: visible;
	}
	#SCM_32_MoBalexandr-sadkov-BnG4KWAzt9c-un {
		position: absolute;
		width: 188px;
		height: 188px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SCM_32_MoBlea-ochel-nsRBbE6-YLs-unsplash {
		position: absolute;
		width: 188px;
		height: 188px;
		left: 0px;
		top: 188px;
		overflow: visible;
	}
	#SCM_32_MoBrajesh-rajput-NqZ2vFqChaw-unsp {
		position: absolute;
		width: 187px;
		height: 187px;
		left: 188px;
		top: 188px;
		overflow: visible;
	}
	#SCM_32_MoBDiscover_Timeless__Craftsmansh {
		left: 16px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 344px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 28px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBGroup_15155 {
		position: absolute;
		width: 340px;
		height: 582px;
		left: 19px;
		top: 836px;
		overflow: visible;
	}
	#SCM_32_MoBRectangle_752 {
		fill: rgba(161,141,87,1);
	}
	.SCM_32_MoBRectangle_752 {
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 582px;
		left: 0px;
		top: 0px;
	}
	#SCM_32_MoBty-feague-87V27nw0sS0-unsplash {
		position: absolute;
		width: 274px;
		height: 311px;
		left: 33px;
		top: 33px;
		overflow: visible;
	}
	#SCM_32_MoBScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 326px;
		height: 154px;
		left: 14px;
		top: 409px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SCM_32_MoBIt_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 312px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SCM_32_MoBVISION {
		left: 14px;
		top: 367px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -12px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#SCM_32_MoBGroup_15154 {
		position: absolute;
		width: 340px;
		height: 582px;
		left: 16px;
		top: 1468px;
		overflow: visible;
	}
	#SCM_32_MoBRectangle_752_bf {
		fill: rgba(161,141,87,1);
	}
	.SCM_32_MoBRectangle_752_bf {
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 582px;
		left: 0px;
		top: 0px;
	}
	#SCM_32_MoBprateek-chawla-87luO7iL1mM-uns {
		position: absolute;
		width: 274px;
		height: 311px;
		left: 33px;
		top: 33px;
		overflow: visible;
	}
	#SCM_32_MoBScroll_Group_28_bh {
		mix-blend-mode: normal;
		position: absolute;
		width: 326px;
		height: 154px;
		left: 14px;
		top: 409px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SCM_32_MoBIt_is_a_long_established_fact__bi {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 312px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SCM_32_MoBMISSION {
		left: 19px;
		top: 367px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -12px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#SCM_32_MoBGroup_15153 {
		position: absolute;
		width: 340px;
		height: 582px;
		left: 16px;
		top: 2100px;
		overflow: visible;
	}
	#SCM_32_MoBRectangle_752_bl {
		fill: rgba(161,141,87,1);
	}
	.SCM_32_MoBRectangle_752_bl {
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 582px;
		left: 0px;
		top: 0px;
	}
	#SCM_32_MoBnoah-smith-1z2hBTKHdWI-unsplas {
		position: absolute;
		width: 274px;
		height: 311px;
		left: 33px;
		top: 33px;
		overflow: visible;
	}
	#SCM_32_MoBScroll_Group_28_bn {
		mix-blend-mode: normal;
		position: absolute;
		width: 326px;
		height: 154px;
		left: 14px;
		top: 409px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SCM_32_MoBIt_is_a_long_established_fact__bo {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 312px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SCM_32_MoBPRINCIPLES {
		left: 18px;
		top: 367px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -12px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#SCM_32_MoBGroup_15149 {
		position: absolute;
		width: 336px;
		height: 38px;
		left: 21px;
		top: 2782px;
		overflow: visible;
	}
	#SCM_32_MoBNEW_EDITION {
		left: 0px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 140px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBGroup_15145 {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 191px;
		top: 0px;
		overflow: visible;
	}
	#SCM_32_MoBRectangle_753 {
		fill: rgba(5,5,5,1);
	}
	.SCM_32_MoBRectangle_753 {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: 0px;
	}
	#SCM_32_MoBView_More {
		left: 38px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 71px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#SCM_32_MoBScroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 359px;
		left: 0px;
		top: 2847px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#SCM_32_MoBGroup_15144 {
		position: relative;
        margin-right: 30px;
		width: 221px;
		height: 301px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#SCM_32_MoBRectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.SCM_32_MoBRectangle_754 {
		filter: drop-shadow(5px 5px 1px rgba(150, 75, 0, 0.388));
		position: absolute;
		overflow: visible;
		width: 227.5px;
		height: 307.5px;
		left: 0px;
		top: 0px;
	}
	#SCM_32_MoBmostafa-mahmoudi-3OZr-hLbsq0-u {
		position: absolute;
		width: 217px;
		height: 231px;
		left: 2px;
		top: 35px;
		overflow: visible;
	}
	#SCM_32_MoBTitle {
		left: 6px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 210px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBn_10000 {
		left: 6px;
		top: 274px;
		position: absolute;
		overflow: visible;
		width: 210px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBGroup_15132 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 8.5px;
		top: 3306px;
		overflow: visible;
	}
	#SCM_32_MoBGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 20px;
		top: 73.095px;
		overflow: visible;
	}
	#SCM_32_MoBRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.SCM_32_MoBRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#SCM_32_MoBGroup_15050 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SCM_32_MoBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 67px;
		top: 480.096px;
		overflow: visible;
	}
	#SCM_32_MoBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#SCM_32_MoBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.SCM_32_MoBmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SCM_32_MoBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#SCM_32_MoBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.SCM_32_MoBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SCM_32_MoBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.SCM_32_MoBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SCM_32_MoBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.SCM_32_MoBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SCM_32_MoBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#SCM_32_MoBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.SCM_32_MoBshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SCM_32_MoBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#SCM_32_MoBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SCM_32_MoBmed_shadow_space_website_7_cg {
		fill: rgba(255,255,255,1);
	}
	.SCM_32_MoBmed_shadow_space_website_7_cg {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SCM_32_MoBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.986px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#SCM_32_MoBn_234567890_mobile_number_shad {
		left: 35px;
		top: 293.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 340.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBaddress_med_shadow_space_websi {
		left: 35px;
		top: 390.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 392.096px;
		overflow: visible;
	}
	#SCM_32_MoBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 339.096px;
		overflow: visible;
	}
	#SCM_32_MoBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 286.096px;
		overflow: visible;
	}
	#SCM_32_MoBn_Copyright_Operate_Live_OPC_P {
		left: 17px;
		top: 565.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#SCM_32_MoBContact_Us {
		left: 117.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -22.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}