
#reqandcomreq_pc {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 60px);
    top:60px;
    left:120px;
    
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: requirements;
    --web-view-id: requirements;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bg_design_reqandcomreq {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#My_Requirements_lbl_1_pc_ {
    left: 80px;
    top: 39px;
    position: absolute;
    overflow: visible;
    width: 132px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Community_Requirements_lbl_1_pc {
    left: 263px;
    top: 39px;
    position: absolute;
    overflow: visible;
    width: 196px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#switch_toggle_req {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.switch_toggle_req {
    position: absolute;
    overflow: visible;
    z-index: 1;
    width: 30px;
    height: 14px;
    left: 210px;
    top: 32px;
}
#Line_req_border_cr {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_req_border_cr {
    overflow: visible;
    position: absolute;
    width: 308px;
    height: 1px;
    left: 75px;
    top: 69.5px;
    transform: matrix(1,0,0,1,0,0);
}