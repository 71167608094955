#REQM_big_1_trending_off {
    position: relative;
    width: 339px;
    height: 427px;
    left: 0px;
    top: 0px;
    margin-top: 5px;
    overflow: visible;
}
#REQM_bdr_all_trending_off {
    fill: rgba(245,245,246,1);
}
.REQM_bdr_all_trending_off {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 357px;
    height: 445px;
    left: 0px;
    top: 0px;
}
#REQM_bdr_amount_trending_off {
    fill: rgba(255,255,255,1);
}
.REQM_bdr_amount_trending_off {
    position: absolute;
    overflow: visible;
    width: 43px;
    height: 21px;
    left: 283px;
    top: 371px;
}
#REQM_n_9_trending_off {
    left: 293px;
    top: 373px;
    position: absolute;
    overflow: visible;
    width: 23px;
    white-space: nowrap;
    text-align: center;
    font-family: Poppins-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#REQM_Image_trending__off {
    position: absolute;
    width: 335px;
    height: 355px;
    /* left: 6px; */
    /* top: 35px; */
    overflow: visible;
}
#REQM_Nike_Air_Force_1_Sha_trending_ {
    left: 18px;
    top: 366px;
    position: absolute;
    overflow: visible;
    width: 167px;
    white-space: nowrap;
    line-height: 19px;
    margin-top: -2px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.1818181800842285px;
}
#REQM_Single_Tour_trending_off {
    left: 18px;
    top: 387px;
    position: absolute;
    overflow: visible;
    width: 77px;
    white-space: nowrap;
    line-height: 18px;
    margin-top: -4px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#REQM_Group_125 {
    position: absolute;
    width: 24px;
    height: 23px;
    left: 2px;
    top: 2px;
    overflow: visible;
}
#REQM_bdr_1_trending_off_PC {
    fill: rgba(255,255,255,1);
}
.REQM_bdr_1_trending_off_PC {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 23px;
    left: 0px;
    top: 0px;
}
#REQM_trophy_trending_off_PC {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 2px;
    top: 2px;
    overflow: visible;
}
#REQM_Group_124 {
    position: absolute;
    width: 24px;
    height: 14px;
    left: 27px;
    top: 7px;
    overflow: visible;
}
#REQM_likes_trending_off_PC {
    fill: rgba(255,255,255,1);
}
.REQM_likes_trending_off_PC {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 13px;
    left: 0px;
    top: 1px;
}
#REQM_n_234_like_trending_off_PC {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 23px;
    white-space: nowrap;
    line-height: 18px;
    margin-top: -4px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#REQM_Group_133 {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 315px;
    top: 1px;
    overflow: visible;
}
#REQM_share_bdr_small_4_trending_com {
    fill: rgba(255,255,255,1);
}
.REQM_share_bdr_small_4_trending_com {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 0px;
    top: 0px;
}
#REQM_share_2_com_of {
    position: absolute;
    width: 15px;
    height: 14px;
    left: 5px;
    top: 5px;
    overflow: visible;
}