	.mediaViewInfo {
		--web-view-name: portfolio event;
		--web-view-id: portfolio_event;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: portfolio_event;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#portfolio_event {
		position: absolute;
		width: 100vw;
		height: calc(100vh - 205px);
		top: 75px;
		background-color: rgba(255,255,255,1);
		overflow-x: hidden;
		overflow-y: scroll;
		--web-view-name: portfolio event;
		--web-view-id: portfolio_event;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#heading_grp_offreqevent_event_ {
		position: absolute;
		width: 235px;
		height: 28px;
		left: 63px;
		top: 13px;
		overflow: visible;
	}
	#req_off_porfolio_reqevent_req_PC {
		opacity: 0.92;
		left: 81px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#selected_grp_offreqevent_req_P {
		position: absolute;
		width: 61px;
		height: 20px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#requirements_offreqevent_req_P {
		opacity: 0.92;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 62px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#A_off_porfolio_reqevent_req_PC {
		opacity: 0.92;
		left: 192px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 44px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,79,4,1);
	}
	#underline_off_porfolio_reqeventreq_PC {
		fill: rgba(255,79,4,1);
	}
	.underline_off_porfolio_reqeventreq_PC {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 3px;
		left: 192px;
		top: 25px;
	}
	#settings-sliders_offreqevent_e {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 328px;
		top: 15px;
		overflow: visible;
	}
