.mediaViewInfo {
    --web-view-name: com slide;
    --web-view-id: my_com_slide_;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: my_com_slide_;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#my_com_slide_ {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 200px);
    top: 75px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: com slide;
    --web-view-id: my_com_slide_;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#top_bg_green_req_mob_y {
    fill: url(#top_bg_green_req_mob_y);
}
.top_bg_green_req_mob_y {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: linear-gradient(145deg, rgb(219, 255, 213),#ebffef); 
}
#mycom_pop_up_bottom_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#com_card_com_req_mob {
    position: relative;
    width: 180px;
    height: 49px;
    left: 10px;
    top: 50px;
    overflow: visible;
}
#bdr_com_req_mob_ {
    fill: url(#bdr_com_req_mob_);
}
.bdr_com_req_mob_ {
    filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 52px;
    left: 0px;
    top: 0px;
}
#pfp_pic_com_req_mob {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 4px;
    top: 4px;
    border-radius: 50%;
    overflow: hidden;
}
#title_lbl_com_req_mob {
    left: 45.594px;
    top: 16px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#minus_close_com_mycom_mob {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 153px;
    top: 0px;
    overflow: visible;
}
#my_combtn_com_mob_12 {
    position: absolute;
    width: 139px;
    height: 40px;
    left: 15px;
    top: 5px;
    overflow: visible;
    border-radius: 50px;
}
#n_2_btn_com_mynew_mob {
    fill: rgba(255,255,255,1);
}
.n_2_btn_com_mynew_mob {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 157px;
    height: 58px;
    left: 0px;
    top: 0px;
}
#My_com__lbl_btn_com_req_mob {
    left: 20px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 73px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(37,37,37,1);
}
#plus_img_btn_comoff_mob_12 {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 102px;
    top: 10px;
    overflow: visible;
}
#down-arrow_for_top_mom {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    bottom: 10px;
    overflow: visible;
}

.com_top_com_mycom_glow {
    background: linear-gradient(145deg, rgb(48, 249, 17),#16cb34); 
    background-position: 400%;
    animation: animatecolor 8s linear ; 
    color: #fff;
}
  
  @keyframes animatecolor {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }

  @keyframes backInLeft {
    0% {
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
    }
    
    80% {
    transform: translateX(0px) scale(0.7);
    opacity: 0.7;
    }
    
    100% {
    transform: scale(1);
    opacity: 1;
    }
    }
    
    .backInLeft {
    animation: backInLeft 1s;
    background-color: #b2fbb297;
    }
  