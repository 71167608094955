	.mediaViewInfo {
		--web-view-name: calender page;
		--web-view-id: calender_page;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: calender_page;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}

  #centre_card_grp_calender_pc { 
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }   
}
	#calender_page {
		position: absolute;
		width: calc(100vw - 120px);
		height: calc(100vh - 60px);
        min-width: 1000px;
        top: 60px;
        left: 120px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: calender page;
		--web-view-id: calender_page;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#com_tata_next_grp {
		position: absolute;
		width: 463px;
		height: 21px;
		left: 211px;
		top: 46px;
		overflow: visible;
	}
	#completed_grp_calender_pc {
		position: absolute;
		width: 146px;
		height: 21px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Completed_lbbl_calender_pc {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 85px;
		white-space: nowrap;
		text-align: center;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#count_bdr {
		fill: rgba(255,255,255,1);
		stroke: rgba(166,210,170,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.count_bdr {
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 21px;
		left: 106px;
		top: 0px;
	}
	#n_0_count_calender_pc {
		left: 118px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 17px;
		white-space: nowrap;
		text-align: center;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#completed_staus_calender_pc {
		fill: rgba(46,243,65,1);
	}
	.completed_staus_calender_pc {
		position: absolute;
		overflow: visible;
		width: 7px;
		height: 7px;
		left: 0px;
		top: 7px;
	}
	#next_up_calender_pc_grp {
		position: absolute;
		width: 121px;
		height: 21px;
		left: 342px;
		top: 0px;
		overflow: visible;
	}
	#Next_Up_lbl_calender_pc {
		left: 15.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#n_0_bdr_next_up_calender_pc {
		fill: rgba(255,255,255,1);
		stroke: rgba(166,210,170,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.n_0_bdr_next_up_calender_pc {
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 21px;
		left: 81px;
		top: 0px;
	}
	#n_0_calender_pc {
		left: 95px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 13px;
		white-space: nowrap;
		text-align: center;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#arrow_next_up {
		fill: rgba(155,99,252,1);
	}
	.arrow_next_up {
		overflow: visible;
		position: absolute;
		width: 8px;
		height: 8px;
		transform: translate(-578.5px, -85px) matrix(1,0,0,1,580,92) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#next_up_arrow_line {
		fill: transparent;
		stroke: rgba(155,99,252,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.next_up_arrow_line {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 8px;
		left: 0px;
		top: 7px;
		transform: matrix(1,0,0,1,0,0);
	}
	#todo_grp_calender_pc {
		position: absolute;
		width: 96px;
		height: 21px;
		left: 196px;
		top: 0px;
		overflow: visible;
	}
	#To-Do_lbl_calender_pc {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 47px;
		white-space: nowrap;
		text-align: center;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#n_0_bdr_calender_pc {
		fill: rgba(255,255,255,1);
		stroke: rgba(166,210,170,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.n_0_bdr_calender_pc {
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 21px;
		left: 56px;
		top: 0px;
	}
	#n_0_calender_pc_ce {
		left: 70px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 13px;
		white-space: nowrap;
		text-align: center;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#centre_card_grp_calender_pc {
		position: absolute;
		width: 588.4px;
		height: 580.26px;
		left: calc((100% - 1100px)/2);
		top: 103px;
		overflow: visible;
	}
	#meeeetings_card {
		position: absolute;
		width: 280.4px;
		height: 280px;
		left: 0px;
		top: 300px;
		overflow: visible;
	}
	#meeting_card_overall_bg_calend_ci {
		fill: url(#meeting_card_overall_bg_calend_ci);
	}
	.meeting_card_overall_bg_calend_ci {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 298px;
		left: 0px;
		top: 0px;
	}
	#Meetings_lbl_headingcalender_p {
		left: 111.88px;
		top: 5.6px;
		position: absolute;
		overflow: visible;
		width: 71px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#Scroll_meeting {
		mix-blend-mode: normal;
		position: absolute;
		width: 273.76px;
		height: 239.94px;
		left: 6.64px;
		top: 35.32px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_meeting::-webkit-scrollbar{display: none;}
	#meeting_grp_calender_page {
		position: relative;
		width: 249.76px;
		height: 55.44px;
		left: 4px;
		top: 5px;
		overflow: visible;
	}
	#line_calender_pc {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_calender_pc {
		overflow: visible;
		position: absolute;
		width: 249.76px;
		height: 1px;
		left: 0px;
		top: 55.44px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Arcane_Series_lbl_calender_pc {
		left: 9.36px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#n_025pm_-_1200am_arcane {
		left: 9.36px;
		top: 23.52px;
		position: absolute;
		overflow: visible;
		width: 121px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#rcane_mode_meetincalender_pc {
		fill: rgba(252,148,148,1);
	}
	.rcane_mode_meetincalender_pc {
		position: absolute;
		overflow: visible;
		width: 3.36px;
		height: 16.8px;
		left: 0.561px;
		top: 3.36px;
	}
	#inacvtice_status_calender_pc {
		fill: rgba(243,46,46,1);
	}
	.inacvtice_status_calender_pc {
		position: absolute;
		overflow: visible;
		width: 11.2px;
		height: 11.2px;
		left: 235.761px;
		top: 26.88px;
	}
	#meeting_img_calender_pc {
		position: absolute;
		width: 22.4px;
		height: 22.4px;
		left: 82.88px;
		top: 5.6px;
		overflow: visible;
	}
	#events_card {
		position: absolute;
		width: 280.4px;
		height: 280.26px;
		left: 308px;
		top: 300px;
		overflow: visible;
	}
	#events_verall_pg_calender_pc_cu {
		fill: url(#events_verall_pg_calender_pc_cu);
	}
	.events_verall_pg_calender_pc_cu {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 298px;
		left: 0px;
		top: 0px;
	}
	#Events_lbl_calender_pc {
		left: 119.48px;
		top: 5.6px;
		position: absolute;
		overflow: visible;
		width: 47px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#Scroll_events {
		mix-blend-mode: normal;
		position: absolute;
		width: 279.76px;
		height: 247.26px;
		left: 0.64px;
		top: 33px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_events::-webkit-scrollbar{display: none;}
	#events_page_calender {
		position: relative;
		width: 249.76px;
		height: 55.76px;
		left: 10px;
		top: 7px;
		overflow: visible;
	}
	#Dragon_Ball_Super_lbl {
		left: 9.36px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 133px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#KAMEHAMEHA {
		left: 9.36px;
		top: 23.52px;
		position: absolute;
		overflow: visible;
		width: 113px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#dragon_ball_super_calender_pc {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.dragon_ball_super_calender_pc {
		overflow: visible;
		position: absolute;
		width: 249.76px;
		height: 1px;
		left: 0px;
		top: 55.76px;
		transform: matrix(1,0,0,1,0,0);
	}
	#dragon_ball_super_mode_calende {
		fill: rgba(175,148,252,1);
	}
	.dragon_ball_super_mode_calende {
		position: absolute;
		overflow: visible;
		width: 3.36px;
		height: 16.8px;
		left: 0.561px;
		top: 3.68px;
	}
	#active_events_calender_pc {
		fill: rgba(46,243,65,1);
	}
	.active_events_calender_pc {
		position: absolute;
		overflow: visible;
		width: 11.2px;
		height: 11.2px;
		left: 234.641px;
		top: 30.56px;
	}
	#calendar_img_calender_pc {
		position: absolute;
		width: 22.4px;
		height: 22.4px;
		left: 88.48px;
		top: 5.6px;
		overflow: visible;
	}
	#tasks_card {
		position: absolute;
		width: 280.4px;
		height: 280px;
		left: 308px;
		top: 0px;
		overflow: visible;
	}
	#tak_bdr_calender_pc_c {
		fill: url(#tak_bdr_calender_pc_c);
	}
	.tak_bdr_calender_pc_c {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 298px;
		left: 0px;
		top: 0px;
	}
	#Tasks_lbl_ {
		left: 117.48px;
		top: 5.6px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#notes_img_taskes_calender_pc {
		position: absolute;
		width: 22.4px;
		height: 22.4px;
		left: 88.48px;
		top: 5.6px;
		overflow: visible;
	}
	#Scroll_tasks {
		mix-blend-mode: normal;
		position: absolute;
		width: 276.76px;
		height: 235.3px;
		left: 3.64px;
		top: 40.32px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_tasks::-webkit-scrollbar{display: none;}
	#Group_task_pc_new {
		position: relative;
		width: 249.76px;
		height: 58.8px;
		left: 7px;
		top: 0px;
		overflow: visible;
	}
	#calender_page_pc {
		left: 9.36px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 123px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#calender_time_ {
		left: 9.36px;
		top: 23.52px;
		position: absolute;
		overflow: visible;
		width: 113px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#arcane_top_line_calender_pc {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.arcane_top_line_calender_pc {
		overflow: visible;
		position: absolute;
		width: 249.76px;
		height: 1px;
		left: 0px;
		top: 58.8px;
		transform: matrix(1,0,0,1,0,0);
	}
	#caleneder_mode_calender_pc {
		fill: rgba(175,148,252,1);
	}
	.caleneder_mode_calender_pc {
		position: absolute;
		overflow: visible;
		width: 3.36px;
		height: 16.8px;
		left: 0.561px;
		top: 3.36px;
	}
	#active_calender_page_pc_calend {
		fill: rgba(46,243,65,1);
	}
	.active_calender_page_pc_calend {
		position: absolute;
		overflow: visible;
		width: 11.2px;
		height: 11.2px;
		left: 234.641px;
		top: 38.08px;
	}
	#immediate_1_calender_pc {
		position: absolute;
		width: 22.4px;
		height: 22.4px;
		left: 202.16px;
		top: 30.8px;
		overflow: visible;
	}
	#arlarm_card {
		position: absolute;
		width: 280.4px;
		height: 280px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#alarm_bdr_overall_pc_dj {
		fill: url(#alarm_bdr_overall_pc_dj);
	}
	.alarm_bdr_overall_pc_dj {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 298px;
		left: 0px;
		top: 0px;
	}
	#Alarms_lbl_calender {
		left: 110.88px;
		top: 6.6px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#clock_alarm_for_snooze {
		position: absolute;
		width: 22.4px;
		height: 22.4px;
		left: 82.88px;
		top: 5.6px;
		overflow: visible;
	}
	#Scroll_alarm {
		mix-blend-mode: normal;
		position: absolute;
		width: 275.76px;
		height: 235.3px;
		left: 4.64px;
		top: 40.32px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_alarm::-webkit-scrollbar{display: none;}
	#waking_up_all_grp_alarm {
		position: relative;
		width: 249.76px;
		height: 58.8px;
		left: 6px;
		top: 0px;
		overflow: visible;
	}
	#Waking_Up_Early_lbl_calender {
		left: 9.36px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 117px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#n_00am_time_for_wake_up {
		left: 9.36px;
		top: 29.36px;
		position: absolute;
		overflow: visible;
		width: 45px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#leaving_office_line {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.leaving_office_line {
		overflow: visible;
		position: absolute;
		width: 249.76px;
		height: 1px;
		left: 0px;
		top: 58.8px;
		transform: matrix(1,0,0,1,0,0);
	}
	#waking_up_early_line {
		fill: rgba(175,148,252,1);
	}
	.waking_up_early_line {
		position: absolute;
		overflow: visible;
		width: 3.36px;
		height: 16.8px;
		left: 0.561px;
		top: 3.36px;
	}
	#n_1135am_time_for_snooze {
		left: 122.641px;
		top: 31.36px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#snooze_img_calender {
		position: absolute;
		width: 22.4px;
		height: 22.4px;
		left: 100.24px;
		top: 30.24px;
		overflow: visible;
	}
	#green_ball {
		fill: rgba(164,255,96,1);
	}
	.green_ball {
		position: absolute;
		overflow: visible;
		width: 11.2px;
		height: 11.2px;
		left: 231.28px;
		top: 38.08px;
	}
	#back_btn_for_calender {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 93px;
		top: 43px;
		overflow: visible;
	}
	
	#right_side_grp_calender_pc {
		position: absolute;
		width: 390px;
		height: 768px;
		right: 0px;
		top: 0px;
		overflow: visible;
	}
	#calender_bdr_calender_pc_ei {
		fill: url(#calender_bdr_calender_pc_ei);
	}
	.calender_bdr_calender_pc_ei {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 768px;
		left: 0px;
		top: 0px;
	}
	#Scroll_date_and_event_oof {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 350px;
		left: 3px;
		top: 350px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#so_much_to_grp {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0px;
		/* top: 60px; */
		overflow: visible;
	}
	#n_am_number_calender_pc {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 80px;
		overflow: visible;
	}
	#n__am_lbl {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_1am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_1am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_1_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_1_am:hover {
       opacity: 100;
    }

	#n_2am_number_calender_pc {
		position: relative;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 0px;
		margin-top: 50px;
		overflow: visible;
	}
	#n_2_am_lbl {
		left: 22px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 42px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_12am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_12am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_0_am {
		position: relative;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_0_am:hover {
		opacity: 100;
	}
	#n_am_number_calender_pc_et {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 160px;
		overflow: visible;
	}
	#n__am_lbl_eu {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_2am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_2am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_2_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_2_am:hover {
		opacity: 100;
	}
	#n_am_number_calender_pc_ex {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 320px;
		overflow: visible;
	}
	#n__am_lbl_ey {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_4am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_4am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_4_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_4_am:hover {
		opacity: 100;
	}
	#n_am_number_calender_pc_e {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 240px;
		overflow: visible;
	}
	#n__am_lbl_e {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_3am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_3am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_3_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_3_am:hover {
		opacity: 100;
	}
	#n_am_number_calender_pc_fa {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 400px;
		overflow: visible;
	}
	#n__am_lbl_fa {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_5_am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_5_am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_5_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity:0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_5_am:hover {
		opacity: 100;
	}
	#n_am_number_calender_pc_fb {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 560px;
		overflow: visible;
	}
	#n__am_lbl_fb {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_7_am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_7_am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_7_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_7_am:hover {
		opacity: 100;
	}
	#n_am_number_calender_pc_fd {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 480px;
		overflow: visible;
	}
	#n__am_lbl_fe {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line__6_am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line__6_am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_6_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_6_am:hover {
		opacity: 100;
	}
	#n_am_number_calender_pc_fh {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 640px;
		overflow: visible;
	}
	#n__am_lbl_fi {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_8am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_8am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_8_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_8_am:hover {
		opacity: 100;
	}
	#n_0am_number_calender_pc {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 800px;
		overflow: visible;
	}
	#Line10am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line10am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_0_am_lbl {
		left: 22px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 42px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#add_btn_10_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_10_am:hover {
		opacity: 100;
	}
	#n_am_number_calender_pc_fp {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 720px;
		overflow: visible;
	}
	#Line_9am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_9am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n__am_lbl_fr {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#add_btn_9_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity:0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_9_am:hover {
		opacity: 100;
	}
	#n_1am_number_calender_pc {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 880px;
		overflow: visible;
	}
	#Line_11am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_11am {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_1_am_lbl {
		left: 22px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 42px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#add_btn_11_am {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_11_am:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 1040px;
		overflow: visible;
	}
	#n__pm_lbl {
		left: 26px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_1pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_1pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 11.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_1_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_1_pm:hover {
		opacity: 100;
	}
	#n_2pm_number_calender_pc {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 960px;
		overflow: visible;
	}
	#Line_12am_f {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_12am_f {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_2_pm_lbl {
		left: 22px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#add_btn_12_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_12_pm:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc_f {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 1120px;
		overflow: visible;
	}
	#n__pm_lbl_f {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_2pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_2pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_2_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_2_pm:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc_ga {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 1280px;
		overflow: visible;
	}
	#n__pm_lbl_ga {
		left: 26px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_4pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_4pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 11.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_4_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_4_pm:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc_gd {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 1200px;
		overflow: visible;
	}
	#n__pm_lbl_ge {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_3am_gf {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_3am_gf {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_3_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_3_pm:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc_gh {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 1360px;
		overflow: visible;
	}
	#n__pm_lbl_gi {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_5pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_5pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_5_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_5_pm:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc_gl {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 1520px;
		overflow: visible;
	}
	#n__pm_lbl_gm {
		left: 26px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_7_pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_7_pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 11.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_7_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_7_pm:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc_gp {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 1440px;
		overflow: visible;
	}
	#n__pm_lbl_gq {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_6pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_6pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_6_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_6_pm:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc_gt {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 1600px;
		overflow: visible;
	}
	#n__pm_lbl_gu {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_8pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_8pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_8_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_8_pm:hover {
		opacity: 100;
	}
	#n_pm_number_calender_pc_gx {
		position: absolute;
		width: 345.5px;
		height: 21px;
		left: 0px;
		top: 1680px;
		overflow: visible;
	}
	#Line_9pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_9pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n__pm_lbl_gz {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#add_btn_9_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 1px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_9_pm:hover {
		opacity: 100;
	}
	#n_0pm_number_calender_pc {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 1760px;
		overflow: visible;
	}
	#Line_10pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_10pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 9.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_0_pm_lbl {
		left: 22px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#add_btn_10_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_10_pm:hover {
		opacity: 100;
	}
	#n_1pm_number_calender_pc {
		position: absolute;
		width: 345.5px;
		height: 20px;
		left: 0px;
		top: 1840px;
		overflow: visible;
	}
	#Line_11pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_11pm {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 65.5px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_1_pm_lbl {
		left: 22px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#add_btn_11_pm {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 0px;
		opacity: 0;
		cursor: pointer;
		overflow: visible;
	}
	#add_btn_11_pm:hover {
		opacity: 100;
	}
	#august_calender_pc_side {
		position: absolute;
		width: 290px;
        height: 270px;
		left: 50px;
		top: 46px;
		overflow: visible;
	}
	#dates_bdr_calender_pc_side {
		fill: rgba(255,255,255,1);
	}
	.dates_bdr_calender_pc_side {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 309px;
		height: 233px;
		left: 0px;
		top: 37px;
	}
	#aug_bdr_calender_pc_side {
		fill: rgba(255,255,255,1);
	}
	.aug_bdr_calender_pc_side {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 309px;
		height: 46px;
		left: 0px;
		top: 0px;
	}
	#August_2023_lbl_calender_pc_si {
		left: 109px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#Path_6951_left_calender_pc_sid {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1.2000000476837158px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_6951_left_calender_pc_sid {
		overflow: visible;
		position: absolute;
		width: 6.273px;
		height: 10.849px;
		left: 12.5px;
		top: 9.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_6952_rgh_calender_pc_side {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1.2000000476837158px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_6952_rgh_calender_pc_side {
		overflow: visible;
		position: absolute;
		width: 6.273px;
		height: 10.849px;
		left: 274.5px;
		top: 9.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Moncalender_pc_side {
		left: 4px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 23px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Tuecalender_pc_side {
		left: 50px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 19px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Wedcalender_pc_side {
		left: 91px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 23px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Thucalender_pc_side {
		left: 137px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 19px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Fricalender_pc_side {
		left: 183px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 13px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Satcalender_pc_side {
		left: 225px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 17px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Suncalender_pc_side {
		left: 267px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 21px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#n_1_calender_pc_side11 {
		left: 8px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(170,170,170,1);
	}
	#n__calender_pc_side11 {
		left: 58px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n__calender_pc_side11_ho {
		left: 101px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n__calender_pc_side11_hp {
		left: 143px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n__calender_pc_side {
		left: 186px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n__calender_pc_side_hr {
		left: 228px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n__calender_pc_side_hs {
		left: 274px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n__calender_pc_side_ht {
		left: 12px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n__calender_pc_side_hu {
		left: 58px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_calender_pc_side {
		left: 101px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_0_calender_pc_side {
		left: 139px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_1calender_pc_side {
		left: 183px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 13px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_2calender_pc_side {
		left: 226px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_3calender_pc_side {
		left: 270px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_4calender_pc_side {
		left: 8px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_5calender_pc_side {
		left: 53px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_6calender_pc_side {
		left: 96px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_7calender_pc_side {
		left: 139px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_8_calender_pc_side {
		left: 182px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_9_calender_pc_side {
		left: 226px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_0calender_pc_side {
		left: 270px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_1_calender_pc_side {
		left: 8px;
		top: 196px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_2_calender_pc_side {
		left: 53px;
		top: 196px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_3_calender_pc_side {
		left: 96px;
		top: 196px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_4_calender_pc_side {
		left: 139px;
		top: 196px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_5_calender_pc_side {
		left: 182px;
		top: 196px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_6_calender_pc_side {
		left: 226px;
		top: 196px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_7_calender_pc_side {
		left: 270px;
		top: 196px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_8calender_pc_side {
		left: 8px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_9calender_pc_side {
		left: 53px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_0_calender_pc_side_ig {
		left: 96px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n_1_calender_pc_side_ih {
		left: 139px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#n__calender_pc_side_ii {
		left: 186px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(170,170,170,1);
	}
	#n__calender_pc_side_ij {
		left: 228px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(170,170,170,1);
	}
	#n__calender_pc_side_ik {
		left: 274px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 7px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(170,170,170,1);
	}