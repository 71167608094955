#all_grp_top_to_down_comp_com_o {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 180px);
    left: 0px;
    top: 114px;
    overflow-y: auto;
    overflow-x: hidden;
}
#desgin_3_com_off_off_PC {
    fill: rgba(248,248,248,1);
}
.desgin_3_com_off_off_PC {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 255px;
    left: 0px;
    top: 0px;
}
#desgin_2_ocom_off_off_PC {
    position: absolute;
    width: 100%;
    height: 227px;
    left: 0px;
    top: 528px;
    overflow: visible;
}
#design_1_com_off_off_PC {
    fill: rgba(0,0,0,0.569);
}
.design_1_com_off_off_PC {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 227px;
    left: 24px;
    top: 528px;
}
#recently_added_com_off_off_PC {
    /* left: 497px; */
    top: 65px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    line-height: 49px;
    
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#explore_awesome_offerings_com_ {
    /* left: 531px; */
    top: 47px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#And_then_there_was_offreqevent_comm_off {
    left: 66px;
    top: 594px;
    position: absolute;
    overflow: visible;
    width: 471px;
    height: 47px;
    line-height: 56.630001068115234px;
    margin-top: -10.815000534057617px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: rgba(255,255,255,1);
    letter-spacing: -0.46875px;
}
#trwnding_this_week_offreqevent_comm_off {
    /* left: 486px; */
    top: 833px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    line-height: 50px;
    /* margin-top: -11.5px; */
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#top_product_offreqevent_com_of {
    /* left: 574.606px; */
    top: 809.533px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#Scroll_recently_added_com_off_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 356px;
    /* left: 40px; */
    top: 112px;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}
#Scroll_recently_added_com_off_::-webkit-scrollbar{
    display: none;
}
#card_1_com_off_off_PC {
    position: absolute;
    width: 270px;
    height: 325px;
    left: 55px;
    top: 24px;
    overflow: visible;
}
#Base_com_off_off_PC {
    fill: rgba(255,255,255,1);
}
.Base_com_off_off_PC {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 288px;
    height: 288px;
    left: 0px;
    top: 0px;
}
#bdr_1com_off_off_PC {
    fill: rgba(255,79,4,0.09);
}
.bdr_1com_off_off_PC {
    position: absolute;
    overflow: visible;
    width: 56px;
    height: 31px;
    left: 189px;
    top: 285px;
}
#number_smal_com_off_off_PC {
    fill: rgba(255,255,255,1);
}
.number_smal_com_off_off_PC {
    position: absolute;
    overflow: visible;
    width: 39px;
    height: 21px;
    left: 40px;
    top: 10px;
}
#trohpie_bdr_small_4com_off_PC {
    fill: rgba(255,255,255,1);
}
.trohpie_bdr_small_4com_off_PC {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 37px;
    left: 0px;
    top: 0px;
}
#n_90_com_off_off_PC {
    left: 202px;
    top: 291px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#Single_Tourcom_off_off_PC {
    left: 17px;
    top: 284px;
    position: absolute;
    overflow: visible;
    width: 75px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.3333333206176758px;
}
#date_com_off_off_PC {
    left: 17px;
    top: 306px;
    position: absolute;
    overflow: visible;
    width: 107px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(17,17,17,1);
}
#Scroll_trending_this_week_com_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 772px;
    left: 0px;
    top: 909px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#all_items_grp_trend_com_off_of {
    position: relative;
    width: 1110px;
    height: 680px;
    left: calc((100% - 1110px)/2);
    text-align: center;
    top: 34px;
    display: inline-block;
    overflow: visible;
}
#share_bdr_strending_com_off_of {
    fill: rgba(255,255,255,1);
}
.share_bdr_strending_com_off_of {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 228px;
    top: 1px;
}
#online-shoppingcom_off_off_PC {
    position: absolute;
    width: 177px;
    height: 177px;
    left: 47px;
    top: 47px;
    overflow: visible;
}
#trophy_com_off_off_PC {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#n_234_com_off_off_PC {
    left: 42px;
    top: 12px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17,17,17,1);
}
#share_1com_off_off_PC {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 235px;
    top: 8px;
    overflow: visible;
}