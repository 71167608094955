.mediaViewInfo {
    --web-view-name: location tab;
    --web-view-id: location_tab;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: location_tab;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#location_tab {
    position: absolute;
    width: 100%;
    height: calc(100% - 185px);
    top: 120px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: location tab;
    --web-view-id: location_tab;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#holder_mob_eit_page_location {
    position: absolute;
    width: 131.234px;
    height: 38.112px;
    left: 121.883px;
    top: 1px;
    overflow: visible;
}
#holder_down_locatiom_mob_eit_p {
    position: absolute;
    width: 100%;
    height: calc(100% - 35px);
    left: 0px;
    top: 38.112px;
    overflow: visible;
}
#top_grp_heading_location {
    position: absolute;
    width: 333px;
    height: 20px;
    left: 20px;
    top: 12.056px;
    overflow: visible;
}
#Generallbl_profile_mob_locatio {
    left: 25px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#dossier_lbl_profile_mob_locati {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Location_lbl_profile_mob_locat {
    left: 143px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#placeholder_lbl_profile_mob_lo {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 117px;
    top: 0px;
    overflow: visible;
}
#Payment_Details_lbl_profile_mo {
    left: 273px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#card_lbl_profile_mob_location {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 248px;
    top: 0px;
    overflow: visible;
}
#next_btn_mob_location {
    position: absolute;
    width: 109px;
    height: 36px;
    left: 35%;
    top: calc(100% - 50px);
    overflow: visible;
    cursor: pointer;
}
#add_bg_Add_Tax_Authority {
    fill: rgba(163,233,200,1);
}
.add_bg_Add_Tax_Authority {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 127px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#Active_lbl_Add_Tax_Authority {
    left: 41px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 28px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Scrolllocation_tab {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 50px);
    height: calc(100% - 110px);
    left: 45px;
    top: 46px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#address_grp_mob_location {
    position: absolute;
    width: 251px;
    height: 91px;
    left: 21px;
    top: 284px;
    overflow: visible;
}
#address_input_lbl_profile_mob_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.address_input_lbl_profile_mob_ {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 70px;
    left: 0px;
    top: 21px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Address_lbl_profile_mob_locati {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#pincode_grp_mob_location {
    position: absolute;
    width: 253px;
    height: 61px;
    left: 19px;
    top: 213px;
    overflow: visible;
}
#pincode_bdr_input_mob_location {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.pincode_bdr_input_mob_location {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 2px;
    top: 21px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Pincode_lbl_lbl_profile_pmob_l {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#city_grp_mob_location {
    position: absolute;
    width: 251px;
    height: 61px;
    left: 21px;
    top: 142px;
    overflow: visible;
}
#city_input_profile_mob_locatio {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.city_input_profile_mob_locatio {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 0px;
    top: 21px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#City_lbl_profile_mob_location {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#state_grp_mob_location {
    position: absolute;
    width: 251px;
    height: 61px;
    left: 21px;
    top: 71px;
    overflow: visible;
}
#state_drop_lbl_profile_mob_loc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.state_drop_lbl_profile_mob_loc {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 0px;
    top: 21px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#State_lbl_profile_mob_location {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 34px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#country_mob_location {
    position: absolute;
    width: 251px;
    height: 61px;
    left: 21px;
    top: 0px;
    overflow: visible;
}
#country_drop_lbl_profile_mob_l {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.country_drop_lbl_profile_mob_l {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 0px;
    top: 21px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Country_lbl_profile_mob_locati {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#whatsapp_input_edit_profile_mo {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.whatsapp_input_edit_profile_mo {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 21px;
    top: 578px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#whats_app_number_lbl_edit_mob {
    left: 21px;
    top: 558px;
    position: absolute;
    overflow: visible;
    width: 129px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#linked_in_edit_profile_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.linked_in_edit_profile_mob {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 21px;
    top: 648px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#linked_in_lbl_edit_profile_mob {
    left: 21px;
    top: 628px;
    position: absolute;
    overflow: visible;
    width: 87px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#insta_url_box_edit_profile_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.insta_url_box_edit_profile_mob {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 21px;
    top: 718px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#insta_url_lbl_edit_profile_mob {
    left: 21px;
    top: 698px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#socials_lbl_edit_profile_mob_e {
    left: 43px;
    top: 532px;
    position: absolute;
    overflow: visible;
    width: 128px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#social_img_edit_profile_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 21px;
    top: 533px;
    overflow: visible;
}
#longitude_grp_mob_location {
    position: absolute;
    width: 253px;
    height: 61px;
    left: 19px;
    top: 456px;
    overflow: visible;
}
#longi_bdr_input_mob_location {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.longi_bdr_input_mob_location {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 2px;
    top: 21px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#longitudelbl_lbl_profile_pmob_ {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#latitude_grp_mob_location {
    position: absolute;
    width: 251px;
    height: 61px;
    left: 21px;
    top: 385px;
    overflow: visible;
}
#latitude_input_profile_mob_loc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.latitude_input_profile_mob_loc {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 0px;
    top: 21px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#latitude_lbl_profile_mob_locat {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}