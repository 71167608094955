	.mediaViewInfo {
		--web-view-name: web 3 mob;
		--web-view-id: web_3_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: web_3_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#web_3_mob {
		position: absolute;
		width: 390px;
		height: 4980px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: web 3 mob;
		--web-view-id: web_3_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#bdr_contact_page_website_2mob {
		filter: drop-shadow(0px 10px 2px rgba(135, 207, 172, 0.647));
		position: absolute;
		width: 367px;
		height: 289.485px;
		left: 11.5px;
		top: 4520px;
		overflow: visible;
	}
	#bg_img_website_3_mob {
		fill: rgba(189,204,255,1);
	}
	.bg_img_website_3_mob {
		position: absolute;
		overflow: visible;
		width: 235px;
		height: 278px;
		left: 155px;
		top: 377px;
	}
	#img_centre_website_3_mob {
		position: absolute;
		width: 276px;
		height: 192.953px;
		left: 17px;
		top: 421px;
		overflow: visible;
	}
	#Scroll_company_intro_web_3 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 95px;
		left: 0px;
		top: 278px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#intro_about_website_3_mob {
		left: 22px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 349px;
		height: 99px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.1px;
	}
	#Heading_website_3_mob {
		left: 62px;
		top: 141px;
		position: absolute;
		overflow: visible;
		width: 106px;
		white-space: nowrap;
		line-height: 73px;
		margin-top: -24px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Top_heading_website_3_mob {
		left: 65px;
		top: 56px;
		position: absolute;
		overflow: visible;
		width: 126px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(255,153,0,1);
	}
	#partner_website_3_mob {
		left: 25px;
		top: 675px;
		position: absolute;
		overflow: visible;
		width: 168px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.1px;
	}
	#what_we_stand_for_lbl_website_ {
		left: 89px;
		top: 826px;
		position: absolute;
		overflow: visible;
		width: 214px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Card_01 {
		position: absolute;
		width: 390px;
		height: 313px;
		left: 0px;
		top: 934px;
		overflow: visible;
	}
	#Scroll_Group_14_mob_3 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 120px;
		left: 0px;
		top: 193px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#intro_visionwebsite_3_mob {
	
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		line-height: 26px;
		
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#vision_lbl_website_3_mob {
		left: 166px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#bdr_visionwebsite_3_mob {
		fill: rgba(150,114,255,1);
	}
	.bdr_visionwebsite_3_mob {
		filter: drop-shadow(0px 1.852px 3.148px rgba(150, 114, 255, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.445px;
		left: 150px;
		top: 0px;
	}
	#line_website_3_mob {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.line_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 8.742px;
		height: 8.742px;
		left: 200.672px;
		top: 50.673px;
		transform: matrix(1,0,0,1,0,0);
	}
	#circle_mag_website_3_mob {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.circle_mag_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 22.689px;
		height: 22.689px;
		left: 183px;
		top: 33px;
		transform: matrix(1,0,0,1,0,0);
	}
	#line_grp_11_website_3_mob {
		transform: matrix(1,0,0,1,84.5,1349.5) rotate(90deg);
		transform-origin: center;
		position: absolute;
		width: 210px;
		height: 11px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#line_centre_website_3_mob {
		opacity: 0.2;
		fill: rgba(0,0,0,0);
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5 10;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_centre_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 3px;
		height: 191.5px;
		left: 5.5px;
		top: 9px;
		transform: matrix(1,0,0,1,0,0);
	}
	#down_ball_1_website_3_mob {
		fill: rgba(77,223,253,1);
	}
	.down_ball_1_website_3_mob {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 0px;
		top: 199px;
	}
	#top_ball_1_website_3_mob {
		fill: rgba(150,114,255,1);
	}
	.top_ball_1_website_3_mob {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 0px;
		top: 0px;
	}
	#Card_02_mission_over_all {
		position: absolute;
		width: 390px;
		height: 313px;
		left: 0px;
		top: 1479px;
		overflow: visible;
	}
	#Scroll_web_3_mob1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 120px;
		left: 0px;
		top: 193px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#mssion_intro_website_3_mob {
		
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		line-height: 26px;
		
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#mission_website_3_mob {
		left: 158px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#mision_img_bdr_website_3_mob {
		fill: rgba(77,223,253,1);
	}
	.mision_img_bdr_website_3_mob {
		filter: drop-shadow(0px 1.852px 3.148px rgba(77, 223, 253, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.445px;
		left: 150px;
		top: 0px;
	}
	#hand_website_3_mob {
		fill: rgba(255,255,255,1);
	}
	.hand_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 25px;
		height: 12.324px;
		left: 183px;
		top: 45.676px;
		transform: matrix(1,0,0,1,0,0);
	}
	#star_down_website_3_mob {
		fill: rgba(255,255,255,1);
	}
	.star_down_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 8.532px;
		height: 8.305px;
		left: 193.417px;
		top: 37.918px;
		transform: matrix(1,0,0,1,0,0);
	}
	#star_left_website_3_mob {
		fill: rgba(255,255,255,1);
	}
	.star_left_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 6.241px;
		height: 6.204px;
		left: 188.026px;
		top: 34.464px;
		transform: matrix(1,0,0,1,0,0);
	}
	#star_top_right_website_3_mob {
		fill: rgba(255,255,255,1);
	}
	.star_top_right_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 5.365px;
		height: 5.326px;
		left: 198.91px;
		top: 33px;
		transform: matrix(1,0,0,1,0,0);
	}
	#down_arow_grp_website_3_mob {
		transform: matrix(1,0,0,1,90.5,1915.8145) rotate(90deg);
		transform-origin: center;
		position: absolute;
		width: 210px;
		height: 11px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#line_centre_website_3_mob_ch {
		opacity: 0.2;
		fill: rgba(0,0,0,0);
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5 10;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_centre_website_3_mob_ch {
		overflow: visible;
		position: absolute;
		width: 191.5px;
		height: 1px;
		transform: translate(11px, 0px) matrix(1,0,0,1,9.5,5.5) rotate(180deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#top_ball_down_website_3_mob {
		fill: rgba(77,223,253,1);
	}
	.top_ball_down_website_3_mob {
		width: 11px;
		height: 11px;
		position: absolute;
		overflow: visible;
		transform: translate(11px, 0px) matrix(1,0,0,1,0,0) rotate(180deg);
		transform-origin: center;
	}
	#ball_2_down_website_3_mob {
		fill: rgba(150,114,255,1);
	}
	.ball_2_down_website_3_mob {
		width: 11px;
		height: 11px;
		position: absolute;
		overflow: visible;
		transform: translate(11px, 0px) matrix(1,0,0,1,199,0) rotate(180deg);
		transform-origin: center;
	}
	#Card_03_princi_website_3_mob {
		position: absolute;
		width: 390px;
		height: 313px;
		left: 0px;
		top: 2045px;
		overflow: visible;
	}
	#Scroll_web_3_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 120px;
		left: 0px;
		top: 193px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#intro_website_3_mob {
		
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		line-height: 26px;
		
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#principle_website_3_mob {
		left: 149.5px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#principles_bdr_website_3_mob {
		fill: rgba(242,184,236,1);
	}
	.principles_bdr_website_3_mob {
		filter: drop-shadow(0px 1.852px 3.148px rgba(242, 184, 236, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.445px;
		left: 150px;
		top: 0px;
	}
	#front_path_website_3_mob {
		fill: rgba(255,255,255,1);
	}
	.front_path_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 16.479px;
		height: 20.006px;
		left: 185px;
		top: 37.994px;
		transform: matrix(1,0,0,1,0,0);
	}
	#top_website_3_mob {
		fill: rgba(255,255,255,1);
	}
	.top_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 18.923px;
		height: 3.37px;
		left: 186.03px;
		top: 33px;
		transform: matrix(1,0,0,1,0,0);
	}
	#right_website_3_mob {
		fill: rgba(255,255,255,1);
	}
	.right_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 2.983px;
		height: 22.591px;
		left: 203.017px;
		top: 34.193px;
		transform: matrix(1,0,0,1,0,0);
	}
	#offerings_view_grp_website_3_m {
		position: absolute;
		width: 202px;
		height: 49px;
		left: 19.872px;
		top: 2394.5px;
		overflow: visible;
	}
	#check_out_my_offerings_website {
		left: 0px;
		top: 28px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 21px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#oferrings_txt_website_3_mob {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#scroll_offering_imgs {
    mix-blend-mode: normal;
    position: relative;
    width: 358px;
    height: 246px;
    left: 20px;
    top: 2484px;
    display: inline-flex;
    overflow-x: auto;
    overflow-y: hidden;
	}
	#Image_03_website_3_mob {
		position: relative;
		width: 290px;
		height: 225px;
		left: 0px;
		padding: 10px;
		top: 21px;
		overflow: visible;
	}
	#check_out_my_offerings_website_cx {
		left: 10px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 21px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#upcoming_evnt_lbl_website_3_mo {
		left: 0px;
		top: 2808px;
		position: absolute;
		overflow: visible;
		width: 391px;
		height: 33px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	
	#Scroll_card_event_scroll_mob_3 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 899px;
		left: 0px;
		top: 2841px;
		display: flex;
        flex-wrap: wrap;
		flex-direction: column;
        overflow-x: auto;
        overflow-y: hidden;
	}
	#Group_14919_mob_3 {
		position: relative;
		width: 350px;
		height: 815px;
		/* left: 20px; */
		top: 42px;
		margin: 30px;
		overflow: visible;
	}
	#upcoming_event_bdr_website_3_m {
		fill: rgba(241,255,255,1);
	}
	.upcoming_event_bdr_website_3_m {
		position: absolute;
		overflow: visible;
		width: 350px;
		height: 815px;
		left: 0px;
		top: 0px;
	}
	#Image_top_website_3_mob {
		position: absolute;
		width: 300px;
		height: 222px;
		left: 25px;
		top: 20px;
		overflow: visible;
	}
	#date_and_day_website_3_mob {
		left: 19px;
		top: 361px;
		position: absolute;
		overflow: visible;
		width: 313px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#Scrollweb_3_mob_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 332px;
		height: 99px;
		left: 7px;
		top: 408px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#event_intro_website_3_mob {
		left: 13px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 325px;
		height: 96px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(84,90,88,1);
	}
	#events_name_lbl_website_3_mob {
		left: 19px;
		top: 320px;
		position: absolute;
		overflow: hidden;
		width: 320px;
		height: 33px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#avaliable_grp_website_3_mob {
		position: absolute;
		width: 67px;
		height: 57px;
		left: 264px;
		top: 520px;
		overflow: visible;
	}
	#Office_website_3_mob {
		left: 0px;
		top: 35px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#aval_website_3_mob {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 68px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#seats_website_3_mob {
		position: absolute;
		width: 40px;
		height: 57px;
		left: 146px;
		top: 520px;
		overflow: visible;
	}
	#n_200_m2_website_3_mob {
		left: 0px;
		top: 35px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#seats_website_3_mob_d {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#cost_website_3_mob {
		position: absolute;
		width: 91px;
		height: 57px;
		left: 7px;
		top: 520px;
		overflow: visible;
	}
	#Confidential_website_3_mob {
		left: 0px;
		top: 35px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#cost_website_3_mob_d {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#organizer_grp_website_3_mob {
		position: absolute;
		width: 320px;
		height: 79px;
		left: 7px;
		top: 612px;
		overflow: visible;
	}
	#n__Room_website_3_mob {
		left: 0px;
		top: 35px;
		position: absolute;
		overflow: visible;
		width: 321px;
		height: 44px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#organizer_website_3_mob {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#enroll_grp_btn_website_3_mob {
		position: absolute;
		width: 324px;
		height: 53px;
		left: 7px;
		top: 716px;
		overflow: visible;
	}
	#bdr_enroll_website_3_mob {
		fill: rgba(248,90,71,1);
	}
	.bdr_enroll_website_3_mob {
		filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 333.444px;
		height: 62.445px;
		left: 0px;
		top: 0px;
	}
	#enroll_lbl_website_3_mob {
		left: 119px;
		top: 16px;
		position: absolute;
		overflow: visible;
		width: 87px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#Loved_by_businesses_and_indivi_mob {
		left: 33px;
		top: 3918px;
		display: none;
		position: absolute;
		overflow: visible;
		width: 338px;
		height: 66px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#testimonial_scroll_website_3_m {
		display: none;
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 355px;
		left: 11px;
		top: 4001px;
		overflow: hidden;
	}
	#Base_website_3_mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(235,235,235,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Base_website_3_mob {
		position: absolute;
		overflow: visible;
		width: 322px;
		height: 300px;
		left: 22px;
		top: 19px;
	}
	#Los_Angeles_CA_website_3_mob {
		left: 112px;
		top: 261px;
		position: absolute;
		overflow: visible;
		width: 78px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(102,102,102,1);
	}
	#Jane_Cooper_website_3_mob {
		left: 112px;
		top: 243px;
		position: absolute;
		overflow: visible;
		width: 72px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(51,51,51,1);
	}
	#Image_down_website_3_mob_ds {
		position: absolute;
		width: 41px;
		height: 40px;
		left: 54px;
		top: 237px;
		overflow: visible;
	}
	#intro_website_3_mob_dt {
		left: 54px;
		top: 102px;
		position: absolute;
		overflow: visible;
		width: 229px;
		height: 105px;
		line-height: 28px;
		margin-top: -6px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#Star_1_website_3_mob {
		fill: rgba(251,176,64,1);
	}
	.Star_1_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 13.097px;
		height: 12.277px;
		left: 54.987px;
		top: 61.698px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Star_2_website_3_mob {
		fill: rgba(251,176,64,1);
	}
	.Star_2_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 13.097px;
		height: 12.277px;
		left: 71.601px;
		top: 61.698px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Star_3_website_3_mob {
		fill: rgba(251,176,64,1);
	}
	.Star_3_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 13.097px;
		height: 12.277px;
		left: 88.215px;
		top: 61.698px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Star_4_website_3_mob {
		fill: rgba(251,176,64,1);
	}
	.Star_4_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 13.097px;
		height: 12.277px;
		left: 104.829px;
		top: 61.698px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Star_5_website_3_mob {
		fill: rgba(251,176,64,1);
	}
	.Star_5_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 13.097px;
		height: 12.277px;
		left: 121.443px;
		top: 61.698px;
		transform: matrix(1,0,0,1,0,0);
	}
	#small_heading_website_3_mob {
		display: none;
		left: 42px;
		top: 4971px;
		position: absolute;
		overflow: visible;
		width: 307px;
		white-space: nowrap;
		line-height: 33px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.2px;
	}
	#subscribe_lbl_website_3_mob {
		display: none;
		left: 34px;
		top: 4914px;
		position: absolute;
		overflow: visible;
		width: 324px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.2px;
	}
	#input_subscirbe_website_3_mob {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.input_subscirbe_website_3_mob {
		display: none;
		position: absolute;
		overflow: visible;
		width: 282px;
		height: 54px;
		left: 55px;
		top: 5030px;
		font-size: 15px;
		border: 2px solid #DF847E;
		border-radius: 7px;
		padding-left: 10px;
	}
	#subscribe_grp_website_3_mob {
		display: none;
		position: absolute;
		width: 149px;
		height: 54px;
		left: 121px;
		top: 5112px;
		overflow: visible;
	}
	#subscribe_bdr_website_3_mob {
		fill: rgba(248,90,71,1);
	}
	.subscribe_bdr_website_3_mob {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 167px;
		height: 72px;
		left: 0px;
		top: 0px;
	}
	#Subscribe_lbl_website_3_mob_d {
		left: 31px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 89px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#copyright_line_website_3_mob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.copyright_line_website_3_mob {
		overflow: visible;
		position: absolute;
		width: 324px;
		height: 1px;
		left: 36.75px;
		top: 4900.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_Copyright_Operate_Live_websi_mob {
		left: 29.25px;
		top: 4930px;
		position: absolute;
		overflow: visible;
		width: 263px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#n_234567890_mobile_number_webs_OB {
		left: 55.5px;
		top: 4562px;
		position: absolute;
		overflow: visible;
		width: 83px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#affanghanioperatelive_email_ob_Mob {
		left: 55.5px;
		top: 4608px;
		position: absolute;
		overflow: visible;
		width: 167px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#address_website_mob_ob {
		left: 55.5px;
		top: 4654px;
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#location_img_website_Ob_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 11.5px;
		top: 4662px;
		overflow: visible;
	}
	#email_address_website_2 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 11.5px;
		top: 4604px;
		overflow: visible;
	}
	#phone_number_website_ob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 11.5px;
		top: 4554px;
		overflow: visible;
	}
	#Connect_Unlock_Unlimited__poss {
		left: 137.5px;
		top: 4747px;
		position: absolute;
		overflow: visible;
		width: 233px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#quote_left_webstie_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 112.5px;
		top: 4753px;
		overflow: visible;
	}
	#quote_right_website_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 335.5px;
		top: 4778px;
		overflow: visible;
	}
	#partner_img_and_lbl_scroll {
		mix-blend-mode: normal;
		position: absolute;
		width: 324px;
		height: 64.849px;
		left: 33px;
		top: 732px;
		display: inline-flex;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#bg_partner_mob {
		fill: transparent;
	}
	.bg_partner_mob {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 130px;
		left: -89px;
		top: -50px;
		background:  rgba( 255, 255, 255, 0.5 );
		box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
		backdrop-filter:  blur( 10px );
		-webkit-backdrop-filter:  blur( 10px );
		border-radius:  10px;
		border:  1px solid rgba( 255, 255, 255, 0.18 );
	}
	#partner_lbls_grp_web_3 {
		position: relative;
		width: 143px;
		height: 27px;
		left: 4px;
		display: inline-flex;
		top: 9px;
		overflow: visible;
	}
	#google_lbl_partner_mob_website {
		left: 30px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 114px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#google_1_mob_website_3 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}

	  /* Menu */
	  #menuToggle
	  {
	  display: block;
	  position: fixed;
	  width: 40px;
	  height: 40px;
	  left: 34px;
	  top: 25px;
	  
	  z-index: 1;
	  
	  -webkit-user-select: none;
	  user-select: none;
	  }
	  
	  #menuToggle li
	  {
	  text-decoration: none;
	  color: #232323;
	  
	  transition: color 0.3s ease;
	  }
	  
	  #menuToggle li:hover
	  {
	  color: #ff4f4f;
	  }
	  
	  
	  #menuToggle input
	  {
	  display: block;
	  width: 40px;
	  height: 32px;
	  position: absolute;
	  top: -7px;
	  left: -5px;
	  
	  cursor: pointer;
	  
	  opacity: 0; /* hide this */
	  z-index: 2; /* and place it over the hamburger */
	  
	  -webkit-touch-callout: none;
	  }
	  
	  /*
	  * Just a quick hamburger
	  */
	  #menuToggle span
	  {
	  display: block;
	  width: 33px;
	  height: 4px;
	  margin-bottom: 5px;
	  position: relative;
	  
	  background: #6495ED;
	  border-radius: 3px;
	  
	  z-index: 1;
	  
	  transform-origin: 4px 0px;
	  
	  transition: transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	  background: 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	  opacity: 0.55s ease;
	  }
	  
	  #menuToggle span:first-child
	  {
	  transform-origin: 0% 0%;
	  }
	  
	  #menuToggle span:nth-last-child(2)
	  {
	  transform-origin: 0% 100%;
	  }
	  
	  /* 
	  * Transform all the slices of hamburger
	  * into a crossmark.
	  */
	  #menuToggle input:checked ~ span
	  {
	  opacity: 1;
	  transform: rotate(45deg) translate(-2px, -1px);
	  background: rgba(255,75,83,1);
	  }
	  
	  /*
	  * But let's hide the middle one.
	  */
	  #menuToggle input:checked ~ span:nth-last-child(3)
	  {
	  opacity: 0;
	  transform: rotate(0deg) scale(0.2, 0.2);
	  }
	  
	  /*
	  * Ohyeah and the last one should go the other direction
	  */
	  #menuToggle input:checked ~ span:nth-last-child(2)
	  {
	  transform: rotate(-45deg) translate(0, -1px);
	  }
	  
	  /*
	  * Make this absolute positioned
	  * at the top left of the screen
	  */
	  #menu
	  {
	  position: absolute;
	  width: 230px;
	  margin: -100px 0 0 -50px;
	  padding: 50px;
	  padding-top: 125px;
	  height: 100vh;
	  background: #ededed;
	  list-style-type: none;
	  -webkit-font-smoothing: antialiased;
	  /* to stop flickering of text in safari */
	  transform-origin: 0% 0%;
	  transform: translate(-100%, 0);
	  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	  background: rgba( 255, 255, 255, 0.25 );
	  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
	  backdrop-filter: blur( 10px );
	  -webkit-backdrop-filter: blur( 10px );
	  border-radius: 10px;
	  border: 1px solid rgba( 255, 255, 255, 0.18 );
	  }
	  
	  #menu li
	  {
	  padding: 10px 0;
	  font-size: 22px;
	  }
	  #menuToggle input:checked ~ ul
	  {
	  transform: none;
	  }
	  