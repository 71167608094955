	.mediaViewInfo {
		--web-view-name: PunctureWizards;
		--web-view-id: PunctureWizards;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: PunctureWizards;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#PunctureWizards {
		position: absolute;
		width: 1366px;
		height: 3287px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: PunctureWizards;
		--web-view-id: PunctureWizards;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#PWText {
		position: absolute;
		width: 509px;
		height: 209px;
		left: 53px;
		top: 228px;
		overflow: visible;
	}
	#PWExperience_the_finest_in_premi {
		left: 0px;
		top: 187px;
		position: absolute;
		overflow: visible;
		width: 375px;
		white-space: nowrap;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(6,18,50,1);
	}
	#PWCompany__Name {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 510px;
		height: 134px;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: bold;
		font-size: 57px;
		color: rgba(6,18,50,1);
	}
	#PWBGs {
		position: absolute;
		width: 683px;
		height: 599px;
		left: 683px;
		top: -71px;
		overflow: visible;
	}
	#PWtechnician-is-repairing-car-fl {
		position: absolute;
		width: 683px;
		height: 599px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWAnime_Edit {
		position: absolute;
		width: 55px;
		height: 55px;
		left: 53px;
		top: 28px;
		overflow: visible;
	}
	#PWGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 498.998px;
		left: 95.5px;
		top: 2769px;
		overflow: visible;
	}
	#PWGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 125.997px;
		overflow: visible;
	}
	#PWGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.PWRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#PWsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#PWlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#PWmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PWmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PWlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#PWn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.PWn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PWlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.PWlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PWtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.PWtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PWInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PWshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PWshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PWinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#PWwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWmed_shadow_space_website_7_bn {
		fill: rgba(255,255,255,1);
	}
	.PWmed_shadow_space_website_7_bn {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PWwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#PWn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PWaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PWaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PWlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#PWemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#PWphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#PWn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#PWContact_Us {
		left: 485px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 205px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -12.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(36,36,36,1);
	}
	#PWGroup_15118 {
		position: absolute;
		width: 1055px;
		height: 1400px;
		left: 156px;
		top: 648px;
		overflow: visible;
	}
	#PWGroup_15114 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWstopped-white-car-with-punctur {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 462px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PWScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#PWIt_is_a_long_established_fact_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PWVISION {
		left: 462px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PWGroup_15115 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 500px;
		overflow: visible;
	}
	#PWshane-rounce-mgt2BlBgprI-unspl {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 655px;
		top: 0px;
		overflow: visible;
	}
	#PWScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 0px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PWScroll_Group_28_b::-webkit-scrollbar{
		display: none;
	}
	#PWIt_is_a_long_established_fact__b {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PWMISSION {
		left: 0px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PWGroup_15116 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 1000px;
		overflow: visible;
	}
	#PWmason-jones-9e_ssIz4jB4-unspla {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWScroll_Group_28_ca {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 462px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PWScroll_Group_28_ca::-webkit-scrollbar{
		display: none;
	}
	#PWIt_is_a_long_established_fact__cb {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PWPRINCIPLES {
		left: 462px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PWGroup_15153 {
		position: absolute;
		width: 1286px;
		height: 428px;
		left: 40px;
		top: 2241px;
		overflow: visible;
	}
	#PWOur_Offerings {
		left: 579px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 130px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PWScroll_Group_90 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1286px;
		height: 380px;
		left: 0px;
		top: 48px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#PWScroll_Group_90::-webkit-scrollbar{
		display: none;
	}
	#PWGroup_15128 {
		position: absolute;
		width: 253px;
		height: 317px;
		left: 50px;
		top: 29px;
		overflow: visible;
	}
	#PWRectangle_750_ci {
		fill: url(#PWRectangle_750_ci);
	}
	.PWRectangle_750_ci {
		filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 258px;
		height: 322px;
		left: 0px;
		top: 0px;
	}
	#PWtechnician-is-repairing-car-fl_cj {
		position: absolute;
		width: 231px;
		height: 262px;
		left: 11px;
		top: 11px;
		overflow: visible;
	}
	#PWPuncture {
		left: 11px;
		top: 281px;
		position: absolute;
		overflow: visible;
		width: 232px;
		height: 27px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
