	.mediaViewInfo {
		--web-view-name: SweetTreat Mob;
		--web-view-id: SweetTreat_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: SweetTreat_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#SweetTreat_Mob {
		position: absolute;
		width: 375px;
		height: 3510px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: SweetTreat Mob;
		--web-view-id: SweetTreat_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#STMOBGroup_15132 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 8.5px;
		top: 2905.904px;
		overflow: visible;
	}
	#STMOBGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 30px;
		top: 73.095px;
		overflow: visible;
	}
	#STMOBRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.STMOBRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#STMOBGroup_15050 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STMOBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 480.096px;
		overflow: visible;
	}
	#STMOBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#STMOBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.STMOBmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#STMOBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#STMOBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.STMOBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#STMOBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.STMOBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#STMOBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.STMOBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#STMOBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#STMOBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.STMOBshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#STMOBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#STMOBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STMOBmed_shadow_space_website_7_bl {
		fill: rgba(255,255,255,1);
	}
	.STMOBmed_shadow_space_website_7_bl {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#STMOBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#STMOBn_234567890_mobile_number_shad {
		left: 48px;
		top: 293.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#STMOBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 340.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#STMOBaddress_med_shadow_space_websi {
		left: 35px;
		top: 390.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#STMOBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 392.096px;
		overflow: visible;
	}
	#STMOBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 339.096px;
		overflow: visible;
	}
	#STMOBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 286.096px;
		overflow: visible;
	}
	#STMOBn_Copyright_Operate_Live_OPC_P {
		left: 27px;
		top: 565.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#STMOBContact_Us {
		left: 127.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -22.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#STMOBGroup_15134 {
		position: absolute;
		width: 366px;
		height: 1637px;
		left: 7px;
		top: 753px;
		overflow: visible;
	}
	#STMOBGroup_15126 {
		position: absolute;
		width: 366px;
		height: 477.079px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STMOBshree-iyer-8vzLN4oHcGQ-unsplas {
		position: absolute;
		width: 220px;
		height: 220px;
		left: 70.5px;
		top: 0px;
		overflow: visible;
	}
	#STMOBScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 0px;
		top: 323.079px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#STMOBScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#STMOBIt_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#STMOBVISION {
		left: 2px;
		top: 258.079px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STMOBGroup_15130 {
		position: absolute;
		width: 366px;
		height: 477px;
		left: 0px;
		top: 580px;
		overflow: visible;
	}
	#STMOBaditya-joshi-uWI48cpw7Vo-unspl {
		position: absolute;
		width: 220px;
		height: 220px;
		left: 70.5px;
		top: 0px;
		overflow: visible;
	}
	#STMOBScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 0px;
		top: 323px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#STMOBScroll_Group_28_b::-webkit-scrollbar{
		display: none;
	}
	#STMOBIt_is_a_long_established_fact__b {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#STMOBMISSION {
		left: 2px;
		top: 258px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STMOBGroup_15131 {
		position: absolute;
		width: 366px;
		height: 477px;
		left: 0px;
		top: 1160px;
		overflow: visible;
	}
	#STMOBsanju-m-gurung-W1qp7p_laj0-uns {
		position: absolute;
		width: 220px;
		height: 220px;
		left: 70.5px;
		top: 0px;
		overflow: visible;
	}
	#STMOBScroll_Group_28_ca {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 0px;
		top: 323px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#STMOBScroll_Group_28_ca::-webkit-scrollbar{
		display: none;
	}
	#STMOBIt_is_a_long_established_fact__ca {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#STMOBPRINCIPLES {
		left: 2px;
		top: 258px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STMOBGroup_15133 {
		position: absolute;
		width: 375px;
		height: 332px;
		left: 0px;
		top: 2490px;
		overflow: visible;
	}
	#STMOBOur_Greatest_Drinks {
		left: 95px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 187px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STMOBScroll_Group_90 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 305px;
		left: 0px;
		top: 27px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#STMOBScroll_Group_90::-webkit-scrollbar{
		display: none;
	}
	#STMOBGroup_15128 {
		position: absolute;
		width: 203px;
		height: 254px;
		left: 15px;
		top: 22px;
		overflow: visible;
	}
	#STMOBRectangle_750_cg {
		fill: url(#STMOBRectangle_750_cg);
	}
	.STMOBRectangle_750_cg {
		filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 208px;
		height: 259px;
		left: 0px;
		top: 0px;
	}
	#STMOBprchi-palwe-wwgulilpmlg-unspla {
		position: absolute;
		width: 185px;
		height: 210px;
		left: 9px;
		top: 9px;
		overflow: visible;
	}
	#STMOBJuice_1 {
		left: 9px;
		top: 226px;
		position: absolute;
		overflow: visible;
		width: 186px;
		height: 20px;
		line-height: 41px;
		margin-top: -13px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#STMOBGroup_15158 {
		position: absolute;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STMOBGroup_15129 {
		position: absolute;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STMOBGroup_15124 {
		position: absolute;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STMOBstefano-zocca-1p-Mx-SaLT0-unsp {
		position: absolute;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STMOBRectangle_749 {
		opacity: 0.24;
		fill: rgba(0,0,0,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.STMOBRectangle_749 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
	}
	#STMOBAnime_Edit {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 13px;
		top: 52px;
		overflow: visible;
	}
	#STMOBYour_Business_Name {
		left: 77px;
		top: 66px;
		position: absolute;
		overflow: visible;
		width: 189px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#STMOBIndulge_in_a_world_of_sweetnes {
		left: 13px;
		top: 243px;
		position: absolute;
		overflow: visible;
		width: 351px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 23px;
		color: rgba(255,255,255,1);
	}
	#STMOBGroup_15157 {
		position: absolute;
		width: 51.122px;
		height: 67.188px;
		left: 161.939px;
		top: 566.094px;
		overflow: visible;
	}
	#STMOBPath_7785 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.STMOBPath_7785 {
		overflow: visible;
		position: absolute;
		width: 32.202px;
		height: 62.657px;
		left: 21.92px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#STMOBPath_7786 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.STMOBPath_7786 {
		overflow: visible;
		position: absolute;
		width: 32.202px;
		height: 60.011px;
		left: 0px;
		top: 10.178px;
		transform: matrix(1,0,0,1,0,0);
	}
	#STMOBIcon_awesome-arrow-down {
		fill: rgba(255,255,255,1);
	}
	.STMOBIcon_awesome-arrow-down {
		overflow: visible;
		position: absolute;
		width: 27.916px;
		height: 28.642px;
		left: 11.964px;
		top: 19.654px;
		transform: matrix(1,0,0,1,0,0);
	}
	body::-webkit-scrollbar{
		display: none;	
	}
