body {
    background:
    linear-gradient(
      rgba(0, 0, 0, 0.3), 
      rgba(0, 0, 0, 0.3), 
    );
  
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

* {
    margin: 0;
    padding: 0;
}