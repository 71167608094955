	.mediaViewInfo {
		--web-view-name: CycleHaven mob;
		--web-view-id: CycleHaven_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: CycleHaven_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#CycleHaven_mob {
		position: absolute;
		width: 390px;
		height: 3702px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(5,5,5,1);
		overflow: hidden;
		--web-view-name: CycleHaven mob;
		--web-view-id: CycleHaven_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#CHMAnime_Edit {
		position: absolute;
		width: 66px;
		height: 66px;
		left: 24px;
		top: 36px;
		overflow: visible;
	}
	#CHMGroup_15122 {
		position: absolute;
		width: 358px;
		height: 588.998px;
		left: 16px;
		top: 3099.998px;
		overflow: visible;
	}
	#CHMGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 45.5px;
		top: 59.997px;
		overflow: visible;
	}
	#CHMRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.CHMRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#CHMGroup_15050 {
		position: absolute;
		width: 358px;
		height: 316px;
		left: 0px;
		top: 272.998px;
		overflow: visible;
	}
	#CHMsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 194px;
		overflow: visible;
	}
	#CHMlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#CHMmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CHMmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHMlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#CHMn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.CHMn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHMlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.CHMlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHMtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.CHMtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHMInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#CHMshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CHMshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHMinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#CHMwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHMmed_shadow_space_website_7_bk {
		fill: rgba(255,255,255,1);
	}
	.CHMmed_shadow_space_website_7_bk {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHMwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#CHMn_234567890_mobile_number_shad {
		left: 35px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#CHMaffanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#CHMaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#CHMlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#CHMemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#CHMphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHMn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#CHMContact_Us {
		left: 109.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(255,255,255,1);
	}
	#CHMCTA {
		position: absolute;
		width: 374px;
		height: 243px;
		left: 16px;
		top: 135px;
		overflow: visible;
	}
	#CHMScroll_Group_92 {
		mix-blend-mode: normal;
		position: absolute;
		width: 374px;
		height: 91px;
		left: 0px;
		top: 152px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHMLorem_ipsum_dolor_sit_amet_con {
		opacity: 0.6;
		left: 7px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 352px;
		height: 92px;
		line-height: 24px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#CHMCompany_Name {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		color: rgba(255,255,255,1);
	}
	#CHMGroup_15155 {
		position: absolute;
		width: 426.065px;
		height: 284.043px;
		left: -18.033px;
		top: 366px;
		overflow: visible;
	}
	#CHMEllipse_45 {
		filter: blur(22px);
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHMEllipse_45 {
		position: absolute;
		overflow: visible;
		width: 384px;
		height: 140px;
		left: 80px;
		top: 240px;
	}
	#CHMwhite-bicycle-standing-park {
		position: absolute;
		width: 426.065px;
		height: 284.043px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHMGroup_15109 {
		position: absolute;
		width: 379px;
		height: 1707px;
		left: 11px;
		top: 780px;
		overflow: visible;
	}
	#CHMGroup_15105 {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHMmika-baumeister-dp9AxaKHdVg-un {
		filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#CHMGroup_15104 {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#CHMScroll_Group_28_cb {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHMIt_is_a_long_established_fact__cc {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#CHMVISION_cd {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHMGroup_15106 {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHMmika-baumeister-dp9AxaKHdVg-un_b {
		filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#CHMGroup_15104_ca {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#CHMScroll_Group_28_cb {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHMIt_is_a_long_established_fact__cc {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#CHMVISION_cd {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHMGroup_15106_ce {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 592px;
		overflow: visible;
	}
	#CHMpatrick-tomasso-1NTFSnV-KLs-un {
		filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#CHMGroup_15104_cg {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#CHMScroll_Group_28_ch {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHMIt_is_a_long_established_fact__ci {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#CHMMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHMGroup_15106_ck {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 1184px;
		overflow: visible;
	}
	#CHMframes-for-your-heart-iOLHAIax {
		filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#CHMGroup_15104_cm {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#CHMScroll_Group_28_cn {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHMIt_is_a_long_established_fact__co {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#CHMPRINCIPLES {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHMScroll_Group_62 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 309px;
		left: 0px;
		top: 2699px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#CHMGroup_15031 {
		position: absolute;
		width: 296px;
		height: 240px;
		left: 11px;
		top: 30px;
		overflow: visible;
	}
	#CHMRectangle_112 {
		fill: rgba(255,255,255,1);
	}
	.CHMRectangle_112 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 314px;
		height: 258px;
		left: 0px;
		top: 0px;
	}
	#CHMMask_Group_4 {
		position: absolute;
		width: 272px;
		height: 164px;
		left: 12px;
		top: 12.001px;
		overflow: visible;
	}
	#CHMGroup_9694 {
		position: absolute;
		width: 218.774px;
		height: 19px;
		left: 11.226px;
		top: 184px;
		overflow: visible;
	}
	#CHMBangalore {
		opacity: 0.5;
		left: 14.774px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 205px;
		height: 19px;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#CHMgeo-alt {
		position: absolute;
		width: 12.521px;
		height: 17.482px;
		left: 0px;
		top: 1.518px;
		overflow: visible;
	}
	#CHMPath_2927 {
		fill: rgba(254,120,71,1);
	}
	.CHMPath_2927 {
		overflow: visible;
		position: absolute;
		width: 12.521px;
		height: 17.482px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHMCycle {
		left: 13px;
		top: 206.998px;
		position: absolute;
		overflow: visible;
		width: 272px;
		height: 22px;
		text-align: left;
		font-family: Sen;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#CHMOur_Collection {
		filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 116px;
		top: 2640.999px;
		position: absolute;
		overflow: visible;
		width: 159px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(255,255,255,1);
	}
