
	.mediaViewInfo {
		--web-view-name: TextureFusion Mob;
		--web-view-id: TextureFusion_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: TextureFusion_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#TextureFusion_Mob {
		position: absolute;
		width: 375px;
		height: 3718px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: TextureFusion Mob;
		--web-view-id: TextureFusion_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#TFM_MoB_35Group_15132 {
		position: absolute;
		width: 375px;
		height: 616px;
		left: 0px;
		top: 3102px;
		overflow: visible;
	}
	#TFM_MoB_35jj-ying-7JX0-bfiuxQ-unsplash {
		position: absolute;
		width: 375px;
		height: 572px;
		left: 0px;
		top: 44px;
		overflow: visible;
	}
	#TFM_MoB_35Group_15050 {
		position: absolute;
		width: 358px;
		height: 600.096px;
		left: 8px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 67px;
		top: 479.096px;
		overflow: visible;
	}
	#TFM_MoB_35linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TFM_MoB_35med_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#TFM_MoB_35n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.TFM_MoB_35n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TFM_MoB_35linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.TFM_MoB_35linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TFM_MoB_35top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.TFM_MoB_35top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TFM_MoB_35Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TFM_MoB_35shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#TFM_MoB_35whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35med_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.TFM_MoB_35med_shadow_space_website_7_ {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#TFM_MoB_35n_234567890_mobile_number_shad {
		left: 35px;
		top: 292.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TFM_MoB_35affanghanioperatelive_email_sh {
		left: 35px;
		top: 339.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TFM_MoB_35address_med_shadow_space_websi {
		left: 35px;
		top: 389.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TFM_MoB_35location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 391.096px;
		overflow: visible;
	}
	#TFM_MoB_35email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 338.096px;
		overflow: visible;
	}
	#TFM_MoB_35phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 285.096px;
		overflow: visible;
	}
	#TFM_MoB_35n_Copyright_Operate_Live_OPC_P {
		left: 17px;
		top: 564.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#TFM_MoB_35Contact_Us {
		left: 117.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: left;
		font-family: Microsoft PhagsPa;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#TFM_MoB_35Rectangle_757 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TFM_MoB_35Rectangle_757 {
		position: absolute;
		overflow: visible;
		width: 274px;
		height: 183px;
		left: 49.5px;
		top: 56px;
	}
	#TFM_MoB_35Group_15117 {
		position: absolute;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35christian-holzinger-CUY_YHhCFl {
		position: absolute;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35Rectangle_748 {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
	}
	.TFM_MoB_35Rectangle_748 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35Anime_Edit {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 20px;
		top: 38px;
		overflow: hidden;
        border-radius: 50%;
	}
	#TFM_MoB_35Your_Business_Name {
		left: 91px;
		top: 55px;
		position: absolute;
		overflow: visible;
		width: 285px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFM_MoB_35Elevate_Your_Space_with_Unique {
		left: 20px;
		top: 231px;
		position: absolute;
		overflow: visible;
		width: 311px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFM_MoB_35Group_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 20px;
		top: 439px;
		overflow: visible;
	}
	#TFM_MoB_35Rectangle_3 {
		fill: rgba(183,177,186,1);
	}
	.TFM_MoB_35Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35Available_Now {
		left: 37px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#TFM_MoB_35pawel-czerwinski-eBd0dPuR-bo-u {
		opacity: 0.5;
		position: absolute;
		width: 375px;
		height: 1779px;
		left: 0px;
		top: 763px;
		overflow: visible;
	}
	#TFM_MoB_35Group_15174 {
		position: absolute;
		width: 332px;
		height: 1706px;
		left: 22px;
		top: 800px;
		overflow: visible;
	}
	#TFM_MoB_35Group_15141 {
		position: absolute;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35Rectangle_752 {
		fill: rgba(223,221,219,1);
	}
	.TFM_MoB_35Rectangle_752 {
		position: absolute;
		overflow: visible;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35n_3430759_5249503 {
		position: absolute;
		width: 270px;
		height: 270px;
		left: 31px;
		top: 33px;
		overflow: visible;
	}
	#TFM_MoB_35Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 332px;
		height: 130px;
		left: 0px;
		top: 401px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TFM_MoB_35It_is_a_long_established_fact_ {
		left: 13px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 316px;
		height: 119px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#TFM_MoB_35VISION {
		left: 15px;
		top: 333px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFM_MoB_35Group_15172 {
		position: absolute;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 580px;
		overflow: visible;
	}
	#TFM_MoB_35Rectangle_752_bu {
		fill: rgba(183,177,186,1);
	}
	.TFM_MoB_35Rectangle_752_bu {
		position: absolute;
		overflow: visible;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35n_60450_OFC6C30 {
		position: absolute;
		width: 270px;
		height: 270px;
		left: 31px;
		top: 33px;
		overflow: visible;
	}
	#TFM_MoB_35Scroll_Group_28_bw {
		mix-blend-mode: normal;
		position: absolute;
		width: 332px;
		height: 130px;
		left: 0px;
		top: 401px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TFM_MoB_35It_is_a_long_established_fact__bx {
		left: 13px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 316px;
		height: 119px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#TFM_MoB_35MISSION {
		left: 15px;
		top: 333px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFM_MoB_35Group_15173 {
		position: absolute;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 1160px;
		overflow: visible;
	}
	#TFM_MoB_35Rectangle_752_b {
		fill: rgba(231,211,203,1);
	}
	.TFM_MoB_35Rectangle_752_b {
		position: absolute;
		overflow: visible;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35n_20566_OCCYZ60 {
		position: absolute;
		width: 270px;
		height: 270px;
		left: 31px;
		top: 33px;
		overflow: visible;
	}
	#TFM_MoB_35Scroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 332px;
		height: 130px;
		left: 0px;
		top: 401px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TFM_MoB_35It_is_a_long_established_fact__b {
		left: 13px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 316px;
		height: 119px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#TFM_MoB_35PRINCIPLES {
		left: 15px;
		top: 333px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFM_MoB_35Group_15170 {
		position: absolute;
		width: 375px;
		height: 387px;
		left: 0px;
		top: 2615px;
		overflow: visible;
	}
	#TFM_MoB_35Scroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 349px;
		left: 0px;
		top: 38px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#TFM_MoB_35Group_15144 {
		position: relative;
        margin-right: 30px;
		width: 219px;
		height: 298px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#TFM_MoB_35Rectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.TFM_MoB_35Rectangle_754 {
		filter: drop-shadow(5px 5px 1px rgba(233, 221, 223, 1));
		position: absolute;
		overflow: visible;
		width: 225.5px;
		height: 304.5px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35luca-laurence-FseXc3OsIic-unsp {
		position: absolute;
		width: 215px;
		height: 241px;
		left: 2px;
		top: 29px;
		overflow: visible;
	}
	#TFM_MoB_35Title {
		left: 6px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 208px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TFM_MoB_35n_10000 {
		left: 6px;
		top: 270px;
		position: absolute;
		overflow: visible;
		width: 208px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TFM_MoB_35Group_15149 {
		position: absolute;
		width: 336px;
		height: 38px;
		left: 20px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35NEW_DESIGN {
		left: 0px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 130px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TFM_MoB_35Group_15145 {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 191px;
		top: 0px;
		overflow: visible;
	}
	#TFM_MoB_35Rectangle_753 {
		fill: rgba(183,177,186,1);
	}
	.TFM_MoB_35Rectangle_753 {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: 0px;
	}
	#TFM_MoB_35View_More {
		left: 38px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 71px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}