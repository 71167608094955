.mediaViewInfo {
    --web-view-name: top profile;
    --web-view-id: top_profile;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: top_profile;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#top_profile {
    position: relative;
    width: 100%;
    height: 664px;
    margin-top: 75px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: top profile;
    --web-view-id: top_profile;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_bg_top_profile {
    position: relative;
    width: 100%;
    height: 641px;
    /* left: 13px; */
    top: 0px;
    overflow: visible;
}
#top_bnner_mob_top_profile {
    position: absolute;
    width: 100%;
    height: 160px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#banner_img_mob_top_profile {
    position: absolute;
    width: 100%;
    height: 160px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#mid_circle_grp_profile_mob_top {
    position: absolute;
    width: 100%;
    height: 528px;
    left: 0px;
    top: 113px;
    overflow: visible;
}
#profil_info_bdr_mob_top_profil {
    fill: rgba(255,255,255,1);
}
.profil_info_bdr_mob_top_profil {
    filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 479px;
    left: 0px;
    top: 57px;
}
#pfp_pic_mob_top_profile {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: white;
    left: 47px;
    top: 0px;
    border-radius: 50%;
    overflow: hidden;
}
#Mohammed_Affan_mob_top_profile {
    left: 15px;
    top: 124px;
    position: absolute;
    overflow: visible;
    width: 198px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#location_grp_mob_top_profile {
    position: absolute;
    width: 239px;
    height: 22px;
    left: 15px;
    top: 156px;
    overflow: visible;
}
#India_Karnataka_mob_top_profil {
    left: 39px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 201px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#india_img_mob_top_profile {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 2px;
    top: 0px;
    overflow: visible;
}
#skills_grp__mob_top_profile {
    position: absolute;
    width: 153px;
    height: 22px;
    left: 15px;
    top: 188px;
    overflow: visible;
}
#skillmob_top_profile_ {
    position: absolute;
    width: 29px;
    height: 22px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#mob_top_profile_designer_skill {
    left: 39px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#connections_grp_mob_top_profil {
    position: absolute;
    width: 128px;
    height: 31px;
    left: 138px;
    top: 262px;
    overflow: visible;
}
#all_con_mob_top_profile {
    fill: rgba(163,233,200,1);
}
.all_con_mob_top_profile {
    position: absolute;
    overflow: visible;
    width: 128px;
    height: 31px;
    left: 0px;
    top: 0px;
}
#mob_top_profile_circle_ {
    fill: rgba(255,255,255,1);
}
.mob_top_profile_circle_ {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 3px;
    top: 3px;
}
#n_23454mob_top_profile {
    left: 5px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#Connections_lbl_mob_top_profil {
    left: 33px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#QR_btn_grp_mob_top_profile {
    position: absolute;
    width: 36px;
    height: 36px;
    left: 161px;
    top: 57px;
    overflow: visible;
}
#QR_circle_bdr_mob_top_profile {
    fill: rgba(255,255,255,1);
}
.QR_circle_bdr_mob_top_profile {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 54px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#QR_img_mob_top_profile {
    position: absolute;
    width: 23px;
    height: 23px;
    left: 7px;
    top: 7px;
    overflow: visible;
}
#profile_link_grp__mob_top_prof {
    position: absolute;
    width: 36px;
    height: 36px;
    right: 50px;
    top: 60px;
    overflow: visible;
}
#offer_circle_mob_top_profile {
    fill: rgba(255,255,255,1);
}
.offer_circle_mob_top_profile {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 54px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#url_img_mob_top_profile {
    position: absolute;
    width: 23px;
    height: 23px;
    left: 7px;
    top: 7px;
    overflow: visible;
}
#About_lbl_lorem_mob_top_profil {
    left: 17px;
    top: 313px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#Scroll_Group_9_mob_top_profile {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 178px;
    left: 0px;
    top: 337px;
    overflow-x: hidden;
    overflow-y: auto;
}
#Lorem_ipsum_dolor_sit_amet_ui_mob {
    left: 17px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 352px;
    height: 140px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#followers_grp_lbl_mob_top_prof {
    position: absolute;
    width: 113px;
    height: 31px;
    left: 15px;
    top: 262px;
    overflow: visible;
}
#overall_mob_top_profile {
    fill: rgba(163,233,200,1);
}
.overall_mob_top_profile {
    position: absolute;
    overflow: visible;
    width: 113px;
    height: 31px;
    left: 0px;
    top: 0px;
}
#mob_top_profile_circle {
    fill: rgba(255,255,255,1);
}
.mob_top_profile_circle {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 3px;
    top: 3px;
}
#n_23mob_top_profile {
    left: 5px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#Followers_lbl_mob_top_profile {
    left: 33px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#skills_grp_mob_top_profile {
    position: absolute;
    width: 226px;
    height: 22px;
    left: 15px;
    top: 220px;
    overflow: visible;
}
#skill_img__mob_top_profile {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#UIUX_designer_skill_lbl_mob_to {
    left: 39px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 188px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#pen_edit_ban_img {
    position: absolute;
    width: 23px;
    height: 23px;
    left:90%;
    top: 15px;
    overflow: visible;
}
#pen_edit_banner_img {
    position: absolute;
    width: 23px;
    height: 23px;
    left:90%;
    top: 182px;
    overflow: visible;
}