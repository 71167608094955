	.mediaViewInfo {
		--web-view-name: PlantPlateDelights;
		--web-view-id: PlantPlateDelights;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: PlantPlateDelights;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#PlantPlateDelights {
		position: absolute;
		width: 1366px;
		height: 3284px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: PlantPlateDelights;
		--web-view-id: PlantPlateDelights;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#PPD_26_PCGroup_15086 {
		position: absolute;
		width: 1335px;
		height: 826px;
		left: 31px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCMask_Group_1 {
		position: absolute;
		width: 901px;
		height: 826px;
		left: 434px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCRectangle_3 {
		fill: rgba(103,123,200,1);
	}
	.PPD_26_PCRectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 4px;
		top: 549px;
	}
	#PPD_26_PCOrder_Now {
		left: 49px;
		top: 562px;
		position: absolute;
		overflow: visible;
		width: 81px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PCHave_no_time_to_prepare_food {
		left: 0px;
		top: 256px;
		position: absolute;
		overflow: visible;
		width: 402px;
		white-space: nowrap;
		line-height: 70px;
		margin-top: -8px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 54px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCChoose_one_of_our_plans_enter_ {
		opacity: 0.5;
		left: 4px;
		top: 430px;
		position: absolute;
		overflow: visible;
		width: 443px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCFOODCOURT {
		left: 4px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 203px;
		white-space: nowrap;
		line-height: 51px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Semplicità;
		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCGroup_15087 {
		position: absolute;
		width: 1362.349px;
		height: 620.792px;
		left: 0px;
		top: 926px;
		overflow: visible;
	}
	#PPD_26_PCRectangle_7 {
		fill: rgba(255,255,255,1);
	}
	.PPD_26_PCRectangle_7 {
		position: absolute;
		overflow: visible;
		width: 750.61px;
		height: 576.32px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCMask_Group_2 {
		position: absolute;
		width: 808.531px;
		height: 620.791px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCGroup_4 {
		position: absolute;
		width: 185px;
		height: 51px;
		left: 915px;
		top: 523px;
		overflow: visible;
	}
	#PPD_26_PCRectangle_6 {
		fill: rgba(103,123,200,1);
	}
	.PPD_26_PCRectangle_6 {
		position: absolute;
		overflow: visible;
		width: 185px;
		height: 51px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCOrder_Now_bh {
		left: 46px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PCGroup_12 {
		position: absolute;
		width: 281px;
		height: 91.728px;
		left: 917px;
		top: 45px;
		overflow: visible;
	}
	#PPD_26_PCLine_2 {
		fill: transparent;
		stroke: rgba(103,123,200,1);
		stroke-width: 7px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCLine_2 {
		overflow: visible;
		position: absolute;
		width: 207.907px;
		height: 7px;
		left: 4.221px;
		top: 91.729px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCOUR_FOOD {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 282px;
		white-space: nowrap;
		line-height: 73px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 56px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCChoose_one_of_our_plans_enter__bl {
		opacity: 0.5;
		left: 990.349px;
		top: 321.4px;
		position: absolute;
		overflow: visible;
		width: 373px;
		white-space: nowrap;
		line-height: 25px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCChoose_one_of_our_plans_enter__bm {
		opacity: 0.5;
		left: 990.349px;
		top: 236.525px;
		position: absolute;
		overflow: visible;
		width: 317px;
		white-space: nowrap;
		line-height: 25px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCChoose_one_of_our_plans_enter__bn {
		opacity: 0.5;
		left: 990.349px;
		top: 447.709px;
		position: absolute;
		overflow: visible;
		width: 317px;
		white-space: nowrap;
		line-height: 25px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCGroup_9 {
		position: absolute;
		width: 197.171px;
		height: 46.113px;
		left: 916.836px;
		top: 189.746px;
		overflow: visible;
	}
	#PPD_26_PCAppetizers {
		left: 70.171px;
		top: 5.346px;
		position: absolute;
		overflow: visible;
		width: 128px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCGroup_6 {
		position: absolute;
		width: 46.113px;
		height: 46.113px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCEllipse_2 {
		fill: rgba(103,123,200,1);
	}
	.PPD_26_PCEllipse_2 {
		position: absolute;
		overflow: visible;
		width: 46.113px;
		height: 46.113px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCGroup_5 {
		position: absolute;
		width: 17.238px;
		height: 17.238px;
		left: 14.368px;
		top: 14.367px;
		overflow: visible;
	}
	#PPD_26_PCLine_3 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCLine_3 {
		overflow: visible;
		position: absolute;
		width: 21.238px;
		height: 4px;
		left: 0px;
		top: 8.619px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCLine_4 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCLine_4 {
		overflow: visible;
		position: absolute;
		width: 17.238px;
		height: 1px;
		transform: translate(0px, 0px) matrix(1,0,0,1,0,8.6191) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCGroup_10 {
		position: absolute;
		width: 232.513px;
		height: 46.113px;
		left: 916.836px;
		top: 274.619px;
		overflow: visible;
	}
	#PPD_26_PCMain_Courses {
		left: 73.513px;
		top: 5.348px;
		position: absolute;
		overflow: visible;
		width: 160px;
		white-space: nowrap;
		line-height: 58px;
		margin-top: -16.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCGroup_7 {
		position: absolute;
		width: 46.113px;
		height: 46.113px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCEllipse_2_by {
		fill: rgba(103,123,200,1);
	}
	.PPD_26_PCEllipse_2_by {
		position: absolute;
		overflow: visible;
		width: 46.113px;
		height: 46.113px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCGroup_5_bz {
		position: absolute;
		width: 17.238px;
		height: 17.238px;
		left: 14.368px;
		top: 14.369px;
		overflow: visible;
	}
	#PPD_26_PCLine_3_b {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCLine_3_b {
		overflow: visible;
		position: absolute;
		width: 21.238px;
		height: 4px;
		left: 0px;
		top: 8.619px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCLine_4_b {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCLine_4_b {
		overflow: visible;
		position: absolute;
		width: 17.238px;
		height: 1px;
		transform: translate(0px, 0px) matrix(1,0,0,1,0,8.6191) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCGroup_11 {
		position: absolute;
		width: 175.513px;
		height: 46.113px;
		left: 916.836px;
		top: 386.893px;
		overflow: visible;
	}
	#PPD_26_PCDesserts {
		left: 73.513px;
		top: 5.348px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCGroup_8 {
		position: absolute;
		width: 46.113px;
		height: 46.113px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCEllipse_2_b {
		fill: rgba(103,123,200,1);
	}
	.PPD_26_PCEllipse_2_b {
		position: absolute;
		overflow: visible;
		width: 46.113px;
		height: 46.113px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCGroup_5_b {
		position: absolute;
		width: 17.238px;
		height: 17.238px;
		left: 14.368px;
		top: 14.369px;
		overflow: visible;
	}
	#PPD_26_PCLine_3_ca {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCLine_3_ca {
		overflow: visible;
		position: absolute;
		width: 21.238px;
		height: 4px;
		left: 0px;
		top: 8.619px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCLine_4_ca {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCLine_4_ca {
		overflow: visible;
		position: absolute;
		width: 17.238px;
		height: 1px;
		transform: translate(0px, 0px) matrix(1,0,0,1,0,8.6191) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCGroup_15088 {
		position: absolute;
		width: 1366px;
		height: 708.171px;
		left: 0px;
		top: 1697px;
		overflow: visible;
	}
	#PPD_26_PCPath_7778 {
		fill: rgba(122,147,243,1);
	}
	.PPD_26_PCPath_7778 {
		overflow: visible;
		position: absolute;
		width: 1361.056px;
		height: 600.746px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCMask_Group_7 {
		opacity: 0.1;
		position: absolute;
		width: 1361.056px;
		height: 600.746px;
		left: 4.944px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCGroup_13 {
		position: absolute;
		width: 149px;
		height: 80.509px;
		left: 606px;
		top: 83.002px;
		overflow: visible;
	}
	#PPD_26_PCLine_2_cd {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 7px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCLine_2_cd {
		overflow: visible;
		position: absolute;
		width: 100.939px;
		height: 7px;
		left: 27.205px;
		top: 80.508px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCPLANS {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 150px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 50px;
		color: rgba(36,36,36,1);
	}
	#PPD_26_PCScroll_Group_87 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1296.253px;
		height: 491.913px;
		left: 32.92px;
		top: 216.258px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#PPD_26_PCGroup_142 {
		position: absolute;
		width: 555.54px;
		height: 431.494px;
		left: 673.713px;
		top: 27.418px;
		overflow: visible;
	}
	#PPD_26_PCRectangle_9 {
		fill: rgba(255,255,255,1);
	}
	.PPD_26_PCRectangle_9 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 573.54px;
		height: 449.494px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCMask_Group_4 {
		position: absolute;
		width: 555.54px;
		height: 431.494px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCRectangle_12 {
		opacity: 0.2;
		fill: rgba(36,36,36,1);
	}
	.PPD_26_PCRectangle_12 {
		position: absolute;
		overflow: visible;
		width: 555.54px;
		height: 431.494px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCOrder_1 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		left: 40.234px;
		top: 27.346px;
		position: absolute;
		overflow: visible;
		width: 106px;
		white-space: nowrap;
		line-height: 52px;
		margin-top: -11px;
		text-align: left;
		font-family: Hobo Std;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PCGroup_143 {
		position: absolute;
		width: 556.08px;
		height: 431.913px;
		left: 67px;
		top: 27px;
		overflow: visible;
	}
	#PPD_26_PCRectangle_8 {
		fill: rgba(255,255,255,1);
	}
	.PPD_26_PCRectangle_8 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 574.08px;
		height: 449.912px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCMask_Group_3 {
		position: absolute;
		width: 556.08px;
		height: 431.912px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCvictoria-shes-4MEL9XS-3JQ-unsp {
		opacity: 0.2;
		fill: rgba(0,0,0,1);
	}
	.PPD_26_PCvictoria-shes-4MEL9XS-3JQ-unsp {
		position: absolute;
		overflow: visible;
		width: 556.08px;
		height: 431.912px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCOrder_1_cp {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		left: 24.04px;
		top: 30.346px;
		position: absolute;
		overflow: visible;
		width: 106px;
		white-space: nowrap;
		line-height: 52px;
		margin-top: -11px;
		text-align: left;
		font-family: Hobo Std;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PCGroup_15091 {
		position: absolute;
		width: 1366px;
		height: 625.824px;
		left: 0px;
		top: 2658.176px;
		overflow: visible;
	}
	#PPD_26_PCText_Background {
		fill: rgba(255,255,255,1);
	}
	.PPD_26_PCText_Background {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 1384px;
		height: 353.641px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCGroup_15089 {
		position: absolute;
		width: 1366px;
		height: 287px;
		left: 0px;
		top: 338.824px;
		overflow: visible;
	}
	#PPD_26_PCsonny-mauricio-yhc4pSbl01A-uns {
		position: absolute;
		width: 1366px;
		height: 287px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCsonny-mauricio-yhc4pSbl01A-uns_cu {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_26_PCsonny-mauricio-yhc4pSbl01A-uns_cu {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 287px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCBorder {
		fill: rgba(204,204,204,1);
	}
	.PPD_26_PCBorder {
		overflow: visible;
		position: absolute;
		width: 1366px;
		height: 0.91px;
		left: 0px;
		top: 243.092px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCFollow_Us {
		left: 1022.626px;
		top: 71.43px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		line-height: 25px;
		margin-top: -2.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PCn_Copyright_Operate_Live_OPC_P {
		left: 71px;
		top: 254px;
		position: absolute;
		overflow: visible;
		width: 512px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PCn_234567890_mobile_number_shad {
		left: 155px;
		top: 30px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PCaffanghanioperatelive_email_sh {
		left: 160px;
		top: 84px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PCaddress_med_shadow_space_websi {
		left: 155px;
		top: 134px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PPD_26_PClocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 120px;
		top: 136px;
		overflow: visible;
	}
	#PPD_26_PCemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 120px;
		top: 83px;
		overflow: visible;
	}
	#PPD_26_PCphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 120px;
		top: 29px;
		overflow: visible;
	}
	#PPD_26_PCsocials_grp_shadow_space_websi {
		position: absolute;
		width: 189px;
		height: 48px;
		left: 974px;
		top: 120px;
		overflow: visible;
	}
	#PPD_26_PClinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 141px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PPD_26_PCmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PClinked_in_shadow_space_website {
		position: absolute;
		width: 23.525px;
		height: 21.386px;
		left: 12.674px;
		top: 13.119px;
		overflow: visible;
	}
	#PPD_26_PCn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.PPD_26_PCn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.547px;
		height: 14.668px;
		left: 7.978px;
		top: 6.719px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PClinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.PPD_26_PClinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.585px;
		height: 14.529px;
		left: 0.593px;
		top: 6.857px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.PPD_26_PCtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.566px;
		height: 5.041px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_26_PCInsta_grp_shadow_space_website {
		position: absolute;
		width: 49px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PPD_26_PCshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 58px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCinstagramshadow_space_website_ {
		position: absolute;
		width: 23.55px;
		height: 23.551px;
		left: 13.07px;
		top: 12.516px;
		overflow: visible;
	}
	#PPD_26_PCwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_26_PCmed_shadow_space_website_7_df {
		fill: rgba(255,255,255,1);
	}
	.PPD_26_PCmed_shadow_space_website_7_df {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPD_26_PCwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 24.154px;
		height: 24.154px;
		left: 11.863px;
		top: 11.912px;
		overflow: visible;
	}
	#PPD_26_PCVISIT_US_ {
		left: 570px;
		top: 2571.002px;
		position: absolute;
		overflow: visible;
		width: 228px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 50px;
		color: rgba(36,36,36,1);
	}