.mediaViewInfo {
    --web-view-name: top bar;
    --web-view-id: top_bar;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: top_bar;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#top_bar {
    position: fixed;
    width: calc(100vw - 136px);
    min-width: 875px;
    height: 64px;
    left: 120px;
    z-index: 20;
    background-color: transparent;
    overflow: hidden;
    --web-view-name: top bar;
    --web-view-id: top_bar;
    --web-enable-deep-linking: true;
}
#mid_op_design {
    fill: rgba(219,242,231,1);
}
.mid_op_design {
    position: absolute;
    overflow: visible;
    width: calc(100% - 560px);
    height: 59px;
    left: 413px;
    top: 0px;
}
#left_grp_op {
    position: absolute;
    width: 363.145px;
    height: 58.662px;
    left: 49.855px;
    top: 0px;
    z-index: 10;
    overflow: visible;
}
#bg_path {
    fill: rgba(219,242,231,1);
}
.bg_path {
    overflow: visible;
    position: absolute;
    width: 363.145px;
    height: 58.662px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#seacch_input_op {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.seacch_input_op {
    position: absolute;
    overflow: visible;
    width: calc(100vh - 450px);
    height: 41px;
    left: 91.145px;
    top: 7px;
    font-size: 17px;
    border-radius: 50px;
    padding-left: 10px;
    font-style: Segeo UI;
    box-shadow: inset 10px 10px 20px #cacaca,inset -10px -10px 20px #ffffff;
}
#search_img_ {
    position: absolute;
    width: 25px;
    height: 25px;
    left: calc(100vh - 400px);
    top: 13px;
    overflow: visible;
}
#bg_deisng_2_op {
    fill: transparent;
}
.bg_deisng_2_op {
    overflow: visible;
    position: absolute;
    width: 363.145px;
    height: 58.662px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#right_grp_op {
    position: absolute;
    width: 436.784px;
    height: 58.662px;
    right: 0px;
    top: 1px;
    z-index: 10;
    background-color: transparent;
    overflow: visible;
}
#bg_design_1_op {
    fill: rgba(219,242,231,1);
}
.bg_design_1_op {
    overflow: visible;
    position: absolute;
    width: 363.145px;
    height: 58.662px;
    left: 0px;
    top: 0px;
   
    transform: matrix(1,0,0,1,0,0);
}
#Icon_material-message {
    fill: rgba(0,0,0,1);
}
.Icon_material-message {
    overflow: visible;
    position: absolute;
    width: 23.095px;
    height: 23.191px;
    left: 97.281px;
    top: 18.809px;
    transform: matrix(1,0,0,1,0,0);
}
#Icon_material-notifications-ac {
    fill: rgba(0,0,0,1);
}
.Icon_material-notifications-ac {
    overflow: visible;
    position: absolute;
    width: 23.095px;
    height: 23.191px;
    left: 242.573px;
    top: 18.809px;
    transform: matrix(1,0,0,1,0,0);
}
#Icon_awesome-user-friends {
    fill: rgba(0,0,0,1);
}
.Icon_awesome-user-friends {
    overflow: visible;
    position: absolute;
    width: 27.312px;
    height: 23.191px;
    left: 165.709px;
    top: 18.809px;
    transform: matrix(1,0,0,1,0,0);
}
#profile_op_pfp {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 386.784px;
    top: 7px;
    overflow: visible;
    padding: 3px;
    border-radius: 50%;
    border:1px solid;
    cursor: pointer;
    overflow: hidden;
}