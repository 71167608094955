.mediaViewInfo {
    --web-view-name: upcomemeeting pop;
    --web-view-id: upcomemeeting_pop;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: upcomemeeting_pop;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#upcomemeeting_pop {
    position: absolute;
    width: 100vw;
    height: 287px;
    z-index: 1;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: upcomemeeting pop;
    --web-view-id: upcomemeeting_pop;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#overall_upcome_meet_pop {
    position: absolute;
    width: 375px;
    height: 287px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#bg_design_meetup {
    position: absolute;
    width: 375px;
    height: 287px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#meeting_scrrol_mob_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 100vw;
    height: 277px;
    left: 12px;
    top: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#card_1_upcome {
    position: relative;
    width: 148px;
    height: 117px;
    left: 21px;
    top: 15px;
    overflow: visible;
}
#back_desgin_meet_up_p {
    fill: url(#back_desgin_meet_up_p);
}
.back_desgin_meet_up_p {
    position: absolute;
    overflow: visible;
    width: 148px;
    height: 107px;
    left: 0px;
    top: 10px;
}
#page1_upcome {
    position: absolute;
    width: 148px;
    height: 102px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#front_design_meet_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 0.20000000298023224px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.front_design_meet_up {
    position: absolute;
    overflow: visible;
    width: 148px;
    height: 102px;
    left: 0px;
    top: 0px;
}
#Web_Development_meetup {
    left: 27px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 108px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(5,5,5,1);
    text-decoration: underline;
}
#Cancelled_meetup {
    left: 34px;
    top: 27px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(5,5,5,1);
}
#date_lbl_meetup {
    left: 33px;
    top: 75px;
    position: absolute;
    overflow: visible;
    width: 82px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#tine_lbl_meetup {
    left: 34px;
    top: 51px;
    position: absolute;
    overflow: visible;
    width: 39px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#date_img_pop_meet {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 11px;
    top: 77px;
    overflow: visible;
}
#status_img_pop_meet {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 11px;
    top: 27px;
    overflow: visible;
}
#time_img_pop_meet {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 11px;
    top: 52px;
    overflow: visible;
}
#arrow-right_open_close {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    top: 251px;
    overflow: visible;
}

@keyframes slideInDown {
    from {
      transform: translate3d(0, -100%, 0);
      visibility: visible;
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInDown {
    animation: slideInDown 1s;
  }