
#all_event_card_grp_mob {
    position: absolute;
    width: 100%;
    height: calc(100% - 35px);
    left: 0px;
    top: 35px;
    overflow: visible;
    }
    #completed_events_overall_grp_m {
        position: absolute;
        width: 100%;
        height: 300px;
        left: 0px;
        top: 620px;
        overflow: visible;
    }
    #completed_events_new_events_pa_br {
        fill: url(#completed_events_new_events_pa_br);
    }
    .completed_events_new_events_pa_br {
        position: absolute;
        overflow: visible;
        width: 100%;
        height: 300px;
        left: 0px;
        top: 0px;
    }
    #completed_events_line_new_even {
        fill: transparent;
        stroke: rgba(38,161,255,1);
        stroke-width: 5px;
        stroke-linejoin: miter;
        stroke-linecap: butt;
        stroke-miterlimit: 4;
        shape-rendering: auto;
    }
    .completed_events_line_new_even {
        overflow: visible;
        position: absolute;
        width: 5px;
        height: 26px;
        left: 8px;
        top: 8px;
        transform: matrix(1,0,0,1,0,0);
    }
    #completed_events_new_events_mo {
        opacity: 0.92;
        left: 18px;
        top: 11.5px;
        position: absolute;
        overflow: visible;
        width: 121px;
        white-space: nowrap;
        line-height: 29px;
        margin-top: -7px;
        text-align: left;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        color: rgba(4,4,4,1);
    }
    #Scroll_completed {
        mix-blend-mode: normal;
        position: absolute;
        width: 100%;
        height: 259.778px;
        /* left: 8.24px; */
        top: 34.223px;
        display: flex;
        flex-wrap:wrap;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    #completed_events_mob_grp {
        position: relative;
        width: 177.664px;
        height: 118.778px;
        left: 12px;
        top: 6px;
        margin: 5px;
        overflow: visible;
    }
    #op_live_name_title_mob {
        position: absolute;
        width: 161px;
        height: 21px;
        left: 8.76px;
        top: 97.777px;
        overflow: visible;
    }
    #op_live_bdr_com_mob {
        opacity: 0.75;
        fill: rgba(255,255,255,1);
    }
    .op_live_bdr_com_mob {
        position: absolute;
        overflow: visible;
        width: 161px;
        height: 21px;
        left: 0px;
        top: 0px;
    }
    #Operate_Live_Event_com_mob {
        left: 0px;
        top: 4px;
        position: absolute;
        overflow: visible;
        width: 162px;
        height: 16px;
        line-height: 29px;
        margin-top: -8.5px;
        text-align: center;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: rgba(4,4,4,1);
    }
    #img_event_new_com_mob {
        position: absolute;
        width: 177.664px;
        height: 98.427px;
        left: 0px;
        top: 0px;
        overflow: visible;
    }
    #shade_img_event_com_mob {
        opacity: 0.29;
        fill: rgba(0,0,0,1);
    }
    .shade_img_event_com_mob {
        position: absolute;
        overflow: visible;
        width: 177.664px;
        height: 98.427px;
        left: 0px;
        top: 0px;
    }
    #trohpie_completed_events {
        position: absolute;
        width: 73px;
        height: 23px;
        left: 2.26px;
        top: 2px;
        overflow: visible;
    }
    #bdr_trophie_com_mob {
        opacity: 0.4;
        fill: rgba(255,255,255,1);
    }
    .bdr_trophie_com_mob {
        position: absolute;
        overflow: visible;
        width: 73px;
        height: 23px;
        left: 0px;
        top: 0px;
    }
    #trophy_img_event_com_mob {
        position: absolute;
        width: auto;
        height: 17px;
        left: 3.5px;
        top: 4px;
        color: white;
        overflow: visible;
    }
    #n_234_com_event_com_mob {
        left: 25.5px;
        top: 4px;
        position: absolute;
        overflow: visible;
        width: 44px;
        height: 17px;
        line-height: 30px;
        margin-top: -9px;
        text-align: center;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: rgba(255,255,255,1);
    }
    #date_com_event_mob {
        position: absolute;
        width: 73px;
        height: 31px;
        left: 3.26px;
        top: 64.777px;
        overflow: visible;
    }
    #bdr_trophie_mob_com {
        opacity: 0.66;
        fill: rgba(255,255,255,1);
    }
    .bdr_trophie_mob_com {
        position: absolute;
        overflow: visible;
        width: 73px;
        height: 31px;
        left: 0px;
        top: 0px;
    }
    #n_3th_Jan_2023__mob_com_event {
        left: 4.24px;
        top: 2.648px;
        position: absolute;
        overflow: visible;
        width: 63px;
        white-space: pre-wrap;
        text-align: left;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        color: rgba(51,51,51,1);
    }
    #trending_events_overall_grp_mo {
        position: absolute;
        width: 100%;
        height: 300px;
        left: 0px;
        top: 310px;
        overflow: visible;
    }
    #trending_events_new_events_mob_ca {
        fill: url(#trending_events_new_events_mob_ca);
    }
    .trending_events_new_events_mob_ca {
        position: absolute;
        overflow: visible;
        width: 100%;
        height: 300px;
        left: 0px;
        top: 0px;
    }
    #trending_events_line_new_event {
        fill: transparent;
        stroke: rgba(255,38,38,1);
        stroke-width: 5px;
        stroke-linejoin: miter;
        stroke-linecap: butt;
        stroke-miterlimit: 4;
        shape-rendering: auto;
    }
    .trending_events_line_new_event {
        overflow: visible;
        position: absolute;
        width: 5px;
        height: 26px;
        left: 8px;
        top: 8px;
        transform: matrix(1,0,0,1,0,0);
    }
    #trending_events_new_events_mob_cc {
        opacity: 0.92;
        left: 18px;
        top: 11.5px;
        position: absolute;
        overflow: visible;
        width: 106px;
        white-space: nowrap;
        line-height: 29px;
        margin-top: -7px;
        text-align: left;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        color: rgba(4,4,4,1);
    }
    #Scrolltrending_mob {
        mix-blend-mode: normal;
        position: absolute;
        width: 100%;
        height: 259.778px;
        /* left: 8.24px; */
        top: 34.222px;
        display: flex;
        flex-wrap:wrap;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    #trending_events_card_1_grp_mob {
        position: relative;
        width: 177.664px;
        height: 118.778px;
        left: 12px;
        top: 6px;
        margin: 5px;
        overflow: visible;
    }
    #title_card_1_trend {
        position: absolute;
        width: 161px;
        height: 21px;
        left: 8.76px;
        top: 97.778px;
        overflow: visible;
    }
    #op_name_trend_mob {
        opacity: 0.75;
        fill: rgba(255,255,255,1);
    }
    .op_name_trend_mob {
        position: absolute;
        overflow: visible;
        width: 161px;
        height: 21px;
        left: 0px;
        top: 0px;
    }
    #Operate_Live_Event_trend_mob {
        left: 0px;
        top: 3px;
        position: absolute;
        overflow: visible;
        width: 162px;
        height: 16px;
        line-height: 29px;
        margin-top: -8.5px;
        text-align: center;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: rgba(4,4,4,1);
    }
    #img_event_trend_mob {
        position: absolute;
        width: 177.664px;
        height: 98.427px;
        left: 0px;
        top: 0px;
        overflow: visible;
    }
    #shade_img_event_trend_mob {
        opacity: 0.29;
        fill: rgba(0,0,0,1);
    }
    .shade_img_event_trend_mob {
        position: absolute;
        overflow: visible;
        width: 177.664px;
        height: 98.427px;
        left: 0px;
        top: 0px;
    }
    #trohpie_trend_mob {
        position: absolute;
        width: 73.5px;
        height: 25px;
        left: 2.26px;
        top: 2.778px;
        overflow: visible;
    }
    #bdr_trophie_trend_mob {
        opacity: 0.4;
        fill: rgba(255,255,255,1);
    }
    .bdr_trophie_trend_mob {
        position: absolute;
        overflow: visible;
        width: 73px;
        height: 25px;
        left: 0px;
        top: 0px;
    }
    #trophy_img_event_trend_mob {
        position: absolute;
        width: auto;
        height: 17px;
        left: 3.5px;
        top: 4px;
        color: white;
        overflow: visible;
    }
    #n_234_com_event_trend_mob {
        left: 22.5px;
        top: 4px;
        position: absolute;
        overflow: visible;
        width: 52px;
        height: 17px;
        line-height: 30px;
        margin-top: -9px;
        text-align: center;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: rgba(255,255,255,1);
    }
    #date_ofg_event_trend_mob {
        position: absolute;
        width: 73.24px;
        height: 31px;
        left: 2.26px;
        top: 64.778px;
        overflow: visible;
    }
    #bdr_trophie_trend_mob_cp {
        opacity: 0.66;
        fill: rgba(255,255,255,1);
    }
    .bdr_trophie_trend_mob_cp {
        position: absolute;
        overflow: visible;
        width: 73px;
        height: 31px;
        left: 0px;
        top: 0px;
    }
    #n_3th_Jan_2023_trend_mob {
        left: 0px;
        top: 0px;
        position: absolute;
        overflow: visible;
        width: 74.24px;
        height: 30.64892578125px;
        text-align: center;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        color: rgba(51,51,51,1);
    }
    #recent_evetns_overall_grp_mob {
        position: absolute;
        width: 100%;
        height: 300px;
        left: 0px;
        top: 0px;
        overflow: visible;
    }
    #recent_events_new_events_mob_ct {
        fill: url(#recent_events_new_events_mob_ct);
    }
    .recent_events_new_events_mob_ct {
        position: absolute;
        overflow: visible;
        width: 100%;
        height: 300px;
        left: 0px;
        top: 0px;
    }
    #recent_events_line_new_events_ {
        fill: transparent;
        stroke: rgba(64,225,150,1);
        stroke-width: 5px;
        stroke-linejoin: miter;
        stroke-linecap: butt;
        stroke-miterlimit: 4;
        shape-rendering: auto;
    }
    .recent_events_line_new_events_ {
        overflow: visible;
        position: absolute;
        width: 5px;
        height: 26px;
        left: 8px;
        top: 8px;
        transform: matrix(1,0,0,1,0,0);
    }
    #recent_events_new_events_mob_cv {
        opacity: 0.92;
        left: 18px;
        top: 11.5px;
        position: absolute;
        overflow: visible;
        width: 92px;
        white-space: nowrap;
        line-height: 29px;
        margin-top: -7px;
        text-align: left;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        color: rgba(4,4,4,1);
    }
    #Scroll_recent_mob {
        mix-blend-mode: normal;
        position: absolute;
        width: 100%;
        height: 260.778px;
        /* left: 8.24px; */
        top: 32.222px;
        display: flex;
        flex-wrap:wrap ;
        /* display: -webkit-inline-box; */
        overflow-x: hidden;
        overflow-y:scroll ;
    }
    #card_recent_event_grp_mob {
        position: relative;
        width: 177.664px;
        height: 118.778px;
        left: 12px;
        top: 8px;
        margin: 5px;
        overflow: visible;
    }
    #op_live_name_card_hodler_mob {
        position: absolute;
        width: 161px;
        height: 21px;
        left: 8.76px;
        top: 97.778px;
        overflow: visible;
    }
    #bdr_name_op_mob {
        opacity: 0.75;
        fill: rgba(255,255,255,1);
    }
    .bdr_name_op_mob {
        position: absolute;
        overflow: visible;
        width: 161px;
        height: 21px;
        left: 0px;
        top: 0px;
    }
    #Operate_Live_Event_lbl_mob {
        left: 0px;
        top: 3px;
        position: absolute;
        overflow: visible;
        width: 162px;
        height: 16px;
        line-height: 29px;
        margin-top: -8.5px;
        text-align: center;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: rgba(4,4,4,1);
    }
    #img_event_new_mob {
        position: absolute;
        width: 177.664px;
        height: 98.427px;
        left: 0px;
        top: 0px;
        overflow: visible;
    }
    #shade_img_event_new_mob {
        opacity: 0.29;
        fill: rgba(0,0,0,1);
    }
    .shade_img_event_new_mob {
        position: absolute;
        overflow: visible;
        width: 177.664px;
        height: 98.427px;
        left: 0px;
        top: 0px;
    }
    #date_of_evnet_mob {
        position: absolute;
        width: 73px;
        height: 31px;
        left: 2.26px;
        top: 63.778px;
        overflow: visible;
    }
    #bdr_date_mob {
        opacity: 0.66;
        fill: rgba(255,255,255,1);
    }
    .bdr_date_mob {
        position: absolute;
        overflow: visible;
        width: 73px;
        height: 31px;
        left: 0px;
        top: 0px;
    }
    #n_3th_Jan_2023_mob_event {
        left: 4.24px;
        top: 2.649px;
        position: absolute;
        overflow: visible;
        width: 63px;
        white-space: pre-wrap;
        text-align: left;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        color: rgba(51,51,51,1);
    }
    #recent_ceven_trophie_mob {
        position: absolute;
        width: 73.5px;
        height: 25px;
        left: 2.26px;
        top: 2.778px;
        overflow: visible;
    }
    #bdr_trophie_mob {
        opacity: 0.4;
        fill: rgba(255,255,255,1);
    }
    .bdr_trophie_mob {
        position: absolute;
        overflow: visible;
        width: 73px;
        height: 25px;
        left: 0px;
        top: 0px;
    }
    #trophy_img_event_new_mob {
        position: absolute;
        width: auto;
        height: 17px;
        left: 3.5px;
        top: 4px;
        color: white;
        overflow: visible;
    }
    #n_234_event_recent_mob {
        left: 20.5px;
        top: 5px;
        position: absolute;
        overflow: visible;
        width: 54px;
        height: 17px;
        text-align: center;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: rgba(255,255,255,1);
    }
    
    #add_event_offreqevent_event_mo {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 25px;
        top: 0px;
        overflow: visible;
    }