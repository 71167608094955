.mediaViewInfo {
    --web-view-name: community off – 2;
    --web-view-id: community_off_mob1;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: community_off_mob1;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#community_off_mob1 {
    position: absolute;
    width: 100vw;
    height:  calc(100vh - 220px);
    top: 150px;
    overflow: hidden;
    --web-view-name: community off – 2;
    --web-view-id: community_off_mob1;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#com_off_bg_img_new {
    position: absolute;
    width: calc(100% - 37px);
    height: calc(100% - 60px);
    left: 0px;
    top: 0px;
    overflow: visible;
}

#community_off_side_menu {
    position: absolute;
    width: 100%;
    height: 634px;
    overflow: hidden;
    --web-view-name: community off – 3;
    --web-view-id: community_off_side_menu;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#com_off_bg_side_menu {
    position: absolute;
    width: 38.112px;
    height: 131.234px;
    right: 0px;
    top: 105.193px;
    overflow: visible;
}
#off_com_overall {
    position: absolute;
    width: 20.869px;
    height: 299.188px;
    right: 8.56px;
    top: 9.235px;
    overflow: visible;
}
#com_off_req_txt {
    transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,310.556,101.235) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#com_off_off_txt {
    transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,326.556,210.3136) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#com_off_mem_txt_1 {
    transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,325.6871,318.4227) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#com_off_mid_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 35px);
    height: calc(100% - 65px);
    left: 0px;
    top: 0px;
    overflow-x: hidden;
    overflow-y: auto;
}
#card_grp_over_all_com_off {
    position: relative;
    width: 311.277px;
    height: 89px;
    left: 5%;
    top: 12px;
    overflow: visible;
}
#community_page_card_off {
    fill: rgba(255,255,255,1);
}
.community_page_card_off {
    filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 317.777px;
    height: 95.5px;
    left: 0px;
    top: 0px;
}
#community_page_mob_pfp_new {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 40.702px;
    top: 11.408px;
    border-radius: 50%;
    overflow: hidden;
}
#community_page_md_zaid {
    left: 3.495px;
    top: 62px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    color: rgba(37,37,37,1);
}
#community_page_card_design {
    fill: rgba(255,235,133,1);
}
.community_page_card_design {
    overflow: visible;
    position: absolute;
    width: 117.404px;
    height: 28.566px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}

#com_off_budget_png_new {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 2.988px;
    overflow: visible;
}
#com_off_delivery_png_new {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 32.397px;
    overflow: visible;
}
#com_off_budget_txt_new {
    left: 168.5px;
    top: 7.988px;
    position: absolute;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#com_off_dep_txt_new {
    left: 168.5px;
    top: 66.012px;
    position: absolute;
    overflow: visible;
    width: 113px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#com_off_location_png_new {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 61.012px;
    overflow: visible;
}
#com_off__timg_txt {
    left: 168.5px;
    top: 36.408px;
    position: absolute;
    overflow: visible;
    width: 51px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
