	.mediaViewInfo {
		--web-view-name: offerings view pc new;
		--web-view-id: offerings_view_pc_new;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: offerings_view_pc_new;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#offerings_view_pc_new {
		position: absolute;
		width: calc(100vw - 125px);
		height: calc(100% - 114px);
        top: 114px;
        /* left: calc((100% - 1237px)/2);  */
		background-color: rgba(255,255,255,1);
		overflow-x: hidden;
		overflow-y: auto;
		--web-view-name: offerings view pc new;
		--web-view-id: offerings_view_pc_new;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#Scroll_cars_preview_offering {
		mix-blend-mode: normal;
		position: absolute;
		width: 198px;
		height: 454px;
		left: 6px;
		top: 72px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_cars_preview_offering::-webkit-scrollbar{
		display: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#preview_1_view_page_pc_offerin {
		position: relative;
		width: 165px;
		height: 99px;
		left: 18px;
		top: 4px;
        margin: 5px;
		overflow: visible;
	}
	#img_preview_1_view_page_pc_off {
		fill: rgba(245,245,246,1);
	}
	.img_preview_1_view_page_pc_off {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 183px;
		height: 117px;
		left: 0px;
		top: 0px;
	}
	#Image_preview_1_view_page_pcof {
		position: absolute;
		width: 126px;
		height: 92px;
		left: 20px;
		top: 4px;
        object-fit: contain;
		overflow: visible;
	}
	#info_grp_view_page_pc_offering {
		position: absolute;
		width: 961px;
		height: 450px;
		left: 204px;
		top: 76px;
		overflow: visible;
	}
	#Nike_1_Sha_view_page_pc_offeri {
		left: 468px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		line-height: 33px;
		margin-top: -3.5px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 26px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.1818181800842285px;
	}
	#trophy_view_page_pc_offering {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 471px;
		top: 43px;
		overflow: visible;
	}
	#n_234_count_view_page_pc_offer {
		left: 505px;
		top: 45px;
		position: absolute;
		overflow: visible;
		width: 93px;
		height: 20px;
		line-height: 22px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
	}
	#share_btn_view_page_pc_offerin {
		position: absolute;
		width: 19px;
		height: 18px;
		left: 830px;
		top: 8px;
		overflow: visible;
		cursor: pointer;
	}
	#Scroll_descri_view_page_pc_off {
		mix-blend-mode: normal;
		position: absolute;
		width: 448px;
		height: 177px;
		left: 468px;
		top: 205px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_descri_view_page_pc_off::-webkit-scrollbar{
		display: none;
	}
	#description_view_page_pc_offer {
		left: 3px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 404px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Button_bargain_view_page_pc_of {
		position: absolute;
		width: 145px;
		height: 51px;
		left: 474px;
		top: 399px;
		overflow: visible;
		border-radius: 5px;
	}
	#bargain_bdr_view_page_pc_offer {
		fill: rgba(248,90,71,1);
	}
	.bargain_bdr_view_page_pc_offer {
		filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 154.444px;
		height: 60.444px;
		left: 0px;
		top: 0px;
	}
	#bargain_view_page_pc_offering {
		left: 41px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 65px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#Button_disable_view_page_pc_of {
		position: absolute;
		width: 145px;
		height: 51px;
		left: 659px;
		top: 399px;
		overflow: visible;
		border-radius: 5px;
	}
	#disable_bdr_view_page_p_offeri {
		fill: rgba(248,90,71,1);
	}
	.disable_bdr_view_page_p_offeri {
		filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 154.444px;
		height: 60.444px;
		left: 0px;
		top: 0px;
	}
	#disable_view_page_pc_offering {
		left: 46px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#line_befoget_view_page_pc_offe {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 3;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_befoget_view_page_pc_offe {
		overflow: visible;
		position: absolute;
		width: 484px;
		height: 1px;
		left: 468.5px;
		top: 76.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#basic_details_grp_view_page_pc {
		position: absolute;
		width: 492px;
		height: 66px;
		left: 469px;
		top: 96px;
		overflow: visible;
	}
	#Budget_Deliverydlview_page_pc_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 131px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Afs_10000_AFN_view_page_pc_off {
		left: 208px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 285px;
		height: 66px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#line_below_location_view_page_ {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 3;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_below_location_view_page_ {
		overflow: visible;
		position: absolute;
		width: 484px;
		height: 1px;
		left: 468.5px;
		top: 188.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#main_big_view_page_pc_offering {
		position: absolute;
		width: 415px;
		height: 450px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#img_2_big_view_page_pc_bdr_off {
		fill: rgba(245,245,246,1);
	}
	.img_2_big_view_page_pc_bdr_off {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 433px;
		height: 468px;
		left: 0px;
		top: 0px;
	}
	#Image_2_big_view_page_pc_offer {
		position: absolute;
		width: 408px;
		height: 399px;
		left: 4px;
		top: 26px;
		overflow: visible;
	}
	#edit_offering_btn_view_page_pc {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 790px;
		top: 5px;
		cursor: pointer;
		overflow: visible;
	}
	#Scroll_timeline_view_page_pc_off {
		mix-blend-mode: normal;
		position: relative;
		width: 100%;
		height: 146px;
		/* left: 197px; */
		display: flex;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#Scroll_timeline_view_page_pc_off::-webkit-scrollbar{
		display: none;
	}
	#timeline_grp_view_page_pc_off {
		position: relative;
		width: 267px;
		height: 91px;
		left: 7px;
		top: 20px;
		overflow: visible;
	}
	#first_green_bdr__view_page_pc_ {
		fill: rgba(255,255,255,1);
	}
	.first_green_bdr__view_page_pc_ {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 211px;
		height: 31px;
		left: 74px;
		top: 38px;
	}
	#firsty_bdr_genn__view_page_pc_ {
		fill: rgba(255,255,255,1);
	}
	.firsty_bdr_genn__view_page_pc_ {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 48px;
		height: 48px;
		left: 41px;
		top: 29px;
	}
	#first_circle__view_page_pc_off {
		fill: rgba(168,248,145,1);
	}
	.first_circle__view_page_pc_off {
		position: absolute;
		overflow: visible;
		width: 18px;
		height: 18px;
		left: 47px;
		top: 35px;
	}
	#first_green_line_view_page_pc_ {
		fill: rgba(168,248,145,1);
	}
	.first_green_line_view_page_pc_ {
		position: absolute;
		overflow: visible;
		width: 182px;
		height: 6px;
		left: 80px;
		top: 41px;
	}
	#Bargained__view_page_pc_offeri {
		left: 18px;
		top: 69px;
		position: absolute;
		overflow: visible;
		width: 77px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#n_4__12__view_page_pc_offering {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 113px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#trophie_detail_view_page_pc_of {
		position: absolute;
		width: 100%;
		height: 89px;
		left: 133px;
		top: 814px;
        display: flex;
		overflow: visible;
	}
	#card_1_trohpie_view_page_pc_of {
		position: relative;
		width: 180px;
		height: 45px;
		left: 0px;
		top: 44px;
		padding: 10px;
		cursor: pointer;
		overflow: visible;
	}
	#img_who_liked_view_page_pc_off {
        position: absolute;
        width: 35px;
        height: 35px;
        left: 0px;
        top: 0px;
        margin: 5px;
        border-radius: 50%;
        overflow: hidden;
	}
	
	#Bargained_view_page_pc_offerin {

		position: absolute;
		overflow: visible;
		width: 77px;
        left: 50px;
		top: 9px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Trophies_Awarded_By_view_page_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 180px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#previous_back_btn_view_page_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 24px;
		top: 17px;
        cursor: pointer;
		overflow: visible;
	}
