.mediaViewInfo {
    --web-view-name: calender mob;
    --web-view-id: calender_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: calender_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#calender_mob {
    position: relative;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-left: 5px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: calender mob;
    --web-view-id: calender_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}