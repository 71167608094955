	.mediaViewInfo {
		--web-view-name: PunctureWizards Mob;
		--web-view-id: PunctureWizards_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: PunctureWizards_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#PunctureWizards_Mob {
		position: absolute;
		width: 390px;
		height: 3601px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: PunctureWizards Mob;
		--web-view-id: PunctureWizards_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#PWMOBGroup_15114 {
		position: absolute;
		width: 390px;
		height: 512px;
		left: 0px;
		top: 863px;
		overflow: visible;
	}
	#PWMOBstopped-white-car-with-punctur {
		position: absolute;
		width: 250px;
		height: 250px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 174px;
		left: 0px;
		top: 338px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PWMOBIt_is_a_long_established_fact_ {
		left: 24px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 174px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PWMOBVISION {
		left: 24px;
		top: 277px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -12px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#PWMOBGroup_15120 {
		position: absolute;
		width: 390px;
		height: 512px;
		left: 0px;
		top: 1425px;
		overflow: visible;
	}
	#PWMOBshane-rounce-mgt2BlBgprI-unspl {
		position: absolute;
		width: 250px;
		height: 250px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBScroll_Group_28_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 174px;
		left: 0px;
		top: 338px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PWMOBIt_is_a_long_established_fact__ba {
		left: 24px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 172px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PWMOBMISSION {
		left: 24px;
		top: 277px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -12px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#PWMOBGroup_15121 {
		position: absolute;
		width: 390px;
		height: 512px;
		left: 0px;
		top: 1987px;
		overflow: visible;
	}
	#PWMOBmason-jones-9e_ssIz4jB4-unspla {
		position: absolute;
		width: 250px;
		height: 250px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBScroll_Group_28_be {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 174px;
		left: 0px;
		top: 338px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PWMOBIt_is_a_long_established_fact__bf {
		left: 24px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 174px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PWMOBPRINCIPLES {
		left: 24px;
		top: 277px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -12px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#PWMOBGroup_15122 {
		position: absolute;
		width: 358px;
		height: 574.998px;
		left: 16px;
		top: 3011px;
		overflow: visible;
	}
	#PWMOBGroup_15040 {
		position: absolute;
		width: 309px;
		height: 174px;
		left: 31.5px;
		top: 44.997px;
		overflow: visible;
	}
	#PWMOBRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.PWMOBRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 309px;
		height: 174px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#PWMOBGroup_15050 {
		position: absolute;
		width: 358px;
		height: 316px;
		left: 0px;
		top: 258.998px;
		overflow: visible;
	}
	#PWMOBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 194px;
		overflow: visible;
	}
	#PWMOBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PWMOBmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PWMOBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#PWMOBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.PWMOBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PWMOBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.PWMOBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PWMOBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.PWMOBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PWMOBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PWMOBshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PWMOBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#PWMOBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBmed_shadow_space_website_7_bw {
		fill: rgba(255,255,255,1);
	}
	.PWMOBmed_shadow_space_website_7_bw {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PWMOBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#PWMOBn_234567890_mobile_number_shad {
		left: 48px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#PWMOBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#PWMOBaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#PWMOBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#PWMOBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#PWMOBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#PWMOBContact_Us {
		left: 128px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -22.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#PWMOBGroup_15152 {
		position: absolute;
		width: 354px;
		height: 254px;
		left: 18px;
		top: 24px;
		overflow: visible;
	}
	#PWMOBText {
		position: absolute;
		width: 354px;
		height: 160px;
		left: 0px;
		top: 94px;
		overflow: visible;
	}
	#PWMOBExperience_the_finest_in_premi {
		left: 5px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 336px;
		white-space: nowrap;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(6,18,50,1);
	}
	#PWMOBCompany__Name {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 355px;
		height: 94px;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(6,18,50,1);
	}
	#PWMOBAnime_Edit {
		position: absolute;
		width: 55px;
		height: 55px;
		left: 4px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBBGs {
		position: absolute;
		width: 390px;
		height: 501px;
		left: 0px;
		top: 278px;
		overflow: visible;
	}
	#PWMOBtechnician-is-repairing-car-fl {
		position: absolute;
		width: 390px;
		height: 501px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PWMOBGroup_15133 {
		position: absolute;
		width: 375px;
		height: 332px;
		left: 0px;
		top: 2589px;
		overflow: visible;
	}
	#PWMOBOur_Offerings {
		left: 123px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 130px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PWMOBScroll_Group_90 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 305px;
		left: 0px;
		top: 27px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#PWMOBGroup_15128 {
		position: absolute;
		width: 203px;
		height: 254px;
		left: 15px;
		top: 22px;
		overflow: visible;
	}
	#PWMOBRectangle_750_ci {
		fill: url(#PWMOBRectangle_750_ci);
	}
	.PWMOBRectangle_750_ci {
		filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 208px;
		height: 259px;
		left: 0px;
		top: 0px;
	}
	#PWMOBtechnician-is-repairing-car-fl_cj {
		position: absolute;
		width: 185px;
		height: 210px;
		left: 9px;
		top: 9px;
		overflow: visible;
	}
	#PWMOBPUNCTURE {
		left: 9px;
		top: 226px;
		position: absolute;
		overflow: visible;
		width: 186px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
