.mediaViewInfo {
    --web-view-name: KnowledgeKorner;
    --web-view-id: KnowledgeKorner;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-center-horizontally: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: KnowledgeKorner;
}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#KnowledgeKorner {
		position: absolute;
		width: 1366px;
		height: 3064px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(249,249,249,0.89);
		overflow: hidden;
		--web-view-name: KnowledgeKorner;
		--web-view-id: KnowledgeKorner;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#grass_shadow_website_6_pc {
		opacity: 0.32;
		fill: rgba(0,154,23,1);
	}
	.grass_shadow_website_6_pc {
		overflow: visible;
		position: absolute;
		width: 1373.327px;
		height: 59.191px;
		left: -3.086px;
		top: 715.283px;
		transform: matrix(1,0,0,1,0,0);
	}
	#sky_img_website_6_pc {
		fill: rgba(135,206,235,1);
	}
	.sky_img_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 1373px;
		height: 716px;
		left: -5px;
		top: 0px;
	}
	#grass_design_website_6_pc {
		fill: rgba(0,154,23,1);
	}
	.grass_design_website_6_pc {
		overflow: visible;
		position: absolute;
		width: 1373.327px;
		height: 59.191px;
		left: -5.086px;
		top: 676.283px;
		transform: matrix(1,0,0,1,0,0);
	}
	#footer_bg_img_website_6_pc {
		position: absolute;
		width: 1366px;
		height: 561px;
		left: 0px;
		top: 2616px;
		overflow: visible;
	}
	#home_shadow_space_website_6 {
		position: absolute;
		width: 79px;
		height: 27px;
		left: 434px;
		top: 26px;
		overflow: visible;
	}
	#Home_shadow_space_website_6_cd {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#home-icon-shadow_space_website {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#offering_shadow_space_website_ {
		position: absolute;
		width: 106px;
		height: 27px;
		left: 560px;
		top: 26px;
		overflow: visible;
	}
	#Offerings_shadow_space_website {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#discount_shadow_space_website_ {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#event_grp_shadow_space_website_kk_pc {
		position: absolute;
		width: 82px;
		height: 27px;
		left: 712px;
		top: 26px;
		overflow: visible;
	}
	#Events_shadow_space_website_6 {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 58px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#election-event-on-a-calendar-s {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#contact_grpshadow_space_websit {
		position: absolute;
		width: 93px;
		height: 27px;
		left: 839px;
		top: 26px;
		overflow: visible;
	}
	

#n__imgs_grp_website_6_pc { 
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 20px); }
    100%   { transform: translate(0, -0px); }   
}
body::-webkit-scrollbar{
	display: none;
}
	#Contact_lbl_shadow_space_websi {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#contact_img_shadow_space_websi {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#Anime_Edit_1_website_6_pc {
		position: absolute;
		width: 100px;
		height: 100px;
		left: 126px;
		top: 17px;
        border-radius: 50%;
		overflow: hidden;
	}
	#LETS_LEARN_AND_GAIN__website_6 {
		left: 258px;
		top: 143px;
		position: absolute;
		overflow: visible;
		width: 851px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 60px;
		color: rgba(0,0,0,0.89);
	}
	#Lets_discover_new_lbl_website_ {
		left: 364px;
		top: 353px;
		position: absolute;
		overflow: visible;
		width: 639px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,0.89);
	}
	#Trusted_by_our_partners_lbl_we {
		left: 572px;
		top: 920px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_web_sug_pc_6 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1205px;
		height: 80px;
		left: 95px;
		top: 993px;
        display: inline-flex;
		overflow-x: auto;
		overflow-y: hidden;
	}
    #Scroll_web_sug_pc_6::-webkit-scrollbar{
		display: none;
	}
	#partner_grp_website_6_pc {
		position: relative;
		width: 252px;
		height: 50px;
		left: 29px;
		top: 14px;
		overflow: visible;
	}
	#Anime_Edit_img_website_6_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Mohammed_Affan_lbl_website_6_p {
		left: 65px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 188px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#Our_Services_lbl_website_6_pc {
		left: 621px;
		top: 1582px;
		position: absolute;
		overflow: visible;
		width: 119px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#mission_card_grp_website_6_pc {
		position: absolute;
		width: 233px;
		height: 325px;
		left: 567px;
		top: 1157px;
		overflow: visible;
	}
	#mission_overall_bdr_website_6_ {
		fill: rgba(255,255,255,1);
	}
	.mission_overall_bdr_website_6_ {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 251px;
		height: 343px;
		left: 0px;
		top: 0px;
	}
	#mission_img_bdr_website_6_pc {
		fill: rgba(167,166,210,1);
	}
	.mission_img_bdr_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 50px;
		height: 50px;
		left: 10px;
		top: 10px;
	}
	#Mission_lbl_website_6_pc {
		left: 10px;
		top: 65px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_mision_pc_web_6 {
		mix-blend-mode: normal;
		position: absolute;
		width: 233px;
		height: 211px;
		left: 0px;
		top: 92px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
    #Scroll_mision_pc_web_6::-webkit-scrollbar{
		display: none;
	}
	#Watashitachi_no_dry_no_zaido_o {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,0.89);
	}

    #principle_2_k_k_websi_pc {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 15px;
		top: 15px;
		overflow: visible;
	}
    #principle_card_grp_website_6_p {
		position: absolute;
		width: 233px;
		height: 325px;
		left: 850px;
		top: 1157px;
		overflow: visible;
	}
	#overall_princi_card_website_6_ {
		fill: rgba(255,255,255,1);
	}
	.overall_princi_card_website_6_ {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 251px;
		height: 343px;
		left: 0px;
		top: 0px;
	}
	#principle_img_bdr_website_6_pc {
		fill: rgba(166,210,202,1);
	}
	.principle_img_bdr_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 50px;
		height: 50px;
		left: 10px;
		top: 10px;
	}
	#Principle_lbl_website_6_pc {
		left: 10px;
		top: 65px;
		position: absolute;
		overflow: visible;
		width: 71px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_princi_web_6_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 233px;
		height: 214px;
		left: 0px;
		top: 92px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
    #Scroll_princi_web_6_pc::-webkit-scrollbar{
		display: none;
	}
	#Kono_sekai_wa_itami_o_shirudar {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,0.89);
	}
	#leadership_2_knowledge_webs_pc {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 15px;
		top: 13.5px;
		overflow: visible;
	}
	#vision_card_website_6_pc_grp {
		position: absolute;
		width: 233px;
		height: 325px;
		left: 284px;
		top: 1157px;
		overflow: visible;
	}
	#vision_card_overall_website_6_ {
		fill: rgba(255,255,255,1);
	}
	.vision_card_overall_website_6_ {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 251px;
		height: 343px;
		left: 0px;
		top: 0px;
	}
	#bdr_img_visionwebsite_6_pc {
		fill: rgba(241,144,144,1);
	}
	.bdr_img_visionwebsite_6_pc {
		position: absolute;
		overflow: visible;
		width: 50px;
		height: 50px;
		left: 10px;
		top: 10px;
	}
	#Vision_lbl_website_6_pc {
		left: 10px;
		top: 65px;
		position: absolute;
		overflow: visible;
		width: 51px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_vision_web_6_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 233px;
		height: 215px;
		left: 0px;
		top: 92px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
    
#Scroll_vision_web_6_pc::-webkit-scrollbar{
    display: none;
}
	#Zaido_wa_gisei_ni_natte_korosa {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,0.89);
	}
	#target_1_shadow_space_website_ {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 15px;
		top: 15px;
		overflow: visible;
	}
	#Scroll_my_services_pc_6_wev {
		mix-blend-mode: normal;
		position: absolute;
		width: 1303px;
		height: 375px;
		left: 29px;
		top: 1622px;
		display: -webkit-box;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#Scroll_my_services_pc_6_wev::-webkit-scrollbar{
		display: none;
	}
	#our_servicces_card_1_website_6 {
		position: relative;
		width: 233px;
		height: 325px;
		left: 22px;
		top: 17px;
        margin: 10px;
		overflow: visible;
	}
	#services_card_1_bdr_website_6_ {
		fill: rgba(255,255,255,1);
	}
	.services_card_1_bdr_website_6_ {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 251px;
		height: 343px;
		left: 0px;
		top: 0px;
	}
	#img_of_services_website_6_pc {
		position: absolute;
		width: 201px;
		height: 265px;
		left: 16px;
		top: 46px;
		overflow: visible;
	}
	#circle_deign_shadow_space_webs {
		fill: rgba(23,18,18,1);
	}
	.circle_deign_shadow_space_webs {
		position: absolute;
		overflow: visible;
		width: 10px;
		height: 10px;
		left: 16px;
		top: 20px;
	}
	#My_Offerings_website_6_pc {
		left: 31px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 187px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,0.89);
	}
	#circle_3_website_6_pc {
		fill: rgba(166,210,202,1);
	}
	.circle_3_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 50px;
		height: 50px;
		left: 1140px;
		top: 1433px;
	}
	#circle_2_website_6_pc {
		fill: rgba(255,168,168,1);
	}
	.circle_2_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 391px;
		top: 1483px;
	}
	#oofering_lbl_med_knowledge_pc {
		left: 635px;
		top: 2085px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
    #Scroll_event_web_6_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 1269.5px;
		height: 353px;
		left: 63px;
		top: 2148px;
        display: -webkit-box;
		overflow-x: auto;
		overflow-y: hidden;
	}

    #Scroll_event_web_6_pc::-webkit-scrollbar {
        display: none;
    }
	#my_event_sectionshadow_space_w {
		position: relative;
		width: 560px;
		height: 323px;
		left: 9px;
		top: 8px;
        margin: 10px;
		overflow: visible;
	}
	#comic_con_bg_shadow_space_webs_dq {
		fill: url(#comic_con_bg_shadow_space_webs_dq);
	}
	.comic_con_bg_shadow_space_webs_dq {
		filter: drop-shadow(8px 8px 5px rgba(219, 219, 219, 1));
		position: absolute;
		overflow: visible;
		width: 529.5px;
		height: 301.5px;
		left: 46px;
		top: 18px;
	}
	#Anime_Edit_shadow_space_websit {
		position: absolute;
		width: 230px;
		height: 323px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#oofering_lbl_med_shadow_space__ds {
		left: 230px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 331px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Scroll_description_event_web_6 {
		mix-blend-mode: normal;
		position: absolute;
		width: 326px;
		height: 158px;
		left: 234px;
		top: 100px;
		overflow: hidden;
	}
	#offering_lbl_med_web_6_pc {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 312px;
		height: 158px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#person_name_event_1_website_6_ {
		filter: blur(44px);
		fill: rgba(255,255,255,1);
	}
	.person_name_event_1_website_6_ {
		position: absolute;
		overflow: visible;
		width: 230px;
		height: 28px;
		left: 0px;
		top: 0px;
	}
	#grp_1_top_pc_web_6 {
		position: absolute;
		width: 297px;
		height: 30px;
		left: 246px;
		top: 56px;
		overflow: visible;
	}
	#date_shadow_space_website_6 {
		left: 36px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 91px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#calendar_2_shadow_space_websit {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#date_shadow_space_website_6_dz {
		left: 180px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 118px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#clock_1_shadow_space_website_6 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 145px;
		top: 0px;
		overflow: visible;
	}
	#grp_2_top_pc_web_6 {
		position: absolute;
		width: 267px;
		height: 30px;
		left: 247px;
		top: 266px;
		overflow: visible;
	}
	#seats_shadow_space_website_6 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#dateshadow_space_website_6 {
		left: 34px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 94px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#price-tag_shadow_space_website {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 148px;
		top: 0px;
		overflow: visible;
	}
	#date_shadow_space_website_6_d {
		left: 183px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 85px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#man_2_shadow_space_website_6 {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 6px;
		top: 4px;
		overflow: visible;
	}
	#date_shadow_space_website_6_ea {
		left: 26px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 198px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#footer_grp_web_6_pc {
		position: absolute;
		width: 1366px;
		height: 446px;
		left: 0px;
		top: 2616px;
		overflow: visible;
	}
	#footer_bg_img_website_6_pc {
		position: absolute;
		width: 1366px;
		height: 446px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_234567890_mobile_number_shad_k_k_pc {
		left: 826px;
		top: 47px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#affanghanioperatelive_email_sh_k_k_pc {
		left: 1037px;
		top: 47px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#address_med_shadow_space_websi_k_k_pc {
		left: 826px;
		top: 107px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#location_img_shadow_space_webs_k_k_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 791px;
		top: 107px;
		overflow: visible;
	}
	#email_address_shadow_space_web_k_k_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 997px;
		top: 46px;
		overflow: visible;
	}
	#phone_number_shadow_space_webs_k_k_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 791px;
		top: 46px;
		overflow: visible;
	}
	#Connect_with_us_and_unlock_lim_k_k_pc {
		left: 826px;
		top: 221px;
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#quote_left_med_shadow_space_we_k_k_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 791px;
		top: 221px;
		overflow: visible;
	}
	#quote_right_med_shadow_space_w_k_k_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 1055px;
		top: 277px;
		overflow: visible;
	}
	#n_Copyright_Operate_Live_med_s_k_k_pc {
		left: 97px;
		top: 399px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#socials_grp_shadow_space_websi_k_k_pc {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 826px;
		top: 335px;
		overflow: visible;
	}
	#linked_in_grp_shadow_space_web_k_k_pc {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_6_k_k_pc:hover {
		fill: rgba(120,120,255,1);
	}
	.med_shadow_space_website_6_k_k_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_6_k_k_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_6_k_k_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_shadow_space_website_k_k_pc {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.191px;
		overflow: visible;
	}
	#n_website_6_layout__k_k_pc {
		fill: rgba(63,61,86,1);
	}
	.n_website_6_layout__k_k_pc {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_shadow_space_websi_k_k_pc {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_shadow_space_websi_k_k_pc {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_shadow_space_website_k_k_pc {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_shadow_space_website_k_k_pc {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_shadow_space_website_k_k_pc {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#shadow_space_website_6_k_k_pc:hover {
		fill: rgba(255,120,120,1);
	}
	.shadow_space_website_6_k_k_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#shadow_space_website_6_k_k_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.shadow_space_website_6_k_k_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagramshadow_space_website__k_k_pc {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#whatsapp_grp_shadow_space_webs_k_k_pc {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_6_ex_k_k_pc:hover {
		fill: rgba(50,172,158,1);
	}
	.med_shadow_space_website_6_ex_k_k_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_6_ex_k_k_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_6_ex_k_k_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_shadow_space_webs_k_k_pc {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#tv_part_21_website_6_pc {
		fill: rgba(0,0,0,1);
	}
	.tv_part_21_website_6_pc {
		overflow: visible;
		position: absolute;
		width: 512.559px;
		height: 310.918px;
		left: 96.628px;
		top: 61.477px;
		transform: matrix(1,0,0,1,0,0);
	}
	#tv_part_1_website_6_pc {
		fill: rgba(255,255,255,1);
	}
	.tv_part_1_website_6_pc {
		overflow: visible;
		position: absolute;
		width: 497.467px;
		height: 275.943px;
		left: 104.469px;
		top: 69.395px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_6_website_6 {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_6_website_6 {
		overflow: visible;
		position: absolute;
		width: 80px;
		height: 56px;
		left: 70px;
		top: 167px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_5_website_6 {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_5_website_6 {
		overflow: visible;
		position: absolute;
		width: 62px;
		height: 43.4px;
		left: 1108px;
		top: 246px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_4_website_6 {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_4_website_6 {
		overflow: visible;
		position: absolute;
		width: 80px;
		height: 56px;
		left: 258px;
		top: 353px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_3_website_6 {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_3_website_6 {
		overflow: visible;
		position: absolute;
		width: 80px;
		height: 56px;
		left: 1220px;
		top: 465px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_2_website_6 {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_2_website_6 {
		overflow: visible;
		position: absolute;
		width: 80px;
		height: 56px;
		left: 892px;
		top: 89px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_1_website_6 {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_1_website_6 {
		overflow: visible;
		position: absolute;
		width: 80px;
		height: 56px;
		left: 513px;
		top: 89px;
		transform: matrix(1,0,0,1,0,0);
	}
	#sheep3_website_6_pc {
		position: absolute;
		width: 58px;
		height: 58px;
		left: 116px;
		top: 658px;
		overflow: visible;
	}
	#sheep_2_website_6_pc {
		position: absolute;
		width: 58px;
		height: 58px;
		left: 79px;
		top: 670px;
		overflow: visible;
	}
	#sheep_1_website_6_pc {
		position: absolute;
		width: 58px;
		height: 58px;
		left: 1202px;
		top: 658px;
		overflow: visible;
	}
	#dove_3_website_6_pc {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 1099px;
		top: 117px;
		overflow: visible;
	}
	#dove_2_website_6_pc {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 1135px;
		top: 129px;
		overflow: visible;
	}
	#dove_1_website_6_pc {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 1135px;
		top: 86px;
		overflow: visible;
	}
	#n__imgs_grp_website_6_pc {
		position: absolute;
		width: 1013px;
		height: 437px;
		left: 177px;
		top: 445px;
		overflow: visible;
	}
	#image_4_shadow_website_6_pc {
		filter: blur(4px);
		fill: rgba(212,212,212,1);
	}
	.image_4_shadow_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 174px;
		height: 33px;
		left: 37px;
		top: 356px;
	}
	#image_3_shadow_website_6_pc {
		filter: blur(4px);
		fill: rgba(212,212,212,1);
	}
	.image_3_shadow_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 174px;
		height: 33px;
		left: 295px;
		top: 428px;
	}
	#image_2_shadow_website_6_pc {
		filter: blur(4px);
		fill: rgba(212,212,212,1);
	}
	.image_2_shadow_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 174px;
		height: 33px;
		left: 569px;
		top: 428px;
	}
	#image_1_shadow_website_6_pc {
		filter: blur(4px);
		fill: rgba(212,212,212,1);
	}
	.image_1_shadow_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 174px;
		height: 33px;
		left: 827px;
		top: 361px;
	}
	#img_4_website_6_pc {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 218px;
		height: 355px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#img_3_website_6_pc {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 218px;
		height: 355px;
		left: 258px;
		top: 65px;
		overflow: visible;
	}
	#img_2_website_6_pc {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 218px;
		height: 355px;
		left: 537px;
		top: 65px;
		overflow: visible;
	}
	#img_1_website_6_pc {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 218px;
		height: 355px;
		left: 795px;
		top: 0px;
		overflow: visible;
	}
	#circle_1_website_6_pc {
		fill: rgba(166,210,170,1);
	}
	.circle_1_website_6_pc {
		position: absolute;
		overflow: visible;
		width: 50px;
		height: 50px;
		left: 1242px;
		top: 1106px;
	}
