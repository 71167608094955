.reset{
    text-align: center;
    font-family:Georgia, 'Times New Roman', Times, serif;
    color:white;
    padding-top: 5px;
    height:30px;
   background-color:#14c871;
}


.child{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    background-color: rgb(247, 247, 247);
    
    border: 1px solid black;
  
   
}


