.mediaViewInfo {
    --web-view-name: noti;
    --web-view-id: notification_Overall_pc;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: notification_Overall_pc;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#notification_Overall_pc {
    position: relative;
    width: 333px;
    height: 421px;
    margin: 15px;
    /* margin-top: 75px; */
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: noti;
    --web-view-id: notification_Overall_pc;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#notification_grp {
    position: absolute;
    width: 326px;
    height: 415px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#notification_bdr {
    fill: rgba(255,255,255,1);
}
.notification_bdr {
    filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
    position: absolute;
    overflow: visible;
    width: 334px;
    height: 423px;
    left: 0px;
    top: 0px;
}
#Notifications_lbl {
    left: 40px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(37,46,72,1);
}
#noti_scroll_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 326px;
    height: 355px;
    left: 0px;
    top: 41px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#card_1_noti {
    position: absolute;
    width: 251px;
    height: 68px;
    left: 38px;
    top: 15px;
    overflow: visible;
}
#down_shadow {
    fill: rgba(255,255,255,1);
}
.down_shadow {
    filter: drop-shadow(0px 10px 80px rgba(123, 126, 246, 0.322));
    position: absolute;
    overflow: visible;
    width: 467px;
    height: 291px;
    left: 12px;
    top: 0px;
}
#bg_pfp_shadow {
    fill: rgba(255,255,255,1);
}
.bg_pfp_shadow {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 51px;
    left: 0px;
    top: 0px;
}
#Mark_Damon_lbl {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    left: 5px;
    top: 0px;
    position: absolute;
    overflow: hidden;
    width: 242px;
    height: 51px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#Mark_Damon_lbl_ {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    left: 118px;
    top: 51px;
    position: absolute;
    overflow: visible;
    width: 134px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
