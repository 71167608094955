#all_grp_top_to_down_comp_reqof {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 180px);
    left: 0px;
    top: 114px;
    overflow-y: auto;
    overflow-x: hidden;
}
#all_grp_top_to_down_comp_reqof::-webkit-scrollbar{
    display: none;
}
#desgin_3_offreqevent_off_PC {
    fill: rgba(248,248,248,1);
}
.desgin_3_offreqevent_off_PC {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 255px;
    left: 0px;
    top: 0px;
}
#desgin_2_offreqevent_off_PC {
    position: absolute;
    width: 100%;
    height: 227px;
    left: 0px;
    top: 528px;
    overflow: visible;
}
#design_1_offreqevent_off_PC {
    fill: rgba(0,0,0,0.569);
}
.design_1_offreqevent_off_PC {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 227px;
    left: 0px;
    top: 528px;
}
#recently_added_offreqevent_off {
    /* left: 497px; */
    top: 65px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    line-height: 49px;
    /* margin-top: -13.5px; */
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#explore_awesome_offerings_offr {
    /* left: 531px; */
    top: 47px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#And_then_there_was_offreqevent {
    /* left: 66px; */
    top: 594px;
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 47px;
    line-height: 56.630001068115234px;
    margin-top: -10.815000534057617px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: rgba(255,255,255,1);
    letter-spacing: -0.46875px;
}
#trwnding_this_week_offreqevent {
    /* left: 486px; */
    top: 833px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: pre-wrap;
    line-height: 50px;
    /* margin-top: -11.5px; */
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#top_product_offreqevent_off_PC {
    /* left: 574.606px; */
    top: 809.533px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#Scroll_recently_added_off_PC {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 356px;
    /* left: 40px; */
    top: 112px;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}
#Scroll_recently_added_off_PC::-webkit-scrollbar{
    display: none;
}

#Scroll_trending_this_week_off_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 772px;
    left: 0px;
    top: 909px;
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Scroll_trending_this_week_off_::-webkit-scrollbar{
    display: none;
}
#all_items_grp_trend_off_PC {
    position: relative;
    width: 1110px;
    height: 680px;
    left: calc((100% - 1110px)/2);
    text-align: center;
    top: 34px;
    display: inline-block;
    overflow: visible;
}
/* #n__card_grp_reqoff_off_PC {
    position: absolute;
    width: 540px;
    height: 679px;
    left: 0px;
    top: 1px;
    overflow: visible;
} */

#small_3_trending_off_PC {
    position: absolute;
    width: 255px;
    height: 313px;
    left: 285px;
    top: 0px;
    overflow: visible;
}
#bdr_small_3_trending_all_off_P {
    fill: rgba(245,245,245,1);
}
.bdr_small_3_trending_all_off_P {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 273px;
    height: 331px;
    left: 0px;
    top: 0px;
}
#amijnt_bdr_small_3_trending_of {
    fill: rgba(255,255,255,1);
}
.amijnt_bdr_small_3_trending_of {
    position: absolute;
    overflow: visible;
    width: 63px;
    height: 34px;
    left: 171px;
    top: 253px;
}
#n_9_small_3_trending_off_PC {
    left: 190px;
    top: 259px;
    position: absolute;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#Image_small_3_trending_off_PC {
    position: absolute;
    width: 214px;
    height: 214px;
    left: 21px;
    top: 37px;
    overflow: visible;
}
#NikeCourt_Air_small_3_trending {
    left: 24.5px;
    top: 260.5px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    line-height: 32px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.1818181800842285px;
}
#Single_Tour_small_3_trending_o {
    left: 24px;
    top: 282px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(17,17,17,1);
}
#no_bdr_small_3_trending_off_PC {
    fill: rgba(255,255,255,1);
}
.no_bdr_small_3_trending_off_PC {
    position: absolute;
    overflow: visible;
    width: 39px;
    height: 21px;
    left: 42px;
    top: 10px;
}
#trophie_bdr_small_3_trending_o {
    fill: rgba(255,255,255,1);
}
.trophie_bdr_small_3_trending_o {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 37px;
    left: 2px;
    top: 1px;
}
#trophy_small_3_trending_off_PC {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 6px;
    top: 5px;
    overflow: visible;
}
#n_234_small_3_trending_off_PC {
    left: 45px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17,17,17,1);
}
#share_bdr_small_3_trending_off {
    fill: rgba(255,255,255,1);
}
.share_bdr_small_3_trending_off {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 216px;
    top: 2px;
}
#share_3_reqoffevent_off_PC {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 223px;
    top: 9px;
    overflow: visible;
}
#small_2_trending_off_PC {
    position: absolute;
    width: 255px;
    height: 325px;
    left: 0px;
    top: 354px;
    overflow: visible;
}
#all_small_2_trending_off_PC {
    fill: rgba(245,245,245,1);
}
.all_small_2_trending_off_PC {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 273px;
    height: 343px;
    left: 0px;
    top: 0px;
}
#Image_small_2_trending_off_PC {
    position: absolute;
    width: 214px;
    height: 214px;
    left: 21px;
    top: 37px;
    overflow: visible;
}
#prince_bdr_small_2_trending_of {
    fill: rgba(255,255,255,1);
}
.prince_bdr_small_2_trending_of {
    position: absolute;
    overflow: visible;
    width: 63px;
    height: 34px;
    left: 171.25px;
    top: 256px;
}
#n_9_small_2_trending_off_PC {
    left: 190.25px;
    top: 262px;
    position: absolute;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#NikeCourt_Air_small_2_trending {
    left: 21.75px;
    top: 263.5px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    line-height: 32px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.1818181800842285px;
}
#Single_Tour_small_2_trending_o {
    left: 22px;
    top: 286px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(17,17,17,1);
}
#number_small_2_trending_off_PC {
    fill: rgba(255,255,255,1);
}
.number_small_2_trending_off_PC {
    position: absolute;
    overflow: visible;
    width: 39px;
    height: 21px;
    left: 41px;
    top: 11px;
}
#trophie_bdr_small_2_trending_o {
    fill: rgba(255,255,255,1);
}
.trophie_bdr_small_2_trending_o {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 37px;
    left: 1px;
    top: 2px;
}
#trophy_small_2_trending_off_PC {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 6px;
    overflow: visible;
}
#n_234_small_2_trending_off_PC {
    left: 44px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17,17,17,1);
}
#sgatre_bdr_small_4_trending_of {
    fill: rgba(255,255,255,1);
}
.sgatre_bdr_small_4_trending_of {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 216px;
    top: 2px;
}
#share_23_reqoffevent_off_PC {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 223px;
    top: 9px;
    overflow: visible;
}
#small_1_trneding_off_PC {
    position: absolute;
    width: 255px;
    height: 325px;
    left: 285px;
    top: 354px;
    overflow: visible;
}
#bdr_trending_1_small_off_PC {
    fill: rgba(245,245,245,1);
}
.bdr_trending_1_small_off_PC {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 273px;
    height: 343px;
    left: 0px;
    top: 0px;
}
#Image_trending_1_small_off_PC {
    position: absolute;
    width: 214px;
    height: 214px;
    left: 21px;
    top: 37px;
    overflow: visible;
}
#bg_trending_1_small_off_PC {
    fill: rgba(255,255,255,1);
}
.bg_trending_1_small_off_PC {
    position: absolute;
    overflow: visible;
    width: 63px;
    height: 34px;
    left: 172px;
    top: 256px;
}
#n_9_amount_trending_1_small_of {
    left: 191px;
    top: 262px;
    position: absolute;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#NikeCourt_Air_trending_1_small {
    left: 23.5px;
    top: 263.5px;
    position: absolute;
    overflow: visible;
    width: 79px;
    white-space: nowrap;
    line-height: 32px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.1818181800842285px;
}
#Single_trending_1_small_off_PC {
    left: 23px;
    top: 286px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(17,17,17,1);
}
#like_count_trending_1_small_of {
    fill: rgba(255,255,255,1);
}
.like_count_trending_1_small_of {
    position: absolute;
    overflow: visible;
    width: 39px;
    height: 21px;
    left: 42px;
    top: 11px;
}
#trohpie_bg_trending_1_small_of {
    fill: rgba(255,255,255,1);
}
.trohpie_bg_trending_1_small_of {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 37px;
    left: 2px;
    top: 2px;
}
#trophy_img_trending_1_small_of {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 6px;
    top: 6px;
    overflow: visible;
}
#n_234_count_trend_off_PC {
    left: 45px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17,17,17,1);
}
#share_bdr_small_40trending_off {
    fill: rgba(255,255,255,1);
}
.share_bdr_small_40trending_off {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 216px;
    top: 2px;
}
#share_5_reqoffevent_off_PC {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 223px;
    top: 9px;
    overflow: visible;
}

#n__card_grp_reqoff_off_PC {
    position: relative;
    width: 540px;
    height: 679px;
    left: 0px;
    top: 1px;
    display: inline-flex;
    overflow: visible;
}

#add_offering_off_PC {
    position: absolute;
    width: 134px;
    height: 30px;
    right: 30px;
    top: 81px;
    cursor: pointer;
    overflow: visible;
}
#Add_Offerings_off_PC {
    left: 36px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    line-height: 27px;
    margin-top: -5.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(17,17,17,1);
}
#add_2_img_off_PC {
    position: absolute;
    width: 29px;
    height: 30px;
    left: 0px;
    top: 0px;
    overflow: visible;
}