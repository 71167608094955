	.mediaViewInfo {
		--web-view-name: Paw Palace;
		--web-view-id: Paw_Palace;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Paw_Palace;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#Paw_Palace {
		position: absolute;
		width: 1366px;
		height: 3223.5px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(243,246,251,1);
		overflow: hidden;
		--web-view-name: Paw_Palace;
		--web-view-id: Paw_Palace;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#petpaws_12_pcGroup_76 {
		position: absolute;
		width: 1876.009px;
		height: 1030.802px;
		left: -140.268px;
		top: -346.32px;
		overflow: visible;
	}
	#petpaws_12_pcPath_261 {
		fill: rgba(255,103,82,1);
	}
	.petpaws_12_pcPath_261 {
		overflow: visible;
		position: absolute;
		width: 1876.009px;
		height: 912.367px;
		left: 0px;
		top: 118.436px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcPath_262_ci {
		opacity: 0.15;
		fill: url(#petpaws_12_pcPath_262_ci);
	}
	.petpaws_12_pcPath_262_ci {
		mix-blend-mode: multiply;
		overflow: visible;
		position: absolute;
		width: 1740.856px;
		height: 831.209px;
		left: 113.22px;
		top: 118.094px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcPath_263_ck {
		opacity: 0.15;
		fill: url(#petpaws_12_pcPath_263_ck);
	}
	.petpaws_12_pcPath_263_ck {
		mix-blend-mode: multiply;
		overflow: visible;
		position: absolute;
		width: 1804.992px;
		height: 911.309px;
		left: 53.47px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcGroup_75 {
		position: absolute;
		width: 2195.199px;
		height: 1167.126px;
		left: -312.123px;
		top: 603.555px;
		overflow: visible;
	}
	#petpaws_12_pcPath_251 {
		fill: rgba(68,135,255,1);
	}
	.petpaws_12_pcPath_251 {
		overflow: visible;
		position: absolute;
		width: 2195.199px;
		height: 1167.125px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcMask_Group_6 {
		opacity: 0.2;
		mix-blend-mode: multiply;
		position: absolute;
		width: 2195.199px;
		height: 1167.127px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcMask_Group_5 {
		opacity: 0.15;
		mix-blend-mode: multiply;
		position: absolute;
		width: 2195.199px;
		height: 1167.127px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_85 {
		position: absolute;
		width: 944px;
		height: 946px;
		left: 1020.983px;
		top: 1734.998px;
		overflow: visible;
	}
	#petpaws_12_pcEllipse_16 {
		fill: rgba(255,229,48,1);
	}
	.petpaws_12_pcEllipse_16 {
		position: absolute;
		overflow: visible;
		width: 944px;
		height: 946px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcEllipse_20_cs {
		opacity: 0.15;
		fill: url(#petpaws_12_pcEllipse_20_cs);
	}
	.petpaws_12_pcEllipse_20_cs {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 732px;
		height: 733px;
		left: 68px;
		top: 0px;
	}
	#petpaws_12_pcEllipse_21_cu {
		opacity: 0.15;
		fill: url(#petpaws_12_pcEllipse_21_cu);
	}
	.petpaws_12_pcEllipse_21_cu {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 560px;
		height: 561px;
		left: 154px;
		top: 0px;
	}
	#petpaws_12_pcGroup_96 {
		transform: matrix(1,0,0,1,-39.7714,2406.1368) rotate(105deg);
		transform-origin: center;
		position: absolute;
		width: 283.628px;
		height: 283.992px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcEllipse_16_cw {
		fill: rgba(255,229,48,1);
	}
	.petpaws_12_pcEllipse_16_cw {
		position: absolute;
		overflow: visible;
		width: 347.724px;
		height: 347.465px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcEllipse_20_cy {
		opacity: 0.15;
		fill: url(#petpaws_12_pcEllipse_20_cy);
	}
	.petpaws_12_pcEllipse_20_cy {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 269.43px;
		height: 269.688px;
		left: 73.016px;
		top: 36.279px;
	}
	#petpaws_12_pcEllipse_21_c {
		opacity: 0.15;
		fill: url(#petpaws_12_pcEllipse_21_c);
	}
	.petpaws_12_pcEllipse_21_c {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 206.461px;
		height: 206.461px;
		left: 129.295px;
		top: 74.535px;
	}
	#petpaws_12_pcGroup_56 {
		position: absolute;
		width: 288px;
		height: 220.001px;
		left: 163.073px;
		top: 245.561px;
		overflow: visible;
	}
	#petpaws_12_pcGet_A_Pet_Now {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(255,255,255,1);
	}
	#petpaws_12_pcPawsitively_Devoted__Your_Pets {
		left: 0px;
		top: 82px;
		position: absolute;
		overflow: visible;
		width: 263px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#petpaws_12_pcGroup_55 {
		position: absolute;
		width: 168px;
		height: 53px;
		left: 0px;
		top: 167px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_1 {
		position: absolute;
		width: 168px;
		height: 53px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcRectangle_21 {
		filter: blur(50px);
		fill: rgba(68,135,255,0.341);
	}
	.petpaws_12_pcRectangle_21 {
		filter: drop-shadow(0px 10px 10px rgba(68, 135, 255, 1));
		position: absolute;
		overflow: visible;
		width: 198px;
		height: 83px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcRectangle_1 {
		fill: rgba(68,135,255,1);
	}
	.petpaws_12_pcRectangle_1 {
		filter: drop-shadow(0px 3px 6px rgba(44, 29, 173, 0.161));
		position: absolute;
		overflow: visible;
		width: 186px;
		height: 71px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcExplore_More {
		left: 38px;
		top: 17px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(248,248,248,1);
	}
	#petpaws_12_pcGroup_101 {
		position: absolute;
		width: 1120px;
		height: 438.001px;
		left: 123px;
		top: 1683px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_100 {
		position: absolute;
		width: 1120px;
		height: 289.001px;
		left: 0px;
		top: 149px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_99 {
		position: absolute;
		width: 1120px;
		height: 45px;
		left: 0px;
		top: 131.002px;
		overflow: visible;
	}
	#petpaws_12_pcEllipse_10 {
		fill: rgba(255,103,82,1);
	}
	.petpaws_12_pcEllipse_10 {
		filter: drop-shadow(0px 3px 6px rgba(253, 104, 69, 0.302));
		position: absolute;
		overflow: visible;
		width: 63px;
		height: 63px;
		left: 1075px;
		top: 0px;
	}
	#petpaws_12_pcPath_201 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaws_12_pcPath_201 {
		overflow: visible;
		position: absolute;
		width: 22.479px;
		height: 2px;
		left: 1087.087px;
		top: 22.23px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcPath_202 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaws_12_pcPath_202 {
		overflow: visible;
		position: absolute;
		width: 7.033px;
		height: 12.066px;
		left: 1102.947px;
		top: 17.752px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcEllipse_10_df {
		fill: rgba(255,103,82,1);
	}
	.petpaws_12_pcEllipse_10_df {
		filter: drop-shadow(0px 3px 6px rgba(253, 104, 69, 0.302));
		position: absolute;
		overflow: visible;
		width: 63px;
		height: 63px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcPath_201_dg {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaws_12_pcPath_201_dg {
		overflow: visible;
		position: absolute;
		width: 22.479px;
		height: 2px;
		left: 12.9px;
		top: 22.23px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcPath_202_dh {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaws_12_pcPath_202_dh {
		overflow: visible;
		position: absolute;
		width: 7.034px;
		height: 12.066px;
		left: 12.9px;
		top: 17.752px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcGroup_14918 {
		position: absolute;
		width: 388px;
		height: 229.001px;
		left: 88px;
		top: 39px;
		overflow: visible;
	}
	#petpaws_12_pcRectangle_22 {
		filter: blur(15px);
		fill: rgba(168,158,255,1);
	}
	.petpaws_12_pcRectangle_22 {
		filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		position: absolute;
		overflow: visible;
		width: 522px;
		height: 369px;
		left: 8px;
		top: 5px;
	}
	#petpaws_12_pcRectangle_15 {
		fill: rgba(253,104,69,1);
	}
	.petpaws_12_pcRectangle_15 {
		filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		position: absolute;
		overflow: visible;
		width: 406px;
		height: 247px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcMask_Group_7 {
		opacity: 0.1;
		mix-blend-mode: multiply;
		position: absolute;
		width: 388px;
		height: 229px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcMask_Group_8 {
		opacity: 0.15;
		mix-blend-mode: multiply;
		position: absolute;
		width: 388px;
		height: 229px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_47 {
		position: absolute;
		width: 290px;
		height: 182.001px;
		left: 18px;
		top: 11px;
		overflow: visible;
	}
	#petpaws_12_pcKenned_Dsouza {
		left: 115.817px;
		top: 81.48px;
		position: absolute;
		overflow: visible;
		width: 111px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(248,248,248,1);
	}
	#petpaws_12_pcn_I_like_that_I_got_to_meet_th {
		opacity: 0.8;
		left: 0px;
		top: 122px;
		position: absolute;
		overflow: visible;
		width: 291px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		color: rgba(248,248,248,1);
	}
	#petpaws_12_pcdownload_2 {
		position: absolute;
		width: 170px;
		height: 71px;
		left: 47px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_14917 {
		position: absolute;
		width: 388px;
		height: 229.001px;
		left: 645px;
		top: 39px;
		overflow: visible;
	}
	#petpaws_12_pcRectangle_22_ds {
		filter: blur(15px);
		fill: rgba(168,158,255,1);
	}
	.petpaws_12_pcRectangle_22_ds {
		filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		position: absolute;
		overflow: visible;
		width: 522px;
		height: 369px;
		left: 8px;
		top: 5px;
	}
	#petpaws_12_pcRectangle_15_dt {
		fill: rgba(253,104,69,1);
	}
	.petpaws_12_pcRectangle_15_dt {
		filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		position: absolute;
		overflow: visible;
		width: 406px;
		height: 247px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcMask_Group_7_du {
		opacity: 0.1;
		mix-blend-mode: multiply;
		position: absolute;
		width: 388px;
		height: 229px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcMask_Group_8_dv {
		opacity: 0.15;
		mix-blend-mode: multiply;
		position: absolute;
		width: 388px;
		height: 229px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_47_dw {
		position: absolute;
		width: 318px;
		height: 182.001px;
		left: 18px;
		top: 11px;
		overflow: visible;
	}
	#petpaws_12_pcKenned_Dsouza_dx {
		left: 116.249px;
		top: 81.48px;
		position: absolute;
		overflow: visible;
		width: 111px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(248,248,248,1);
	}
	#petpaws_12_pcn_I_like_that_I_got_to_meet_th_dy {
		opacity: 0.8;
		left: 0px;
		top: 122px;
		position: absolute;
		overflow: visible;
		width: 291px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		color: rgba(248,248,248,1);
	}
	#petpaws_12_pcdownload_2_dz {
		position: absolute;
		width: 170px;
		height: 71px;
		left: 148px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_14916 {
		position: absolute;
		width: 490px;
		height: 289.001px;
		left: 322px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcRectangle_22_d {
		filter: blur(15px);
		fill: rgba(168,158,255,1);
	}
	.petpaws_12_pcRectangle_22_d {
		filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		position: absolute;
		overflow: visible;
		width: 618px;
		height: 426px;
		left: 11px;
		top: 7px;
	}
	#petpaws_12_pcRectangle_15_d {
		fill: rgba(68,135,255,1);
	}
	.petpaws_12_pcRectangle_15_d {
		filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 307px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcMask_Group_7_d {
		opacity: 0.1;
		mix-blend-mode: multiply;
		position: absolute;
		width: 490px;
		height: 289px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcMask_Group_8_d {
		opacity: 0.15;
		mix-blend-mode: multiply;
		position: absolute;
		width: 490px;
		height: 289px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_47_d {
		position: absolute;
		width: 450px;
		height: 169.582px;
		left: 8px;
		top: 116.418px;
		overflow: visible;
	}
	#petpaws_12_pcKenned_Dsouza_d {
		left: 163.455px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 151px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(248,248,248,1);
	}
	#petpaws_12_pcScroll_Group_35 {
		mix-blend-mode: normal;
		position: absolute;
		width: 450px;
		height: 131px;
		left: 0px;
		top: 38.582px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#petpaws_12_pcScroll_Group_35::-webkit-scrollbar{
		display: none;
	}
	#petpaws_12_pcn_I_like_that_I_got_to_meet_th_d {
		opacity: 0.8;
		left: 29px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 393px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(248,248,248,1);
	}
	#petpaws_12_pcdownload_2_d {
		position: absolute;
		width: 379px;
		height: 89px;
		left: 49px;
		top: 17px;
		overflow: visible;
	}
	#petpaws_12_pcHeres_what_We_Provides {
		left: 351px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 409px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(255,103,82,1);
	}
	#petpaws_12_pcPath_249 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaws_12_pcPath_249 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 1px;
		left: 911.52px;
		top: 455.561px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcGroup_73 {
		position: absolute;
		width: 417.453px;
		height: 512.819px;
		left: 825.547px;
		top: 94px;
		overflow: visible;
	}
	#petpaws_12_pcEllipse_17 {
		fill: rgba(255,229,48,1);
	}
	.petpaws_12_pcEllipse_17 {
		position: absolute;
		overflow: visible;
		width: 383.806px;
		height: 383.805px;
		left: 33.648px;
		top: 129.014px;
	}
	#petpaws_12_pcMask_Group_3 {
		position: absolute;
		width: 383.806px;
		height: 383.805px;
		left: 33.648px;
		top: 129.014px;
		overflow: visible;
	}
	#petpaws_12_pcMask_Group_4 {
		position: absolute;
		width: 417.453px;
		height: 402.645px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_95 {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 163.073px;
		top: 40px;
		overflow: visible;
	}
	#petpaws_12_pcAnime_Edit {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 0px;
		top: 0px;
		overflow: hidden;
        border-radius: 50%;
	}
	#petpaws_12_pcGroup_107 {
		position: absolute;
		width: 1134px;
		height: 123.6px;
		left: 111px;
		top: 2374.4px;
		overflow: visible;
	}
	#petpaws_12_pcAmazing_Partner {
		left: 502.111px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 135px;
		white-space: nowrap;
		text-align: center;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(177,187,205,1);
	}
	#petpaws_12_pcScroll_Group_36 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1134px;
		height: 76px;
		left: 0px;
		top: 47.6px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#petpaws_12_pcScroll_Group_36::-webkit-scrollbar{
		display: none;
	}
	#petpaws_12_pcpartner_grp_website_6_pc {
		position: absolute;
		width: 252px;
		height: 50px;
		left: 34px;
		top: 13px;
		overflow: visible;
	}
	#petpaws_12_pcAnime_Edit_img_website_6_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcMohammed_Affan_lbl_website_6_p {
		left: 65px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 188px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#petpaws_12_pcGroup_14899 {
		position: absolute;
		width: 976.535px;
		height: 437.266px;
		left: 204px;
		top: 804.383px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_43 {
		position: absolute;
		width: 395.023px;
		height: 392.279px;
		left: 581.512px;
		top: 44.986px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_42 {
		position: absolute;
		width: 395.023px;
		height: 392.279px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_38:hover {
		width: 186.279px;
		height: 186.279px;
		left: 6px;
		top: 5px;
	}
	#petpaws_12_pcGroup_38 {
		position: absolute;
		width: 175.032px;
		height: 175.032px;
		left: 0px;
		top: 0px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#petpaws_12_pcRectangle_15_e {
		fill: rgba(255,255,255,1);
	}
	.petpaws_12_pcRectangle_15_e {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 193.032px;
		height: 193.031px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcGroup_37_e {
		position: absolute;
		width: 56.235px;
		height: 106.056px;
		left: 59.75px;
		top: 35.146px;
		overflow: visible;
	}
	#petpaws_12_pcVision_e {
		left: 3.514px;
		top: 85.057px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(112,112,112,1);
	}
	#petpaws_12_pcdog_e {
		opacity: 0.3;
		position: absolute;
		width: 56.235px;
		height: 56.249px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcPath_205_e {
		fill: rgba(0,34,92,1);
	}
	.petpaws_12_pcPath_205_e {
		overflow: visible;
		position: absolute;
		width: 2.336px;
		height: 2.336px;
		left: 30.193px;
		top: 19.725px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcPath_206_e {
		fill: rgba(0,34,92,1);
	}
	.petpaws_12_pcPath_206_e {
		overflow: visible;
		position: absolute;
		width: 2.336px;
		height: 2.336px;
		left: 8.628px;
		top: 12px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcPath_207_e {
		fill: rgba(0,34,92,1);
	}
	.petpaws_12_pcPath_207_e {
		overflow: visible;
		position: absolute;
		width: 56.235px;
		height: 56.248px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcGroup_40:hover {
		width: 186.279px;
		height: 186.279px;
		left: 108.604px;
		top: 206px;
	}
	#petpaws_12_pcGroup_40 {
		position: absolute;
		width: 175.032px;
		height: 175.032px;
		left: 102.981px;
		top: 200.338px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#petpaws_12_pcRectangle_15_fl {
		fill: rgba(255,255,255,1);
	}
	.petpaws_12_pcRectangle_15_fl {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 193.032px;
		height: 193.031px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcGroup_37_fm {
		position: absolute;
		width: 72.403px;
		height: 113.788px;
		left: 51.314px;
		top: 27.414px;
		overflow: visible;
	}
	#petpaws_12_pcMask_Group_2_fn {
		position: absolute;
		width: 72.403px;
		height: 72.402px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcPrinciples_fo {
		left: 1.406px;
		top: 92.789px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(112,112,112,1);
	}
	#petpaws_12_pcGroup_65_fp {
		position: absolute;
		width: 56.235px;
		height: 56.233px;
		left: 59.75px;
		top: 36.553px;
		overflow: visible;
	}
	#petpaws_12_pcpet-house_fq {
		opacity: 0.3;
		mix-blend-mode: normal;
		position: absolute;
		width: 56.235px;
		height: 56.233px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_60_fr {
		position: absolute;
		width: 56.235px;
		height: 56.233px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_59_fs {
		position: absolute;
		width: 56.235px;
		height: 56.233px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcPath_221_ft {
		fill: rgba(0,34,92,1);
	}
	.petpaws_12_pcPath_221_ft {
		overflow: visible;
		position: absolute;
		width: 56.235px;
		height: 56.232px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcGroup_62_fu {
		position: absolute;
		width: 3.295px;
		height: 3.295px;
		left: 26.47px;
		top: 19.77px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_61_fv {
		position: absolute;
		width: 3.295px;
		height: 3.295px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcEllipse_15_fw {
		fill: rgba(0,34,92,1);
	}
	.petpaws_12_pcEllipse_15_fw {
		position: absolute;
		overflow: visible;
		width: 3.295px;
		height: 3.295px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcGroup_39:hover {
		width: 186.279px;
		height: 186.279px;
		left: 208.744px;
		top: 5px;
	}
	#petpaws_12_pcGroup_39 {
		position: absolute;
		width: 175.032px;
		height: 175.032px;
		left: 201.744px;
		top: 0px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#petpaws_12_pcRectangle_15_f {
		fill: rgba(255,255,255,1);
	}
	.petpaws_12_pcRectangle_15_f {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 193.032px;
		height: 193.031px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcGroup_37_ga {
		position: absolute;
		width: 54px;
		height: 106.056px;
		left: 59.047px;
		top: 35.146px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_63_ga {
		opacity: 0.3;
		mix-blend-mode: normal;
		position: absolute;
		width: 48.739px;
		height: 56.235px;
		left: 4.218px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pckitten_gb {
		position: absolute;
		width: 48.739px;
		height: 56.235px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcGroup_58_gc {
		position: absolute;
		width: 48.739px;
		height: 56.235px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcPath_208_gd {
		fill: rgba(0,34,92,1);
	}
	.petpaws_12_pcPath_208_gd {
		overflow: visible;
		position: absolute;
		width: 48.739px;
		height: 56.234px;
		left: 0px;
		top: 0.002px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcEllipse_12_ge {
		fill: rgba(0,34,92,1);
	}
	.petpaws_12_pcEllipse_12_ge {
		position: absolute;
		overflow: visible;
		width: 1.875px;
		height: 1.875px;
		left: 8.436px;
		top: 7.496px;
	}
	#petpaws_12_pcEllipse_13_gf {
		fill: rgba(0,34,92,1);
	}
	.petpaws_12_pcEllipse_13_gf {
		position: absolute;
		overflow: visible;
		width: 1.875px;
		height: 1.875px;
		left: 13.122px;
		top: 7.496px;
	}
	#petpaws_12_pcMission_gg {
		left: 0px;
		top: 85.057px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(112,112,112,1);
	}
	#petpaws_12_pcGroup_57 {
		position: absolute;
		width: 96px;
		height: 44px;
		left: 154.124px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcVision_hd {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(255,103,82,1);
	}
	#petpaws_12_pcScroll_Group_1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 463px;
		height: 180px;
		left: 0px;
		top: 64.617px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#petpaws_12_pcScroll_Group_1::-webkit-scrollbar{
		display: none;
	}
	#petpaws_12_pcn_I_like_that_I_got_to_meet_th_hf {
		opacity: 0.8;
		left: 8px;
		top: 2px;
		position: absolute;
		overflow: visible;
		width: 455px;
		height: 176px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#petpaws_12_pcSHOP_NAME {
		left: 163.073px;
		top: 154.561px;
		position: absolute;
		overflow: visible;
		width: 352px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 59px;
		color: rgba(255,255,255,1);
	}
	#petpaws_12_pcGroup_14910 {
		position: absolute;
		width: 2323.922px;
		height: 619.994px;
		left: -478.961px;
		top: 2766.1px;
		overflow: visible;
	}
	#petpaws_12_pcEllipse_19 {
		fill: rgba(255,103,82,1);
	}
	.petpaws_12_pcEllipse_19 {
		position: absolute;
		overflow: visible;
		width: 2323.922px;
		height: 619.994px;
		left: 0px;
		top: 0px;
	}
	#petpaws_12_pcsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 1310px;
		top: 311.9px;
		overflow: visible;
	}
	#petpaws_12_pclinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.petpaws_12_pcmed_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#petpaws_12_pcmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.petpaws_12_pcmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#petpaws_12_pclinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#petpaws_12_pcn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.petpaws_12_pcn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pclinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.petpaws_12_pclinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pctop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.petpaws_12_pctop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaws_12_pcInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.petpaws_12_pcshadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#petpaws_12_pcshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.petpaws_12_pcshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#petpaws_12_pcinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#petpaws_12_pcwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaws_12_pcmed_shadow_space_website_7_hw:hover {
		fill: rgba(50,172,158,1);
	}
	.petpaws_12_pcmed_shadow_space_website_7_hw:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#petpaws_12_pcmed_shadow_space_website_7_hw {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.petpaws_12_pcmed_shadow_space_website_7_hw {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#petpaws_12_pcwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#petpaws_12_pcn_234567890_mobile_number_shad {
		left: 1345px;
		top: 80.9px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#petpaws_12_pcaffanghanioperatelive_email_sh {
		left: 1350px;
		top: 133.9px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#petpaws_12_pcaddress_med_shadow_space_websi {
		left: 1345px;
		top: 183.9px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#petpaws_12_pclocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 1310px;
		top: 185.9px;
		overflow: visible;
	}
	#petpaws_12_pcemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 1310px;
		top: 132.9px;
		overflow: visible;
	}
	#petpaws_12_pcphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 1310px;
		top: 79.9px;
		overflow: visible;
	}
	#petpaws_12_pcn_Copyright_Operate_Live_OPC_P {
		left: 1310px;
		top: 383.9px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#petpaws_12_pcRectangle_694 {
		fill: rgba(255,255,255,1);
	}
	.petpaws_12_pcRectangle_694 {
		position: absolute;
		overflow: visible;
		width: 491px;
		height: 311px;
		left: 652px;
		top: 94.9px;
		border-radius: 10px;
	}
	#petpaws_12_pcn_a21e555c63e24831805521512170 {
		position: absolute;
		width: 141px;
		height: 164px;
		left: 515px;
		top: 250.9px;
		overflow: visible;
	}
	#petpaws_12_pcVisit_Us_ {
		left: 837px;
		top: 46.9px;
		position: absolute;
		overflow: visible;
		width: 122px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}