.OFFM_big_1_trending_off_mob {
    position: relative;
    width: 339px;
    height: 427px;
    left: 0px;
    /* top: 461px; */
    margin-top: 5px;
    overflow: visible;
}
#OFFM_bdr_all_trending_off {
    fill: rgba(245,245,246,1);
}
.OFFM_bdr_all_trending_off {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 357px;
    height: 445px;
    left: 0px;
    top: 0px;
}
#OFFM_bdr_amount_trending_off {
    fill: rgba(255,255,255,1);
}
.OFFM_bdr_amount_trending_off {
    position: absolute;
    overflow: visible;
    width: 65px;
    height: 21px;
    left: 270px;
    top: 370px;
}
#OFFM_n_9_trending_off {
    left: 275px;
    top: 373px;
    position: absolute;
    overflow: visible;
    width: 60px;
    white-space: nowrap;
    text-align: center;
    font-family: Poppins-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#OFFM_Image_trending__off_ {
    position: absolute;
    width: 336px;
    height: 355px;
    /* left: 6px;
    top: 35px; */
    overflow: visible;
}
#OFFM_Nike_Air_Force_1_Sha_trending_ {
    left: 5px;
    top: 366px;
    position: absolute;
    overflow: visible;
    width: 250px;
    white-space: nowrap;
    /* line-height: 19px; */
    /* margin-top: -2px; */
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.1818181800842285px;
}
#OFFM_Single_Tour_trending_off {
    left: 18px;
    top: 398px;
    position: absolute;
    overflow: visible;
    width: 280px;
    white-space: nowrap;
    /* line-height: 18px; */
    /* margin-top: -4px; */
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(17,17,17,1);
}
#OFFM_Group_125 {
    position: absolute;
    width: 24px;
    height: 23px;
    left: 2px;
    top: 2px;
    overflow: visible;
}
#OFFM_bdr_1_trending_off_ {
    fill: rgba(255,255,255,1);
}
.OFFM_bdr_1_trending_off_ {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 23px;
    left: 0px;
    top: 0px;
}
#OFFM_trophy_trending_off_ {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 2px;
    top: 2px;
    overflow: visible;
}
#OFFM_Group_124 {
    position: absolute;
    width: 24px;
    height: 14px;
    left: 27px;
    top: 7px;
    overflow: visible;
}
#OFFM_likes_trending_off_ {
    fill: rgba(255,255,255,1);
}
.OFFM_likes_trending_off_ {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 13px;
    left: 0px;
    top: 1px;
}
#OFFM_n_234_like_trending_off_ {
    left: 1px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 23px;
    white-space: nowrap;
    /* line-height: 18px; */
    /* margin-top: -4px; */
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#off_m_Group_134 {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 311px;
    top: 2px;
    overflow: visible;
}
#off_m_share_bdr_small_4_trending_com {
    fill: rgba(255,255,255,1);
}
.off_m_share_bdr_small_4_trending_com {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 0px;
    top: 0px;
}
#off_m_share_2_com_of {
    position: absolute;
    width: 15px;
    height: 14px;
    left: 5px;
    top: 5px;
    overflow: visible;
}