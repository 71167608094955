/* #n__card_grp_reqoff_req_Pc {
    position: absolute;
    width: 540px;
    height: 679px;
    left: 0px;
    top: 1px;
    overflow: visible;
} */
#small_4_trending_req_Pc {
    position: relative;
    width: 255px;
    height: 313px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#all_bdr_small_4_trending_req_Pc {
    fill: rgba(245,245,245,1);
}
.all_bdr_small_4_trending_req_Pc {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 273px;
    height: 331px;
    left: 0px;
    top: 0px;
}
#Image_small_4_trending_req_Pc {
    position: absolute;
    width: 214px;
    height: 214px;
    left: 21px;
    top: 37px;
    overflow: visible;
}
#bdr_amount_small_4_trending_req_Pc {
    fill: rgba(255,255,255,1);
}
.bdr_amount_small_4_trending_req_Pc {
    position: absolute;
    overflow: visible;
    width: 75px;
    height: 34px;
    left: 177px;
    top: 253px;
}
#n_9_small_4_trending_req_Pc {
    left: 180px;
    top: 259px;
    position: absolute;
    overflow: visible;
    width: 70px;
    white-space: pre;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#NikeCourt_Air_small_4_trending_req_Pc {
    /* left: 25.5px; */
    top: 260.5px;
    position: absolute;
    overflow: visible;
    width: 170px;
    white-space: pre-wrap;
    line-height: 32px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.1818181800842285px;
}
#Single_Tour_small_4_trending_req_Pc {
    left: 5px;
    top: 282px;
    position: absolute;
    overflow: visible;
    width: 175px;
    white-space: pre;
    line-height: 30px;
    /* margin-top: -8.5px; */
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(17,17,17,1);
}
#number_small_4_trending_req_Pc {
    fill: rgba(255,255,255,1);
}
.number_small_4_trending_req_Pc {
    position: absolute;
    overflow: visible;
    width: 39px;
    height: 21px;
    left: 41px;
    top: 10px;
}
#trohpie_bdr_small_4_trending_req_Pc {
    fill: rgba(255,255,255,1);
}
.trohpie_bdr_small_4_trending_req_Pc {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 37px;
    left: 1px;
    top: 1px;
}
#trophy_small_4_trending_req_Pc {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#n_234_small_4_trending_req_Pc {
    left: 44px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17,17,17,1);
}
#share_bdr_small_4_trending_req_Pc {
    fill: rgba(255,255,255,1);
}
.share_bdr_small_4_trending_req_Pc {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 216px;
    top: 2px;
}
#share_2_reqoffevent_req_Pc {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 223px;
    top: 9px;
    overflow: visible;
}