	.mediaViewInfo {
		--web-view-name: website 1 pc;
		--web-view-id: website_1_pc;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: website_1_pc;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#website_1_pc {
		position: absolute;
		width: 1366px;
		height: 3917px;
        left:calc((100vw - 1366px)/2);
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: website 1 pc;
		--web-view-id: website_1_pc;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#top_banner_bg_website_1_op_liv {
		position: absolute;
		width: 1366px;
		height: 226px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#top_menu_bg_desgin_website_1_o {
		fill: rgba(143,233,191,1);
	}
	.top_menu_bg_desgin_website_1_o {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 69px;
		left: 0px;
		top: 226px;
	}
	#top_meny_btn_website_1_op_live {
		position: absolute;
		width: 479px;
		height: 27px;
		left: 444px;
		top: 247px;
		overflow: visible;
	}
	#Intro_txt_website_1_op_live_th {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#intro_img_website_1_op_live_th {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#Offerings_txt_website_1_op_liv {
		left: 137px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#discount_img_website_1_op_live {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 112px;
		top: 4px;
		overflow: visible;
	}
	#Events_txt_website_1_op_live_t {
		left: 284px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 58px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#event_img_website_1_op_live_th {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 259px;
		top: 4px;
		overflow: visible;
	}
	#Contact_txt_website_1_op_live_ {
		left: 411px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#contact_img_website_1_op_live_ {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 386px;
		top: 4px;
		overflow: visible;
	}
	#top_overall_grp_website_1_op_l {
		position: absolute;
		width: 1033px;
		height: 623px;
		left: 121px;
		top: 337px;
		overflow: visible;
	}
	#pfp_pic_vwebsite_1_op_live_the {
		position: absolute;
		width: 233px;
		height: 233px;
		left: 0px;
		top: 0px;
		border-radius: 50%;
		overflow: hidden;
	}
	#top_name_website_1_op_live_the {
		left: 259px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 293px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		color: rgba(0,0,0,1);
	}
	#Scroll_intro_web_1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 785px;
		height: 148px;
		left: 248px;
		top: 85px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#Lorem_ipsum_dolor_sit_amet_em_pc {
		
	    position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
        left: 10px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#mission_grp_web_1 {
		position: absolute;
		width: 290px;
		height: 397px;
		left: 73px;
		top: 291px;
		overflow: visible;
	}
	#mission_card_ {
		fill: rgba(143,214,233,1);
	}
	.mission_card_ {
		position: absolute;
		overflow: visible;
		width: 290px;
		height: 397px;
		left: 0px;
		top: 0px;
	}
	#box_1_website_1_op_live_theme {
		position: absolute;
		width: 69px;
		height: 59px;
		left: 110px;
		top: 55px;
		overflow: visible;
	}
	#mission_pc_web1 {
		left: 99px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Scroll_mission_details_web_1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 270px;
		height: 252px;
		left: 20px;
		top: 145px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Lorem_ipsum_dolor_sit_amet_con_ca {
	
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#vision_grp_web_1 {
		position: absolute;
		width: 290px;
		height: 397px;
		left: 398px;
		top: 291px;
		overflow: visible;
	}
	#vision_card {
		fill: rgba(143,233,191,1);
	}
	.vision_card {
		position: absolute;
		overflow: visible;
		width: 290px;
		height: 397px;
		left: 0px;
		top: 0px;
	}
	#box_2_website_1_op_live_theme {
		position: absolute;
		width: 70px;
		height: 59px;
		left: 112px;
		top: 55px;
		overflow: visible;
	}
	#top_offerings_ce {
		left: 109px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Scroll_vision_details_web_1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 265px;
		height: 252px;
		left: 26px;
		top: 145px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Lorem_ipsum_dolor_sit_amet_con_cg {
		
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#princi_grp_web_1 {
		position: absolute;
		width: 290px;
		height: 397px;
		left: 729px;
		top: 291px;
		overflow: visible;
	}
	#principle_mission_card_ {
		fill: rgba(215,232,188,1);
	}
	.principle_mission_card_ {
		position: absolute;
		overflow: visible;
		width: 290px;
		height: 397px;
		left: 0px;
		top: 0px;
	}
	#box_3_off_website_1_op_live_th {
		position: absolute;
		width: 70px;
		height: 59px;
		left: 110px;
		top: 55px;
		overflow: visible;
	}
	#top_offerings_ck_pc {
		left: 88px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 115px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Scroll_prrinci_details_web_1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 265px;
		height: 252px;
		left: 26px;
		top: 145px;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	#Lorem_ipsum_dolor_sit_am_principle_pc {
		
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Mohammed_Affan_Ghani_lbl_websi {
		left: 527px;
		top: 1079px;
		position: absolute;
		overflow: visible;
		width: 314px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		color: rgba(0,0,0,1);
	}
	#partner_bg_website_1_op_live_t {
		fill: transparent;
	}
	.partner_bg_website_1_op_live_t {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 130px;
		left: 0px;
		top: 1152px;
		background:  rgba( 255, 255, 255, 0.5 );
		box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
		backdrop-filter:  blur( 10px );
		-webkit-backdrop-filter:  blur( 10px );
		border-radius:  10px;
		border:  1px solid rgba( 255, 255, 255, 0.18 );
	}
	#Scroll_partner_web1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1082px;
		height: 83px;
		left: 143px;
		top: 1190px;
		display: inline-flex;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#partner_lbls_grp {
		position: relative;
		width: 175px;
		height: 27px;
		left: 13px;
		top: 14px;
		overflow: visible;
	}
	#google_lbl_partner {
		left: 30px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#google_1_website_1 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}

	#Scroll_my_offering_web_1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1366px;
		height: 1249px;
		left: 0px;
		top: 1413px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Recent_Work__website_1_op_live {
		position: absolute;
		width: 1005px;
		height: 610.5px;
		left: 180px;
		top: 29.5px;
		overflow: visible;
	}
	#first_offering_grp_web_1 {
		position: relative;
		width: 475px;
		height: 555.5px;
		left: 0px;
		top: 55px;
		overflow: visible;
	}
	#Project1_website_1_op_live_the {
		position: absolute;
		width: 475px;
		height: 531px;
		left: 0px;
		top: 24.5px;
		overflow: visible;
	}
	#Offer_1_web_1 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 476px;
		height: 21px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#second_offering_grp_web_1 {
		position: relative;
		width: 475px;
		height: 540.5px;
		left: 530px;
		top: 0px;
		overflow: visible;
	}
	#Project2_website_1_op_live_the {
		position: absolute;
		width: 475px;
		height: 517px;
		left: 0px;
		top: 23.5px;
		overflow: visible;
	}
	#Offer_2_web_1 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 476px;
		height: 21px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}


	#next_to_circle_website_1_op_li {
		fill: rgba(143,233,191,1);
	}
	.next_to_circle_website_1_op_li {
		overflow: visible;
		position: absolute;
		width: 832.848px;
		height: 876.211px;
		transform: matrix(1,0,0,1,905.4981,2388.4336) rotate(-8deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#disign_bg_circle_website_1_op_ {
		fill: rgba(143,233,191,1);
	}
	.disign_bg_circle_website_1_op_ {
		width: 530px;
		height: 522px;
		position: absolute;
		overflow: visible;
		transform: matrix(1,0,0,1,783.4053,2803.288) rotate(-5deg);
		transform-origin: center;
	}
	#Colonel_gravity_get_thought_fa {
		left: 180px;
		top: 1391.5px;
		position: absolute;
		overflow: visible;
		width: 471px;
		height: 21px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#Best_Real_Estate_Deals_website {
		left: 180px;
		top: 1333.5px;
		position: absolute;
		overflow: visible;
		width: 179px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#Illustration_website_1 {
		position: absolute;
		width: 479.938px;
		height: 276.261px;
		left: 835.849px;
		top: 2864.112px;
		overflow: visible;
	}
	#table_website_1 {
		fill: rgba(69,74,105,1);
	}
	.table_website_1 {
		overflow: visible;
		position: absolute;
		width: 479.938px;
		height: 28.019px;
		left: 0px;
		top: 248.242px;
		transform: matrix(1,0,0,1,0,0);
	}
	#hair_back_website_1 {
		fill: rgba(69,74,105,1);
	}
	.hair_back_website_1 {
		overflow: visible;
		position: absolute;
		width: 120.639px;
		height: 142.913px;
		left: 188.618px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#neck_website_1_ {
		fill: rgba(238,183,184,1);
	}
	.neck_website_1_ {
		overflow: visible;
		position: absolute;
		width: 86.157px;
		height: 116.892px;
		left: 203.783px;
		top: 79.69px;
		transform: matrix(1,0,0,1,0,0);
	}
	#shirt_colar_website_1 {
		fill: rgba(255,255,255,1);
	}
	.shirt_colar_website_1 {
		overflow: visible;
		position: absolute;
		width: 106.372px;
		height: 107.951px;
		left: 198.297px;
		top: 111.005px;
		transform: matrix(1,0,0,1,0,0);
	}
	#shirt_website_1_ {
		fill: rgba(223,132,126,1);
	}
	.shirt_website_1_ {
		overflow: visible;
		position: absolute;
		width: 162.586px;
		height: 138.027px;
		left: 167.865px;
		top: 109.94px;
		transform: matrix(1,0,0,1,0,0);
	}
	#face_website_1 {
		fill: rgba(238,183,184,1);
	}
	.face_website_1 {
		overflow: visible;
		position: absolute;
		width: 72.325px;
		height: 77.388px;
		left: 212.638px;
		top: 27.82px;
		transform: matrix(1,0,0,1,0,0);
	}
	#front_hair_website_1_ {
		fill: rgba(69,74,105,1);
	}
	.front_hair_website_1_ {
		overflow: visible;
		position: absolute;
		width: 95.989px;
		height: 127.309px;
		left: 201.172px;
		top: 9.646px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_colar_website_1 {
		fill: rgba(255,255,255,1);
	}
	.left_colar_website_1 {
		overflow: visible;
		position: absolute;
		width: 34.201px;
		height: 31.493px;
		left: 251.06px;
		top: 223.648px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_hand_arm_website_1 {
		fill: rgba(223,132,126,1);
	}
	.left_hand_arm_website_1 {
		overflow: visible;
		position: absolute;
		width: 84.272px;
		height: 103.44px;
		left: 275.326px;
		top: 149.509px;
		transform: matrix(1,0,0,1,0,0);
	}
	#right_hand_top_website_1_ {
		fill: rgba(238,183,184,1);
	}
	.right_hand_top_website_1_ {
		overflow: visible;
		position: absolute;
		width: 42.145px;
		height: 75.85px;
		left: 175.122px;
		top: 73.988px;
		transform: matrix(1,0,0,1,0,0);
	}
	#right_arm_collar_website_1 {
		fill: rgba(255,255,255,1);
	}
	.right_arm_collar_website_1 {
		overflow: visible;
		position: absolute;
		width: 29.737px;
		height: 21.604px;
		left: 174.961px;
		top: 138.75px;
		transform: matrix(1,0,0,1,0,0);
	}
	#right_arm_website_1_ {
		fill: rgba(223,132,126,1);
	}
	.right_arm_website_1_ {
		overflow: visible;
		position: absolute;
		width: 43.516px;
		height: 103.593px;
		left: 163.26px;
		top: 152.275px;
		transform: matrix(1,0,0,1,0,0);
	}
	#down_hand_website_1_ {
		fill: rgba(238,183,184,1);
	}
	.down_hand_website_1_ {
		overflow: visible;
		position: absolute;
		width: 86.431px;
		height: 32.634px;
		left: 177.425px;
		top: 229.887px;
		transform: matrix(1,0,0,1,0,0);
	}
	#steam_3_website_1_ {
		fill: rgba(62,60,85,1);
	}
	.steam_3_website_1_ {
		overflow: visible;
		position: absolute;
		width: 4.876px;
		height: 6.834px;
		left: 59.648px;
		top: 236.619px;
		transform: matrix(1,0,0,1,0,0);
	}
	#stem_2_pot_website_1_ {
		fill: rgba(62,60,85,1);
	}
	.stem_2_pot_website_1_ {
		overflow: visible;
		position: absolute;
		width: 4.876px;
		height: 6.834px;
		left: 59.648px;
		top: 232.438px;
		transform: matrix(1,0,0,1,0,0);
	}
	#stem_pot_1_website_1_ {
		fill: rgba(62,60,85,1);
	}
	.stem_pot_1_website_1_ {
		overflow: visible;
		position: absolute;
		width: 4.876px;
		height: 6.834px;
		left: 59.648px;
		top: 228.263px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leave_design_website_1_ {
		fill: rgba(56,210,159,1);
	}
	.pot_1_leave_design_website_1_ {
		overflow: visible;
		position: absolute;
		width: 23.394px;
		height: 55.971px;
		left: 50.353px;
		top: 170.261px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_design_website_1_ {
		fill: rgba(243,123,123,1);
		stroke: rgba(243,123,123,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.pot_1_design_website_1_ {
		overflow: visible;
		position: absolute;
		width: 45.688px;
		height: 26.737px;
		left: 40.015px;
		top: 238.875px;
		transform: matrix(1,0,0,1,0,0);
	}
	#laptop_website_1_ {
		fill: rgba(62,60,85,1);
	}
	.laptop_website_1_ {
		overflow: visible;
		position: absolute;
		width: 113.087px;
		height: 81.014px;
		left: 325.945px;
		top: 186.902px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Connect__Follow_Us__website_pc_ {
		left: 173px;
		top: 2972.677px;
		position: absolute;
		overflow: visible;
		width: 324px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 34px;
		color: rgba(54,54,54,1);
	}
	#footer_overll_grp_web_1 {
		position: absolute;
		width: 1374px;
		height: 489px;
		left: 0px;
		top: 3434px;
		overflow: visible;
	}
	#footer_bdr_web1_da {
		fill: url(#footer_bdr_web1_da);
	}
	.footer_bdr_web1_da {
		overflow: visible;
		position: absolute;
		width: 1374px;
		height: 489px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_234567890_mobile_number_webs_em_pc {
		left: 191px;
		top: 57px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#affanghanioperatelive_email_we_pc {
		left: 192px;
		top: 110.799px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#address_website_1 {
		left: 187px;
		top: 164.661px;
		position: absolute;
		overflow: visible;
		width: 449px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#location_img_website_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 143px;
		top: 164.699px;
		overflow: visible;
	}
	#email_address_website_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 143px;
		top: 110.798px;
		overflow: visible;
	}
	#phone_number_website_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 143px;
		top: 57px;
		overflow: visible;
	}
	#quote_left_webstie_1 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 813.704px;
		top: 265.495px;
		overflow: visible;
	}
	#quote_right_website_1 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 1016.993px;
		top: 293.444px;
		overflow: visible;
	}
	#Discover_a_World_of_Endless_Po {
		left: 852px;
		top: 264.995px;
		position: absolute;
		overflow: visible;
		width: 386px;
		height: 54px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#bottom_line_website_1_op_live_ {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.bottom_line_website_1_op_live_ {
		overflow: visible;
		position: absolute;
		width: 1172.981px;
		height: 1px;
		left: 100px;
		top: 372.86px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_Copyright_Operate_Live_websi_em {
		left: 139.5px;
		top: 419.161px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#socials_grp_web_1 {
		position: absolute;
		width: 255px;
		height: 65px;
		left: 207px;
		top: 3047.586px;
		overflow: visible;
	}
	#linked_in_grp {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 190px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_5_web1:hover {
		fill: rgba(120,120,255,1);
	}
	.Ellipse_5_web1:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_5_web1 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_5_web1 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_website_1_layout {
		position: absolute;
		width: 31.653px;
		height: 28.776px;
		left: 17.063px;
		top: 17.875px;
		overflow: visible;
	}
	#n_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.n_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 20.919px;
		height: 19.736px;
		left: 10.734px;
		top: 9.04px;
		transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_down {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_down {
		overflow: visible;
		position: absolute;
		width: 6.169px;
		height: 19.549px;
		left: 0.798px;
		top: 9.227px;
		transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 7.489px;
		height: 6.783px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_ {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 95px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_4_web1:hover {
		fill: rgba(255,120,120,1);
	}
	.Ellipse_4_web1:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_4_web1 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_4_web1 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagram_website_1_layout {
		position: absolute;
		width: 31.688px;
		height: 31.688px;
		left: 17.063px;
		top: 17.063px;
		overflow: visible;
	}
	#whatsapp_grp {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_3_web1:hover {
		fill: rgba(120,255,165,1);
	}
	.Ellipse_3_web1:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_3_web1 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_3_web1 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_website_1_layout {
		position: absolute;
		width: 32.5px;
		height: 32.5px;
		left: 16.25px;
		cursor: pointer;
		top: 16.25px;
		overflow: visible;
	}
	#map_em_pc_new {
		fill: rgba(255,255,255,1);
	}
	.map_em_pc_new {
		position: absolute;
		overflow: visible;
		width: 452px;
		height: 205px;
		left: 797px;
		top: 3473px;
	}
