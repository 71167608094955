	.mediaViewInfo {
		--web-view-name: website 3;
		--web-view-id: website_3;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: website_3;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#website_3 {
		position: absolute;
		width: 1440px;
		height: 3720px;
        left:calc((100vw - 1440px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: website 3;
		--web-view-id: website_3;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#right_top_blue_design_website_ {
		fill: rgba(189,204,255,1);
	}
	.right_top_blue_design_website_ {
		position: absolute;
		overflow: visible;
		width: 511px;
		height: 841px;
		left: 929px;
		top: 0px;
	}
	#Lines_top_website_3 {
		position: absolute;
		width: 456px;
		height: 841px;
		left: 248px;
		top: -110px;
		overflow: visible;
	}
	#bg_3_website_3 {
		opacity: 0.1;
		fill: rgba(52,84,209,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.bg_3_website_3 {
		width: 1px;
		height: 841px;
		position: absolute;
		overflow: visible;
		transform: translate(0px, 0px) matrix(1,0,0,1,455,0) rotate(180deg);
		transform-origin: center;
	}
	#bg_2_website_3 {
		opacity: 0.1;
		fill: rgba(52,84,209,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.bg_2_website_3 {
		width: 1px;
		height: 224px;
		position: absolute;
		overflow: visible;
		transform: translate(0px, 0px) matrix(1,0,0,1,229,110) rotate(180deg);
		transform-origin: center;
	}
	#bg_1_website_3 {
		opacity: 0.1;
		fill: rgba(52,84,209,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.bg_1_website_3 {
		position: absolute;
		overflow: visible;
		width: 2px;
		height: 427px;
		left: 0px;
		top: 93px;
	}
	#Scroll_comp_intro_web_3 {
		mix-blend-mode: normal;
		position: absolute;
		width: 536px;
		height: 204px;
		left: 135px;
		top: 451px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#Scroll_comp_intro_web_3::-webkit-scrollbar{
		display: none;
	
	}
	#intro_lbl_website_3_ob_pc {
	
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
        left: 0px;
		
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.1px;
	}
	#Heading_website_3 {
		left: 135px;
		top: 277px;
		position: absolute;
		overflow: visible;
		width: 537px;
		height: 145px;
		line-height: 73px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 54px;
		color: rgba(0,0,0,1);
	}
	#Small_Heading_top_website_3 {
		left: 138px;
		top: 224px;
		position: absolute;
		overflow: visible;
		width: 126px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(255,153,0,1);
	}
	
	#we_stand_for_grp {
		position: absolute;
		width: 1100px;
		height: 438px;
		left: 183px;
		top: 1001px;
		overflow: visible;
	}
	#Card_03_website_3 {
		position: absolute;
		width: 273px;
		height: 309px;
		left: 827px;
		top: 125px;
		overflow: visible;
	}
	#Scroll_Group_principle_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 273px;
		height: 120px;
		left: 0px;
		top: 189px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#intro_lbl_website_3_b {
	
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		line-height: 26px;
		left: 0px;
		top: 0px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#principles_lbl_website_3 {
		left: 84.5px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#Fill_pink_website_3 {
		fill: rgba(242,184,236,1);
	}
	.Fill_pink_website_3 {
		filter: drop-shadow(0px 1.852px 3.148px rgba(242, 184, 236, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.445px;
		left: 85px;
		top: 0px;
	}
	#Icon_left_website_3 {
		fill: rgba(255,255,255,1);
	}
	.Icon_left_website_3 {
		overflow: visible;
		position: absolute;
		width: 16.479px;
		height: 20.006px;
		left: 120px;
		top: 37.994px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_top_website_3 {
		fill: rgba(255,255,255,1);
	}
	.Icon_top_website_3 {
		overflow: visible;
		position: absolute;
		width: 18.923px;
		height: 3.37px;
		left: 121.03px;
		top: 33px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_right_website_3 {
		fill: rgba(255,255,255,1);
	}
	.Icon_right_website_3 {
		overflow: visible;
		position: absolute;
		width: 2.983px;
		height: 22.591px;
		left: 138.017px;
		top: 34.193px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Card_02_website_3 {
		position: absolute;
		width: 260px;
		height: 313px;
		left: 407px;
		top: 125px;
		overflow: visible;
	}
	#Scroll_Group_11 {
		mix-blend-mode: normal;
		position: absolute;
		width: 260px;
		height: 120px;
		left: 0px;
		top: 193px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#Scroll_Group_11::-webkit-scrollbar{
		display: none;
	}
	#intro_2_website_3 {
	
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		line-height: 26px;
        left: 0px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#mission_lbl_website_3 {
		left: 93px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#Icon_2_grp_websiote_3 {
		position: absolute;
		width: 90px;
		height: 90px;
		left: 85px;
		top: 0px;
		overflow: visible;
	}
	#bg_blue_design {
		fill: rgba(77,223,253,1);
	}
	.bg_blue_design {
		filter: drop-shadow(0px 1.852px 3.148px rgba(77, 223, 253, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.445px;
		left: 0px;
		top: 0px;
	}
	#Icon_hand_website_3 {
		fill: rgba(255,255,255,1);
	}
	.Icon_hand_website_3 {
		overflow: visible;
		position: absolute;
		width: 25px;
		height: 12.324px;
		left: 33px;
		top: 45.676px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_3_website_3 {
		fill: rgba(255,255,255,1);
	}
	.Icon_3_website_3 {
		overflow: visible;
		position: absolute;
		width: 8.532px;
		height: 8.305px;
		left: 43.417px;
		top: 37.918px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_plus_2_website_3 {
		fill: rgba(255,255,255,1);
	}
	.Icon_plus_2_website_3 {
		overflow: visible;
		position: absolute;
		width: 6.241px;
		height: 6.204px;
		left: 38.026px;
		top: 34.464px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_plus_1_website_3 {
		fill: rgba(255,255,255,1);
	}
	.Icon_plus_1_website_3 {
		overflow: visible;
		position: absolute;
		width: 5.365px;
		height: 5.326px;
		left: 48.91px;
		top: 33px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Card_01_website_3 {
		position: absolute;
		width: 234px;
		height: 313px;
		left: 0px;
		top: 125px;
		overflow: visible;
	}
	#Scroll_Group_10 {
		mix-blend-mode: normal;
		position: absolute;
		width: 234px;
		height: 120px;
		left: 0px;
		top: 193px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#Scroll_Group_10::-webkit-scrollbar{
		display: none;
	}
	#intro_website_3 {
	
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		line-height: 26px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#Vision_website_3 {
		left: 88px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#Icon_grp_website_3 {
		position: absolute;
		width: 90px;
		height: 90px;
		left: 72px;
		top: 0px;
		overflow: visible;
	}
	#Fill_11_website_3 {
		fill: rgba(150,114,255,1);
	}
	.Fill_11_website_3 {
		filter: drop-shadow(0px 1.852px 3.148px rgba(150, 114, 255, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.445px;
		left: 0px;
		top: 0px;
	}
	#Icon_2_website_3 {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.Icon_2_website_3 {
		overflow: visible;
		position: absolute;
		width: 8.742px;
		height: 8.742px;
		left: 50.672px;
		top: 50.673px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_1_website_3 {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.Icon_1_website_3 {
		overflow: visible;
		position: absolute;
		width: 22.689px;
		height: 22.689px;
		left: 33px;
		top: 33px;
		transform: matrix(1,0,0,1,0,0);
	}
	#what_we_stand_for_website_3 {
		left: 367px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 341px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#deisgn_grp_we_stand_left {
		position: absolute;
		width: 210px;
		height: 11px;
		left: 210px;
		top: 166px;
		overflow: visible;
	}
	#centre_linewebsite_3 {
		opacity: 0.2;
		fill: rgba(0,0,0,0);
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5 10;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.centre_linewebsite_3 {
		overflow: visible;
		position: absolute;
		width: 191.5px;
		height: 3px;
		left: 9px;
		top: 5.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#right_we_stand_circle {
		fill: rgba(77,223,253,1);
	}
	.right_we_stand_circle {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 199px;
		top: 0px;
	}
	#left_we_stand_circle {
		fill: rgba(150,114,255,1);
	}
	.left_we_stand_circle {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 0px;
		top: 0px;
	}
	#what_we_shand_for_arrow_mid_le {
		position: absolute;
		width: 210px;
		height: 11px;
		left: 638px;
		top: 166px;
		overflow: visible;
	}
	#centre_line_website_3 {
		opacity: 0.2;
		fill: rgba(0,0,0,0);
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5 10;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.centre_line_website_3 {
		overflow: visible;
		position: absolute;
		width: 191.5px;
		height: 1px;
		transform: translate(0px, 0px) matrix(1,0,0,1,9.5,5.5) rotate(180deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#circle_left_website_3 {
		fill: rgba(77,223,253,1);
	}
	.circle_left_website_3 {
		width: 11px;
		height: 11px;
		position: absolute;
		overflow: visible;
		transform: translate(0px, 0px) matrix(1,0,0,1,0,0) rotate(180deg);
		transform-origin: center;
	}
	#circle_right_website_3 {
		fill: rgba(150,114,255,1);
	}
	.circle_right_website_3 {
		width: 11px;
		height: 11px;
		position: absolute;
		overflow: visible;
		transform: translate(0px, 0px) matrix(1,0,0,1,199,0) rotate(180deg);
		transform-origin: center;
	}
	
	
	#Featured_Listing_of_the_Week {
		left: 339px;
		top: 2477px;
		position: absolute;
		overflow: visible;
		width: 764px;
		height: 53px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#offering_area_web_3 {
		position: absolute;
		width: 1185px;
		height: 601px;
		left: 120px;
		top: 1683px;
		overflow: visible;
	}
	#Scroll_Offering_web_3 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1185px;
		height: 433px;
		left: 0px;
		top: 168px;
        display: inline-flex;
		overflow-x: auto;
        overflow-y: hidden;

	}
	#Scroll_Offering_web_3::-webkit-scrollbar{
		display: none;
	}
	#name_anf_off_img_web_3 {
		position: relative;
      
		width: 370px;
		height: 383px;
		left: 15px;
		top: 0px;
		overflow: visible;
	}
	#Image_03_website_3 {
		position: relative;
		width: 370px;
		height: 300px;
		left: 0px;
		top: 83px;
        padding: 10px;
		overflow: visible;
	}
	#Button_3D_website_3 {
		position: absolute;
		width: 28px;
		height: 26px;
		left: 95px;
		top: 97px;
		overflow: visible;
	}
	#Fill_3D_website_3 {
		fill: rgba(0,0,0,0.4);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Fill_3D_website_3 {
		position: absolute;
		overflow: visible;
		width: 28px;
		height: 26px;
		left: 0px;
		top: 0px;
	}
	#n_D_website_3 {
		left: 6px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 16px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(255,255,255,1);
	}
	#Button_featured_website_3 {
		position: absolute;
		width: 75px;
		height: 26px;
		left: 14px;
		top: 97px;
		overflow: visible;
	}
	#Fill_box_website_3 {
		fill: rgba(0,0,0,0.4);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Fill_box_website_3 {
		position: absolute;
		overflow: visible;
		width: 75px;
		height: 26px;
		left: 0px;
		top: 0px;
	}
	#Featured_website_3 {
		left: 14.5px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 47px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(255,255,255,1);
	}
	#offering_Menu_website_3 {
		position: absolute;
		width: 168px;
		height: 26px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Menu_website_3 {
		position: absolute;
		width: 168px;
		height: 26px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Residential_Property_website_3 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 169px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(0,0,0,1);
	}
	#offering_mid_grp_website_3 {
		position: absolute;
		width: 470px;
		height: 91px;
		left: 15px;
		top: 0px;
		overflow: visible;
	}
	#slogal_website_3 {
		left: 0px;
		top: 70px;
		position: absolute;
		overflow: visible;
		width: 471px;
		height: 21px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#offerings_lbl_website_3 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 164px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#img_website_website_3 {
		position: absolute;
		width: 633.5px;
		height: 442.883px;
		left: 671px;
		top: 212px;
		overflow: visible;
	}
	#footer_grp_website_3 {
		position: absolute;
		width: 1173px;
		height: 69.5px;
		left: 134px;
		top: 3600.75px;
		overflow: visible;
	}
	#Line_2_website_3 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_2_website_3 {
		overflow: visible;
		position: absolute;
		width: 1173px;
		height: 1px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_Copyright_Operate_Live_websi_ob_pc {
		left: 39.5px;
		top: 46.5px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#home_website_3 {
		position: absolute;
		width: 79px;
		height: 27px;
		left: 714px;
		top: 55px;
		overflow: visible;
	}
	#Home_website_3_et {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#home-icon-silhouette_website_3 {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#offering_website_3 {
		position: absolute;
		width: 106px;
		height: 27px;
		left: 828px;
		top: 55px;
		overflow: visible;
	}
	#Offerings_website_3 {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#discount_website_3 {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#event_grp_website_3 {
		position: absolute;
		width: 82px;
		height: 27px;
		left: 969px;
		top: 55px;
		overflow: visible;
	}
	#Events_website_3 {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 58px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#election-event-on-a-calendar-w {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#contact_grp_website_3 {
		position: absolute;
		width: 93px;
		height: 27px;
		left: 1086px;
		top: 55px;
		overflow: visible;
	}
	#Contact_lbl_website_3 {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#contact_img_website_3 {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#Partners_grp_web_3 {
		position: absolute;
		width: 804px;
		height: 88px;
		left: 109px;
		top: 771px;
		overflow: visible;
	}
	#text_our_partners_website_3 {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 168px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.1px;
	}
	#Scroll_Group_13 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 80px;
		left: 0px;
		top: 39px;
        display: inline-flex;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#Scroll_Group_13::-webkit-scrollbar{
		display: none;
	
	}
	#partner_lbls_grp3 {
    position: relative;
    width: 200px;
    display: inline-flex;
    height: 27px;
    left: 26px;
    top: 7px;
    overflow: visible;
	}
	#google_lbl_partner_website_23 {
		left: 30px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#microsoft_lbl_partnw_website_2 {
		left: 210px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#microscope_lbl_partner_website {
		left: 390px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#google_1_website_23 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#microsoft_website_23 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 180px;
		top: 1px;
		overflow: visible;
	}
	#scope_website_23 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 360px;
		top: 1px;
		overflow: visible;
	}
	#googlewebsite_23 {
		left: 572px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#google_1_1_website_23 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 542px;
		top: 1px;
		overflow: visible;
	}
	#hiding_part_1_web_3_pc_comment {
		position: absolute;
		width: 570px;
		height: 528px;
		left: 136px;
		top: 3473px;
		display: none;
		overflow: visible;
	}
	#Card_01_testi_website_3 {
		position: absolute;
		width: 380px;
		height: 367px;
		left: 0px;
		top: 161px;
		overflow: visible;
	}
	#Base_website_3 {
		fill: rgba(255,255,255,1);
		stroke: rgba(235,235,235,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Base_website_3 {
		position: absolute;
		overflow: visible;
		width: 380px;
		height: 367px;
		left: 0px;
		top: 0px;
	}
	#Los_Angeles_CA_website_3 {
		left: 106px;
		top: 296px;
		position: absolute;
		overflow: visible;
		width: 99px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(102,102,102,1);
	}
	#Jane_Cooper_website_3 {
		left: 106px;
		top: 274px;
		position: absolute;
		overflow: visible;
		width: 89px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#Image_website_3 {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 38px;
		top: 267px;
		overflow: visible;
	}
	#Moderate_children_website_3 {
		left: 38px;
		top: 102px;
		position: absolute;
		overflow: visible;
		width: 271px;
		height: 105px;
		line-height: 28px;
		margin-top: -6px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#Review_website_3 {
		position: absolute;
		width: 94.024px;
		height: 13.907px;
		left: 38.988px;
		top: 52.514px;
		overflow: visible;
	}
	#Star_1_website_3 {
		fill: rgba(251,176,64,1);
	}
	.Star_1_website_3 {
		overflow: visible;
		position: absolute;
		width: 15.479px;
		height: 13.906px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Star_2_website_3 {
		fill: rgba(251,176,64,1);
	}
	.Star_2_website_3 {
		overflow: visible;
		position: absolute;
		width: 15.479px;
		height: 13.906px;
		left: 19.636px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Star_3_website_3 {
		fill: rgba(251,176,64,1);
	}
	.Star_3_website_3 {
		overflow: visible;
		position: absolute;
		width: 15.479px;
		height: 13.906px;
		left: 39.273px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Star_4_website_3 {
		fill: rgba(251,176,64,1);
	}
	.Star_4_website_3 {
		overflow: visible;
		position: absolute;
		width: 15.479px;
		height: 13.906px;
		left: 58.909px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Star_5_website_3 {
		fill: rgba(251,176,64,1);
	}
	.Star_5_website_3 {
		overflow: visible;
		position: absolute;
		width: 15.479px;
		height: 13.906px;
		left: 78.545px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Loved_by_businesses_and_indivi {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 571px;
		height: 106px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}

	#hidding_part_2_web_3_pc_info {
		position: absolute;
		width: 686px;
		height: 267px;
		left: 377px;
		top: 4123px;
		display: none;
		overflow: visible;
	}
	#Email_input_field {
		position: absolute;
		width: 686px;
		height: 124px;
		left: 0px;
		top: 143px;
		overflow: visible;
	}
	#bg_website_3 {
		fill: rgba(255,255,255,1);
	}
	.bg_website_3 {
		filter: drop-shadow(0px 3.019px 3.148px rgba(0, 0, 0, 0.008));
		position: absolute;
		overflow: visible;
		width: 695.444px;
		height: 133.445px;
		left: 0px;
		top: 0px;
	}
	#text_website_3 {
		opacity: 0.2;
		left: 45px;
		top: 27px;
		position: absolute;
		overflow: visible;
		width: 192px;
		white-space: nowrap;
		line-height: 24px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.2px;
	}
	#Button_Send_website_3 {
		position: absolute;
		width: 167px;
		height: 72px;
		left: 489px;
		top: 169px;
		overflow: visible;
	}
	#bg_website_3_orange {
		fill: rgba(248,90,71,1);
	}
	.bg_website_3_orange {
		filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 176.444px;
		height: 81.445px;
		left: 0px;
		top: 0px;
	}
	#subscribe_lbl__website_3 {
		left: 44px;
		top: 23px;
		position: absolute;
		overflow: visible;
		width: 81px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.2px;
	}
	#to_join_website_3 {
		left: 153px;
		top: 76px;
		position: absolute;
		overflow: visible;
		width: 353px;
		white-space: nowrap;
		line-height: 33px;
		margin-top: -5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 23px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.2px;
	}
	#sub_title_lbl_website_3 {
		left: 53px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 583px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -10px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 45px;
		color: rgba(49,53,59,1);
		letter-spacing: 0.2px;
	}

	#Scroll_upcoming_event_web_3_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 1376px;
		height: 650px;
		left: 32px;
		top: 2648px;
		display: flex;
    overflow-x: auto;
    flex-wrap: wrap;
    flex-direction: column;
    overflow-y: hidden;
	}
	#Upcoming_evnt_grp_down_3_pc {
		position: relative;
		width: 1170px;
		height: 526px;
		/* left: 103px; */
		top: 41px;
		margin-right: 50px;
		overflow: visible;
	}
	#bgof_card_eventwebsite_3_pc {
		fill: rgba(241,255,255,1);
	}
	.bgof_card_eventwebsite_3_pc {
		position: absolute;
		overflow: visible;
		width: 1170px;
		height: 526px;
		left: 0px;
		top: 0px;
	}
	#Button_website_3_pc {
		display: none;
		position: absolute;
		width: 412px;
		height: 53px;
		left: 608px;
		top: 453px;
		overflow: visible;
	}
	#Fbdr_website_3 {
		fill: rgba(248,90,71,1);
	}
	.Fbdr_website_3 {
		filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 421.444px;
		height: 62.445px;
		left: 0px;
		top: 0px;
	}
	#enroll_now_website_3 {
		left: 163.5px;
		top: 16px;
		position: absolute;
		overflow: visible;
		width: 87px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#organizer_grp_website_3_pc {
		position: absolute;
		width: 313px;
		height: 57px;
		left: 608px;
		top: 341px;
		overflow: visible;
	}
	#n__Room_website_3 {
		left: 0px;
		top: 35px;
		position: absolute;
		overflow: visible;
		width: 314px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#organizer_website_3 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#when_to_wen_date_grp_3_pc {
		position: absolute;
		width: 298px;
		height: 22px;
		left: 608px;
		top: 61px;
		overflow: visible;
	}
	#date_website_3_pc {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 299px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#avaliable_website_3_pc {
		position: absolute;
		width: 67px;
		height: 57px;
		left: 945px;
		top: 246px;
		overflow: visible;
	}
	#n_000_website_3_pc {
		left: 0px;
		top: 35px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#avaliablewebsite_3_pc {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 68px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#seats_grp_website_3_pc {
		position: absolute;
		width: 40px;
		height: 57px;
		left: 786px;
		top: 246px;
		overflow: visible;
	}
	#n_200_m2_website_3 {
		left: 0px;
		top: 35px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#seats_website_3 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#cost_grp_website_3_pc {
		position: absolute;
		width: 91px;
		height: 57px;
		left: 608px;
		top: 246px;
		overflow: visible;
	}
	#Confidential_website_3 {
		left: 0px;
		top: 35px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(84,90,88,1);
	}
	#cost_website_3 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Scroll_event_desc_3 {
		mix-blend-mode: normal;
		position: absolute;
		width: 364px;
		height: 108px;
		left: 598px;
		top: 126px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#event_info_website_3_pc {
		left: 10px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 345px;
		height: 96px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(84,90,88,1);
	}
	#event__name_website_3_pc {
		left: 608px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 379px;
		height: 41px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#Image_1_website_3_pc {
		position: absolute;
		width: 492px;
		height: 364px;
		left: 39px;
		top: 81px;
		overflow: visible;
	}

	#all_info_grp_card_below_pc_3_w {
		position: absolute;
		width: 1173px;
		height: 228px;
		left: 130.803px;
		top: 3340px;
		overflow: visible;
	}
	#bg_of_info_web_3_pc {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 1173px;
		height: 228px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Connect_Unlock_3_pc {
		left: 914px;
		top: 144.5px;
		position: absolute;
		overflow: visible;
		width: 233px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#n_234567890e_number_website_3_ {
		left: 69px;
		top: 19px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#affanghanioperatelive_website3_pc {
		left: 70px;
		top: 76px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#address_website_3_pc {
		left: 65px;
		top: 130px;
		position: absolute;
		overflow: visible;
		width: 730px;
		height: 78px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#location_img_website_3_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 21px;
		top: 130px;
		overflow: visible;
	}
	#email_address_website_3_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 21px;
		top: 74px;
		overflow: visible;
	}
	#phone_number_website_3_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 21px;
		top: 17px;
		overflow: visible;
	}
	#quote_left_webstie_3_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 874px;
		top: 144.5px;
		overflow: visible;
	}
	#quote_right_website_3_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 1115px;
		top: 171.5px;
		overflow: visible;
	}