.mediaViewInfo {
    --web-view-name: community mem – 1;
    --web-view-id: community_mem_mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: community_mem_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#community_mem_mob {
    position: absolute;
    width: 100vw;
    height:  calc(100vh - 220px);
    top: 150px;
    overflow: hidden;
    --web-view-name: community mem – 1;
    --web-view-id: community_mem_mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#com_mem_bg_img_mob {
    position: absolute;
    width: calc(100% - 35px);
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#mid_scroll_com_mem_mob_new {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 45px);
    height: 100%;
    left: 0px;
    top: 0px;
    overflow-x: hidden;
    overflow-y: auto;
}
#card_grp_over_all_com_mem_new {
    position: relative;
    width: 311.277px;
    height: 89px;
    left: 15px;
    top: 11px;
    z-index: 1;
    overflow: visible;
}
#community_mem_page_card_new {
    fill: rgba(255,255,255,1);
}
.community_mem_page_card_new {
    filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 317.777px;
    height: 95.5px;
    left: 0px;
    top: 0px;
}
#community_mem_page_mob_new {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 34.702px;
    top: 11.408px;
    border-radius: 50%;
    overflow: hidden;
}
#community_mem_page_new {
    left: 13.495px;
    top: 62px;
    position: absolute;
    overflow: visible;
    width: 89px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    color: rgba(37,37,37,1);
}
#community_mem_page_card_design_new {
    fill: rgba(255,235,133,1);
}
.community_mem_page_card_design_new {
    overflow: visible;
    position: absolute;
    width: 117.404px;
    height: 28.566px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#com_mem_minus_new {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 28px;
    top: 4.988px;
    cursor: pointer;
    z-index: 1;
    overflow: visible;
}
#com_mem_dget_png_new {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 2.988px;
    overflow: visible;
}
#com_mem_delivery_png_new {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 32.397px;
    overflow: visible;
}
#com_mem_budget_txt_new {
    left: 168.5px;
    top: 7.988px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#com_mem_dep_txt_new {
    left: 168.5px;
    top: 66.012px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#com_mem_location_png_new {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 61.012px;
    overflow: visible;
}
#com_mem__timg_txt_new {
    left: 168.5px;
    top: 36.408px;
    position: absolute;
    overflow: visible;
    width: 114px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}

#community_mem_side_grp {
    position: absolute;
    width: 100%;
    height: 634px;
    overflow: hidden;
    --web-view-name: community mem – 2;
    --web-view-id: community_mem_side_grp;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#com_mem_bg_side_menu {
    position: absolute;
    width: 37.671px;
    height: 122.703px;
    right: 0px;
    top: 220.343px;
    overflow: visible;
}

#mem_page_grp_right_new {
    position: absolute;
    width: 20px;
    height: 310.976px;
    right: 10px;
    top: 15.212px;
    overflow: visible;
}
#com_mem_req_txt_new {
    transform: translate(-340.869px, -15.212px) matrix(1,0,0,1,305.3689,50.7125) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(4,4,4,1);
}
#com_mem_off_txt_new {
    transform: translate(-340.869px, -15.212px) matrix(1,0,0,1,317.8689,163.291) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#com_mem_mem_txt_new {
    transform: translate(-340.869px, -15.212px) matrix(1,0,0,1,317.8689,259.2124) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#add_btn_com_mem_mob_new {
    position: absolute;
    width: 18.503px;
    height: 18.503px;
    left: 0.131px;
    top: 292.474px;
    overflow: visible;
}
#bdr_plus_btn_new {
    fill: rgba(255,255,255,1);
}
.bdr_plus_btn_new {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 36.503px;
    height: 36.503px;
    left: 0px;
    top: 0px;
}
#plus_btn_mem_new {
    position: absolute;
    width: 12.282px;
    height: 12.282px;
    left: 3.111px;
    top: 3.111px;
    overflow: visible;
}