	.mediaViewInfo {
		--web-view-name: PerfumePulse;
		--web-view-id: PerfumePulse;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: PerfumePulse;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#PerfumePulse {
		position: absolute;
		width: 1366px;
		height: 3794px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: PerfumePulse;
		--web-view-id: PerfumePulse;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#PPText {
		position: absolute;
		width: 509px;
		height: 209px;
		left: 53px;
		top: 228px;
		overflow: visible;
	}
	#PPExperience_the_finest_in_premi {
		left: 0px;
		top: 187px;
		position: absolute;
		overflow: visible;
		width: 375px;
		white-space: nowrap;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(6,18,50,1);
	}
	#PPCompany__Name {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 510px;
		height: 134px;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: bold;
		font-size: 57px;
		color: rgba(6,18,50,1);
	}
	#PPBGs {
		position: absolute;
		width: 683px;
		height: 716px;
		left: 683px;
		top: 0px;
		overflow: visible;
	}
	#PPSee_the_Collection {
		position: absolute;
		width: 341px;
		height: 358px;
		left: 342px;
		top: 358px;
		overflow: visible;
	}
	#PPBG {
		fill: rgba(7,82,181,1);
	}
	.PPBG {
		position: absolute;
		overflow: visible;
		width: 341px;
		height: 358px;
		left: 0px;
		top: 0px;
	}
	#PPIcon_feather-arrow-right-circl {
		position: absolute;
		width: 29.271px;
		height: 29.271px;
		left: 155.224px;
		top: 236.219px;
		overflow: visible;
	}
	#PPPath_1 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPPath_1 {
		overflow: visible;
		position: absolute;
		width: 32.271px;
		height: 32.271px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPPath_2 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPPath_2 {
		overflow: visible;
		position: absolute;
		width: 9.476px;
		height: 15.951px;
		left: 14.636px;
		top: 8.781px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPPath_3 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPPath_3 {
		overflow: visible;
		position: absolute;
		width: 14.709px;
		height: 3px;
		left: 8.781px;
		top: 14.637px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPSee_the_Collection_bb {
		position: absolute;
		width: 85px;
		height: 55px;
		left: 108px;
		top: 151px;
		overflow: visible;
	}
	#PPSee_the {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 67px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PPcollection {
		left: 0px;
		top: 28px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PPmiguel-andrade-potCPE_Cw8A-uns {
		position: absolute;
		width: 342px;
		height: 358px;
		left: 0px;
		top: 358px;
		overflow: visible;
	}
	#PPjeroen-den-otter-2b0JeJTEclQ-u {
		position: absolute;
		width: 683px;
		height: 358px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPTop_Nav {
		position: absolute;
		width: 46.834px;
		height: 19.514px;
		left: 583.965px;
		top: 45.514px;
		overflow: visible;
	}
	#PPMenu_Button {
		position: absolute;
		width: 46.834px;
		height: 19.514px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPLine_1 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPLine_1 {
		overflow: visible;
		position: absolute;
		width: 23.417px;
		height: 3px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPLine_2 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPLine_2 {
		overflow: visible;
		position: absolute;
		width: 46.834px;
		height: 3px;
		left: 0px;
		top: 9.756px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPLine_3 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPLine_3 {
		overflow: visible;
		position: absolute;
		width: 23.417px;
		height: 3px;
		left: 23.417px;
		top: 19.514px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPAnime_Edit {
		position: absolute;
		width: 55px;
		height: 55px;
		left: 53px;
		top: 28px;
		overflow: hidden;
        border-radius: 50%;
	}
	#PPGroup_15039 {
		position: absolute;
		width: 1193px;
		height: 1695px;
		left: 87px;
		top: 795px;
		overflow: visible;
	}
	#PPGroup_15033 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPbundo-kim-ur2zmbseUIA-unsplash {
		filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPGroup_15038 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 600px;
		top: 38px;
		overflow: visible;
	}
	#PPScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PPIt_is_a_long_established_fact_ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 265px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PPVISION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPGroup_15034 {
		position: absolute;
		width: 1193px;
		height: 419.078px;
		left: 0px;
		top: 638px;
		overflow: visible;
	}
	#PPhanna-balan-d1GwyeOlZDw-unspla {
		filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 560px;
		height: 419.078px;
		left: 633px;
		top: 0px;
		overflow: visible;
	}
	#PPGroup_15037 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 0px;
		top: 39px;
		overflow: visible;
	}
	#PPScroll_Group_28_bw {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PPScroll_Group_28_bw::-webkit-scrollbar{
		display: none;
	}
	#PPIt_is_a_long_established_fact__bx {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PPMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPGroup_15035 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 1276px;
		overflow: visible;
	}
	#PPemily-wang-a5917t2ea8I-unsplas {
		filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 600px;
		top: 103px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PPScroll_Group_28_b::-webkit-scrollbar{
		display: none;
	}
	#PPScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#PPIt_is_a_long_established_fact__b {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#PPPRINCIPLES {
		left: 618px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPGroup_15119 {
		position: absolute;
		width: 1341px;
		height: 463px;
		left: 0px;
		top: 2640px;
		overflow: visible;
	}
	#PPScroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1341px;
		height: 386px;
		left: 0px;
		top: 77px;
        display: inline-flex;
		overflow-x: auto;
        overflow-y: hidden;
	}
	#PPScroll_Group_77::-webkit-scrollbar{
		display: none;
	}
	#PPn_ {
		position: relative;
		width: 307px;
		height: 342px;
		left: 15px;
		top: 21px;
		overflow: visible;
	}
	#PPMask_Group_22 {
		position: absolute;
		width: 307px;
		height: 311px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPRectangle_664 {
		fill: rgba(0,0,0,1);
	}
	.PPRectangle_664 {
		position: absolute;
		overflow: visible;
		width: 307px;
		height: 46px;
		left: 0px;
		top: 296px;
	}
	#PPPERFUME {
		left: 9px;
		top: 309px;
		position: absolute;
		overflow: visible;
		width: 293px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}
	#PPGroup_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 131px;
		top: 268px;
		overflow: visible;
	}
	#PPEllipse_38 {
		fill: rgba(255,255,255,1);
	}
	.PPEllipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#PPEllipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.PPEllipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#PPEllipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.PPEllipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#PPEllipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.PPEllipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#PPEllipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.PPEllipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#PPIn_Stock {
		left: 632px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 77px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 509.998px;
		left: 95.5px;
		top: 3253px;
		overflow: visible;
	}
	#PPGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 136.996px;
		overflow: visible;
	}
	#PPGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.PPRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#PPsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.002px;
		overflow: visible;
	}
	#PPlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#PPmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PPmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#PPn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.PPn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.PPlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.PPtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PPshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PPshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#PPwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPmed_shadow_space_website_7_cw {
		fill: rgba(255,255,255,1);
	}
	.PPmed_shadow_space_website_7_cw {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#PPn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.002px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.002px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.002px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#PPlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.002px;
		overflow: visible;
	}
	#PPemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.002px;
		overflow: visible;
	}
	#PPphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.002px;
		overflow: visible;
	}
	#PPn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.002px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#PPContact_Us {
		left: 450px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 255px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 50px;
		color: rgba(36,36,36,1);
	}