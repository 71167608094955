.mediaViewInfo {
    --web-view-name: community Req – 1;
    --web-view-id: community_Req__1;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: community_Req__1;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#community_Req__1 {
    position: absolute;
    width: calc(100% - 136px);
    height:calc(100% - 150px);
    top: 130px;
    left: 120px;
    overflow: hidden;
    --web-view-name: community Req – 1;
    --web-view-id: community_Req__1;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#design_req {
    position: absolute;
    width: 1256.662px;
    height: 567px;
    left: 0.151px;
    top: 0px;
    overflow: visible;
}
#cube_green_design_req {
    fill: rgba(255,255,255,1);
    stroke: rgba(163,214,147,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.cube_green_design_req {
    overflow: visible;
    position: absolute;
    width: 2787.676px;
    height: 1898.021px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#labels_grp_req {
    position: absolute;
    width: 444px;
    height: 22px;
    left: 46px;
    top: 14.895px;
    overflow: visible;
}
#Offerings_req {
    left: 190px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#Requirements_req {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 112px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#Members_req {
    left: 369px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#Scroll_Group_req_ {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 160px);
    height: calc(100% - 100px);
    left: 11px;
    top: 60.438px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#overall_card_req_page {
    position: relative;
    width: 199px;
    height: 243px;
    left: 10px;
    top: 8px;
    overflow: visible;
}
#full_card_back_bdr_req_ {
    fill: url(#full_card_back_bdr_req_);
}
.full_card_back_bdr_req_ {
    filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 205.5px;
    height: 249.5px;
    left: 0px;
    top: 0px;
}
#card_title_bg_bdr {
    fill: rgba(219,242,231,1);
}
.card_title_bg_bdr {
    position: absolute;
    overflow: visible;
    width: 169.518px;
    height: 20.793px;
    left: 14.5px;
    top: 87px;
}
#Website_Development_lbl_card_t {
    left: 16.5px;
    top: 87px;
    position: absolute;
    overflow: visible;
    width: 167px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(37,37,37,1);
}
#card_top_design_req_ {
    fill: url(#card_top_design_req_);
}
.card_top_design_req_ {
    overflow: visible;
    position: absolute;
    width: 199px;
    height: 47.484px;
    left: 0px;
    top: 0.516px;
    transform: matrix(1,0,0,1,0,0);
}
#n_000_inr_lbl {
    left: 153px;
    top: 165.457px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#req_1_budget_img {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 111px;
    top: 160.457px;
    overflow: visible;
}
#Delivery_Deadline_img_req {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 14px;
    top: 160.457px;
    overflow: visible;
}
#delivery_location_img_req {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 14px;
    top: 120px;
    overflow: visible;
}
#n_4_HR_time_req {
    left: 56px;
    top: 165.457px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Online_Deployment_lbl_req {
    left: 56px;
    top: 124.756px;
    position: absolute;
    overflow: visible;
    width: 130px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#dustbin {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 180px;
    top: 1.457px;
    overflow: visible;
}
#bid_btn_grp_req {
    position: absolute;
    width: 72px;
    height: 27px;
    left: 13px;
    top: 207.457px;
    cursor: pointer;
    overflow: visible;
    border-radius: 50px;
}
#bid_bdr_req {
    fill: rgba(255,255,255,1);
}
.bid_bdr_req {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#Bid_lbl_req {
    left: 37px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#bid_btn_img {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 14px;
    top: 4px;
    overflow: visible;
}
#detail_btn_grp {
    position: absolute;
    width: 72px;
    height: 27px;
    left: 106px;
    top: 207.457px;
    overflow: visible;
    cursor: pointer;
    border-radius: 50px;
}
#detail_bdr_req {
    fill: rgba(255,255,255,1);
}
.detail_bdr_req {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#Details_lbl_req {
    left: 24px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 46px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#detail_btn_req {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 3px;
    top: 4px;
    overflow: visible;
}
#card_3_image_grp {
    position: absolute;
    width: 174px;
    height: 70px;
    left: 13px;
    top: 11.457px;
    overflow: visible;
}
#photo_3_req {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 129px;
    top: 13px;
    overflow: visible;
}
#photo_2_req {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 52px;
    top: 0px;
    overflow: visible;
}
#photo_1_req {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 0px;
    top: 13px;
    overflow: visible;
}

@keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translate3d(0, -20%, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInDown {
    animation: fadeInDown 1s;
  }
