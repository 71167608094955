
#BottomBar_group {
    position: fixed;

    bottom: 0%;
    padding: 15px;
    width: 100%;
    height: 65px;
    z-index: 1;
    left: 0px;
}
#BottomBar_border {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
    width: 100%;
}
.BottomBar_border {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 65px;
    left: 0px;
    top: 0px;
}
#bell_bottombar {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 80%;
    text-align: center;
    top: 18px;
    overflow: visible;
}
#add-friend_bottombar {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 50%;
    top: 18px;
    overflow: visible;
}
#customer-support_bottombar {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 20%;
    top: 18px;
    overflow: visible;
}
