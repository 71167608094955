	.mediaViewInfo {
		--web-view-name: calender event part;
		--web-view-id: calender_event_part;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: calender_event_part;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#calender_event_part {
		position: absolute;
        width: 100%;
        height: 60px;
        top: -45px;
        left: 65px;
		/* background-color: rgba(255,255,255,1); */
		overflow: hidden;
		--web-view-name: calender event part;
		--web-view-id: calender_event_part;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#Scroll_weekly_sync_up {
        mix-blend-mode: normal;
        position: absolute;
        width: calc(100% - 120px);
        height: 55px;
        /* left: 15px; */
        top: 0px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        overflow-x: scroll;
        overflow-y: hidden;
	}
	#wekluy_sync_up_grp {
		position: relative;
		width: 183px;
		height: 53px;
		left: 5px;
		top: 1px;
        /* margin: 10px; */
		overflow: visible;
	}
	#over_all_calender_card_bdr {
		fill: rgba(255,255,255,1);
		stroke: rgba(255,137,1,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.over_all_calender_card_bdr {
		position: absolute;
		overflow: visible;
		width: 183px;
		height: 53px;
		left: 0px;
		top: 0px;
	}
	#Weekly_Sync_Up_lbl_name {
		left: 54px;
		top: 7px;
		position: absolute;
		overflow: hidden;
		width: 130px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#n_30am_-_1030am_wekluy_sync_up {
		left: 53px;
		top: 27px;
		position: absolute;
		overflow: visible;
		width: 119px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#img_bdr_wekluy_sync_up {
		fill: rgba(253,164,61,1);
	}
	.img_bdr_wekluy_sync_up {
		position: absolute;
		overflow: visible;
		width: 34px;
		height: 34px;
		left: 9px;
		top: 9px;
	}
	#bell_wekluy_sync_up {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 14px;
		top: 14px;
		overflow: visible;
	}
	#line_below_event_card {
		position: absolute;
		width: 100%;
		height: 5px;
		/* left: 15px; */
		top: 55px;
		overflow: visible;
	}
	#calendar_line_event_part {
		fill: transparent;
		stroke: rgba(119,128,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.calendar_line_event_part {
		overflow: visible;
		position: absolute;
		width: 280px;
		height: 1px;
		left: 2.5px;
		top: 2.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#line_left_dot_caleneder {
		fill: rgba(119,128,255,1);
	}
	.line_left_dot_caleneder {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
