.mediaViewInfo {
    --web-view-name: coming soon mob;
    --web-view-id: coming_soon_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: coming_soon_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#coming_soon_mob {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 135px);
    top: 70px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: coming soon mob;
    --web-view-id: coming_soon_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_back_some_soon_mob_co {
    fill: rgba(219,242,231,1);
}
.over_all_back_some_soon_mob_co {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 14px;
}
#mid_overall_grp_come_mob_come {
    position: absolute;
    width: 339.743px;
    height: 381.987px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: visible;
}
#mid_design_hex_mob_come {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.mid_design_hex_mob_come {
    overflow: visible;
    position: absolute;
    width: 224.873px;
    height: 256.366px;
    left: 48.497px;
    top: 69.418px;
    transform: matrix(1,0,0,1,0,0);
}
#speaker_grp_mob_come {
    transform: translate(0px, 0px) matrix(1,0,0,1,4.9604,191.1535) rotate(-9deg);
    transform-origin: center;
    position: absolute;
    width: 78.88px;
    height: 69.626px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#back_speaker_mob_come {
    fill: rgba(255,165,0,1);
}
.back_speaker_mob_come {
    filter: drop-shadow(0px 5px 6px rgba(255, 165, 0, 1));
    overflow: visible;
    position: absolute;
    width: 93.368px;
    height: 93.336px;
    left: 0.592px;
    top: 5.772px;
    transform: matrix(1,0,0,1,0,0);
}
#front_speak_mob_come {
    fill: rgba(255,165,0,1);
}
.front_speak_mob_come {
    filter: drop-shadow(0px 5px 6px rgba(255, 165, 0, 1));
    overflow: visible;
    position: absolute;
    width: 46.454px;
    height: 88.587px;
    left: 60.191px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#C_backOMING_mob_come {
    filter: blur(5px);
    transform: translate(0px, 0px) matrix(1,0,0,1,90.8219,126.6351) rotate(-11deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 240px;
    height: 127px;
    text-align: left;
    font-family: Comic Sans MS;
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    color: rgba(87,170,130,1);
    text-transform: uppercase;
}
#COMING_front_mob_come {
    transform: translate(0px, 0px) matrix(1,0,0,1,89.2162,117.6351) rotate(-11deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 240px;
    height: 127px;
    text-align: left;
    font-family: Comic Sans MS;
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    color: rgba(255,255,255,1);
    text-transform: uppercase;
}
#top_line_mob_come {
    fill: rgba(0,32,54,1);
    stroke: rgba(1,33,56,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.top_line_mob_come {
    overflow: visible;
    position: absolute;
    width: 35.531px;
    height: 47.187px;
    left: 141.906px;
    top: 61.291px;
    transform: matrix(1,0,0,1,0,0);
}
#top_right_line_mob_come {
    fill: transparent;
    stroke: rgba(4,32,61,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.top_right_line_mob_come {
    overflow: visible;
    position: absolute;
    width: 72.424px;
    height: 97.768px;
    left: 198.424px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Stay_tuned__front__mob_come {
    filter: drop-shadow(0px 5px 3px rgba(66, 178, 66, 1));
    transform: translate(0px, 0px) matrix(1,0,0,1,135.5563,278.6667) rotate(-11deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 113px;
    white-space: nowrap;
    line-height: 33px;
    margin-top: -9px;
    text-align: left;
    font-family: Comic Sans MS;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(66,178,66,1);
    text-transform: uppercase;
}
#down_like_left_mob_come {
    fill: transparent;
    stroke: rgba(1,33,56,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.down_like_left_mob_come {
    overflow: visible;
    position: absolute;
    width: 49.674px;
    height: 66.505px;
    left: 65.176px;
    top: 280.655px;
    transform: matrix(1,0,0,1,0,0);
}
#down_line_mob_come {
    fill: transparent;
    stroke: rgba(0,31,57,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.down_line_mob_come {
    overflow: visible;
    position: absolute;
    width: 27.16px;
    height: 35.375px;
    left: 93.752px;
    top: 348.392px;
    transform: matrix(1,0,0,1,0,0);
}