#right_req_com_off_grp_full {
    position: absolute;
    width: 147px;
    height: calc(100% - 60px);
    right: 0px;
    top: 55px;
    overflow: visible;
}
#rec_card_holder_com_off_pcZ_bz {
    fill: url(#rec_card_holder_com_off_pcZ_bz);
}
.rec_card_holder_com_off_pcZ_bz {
    position: absolute;
    overflow: visible;
    width: 147px;
    height: 100%; 
    left: 0px;
    top: 0px;
}
#card_right_grp_com_off_pcZ {
    position: relative;
    width: 133px;
    height: 133px;
    left: 7px;
    top: 55px;
    margin-top: 5px;
    overflow: visible;
}
#full_card_bdr_com_off_pcZ_b {
    fill: url(#full_card_bdr_com_off_pcZ_b);
}
.full_card_bdr_com_off_pcZ_b {
    filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 151px;
    height: 151px;
    left: 0px;
    top: 0px;
}
#INR_300000__29_Sep_com_off_pcZ {
    left: 0px;
    top: 115px;
    position: absolute;
    overflow: visible;
    width: 134px;
    height: 16px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(37,37,37,1);
}
#zaid_img_pfp_com_off_pcZ {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 27px;
    top: 5px;
    overflow: visible;
}
#minus_2_close_com_off_pcZ {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 113px;
    top: 1px;
    overflow: visible;
}
#Website_Development_title_com_ {
    left: 6px;
    top: 92px;
    position: absolute;
    overflow: hidden;
    width: 122px;
    height: 20px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#plus_add_grp_com_off {
    position: absolute;
    width: 139px;
    height: 40px;
    left: 4px;
    top: 5px;
    overflow: visible;
    cursor: pointer;
    border-radius: 50px;
}
#req_btn_rec_com_off_pcZ {
    fill: rgba(255,255,255,1);
}
.req_btn_rec_com_off_pcZ {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 157px;
    height: 58px;
    left: 0px;
    top: 0px;
}
#My_Requirements_lbl_btn_com_of {
    left: 4px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 109px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(37,37,37,1);
}
#plus_img_btn_com_off_pcZ {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 114px;
    top: 10px;
    overflow: visible;
}

@keyframes slideInDown {
    from {
      transform: translate3d(0, -100%, 0);
      visibility: visible;
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInDown {
    animation: slideInDown 1.5s;
  }