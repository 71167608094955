	.mediaViewInfo {
		--web-view-name: DriveVelocity;
		--web-view-id: DriveVelocity;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: DriveVelocity;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}

	#DriveVelocity {
		position: absolute;
		width: 1366px;
		height: 4346px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(249,249,249,0.89);
		overflow: hidden;
		--web-view-name: DriveVelocity;
		--web-view-id: DriveVelocity;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#avaliable_behind_bdr_website_7 {
		fill: rgba(99,113,203,1);
	}
	.avaliable_behind_bdr_website_7 {
		overflow: visible;
		position: absolute;
		width: 685.604px;
		height: 404.5px;
		left: -169.578px;
		top: 3247.357px;
		transform: matrix(1,0,0,1,0,0);
	}
	#princi_img_and_evertyhng_grp_w {
		position: absolute;
		width: 942.695px;
		height: 578.661px;
		left: 157.305px;
		top: 794px;
		overflow: visible;
	}
	#princi_design_img_website_7_pc {
		fill: rgba(99,113,203,1);
	}
	.princi_design_img_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 466.46px;
		height: 194.257px;
		transform: translate(-157.305px, -794px) matrix(1,0,0,1,21.2035,1042.3028) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#shade_princi_img_website_7_pc {
		fill: rgba(0,0,0,1);
	}
	.shade_princi_img_website_7_pc {
		filter: drop-shadow(-70px 73px 99px rgba(0, 0, 0, 0.122));
		position: absolute;
		overflow: visible;
		width: 643px;
		height: 757px;
		left: 67.695px;
		top: 0px;
	}
	#princi_Group_website_7_pc {
		position: absolute;
		width: 346px;
		height: 460px;
		left: 67.695px;
		top: 0px;
		overflow: visible;
	}
	#princi_text_grp_website_7_pc {
		position: absolute;
		width: 442px;
		height: 293px;
		left: 500.695px;
		top: 112px;
		overflow: visible;
	}
	#Principle_lbl_website_7_pc {
		left: 9px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 152px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#top_princi_bdr_website_7_pc {
		fill: rgba(222,14,11,1);
	}
	.top_princi_bdr_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 0px;
		left: 8px;
		top: 0px;
	}
	#Scroll_principle_web_7_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 442px;
		height: 199px;
		left: 0px;
		top: 94px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_principle_web_7_pc::-webkit-scrollbar{
		display: none;
	}
	#The_Mercedes-AMG_GT_combines_t {
		left: 9px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 120px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(116,116,116,1);
	}
	#avaliable_card_bg_website_7_pc {
		fill: rgba(192,192,192,1);
	}
	.avaliable_card_bg_website_7_pc {
		width: 1975.545px;
		height: 405.723px;
		position: absolute;
		overflow: visible;
		transform: matrix(1,0,0,1,-272.0387,3260.6196) rotate(-6deg);
		transform-origin: center;
	}
	#vision_top_line_website_7_pc {
		fill: rgba(99,113,203,1);
	}
	.vision_top_line_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 225px;
		top: 1507px;
	}
	#vision_img_and_evertyhng_grp_w {
		position: absolute;
		width: 1155px;
		height: 727px;
		left: 211px;
		top: 1400px;
		overflow: visible;
	}
	#vision_bdr_website_7_pc {
		fill: rgba(99,113,203,1);
	}
	.vision_bdr_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 662px;
		height: 146px;
		left: 14px;
		top: 413px;
	}
	#mask_top_visoon_img_website_7_ {
		position: absolute;
		width: 583px;
		height: 574px;
		left: 572px;
		top: 0px;
		overflow: visible;
	}
	#small_thumbnails_website_7_pc {
		position: absolute;
		width: 697px;
		height: 260px;
		left: 242px;
		top: 467px;
		overflow: visible;
	}
	#overall_bdr_thumb_website_7_pc {
		fill: rgba(192,192,192,1);
	}
	.overall_bdr_thumb_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 697px;
		height: 260px;
		left: 0px;
		top: 0px;
	}
	#img_2_website_7_pc_Group_3 {
		position: absolute;
		width: 273px;
		height: 220px;
		left: 318px;
		top: 20.002px;
		overflow: visible;
	}
	#website_7_pc_Group_4 {
		position: absolute;
		width: 272px;
		height: 220px;
		left: 24px;
		top: 20.002px;
		overflow: visible;
	}
	#arrow_website_7_pc {
		position: absolute;
		width: 48.28px;
		height: 12px;
		left: 619.72px;
		top: 130px;
		overflow: visible;
	}
	#Line_1_website_7_pc {
		fill: transparent;
		stroke: rgba(222,14,11,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_1_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 41.18px;
		height: 2px;
		left: 0px;
		top: 5.736px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Polygon_2_website_7_pc {
		fill: rgba(222,14,11,1);
	}
	.Polygon_2_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 12px;
		height: 10px;
		transform: translate(0px, 0.246px) matrix(1,0,0,1,37.2803,0.7539) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Vision_lbl_website_7_pc {
		left: 14px;
		top: 118.998px;
		position: absolute;
		overflow: visible;
		width: 111px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 41px;
		color: rgba(0,0,0,1);
	}
	#Scroll_vision_web_7_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 445px;
		height: 197px;
		left: 0px;
		top: 188.998px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_vision_web_7_pc::-webkit-scrollbar{
		display: none;
	}
	#The_cockpit_impresses_with_its {
		left: 14px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 133px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#line_3_missionwebsite_7_pc {
		fill: rgba(99,113,203,1);
	}
	.line_3_missionwebsite_7_pc {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.207px;
		left: 666.437px;
		top: 2782.014px;
		transform: matrix(1,0,0,1,0,0);
	}
	#line_2_mission_website_7_pc {
		fill: rgba(99,113,203,1);
	}
	.line_2_mission_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.207px;
		left: 666.437px;
		top: 2810.693px;
		transform: matrix(1,0,0,1,0,0);
	}
	#line1_mission_website_7_pc {
		fill: rgba(99,113,203,1);
	}
	.line1_mission_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.207px;
		left: 666.437px;
		top: 2840.107px;
		transform: matrix(1,0,0,1,0,0);
	}
	#mision_img_and_evertyhng_grp_w {
		position: absolute;
		width: 1475px;
		height: 563px;
		left: -366px;
		top: 2279.002px;
		overflow: visible;
	}
	#chris-nguyen-aTX_bRaOZnA-unspl {
		position: absolute;
		width: 1000px;
		height: 563px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#white_shade_website_7_pc_c {
		fill: url(#white_shade_website_7_pc_c);
	}
	.white_shade_website_7_pc_c {
		position: absolute;
		overflow: visible;
		width: 695px;
		height: 563px;
		left: 322px;
		top: 0px;
	}
	#mision_img_lines_grp_website_7 {
		fill: rgba(99,113,203,1);
	}
	.mision_img_lines_grp_website_7 {
		overflow: visible;
		position: absolute;
		width: 685.604px;
		height: 285.52px;
		left: 251.158px;
		top: 277.355px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Masterpiece_lbl_website_7_pc {
		left: 1033px;
		top: 112.998px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 9px;
		color: rgba(99,113,203,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#Mission_lbl_website_7_pc {
		left: 1033px;
		top: 157.998px;
		position: absolute;
		overflow: visible;
		width: 136px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#mision_like_website_7_pc {
		fill: rgba(99,113,203,1);
	}
	.mision_like_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 1032px;
		top: 146.998px;
	}
	#Scroll_mision_web_7_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 442px;
		height: 216px;
		left: 1033px;
		top: 226.998px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_mision_web_7_pc::-webkit-scrollbar{
		display: none;
	}
	#An_aggregate_of_racing-lev_web {
		left: 2px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(116,116,116,1);
	}
	#kisspng-mercedes-ben_website_7 {
		position: absolute;
		width: 278.729px;
		height: 313.898px;
		left: 591.225px;
		top: 128.928px;
		overflow: visible;
	}
	#kisspng-mercedes-ben_website_7 { 
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 0px;
}
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 20px); }
    100%   { transform: translate(0, -0px); }   
}
	#Scroll_off_website_7_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 1345.944px;
		height: 557.5px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		left: 0px;
		top: 3184.5px;
		overflow-x: scroll;
		overflow-y: hidden;
		transform: rotate(-6deg);
	}
	#Scroll_off_website_7_pc::-webkit-scrollbar{
		display: none;
	}
	#offering_card_1_shadow_space_w7 {
		position: relative;
		width: 230px;
		height: 325px;
		/* left: 66px; */
		top: 120px;
		margin-left: 15px;
		overflow: visible;
	}
	#card_bg_offeringshadow_space_w7 {
		fill: rgba(255,255,255,1);
	}
	.card_bg_offeringshadow_space_w7 {
		filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 245.5px;
		height: 340.5px;
		left: 0px;
		top: 0px;
	}
	#oofering_lbl_med_shadow_space_7mob {
		left: 18px;
		top: 288px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#markus-shadow_space_website_7 {
		position: absolute;
		width: 204.834px;
		height: 270.879px;
		left: 11.5px;
		top: 11.17px;
		overflow: visible;
	}
	#Icon_awesome-car_website_7_pc {
		fill: rgba(0,0,0,1);
	}
	.Icon_awesome-car_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 36px;
		height: 27px;
		left: 7.5px;
		top: 23.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Avaliable_Now_lbl_website_7_pc {
		left: 535px;
		top: 3113px;
		position: absolute;
		overflow: visible;
		width: 298px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#header_all_grp_web_7_pc {
		position: absolute;
		width: 1369px;
		height: 770px;
		left: -4px;
		top: 0px;
		overflow: visible;
	}
	#peter-broomfield-m3m-lnR90uM-u {
		position: absolute;
		width: 1369px;
		height: 770px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#text_unlessh_website_7_pc {
		position: absolute;
		width: 379px;
		height: 129px;
		left: 166px;
		top: 359px;
		overflow: visible;
	}
	#Unleash_the_True_Powe_website_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 228px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 41px;
		color: rgba(255,255,255,1);
	}
	#Drive_into_the_Future_Your_Ult {
		left: 0px;
		top: 108px;
		position: absolute;
		overflow: visible;
		width: 380px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(173,173,173,1);
	}
	#pre-order_button_website_7_pc {
		position: absolute;
		width: 163px;
		height: 13px;
		left: 229px;
		top: 524px;
		overflow: visible;
	}
	#line_pre_website_7_pc {
		fill: rgba(222,14,11,1);
	}
	.line_pre_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 0px;
		top: 7px;
	}
	#PRE-ORDER_NOW_website_7_pc {
		left: 76px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 88px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 9px;
		color: rgba(222,14,11,1);
		letter-spacing: 0.95px;
	}
	#zane-lee-bXbi1jJVDf0-unsplash {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 94px;
		top: 21px;
		overflow: visible;
	}
	#footer_all_grp_web_7_pc {
		position: absolute;
		width: 1555px;
		height: 571px;
		left: 39px;
		top: 3774px;
		overflow: visible;
	}
	#fotter_img_website_7_pc {
		position: absolute;
		width: 677px;
		height: 497px;
		left: 878px;
		top: 0px;
		overflow: visible;
	}
	#shade_footer_design_website_7__du {
		fill: url(#shade_footer_design_website_7__du);
	}
	.shade_footer_design_website_7__du {
		position: absolute;
		overflow: visible;
		width: 454px;
		height: 427px;
		left: 869px;
		top: 26px;
	}
	#bdr_info_website_7_pc {
		fill: rgba(192,192,192,1);
	}
	.bdr_info_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 493px;
		height: 454px;
		left: 554px;
		top: 117px;
	}
	#Call_us_for_questions_website_ {
		left: 628px;
		top: 141px;
		position: absolute;
		overflow: visible;
		width: 209px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#call_below_line_website_7_pc {
		fill: rgba(255,255,255,1);
	}
	.call_below_line_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 627px;
		top: 180px;
	}
	#call_web_7_pc {
		position: absolute;
		width: 346px;
		height: 60px;
		left: 627px;
		top: 200px;
		overflow: visible;
	}
	#no_bdr_website_7_pc {
		fill: rgba(99,113,203,1);
	}
	.no_bdr_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 60px;
		left: 0px;
		top: 0px;
	}
	#n_1_123-456-7890_website_7_pc {
		left: 69px;
		top: 16px;
		position: absolute;
		overflow: visible;
		width: 166px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#phone-receiver_bdr_website_7_p7 {
		fill: rgba(255,255,255,1);
	}
	.phone-receiver_bdr_website_7_p7 {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 60px;
		left: 0px;
		top: 0px;
	}
	#phone-receiver_website_7_pc {
		fill: rgba(21,21,21,1);
	}
	.phone-receiver_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 23.454px;
		height: 23.439px;
		left: 18.163px;
		top: 17.434px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Email_us_for_inquiries_website {
		left: 628px;
		top: 277px;
		position: absolute;
		overflow: visible;
		width: 211px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#email_bdr_website_7_pc {
		fill: rgba(255,255,255,1);
	}
	.email_bdr_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 627px;
		top: 316px;
	}
	#email_web_7_pc {
		position: absolute;
		width: 346px;
		height: 61px;
		left: 628px;
		top: 336px;
		overflow: visible;
	}
	#email_bdr_website_7_pc_d {
		fill: rgba(99,113,203,1);
	}
	.email_bdr_website_7_pc_d {
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 61px;
		left: 0px;
		top: 0px;
	}
	#emailmailcom_website_7_pc {
		left: 69px;
		top: 17px;
		position: absolute;
		overflow: visible;
		width: 278px;
		height: 27px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
	}
	#mail_bdr_website_7_pc {
		fill: rgba(255,255,255,1);
	}
	.mail_bdr_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 61px;
		left: 0px;
		top: 0px;
	}
	#envelope_website_7_pc {
		position: absolute;
		width: 29.462px;
		height: 23.147px;
		left: 15.144px;
		top: 19.268px;
		overflow: visible;
	}
	#Path_15_website_7_pc {
		fill: rgba(21,21,21,1);
	}
	.Path_15_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 29.462px;
		height: 14.73px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_16_website_7_pc {
		fill: rgba(21,21,21,1);
	}
	.Path_16_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 29.462px;
		height: 15.684px;
		left: 0px;
		top: 7.463px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 528px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(173,173,173,1);
	}
	#dv_socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 160px;
		top: 437px;
		overflow: visible;
	}
	#linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.med_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.191px;
		overflow: visible;
	}
	#n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#shadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_7_eq:hover {
		fill: rgba(50,172,158,1);
	}
	.med_shadow_space_website_7_eq:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_7_eq {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_7_eq {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#gps_footer_website_7_pc {
		position: absolute;
		width: 402px;
		height: 251px;
		left: 53px;
		top: 143px;
		overflow: visible;
	}
	#bdr_screen_website_7_pc {
		fill: rgba(0,0,0,1);
	}
	.bdr_screen_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 402px;
		height: 251px;
		left: 0px;
		top: 0px;
	}
	#screen_map_website_7_pc {
		fill: rgba(255,255,255,1);
	}
	.screen_map_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 385px;
		height: 211px;
		left: 8px;
		top: 7px;
	}
	#Icon_awesome-car_website_7_pc_ew {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-car_website_7_pc_ew {
		overflow: visible;
		position: absolute;
		width: 15.924px;
		height: 11.943px;
		left: 29.635px;
		top: 229.723px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-musicwebsite_7_pc {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-musicwebsite_7_pc {
		overflow: visible;
		position: absolute;
		width: 11.944px;
		height: 11.943px;
		left: 74.993px;
		top: 229.723px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-location-arrow_we {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-location-arrow_we {
		overflow: visible;
		position: absolute;
		width: 11.943px;
		height: 11.943px;
		left: 175.159px;
		top: 229.723px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_459321__website_7_pc {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_459321__website_7_pc {
		overflow: visible;
		position: absolute;
		width: 9.465px;
		height: 13.943px;
		left: 234.466px;
		top: 229.723px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_4601_website_7_pc {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_4601_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 12.451px;
		left: 231.48px;
		top: 230.469px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_459_website_7_pc {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_459_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 9.465px;
		height: 13.943px;
		left: 271.474px;
		top: 229.723px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_460_website_7_pc {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_460_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 12.451px;
		left: 281.924px;
		top: 230.469px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-volume-up_website {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-volume-up_website {
		overflow: visible;
		position: absolute;
		width: 13.132px;
		height: 10.943px;
		left: 364.569px;
		top: 230.723px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Email_us_for_inquiries_website_e {
		left: 629px;
		top: 415px;
		position: absolute;
		overflow: visible;
		width: 211px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#line1_website_7_pc {
		fill: rgba(255,255,255,1);
	}
	.line1_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 628px;
		top: 454px;
	}
	#location_web_7_pc {
		position: absolute;
		width: 346px;
		height: 61px;
		left: 629px;
		top: 474px;
		overflow: visible;
	}
	#address_bdr_lbl_website_7_pc {
		fill: rgba(99,113,203,1);
	}
	.address_bdr_lbl_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 61px;
		left: 0px;
		top: 0px;
	}
	#address_website_7_pc {
		left: 69px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 278px;
		height: 51px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
	}
	#address_icon_bdr_website_7_pc {
		fill: rgba(255,255,255,1);
	}
	.address_icon_bdr_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 61px;
		left: 0px;
		top: 0px;
	}
	#Icon_material-edit-location_we7 {
		fill: rgba(0,0,0,1);
	}
	.Icon_material-edit-location_we7 {
		overflow: visible;
		position: absolute;
		width: 21px;
		height: 30px;
		left: 19px;
		top: 16px;
		transform: matrix(1,0,0,1,0,0);
	}