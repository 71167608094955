#big_1_trending_off_PC {
    position: relative;
    width: 540px;
    height: 680px;
    /* left: 570px; */
   margin: 10px;
    overflow: visible;
}
#bdr_all_trending_off_PC {
    fill: rgba(245,245,246,1);
}
.bdr_all_trending_off_PC {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 558px;
    height: 698px;
    left: 0px;
    top: 0px;
}
#bdr_amount_trending_off_PC {
    fill: rgba(255,255,255,1);
}
.bdr_amount_trending_off_PC {
    position: absolute;
    overflow: visible;
    width: 63px;
    height: 34px;
    left: 457px;
    top: 591px;
}
#n_9_trending_off_PC {
    left: 474px;
    top: 597px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    text-align: center;
    font-family: Poppins-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#Image_trending__off_PC {
    position: absolute;
    width: 531px;
    height: 520px;
    left: 9px;
    top: 55px;
    overflow: visible;
}
#Nike_Air_Force_1_Sha_trending_ {
    left: 29px;
    top: 575px;
    position: absolute;
    overflow: visible;
    width: 400px;
    white-space: pre-wrap;
    line-height: 32px;
    /* margin-top: -3.5px; */
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.1818181800842285px;
}
#Single_Tour_trending_off_PC {
    left: 29px;
    top: 617px;
    position: absolute;
    overflow: visible;
    width: 131px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -6.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(17,17,17,1);
}
#likes_trending_off_PC {
    fill: rgba(255,255,255,1);
}
.likes_trending_off_PC {
    position: absolute;
    overflow: visible;
    width: 39px;
    height: 21px;
    left: 43px;
    top: 12px;
}
#bdr_1_trending_off_PC {
    fill: rgba(255,255,255,1);
}
.bdr_1_trending_off_PC {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 37px;
    left: 3px;
    top: 3px;
}
#trophy_trending_off_PC {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 7px;
    top: 7px;
    overflow: visible;
}
#n_234_like_trending_off_PC {
    left: 46px;
    top: 12px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17,17,17,1);
}


#trohpie_bdr_small_7_trending_o {
    fill: rgba(255,255,255,1);
}
.trohpie_bdr_small_7_trending_o {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 38px;
    left: 500px;
    top: 2px;
}
#share_1_reqoffevent_off_PC_da {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 505px;
    top: 9px;
    overflow: visible;
}