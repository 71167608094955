	.mediaViewInfo {
		--web-view-name: KnowledgeSpace Mob;
		--web-view-id: KnowledgeSpace_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: KnowledgeSpace_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#KnowledgeSpace_Mob {
		position: absolute;
		width: 320px;
		height: 4448px;
        left:calc((100vw - 320px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: KnowledgeSpace Mob;
		--web-view-id: KnowledgeSpace_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#KS_20_mobRectangle_79 {
		position: absolute;
		width: 320px;
		height: 500px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_mobGroup_99 {
		position: absolute;
		width: 320px;
		height: 614px;
		left: 0px;
		top: 2740px;
		overflow: visible;
	}
	#KS_20_mobScroll_Group_69 {
		mix-blend-mode: normal;
		position: absolute;
		width: 320px;
		height: 579.844px;
		left: 0px;
		top: 34.156px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#KS_20_mobGroup_49 {
		position: relative;
		width: 282px;
		height: 531.844px;
		left: 19px;
		top: 24px;
        margin-right: 30px;
		overflow: visible;
	}
	#KS_20_mobRectangle_115 {
		position: absolute;
		width: 282px;
		height: 228.793px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_mobGroup_48 {
		position: absolute;
		width: 282px;
		height: 303.051px;
		left: 0px;
		top: 228.793px;
		overflow: visible;
	}
	#KS_20_mobRectangle_116 {
		fill: rgba(241,241,241,1);
	}
	.KS_20_mobRectangle_116 {
		position: absolute;
		overflow: visible;
		width: 282px;
		height: 303.051px;
		left: 0px;
		top: 0px;
	}
	#KS_20_mobRectangle_117 {
		fill: rgba(207,207,207,1);
	}
	.KS_20_mobRectangle_117 {
		position: absolute;
		overflow: visible;
		width: 282px;
		height: 2.281px;
		left: 0px;
		top: 142.9px;
	}
	#KS_20_mobRectangle_118 {
		fill: rgba(207,207,207,1);
	}
	.KS_20_mobRectangle_118 {
		position: absolute;
		overflow: visible;
		width: 186.227px;
		height: 1.52px;
		left: 47.887px;
		top: 206.748px;
	}
	#KS_20_mobDigital_Marketing {
		left: 18.563px;
		top: 21.563px;
		position: absolute;
		overflow: visible;
		width: 191px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -4px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobTime__10_am {
		/* left: 86.973px; */
		top: 173.305px;
		position: absolute;
		overflow: visible;
		width: 100%;
        text-align: center;
		white-space: nowrap;
		line-height: 24px;
		margin-top: -3px;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobLorem_ipsum_dolor_sit_amet_con {
		left: 19.563px;
		top: 72.971px;
		position: absolute;
		overflow: visible;
		width: 222.951px;
		height: 57.32614517211914px;
		line-height: 18px;
		margin-top: -2px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(72,72,72,1);
	}
	#KS_20_mobRectangle_120 {
		fill: rgba(39,174,96,1);
	}
	.KS_20_mobRectangle_120 {
		position: absolute;
		overflow: visible;
		width: 247px;
		height: 47px;
		left: 17.5px;
		top: 236.393px;
	}
	#KS_20_mobJoin_Now {
		left: 102.354px;
		top: 247.035px;
		position: absolute;
		overflow: visible;
		width: 77px;
		white-space: nowrap;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#KS_20_mobCourses {
		left: 93px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 135px;
		white-space: nowrap;
		text-align: center;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobGroup_105 {
		position: absolute;
		width: 509px;
		height: 673px;
		left: 8px;
		top: 3749px;
		overflow: visible;
	}
	#KS_20_mobContact {
		left: 87px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 130px;
		white-space: nowrap;
		line-height: 48px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(17,17,17,1);
	}
	#KS_20_mobGroup_90 {
		position: absolute;
		width: 303px;
		height: 530px;
		left: 0px;
		top: 59px;
		overflow: visible;
	}
	#KS_20_moblocation {
		position: absolute;
		width: 24px;
		height: 30px;
		left: 32px;
		top: 207px;
		overflow: visible;
	}
	#KS_20_mobmessage {
		position: absolute;
		width: 30px;
		height: 21px;
		left: 31px;
		top: 345px;
		overflow: visible;
	}
	#KS_20_mobScroll_Group_71 {
		mix-blend-mode: normal;
		position: absolute;
		width: 290px;
		height: 79px;
		left: 13px;
		top: 246px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_mobLSE_Houghton___Street_London__ {
		left: 19px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 267px;
		height: 71px;
		line-height: 18px;
		margin-top: -2px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(72,72,72,1);
	}
	#KS_20_mobhellogmailcom {
		left: 31px;
		top: 381px;
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 24px;
		line-height: 18px;
		margin-top: -2px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(72,72,72,1);
	}
	#KS_20_mobphone {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 32px;
		top: 459px;
		overflow: visible;
	}
	#KS_20_mobn_4420_74057686 {
		left: 32px;
		top: 505px;
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 25px;
		line-height: 18px;
		margin-top: -2px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(72,72,72,1);
	}
	#KS_20_mobRectangle_740 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.KS_20_mobRectangle_740 {
		position: absolute;
		overflow: visible;
		width: 303px;
		height: 196px;
		left: 0px;
		top: 0px;
	}
	#KS_20_mobn_Copyright_Live_OPC_Private_L {
		left: 2px;
		top: 629px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 44px;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(173,173,173,1);
	}
	#KS_20_mobGroup_100 {
		position: absolute;
		width: 320px;
		height: 289px;
		left: 0px;
		top: 3404px;
		overflow: visible;
	}
	#KS_20_mobRectangle_121 {
		fill: rgba(241,241,241,1);
	}
	.KS_20_mobRectangle_121 {
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 289px;
		left: 0px;
		top: 0px;
	}
	#KS_20_mobOur_Teachers {
		left: 50px;
		top: 30px;
		position: absolute;
		overflow: visible;
		width: 220px;
		white-space: nowrap;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(17,17,17,1);
	}
	#KS_20_mobScroll_Group_70 {
		mix-blend-mode: normal;
		position: absolute;
		width: 310px;
		height: 210px;
		left: 0px;
		top: 79px;
		overflow: hidden;
	}
	#KS_20_mobGroup_96 {
		position: absolute;
		width: 157px;
		height: 188px;
		left: 17px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_mobGroup_95 {
		position: absolute;
		width: 157px;
		height: 188px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_mobEllipse_17 {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 31px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_mobBenton_Colley {
		left: 2px;
		top: 125px;
		position: absolute;
		overflow: visible;
		width: 155px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -4px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobPhotography_Head {
		left: 0px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 158px;
		white-space: nowrap;
		line-height: 24px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(39,174,96,1);
	}
	#KS_20_mobGroup_107 {
		position: absolute;
		width: 320px;
		height: 500px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_mobRectangle_78 {
		opacity: 0.26;
		fill: rgba(26,26,26,1);
	}
	.KS_20_mobRectangle_78 {
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 500px;
		left: 0px;
		top: 0px;
	}
	#KS_20_mobRectangle_80 {
		fill: rgba(39,174,97,1);
	}
	.KS_20_mobRectangle_80 {
		position: absolute;
		overflow: visible;
		width: 134px;
		height: 47px;
		left: 93px;
		top: 344px;
	}
	#KS_20_mobKnowledge___is_power {
		left: 62px;
		top: 134px;
		position: absolute;
		overflow: visible;
		width: 197px;
		white-space: nowrap;
		text-align: center;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(255,255,255,1);
	}
	#KS_20_mobAny_succesfull_career_starts__ {
		left: 63px;
		top: 259px;
		position: absolute;
		overflow: visible;
		width: 195px;
		white-space: nowrap;
		line-height: 18px;
		margin-top: -2px;
		text-align: center;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#KS_20_mobLearn_more {
		left: 108px;
		top: 358px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: center;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#KS_20_moblogo {
		position: absolute;
		width: 40px;
		height: 32px;
		left: 19px;
		top: 18px;
        border-radius: 50%;
		overflow: hidden;
	}
	#KS_20_mobGroup_109 {
		position: absolute;
		width: 290px;
		height: 674px;
		left: 30px;
		top: 1286px;
		overflow: visible;
	}
	#KS_20_mobtamanna-rumee-vaTsR-ghLog-unsp {
		position: absolute;
		width: 260px;
		height: 331px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_mobGroup_3 {
		position: absolute;
		width: 290px;
		height: 305px;
		left: 0px;
		top: 369px;
		overflow: visible;
	}
	#KS_20_mobMission {
		left: 14px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 126px;
		white-space: nowrap;
		line-height: 48px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobScroll_Group_65 {
		mix-blend-mode: normal;
		position: absolute;
		width: 290px;
		height: 235px;
		left: 0px;
		top: 70px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_mobLorem_ipsum_dolor_sit_amet_con_eh {
		left: 15px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 246px;
		height: 232px;
		line-height: 24px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobGroup_15057 {
		position: absolute;
		width: 290px;
		height: 674px;
		left: 30px;
		top: 556px;
		overflow: visible;
	}
	#KS_20_mobtim-mossholder-WE_Kv_ZB1l0-uns {
		position: absolute;
		width: 260px;
		height: 331px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_mobGroup_3_ek {
		position: absolute;
		width: 290px;
		height: 305px;
		left: 0px;
		top: 369px;
		overflow: visible;
	}
	#KS_20_mobVision {
		left: 14px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		line-height: 48px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobScroll_Group_65_em {
		mix-blend-mode: normal;
		position: absolute;
		width: 290px;
		height: 235px;
		left: 0px;
		top: 70px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_mobLorem_ipsum_dolor_sit_amet_con_en {
		left: 15px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 246px;
		height: 232px;
		line-height: 24px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobGroup_15058 {
		position: absolute;
		width: 290px;
		height: 674px;
		left: 30px;
		top: 2016px;
		overflow: visible;
	}
	#KS_20_mobnguyen-khanh-ly-liiusuaISP0-un {
		position: absolute;
		width: 260px;
		height: 331px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_mobGroup_3_eq {
		position: absolute;
		width: 290px;
		height: 305px;
		left: 0px;
		top: 369px;
		overflow: visible;
	}
	#KS_20_mobPrinciples {
		left: 14px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 162px;
		white-space: nowrap;
		line-height: 48px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(26,26,26,1);
	}
	#KS_20_mobScroll_Group_65_es {
		mix-blend-mode: normal;
		position: absolute;
		width: 290px;
		height: 235px;
		left: 0px;
		top: 70px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_mobLorem_ipsum_dolor_sit_amet_con_et {
		left: 15px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 246px;
		height: 232px;
		line-height: 24px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(26,26,26,1);
	}