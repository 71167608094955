	.mediaViewInfo {
		--web-view-name: Website 4;
		--web-view-id: Website_4;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Website_4;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#Website_4 {
		position: absolute;
		width: 1366px;
		height: 2935px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Website 4;
		--web-view-id: Website_4;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#princi_desgin_bdr_med_web_4_pc {
		fill: rgba(187,177,224,1);
	}
	.princi_desgin_bdr_med_web_4_pc {
		position: absolute;
		overflow: visible;
		width: 246px;
		height: 392px;
		left: 890px;
		top: 896px;
	}
	#vision_desgin_bdr_med_web_4_pc {
		fill: rgba(135,207,172,0.647);
	}
	.vision_desgin_bdr_med_web_4_pc {
		position: absolute;
		overflow: visible;
		width: 246px;
		height: 392px;
		left: 570px;
		top: 896px;
	}
	#mission_desgin_bdr_med_web_4_p {
		fill: rgba(166,210,202,0.651);
	}
	.mission_desgin_bdr_med_web_4_p {
		position: absolute;
		overflow: visible;
		width: 246px;
		height: 392px;
		left: 250px;
		top: 896px;
	}
	#design_main_page {
		fill: rgba(32,142,121,1);
	}
	.design_main_page {
		overflow: visible;
		position: absolute;
		width: 965.786px;
		height: 759.197px;
		left: 401.037px;
		top: 1.803px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Heading_left_med_web_4_pc {
		left: 155px;
		top: 248px;
		position: absolute;
		overflow: visible;
		width: 428px;
		height: 72px;
		line-height: 73px;
		margin-top: -9.5px;
		text-align: right;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 54px;
		color: rgba(0,0,0,1);
	}
	#menu_grp_med_web_4_pc {
		position: absolute;
		width: 348px;
		height: 27px;
		left: 509px;
		top: 55px;
		overflow: visible;
	}
	#home_med_web_4_pc {
		position: absolute;
		width: 79px;
		height: 27px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Homemed_web_4_pc {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#home-icon-silhouette_med_web_4 {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#offering_med_web_4_pc {
		position: absolute;
		width: 106px;
		height: 27px;
		left: 114px;
		top: 0px;
		overflow: visible;
	}
	#Offerings_med_web_4_pc {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#discount_med_web_4_pc {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#contact_grp_med_web_4_pc {
		position: absolute;
		width: 93px;
		height: 27px;
		left: 255px;
		top: 0px;
		overflow: visible;
	}
	#Contact_lbl_med_web_4_pc {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#contact_img_med_web_4_pc {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#Heading_med_web_4_pc {
		left: 656px;
		top: 248px;
		position: absolute;
		overflow: visible;
		width: 382px;
		height: 72px;
		line-height: 73px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 54px;
		color: rgba(0,0,0,1);
	}
	#markus-wink_img_centre_med_web {
		position: absolute;
		width: 584.558px;
		height: 297.121px;
		left: 391px;
		top: 363.824px;
		overflow: visible;
	}
	#Scroll_intro_med_web_4_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 382px;
		height: 213px;
		left: 92px;
		top: 448px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_intro_med_web_4_pc::-webkit-scrollbar{
		display: none;
	}
	#Lorem_ipsum_dolor_sit_amet_hh_pc {
		left: 10px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 365px;
		height: 206px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#text_our_partner_bdr_med_web_4 {
		transform: matrix(1,0,0,1,1168,290) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 181px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.1px;
	}
	#Scroll_scroll_bdr_med_web_4_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 305px;
		height: 546px;
		left: 1174px;
		top: 205px;
      
		overflow-x: hidden;
		overflow-y: auto;
	}
	#g_and_google_grp {
		position: relative;
		width: 27px;
		height: 175px;
		left: 9px;
		top: 10px;
		overflow: visible;
	}
	#google_lbl_partnermed_web_4_pc {
		transform: translate(-1182px, -274px) matrix(1,0,0,1,1123,363) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(233,255,251,1);
	}
	#google_1img_med_web_4_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 1px;
		top: 0px;
		overflow: visible;
		transform: rotate(90deg);
	}
	#marijuana_img_bdr_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 594px;
		top: 259px;
		overflow: visible;
	}
	#follow_us_btn__grp_web_4_pc:hover {
		width: 209px;
		height: 43px;
		left: 762px;
		top: 612px;
	}
	#follow_us_btn__grp_web_4_pc {
		position: absolute;
		width: 200px;
		height: 41px;
		left: 766px;
		top: 615px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#bdr_med_web_4_pc_cl {
		fill: rgba(92,181,164,1);
	}
	.bdr_med_web_4_pc_cl {
		filter: drop-shadow(5px 5px 2px rgba(223, 223, 223, 1));
		position: absolute;
		overflow: visible;
		width: 208px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#Follow_Us_med_web_4_pc_cm {
		left: 69px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 64px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#arrow_follow_us_med_web_4_pc_cn {
		fill: rgba(255,255,255,1);
	}
	.arrow_follow_us_med_web_4_pc_cn {
		overflow: visible;
		position: absolute;
		width: 20px;
		height: 19.494px;
		left: 142px;
		top: 11.002px;
		transform: matrix(1,0,0,1,0,0);
	}
	#all_grp_mission_vision_princi {
		position: absolute;
		width: 907px;
		height: 412px;
		left: 250px;
		top: 876px;
		overflow: visible;
	}
	#mission_all_med_web_4_pc:hover {
		left: 20px;
		top: 0px;
	}
	#mission_all_med_web_4_pc {
		position: absolute;
		width: 247px;
		height: 392px;
		left: 0px;
		top: 20px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#bdr_mission_med_web_4_pc {
		fill: rgba(166,210,202,0.651);
	}
	.bdr_mission_med_web_4_pc {
		position: absolute;
		overflow: visible;
		width: 247px;
		height: 392px;
		left: 0px;
		top: 0px;
	}
	#box_1_website_med_web_4_pc {
		position: absolute;
		width: 69px;
		height: 59px;
		left: 95px;
		top: 57px;
		overflow: visible;
	}
	#mission_lbl_med_web_4_pc {
		left: 84px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Scroll_mission_med_web_4_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 238px;
		height: 252px;
		left: 5px;
		top: 135px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_mission_med_web_4_pc::-webkit-scrollbar{
		display: none;
	}
	#Lorem_ipsum_dolor_sit_amet_con_c {
		left: 15px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 224px;
		height: 264px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#vision_grp_all_med_web_4_pc:hover {
		left: 340px;
		top: 0px;
	}
	#vision_grp_all_med_web_4_pc {
		position: absolute;
		width: 247px;
		height: 392px;
		left: 320px;
		top: 20px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#bdr_vision_back_med_web_4_pc {
		fill: rgba(135,207,172,0.647);
	}
	.bdr_vision_back_med_web_4_pc {
		position: absolute;
		overflow: visible;
		width: 247px;
		height: 392px;
		left: 0px;
		top: 0px;
	}
	#box_2_website_1_med_web_4_pc {
		position: absolute;
		width: 70px;
		height: 59px;
		left: 91px;
		top: 57px;
		overflow: visible;
	}
	#vision_lbl_med_web_4_pc {
		left: 88px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Scroll_vision_med_web_4_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 238px;
		height: 252px;
		left: 5px;
		top: 135px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_vision_med_web_4_pc::-webkit-scrollbar{
		display: none;
	}
	#Lorem_ipsum_dolor_sit_amet_con_dc {
		left: 15px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 224px;
		height: 264px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#principle_grp_all_med_web_4_pc:hover {
		left: 660px;
		top: 0px;
	}
	#principle_grp_all_med_web_4_pc {
		position: absolute;
		width: 247px;
		height: 392px;
		left: 640px;
		top: 20px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#princi_bdr_akll_med_web_4_pc {
		fill: rgba(151,135,207,0.647);
	}
	.princi_bdr_akll_med_web_4_pc {
		position: absolute;
		overflow: visible;
		width: 247px;
		height: 392px;
		left: 0px;
		top: 0px;
	}
	#box_3_off_med_web_4_pc_op_live {
		position: absolute;
		width: 70px;
		height: 59px;
		left: 89px;
		top: 57px;
		overflow: visible;
	}
	#princi_med_web_4_pc_lbl {
		left: 67px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 115px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Scroll_prrinci_details_med_web {
		mix-blend-mode: normal;
		position: absolute;
		width: 238px;
		height: 252px;
		left: 5px;
		top: 135px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_prrinci_details_med_web::-webkit-scrollbar{
		display: none;
	}
	#Lorem_ipsum_dolor_sit_amet_con_do {
		left: 15px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 224px;
		height: 264px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#oofering_lbl_med_web_4_pc {
		left: 155px;
		top: 1400px;
		position: absolute;
		overflow: visible;
		width: 179px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#Scroll_offering_med_web_4_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 1152px;
		height: 411px;
		left: 127px;
		top: 1484px;
        display: -webkit-box;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#Scroll_offering_med_web_4_pc::-webkit-scrollbar{
		display: none;
	}
	#offering_crd_1_grp_med_web_4_p {
		position: relative;
		width: 200px;
		height: 332px;
		margin: 20px;
		top: 11px;
		overflow: visible;
	}
	#title_bdr_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.title_bdr_med_web_4_pc {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 190px;
		height: 50px;
		left: 14px;
		top: 300px;
	}
	#BiziBees_PVT_LTD_med_web_4_pc {
		left: 14px;
		top: 306px;
		position: absolute;
		overflow: visible;
		width: 173px;
		height: 20px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#img_border_med_web_4_pc_of {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 300px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chuttersna_med_web_4_pc_img {
		position: absolute;
		width: 172px;
		height: 277px;
		left: 14px;
		top: 7px;
		overflow: visible;
	}
	#map_area_med_web_4_pc {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.map_area_med_web_4_pc {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 330px;
		left: 0px;
		top: 2127px;
	}
	#map_top_med_web_4_pc {
		fill: rgba(32,142,121,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.map_top_med_web_4_pc {
		overflow: visible;
		position: absolute;
		width: 1396.902px;
		height: 23px;
		left: 0.098px;
		top: 2104px;
		transform: matrix(1,0,0,1,0,0);
	}
	#map_below_desion_med_web_4_pc {
		fill: rgba(32,142,121,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.map_below_desion_med_web_4_pc {
		overflow: visible;
		position: absolute;
		width: 1366px;
		height: 23px;
		left: 0.098px;
		top: 2457px;
		transform: matrix(1,0,0,1,0,0);
	}
	#footer_med_web_4_pc {
		fill: rgba(149,213,182,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.footer_med_web_4_pc {
		position: absolute;
		overflow: visible;
		width: 1367px;
		height: 299px;
		left: 0px;
		top: 2641px;
	}
	#n_234567890_mobile_number_webs {
		left: 85px;
		top: 2664px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#affanghanioperatelive_email_we {
		left: 86px;
		top: 2718px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#address_med_web_4_pc {
		left: 81px;
		top: 2772px;
		position: absolute;
		overflow: visible;
		width: 373px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Connect_with_us_and_unlock_lim_hh_pc {
		left: 1034px;
		top: 2823px;
		position: absolute;
		overflow: visible;
		width: 271px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#location_img_med_web_4_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 37px;
		top: 2772px;
		overflow: visible;
	}
	#email_address_med_web_4_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 37px;
		top: 2718px;
		overflow: visible;
	}
	#phone_number_med_web_4_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 37px;
		top: 2664px;
		overflow: visible;
	}
	#quote_left_med_web_4_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 994px;
		top: 2823px;
		overflow: visible;
	}
	#quote_right_med_web_4_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 1184px;
		top: 2880px;
		overflow: visible;
	}
	#n_Copyright_Operate_Live_med_w {
		left: 37px;
		top: 2882.25px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#socials_grp_med_web_4_pc {
		position: absolute;
		width: 255px;
		height: 65px;
		left: 1042px;
		top: 2712px;
		overflow: visible;
	}
	#linked_in_grp_med_web_4_pc {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 190px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_5_med_web_4_pc:hover {
		fill: rgba(120,120,255,1);
	}
	.Ellipse_5_med_web_4_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_5_med_web_4_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_5_med_web_4_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_website_4_layout {
		position: absolute;
		width: 31.653px;
		height: 28.776px;
		left: 17.063px;
		top: 17.875px;
		overflow: visible;
	}
	#n_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.n_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 20.919px;
		height: 19.736px;
		left: 10.734px;
		top: 9.041px;
		transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_down {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_down {
		overflow: visible;
		position: absolute;
		width: 6.169px;
		height: 19.549px;
		left: 0.798px;
		top: 9.227px;
		transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 7.489px;
		height: 6.783px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_med_web_4_pc {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 95px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_4_med_web_4_pc:hover {
		fill: rgba(255,120,120,1);
	}
	.Ellipse_4_med_web_4_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_4_med_web_4_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_4_med_web_4_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagram_website_4_layout {
		position: absolute;
		width: 31.688px;
		height: 31.688px;
		left: 17.063px;
		top: 17.063px;
		overflow: visible;
	}
	#whatsapp_grp_med_web_4_pc {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_3_med_web_4_pc:hover {
		fill: rgba(50,172,158,1);
	}
	.Ellipse_3_med_web_4_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_3_med_web_4_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_3_med_web_4_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_website4_layout {
		position: absolute;
		width: 32.5px;
		height: 32.5px;
		left: 16.25px;
		top: 16.25px;
		overflow: visible;
	}
	#medincine_bdr_web_4_pc {
		fill: rgba(32,142,121,1);
	}
	.medincine_bdr_web_4_pc {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 70px;
		left: -1px;
		top: 2572px;
	}
	#Medicine_is_not_only_a_science {
		left: 0px;
		top: 2588px;
		position: absolute;
		overflow: visible;
		width: 2383px;
		height: 35px;
		text-align: center;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(255,255,255,1);
	}
	#profile_pic_logo_web_4_pc {
		position: absolute;
		width: 100px;
		height: 100px;
		left: 123px;
		top: 55px;
        border-radius: 50%;
		overflow: hidden;
	}
	@keyframes breath {
  from {
    animation-timing-function: ease-out;
  }

  to {
    transform: scale(1.25) translateY(-5px) perspective(1px);
    text-shadow: 0 0 40px var(--glow-color);
    animation-timing-function: ease-in-out;
  }
}

@media (prefers-reduced-motion:no-preference) {
  [letter-animation] > span {
    display: inline-block;
    white-space: break-spaces;
  }

  [letter-animation=breath] {
    --glow-color: white;
  }

  [letter-animation=breath]>span {
    animation: breath 1.2s ease calc(var(--index) * 100 * 1ms) infinite alternate;
  }
}

@media (prefers-reduced-motion:no-preference) and (prefers-color-scheme: light) {
  [letter-animation=breath] {
    --glow-color: black;
  }
}
