.mediaViewInfo {
    --web-view-name: pop up meeting room;
    --web-view-id: pop_up_meeting_room;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pop_up_meeting_room;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pop_up_meeting_room {
    position: absolute;
    width: 371px;
    height: 532px;
    top: 50%;
    left: 50%;
    border-radius: 15px;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: pop up meeting room;
    --web-view-id: pop_up_meeting_room;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#meetingRoom_popbg {
    position: absolute;
    width: 371px;
    height: 532px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_border_meetingPop {
    position: absolute;
    width: 371px;
    height: 91.574px;
    left: 0px;
    top: -40.318px;
    overflow: visible;
}
#border_meetpop_ {
    fill: rgba(219,242,231,1);
}
.border_meetpop_ {
    width: 91.574px;
    height: 371px;
    position: absolute;
    overflow: visible;
    transform: translate(0px, 40.318px) matrix(1,0,0,1,-44.287,-180.0308) rotate(90deg);
    transform-origin: center;
}
#Meeting_Request_lbl_mp {
    left: 111px;
    top: 52.318px;
    position: absolute;
    overflow: visible;
    width: 161px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#txtagenda_pop_val {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtagenda_pop_val {
    position: absolute;
    overflow: visible;
    width: 301.728px;
    height: 55px;
    left: 35px;
    top: 295px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius: 10px;
}
#txtMeeting_Room_pop_val {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtMeeting_Room_pop_val {
    position: absolute;
    overflow: visible;
    width: 301.728px;
    height: 45px;
    left: 35px;
    top: 81px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius: 10px;
}
#txtDate_pop_val {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtDate_pop_val {
    position: absolute;
    overflow: visible;
    width: 191.228px;
    height: 37px;
    left: 145.5px;
    top: 146px;
    font-size: 15px;
    padding: 10px;
    border: 2px solid darkcyan;
    border-radius: 10px;
}
#Meeting_On_lbl_mp {
    left: 20px;
    top: 151px;
    position: absolute;
    overflow: visible;
    width: 116px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#txtprevious_remarks_val {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtprevious_remarks_val {
    position: absolute;
    overflow: visible;
    width: 301.728px;
    height: 72px;
    left: 35px;
    top: 203px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius: 10px;
}
#txtremarks_val_mp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtremarks_val_mp {
    position: absolute;
    overflow: visible;
    width: 301.728px;
    height: 55px;
    left: 34.636px;
    top: 370px;
    font-size: 15px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius: 10px;
}
#Group_btn_accept_:hover {
    left: 32px;
    top: 469px;
}
#Group_btn_accept_ {
    position: absolute;
    width: 97px;
    height: 32px;
    left: 29px;
    top: 464px;
    overflow: visible;
    border-radius: 10px;
    transition: all .3s ease-out;
}
#border_grp_btn_acc {
    fill: rgba(219,242,231,1);
}
.border_grp_btn_acc {
    position: absolute;
    overflow: visible;
    width: 97px;
    height: 32px;
    left: 0px;
    top: 0px;
}
#Accept_btn_acc {
    left: 22px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_reject_btn_rej:hover {
    left: 144px;
    top: 469px;
}
#Group_reject_btn_rej {
    position: absolute;
    width: 97px;
    height: 32px;
    left: 141px;
    top: 464px;
    overflow: visible;
    border-radius: 10px;
    transition: all .3s ease-out;
}
#border_btn_rej_ {
    fill: rgba(219,242,231,1);
}
.border_btn_rej_ {
    position: absolute;
    overflow: visible;
    width: 97px;
    height: 32px;
    left: 0px;
    top: 0px;
}
#Reject_lbl_rej {
    left: 26px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 46px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_btn_propose_pr:hover {
    left: 254px;
    top: 469px;
}
#Group_btn_propose_pr {
    position: absolute;
    width: 97px;
    height: 32px;
    left: 251px;
    top: 464px;
    overflow: visible;
    border-radius: 10px;
    transition: all .3s ease-out;
}
#border_pro_btn_pr {
    fill: rgba(219,242,231,1);
}
.border_pro_btn_pr {
    position: absolute;
    overflow: visible;
    width: 97px;
    height: 32px;
    left: 0px;
    top: 0px;
}
#Propose_btn_pro__lbl {
    left: 18px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#closeiconmeetingpop {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 344px;
    top: 10px;
    cursor: pointer;
    overflow: visible;
}