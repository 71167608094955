.mediaViewInfo {
    --web-view-name: pop caller;
    --web-view-id: pop_caller;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pop_caller;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pop_caller {
    position: relative;
    width: 331px;
    height: 42px;
    top: 230px;
    left:430px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: pop caller;
    --web-view-id: pop_caller;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bg_design_callpop {
    position: absolute;
    width: 331px;
    height: 42px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#lbl_name_callpop {
    left: 10px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#btn_grp_popcall {
    position: absolute;
    width: 53px;
    height: 25px;
    left: 192px;
    top: 9px;
    overflow: visible;
    border-radius:  10px;
}
#rec_accept_popcall {
    opacity: 0.5;
    fill: rgba(188,226,179,1);
}
.rec_accept_popcall {
    position: absolute;
    overflow: visible;
    width: 53px;
    height: 25px;
    left: 0px;
    top: 0px;
}
#accept_call_popcall {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 17px;
    top: 3px;
    overflow: visible;
}
#btn_grp_decline_popcall {
    position: absolute;
    width: 53px;
    height: 26px;
    left: 261px;
    top: 9px;
    overflow: visible;
    border-radius:  10px;
}
#decline_rec_popcall {
    opacity: 0.5;
    fill: rgba(188,226,179,1);
}
.decline_rec_popcall {
    position: absolute;
    overflow: visible;
    width: 53px;
    height: 25px;
    left: 0px;
    top: 1px;
}
#decline_img_popcall {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 14px;
    top: 0px;
    overflow: visible;
}