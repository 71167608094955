#OFFM_card_1_reqoffevent_off {
    position: relative;
    width: 170px;
    height: 204px;
    left: 10px;
    top: 16px;
    margin: 5px;
    overflow: visible;
}
#OFFM_Base_reqoffevent_off {
    fill: rgba(255,255,255,1);
}
.OFFM_Base_reqoffevent_off {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 188px;
    height: 188px;
    left: 0px;
    top: 0px;
}
#OFFM_bdr_1_reqoffevent_off {
    fill: rgba(255,79,4,0.09);
}
.OFFM_bdr_1_reqoffevent_off {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 22px;
    left: 130px;
    top: 177px;
}
#OFFM_n_90_reqoffevent_of {
    left: 140px;
    top: 183px;
    position: absolute;
    overflow: visible;
    width: 21px;
    white-space: nowrap;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#OFFM_Single_Tour_reqoffevent_of {
    left: 0px;
    top: 177px;
    position: absolute;
    overflow: visible;
    width: 51px;
    white-space: nowrap;
    
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.3333333206176758px;
}
#OFFM_date_reqoffevent {
    left: 0px;
    top: 193px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#OFFM_online-shopping_reqoffeven {
    position: absolute;
    width: 169px;
    height: 168px;
    /* left: 30px; */
    /* top: 30px; */
    overflow: visible;
}
#OFFM_Group_131 {
    position: absolute;
    width: 27px;
    height: 27px;
    left: 2px;
    top: 3px;
    overflow: visible;
}
#OFFM_Ellipse_1 {
    fill: rgba(255,255,255,1);
}
.OFFM_Ellipse_1 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 45px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#OFFM_trophy_reqoffevent_off {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 3px;
    top: 4px;
    overflow: visible;
}
#OFFM_Group_130 {
    position: absolute;
    width: 24px;
    height: 15px;
    left: 30px;
    top: 9px;
    overflow: visible;
}
#OFFM_Rectangle_31 {
    fill: rgba(255,255,255,1);
}
.OFFM_Rectangle_31 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 42px;
    height: 33px;
    left: 0px;
    top: 0px;
}
#OFFM_n_234_reqoffevent_off {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 23px;
    white-space: nowrap;

    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#OFFM_Group_132 {
    position: absolute;
    width: 27px;
    height: 27px;
    left: 141px;
    top: 3px;
    overflow: visible;
}
#OFFM_Ellipse_2 {
    fill: rgba(255,255,255,1);
}
.OFFM_Ellipse_2 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 45px;
    height: 45px;
    left: 0px;
    top: 0px;
}
#OFFM_share_1_reqoffevent_off {
    position: absolute;
    width: 17px;
    height: 17px;
    left: 5px;
    top: 5px;
    overflow: visible;
}