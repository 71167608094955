.mediaViewInfo {
    --web-view-name: community req – 1;
    --web-view-id: community_req_Mob1;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: community_req_Mob1;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#community_req_Mob1 {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 220px);
    top: 150px;
    overflow: visible;
    --web-view-name: community req – 1;
    --web-view-id: community_req_Mob1;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#community_req_side_menu {
    position: absolute;
    width: 100%;
    height: calc(100% - 60px);
    overflow: hidden;
    --web-view-name: community req – 2;
    --web-view-id: community_req__2;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#req_overall_com_req_mob {
    position: absolute;
    width: 37.873px;
    height: 310.184px;
    right: 0px;
    top: 0.239px;
    overflow: visible;
}
#com_req_mob_side_bar {
    position: absolute;
    width: 37.873px;
    height: 127.822px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#com_req_bg_img_com_req_mob {
    position: absolute;
    width: calc(100% - 35px);
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#req_off_mem_grp_1 {
    position: absolute;
    width: 20.869px;
    height: 299.188px;
    left:9.56px;
    top: 10.996px;
    overflow: visible;
}
#com_off_req_txt_com_req_mob {
    transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,310.556,101.235) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#com_off_off_txt_com_req_mob {
    transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,326.556,210.3136) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#com_off_mem_txt_com_req_mob {
    transform: translate(-348.687px, -62.235px) matrix(1,0,0,1,325.6871,318.4227) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}

#mid_scroll_card {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 45px);
    height: calc(100% - 60px);
    left: 0px;
    top: 0px;
    overflow-x: hidden;
    overflow-y: auto;
}
#card_grp_over_all_mob {
    position: relative;
    width: 311.277px;
    height: 89px;
    left: 5%;
    top: 13px;
    overflow: visible;
}
#com_req_mob_card_1 {
    fill: rgba(255,255,255,1);
}
.com_req_mob_card_1 {
    filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 317.777px;
    height: 95.5px;
    left: 0px;
    top: 0px;
}
#com_req_mob_mob_pfp {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 34.702px;
    top: 11.408px;
    border-radius: 50%;
    overflow: hidden;
}
#com_req_mob_md_zaid {
    left: 2.495px;
    top: 62px;
    position: absolute;
    overflow: visible;
    width: 116px;
    height: 15px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    color: rgba(37,37,37,1);
}
#com_req_mob_design_grp {
    position: absolute;
    width: 117.404px;
    height: 28.566px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#com_req_mob_page_card_design {
    fill: rgba(255,235,133,1);
}
.com_req_mob_page_card_design {
    overflow: visible;
    position: absolute;
    width: 117.404px;
    height: 28.566px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#com_req_mob_minus {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 285.777px;
    top: 4.988px;
    overflow: visible;
}
#com_req_mob_budget_png {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 2.988px;
    overflow: visible;
}
#com_req_mob_delivery_png {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 32.397px;
    overflow: visible;
}
#com_req_mob_budget_txt {
    left: 168.5px;
    top: 7.988px;
    position: absolute;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#com_req_mob_dep_txt {
    left: 168.5px;
    top: 66.012px;
    position: absolute;
    overflow: visible;
    width: 113px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#com_req_mob_location_png {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 138.273px;
    top: 61.012px;
    overflow: visible;
}
#com_req_mob__timg_txt {
    left: 168.5px;
    top: 36.408px;
    position: absolute;
    overflow: visible;
    width: 51px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
