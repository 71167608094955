	.mediaViewInfo {
		--web-view-name: CycleHaven;
		--web-view-id: CycleHaven;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: CycleHaven;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#CycleHaven {
		position: absolute;
		width: 1366px;
		height: 3741px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(5,5,5,1);
		overflow: hidden;
		--web-view-name: CycleHaven;
		--web-view-id: CycleHaven;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#CHEllipse_45 {
		filter: blur(22px);
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHEllipse_45 {
		position: absolute;
		overflow: visible;
		width: 700px;
		height: 150px;
		left: 624px;
		top: 571px;
	}
	#CHAnime_Edit {
		position: absolute;
		width: 55px;
		height: 55px;
		left: 53px;
		top: 28px;
		overflow: visible;
	}
	#CHGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 498.998px;
		left: 95.5px;
		top: 3242.002px;
		overflow: visible;
	}
	#CHGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 125.997px;
		overflow: visible;
	}
	#CHGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.CHRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#CHsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#CHlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#CHmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CHmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#CHn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.CHn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.CHlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.CHtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#CHshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CHshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#CHwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHmed_shadow_space_website_7_bk {
		fill: rgba(255,255,255,1);
	}
	.CHmed_shadow_space_website_7_bk {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#CHn_234567890_mobile_number_shad {
		left: 703.5px;
		top: 28.001px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#CHaffanghanioperatelive_email_sh {
		left: 703.5px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#CHaddress_med_shadow_space_websi {
		left: 703.5px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 66px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#CHlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#CHemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#CHphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#CHn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#CHContact_Us {
		left: 485px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 205px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -12.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(255,255,255,1);
	}
	#CHCTA {
		position: absolute;
		width: 432px;
		height: 295px;
		left: 75px;
		top: 188px;
		overflow: visible;
	}
	#CHScroll_Group_92 {
		mix-blend-mode: normal;
		position: absolute;
		width: 432px;
		height: 91px;
		left: 0px;
		top: 204px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHScroll_Group_92::-webkit-scrollbar{
		display: none;
	}
	#CHLorem_ipsum_dolor_sit_amet_con {
		opacity: 0.6;
		left: 7px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 415px;
		height: 69px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#CHCompany_Name {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 286px;
		white-space: nowrap;
		line-height: 100px;
		margin-top: -18px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 64px;
		color: rgba(255,255,255,1);
	}
	#CHGroup_15154 {
		position: absolute;
		width: 168.915px;
		height: 45.743px;
		left: 64px;
		top: 516.5px;
		overflow: visible;
	}
	#CHPath_7783 {
		fill: transparent;
		stroke: rgba(81,232,239,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHPath_7783 {
		overflow: visible;
		position: absolute;
		width: 119.314px;
		height: 19.371px;
		left: 50.085px;
		top: 27px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHPath_7784 {
		fill: transparent;
		stroke: rgba(81,232,239,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHPath_7784 {
		overflow: visible;
		position: absolute;
		width: 119.314px;
		height: 19.371px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHBuy_Now {
		left: 47px;
		top: 12.5px;
		position: absolute;
		overflow: visible;
		width: 76px;
		white-space: nowrap;
		line-height: 100px;
		margin-top: -42.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		letter-spacing: 1.71px;
	}
	#CHwhite-bicycle-standing-park {
		position: absolute;
		width: 964px;
		height: 642.333px;
		left: 442px;
		top: 28px;
		overflow: visible;
	}
	#CHGroup_15039 {
		position: absolute;
		width: 1193px;
		height: 1695px;
		left: 87px;
		top: 796px;
		overflow: visible;
	}
	#CHGroup_15033 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHalvan-nee-EHILKZNmTuA-unsplash {
		filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHGroup_15038 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 600px;
		top: 38px;
		overflow: visible;
	}
	#CHScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#CHIt_is_a_long_established_fact_ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 265px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#CHVISION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHGroup_15034 {
		position: absolute;
		width: 1193px;
		height: 419.078px;
		left: 0px;
		top: 638px;
		overflow: visible;
	}
	#CHkaur-kristjan-miUC0b1IVYU-unsp {
		filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		position: absolute;
		width: 560px;
		height: 419.078px;
		left: 633px;
		top: 0px;
		overflow: visible;
	}
	#CHGroup_15037 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 0px;
		top: 39px;
		overflow: visible;
	}
	#CHScroll_Group_28_cd {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHScroll_Group_28_cd::-webkit-scrollbar{
		display: none;
	}
	#CHIt_is_a_long_established_fact__ce {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#CHMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHGroup_15035 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 1276px;
		overflow: visible;
	}
	#CHsaurav-kundu-H8QttyFgroY-unspl {
		filter: drop-shadow(0px 3px 20px rgba(47, 249, 249, 0.486));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHScroll_Group_28_ci {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 600px;
		top: 103px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHScroll_Group_28_ci::-webkit-scrollbar{
		display: none;
	}
	#CHIt_is_a_long_established_fact__cj {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#CHPRINCIPLES {
		left: 618px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHGroup_15032 {
		position: absolute;
		width: 1300px;
		height: 660px;
		left: 0px;
		top: 2640.999px;
		overflow: visible;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#CHScroll_Group_60 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1300px;
		height: 572.001px;
		left: 0px;
		top: 87.999px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#CHScroll_Group_60::-webkit-scrollbar{
		display: none;
	}
	#CHGroup_15031 {
		position: absolute;
		width: 485px;
		height: 385px;
		left: 28px;
		top: 27px;
		overflow: visible;
	}
	#CHRectangle_112 {
		fill: rgba(255,255,255,1);
	}
	.CHRectangle_112 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 503px;
		height: 403px;
		left: 0px;
		top: 0px;
	}
	#CHMask_Group_4 {
		position: absolute;
		width: 460px;
		height: 277px;
		left: 13px;
		top: 12px;
		overflow: visible;
	}
	#CHCycle {
		left: 11px;
		top: 343px;
		position: absolute;
		overflow: visible;
		width: 463px;
		height: 30px;
		text-align: left;
		font-family: Sen;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#CHGroup_9694 {
		position: absolute;
		width: 370px;
		height: 26px;
		left: 11px;
		top: 303px;
		overflow: visible;
	}
	#CHBangalore {
		opacity: 0.5;
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 26px;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#CHgeo-alt {
		position: absolute;
		width: 17px;
		height: 22.667px;
		left: 0px;
		top: 2px;
		overflow: visible;
	}
	#CHPath_2927 {
		fill: rgba(254,120,71,1);
	}
	.CHPath_2927 {
		overflow: visible;
		position: absolute;
		width: 17px;
		height: 22.667px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHOur_Collection {
		filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 543px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		color: rgba(255,255,255,1);
	}
