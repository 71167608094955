.mediaViewInfo {
  --web-view-name: top part calender;
  --web-view-id: top_part_calender;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: top_part_calender;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#mb_top_part_calender {
  position: absolute;
  width: 100vw;
  height: calc(100vh - 140px);
  top: 75px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  --web-view-name: top part calender;
  --web-view-id: top_part_calender;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
#mb_com_tata_next_grp_mob {
  position: absolute;
  width: 339.454px;
  height: 51.5px;
  left: 20.773px;
  top: 20.5px;
  overflow: visible;
}
#mb_completed_grp_calender_mob {
  position: absolute;
  width: 146px;
  height: 21px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#mb_Completed_lbbl_calender_mob {
  left: 12px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 85px;
  white-space: nowrap;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#mb_count_bdr_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(166, 210, 170, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.mb_count_bdr_mob {
  position: absolute;
  overflow: visible;
  width: 40px;
  height: 21px;
  left: 106px;
  top: 0px;
}
#mb_n_0_count_calender_mob {
  left: 118px;
  top: 1px;
  position: absolute;
  overflow: visible;
  width: 17px;
  white-space: nowrap;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#mb_completed_staus_calender_mob {
  fill: rgba(46, 243, 65, 1);
}
.mb_completed_staus_calender_mob {
  position: absolute;
  overflow: visible;
  width: 7px;
  height: 7px;
  left: 0px;
  top: 7px;
}
#mb_next_up_calender_mobgrp {
  position: absolute;
  width: 339.454px;
  height: 21.5px;
  left: 0px;
  top: 30px;
  overflow: visible;
}
#mb_Next_Up_lbl_calender_mob {
  left: 15.454px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 57px;
  white-space: nowrap;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#mb_n_0_bdr_next_up_calender_mob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(166, 210, 170, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.mb_n_0_bdr_next_up_calender_mob {
  position: absolute;
  overflow: visible;
  width: 69px;
  height: 21px;
  left: 77px;
  top: 0px;
}
#mb_arrow_next_up_mob {
  fill: rgba(155, 99, 252, 1);
}
.mb_arrow_next_up_mob {
  overflow: visible;
  position: absolute;
  width: 8px;
  height: 8px;
  transform: translate(-0.046px, 0px) matrix(1, 0, 0, 1, 1.5, 7) rotate(90deg);
  transform-origin: center;
  left: 0px;
  top: 0px;
}
#mb_next_up_arrow_line_mob {
  fill: transparent;
  stroke: rgba(155, 99, 252, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.mb_next_up_arrow_line_mob {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 8px;
  left: 0px;
  top: 7px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#mb_Next_Up_lbl_calender_mob_c {
  left: 82.454px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 57px;
  white-space: nowrap;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#mb_Next_Up_lbl_calender_mob_da {
  left: 146.454px;
  top: 0.5px;
  position: absolute;
  overflow: visible;
  width: 194px;
  height: 21px;
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#mb_todo_grp_calender_mob {
  position: absolute;
  width: 121px;
  height: 21px;
  left: 151px;
  top: 0px;
  overflow: visible;
}
#mb_To-Do_lbl_calender_mob {
  left: 0px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 79px;
  white-space: nowrap;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}
#mb_n_0_bdr_calendemob {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(166, 210, 170, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.mb_n_0_bdr_calendemob {
  position: absolute;
  overflow: visible;
  width: 40px;
  height: 21px;
  left: 81px;
  top: 0px;
}
#mb_n_0_calender_mob {
  left: 95px;
  top: 1px;
  position: absolute;
  overflow: visible;
  width: 13px;
  white-space: nowrap;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
}
#mb_Icon_feather-menu_mob {
  position: absolute;
  width: 27px;
  height: 18px;
  right: 20px;
  top: 21.5px;
  overflow: visible;
}
#mb_Path_6953_mobcal {
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.mb_Path_6953_mobcal {
  overflow: visible;
  position: absolute;
  width: 30px;
  height: 3px;
  left: 0px;
  top: 9px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#mb_Path_6954_mobcal {
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.mb_Path_6954_mobcal {
  overflow: visible;
  position: absolute;
  width: 30px;
  height: 3px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#mb_Path_6955_mobcal {
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.mb_Path_6955_mobcal {
  overflow: visible;
  position: absolute;
  width: 30px;
  height: 3px;
  left: 0px;
  top: 18px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
