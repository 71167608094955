	.mediaViewInfo {
		--web-view-name: Journey_Junkies;
		--web-view-id: Journey_Junkies;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Journey_Junkies;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Journey_Junkies {
		position: absolute;
		width: 1366px;
		height: 4086px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Journey_Junkies;
		--web-view-id: Journey_Junkies;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#Jrny_Jnks_Rectangle_682_cb {
		fill: url(#Rectangle_682_cb);
	}
	.Jrny_Jnks_Rectangle_682_cb {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 786px;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_path_3 {
		opacity: 0.19;
		fill: rgba(55,143,50,1);
	}
	.Jrny_Jnks_path_3 {
		overflow: visible;
		position: absolute;
		width: 477.724px;
		height: 462.343px;
		transform: matrix(1,0,0,1,95.5598,127.4256) rotate(54deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_path_2 {
		opacity: 0.3;
		fill: rgba(55,143,50,1);
	}
	.Jrny_Jnks_path_2 {
		overflow: visible;
		position: absolute;
		width: 477.724px;
		height: 462.343px;
		transform: matrix(1,0,0,1,95.5596,127.4255) rotate(15deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_path_1 {
		position: absolute;
		width: 462.891px;
		height: 447.988px;
		left: 102.731px;
		top: 134.244px;
		overflow: visible;
	}
	#Jrny_Jnks_explore_Unwind_Discover {
		filter: drop-shadow(0px 16px 21px rgba(0, 0, 0, 0.161));
		left: 652.246px;
		top: 101px;
		position: absolute;
		overflow: visible;
		width: 202px;
		white-space: nowrap;
		text-align: left;
		font-family: Square721 BT;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#Jrny_Jnks_Scroll_Group_19 {
		mix-blend-mode: normal;
		position: absolute;
		width: 502px;
		height: 119px;
		left: 639.246px;
		top: 332px;
		overflow: hidden;
	}
	#Jrny_Jnks_Lorem_ipsum_dolor_sit_amet_con {
		left: 14px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 487px;
		height: 167px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(125,125,125,1);
	}
	#Jrny_Jnks_Ellipse_1 {
		fill: rgba(255,136,0,1);
	}
	.Jrny_Jnks_Ellipse_1 {
		position: absolute;
		overflow: visible;
		width: 22px;
		height: 21px;
		left: 104.246px;
		top: 210px;
	}
	#Jrny_Jnks_Line_1 {
		fill: transparent;
		stroke: rgba(13,146,6,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Jrny_Jnks_Line_1 {
		overflow: visible;
		position: absolute;
		width: 4px;
		height: 172.795px;
		left: 625.23px;
		top: 331.407px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_joaquin-3VK6Urf2vE8-unsplash {
		filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 231px;
		height: 120px;
		left: 625.246px;
		top: 548px;
		overflow: visible;
	}
	#Jrny_Jnks_ammie-ngo-vcu-OZBxxRk-unsplash {
		filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 231px;
		height: 120px;
		left: 866.246px;
		top: 548px;
		overflow: visible;
	}
	#Jrny_Jnks_daniel-burka-facU72FcKBI-unspl {
		filter: drop-shadow(5px 6px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 231px;
		height: 120px;
		left: 1107.246px;
		top: 548px;
		overflow: visible;
	}
	#Jrny_Jnks_Group_4 {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 1122px;
		top: 610px;
		overflow: visible;
	}
	#Jrny_Jnks_Ellipse_2 {
		opacity: 0.79;
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Ellipse_2 {
		filter: drop-shadow(5px 0px 7px rgba(0, 0, 0, 0.529));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_Icon_feather-arrow-down-right {
		transform: translate(0.411px, -0.412px) matrix(1,0,0,1,10.1247,16.2582) rotate(-45deg);
		transform-origin: center;
		position: absolute;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_Path_2_cq {
		fill: transparent;
		stroke: rgba(13,146,6,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Jrny_Jnks_Path_2_cq {
		overflow: visible;
		position: absolute;
		width: 9.899px;
		height: 9.9px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_Group_5 {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 7.246px;
		top: 610px;
		overflow: visible;
	}
	#Jrny_Jnks_Ellipse_3 {
		opacity: 0.79;
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Ellipse_3 {
		filter: drop-shadow(0px 7px 20px rgba(0, 0, 0, 0.271));
		width: 38px;
		height: 38px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.09px, -0.412px) matrix(1,0,0,1,0.0903,0.4121) rotate(90deg);
		transform-origin: center;
	}
	#Jrny_Jnks_Group_1 {
		position: absolute;
		width: 16.469px;
		height: 16.469px;
		left: 10.67px;
		top: 10.348px;
		overflow: visible;
	}
	#Jrny_Jnks_Path_1_cu {
		fill: transparent;
		stroke: rgba(120,147,118,1);
		stroke-width: 3px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Jrny_Jnks_Path_1_cu {
		overflow: visible;
		position: absolute;
		width: 11.645px;
		height: 11.645px;
		transform: translate(0px, 0px) matrix(1,0,0,1,2.4118,2.4118) rotate(45deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_Path_2_cv {
		fill: transparent;
		stroke: rgba(120,147,118,1);
		stroke-width: 3px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Jrny_Jnks_Path_2_cv {
		overflow: visible;
		position: absolute;
		width: 7.793px;
		height: 7.793px;
		transform: translate(0px, 0px) matrix(1,0,0,1,4.3382,7.0624) rotate(45deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_Group_14866:hover {
		width: 135px;
		height: 42px;
		top: 462px;
	}
	#Jrny_Jnks_Group_14866 {
		position: absolute;
		width: 129px;
		height: 39px;
		left: 648px;
		top: 466px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#Jrny_Jnks_Rectangle_2_c {
		fill: rgba(255,136,0,1);
	}
	.Jrny_Jnks_Rectangle_2_c {
		position: absolute;
		overflow: visible;
		width: 129px;
		height: 39px;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_Check_Out {
		left: 23px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 84px;
		white-space: nowrap;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#Jrny_Jnks_Group_3 {
		position: absolute;
		width: 128px;
		height: 38px;
		left: 982.247px;
		top: 610px;
		overflow: visible;
	}
	#Jrny_Jnks_Rectangle_3 {
		opacity: 0.79;
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Rectangle_3 {
		filter: drop-shadow(5px 0px 7px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 149px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_See_all_photos {
		left: 16px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(13,146,6,1);
	}
	#Jrny_Jnks_Ellipse_4 {
		opacity: 0.79;
		fill: rgba(255,255,255,1);
		stroke: rgba(13,146,6,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Jrny_Jnks_Ellipse_4 {
		width: 38px;
		height: 38px;
		position: absolute;
		overflow: visible;
		transform: matrix(1,0,0,1,7.2461,251) rotate(90deg);
		transform-origin: center;
	}
	#Jrny_Jnks_Ellipse_5 {
		opacity: 0.79;
		fill: rgba(255,255,255,1);
		stroke: rgba(13,146,6,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Jrny_Jnks_Ellipse_5 {
		width: 38px;
		height: 38px;
		position: absolute;
		overflow: visible;
		transform: matrix(1,0,0,1,7.2461,308) rotate(90deg);
		transform-origin: center;
	}
	#Jrny_Jnks_Icon_awesome-facebook-f {
		fill: rgba(0,0,0,1);
	}
	.Jrny_Jnks_Icon_awesome-facebook-f {
		overflow: visible;
		position: absolute;
		width: 10.87px;
		height: 20.295px;
		left: 20.448px;
		top: 260.2px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_Icon_metro-twitter {
		fill: rgba(0,0,0,1);
	}
	.Jrny_Jnks_Icon_metro-twitter {
		overflow: visible;
		position: absolute;
		width: 19.525px;
		height: 15.868px;
		left: 16.65px;
		top: 319.064px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_Rectangle_5 {
		fill: rgba(120,147,118,1);
	}
	.Jrny_Jnks_Rectangle_5 {
		filter: drop-shadow(5px 14px 15px rgba(0, 0, 0, 0.18));
		position: absolute;
		overflow: visible;
		width: 83px;
		height: 83px;
		left: 7.246px;
		top: 373px;
	}
	#Jrny_Jnks_Icon_simple-whatsapp {
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Icon_simple-whatsapp {
		overflow: visible;
		position: absolute;
		width: 22.786px;
		height: 22.786px;
		left: 15.02px;
		top: 381.094px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_leaf {
		position: absolute;
		width: 383.232px;
		height: 383.232px;
		left: 1013.884px;
		top: -24.635px;
		overflow: visible;
	}
	#Jrny_Jnks_Group_14870 {
		position: absolute;
		width: 820px;
		height: 451px;
		left: 273px;
		top: 786px;
		overflow: visible;
	}
	#Jrny_Jnks_Rectangle_683 {
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Rectangle_683 {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 746px;
		height: 358px;
		left: 92px;
		top: 56px;
	}
	#Jrny_Jnks_pietro-de-grandi-T7K4aEPoGGk-u {
		position: absolute;
		width: 334px;
		height: 451px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_VISION {
		left: 389px;
		top: 89px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#Jrny_Jnks_Lorem_ipsum_dolor_sit_amet_con_dj {
		left: 389px;
		top: 156px;
		position: absolute;
		overflow-x: hidden;
		overflow-y: scroll;

		width: 380px;
		height: 197px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#Jrny_Jnks_Group_14869 {
		position: absolute;
		width: 100%;
		height: 464px;
		/* left: 262px; */
		top: 1337px;
		overflow: visible;
	}
	#Jrny_Jnks_Rectangle_684 {
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Rectangle_684 {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 766px;
		height: 367px;
		left: 0px;
		top: 58px;
	}
	#Jrny_Jnks_jake-blucker-tMzCrBkM99Y-unspl_dq {
		position: absolute;
		width: 344px;
		height: 464px;
		left: 499px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_MISSION {
		left: 54px;
		top: 83px;
		position: absolute;
		overflow: visible;
		width: 167px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 39px;
		color: rgba(0,0,0,1);
	}
	#Jrny_Jnks_Lorem_ipsum_dolor_sit_amet_con_ds {
		left: 54px;
		top: 160px;
		position: absolute;
		overflow-x: hidden;
		overflow-y: scroll;
		width: 391px;
		height: 203px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#Jrny_Jnks_Rectangle_685 {
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Rectangle_685 {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 766px;
		height: 367px;
		left: 0px;
		top: 58px;
	}
	#Jrny_Jnks_jake-blucker-tMzCrBkM99Y-unspl_dq {
		position: absolute;
		width: 344px;
		height: 464px;
		left: 499px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_MISSION_dr {
		left: 54px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 133px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#Jrny_Jnks_Lorem_ipsum_dolor_sit_amet_con_ds {
		left: 54px;
		top: 160px;
		position: absolute;
		overflow-x: hidden;
		overflow-y: scroll;
		width: 391px;
		height: 203px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}
	#Jrny_Jnks_Group_14868 {
		position: absolute;
		width: 843px;
		height: 464px;
		left: 262px;
		top: 1901px;
		overflow: visible;
	}
	#Jrny_Jnks_Rectangle_686 {
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Rectangle_686 {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 766px;
		height: 368px;
		left: 95px;
		top: 58px;
	}
	#Jrny_Jnks_tom-barrett-M0AWNxnLaMw-unspla {
		position: absolute;
		width: 344px;
		height: 464px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_PRINCIPLES {
		left: 399px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 173px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#Jrny_Jnks_Lorem_ipsum_dolor_sit_amet_con_dx {
		left: 399px;
		top: 160px;
		position: absolute;
		overflow-x : hidden;
		overflow-y : scroll;
		width: 391px;
		height: 203px;
		text-align: left;
		font-family: Source Sans Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(125,125,125,1);
	}

	#Jrny_Jnks_Group_14871 {
		position: absolute;
		width: 100%;
		height: 525px;
		left: 0px;
		top: 1460px;
		overflow: visible;
	}
	#Jrny_Jnks_taneli-lahtinen-2gJJrH4OnNk-un {
		position: absolute;
		width: 100%;
		height: 525px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_Scroll_Group_23 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 427px;
		/* left: 71px; */
		top: 60px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#Jrny_Jnks_Group_14872 {
		position: relative;
		width: 250px;
		height: 350px;
		left: 41px;
		top: 28px;
		margin-right: 50px;
		overflow: visible;
	}
	#Jrny_Jnks_Rectangle_688 {
		fill: rgba(255,255,255,1);
	}
	.Jrny_Jnks_Rectangle_688 {
		position: absolute;
		overflow: visible;
		width: 250px;
		height: 350px;
		left: 0px;
		top: 0px;
	}
	#Jrny_Jnks_dariusz-sankowski-3OiYMgDKJ6k- {
		position: absolute;
		width: 220px;
		height: 296px;
		left: 15px;
		top: 43px;
		overflow: visible;
	}
	#Jrny_Jnks_Travel_One {
		left: 15px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 221px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}

	#Jrny_Jnks_FIND_POPULAR_DESTINATION_ {
		left: 450px;
		top: 2619px;
		position: absolute;
		overflow: visible;
		width: 468px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 31px;
		color: rgba(0,0,0,1);
	}
	#Jrny_Jnks_Line_28 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Jrny_Jnks_Line_28 {
		overflow: visible;
		position: absolute;
		width: 1269px;
		height: 2px;
		left: 48.5px;
		top: 3524.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 791px;
		top: 3899px;
		overflow: visible;
	}
	#Jrny_Jnks_linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_med_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.Jrny_Jnks_med_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Jrny_Jnks_med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Jrny_Jnks_med_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#Jrny_Jnks_linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.191px;
		overflow: visible;
	}
	#Jrny_Jnks_n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.Jrny_Jnks_n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.Jrny_Jnks_linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.Jrny_Jnks_top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Jrny_Jnks_Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_shadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.Jrny_Jnks_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Jrny_Jnks_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Jrny_Jnks_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#Jrny_Jnks_instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#Jrny_Jnks_whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_med_shadow_space_website_7_ej:hover {
		fill: rgba(50,172,158,1);
	}
	.Jrny_Jnks_med_shadow_space_website_7_ej:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Jrny_Jnks_med_shadow_space_website_7_ej {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Jrny_Jnks_med_shadow_space_website_7_ej {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#Jrny_Jnks_whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#Jrny_Jnks_Group_14873 {
		position: absolute;
		width: 638px;
		height: 712px;
		left: 49px;
		top: 3639px;
		overflow: visible;
	}
	#Jrny_Jnks_kisspng-billboard-advertising- {
		position: absolute;
		width: 638px;
		height: 712px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Jrny_Jnks_Rectangle_689 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Jrny_Jnks_Rectangle_689 {
		position: absolute;
		overflow: visible;
		width: 617px;
		height: 286px;
		left: 11px;
		top: 44px;
	}
	#Jrny_Jnks_n_234567890_mobile_number_shad {
		left: 826px;
		top: 3640px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Jrny_Jnks_affanghanioperatelive_email_sh {
		left: 831px;
		top: 3700px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Jrny_Jnks_address_med_shadow_space_websi {
		left: 826px;
		top: 3759px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Jrny_Jnks_location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 791px;
		top: 3759px;
		overflow: visible;
	}
	#Jrny_Jnks_email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 791px;
		top: 3699px;
		overflow: visible;
	}
	#Jrny_Jnks_phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 791px;
		top: 3639px;
		overflow: visible;
	}
	#Jrny_Jnks_n_Copyright_Operate_Live_OPC_P {
		left: 791px;
		top: 3984px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(173,173,173,1);
	}