#overall_my_offering_req_page {
    position: absolute;
    width: 147px;
    height: calc(100% - 60px);
    right: 0px;
    top: 55px;
    overflow: visible;
}
#my_offering_right_full_bdr_b {
    fill: url(#my_offering_right_full_bdr_b);
}
.my_offering_right_full_bdr_b {
    position: absolute;
    overflow: visible;
    width: 147px;
    height: 100%;
    left: 0px;
    top: 0px;
}
#card_offering_right_side {
    position: relative;
    width: 133px;
    height: 133px;
    left: 7px;
    top: 55px;
    margin-top: 5px;
    overflow: visible;
}
#card_bdr_req_page_b {
    fill: url(#card_bdr_req_page_b);
}
.card_bdr_req_page_b {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 151px;
    height: 151px;
    left: 0px;
    top: 0px;
}
#INR_300000__29_Sep_req {
    left: 0px;
    top: 115px;
    position: absolute;
    overflow: visible;
    width: 134px;
    height: 16px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(37,37,37,1);
}
#zain_pic_req {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 27px;
    top: 5px;
    object-fit: fill;
    border-radius: 5px;
    overflow: hidden;
}
#minus_2_card_req {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 113px;
    top: 1px;
    overflow: visible;
}
#Website_Development_marquee_re {
    left: 6px;
    top: 92px;
    position: absolute;
    overflow: hidden;
    width: 122px;
    height: 20px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#my_offering_btn_req_page {
    position: absolute;
    width: 139px;
    height: 40px;
    left: 4px;
    top: 4px;
    cursor: pointer;
    overflow: visible;
    border-radius: 50px;
}
#offering_req_page {
    fill: rgba(255,255,255,1);
}
.offering_req_page {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 157px;
    height: 58px;
    left: 0px;
    top: 0px;
}
#My_Offerings_lbl_req_page {
    left: 10px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(37,37,37,1);
}
#plus_img_req_page {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 108px;
    top: 10px;
    overflow: visible;
}

@keyframes backInLeft {
    0% {
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
    }
    
    80% {
    transform: translateX(0px) scale(0.7);
    opacity: 0.7;
    }
    
    100% {
    transform: scale(1);
    opacity: 1;
    }
    }
    
    .backInLeft {
    animation: backInLeft 1s;
    }