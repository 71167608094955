.mediaViewInfo {
    --web-view-name: suggestions;
    --web-view-id: suggestions;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: suggestions;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#suggestions_Pc_page {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 60px);
    top:60px;
    left:120px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: suggestions;
    --web-view-id: suggestions;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#suggestion_bg_image {
    opacity: 0.77;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: scroll;
}
#PC_Suggestions_heading {
    left: 62px;
    top: 40px;
    position: absolute;
    overflow: visible;
    width: 109px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#suggestions_Line_1 {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.suggestions_Line_1 {
    overflow: visible;
    position: absolute;
    width: 125px;
    height: 1px;
    left: 62.5px;
    top: 81.5px;
    transform: matrix(1,0,0,1,0,0);
}
#card_suggestion_pc {
    position: relative;
    width: 359px;
    height: 209px;
    left: 63px;
    top: 96px;
    overflow-y: scroll;
    overflow: visible;
}
#border_btn_ignore {
    fill: rgba(255,255,255,1);
}
.border_btn_ignore {
    position: absolute;
    overflow: visible;
    width: 19px;
    height: 23px;
    left: 335px;
    top: 4px;
}
#suggestions_card_ {
    fill: rgba(255,255,255,1);
}
.suggestions_card_ {
    position: absolute;
    overflow: visible;
    width: 359px;
    height: 209px;
    left: 0px;
    top: 0px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
#suggestions_pfp {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 16.5px;
    top: 23.5px;
    border-radius: 50%;
    overflow: hidden;
}
#suggestion_info_grp {
    position: absolute;
    width: 248px;
    height: 40px;
    left: 87px;
    top: 58.5px;
    overflow: visible;
}
#suggestion_profession {
    left: 27px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 77px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#suggestion_Connected_On {
  
    top: 30px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#suggestion_SD {
    left: 118px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 131px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#suggestion_11-02-2020 {
    left: 118px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#suggestion_Scroll_Group_1 {
    mix-blend-mode: normal;
    position: absolute;
    width: 258px;
    height: 49px;
    left: 87px;
    top: 107px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#scrolable_data_suggestions {
    left: 4px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 213px;
    height: 180px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#suggestions_name_box {
    fill: rgba(219,242,231,1);
}
.suggestions_name_box {
    position: absolute;
    overflow: visible;
    width: 220px;
    height: 30px;
    left: 114px;
    top: 26px;
}
#suggestions_pfp_name_pc {
    left: 125.237px;
    top: 30px;
    position: absolute;
    overflow: visible;
    width: 137px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    border-radius: 50%;
    color: rgba(112,112,112,1);
}
#suggestion_card_button_grp {
    position: absolute;
    width: 204px;
    height: 36px;
    left: 112px;
    top: 162px;
    overflow: visible;
}
#suggestion_round_1 {
    fill: rgba(219,242,231,1);
}
.suggestion_round_1 {
    position: absolute;
    overflow: visible;
    width: 36px;
    height: 36px;
    left: 0px;
    top: 0px;
    border-radius:  9px;
    cursor: pointer;
    background:  #e0e0e0;
    box-shadow:   2px 2px 5px #9f9f9f,-2px -2px 5px #ffffff;
}
#suggestion_link_img {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 5px;
    top: 5px;
    cursor: pointer;
    overflow: visible;
}
#suggestion_round_2 {
    fill: rgba(219,242,231,1);
}
.suggestion_round_2 {
    position: absolute;
    overflow: visible;
    width: 36px;
    height: 36px;
    left: 56px;
    top: 0px;
    border-radius:  9px;
    cursor: pointer;
    background:  #e0e0e0;
    box-shadow:   2px 2px 5px #9f9f9f,-2px -2px 5px #ffffff;
}
#suggestion_follow_img {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 61px;
    top: 5px;
    cursor: pointer;
    overflow: visible;
}
#suggestion_round_3 {
    fill: rgba(219,242,231,1);
}
.suggestion_round_3 {
    position: absolute;
    overflow: visible;
    width: 36px;
    height: 36px;
    left: 112px;
    top: 0px;
    border-radius:  9px;
    cursor: pointer;
    background:  #e0e0e0;
    box-shadow:   2px 2px 5px #9f9f9f,-2px -2px 5px #ffffff;
}
#suggestion_prohibition_img {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 117px;
    top: 5px;
    cursor: pointer;
    overflow: visible;
}
#suggestion_final_round {
    fill: rgba(219,242,231,1);
}
.suggestion_final_round {
    position: absolute;
    overflow: visible;
    width: 36px;
    height: 36px;
    left: 168px;
    top: 0px;
    cursor: pointer;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   2px 2px 5px #9f9f9f,-2px -2px 5px #ffffff;
}
#suggestion_user_img {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 173px;
    top: 5px;
    cursor: pointer;
    overflow: visible;
}