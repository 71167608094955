.mediaViewInfo {
    --web-view-name: Offerings page mob;
    --web-view-id: Offerings_page_mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: Offerings_page_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#Offerings_page_mob {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 185px);
    top: 45px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: Offerings page mob;
    --web-view-id: Offerings_page_mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#offering_bg_img {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 140px);
    left: 0px;
    top: 0px;
    overflow: visible;
}
#My_Offerings_name_tag {
    left: 18px;
    top: 30px;
    position: absolute;
    overflow: visible;
    width: 116px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(4,4,4,1);
}

#offering_switch_new {
    fill: rgba(255,255,255,1);
}

.offering_switch_new {
    position: absolute;
    overflow: visible;
    width: 34px;
    height: 13px;
    right: 80px;
    top: 20px;
}

