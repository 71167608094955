	.mediaViewInfo {
		--web-view-name: GamersHubMob;
		--web-view-id: GamersHubMob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: GamersHubMob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#GamersHubMob {
		position: absolute;
		width: 390px;
		height: 4458px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: GamersHubMob;
		--web-view-id: GamersHubMob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#GamersHub_16_mobGroup_14998 {
		position: absolute;
		width: 373.628px;
		height: 1209.316px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobMask_Group_1 {
		position: absolute;
		width: 154px;
		height: 154px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobefeb0ad6-be8c-4667-ab83-307500 {
		position: absolute;
		width: 44px;
		height: 44px;
		left: 92px;
		top: 21px;
		border-radius: 50%;
		overflow: hidden;
	}
	#GamersHub_16_mobCompany_Name {
		left: 147px;
		top: 31px;
		position: absolute;
		overflow: visible;
		width: 129px;
		white-space: nowrap;
		text-align: left;
		font-family: Headliner No 45;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobGroup_14986 {
		position: absolute;
		width: 358.878px;
		height: 275.59px;
		left: 14.75px;
		top: 196px;
		overflow: visible;
	}
	#GamersHub_16_mobPLAY_GAME_EVERYDAY_ALWAYS_BE_W {
		opacity: 1;
		left: 45.083px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 239px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobCreate_your_own_game_platform_ {
		transform: translate(-52.5px, -223px) matrix(1,0,0,1,-27.4341,403.8352) rotate(-90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 176px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobLine_38 {
		fill: transparent;
		stroke: rgba(92,35,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_38 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 43.809px;
		left: 0px;
		top: 121.494px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobLine_39 {
		fill: transparent;
		stroke: rgba(255,12,12,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_39 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 71.827px;
		left: 0px;
		top: 203.509px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobflorian-olivo-Mf23RF8xArY-unsp {
		position: absolute;
		width: 276.1px;
		height: 180.331px;
		left: 45.083px;
		top: 95.259px;
		overflow: visible;
	}
	#GamersHub_16_mobPath_6953 {
		fill: rgba(0,0,0,0);
		stroke: rgba(92,35,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobPath_6953 {
		overflow: visible;
		position: absolute;
		width: 0px;
		height: 43.809px;
		transform: translate(-52.5px, -223px) matrix(1,0,0,1,341.3345,282.8555) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_mobLine_41 {
		fill: transparent;
		stroke: rgba(255,12,12,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_41 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 71.827px;
		transform: translate(-52.5px, -223px) matrix(1,0,0,1,261.8668,268.8468) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_mobGroup_14980 {
		position: absolute;
		width: 27.508px;
		height: 27.508px;
		left: 331.37px;
		top: 248.082px;
		overflow: visible;
	}
	#GamersHub_16_mobEllipse_2 {
		fill: rgba(35,33,38,1);
	}
	.GamersHub_16_mobEllipse_2 {
		position: absolute;
		overflow: visible;
		width: 27.508px;
		height: 27.508px;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_mobPolygon_1 {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_mobPolygon_1 {
		overflow: visible;
		position: absolute;
		width: 6.521px;
		height: 5.589px;
		transform: translate(0px, 0px) matrix(1,0,0,1,10.3862,10.8519) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_mobGroup_14987 {
		position: absolute;
		width: 347.25px;
		height: 667.816px;
		left: 21px;
		top: 541.5px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14969 {
		position: absolute;
		width: 142.187px;
		height: 298.629px;
		left: 1.069px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobRectangle_718 {
		filter: blur(50px);
		fill: rgba(255,68,87,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobRectangle_718 {
		position: absolute;
		overflow: visible;
		width: 440px;
		height: 473px;
		left: 2.181px;
		top: 125.5px;
	}
	#GamersHub_16_mobRectangle_713 {
		fill: rgba(255,68,87,1);
	}
	.GamersHub_16_mobRectangle_713 {
		position: absolute;
		overflow: visible;
		width: 25px;
		height: 25px;
		left: 117.181px;
		top: 72.5px;
	}
	#GamersHub_16_mobConsole {
		left: 13.181px;
		top: 75.5px;
		position: absolute;
		overflow: visible;
		width: 53px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(236,58,74,1);
	}
	#GamersHub_16_mobLine_30 {
		fill: transparent;
		stroke: rgba(255,36,36,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_30 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 250.164px;
		left: 0.356px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobLine_31 {
		fill: transparent;
		stroke: rgba(255,36,36,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_31 {
		overflow: visible;
		position: absolute;
		width: 3.426px;
		height: 168.931px;
		left: 0.356px;
		top: 81.027px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobpexels-anthony--139038 {
		position: absolute;
		width: 142.188px;
		height: 199.561px;
		left: 0px;
		top: 99.068px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14968 {
		position: absolute;
		width: 143px;
		height: 298.5px;
		left: 204.25px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobRectangle_719 {
		filter: blur(17px);
		fill: rgba(19,16,27,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobRectangle_719 {
		position: absolute;
		overflow: visible;
		width: 245px;
		height: 296px;
		left: 0px;
		top: 104.5px;
	}
	#GamersHub_16_mobSwitch {
		left: 15px;
		top: 75.5px;
		position: absolute;
		overflow: visible;
		width: 44px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(33,27,33,1);
	}
	#GamersHub_16_mobRectangle_714 {
		fill: rgba(25,22,33,1);
	}
	.GamersHub_16_mobRectangle_714 {
		position: absolute;
		overflow: visible;
		width: 25px;
		height: 25px;
		left: 118px;
		top: 72.5px;
	}
	#GamersHub_16_mobLine_32 {
		fill: transparent;
		stroke: rgba(36,24,34,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_32 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 250.164px;
		left: 0.834px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobLine_33 {
		fill: transparent;
		stroke: rgba(55,114,54,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_33 {
		overflow: visible;
		position: absolute;
		width: 3.426px;
		height: 168.931px;
		left: 0.834px;
		top: 81.25px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobpexels-pixabay-371924 {
		position: absolute;
		width: 143px;
		height: 199px;
		left: 0px;
		top: 99.5px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14981 {
		position: absolute;
		width: 143.256px;
		height: 324.643px;
		left: 0px;
		top: 343.173px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14970 {
		position: absolute;
		width: 1.425px;
		height: 250.164px;
		left: 0px;
		top: 74.479px;
		overflow: visible;
	}
	#GamersHub_16_mobLine_34 {
		fill: transparent;
		stroke: rgba(202,222,228,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_34 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 250.164px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobLine_35 {
		fill: transparent;
		stroke: rgba(202,222,228,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_35 {
		overflow: visible;
		position: absolute;
		width: 3.426px;
		height: 168.931px;
		left: 0px;
		top: 81.25px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobRectangle_720 {
		filter: blur(30px);
		fill: rgba(4,104,118,1);
	}
	.GamersHub_16_mobRectangle_720 {
		position: absolute;
		overflow: visible;
		width: 323px;
		height: 371px;
		left: 0.25px;
		top: 8.327px;
	}
	#GamersHub_16_mobpexels-harsch-shivam-2007647 {
		position: absolute;
		width: 142.543px;
		height: 199.561px;
		left: 0.713px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobVR {
		left: 9.25px;
		top: 205.327px;
		position: absolute;
		overflow: visible;
		width: 20px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(4,104,118,1);
	}
	#GamersHub_16_mobRectangle_715 {
		fill: rgba(4,104,118,1);
	}
	.GamersHub_16_mobRectangle_715 {
		position: absolute;
		overflow: visible;
		width: 25px;
		height: 25px;
		left: 118.25px;
		top: 202.327px;
	}
	#GamersHub_16_mobIcon_ionic-ios-arrow-back {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_mobIcon_ionic-ios-arrow-back {
		overflow: visible;
		position: absolute;
		width: 6.684px;
		height: 11.692px;
		left: 127.62px;
		top: 209.038px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobGroup_14982 {
		position: absolute;
		width: 143px;
		height: 324.643px;
		left: 204.25px;
		top: 343.173px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14971 {
		position: absolute;
		width: 1.425px;
		height: 250.164px;
		left: 0.122px;
		top: 74.479px;
		overflow: visible;
	}
	#GamersHub_16_mobLine_36 {
		fill: transparent;
		stroke: rgba(149,179,48,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_36 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 250.164px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobLine_37 {
		fill: transparent;
		stroke: rgba(149,179,48,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_37 {
		overflow: visible;
		position: absolute;
		width: 3.426px;
		height: 168.931px;
		left: 0px;
		top: 81.25px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobRectangle_721 {
		filter: blur(42px);
		fill: rgba(110,134,41,1);
	}
	.GamersHub_16_mobRectangle_721 {
		position: absolute;
		overflow: visible;
		width: 395px;
		height: 443px;
		left: 0px;
		top: 8.327px;
	}
	#GamersHub_16_mobpexels-karol-d-841228 {
		position: absolute;
		width: 142.544px;
		height: 199.561px;
		left: 0.122px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobKeyboard {
		left: 10px;
		top: 205.327px;
		position: absolute;
		overflow: visible;
		width: 64px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(110,134,41,1);
	}
	#GamersHub_16_mobRectangle_716 {
		fill: rgba(110,134,41,1);
	}
	.GamersHub_16_mobRectangle_716 {
		position: absolute;
		overflow: visible;
		width: 25px;
		height: 25px;
		left: 118px;
		top: 202.327px;
	}
	#GamersHub_16_mobIcon_ionic-ios-arrow-back_d {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_mobIcon_ionic-ios-arrow-back_d {
		overflow: visible;
		position: absolute;
		width: 6.684px;
		height: 11.692px;
		left: 127.207px;
		top: 209.038px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobGroup_14996 {
		position: absolute;
		width: 369px;
		height: 1929.296px;
		left: 11px;
		top: 1329.188px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14990 {
		position: absolute;
		width: 361px;
		height: 598px;
		left: 4px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14989 {
		position: absolute;
		width: 295.791px;
		height: 241.802px;
		left: 31.315px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobPath_6955 {
		fill: rgba(92,35,255,1);
	}
	.GamersHub_16_mobPath_6955 {
		overflow: visible;
		position: absolute;
		width: 295.791px;
		height: 102.415px;
		left: 0px;
		top: 120.901px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobkisspng-twisted-metal-black-pl {
		position: absolute;
		width: 241.802px;
		height: 241.802px;
		left: 26.995px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14988 {
		position: absolute;
		width: 361px;
		height: 326px;
		left: 0px;
		top: 272px;
		overflow: visible;
	}
	#GamersHub_16_mobScroll_Group_51 {
		mix-blend-mode: normal;
		position: absolute;
		width: 361px;
		height: 269px;
		left: 0px;
		top: 57px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#GamersHub_16_mobElevate_Your_Space_with_Timele {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 345px;
		height: 249px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobVision {
		left: 11px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobGroup_14992 {
		position: absolute;
		width: 369px;
		height: 577.819px;
		left: 0px;
		top: 694.727px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14991 {
		position: absolute;
		width: 295.79px;
		height: 211.863px;
		left: 26px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobPath_6957 {
		fill: rgba(78,187,87,1);
	}
	.GamersHub_16_mobPath_6957 {
		overflow: visible;
		position: absolute;
		width: 295.79px;
		height: 124.099px;
		left: 0px;
		top: 87.764px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobkisspng-computer-keyboard-cors {
		position: absolute;
		width: 242.368px;
		height: 175.528px;
		left: 26.816px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14973 {
		position: absolute;
		width: 369px;
		height: 326px;
		left: 0px;
		top: 251.819px;
		overflow: visible;
	}
	#GamersHub_16_mobScroll_Group_51_en {
		mix-blend-mode: normal;
		position: absolute;
		width: 369px;
		height: 269px;
		left: 0px;
		top: 57px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#GamersHub_16_mobElevate_Your_Space_with_Timele_eo {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 226px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobMission {
		left: 11px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobGroup_14995 {
		position: absolute;
		width: 369px;
		height: 560.022px;
		left: 0px;
		top: 1369.274px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14993 {
		position: absolute;
		width: 336.389px;
		height: 193.377px;
		left: 6px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobPath_6956 {
		fill: rgba(78,187,87,1);
	}
	.GamersHub_16_mobPath_6956 {
		overflow: visible;
		position: absolute;
		width: 336.389px;
		height: 116.471px;
		left: 0px;
		top: 76.906px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobMy_project {
		position: absolute;
		width: 285.73px;
		height: 160.723px;
		left: 25.069px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobEllipse_63 {
		filter: blur(9px);
		fill: rgba(0,0,0,1);
	}
	.GamersHub_16_mobEllipse_63 {
		position: absolute;
		overflow: visible;
		width: 330.866px;
		height: 65.262px;
		left: 22.05px;
		top: 135.142px;
	}
	#GamersHub_16_mobGroup_14994 {
		position: absolute;
		width: 369px;
		height: 326px;
		left: 0px;
		top: 234.022px;
		overflow: visible;
	}
	#GamersHub_16_mobScroll_Group_51_ew {
		mix-blend-mode: normal;
		position: absolute;
		width: 369px;
		height: 269px;
		left: 0px;
		top: 57px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#GamersHub_16_mobElevate_Your_Space_with_Timele_ex {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 249px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobPrinciples {
		left: 11px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobGroup_14984 {
		position: absolute;
		width: 176.66px;
		height: 79.148px;
		left: 106.67px;
		top: 3378.356px;
		overflow: visible;
	}
	#GamersHub_16_mobprinci_design_img_website_7_pc {
		fill: rgba(247,33,38,1);
	}
	.GamersHub_16_mobprinci_design_img_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 175.359px;
		height: 76.1px;
		transform: translate(-608px, -2195.97px) matrix(1,0,0,1,608.6506,2197.4942) rotate(1deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_mobOur_Services_lbl_website_6_pc {
		left: 31px;
		top: 23.03px;
		position: absolute;
		overflow: visible;
		width: 115px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(255,255,255,0.89);
	}
	#GamersHub_16_mobScroll_Group_55 {
		mix-blend-mode: normal;
		position: absolute;
		width: 380.5px;
		height: 232.998px;
		left: 0px;
		top: 3497.002px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#GamersHub_16_mobGroup_14977 {
		position: relative;
		margin-right: 30px;
		width: 241.116px;
		height: 173.821px;
		left: 17px;
		top: 25px;
		overflow: visible;
	}
	#GamersHub_16_mobPath_448 {
		fill: rgba(0,0,0,1);
	}
	.GamersHub_16_mobPath_448 {
		overflow: visible;
		position: absolute;
		width: 240.468px;
		height: 145.867px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobn_71177 {
		position: absolute;
		width: 233.388px;
		height: 129.459px;
		left: 3.679px;
		top: 3.715px;
		overflow: visible;
	}
	#GamersHub_16_mobMinecraft {
		left: 1.116px;
		top: 151.821px;
		position: absolute;
		overflow: visible;
		width: 241px;
		height: 22px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobGroup_14999 {
		position: absolute;
		width: 412px;
		height: 594.5px;
		left: -11px;
		top: 3846.5px;
		overflow: visible;
	}
	#GamersHub_16_mobGroup_14976 {
		position: absolute;
		width: 412px;
		height: 231.75px;
		left: 0px;
		top: 3.372px;
		overflow: visible;
	}
	#GamersHub_16_mobMy_project_e {
		position: absolute;
		width: 412px;
		height: 231.75px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobRectangle_717 {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_mobRectangle_717 {
		position: absolute;
		overflow: visible;
		width: 205.276px;
		height: 114.694px;
		left: 101.009px;
		top: 55.392px;
	}
	#GamersHub_16_mobGroup_14997 {
		position: absolute;
		width: 355px;
		height: 180px;
		left: 34px;
		top: 235.5px;
		overflow: visible;
	}
	#GamersHub_16_mobn_234567890_mobile_number_shad {
		left: 35px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobaffanghanioperatelive_email_sh {
		left: 35px;
		top: 61px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobaddress_med_shadow_space_websi {
		left: 35px;
		top: 120px;
		position: absolute;
		overflow: visible;
		width: 321px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_moblocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 120px;
		overflow: visible;
	}
	#GamersHub_16_mobemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 60px;
		overflow: visible;
	}
	#GamersHub_16_mobphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 112px;
		top: 475.5px;
		overflow: visible;
	}
	#GamersHub_16_moblinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.GamersHub_16_mobmed_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#GamersHub_16_mobmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.GamersHub_16_mobmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#GamersHub_16_moblinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.192px;
		overflow: visible;
	}
	#GamersHub_16_mobn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.GamersHub_16_mobn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.671px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_moblinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.GamersHub_16_moblinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.554px;
		height: 14.428px;
		left: 0.589px;
		top: 6.809px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.GamersHub_16_mobtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.526px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_mobInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.GamersHub_16_mobshadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#GamersHub_16_mobshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.GamersHub_16_mobshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#GamersHub_16_mobinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#GamersHub_16_mobwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_mobmed_shadow_space_website_7_fv:hover {
		fill: rgba(50,172,158,1);
	}
	.GamersHub_16_mobmed_shadow_space_website_7_fv:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#GamersHub_16_mobmed_shadow_space_website_7_fv {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.GamersHub_16_mobmed_shadow_space_website_7_fv {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#GamersHub_16_mobwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 11.993px;
		overflow: visible;
	}
	#GamersHub_16_mobn_Copyright_Operate_Live_OPC_P {
		left: 62px;
		top: 550.5px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_mobLine_28 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_mobLine_28 {
		overflow: visible;
		position: absolute;
		width: 370.016px;
		height: 5px;
		left: 19.5px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}