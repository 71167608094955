.mediaViewInfo {
    --web-view-name: event feed;
    --web-view-id: event_feed;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: event_feed;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#event_feed {
    position: relative;
    width: 100%;
    height: 167px;
    margin-top: 15px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: event feed;
    --web-view-id: event_feed;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Events_Feed_lbl_profile_page {
    left: 20px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 84px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#event_feed_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 147px;
    left: 0px;
    top: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
}
#event_feed_grp {
    position: relative;
    width: 350px;
    height: 100px;
    left: 11px;
    top: 13px;
    margin-right: 10px;
    overflow: visible;
}
#bdr_card_1 {
    fill: rgba(255,255,255,1);
}
.bdr_card_1 {
    filter: drop-shadow(5px 5px 2px rgba(177, 224, 201, 1));
    position: absolute;
    overflow: visible;
    width: 358px;
    height: 108px;
    left: 0px;
    top: 0px;
}
#img_card_1_hmm {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 16px;
    top: 15px;
    overflow: visible;
}
#Events_name_lbl {
    left: 111px;
    top: 15px;
    position: absolute;
    overflow: hidden;
    width: 170px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#Events_Feed_lbl_profile_page_l {
    left: 111px;
    top: 55px;
    position: absolute;
    overflow: visible;
    width: 219px;
    height: 40px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#Category_-_Education_lbl {
    left: 111px;
    top: 34px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#n_50000_lbl {
    left: 293px;
    top: 15px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}