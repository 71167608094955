#Scroll_grp_events_pc {
    mix-blend-mode: normal;
    position: absolute;
    width: 324px;
    height: 550px;
    left: 21px;
    top: 140px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#add_calender_event_grp {
    position: absolute;
    width: 250px;
    height: 672px;
    left: 15px;
    top: 3px;
    overflow: visible;
}
#upload_banner_grp_add_add_cale {
    position: absolute;
    width: 250px;
    height: 98px;
    left: 0px;
    top: 574px;
    overflow: visible;
}
#Upload_Banner_IMG_add_calender {
    left: 0px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 164px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#upload_imgadd_calender_event {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 198px;
    top: 0px;
    overflow: visible;
    cursor: pointer;
}
#img_preview_section_add_calend {
    position: absolute;
    width: 250px;
    height: 70px;
    left: 0px;
    top: 28px;
    overflow: visible;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
   
}
#add_calender_event_loca_grp {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 488px;
    overflow: visible;
}
#location_bdr_add_calender_even {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.location_bdr_add_calender_even {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Location_lbl_add_calender_even {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_calender_event_grp_end_atd {
    position: absolute;
    width: 250px;
    height: 63px;
    left: 0px;
    top: 149px;
    overflow: visible;
}
#end_bdr_add_calender_event {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.end_bdr_add_calender_event {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#ends_lbl_add_calender_event {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 58px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_calender_event_grp_cq {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 75px;
    overflow: visible;
}
#date_bdr_add_calender_event {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.date_bdr_add_calender_event {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Date_lbl_add_calender_event {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_calender_event_category_gr {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 299px;
    overflow: visible;
}
#category_bdr_add_calender_even {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.category_bdr_add_calender_even {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Category_lbl_add_calender_even {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_calender_event_description {
    position: absolute;
    width: 250px;
    height: 103px;
    left: 0px;
    top: 373px;
    overflow: visible;
}
#descp_bdr_add_calender_event {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.descp_bdr_add_calender_event {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 80px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Description_lbl_add_calender_e {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 86px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#event_name_grp_add_event_detai {
    position: absolute;
    width: 250px;
    height: 63px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#event_name_bdr_add_event_detai {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.event_name_bdr_add_event_detai {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Event_Name_lbl_add_event_detai {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 92px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#event_cost_grp_add_event_detai {
    position: absolute;
    width: 115px;
    height: 62px;
    left: 135px;
    top: 225px;
    overflow: visible;
}
#event_cost_bdr_add_event_detai {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.event_cost_bdr_add_event_detai {
    position: absolute;
    overflow: visible;
    width: 115px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Event_Cost_lbl_add_event_detai {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 80px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#event_seats_grp_add_event_deta {
    position: absolute;
    width: 115px;
    height: 63px;
    left: 0px;
    top: 224px;
    overflow: visible;
}
#event_seats_bdr_add_event_deta {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.event_seats_bdr_add_event_deta {
    position: absolute;
    overflow: visible;
    width: 115px;
    height: 40px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Event_Seats_lbl_add_event_deta {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 86px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}