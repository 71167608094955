
	.mediaViewInfo {
		--web-view-name: VerdantHarvestGrove;
		--web-view-id: VerdantHarvestGrove;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: VerdantHarvestGrove;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#VerdantHarvestGrove {
		position: absolute;
		width: 1366px;
		height: 3640px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: VerdantHarvestGrove;
		--web-view-id: VerdantHarvestGrove;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#VHG_PC_28Group_15101 {
		position: absolute;
		width: 1175px;
		height: 487.998px;
		left: 96px;
		top: 3133.002px;
		overflow: visible;
	}
	#VHG_PC_28Group_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 114.997px;
		overflow: visible;
	}
	#VHG_PC_28Group_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28Rectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28Rectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#VHG_PC_28socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#VHG_PC_28linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28med_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#VHG_PC_28linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#VHG_PC_28n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.VHG_PC_28n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#VHG_PC_28linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.VHG_PC_28linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#VHG_PC_28top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.VHG_PC_28top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#VHG_PC_28Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#VHG_PC_28instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#VHG_PC_28whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28med_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28med_shadow_space_website_7_ {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#VHG_PC_28whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#VHG_PC_28n_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_PC_28affanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_PC_28address_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_PC_28location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#VHG_PC_28email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#VHG_PC_28phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#VHG_PC_28n_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#VHG_PC_28Contact_Us {
		left: 510.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#VHG_PC_28Group_15117 {
		position: absolute;
		width: 1366px;
		height: 898px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28mk-s-tHHFiw6GNEU-unsplash {
		position: absolute;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28Rectangle_748 {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
	}
	.VHG_PC_28Rectangle_748 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#VHG_PC_28amjd-rdwan-CEAPSFlMLbk-unsplas {
		position: absolute;
		width: 500px;
		height: 500px;
		left: 433px;
		top: 398px;
		overflow: visible;
	}
	#VHG_PC_28Anime_Edit {
		position: absolute;
		width: 80px;
		height: 80px;
		left: 61px;
		top: 45px;
        border-radius: 50%;
		overflow: hidden;
	}
	#VHG_PC_28Your_Business_Name {
		left: 151px;
		top: 71px;
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#VHG_PC_28Cultivating_Wellness_from_Farm {
		left: 61px;
		top: 190px;
		position: absolute;
		overflow: visible;
		width: 807px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(255,255,255,1);
	}
	#VHG_PC_28Group_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 61px;
		top: 452px;
		overflow: visible;
	}
	#VHG_PC_28Rectangle_3 {
		fill: rgba(233,153,15,1);
	}
	.VHG_PC_28Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#VHG_PC_28Available_Now {
		left: 37px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#VHG_PC_28Group_15118 {
		position: absolute;
		width: 1055px;
		height: 1400px;
		left: 134px;
		top: 998px;
		overflow: visible;
	}
	#VHG_PC_28Group_15114 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28tandem-x-visuals-dqZ0JREudKw-u {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 462px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#VHG_PC_28Scroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#VHG_PC_28It_is_a_long_established_fact_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#VHG_PC_28VISION {
		left: 462px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_PC_28Group_15115 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 500px;
		overflow: visible;
	}
	#VHG_PC_28mk-s-7GeBVqFwOl4-unsplash {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 655px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28Scroll_Group_28_by {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 0px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#VHG_PC_28Scroll_Group_28_by::-webkit-scrollbar{
		display: none;
	}
	#VHG_PC_28It_is_a_long_established_fact__bz {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#VHG_PC_28MISSION {
		left: 0px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_PC_28Group_15116 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 1000px;
		overflow: visible;
	}
	#VHG_PC_28ricardo-gomez-angel-I5XthC605H {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28Scroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 462px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#VHG_PC_28Scroll_Group_28_b::-webkit-scrollbar{
		display: none;
	}
	#VHG_PC_28It_is_a_long_established_fact__b {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#VHG_PC_28PRINCIPLES {
		left: 462px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_PC_28Group_15119 {
		position: absolute;
		width: 1341px;
		height: 463px;
		left: 13px;
		top: 2498px;
		overflow: visible;
	}
	#VHG_PC_28Scroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1341px;
		height: 386px;
		left: 0px;
		top: 77px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#VHG_PC_28Scroll_Group_77::-webkit-scrollbar{
		display: none;
	}
	#VHG_PC_28n_ {
		position: relative;
		width: 307px;
		height: 342px;
		left: 25px;
		top: 21px;
        margin-right: 30px;
		overflow: visible;
	}
	#VHG_PC_28Mask_Group_22 {
		position: absolute;
		width: 307px;
		height: 296px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_PC_28Rectangle_664 {
		fill: rgba(0,0,0,1);
	}
	.VHG_PC_28Rectangle_664 {
		position: absolute;
		overflow: visible;
		width: 307px;
		height: 46px;
		left: 0px;
		top: 296px;
	}
	#VHG_PC_28Something {
		left: 9px;
		top: 309px;
		position: absolute;
		overflow: visible;
		width: 293px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}
	#VHG_PC_28Group_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 131px;
		top: 268px;
		overflow: visible;
	}
	#VHG_PC_28Ellipse_38 {
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28Ellipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#VHG_PC_28Ellipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28Ellipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#VHG_PC_28Ellipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28Ellipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#VHG_PC_28Ellipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28Ellipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#VHG_PC_28Ellipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.VHG_PC_28Ellipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#VHG_PC_28Available_Now_ci {
		left: 603px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 136px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}