	.mediaViewInfo {
		--web-view-name: calender page Alarm mob;
		--web-view-id: calender_page_Alarm_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: calender_page_Alarm_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#mb_calender_page_Alarm_mob {
	position: absolute;
    width: 100%;
    height: calc(100% - 75px);
    top: 75px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: calender page Alarm mob;
    --web-view-id: calender_page_Alarm_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
	}
	#mb_calender_page_Alarm_mob_grp {
		position: absolute;
    width: 100%;
    height: calc(100% - 17px);
    left: -26px;
    top: 17px;
    overflow: visible;
	}
	#mb_alarm_mob_calender {
		fill: rgba(255,255,255,1);
		stroke: rgba(78,187,87,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.mb_alarm_mob_calender {
		position: absolute;
		overflow: visible;
		width: calc(100% - 20px);
		height: 100%;
		left: 0px;
		top: 0px;
	}
	#mb_right_atlarm_mob_ {
		position: absolute;
        width: 22.5px;
        height: 332.1px;
        right: -15px;
        top: 25.5px;
        overflow: visible;
	}
	#mb_mobTasksalarm_lbl_ {
		transform: translate(-340.5px, -156.5px) matrix(1,0,0,1,337.5,259.5396) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#mb_Meetings_lbl_headingcalender_m {
		transform: translate(-340.5px, -156.5px) matrix(1,0,0,1,324.5,351.9829) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#mb_Events_lbl_calender_mob {
		transform: translate(-340.5px, -156.5px) matrix(1,0,0,1,333.5,459.1001) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#mb_alarm_mob_cal_mob {
		position: absolute;
		width: 22.5px;
		height: 42.22px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#mb_mobAlarms_lbl_calender {
		transform: translate(-340.5px, -156.5px) matrix(1,0,0,1,332.5,168.2197) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(78,187,87,1);
	}
	#mb_Line_29_alarm_mob {
		fill: transparent;
		stroke: rgba(78,187,87,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.mb_Line_29_alarm_mob {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 42px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#mb_Scroll_alarm_mob_ {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 45px);
    height: 100%;
    left: 26px;
    /* top: 6.32px; */
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-content: flex-start;	
    overflow-x: hidden;
    overflow-y: scroll;
	}

	#mb_waking_up_all_grp_alarm_Mob {
		position: relative;
		width: 249.76px;
		height: 58.8px;
		left: 32.64px;
		top: 0px;
        margin: 10px;
		overflow: visible;
	}
	#mb_Waking_Up_Early_lbl_calender_Mob {
		left: 9.16px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 234px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#mb_n_00am_time_for_wake_up {
		left: 9.16px;
		top: 29.36px;
		position: absolute;
		overflow: visible;
		width: 45px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#mb_waking_up_early_line {
		fill: rgba(175,148,252,1);
	}
	.mb_waking_up_early_line {
		position: absolute;
		overflow: visible;
		width: 3.36px;
		height: 16.8px;
		left: 0.36px;
		top: 3.36px;
	}
	#mb_n_1135am_time_for_snooze {
		left: 122.44px;
		top: 31.36px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#mb_snooze_img_calender {
		position: absolute;
		width: 22.4px;
		height: 22.4px;
		left: 100.04px;
		top: 30.24px;
		overflow: visible;
	}
	#mb_green_ball {
		fill: rgba(164,255,96,1);
	}
	.mb_green_ball {
		position: absolute;
		overflow: visible;
		width: 11.2px;
		height: 11.2px;
		left: 231.08px;
		top: 38.08px;
	}
	#mb_arcane_top_line_calender_wa {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.mb_arcane_top_line_calender_wa {
		overflow: visible;
		position: absolute;
		width: 249.76px;
		height: 1px;
		left: 0px;
		top: 58.8px;
		transform: matrix(1,0,0,1,0,0);
	}
