	.mediaViewInfo {
		--web-view-name: connection page;
		--web-view-id: connection_page;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: connection_page;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#connection_page {
		position: absolute;
		width: calc(100vw - 120px);
		height: calc(100vh - 60px);
        top: 60px;
        left: 120px;
		background-color: rgba(255,255,255,1);
		overflow-x: hidden;
		overflow-y: auto;
		--web-view-name: connection page;
		--web-view-id: connection_page;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#heading_grp_conreqsug_con_page {
		position: absolute;
		width: 364px;
		height: 42px;
		left: calc((100% - 364px)/2);
		top: 22px;
		overflow: visible;
	}
	#conreqsug_con_page_new_pc_req {
		opacity: 0.92;
		left: 151px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 65px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(0,0,0,1);
	}
	#selected_grp_offreqevent_com_r {
		position: absolute;
		width: 99px;
		height: 24px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#conreqsug_con_page_new_pc_conn {
		opacity: 0.92;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(255,93,24,1);
	}
	#conreqsug_con_page_new_pc_sug {
		opacity: 0.92;
		left: 267px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 98px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(48,47,47,1);
	}
	#underline_conreqsug_con_page_n {
		fill: rgba(255,79,4,1);
	}
	.underline_conreqsug_con_page_n {
		position: absolute;
		overflow: visible;
		width: 99px;
		height: 3px;
		left: 0px;
		top: 39px;
	}
	#desgin_con_page_new_pc {
		fill: rgba(248,248,248,1);
	}
	.desgin_con_page_new_pc {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 203px;
		left: 0px;
		top: 94px;
	}
	#Scroll_connection_page_pc_new {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 636px;
		/* left: 59px; */
		top: 108px;
        display: block;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_connection_page_pc_new::-webkit-scrollbar{
		display: none;
	}
	#overrall_car_conreqsug_con_pag {
		position: relative;
		width: 185px;
		height: 287px;
		left: 15px;
		top: 24px;
        display: inline-block;
        margin: 10px;
		overflow: visible;
	}
	#over_all_bdr_sug_con_page_new_ {
		fill: rgba(255,255,255,1);
	}
	.over_all_bdr_sug_con_page_new_ {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 193px;
		height: 295px;
		left: 0px;
		top: 0px;
	}
	#Anime_Edit_con_page_new_pc {
		position: absolute;
		width: 150px;
		height: 142px;
		left: 18px;
		top: 35px;
		overflow: visible;
	}
	#desgination_pc_con_page {
		position: absolute;
		width: 170px;
		height: 25px;
		left: 12px;
		top: 182px;
		overflow: visible;
	}
	#job-seeker_con_page_new_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#con_page_new_pc_cor {
		left: 30px;
		top: 3px;
		position: absolute;
		overflow: hidden;
		width: 141px;
		height: 20px;
		
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: capitalize;
	}
	#Mohammed_Affan_con_page_new_pc {
		left: 1px;
		top: 8px;
		position: absolute;
		overflow: hidden;
		width: 185px;
		height: 20px;
	
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
	}
	#block_btn_grp_con_page_new_pc {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 103px;
		top: 242px;
        cursor: pointer;
		overflow: visible;
	}
	#share_btn_con_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.share_btn_con_page_new_pc {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#share_img_con_page_new_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#disconnect_btn_grp_con_page_ne {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 49px;
		top: 242px;
        cursor: pointer;
		overflow: visible;
	}
	#bdr_no_Copines {
		fill: rgba(255,255,255,1);
	}
	.bdr_no_Copines {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#no-connection_Copines {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#company_anme_new_ {
		position: absolute;
		width: 170px;
		height: 25px;
		left: 12px;
		top: 212px;
		overflow: visible;
	}
	#office-building_img_con_page_n {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#operate_live_lbl_con_page_new_ {
		left: 30px;
		top: 3px;
		position: absolute;
		overflow: hidden;
		width: 141px;
		height: 20px;
	
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		text-transform: capitalize;
	}
