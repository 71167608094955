
	.mediaViewInfo {
		--web-view-name: TextureFusion;
		--web-view-id: TextureFusion;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: TextureFusion;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#TextureFusion {
		position: absolute;
		width: 1366px;
		height: 3287px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: TextureFusion;
		--web-view-id: TextureFusion;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#TFP_PC_34Group_15171 {
		position: absolute;
		width: 1366px;
		height: 483.998px;
		left: 0px;
		top: 2808.002px;
		overflow: visible;
	}
	#TFP_PC_34jj-ying-7JX0-bfiuxQ-unsplash {
		position: absolute;
		width: 1366px;
		height: 425px;
		left: 0px;
		top: 58.998px;
		overflow: visible;
	}
	#TFP_PC_34Group_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 96px;
		top: 120.997px;
		overflow: visible;
	}
	#TFP_PC_34Rectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.TFP_PC_34Rectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#TFP_PC_34socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 764px;
		top: 346.998px;
		overflow: visible;
	}
	#TFP_PC_34linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#TFP_PC_34med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TFP_PC_34med_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.603px;
		overflow: visible;
	}
	#TFP_PC_34n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.TFP_PC_34n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.671px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TFP_PC_34linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.TFP_PC_34linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.809px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TFP_PC_34top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.TFP_PC_34top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TFP_PC_34Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#TFP_PC_34shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TFP_PC_34shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.003px;
		overflow: visible;
	}
	#TFP_PC_34whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TFP_PC_34med_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.TFP_PC_34med_shadow_space_website_7_ {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#TFP_PC_34n_234567890_mobile_number_shad {
		left: 799px;
		top: 115.998px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TFP_PC_34affanghanioperatelive_email_sh {
		left: 804px;
		top: 168.998px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TFP_PC_34address_med_shadow_space_websi {
		left: 799px;
		top: 218.998px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TFP_PC_34location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 764px;
		top: 220.998px;
		overflow: visible;
	}
	#TFP_PC_34email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 764px;
		top: 167.998px;
		overflow: visible;
	}
	#TFP_PC_34phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 764px;
		top: 114.998px;
		overflow: visible;
	}
	#TFP_PC_34n_Copyright_Operate_Live_OPC_P {
		left: 764px;
		top: 442.998px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#TFP_PC_34Contact_Us {
		left: 606.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#TFP_PC_34Group_15117 {
		position: absolute;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TFP_PC_34christian-holzinger-CUY_YHhCFl {
		position: absolute;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TFP_PC_34Rectangle_748 {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
	}
	.TFP_PC_34Rectangle_748 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34Anime_Edit {
		position: absolute;
		width: 80px;
		height: 80px;
		left: 61px;
		top: 45px;
		overflow: hidden;
        border-radius: 50%;
	}
	#TFP_PC_34Your_Business_Name {
		left: 151px;
		top: 71px;
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFP_PC_34Elevate_Your_Space_with_Unique {
		left: 61px;
		top: 209px;
		position: absolute;
		overflow: visible;
		width: 906px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(255,255,255,1);
	}
	#TFP_PC_34Group_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 61px;
		top: 452px;
		overflow: visible;
	}
	#TFP_PC_34Rectangle_3 {
		fill: rgba(183,177,186,1);
	}
	.TFP_PC_34Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34Available_Now {
		left: 37px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#TFP_PC_34Group_15169 {
		position: absolute;
		width: 1366px;
		height: 1388px;
		left: 0px;
		top: 763px;
		overflow: visible;
	}
	#TFP_PC_34pawel-czerwinski-eBd0dPuR-bo-u {
		opacity: 0.5;
		position: absolute;
		width: 1366px;
		height: 1388px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TFP_PC_34Group_15141 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 226px;
		top: 70px;
		overflow: visible;
	}
	#TFP_PC_34Rectangle_752 {
		fill: rgba(223,221,219,1);
	}
	.TFP_PC_34Rectangle_752 {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34n_3430759_5249503 {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#TFP_PC_34Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TFP_PC_34Scroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#TFP_PC_34It_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#TFP_PC_34VISION {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFP_PC_34Group_15142 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 226px;
		top: 506px;
		overflow: visible;
	}
	#TFP_PC_34Rectangle_752_bu {
		fill: rgba(183,177,186,1);
	}
	.TFP_PC_34Rectangle_752_bu {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34n_60450_OFC6C30 {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 609px;
		top: 33px;
		overflow: visible;
	}
	#TFP_PC_34Scroll_Group_28_bw {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 107px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TFP_PC_34Scroll_Group_28_bw::-webkit-scrollbar{
		display: none;
	}
	#TFP_PC_34It_is_a_long_established_fact__bx {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#TFP_PC_34MISSION {
		left: 109px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFP_PC_34Group_15143 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 226px;
		top: 942px;
		overflow: visible;
	}
	#TFP_PC_34Rectangle_752_b {
		fill: rgba(230,209,200,1);
	}
	.TFP_PC_34Rectangle_752_b {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34n_20566_OCCYZ60 {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#TFP_PC_34Scroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TFP_PC_34Scroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#TFP_PC_34It_is_a_long_established_fact__b {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#TFP_PC_34PRINCIPLES {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFP_PC_34Group_15170 {
		position: absolute;
		width: 1286px;
		height: 457px;
		left: 40px;
		top: 2251px;
		overflow: visible;
	}
	#TFP_PC_34FRESH_DESIGNS {
		left: 19px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 280px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(0,0,0,1);
	}
	#TFP_PC_34Group_15145 {
		position: absolute;
		width: 185px;
		height: 61px;
		left: 1009px;
		top: 0px;
		overflow: visible;
	}
	#TFP_PC_34Rectangle_753 {
		fill: rgba(183,177,186,1);
	}
	.TFP_PC_34Rectangle_753 {
		position: absolute;
		overflow: visible;
		width: 185px;
		height: 61px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34View_More {
		left: 46px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TFP_PC_34Scroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1286px;
		height: 396px;
		left: 0px;
		top: 61px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#TFP_PC_34Scroll_Group_91::-webkit-scrollbar{
		display: none;
	}
	#TFP_PC_34Group_15144 {
		position: relative;
        margin-right: 30px;
		width: 243px;
		height: 331px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#TFP_PC_34Rectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.TFP_PC_34Rectangle_754 {
		filter: drop-shadow(5px 5px 1px rgba(233, 221, 223, 1));
		position: absolute;
		overflow: visible;
		width: 249.5px;
		height: 337.5px;
		left: 0px;
		top: 0px;
	}
	#TFP_PC_34luca-laurence-FseXc3OsIic-unsp {
		position: absolute;
		width: 239px;
		height: 267px;
		left: 2px;
		top: 32px;
		overflow: visible;
	}
	#TFP_PC_34Title {
		left: 7px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TFP_PC_34n_10000 {
		left: 7px;
		top: 299px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}