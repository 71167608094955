
	.mediaViewInfo {
		--web-view-name: FreshSipHub Mob;
		--web-view-id: FreshSipHub_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: FreshSipHub_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#FreshSipHub_Mob {
		position: absolute;
		width: 375px;
		height: 3510px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: FreshSipHub Mob;
		--web-view-id: FreshSipHub_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#FSH_MoB_30Group_15129 {
		position: absolute;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30Group_15124 {
		position: absolute;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30jugoslocos-i8JfQDc4Ha8-unsplas {
		position: absolute;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30Rectangle_749 {
		opacity: 0.24;
		fill: rgba(0,0,0,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FSH_MoB_30Rectangle_749 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 684px;
		left: 0px;
		top: 0px;
	}
	#FSH_MoB_30Anime_Edit {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 14px;
		top: 109px;
        border-radius: 50%;
		overflow: hidden;
	}
	#FSH_MoB_30Your_Business_Name {
		left: 78px;
		top: 123px;
		position: absolute;
		overflow: visible;
		width: 189px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#FSH_MoB_30Revitalize_Your_Life_with_the_ {
		left: 8px;
		top: 243px;
		position: absolute;
		overflow: visible;
		width: 358px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#FSH_MoB_30Group_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 84px;
		top: 452px;
		overflow: visible;
	}
	#FSH_MoB_30Rectangle_3 {
		fill: rgba(233,153,15,1);
	}
	.FSH_MoB_30Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#FSH_MoB_30Available_Now {
		left: 37px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#FSH_MoB_30Group_15134 {
		position: absolute;
		width: 366px;
		height: 1637px;
		left: 7px;
		top: 753px;
		overflow: visible;
	}
	#FSH_MoB_30Group_15126 {
		position: absolute;
		width: 366px;
		height: 477.079px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30tangerine-newt-hINQgaTqg7Q-uns {
		position: absolute;
		width: 220px;
		height: 220px;
		left: 70.5px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 0px;
		top: 323.079px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FSH_MoB_30It_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FSH_MoB_30VISION {
		left: 2px;
		top: 258.079px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_MoB_30Group_15130 {
		position: absolute;
		width: 366px;
		height: 477px;
		left: 0px;
		top: 580px;
		overflow: visible;
	}
	#FSH_MoB_30whitney-wright-TgQkxQc-t_U-uns {
		position: absolute;
		width: 220px;
		height: 220px;
		left: 70.5px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30Scroll_Group_28_bb {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 0px;
		top: 323px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FSH_MoB_30It_is_a_long_established_fact__bc {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FSH_MoB_30MISSION {
		left: 2px;
		top: 258px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_MoB_30Group_15131 {
		position: absolute;
		width: 366px;
		height: 477px;
		left: 0px;
		top: 1160px;
		overflow: visible;
	}
	#FSH_MoB_30alina-karpenko-jH1PBHevj38-uns {
		position: absolute;
		width: 220px;
		height: 220px;
		left: 70.5px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30Scroll_Group_28_bg {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 0px;
		top: 323px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FSH_MoB_30It_is_a_long_established_fact__bh {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FSH_MoB_30PRINCIPLES {
		left: 2px;
		top: 258px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_MoB_30Group_15133 {
		position: absolute;
		width: 375px;
		height: 332px;
		left: 0px;
		top: 2490px;
		overflow: visible;
	}
	#FSH_MoB_30Our_Greatest_Drinks {
		left: 95px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 187px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_MoB_30Scroll_Group_90 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 305px;
		left: 0px;
		top: 27px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#FSH_MoB_30Group_15128 {
		position: relative;
        margin-right: 30px;
		width: 203px;
		height: 254px;
		left: 15px;
		top: 22px;
		overflow: visible;
	}
	#FSH_MoB_30Rectangle_750_bo {
		fill: url(#FSH_MoB_30Rectangle_750_bo);
	}
	.FSH_MoB_30Rectangle_750_bo {
		filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 208px;
		height: 259px;
		left: 0px;
		top: 0px;
	}
	#FSH_MoB_30sam-hojati-lw8GflbJwLc-unsplas {
		position: absolute;
		width: 185px;
		height: 210px;
		left: 9px;
		top: 9px;
		overflow: visible;
	}
	#FSH_MoB_30Juice_1 {
		left: 9px;
		top: 226px;
		position: absolute;
		overflow: visible;
		width: 186px;
		height: 20px;
		line-height: 41px;
		margin-top: -13px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#FSH_MoB_30Group_15132 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 8.5px;
		top: 2905.904px;
		overflow: visible;
	}
	#FSH_MoB_30Group_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 30px;
		top: 73.095px;
		overflow: visible;
	}
	#FSH_MoB_30Rectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.FSH_MoB_30Rectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#FSH_MoB_30Group_15050 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 480.096px;
		overflow: visible;
	}
	#FSH_MoB_30linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FSH_MoB_30med_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FSH_MoB_30linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#FSH_MoB_30n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.FSH_MoB_30n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FSH_MoB_30linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.FSH_MoB_30linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FSH_MoB_30top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.FSH_MoB_30top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FSH_MoB_30Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FSH_MoB_30shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FSH_MoB_30instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#FSH_MoB_30whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_MoB_30med_shadow_space_website_7_b {
		fill: rgba(255,255,255,1);
	}
	.FSH_MoB_30med_shadow_space_website_7_b {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FSH_MoB_30whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#FSH_MoB_30n_234567890_mobile_number_shad {
		left: 48px;
		top: 293.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FSH_MoB_30affanghanioperatelive_email_sh {
		left: 35px;
		top: 340.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FSH_MoB_30address_med_shadow_space_websi {
		left: 35px;
		top: 390.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#FSH_MoB_30location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 392.096px;
		overflow: visible;
	}
	#FSH_MoB_30email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 339.096px;
		overflow: visible;
	}
	#FSH_MoB_30phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 286.096px;
		overflow: visible;
	}
	#FSH_MoB_30n_Copyright_Operate_Live_OPC_P {
		left: 27px;
		top: 565.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#FSH_MoB_30Contact_Us {
		left: 127.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -22.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}