	.mediaViewInfo {
		--web-view-name: TeaCorner Mob;
		--web-view-id: TeaCorner_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: TeaCorner_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#TeaCorner_Mob {
		position: absolute;
		width: 375px;
		height: 3363px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(249,249,249,0.886);
		overflow: hidden;
		--web-view-name: TeaCorner Mob;
		--web-view-id: TeaCorner_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#tcm_TC_17_MOBGroup_15005 {
		position: absolute;
		width: 375px;
		height: 272px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBside-view-two-cups-coffee-cook {
		position: absolute;
		width: 375px;
		height: 272px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBRectangle_722 {
		opacity: 0.74;
		fill: rgba(92,226,104,1);
	}
	.tcm_TC_17_MOBRectangle_722 {
		position: absolute;
		overflow: visible;
		width: 100px;
		height: 212px;
		left: 41px;
		top: 0px;
	}
	#tcm_TC_17_MOBAnime_Edit {
		position: absolute;
		width: 19px;
		height: 19px;
		left: 52px;
		top: 13px;
        border-radius: 50%;
		overflow: hidden;
	}
	#tcm_TC_17_MOBTea_Corner {
		left: 78px;
		top: 19px;
		position: absolute;
		overflow: visible;
		width: 25px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 5px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBThe_Beauty_of__Science_and__Na {
		left: 52px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 76px;
		height: 30px;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBTea_A_hug_in_a_cup_that_warms_ {
		left: 51px;
		top: 73px;
		position: absolute;
		overflow: visible;
		width: 81px;
		height: 130px;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#tcm_TC_17_MOBIcon_ionic-logo-whatsapp {
		fill: rgba(255,255,255,1);
	}
	.tcm_TC_17_MOBIcon_ionic-logo-whatsapp {
		overflow: visible;
		position: absolute;
		width: 8.489px;
		height: 8.49px;
		left: 48.001px;
		top: 181.931px;
		transform: matrix(1,0,0,1,0,0);
	}
	#tcm_TC_17_MOBIcon_awesome-instagram {
		fill: rgba(255,255,255,1);
	}
	.tcm_TC_17_MOBIcon_awesome-instagram {
		overflow: visible;
		position: absolute;
		width: 9.489px;
		height: 9.487px;
		left: 48.001px;
		top: 195.214px;
		transform: matrix(1,0,0,1,0,0);
	}
	#tcm_TC_17_MOBn_856321470 {
		left: 60.39px;
		top: 180.813px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(255,255,255,1);
	}
	#tcm_TC_17_MOByour_username {
		left: 60.39px;
		top: 193.148px;
		position: absolute;
		overflow: visible;
		width: 58px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(255,255,255,1);
	}
	#tcm_TC_17_MOBGroup_15011 {
		position: absolute;
		width: 448px;
		height: 543px;
		left: 13px;
		top: 322px;
		overflow: visible;
	}
	#tcm_TC_17_MOBvision_bdr_website_7_pc {
		fill: rgba(195,191,188,1);
	}
	.tcm_TC_17_MOBvision_bdr_website_7_pc {
		width: 543px;
		height: 102px;
		position: absolute;
		overflow: visible;
		transform: translate(-13px, -322px) matrix(1,0,0,1,52.5,542.5) rotate(-90deg);
		transform-origin: center;
	}
	#tcm_TC_17_MOBGroup_15006 {
		position: absolute;
		width: 445px;
		height: 240px;
		left: 3px;
		top: 278.998px;
		overflow: visible;
	}
	#tcm_TC_17_MOBVision_lbl_website_7_pc {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 68px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -8px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBScroll_vision_web_7_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 445px;
		height: 197px;
		left: 0px;
		top: 43px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#tcm_TC_17_MOBThe_cockpit_impresses_with_its {
		left: 14px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 238px;
		height: 228px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#tcm_TC_17_MOBmask_top_visoon_img_website_7_ {
		position: absolute;
		width: 260px;
		height: 320px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBGroup_15013 {
		position: absolute;
		width: 448px;
		height: 543px;
		left: -85px;
		top: 911px;
		overflow: visible;
	}
	#tcm_TC_17_MOBvision_bdr_website_7_pc_di {
		fill: rgba(97,64,38,1);
	}
	.tcm_TC_17_MOBvision_bdr_website_7_pc_di {
		width: 543px;
		height: 102px;
		position: absolute;
		overflow: visible;
		transform: translate(85px, -911px) matrix(1,0,0,1,-219.5,1131.5) rotate(-90deg);
		transform-origin: center;
	}
	#tcm_TC_17_MOBGroup_15007 {
		position: absolute;
		width: 445px;
		height: 246px;
		left: 0px;
		top: 272.998px;
		overflow: visible;
	}
	#tcm_TC_17_MOBVision_lbl_website_7_pc_dk {
		left: 213px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -8px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBScroll_vision_web_7_pc_dl {
		mix-blend-mode: normal;
		position: absolute;
		width: 445px;
		height: 197px;
		left: 0px;
		top: 49px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#tcm_TC_17_MOBThe_cockpit_impresses_with_its_dm {
		left: 208px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 238px;
		height: 228px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#tcm_TC_17_MOBmask_top_visoon_img_website_7__dn {
		position: absolute;
		width: 260px;
		height: 320px;
		left: 188px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBGroup_15012 {
		position: absolute;
		width: 448px;
		height: 543px;
		left: 16px;
		top: 1500px;
		overflow: visible;
	}
	#tcm_TC_17_MOBvision_bdr_website_7_pc_dp {
		fill: rgba(207,144,100,1);
	}
	.tcm_TC_17_MOBvision_bdr_website_7_pc_dp {
		width: 543px;
		height: 102px;
		position: absolute;
		overflow: visible;
		transform: translate(-16px, -1500px) matrix(1,0,0,1,55.5,1720.5) rotate(-90deg);
		transform-origin: center;
	}
	#tcm_TC_17_MOBGroup_15008 {
		position: absolute;
		width: 445px;
		height: 240px;
		left: 3px;
		top: 278.998px;
		overflow: visible;
	}
	#tcm_TC_17_MOBVision_lbl_website_7_pc_dr {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 106px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -8px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBScroll_vision_web_7_pc_ds {
		mix-blend-mode: normal;
		position: absolute;
		width: 445px;
		height: 197px;
		left: 0px;
		top: 43px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#tcm_TC_17_MOBThe_cockpit_impresses_with_its_dt {
		left: 14px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 238px;
		height: 228px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#tcm_TC_17_MOBmask_top_visoon_img_website_7__du {
		position: absolute;
		width: 260px;
		height: 320px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBAvaliable_Now_lbl_website_7_pc {
		left: 133px;
		top: 2171px;
		position: absolute;
		overflow: visible;
		width: 31px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBGroup_15014 {
		position: absolute;
		width: 1294px;
		height: 354px;
		left: 0px;
		top: 2253px;
		overflow: visible;
	}
	#tcm_TC_17_MOBScroll_Group_56 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 354px;
		left: 0px;
		top: 0px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#tcm_TC_17_MOBn_667477_3386277_1 {
		position: absolute;
		width: 1376px;
		height: 354px;
		left: -1px;
		top: -5px;
		overflow: visible;
	}
	#tcm_TC_17_MOBGroup_15003 {
		position: relative;
		width: 243px;
		height: 276px;
		top: 25px;
        margin-right: 30px;
		overflow: visible;
	}
	#tcm_TC_17_MOBcoffee-porcelain-cup-wooden-ta {
		position: absolute;
		width: 243px;
		height: 244px;
		left: 0px;
		top: 32px;
		overflow: visible;
	}
	#tcm_TC_17_MOBGinger_Tea {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 244px;
		height: 22px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBGroup_15010 {
		position: absolute;
		width: 477.645px;
		height: 798.381px;
		left: -51.322px;
		top: 2634.619px;
		overflow: visible;
	}
	#tcm_TC_17_MOBvision_bdr_website_7_pc_d {
		fill: rgba(207,144,100,1);
	}
	.tcm_TC_17_MOBvision_bdr_website_7_pc_d {
		position: absolute;
		overflow: visible;
		width: 378px;
		height: 726px;
		left: 51.322px;
		top: 72.381px;
	}
	#tcm_TC_17_MOBkisspng-turkish-coffee-tea-caf {
		position: absolute;
		width: 477.645px;
		height: 494.762px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBEllipse_64 {
		fill: rgba(255,255,255,1);
	}
	.tcm_TC_17_MOBEllipse_64 {
		position: absolute;
		overflow: visible;
		width: 188px;
		height: 193px;
		left: 173.033px;
		top: 155.381px;
		border-radius: 100px;
	}
	#tcm_TC_17_MOBGroup_15004 {
		position: absolute;
		width: 345px;
		height: 242px;
		left: 70.322px;
		top: 379.381px;
		overflow: visible;
	}
	#tcm_TC_17_MOBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 75px;
		top: 194px;
		overflow: visible;
	}
	#tcm_TC_17_MOBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.tcm_TC_17_MOBmed_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#tcm_TC_17_MOBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.tcm_TC_17_MOBmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#tcm_TC_17_MOBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.672px;
		overflow: visible;
	}
	#tcm_TC_17_MOBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.tcm_TC_17_MOBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#tcm_TC_17_MOBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.tcm_TC_17_MOBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.554px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#tcm_TC_17_MOBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.tcm_TC_17_MOBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.526px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#tcm_TC_17_MOBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.tcm_TC_17_MOBshadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#tcm_TC_17_MOBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.tcm_TC_17_MOBshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#tcm_TC_17_MOBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.072px;
		overflow: visible;
	}
	#tcm_TC_17_MOBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBmed_shadow_space_website_7_ek:hover {
		fill: rgba(50,172,158,1);
	}
	.tcm_TC_17_MOBmed_shadow_space_website_7_ek:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#tcm_TC_17_MOBmed_shadow_space_website_7_ek {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.tcm_TC_17_MOBmed_shadow_space_website_7_ek {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#tcm_TC_17_MOBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.473px;
		overflow: visible;
	}
	#tcm_TC_17_MOBn_234567890_mobile_number_shad {
		left: 35px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 311px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#tcm_TC_17_MOBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#tcm_TC_17_MOBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#tcm_TC_17_MOBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcm_TC_17_MOBn_Copyright_Operate_Live_OPC_P {
		left: 93.322px;
		top: 664.381px;
		position: absolute;
		overflow: visible;
		width: 293px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}