
	.mediaViewInfo {
		--web-view-name: ShoeStride_Mob;
		--web-view-id: ShoeStride_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ShoeStride_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ShoeStride_Mob {
		position: absolute;
		width: 375px;
		height: 3925px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: ShoeStride_Mob;
		--web-view-id: ShoeStride_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#SS_31_MoBGroup_15140 {
		position: absolute;
		width: 1217px;
		height: 561px;
		left: 0px;
		top: 39px;
		overflow: visible;
	}
	#SS_31_MoBEllipse_44 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SS_31_MoBEllipse_44 {
		position: absolute;
		overflow: visible;
		width: 64px;
		height: 1px;
		left: 1153px;
		top: 247px;
	}
	#SS_31_MoBYour_Name {
		left: 17px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 235px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBDISCOVER_LIMITED__SHOES_WITHOU {
		left: 17px;
		top: 78px;
		position: absolute;
		overflow: visible;
		width: 308px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBGroup_15146 {
		position: absolute;
		width: 375px;
		height: 375px;
		left: 0px;
		top: 186px;
		overflow: visible;
	}
	#SS_31_MoBthe-dk-photography-b9e8ffVw5XY {
		position: absolute;
		width: 187px;
		height: 188px;
		left: 188px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_MoBthibaut-burckel-n2V5MLDPE-k-un {
		position: absolute;
		width: 188px;
		height: 188px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_MoBthe-dk-photography-A8HaTLON5Gg {
		position: absolute;
		width: 186px;
		height: 185px;
		left: 2px;
		top: 188px;
		overflow: visible;
	}
	#SS_31_MoBkyle-austin-vHIv0AreyDk-unspla {
		position: absolute;
		width: 187px;
		height: 187px;
		left: 188px;
		top: 188px;
		overflow: visible;
	}
	#SS_31_MoBGroup_15139 {
		position: absolute;
		width: 375px;
		height: 228px;
		left: 0px;
		top: 650px;
		overflow: visible;
	}
	#SS_31_MoBRectangle_752 {
		fill: rgba(5,5,5,1);
	}
	.SS_31_MoBRectangle_752 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 228px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBLorem_Ipsum_is_simply_dummy_te {
		left: 79px;
		top: 17px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 195px;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#SS_31_MoBGroup_15141 {
		position: absolute;
		width: 340px;
		height: 582px;
		left: 21px;
		top: 928px;
		overflow: visible;
	}
	#SS_31_MoBRectangle_752_bd {
		fill: rgba(5,5,5,1);
	}
	.SS_31_MoBRectangle_752_bd {
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 582px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBmarten-bjork-_ccRYJWcNwk-unspl {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#SS_31_MoBScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 326px;
		height: 154px;
		left: 14px;
		top: 409px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SS_31_MoBIt_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 312px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SS_31_MoBVISION {
		left: 14px;
		top: 363px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SS_31_MoBGroup_15147 {
		position: absolute;
		width: 340px;
		height: 582px;
		left: 18px;
		top: 1560px;
		overflow: visible;
	}
	#SS_31_MoBRectangle_752_bj {
		fill: rgba(5,5,5,1);
	}
	.SS_31_MoBRectangle_752_bj {
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 582px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBxavier-teo-SxAXphIPWeg-unsplas {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#SS_31_MoBScroll_Group_28_bl {
		mix-blend-mode: normal;
		position: absolute;
		width: 326px;
		height: 154px;
		left: 14px;
		top: 409px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SS_31_MoBIt_is_a_long_established_fact__bm {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 312px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SS_31_MoBMISSION {
		left: 14px;
		top: 363px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SS_31_MoBGroup_15148 {
		position: absolute;
		width: 340px;
		height: 582px;
		left: 18px;
		top: 2192px;
		overflow: visible;
	}
	#SS_31_MoBRectangle_752_bp {
		fill: rgba(5,5,5,1);
	}
	.SS_31_MoBRectangle_752_bp {
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 582px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBhanne-van-oeckel-4R2tRB7R5uQ-u {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#SS_31_MoBScroll_Group_28_br {
		mix-blend-mode: normal;
		position: absolute;
		width: 326px;
		height: 154px;
		left: 14px;
		top: 409px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SS_31_MoBIt_is_a_long_established_fact__bs {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 312px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SS_31_MoBPRINCIPLES {
		left: 18px;
		top: 363px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SS_31_MoBGroup_15149 {
		position: absolute;
		width: 336px;
		height: 38px;
		left: 21px;
		top: 2836px;
		overflow: visible;
	}
	#SS_31_MoBNEW_EDITION {
		left: 0px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 140px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBGroup_15145 {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 191px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_MoBRectangle_753 {
		fill: rgba(5,5,5,1);
	}
	.SS_31_MoBRectangle_753 {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBView_More {
		left: 38px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 71px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#SS_31_MoBScroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 359px;
		left: 0px;
		top: 2901px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#SS_31_MoBGroup_15144 {
		position: relative;
        margin-right: 30px;
		width: 221px;
		height: 301px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#SS_31_MoBRectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.SS_31_MoBRectangle_754 {
		filter: drop-shadow(5px 5px 1px rgba(235, 99, 99, 0.518));
		position: absolute;
		overflow: visible;
		width: 227.5px;
		height: 307.5px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBthibaut-burckel-n2V5MLDPE-k-un_b {
		position: absolute;
		width: 217px;
		height: 231px;
		left: 2px;
		top: 35px;
		overflow: visible;
	}
	#SS_31_MoBTitle {
		left: 6px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 210px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBn_10000 {
		left: 6px;
		top: 274px;
		position: absolute;
		overflow: visible;
		width: 210px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBGroup_15132 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 10px;
		top: 3320px;
		overflow: visible;
	}
	#SS_31_MoBGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 20px;
		top: 73.095px;
		overflow: visible;
	}
	#SS_31_MoBRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.SS_31_MoBRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#SS_31_MoBGroup_15050 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_MoBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 67px;
		top: 480.096px;
		overflow: visible;
	}
	#SS_31_MoBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_MoBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.SS_31_MoBmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#SS_31_MoBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.SS_31_MoBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SS_31_MoBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.SS_31_MoBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SS_31_MoBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.SS_31_MoBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SS_31_MoBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_MoBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.SS_31_MoBshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#SS_31_MoBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SS_31_MoBmed_shadow_space_website_7_ck {
		fill: rgba(255,255,255,1);
	}
	.SS_31_MoBmed_shadow_space_website_7_ck {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SS_31_MoBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.986px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#SS_31_MoBn_234567890_mobile_number_shad {
		left: 35px;
		top: 293.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 340.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBaddress_med_shadow_space_websi {
		left: 35px;
		top: 390.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 392.096px;
		overflow: visible;
	}
	#SS_31_MoBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 339.096px;
		overflow: visible;
	}
	#SS_31_MoBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 286.096px;
		overflow: visible;
	}
	#SS_31_MoBn_Copyright_Operate_Live_OPC_P {
		left: 17px;
		top: 565.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#SS_31_MoBContact_Us {
		left: 117.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -22.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}