	.mediaViewInfo {
		--web-view-name: BakersStop;
		--web-view-id: BakersStop;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: BakersStop;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#BakersStop {
		position: absolute;
		width: 1366px;
		height: 3880px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: BakersStop;
		--web-view-id: BakersStop;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#BCS_21_PCLine_20 {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_20 {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 309.618px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_21 {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_21 {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 323.87px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_22 {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_22 {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 338.121px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_23 {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_23 {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 352.373px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_24 {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_24 {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 366.625px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_25 {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_25 {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 380.876px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_20_p {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_20_p {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 305.917px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_21_q {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_21_q {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 319.998px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_22_r {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_22_r {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 334.08px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_23_s {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_23_s {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 348.161px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_24_t {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_24_t {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 362.242px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_25_u {
		opacity: 0.4;
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_25_u {
		overflow: visible;
		position: absolute;
		width: 6.337px;
		height: 3px;
		left: 0px;
		top: 376.324px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCCategory_Section {
		position: absolute;
		width: 1341px;
		height: 396px;
		left: 0px;
		top: 2903px;
		overflow: visible;
	}
	#BCS_21_PCRectangle_662 {
		fill: rgba(0,0,0,1);
	}
	.BCS_21_PCRectangle_662 {
		position: absolute;
		overflow: visible;
		width: 1316px;
		height: 5px;
		left: 25px;
		top: 0px;
	}
	#BCS_21_PCScroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1341px;
		height: 391px;
		left: 0px;
		top: 5px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow: hidden;
	}
	#BCS_21_PCn_ {
		position: absolute;
		width: 307px;
		height: 366px;
		left: 25px;
		top: 25px;
		overflow: visible;
	}
	#BCS_21_PCMask_Group_22 {
		position: relative;
		width: 307px;
		height: 366px;
		left: 0px;
		top: 0px;
        margin-right: 30px;
		overflow: visible;
	}
	#BCS_21_PCRectangle_664 {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCRectangle_664 {
		position: absolute;
		overflow: visible;
		width: 170px;
		height: 70px;
		left: 0px;
		top: 296px;
	}
	#BCS_21_PCHyundai_Azera_2020 {
		left: 5px;
		top: 310px;
		position: absolute;
		overflow: visible;
		width: 161px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
		text-transform: uppercase;
	}
	#BCS_21_PCGroup_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 131px;
		top: 268px;
		overflow: visible;
	}
	#BCS_21_PCEllipse_38 {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCEllipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_PCEllipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCEllipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#BCS_21_PCEllipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCEllipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#BCS_21_PCEllipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCEllipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#BCS_21_PCEllipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCEllipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#BCS_21_PCn_rd_Section {
		position: absolute;
		width: 1057px;
		height: 489px;
		left: 132px;
		top: 2128px;
		overflow: visible;
	}
	#BCS_21_PCScroll_Group_76 {
		mix-blend-mode: normal;
		position: absolute;
		width: 575px;
		height: 343px;
		left: 482px;
		top: 134px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BCS_21_PCScroll_Group_76::-webkit-scrollbar{
		display: none;
	}
	#BCS_21_PCBinQasem_provides_a_platform_o {
		left: 28px;
		top: 25px;
		position: absolute;
		overflow: visible;
		width: 520px;
		height: 293px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCPrinciple {
		left: 510px;
		top: 57px;
		position: absolute;
		overflow: visible;
		width: 230px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 46px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#BCS_21_PCMask_Group_23 {
		position: absolute;
		width: 427px;
		height: 489px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCn_rd_Section_bd {
		position: absolute;
		width: 1050px;
		height: 489px;
		left: 111px;
		top: 1554px;
		overflow: visible;
	}
	#BCS_21_PCScroll_Group_75 {
		mix-blend-mode: normal;
		position: absolute;
		width: 561px;
		height: 327px;
		left: 0px;
		top: 142px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BCS_21_PCScroll_Group_75::-webkit-scrollbar{
		display: none;
	}
	#BCS_21_PCBinQasem_provides_a_platform_o_bf {
		left: 21px;
		top: 17px;
		position: absolute;
		overflow: visible;
		width: 520px;
		height: 293px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCMission {
		left: 21px;
		top: 57px;
		position: absolute;
		overflow: visible;
		width: 197px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 46px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#BCS_21_PCMask_Group_23_bh {
		position: absolute;
		width: 427px;
		height: 489px;
		left: 623px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCn_rd_Section_bi {
		position: absolute;
		width: 1075px;
		height: 489px;
		left: 132px;
		top: 980px;
		overflow: visible;
	}
	#BCS_21_PCScroll_Group_74 {
		mix-blend-mode: normal;
		position: absolute;
		width: 588px;
		height: 315px;
		left: 487px;
		top: 148px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#BCS_21_PCScroll_Group_74::-webkit-scrollbar{
		display: none;
	}
	#BCS_21_PCBinQasem_provides_a_platform_o_bk {
		left: 23px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 520px;
		height: 293px;
		line-height: 30px;
		margin-top: -7px;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCVISION {
		left: 510px;
		top: 57px;
		position: absolute;
		overflow: visible;
		width: 158px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 46px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#BCS_21_PCMask_Group_23_bm {
		position: absolute;
		width: 427px;
		height: 489px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCour_services {
		left: 534px;
		top: 2813px;
		position: absolute;
		overflow: visible;
		width: 316px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 46px;
		color: rgba(34,34,34,1);
		text-transform: uppercase;
	}
	#BCS_21_PCGroup_15060 {
		position: absolute;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 3479px;
		overflow: visible;
	}
	#BCS_21_PCGroup_14807 {
		position: absolute;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCMask_Group_25 {
		position: absolute;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCRectangle_678 {
		opacity: 0.97;
		fill: rgba(50,50,50,1);
	}
	.BCS_21_PCRectangle_678 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_PCGroup_14850 {
		position: absolute;
		width: 566px;
		height: 365px;
		left: 744px;
		top: 25px;
		overflow: visible;
	}
	#BCS_21_PCn_2321397_luyk_98k2_201103 {
		position: absolute;
		width: 566px;
		height: 365px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCRectangle_680 {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCRectangle_680 {
		position: absolute;
		overflow: visible;
		width: 522px;
		height: 235px;
		left: 21px;
		top: 110px;
	}
	#BCS_21_PCRectangle_741 {
		fill: rgba(236,224,200,1);
	}
	.BCS_21_PCRectangle_741 {
		position: absolute;
		overflow: visible;
		width: 173px;
		height: 51px;
		left: 939px;
		top: 35px;
	}
	#BCS_21_PCBAKERY {
		left: 969px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 117px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe Script;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCGroup_15059 {
		position: absolute;
		width: 1365.56px;
		height: 204.113px;
		left: 0px;
		top: 172px;
		overflow: visible;
	}
	#BCS_21_PCFor_Queries_infoandroomedanet {
		left: 68.371px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 219px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#BCS_21_PCFor_call__920012888 {
		left: 68.371px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#BCS_21_PCtelephone {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCtelephone {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 41.845px;
		top: 25.525px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCsend_1 {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCsend_1 {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 41.845px;
		top: 4.72px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCLine_26 {
		fill: transparent;
		stroke: rgba(86,86,86,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCLine_26 {
		overflow: visible;
		position: absolute;
		width: 1365.56px;
		height: 2px;
		left: 0px;
		top: 204.113px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCsocials_grp_med_web_4_mob {
		position: absolute;
		width: 210px;
		height: 50.001px;
		left: 41.845px;
		top: 129.999px;
		overflow: visible;
	}
	#BCS_21_PClinked_in_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 160px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCEllipse_5_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCEllipse_5_med_web_4_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_PClinked_in_website_4_layout {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#BCS_21_PCn_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.BCS_21_PCn_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.954px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PClinked_in_i_down {
		fill: rgba(63,61,86,1);
	}
	.BCS_21_PClinked_in_i_down {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.097px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCtop_dot_i_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.BCS_21_PCtop_dot_i_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCInsta_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 80px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCEllipse_4_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCEllipse_4_med_web_4_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_PCinstagram_website_4_layout {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.126px;
		overflow: visible;
	}
	#BCS_21_PCwhatsapp_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#BCS_21_PCEllipse_3_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCEllipse_3_med_web_4_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_PCwhatsapp_lbl_website4_layout {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#BCS_21_PCIcon_metro-location {
		fill: rgba(255,255,255,1);
	}
	.BCS_21_PCIcon_metro-location {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 18.209px;
		left: 41.845px;
		top: 46.33px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BCS_21_PCaddress {
		left: 68.371px;
		top: 45.539px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 62px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#BCS_21_PCGroup_14851 {
		position: absolute;
		width: 1299px;
		height: 802px;
		left: 34px;
		top: 70px;
		overflow: visible;
	}
	#BCS_21_PCRectangle_655 {
		fill: rgba(246,246,246,1);
	}
	.BCS_21_PCRectangle_655 {
		position: absolute;
		overflow: visible;
		width: 764px;
		height: 595px;
		left: 470px;
		top: 207px;
	}
	#BCS_21_PCAlways_Find_The_Best_Deals {
		left: 8px;
		top: 128px;
		position: absolute;
		overflow: visible;
		width: 125px;
		white-space: nowrap;
		text-align: right;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCSocial {
		position: absolute;
		width: 266px;
		height: 12px;
		left: 588px;
		top: 559px;
		overflow: visible;
	}
	#BCS_21_PCFacebook {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 45px;
		white-space: nowrap;
		text-align: right;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCInstagram {
		left: 78px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 48px;
		white-space: nowrap;
		text-align: right;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCTwitter {
		left: 159px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 32px;
		white-space: nowrap;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCSnapchat {
		left: 222px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 45px;
		white-space: nowrap;
		text-align: left;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCBEST_IN__CLASS_ {
		left: 0px;
		top: 158px;
		position: absolute;
		overflow: visible;
		width: 300px;
		white-space: nowrap;
		line-height: 83px;
		margin-top: -1px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 81px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCMask_Group_20 {
		position: absolute;
		width: 447px;
		height: 510px;
		left: 411px;
		top: 0px;
		overflow: visible;
	}
	#BCS_21_PCMask_Group_21 {
		position: absolute;
		width: 398px;
		height: 395px;
		left: 901px;
		top: 229px;
		overflow: visible;
	}
	#BCS_21_PCSell_btn {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 8px;
		top: 380px;
		overflow: visible;
	}
	#BCS_21_PCRectangle_659 {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1.5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.BCS_21_PCRectangle_659 {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: 0px;
	}
	#BCS_21_PCGet_it_now {
		left: 33px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 67px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#BCS_21_PCNAME {
		left: 8px;
		top: 36px;
		position: absolute;
		overflow: visible;
		width: 70px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe Script;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(34,34,34,1);
	}
	#BCS_21_PCContact_us_ {
		left: 209px;
		top: 3526px;
		position: absolute;
		overflow: visible;
		width: 203px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}