#task_pop_up_calender_page {
    position: absolute;
    width: 250px;
    height: 550px;
    left: 36px;
    top: 65px;
    overflow: visible;
}
#loca_grp_add_task_pop_up {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 373px;
    overflow: visible;
}
#location_bdr_add_task_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.location_bdr_add_task_pop_up {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Location_lbl_add_task_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#ends_at_grp_add_task_pop_up {
    position: absolute;
    width: 250px;
    height: 63px;
    left: 0px;
    top: 224px;
    overflow: visible;
}
#end_at_bdr_add_task_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.end_at_bdr_add_task_pop_up {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#end_at_lbl_add_task_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 58px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#start_at_grp_add_task_pop_up {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 150px;
    overflow: visible;
}
#start_at_bdr_add_event_details {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.start_at_bdr_add_event_details {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#start_at_lbl_add_task_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#category_grp_add_task_pop_up {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 299px;
    overflow: visible;
}
#category_bdr_add_task_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.category_bdr_add_task_pop_up {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Category_lbl_add_task_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#description_grp_add_task_pop_u {
    position: absolute;
    width: 250px;
    height: 103px;
    left: 0px;
    top: 447px;
    overflow: visible;
}
#descp_bdr_add_task_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.descp_bdr_add_task_pop_up {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 80px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Description_lbl_add_task_pop_u {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 86px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}

#titile_grp_add_task_pop_up {
    position: absolute;
    width: 250px;
    height: 63px;
    left: 0px;
    top: 75px;
    overflow: visible;
}
#title_bdr_add_task_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.title_bdr_add_task_pop_up {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#title_lbl_add_task_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}