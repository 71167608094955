	.mediaViewInfo {
		--web-view-name: TechTrekker;
		--web-view-id: TechTrekker;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: TechTrekker;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#TechTrekker {
		position: absolute;
		width: 1366px;
		height: 4273px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: TechTrekker;
		--web-view-id: TechTrekker;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#TTGroup_15169 {
		position: absolute;
		width: 1545px;
		height: 775.366px;
		left: 17px;
		top: -42px;
		overflow: visible;
	}
	#TTquaritsch-photography-m2zuB8Dq {
		position: absolute;
		width: 1162px;
		height: 775.366px;
		left: 383px;
		top: 0px;
		overflow: visible;
	}
	#TTBottom_left_text {
		position: absolute;
		width: 377px;
		height: 180px;
		left: 87px;
		top: 540px;
		overflow: visible;
	}
	#TTNEW_GADGETS {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 131px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(114,124,137,1);
		letter-spacing: 2.2px;
	}
	#TTBuy_Now {
		left: 3px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: lighter;
		font-size: 13px;
		color: rgba(114,124,137,1);
	}
	#TTView_details {
		left: 257px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 80px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(33,109,252,1);
	}
	#TTCompany_Name {
		left: 0px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 378px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 46px;
		color: rgba(0,28,65,1);
	}
	#TTEllipse_3 {
		fill: rgba(33,109,252,1);
	}
	.TTEllipse_3 {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 5px;
		top: 169px;
	}
	#TTEllipse_4 {
		fill: rgba(214,217,224,1);
	}
	.TTEllipse_4 {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 40px;
		top: 169px;
	}
	#TTEllipse_5 {
		fill: rgba(214,217,224,1);
	}
	.TTEllipse_5 {
		position: absolute;
		overflow: visible;
		width: 10px;
		height: 11px;
		left: 76px;
		top: 169px;
	}
	#TTPath_6 {
		fill: rgba(33,109,252,1);
	}
	.TTPath_6 {
		overflow: visible;
		position: absolute;
		width: 8.108px;
		height: 12.012px;
		transform: translate(0.453px, 0.362px) matrix(1,0,0,1,347.8805,120.3858) rotate(180deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#TTLine_1 {
		fill: transparent;
		stroke: rgba(190,190,190,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTLine_1 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 15.427px;
		left: 238.231px;
		top: 118.748px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTBottom_right_items {
		position: absolute;
		width: 54px;
		height: 120px;
		left: 1260px;
		top: 639px;
		overflow: visible;
	}
	#TTRectangle_15 {
		fill: rgba(234,238,244,1);
	}
	.TTRectangle_15 {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 49px;
		left: 0px;
		top: 71px;
	}
	#TTRectangle_16 {
		fill: rgba(255,88,178,0.141);
	}
	.TTRectangle_16 {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#TThand-heart {
		position: absolute;
		width: 19.034px;
		height: 16.73px;
		left: 17.826px;
		top: 16.862px;
		overflow: visible;
	}
	#TTPath_5 {
		fill: rgba(255,88,178,0);
		stroke: rgba(255,88,178,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: square;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.TTPath_5 {
		overflow: visible;
		position: absolute;
		width: 23.104px;
		height: 21.633px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTquestion-mark {
		position: absolute;
		width: 13.332px;
		height: 22.073px;
		left: 20.327px;
		top: 84.828px;
		overflow: visible;
	}
	#TTPath_7 {
		fill: rgba(93,106,126,1);
	}
	.TTPath_7 {
		overflow: visible;
		position: absolute;
		width: 13.332px;
		height: 16.555px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_8 {
		fill: rgba(93,106,126,1);
	}
	.TTPath_8 {
		overflow: visible;
		position: absolute;
		width: 3.678px;
		height: 3.678px;
		left: 4.503px;
		top: 18.395px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTControllers {
		position: absolute;
		width: 675px;
		height: 255px;
		left: 0px;
		top: 212px;
		overflow: visible;
	}
	#TTRectangle_1 {
		opacity: 0.35;
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTRectangle_1 {
		position: absolute;
		overflow: visible;
		width: 175px;
		height: 248px;
		left: 0px;
		top: 0px;
	}
	#TTRectangle_2 {
		opacity: 0.35;
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTRectangle_2 {
		position: absolute;
		overflow: visible;
		width: 174px;
		height: 248px;
		left: 246px;
		top: 0px;
	}
	#TTRectangle_3 {
		opacity: 0.35;
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTRectangle_3 {
		position: absolute;
		overflow: visible;
		width: 174px;
		height: 248px;
		left: 492px;
		top: 0px;
	}
	#TTstefen-tan-KYw1eUx1J7Y-unsplas {
		position: absolute;
		width: 192px;
		height: 255px;
		left: 483px;
		top: 0px;
		overflow: visible;
	}
	#TTn_a36d7d2482866597129061513543 {
		position: absolute;
		width: 131px;
		height: 196px;
		left: 22px;
		top: 26px;
		overflow: visible;
	}
	#TTryan-putra-j4PqlNVZ4Bc-unsplas {
		position: absolute;
		width: 165.269px;
		height: 248px;
		left: 246.648px;
		top: 0px;
		overflow: visible;
	}
	#TTjoshua-newton-g4_IymCiD-k-unsp {
		position: absolute;
		width: 61px;
		height: 61px;
		left: 75px;
		top: 78px;
		overflow: visible;
	}
	#TTService {
		position: absolute;
		width: 1366px;
		height: 1413px;
		left: 0px;
		top: 1260px;
		overflow: visible;
	}
	#TTRectangle_46 {
		fill: rgba(251,252,253,1);
	}
	.TTRectangle_46 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 1413px;
		left: 0px;
		top: 0px;
	}
	#TTOUR_SERVICES {
		left: 613px;
		top: 64px;
		position: absolute;
		overflow: visible;
		width: 141px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -30px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(61,55,241,1);
	}
	#TTWe_Provide_Best_Quality_Gadget {
		left: 391px;
		top: 105px;
		position: absolute;
		overflow: visible;
		width: 585px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -20px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(90,84,84,1);
	}
	#TTGroup_15176 {
		position: absolute;
		width: 370px;
		height: 451px;
		left: 98px;
		top: 349px;
		overflow: visible;
	}
	#TTRectangle_47_bn {
		fill: url(#TTRectangle_47_bn);
	}
	.TTRectangle_47_bn {
		filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 490px;
		height: 571px;
		left: 0px;
		top: 0px;
	}
	#TTOur_Services {
		left: 116.613px;
		top: 244.936px;
		position: absolute;
		overflow: visible;
		width: 123px;
		white-space: nowrap;
		line-height: 54px;
		margin-top: -16px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(255,255,255,1);
	}
	#TTFast_Powerful__Most_Secure_Net {
		left: 21px;
		top: 285.045px;
		position: absolute;
		overflow: visible;
		width: 329px;
		height: 62px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#TTRectangle_50 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_50 {
		filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 191px;
		height: 105px;
		left: 119px;
		top: 358px;
	}
	#TTRead_More {
		left: 149px;
		top: 370px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTIcon1 {
		position: absolute;
		width: 100.682px;
		height: 100.916px;
		left: 135px;
		top: 84px;
		overflow: visible;
	}
	#TTPath_1202 {
		fill: rgba(255,255,255,1);
	}
	.TTPath_1202 {
		overflow: visible;
		position: absolute;
		width: 82.914px;
		height: 85.813px;
		left: 0.001px;
		top: 15.103px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1203 {
		fill: rgba(255,255,255,1);
	}
	.TTPath_1203 {
		overflow: visible;
		position: absolute;
		width: 33.005px;
		height: 33.057px;
		left: 67.677px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTRectangle_92 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_92 {
		position: absolute;
		overflow: visible;
		width: 19.803px;
		height: 3.301px;
		left: 74.278px;
		top: 6.601px;
	}
	#TTRectangle_93 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_93 {
		position: absolute;
		overflow: visible;
		width: 13.202px;
		height: 3.301px;
		left: 74.278px;
		top: 13.202px;
	}
	#TTRectangle_94 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_94 {
		position: absolute;
		overflow: visible;
		width: 3.3px;
		height: 3.301px;
		left: 90.781px;
		top: 13.202px;
	}
	#TTPath_1204 {
		fill: rgba(255,255,255,1);
	}
	.TTPath_1204 {
		overflow: visible;
		position: absolute;
		width: 64.612px;
		height: 41.87px;
		left: 0px;
		top: 4.951px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1205 {
		fill: rgba(255,255,255,1);
	}
	.TTPath_1205 {
		overflow: visible;
		position: absolute;
		width: 17.019px;
		height: 49.513px;
		left: 82.862px;
		top: 29.925px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTGroup_15177 {
		position: absolute;
		width: 370px;
		height: 451px;
		left: 497px;
		top: 349px;
		overflow: visible;
	}
	#TTRectangle_51 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_51 {
		filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 490px;
		height: 571px;
		left: 0px;
		top: 0px;
	}
	#TTSecurity_Solutions {
		left: 90.613px;
		top: 244.936px;
		position: absolute;
		overflow: visible;
		width: 175px;
		white-space: nowrap;
		line-height: 54px;
		margin-top: -16px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(90,84,84,1);
	}
	#TTFast_Powerful__Most_Secure_Net_b {
		left: 21px;
		top: 285.045px;
		position: absolute;
		overflow: visible;
		width: 329px;
		height: 62px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(116,116,116,1);
	}
	#TTRectangle_52 {
		fill: transparent;
		stroke: rgba(236,227,227,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTRectangle_52 {
		position: absolute;
		overflow: visible;
		width: 131px;
		height: 45px;
		left: 119px;
		top: 358px;
	}
	#TTRead_More_b {
		left: 149px;
		top: 370px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTIcon2 {
		position: absolute;
		width: 100px;
		height: 100px;
		left: 135px;
		top: 85px;
		overflow: visible;
	}
	#TTPath_1206 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1206 {
		overflow: visible;
		position: absolute;
		width: 63.124px;
		height: 92.246px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1207 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1207 {
		overflow: visible;
		position: absolute;
		width: 63.16px;
		height: 92.245px;
		left: 36.84px;
		top: 7.755px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1208 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1208 {
		overflow: visible;
		position: absolute;
		width: 3.928px;
		height: 3.913px;
		left: 30.141px;
		top: 92.648px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1209 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1209 {
		overflow: visible;
		position: absolute;
		width: 3.939px;
		height: 3.92px;
		left: 65.999px;
		top: 3.466px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1210 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1210 {
		overflow: visible;
		position: absolute;
		width: 78.906px;
		height: 50.245px;
		left: 10.547px;
		top: 18.7px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1211 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1211 {
		overflow: visible;
		position: absolute;
		width: 32.813px;
		height: 42.883px;
		left: 33.594px;
		top: 36.414px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1212 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1212 {
		overflow: visible;
		position: absolute;
		width: 11.236px;
		height: 15.623px;
		left: 44.36px;
		top: 56.838px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTGroup_15178 {
		position: absolute;
		width: 370px;
		height: 451px;
		left: 897px;
		top: 349px;
		overflow: visible;
	}
	#TTRectangle_53 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_53 {
		filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 490px;
		height: 571px;
		left: 0px;
		top: 0px;
	}
	#TTSound_Systems {
		left: 104.613px;
		top: 244.936px;
		position: absolute;
		overflow: visible;
		width: 147px;
		white-space: nowrap;
		line-height: 54px;
		margin-top: -16px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(90,84,84,1);
	}
	#TTFast_Powerful__Most_Secure_Net_ch {
		left: 21px;
		top: 285.045px;
		position: absolute;
		overflow: visible;
		width: 329px;
		height: 62px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(116,116,116,1);
	}
	#TTRectangle_54 {
		fill: transparent;
		stroke: rgba(236,227,227,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTRectangle_54 {
		position: absolute;
		overflow: visible;
		width: 131px;
		height: 45px;
		left: 119px;
		top: 358px;
	}
	#TTRead_More_cj {
		left: 149px;
		top: 370px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTIcon3 {
		position: absolute;
		width: 100.775px;
		height: 99px;
		left: 135px;
		top: 85px;
		overflow: visible;
	}
	#TTPath_1213 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1213 {
		overflow: visible;
		position: absolute;
		width: 43.499px;
		height: 11.35px;
		left: 28.638px;
		top: 43.661px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1214 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1214 {
		overflow: visible;
		position: absolute;
		width: 30.497px;
		height: 8.657px;
		left: 35.139px;
		top: 52.853px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTGroup_296 {
		position: absolute;
		width: 100.775px;
		height: 99px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTGroup_295 {
		position: absolute;
		width: 100.775px;
		height: 99px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTGroup_15171 {
		position: absolute;
		width: 370px;
		height: 451px;
		left: 299px;
		top: 850px;
		overflow: visible;
	}
	#TTRectangle_57 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_57 {
		filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 490px;
		height: 571px;
		left: 0px;
		top: 0px;
	}
	#TTPeripherals {
		left: 123.613px;
		top: 244.936px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		line-height: 54px;
		margin-top: -16px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(90,84,84,1);
	}
	#TTFast_Powerful__Most_Secure_Net_cs {
		left: 21px;
		top: 285.045px;
		position: absolute;
		overflow: visible;
		width: 329px;
		height: 62px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(116,116,116,1);
	}
	#TTRectangle_58 {
		fill: transparent;
		stroke: rgba(236,227,227,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTRectangle_58 {
		position: absolute;
		overflow: visible;
		width: 131px;
		height: 45px;
		left: 119px;
		top: 358px;
	}
	#TTRead_More_cu {
		left: 149px;
		top: 370px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTIcon4 {
		position: absolute;
		width: 100.593px;
		height: 100.595px;
		left: 135px;
		top: 87px;
		overflow: visible;
	}
	#TTPath_1217 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1217 {
		overflow: visible;
		position: absolute;
		width: 3.929px;
		height: 3.93px;
		left: 20.18px;
		top: 50.168px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1218 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1218 {
		overflow: visible;
		position: absolute;
		width: 5.631px;
		height: 3.93px;
		left: 29.563px;
		top: 50.168px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1219 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1219 {
		overflow: visible;
		position: absolute;
		width: 3.929px;
		height: 3.93px;
		left: 38.948px;
		top: 50.168px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1220 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1220 {
		overflow: visible;
		position: absolute;
		width: 3.93px;
		height: 3.93px;
		left: 48.332px;
		top: 50.168px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1221 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1221 {
		overflow: visible;
		position: absolute;
		width: 3.93px;
		height: 3.93px;
		left: 57.715px;
		top: 50.168px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1222 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1222 {
		overflow: visible;
		position: absolute;
		width: 3.929px;
		height: 3.93px;
		left: 67.1px;
		top: 50.168px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1223 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1223 {
		overflow: visible;
		position: absolute;
		width: 3.93px;
		height: 3.93px;
		left: 76.484px;
		top: 50.168px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1224 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1224 {
		overflow: visible;
		position: absolute;
		width: 5.482px;
		height: 3.93px;
		left: 10.609px;
		top: 60.155px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1225 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1225 {
		overflow: visible;
		position: absolute;
		width: 5.481px;
		height: 3.93px;
		left: 21.556px;
		top: 60.155px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1226 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1226 {
		overflow: visible;
		position: absolute;
		width: 3.928px;
		height: 3.93px;
		left: 30.967px;
		top: 60.155px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1227 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1227 {
		overflow: visible;
		position: absolute;
		width: 3.929px;
		height: 3.93px;
		left: 40.473px;
		top: 60.155px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1228 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1228 {
		overflow: visible;
		position: absolute;
		width: 3.93px;
		height: 3.929px;
		left: 21.815px;
		top: 69.618px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1229 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1229 {
		overflow: visible;
		position: absolute;
		width: 3.928px;
		height: 3.929px;
		left: 31.323px;
		top: 69.618px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1230 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1230 {
		overflow: visible;
		position: absolute;
		width: 7.861px;
		height: 3.93px;
		left: 52.263px;
		top: 60.155px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1231 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1231 {
		overflow: visible;
		position: absolute;
		width: 14.605px;
		height: 3.929px;
		left: 41.979px;
		top: 69.618px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1232 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1232 {
		overflow: visible;
		position: absolute;
		width: 5.505px;
		height: 3.93px;
		left: 84.478px;
		top: 60.155px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1233 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1233 {
		overflow: visible;
		position: absolute;
		width: 3.929px;
		height: 3.93px;
		left: 37.919px;
		top: 10.61px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1234 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1234 {
		overflow: visible;
		position: absolute;
		width: 100.593px;
		height: 89.985px;
		left: 0px;
		top: 10.61px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1235 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1235 {
		overflow: visible;
		position: absolute;
		width: 34.579px;
		height: 35.365px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1236 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1236 {
		overflow: visible;
		position: absolute;
		width: 17.008px;
		height: 3.93px;
		left: 8.785px;
		top: 23.574px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1237 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1237 {
		overflow: visible;
		position: absolute;
		width: 17.008px;
		height: 3.93px;
		left: 8.785px;
		top: 15.718px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1238 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1238 {
		overflow: visible;
		position: absolute;
		width: 17.008px;
		height: 3.93px;
		left: 8.785px;
		top: 7.86px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTGroup_15170 {
		position: absolute;
		width: 370px;
		height: 451px;
		left: 698px;
		top: 850px;
		overflow: visible;
	}
	#TTRectangle_55 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_55 {
		filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 490px;
		height: 571px;
		left: 0px;
		top: 0px;
	}
	#TTPrinters_Projectors__Scanners {
		left: 28.613px;
		top: 244.936px;
		position: absolute;
		overflow: visible;
		width: 299px;
		white-space: nowrap;
		line-height: 54px;
		margin-top: -16px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(90,84,84,1);
	}
	#TTFast_Powerful__Most_Secure_Net_dl {
		left: 21px;
		top: 285.045px;
		position: absolute;
		overflow: visible;
		width: 329px;
		height: 62px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(116,116,116,1);
	}
	#TTRectangle_60 {
		fill: transparent;
		stroke: rgba(236,227,227,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTRectangle_60 {
		position: absolute;
		overflow: visible;
		width: 131px;
		height: 45px;
		left: 119px;
		top: 358px;
	}
	#TTRead_More_dn {
		left: 149px;
		top: 370px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTIcon6 {
		position: absolute;
		width: 100.387px;
		height: 100.387px;
		left: 135px;
		top: 87px;
		overflow: visible;
	}
	#TTPath_1247 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1247 {
		overflow: visible;
		position: absolute;
		width: 100.387px;
		height: 100.387px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTPath_1248 {
		fill: rgba(61,55,241,1);
	}
	.TTPath_1248 {
		overflow: visible;
		position: absolute;
		width: 15.058px;
		height: 15.058px;
		left: 60.232px;
		top: 55.213px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTRectangle_95 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_95 {
		position: absolute;
		overflow: visible;
		width: 15.058px;
		height: 5.02px;
		left: 10.039px;
		top: 55.213px;
	}
	#TTRectangle_96 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_96 {
		position: absolute;
		overflow: visible;
		width: 15.058px;
		height: 5.02px;
		left: 10.039px;
		top: 65.251px;
	}
	#TTRectangle_97 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_97 {
		position: absolute;
		overflow: visible;
		width: 5.02px;
		height: 5.02px;
		left: 30.116px;
		top: 65.251px;
	}
	#TTRectangle_98 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_98 {
		position: absolute;
		overflow: visible;
		width: 5.02px;
		height: 5.02px;
		left: 30.116px;
		top: 55.213px;
	}
	#TTRectangle_99 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_99 {
		position: absolute;
		overflow: visible;
		width: 15.058px;
		height: 5.02px;
		left: 10.039px;
		top: 45.174px;
	}
	#TTRectangle_100 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_100 {
		position: absolute;
		overflow: visible;
		width: 5.02px;
		height: 5.02px;
		left: 30.116px;
		top: 45.174px;
	}
	#TTRectangle_101 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_101 {
		position: absolute;
		overflow: visible;
		width: 15.058px;
		height: 5.02px;
		left: 55.213px;
		top: 25.097px;
	}
	#TTRectangle_102 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_102 {
		position: absolute;
		overflow: visible;
		width: 5.02px;
		height: 5.02px;
		left: 75.291px;
		top: 25.097px;
	}
	#TTBrands {
		position: absolute;
		width: 1366px;
		height: 283px;
		left: 0px;
		top: 873px;
		overflow: visible;
	}
	#TTRectangle_79 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_79 {
		filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.039));
		position: absolute;
		overflow: visible;
		width: 1516px;
		height: 433px;
		left: 0px;
		top: 0px;
	}
	#TTScroll_Group_97 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1295px;
		height: 212px;
		left: 30px;
		top: 71px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#TTLogitech-logo-300x300 {
		opacity: 0.61;
		position: absolute;
		width: 188px;
		height: 188px;
		left: 56px;
		top: 12px;
		overflow: visible;
	}
	#TTOur_Partners {
		left: 570px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 227px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		color: rgba(0,28,65,1);
	}
	#TTTop_pick {
		position: absolute;
		width: 1209px;
		height: 927.793px;
		left: 98px;
		top: 2752px;
		overflow: visible;
	}
	#TTTitle {
		position: absolute;
		width: 228px;
		height: 87px;
		left: 0px;
		top: 85px;
		overflow: visible;
	}
	#TTTop_Picks {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 169px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -20px;
		text-align: left;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(90,84,84,1);
	}
	#TTWe_Provide_Best_Quality {
		left: 0px;
		top: 58px;
		position: absolute;
		overflow: visible;
		width: 229px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -29px;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: lighter;
		font-size: 22px;
		color: rgba(116,116,116,1);
	}
	#TTMenu {
		position: absolute;
		width: 198px;
		height: 37px;
		left: 0px;
		top: 264px;
		overflow: visible;
	}
	#TTAll_Parts {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 85px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -29px;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(61,55,241,1);
	}
	#TTRectangle_115 {
		fill: rgba(61,55,241,1);
	}
	.TTRectangle_115 {
		position: absolute;
		overflow: visible;
		width: 198px;
		height: 2px;
		left: 0px;
		top: 35px;
	}
	#TTScroll_Group_96 {
		mix-blend-mode: normal;
		position: absolute;
		width: 840px;
		height: 927.793px;
		left: 369px;
		top: 0px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#TTn_ {
		position: absolute;
		width: 370px;
		height: 410.793px;
		left: 431px;
		top: 466px;
		overflow: visible;
	}
	#TTPath_1297 {
		fill: rgba(255,255,255,1);
	}
	.TTPath_1297 {
		overflow: visible;
		position: absolute;
		width: 370px;
		height: 410.793px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTProduct_4 {
		left: 30px;
		top: 234.97px;
		position: absolute;
		overflow: visible;
		width: 311px;
		height: 62px;
		line-height: 26px;
		margin-top: -2px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(90,84,84,1);
	}
	#TTRead_more {
		position: absolute;
		width: 131px;
		height: 45px;
		left: 115px;
		top: 305px;
		overflow: visible;
	}
	#TTRectangle_117 {
		fill: rgba(241,241,255,1);
	}
	.TTRectangle_117 {
		position: absolute;
		overflow: visible;
		width: 131px;
		height: 45px;
		left: 0px;
		top: 0px;
	}
	#TTRead_More_eh {
		left: 30px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTbrand-identity {
		position: absolute;
		width: 152px;
		height: 131px;
		left: 109px;
		top: 50px;
		overflow: visible;
	}
	#TTn__ej {
		position: absolute;
		width: 370px;
		height: 410.793px;
		left: 31px;
		top: 466px;
		overflow: visible;
	}
	#TTPath_1191 {
		fill: rgba(255,255,255,1);
	}
	.TTPath_1191 {
		overflow: visible;
		position: absolute;
		width: 370px;
		height: 410.793px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTProduct_3 {
		left: 30px;
		top: 234.97px;
		position: absolute;
		overflow: visible;
		width: 311px;
		height: 62px;
		line-height: 26px;
		margin-top: -2px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(90,84,84,1);
	}
	#TTbrand-identity_em {
		position: absolute;
		width: 184px;
		height: 157px;
		left: 88px;
		top: 36px;
		overflow: visible;
	}
	#TTRead_more_en {
		position: absolute;
		width: 131px;
		height: 45px;
		left: 115px;
		top: 305px;
		overflow: visible;
	}
	#TTRectangle_87 {
		fill: rgba(241,241,255,1);
	}
	.TTRectangle_87 {
		position: absolute;
		overflow: visible;
		width: 131px;
		height: 45px;
		left: 0px;
		top: 0px;
	}
	#TTRead_More_ep {
		left: 30px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTn__eq {
		position: absolute;
		width: 370px;
		height: 410.793px;
		left: 431px;
		top: 25px;
		overflow: visible;
	}
	#TTPath_1191_er {
		fill: rgba(255,255,255,1);
	}
	.TTPath_1191_er {
		overflow: visible;
		position: absolute;
		width: 370px;
		height: 410.793px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTProduct_2 {
		left: 30px;
		top: 234.97px;
		position: absolute;
		overflow: visible;
		width: 311px;
		height: 62px;
		line-height: 26px;
		margin-top: -2px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(90,84,84,1);
	}
	#TTRead_more_et {
		position: absolute;
		width: 131px;
		height: 45px;
		left: 115px;
		top: 305px;
		overflow: visible;
	}
	#TTRectangle_87_eu {
		fill: rgba(241,241,255,1);
	}
	.TTRectangle_87_eu {
		position: absolute;
		overflow: visible;
		width: 131px;
		height: 45px;
		left: 0px;
		top: 0px;
	}
	#TTRead_More_ev {
		left: 30px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTbrand-identity_ew {
		position: absolute;
		width: 152px;
		height: 131px;
		left: 109px;
		top: 50px;
		overflow: visible;
	}
	#TTn__ex {
		position: absolute;
		width: 370px;
		height: 410.793px;
		left: 31px;
		top: 25px;
		overflow: visible;
	}
	#TTPath_1191_ey {
		fill: rgba(255,255,255,1);
	}
	.TTPath_1191_ey {
		overflow: visible;
		position: absolute;
		width: 370px;
		height: 410.793px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTProduct_1 {
		left: 30px;
		top: 234.97px;
		position: absolute;
		overflow: visible;
		width: 311px;
		height: 62px;
		line-height: 26px;
		margin-top: -2px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(90,84,84,1);
	}
	#TTRead_more_e {
		position: absolute;
		width: 131px;
		height: 45px;
		left: 115px;
		top: 305px;
		overflow: visible;
	}
	#TTRectangle_87_e {
		fill: rgba(241,241,255,1);
	}
	.TTRectangle_87_e {
		position: absolute;
		overflow: visible;
		width: 131px;
		height: 45px;
		left: 0px;
		top: 0px;
	}
	#TTRead_More_e {
		left: 30px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -32.5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(75,100,126,1);
	}
	#TTbrand-identity_e {
		position: absolute;
		width: 174px;
		height: 149px;
		left: 98px;
		top: 50px;
		overflow: visible;
	}
	#TTGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 487.998px;
		left: 135.5px;
		top: 3768.427px;
		overflow: visible;
	}
	#TTGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 114.997px;
		overflow: visible;
	}
	#TTGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.TTRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#TTsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#TTlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#TTmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TTmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TTlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#TTn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.TTn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.TTlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.TTtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#TTshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TTshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TTinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#TTwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTmed_shadow_space_website_7_fj {
		fill: rgba(255,255,255,1);
	}
	.TTmed_shadow_space_website_7_fj {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TTwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#TTn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TTaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TTaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TTlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#TTemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#TTphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#TTn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#TTContact_Us {
		left: 510.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
