	.mediaViewInfo {
		--web-view-name: pop up offering;
		--web-view-id: pop_up_offering;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: pop_up_offering;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#pop_up_offering {
		position: absolute;
		width: 308px;
		height: 697px;
        top: 60%;
        left: 50%;
        border-radius: 15px;
        transform: translate(-50%, -50%); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: pop up offering;
		--web-view-id: pop_up_offering;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#top_bdr_green_i {
		fill: url(#top_bdr_green_i);
	}
	.top_bdr_green_i {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 308px;
		height: 77px;
		left: 0px;
		top: 0px;
	}
	#serivs_promise_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.serivs_promise_ {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 17px;
		top: 175px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#budget_amount__offerings_pop {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.budget_amount__offerings_pop {
		position: absolute;
		overflow: visible;
		width: 148px;
		height: 41px;
		left: 17px;
		top: 226px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#idk_offerings_pop {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.idk_offerings_pop {
		position: absolute;
		overflow: visible;
		width: 109px;
		height: 41px;
		left: 176px;
		top: 226px;
		border: 2px solid darkcyan;
	}
	#estimated_duration_offerings_p {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.estimated_duration_offerings_p {
		position: absolute;
		overflow: visible;
		width: 148px;
		height: 41px;
		left: 17px;
		top: 281px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#hrs_place_offerings_pop {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.hrs_place_offerings_pop {
		position: absolute;
		overflow: visible;
		width: 109px;
		height: 41px;
		left: 176px;
		top: 281px;
		border: 2px solid darkcyan;
	}
	#del_loc_offerings_pop {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.del_loc_offerings_pop {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 17px;
		top: 386px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#min_order_quantity_offerings_p {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.min_order_quantity_offerings_p {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 17px;
		top: 441px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#desc_offerings_pop {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.desc_offerings_pop {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 63px;
		left: 17px;
		top: 551px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#Offerings_lbl_offerings_pop {
		left: 113px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(37,37,37,1);
	}
	#seb_grp_offerings_pop:hover {
		left: 107px;
		top: 641px;
	}
	#seb_grp_offerings_pop {
		position: absolute;
		width: 97px;
		height: 32px;
		left: 104px;
		top: 636px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#bdr_sub_offerings_pop {
		fill: rgba(219,242,231,1);
	}
	.bdr_sub_offerings_pop {
		position: absolute;
		overflow: visible;
		width: 97px;
		height: 32px;
		left: 0px;
		top: 0px;
	}
	#Submit_offerings_pop {
		left: 17px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 64px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(37,37,37,1);
	}
	#grp_off_upload_offerings_pop {
		position: absolute;
		width: 170px;
		height: 94.284px;
		left: 69px;
		top: 38px;
		overflow: visible;
	}
	#profile_3_offerings_pop {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 50px;
		top: 0px;
		overflow: visible;
	}
	#profile_2_offerings_pop {
		position: absolute;
		width: 42px;
		height: 42px;
		left: 128px;
		top: 14px;
		overflow: visible;
	}
	#profile_1_offerings_pop {
		position: absolute;
		width: 42px;
		height: 42px;
		left: 0px;
		top: 14px;
		overflow: visible;
	}
	#add_offerings_pop {
		position: absolute;
		width: 28.284px;
		height: 28.284px;
		left: 100px;
		top: 66px;
		overflow: visible;
	}
	#cancel_offerings_pop {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 50.142px;
		top: 70px;
		overflow: visible;
	}
	#accept_offerings_pop {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 77.142px;
		top: 70.142px;
		overflow: visible;
	}
	#close2_offerings_pop {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 280.142px;
		top: 6.5px;
        cursor: pointer;
		overflow: visible;
	}
	#pro_and_ser_grp_off {
		position: absolute;
		width: 193px;
		height: 22px;
		left: 49px;
		top: 143px;
		overflow: visible;
	}
	#pro_radio_off {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.pro_radio_off {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 0px;
		top: 4px;
	}
	#product_lbl_off {
		left: 20px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 60px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#serv_radio_ogg {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.serv_radio_ogg {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 120px;
		top: 4px;
	}
	#Service_lbl_off_ {
		left: 140px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 54px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#del_loc_offerings_pop_be {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.del_loc_offerings_pop_be {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 16px;
		top: 336px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#URKL_offerings_input {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.URKL_offerings_input {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 17px;
		top: 496px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
