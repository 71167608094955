	.mediaViewInfo {
		--web-view-name: DriveVelocity_Mob;
		--web-view-id: DriveVelocity_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: DriveVelocity_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
#Scroll_prin_mob_web_7::-webkit-scrollbar{
		display: none;
	}
#Scroll_visiiion_web_7_mob::-webkit-scrollbar{
		display: none;
	}
#Scroll_mission_web_7_mob::-webkit-scrollbar{
		display: none;
	}
#Scroll_avaliable_now_web_mob_7::-webkit-scrollbar{
		display: none
	}
	#DriveVelocity_Mob {
		position: absolute;
		width: 375px;
		height: 4049px;
		background-color: rgba(249,249,249,0.886);
		overflow: hidden;
		--web-view-name: DriveVelocity_Mob;
		--web-view-id: DriveVelocity_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#header_mob_7_web {
		position: absolute;
		width: 495px;
		height: 267px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#car_bg_web_7_mob {
		position: absolute;
		width: 495px;
		height: 267px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#text_unleash_grp_web_7_mob {
		position: absolute;
		width: 166px;
		height: 59px;
		left: 15px;
		top: 72px;
		overflow: visible;
	}
	#Unleash_the_True_Power_web_7_m {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 167px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#Drive_into_the_Future__Your_Ul {
		left: 0px;
		top: 33px;
		position: absolute;
		overflow: visible;
		width: 152px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(173,173,173,1);
	}
	#pre-order_button {
		position: absolute;
		width: 97px;
		height: 14px;
		left: 22px;
		top: 147px;
		overflow: visible;
	}
	#pre_line_web_7_mob {
		fill: rgba(222,14,11,1);
	}
	.pre_line_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 16px;
		height: 1px;
		left: 40px;
		top: 0px;
	}
	#PRE-ORDER_NOW_web_7_mob {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 98px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 10px;
		color: rgba(222,14,11,1);
		letter-spacing: 0.95px;
	}
	#zane-lee-bXbi1jJVDf0-unsplash_DV_mob {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 16px;
		top: 11px;
		border-radius: 50%;
		overflow: hidden;
	}
	#prinic_grp_web_7_mob {
		position: absolute;
		width: 336px;
		height: 467.791px;
		left: 24px;
		top: 1847.209px;
		overflow: visible;
	}
	#Path_1_5_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Path_1_5_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 100.769px;
		height: 83.113px;
		transform: translate(0px, 0px) matrix(1,0,0,1,4.6722,56.8339) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Path_24_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Path_24_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 74.931px;
		height: 83.113px;
		transform: translate(0px, 0px) matrix(1,0,0,1,17.5913,101.1882) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Path_33_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Path_33_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 74.931px;
		height: 83.113px;
		transform: translate(0px, 0px) matrix(1,0,0,1,17.5913,132.6257) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Path_42_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Path_42_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 65.026px;
		height: 83.113px;
		transform: translate(0px, 0px) matrix(1,0,0,1,22.5436,159.5406) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Path_51_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Path_51_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 59.187px;
		height: 83.113px;
		transform: translate(0px, 0px) matrix(1,0,0,1,25.463,176.4298) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#shade_princi_img_web_7_mob {
		fill: rgba(0,0,0,1);
	}
	.shade_princi_img_web_7_mob {
		filter: drop-shadow(-70px 73px 99px rgba(0, 0, 0, 0.122));
		position: absolute;
		overflow: visible;
		width: 445.037px;
		height: 493.811px;
		left: 42.463px;
		top: 0px;
	}
	#Mask_Groupn_web_7_mob {
		position: absolute;
		width: 148.037px;
		height: 196.811px;
		left: 42.463px;
		top: 0px;
		overflow: visible;
	}
	#Principle_lbl_web_7_mob {
		left: 10px;
		top: 269.791px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		line-height: 33px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#princi_top_line_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.princi_top_line_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 51px;
		height: 3px;
		left: 10px;
		top: 264.791px;
	}
	#Scroll_prin_mob_web_7 {
		mix-blend-mode: normal;
		position: absolute;
		width: 336px;
		height: 156px;
		left: 0px;
		top: 311.791px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#The_Mercedes-AMG_desc_web_7_mo {
		left: 10px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 119px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(116,116,116,1);
	}
	#vision_grp_all_web_7_mob {
		position: absolute;
		width: 662px;
		height: 697.76px;
		left: -159px;
		top: 282.209px;
		overflow: visible;
	}
	#red_lineweb_7_mob {
		fill: rgba(99,113,203,1);
	}
	.red_lineweb_7_mob {
		position: absolute;
		overflow: visible;
		width: 662px;
		height: 146px;
		left: 0px;
		top: 197.76px;
	}
	#Mask_Group_web_7_mob {
		position: absolute;
		width: 283px;
		height: 289px;
		left: 329px;
		top: 0px;
		overflow: visible;
	}
	#small_thumbnails {
		transform: translate(0px, 0px) matrix(1,0,0,1,43.7406,130.2594) rotate(-90deg);
		transform-origin: center;
		position: absolute;
		width: 415.519px;
		height: 155px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#over_all_bdr_black_web_7_mob {
		fill: rgba(192,192,192,1);
	}
	.over_all_bdr_black_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 155px;
		height: 415.52px;
		left: 0px;
		top: 0px;
	}
	#car_grp_web_7_mob {
		position: absolute;
		width: 131.154px;
		height: 162.75px;
		left: 11.924px;
		top: 63.191px;
		overflow: visible;
	}
	#gear_Group_web_7_mob {
		position: absolute;
		width: 131.154px;
		height: 162.154px;
		left: 11.924px;
		top: 239.057px;
		overflow: visible;
	}
	#Line_1_web_7_mob {
		fill: transparent;
		stroke: rgba(222,14,11,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_1_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 24.549px;
		left: 80.92px;
		top: 21.521px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_web_7_mob_arrow {
		fill: rgba(222,14,11,1);
	}
	.n_web_7_mob_arrow {
		overflow: visible;
		position: absolute;
		width: 7.154px;
		height: 5.962px;
		transform: translate(0px, 415.519px) matrix(1,0,0,1,391.6734,78.0957) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Vision_lbl_web_7_mob {
		left: 193px;
		top: 480.76px;
		position: absolute;
		overflow: visible;
		width: 68px;
		white-space: nowrap;
		line-height: 33px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#vison_top_line_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.vison_top_line_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 47px;
		height: 2px;
		left: 193px;
		top: 476.76px;
	}
	#Scroll_visiiion_web_7_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 341px;
		height: 177px;
		left: 187px;
		top: 520.76px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#The_cockpit_impresses_with_its {
		left: 6px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#Avaliable_Now_lbl_web_7_mob {
		left: 95px;
		top: 2424px;
		position: absolute;
		overflow: visible;
		width: 187px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#mission_all_grp_mob_web_7 {
		position: absolute;
		width: 771.5px;
		height: 724px;
		left: -381px;
		top: 1043.209px;
		overflow: visible;
	}
	#chris-nguyen-aTX_bRaOZnA-unspl {
		position: absolute;
		width: 693px;
		height: 389.813px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#wite_engine_shade_web_7_mob_da {
		fill: url(#wite_engine_shade_web_7_mob_da);
	}
	.wite_engine_shade_web_7_mob_da {
		position: absolute;
		overflow: visible;
		width: 726px;
		height: 389.879px;
		left: 45.5px;
		top: 0px;
	}
	#Union_2_engine_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Union_2_engine_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 474.888px;
		height: 197.768px;
		left: 174.311px;
		top: 192.111px;
		transform: matrix(1,0,0,1,0,0);
	}
	#kisspng-mercedes-benz-a_web_7_ {
		position: absolute;
		width: 159.063px;
		height: 179.133px;
		left: 443.859px;
		top: 89.303px;
		overflow: visible;
	}
	#mission_grp_web_7_mob {
		position: absolute;
		width: 352px;
		height: 233px;
		left: 404px;
		top: 491px;
		overflow: visible;
	}
	#Masterpiece_web_7_mob {
		left: 11px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 9px;
		color: rgba(99,113,203,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#Mission_lbl_web_7_mob {
		left: 11px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#Scroll_mission_web_7_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 352px;
		height: 177px;
		left: 0px;
		top: 56px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#An_aggregate_of_racing-level_p {
		left: 13px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 334px;
		height: 180px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(116,116,116,1);
	}
	#Path_11_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Path_11_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.207px;
		left: 714.027px;
		top: 405.014px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_12_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Path_12_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.207px;
		left: 714.027px;
		top: 433.693px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_13_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.Path_13_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.207px;
		left: 714.027px;
		top: 463.107px;
		transform: matrix(1,0,0,1,0,0);
	}
	#avaliable_now_design_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.avaliable_now_design_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 474.888px;
		height: 197.768px;
		left: -206.689px;
		top: 2527.111px;
		transform: matrix(1,0,0,1,0,0);
	}
	#off_bdr_web_7_mob {
		fill: rgba(192,192,192,1);
	}
	.off_bdr_web_7_mob {
		width: 1975.545px;
		height: 360px;
		position: absolute;
		overflow: visible;
		transform: matrix(1,0,0,1,-269.6973,2526.3301) rotate(-6deg);
		transform-origin: center;
	}
	#Scroll_avaliable_now_web_mob_7 {
		mix-blend-mode: normal;
		position: absolute;
		width: 344.457px;
		height: 360px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		left: 21.5px;
		top: 2588px;
		transform: rotate(-6deg);
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#offering_card_1_shadow_space_w7MOB {
		position: relative;
		width: 178.957px;
		height: 320px;
		left: 19px;
		top: 0px;
		margin-right: 60px;
		overflow: visible;
	}
	#card_bg_offeringshadow_space_w7MOB {
		fill: rgba(255,255,255,1);
	}
	.card_bg_offeringshadow_space_w7MOB {
		filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 194.457px;
		height: 268.375px;
		left: 0px;
		top: 0px;
	}
	#oofering_lbl_med_shadow_space_7mob {
		left: 14.005px;
		top: 227.197px;
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#markus-shadow_space_website_7 {
		position: absolute;
		width: 159.376px;
		height: 210.766px;
		left: 8.948px;
		top: 8.691px;
		overflow: visible;
	}
	#Icon_awesome-car_web_7_mob {
		fill: rgba(0,0,0,1);
	}
	.Icon_awesome-car_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 28.01px;
		height: 21.008px;
		left: 5.835px;
		top: 18.285px;
		transform: matrix(1,0,0,1,0,0);
	}
	#footer_grp_mob_7_mob {
		position: absolute;
		width: 452px;
		height: 943px;
		left: -29px;
		top: 3079px;
		overflow: visible;
	}
	#footer_web_7_mob {
		position: absolute;
		width: 452px;
		height: 327px;
		left: 0px;
		top: 557px;
		overflow: visible;
	}
	#jakob-rosen-FsBbavP9YA4-unspla {
		position: absolute;
		width: 446px;
		height: 327px;
		left: 6px;
		top: 0px;
		overflow: visible;
	}
	#white_shade_footer_web_7_mob_dx {
		fill: url(#white_shade_footer_web_7_mob_dx);
	}
	.white_shade_footer_web_7_mob_dx {
		position: absolute;
		overflow: visible;
		width: 299px;
		height: 281px;
		left: 0px;
		top: 17px;
	}
	#gps_map_web_7_mob {
		position: absolute;
		width: 334px;
		height: 208px;
		left: 50px;
		top: 0px;
		overflow: visible;
	}
	#bdr_gps_web_7_mob {
		fill: rgba(0,0,0,1);
	}
	.bdr_gps_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 334px;
		height: 208px;
		left: 0px;
		top: 0px;
	}
	#screen_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.screen_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 319px;
		height: 175px;
		left: 7px;
		top: 6px;
	}
	#Icon_awesome-car_web_7_mob_d {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-car_web_7_mob_d {
		overflow: visible;
		position: absolute;
		width: 13.222px;
		height: 9.916px;
		left: 24.606px;
		top: 190.74px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesomme-music_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesomme-music_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 9.917px;
		height: 9.916px;
		left: 62.267px;
		top: 190.74px;
		transform: matrix(1,0,0,1,0,0);
	}
	#arrow_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.arrow_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 9.917px;
		height: 9.916px;
		left: 145.435px;
		top: 190.74px;
		transform: matrix(1,0,0,1,0,0);
	}
	#back_web_7_mob {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.back_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 8.198px;
		height: 11.916px;
		left: 194.678px;
		top: 190.74px;
		transform: matrix(1,0,0,1,0,0);
	}
	#back_line_web_7_mob {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.back_line_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 10.678px;
		left: 192.199px;
		top: 191.359px;
		transform: matrix(1,0,0,1,0,0);
	}
	#play_web_7_mob {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.play_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 8.198px;
		height: 11.916px;
		left: 225.406px;
		top: 190.74px;
		transform: matrix(1,0,0,1,0,0);
	}
	#forward_line_ {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.forward_line_ {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 10.678px;
		left: 234.083px;
		top: 191.359px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-volume-up_web_7_m {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-volume-up_web_7_m {
		overflow: visible;
		position: absolute;
		width: 10.903px;
		height: 9.086px;
		left: 302.703px;
		top: 191.57px;
		transform: matrix(1,0,0,1,0,0);
	}
	#dv_socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 123px;
		top: 236px;
		overflow: visible;
	}
	#linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.med_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 13.815px;
		top: 13.191px;
		overflow: visible;
	}
	#n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#shadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_7_em:hover {
		fill: rgba(50,172,158,1);
	}
	.med_shadow_space_website_7_em:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_7_em {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_7_em {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#overall_bdr_web_7_mob {
		fill: rgba(192,192,192,1);
	}
	.overall_bdr_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 345px;
		left: 29px;
		top: 381px;
	}
	#Call_us_for_questions_web_7_mo {
		left: 85px;
		top: 399px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 12px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#top_line_call_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.top_line_call_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 1px;
		left: 85px;
		top: 429px;
	}
	#bd_acllr_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.bd_acllr_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 263px;
		height: 46px;
		left: 85px;
		top: 444px;
	}
	#n_1_123-456-7890_web_7_mob {
		left: 137px;
		top: 456px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		line-height: 31px;
		margin-top: -8px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#call_bdr_img_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.call_bdr_img_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 46px;
		left: 85px;
		top: 444px;
	}
	#call_web_7_mob {
		fill: rgba(21,21,21,1);
	}
	.call_web_7_mob {
		overflow: visible;
		position: absolute;
		width: 17.84px;
		height: 17.828px;
		left: 98.343px;
		top: 457.395px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Email_us_for_inquiries_web_7_m {
		left: 85px;
		top: 503px;
		position: absolute;
		overflow: visible;
		width: 155px;
		white-space: nowrap;
		line-height: 12px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#email_top_line_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.email_top_line_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 1px;
		left: 85px;
		top: 532px;
	}
	#bdr_email_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.bdr_email_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 263px;
		height: 46px;
		left: 85px;
		top: 548px;
	}
	#emailmailcom_web_7_mob {
		left: 138px;
		top: 561px;
		position: absolute;
		overflow: visible;
		width: 212px;
		height: 27px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
	}
	#email_bdr_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.email_bdr_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 45px;
		height: 46px;
		left: 85px;
		top: 548px;
	}
	#email_web_7_mob_2 {
		fill: rgba(21,21,21,1);
	}
	.email_web_7_mob_2 {
		overflow: visible;
		position: absolute;
		width: 22.411px;
		height: 11.205px;
		left: 96.807px;
		top: 562.236px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_email_web_7_mob_1 {
		fill: rgba(21,21,21,1);
	}
	.n_email_web_7_mob_1 {
		overflow: visible;
		position: absolute;
		width: 22.411px;
		height: 11.93px;
		left: 96.807px;
		top: 567.914px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Visit_us_for_inquiries_web_7_m {
		left: 86px;
		top: 608px;
		position: absolute;
		overflow: visible;
		width: 149px;
		white-space: nowrap;
		line-height: 12px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#emai_bdr_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.emai_bdr_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 45px;
		height: 1px;
		left: 85px;
		top: 637px;
	}
	#address_bdr_web_7_mob {
		fill: rgba(99,113,203,1);
	}
	.address_bdr_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 263px;
		height: 46px;
		left: 86px;
		top: 653px;
	}
	#address_lbl_web_7_mob {
		left: 139px;
		top: 665px;
		position: absolute;
		overflow: visible;
		width: 212px;
		height: 27px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
	}
	#loc_bdr_web_7_mob {
		fill: rgba(255,255,255,1);
	}
	.loc_bdr_web_7_mob {
		position: absolute;
		overflow: visible;
		width: 45px;
		height: 46px;
		left: 86px;
		top: 653px;
	}
	#Icon_material-edit-location_we {
		fill: rgba(0,0,0,1);
	}
	.Icon_material-edit-location_we {
		overflow: visible;
		position: absolute;
		width: 15.974px;
		height: 22.82px;
		left: 100.501px;
		top: 664.723px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_Copyright_Operate_Live_OPC_a {
		left: 39px;
		top: 907px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(173,173,173,1);
	}