	.tcp_mediaViewInfo {
		--web-view-name: TeaCorner;
		--web-view-id: TeaCorner;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: TeaCorner;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#TeaCorner {
		position: absolute;
		width: 1366px;
		height: 3747px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: TeaCorner;
		--web-view-id: TeaCorner;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#tcp_TC_17_pcGroup_15005 {
		position: absolute;
		width: 1366px;
		height: 568px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcside-view-two-cups-coffee-cook {
		position: absolute;
		width: 1366px;
		height: 568px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcRectangle_722 {
		opacity: 0.74;
		fill: rgba(92,226,104,1);
	}
	.tcp_TC_17_pcRectangle_722 {
		position: absolute;
		overflow: visible;
		width: 299px;
		height: 421px;
		left: 148px;
		top: 0px;
	}
	#tcp_TC_17_pcAnime_Edit {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 188px;
		top: 47px;
        border-radius: 50%;
		overflow: hidden;
	}
	#tcp_TC_17_pcTea_Corner {
		left: 283px;
		top: 68px;
		position: absolute;
		overflow: visible;
		width: 98px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcThe_Beauty_of__Science_and__Na {
		left: 187px;
		top: 145px;
		position: absolute;
		overflow: visible;
		width: 254px;
		height: 95px;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: normal;
		font-size: 28px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcTea_A_hug_in_a_cup_that_warms_ {
		left: 188px;
		top: 268px;
		position: absolute;
		overflow: visible;
		width: 226px;
		height: 50px;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#tcp_TC_17_pcIcon_ionic-logo-whatsapp {
		fill: rgba(255,255,255,1);
	}
	.tcp_TC_17_pcIcon_ionic-logo-whatsapp {
		overflow: visible;
		position: absolute;
		width: 20px;
		height: 20px;
		left: 188px;
		top: 340px;
		transform: matrix(1,0,0,1,0,0);
	}
	#tcp_TC_17_pcIcon_awesome-instagram {
		fill: rgba(255,255,255,1);
	}
	.tcp_TC_17_pcIcon_awesome-instagram {
		overflow: visible;
		position: absolute;
		width: 20px;
		height: 19.996px;
		left: 188px;
		top: 371px;
		transform: matrix(1,0,0,1,0,0);
	}
	#tcp_TC_17_pcn_856321470 {
		left: 223px;
		top: 342px;
		position: absolute;
		overflow: visible;
		width: 76px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#tcp_TC_17_pcyour_username {
		left: 223px;
		top: 373px;
		position: absolute;
		overflow: visible;
		width: 107px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#tcp_TC_17_pcGroup_15002 {
		position: absolute;
		width: 1141px;
		height: 574px;
		left: 148px;
		top: 668px;
		overflow: visible;
	}
	#tcp_TC_17_pcvision_bdr_website_7_pc {
		fill: rgba(195,191,188,1);
	}
	.tcp_TC_17_pcvision_bdr_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 662px;
		height: 146px;
		left: 0px;
		top: 422px;
	}
	#tcp_TC_17_pcmask_top_visoon_img_website_7_ {
		position: absolute;
		width: 583px;
		height: 574px;
		left: 558px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcVision_lbl_website_7_pc {
		left: 14px;
		top: 88.998px;
		position: absolute;
		overflow: visible;
		width: 111px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 41px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcScroll_vision_web_7_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 445px;
		height: 197px;
		left: 0px;
		top: 158.998px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#tcp_TC_17_pcScroll_vision_web_7_pc::-webkit-scrollbar{
		display: none;
	}
	#tcp_TC_17_pcThe_cockpit_impresses_with_its {
		left: 14px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 133px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#tcp_TC_17_pcGroup_15001 {
		position: absolute;
		width: 1141px;
		height: 574px;
		left: 148px;
		top: 1394px;
		overflow: visible;
	}
	#tcp_TC_17_pcvision_bdr_website_7_pc_dh {
		fill: rgba(97,64,38,1);
	}
	.tcp_TC_17_pcvision_bdr_website_7_pc_dh {
		position: absolute;
		overflow: visible;
		width: 662px;
		height: 146px;
		left: 475px;
		top: 422px;
	}
	#tcp_TC_17_pcmask_top_visoon_img_website_7__di {
		position: absolute;
		width: 583px;
		height: 574px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcVision_lbl_website_7_pc_dj {
		left: 710px;
		top: 88.998px;
		position: absolute;
		overflow: visible;
		width: 140px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 41px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcScroll_vision_web_7_pc_dk {
		mix-blend-mode: normal;
		position: absolute;
		width: 445px;
		height: 197px;
		left: 696px;
		top: 158.998px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#tcp_TC_17_pcScroll_vision_web_7_pc_dk::-webkit-scrollbar{
		display: none;
	}
	#tcp_TC_17_pcThe_cockpit_impresses_with_its_dl {
		left: 14px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 133px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#tcp_TC_17_pcGroup_15000 {
		position: absolute;
		width: 1141px;
		height: 574px;
		left: 148px;
		top: 2120px;
		overflow: visible;
	}
	#tcp_TC_17_pcvision_bdr_website_7_pc_dn {
		fill: rgba(207,144,100,1);
	}
	.tcp_TC_17_pcvision_bdr_website_7_pc_dn {
		position: absolute;
		overflow: visible;
		width: 662px;
		height: 146px;
		left: 0px;
		top: 422px;
	}
	#tcp_TC_17_pcmask_top_visoon_img_website_7__do {
		position: absolute;
		width: 583px;
		height: 574px;
		left: 558px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcVision_lbl_website_7_pc_dp {
		left: 14px;
		top: 88.998px;
		position: absolute;
		overflow: visible;
		width: 173px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 41px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcScroll_vision_web_7_pc_dq {
		mix-blend-mode: normal;
		position: absolute;
		width: 445px;
		height: 197px;
		left: 0px;
		top: 158.998px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#tcp_TC_17_pcScroll_vision_web_7_pc_dq::-webkit-scrollbar{
		display: none;
	}
	#tcp_TC_17_pcThe_cockpit_impresses_with_its_dr {
		left: 14px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 133px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#tcp_TC_17_pcAvaliable_Now_lbl_website_7_pc {
		left: 535px;
		top: 2794px;
		position: absolute;
		overflow: visible;
		width: 298px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcn_667477_3386277_1 {
		position: absolute;
		width: 1376px;
		height: 354px;
		left: 0px;
		top: 2912px;
		overflow: visible;
	}
	#tcp_TC_17_pcScroll_Group_56 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 354px;
		top: 2912px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#tcp_TC_17_pcScroll_Group_56::-webkit-scrollbar{
		display: none;
	}
	#tcp_TC_17_pcGroup_15003 {
		position: relative;
		width: 270px;
		height: 308px;
		left: 48px;
		top: 23px;
        margin-right: 30px;
		overflow: visible;
	}
	#tcp_TC_17_pccoffee-porcelain-cup-wooden-ta {
		position: absolute;
		width: 270px;
		height: 270px;
		left: 0px;
		top: 38px;
		overflow: visible;
	}
	#tcp_TC_17_pcGinger_Tea {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 271px;
		height: 33px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcGroup_15009 {
		position: absolute;
		width: 1376px;
		height: 494.762px;
		left: 0px;
		top: 3335.619px;
		overflow: visible;
	}
	#tcp_TC_17_pcvision_bdr_website_7_pc_dz {
		fill: rgba(207,144,100,1);
	}
	.tcp_TC_17_pcvision_bdr_website_7_pc_dz {
		position: absolute;
		overflow: visible;
		width: 1376px;
		height: 339px;
		left: 0px;
		top: 82.381px;
	}
	#tcp_TC_17_pcGroup_15004 {
		position: absolute;
		width: 479px;
		height: 280px;
		left: 832px;
		top: 107.381px;
		overflow: visible;
	}
	#tcp_TC_17_pcsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 0px;
		top: 232px;
		overflow: visible;
	}
	#tcp_TC_17_pclinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.tcp_TC_17_pcmed_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#tcp_TC_17_pcmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.tcp_TC_17_pcmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#tcp_TC_17_pclinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.672px;
		overflow: visible;
	}
	#tcp_TC_17_pcn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.tcp_TC_17_pcn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#tcp_TC_17_pclinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.tcp_TC_17_pclinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#tcp_TC_17_pctop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.tcp_TC_17_pctop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#tcp_TC_17_pcInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.tcp_TC_17_pcshadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#tcp_TC_17_pcshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.tcp_TC_17_pcshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#tcp_TC_17_pcinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.072px;
		overflow: visible;
	}
	#tcp_TC_17_pcwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcmed_shadow_space_website_7_ee:hover {
		fill: rgba(50,172,158,1);
	}
	.tcp_TC_17_pcmed_shadow_space_website_7_ee:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#tcp_TC_17_pcmed_shadow_space_website_7_ee {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.tcp_TC_17_pcmed_shadow_space_website_7_ee {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#tcp_TC_17_pcwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.473px;
		overflow: visible;
	}
	#tcp_TC_17_pcn_234567890_mobile_number_shad {
		left: 35px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcaffanghanioperatelive_email_sh {
		left: 40px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pcaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pclocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#tcp_TC_17_pcemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#tcp_TC_17_pcphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcn_Copyright_Operate_Live_OPC_P {
		left: 176px;
		top: 365.381px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#tcp_TC_17_pckisspng-turkish-coffee-tea-caf {
		position: absolute;
		width: 477.645px;
		height: 494.762px;
		left: 128.967px;
		top: 0px;
		overflow: visible;
	}
	#tcp_TC_17_pcEllipse_64 {
		fill: rgba(255,255,255,1);
	}
	.tcp_TC_17_pcEllipse_64 {
		position: absolute;
		overflow: visible;
		width: 188px;
		height: 193px;
		left: 302px;
		top: 155.381px;
		border-radius: 100px;
	}