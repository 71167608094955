	.mediaViewInfo {
		--web-view-name: CaptureSphere;
		--web-view-id: CaptureSphere;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: CaptureSphere;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#CaptureSphere {
		position: absolute;
		width: 1366px;
		height: 3603px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: CaptureSphere;
		--web-view-id: CaptureSphere;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#cApTure_25_PCGallery {
		position: absolute;
		width: 1366px;
		height: 1193.229px;
		left: 0px;
		top: 1917.5px;
		overflow: visible;
	}
	#cApTure_25_PCRectangle_489 {
		fill: rgba(247,249,251,1);
	}
	.cApTure_25_PCRectangle_489 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 1193.229px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCMask_Group_106 {
		position: absolute;
		width: 392px;
		height: 347px;
		left: 494px;
		top: 188.631px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_108 {
		position: absolute;
		width: 392px;
		height: 232px;
		left: 494px;
		top: 583.379px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_111 {
		position: absolute;
		width: 300px;
		height: 429.727px;
		left: 136px;
		top: 511.375px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_112 {
		position: absolute;
		width: 300px;
		height: 432px;
		left: 944px;
		top: 187.529px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_115 {
		position: absolute;
		width: 300px;
		height: 156px;
		left: 944px;
		top: 667.238px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_116 {
		position: absolute;
		width: 300px;
		height: 278px;
		left: 136px;
		top: 189.461px;
		overflow: visible;
	}
	#cApTure_25_PCGroup_60 {
		position: absolute;
		width: 317px;
		height: 77px;
		left: 524.5px;
		top: 1029.229px;
		overflow: visible;
	}
	#cApTure_25_PCRectangle_196 {
		fill: rgba(0,0,0,1);
	}
	.cApTure_25_PCRectangle_196 {
		filter: drop-shadow(0px 15px 20px rgba(0, 0, 0, 0.078));
		position: absolute;
		overflow: visible;
		width: 377px;
		height: 137px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCLoad_more_Images {
		left: 45px;
    top: 28px;
		position: absolute;
		overflow: visible;
		width: 169px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -3px;
		text-align: left;
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.15px;
	}
	#cApTure_25_PCGroup_13 {
		position: absolute;
		width: 20px;
		height: 13.098px;
		left: 263.049px;
		top: 31.951px;
		overflow: visible;
	}
	#cApTure_25_PCPath_13 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.cApTure_25_PCPath_13 {
		overflow: visible;
		position: absolute;
		width: 13.098px;
		height: 6.549px;
		transform: translate(0px, 0px) matrix(1,0,0,1,10.1763,3.2742) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCLine_14 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.cApTure_25_PCLine_14 {
		overflow: visible;
		position: absolute;
		width: 19.451px;
		height: 1px;
		left: 0px;
		top: 6.549px;
		transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PCEvents_we_Cover {
		left: 473px;
		top: 54.064px;
		position: absolute;
		overflow: visible;
		width: 422px;
		white-space: nowrap;
		text-align: left;
		font-family: Baskerville;
		font-style: italic;
		font-weight: normal;
		font-size: 60px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCIntro {
		position: absolute;
		width: 1366px;
		height: 710px;
		left: 0px;
		top: 6px;
		overflow: visible;
	}
	#cApTure_25_PCRectangle_375 {
		fill: rgba(255,255,255,1);
		stroke: rgba(255,255,255,1);
		stroke-width: 10px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.cApTure_25_PCRectangle_375 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 710px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCMask_Group_83 {
		position: absolute;
		width: 155.642px;
		height: 266.135px;
		left: 1107.855px;
		top: 208.92px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_84 {
		position: absolute;
		width: 401.58px;
		height: 204.947px;
		left: 861.917px;
		top: 484.559px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_85 {
		position: absolute;
		width: 154.454px;
		height: 158.018px;
		left: 943.896px;
		top: 132.107px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_86 {
		position: absolute;
		width: 154.454px;
		height: 169.432px;
		left: 534px;
		top: 520.076px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_87 {
		position: absolute;
		width: 236.433px;
		height: 175.611px;
		left: 861.917px;
		top: 299.443px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_88 {
		position: absolute;
		width: 154.454px;
		height: 286.928px;
		left: 697.958px;
		top: 402.58px;
		overflow: visible;
	}
	#cApTure_25_PCMask_Group_89 {
		position: absolute;
		width: 155.642px;
		height: 138.414px;
		left: 1107.855px;
		top: 61px;
		overflow: visible;
	}
	#cApTure_25_PCWe_are_Experienced {
		left: 329px;
		top: 237px;
		position: absolute;
		overflow: visible;
		width: 279px;
		white-space: nowrap;
		text-align: left;
		font-family: Museo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
		letter-spacing: 0.35px;
	}
	#cApTure_25_PCPhotographers {
		left: 169px;
		top: 269px;
		position: absolute;
		overflow: visible;
		width: 425px;
		white-space: nowrap;
		line-height: 100px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baskerville;
		font-style: normal;
		font-weight: normal;
		font-size: 65px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCSubtraction_8 {
		fill: rgba(0,0,0,1);
	}
	.cApTure_25_PCSubtraction_8 {
        border-radius: 50%;
		overflow: hidden;
		position: absolute;
		width: 30px;
		height: 30.01px;
		left: 125px;
		top: 45px;
		transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PCGroup_37 {
		position: absolute;
		width: 5px;
		height: 64.95px;
		left: 166px;
		top: 585px;
		overflow: visible;
	}
	#cApTure_25_PCRectangle_337 {
		fill: rgba(234,234,234,1);
	}
	.cApTure_25_PCRectangle_337 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 64.951px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCRectangle_338 {
		fill: rgba(0,0,0,1);
	}
	.cApTure_25_PCRectangle_338 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 18.318px;
		left: 0px;
		top: 13px;
	}
	#cApTure_25_PCOperate_Live {
		left: 170px;
		top: 47px;
		position: absolute;
		overflow: visible;
		width: 120px;
		white-space: nowrap;
		text-align: left;
		font-family: Museo;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
		letter-spacing: 0.35px;
	}
	#cApTure_25_PCWe_Are_Exp {
		position: absolute;
		width: 1440.5px;
		height: 1000.896px;
		left: -37.25px;
		top: 816px;
		overflow: visible;
	}
	#cApTure_25_PCRectangle_391 {
		fill: rgba(247,249,251,1);
	}
	.cApTure_25_PCRectangle_391 {
		position: absolute;
		overflow: visible;
		width: 1440px;
		height: 1000.896px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCLine_51 {
		opacity: 0.5;
		fill: transparent;
		stroke: rgba(210,221,232,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.cApTure_25_PCLine_51 {
		overflow: visible;
		position: absolute;
		width: 1440px;
		height: 1px;
		left: 0.5px;
		top: 0.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PCLorem_Ipsum_is_simply_dummy_te {
		opacity: 0.8;
		left: 633px;
		top: 66px;
		position: absolute;
		overflow: visible;
		width: 641px;
		height: 285px;
		line-height: 24px;
		margin-top: -3px;
		text-align: left;
		font-family: Aktiv Grotesk;
		font-style: normal;
		font-weight: lighter;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCGroup_57 {
		position: absolute;
		width: 431px;
		height: 217px;
		left: 144px;
		top: 42px;
		overflow: visible;
	}
	#cApTure_25_PCWe_are__Professional {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 427px;
		white-space: nowrap;
		text-align: left;
		font-family: Baskerville;
		font-style: italic;
		font-weight: normal;
		font-size: 60px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCphotographers {
		left: 169px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 263px;
		white-space: nowrap;
		text-align: left;
		font-family: Baskerville;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCoffering_card_1_shadow_space_w {
		position: absolute;
		width: 281.482px;
		height: 397.746px;
		left: 130.509px;
		top: 446px;
		overflow: visible;
	}
	#cApTure_25_PCcard_bg_offeringshadow_space_w {
		fill: rgba(255,255,255,1);
	}
	.cApTure_25_PCcard_bg_offeringshadow_space_w {
		filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 296.982px;
		height: 413.246px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PChalf_circle_shadow_space_websi {
		fill: rgba(46,46,46,1);
	}
	.cApTure_25_PChalf_circle_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 22px;
		height: 42px;
		left: 23px;
		top: 18px;
		transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PCcircle_deign_shadow_space_webs {
		fill: rgba(46,46,46,1);
	}
	.cApTure_25_PCcircle_deign_shadow_space_webs {
		position: absolute;
		overflow: visible;
		width: 8.567px;
		height: 8.566px;
		left: 9.791px;
		top: 35.492px;
	}
	#cApTure_25_PCoofering_lbl_med_shadow_space_ {
		left: 55px;
		top: 28px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCScroll_Group_82 {
		mix-blend-mode: normal;
		position: absolute;
		width: 270px;
		height: 316px;
		left: 6px;
		top: 75px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#cApTure_25_PCScroll_Group_82::-webkit-scrollbar{
		display: none;
	}
	#cApTure_25_PCintro_lbl_14_web_pc {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 316px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#cApTure_25_PCoffering_card_1_shadow_space_w_ce {
		position: absolute;
		width: 281.482px;
		height: 397.746px;
		left: 579.509px;
		top: 446px;
		overflow: visible;
	}
	#cApTure_25_PCcard_bg_offeringshadow_space_w_cf {
		fill: rgba(255,255,255,1);
	}
	.cApTure_25_PCcard_bg_offeringshadow_space_w_cf {
		filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 296.982px;
		height: 413.246px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PChalf_circle_shadow_space_websi_cg {
		fill: rgba(46,46,46,1);
	}
	.cApTure_25_PChalf_circle_shadow_space_websi_cg {
		overflow: visible;
		position: absolute;
		width: 22px;
		height: 42px;
		left: 23px;
		top: 18px;
		transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PCcircle_deign_shadow_space_webs_ch {
		fill: rgba(46,46,46,1);
	}
	.cApTure_25_PCcircle_deign_shadow_space_webs_ch {
		position: absolute;
		overflow: visible;
		width: 8.567px;
		height: 8.566px;
		left: 9.791px;
		top: 35.492px;
	}
	#cApTure_25_PCoofering_lbl_med_shadow_space__ci {
		left: 55px;
		top: 28px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCScroll_Group_83 {
		mix-blend-mode: normal;
		position: absolute;
		width: 270px;
		height: 316px;
		left: 5px;
		top: 75px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#cApTure_25_PCScroll_Group_83::-webkit-scrollbar{
		display: none;
	}
	#cApTure_25_PCintro_lbl_14_web_pc_ck {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 316px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#cApTure_25_PCoffering_card_1_shadow_space_w_cl {
		position: absolute;
		width: 281.482px;
		height: 397.746px;
		left: 1028.509px;
		top: 446px;
		overflow: visible;
	}
	#cApTure_25_PCcard_bg_offeringshadow_space_w_cm {
		fill: rgba(255,255,255,1);
	}
	.cApTure_25_PCcard_bg_offeringshadow_space_w_cm {
		filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 296.982px;
		height: 413.246px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PChalf_circle_shadow_space_websi_cn {
		fill: rgba(46,46,46,1);
	}
	.cApTure_25_PChalf_circle_shadow_space_websi_cn {
		overflow: visible;
		position: absolute;
		width: 22px;
		height: 42px;
		left: 23px;
		top: 18px;
		transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PCcircle_deign_shadow_space_webs_co {
		fill: rgba(46,46,46,1);
	}
	.cApTure_25_PCcircle_deign_shadow_space_webs_co {
		position: absolute;
		overflow: visible;
		width: 8.567px;
		height: 8.566px;
		left: 9.791px;
		top: 35.492px;
	}
	#cApTure_25_PCoofering_lbl_med_shadow_space__cp {
		left: 55px;
		top: 28px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCScroll_Group_84 {
		mix-blend-mode: normal;
		position: absolute;
		width: 270px;
		height: 316px;
		left: 6px;
		top: 75px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#cApTure_25_PCScroll_Group_84::-webkit-scrollbar{
		display: none;
	}
	
	#cApTure_25_PCintro_lbl_14_web_pc_cr {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 316px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#cApTure_25_PCGroup_15076 {
		position: absolute;
		width: 1366px;
		height: 392px;
		left: 0px;
		top: 3211px;
		overflow: visible;
	}
	#cApTure_25_PCRectangle_747 {
		fill: rgba(255,255,255,1);
		stroke: rgba(255,255,255,1);
		stroke-width: 10px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.cApTure_25_PCRectangle_747 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 392px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCGroup_15081 {
		position: absolute;
		width: 509px;
		height: 364px;
		left: 59px;
		top: 28px;
		overflow: visible;
	}
	#cApTure_25_PCkisspng-paper-area-rectangle-s {
		position: absolute;
		width: 509px;
		height: 364px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#cApTure_25_PCRectangle_748 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.cApTure_25_PCRectangle_748 {
		position: absolute;
		overflow: visible;
		width: 410px;
		height: 272px;
		left: 50px;
		top: 42px;
	}
	#cApTure_25_PCsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 683px;
		top: 253px;
		overflow: visible;
	}
	#cApTure_25_PClinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#cApTure_25_PCmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.cApTure_25_PCmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PClinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#cApTure_25_PCn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.cApTure_25_PCn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PClinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.cApTure_25_PClinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PCtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.cApTure_25_PCtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#cApTure_25_PCInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#cApTure_25_PCshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.cApTure_25_PCshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#cApTure_25_PCwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#cApTure_25_PCmed_shadow_space_website_7_c {
		fill: rgba(255,255,255,1);
	}
	.cApTure_25_PCmed_shadow_space_website_7_c {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#cApTure_25_PCwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#cApTure_25_PCn_234567890_mobile_number_shad {
		left: 718px;
		top: 22px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCaffanghanioperatelive_email_sh {
		left: 723px;
		top: 75px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCaddress_med_shadow_space_websi {
		left: 718px;
		top: 125px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PClocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 683px;
		top: 127px;
		overflow: visible;
	}
	#cApTure_25_PCemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 683px;
		top: 74px;
		overflow: visible;
	}
	#cApTure_25_PCphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 683px;
		top: 21px;
		overflow: visible;
	}
	#cApTure_25_PCn_Copyright_Operate_Live_OPC_P {
		left: 683px;
		top: 349px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#cApTure_25_PCVisit_Us_ {
		left: 271px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -3px;
		text-align: left;
		font-family: Baskerville;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
		letter-spacing: 0.15px;
	}