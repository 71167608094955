
	.mediaViewInfo {
		--web-view-name: InkwellHaven Mob;
		--web-view-id: InkwellHaven_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: InkwellHaven_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#InkwellHaven_Mob {
		position: absolute;
		width: 375px;
		height: 3627px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: InkwellHaven Mob;
		--web-view-id: InkwellHaven_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#IWH_MOB_33Group_15132 {
		position: absolute;
		width: 358px;
		height: 600.096px;
		left: 8.5px;
		top: 3021px;
		overflow: visible;
	}
	#IWH_MOB_33Group_15050 {
		position: absolute;
		width: 358px;
		height: 600.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 67px;
		top: 479.096px;
		overflow: visible;
	}
	#IWH_MOB_33linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.IWH_MOB_33med_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#IWH_MOB_33linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#IWH_MOB_33n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.IWH_MOB_33n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#IWH_MOB_33linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.IWH_MOB_33linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#IWH_MOB_33top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.IWH_MOB_33top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#IWH_MOB_33Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.IWH_MOB_33shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#IWH_MOB_33instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#IWH_MOB_33whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33med_shadow_space_website_7_bf {
		fill: rgba(255,255,255,1);
	}
	.IWH_MOB_33med_shadow_space_website_7_bf {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#IWH_MOB_33whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#IWH_MOB_33n_234567890_mobile_number_shad {
		left: 35px;
		top: 292.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33affanghanioperatelive_email_sh {
		left: 35px;
		top: 339.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33address_med_shadow_space_websi {
		left: 35px;
		top: 389.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 391.096px;
		overflow: visible;
	}
	#IWH_MOB_33email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 338.096px;
		overflow: visible;
	}
	#IWH_MOB_33phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 285.096px;
		overflow: visible;
	}
	#IWH_MOB_33n_Copyright_Operate_Live_OPC_P {
		left: 17px;
		top: 564.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33Contact_Us {
		left: 117.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: left;
		font-family: Microsoft PhagsPa;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#IWH_MOB_33kisspng-paper-area-rectangle-s {
		position: absolute;
		width: 340px;
		height: 243.688px;
		left: 9px;
		top: 28px;
		overflow: visible;
	}
	#IWH_MOB_33Rectangle_757 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.IWH_MOB_33Rectangle_757 {
		position: absolute;
		overflow: visible;
		width: 274px;
		height: 183px;
		left: 41.5px;
		top: 56px;
	}
	#IWH_MOB_33Group_15159 {
		position: absolute;
		width: 344px;
		height: 650px;
		left: 20px;
		top: 14px;
		overflow: visible;
	}
	#IWH_MOB_33deepigoyal--TCygdpnO3g-unsplas {
		position: absolute;
		width: 344px;
		height: 315px;
		left: 0px;
		top: 335px;
		overflow: visible;
	}
	#IWH_MOB_33Essential_That_Inspires_You_to {
		left: 0px;
		top: 111px;
		position: absolute;
		overflow: visible;
		width: 295px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33Crafting_Words_One_Page_at_a_T {
		left: 0px;
		top: 203px;
		position: absolute;
		overflow: visible;
		width: 345px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33Your_Name {
		left: 0px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33Anime_Edit {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 60px;
		height: 60px;
		left: 274px;
		top: 0px;
		overflow: hidden;
        border-radius: 50%;
	}
	#IWH_MOB_33n_1994588_2111w039n0037Bp17 {
		opacity: 0.2;
		position: absolute;
		width: 375px;
		height: 1636px;
		left: 0px;
		top: 764px;
		overflow: visible;
	}
	#IWH_MOB_33Group_15164 {
		position: absolute;
		width: 349px;
		height: 467px;
		left: 16px;
		top: 799px;
		overflow: visible;
	}
	#IWH_MOB_33Group_15160 {
		position: absolute;
		width: 288.01px;
		height: 220.593px;
		left: 23.495px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33Path_7783 {
		fill: rgba(235,161,99,1);
	}
	.IWH_MOB_33Path_7783 {
		overflow: visible;
		position: absolute;
		width: 288.01px;
		height: 66.065px;
		left: 0px;
		top: 154.527px;
		transform: matrix(1,0,0,1,0,0);
	}
	#IWH_MOB_33kisspng-learning-education-bla {
		position: absolute;
		width: 218px;
		height: 218px;
		left: 34.819px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33Group_15163 {
		position: absolute;
		width: 349px;
		height: 195px;
		left: 0px;
		top: 272px;
		overflow: visible;
	}
	#IWH_MOB_33Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 349px;
		height: 152px;
		left: 0px;
		top: 43px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#IWH_MOB_33It_is_a_long_established_fact_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#IWH_MOB_33VISION {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		line-height: 37px;
		margin-top: -10px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33Group_15167 {
		position: absolute;
		width: 369px;
		height: 529px;
		left: 6px;
		top: 1289px;
		overflow: visible;
	}
	#IWH_MOB_33Group_15161 {
		position: absolute;
		width: 326.703px;
		height: 320.616px;
		left: 18.148px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33Path_7784 {
		fill: rgba(99,235,131,1);
	}
	.IWH_MOB_33Path_7784 {
		overflow: visible;
		position: absolute;
		width: 288.01px;
		height: 66.065px;
		left: 19.346px;
		top: 235.731px;
		transform: matrix(1,0,0,1,0,0);
	}
	#IWH_MOB_33kisspng-school-royalty-free-dr {
		position: absolute;
		width: 326.703px;
		height: 320.616px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33Group_15165 {
		position: absolute;
		width: 369px;
		height: 172px;
		left: 0px;
		top: 357px;
		overflow: visible;
	}
	#IWH_MOB_33Scroll_Group_28_cb {
		mix-blend-mode: normal;
		position: absolute;
		width: 369px;
		height: 129px;
		left: 0px;
		top: 43px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#IWH_MOB_33It_is_a_long_established_fact__cc {
		left: 7px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 357px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#IWH_MOB_33MISSION {
		left: 7px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 37px;
		margin-top: -10px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33Group_15168 {
		position: absolute;
		width: 362px;
		height: 488px;
		left: 10px;
		top: 1857px;
		overflow: visible;
	}
	#IWH_MOB_33Group_15162 {
		position: absolute;
		width: 288px;
		height: 262px;
		left: 30px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33Path_7785 {
		fill: rgba(99,99,235,1);
	}
	.IWH_MOB_33Path_7785 {
		overflow: visible;
		position: absolute;
		width: 288px;
		height: 66.065px;
		left: 0px;
		top: 163.438px;
		transform: matrix(1,0,0,1,0,0);
	}
	#IWH_MOB_33n_359207_19882 {
		position: absolute;
		width: 262px;
		height: 262px;
		left: 12.819px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33Group_15166 {
		position: absolute;
		width: 362px;
		height: 195px;
		left: 0px;
		top: 293px;
		overflow: visible;
	}
	#IWH_MOB_33Scroll_Group_28_cj {
		mix-blend-mode: normal;
		position: absolute;
		width: 362px;
		height: 152px;
		left: 0px;
		top: 43px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#IWH_MOB_33It_is_a_long_established_fact__ck {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 350px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#IWH_MOB_33PRINCIPLES {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		line-height: 37px;
		margin-top: -10px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33Group_15157 {
		position: absolute;
		width: 375px;
		height: 420px;
		left: 0px;
		top: 2500px;
		overflow: visible;
	}
	#IWH_MOB_33school-supplies-art-classes {
		position: absolute;
		width: 375px;
		height: 327px;
		left: 0px;
		top: 93px;
		overflow: visible;
	}
	#IWH_MOB_33Rectangle_755 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.IWH_MOB_33Rectangle_755 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 327px;
		left: 0px;
		top: 93px;
	}
	#IWH_MOB_33Available_Stationery {
		left: 52px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 272px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33Scroll_offering_med_web_4_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 306px;
		left: 0px;
		top: 104px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#IWH_MOB_33offering_crd_1_grp_med_web_4_p {
		position: relative;
        margin-right: 30px;
		width: 167px;
		height: 277px;
		left: 15px;
		top: 11px;
		overflow: visible;
	}
	#IWH_MOB_33title_bdr_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.IWH_MOB_33title_bdr_med_web_4_pc {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 161px;
		height: 45px;
		left: 12px;
		top: 250px;
	}
	#IWH_MOB_33BiziBees_PVT_LTD_med_web_4_pc {
		left: 12px;
		top: 255px;
		position: absolute;
		overflow: visible;
		width: 144px;
		height: 20px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#IWH_MOB_33img_border_med_web_4_pc_of {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 167px;
		height: 250px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_MOB_33chuttersna_med_web_4_pc_img {
		position: absolute;
		width: 143px;
		height: 231px;
		left: 12px;
		top: 6px;
		overflow: visible;
	}