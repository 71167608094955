
	.mediaViewInfo {
		--web-view-name: FreshSipHub;
		--web-view-id: FreshSipHub;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: FreshSipHub;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#FreshSipHub {
		position: absolute;
		width: 1366px;
		height: 3277px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: FreshSipHub;
		--web-view-id: FreshSipHub;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#FSH_PC_30Group_15101 {
		position: absolute;
		width: 1175px;
		height: 487.998px;
		left: 95.5px;
		top: 2768.904px;
		overflow: visible;
	}
	#FSH_PC_30Group_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 114.997px;
		overflow: visible;
	}
	#FSH_PC_30Group_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30Rectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.FSH_PC_30Rectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#FSH_PC_30socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#FSH_PC_30linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FSH_PC_30med_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FSH_PC_30linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#FSH_PC_30n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.FSH_PC_30n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FSH_PC_30linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.FSH_PC_30linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FSH_PC_30top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.FSH_PC_30top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FSH_PC_30Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FSH_PC_30shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FSH_PC_30instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#FSH_PC_30whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30med_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.FSH_PC_30med_shadow_space_website_7_ {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FSH_PC_30whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#FSH_PC_30n_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_PC_30affanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_PC_30address_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_PC_30location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#FSH_PC_30email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#FSH_PC_30phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#FSH_PC_30n_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#FSH_PC_30Contact_Us {
		left: 510.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#FSH_PC_30Ellipse_44 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FSH_PC_30Ellipse_44 {
		position: absolute;
		overflow: visible;
		width: 64px;
		height: 1px;
		left: 1153px;
		top: 286px;
	}
	#FSH_PC_30Group_15129 {
		position: absolute;
		width: 1366px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30Group_15124 {
		position: absolute;
		width: 1366px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30jugoslocos-i8JfQDc4Ha8-unsplas {
		position: absolute;
		width: 1366px;
		height: 684px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30Rectangle_749 {
		opacity: 0.24;
		fill: rgba(0,0,0,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.FSH_PC_30Rectangle_749 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 684px;
		left: 0px;
		top: 0px;
	}
	#FSH_PC_30Anime_Edit {
		position: absolute;
		width: 80px;
		height: 80px;
		left: 59px;
		top: 88px;
        border-radius: 50%;
		overflow: hidden;
	}
	#FSH_PC_30Your_Business_Name {
		left: 149px;
		top: 114px;
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#FSH_PC_30Revitalize_Your_Life_with_the_ {
		left: 59px;
		top: 257px;
		position: absolute;
		overflow: visible;
		width: 1095px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(255,255,255,1);
	}
	#FSH_PC_30Group_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 61px;
		top: 452px;
		overflow: visible;
	}
	#FSH_PC_30Rectangle_3 {
		fill: rgba(233,153,15,1);
	}
	.FSH_PC_30Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#FSH_PC_30Available_Now {
		left: 37px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#FSH_PC_30Group_15135 {
		position: absolute;
		width: 1078px;
		height: 1370.174px;
		left: 144px;
		top: 770.922px;
		overflow: visible;
	}
	#FSH_PC_30Group_15126 {
		position: absolute;
		width: 1078px;
		height: 423.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30tangerine-newt-hINQgaTqg7Q-uns {
		position: absolute;
		width: 423.096px;
		height: 423.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 485px;
		top: 149.078px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FSH_PC_30It_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FSH_PC_30VISION {
		left: 487px;
		top: 84.078px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_PC_30Group_15125 {
		position: absolute;
		width: 1078px;
		height: 423.096px;
		left: 0px;
		top: 474px;
		overflow: visible;
	}
	#FSH_PC_30whitney-wright-TgQkxQc-t_U-uns {
		position: absolute;
		width: 423.096px;
		height: 423.096px;
		left: 654.904px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30Scroll_Group_88 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 0px;
		top: 149.078px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FSH_PC_30It_is_a_long_established_fact__b {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FSH_PC_30MISSION {
		left: 5px;
		top: 84.078px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_PC_30Group_15127 {
		position: absolute;
		width: 1078px;
		height: 423.096px;
		left: 0px;
		top: 947.078px;
		overflow: visible;
	}
	#FSH_PC_30alina-karpenko-jH1PBHevj38-uns {
		position: absolute;
		width: 423.096px;
		height: 423.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FSH_PC_30Scroll_Group_89 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 485px;
		top: 149.078px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FSH_PC_30It_is_a_long_established_fact__ca {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FSH_PC_30PRINCIPLES {
		left: 490px;
		top: 84.078px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_PC_30Our_Greatest_Drinks {
		left: 590px;
		top: 2241px;
		position: absolute;
		overflow: visible;
		width: 187px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FSH_PC_30Scroll_Group_90 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1286px;
		height: 380px;
		left: 40px;
		top: 2289px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#FSH_PC_30Group_15128 {
		position: relative;
        margin-right: 30px;
		width: 253px;
		height: 317px;
		left: 50px;
		top: 29px;
		overflow: visible;
	}
	#FSH_PC_30Rectangle_750_cd {
		fill: url(#FSH_PC_30Rectangle_750_cd);
	}
	.FSH_PC_30Rectangle_750_cd {
		filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 258px;
		height: 322px;
		left: 0px;
		top: 0px;
	}
	#FSH_PC_30sam-hojati-lw8GflbJwLc-unsplas {
		position: absolute;
		width: 231px;
		height: 262px;
		left: 11px;
		top: 11px;
		overflow: visible;
	}
	#FSH_PC_30Juice_1 {
		left: 11px;
		top: 281px;
		position: absolute;
		overflow: visible;
		width: 232px;
		height: 27px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}