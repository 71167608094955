
	.mediaViewInfo {
		--web-view-name: right component;
		--web-view-id: right_component;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: right_component;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#right_component {
	    position: absolute;
        width: 309px;
        height: 770px;
        top: 75px;
        left: 100px;
        background-color: rgba(255,255,255,1);
        overflow: hidden;
        --web-view-name: right component;
        --web-view-id: right_component;
        --web-scale-on-resize: true;
        --web-enable-deep-linking: true
	}
	#right_side_grp_calender_mob {
		position: absolute;
		width: 296px;
		height: 767px;
		left: 13px;
		top: 0px;
		overflow: visible;
	}
	#calender_bdr_calendermob_cu {
		fill: url(#calender_bdr_calendermob_cu);
	}
	.calender_bdr_calendermob_cu {
		position: absolute;
		overflow: visible;
		width: 296px;
		height: 767px;
		left: 0px;
		top: 0px;
	}
	#Scroll_date_and_event_oof_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 290px;
		height: 429px;
		left: 3px;
		top: 325px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#so_much_to_grpmob {
		position: absolute;
		width: 276.611px;
		height: 936px;
		left: 0.195px;
		top: 5px;
		overflow: visible;
	}
	#n_am_number_calendemob {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 40px;
		overflow: visible;
	}
	#n__am_lbl {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_1am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_1am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.081px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_1am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_2am_number_calendemob {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_2_am_lbl {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_12am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_12am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.049px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_12_am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_am_number_calender_mob {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 80px;
		overflow: visible;
	}
	#n__am_lbl_c {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_2am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_2am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.11px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_2_am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#n_am_number_calendemob_c {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 160px;
		overflow: visible;
	}
	#n__am_lbl_da {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_4am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_4am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.171px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_4am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_am_number_calender_mob_dd {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 120px;
		overflow: visible;
	}
	#n__am_lbl_de {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_3am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_3am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.141px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_3am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#n_am_number_calender_mob_dh {
		position: absolute;
		width: 276.611px;
		height: 17.001px;
		left: 0px;
		top: 200px;
		overflow: visible;
	}
	#n__am_lbl_di {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_5_am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_5_am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.203px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_5am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#n_am_number_calender_mob_dl {
		position: absolute;
		width: 276.611px;
		height: 16.001px;
		left: 0px;
		top: 280px;
		overflow: visible;
	}
	#n__am_lbl_dm {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_7_am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_7_am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.264px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_7am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_am_number_calender_mob_dp {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 240px;
		overflow: visible;
	}
	#n__am_lbl_dq {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni__6_am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni__6_am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.232px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_6am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#n_am_number_calender_mob_dt {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 320px;
		overflow: visible;
	}
	#n__am_lbl_du {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_8am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_8am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.293px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_8am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#n_0am_number_calendermob {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 400px;
		overflow: visible;
	}
	#LineOni10am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni10am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.354px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_0_am_lbl {
		left: 18px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#add_btn_10am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_am_number_calender_mob_d {
		position: absolute;
		width: 276.611px;
		height: 17.001px;
		left: 0px;
		top: 360px;
		overflow: visible;
	}
	#LineOni_9am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_9am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.325px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n__am_lbl_d {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 27px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#add_btn_9am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#n_1am_number_calendermob {
		position: absolute;
		width: 276.611px;
		height: 17.001px;
		left: 0px;
		top: 440px;
		overflow: visible;
	}
	#LineOni_11am {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_11am {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.386px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_1_am_lbl {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#add_btn_11am {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#n_pm_number_calender_mob {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 519px;
		overflow: visible;
	}
	#n__pm_lbl {
		left: 21px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_1pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_1pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 9.446px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_1_pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_2pm_number_calender_mob {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 480px;
		overflow: visible;
	}
	#LineOni_12am_ee {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_12am_ee {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.416px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_2_pm_lbl {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#add_btn_12_pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_pm_number_calender_mob_eh {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 560px;
		overflow: visible;
	}
	#n__pm_lbl_ei {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_2pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_2pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.477px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_2_pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_pm_number_calender_mob_el {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 639px;
		overflow: visible;
	}
	#n__pm_lbl_em {
		left: 21px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_4pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_4pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 9.538px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_4_pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_pm_number_calendermob {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 600px;
		overflow: visible;
	}
	#n__pm_lbl_eq {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_3am_er {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_3am_er {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.507px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_3_pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_pm_number_calender_mob_et {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 680px;
		overflow: visible;
	}
	#n__pm_lbl_eu {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_5pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_5pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.568px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_5pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_pm_number_calender_mob_ex {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 759px;
		overflow: visible;
	}
	#n__pm_lbl_ey {
		left: 21px;
		top: 1px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_7_pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_7_pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 9.629px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_7pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_pm_number_calender_mob_e {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 720px;
		overflow: visible;
	}
	#n__pm_lbl_e {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_6pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_6pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.599px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_6pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_pm_number_calender_mob_fa {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 800px;
		overflow: visible;
	}
	#n__pm_lbl_fa {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#LineOni_8pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_8pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.66px;
		transform: matrix(1,0,0,1,0,0);
	}
	#add_btn_8pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_pm_number_calendermob_e {
		position: absolute;
		width: 276.611px;
		height: 17px;
		left: 0px;
		top: 840px;
		overflow: visible;
	}
	#LineOni_9pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_9pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.69px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n__pm_lbl_fb {
		left: 21px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#add_btn_9pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#n_0pm_number_calender_mob {
		position: absolute;
		width: 276.611px;
		height: 16.001px;
		left: 0px;
		top: 881px;
		overflow: visible;
	}
	#LineOni_10pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_10pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 7.721px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_0_pm_lbl {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#add_btn_10pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_1pm_number_calendemob {
		position: absolute;
		width: 276.611px;
		height: 16px;
		left: 0px;
		top: 920px;
		overflow: visible;
	}
	#LineOni_11pm {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.LineOni_11pm {
		overflow: visible;
		position: absolute;
		width: 224.171px;
		height: 1px;
		left: 52.44px;
		top: 8.751px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_1_pm_lbl {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(0,0,0,1);
	}
	#add_btn_11pm {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#august_calender_mobside {
        position: absolute;
        width: 243px;
        height: 270px;
        left: 38px;
        top: 10px;
        overflow: visible;
	}
	#mobdates_bdr_calender_pc_side {
		fill: rgba(255,255,255,1);
	}
	.mobdates_bdr_calender_pc_side {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 239px;
		height: 233px;
		left: 0px;
		top: 37px;
	}
	#mobaug_bdr_calender_pc_side {
		fill: rgba(255,255,255,1);
	}
	.mobaug_bdr_calender_pc_side {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 239px;
		height: 46px;
		left: 0px;
		top: 0px;
	}
	#mobAugust_2023_lbl_calender_pc {
		left: 83px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#Pmobath_6951_left_calender_pc_ {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1.2000000476837158px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Pmobath_6951_left_calender_pc_ {
		overflow: visible;
		position: absolute;
		width: 5.02px;
		height: 10.713px;
		left: 9.807px;
		top: 9.416px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Pmobath_6952_rgh_calender_pc_s {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1.2000000476837158px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Pmobath_6952_rgh_calender_pc_s {
		overflow: visible;
		position: absolute;
		width: 5.02px;
		height: 10.713px;
		left: 208.362px;
		top: 9.416px;
		transform: matrix(1,0,0,1,0,0);
	}
	#mobMoncalender_pc_side {
		left: 1px;
		top: 39px;
		position: absolute;
		overflow: visible;
		width: 21px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#mobTuecalender_pc_side {
		left: 37px;
		top: 39px;
		position: absolute;
		overflow: visible;
		width: 17px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#mobWedcalender_pc_side {
		left: 67px;
		top: 39px;
		position: absolute;
		overflow: visible;
		width: 21px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#mobThucalender_pc_side {
		left: 102px;
		top: 39px;
		position: absolute;
		overflow: visible;
		width: 19px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#mobFricalender_pc_side {
		left: 137px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 13px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#mobSatcalender_pc_side {
		left: 169px;
		top: 39px;
		position: absolute;
		overflow: visible;
		width: 17px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#mobmobSuncalender_pc_side {
		left: 201px;
		top: 39px;
		position: absolute;
		overflow: visible;
		width: 19px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 10px;
		color: rgba(0,0,0,1);
	}
	#mob31_calender_pc_side11 {
		left: 6px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(170,170,170,1);
	}
	#mob1_calender_pc_side11 {
		left: 44px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob2_calender_pc_side11 {
		left: 77px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob3_calender_pc_side11 {
		left: 109px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob4_calender_pc_side {
		left: 141px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob5mob_calender_pc_side {
		left: 173px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob6_calender_pc_side {
		left: 208px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob7_calender_pc_side {
		left: 9px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob8_calender_pc_side {
		left: 44px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob9calender_pc_side {
		left: 77px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob10_calender_pc_side {
		left: 106px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob11calender_pc_side {
		left: 139px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 9px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(0,0,0,1);
	}
	#mob12calender_pc_side {
		left: 172px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob13calender_pc_side {
		left: 205px;
		top: 118px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob14calender_pc_side {
		left: 6px;
		top: 156px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob15calender_pc_side {
		left: 40px;
		top: 156px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob16calender_pc_side {
		left: 73px;
		top: 156px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob17calender_pc_side {
		left: 106px;
		top: 156px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob18_calender_pc_side {
		left: 138px;
		top: 156px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob19_calender_pc_side {
		left: 172px;
		top: 156px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob20calender_pc_side {
		left: 205px;
		top: 156px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#n_mob1_calender_pc_side {
		left: 6px;
		top: 195px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob22_calender_pc_side {
		left: 40px;
		top: 195px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#n_mob3_calender_pc_side {
		left: 73px;
		top: 195px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#n_mob4_calender_pc_side {
		left: 106px;
		top: 195px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob25_calender_pc_side {
		left: 138px;
		top: 195px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob26_calender_pc_side {
		left: 172px;
		top: 195px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob27_calender_pc_side {
		left: 205px;
		top: 195px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob28calender_pc_side {
		left: 6px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob29calender_pc_side {
		left: 40px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob30_calender_pc_side {
		left: 73px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob31_calender_pc_side {
		left: 106px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 11px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#mob1_calender_pc_side {
		left: 141px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(170,170,170,1);
	}
	#n__calender_mobside {
		left: 173px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(170,170,170,1);
	}
	#n__calender_mobside_gw {
		left: 208px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 5px;
		white-space: nowrap;
		text-align: center;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 8px;
		color: rgba(170,170,170,1);
	}
	#Icon_ionic-ios-arrow-back3123 {
		fill: rgba(0,0,0,1);
	}
	.Icon_ionic-ios-arrow-back3123 {
		overflow: visible;
		position: absolute;
		width: 13.503px;
		height: 23.619px;
		left: 10.95px;
		top: 10.691px;
		transform: matrix(1,0,0,1,0,0);
	}

    
    @keyframes fadeInRight {
        from {
          opacity: 0;
          transform: translate3d(100%, 0, 0);
        }
      
        to {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
      
      .fadeInRight {
        animation-name: fadeInRight;
      }