.mediaViewInfo {
    --web-view-name: sugg;
    --web-view-id: sugg;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: sugg;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#sugg {
    position: absolute;
    width: 334px;
    height: 366px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: sugg;
    --web-view-id: sugg;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#suggestions_grp_profile {
    position: absolute;
    width: 326px;
    height: 359px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#suggestions_bdr {
    fill: rgba(255,255,255,1);
}
.suggestions_bdr {
    filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
    position: absolute;
    overflow: visible;
    width: 334px;
    height: 367px;
    left: 0px;
    top: 0px;
}
#Suggestions_lbl_profile {
    left: 40px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 92px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(37,46,72,1);
}
#suggestions_scroll_profile_pag {
    mix-blend-mode: normal;
    position: absolute;
    width: 320px;
    height: 307px;
    left: 6px;
    top: 41px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#card_1_suggestion {
    position: relative;
    width: 251px;
    height: 95px;
    left: 34px;
    top: 11px;
    margin-top: 15px;
    overflow: visible;
}
/* #dow_shadow_sugg {
    fill: rgba(255,255,255,1);
}
.dow_shadow_sugg {
    filter: drop-shadow(0px 10px 80px rgba(123, 126, 246, 0.322));
    position: absolute;
    overflow: visible;
    width: 467px;
    height: 317px;
    left: 12px;
    top: 18px;
} */
#bg_shadow_sugg {
    fill: rgba(255,255,255,1);
}
.bg_shadow_sugg {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 94px;
    left: 0px;
    top: 0px;
}
#img_bdr_suggestion {
    position: absolute;
    width: 251px;
    height: 94px;
    left: 0px;
    top: 1px;
    border: 1px solid rgba(67, 205, 138, 0.651);
    border-radius: 25px;
    background-color: rgb(112, 243, 179); /* Fallback color */
    background-color: rgb(67,205,138, 0.4); /* Black w/opacity/see-through */
    object-fit: contain;
    overflow: hidden;
}
#sugg_down_bdr_ {
    fill: url(#sugg_down_bdr_);
}
.sugg_down_bdr_ {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 59px;
    left: 0px;
    top: 36px;
}
#Mark_Damon_lbl_suggestions {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    left: 5%;
    /* top: 70px; */
    bottom: 0px;
    position: absolute;
    overflow: visible;
    width: 90%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    background-color: rgba(135, 207, 172, 0.651);
    color: rgba(247,249,255,1);
}