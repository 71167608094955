.mediaViewInfo {
    --web-view-name: req pop up;
    --web-view-id: req_pop_up;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: req_pop_up;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#req_pop_up {
    position: absolute;
    width: 265px;
    height: 573px;
    top: 50%;
    left: 50%;
    border-radius: 15px;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: req pop up;
    --web-view-id: req_pop_up;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bg_pop_req_design {
    position: absolute;
    width: 265px;
    height: 543px;
    left: 0px;
    top: 30px;
    overflow: visible;
}
#dsfndsfd_s {
    fill: url(#dsfndsfd_s);
}
.dsfndsfd_s {
    position: absolute;
    overflow: visible;
    width: 265px;
    height: 30px;
    left: 0px;
    top: 0px;
}
#Requirements_pop_na {
    left: 77px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 112px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#grp_useless_req {
    position: absolute;
    width: 179px;
    height: 22px;
    left: 43px;
    top: 45px;
    overflow: visible;
}
#req_add {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Add_title_req {
    left: 148px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 32px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#req_pop_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 265px;
    height: 573px;
    left: 0px;
    top: 35px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Group_req_com_ {
    position: absolute;
    width: 253px;
    height: 37px;
    left: 2px;
    top: 3px;
    overflow: visible;
}
#border_data_req_com {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 0.5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.border_data_req_com {
    position: absolute;
    overflow: visible;
    width: 253px;
    height: 37px;
    left: 0px;
    top: 0px;
}
#Testing_data_val_req {
    left: 20px;
    top: 9px;
    position: absolute;
    overflow: visible;
    width: 48px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#plus1_add_req {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 191px;
    top: 9px;
    cursor: pointer;
    overflow: visible;
}

#close_comreq_pc {
    position: relative;
    width: 20px;
    height: 20px;
   
    float: right;
    margin: 7px;
    cursor: pointer;
    overflow: visible;
}