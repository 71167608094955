#meeting_calender_pop_up {
    position: absolute;
    width: 250px;
    height: 594px;
    left: 36px;
    top: 65px;
    overflow: visible;
}
#attendies_grp_add_meeting_cale {
    position: absolute;
    width: 250px;
    height: 66px;
    left: 0px;
    top: 528px;
    overflow: visible;
}
#attendies_lbl_meeting_calender {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 72px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_attendies_meeting_calender {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 225px;
    top: 0px;
    cursor: pointer;
    overflow: visible;
}
#img_imreanmeeting_calender {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0px;
    top: 31px;
    border-radius: 50%;
    overflow: hidden;
}
#imran_fool_meeting_calender {
    left: 40px;
    top: 27px;
    position: absolute;
    overflow: visible;
    width: 210px;
    height: 22px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(76,193,87,1);
}
#Full_Stack_Developer_meeting_c {
    left: 40px;
    top: 49px;
    position: absolute;
    overflow: visible;
    width: 117px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#end_time_grp_add_meeting_calen {
    position: absolute;
    width: 250px;
    height: 63px;
    left: 0px;
    top: 224px;
    overflow: visible;
}
#end_time_bdr_add_meeting_calen {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.end_time_bdr_add_meeting_calen {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#end_Time_lbl_add_meeting_calen {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 58px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#sstart_at_grp_add_meeting_cale {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 150px;
    overflow: visible;
}
#start_at__bdr_add_meeting_cale {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.start_at__bdr_add_meeting_cale {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#start_at_lbl_add_meeting_calen {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#category_grp_add_meeting_calen {
    position: absolute;
    width: 250px;
    height: 102px;
    left: 0px;
    top: 414px;
    overflow: visible;
}
#category_bdr_add_meeting_calen {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.category_bdr_add_meeting_calen {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 80px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Category_lbl_add_meeting_calen {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 64px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#agenda_grp_add_meeting_calende {
    position: absolute;
    width: 250px;
    height: 103px;
    left: 0px;
    top: 299px;
    overflow: visible;
}
#event_agenda_bdr_add_event_det {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.event_agenda_bdr_add_event_det {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 80px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#agenda_lbl_add_meeting_calende {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 59px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}

#title_type_grp_add_meeting_cal {
    position: absolute;
    width: 250px;
    height: 63px;
    left: 0px;
    top: 75px;
    overflow: visible;
}
#titletype_bdr_add_meeting_cale {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.titletype_bdr_add_meeting_cale {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#titletype_lbl_add_meeting_cale {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}