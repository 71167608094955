	.CononMbmediaViewInfo {
		--web-view-name: connection mob;
		--web-view-id: connection_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: connection_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#CononMbconnection_mob {
		position: absolute;
        width: 100%;
        height:calc(100% - 105px);
        left: 0px;
        top: 45px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: connection mob;
		--web-view-id: connection_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#CononMbdesgin_con_page_new_pc {
		fill: rgba(248,248,248,1);
	}
	.CononMbdesgin_con_page_new_pc {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 203px;
		left: 0px;
		top: 0px;
	}
	#CononMbScroll_Group_8 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 100%;
		left: calc((100% - 375px)/2);
		top: 0px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CononMboverrall_car_conreqsug_con_pag {
		position: relative;
		width: 159px;
		height: 246px;
		/* left: 23px; */
		top: 24px;
        margin: 10px;
		overflow: visible;
	}
	#CononMbover_all_bdr_sug_con_page_new_ {
		fill: rgba(255,255,255,1);
	}
	.CononMbover_all_bdr_sug_con_page_new_ {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 167px;
		height: 254px;
		left: 0px;
		top: 0px;
	}
	#CononMbAnime_Edit_con_page_new_pc {
		position: absolute;
		width: 129px;
		height: 122px;
		left: 15px;
		top: 30px;
		overflow: visible;
	}
	#CononMbdesgination_pc_con_page {
		position: absolute;
		width: 146px;
		height: 23px;
		left: 10px;
		top: 156px;
		overflow: visible;
	}
	#CononMbjob-seeker_con_page_new_pc {
		position: absolute;
		width: 22px;
		height: 21px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CononMbcon_page_new_pc_cor {
		left: 26px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 121px;
		height: 20px;
		
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: capitalize;
	}
	#CononMbMohammed_Affan_con_page_new_pc {
		left: 1px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 159px;
		height: 20px;
		
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
	}
	#CononMbblock_btn_grp_con_page_new_pc {
		position: absolute;
		width: 33px;
		height: 33px;
		left: 88px;
		top: 207px;
		overflow: visible;
	}
	#CononMbshare_btn_con_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.CononMbshare_btn_con_page_new_pc {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 39px;
		height: 39px;
		left: 0px;
		top: 0px;
	}
	#CononMbshare_img_con_page_new_pc {
		position: absolute;
		width: 22px;
		height: 22px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#CononMbdisconnect_btn_grp_con_page_ne {
		position: absolute;
		width: 33px;
		height: 33px;
		left: 42px;
		top: 207px;
		overflow: visible;
	}
	#CononMbbdr_no_Copines {
		fill: rgba(255,255,255,1);
	}
	.CononMbbdr_no_Copines {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 39px;
		height: 39px;
		left: 0px;
		top: 0px;
	}
	#CononMbno-connection_Copines {
		position: absolute;
		width: 21px;
		height: 22px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#CononMbcompany_anme_new_ {
		position: absolute;
		width: 146px;
		height: 22px;
		left: 10px;
		top: 182px;
		overflow: visible;
	}
	#CononMboffice-building_img_con_page_n {
		position: absolute;
		width: 22px;
		height: 21px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CononMboperate_live_lbl_con_page_new_ {
		left: 26px;
		top: 2px;
		position: absolute;
		overflow: visible;
		width: 121px;
		height: 20px;
	
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		text-transform: capitalize;
	}
