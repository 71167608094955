	.mediaViewInfo {
		--web-view-name: KnowledgeSpace;
		--web-view-id: KnowledgeSpace;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: KnowledgeSpace;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#KnowledgeSpace {
		position: absolute;
		width: 1440px;
		height: 4790px;
        left:calc((100vw - 1440px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: KnowledgeSpace;
		--web-view-id: KnowledgeSpace;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#KS_20_PCGroup_117 {
		position: absolute;
		width: 752px;
		height: 428px;
		left: 344px;
		top: 4331px;
		overflow: visible;
	}
	#KS_20_PClocation {
		position: absolute;
		width: 24px;
		height: 30px;
		left: 576px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCn_Copyright_Live_OPC_Private_L {
		left: 120px;
		top: 406px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(173,173,173,1);
	}
	#KS_20_PCmessage {
		position: absolute;
		width: 30px;
		height: 21px;
		left: 576px;
		top: 157px;
		overflow: visible;
	}
	#KS_20_PCphone {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 576px;
		top: 295px;
		overflow: visible;
	}
	#KS_20_PCScroll_Group_66 {
		mix-blend-mode: normal;
		position: absolute;
		width: 182px;
		height: 81px;
		left: 570px;
		top: 41px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_PCScroll_Group_66::-webkit-scrollbar{
		display: none;
	}
	#KS_20_PCLSE_Houghton_Street_London_WC2 {
		left: 6px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 76px;
		line-height: 24px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(72,72,72,1);
	}
	#KS_20_PChellogmailcom {
		left: 576px;
		top: 203px;
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 76px;
		line-height: 24px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(72,72,72,1);
	}
	#KS_20_PCn_4057686 {
		left: 576px;
		top: 341px;
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 76px;
		line-height: 24px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(72,72,72,1);
	}
	#KS_20_PCRectangle_740 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.KS_20_PCRectangle_740 {
		position: absolute;
		overflow: visible;
		width: 476px;
		height: 314px;
		left: 0px;
		top: 0px;
	}
	#KS_20_PCGroup_111 {
		position: absolute;
		width: 1326px;
		height: 852px;
		left: 75px;
		top: 2422px;
		overflow: visible;
	}
	#KS_20_PCCourses {
		left: 556px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 179px;
		white-space: nowrap;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 48px;
		color: rgba(17,17,17,1);
	}
	#KS_20_PCScroll_Group_67 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1326px;
		height: 786px;
		left: 0px;
		top: 66px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#KS_20_PCScroll_Group_67::-webkit-scrollbar{
		display: none;
	}
	#KS_20_PCGroup_15053 {
		position: relative;
		width: 371px;
		height: 700px;
		left: 58px;
		top: 43px;
        margin-right: 30px;
		overflow: visible;
	}
	#KS_20_PCRectangle_71 {
		position: absolute;
		width: 371px;
		height: 301px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_25 {
		position: absolute;
		width: 371px;
		height: 399px;
		left: 0px;
		top: 301px;
		overflow: visible;
	}
	#KS_20_PCRectangle_72 {
		fill: rgba(241,241,241,1);
	}
	.KS_20_PCRectangle_72 {
		position: absolute;
		overflow: visible;
		width: 371px;
		height: 399px;
		left: 0px;
		top: 0px;
	}
	#KS_20_PCRectangle_73 {
		fill: rgba(207,207,207,1);
	}
	.KS_20_PCRectangle_73 {
		position: absolute;
		overflow: visible;
		width: 371px;
		height: 3px;
		left: 0px;
		top: 188px;
	}
	#KS_20_PCRectangle_74 {
		fill: rgba(207,207,207,1);
	}
	.KS_20_PCRectangle_74 {
		position: absolute;
		overflow: visible;
		width: 245px;
		height: 2px;
		left: 63px;
		top: 272px;
	}
	#KS_20_PCWeb_Design {
		left: 31px;
		top: 31px;
		position: absolute;
		overflow: visible;
		width: 171px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCTime__3_pm {
		/* left: 112px; */
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 100%;
        text-align: center;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -4px;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCScroll_Group_68 {
		mix-blend-mode: normal;
		position: absolute;
		width: 312px;
		height: 84px;
		left: 21px;
		top: 89px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_PCLorem_ipsum_dolor_sit_amet_con {
		left: 10px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 293px;
		height: 70px;
		line-height: 24px;
		margin-top: -3px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(72,72,72,1);
	}
	#KS_20_PCRectangle_76 {
		fill: rgba(39,174,96,1);
	}
	.KS_20_PCRectangle_76 {
		position: absolute;
		overflow: visible;
		width: 325px;
		height: 60px;
		left: 24px;
		top: 311px;
	}
	#KS_20_PCJoin_Now {
		left: 136px;
		top: 325px;
		position: absolute;
		overflow: visible;
		width: 102px;
		white-space: nowrap;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(255,255,255,1);
	}
	#KS_20_PCGroup_112 {
		position: absolute;
		width: 1440px;
		height: 664px;
		left: 0px;
		top: 3441px;
		overflow: visible;
	}
	#KS_20_PCPath_2 {
		fill: rgba(241,241,241,1);
	}
	.KS_20_PCPath_2 {
		overflow: visible;
		position: absolute;
		width: 1440px;
		height: 664px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#KS_20_PCOur_Teachers {
		left: 574px;
		top: 67px;
		position: absolute;
		overflow: visible;
		width: 293px;
		white-space: nowrap;
		line-height: 60px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 48px;
		color: rgba(17,17,17,1);
	}
	#KS_20_PCGroup_27 {
		position: absolute;
		width: 329px;
		height: 96px;
		left: 134px;
		top: 173px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6 {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26 {
		position: absolute;
		width: 197px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCVelvet_Vachon {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 198px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCDesign_Head {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 143px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_27_d {
		position: absolute;
		width: 319px;
		height: 96px;
		left: 534px;
		top: 173px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6_d {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26_d {
		position: absolute;
		width: 187px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCArlene_Anello {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 188px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCSEO_Head {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 114px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_27_ea {
		position: absolute;
		width: 341px;
		height: 96px;
		left: 934px;
		top: 173px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6_ea {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26_ea {
		position: absolute;
		width: 209px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCBenton_Colley {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 193px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCPhotography_Head {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 210px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_27_ec {
		position: absolute;
		width: 317px;
		height: 96px;
		left: 134px;
		top: 329px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6_ed {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26_ee {
		position: absolute;
		width: 185px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCFloyd_Fukuda {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 186px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCMarketing_HOD {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 174px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_27_eh {
		position: absolute;
		width: 284px;
		height: 96px;
		left: 534px;
		top: 329px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6_ei {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26_ej {
		position: absolute;
		width: 152px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCElena_Cully {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 153px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCDesign_Head_el {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 143px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_27_em {
		position: absolute;
		width: 335px;
		height: 96px;
		left: 934px;
		top: 329px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6_en {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26_eo {
		position: absolute;
		width: 203px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCBurton_Brooke {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 204px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCWeb_Technologist {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 200px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_27_er {
		position: absolute;
		width: 349px;
		height: 96px;
		left: 134px;
		top: 485px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6_es {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26_et {
		position: absolute;
		width: 217px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCRessie_Rottman {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 218px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCDesign_Head_ev {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 143px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_27_ew {
		position: absolute;
		width: 341px;
		height: 96px;
		left: 534px;
		top: 485px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6_ex {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26_ey {
		position: absolute;
		width: 209px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCReed_Recio {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 156px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCPhotography_Head_e {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 210px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_27_e {
		position: absolute;
		width: 345px;
		height: 96px;
		left: 934px;
		top: 485px;
		overflow: visible;
	}
	#KS_20_PCEllipse_6_e {
		position: absolute;
		width: 96px;
		height: 96px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_26_e {
		position: absolute;
		width: 213px;
		height: 75px;
		left: 132px;
		top: 11px;
		overflow: visible;
	}
	#KS_20_PCNancee_Bluford {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 214px;
		white-space: nowrap;
		line-height: 36px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCSEO_Head_e {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 114px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -3px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(39,174,96,1);
	}
	#KS_20_PCGroup_108 {
		position: absolute;
		width: 1440px;
		height: 700px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCRectangle_1 {
		fill: rgba(26,26,26,1);
	}
	.KS_20_PCRectangle_1 {
		position: absolute;
		overflow: visible;
		width: 1440px;
		height: 700px;
		left: 0px;
		top: 0px;
	}
	#KS_20_PCPath_1 {
		opacity: 0.7;
		position: absolute;
		width: 1440px;
		height: 700px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCKnowledge_is_power {
		left: 381px;
		top: 271px;
		position: absolute;
		overflow: visible;
		width: 678px;
		white-space: nowrap;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 72px;
		color: rgba(255,255,255,1);
	}
	#KS_20_PCAny_succesfull_career_starts_w {
		left: 451px;
		top: 377px;
		position: absolute;
		overflow: visible;
		width: 540px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -4px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(255,255,255,1);
	}
	#KS_20_PClogo {
		position: absolute;
		width: 40px;
		height: 32px;
		left: 135px;
		top: 48px;
        border-radius: 50%;
		overflow: hidden;
	}
	#KS_20_PCRectangle_148 {
		fill: rgba(39,174,97,1);
	}
	.KS_20_PCRectangle_148 {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 56px;
		left: 623px;
		top: 462px;
	}
	#KS_20_PCLearn_more {
		left: 652px;
		top: 477px;
		position: absolute;
		overflow: visible;
		width: 139px;
		white-space: nowrap;
		text-align: center;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
		color: rgba(255,255,255,1);
	}
	#KS_20_PCName {
		left: 192px;
		top: 53px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#KS_20_PCGroup_15056 {
		position: absolute;
		width: 1167px;
		height: 1527px;
		left: 136px;
		top: 760px;
		overflow: visible;
	}
	#KS_20_PCGroup_109 {
		position: absolute;
		width: 1162px;
		height: 440px;
		left: 0px;
		top: 543px;
		overflow: visible;
	}
	#KS_20_PCtamanna-rumee-vaTsR-ghLog-unsp {
		position: absolute;
		width: 440px;
		height: 440px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_3 {
		position: absolute;
		width: 480px;
		height: 305px;
		left: 682px;
		top: 64px;
		overflow: visible;
	}
	#KS_20_PCMission {
		left: 14px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 126px;
		white-space: nowrap;
		line-height: 48px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCScroll_Group_65 {
		mix-blend-mode: normal;
		position: absolute;
		width: 480px;
		height: 235px;
		left: 0px;
		top: 70px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_PCScroll_Group_65::-webkit-scrollbar{
		display: none;
	}
	#KS_20_PCLorem_ipsum_dolor_sit_amet_con_fl {
		left: 15px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 451px;
		height: 232px;
		line-height: 24px;
		margin-top: -3px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCGroup_15051 {
		position: absolute;
		width: 1164px;
		height: 440px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCtim-mossholder-WE_Kv_ZB1l0-uns {
		position: absolute;
		width: 440px;
		height: 440px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_3_fo {
		position: absolute;
		width: 484px;
		height: 326px;
		left: 680px;
		top: 64px;
		overflow: visible;
	}
	#KS_20_PCVision {
		left: 16px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		line-height: 48px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCScroll_Group_64 {
		mix-blend-mode: normal;
		position: absolute;
		width: 484px;
		height: 267px;
		left: 0px;
		top: 59px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_PCScroll_Group_64::-webkit-scrollbar{
		display: none;
	}
	#KS_20_PCLorem_ipsum_dolor_sit_amet_con_fr {
		left: 17px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 451px;
		height: 249px;
		line-height: 24px;
		margin-top: -3px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCGroup_15052 {
		position: absolute;
		width: 1167px;
		height: 440px;
		left: 0px;
		top: 1087px;
		overflow: visible;
	}
	#KS_20_PCnguyen-khanh-ly-liiusuaISP0-un {
		position: absolute;
		width: 440px;
		height: 440px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#KS_20_PCGroup_3_fu {
		position: absolute;
		width: 490px;
		height: 335px;
		left: 677px;
		top: 64px;
		overflow: visible;
	}
	#KS_20_PCPrinciples {
		left: 19px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 162px;
		white-space: nowrap;
		line-height: 48px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCScroll_Group_63 {
		mix-blend-mode: normal;
		position: absolute;
		width: 490px;
		height: 286px;
		left: 0px;
		top: 49px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#KS_20_PCScroll_Group_63::-webkit-scrollbar{
		display: none;
	}
	#KS_20_PCLorem_ipsum_dolor_sit_amet_con_fx {
		left: 20px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 451px;
		height: 258px;
		line-height: 24px;
		margin-top: -3px;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(26,26,26,1);
	}
	#KS_20_PCContact {
		left: 634px;
		top: 4205px;
		position: absolute;
		overflow: visible;
		width: 174px;
		white-space: nowrap;
		line-height: 60px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 48px;
		color: rgba(17,17,17,1);
	}