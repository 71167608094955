	.mediaViewInfo {
		--web-view-name: side nav bar mob;
		--web-view-id: side_nav_bar_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: side_nav_bar_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#side_nav_bar_mob {
		position: absolute;
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 10;
		background-color: rgba(219,242,231,1);
        overflow-x: hidden;
        overflow-y: auto;
		--web-view-name: side nav bar mob;
		--web-view-id: side_nav_bar_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}

   
@keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInRight {
    animation: fadeInRight 1s;
  }

	#side_nav_bg__be {
		fill: url(#side_nav_bg__be);
	}
	.side_nav_bg__be {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		/* left: -5px; */
		top: 0px;
	}
	#left_comp_mob_menu {
		position: absolute;
		width: 416px;
		height: 675px;
		left: -318px;
		top: 68px;
		overflow: visible;
	}
	#side_nav_border_3 {
		fill: rgba(204,214,209,1);
	}
	.side_nav_border_3 {
		position: absolute;
		overflow: visible;
		width: 81px;
		height: 565px;
		left: 335px;
		top: 56px;
	}
	#side_nav_border_2 {
		fill: rgba(255,255,255,1);
	}
	.side_nav_border_2 {
		position: absolute;
		overflow: visible;
		width: 81px;
		height: 674px;
		left: 294px;
		top: 1px;
        transition: all 0.30000001192092896s ease-out;
        --web-animation: fadein 0.30000001192092896s ease-out;
        cursor: pointer;
	}
	#side_nav_border_1 {
		fill: rgba(219,242,231,1);
	}
	.side_nav_border_1 {
		overflow: visible;
		position: absolute;
		width: 67.056px;
		height: 376.655px;
		transform: translate(318px, -68px) matrix(1,0,0,1,-163.2004,-86.7996) rotate(-90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#logged_user_mob_pic {
		position: absolute;
		width: 78px;
		height: 78px;
		left: 198px;
		top: 22px;
        border-radius: 50%;
		overflow: hidden;
	}
	#dash_bdr_grp_mob {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 139px;
		top: 186px;
		overflow: visible;
	}
	#dash_mob {
		fill: rgba(255,255,255,1);
	}
	.dash_mob {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Dashboard_side_lbl_mob {
		left: 51px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#dashboard_Icon_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#metting_room_grp_mob {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 139px;
		top: 414px;
		overflow: visible;
	}
	#meet_mob {
		fill: rgba(255,255,255,1);
	}
	.meet_mob {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Meeting_Room_side_lbl_mob {
		left: 51px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#meeting_room_icon_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#portfolio_grp_mob {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 139px;
		top: 243px;
		overflow: visible;
	}
	#portfolio_mob {
		fill: rgba(255,255,255,1);
	}
	.portfolio_mob {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#portfolio_side_lbl_mob {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 76px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#portfolio_icon_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#connection_grp_mob {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 139px;
		top: 300px;
		overflow: visible;
	}
	#con_mob {
		fill: rgba(255,255,255,1);
	}
	.con_mob {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#My_Connection_side_lbl_mob {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 61px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#my_connections_icon_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#market_grp_mob {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 139px;
		top: 470px;
		overflow: visible;
	}
	#market_mob {
		fill: rgba(255,255,255,1);
	}
	.market_mob {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Market_side_lbl_mob {
		left: 51px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#market_icon_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#event_grp_mob {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 139px;
		top: 527px;
		overflow: visible;
	}
	#event_mob {
		fill: rgba(255,255,255,1);
	}
	.event_mob {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Events_side_lbl_mob {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 88px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#events_icon_ {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#logout_grp_mob {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 139px;
		top: 584px;
		overflow: visible;
	}
	#log_mob {
		fill: rgba(255,255,255,1);
	}
	.log_mob {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Logout_side_lbl_mob {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 64px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#logout_icon_I_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#com_grp_mob {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 139px;
		top: 357px;
		overflow: visible;
	}
	#com_mob {
		fill: rgba(255,255,255,1);
	}
	.com_mob {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Communities_side_lbl_mob {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 118px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#community_icon_I_1_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 7px;
		top: 6px;
		overflow: visible;
	}
	#Dashboard_side_lbl_mob_cg {
		left: 98px;
		top: 110px;
		position: absolute;
		overflow: visible;
		width: 278px;
		height: 22px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(4,4,4,1);
	}
