
	.mediaViewInfo {
		--web-view-name: menu – 2;
		--web-view-id: side_bar_icon;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: side_bar_icon;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#side_bar_icon {
		position: fixed;
		width: 112px;
		height: 100vh;
        z-index: 100;
		background-color: rgba(219,242,231,1);
		overflow-y: auto;
        overflow-x: hidden;
		--web-view-name: menu – 2;
		--web-view-id: side_bar_icon;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}

    #side_bar_icon::-webkit-scrollbar {
        display: none;
    }
	#side_nav_icon_border {
		fill: rgba(219,242,231,1);
	}
	.side_nav_icon_border {
		position: absolute;
		overflow: visible;
		width: 112px;
		height: 100%;
		left: 0px;
		top: 0px;
	}
	#side_logo_icon {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 22px;
		top: 28px;
		overflow: visible;
	}
	#dash_bdr_grp {
		position: absolute;
		width: 43px;
		height: 43px;
		left: 35px;
		top: 113px;
        cursor: pointer;
		overflow: visible;
	}
	#dash_bdr {
		fill: rgba(255,255,255,1);
	}
	.dash_bdr {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#dashboard_Icon_I {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#metting_room_grp {
		position: absolute;
		width: 43px;
		height: 43px;
		left: 35px;
		top: 341px;
        cursor: pointer;
		overflow: visible;
	}
	#meet_bdr {
		fill: rgba(255,255,255,1);
	}
	.meet_bdr {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#meeting_room_icon_I {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#portfolio_grp {
		position: absolute;
		width: 43px;
		height: 43px;
		left: 35px;
		top: 170px;
        cursor: pointer;
		overflow: visible;
	}
	#portfolio_bdr {
		fill: rgba(255,255,255,1);
	}
	.portfolio_bdr {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#portfolio_icon_I {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#connection_grp {
		position: absolute;
		width: 43px;
		height: 43px;
		left: 35px;
		top: 227px;
        cursor: pointer;
		overflow: visible;
	}
	#con_bdr {
		fill: rgba(255,255,255,1);
	}
	.con_bdr {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#my_connections_icon_I {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#market_grpp {
		position: absolute;
		width: 43px;
		height: 43px;
		left: 35px;
		top: 398px;
        cursor: pointer;
		overflow: visible;
	}
	#market_bdr {
		fill: rgba(255,255,255,1);
	}
	.market_bdr {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#market_icon_I {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#event_grp {
		position: absolute;
		width: 43px;
		height: 43px;
		left: 35px;
		top: 455px;
        cursor: pointer;
		overflow: visible;
	}
	#event_bdr {
		fill: rgba(255,255,255,1);
	}
	.event_bdr {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#events_icon_I {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#logout_grp {
		position: absolute;
		width: 43px;
		height: 43px;
		left: 35px;
		top: 512px;
        cursor: pointer;
		overflow: visible;
	}
	#log_bdr {
		fill: rgba(255,255,255,1);
	}
	.log_bdr {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#logout_icon_I {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#com_grp {
		position: absolute;
		width: 43px;
		height: 43px;
		left: 35px;
		top: 284px;
        cursor: pointer;
		overflow: visible;
	}
	#com_bdr {
		fill: rgba(255,255,255,1);
	}
	.com_bdr {
		position: absolute;
		overflow: visible;
		width: 43px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#community_icon_I {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 7px;
		top: 6px;
		overflow: visible;
	}
