.mediaViewInfo {
    --web-view-name: ad profile page pc;
    --web-view-id: ad_profile_page_pc;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: ad_profile_page_pc;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#ad_profile_page_pc {
    position: relative;
    width: 100%;
    height: 91px;
    /* top: calc(100% - 200px); */
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: ad profile page pc;
    --web-view-id: ad_profile_page_pc;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#ad_bdr {
    fill: rgba(177,224,201,1);
}
.ad_bdr {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 90px;
    left: 0px;
    top: 0px;
}
#AD_AREA_lbl {
    /* left: 282px; */
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    color: rgba(37,46,72,1);
}