
	.mediaViewInfo {
		--web-view-name: FruitFiesta;
		--web-view-id: FruitFiesta;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: FruitFiesta;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#FruitFiesta {
		position: absolute;
		width: 1366px;
		height: 3512px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: FruitFiesta;
		--web-view-id: FruitFiesta;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#FF_PC_29Group_15101 {
		position: absolute;
		width: 1175px;
		height: 481.998px;
		left: 96px;
		top: 3010.002px;
		overflow: visible;
	}
	#FF_PC_29Group_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 108.997px;
		overflow: visible;
	}
	#FF_PC_29Group_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29Rectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29Rectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#FF_PC_29socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#FF_PC_29linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29med_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FF_PC_29linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#FF_PC_29n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.FF_PC_29n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FF_PC_29linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.FF_PC_29linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FF_PC_29top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.FF_PC_29top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#FF_PC_29Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FF_PC_29instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#FF_PC_29whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29med_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29med_shadow_space_website_7_ {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#FF_PC_29whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#FF_PC_29n_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FF_PC_29affanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FF_PC_29address_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FF_PC_29location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#FF_PC_29email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#FF_PC_29phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#FF_PC_29n_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#FF_PC_29Contact_Us {
		left: 523.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 128px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -20px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(36,36,36,1);
	}
	#FF_PC_29Group_15117 {
		position: absolute;
		width: 1399px;
		height: 789px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29julia-zolotova-M_xIaxQE3Ms-uns {
		position: absolute;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29Rectangle_748 {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
	}
	.FF_PC_29Rectangle_748 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#FF_PC_29mae-mu-vbAEHCrvXZ0-unsplash {
		position: absolute;
		width: 514px;
		height: 514px;
		left: 885px;
		top: 275px;
		overflow: visible;
	}
	#FF_PC_29Anime_Edit {
		position: absolute;
		width: 80px;
		height: 80px;
		left: 61px;
		top: 45px;
        border-radius: 50%;
		overflow: hidden;
	}
	#FF_PC_29Your_Business_Name {
		left: 151px;
		top: 71px;
		position: absolute;
		overflow: visible;
		width: 184px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#FF_PC_29Exploring_Orchards_of_Flavor__ {
		left: 61px;
		top: 169px;
		position: absolute;
		overflow: visible;
		width: 865px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(255,255,255,1);
	}
	#FF_PC_29Group_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 61px;
		top: 452px;
		overflow: visible;
	}
	#FF_PC_29Rectangle_3 {
		fill: rgba(233,153,15,1);
	}
	.FF_PC_29Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#FF_PC_29Order_Now {
		left: 45px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 81px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#FF_PC_29Group_15118 {
		position: absolute;
		width: 1055px;
		height: 1400px;
		left: 156px;
		top: 819px;
		overflow: visible;
	}
	#FF_PC_29Group_15114 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29sneha-cecil-Qt6ojt3CacE-unspla {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 462px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FF_PC_29Scroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#FF_PC_29It_is_a_long_established_fact_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FF_PC_29VISION {
		left: 462px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FF_PC_29Group_15115 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 500px;
		overflow: visible;
	}
	#FF_PC_29tom-brunberg-D4ZtZX1UeAI-unspl {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 655px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29Scroll_Group_28_by {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 0px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FF_PC_29Scroll_Group_28_by::-webkit-scrollbar{
		display: none;
	}
	#FF_PC_29It_is_a_long_established_fact__bz {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FF_PC_29MISSION {
		left: 0px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FF_PC_29Group_15116 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 1000px;
		overflow: visible;
	}
	#FF_PC_29brenda-godinez-_Zn_7FzoL1w-uns {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29Scroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 462px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#FF_PC_29Scroll_Group_28_b::-webkit-scrollbar{
		display: none;
	}
	#FF_PC_29It_is_a_long_established_fact__b {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#FF_PC_29PRINCIPLES {
		left: 462px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#FF_PC_29Group_15119 {
		position: absolute;
		width: 1341px;
		height: 463px;
		left: 13px;
		top: 2369px;
		overflow: visible;
	}
	#FF_PC_29Scroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1341px;
		height: 386px;
		left: 0px;
		top: 77px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#FF_PC_29Scroll_Group_77::-webkit-scrollbar{
		display: none;
	}
	#FF_PC_29n_ {
		position: relative;
		width: 307px;
		height: 342px;
		left: 25px;
        margin-right: 30px;
		top: 21px;
		overflow: visible;
	}
	#FF_PC_29Mask_Group_22 {
		position: absolute;
		width: 307px;
		height: 311px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#FF_PC_29Rectangle_664 {
		fill: rgba(0,0,0,1);
	}
	.FF_PC_29Rectangle_664 {
		position: absolute;
		overflow: visible;
		width: 307px;
		height: 46px;
		left: 0px;
		top: 296px;
	}
	#FF_PC_29Something {
		left: 9px;
		top: 309px;
		position: absolute;
		overflow: visible;
		width: 293px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}
	#FF_PC_29Group_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 131px;
		top: 268px;
		overflow: visible;
	}
	#FF_PC_29Ellipse_38 {
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29Ellipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#FF_PC_29Ellipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29Ellipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#FF_PC_29Ellipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29Ellipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#FF_PC_29Ellipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29Ellipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#FF_PC_29Ellipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.FF_PC_29Ellipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#FF_PC_29In_Stock {
		left: 632px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 77px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}