.mediaViewInfo {
    --web-view-name: off feed;
    --web-view-id: off_feed;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: off_feed;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#off_feed {
    position: relative;
    width: 100%;
    height: 257px;
    /* top: calc(100% - 100px); */
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: off feed;
    --web-view-id: off_feed;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#offering_ffeed_grp {
    position: absolute;
    width: 100%;
    height: 251.209px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#off_feed_bdr {
    fill: rgba(255,255,255,1);
}
.off_feed_bdr {
    filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 259px;
    left: 0px;
    top: 0px;
}
#Offerings_Feed_lbl {
    left: 20px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#off_feed_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 250.778px;
    left: 0px;
    top: 0.431px;
    display: inline-flex;
    overflow-x: scroll;
    white-space: nowrap;
    overflow-y: hidden;
}
/* #shadow_offf_feed {
    fill: rgba(255,255,255,1);
}
.shadow_offf_feed {
    filter: drop-shadow(0px 10px 80px rgba(123, 126, 246, 0.251));
    position: absolute;
    overflow: visible;
    width: 401px;
    height: 348px;
    left: 30px;
    top: 83.244px;
} */
#card_1_grp_profile_off {
    position: relative;
    width: 187px;
    height: 166px;
    left: 16.999px;
    top: 47.569px;
    margin: 5px; 
    overflow: visible;
}
#card_1_of_feed_1 {
    position: absolute;
    width: 187px;
    height: 125.35px;
    left: 0px;
    top: 18.325px;
    object-fit: contain;
    border-radius: 5px;
    overflow: hidden;
}
#Offering_lbl {
    left: 58px;
    top: 149px;
    position: absolute;
    overflow: visible;
    width: 59px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,46,72,1);
}
#Mohammed_Affan_lbl_1 {
    left: 36px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,46,72,1);
}