	.mediaViewInfo {
		--web-view-name: ElectroTech Solutions;
		--web-view-id: ElectroTech_Solutions;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ElectroTech_Solutions;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ElectroTech_Solutions {
		position: absolute;
		width: 1366px;
		height: 3938px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: ElectroTech Solutions;
		--web-view-id: ElectroTech_Solutions;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#ETS_27_PCGroup_15103 {
		position: absolute;
		width: 1418.769px;
		height: 602.967px;
		left: -52.769px;
		top: -14.967px;
		overflow: visible;
	}
	#ETS_27_PCcopy-space-colorful-sewing-thr {
		position: absolute;
		width: 469.61px;
		height: 294.371px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCPowering_Your_World__Expert_El {
		left: 167.769px;
		top: 293.967px;
		position: absolute;
		overflow: visible;
		width: 625px;
		white-space: nowrap;
		line-height: 70px;
		margin-top: -8px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 54px;
		color: rgba(36,36,36,1);
	}
	#ETS_27_PCJoin_the_Spark_of_Excellence_I {
		opacity: 0.5;
		left: 167.769px;
		top: 528.967px;
		position: absolute;
		overflow: visible;
		width: 541px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(36,36,36,1);
	}
	#ETS_27_PCGroup_15102 {
		position: absolute;
		width: 588px;
		height: 588px;
		left: 830.769px;
		top: 14.967px;
		overflow: visible;
	}
	#ETS_27_PCRectangle_747 {
		fill: rgba(237,149,75,1);
	}
	.ETS_27_PCRectangle_747 {
		position: absolute;
		overflow: visible;
		width: 374px;
		height: 374px;
		left: 214px;
		top: 0px;
	}
	#ETS_27_PCelectrical-shielded-cable-with {
		position: absolute;
		width: 507px;
		height: 507px;
		left: 0px;
		top: 81px;
		overflow: visible;
	}
	#ETS_27_PCGroup_15111 {
		position: absolute;
		width: 393px;
		height: 70px;
		left: 167.769px;
		top: 102.967px;
		overflow: visible;
	}
	#ETS_27_PCAnime_Edit {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 0px;
		top: 0px;
        border-radius: 50%;
		overflow: hidden;
	}
	#ETS_27_PCElectician {
		left: 80px;
		top: 22px;
		position: absolute;
		overflow: visible;
		width: 314px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_PCGroup_15039 {
		position: absolute;
		width: 1193px;
		height: 1695px;
		left: 87px;
		top: 795px;
		overflow: visible;
	}
	#ETS_27_PCGroup_15033 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCmika-baumeister-dp9AxaKHdVg-un {
		filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCGroup_15038 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 600px;
		top: 38px;
		overflow: visible;
	}
	#ETS_27_PCScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#ETS_27_PCScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#ETS_27_PCIt_is_a_long_established_fact_ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#ETS_27_PCVISION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_PCGroup_15034 {
		position: absolute;
		width: 1193px;
		height: 419.078px;
		left: 0px;
		top: 638px;
		overflow: visible;
	}
	#ETS_27_PCpatrick-tomasso-1NTFSnV-KLs-un {
		filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 560px;
		height: 419.078px;
		left: 633px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCGroup_15037 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 0px;
		top: 39px;
		overflow: visible;
	}
	#ETS_27_PCScroll_Group_28_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#ETS_27_PCScroll_Group_28_::-webkit-scrollbar{
		display: none;
	}
	#ETS_27_PCIt_is_a_long_established_fact__ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#ETS_27_PCMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_PCGroup_15035 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 1276px;
		overflow: visible;
	}
	#ETS_27_PCframes-for-your-heart-iOLHAIax {
		filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCScroll_Group_28_bc {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 600px;
		top: 103px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#ETS_27_PCScroll_Group_28_bc::-webkit-scrollbar{
		display: none;
	}
	
	#ETS_27_PCIt_is_a_long_established_fact__bd {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#ETS_27_PCPRINCIPLES {
		left: 618px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_PCGroup_15107 {
		position: absolute;
		width: 1366px;
		height: 564.998px;
		left: 0px;
		top: 2640.002px;
		overflow: visible;
	}
	#ETS_27_PCOur_Services {
		left: 546px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 275px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 50px;
		color: rgba(36,36,36,1);
	}
	#ETS_27_PCsteve-johnson-hokONTrHIAQ-unsp {
		position: absolute;
		width: 1366px;
		height: 387px;
		left: 0px;
		top: 177.998px;
		overflow: visible;
	}
	#ETS_27_PCScroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1341px;
		height: 387px;
		left: 13px;
		top: 177.998px;
		overflow-x: scroll;
		overflow-y: hidden;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
	}
	#ETS_27_PCScroll_Group_77::-webkit-scrollbar{
		display: none;
	}
	#ETS_27_PCn_ {
		position: relative;
        margin-right: 30px;
		width: 307px;
		height: 342px;
		left: 25px;
		top: 21px;
		overflow: visible;
	}
	#ETS_27_PCMask_Group_22 {
		position: absolute;
		width: 307px;
		height: 296px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCRectangle_664 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCRectangle_664 {
		position: absolute;
		overflow: visible;
		width: 307px;
		height: 46px;
		left: 0px;
		top: 296px;
	}
	#ETS_27_PCSomething {
		left: 9px;
		top: 309px;
		position: absolute;
		overflow: visible;
		width: 293px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
		text-transform: uppercase;
	}
	#ETS_27_PCGroup_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 131px;
		top: 268px;
		overflow: visible;
	}
	#ETS_27_PCEllipse_38 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCEllipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#ETS_27_PCEllipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCEllipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#ETS_27_PCEllipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCEllipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#ETS_27_PCEllipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCEllipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#ETS_27_PCEllipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCEllipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#ETS_27_PCGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 509.998px;
		left: 96px;
		top: 3413.002px;
		overflow: visible;
	}
	#ETS_27_PCGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 136.998px;
		overflow: visible;
	}
	#ETS_27_PCGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#ETS_27_PCsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255px;
		overflow: visible;
	}
	#ETS_27_PClinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#ETS_27_PClinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#ETS_27_PCn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.ETS_27_PCn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#ETS_27_PClinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.ETS_27_PClinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#ETS_27_PCtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.ETS_27_PCtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#ETS_27_PCInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#ETS_27_PCinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#ETS_27_PCwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_PCmed_shadow_space_website_7_b {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_PCmed_shadow_space_website_7_b {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#ETS_27_PCwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#ETS_27_PCn_234567890_mobile_number_shad {
		left: 703px;
		top: 24px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_PCaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_PCaddress_med_shadow_space_websi {
		left: 703px;
		top: 127px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_PClocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129px;
		overflow: visible;
	}
	#ETS_27_PCemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76px;
		overflow: visible;
	}
	#ETS_27_PCphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23px;
		overflow: visible;
	}
	#ETS_27_PCn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_PCContact_Us {
		left: 450px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 255px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 50px;
		color: rgba(36,36,36,1);
	}