.mediaViewInfo {
		--web-view-name: Clinic Mob;
		--web-view-id: Clinic_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Clinic_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Clinic_Mob {
		position: absolute;
		width: 375px;
		height: 4260px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: Clinic Mob;
		--web-view-id: Clinic_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#Clinic_23_mobGroup_15043 {
		position: absolute;
		width: 375px;
		height: 464px;
		left: 0px;
		top: 19px;
		overflow: visible;
	}
	#Clinic_23_mobn_0173304_8507 {
		position: absolute;
		width: 375px;
		height: 249px;
		left: 0px;
		top: 95px;
		overflow: visible;
	}
	#Clinic_23_mobGroup_15044 {
		position: absolute;
		width: 333px;
		height: 139px;
		left: 21px;
		top: 325px;
		overflow: visible;
	}
	#Clinic_23_mobRectangle_102 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_mobRectangle_102 {
		filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		position: absolute;
		overflow: visible;
		width: 360px;
		height: 200px;
		left: 27px;
		top: 0px;
	}
	#Clinic_23_mobRectangle_746 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_mobRectangle_746 {
		filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		position: absolute;
		overflow: visible;
		width: 414px;
		height: 200px;
		left: 0px;
		top: 20px;
	}
	#Clinic_23_mobHealing_Starts_Here_ {
		filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 56px;
		top: 31px;
		position: absolute;
		overflow: visible;
		width: 217px;
		white-space: nowrap;
		text-align: left;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(215,113,108,1);
	}
	#Clinic_23_mobAnime_Edit {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 88px;
		top: 0px;
        border-radius: 50%;
		overflow: hidden;
	}
	#Clinic_23_mobOni_Chan_Clinic {
		left: 158px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 119px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_mobGroup_15047 {
		position: absolute;
		width: 322.86px;
		height: 238.974px;
		left: 22.412px;
		top: 555.001px;
		overflow: visible;
	}
	#Clinic_23_mobUnion_2 {
		fill: rgba(215,113,108,1);
	}
	.Clinic_23_mobUnion_2 {
		overflow: visible;
		position: absolute;
		width: 207.932px;
		height: 207.932px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_mobGroup_15046 {
		position: absolute;
		width: 280.298px;
		height: 180.974px;
		left: 42.563px;
		top: 58px;
		overflow: visible;
	}
	#Clinic_23_mobn_8706987_TaeAugust07 {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 280.298px;
		height: 180.974px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_mobGet_The_Best__Treatments_Done_ {
		filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 38px;
		top: 901.001px;
		position: absolute;
		overflow: visible;
		width: 207px;
		white-space: nowrap;
		line-height: 44px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(20,57,91,1);
	}
	#Clinic_23_mobGroup_9721 {
		position: absolute;
		width: 218px;
		height: 63px;
		left: 38px;
		top: 1066px;
		overflow: visible;
	}
	#Clinic_23_mobRectangle_107 {
		fill: rgba(215,113,108,1);
	}
	.Clinic_23_mobRectangle_107 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 236px;
		height: 81px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_mobSee_All_Apartment {
		left: 39px;
		top: 19px;
		position: absolute;
		overflow: visible;
		width: 143px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#Clinic_23_mobGroup_15033 {
		position: absolute;
		width: 364px;
		height: 618px;
		left: 11px;
		top: 1275px;
		overflow: visible;
	}
	#Clinic_23_mobclose-up-doctor-writing {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 299px;
		height: 224px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_mobGroup_15038 {
		position: absolute;
		width: 364px;
		height: 343px;
		left: 0px;
		top: 275px;
		overflow: visible;
	}
	#Clinic_23_mobScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 364px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Clinic_23_mobIt_is_a_long_established_fact_ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#Clinic_23_mobVISION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_mobGroup_15048 {
		position: absolute;
		width: 364px;
		height: 618px;
		left: 11px;
		top: 1933px;
		overflow: visible;
	}
	#Clinic_23_mobclose-up-businessman-businessw {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 299px;
		height: 224px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_mobGroup_15038_bn {
		position: absolute;
		width: 364px;
		height: 343px;
		left: 0px;
		top: 275px;
		overflow: visible;
	}
	#Clinic_23_mobScroll_Group_28_bo {
		mix-blend-mode: normal;
		position: absolute;
		width: 364px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Clinic_23_mobIt_is_a_long_established_fact__bp {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#Clinic_23_mobMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_mobGroup_15049 {
		position: absolute;
		width: 364px;
		height: 618px;
		left: 11px;
		top: 2591px;
		overflow: visible;
	}
	#Clinic_23_mobdoctor-patient {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 299px;
		height: 224px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_mobGroup_15038_bt {
		position: absolute;
		width: 364px;
		height: 343px;
		left: 0px;
		top: 275px;
		overflow: visible;
	}
	#Clinic_23_mobScroll_Group_28_bu {
		mix-blend-mode: normal;
		position: absolute;
		width: 364px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Clinic_23_mobIt_is_a_long_established_fact__bv {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#Clinic_23_mobPRINCIPLES {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_mobOur_Teatments {
		filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 102px;
		top: 3269px;
		position: absolute;
		overflow: visible;
		width: 173px;
		white-space: nowrap;
		text-align: center;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(215,113,108,1);
	}
	#Clinic_23_mobScroll_Group_62 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 340px;
		left: 0px;
		top: 3302px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#Clinic_23_mobGroup_15031 {
		position: relative;
		width: 296px;
		height: 237px;
		left: 11px;
		top: 30px;
        margin-right: 30px;
		overflow: visible;
	}
	#Clinic_23_mobRectangle_112 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_mobRectangle_112 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 314px;
		height: 255px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_mobMask_Group_4 {
		position: absolute;
		width: 272px;
		height: 164px;
		left: 12px;
		top: 12.001px;
		overflow: visible;
	}
	#Clinic_23_mobApartment_London {
		left: 11px;
		top: 208px;
		position: absolute;
		overflow: visible;
		width: 117px;
		white-space: nowrap;
		text-align: left;
		font-family: Sen;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#Clinic_23_mobGroup_9694 {
		position: absolute;
		width: 218.774px;
		height: 19px;
		left: 11.226px;
		top: 184px;
		overflow: visible;
	}
	#Clinic_23_mobBangalore {
		opacity: 0.5;
		left: 14.774px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 205px;
		height: 19px;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#Clinic_23_mobgeo-alt {
		position: absolute;
		width: 12.521px;
		height: 17.482px;
		left: 0px;
		top: 1.518px;
		overflow: visible;
	}
	#Clinic_23_mobPath_2927 {
		fill: rgba(254,120,71,1);
	}
	.Clinic_23_mobPath_2927 {
		overflow: visible;
		position: absolute;
		width: 12.521px;
		height: 17.482px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_mobGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 39px;
		top: 3701.999px;
		overflow: visible;
	}
	#Clinic_23_mobRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_mobRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#Clinic_23_mobGroup_15050 {
		position: absolute;
		width: 358px;
		height: 316px;
		left: 9px;
		top: 3915px;
		overflow: visible;
	}
	#Clinic_23_mobsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 194px;
		overflow: visible;
	}
	#Clinic_23_moblinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_mobmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_mobmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_moblinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#Clinic_23_mobn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.Clinic_23_mobn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_moblinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.Clinic_23_moblinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_mobtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.Clinic_23_mobtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_mobInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_mobshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_mobshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_mobinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#Clinic_23_mobwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_mobmed_shadow_space_website_7_cl {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_mobmed_shadow_space_website_7_cl {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_mobwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#Clinic_23_mobn_234567890_mobile_number_shad {
		left: 48px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_mobaffanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_mobaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_moblocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#Clinic_23_mobemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#Clinic_23_mobphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_mobn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}