	.mediaViewInfo {
		--web-view-name: TechTrekker mob;
		--web-view-id: TechTrekker_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: TechTrekker_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#TechTrekker_mob {
		position: absolute;
		width: 375px;
		height: 4709px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: TechTrekker mob;
		--web-view-id: TechTrekker_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#TTMOBGroup_15132 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 8.5px;
		top: 4097.904px;
		overflow: visible;
	}
	#TTMOBGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 30px;
		top: 73.095px;
		overflow: visible;
	}
	#TTMOBRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#TTMOBGroup_15050 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 480.096px;
		overflow: visible;
	}
	#TTMOBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TTMOBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#TTMOBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.TTMOBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.TTMOBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.TTMOBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TTMOBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#TTMOBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBmed_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.TTMOBmed_shadow_space_website_7_ {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TTMOBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#TTMOBn_234567890_mobile_number_shad {
		left: 48px;
		top: 293.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TTMOBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 340.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TTMOBaddress_med_shadow_space_websi {
		left: 35px;
		top: 390.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TTMOBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 392.096px;
		overflow: visible;
	}
	#TTMOBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 339.096px;
		overflow: visible;
	}
	#TTMOBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 286.096px;
		overflow: visible;
	}
	#TTMOBn_Copyright_Operate_Live_OPC_P {
		left: 27px;
		top: 565.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#TTMOBContact_Us {
		left: 127.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -22.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#TTMOBScroll_Group_95 {
		mix-blend-mode: normal;
		position: absolute;
		width: 356px;
		height: 241px;
		left: 9px;
		top: 119px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#TTMOBControllers {
		position: absolute;
		width: 525px;
		height: 219px;
		left: 6px;
		top: 11px;
		overflow: visible;
	}
	#TTMOBRectangle_3 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTMOBRectangle_3 {
		position: absolute;
		overflow: visible;
		width: 149px;
		height: 213px;
		left: 364px;
		top: 0px;
	}
	#TTMOBstefen-tan-KYw1eUx1J7Y-unsplas {
		position: absolute;
		width: 165px;
		height: 219px;
		left: 360px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBGroup_15173 {
		position: absolute;
		width: 150px;
		height: 213px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBRectangle_1 {
		opacity: 0.35;
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTMOBRectangle_1 {
		position: absolute;
		overflow: visible;
		width: 150px;
		height: 213px;
		left: 0px;
		top: 0px;
	}
	#TTMOBn_a36d7d2482866597129061513543 {
		position: absolute;
		width: 112px;
		height: 169px;
		left: 19px;
		top: 22px;
		overflow: visible;
	}
	#TTMOBGroup_15172 {
		position: absolute;
		width: 150px;
		height: 213.16px;
		left: 180px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBRectangle_2 {
		opacity: 0.35;
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTMOBRectangle_2 {
		position: absolute;
		overflow: visible;
		width: 150px;
		height: 213px;
		left: 0px;
		top: 0px;
	}
	#TTMOBryan-putra-j4PqlNVZ4Bc-unsplas {
		position: absolute;
		width: 142.051px;
		height: 213.16px;
		left: 0.998px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBquaritsch-photography-m2zuB8Dq {
		position: absolute;
		width: 495px;
		height: 330.366px;
		left: -28px;
		top: 312px;
		overflow: visible;
	}
	#TTMOBCompany_Name {
		left: 100px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 206px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,28,65,1);
	}
	#TTMOBjoshua-newton-g4_IymCiD-k-unsp {
		position: absolute;
		width: 61px;
		height: 61px;
		left: 24px;
		top: 22px;
		overflow: visible;
	}
	#TTMOBBottom_right_items {
		position: absolute;
		width: 54px;
		height: 120px;
		left: 24px;
		top: 477px;
		overflow: visible;
	}
	#TTMOBRectangle_15 {
		fill: rgba(234,238,244,1);
	}
	.TTMOBRectangle_15 {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 49px;
		left: 0px;
		top: 71px;
	}
	#TTMOBRectangle_16 {
		fill: rgba(255,88,178,0.141);
	}
	.TTMOBRectangle_16 {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#TTMOBhand-heart {
		position: absolute;
		width: 19.034px;
		height: 16.73px;
		left: 17.825px;
		top: 16.862px;
		overflow: visible;
	}
	#TTMOBPath_5 {
		fill: rgba(255,88,178,0);
		stroke: rgba(255,88,178,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: square;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.TTMOBPath_5 {
		overflow: visible;
		position: absolute;
		width: 23.104px;
		height: 21.633px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBquestion-mark {
		position: absolute;
		width: 13.332px;
		height: 22.073px;
		left: 20.327px;
		top: 84.828px;
		overflow: visible;
	}
	#TTMOBPath_7 {
		fill: rgba(93,106,126,1);
	}
	.TTMOBPath_7 {
		overflow: visible;
		position: absolute;
		width: 13.332px;
		height: 16.555px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_8 {
		fill: rgba(93,106,126,1);
	}
	.TTMOBPath_8 {
		overflow: visible;
		position: absolute;
		width: 3.678px;
		height: 3.678px;
		left: 4.503px;
		top: 18.395px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBGroup_15174 {
		position: absolute;
		width: 353.442px;
		height: 73px;
		left: 10.779px;
		top: 642.366px;
		overflow: visible;
	}
	#TTMOBNEW_GADGETS {
		left: 2px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 131px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(114,124,137,1);
		letter-spacing: 2.2px;
	}
	#TTMOBBuy_Now {
		left: 0px;
		top: 31px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: lighter;
		font-size: 13px;
		color: rgba(114,124,137,1);
	}
	#TTMOBView_details {
		left: 254px;
		top: 31px;
		position: absolute;
		overflow: visible;
		width: 80px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(33,109,252,1);
	}
	#TTMOBEllipse_3 {
		fill: rgba(33,109,252,1);
	}
	.TTMOBEllipse_3 {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 2px;
		top: 62px;
	}
	#TTMOBEllipse_4 {
		fill: rgba(214,217,224,1);
	}
	.TTMOBEllipse_4 {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 37px;
		top: 62px;
	}
	#TTMOBEllipse_5 {
		fill: rgba(214,217,224,1);
	}
	.TTMOBEllipse_5 {
		position: absolute;
		overflow: visible;
		width: 10px;
		height: 11px;
		left: 73px;
		top: 62px;
	}
	#TTMOBPath_6 {
		fill: rgba(33,109,252,1);
	}
	.TTMOBPath_6 {
		overflow: visible;
		position: absolute;
		width: 8.108px;
		height: 12.012px;
		transform: translate(-107px, -565px) matrix(1,0,0,1,452.3337,598.7474) rotate(180deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#TTMOBLine_1 {
		fill: transparent;
		stroke: rgba(190,190,190,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTMOBLine_1 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 15.427px;
		left: 235.231px;
		top: 31.748px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBBrands {
		position: absolute;
		width: 375px;
		height: 122px;
		left: 0px;
		top: 873px;
		overflow: visible;
	}
	#TTMOBRectangle_79 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_79 {
		filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.039));
		position: absolute;
		overflow: visible;
		width: 525px;
		height: 272px;
		left: 0px;
		top: 0px;
	}
	#TTMOBLogitech-logo-300x300 {
		opacity: 0.61;
		position: absolute;
		width: 81px;
		height: 81px;
		left: 37px;
		top: 36px;
		overflow: visible;
	}
	#TTMOBOur_Partners {
		left: 139px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 98px;
		white-space: nowrap;
		text-align: left;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,28,65,1);
	}
	#TTMOBRectangle_46 {
		fill: rgba(251,252,253,1);
	}
	.TTMOBRectangle_46 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 2352px;
		left: 0px;
		top: 1075px;
	}
	#TTMOBGroup_15175 {
		position: absolute;
		width: 292px;
		height: 65px;
		left: 42px;
		top: 1103px;
		overflow: visible;
	}
	#TTMOBOUR_SERVICES {
		left: 87px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 119px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -31.5px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(61,55,241,1);
	}
	#TTMOBWe_Provide_Best_Quality_Gadget {
		left: 0px;
		top: 41px;
		position: absolute;
		overflow: visible;
		width: 293px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -30px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(90,84,84,1);
	}
	#TTMOBGroup_15176 {
		position: absolute;
		width: 330px;
		height: 403px;
		left: 23px;
		top: 1204px;
		overflow: visible;
	}
	#TTMOBRectangle_47_ci {
		fill: url(#TTMOBRectangle_47_ci);
	}
	.TTMOBRectangle_47_ci {
		filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 450px;
		height: 523px;
		left: 0px;
		top: 0px;
	}
	#TTMOBOur_Services {
		left: 104.125px;
		top: 218.706px;
		position: absolute;
		overflow: visible;
		width: 107px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -14px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(255,255,255,1);
	}
	#TTMOBFast_Powerful__Most_Secure_Net {
		left: 18.751px;
		top: 254.52px;
		position: absolute;
		overflow: visible;
		width: 293.875px;
		height: 55.3604621887207px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#TTMOBRectangle_50 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_50 {
		filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 100px;
		left: 106px;
		top: 320px;
	}
	#TTMOBRead_More {
		left: 133px;
		top: 330px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		line-height: 69px;
		margin-top: -28px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(75,100,126,1);
	}
	#TTMOBIcon1 {
		position: absolute;
		width: 89.9px;
		height: 90.109px;
		left: 120.543px;
		top: 75.004px;
		overflow: visible;
	}
	#TTMOBPath_1202 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBPath_1202 {
		overflow: visible;
		position: absolute;
		width: 74.035px;
		height: 76.624px;
		left: 0.001px;
		top: 13.486px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1203 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBPath_1203 {
		overflow: visible;
		position: absolute;
		width: 29.47px;
		height: 29.517px;
		left: 60.43px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBRectangle_92 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_92 {
		position: absolute;
		overflow: visible;
		width: 17.682px;
		height: 2.947px;
		left: 66.324px;
		top: 5.894px;
	}
	#TTMOBRectangle_93 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_93 {
		position: absolute;
		overflow: visible;
		width: 11.788px;
		height: 2.947px;
		left: 66.324px;
		top: 11.789px;
	}
	#TTMOBRectangle_94 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_94 {
		position: absolute;
		overflow: visible;
		width: 2.947px;
		height: 2.947px;
		left: 81.059px;
		top: 11.789px;
	}
	#TTMOBPath_1204 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBPath_1204 {
		overflow: visible;
		position: absolute;
		width: 57.693px;
		height: 37.386px;
		left: 0px;
		top: 4.421px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1205 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBPath_1205 {
		overflow: visible;
		position: absolute;
		width: 15.197px;
		height: 44.21px;
		left: 73.988px;
		top: 26.72px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBGroup_15177 {
		position: absolute;
		width: 330px;
		height: 402px;
		left: 23px;
		top: 1637px;
		overflow: visible;
	}
	#TTMOBRectangle_51 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_51 {
		filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 450px;
		height: 522px;
		left: 0px;
		top: 0px;
	}
	#TTMOBSecurity_Solutions {
		left: 80.817px;
		top: 218.456px;
		position: absolute;
		overflow: visible;
		width: 151px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -14px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(90,84,84,1);
	}
	#TTMOBFast_Powerful__Most_Secure_Net_cy {
		left: 18.73px;
		top: 254.229px;
		position: absolute;
		overflow: visible;
		width: 293.541px;
		height: 55.297298431396484px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(116,116,116,1);
	}
	#TTMOBRectangle_52 {
		fill: transparent;
		stroke: rgba(236,227,227,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTMOBRectangle_52 {
		position: absolute;
		overflow: visible;
		width: 117px;
		height: 40px;
		left: 106px;
		top: 319px;
	}
	#TTMOBRead_More_c {
		left: 133px;
		top: 330px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		line-height: 69px;
		margin-top: -28px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(75,100,126,1);
	}
	#TTMOBIcon2 {
		position: absolute;
		width: 89.189px;
		height: 89.189px;
		left: 120.405px;
		top: 75.811px;
		overflow: visible;
	}
	#TTMOBPath_1206 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1206 {
		overflow: visible;
		position: absolute;
		width: 56.299px;
		height: 82.273px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1207 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1207 {
		overflow: visible;
		position: absolute;
		width: 56.332px;
		height: 82.273px;
		left: 32.857px;
		top: 6.916px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1208 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1208 {
		overflow: visible;
		position: absolute;
		width: 3.504px;
		height: 3.49px;
		left: 26.882px;
		top: 82.632px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1209 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1209 {
		overflow: visible;
		position: absolute;
		width: 3.513px;
		height: 3.496px;
		left: 58.864px;
		top: 3.091px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1210 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1210 {
		overflow: visible;
		position: absolute;
		width: 70.376px;
		height: 44.813px;
		left: 9.407px;
		top: 16.679px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1211 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1211 {
		overflow: visible;
		position: absolute;
		width: 29.265px;
		height: 38.247px;
		left: 29.962px;
		top: 32.477px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1212 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1212 {
		overflow: visible;
		position: absolute;
		width: 10.021px;
		height: 13.934px;
		left: 39.565px;
		top: 50.693px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBGroup_15178 {
		position: absolute;
		width: 330px;
		height: 402px;
		left: 23px;
		top: 2069px;
		overflow: visible;
	}
	#TTMOBRectangle_53 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_53 {
		filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 450px;
		height: 522px;
		left: 0px;
		top: 0px;
	}
	#TTMOBSound_Systems {
		left: 93.303px;
		top: 218.456px;
		position: absolute;
		overflow: visible;
		width: 127px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -14px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(90,84,84,1);
	}
	#TTMOBFast_Powerful__Most_Secure_Net_dc {
		left: 18.73px;
		top: 254.229px;
		position: absolute;
		overflow: visible;
		width: 293.541px;
		height: 55.297298431396484px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(116,116,116,1);
	}
	#TTMOBRectangle_54 {
		fill: transparent;
		stroke: rgba(236,227,227,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTMOBRectangle_54 {
		position: absolute;
		overflow: visible;
		width: 117px;
		height: 40px;
		left: 106px;
		top: 319px;
	}
	#TTMOBRead_More_de {
		left: 133px;
		top: 330px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		line-height: 69px;
		margin-top: -28px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(75,100,126,1);
	}
	#TTMOBIcon3 {
		position: absolute;
		width: 89.88px;
		height: 88.297px;
		left: 120.405px;
		top: 75.811px;
		overflow: visible;
	}
	#TTMOBPath_1213 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1213 {
		overflow: visible;
		position: absolute;
		width: 38.796px;
		height: 10.123px;
		left: 25.542px;
		top: 38.94px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1214 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1214 {
		overflow: visible;
		position: absolute;
		width: 27.2px;
		height: 7.721px;
		left: 31.34px;
		top: 47.14px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBGroup_296 {
		position: absolute;
		width: 89.88px;
		height: 88.297px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBGroup_295 {
		position: absolute;
		width: 89.88px;
		height: 88.297px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBGroup_15171 {
		position: absolute;
		width: 330px;
		height: 402px;
		left: 23px;
		top: 2501px;
		overflow: visible;
	}
	#TTMOBRectangle_57 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_57 {
		filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 450px;
		height: 522px;
		left: 0px;
		top: 0px;
	}
	#TTMOBPeripherals {
		left: 110.249px;
		top: 218.456px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -14px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(90,84,84,1);
	}
	#TTMOBFast_Powerful__Most_Secure_Net_dn {
		left: 18.73px;
		top: 254.229px;
		position: absolute;
		overflow: visible;
		width: 293.541px;
		height: 55.297298431396484px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(116,116,116,1);
	}
	#TTMOBRectangle_58 {
		fill: transparent;
		stroke: rgba(236,227,227,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTMOBRectangle_58 {
		position: absolute;
		overflow: visible;
		width: 117px;
		height: 40px;
		left: 106px;
		top: 319px;
	}
	#TTMOBRead_More_dp {
		left: 133px;
		top: 330px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		line-height: 69px;
		margin-top: -28px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(75,100,126,1);
	}
	#TTMOBIcon4 {
		position: absolute;
		width: 89.718px;
		height: 89.72px;
		left: 120.406px;
		top: 77.595px;
		overflow: visible;
	}
	#TTMOBPath_1217 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1217 {
		overflow: visible;
		position: absolute;
		width: 3.505px;
		height: 3.505px;
		left: 17.998px;
		top: 44.745px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1218 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1218 {
		overflow: visible;
		position: absolute;
		width: 5.022px;
		height: 3.505px;
		left: 26.367px;
		top: 44.745px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1219 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1219 {
		overflow: visible;
		position: absolute;
		width: 3.505px;
		height: 3.505px;
		left: 34.737px;
		top: 44.745px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1220 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1220 {
		overflow: visible;
		position: absolute;
		width: 3.505px;
		height: 3.505px;
		left: 43.107px;
		top: 44.745px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1221 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1221 {
		overflow: visible;
		position: absolute;
		width: 3.505px;
		height: 3.505px;
		left: 51.476px;
		top: 44.745px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1222 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1222 {
		overflow: visible;
		position: absolute;
		width: 3.505px;
		height: 3.505px;
		left: 59.845px;
		top: 44.745px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1223 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1223 {
		overflow: visible;
		position: absolute;
		width: 3.505px;
		height: 3.505px;
		left: 68.215px;
		top: 44.745px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1224 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1224 {
		overflow: visible;
		position: absolute;
		width: 4.889px;
		height: 3.505px;
		left: 9.462px;
		top: 53.651px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1225 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1225 {
		overflow: visible;
		position: absolute;
		width: 4.888px;
		height: 3.505px;
		left: 19.225px;
		top: 53.651px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1226 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1226 {
		overflow: visible;
		position: absolute;
		width: 3.503px;
		height: 3.505px;
		left: 27.619px;
		top: 53.651px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1227 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1227 {
		overflow: visible;
		position: absolute;
		width: 3.504px;
		height: 3.505px;
		left: 36.097px;
		top: 53.651px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1228 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1228 {
		overflow: visible;
		position: absolute;
		width: 3.505px;
		height: 3.504px;
		left: 19.458px;
		top: 62.091px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1229 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1229 {
		overflow: visible;
		position: absolute;
		width: 3.503px;
		height: 3.504px;
		left: 27.937px;
		top: 62.091px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1230 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1230 {
		overflow: visible;
		position: absolute;
		width: 7.011px;
		height: 3.505px;
		left: 46.613px;
		top: 53.651px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1231 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1231 {
		overflow: visible;
		position: absolute;
		width: 13.026px;
		height: 3.504px;
		left: 37.441px;
		top: 62.091px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1232 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1232 {
		overflow: visible;
		position: absolute;
		width: 4.91px;
		height: 3.505px;
		left: 75.345px;
		top: 53.651px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1233 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1233 {
		overflow: visible;
		position: absolute;
		width: 3.505px;
		height: 3.505px;
		left: 33.819px;
		top: 9.463px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1234 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1234 {
		overflow: visible;
		position: absolute;
		width: 89.718px;
		height: 80.256px;
		left: 0px;
		top: 9.463px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1235 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1235 {
		overflow: visible;
		position: absolute;
		width: 30.84px;
		height: 31.542px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1236 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1236 {
		overflow: visible;
		position: absolute;
		width: 15.169px;
		height: 3.505px;
		left: 7.835px;
		top: 21.025px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1237 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1237 {
		overflow: visible;
		position: absolute;
		width: 15.169px;
		height: 3.504px;
		left: 7.835px;
		top: 14.019px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1238 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1238 {
		overflow: visible;
		position: absolute;
		width: 15.169px;
		height: 3.505px;
		left: 7.835px;
		top: 7.01px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBGroup_15170 {
		position: absolute;
		width: 330px;
		height: 402px;
		left: 23px;
		top: 2982px;
		overflow: visible;
	}
	#TTMOBRectangle_55 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBRectangle_55 {
		filter: drop-shadow(0px 40px 40px rgba(197, 195, 249, 0.149));
		position: absolute;
		overflow: visible;
		width: 450px;
		height: 522px;
		left: 0px;
		top: 0px;
	}
	#TTMOBPrinters_Projectors__Scanners {
		left: 25.52px;
		top: 218.456px;
		position: absolute;
		overflow: visible;
		width: 259px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -14px;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(90,84,84,1);
	}
	#TTMOBFast_Powerful__Most_Secure_Net_eg {
		left: 18.73px;
		top: 254.229px;
		position: absolute;
		overflow: visible;
		width: 293.541px;
		height: 55.297298431396484px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(116,116,116,1);
	}
	#TTMOBRectangle_60 {
		fill: transparent;
		stroke: rgba(236,227,227,1);
		stroke-width: 1px;
		stroke-linejoin: round;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.TTMOBRectangle_60 {
		position: absolute;
		overflow: visible;
		width: 117px;
		height: 40px;
		left: 106px;
		top: 319px;
	}
	#TTMOBRead_More_ei {
		left: 133px;
		top: 330px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		line-height: 69px;
		margin-top: -28px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(75,100,126,1);
	}
	#TTMOBIcon6 {
		position: absolute;
		width: 89.535px;
		height: 89.535px;
		left: 120.405px;
		top: 77.595px;
		overflow: visible;
	}
	#TTMOBPath_1247 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1247 {
		overflow: visible;
		position: absolute;
		width: 89.534px;
		height: 89.534px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBPath_1248 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBPath_1248 {
		overflow: visible;
		position: absolute;
		width: 13.43px;
		height: 13.43px;
		left: 53.721px;
		top: 49.244px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBRectangle_95 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_95 {
		position: absolute;
		overflow: visible;
		width: 13.43px;
		height: 4.477px;
		left: 8.954px;
		top: 49.244px;
	}
	#TTMOBRectangle_96 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_96 {
		position: absolute;
		overflow: visible;
		width: 13.43px;
		height: 4.477px;
		left: 8.954px;
		top: 58.197px;
	}
	#TTMOBRectangle_97 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_97 {
		position: absolute;
		overflow: visible;
		width: 4.477px;
		height: 4.477px;
		left: 26.86px;
		top: 58.197px;
	}
	#TTMOBRectangle_98 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_98 {
		position: absolute;
		overflow: visible;
		width: 4.477px;
		height: 4.477px;
		left: 26.86px;
		top: 49.244px;
	}
	#TTMOBRectangle_99 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_99 {
		position: absolute;
		overflow: visible;
		width: 13.43px;
		height: 4.477px;
		left: 8.954px;
		top: 40.29px;
	}
	#TTMOBRectangle_100 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_100 {
		position: absolute;
		overflow: visible;
		width: 4.477px;
		height: 4.477px;
		left: 26.86px;
		top: 40.29px;
	}
	#TTMOBRectangle_101 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_101 {
		position: absolute;
		overflow: visible;
		width: 13.43px;
		height: 4.477px;
		left: 49.244px;
		top: 22.383px;
	}
	#TTMOBRectangle_102 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_102 {
		position: absolute;
		overflow: visible;
		width: 4.477px;
		height: 4.477px;
		left: 67.151px;
		top: 22.383px;
	}
	#TTMOBTitle {
		position: absolute;
		width: 228px;
		height: 75px;
		left: 74px;
		top: 3477px;
		overflow: visible;
	}
	#TTMOBTop_Picks {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 106px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -27.5px;
		text-align: left;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(90,84,84,1);
	}
	#TTMOBWe_Provide_Best_Quality {
		left: 0px;
		top: 46px;
		position: absolute;
		overflow: visible;
		width: 229px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -29px;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: lighter;
		font-size: 22px;
		color: rgba(116,116,116,1);
	}
	#TTMOBMenu {
		position: absolute;
		width: 198px;
		height: 37px;
		left: 74px;
		top: 3582px;
		overflow: visible;
	}
	#TTMOBAll_Parts {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 85px;
		white-space: nowrap;
		line-height: 80px;
		margin-top: -29px;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(61,55,241,1);
	}
	#TTMOBRectangle_115 {
		fill: rgba(61,55,241,1);
	}
	.TTMOBRectangle_115 {
		position: absolute;
		overflow: visible;
		width: 198px;
		height: 2px;
		left: 0px;
		top: 35px;
	}
	#TTMOBScroll_Group_96 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 408.793px;
		left: 0px;
		top: 3619px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#TTMOBGroup_15179 {
		position: absolute;
		width: 317.511px;
		height: 351.239px;
		left: 31px;
		top: 25px;
		overflow: visible;
	}
	#TTMOBn_ {
		position: absolute;
		width: 152.57px;
		height: 169.392px;
		left: 164.941px;
		top: 181.848px;
		overflow: visible;
	}
	#TTMOBPath_1297 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBPath_1297 {
		overflow: visible;
		position: absolute;
		width: 152.571px;
		height: 169.392px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBProduct_4 {
		left: 48.285px;
		top: 96.89px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(90,84,84,1);
	}
	#TTMOBRead_more {
		position: absolute;
		width: 54px;
		height: 18px;
		left: 47.059px;
		top: 137.153px;
		overflow: visible;
	}
	#TTMOBRectangle_117 {
		fill: rgba(241,241,255,1);
	}
	.TTMOBRectangle_117 {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 18px;
		left: 0px;
		top: 0px;
	}
	#TTMOBRead_More_e {
		left: 13px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 29px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -13px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 6px;
		color: rgba(75,100,126,1);
	}
	#TTMOBbrand-identity {
		position: absolute;
		width: 63px;
		height: 54px;
		left: 45.059px;
		top: 20.152px;
		overflow: visible;
	}
	#TTMOBn__e {
		position: absolute;
		width: 152.57px;
		height: 169.392px;
		left: 0px;
		top: 181.848px;
		overflow: visible;
	}
	#TTMOBPath_1191 {
		fill: rgba(255,255,255,1);
	}
	.TTMOBPath_1191 {
		overflow: visible;
		position: absolute;
		width: 152.571px;
		height: 169.392px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBProduct_3 {
		left: 48.285px;
		top: 96.89px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(90,84,84,1);
	}
	#TTMOBbrand-identity_fc {
		position: absolute;
		width: 76px;
		height: 64px;
		left: 36px;
		top: 15.152px;
		overflow: visible;
	}
	#TTMOBRead_more_fd {
		position: absolute;
		width: 54px;
		height: 18px;
		left: 47px;
		top: 137.153px;
		overflow: visible;
	}
	#TTMOBRectangle_87 {
		fill: rgba(241,241,255,1);
	}
	.TTMOBRectangle_87 {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 18px;
		left: 0px;
		top: 0px;
	}
	#TTMOBRead_More_ff {
		left: 13px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 29px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -13px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 6px;
		color: rgba(75,100,126,1);
	}
	#TTMOBn__fg {
		position: absolute;
		width: 152.57px;
		height: 169.392px;
		left: 164.941px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBPath_1191_fh {
		fill: rgba(255,255,255,1);
	}
	.TTMOBPath_1191_fh {
		overflow: visible;
		position: absolute;
		width: 152.571px;
		height: 169.392px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBProduct_2 {
		left: 48.285px;
		top: 96.891px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(90,84,84,1);
	}
	#TTMOBRead_more_fj {
		position: absolute;
		width: 54px;
		height: 18px;
		left: 49.059px;
		top: 137px;
		overflow: visible;
	}
	#TTMOBRectangle_87_fk {
		fill: rgba(241,241,255,1);
	}
	.TTMOBRectangle_87_fk {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 18px;
		left: 0px;
		top: 0px;
	}
	#TTMOBRead_More_fl {
		left: 13px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 29px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -13px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 6px;
		color: rgba(75,100,126,1);
	}
	#TTMOBbrand-identity_fm {
		position: absolute;
		width: 63px;
		height: 53px;
		left: 45.059px;
		top: 21px;
		overflow: visible;
	}
	#TTMOBn__fn {
		position: absolute;
		width: 152.57px;
		height: 169.392px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TTMOBPath_1191_fo {
		fill: rgba(255,255,255,1);
	}
	.TTMOBPath_1191_fo {
		overflow: visible;
		position: absolute;
		width: 152.571px;
		height: 169.392px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TTMOBProduct_1 {
		left: 48.285px;
		top: 96.891px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: center;
		font-family: Lato;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(90,84,84,1);
	}
	#TTMOBRead_more_fq {
		position: absolute;
		width: 54px;
		height: 18px;
		left: 47px;
		top: 137px;
		overflow: visible;
	}
	#TTMOBRectangle_87_fr {
		fill: rgba(241,241,255,1);
	}
	.TTMOBRectangle_87_fr {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 18px;
		left: 0px;
		top: 0px;
	}
	#TTMOBRead_More_fs {
		left: 13px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 29px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -13px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 6px;
		color: rgba(75,100,126,1);
	}
	#TTMOBbrand-identity_ft {
		position: absolute;
		width: 72px;
		height: 61px;
		left: 40px;
		top: 21px;
		overflow: visible;
	}
