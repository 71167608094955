.mediaViewInfo {
    --web-view-name: profile page;
    --web-view-id: profile_page;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: profile_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
.main-page {
    position: fixed;
    display: table;
    left: 120px;
    width: calc(100vw - 120px);
    height: calc(100vh - 75px);
}

.main-content {
   position: relative;
   display: table-cell;
   width: calc(100% - 480px);
}
.container-row{
    display: table-row;
}
.container-cell
{
display: table-cell;
width: 100%;
padding-right: 10px;
}
.inner-table{
    position: relative;
display: table;
overflow-y: scroll;
width: 100%;

}

.right-content {
   
    position: relative;
    display: table-cell;
    width: 350px;
   vertical-align: top;
   
 }