#Border_box_requests {
    position: relative;
    margin: 5px;
    width: 359px;
    height: 203px;
    left: 70px;
    top: 103px;
    overflow: visible;
}
#border_req_outer {
    fill: rgba(255,255,255,1);
}
.border_req_outer {
    position: absolute;
    overflow: visible;
    width: 359px;
    height: 203px;
    left: 0px;
    top: 0px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
#porfile_img_req_ {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 14.5px;
    top: 23.5px;
    border-radius: 50%;
    overflow: hidden;
}
#border_name_lbl_req {
    fill: rgba(219,242,231,1);
}
.border_name_lbl_req {
    position: absolute;
    overflow: visible;
    width: 220px;
    height: 30px;
    left: 116px;
    top: 20px;
}
#Mohammed_Zaid_lbl_req {
    left: 134.237px;
    top: 23.5px;
    position: absolute;
    overflow: visible;
    width: 137px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#Profession_lbl_req {
    left: 114px;
    top: 68.5px;
    position: absolute;
    overflow: visible;
    width: 77px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Connected_On_lbl_req {
    left: 87px;
    top: 88.5px;
    position: absolute;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Software_Developer_lbl_req {
    left: 205px;
    top: 68.5px;
    position: absolute;
    overflow: visible;
    width: 131px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#date_req_lbl {
    left: 205px;
    top: 88.5px;
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#border_cancel_req:hover {
    left: 35px;
    top: 172px;
}
#border_cancel_req {
    position: absolute;
    width: 70px;
    height: 26px;
    left: 29px;
    top: 168.5px;
    overflow: visible;
    border-radius: 15px;
    transition: all .3s ease-out;
}
#border_req_cancel {
    fill: rgba(219,242,231,1);
}
.border_req_cancel {
    position: absolute;
    overflow: visible;
    width: 70px;
    height: 26px;
    left: 0px;
    top: 0px;
}

#Cancel_lbl_req {
    left: 8px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 45px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#group_block_req:hover {
    left: 150px;
    top: 173px;
}
#group_block_req {
    position: absolute;
    width: 70px;
    height: 26px;
    left: 145px;
    top: 169.5px;
    overflow: visible;
    border-radius: 15px;
    transition: all .3s ease-out;
}
#border_block_req {
    fill: rgba(219,242,231,1);
}
.border_block_req {
    position: absolute;
    overflow: visible;
    width: 70px;
    height: 26px;
    left: 0px;
    top: 0px;
}
#Block_req_lbl {
    left: 17px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 36px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Group_ignore_req:hover {
    left: 261px;
    top: 173px;
}
#Group_ignore_req {
    position: absolute;
    width: 70px;
    height: 26px;
    left: 256px;
    top: 169.5px;
    overflow: visible;
    border-radius: 15px;
    transition: all .3s ease-out;
}
#border_ignore_req {
    fill: rgba(219,242,231,1);
}
.border_ignore_req {
    position: absolute;
    overflow: visible;
    width: 70px;
    height: 26px;
    left: 0px;
    top: 0px;
}
#Ignore_lbl_req {
    left: 13px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Scroll_intro_req {
    mix-blend-mode: normal;
    position: absolute;
    width: 248px;
    height: 49px;
    left: 87px;
    top: 109px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#intro_value_req {
    left: 4px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 213px;
    height: 180px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}

@keyframes slideInRight {
    from {
      transform: translate3d(100%, 0, 0);
      visibility: visible;
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInRight {
    animation: slideInRight 1s;
  }