	.mediaViewInfo {
		--web-view-name: people con;
		--web-view-id: people_con;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: people_con;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#people_con {
		position: absolute;
		width: 100vw;
		height: calc(100vh - 140px);
        top: 75px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: people con;
		--web-view-id: people_con;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#heading_grp_conreqsug_mob_con {
		position: absolute;
		width: 273px;
		height: 28px;
		left: 51px;
		top: 10px;
		overflow: visible;
	}
	#req_conreqsug_mob_con {
		opacity: 0.92;
		left: 108px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 60px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#con_grp_conreqsug_mob_con {
		position: absolute;
		width: 82px;
		height: 20px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#con_conreqsug_mob_con {
		opacity: 0.92;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 83px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,93,24,1);
	}
	#sugg_conreqsug_mob_con {
		opacity: 0.92;
		left: 192px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -7px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(48,47,47,1);
	}
	#underline_conreqsug_mob_con {
		fill: rgba(255,79,4,1);
	}
	.underline_conreqsug_mob_con {
		position: absolute;
		overflow: visible;
		width: 82px;
		height: 3px;
		left: 0px;
		top: 25px;
	}
