.mediaViewInfo {
    --web-view-name: requirements;
    --web-view-id: requirements;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: requirements;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#My_Requirements_pc_r {
    position: relative;
    overflow: visible;
    width: 391px;
    height: 231px;
    left: 10px;
    top: 10px; 
}
#card_border_requirementPC {
    fill: rgba(255,255,255,1);
}
.card_border_requirementPC {
    position: absolute;
    overflow: visible;
    width: 391px;
    height: 231px;
    left: 27px;
    top: 77px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
#name_border_requirementPC {
    fill: rgba(219,242,231,1);
}
.name_border_requirementPC {
    position: absolute;
    overflow: visible;
    width: 280px;
    height: 30px;
    left: 120px;
    top: 111px;
}
#Website_Development_lbl_reqPC {
    left: 128px;
    top: 115px;
    position: absolute;
    overflow: visible;
    width: 178px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Budget_req_pc {
    left: 40px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Duration_lbl_reqPc {
    left: 40px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 126px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Location_lbl_reqPc {
    left: 40px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 124px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#budget_amount_req {
    left: 244px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#duration_req_pc {
    left: 244px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Online_Deployment_value_req_pc {
    left: 244px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 130px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Group_10_btn_reqpc:hover {
    left: 303px;
    top: 262px;
}
#Group_10_btn_reqpc {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 300px;
    top: 259px;
    z-index: 1;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_btn_reqpc {
    fill: rgba(219,242,231,1);
}
.border_btn_reqpc {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#View_more_pc_req_lbl {
    left: 5px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}


#Group_8_btn_reqpc:hover {
    left: 41px;
    top: 262px;
}
#Group_8_btn_reqpc {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 38px;
    top: 259px;
    z-index: 1;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_btn_reqpc {
    fill: rgba(219,242,231,1);
}
.border_btn_reqpc {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#Bids_lbl_req_pc {
    left: 30px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 32px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#coding_img_reqprofile {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 40px;
    top: 91px;
    overflow: visible;
}

#Group_41_btn_reqpc:hover {
    left: 148px;
    top: 262px;
}
#Group_41_btn_reqpc {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 145px;
    top: 259px;
    z-index: 1;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_btn_reqpc {
    fill: rgba(219,242,231,1);
}
.border_btn_reqpc {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#Disable_lbl_req_pc {
    left: 18px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 56px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_44_border_addReq {
    position: absolute;
    width: 167px;
    height: 30px;
    right: 10px;
    top: 28px;
    overflow: visible;
    cursor: pointer;
}
#Add_Requirements_lbl_btn_req {
    left: 36px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 132px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(37,37,37,1);
}
#add_req_lbl_btn_ {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

.BidsModalDesign{
    background-color:white;
    margin: auto;
    position: relative;
    min-width: 100%;
    max-width: auto;
    height: auto;
}

.titleBids{
    font-family: 'Times New Roman', Times, serif;
  text-align: center;
   color:black;
   background-color:#DBF2E7;
    }

    @keyframes fadeInLeft {
        from {
          opacity: 0;
          transform: translate3d(-100%, 0, 0);
        }
      
        to {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
      
      .fadeInLeft {
        animation-name: fadeInLeft;
      }