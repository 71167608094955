.mediaViewInfo {
    --web-view-name: Community requirements ;
    --web-view-id: Community_requirements_;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: Community_requirements_;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
 /* #Community_requirments_Pc_co {
    position: relative;
    width: 1254px;
    height: 637px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: Community requirements ;
    --web-view-id: Community_requirements_;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
 }  */

 #Community_requirments_Pc_co {
    position: relative;
    overflow: visible;
    width: 391px;
    height: 225px;
    left: 10px;
    top: 10px;
} 

#card_border_comreq {
    fill: rgba(255,255,255,1);
}
.card_border_comreq {
    position: absolute;
    overflow: visible;
    width: 391px;
    height: 225px;
    left: 27px;
    top: 77px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
#name_border_comreq {
    fill: rgba(219,242,231,1);
}
.name_border_comreq {
    position: absolute;
    overflow: visible;
    width: 280px;
    height: 30px;
    left: 120px;
    top: 111px;
}
#Website_Development_lbl_comreq {
    left: 128px;
    top: 115px;
    position: absolute;
    overflow: visible;
    width: 178px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Budget_lbl_comreq {
    left: 40px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Duration_lbl_comreq {
    left: 40px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 126px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Delivery_Location_lbl_comreq {
    left: 40px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 124px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#budget_com {
    left: 244px;
    top: 178px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#delivery_deadline_comreq_pc {
    left: 244px;
    top: 202px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#delivery_location_comreq_pc {
    left: 244px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 130px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#Group_bids_btn_comreq:hover {
    left: 192px;
    top: 262px;
}
#Group_bids_btn_comreq {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 189px;
    top: 259px;
    z-index: 1;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_btn_comreq {
    fill: rgba(219,242,231,1);
}
.border_btn_comreq {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#Bids_lbl_btn_comreq {
    left: 30px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 32px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#coding_lbl_com_req {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 40px;
    top: 91px;
    overflow: visible;
}
#Group_view_btn_req:hover {
    left: 303px;
    top: 262px;
}
#Group_view_btn_req {
    position: absolute;
    width: 90px;
    height: 29px;
    left: 300px;
    top: 259px;
    z-index: 1;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_comreq_btn_ {
    fill: rgba(219,242,231,1);
}
.border_comreq_btn_ {
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 29px;
    left: 0px;
    top: 0px;
}
#View_more_btn_comreq {
    left: 5px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
@keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInLeft {
    animation-name: fadeInLeft;
  }