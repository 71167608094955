#down_grp_com_pc_event {
    position: absolute;
    width: calc(100vw - 220px);
    height: 110vh;
    left: 41px;
    top: 140px;
    overflow: visible;
}
#bg_com_event_page_pc_for_cards_ {
    fill: url(#bg_com_event_page_pc_for_cards_);
}
.bg_com_event_page_pc_for_cards_ {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}
#recent_event_card_1_com_pc_eve { 
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 10px;
    margin-top: 010px;
}
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 20px); }
    100%   { transform: translate(0, -0px); }   
}
#recent_com_pc_event {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 22px;
    top: 21px;
    overflow: visible;
}
#recent_events_line_com_events_pc {
    fill: transparent;
    stroke: rgba(64,225,150,1);
    stroke-width: 5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.recent_events_line_com_events_pc {
    overflow: visible;
    position: absolute;
    width: 5px;
    height: 26px;
    left: 18.24px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#recent_events_new_events_page_ {
    opacity: 0.92;
    left: 28.24px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#recent_event_card_1_com_pc_eve {
    position: relative;
    width: 326px;
    height: 349px;
    left: 0px;
    /* top: 40px; */
    /* display: flex; */
    /* flex-wrap: wrap; */
    overflow: visible;
}
#all_card_borer_v_com_pc_event {
    fill: rgba(255,255,255,1);
}
.all_card_borer_v_com_pc_event {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 344px;
    height: 367px;
    left: 0px;
    top: 0px;
}
#By_by_vent_com_pc_event {
    left: 20px;
    top: 203.39px;
    position: absolute;
    overflow: visible;
    width: 15px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(208,208,208,1);
}
#Son_Goku_lbl_com_pc_event {
    left: 71.801px;
    top: 203.39px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#Comic_Con_Bangalore_-_2023_vco {
    left: 20px;
    top: 228.222px;
    position: absolute;
    overflow: visible;
    width: 241px;
    white-space: nowrap;
    line-height: 24px;
    margin-top: -3px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(0,0,0,1);
}
#img_logo_com_pc_event {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 36px;
    top: 195px;
    border-radius: 50%;
    overflow: hidden;
}
#idk_ventcom_pc_event {
    position: absolute;
    width: 284.616px;
    height: 159px;
    left: 21.623px;
    top: 19.222px;
    overflow: visible;
}
#img_bdrcom_pc_event {
    opacity: 0.29;
    fill: rgba(0,0,0,1);
}
.img_bdrcom_pc_event {
    position: absolute;
    overflow: visible;
    width: 286.239px;
    height: 159px;
    left: 20px;
    top: 19.222px;
}
#amount_bdr_com_pc_event {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.amount_bdr_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 222.239px;
    top: 28px;
}
#n_1139_vent_com_pc_event {
    left: 235.739px;
    top: 34px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -4.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(255,255,255,1);
}
#bdr_trophie_v_com_pc_event {
    opacity: 0.4;
    fill: rgba(255,255,255,1);
}
.bdr_trophie_v_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 31px;
    left: 25.5px;
    top: 28px;
}
#from_to_grp_com_pc_event {
    position: absolute;
    width: 264.74px;
    height: 55px;
    left: 20px;
    top: 273.029px;
    overflow: visible;
}
#Fromcom_pc_event {
    left: 34.5px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#n_3th_Jan_2023_vent_com_pc_eve {
    left: 34.5px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(51,51,51,1);
}
#cal_64_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_64_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 16.5px;
}
#cal_8h_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_8h_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 19.5px;
}
#cal_87_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_87_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 12.3px;
    top: 22.5px;
}
#cal_57_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_57_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 16.5px;
}
#cal_r54_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_r54_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 19.5px;
}
#cal_ourter_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_ourter_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 9.3px;
    top: 22.5px;
}
#cal_6_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_6_com_pc_event {
    overflow: visible;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0px;
    top: 10.5px;
    transform: matrix(1,0,0,1,0,0);
}
#cal_6_com_pc_event_bt {
    fill: rgba(173,173,173,1);
}
.cal_6_com_pc_event_bt {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 16.5px;
}
#cal_5_ecom_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_5_ecom_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 19.5px;
}
#cal_4com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_4com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 3.3px;
    top: 22.5px;
}
#cal_3_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_3_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 16.5px;
}
#cal_2_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_2_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 19.5px;
}
#cal_1_com_pc_event {
    fill: rgba(173,173,173,1);
}
.cal_1_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 1.5px;
    height: 1.5px;
    left: 6.3px;
    top: 22.5px;
}
#To_lbl_com_pc_event {
    left: 172.981px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 12px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(51,51,51,1);
}
#date_tocom_pc_event {
    left: 172.739px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(51,51,51,1);
}
#signal_com_pc_event {
    fill: rgba(179,212,49,1);
}
.signal_com_pc_event {
    position: absolute;
    overflow: visible;
    width: 6.193px;
    height: 6.193px;
    left: 215.487px;
    top: 152px;
}
#Online_Event_lbl_com_pc_event {
    left: 227.239px;
    top: 148px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    line-height: 19px;
    margin-top: -4px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(255,255,255,1);
}
#trophy_img_com_pc_event {
    position: absolute;
    width: auto;
    height: 24.942px;
    left: 28.933px;
    top: 31px;
    color: white;
    overflow: visible;
}
#n_234_com_com_pc_event {
    left: 61.856px;
    top: 35px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    line-height: 28px;
    margin-top: -7px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255,255,255,1);
}
#share_bdrevent_new_pc_com_pc_e {
    fill: rgba(255,255,255,1);
}
.share_bdrevent_new_pc_com_pc_e {
    position: absolute;
    overflow: visible;
    width: 37.911px;
    height: 38px;
    left: 268.374px;
    top: 185px;
}
#share_com_pc_event {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 275.239px;
    top: 192px;
    overflow: visible;
}