	.mediaViewInfo {
		--web-view-name: FurniGem mob;
		--web-view-id: FurniGem_mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: FurniGem_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#FurniGem_mob {
		position: absolute;
		width: 390px;
		height: 4290px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: FurniGem mob;
		--web-view-id: FurniGem_mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#thm15FG_mobGroup_14967 {
		position: absolute;
		width: 380px;
		height: 1059px;
		left: 10px;
		top: 22px;
		overflow: visible;
	}
	#thm15FG_mobTransform__Your_Space {
		left: 24px;
		top: 99px;
		position: absolute;
		overflow: visible;
		width: 323px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 64px;
		color: rgba(0,0,0,1);
		letter-spacing: 0.2px;
	}
	#thm15FG_mobAnime_Edit {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 48px;
		top: 0px;
        border-radius: 50%;
		overflow: hidden;
	}
	#thm15FG_mobTransform_Your_Space_With_Our_ {
		left: 53px;
		top: 844px;
		position: absolute;
		overflow: visible;
		width: 294px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(12,54,83,1);
	}
	#thm15FG_mobdi_an_h-g_8MrEZAvyE-unsplash {
		position: absolute;
		width: 234px;
		height: 332px;
		left: 82px;
		top: 488px;
		overflow: visible;
	}
	#thm15FG_mobScroll_Group_49 {
		mix-blend-mode: normal;
		position: absolute;
		width: 371px;
		height: 154px;
		left: 9px;
		top: 285px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#thm15FG_mobScroll_Group_49::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_mobIntroduction {
		left: 14px;
		top: 17px;
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 120px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobScroll_Group_54 {
		mix-blend-mode: normal;
		position: absolute;
		width: 373px;
		height: 142px;
		left: 0px;
		top: 917px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#thm15FG_mobScroll_Group_54::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_mobGroup_14963 {
		position: absolute;
		width: 606px;
		height: 120px;
		left: 9px;
		top: 11px;
		overflow: visible;
	}
	#thm15FG_mobguzman-barquin-FkKClUPUURU-uns {
		position: absolute;
		width: 186px;
		height: 120px;
		left: 420px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_mobspacejoy-YqFz7UMm8qE-unsplash {
		position: absolute;
		width: 186px;
		height: 120px;
		left: 210px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_mobmyxa69-nZnVdtlJvbw-unsplash {
		position: absolute;
		width: 186px;
		height: 120px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_mobCompany_Name {
		left: 108px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 251px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(12,54,83,1);
	}
	#thm15FG_mobGroup_14961 {
		position: absolute;
		width: 336px;
		height: 564px;
		left: 37px;
		top: 1243px;
		overflow: visible;
	}
	#thm15FG_mobRectangle_707 {
		fill: rgba(232,232,232,1);
	}
	.thm15FG_mobRectangle_707 {
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 330px;
		left: 0px;
		top: 234px;
	}
	#thm15FG_mobkisspng-furniture-poster-couch {
		position: absolute;
		width: 277px;
		height: 255px;
		left: 26px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_mobScroll_Group_51 {
		mix-blend-mode: normal;
		position: absolute;
		width: 328px;
		height: 245px;
		left: 0px;
		top: 314px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#thm15FG_mobScroll_Group_51::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_mobElevate_Your_Space_with_Timele {
		left: 15px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 302px;
		height: 223px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobVision {
		left: 138px;
		top: 286px;
		position: absolute;
		overflow: visible;
		width: 62px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 21px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobGroup_14964 {
		position: absolute;
		width: 336px;
		height: 564px;
		left: 27px;
		top: 1857px;
		overflow: visible;
	}
	#thm15FG_mobRectangle_707_de {
		fill: rgba(232,232,232,1);
	}
	.thm15FG_mobRectangle_707_de {
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 330px;
		left: 0px;
		top: 234px;
	}
	#thm15FG_mobkisspng-window-living-room-cur {
		position: absolute;
		width: 277px;
		height: 255px;
		left: 26px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_mobScroll_Group_51_dg {
		mix-blend-mode: normal;
		position: absolute;
		width: 328px;
		height: 245px;
		left: 0px;
		top: 314px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#thm15FG_mobScroll_Group_51_dg::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_mobElevate_Your_Space_with_Timele_dh {
		left: 15px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 302px;
		height: 223px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobMission {
		left: 138px;
		top: 286px;
		position: absolute;
		overflow: visible;
		width: 77px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 21px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobGroup_14965 {
		position: absolute;
		width: 336px;
		height: 564px;
		left: 27px;
		top: 2471px;
		overflow: visible;
	}
	#thm15FG_mobRectangle_707_dk {
		fill: rgba(232,232,232,1);
	}
	.thm15FG_mobRectangle_707_dk {
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 330px;
		left: 0px;
		top: 234px;
	}
	#thm15FG_mobsuchit-poojari-ljRiZl00n18-uns {
		position: absolute;
		width: 277px;
		height: 255px;
		left: 26px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_mobScroll_Group_51_dm {
		mix-blend-mode: normal;
		position: absolute;
		width: 328px;
		height: 245px;
		left: 0px;
		top: 314px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#thm15FG_mobScroll_Group_51_dm::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_mobElevate_Your_Space_with_Timele_dn {
		left: 15px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 302px;
		height: 223px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobPrinciples {
		left: 120px;
		top: 286px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 21px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobProduct {
		left: 135px;
		top: 3091px;
		position: absolute;
		overflow: visible;
		width: 122px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobScroll_Group_50 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 386px;
		left: 0px;
		top: 3159px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#thm15FG_mobScroll_Group_50::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_mobGroup_14957 {
		position: relative;
		width: 248px;
		height: 318px;
		left: 24px;
		top: 33px;
        margin-right: 30px;
		overflow: visible;
	}
	#thm15FG_mobRectangle_708 {
		fill: rgba(255,255,255,1);
	}
	.thm15FG_mobRectangle_708 {
		position: absolute;
		overflow: visible;
		width: 248px;
		height: 318px;
		left: 0px;
		top: 0px;
	}
	#thm15FG_mobavery-klein-JaXs8Tk5Iww-unspla {
		position: absolute;
		width: 217px;
		height: 261px;
		left: 16px;
		top: 16px;
		overflow: visible;
	}
	#thm15FG_mobChair {
		left: 16px;
		top: 288px;
		position: absolute;
		overflow: visible;
		width: 218px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_mobRectangle_711 {
		fill: rgba(59,59,59,1);
	}
	.thm15FG_mobRectangle_711 {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 695px;
		left: 0px;
		top: 3645px;
	}
	#thm15FG_mobRectangle_712 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.thm15FG_mobRectangle_712 {
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 209px;
		left: 19px;
		top: 3715px;
	}
	#thm15FG_mobVisit_Us {
		left: 159px;
		top: 3664px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_mobn_234567890_mobile_number_shad {
		left: 54px;
		top: 3976px;
		position: absolute;
		overflow: visible;
		width: 83px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_mobaffanghanioperatelive_email_sh {
		left: 54px;
		top: 4025px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_mobaddress_med_shadow_space_websi {
		left: 54px;
		top: 4074px;
		position: absolute;
		overflow: visible;
		width: 309px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_moblocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 19px;
		top: 4074px;
		overflow: visible;
	}
	#thm15FG_mobemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 19px;
		top: 4024px;
		overflow: visible;
	}
	#thm15FG_mobphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 19px;
		top: 3969px;
		overflow: visible;
	}
	#thm15FG_mobLine_29 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.thm15FG_mobLine_29 {
		overflow: visible;
		position: absolute;
		width: 390px;
		height: 2px;
		left: 0.5px;
		top: 4182.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_mobsocials_grp_shadow_space_websi {
		position: absolute;
		width: 210px;
		height: 50.001px;
		left: 90px;
		top: 4211.999px;
		overflow: visible;
	}
	#thm15FG_moblinked_in_grp_shadow_space_web {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 160px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_mobEllipse_5shadow_space_website_:hover {
		fill: rgba(120,120,255,1);
	}
	.thm15FG_mobEllipse_5shadow_space_website_:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#thm15FG_mobEllipse_5shadow_space_website_ {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.thm15FG_mobEllipse_5shadow_space_website_ {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#thm15FG_moblinked_in_shadow_space_website {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#thm15FG_mobn_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.thm15FG_mobn_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.954px;
		transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_moblinked_in_i_down {
		fill: rgba(63,61,86,1);
	}
	.thm15FG_moblinked_in_i_down {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.097px;
		transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_mobtop_dot_i_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.thm15FG_mobtop_dot_i_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_mobInsta_grp_med_shadow_space_web {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 80px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_mobEllipseshadow_space_website_5_:hover {
		fill: rgba(255,120,120,1);
	}
	.thm15FG_mobEllipseshadow_space_website_5_:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#thm15FG_mobEllipseshadow_space_website_5_ {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.thm15FG_mobEllipseshadow_space_website_5_ {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#thm15FG_mobinstagram_shadow_space_website {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.126px;
		overflow: visible;
	}
	#thm15FG_mobwhatsapp_grpshadow_space_websi {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#thm15FG_mobEllipse_3_shadow_space_website:hover {
		fill: rgba(50,172,158,1);
	}
	.thm15FG_mobEllipse_3_shadow_space_website:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#thm15FG_mobEllipse_3_shadow_space_website {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.thm15FG_mobEllipse_3_shadow_space_website {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#thm15FG_mobwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}