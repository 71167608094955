.mediaViewInfo {
    --web-view-name: onlineuser pop;
    --web-view-id: onlineuser_pop;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: onlineuser_pop;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#onlineuser_pop {
    position: relative;
    width: 214px;
    height: 643px;
    background-color: rgba(255,255,255,1);
    top:60px;
    float: right;
    overflow: hidden;
    --web-view-name: onlineuser pop;
    --web-view-id: onlineuser_pop;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_onlineuser_pop_ {
    position: absolute;
    width: 214px;
    height: 645px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#inner_overall_ {
    position: absolute;
    width: 214px;
    height: 645px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#bg_design_online_user_rec {
    position: absolute;
    width: 214px;
    height: 645px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#username_lbl_and_rec {
    position: absolute;
    width: 170px;
    height: 33px;
    left: 15px;
    top: 12px;
    overflow: visible;
}
#Online_user_bdr_mob {
    fill: rgba(219,242,231,1);
}
.Online_user_bdr_mob {
    position: absolute;
    overflow: visible;
    width: 170px;
    height: 33px;
    left: 0px;
    top: 0px;
}
#online_user_lbl_mob {
    left: 56px;
    top: 8px;
    position: absolute;
    overflow: visible;
    width: 73px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(5,5,5,1);
}
#online_user_scrooll {
    mix-blend-mode: normal;
    position: absolute;
    width: 193px;
    height: 547px;
    left: 11px;
    top: 46px;
    overflow-x: hidden;
    overflow-y: auto;
}
#card1_online_user_ {
    position: relative;
    width: 193px;
    height: 60px;
    left: 0px;
    top: 7px;
    margin-top: 5px;
    overflow: visible;
}
#card_1_bdr_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 0.5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.card_1_bdr_mob {
    position: absolute;
    overflow: visible;
    width: 193px;
    height: 60px;
    left: 0px;
    top: 0px;
}
#user_1_img_tag_mob {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 16px;
    top: 5px;
    overflow: visible;
}
#online_user_lbl_mob_u {
    left: 73px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(5,5,5,1);
}
#call_btn_over_all_mob {
    position: absolute;
    width: 45px;
    height: 22px;
    left: 100px;
    top: 33px;
    overflow: visible;
}
#Online_user_bdr_mob_w {
    fill: rgba(219,242,231,1);
}
.Online_user_bdr_mob_w {
    position: absolute;
    overflow: visible;
    width: 45px;
    height: 22px;
    left: 0px;
    top: 0px;
}
#online_user_lbl_mob_x {
    left: 12px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(5,5,5,1);
}
#arrow_right_open_close {
    position: relative;
    width: 30px;
    height: 30px;
    float: right;
    transform: rotate(90deg);
    overflow: visible;
}
@keyframes slideInRight {
    from {
      transform: translate3d(100%, 0, 0);
      visibility: visible;
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInRight {
    animation: slideInRight 1s;
  }


  @keyframes slideInLeft {
    from {
      transform: translate3d(-100%, 0, 0);
      visibility: visible;
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInLeft {
    animation: slideInLeft 1s;
  }