	.mediaViewInfo {
		--web-view-name: ride revolution;
		--web-view-id: ride_revolution;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ride_revolution;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ride_revolution {
		position: absolute;
		width: 1366px;
		height: 4346px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(249,249,249,0.89);
		overflow: hidden;
		--web-view-name: ride revolution;
		--web-view-id: ride_revolution;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#avaliable_behind_bdr_website_7 {
		fill: rgba(222,14,11,1);
	}
	.avaliable_behind_bdr_website_7 {
		overflow: visible;
		position: absolute;
		width: 685.604px;
		height: 404.5px;
		left: -169.578px;
		top: 3247.357px;
		transform: matrix(1,0,0,1,0,0);
	}
	#yamede_princi_web_8pc_bike {
		position: absolute;
		width: 949.695px;
		height: 578.723px;
		left: 157.305px;
		top: 2367px;
		overflow: visible;
	}
	#princi_design_img_website_7_pc {
		fill: rgba(222,14,11,1);
	}
	.princi_design_img_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 466.46px;
		height: 194.257px;
		transform: translate(0px, 0.062px) matrix(1,0,0,1,-136.1016,248.3028) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#shade_princi_img_website_7_pc_ {
		fill: rgba(0,0,0,1);
	}
	.shade_princi_img_website_7_pc_ {
		filter: drop-shadow(-70px 73px 99px rgba(0, 0, 0, 0.122));
		position: absolute;
		overflow: visible;
		width: 643px;
		height: 757px;
		left: 67.695px;
		top: 0px;
	}
	#princi_Group_website_7_pc_bike {
		position: absolute;
		width: 346px;
		height: 460px;
		left: 67.695px;
		top: 0px;
		overflow: visible;
	}
	#princi_text_grp_website_7_pc_b {
		position: absolute;
		width: 457px;
		height: 308px;
		left: 492.695px;
		top: 112px;
		overflow: visible;
	}
	#Principle_lbl_website_7_pc_bik {
		left: 17px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 152px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#top_princi_bdr_website_7_pc_bi {
		fill: rgba(222,14,11,1);
	}
	.top_princi_bdr_website_7_pc_bi {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 0px;
		left: 16px;
		top: 0px;
	}
	#Scroll_prinic_web_8_pc_bike {
		mix-blend-mode: normal;
		position: absolute;
		width: 457px;
		height: 220px;
		left: 0px;
		top: 88px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#mh_in_40_seconds__bike {
		left: 17px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 120px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(116,116,116,1);
	}
	#onichan_vision_8_pc_web {
		position: absolute;
		width: 1149px;
		height: 727px;
		left: 217px;
		top: 782px;
		overflow: visible;
	}
	#vision_bdr_website_7_pc_bike {
		fill: rgba(222,14,11,1);
	}
	.vision_bdr_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 662px;
		height: 146px;
		left: 8px;
		top: 413px;
	}
	#mask_top_visoon_img_website_7_ {
		position: absolute;
		width: 583px;
		height: 574px;
		left: 566px;
		top: 0px;
		overflow: visible;
	}
	#small_thumbnails_website_7_pc_ {
		position: absolute;
		width: 697px;
		height: 260px;
		left: 236px;
		top: 467px;
		overflow: visible;
	}
	#overall_bdr_thumb_website_7_pc {
		fill: rgba(21,21,21,1);
	}
	.overall_bdr_thumb_website_7_pc {
		position: absolute;
		overflow: visible;
		width: 697px;
		height: 260px;
		left: 0px;
		top: 0px;
	}
	#img_2_website_7_pc_Group_3_bik {
		position: absolute;
		width: 273px;
		height: 220px;
		left: 318px;
		top: 20px;
		overflow: visible;
	}
	#website_7_pc_Group_4_bike {
		position: absolute;
		width: 272px;
		height: 220px;
		left: 24px;
		top: 20px;
		overflow: visible;
	}
	#arrow_website_7_pcbike {
		position: absolute;
		width: 48.28px;
		height: 12px;
		left: 619.72px;
		top: 130px;
		overflow: visible;
	}
	#Line_1_website_7_pc_bike {
		fill: transparent;
		stroke: rgba(222,14,11,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_1_website_7_pc_bike {
		overflow: visible;
		position: absolute;
		width: 41.18px;
		height: 2px;
		left: 0px;
		top: 5.737px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Polygon_2_website_7_pc_bike {
		fill: rgba(222,14,11,1);
	}
	.Polygon_2_website_7_pc_bike {
		overflow: visible;
		position: absolute;
		width: 12px;
		height: 10px;
		transform: translate(0px, 0px) matrix(1,0,0,1,37.2803,1.0005) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#Vision_lbl_website_7_pc_bike {
		left: 8px;
		top: 119px;
		position: absolute;
		overflow: visible;
		width: 111px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 41px;
		color: rgba(0,0,0,1);
	}
	#vision_top_line_website_7_pcbi {
		fill: rgba(222,14,11,1);
	}
	.vision_top_line_website_7_pcbi {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 8px;
		top: 107px;
	}
	#Scroll_vision_intro_web_8_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 449px;
		height: 184px;
		left: 0px;
		top: 188px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#oncbike_struments_and_a_1_bike {
		left: 8px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 133px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(116,116,116,1);
	}
	#mision_with_img_grp_pc_8_web_b {
		position: absolute;
		width: 1472px;
		height: 563.371px;
		left: -366px;
		top: 1668px;
		overflow: visible;
	}
	#massimiliano-mor_bike_ISY-unsp {
		position: absolute;
		width: 1000px;
		height: 562.5px;
		left: 0px;
		top: 0.059px;
		overflow: visible;
	}
	#white_shade_website_7_pc_bike_cz {
		fill: url(#white_shade_website_7_pc_bike_cz);
	}
	.white_shade_website_7_pc_bike_cz {
		position: absolute;
		overflow: visible;
		width: 695px;
		height: 563px;
		left: 322px;
		top: 0px;
	}
	#mision_img_lines_grp_website_7 {
		fill: rgba(222,14,11,1);
	}
	.mision_img_lines_grp_website_7 {
		overflow: visible;
		position: absolute;
		width: 685.604px;
		height: 285.52px;
		left: 251.158px;
		top: 277.415px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Masterpiece_lbl_website_7_pc_b {
		left: 1033px;
		top: 113px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 9px;
		color: rgba(222,14,11,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#Mission_lbl_website_7_pcbike {
		left: 1033px;
		top: 158px;
		position: absolute;
		overflow: visible;
		width: 136px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#mision_like_website_7_pc_bike {
		fill: rgba(222,14,11,1);
	}
	.mision_like_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 1032px;
		top: 147px;
	}
	#Scroll_mission_web_8_pc_bike {
		mix-blend-mode: normal;
		position: absolute;
		width: 440px;
		height: 183px;
		left: 1032px;
		top: 227px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#An_aggregate_of_racing-lev_web {
		left: 3px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 420px;
		height: 160px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(116,116,116,1);
	}
	#line_3_missionwebsite_7_pc_bik {
		fill: rgba(222,14,11,1);
	}
	.line_3_missionwebsite_7_pc_bik {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.206px;
		left: 1032.437px;
		top: 503.071px;
		transform: matrix(1,0,0,1,0,0);
	}
	#line_2_mission_website_7_pc_bi {
		fill: rgba(222,14,11,1);
	}
	.line_2_mission_website_7_pc_bi {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.206px;
		left: 1032.437px;
		top: 531.751px;
		transform: matrix(1,0,0,1,0,0);
	}
	#line1_mission_website_7_pc_bik {
		fill: rgba(222,14,11,1);
	}
	.line1_mission_website_7_pc_bik {
		overflow: visible;
		position: absolute;
		width: 26.473px;
		height: 2.206px;
		left: 1032.437px;
		top: 561.165px;
		transform: matrix(1,0,0,1,0,0);
	}
	#kisspng-mercedes-ben_website_7 {
		position: absolute;
		width: 278.729px;
		height: 313.897px;
		left: 540.271px;
		top: 130.558px;
		overflow: visible;
	}
	#avaliable_card_bg_website_7_pc {
		fill: rgba(13,13,13,1);
	}
	.avaliable_card_bg_website_7_pc {
		width: 1975.545px;
		height: 405.723px;
		position: absolute;
		overflow: visible;
		transform: matrix(1,0,0,1,-272.0388,3260.6196) rotate(-6deg);
		transform-origin: center;
	}
	#Scroll_off_website_7_pc_bike {
		mix-blend-mode: normal;
		position: absolute;
		width: 1365.944px;
		height: 557.5px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		left: 0.5px;
		transform: rotate(-6deg);
		top: 3184.5px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#offering_card_1_shadow_space_w8 {
		position: relative;
		width: 230px;
		height: 325px;
		left: 66px;
		top: 120px;
		margin-left: 15px;
		overflow: visible;
	}
	#card_bg_offeringshadow_space_w8 {
		fill: rgba(255,255,255,1);
	}
	.card_bg_offeringshadow_space_w8 {
		filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 245.5px;
		height: 340.5px;
		left: 0px;
		top: 0px;
	}
	#oofering_lbl_med_shadow_space_rr_pc {
		left: 18px;
		top: 292px;
		position: absolute;
		overflow: visible;
		width: 166px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#markus-shadow_space_website_7 {
		position: absolute;
		width: 204.834px;
		height: 270.879px;
		left: 11.5px;
		top: 11.17px;
		overflow: visible;
	}
	#Icon_awesome-car_website_7_pc {
		fill: rgba(0,0,0,1);
	}
	.Icon_awesome-car_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 36px;
		height: 27px;
		left: 7.5px;
		top: 23.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Avaliable_Now_lbl_website_7_pc {
		left: 535px;
		top: 3113px;
		position: absolute;
		overflow: visible;
		width: 298px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#header_all_grp_web_mob_8 {
		position: absolute;
		width: 1412px;
		height: 794px;
		left: -47px;
		top: -12px;
		overflow: visible;
	}
	#harley-davidson-eeTJKC_wz34-un {
		position: absolute;
		width: 1412px;
		height: 794px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#text_unlessh_website_7_pc_bike {
		position: absolute;
		width: 422px;
		height: 129px;
		left: 84px;
		top: 325px;
		overflow: visible;
	}
	#Unleash_the_True_Powe_website_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 228px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 41px;
		color: rgba(255,255,255,1);
	}
	#Ride_into_the_Future_Your_Ulti {
		left: 0px;
		top: 108px;
		position: absolute;
		overflow: visible;
		width: 423px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#pre-order_button_website_7_pc_ {
		position: absolute;
		width: 209px;
		height: 20px;
		left: 96px;
		top: 478px;
		overflow: visible;
	}
	#line_pre_website_7_pc_bike {
		fill: rgba(222,14,11,1);
	}
	.line_pre_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 3px;
		left: 0px;
		top: 9px;
	}
	#PRE-ORDER_NOW_website_7_pc_bik {
		left: 64px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 146px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(222,14,11,1);
		letter-spacing: 0.95px;
	}
	#zane-lee-bXbi1jJVDf0-unsplash_PC {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 137px;
		top: 33px;
		border-radius: 50%;
		overflow: hidden;
	}
	#footer_all_grp_web_mob_8 {
		position: absolute;
		width: 1555px;
		height: 571px;
		left: 39px;
		top: 3774px;
		overflow: visible;
	}
	#fotter_img_website_7_pc_bike {
		position: absolute;
		width: 677px;
		height: 497px;
		left: 878px;
		top: 0px;
		overflow: visible;
	}
	#shade_footer_design_website_7__du {
		fill: url(#shade_footer_design_website_7__du);
	}
	.shade_footer_design_website_7__du {
		position: absolute;
		overflow: visible;
		width: 454px;
		height: 427px;
		left: 869px;
		top: 26px;
	}
	#bdr_info_website_7_pc_bike {
		fill: rgba(21,21,21,1);
	}
	.bdr_info_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 493px;
		height: 454px;
		left: 554px;
		top: 117px;
	}
	#Call_us_for_questions_website_ {
		left: 628px;
		top: 141px;
		position: absolute;
		overflow: visible;
		width: 209px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#call_below_line_website_7_pc_b {
		fill: rgba(255,255,255,1);
	}
	.call_below_line_website_7_pc_b {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 627px;
		top: 180px;
	}
	#no_bdr_website_7_pc_bike {
		fill: rgba(222,14,11,1);
	}
	.no_bdr_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 60px;
		left: 627px;
		top: 200px;
	}
	#n_1_123-456-7890_website_7_pc_ {
		left: 696px;
		top: 216px;
		position: absolute;
		overflow: visible;
		width: 166px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#phone-receiver_bdr_website_7_p {
		fill: rgba(255,255,255,1);
	}
	.phone-receiver_bdr_website_7_p {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 60px;
		left: 627px;
		top: 200px;
	}
	#phone-receiver_website_7_pc_bi {
		fill: rgba(21,21,21,1);
	}
	.phone-receiver_website_7_pc_bi {
		overflow: visible;
		position: absolute;
		width: 23.454px;
		height: 23.438px;
		left: 645.163px;
		top: 217.434px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Email_us_for_inquiries_website {
		left: 628px;
		top: 276.999px;
		position: absolute;
		overflow: visible;
		width: 211px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#email_bdr_website_7_pc_bike {
		fill: rgba(255,255,255,1);
	}
	.email_bdr_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 627px;
		top: 316.001px;
	}
	#email_bdr_website_7_pc_bike_d {
		fill: rgba(222,14,11,1);
	}
	.email_bdr_website_7_pc_bike_d {
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 61px;
		left: 628px;
		top: 336.001px;
	}
	#emailmailcom_website_7_pc_bike {
		left: 697px;
		top: 352.999px;
		position: absolute;
		overflow: visible;
		width: 278px;
		height: 27px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
	}
	#mail_bdr_website_7_pc_bike {
		fill: rgba(255,255,255,1);
	}
	.mail_bdr_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 61px;
		left: 628px;
		top: 336px;
	}
	#envelope_website_7_pc_bike {
		position: absolute;
		width: 29.462px;
		height: 23.147px;
		left: 643.144px;
		top: 355.268px;
		overflow: visible;
	}
	#Path_15_website_7_pc_bike {
		fill: rgba(21,21,21,1);
	}
	.Path_15_website_7_pc_bike {
		overflow: visible;
		position: absolute;
		width: 29.462px;
		height: 14.73px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_16_website_7_pc_bike {
		fill: rgba(21,21,21,1);
	}
	.Path_16_website_7_pc_bike {
		overflow: visible;
		position: absolute;
		width: 29.462px;
		height: 15.685px;
		left: 0px;
		top: 7.463px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 528px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(173,173,173,1);
	}
	#rr_socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 160px;
		top: 437px;
		overflow: visible;
	}
	#linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.med_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.192px;
		overflow: visible;
	}
	#n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.671px;
		transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.809px;
		transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#shadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#med_shadow_space_website_7_eo:hover {
		fill: rgba(50,172,158,1);
	}
	.med_shadow_space_website_7_eo:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#med_shadow_space_website_7_eo {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.med_shadow_space_website_7_eo {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 11.993px;
		overflow: visible;
	}
	#gps_footer_website_7_pc_bike {
		position: absolute;
		width: 402px;
		height: 251px;
		left: 53px;
		top: 143px;
		overflow: visible;
	}
	#bdr_screen_website_7_pc_bike {
		fill: rgba(0,0,0,1);
	}
	.bdr_screen_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 402px;
		height: 251px;
		left: 0px;
		top: 0px;
	}
	#screen_map_website_7_pc_bike {
		fill: rgba(255,255,255,1);
	}
	.screen_map_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 385px;
		height: 211px;
		left: 8px;
		top: 7px;
	}
	#Icon_awesome-car_website_7_pc_ {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-car_website_7_pc_ {
		overflow: visible;
		position: absolute;
		width: 15.924px;
		height: 11.943px;
		left: 29.635px;
		top: 229.723px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-musicwebsite_7_pc {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-musicwebsite_7_pc {
		overflow: visible;
		position: absolute;
		width: 11.944px;
		height: 11.944px;
		left: 74.993px;
		top: 229.722px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-lbikewebsite_7_pc {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-lbikewebsite_7_pc {
		overflow: visible;
		position: absolute;
		width: 11.943px;
		height: 11.943px;
		left: 175.159px;
		top: 229.723px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_459321__website_7_pc_bike {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_459321__website_7_pc_bike {
		overflow: visible;
		position: absolute;
		width: 9.465px;
		height: 13.943px;
		left: 234.466px;
		top: 229.723px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_4601_website_7_pc_bike {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_4601_website_7_pc_bike {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 12.45px;
		left: 231.48px;
		top: 230.469px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_459_website_7_pc_bike {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_459_website_7_pc_bike {
		overflow: visible;
		position: absolute;
		width: 9.465px;
		height: 13.943px;
		left: 271.474px;
		top: 229.723px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_460_website_7_pc_bike {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Path_460_website_7_pc_bike {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 12.45px;
		left: 281.924px;
		top: 230.469px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-volume-up_website {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-volume-up_website {
		overflow: visible;
		position: absolute;
		width: 13.132px;
		height: 10.944px;
		left: 364.569px;
		top: 230.722px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Email_us_for_inquiries_website_e {
		left: 629px;
		top: 415px;
		position: absolute;
		overflow: visible;
		width: 211px;
		white-space: nowrap;
		line-height: 16px;
		margin-top: -0.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
		text-transform: uppercase;
	}
	#line1_website_7_pc_bike {
		fill: rgba(255,255,255,1);
	}
	.line1_website_7_pc_bike {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 1px;
		left: 628px;
		top: 454px;
	}
	#address_bdr_lbl_website_7_pc_b {
		fill: rgba(222,14,11,1);
	}
	.address_bdr_lbl_website_7_pc_b {
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 61px;
		left: 629px;
		top: 474px;
	}
	#address_website_7_pc_bike {
		left: 698px;
		top: 474px;
		position: absolute;
		overflow: visible;
		width: 278px;
		height: 61px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.95px;
	}
	#address_icon_bdr_website_7_pc_ {
		fill: rgba(255,255,255,1);
	}
	.address_icon_bdr_website_7_pc_ {
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 61px;
		left: 629px;
		top: 474px;
	}
	#Icon_material-edit-location_we8 {
		fill: rgba(0,0,0,1);
	}
	.Icon_material-edit-location_we8 {
		overflow: visible;
		position: absolute;
		width: 21px;
		height: 30px;
		left: 648px;
		top: 490px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Scroll_vision_intro_web_8_pc::-webkit-scrollbar{
		display: none
	}
#Scroll_mission_web_8_pc_bike::-webkit-scrollbar{
		display: none;
	}
#Scroll_prinic_web_8_pc_bike::-webkit-scrollbar{
		display: none;
	}
#Scroll_off_website_7_pc_bike::-webkit-scrollbar{
		display: none;
	}
#kisspng-mercedes-ben_website_7 { 
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 20px); }
    100%   { transform: translate(0, -0px); }   
}


