
	.mediaViewInfo {
		--web-view-name: InkwellHaven;
		--web-view-id: InkwellHaven;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: InkwellHaven;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#InkwellHaven {
		position: absolute;
		width: 1366px;
		height: 3358px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: InkwellHaven;
		--web-view-id: InkwellHaven;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#IWH_PC_33Group_15156 {
		position: absolute;
		width: 1366px;
		height: 1251px;
		left: 0px;
		top: 826px;
		overflow: visible;
	}
	#IWH_PC_33n_1994588_2111w039n0037Bp17 {
		opacity: 0.2;
		position: absolute;
		width: 1366px;
		height: 1251px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 605px;
		top: 141px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#IWH_PC_33Scroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#IWH_PC_33It_is_a_long_established_fact_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#IWH_PC_33VISION {
		left: 605px;
		top: 87px;
		position: absolute;
		overflow: visible;
		width: 62px;
		white-space: nowrap;
		line-height: 37px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33Scroll_Group_28_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 129px;
		left: 153px;
		top: 566px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#IWH_PC_33Scroll_Group_28_::-webkit-scrollbar{
		display: none;
	}
	#IWH_PC_33It_is_a_long_established_fact__ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 126px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#IWH_PC_33MISSION {
		left: 153px;
		top: 512px;
		position: absolute;
		overflow: visible;
		width: 78px;
		white-space: nowrap;
		line-height: 37px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33Scroll_Group_28_ba {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 605px;
		top: 974px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#IWH_PC_33Scroll_Group_28_ba::-webkit-scrollbar{
		display: none;
	}
	#IWH_PC_33It_is_a_long_established_fact__bb {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#IWH_PC_33PRINCIPLES {
		left: 605px;
		top: 920px;
		position: absolute;
		overflow: visible;
		width: 101px;
		white-space: nowrap;
		line-height: 37px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33Group_15160 {
		position: absolute;
		width: 288.01px;
		height: 220.593px;
		left: 161.181px;
		top: 41px;
		overflow: visible;
	}
	#IWH_PC_33Path_7783 {
		fill: rgba(235,161,99,1);
	}
	.IWH_PC_33Path_7783 {
		overflow: visible;
		position: absolute;
		width: 288.01px;
		height: 66.065px;
		left: 0px;
		top: 154.527px;
		transform: matrix(1,0,0,1,0,0);
	}
	#IWH_PC_33kisspng-learning-education-bla {
		position: absolute;
		width: 218px;
		height: 218px;
		left: 34.819px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33Group_15161 {
		position: absolute;
		width: 326.703px;
		height: 320.616px;
		left: 792.463px;
		top: 373.828px;
		overflow: visible;
	}
	#IWH_PC_33Path_7784 {
		fill: rgba(99,235,131,1);
	}
	.IWH_PC_33Path_7784 {
		overflow: visible;
		position: absolute;
		width: 288.01px;
		height: 66.065px;
		left: 19.346px;
		top: 235.731px;
		transform: matrix(1,0,0,1,0,0);
	}
	#IWH_PC_33kisspng-school-royalty-free-dr {
		position: absolute;
		width: 326.703px;
		height: 320.616px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33Group_15162 {
		position: absolute;
		width: 288.01px;
		height: 263px;
		left: 161.181px;
		top: 865px;
		overflow: visible;
	}
	#IWH_PC_33Path_7785 {
		fill: rgba(99,99,235,1);
	}
	.IWH_PC_33Path_7785 {
		overflow: visible;
		position: absolute;
		width: 288.01px;
		height: 66.065px;
		left: 0px;
		top: 163.428px;
		transform: matrix(1,0,0,1,0,0);
	}
	#IWH_PC_33n_359207_19882 {
		position: absolute;
		width: 263px;
		height: 263px;
		left: 12.819px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33Group_15158 {
		position: absolute;
		width: 1131.5px;
		height: 466.813px;
		left: 117.25px;
		top: 2884.186px;
		overflow: visible;
	}
	#IWH_PC_33socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 624.5px;
		top: 348.813px;
		overflow: visible;
	}
	#IWH_PC_33linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.IWH_PC_33med_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#IWH_PC_33linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#IWH_PC_33n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.IWH_PC_33n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.671px;
		transform: matrix(1,0,0,1,0,0);
	}
	#IWH_PC_33linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.IWH_PC_33linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#IWH_PC_33top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.IWH_PC_33top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#IWH_PC_33Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.IWH_PC_33shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#IWH_PC_33instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.003px;
		overflow: visible;
	}
	#IWH_PC_33whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33med_shadow_space_website_7_by {
		fill: rgba(255,255,255,1);
	}
	.IWH_PC_33med_shadow_space_website_7_by {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#IWH_PC_33whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#IWH_PC_33n_234567890_mobile_number_shad {
		left: 659.5px;
		top: 117.813px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33affanghanioperatelive_email_sh {
		left: 664.5px;
		top: 170.813px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33address_med_shadow_space_websi {
		left: 659.5px;
		top: 220.813px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 624.5px;
		top: 222.813px;
		overflow: visible;
	}
	#IWH_PC_33email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 624.5px;
		top: 169.813px;
		overflow: visible;
	}
	#IWH_PC_33phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 624.5px;
		top: 116.813px;
		overflow: visible;
	}
	#IWH_PC_33n_Copyright_Operate_Live_OPC_P {
		left: 624.5px;
		top: 444.813px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33Contact_Us {
		left: 489.25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 163px;
		white-space: nowrap;
		text-align: left;
		font-family: Microsoft PhagsPa;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#IWH_PC_33kisspng-paper-area-rectangle-s {
		position: absolute;
		width: 500px;
		height: 357.813px;
		left: 0px;
		top: 87px;
		overflow: visible;
	}
	#IWH_PC_33Rectangle_756 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.IWH_PC_33Rectangle_756 {
		position: absolute;
		overflow: visible;
		width: 405px;
		height: 266px;
		left: 46px;
		top: 130.814px;
	}
	#IWH_PC_33Group_15159 {
		position: absolute;
		width: 1325px;
		height: 595px;
		left: 20px;
		top: 20px;
		overflow: visible;
	}
	#IWH_PC_33deepigoyal--TCygdpnO3g-unsplas {
		position: absolute;
		width: 650px;
		height: 595px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33Essential_That_Inspires_You_to {
		left: 690px;
		top: 211px;
		position: absolute;
		overflow: visible;
		width: 491px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33Crafting_Words_One_Page_at_a_T {
		left: 690px;
		top: 365px;
		position: absolute;
		overflow: visible;
		width: 636px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33Your_Name {
		left: 690px;
		top: 27px;
		position: absolute;
		overflow: visible;
		width: 378px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33Anime_Edit {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 80px;
		height: 80px;
		left: 1245px;
		top: 0px;
		overflow: hidden;
        border-radius: 50%;
	}
	#IWH_PC_33Group_15157 {
		position: absolute;
		width: 1366px;
		height: 488px;
		left: 0px;
		top: 2234px;
		overflow: visible;
	}
	#IWH_PC_33school-supplies-art-classes {
		position: absolute;
		width: 1366px;
		height: 395px;
		left: 0px;
		top: 93px;
		overflow: visible;
	}
	#IWH_PC_33Rectangle_755 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.IWH_PC_33Rectangle_755 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 395px;
		left: 0px;
		top: 93px;
	}
	#IWH_PC_33Available_Stationery {
		left: 548px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 272px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33Scroll_offering_med_web_4_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 1336px;
		height: 357px;
		left: 15px;
		top: 113px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#IWH_PC_33Scroll_offering_med_web_4_pc::-webkit-scrollbar{
		display: none;
	}
	#IWH_PC_33offering_crd_1_grp_med_web_4_p {
		position: relative;
        margin-right: 30px;
		width: 200px;
		height: 332px;
		left: 15px;
		top: 11px;
		overflow: visible;
	}
	#IWH_PC_33title_bdr_med_web_4_pc {
		fill: rgba(255,255,255,1);
	}
	.IWH_PC_33title_bdr_med_web_4_pc {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 190px;
		height: 50px;
		left: 14px;
		top: 300px;
	}
	#IWH_PC_33BiziBees_PVT_LTD_med_web_4_pc {
		left: 14px;
		top: 306px;
		position: absolute;
		overflow: visible;
		width: 173px;
		height: 20px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#IWH_PC_33img_border_med_web_4_pc_of {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 300px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#IWH_PC_33chuttersna_med_web_4_pc_img {
		position: absolute;
		width: 172px;
		height: 277px;
		left: 14px;
		top: 7px;
		overflow: visible;
	}