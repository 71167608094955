	.mediaViewInfo {
		--web-view-name: Paw Palace Mob;
		--web-view-id: Paw_Palace_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Paw_Palace_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar	{
		display: none;
	}
	#Paw_Palace_Mob {
		position: absolute;
		width: 375px;
		height: 3446px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(255,255,255,0.886);
		overflow: hidden;
		--web-view-name: Paw_Palace_Mob;
		--web-view-id: Paw_Palace_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#petpaw_12_mobGroup_96 {
		transform: matrix(1,0,0,1,-175.7714,2202.3693) rotate(105deg);
		transform-origin: center;
		position: absolute;
		width: 283.628px;
		height: 283.992px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobEllipse_16 {
		fill: rgba(255,229,48,1);
	}
	.petpaw_12_mobEllipse_16 {
		position: absolute;
		overflow: visible;
		width: 347.724px;
		height: 347.465px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobEllipse_20_ci {
		opacity: 0.15;
		fill: url(#petpaw_12_mobEllipse_20_ci);
	}
	.petpaw_12_mobEllipse_20_ci {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 269.43px;
		height: 269.688px;
		left: 73.016px;
		top: 36.277px;
	}
	#petpaw_12_mobEllipse_21_ck {
		opacity: 0.15;
		fill: url(#petpaw_12_mobEllipse_21_ck);
	}
	.petpaw_12_mobEllipse_21_ck {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 206.461px;
		height: 206.461px;
		left: 129.295px;
		top: 74.535px;
	}
	#petpaw_12_mobGroup_85 {
		position: absolute;
		width: 944px;
		height: 946px;
		left: 187.5px;
		top: 1344.998px;
		overflow: visible;
	}
	#petpaw_12_mobEllipse_16_cm {
		fill: rgba(255,229,48,1);
	}
	.petpaw_12_mobEllipse_16_cm {
		position: absolute;
		overflow: visible;
		width: 944px;
		height: 946px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobEllipse_20_co {
		opacity: 0.15;
		fill: url(#petpaw_12_mobEllipse_20_co);
	}
	.petpaw_12_mobEllipse_20_co {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 732px;
		height: 733px;
		left: 68px;
		top: 0px;
	}
	#petpaw_12_mobEllipse_21_cq {
		opacity: 0.15;
		fill: url(#petpaw_12_mobEllipse_21_cq);
	}
	.petpaw_12_mobEllipse_21_cq {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 560px;
		height: 561px;
		left: 154px;
		top: 0px;
	}
	#petpaw_12_mobGroup_73 {
		position: absolute;
		width: 260px;
		height: 319.396px;
		left: 58px;
		top: 473.063px;
		overflow: visible;
	}
	#petpaw_12_mobEllipse_17 {
		fill: rgba(255,229,48,1);
	}
	.petpaw_12_mobEllipse_17 {
		position: absolute;
		overflow: visible;
		width: 239.043px;
		height: 239.043px;
		left: 20.957px;
		top: 80.352px;
	}
	#petpaw_12_mobMask_Group_3 {
		position: absolute;
		width: 239.043px;
		height: 239.043px;
		left: 20.957px;
		top: 80.352px;
		overflow: visible;
	}
	#petpaw_12_mobMask_Group_4 {
		position: absolute;
		width: 260px;
		height: 250.777px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14911 {
		position: absolute;
		width: 2195.199px;
		height: 1654.761px;
		left: -587.123px;
		top: -148.9px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_76 {
		position: absolute;
		width: 949.827px;
		height: 621.962px;
		left: 490.374px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobPath_261 {
		fill: rgba(255,103,82,1);
	}
	.petpaw_12_mobPath_261 {
		overflow: visible;
		position: absolute;
		width: 948.508px;
		height: 565.438px;
		left: 1.32px;
		top: 56.525px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobPath_262_cz {
		opacity: 0.15;
		fill: url(#petpaw_12_mobPath_262_cz);
	}
	.petpaw_12_mobPath_262_cz {
		mix-blend-mode: multiply;
		overflow: visible;
		position: absolute;
		width: 830.845px;
		height: 396.705px;
		left: 28.516px;
		top: 56.361px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobPath_263_c {
		opacity: 0.15;
		fill: url(#petpaw_12_mobPath_263_c);
	}
	.petpaw_12_mobPath_263_c {
		mix-blend-mode: multiply;
		overflow: visible;
		position: absolute;
		width: 861.454px;
		height: 434.934px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobGroup_56 {
		position: absolute;
		width: 278px;
		height: 203.001px;
		left: 627.123px;
		top: 378.461px;
		overflow: visible;
	}
	#petpaw_12_mobGet_A_Pet_Now {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 217px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(255,255,255,1);
	}
	#petpaw_12_mobPawsitively_Devoted__Your_Pets {
		left: 0px;
		top: 59px;
		position: absolute;
		overflow: visible;
		width: 279px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#petpaw_12_mobGroup_55 {
		position: absolute;
		width: 168px;
		height: 53px;
		left: 0px;
		top: 150px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_1 {
		position: absolute;
		width: 168px;
		height: 53px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobRectangle_21 {
		filter: blur(50px);
		fill: rgba(68,135,255,0.341);
	}
	.petpaw_12_mobRectangle_21 {
		filter: drop-shadow(0px 10px 10px rgba(68, 135, 255, 1));
		position: absolute;
		overflow: visible;
		width: 198px;
		height: 83px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobRectangle_1 {
		fill: rgba(68,135,255,1);
	}
	.petpaw_12_mobRectangle_1 {
		filter: drop-shadow(0px 3px 6px rgba(44, 29, 173, 0.161));
		position: absolute;
		overflow: visible;
		width: 186px;
		height: 71px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobExplore_More {
		left: 38px;
		top: 17px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(248,248,248,1);
	}
	#petpaw_12_mobGroup_95 {
		position: absolute;
		width: 55px;
		height: 54px;
		left: 627.123px;
		top: 180.9px;
		overflow: visible;
	}
	#petpaw_12_mobAnime_Edit {
		position: absolute;
		width: 55px;
		height: 54px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobSHOP_NAME {
		left: 627.123px;
		top: 301.461px;
		position: absolute;
		overflow: visible;
		width: 210px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(255,255,255,1);
	}
	#petpaw_12_mobGroup_75 {
		position: absolute;
		width: 2195.199px;
		height: 1167.126px;
		left: 0px;
		top: 487.635px;
		overflow: visible;
	}
	#petpaw_12_mobPath_251 {
		fill: rgba(68,135,255,1);
	}
	.petpaw_12_mobPath_251 {
		overflow: visible;
		position: absolute;
		width: 2195.199px;
		height: 1167.125px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobMask_Group_6 {
		opacity: 0.2;
		mix-blend-mode: multiply;
		position: absolute;
		width: 2195.199px;
		height: 1167.127px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobMask_Group_5 {
		opacity: 0.15;
		mix-blend-mode: multiply;
		position: absolute;
		width: 2195.199px;
		height: 1167.127px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14899 {
		position: absolute;
		width: 421px;
		height: 661.631px;
		left: 0px;
		top: 863.926px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_43 {
		position: absolute;
		width: 317.079px;
		height: 315.896px;
		left: 25.961px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_42 {
		position: absolute;
		width: 317.079px;
		height: 315.896px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_38 {
		position: absolute;
		width: 147.3px;
		height: 147.3px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobRectangle_15 {
		fill: rgba(255,255,255,1);
	}
	.petpaw_12_mobRectangle_15 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 165.3px;
		height: 165.299px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobGroup_37 {
		position: absolute;
		width: 47.325px;
		height: 87.579px;
		left: 50.039px;
		top: 29.578px;
		overflow: visible;
	}
	#petpaw_12_mobVision {
		left: 8px;
		top: 71.58px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(112,112,112,1);
	}
	#petpaw_12_mobdog {
		opacity: 0.3;
		position: absolute;
		width: 47.325px;
		height: 47.336px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobPath_205 {
		fill: rgba(0,34,92,1);
	}
	.petpaw_12_mobPath_205 {
		overflow: visible;
		position: absolute;
		width: 1.966px;
		height: 1.967px;
		left: 25.409px;
		top: 16.6px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobPath_206 {
		fill: rgba(0,34,92,1);
	}
	.petpaw_12_mobPath_206 {
		overflow: visible;
		position: absolute;
		width: 1.966px;
		height: 1.967px;
		left: 7.262px;
		top: 10.098px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobPath_207 {
		fill: rgba(0,34,92,1);
	}
	.petpaw_12_mobPath_207 {
		overflow: visible;
		position: absolute;
		width: 47.325px;
		height: 47.336px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobGroup_40 {
		position: absolute;
		width: 147.3px;
		height: 147.3px;
		left: 86.664px;
		top: 168.596px;
		overflow: visible;
	}
	#petpaw_12_mobRectangle_15_dt {
		fill: rgba(255,255,255,1);
	}
	.petpaw_12_mobRectangle_15_dt {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 165.3px;
		height: 165.299px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobGroup_37_du {
		position: absolute;
		width: 60.931px;
		height: 94.087px;
		left: 43.185px;
		top: 23.072px;
		overflow: visible;
	}
	#petpaw_12_mobMask_Group_2 {
		position: absolute;
		width: 60.931px;
		height: 60.932px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobPrinciples {
		left: 3.19px;
		top: 78.086px;
		position: absolute;
		overflow: visible;
		width: 51px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(112,112,112,1);
	}
	#petpaw_12_mobGroup_65 {
		position: absolute;
		width: 47.325px;
		height: 47.323px;
		left: 47.375px;
		top: 30.762px;
		overflow: visible;
	}
	#petpaw_12_mobpet-house {
		opacity: 0.3;
		mix-blend-mode: normal;
		position: absolute;
		width: 47.325px;
		height: 47.323px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_60 {
		position: absolute;
		width: 47.325px;
		height: 47.323px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_59 {
		position: absolute;
		width: 47.325px;
		height: 47.323px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobPath_221 {
		fill: rgba(0,34,92,1);
	}
	.petpaw_12_mobPath_221 {
		overflow: visible;
		position: absolute;
		width: 47.325px;
		height: 47.324px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobGroup_62 {
		position: absolute;
		width: 2.773px;
		height: 2.773px;
		left: 22.276px;
		top: 16.637px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_61 {
		position: absolute;
		width: 2.773px;
		height: 2.773px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobEllipse_15 {
		fill: rgba(0,34,92,1);
	}
	.petpaw_12_mobEllipse_15 {
		position: absolute;
		overflow: visible;
		width: 2.773px;
		height: 2.773px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobGroup_39 {
		position: absolute;
		width: 147.3px;
		height: 147.3px;
		left: 169.779px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobRectangle_15_d {
		fill: rgba(255,255,255,1);
	}
	.petpaw_12_mobRectangle_15_d {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 165.3px;
		height: 165.299px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobGroup_37_d {
		position: absolute;
		width: 41.016px;
		height: 87.579px;
		left: 51.26px;
		top: 29.578px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_63 {
		opacity: 0.3;
		mix-blend-mode: normal;
		position: absolute;
		width: 41.016px;
		height: 47.325px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobkitten {
		position: absolute;
		width: 41.016px;
		height: 47.325px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_58 {
		position: absolute;
		width: 41.016px;
		height: 47.325px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobPath_208 {
		fill: rgba(0,34,92,1);
	}
	.petpaw_12_mobPath_208 {
		overflow: visible;
		position: absolute;
		width: 41.017px;
		height: 47.324px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobEllipse_12 {
		fill: rgba(0,34,92,1);
	}
	.petpaw_12_mobEllipse_12 {
		position: absolute;
		overflow: visible;
		width: 1.578px;
		height: 1.578px;
		left: 7.099px;
		top: 6.309px;
	}
	#petpaw_12_mobEllipse_13 {
		fill: rgba(0,34,92,1);
	}
	.petpaw_12_mobEllipse_13 {
		position: absolute;
		overflow: visible;
		width: 1.578px;
		height: 1.578px;
		left: 11.043px;
		top: 6.309px;
	}
	#petpaw_12_mobMission {
		left: 1px;
		top: 71.58px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: center;
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(112,112,112,1);
	}
	#petpaw_12_mobVision_ef {
		left: 141.124px;
		top: 367.014px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(255,103,82,1);
	}
	#petpaw_12_mobScroll_Group_1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 421px;
		height: 239px;
		left: 0px;
		top: 422.631px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#petpaw_12_mobScroll_Group_1::-webkit-scrollbar{
		display: none;
	}
	#petpaw_12_mobn_I_like_that_I_got_to_meet_th {
		opacity: 0.8;
		left: 18px;
		top: 17px;
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 222px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#petpaw_12_mobGroup_101 {
		position: absolute;
		width: 352.5px;
		height: 870.305px;
		left: 12.5px;
		top: 1546px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_100 {
		position: absolute;
		width: 352.5px;
		height: 791.609px;
		left: 0px;
		top: 78.697px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_99 {
		transform: translate(-386.658px, 226.736px) matrix(1,0,0,1,163.8547,146.5673) rotate(-90deg);
		transform-origin: center;
		position: absolute;
		width: 791.609px;
		height: 45px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_93 {
		position: absolute;
		width: 45px;
		height: 45px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobEllipse_10 {
		fill: rgba(255,103,82,1);
	}
	.petpaw_12_mobEllipse_10 {
		filter: drop-shadow(0px 3px 6px rgba(253, 104, 69, 0.302));
		position: absolute;
		overflow: visible;
		width: 63px;
		height: 63px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobGroup_46 {
		position: absolute;
		width: 20.479px;
		height: 9.239px;
		left: 17.752px;
		top: 12.436px;
		overflow: visible;
	}
	#petpaw_12_mobPath_201 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaw_12_mobPath_201 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 22.479px;
		left: 4.479px;
		top: -0.002px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobPath_202 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaw_12_mobPath_202 {
		overflow: visible;
		position: absolute;
		width: 12.067px;
		height: 7.033px;
		left: 0px;
		top: -0.002px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobGroup_94 {
		position: absolute;
		width: 45px;
		height: 45px;
		left: 0px;
		top: 746.609px;
		overflow: visible;
	}
	#petpaw_12_mobEllipse_10_er {
		fill: rgba(255,103,82,1);
	}
	.petpaw_12_mobEllipse_10_er {
		filter: drop-shadow(0px 3px 6px rgba(253, 104, 69, 0.302));
		position: absolute;
		overflow: visible;
		width: 63px;
		height: 63px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobGroup_46_es {
		position: absolute;
		width: 20.479px;
		height: 9.239px;
		left: 17.752px;
		top: 11.621px;
		overflow: visible;
	}
	#petpaw_12_mobPath_201_et {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaw_12_mobPath_201_et {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 22.479px;
		left: 4.479px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobPath_202_eu {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.petpaw_12_mobPath_202_eu {
		overflow: visible;
		position: absolute;
		width: 12.067px;
		height: 7.033px;
		left: 0px;
		top: 15.859px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobGroup_14915 {
		position: absolute;
		width: 352.5px;
		height: 661.001px;
		left: 0px;
		top: 63.303px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14904 {
		position: absolute;
		width: 351.5px;
		height: 207.001px;
		left: 1px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobRectangle_22 {
		filter: blur(15px);
		fill: rgba(168,158,255,1);
	}
	.petpaw_12_mobRectangle_22 {
		filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		position: absolute;
		overflow: visible;
		width: 486px;
		height: 347px;
		left: 7px;
		top: 5px;
	}
	#petpaw_12_mobRectangle_15_ey {
		fill: rgba(253,104,69,1);
	}
	.petpaw_12_mobRectangle_15_ey {
		filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		position: absolute;
		overflow: visible;
		width: 369px;
		height: 225px;
		left: 0.5px;
		top: 0px;
	}
	#petpaw_12_mobMask_Group_7 {
		opacity: 0.1;
		mix-blend-mode: multiply;
		position: absolute;
		width: 351px;
		height: 207px;
		left: 0px;
		top: -0.002px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14902 {
		position: absolute;
		width: 351px;
		height: 207px;
		left: 0px;
		top: -0.002px;
		overflow: visible;
	}
	#petpaw_12_mobMask_Group_8 {
		opacity: 0.15;
		mix-blend-mode: multiply;
		position: absolute;
		width: 351px;
		height: 207px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_47 {
		position: absolute;
		width: 337px;
		height: 192px;
		left: 9.5px;
		top: 10.002px;
		overflow: visible;
	}
	#petpaw_12_mobKenned_Dsouza {
		left: 122px;
		top: 73.596px;
		position: absolute;
		overflow: visible;
		width: 89px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(248,248,248,1);
	}
	#petpaw_12_mobScroll_Group_31 {
		mix-blend-mode: normal;
		position: absolute;
		width: 337px;
		height: 93px;
		left: 0px;
		top: 99px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#petpaw_12_mobScroll_Group_31::-webkit-scrollbar{
		display: none;
	}
	#petpaw_12_mobn_I_like_that_I_got_to_meet_th_e {
		opacity: 0.8;
		left: 11px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 309px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(248,248,248,1);
	}
	#petpaw_12_mobdownload_2 {
		position: absolute;
		width: 271px;
		height: 64px;
		left: 31px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14907 {
		position: absolute;
		width: 350.5px;
		height: 207.001px;
		left: 0px;
		top: 454px;
		overflow: visible;
	}
	#petpaw_12_mobRectangle_22_e {
		filter: blur(15px);
		fill: rgba(168,158,255,1);
	}
	.petpaw_12_mobRectangle_22_e {
		filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		position: absolute;
		overflow: visible;
		width: 486px;
		height: 347px;
		left: 7px;
		top: 5px;
	}
	#petpaw_12_mobRectangle_15_e {
		fill: rgba(253,104,69,1);
	}
	.petpaw_12_mobRectangle_15_e {
		filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		position: absolute;
		overflow: visible;
		width: 368px;
		height: 225px;
		left: 0.5px;
		top: 0px;
	}
	#petpaw_12_mobMask_Group_7_fa {
		opacity: 0.1;
		mix-blend-mode: multiply;
		position: absolute;
		width: 350px;
		height: 207px;
		left: 0.5px;
		top: -0.002px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14903 {
		position: absolute;
		width: 350px;
		height: 207px;
		left: 0px;
		top: -0.002px;
		overflow: visible;
	}
	#petpaw_12_mobMask_Group_8_fc {
		opacity: 0.15;
		mix-blend-mode: multiply;
		position: absolute;
		width: 350px;
		height: 207px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_47_fd {
		position: absolute;
		width: 325px;
		height: 187px;
		left: 6px;
		top: 10.002px;
		overflow: visible;
	}
	#petpaw_12_mobKenned_Dsouza_fe {
		left: 122.5px;
		top: 73.424px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(248,248,248,1);
	}
	#petpaw_12_mobScroll_Group_34 {
		mix-blend-mode: normal;
		position: absolute;
		width: 325px;
		height: 96px;
		left: 0px;
		top: 91px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#petpaw_12_mobScroll_Group_34::-webkit-scrollbar{
		display: none
	}
	#petpaw_12_mobn_I_like_that_I_got_to_meet_th_fg {
		opacity: 0.8;
		left: 15px;
		top: 16px;
		position: absolute;
		overflow: visible;
		width: 295px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(248,248,248,1);
	}
	#petpaw_12_mobdownload_2_fh {
		position: absolute;
		width: 271px;
		height: 64px;
		left: 34.5px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14906 {
		position: absolute;
		width: 350px;
		height: 207px;
		left: 0px;
		top: 227px;
		overflow: visible;
	}
	#petpaw_12_mobRectangle_22_fj {
		filter: blur(15px);
		fill: rgba(168,158,255,1);
	}
	.petpaw_12_mobRectangle_22_fj {
		filter: drop-shadow(0px 20px 20px rgba(50, 32, 206, 0.161));
		position: absolute;
		overflow: visible;
		width: 484px;
		height: 347px;
		left: 8px;
		top: 5px;
	}
	#petpaw_12_mobMask_Group_7_fk {
		opacity: 0.1;
		mix-blend-mode: multiply;
		position: absolute;
		width: 350px;
		height: 207px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14905 {
		position: absolute;
		width: 350px;
		height: 207px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobMask_Group_8_fm {
		opacity: 0.15;
		mix-blend-mode: multiply;
		position: absolute;
		width: 350px;
		height: 207px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobGroup_14901 {
		position: absolute;
		width: 350px;
		height: 207px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobRectangle_15_fo {
		fill: rgba(68,135,255,1);
	}
	.petpaw_12_mobRectangle_15_fo {
		filter: drop-shadow(0px 3px 6px rgba(50, 32, 206, 0.502));
		position: absolute;
		overflow: visible;
		width: 368px;
		height: 225px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobGroup_47_fp {
		position: absolute;
		width: 341px;
		height: 118.811px;
		left: 3px;
		top: 83.189px;
		overflow: visible;
	}
	#petpaw_12_mobKenned_Dsouza_fq {
		left: 116.519px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(248,248,248,1);
	}
	#petpaw_12_mobScroll_Group_32 {
		mix-blend-mode: normal;
		position: absolute;
		width: 341px;
		height: 92px;
		left: 0px;
		top: 26.811px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#petpaw_12_mobScroll_Group_32::-webkit-scrollbar{
		display: none;
	}
	#petpaw_12_mobn_I_like_that_I_got_to_meet_th_fs {
		opacity: 0.8;
		left: 18px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 309px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(248,248,248,1);
	}
	#petpaw_12_mobdownload_2_ft {
		position: absolute;
		width: 271px;
		height: 64px;
		left: 35px;
		top: 13px;
		overflow: visible;
	}
	#petpaw_12_mobHeres_what_We_Provides {
		left: 24px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 303px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(255,103,82,1);
	}
	#petpaw_12_mobScroll_Group_33 {
		mix-blend-mode: normal;
		position: absolute;
		width: 370px;
		height: 55px;
		left: 0px;
		top: 2635px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#petpaw_12_mobScroll_Group_33::-webkit-scrollbar{
		display: none;
	}
	#petpaw_12_mobpartner_grp_website_6_pc {
		position: absolute;
		width: 222px;
		height: 30px;
		left: 14px;
		top: 9px;
		overflow: visible;
	}
	#petpaw_12_mobAnime_Edit_img_website_6_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobMohammed_Affan_lbl_website_6_p {
		left: 35px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 188px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,0.89);
	}
	#petpaw_12_mobAmazing_Partner {
		left: 121.111px;
		top: 2580.4px;
		position: absolute;
		overflow: visible;
		width: 135px;
		white-space: nowrap;
		text-align: center;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(177,187,205,1);
	}
	#petpaw_12_mobGroup_14909 {
		position: absolute;
		width: 2323.922px;
		height: 1100.994px;
		left: -951.461px;
		top: 2771.1px;
		overflow: visible;
	}
	#petpaw_12_mobEllipse_19 {
		fill: rgba(255,103,82,1);
	}
	.petpaw_12_mobEllipse_19 {
		position: absolute;
		overflow: visible;
		width: 2323.922px;
		height: 1100.994px;
		left: 0px;
		top: 0px;
	}
	#petpaw_12_mobGroup_14908 {
		position: absolute;
		width: 479px;
		height: 323px;
		left: 967.461px;
		top: 303.9px;
		overflow: visible;
	}
	#petpaw_12_mobn_234567890_mobile_number_shad {
		left: 35px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#petpaw_12_mobaffanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#petpaw_12_mobaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#petpaw_12_moblocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#petpaw_12_mobemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#petpaw_12_mobphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 79px;
		top: 191px;
		overflow: visible;
	}
	#petpaw_12_moblinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.petpaw_12_mobmed_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#petpaw_12_mobmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.petpaw_12_mobmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#petpaw_12_moblinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#petpaw_12_mobn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.petpaw_12_mobn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_moblinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.petpaw_12_moblinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.petpaw_12_mobtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#petpaw_12_mobInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.petpaw_12_mobshadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#petpaw_12_mobshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.petpaw_12_mobshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#petpaw_12_mobinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#petpaw_12_mobwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#petpaw_12_mobmed_shadow_space_website_7_gm:hover {
		fill: rgba(50,172,158,1);
	}
	.petpaw_12_mobmed_shadow_space_website_7_gm:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#petpaw_12_mobmed_shadow_space_website_7_gm {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.petpaw_12_mobmed_shadow_space_website_7_gm {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#petpaw_12_mobwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#petpaw_12_mobn_Copyright_Operate_Live_OPC_P {
		left: 15px;
		top: 279px;
		position: absolute;
		overflow: visible;
		width: 293px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#petpaw_12_mobRectangle_693 {
		fill: rgba(255,255,255,1);
	}
	.petpaw_12_mobRectangle_693 {
		position: absolute;
		overflow: visible;
		width: 266px;
		height: 175px;
		left: 1006.461px;
		top: 65.9px;
		border-radius: 10px;
	}
	#petpaw_12_mobn_a21e555c63e24831805521512170 {
		position: absolute;
		width: 85px;
		height: 98px;
		left: 967.461px;
		top: 191.9px;
		overflow: visible;
	}
	#petpaw_12_mobVisit_Us_ {
		left: 1091.461px;
		top: 27.9px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -3px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}