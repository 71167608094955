#down_off_grp_overall {
    position: absolute;
    width: 100%;
    height: 66.5px;
    left: 0px;
    bottom: 0px;
    overflow: visible;
}

#my_requirment_btn_com_req_mob {
    position: absolute;
    width: 29px;
    height: 40px;
    left: 16px;
    top: 11.5px;
    overflow: visible;
    border-radius: 50px;
}
#req_btn_rec_com_req_mob {
    fill: rgba(255,255,255,1);
}
.req_btn_rec_com_req_mob {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 47px;
    height: 58px;
    left: 0px;
    top: 0px;
}
#plus_img_btn_com_req_mob {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 4px;
    top: 10px;
    overflow: visible;
}
#com_req_mob_down_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 100px);
    height: 66px;
    left: 54px;
    top: 0.5px;
    overflow-x: auto;
    overflow-y: hidden;
}
#req_card_com_req_mob {
    position: relative;
    width: 180px;
    height: 49px;
    left: 0px;
    top: 7px;
    overflow: visible;
}
#bdr_com_req_mob_bj {
    fill: url(#bdr_com_req_mob_bj);
}
.bdr_com_req_mob_bj {
    filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 52px;
    left: 0px;
    top: 0px;
}
#pfp_pic_com_req_mob {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 4px;
    top: 4px;
    overflow: visible;
}
#software_developer_lbl_com_req {
    left: 46.594px;
    top: 6.5px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#inr_and_date_com_req_mob {
    left: 46.594px;
    top: 26px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#minus_2_close_com_req_mob {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 153px;
    top: 0px;
    overflow: visible;
}
#req_card_com_req_mob_1 {
    position: absolute;
    width: 180px;
    height: 49px;
    left: 187px;
    top: 7px;
    overflow: visible;
}
#bdr_com_req_mob1_bq {
    fill: url(#bdr_com_req_mob1_bq);
}
.bdr_com_req_mob1_bq {
    filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 52px;
    left: 0px;
    top: 0px;
}
#pfp_pic_com_req_mob_1 {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 4px;
    top: 4px;
    overflow: visible;
}
#Mohammed_Zaid_lbl_com_req_mob_ {
    left: 46.594px;
    top: 6.5px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#inr_and_date_com_req_mob_1 {
    left: 46.594px;
    top: 26px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#minus_2_close_com_req_mob_1 {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 153px;
    top: 0px;
    overflow: visible;
}
#up-arrow_for_bottom {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    top: 16.5px;
    overflow: visible;
}