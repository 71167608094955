	.mediaViewInfo {
		--web-view-name: view page req mob;
		--web-view-id: view_page_req_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: view_page_req_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#view_page_req_mob {
		position: absolute;
		width: 100vw;
		height: calc(100vh - 215px);
        top: 75px;
		background-color: rgba(255,255,255,1);
        overflow-x: hidden;
        overflow-y: auto;
		--web-view-name: view page req mob;
		--web-view-id: view_page_req_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#view_page_req_mob::-webkit-scrollbar {
		display: none;
	}
	#previous_back_btn_back_page_vi {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 15px;
		top: 0px;
		overflow: visible;
	}
	#overall_grp_with_detail_and_bt {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 32px;
		overflow: visible;
	}
	#Scroll_cars_preview__mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 109px;
		left: 0px;
		top: 361px;
		display: flex;
		flex-wrap: wrap;
        flex-direction: column;
		align-content: flex-start;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#Scroll_cars_preview__mob::-webkit-scrollbar {
		display: none;
	}
	#preview_1_view_page_mob {
		position: relative;
		width: 135px;
		height: 85px;
		left: 18px;
		top: 9px;
		overflow: visible;
	}
	#img_preview_1_view_page__mob {
		fill: rgba(245,245,246,1);
	}
	.img_preview_1_view_page__mob {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 153px;
		height: 103px;
		left: 0px;
		top: 0px;
	}
	#Image_preview_1_view_page_mob {
		position: absolute;
		width: 104px;
		height: 79.879px;
		left: 16px;
		top: 3.072px;
		overflow: visible;
	}
	#Nike_Air_Force_req_mob_view_page {
		left: 16px;
		top: 483px;
		position: absolute;
		overflow: visible;
		width: 338px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(17,17,17,1);
	}
	#trophy_view_page_mob {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 18px;
		top: 521px;
		overflow: visible;
	}
	#n_234_count_view_page_mob {
		left: 52px;
		top: 523px;
		position: absolute;
		overflow: visible;
		width: 93px;
		height: 20px;
		line-height: 22px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
	}
	#line_before_budget_view_page_m {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 3;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_before_budget_view_page_m {
		overflow: visible;
		position: absolute;
		width: 346.003px;
		height: 2px;
		left: 15.5px;
		top: 553.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Budget_Delivery_Deadline_Deliv_mob {
		left: 16px;
		top: 568px;
		position: absolute;
		overflow: visible;
		width: 117px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Afs_10000_mob_details_info_view {
		left: 152px;
		top: 566px;
		position: absolute;
		overflow: visible;
		width: 202px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#display_img_mob_with_edit_shar {
		position: absolute;
		width: 325px;
		height: 353px;
		left: 25px;
		top: 0px;
		overflow: visible;
	}
	#img_2_big_view_page_mob_bdr {
		fill: rgba(245,245,246,1);
	}
	.img_2_big_view_page_mob_bdr {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 343px;
		height: 371px;
		left: 0px;
		top: 0px;
	}
	#Image_2_big_view_page_mob {
		position: absolute;
		width: 320px;
		height: 313px;
		left: 3px;
		top: 20px;
		overflow: visible;
	}
	#share_btn_view_page_mob {
		position: absolute;
		width: 19px;
		height: 19px;
		left: 298px;
		top: 10px;
		overflow: visible;
	}
	#edit_req_btn_view_page_mob {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 258px;
		top: 7px;
		overflow: visible;
	}
	#Scroll_description__mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 195px;
		left: 0px;
		top: 657.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#description_of_title_view_page {
		/* left: 18px; */
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		margin: 5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#line_below_location_view_page_ {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 3;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_below_location_view_page_ {
		overflow: visible;
		position: absolute;
		width: 346px;
		height: 1px;
		left: 15.5px;
		top: 637.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Button_bargain_view_page_mob {
		position: absolute;
		width: 140px;
		height: 45px;
		left: 23px;
		top: 890px;
		overflow: visible;
		border-radius: 5px;
	}
	#bargain_bdr_view_page_mob {
		fill: rgba(248,90,71,1);
	}
	.bargain_bdr_view_page_mob {
		filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 140px;
		height: 45px;
		left: 0px;
		top: 0px;
	}
	#bargain_view_page__mob {
		left: 57px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#Button_disable_view_page_mob {
		position: absolute;
		width: 140px;
		height: 45px;
		left: 208px;
		top: 890px;
		overflow: visible;
		border-radius: 5px;
	}
	#disable_bdr_view_page_mob {
		fill: rgba(248,90,71,1);
	}
	.disable_bdr_view_page_mob {
		filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 140px;
		height: 45px;
		left: 0px;
		top: 0px;
	}
	#disable_view_page_mob {
		left: 46px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#Scroll_timeline_view_page__mob_req {
	mix-blend-mode: normal;
    position: relative;
    width: 100%;
    height: 146px;
    left: 0px;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
	}
	#timeline_grp_view_page_mob_req {
		position: relative;
		width: 147px;
		height: 86px;
		left: 12px;
		top: 24px;
		overflow: visible;
	}
	#behind_gren_line__view_page_mo {
		fill: rgba(255,255,255,1);
	}
	.behind_gren_line__view_page_mo {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 118px;
		height: 31px;
		left: 47px;
		top: 36px;
	}
	#bg_circle_green__view_page_mob {
		fill: rgba(255,255,255,1);
	}
	.bg_circle_green__view_page_mob {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 20px;
		top: 30px;
	}
	#first_circ__view_page_mob {
		fill: rgba(168,248,145,1);
	}
	.first_circ__view_page_mob {
		position: absolute;
		overflow: visible;
		width: 10px;
		height: 10px;
		left: 26px;
		top: 36px;
	}
	#first_green__view_page_mob {
		fill: rgba(168,248,145,1);
	}
	.first_green__view_page_mob {
		position: absolute;
		overflow: visible;
		width: 90px;
		height: 6px;
		left: 53px;
		top: 39px;
	}
	#Bargained__view_page_mob {
		left: 13px;
		top: 69px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#n_4__12__view_page_mob {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 87px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#trophie_detail_view_page__mob {
		position: absolute;
		width: 100%;
		height: 76px;
		left: 16px;
		top: 1210px;
		display: flex;
		flex-wrap: wrap;
		overflow: visible;
	}
	#card_1_trohpie_view_page_mob_r {
		position: relative;
		width: 185px;
		height: 35px;
		left: 0px;
		top: 41px;
		overflow: visible;
	}
	#img_who_liked_view_page_mob_re {
		position: absolute;
		width: 35px;
		height: 35px;
		left: 0px;
		top: 0px;
		border-radius: 50%;
		overflow: hidden;
	}
	#Bargained_view_page_mob_req {
		left: 40px;
		top: 8px;
		position: relative;
		overflow: visible;
		width: 170px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Trophies_Awarded_By_view_page_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 150px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}


