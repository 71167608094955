	.mediaViewInfo {
		--web-view-name: KnowledgeKorner mob;
		--web-view-id: KnowledgeKorner_mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: KnowledgeKorner_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#n__card_grp_website_6_mob { 
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 3px;
    margin-top: 5px;
}
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 7px); }
    100%   { transform: translate(0, -0px); }   
}
	body::-webkit-scrollbar{
		display: none;
	}
#Scroll_vis_mob_web_6::-webkit-scrollbar{
		display: none;
	}
#Scroll_princi_mob_web_6::-webkit-scrollbar{
		display: none;
	}
#Scroll_vis_mis_and_prin::-webkit-scrollbar{
		display: none
	}
#Scroll_services_website_6_mob::-webkit-scrollbar{
		display: none;
	}
#Scroll_mis_mob_web_6::-webkit-scrollbar{
		display: none;
	}
	#KnowledgeKorner_mob {
		position: absolute;
		width: 375px;
		height: 2515px;
        left:calc((100vw - 375px)/2);
		background-color: rgba(246,246,246,1);
		overflow: hidden;
		--web-view-name: KnowledgeKorner mob;
		--web-view-id: KnowledgeKorner_mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#circle_4_website_6_mob {
		fill: rgba(92,35,255,1);
	}
	.circle_4_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 30px;
		height: 30px;
		left: 261px;
		top: 1352px;
	}
	#circle_3_website_6_mob {
		fill: rgba(215,232,188,1);
	}
	.circle_3_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 30px;
		height: 30px;
		left: 14px;
		top: 1050px;
	}
	#circel_2_website_6_mob {
		fill: rgba(166,210,202,1);
	}
	.circel_2_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 30px;
		height: 30px;
		left: 314px;
		top: 753px;
	}
	#header_overall_grp_mob_web_6_m {
		position: absolute;
		width: 382.086px;
		height: 261.999px;
		left: -2px;
		top: 0px;
		overflow: visible;
	}
	#shadow_grass_website_6_mob {
		opacity: 0.32;
		fill: rgba(0,154,23,1);
	}
	.shadow_grass_website_6_mob {
		overflow: visible;
		position: absolute;
		width: 379.533px;
		height: 16.357px;
		left: 2.553px;
		top: 197.676px;
		transform: matrix(1,0,0,1,0,0);
	}
	#sky_mob_website_6_mob {
		fill: rgba(135,206,235,1);
	}
	.sky_mob_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 379px;
		height: 198px;
		left: 0px;
		top: 0px;
	}
	#Path_449_grass_website_6_mob {
		fill: rgba(0,154,23,1);
	}
	.Path_449_grass_website_6_mob {
		overflow: visible;
		position: absolute;
		width: 379.533px;
		height: 16.357px;
		left: 2px;
		top: 186.898px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Anime_Edit_213_website_6_mob {
		position: absolute;
		width: 28px;
		height: 27px;
		left: 176px;
		top: 5px;
        border-radius: 50%;
		overflow: hidden;
	}
	#LETS_LEARN_AND_GAIN_NEW__KNOWL {
		left: 75.086px;
		top: 40px;
		position: absolute;
		overflow: visible;
		width: 227px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,0.89);
	}
	#Lets_discover_new_knowledge_an {
		left: 104.086px;
		top: 98px;
		position: absolute;
		overflow: visible;
		width: 155px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 6px;
		color: rgba(0,0,0,0.89);
	}
	#n__card_grp_website_6_mob {
		position: absolute;
		width: 282px;
		height: 139.999px;
		left: 49px;
		top: 122px;
		overflow: visible;
	}
	#shadow_grp_img_website_6_mob {
		position: absolute;
		width: 282px;
		height: 33px;
		left: 0px;
		top: 107px;
		overflow: visible;
	}
	#image_4_shadow_website_6_mob {
		filter: blur(4px);
		fill: rgba(212,212,212,1);
	}
	.image_4_shadow_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 86px;
		height: 28px;
		left: 0px;
		top: 0px;
	}
	#image_3_shadow_website_6_mob {
		filter: blur(4px);
		fill: rgba(212,212,212,1);
	}
	.image_3_shadow_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 87px;
		height: 28px;
		left: 71px;
		top: 29px;
	}
	#image_2_shadow_website_6_mob {
		filter: blur(4px);
		fill: rgba(212,212,212,1);
	}
	.image_2_shadow_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 86px;
		height: 28px;
		left: 148px;
		top: 29px;
	}
	#image_1_shadow_website_6_mob {
		filter: blur(4px);
		fill: rgba(212,212,212,1);
	}
	.image_1_shadow_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 87px;
		height: 27px;
		left: 219px;
		top: 2px;
	}
	#img_4_website_6_mob {
		position: absolute;
		width: 63.273px;
		height: 103.348px;
		left: 3.001px;
		top: 0px;
		overflow: visible;
	}
	#img_3_website_6_mob {
		position: absolute;
		width: 63.273px;
		height: 103.348px;
		left: 72.293px;
		top: 19.723px;
		overflow: visible;
	}
	#img_2_website_6_mob {
		position: absolute;
		width: 63.273px;
		height: 103.348px;
		left: 146.396px;
		top: 19.723px;
		overflow: visible;
	}
	#img_1_website_6_mob {
		position: absolute;
		width: 63.273px;
		height: 103.348px;
		left: 214.725px;
		top: 0px;
		overflow: visible;
	}
	#Icon_awesome-cloud_6_website_6_mob_cloud {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_6_website_6_mob_cloud {
		overflow: visible;
		position: absolute;
		width: 22.109px;
		height: 15.477px;
		left: 22.751px;
		top: 46.152px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_5_website_6_mob_cloud {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_5_website_6_mob_cloud {
		overflow: visible;
		position: absolute;
		width: 17.134px;
		height: 11.994px;
		left: 309.613px;
		top: 67.984px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_4_website_6_mob_cloud {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_4_website_6_mob_cloud {
		overflow: visible;
		position: absolute;
		width: 22.109px;
		height: 15.477px;
		left: 74.707px;
		top: 97.555px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_3_website_6_mob_cloud {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_3_website_6_mob_cloud {
		overflow: visible;
		position: absolute;
		width: 22.109px;
		height: 15.477px;
		left: 340.565px;
		top: 128.508px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_2_website_6_mob_cloud {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_2_website_6_mob_cloud {
		overflow: visible;
		position: absolute;
		width: 22.109px;
		height: 15.477px;
		left: 249.919px;
		top: 24.596px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_awesome-cloud_1_website_6_mob_cloud {
		fill: rgba(255,255,255,1);
	}
	.Icon_awesome-cloud_1_website_6_mob_cloud {
		overflow: visible;
		position: absolute;
		width: 22.109px;
		height: 15.477px;
		left: 145.178px;
		top: 24.596px;
		transform: matrix(1,0,0,1,0,0);
	}
	#sheep_3_website_6_mob {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 35.086px;
		top: 182px;
		overflow: visible;
	}
	#sheep_2_website_6_mob {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 25.086px;
		top: 185px;
		overflow: visible;
	}
	#sheep_1_website_6_mob {
		position: absolute;
		width: 16px;
		height: 16px;
		left: 336.086px;
		top: 182px;
		overflow: visible;
	}
	#dove_3_website_6_mob {
		position: absolute;
		width: 11px;
		height: 11px;
		left: 307.086px;
		top: 32px;
		overflow: visible;
	}
	#dove_2_website_6_mob {
		position: absolute;
		width: 11px;
		height: 11px;
		left: 317.086px;
		top: 36px;
		overflow: visible;
	}
	#dove_1_website_6_mob {
		position: absolute;
		width: 11px;
		height: 11px;
		left: 317.086px;
		top: 24px;
		overflow: visible;
	}
	#Scroll_partners_web_6_mov {
		mix-blend-mode: normal;
		position: absolute;
		width: 360px;
		height: 48px;
		left: 5px;
		top: 339px;
        display: inline-flex;
		overflow-x: auto;
		overflow-y: hidden;
	}
    #Scroll_partners_web_6_mov::-webkit-scrollbar {
        display: none;
    }
	#partner_card_1_grp_website_6_m {
		position: relative;
		width: 211px;
		height: 30px;
		left: 9px;
		top: 7px;
		overflow: visible;
	}
	#Anime_Edit_img_website_6_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Mohammed_Affan_website_6_mob {
		left: 35px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_services_website_6_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 360px;
		height: 287px;
		left: 0px;
		top: 763px;
		display: -webkit-box;
		overflow-x: auto;
		overflow-y: hidden;
	}
    #Scroll_services_website_6_mob::-webkit-scrollbar {
        display: none;
    }
	#my_services_card_1_website_6_m {
		position: relative;
		width: 174px;
		height: 242px;
		left: 14px;
		top: 22px;
        margin: 10px;
		overflow: visible;
	}
	#services_card_bdr_website_6_mo {
		fill: rgba(255,255,255,1);
	}
	.services_card_bdr_website_6_mo {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 192px;
		height: 260px;
		left: 0px;
		top: 0px;
	}
	#img_offering_website_6_mob {
		position: absolute;
		width: 150px;
		height: 198px;
		left: 12px;
		top: 34px;
		overflow: visible;
	}
	#circle_deign_shadow_space_webs {
		fill: rgba(23,18,18,1);
	}
	.circle_deign_shadow_space_webs {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 12px;
		top: 16px;
	}
	#My_Offerings_lbl_website_6_mob {
		left: 23px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 140px;
		height: 17px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,0.89);
	}
	#circle_1_website_6_mob {
		fill: rgba(255,168,168,1);
	}
	.circle_1_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 20px;
		height: 20px;
		left: 314px;
		top: 256px;
	}
	#Trusted_by_our_partners_lbl_we {
		left: 77px;
		top: 279px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_vis_mis_and_prin {
		mix-blend-mode: normal;
		position: absolute;
		width: 355px;
		height: 298px;
		left: 5px;
		top: 396px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#vis_mis_and_prin_grp_website_6 {
		position: absolute;
		width: 562px;
		height: 242px;
		left: 9px;
		top: 30px;
		overflow: hidden;
	}
	#princi_grp_website_6_mob {
		position: absolute;
		width: 174px;
		height: 242px;
		left: 388px;
		top: 0px;
		overflow: visible;
	}
	#prini_overall_card_bdr_website {
		fill: rgba(255,255,255,1);
	}
	.prini_overall_card_bdr_website {
		filter: drop-shadow(0px 3px 6px rgba(135, 207, 172, 0.647));
		position: absolute;
		overflow: visible;
		width: 192px;
		height: 260px;
		left: 0px;
		top: 0px;
	}
	#princi_bdr_website_6_mob {
		fill: rgba(166,210,202,1);
	}
	.princi_bdr_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 38px;
		height: 38px;
		left: 5px;
		top: 5px;
	}
	#Principles_lbl_website_6_mob {
		left: 2px;
		top: 48px;
		position: absolute;
		overflow: visible;
		width: 61px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_princi_mob_web_6 {
		mix-blend-mode: normal;
		position: absolute;
		width: 174px;
		height: 160px;
		left: 0px;
		top: 69px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Kono_sekai_wa_itami_o_shirudar {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,0.89);
	}
	#leadership_2_knowledge_webs_mob {
		position: absolute;
		width: 29.786px;
		height: 29.785px;
		left: 9.169px;
		top: 8.053px;
		overflow: visible;
	}
	#vision_grp_website_6_mob {
		position: absolute;
		width: 173px;
		height: 242px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#overall_card_bdr_website_6_mob {
		fill: rgba(255,255,255,1);
	}
	.overall_card_bdr_website_6_mob {
		filter: drop-shadow(0px 3px 6px rgba(241, 144, 144, 1));
		position: absolute;
		overflow: visible;
		width: 191px;
		height: 260px;
		left: 0px;
		top: 0px;
	}
	#img_vision_bdr_website_6_mob {
		fill: rgba(241,144,144,1);
	}
	.img_vision_bdr_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 38px;
		height: 38px;
		left: 5px;
		top: 5px;
	}
	#Vision_website_6_mob {
		left: 5px;
		top: 48px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_vis_mob_web_6 {
		mix-blend-mode: normal;
		position: absolute;
		width: 173px;
		height: 160px;
		left: 0px;
		top: 69px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Zaido_wa_gisei_ni_natte_korosa {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,0.89);
	}
	#target_1_kk_website_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 9px;
		top: 9px;
		overflow: visible;
	}
	#mission_grp_website_6_mob {
		position: absolute;
		width: 174px;
		height: 242px;
		left: 194px;
		top: 0px;
		overflow: visible;
	}
	#whole_card_bdr_website_6_mob {
		fill: rgba(255,255,255,1);
	}
	.whole_card_bdr_website_6_mob {
		filter: drop-shadow(0px 3px 6px rgba(197, 196, 218, 1));
		position: absolute;
		overflow: visible;
		width: 192px;
		height: 260px;
		left: 0px;
		top: 0px;
	}
	#mision_bdr_website_6_mob {
		fill: rgba(167,166,210,1);
	}
	.mision_bdr_website_6_mob {
		position: absolute;
		overflow: visible;
		width: 37px;
		height: 37px;
		left: 5px;
		top: 5px;
	}
	#Mission_website_6_mob {
		left: 5px;
		top: 47px;
		position: absolute;
		overflow: visible;
		width: 48px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_mis_mob_web_6 {
		mix-blend-mode: normal;
		position: absolute;
		width: 174px;
		height: 166px;
		left: 0px;
		top: 69px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Watashitachi_no_dry_no_zaido_o {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,0.89);
	}
	#principle_2_k_k_websi_mob {
		position: absolute;
		width: 31px;
		height: 31px;
		left: 8px;
		top: 8px;
		overflow: visible;
	}
	#Our_Services_lbl_website_6_mob {
		left: 143px;
		top: 718px;
		position: absolute;
		overflow: visible;
		width: 119px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,0.89);
	}
	#Scroll_event_grp_mob {
		mix-blend-mode: normal;
		position: absolute;
		width: 375.5px;
		height: 568px;
		left: 0px;
		top: 1131px;
        display: -webkit-box;
		overflow-x: auto;
		overflow-y: hidden;
	}
    #Scroll_event_grp_mob::-webkit-scrollbar {
        display: none;
    }
	#event_grp__website_k_k_mob {
		position: relative;
		width: 287px;
		height: 526px;
		left: 9px;
		top: 24px;
        margin: 10px;
		overflow: visible;
	}
	#event_bg_card_1_k_k_space_mob {
		fill: rgba(255,255,255,1);
	}
	.event_bg_card_1_k_k_space_mob {
		filter: drop-shadow(8px 8px 5px rgba(166, 210, 202, 1));
		width: 442px;
		height: 286px;
		position: absolute;
		overflow: visible;
		transform: translate(-43px, -1974px) matrix(1,0,0,1,-35,2117) rotate(90deg);
		transform-origin: center;
	}
	#Anime_Edit_k_k_websit_mob {
		position: absolute;
		width: 230px;
		height: 210px;
		left: 28px;
		top: 0px;
		overflow: visible;
	}
	#oofering_lbl_k_k_websi_mob {
		left: 0px;
		top: 217px;
		position: absolute;
		overflow: visible;
		width: 287px;
		height: 22px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Scrolldesc_K_K_websit_mob {
	mix-blend-mode: normal;
    position: absolute;
    width: 272px;
    height: 135px;
    left: 7px;
    top: 368px;
    overflow: hidden;
}
	#oofering_lbl_k_k_webs_mob {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#all_details_small_web_6__mob {
		position: absolute;
		width: 259px;
		height: 99px;
		left: 28px;
		top: 252px;
		overflow: visible;
	}
	#dateshadow_space_website_6_mob {
		left: 29px;
		top: 44px;
		position: absolute;
		overflow: visible;
		width: 91px;
		height: 17px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#calendar_2_k_k_websit_mob {
		position: absolute;
		width: 24px;
		height: 24px;
		left: 0px;
		top: 41px;
		overflow: visible;
	}
	#date_k_k_website_6_mob {
		left: 142px;
		top: 44px;
		position: absolute;
		overflow: visible;
		width: 118px;
		height: 17px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#clock_1_shadow_space_website_6_k_k_mob {
		position: absolute;
		width: 24px;
		height: 24px;
		left: 114px;
		top: 41px;
		overflow: visible;
	}
	#man_2_shadow_space_website_6_m_k_k_mob {
		position: absolute;
		width: 24px;
		height: 24px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#date_shadow_space_website_6_mo_eh_k_k_mob {
		left: 29px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 179px;
		height: 17px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#seats_shadow_space_website_6_m_k_k_mob {
		position: absolute;
		width: 24px;
		height: 24px;
		left: 0px;
		top: 75px;
		overflow: visible;
	}
	#date_shadow_space_website_6_mo_ej_k_k_mob {
		left: 35px;
		top: 78px;
		position: absolute;
		overflow: visible;
		width: 94px;
		height: 17px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#price-tag_1_shadow_space_websi_k_k_mob {
		position: absolute;
		width: 24px;
		height: 24px;
		left: 114px;
		top: 75px;
		overflow: visible;
	}
	#date_shadow_space_website_6_mo_el_k_k_mob {
		left: 142px;
		top: 78px;
		position: absolute;
		overflow: visible;
		width: 85px;
		height: 17px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#oofering_lbl_med_kk_mob {
		left: 140px;
		top: 1077px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#footer_grp_website_6_mob {
		position: absolute;
		width: 380px;
		height: 755px;
		left: 0px;
		top: 1758px;
		overflow: visible;
	}
	#n_3197677_SL-030720-28740-05 {
		position: absolute;
		width: 380px;
		height: 755px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_maps_mob_kk {
		position: absolute;
		width: 323.11px;
		height: 195.998px;
		left: 28px;
		top: 23px;
		overflow: visible;
	}
	#Path_448_mob_kk {
		fill: rgba(0,0,0,1);
	}
	.Path_448_mob_kk {
		overflow: visible;
		position: absolute;
		width: 323.11px;
		height: 195.998px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_map_location_mob {
		fill: rgba(255,255,255,1);
	}
	.Path_map_location_mob {
		overflow: visible;
		position: absolute;
		width: 313.597px;
		height: 173.951px;
		left: 4.943px;
		top: 4.992px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_k_k_mobile_number_shad_mob {
		left: 56px;
		top: 287px;
		position: absolute;
		overflow: visible;
		width: 83px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#k_k_email_mob_ {
		left: 56px;
		top: 336px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#address_med_k_k_websi_mob {
		left: 56px;
		top: 385px;
		position: absolute;
		overflow: visible;
		width: 309px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#location_img_shadow_space_webs_k_k_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 21px;
		top: 385px;
		overflow: visible;
	}
	#email_address_shadow_space_web_k_k_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 21px;
		top: 335px;
		overflow: visible;
	}
	#phone_number_shadow_space_webs_k_k_mob {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 21px;
		top: 280px;
		overflow: visible;
	}
	#Connect_with_us_and_unlock_lim_k_k_mob {
		left: 56px;
		top: 499px;
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 63px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#quote_left_med_shadow_space_we_k_k_mob {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 21px;
		top: 499px;
		overflow: visible;
	}
	#quote_right_med_shadow_space_w_k_k_mob {
		position: absolute;
		width: 25px;
		height: 25px;
		right: 21px;
		top: 541px;
		overflow: visible;
	}
	#socials_grp_shadow_space_websi_k_k_mob {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 94px;
		top: 613px;
		overflow: visible;
	}
	#linked_in_grp_shadow_space_web_k_k_mob {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_5_med_shadow_space_web_k_k_mob:hover {
		fill: rgba(120,120,255,1);
	}
	.Ellipse_5_med_shadow_space_web_k_k_mob:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_5_med_shadow_space_web_k_k_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_5_med_shadow_space_web_k_k_mob {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#linked_in_shadow_space_website_k_k_mob {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.191px;
		overflow: visible;
	}
	#n_website_mob_5layout__k_k_mob {
		fill: rgba(63,61,86,1);
	}
	.n_website_mob_5layout__k_k_mob {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_shadow_space_websi_k_k_mob {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_shadow_space_websi_k_k_mob {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_shadow_space_website_k_k_mob {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_shadow_space_website_k_k_mob {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Insta_grp_shadow_space_website_k_k_mob {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_4_shadow_space_website_k_k_mob:hover {
		fill: rgba(255,120,120,1);
	}
	.Ellipse_4_shadow_space_website_k_k_mob:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_4_shadow_space_website_k_k_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_4_shadow_space_website_k_k_mob {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#instagramshadow_space_website__k_k_mob {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#whatsapp_grp_shadow_space_webs_k_k_mob {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Ellipse_3_med_shadow_space_web_k_k_mob:hover {
		fill: rgba(50,172,158,1);
	}
	.Ellipse_3_med_shadow_space_web_k_k_mob:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Ellipse_3_med_shadow_space_web_k_k_mob {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Ellipse_3_med_shadow_space_web_k_k_mob {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#whatsapp_lbl_shadow_space_webs_k_k_mob {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#n_Copyright_Operate_Live_med_s_k_k_mob {
		left: 21px;
		top: 696px;
		position: absolute;
		overflow: visible;
		width: 318px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}


    /* Menu */
  #menuToggle
  {
  display: block;
  position: fixed;
  width: 40px;
  height: 40px;
  left: 34px;
  top: 25px;
  
  z-index: 1;
  
  -webkit-user-select: none;
  user-select: none;
  }
  
  #menuToggle li
  {
  text-decoration: none;
  color: #232323;
  
  transition: color 0.3s ease;
  }
  
  #menuToggle li:hover
  {
  color: #ff4f4f;
  }
  
  
  #menuToggle input
  {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
  }
  
  /*
  * Just a quick hamburger
  */
  #menuToggle span
  {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: #6495ED;
  border-radius: 3px;
  
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  background: 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  opacity: 0.55s ease;
  }
  
  #menuToggle span:first-child
  {
  transform-origin: 0% 0%;
  }
  
  #menuToggle span:nth-last-child(2)
  {
  transform-origin: 0% 100%;
  }
  
  /* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
  #menuToggle input:checked ~ span
  {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: rgba(255,75,83,1);
  }
  
  /*
  * But let's hide the middle one.
  */
  #menuToggle input:checked ~ span:nth-last-child(3)
  {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
  }
  
  /*
  * Ohyeah and the last one should go the other direction
  */
  #menuToggle input:checked ~ span:nth-last-child(2)
  {
  transform: rotate(-45deg) translate(0, -1px);
  }
  
  /*
  * Make this absolute positioned
  * at the top left of the screen
  */
  #menu
  {
  position: absolute;
  width: 230px;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  height: 100vh;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 10px );
  -webkit-backdrop-filter: blur( 10px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  }
  
  #menu li
  {
  padding: 10px 0;
  font-size: 22px;
  }
  #menuToggle input:checked ~ ul
  {
  transform: none;
  }
  