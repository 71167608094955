#scroll_and_plus_btn_req_com {
    position: absolute;
    width: calc(100vw - 136px);
    height: 70px;
    left: 120px;
    top: 60px; 
    overflow: visible;
}
#Scroll_req_page {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 26px);
    height: 70px;
    left: 0px;
    top: 0px;
    overflow-x: auto;
    overflow-y: hidden;
}
#com_top_com_req {
    position: relative;
    width: 231px;
    height: 58px;
    left: 11px;
    top: 0px;
    cursor: pointer;
    overflow: visible;
    border-radius: 50px;
}


.com_top_com_req_glow {
    background: linear-gradient(145deg, rgb(48, 249, 17),#16cb34); 
    background-position: 400%;
    animation: animatecolor 8s linear ; 
    color: #fff;
}
  
  @keyframes animatecolor {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 400%;
    }
  }

@keyframes backInLeft {
    0% {
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
    }
    
    80% {
    transform: translateX(0px) scale(0.7);
    opacity: 0.7;
    }
    
    100% {
    transform: scale(1);
    opacity: 1;
    }
    }
    
    .backInLeft {
    animation: backInLeft 1s;
    background-color: #b2fbb297;
    }
    


#bdr_community_req_pge_ {
    fill: url(#bdr_community_req_pge_);
}
.bdr_community_req_pge_ {
    filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 249px;
    height: 76px;
    left: 0px;
    top: 0px;
    animation: animatecolor 8s linear infinite; 
}
#photo_community_req_page {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 4px;
    top: 4px;
    border-radius: 50%;
    overflow: hidden;
}
#_page {
    left: 64.237px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 137px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}

#Mohammed_Zaid_lbl_req_page {
    left: 64.237px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 137px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#com_btn_top_right_req {
    position: absolute;
    width: 26px;
    height: 58px;
    right: 0px;
    top: 0px;
    cursor: pointer;
    overflow: visible;
}
#plus_btn_bdr {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 0.5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.plus_btn_bdr {
    filter: drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 32.5px;
    height: 64.5px;
    left: 0px;
    top: 0px;
}
#plus_img_btn {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 3px;
    top: 19px;
    overflow: visible;
}