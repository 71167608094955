
	.mediaViewInfo {
		--web-view-name: theme small;
		--web-view-id: theme_small;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: theme_small;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#theme_small {
		position: absolute;
		width: 1118px;
		height: 739px;
        border-radius: 15px;
 top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    background-color: rgb(247 255 251);
		overflow: hidden;
		--web-view-name: theme small;
		--web-view-id: theme_small;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#select_btn_grp_website_1_op_li {
		position: absolute;
		width: 149px;
		height: 54px;
		left: 485px;
		top: 645px;
		overflow: visible;
	}
	#select_btn_website_1_op_live_t {
		fill: rgba(143,233,191,1);
	}
	.select_btn_website_1_op_live_t {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 167px;
		height: 72px;
		left: 0px;
		top: 0px;
	}
	#select_website_1_op_live_theme {
		left: 47px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 56px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Scroll_all_themes_scroll_for_w {
		mix-blend-mode: normal;
		position: absolute;
		width: 1118px;
		height: 556px;
		left: 0px;
		top: 67px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#enchnting_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 177px;
		left: 20px;
		top: 47px;
		overflow: visible;
	}
	#layout_1_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_1_small_theme {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_1_small_theme {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 159px;
	}
	#Enchanting_Emerald_small_theme {
		left: 71px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_1_mob_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#design_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 177px;
		left: 399px;
		top: 47px;
		overflow: visible;
	}
	#website_2_layout_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_2_small_theme {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_2_small_theme {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 60px;
		top: 159px;
	}
	#Flamingo_Fiesta_lbl_small_them {
		left: 85px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_2_mob_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#ocean_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 177px;
		left: 778px;
		top: 47px;
		overflow: visible;
	}
	#website_3_layout_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_3_small_theme {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_3_small_theme {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 69px;
		top: 159px;
	}
	#ocean_blue_lbl_small_theme {
		left: 94px;
		top: 157px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_3_pc_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#PC_1_small_theme {
		left: 130px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 22px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(148,154,153,1);
	}
	#Mob_1_small_theme {
		left: 290px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(148,154,153,1);
	}
	#PC_2_small_theme {
		left: 509px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 22px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(148,154,153,1);
	}
	#Mob_2_small_theme {
		left: 669px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(148,154,153,1);
	}
	#PC_3_small_theme {
		left: 888px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 22px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(148,154,153,1);
	}
	#Mob_3_small_theme {
		left: 1048px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(148,154,153,1);
	}
	#med_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 20px;
		top: 271px;
		overflow: visible;
	}
	#layout_4_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_4_small_theme {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_4_small_theme {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 158px;
	}
	#sahara_small_theme {
		left: 71px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_4_mob_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#dark_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 399px;
		top: 271px;
		overflow: visible;
	}
	#layout_5_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_5_small_theme {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_5_small_theme {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#shadow_space_small_theme {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_5_mob_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#school_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 778px;
		top: 271px;
		overflow: visible;
	}
	#layout_6_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_6_small_theme {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_6_small_theme {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#knowledge_kornersmall_theme {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 123px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_6_mob_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#car_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 15px;
		top: 495px;
		overflow: visible;
	}
	#layout_7_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_7_mob_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_7_small_theme {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_7_small_theme {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 167px;
	}
	#drive_velocity_small_theme {
		left: 71px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#bike_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 394px;
		top: 495px;
		overflow: visible;
	}
	#layout_8_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_8_mob_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_7_small_theme_dk {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_7_small_theme_dk {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#ride_revolutionbigtheme {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#fash_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 773px;
		top: 495px;
		overflow: visible;
	}
	#layout_9_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_9_mob_small_theme {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_9_small_theme {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_9_small_theme {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#fashion_finds_small_theme {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#med_grp_dummy_theme_pc {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 20px;
		top: 729px;
		overflow: visible;
	}
	#layout_4_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_4_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_4_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 158px;
	}
	#sahara_small_theme_dummy_pc {
		left: 71px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_4_mob_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#shadow__grp_dummy_theme_pc {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 399px;
		top: 729px;
		overflow: visible;
	}
	#layout_5_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_5_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_5_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#shadow_space_small_theme_dummy {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_5_mob_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#paw_palace_grp_theme_pc {
		position: absolute;
		width: 330px;
		height: 175px;
		left: 778px;
		top: 729px;
		overflow: visible;
	}
	#layout_6_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_6_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_6_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#paw_palace_theme_du {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 123px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_6_mob_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#car_grp_dummy_theme_pc {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 15px;
		top: 953px;
       
		overflow: visible;
	}
	#layout_7_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_7_mob_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_7_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_7_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 167px;
	}
	#drive_velocity_small_theme_dum {
		left: 71px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#bike_grp_dummy_theme_pc {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 394px;
		top: 953px;
		overflow: visible;
	}
	#layout_8_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_8_mob_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_7_small_theme_dummy__ee {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_7_small_theme_dummy__ee {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#ride_revolutionbigtheme_dummy_ {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#fashion_finds_grp_dummy_theme_ {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 773px;
		top: 953px;
        /* display: none; */
		overflow: visible;
	}
	
	#layout_9_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_9_mob_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_9_small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_9_small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#fashion_finds_small_theme_dumm {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#close_theme_small_pc_ {
		position: absolute;
		width: 25px;
		height: 25px;
		right: 10px;
		top: 10px;
        cursor: pointer;
		overflow: visible;
	}

	#n_6_grp_dummy_theme_pc {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 18px;
		top: 1184px;
		overflow: visible;
	}
	#layout_16_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_16_mob_small_theme_dummy_p {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_16_btn_9_small_theme_dum {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_16_btn_9_small_theme_dum {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#n_6_small_theme_dummy_pc {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#shadow_17_grp_dummy_theme_pc {

		position: absolute;
		width: 330px;
		height: 175px;
		left: 397px;
		top: 1184px;
		overflow: visible;
	}
	#layout_17small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_17small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_17small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#shadow_17space_small_theme_dum {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_17mob_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#school_18_grp_dummy_theme_pc {
		
		position: absolute;
		width: 330px;
		height: 175px;
		left: 776px;
		top: 1184px;
		overflow: visible;
	}
	#layout_18_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_18_small_theme_dummy {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_18_small_theme_dummy {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 158px;
	}
	#knowledge18__kornersmall_theme {
		left: 74px;
		top: 155px;
		position: absolute;
		overflow: visible;
		width: 123px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#web_18_mob_small_theme_dummy_p {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#car_19grp_dummy_theme_pc {
		
		position: absolute;
		width: 330px;
		height: 184px;
		left: 13px;
		top: 1408px;
		overflow: visible;
	}
	#layout_19small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_19mob_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_19small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_19small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 46px;
		top: 167px;
	}
	#drive_19velocity_small_theme_d {
		left: 71px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#bike_20grp_dummy_theme_pc {

		position: absolute;
		width: 330px;
		height: 184px;
		left: 392px;
		top: 1408px;
		overflow: visible;
	}
	#layout_20small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_20mob_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_20small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_20small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#ride_20revolutionbigtheme_dumm {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
		#bike_21grp_dummy_theme_pc {
	
		position: absolute;
		width: 330px;
		height: 184px;
		left: 773px;
		top: 1406px;
		overflow: visible;
	}
	#bike_22grp_dummy_theme_pc {

	position: absolute;
	width: 330px;
	height: 184px;
	left: 13px;
	top: 1632px;
	overflow: visible;
}
#bike_23grp_dummy_theme_pc {

position: absolute;
width: 330px;
height: 184px;
left: 392px;
top: 1632px;
overflow: visible;
}
#bike_24grp_dummy_theme_pc {

position: absolute;
width: 330px;
height: 184px;
left: 773px;
top: 1632px;
overflow: visible;
}
#bike_25grp_dummy_theme_pc {

position: absolute;
width: 330px;
height: 184px;
left: 13px;
top: 1858px;
overflow: visible;
}
#bike_26grp_dummy_theme_pc {

position: absolute;
width: 330px;
height: 184px;
left: 392px;
top: 1858px;
overflow: visible;
}
#bike_27grp_dummy_theme_pc {

position: absolute;
width: 330px;
height: 184px;
left: 773px;
top: 1858px;
overflow: visible;
}
#bike_28grp_dummy_theme_pc {

position: absolute;
width: 330px;
height: 184px;
left: 13px;
top: 2084px;
overflow: visible;
}
#bike_29grp_dummy_theme_pc {
position: absolute;
width: 330px;
height: 184px;
left: 392px;
top: 2084px;
overflow: visible;
}

#bike_30grp_dummy_theme_pc {
	position: absolute;
	width: 330px;
	height: 184px;
	left: 773px;
	top: 2084px;
	overflow: visible;
	}
	#bike_31grp_dummy_theme_pc {
	position: absolute;
	width: 330px;
	height: 184px;
	left: 13px;
	top: 2310px;
	overflow: visible;
	}
	
	#bike_32grp_dummy_theme_pc {
		position: absolute;
		width: 330px;
		height: 184px;
		left: 392px;
		top: 2310px;
		overflow: visible;
		}
		#bike_33grp_dummy_theme_pc {
			position: absolute;
			width: 330px;
			height: 184px;
			left: 773px;
			top: 2310px;
			overflow: visible;
			}
			#bike_34grp_dummy_theme_pc {
			position: absolute;
			width: 330px;
			height: 184px;
			left: 13px;
			top: 2536px;
			overflow: visible;
			}
			
			#bike_35grp_dummy_theme_pc {
				position: absolute;
				width: 330px;
				height: 184px;
				left: 392px;
				top: 2536px;
				overflow: visible;
				}
				#bike_36grp_dummy_theme_pc {
					position: absolute;
					width: 330px;
					height: 184px;
					left: 773px;
					top: 2536px;
					overflow: visible;
					}
					#bike_37grp_dummy_theme_pc {
						position: absolute;
						width: 330px;
						height: 184px;
						left: 13px;
						top: 2762px;
						overflow: visible;
						}
	#layout_21small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 245px;
		height: 149px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#web_21mob_small_theme_dummy_pc {
		filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 76px;
		height: 149px;
		left: 254px;
		top: 0px;
		overflow: visible;
	}
	#radio_btn_21small_theme_dummy_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.radio_btn_21small_theme_dummy_ {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 49px;
		top: 167px;
	}
	#ride21_revolutionbigtheme_dumm {
		left: 74px;
		top: 164px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	