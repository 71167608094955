.mediaViewInfo {
    --web-view-name: ad area;
    --web-view-id: ad_area;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: ad_area;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#ad_area {
    position: relative;
    width: 100%;
    height: 94px;
    margin-left: 5px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: ad area;
    --web-view-id: ad_area;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#ad_bdr_ad_mob {
    fill: rgba(177,224,201,1);
}
.ad_bdr_ad_mob {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 90px;
    /* left: 18px; */
    top: 2px;
}
#AD_AREA_lbl_ad_mob {
    /* left: 115px; */
    top: 22px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    color: rgba(37,46,72,1);
}