	.mediaViewInfo {
		--web-view-name: True Vision Mob;
		--web-view-id: True_Vision_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: True_Vision_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#True_Vision_Mob {
		position: absolute;
		width: 375px;
		height: 3510px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: True Vision Mob;
		--web-view-id: True_Vision_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#TVMGroup_15132 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 8.5px;
		top: 2905.904px;
		overflow: visible;
	}
	#TVMGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 30px;
		top: 73.095px;
		overflow: visible;
	}
	#TVMRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.TVMRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#TVMGroup_15050 {
		position: absolute;
		width: 358px;
		height: 601.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVMsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 480.096px;
		overflow: visible;
	}
	#TVMlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#TVMmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TVMmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TVMlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#TVMn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.TVMn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TVMlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.TVMlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TVMtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.TVMtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TVMInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#TVMshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TVMshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TVMinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#TVMwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVMmed_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.TVMmed_shadow_space_website_7_ {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TVMwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#TVMn_234567890_mobile_number_shad {
		left: 48px;
		top: 293.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TVMaffanghanioperatelive_email_sh {
		left: 35px;
		top: 340.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TVMaddress_med_shadow_space_websi {
		left: 35px;
		top: 390.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#TVMlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 392.096px;
		overflow: visible;
	}
	#TVMemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 339.096px;
		overflow: visible;
	}
	#TVMphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 286.096px;
		overflow: visible;
	}
	#TVMn_Copyright_Operate_Live_OPC_P {
		left: 27px;
		top: 565.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#TVMContact_Us {
		left: 127.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -22.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#TVMGroup_15168 {
		position: absolute;
		width: 1042px;
		height: 695px;
		left: -442px;
		top: -26px;
		overflow: visible;
	}
	#TVMvolodymyr-hryshchenko-NQQ_WKbj {
		position: absolute;
		width: 1042px;
		height: 695px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVMSee_the_world_through_a__clear {
		left: 459px;
		top: 194px;
		position: absolute;
		overflow: visible;
		width: 333px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(255,255,255,1);
	}
	#TVMAnime_Edit {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 459px;
		top: 68px;
		overflow: visible;
	}
	#TVMYour_Business_Name {
		left: 504px;
		top: 77px;
		position: absolute;
		overflow: visible;
		width: 157px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#TVMGroup_15114 {
		position: absolute;
		width: 365px;
		height: 497px;
		left: 10px;
		top: 781px;
		overflow: visible;
	}
	#TVMjoshua-newton-g4_IymCiD-k-unsp {
		position: absolute;
		width: 258px;
		height: 258px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#TVMScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 365px;
		height: 152px;
		left: 0px;
		top: 345px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TVMIt_is_a_long_established_fact_ {
		left: 10px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 348px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#TVMVISION {
		left: 10px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVMGroup_15165 {
		position: absolute;
		width: 365px;
		height: 497px;
		left: 19px;
		top: 1348px;
		overflow: visible;
	}
	#TVMfelipepelaquim-0q5Y7OFmMhM-uns {
		position: absolute;
		width: 258px;
		height: 258px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#TVMScroll_Group_28_bo {
		mix-blend-mode: normal;
		position: absolute;
		width: 365px;
		height: 152px;
		left: 0px;
		top: 345px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TVMIt_is_a_long_established_fact__bp {
		left: 10px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 348px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#TVMMISSION {
		left: 10px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVMGroup_15166 {
		position: absolute;
		width: 365px;
		height: 497px;
		left: 10px;
		top: 1915px;
		overflow: visible;
	}
	#TVMdmitry-ratushny-wpi3sDUrSEk-un {
		position: absolute;
		width: 258px;
		height: 258px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#TVMScroll_Group_28_bt {
		mix-blend-mode: normal;
		position: absolute;
		width: 365px;
		height: 152px;
		left: 0px;
		top: 345px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TVMIt_is_a_long_established_fact__bu {
		left: 10px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 348px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#TVMPRINCIPLE {
		left: 10px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 100px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVMScroll_Group_94 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 282px;
		left: 0px;
		top: 2535px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#TVMn_ {
		position: absolute;
		width: 217px;
		height: 242px;
		left: 19px;
		top: 20px;
		overflow: visible;
	}
	#TVMMask_Group_22 {
		position: absolute;
		width: 217px;
		height: 220px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVMRectangle_664 {
		fill: rgba(0,0,0,1);
	}
	.TVMRectangle_664 {
		position: absolute;
		overflow: visible;
		width: 217px;
		height: 33px;
		left: 0px;
		top: 209px;
	}
	#TVMCOSMOS {
		left: 7px;
		top: 218px;
		position: absolute;
		overflow: visible;
		width: 207px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}
	#TVMGroup_10773 {
		position: absolute;
		width: 33px;
		height: 3px;
		left: 92px;
		top: 190px;
		overflow: visible;
	}
	#TVMEllipse_38 {
		fill: rgba(255,255,255,1);
	}
	.TVMEllipse_38 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 0px;
		top: 0px;
	}
	#TVMEllipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.TVMEllipse_39 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 8px;
		top: 0px;
	}
	#TVMEllipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.TVMEllipse_40 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 15px;
		top: 0px;
	}
	#TVMEllipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.TVMEllipse_41 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 21px;
		top: 0px;
	}
	#TVMEllipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.TVMEllipse_42 {
		position: absolute;
		overflow: visible;
		width: 4px;
		height: 3px;
		left: 29px;
		top: 0px;
	}
	#TVMWIDE_RANGE_OF_DESIGN {
		left: 70px;
		top: 2482px;
		position: absolute;
		overflow: visible;
		width: 236px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
