	.n_14_webmobmediaViewInfo {
		--web-view-name: FeatheredScapeMob;
		--web-view-id: FeatheredScapeMob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: FeatheredScapeMob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#FeatheredScapeMob {
		position: absolute;
		width: 390px;
		height: 3603px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: FeatheredScapeMob;
		--web-view-id: FeatheredScapeMob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#n_14_webmobGroup_14927 {
		position: absolute;
		width: 417px;
		height: 261px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_14_webmobstyle_3 {
		position: absolute;
		width: 201px;
		height: 261px;
		left: 216px;
		top: 0px;
		overflow: visible;
	}
	#n_14_webmobRectangle_84 {
		fill: rgba(249,129,105,1);
	}
	.n_14_webmobRectangle_84 {
		filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 154px;
		height: 110px;
		left: 15px;
		top: 220px;
	}
	#n_14_webmobDiscover_a_new_world_of_birdin {
		left: 15px;
		top: 55px;
		position: absolute;
		overflow: visible;
		width: 200px;
		height: 50px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: lighter;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#n_14_webmobExplore_Now_ {
		left: 29px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 40px;
		white-space: nowrap;
		line-height: 24px;
		margin-top: -9px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 6px;
		color: rgba(255,255,255,1);
	}
	#n_14_webmobScroll_Group_42 {
		mix-blend-mode: normal;
		position: absolute;
		width: 201px;
		height: 59px;
		left: 0px;
		top: 129px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n_14_webmobintro_part {
		opacity: 0.71;
		left: 5px;
		top: 2px;
		position: absolute;
		overflow: visible;
		width: 202px;
		height: 113px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#n_14_webmobzane-lee-bXbi1jJVDf0-unsplash_ {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 17px;
		top: 13px;
		border-radius: 50%;
		overflow: hidden;
	}
	#n_14_webmobOperate_Live {
		left: 45px;
		top: 19px;
		position: absolute;
		overflow: visible;
		width: 51px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 9px;
		color: rgba(0,0,0,1);
	}
	#n_14_webmobGroup_14943 {
		position: absolute;
		width: 320px;
		height: 656px;
		left: 41px;
		top: 377px;
		overflow: visible;
	}
	#n_14_webmobmark-olsen-tjZPseTxe6k-unsplas {
		position: absolute;
		width: 268px;
		height: 373px;
		left: 20px;
		top: 0px;
		overflow: visible;
	}
	#n_14_webmobEmbark_on_an_Avian_Adventure_E {
		left: 0px;
		top: 441px;
		position: absolute;
		overflow: visible;
		width: 321px;
		height: 110px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(12,54,83,1);
	}
	#n_14_webmobGroup_14929 {
		position: absolute;
		width: 204px;
		height: 48px;
		left: 52px;
		top: 608px;
		overflow: visible;
	}
	#n_14_webmobRectangle_89 {
		fill: rgba(249,129,105,1);
	}
	.n_14_webmobRectangle_89 {
		filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 291px;
		height: 135px;
		left: 0px;
		top: 0px;
	}
	#n_14_webmobKnow_More_About_Us {
		left: 28px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 153px;
		white-space: nowrap;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#n_14_webmobGroup_14953 {
		position: absolute;
		width: 273px;
		height: 1055px;
		left: 72px;
		top: 1179px;
		overflow: visible;
	}
	#n_14_webmobCard_01_website_3 {
		position: absolute;
		width: 234px;
		height: 256px;
		left: 20px;
		top: 0px;
		overflow: visible;
	}
	#n_14_webmobScroll_Group_10 {
		mix-blend-mode: normal;
		position: absolute;
		width: 234px;
		height: 120px;
		left: 0px;
		top: 136px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n_14_webmobintro_website_3 {
		left: -13px;
		top: -4px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 77px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#n_14_webmobVision_website_3 {
		left: 88px;
		top: 83px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#n_14_webmobaarn-giri-3lGi0BXJ1W0-unsplash {
		position: absolute;
		width: 234px;
		height: 73px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_14_webmobCard_02_website_3 {
		position: absolute;
		width: 260px;
		height: 256px;
		left: 7px;
		top: 349px;
		overflow: visible;
	}
	#n_14_webmobScroll_Group_11 {
		mix-blend-mode: normal;
		position: absolute;
		width: 260px;
		height: 120px;
		left: 0px;
		top: 136px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n_14_webmobintro_2_website_3 {
		left: 0px;
		top: -4px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 73px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#n_14_webmobmission_lbl_website_3 {
		left: 93px;
		top: 83px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#n_14_webmobzdenek-machacek-cfIcTiopin4-un {
		position: absolute;
		width: 234px;
		height: 73px;
		left: 13px;
		top: 0px;
		overflow: visible;
	}
	#n_14_webmobCard_03_website_3 {
		position: absolute;
		width: 273px;
		height: 276px;
		left: 0px;
		top: 779px;
		overflow: visible;
	}
	#n_14_webmobScroll_Group_12 {
		mix-blend-mode: normal;
		position: absolute;
		width: 273px;
		height: 120px;
		left: 0px;
		top: 156px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n_14_webmobintro_lbl_website_3 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 73px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#n_14_webmobprinciples_lbl_website_3 {
		left: 84.5px;
		top: 107px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#n_14_webmobIcon_top_website_3 {
		fill: rgba(255,255,255,1);
	}
	.n_14_webmobIcon_top_website_3 {
		overflow: visible;
		position: absolute;
		width: 18.924px;
		height: 3.37px;
		left: 121.029px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_14_webmobkevin-mueller-aeNg4YA41P8-unsp {
		position: absolute;
		width: 234px;
		height: 73px;
		left: 20px;
		top: 24px;
		overflow: visible;
	}
	#n_14_webmobOur_Collection {
		left: 72px;
		top: 2327px;
		position: absolute;
		overflow: visible;
		width: 248px;
		white-space: nowrap;
		line-height: 46px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(12,54,83,1);
	}
	#n_14_webmobScroll_Group_47 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 384px;
		left: 0px;
		top: 2391px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#n_14_webmobGroup_14950 {
		position: relative;
		width: 231px;
		height: 317px;
		left: 30px;
		margin-right: 30px;
		top: 19px;
		overflow: visible;
	}
	#n_14_webmobRectangle_703 {
		fill: transparent;
	}
	.n_14_webmobRectangle_703 {
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 317px;
		left: 0px;
		top: 0px;
		background:  rgba( 255, 255, 255, 0.05 );
		box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
		backdrop-filter:  blur( 4px );
		-webkit-backdrop-filter:  blur( 4px );
		border-radius:  10px;
		border:  1px solid rgba( 255, 255, 255, 0.18 );
	}
	#n_14_webmobkevin-mueller-aeNg4YA41P8-unsp_ds {
		position: absolute;
		width: 210px;
		height: 253px;
		left: 10px;
		top: 54px;
		overflow: visible;
	}
	#n_14_webmobParrot {
		left: 17px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#n_14_webmobfooter_bg_web_9_pc {
		opacity: 0.93;
		fill: rgba(50,50,50,1);
	}
	.n_14_webmobfooter_bg_web_9_pc {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 774px;
		left: 0px;
		top: 2836px;
	}
	#n_14_webmobGroup_14951 {
		position: absolute;
		width: 374.661px;
		height: 405.895px;
		left: 0px;
		top: 2868px;
		overflow: visible;
	}
	#n_14_webmobMy_project {
		position: absolute;
		width: 374.661px;
		height: 405.895px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_14_webmobdisplay_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.n_14_webmobdisplay_web_9_pc {
		position: absolute;
		overflow: visible;
		width: 285px;
		height: 150px;
		left: 60px;
		top: 237.494px;
	}
	#n_14_webmobVISIT_US {
		left: 227.33px;
    top: 168.494px;
    position: absolute;
    overflow: visible;
    width: 155px;
    white-space: nowrap;
    line-height: 46px;
    margin-top: -6.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    color: rgba(255,255,255,1);
	}
	#n_14_webmobFor_Queries_infoandroom_web_9_ {
		left: 68.526px;
		top: 3308px;
		position: absolute;
		overflow: visible;
		width: 219px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#n_14_webmobFor_call__920012888_web_9_pc {
		left: 68.526px;
		top: 3329px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#n_14_webmobtelephone_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.n_14_webmobtelephone_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 11.381px;
		height: 10.522px;
		left: 42px;
		top: 3333.525px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_14_webmobsend_1_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.n_14_webmobsend_1_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 42px;
		top: 3312.72px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_14_webmobIcon_metro-locatio_web_9_pc_n {
		fill: rgba(255,255,255,1);
	}
	.n_14_webmobIcon_metro-locatio_web_9_pc_n {
		overflow: visible;
		position: absolute;
		width: 11.381px;
		height: 18.208px;
		left: 41.999px;
		top: 3354.331px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_14_webmobaddress_lbl_web_9_pc {
		left: 68.526px;
		top: 3353.539px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 62px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#n_14_webmobGroup_14952 {
		position: absolute;
		width: 210px;
		height: 50.001px;
		left: 90px;
		top: 3425.999px;
		overflow: visible;
	}
	#n_14_webmoblinked_in_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 160px;
		top: 0.001px;
		overflow: visible;
	}
	#n_14_webmobEllipse_5_med_web_9_pc:hover {
		fill: rgba(120,120,255,1);
	}
	.n_14_webmobEllipse_5_med_web_9_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n_14_webmobEllipse_5_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n_14_webmobEllipse_5_med_web_9_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n_14_webmoblinked_in_website_9_layout {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#n_14_webmobn_website_2_layout_web_9_pc {
		fill: rgba(63,61,86,1);
	}
	.n_14_webmobn_website_2_layout_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.954px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_14_webmoblinked_in_i_down_web_9_pc {
		fill: rgba(63,61,86,1);
	}
	.n_14_webmoblinked_in_i_down_web_9_pc {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.097px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_14_webmobtop_dot_i_website_9_layout {
		fill: rgba(63,61,86,1);
	}
	.n_14_webmobtop_dot_i_website_9_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_14_webmobInsta_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 80px;
		top: 0px;
		overflow: visible;
	}
	#n_14_webmobEllipse_4_med_web_9_pc:hover {
		fill: rgba(255,120,120,1);
	}
	.n_14_webmobEllipse_4_med_web_9_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n_14_webmobEllipse_4_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n_14_webmobEllipse_4_med_web_9_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n_14_webmobinstagram_website_9_layout {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.126px;
		overflow: visible;
	}
	#n_14_webmobwhatsapp_grp_med_web_9_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#n_14_webmobEllipse_3_med_web_9_pc:hover {
		fill: rgba(50,172,158,1);
	}
	.n_14_webmobEllipse_3_med_web_9_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n_14_webmobEllipse_3_med_web_9_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n_14_webmobEllipse_3_med_web_9_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n_14_webmobwhatsapp_lbl_website9_layout {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#n_14_webmobn_Copyright_Operate_website_9_ {
		left: 34.5px;
		top: 3529.46px;
		position: absolute;
		overflow: visible;
		width: 322px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}