	.mediaViewInfo {
		--web-view-name: Request Page;
		--web-view-id: Request_Page;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Request_Page;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Request_Page {
		position: absolute;
		width: calc(100vw - 120px);
		height: calc(100vh - 60px);
        top: 60px;
        left: 120px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Request Page;
		--web-view-id: Request_Page;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#heading_grp_conreqsug_req_page {
		position: absolute;
		width: 364px;
		height: 42px;
		left: calc((100% - 364px)/2);
		top: 22px;
		overflow: visible;
	}
	#conreqsug_req_page_new_pc_req {
		opacity: 0.92;
		left: 151px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 65px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(255,79,4,1);
	}
	#selected_grp_conreqsug_req_pag {
		position: absolute;
		width: 99px;
		height: 24px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#conreqsug_req_page_new_pc_conn {
		opacity: 0.92;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(0,0,0,1);
	}
	#conreqsug_req_page_new_pc_sug {
		opacity: 0.92;
		left: 267px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 98px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(48,47,47,1);
	}
	#underline_conreqsug_req_page_n {
		fill: rgba(255,79,4,1);
	}
	.underline_conreqsug_req_page_n {
		position: absolute;
		overflow: visible;
		width: 64px;
		height: 3px;
		left: 151px;
		top: 39px;
	}
	#desgin_3_conreqsug_req_page_ne {
		fill: rgba(248,248,248,1);
	}
	.desgin_3_conreqsug_req_page_ne {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 203px;
		left: 0px;
		top: 94px;
	}
	#Scroll_req_new_layout {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 616px;
		/* left: 63px; */
		top: 118px;
        display: block;
		overflow-x: hidden;
		overflow-y: scroll;
	}
    #Scroll_req_new_layout::-webkit-scrollbar{
		display: none;
	}
	#card_1_req_page_pc_inichan {
		position: relative;
		width: 185px;
		height: 320px;
		left: 11px;
		top: 14px;
        display: inline-block;
        margin: 10px;
		overflow: visible;
	}
	#over_all_card_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.over_all_card_erq_page_new_pc {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 193px;
		height: 328px;
		left: 0px;
		top: 0px;
	}
	#Anime_Edit_goku_thats_me {
		position: absolute;
		width: 150px;
		height: 142px;
		left: 18px;
		top: 35px;
		overflow: visible;
	}
	#designation_grp_req_page_new {
		position: absolute;
		width: 170px;
		height: 25px;
		left: 12px;
		top: 182px;
		overflow: visible;
	}
	#job-seeker_img_erq_page_new_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#erq_page_new_pc_job_name {
		left: 30px;
		top: 3px;
		position: absolute;
		overflow: hidden;
		width: 141px;
		height: 20px;
	
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: capitalize;
	}
	#Mohammed_Affan_goku_v {
		left: 1px;
		top: 8px;
		position: absolute;
		overflow: hidden;
		width: 185px;
		height: 20px;
		/* line-height: 49px;
		margin-top: -17px; */
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
	}
	#reject_erq_page_new_pc {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 52px;
		top: 275px;
        cursor: pointer;
		overflow: visible;
	}
	#reqj_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.reqj_erq_page_new_pc {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#reject_img_erq_page_new_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#accept_grp_erq_page_new_pc {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 8px;
		top: 275px;
        cursor: pointer;
		overflow: visible;
	}
	#accept_bdr_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.accept_bdr_erq_page_new_pc {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#accept_img_erq_page_new_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#ignore_grp_erq_page_new_pc {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 96px;
		top: 276px;
        cursor: pointer;
		overflow: visible;
	}
	#bg_ignore_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.bg_ignore_erq_page_new_pc {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#ignore_u_erq_page_new_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#block_grp_erq_page_new_pc {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 140px;
		top: 276px;
        cursor: pointer;
		overflow: visible;
	}
	#block_bg_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.block_bg_erq_page_new_pc {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#block_img_erq_page_new_pc {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#company_name_request_page_new_ {
		position: absolute;
		width: 170px;
		height: 25px;
		left: 12px;
		top: 212px;
		overflow: visible;
	}
	#office-building_req_page_new_p {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#OP_conreqsug_erq_page_new_pc {
		left: 30px;
		top: 3px;
		position: absolute;
		overflow: hidden;
		width: 141px;
		height: 20px;
	
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		text-transform: capitalize;
	}
	#marquee_op_intro_person {
		left: 8px;
		top: 247px;
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
        border-radius: 5px;
        background-color: rgba(219,242,231,1);
		color: rgba(17,17,17,1);
		text-transform: capitalize;
	}