.mediaViewInfo {
    --web-view-name: Meeting room cam;
    --web-view-id: Meeting_room_cam_mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: Meeting_room_cam_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#Meeting_room_cam_mob {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 145px);
    background-color: rgba(255,255,255,1);
    top:80px;
    overflow-x: hidden;
    overflow-y: scroll;
    --web-view-name: Meeting room cam;
    --web-view-id: Meeting_room_cam_mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#chat_full_grp_mob {
    position: absolute;
    width: 325.408px;
    height: 219.037px;
    left: -0.408px;
    top: 514.963px;
    overflow: visible;
}
#chat_bg_mob_ {
    position: absolute;
    width: 325.408px;
    height: 219.037px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#user_2_text_grp_mob {
    position: absolute;
    width: 290.746px;
    height: 68.429px;
    left: 20.254px;
    top: 25.743px;
    overflow: visible;
}
#chat_2_design_mob {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chat_2_design_mob {
    overflow: visible;
    position: absolute;
    width: 252.03px;
    height: 42.683px;
    left: 0px;
    top: 10.462px;
    transform: matrix(1,0,0,1,0,0);
}
#chat_2_time_mob {
    left: 4.56px;
    top: 51.429px;
    position: absolute;
    overflow: visible;
    width: 25px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(255,87,87,1);
}
#chat_2_username_mob {
    left: 42.932px;
    top: 51.429px;
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(255,87,87,1);
}
#chat_2_txt_input_mob {
    left: 6.634px;
    top: 22.093px;
    position: absolute;
    overflow: visible;
    width: 220px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#online_user_pf_2_img_mob {
    position: absolute;
    width: 30px;
    height: 27.909px;
    left: 260.746px;
    top: 0px;
    overflow: visible;
}
#user_1_txt_grp_mob {
    position: absolute;
    width: 285.868px;
    height: 75.055px;
    left: 26px;
    top: 92.757px;
    overflow: visible;
}
#chat_1_design_mob {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chat_1_design_mob {
    overflow: visible;
    position: absolute;
    width: 246.788px;
    height: 42.683px;
    left: 41.004px;
    top: 15.268px;
    transform: matrix(1,0,0,1,0,0);
}
#chat_1_time_mob {
    left: 56.579px;
    top: 56.951px;
    position: absolute;
    overflow: visible;
    width: 25px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(77,242,94,1);
}
#chat_1_username_mob {
    left: 94.295px;
    top: 58.055px;
    position: absolute;
    overflow: visible;
    width: 134px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(77,242,94,1);
}
#chat_1_input_mob {
    left: 58.864px;
    top: 26.886px;
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#online_user_pf_1_img_mob {
    position: absolute;
    width: 30px;
    height: 27.502px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_input_text_mob {
    position: absolute;
    width: 285px;
    height: 34.111px;
    left: 26px;
    top: 172.522px;
    overflow: visible;
}
#chat_msg_input_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chat_msg_input_mob {
    position: absolute;
    overflow: visible;
    width: 285px;
    height: 34.111px;
    left: 0px;
    top: 0px;
    font-size: 17px;
    border-radius: 50px;
    padding-left: 15px;
    font-style: Segeo UI;
    box-shadow: inset 10px 10px 20px #cacaca,inset -10px -10px 20px #ffffff;
    border: 1px solid;
}
#send-message_input_mob {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 258px;
    top: 8.269px;
    overflow: visible;
}
#vids_mob {
    position: absolute;
    width: 325px;
    height: 443.438px;
    left: -0.408px;
    top: 75px;
    overflow: visible;
}
#vid_1_mob {
    position: absolute;
    width: 325px;
    height: 443.438px;
    left: 0px;
    overflow: visible;
}
#vid_cam_1_mob {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    border: 2px solid #000;
    overflow: visible;
}
#Group_call_ended_mob {
    position: absolute;
    width: 41px;
    height: 33.077px;
    left: 50.597px;
    top: 401.058px;
    overflow: visible;
}
#border_call_end_mob {
    fill: rgba(251,54,61,1);
}
.border_call_end_mob {
    position: absolute;
    overflow: visible;
    width: 41px;
    height: 33.077px;
    left: 0px;
    top: 0px;
}
#call-end_mob {
    position: absolute;
    width: 21.6px;
    height: 21.6px;
    left: 10.214px;
    top: 6.508px;
    overflow: visible;
}
#Group_video_off_mob {
    position: absolute;
    width: 42px;
    height: 33.077px;
    left: 185.801px;
    top: 401.058px;
    overflow: visible;
}
#border_cameraonoff {
    opacity: 0.92;
    fill: rgba(255,255,255,1);
}
.border_cameraonoff {
    position: absolute;
    overflow: hidden;
    width: 42px;
    height: 33.077px;
    left: 0px;
    top: 0px;
    border: 2px solid;
    opacity: 0.6;
    border-radius: 5px;
}
#video-camera3_mob {
    position: absolute;
    width: 21.6px;
    height: 21.6px;
    left: 10.369px;
    top: 6.508px;
    overflow: visible;
}
#Group_start_recording_mob {
    position: absolute;
    width: 42px;
    height: 33.077px;
    left: 95.199px;
    top: 401.058px;
    overflow: visible;
}

#Group_stop_recording {
    position: absolute;
    width: 42px;
    height: 33.077px;
    left: 140.199px;
    top: 401.058px;
    overflow: visible;
}
#border_start_mob {
    opacity: 0.92;
    fill: rgba(255,255,255,1);
}
.border_start_mob {
    position: absolute;
    overflow: hidden;
    border: 2px solid;
    opacity: 0.4;
    border-radius: 6px;
    width: 42px;
    height: 33.077px;
    left: 0px;
    top: 0px;
}
#rec-button_mob {
    position: absolute;
    width: 20.195px;
    height: 20.195px;
    left: 11.746px;
    top: 7.425px;
    overflow: visible;
}
#Group_micoff_mob {
    position: absolute;
    width: 42px;
    height: 33.077px;
    left: 232.403px;
    top: 401.058px;
    overflow: visible;
}
#border_micoffon_mob {
    opacity: 0.92;
    fill: rgba(255,255,255,1);
}
.border_micoffon_mob {
    position: absolute;
    overflow: hidden;
    width: 42px;
    height: 33.077px;
    left: 0px;
    top: 0px;
    border: 2px solid;
    opacity: 0.6;
    border-radius: 5px;
}
#mic-on-off-ou {
    position: absolute;
    width: 20.195px;
    height: 20.195px;
    left: 11.9px;
    top: 7.425px;
    overflow: visible;
}
#Group_outer_name_lbl {
    position: absolute;
    width: calc(100% - 39px);
    height: 25.841px;
    overflow: visible;
}
#border_name_lbl_meet {
    opacity: 0.92;
    fill: rgba(255,255,255,1);
}
.border_name_lbl_meet {
    position: absolute;
    overflow: visible;
    width: 96px;
    height: 25.841px;
    left: 0px;
    top: 0px;
}
#video_name_lbl_value {
    left: 11px;
    top: 5.921px;
    position: absolute;
    overflow: visible;
    width: 77px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(0,0,0,1);
}
#opposite_person_mob {
    position: absolute;
    width: 112px;
    height: 71.322px;
    left: 201px;
    top: 12.517px;
    overflow: visible;
}

#vid_cam_2_mob {
    position: absolute;
    /* border: 2px solid black; */
    width: 112px;
    height: 71.322px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Mohammed_Zaid_bl {
    left: 8px;
    top: -11px;
    position: absolute;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    background-color: white;
    color: black;
}
#upcome_meet_scroll_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 100vw;
    height: 74.9px;
    left: 0px;
    top: 0px;
    overflow-x: scroll;
    overflow-y: hidden;
}
#upmeet_design_mob {
    position: absolute;
    width: 375px;
    height: 74.9px;
    left: -30px;
    top: 0px;
    overflow: visible;
}
#upcome_pop_mob {
    position: absolute;
    width: 143px;
    height: 54px;
    left: 3px;
    top: 2px;
    overflow: visible;
}
#n_6170 {
    position: absolute;
    width: 143px;
    height: 54px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Web_Development {
    left: 18px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 108px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(5,5,5,1);
    text-decoration: underline;
}
#Remaining {
    left: 18px;
    top: 27px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(5,5,5,1);
}
#n_3056 {
    left: 84px;
    top: 27px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(5,5,5,1);
}
#right-arrow {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 92%;
    top: 20px;
    transform: rotate(180deg);
    overflow: visible;
}
#online_users_mob {
    position: absolute;
    width: 70.376px;
    height: 645px;
    right: 0px;
    top: 58px;
    overflow: visible;
}
#ou-bg-img {
    position: absolute;
    width: 70.376px;
    height: 645px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Scroll_Group_1 {
    mix-blend-mode: normal;
    position: absolute;
    width: 52px;
    height: 549px;
    left: 18.376px;
    top: 56px;
    overflow-x: hidden;
    overflow-y: auto;
}
#Online_users_mob_1 {
    position: relative;
    width: 40px;
    height: 40px;
    margin-top: 9px;
    left: 7px;
    top: -2px;
  
    overflow: visible;
}
#user_1_img_tag {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#arrow-right_ou {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 30.376px;
    /* top: 605px; */
    overflow: visible;
}