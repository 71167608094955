#card_grp_photos_com_off_pcZ {
    position: absolute;
    width: 174px;
    height: 70px;
    left: 13px;
    top: 12px;
    overflow: visible;
}
#photo_3_com_off_pcZ {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 52px;
    top: 0px;
    overflow: visible;
}
#photo_2_com_off_pcZ {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 129px;
    top: 13px;
    overflow: visible;
}
#photo_1_com_off_pcZ {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 0px;
    top: 13px;
    overflow: visible;
}