	.mediaViewInfo {
		--web-view-name: RadiantLocks;
		--web-view-id: RadiantLocks;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: RadiantLocks;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#RadiantLocks {
		position: absolute;
		width: 1366px;
		height: 3503px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: RadiantLocks;
		--web-view-id: RadiantLocks;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#RLGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 472.998px;
		left: 95.5px;
		top: 3017px;
		overflow: visible;
	}
	#RLGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 99.997px;
		overflow: visible;
	}
	#RLGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#RLRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.RLRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#RLsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#RLlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#RLmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.RLmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#RLlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#RLn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.RLn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#RLlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.RLlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#RLtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.RLtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#RLInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#RLshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.RLshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#RLinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#RLwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#RLmed_shadow_space_website_7_bn {
		fill: rgba(255,255,255,1);
	}
	.RLmed_shadow_space_website_7_bn {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#RLwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#RLn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#RLaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#RLaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#RLlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#RLemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#RLphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#RLn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#RLContact_Us {
		left: 510.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#RLGroup_15151 {
		position: absolute;
		width: 915px;
		height: 1248px;
		left: 226px;
		top: 1112px;
		overflow: visible;
	}
	#RLGroup_15141 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#RLRectangle_752 {
		fill: rgba(249,188,137,1);
	}
	.RLRectangle_752 {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#RLjamie-street-aMuq-Xz7R_M-unspl {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#RLScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#RLScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#RLIt_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#RLVISION {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#RLGroup_15142 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 436px;
		overflow: visible;
	}
	#RLRectangle_752_b {
		fill: rgba(216,180,180,1);
	}
	.RLRectangle_752_b {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#RLshari-sirotnak-ajzbkcRy-wU-uns {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 609px;
		top: 33px;
		overflow: visible;
	}
	#RLScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 107px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#RLScroll_Group_28_b::-webkit-scrollbar{
		display: none;
	}
	#RLIt_is_a_long_established_fact__b {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#RLMISSION {
		left: 109px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#RLGroup_15143 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 872px;
		overflow: visible;
	}
	#RLRectangle_752_cb {
		fill: rgba(150,222,145,1);
	}
	.RLRectangle_752_cb {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#RLjamie-street-aMuq-Xz7R_M-unspl_cc {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#RLScroll_Group_28_cd {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#RLScroll_Group_28_cd::-webkit-scrollbar{
		display: none;
	}
	#RLIt_is_a_long_established_fact__ce {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#RLPRINCIPLES {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#RLGroup_15150 {
		position: absolute;
		width: 1286px;
		height: 457px;
		left: 40px;
		top: 2460px;
		overflow: visible;
	}
	#RLOfferings {
		left: 65px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 143px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(0,0,0,1);
	}
	#RLGroup_15145 {
		position: absolute;
		width: 185px;
		height: 61px;
		left: 1009px;
		top: 0px;
		overflow: visible;
	}
	#RLRectangle_753 {
		fill: rgba(255,255,255,1);
		stroke: rgba(0,0,0,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.RLRectangle_753 {
		filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.678));
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 79px;
		left: 0px;
		top: 0px;
	}
	#RLView_More {
		left: 46px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#RLScroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1286px;
		height: 396px;
		left: 0px;
		top: 61px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#RLScroll_Group_91::-webkit-scrollbar{
		display: none;
	}
	#RLGroup_15144 {
		position: absolute;
		width: 243px;
		height: 331px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#RLRectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.RLRectangle_754 {
		filter: drop-shadow(5px 5px 1px rgba(235, 99, 99, 0.518));
		position: absolute;
		overflow: visible;
		width: 249.5px;
		height: 337.5px;
		left: 0px;
		top: 0px;
	}
	#RLbaylee-gramling-a3xr2mVjT5M-un {
		position: absolute;
		width: 239px;
		height: 255px;
		left: 2px;
		top: 38px;
		overflow: visible;
	}
	#RLTitle {
		left: 7px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#RLn_10000 {
		left: 7px;
		top: 299px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#RLGroup_15139 {
		position: absolute;
		width: 1366px;
		height: 157px;
		left: 0px;
		top: 855px;
		overflow: visible;
	}
	#RLRectangle_752_cs {
		fill: rgba(235,161,99,1);
	}
	.RLRectangle_752_cs {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 157px;
		left: 0px;
		top: 0px;
	}
	#RLLorem_Ipsum_is_simply_dummy_te {
		left: 79px;
		top: 22px;
		position: absolute;
		overflow: visible;
		width: 1210px;
		height: 114px;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#RLGroup_15086 {
		position: absolute;
		width: 1335px;
		height: 826px;
		left: 31px;
		top: 0px;
		overflow: visible;
	}
	#RLMask_Group_1 {
		position: absolute;
		width: 901px;
		height: 826px;
		left: 434px;
		top: 0px;
		overflow: visible;
	}
	#RLRectangle_3 {
		fill: rgba(252,213,70,1);
	}
	.RLRectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 595px;
	}
	#RLOrder_Now {
		left: 45px;
		top: 608px;
		position: absolute;
		overflow: visible;
		width: 81px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#RLWhere_Beauty_Meets__Confidence {
		left: 0px;
		top: 235px;
		position: absolute;
		overflow: visible;
		width: 531px;
		white-space: nowrap;
		line-height: 70px;
		margin-top: -8px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 54px;
		color: rgba(36,36,36,1);
	}
	#RLChoose_one_of_our_plans_enter_ {
		opacity: 0.5;
		left: 4px;
		top: 493px;
		position: absolute;
		overflow: visible;
		width: 443px;
		white-space: nowrap;
		line-height: 30px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(36,36,36,1);
	}
	#RLCompany_Name {
		left: 4px;
		top: 49px;
		position: absolute;
		overflow: visible;
		width: 189px;
		white-space: nowrap;
		line-height: 51px;
		margin-top: -13px;
		text-align: left;
		font-family: Semplicità;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(36,36,36,1);
	}
	body::-webkit-scrollbar{
		display: none;
	}
	@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 3s;
}
