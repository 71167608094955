	.mediaViewInfo {
		--web-view-name: calender page Task mob;
		--web-view-id: calender_page_Task_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: calender_page_Task_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#calender_page_Task_mob {
		position: absolute;
        width: 100%;
        height: calc(100% - 75px);
        top: 75px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: calender page Task mob;
		--web-view-id: calender_page_Task_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#calender_page_Task_mob_grp {
		position: absolute;
        width: 100%;
        height: calc(100% - 17px);
        left: -26px;
        top: 17px;
		overflow: visible;
	}
	#calender_page_Task_mob_bdr {
		fill: rgba(255,255,255,1);
		stroke: rgba(78,187,87,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.calender_page_Task_mob_bdr {
		position: absolute;
		overflow: visible;
		width: calc(100% - 20px);
		height: 100%;
		left: 0px;
		top: 0px;
	}
	#right_menu_calender_page_Task_ {
		position: absolute;
		width: 22.5px;
		height: 331.88px;
        right: -15px;
		top: 25.72px;
		overflow: visible;
	}
	#mobtrask_mob_lbl_calender {
		transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,332.5,168.2197) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#mobMeetings_lbl_headingcalende {
		transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,324.5,351.9829) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#Events_lbl_calender_mob {
		transform: translate(-340.5px, -156.72px) matrix(1,0,0,1,333.5,459.1001) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#underline_task_mob {
		position: absolute;
		width: 22.5px;
		height: 32.46px;
		left: 0px;
		top: 96.32px;
		overflow: visible;
	}
	#mobTasks_lbl_ {
		transform: translate(-340.5px, -253.04px) matrix(1,0,0,1,337.5,259.5396) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(78,187,87,1);
	}
	#mobLine_29 {
		fill: transparent;
		stroke: rgba(78,187,87,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.mobLine_29 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 32px;
		left: 0px;
		top: 0.46px;
		transform: matrix(1,0,0,1,0,0);
	}
	#task_mobc_cal_mob {
	mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 45px);
    height: 100%;
    left: 26px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-content: flex-start;
    overflow-x: hidden;
    overflow-y: scroll;
	}
	#card_1_task_mob {
		position: relative;
		width: 249.76px;
		height: 58.8px;
		left: 30.64px;
		top: 0px;
        margin: 10px;
		overflow: visible;
	}
	#calender_page_mob_lbl {
		left: 9.16px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 237px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#calender_time_mob {
		left: 9.16px;
		top: 23.52px;
		position: absolute;
		overflow: visible;
		width: 113px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
	}
	#mobcaleneder_mode_calender {
		fill: rgba(175,148,252,1);
	}
	.mobcaleneder_mode_calender {
		position: absolute;
		overflow: visible;
		width: 3.36px;
		height: 16.8px;
		left: 0.36px;
		top: 3.36px;
	}
	#mobactive_calender_pagemobcale {
		fill: rgba(46,243,65,1);
	}
	.mobactive_calender_pagemobcale {
		position: absolute;
		overflow: visible;
		width: 11.2px;
		height: 11.2px;
		left: 234.44px;
		top: 29.08px;
	}
	#immediate_1_calender_mob {
		position: absolute;
		width: 22.4px;
		height: 22.4px;
		left: 201.96px;
		top: 21.8px;
		overflow: visible;
	}
	#arcane_toptaskcalender_mob {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.arcane_toptaskcalender_mob {
		overflow: visible;
		position: absolute;
		width: 249.76px;
		height: 1px;
		left: 0px;
		top: 58.8px;
		transform: matrix(1,0,0,1,0,0);
	}
