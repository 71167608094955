	.mediaViewInfo {
		--web-view-name: VerdantHarvestGrove Mob;
		--web-view-id: VerdantHarvestGrove_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: VerdantHarvestGrove_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#VerdantHarvestGrove_Mob {
		position: absolute;
		width: 390px;
		height: 3702px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: VerdantHarvestGrove Mob;
		--web-view-id: VerdantHarvestGrove_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#VHG_MOB_29mk-s-tHHFiw6GNEU-unsplash {
		position: absolute;
		width: 390px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29Rectangle_748 {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
	}
	.VHG_MOB_29Rectangle_748 {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#VHG_MOB_29amjd-rdwan-CEAPSFlMLbk-unsplas {
		position: absolute;
		width: 350px;
		height: 350px;
		left: 20px;
		top: 463px;
		overflow: visible;
	}
	#VHG_MOB_29Anime_Edit {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 61px;
		top: 45px;
        border-radius: 50%;
		overflow: hidden;
	}
	#VHG_MOB_29Your_Business_Name {
		left: 131px;
		top: 64px;
		position: absolute;
		overflow: visible;
		width: 254px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#VHG_MOB_29Cultivating_Wellness_from_Farm {
		left: 11px;
		top: 138px;
		position: absolute;
		overflow: visible;
		width: 380px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#VHG_MOB_29Group_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 18px;
		top: 283px;
		overflow: visible;
	}
	#VHG_MOB_29Rectangle_3 {
		fill: rgba(233,153,15,1);
	}
	.VHG_MOB_29Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#VHG_MOB_29Order_Now {
		left: 45px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 81px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#VHG_MOB_29Group_15123 {
		position: absolute;
		width: 390px;
		height: 1636px;
		left: 0px;
		top: 863px;
		overflow: visible;
	}
	#VHG_MOB_29Group_15114 {
		position: absolute;
		width: 390px;
		height: 512px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29tandem-x-visuals-dqZ0JREudKw-u {
		position: absolute;
		width: 250px;
		height: 250px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29Scroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 174px;
		left: 0px;
		top: 338px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#VHG_MOB_29It_is_a_long_established_fact_ {
		left: 24px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 189px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#VHG_MOB_29VISION {
		left: 24px;
		top: 273px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_MOB_29Group_15120 {
		position: absolute;
		width: 390px;
		height: 512px;
		left: 0px;
		top: 562px;
		overflow: visible;
	}
	#VHG_MOB_29mk-s-7GeBVqFwOl4-unsplash {
		position: absolute;
		width: 250px;
		height: 250px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29Scroll_Group_28_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 174px;
		left: 0px;
		top: 338px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#VHG_MOB_29It_is_a_long_established_fact__ {
		left: 24px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 189px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#VHG_MOB_29MISSION {
		left: 24px;
		top: 273px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_MOB_29Group_15121 {
		position: absolute;
		width: 390px;
		height: 512px;
		left: 0px;
		top: 1124px;
		overflow: visible;
	}
	#VHG_MOB_29ricardo-gomez-angel-I5XthC605H {
		position: absolute;
		width: 250px;
		height: 250px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29Scroll_Group_28_bd {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 174px;
		left: 0px;
		top: 338px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#VHG_MOB_29It_is_a_long_established_fact__be {
		left: 24px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 353px;
		height: 189px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#VHG_MOB_29PRINCIPLES {
		left: 24px;
		top: 273px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_MOB_29Scroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 377px;
		height: 324px;
		left: 6.5px;
		top: 2675.998px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#VHG_MOB_29n_ {
		position: relative;
		width: 247px;
		height: 275px;
		left: 11px;
        margin-right: 30px;
		top: 21.001px;
		overflow: visible;
	}
	#VHG_MOB_29Mask_Group_22 {
		position: absolute;
		width: 247px;
		height: 229px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#VHG_MOB_29Rectangle_664 {
		fill: rgba(0,0,0,1);
	}
	.VHG_MOB_29Rectangle_664 {
		position: absolute;
		overflow: visible;
		width: 247px;
		height: 46px;
		left: 0px;
		top: 229.001px;
	}
	#VHG_MOB_29Something {
		left: 9px;
		top: 242.001px;
		position: absolute;
		overflow: visible;
		width: 233px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}
	#VHG_MOB_29Group_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 101px;
		top: 214.001px;
		overflow: visible;
	}
	#VHG_MOB_29Ellipse_38 {
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29Ellipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#VHG_MOB_29Ellipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29Ellipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#VHG_MOB_29Ellipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29Ellipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#VHG_MOB_29Ellipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29Ellipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#VHG_MOB_29Ellipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29Ellipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#VHG_MOB_29PRINCIPLES_br {
		left: 140px;
		top: 2599px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#VHG_MOB_29Group_15122 {
		position: absolute;
		width: 358px;
		height: 588.998px;
		left: 16px;
		top: 3099.998px;
		overflow: visible;
	}
	#VHG_MOB_29Group_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 45.5px;
		top: 59.997px;
		overflow: visible;
	}
	#VHG_MOB_29Rectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29Rectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#VHG_MOB_29Group_15050 {
		position: absolute;
		width: 358px;
		height: 316px;
		left: 0px;
		top: 272.998px;
		overflow: visible;
	}
	#VHG_MOB_29socials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 194px;
		overflow: visible;
	}
	#VHG_MOB_29linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29med_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#VHG_MOB_29linked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#VHG_MOB_29n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.VHG_MOB_29n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#VHG_MOB_29linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.VHG_MOB_29linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#VHG_MOB_29top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.VHG_MOB_29top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#VHG_MOB_29Insta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#VHG_MOB_29instagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#VHG_MOB_29whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29med_shadow_space_website_7_b {
		fill: rgba(255,255,255,1);
	}
	.VHG_MOB_29med_shadow_space_website_7_b {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#VHG_MOB_29whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#VHG_MOB_29n_234567890_mobile_number_shad {
		left: 48px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#VHG_MOB_29affanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#VHG_MOB_29address_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#VHG_MOB_29location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#VHG_MOB_29email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#VHG_MOB_29phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#VHG_MOB_29n_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#VHG_MOB_29Contact_Us {
		left: 109.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}