	.mediaViewInfo {
		--web-view-name: website 2;
		--web-view-id: website_2;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: website_2;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#website_2 {
		position: absolute;
		width: 1348px;
		height: 5858px;
        left:calc((100vw - 1348px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: website 2;
		--web-view-id: website_2;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#leaves_grp_website_2 {
		position: absolute;
		width: 379.067px;
		height: 432.061px;
		left: 1155.559px;
		top: 1574.629px;
		overflow: visible;
		padding: -49px 153px 312px 115px;
	}
	#Leave_down_website_2 {
		opacity: 0.8;
		transform: translate(-1185.559px, -1574.629px) matrix(1,0,0,1,1233.4896,1679.1857) rotate(-59deg);
		transform-origin: center;
		position: absolute;
		width: 283.54px;
		height: 271.864px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#leaf_stem_27 {
		fill: rgba(241,90,90,1);
	}
	.leaf_stem_27 {
		overflow: visible;
		position: absolute;
		width: 379.067px;
		height: 383.062px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_26 {
		fill: rgba(68,64,83,1);
	}
	.leaf_stem_26 {
		overflow: visible;
		position: absolute;
		width: 76.525px;
		height: 68.5px;
		left: 115.932px;
		top: 75.086px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_25 {
		fill: rgba(68,64,83,1);
	}
	.leaf_stem_25 {
		overflow: visible;
		position: absolute;
		width: 86.638px;
		height: 82.782px;
		left: 142.273px;
		top: 142.743px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_24 {
		fill: rgba(68,64,83,1);
	}
	.leaf_stem_24 {
		overflow: visible;
		position: absolute;
		width: 98.874px;
		height: 79.602px;
		left: 180.618px;
		top: 224.66px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_23 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_23 {
		overflow: visible;
		position: absolute;
		width: 217.719px;
		height: 253.72px;
		left: 99.207px;
		top: 80.212px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_22 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_22 {
		overflow: visible;
		position: absolute;
		width: 30.147px;
		height: 26.913px;
		left: 298.736px;
		top: 141.195px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_21 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_21 {
		overflow: visible;
		position: absolute;
		width: 52.303px;
		height: 46.538px;
		left: 275.906px;
		top: 161.714px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_20 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_20 {
		overflow: visible;
		position: absolute;
		width: 55.6px;
		height: 43.296px;
		left: 260.1px;
		top: 195.316px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_19 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_19 {
		overflow: visible;
		position: absolute;
		width: 51.087px;
		height: 35.309px;
		left: 232.005px;
		top: 230.8px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_18 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_18 {
		overflow: visible;
		position: absolute;
		width: 21.244px;
		height: 21.042px;
		left: 290.261px;
		top: 129.653px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_17 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_17 {
		overflow: visible;
		position: absolute;
		width: 48.514px;
		height: 42.8px;
		left: 246.583px;
		top: 131.361px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_16 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_16 {
		overflow: visible;
		position: absolute;
		width: 54.823px;
		height: 44.443px;
		left: 224.985px;
		top: 155.621px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_15 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_15 {
		overflow: visible;
		position: absolute;
		width: 37.998px;
		height: 33.676px;
		left: 215.132px;
		top: 193.436px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_14 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_14 {
		overflow: visible;
		position: absolute;
		width: 16.155px;
		height: 22.572px;
		left: 197.662px;
		top: 199.183px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_13 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_13 {
		overflow: visible;
		position: absolute;
		width: 17.024px;
		height: 17.628px;
		left: 177.763px;
		top: 199.693px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_12 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_12 {
		overflow: visible;
		position: absolute;
		width: 43.193px;
		height: 60.187px;
		left: 180.339px;
		top: 114.085px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_11 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_11 {
		overflow: visible;
		position: absolute;
		width: 71.427px;
		height: 65.708px;
		left: 113.983px;
		top: 149.533px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_0 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_0 {
		overflow: visible;
		position: absolute;
		width: 70.623px;
		height: 60.738px;
		left: 105.939px;
		top: 122.776px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_9 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 0.75px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_9 {
		overflow: visible;
		position: absolute;
		width: 38.862px;
		height: 57.255px;
		left: 168.101px;
		top: 81.657px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_8 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 0.75px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_8 {
		overflow: visible;
		position: absolute;
		width: 48.157px;
		height: 40.584px;
		left: 115.003px;
		top: 98.535px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_7 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 0.75px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_7 {
		overflow: visible;
		position: absolute;
		width: 19.803px;
		height: 28.369px;
		left: 148.393px;
		top: 66.773px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_6 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_6 {
		overflow: visible;
		position: absolute;
		width: 32.704px;
		height: 24.496px;
		left: 173.666px;
		top: 232.349px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_5 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_5 {
		overflow: visible;
		position: absolute;
		width: 37.563px;
		height: 48.802px;
		left: 164.931px;
		top: 261.837px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_4 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_4 {
		overflow: visible;
		position: absolute;
		width: 49.402px;
		height: 46.244px;
		left: 127.781px;
		top: 221.134px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_3 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_3 {
		overflow: visible;
		position: absolute;
		width: 35.015px;
		height: 44.787px;
		left: 128.642px;
		top: 266.755px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_2 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_2 {
		overflow: visible;
		position: absolute;
		width: 41.825px;
		height: 34.516px;
		left: 98.833px;
		top: 229.496px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_stem_1 {
		fill: transparent;
		stroke: rgba(68,64,83,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.leaf_stem_1 {
		overflow: visible;
		position: absolute;
		width: 18.299px;
		height: 24.148px;
		left: 100.59px;
		top: 265.299px;
		transform: matrix(1,0,0,1,0,0);
	}
	#top_leaves_website_2 {
		opacity: 0.6;
		transform: translate(-1185.559px, -1574.629px) matrix(1,0,0,1,1310.2941,1583.0225) rotate(12deg);
		transform-origin: center;
		position: absolute;
		width: 91.597px;
		height: 103.274px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#stem_red_website_2 {
		fill: transparent;
		stroke: rgba(83,84,97,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.stem_red_website_2 {
		overflow: visible;
		position: absolute;
		width: 79.759px;
		height: 76.252px;
		left: 15.422px;
		top: 43.073px;
		transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_12_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_12_website_2 {
		overflow: visible;
		position: absolute;
		width: 19.151px;
		height: 18.945px;
		left: 12.979px;
		top: 24.373px;
		transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_11_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_11_website_2 {
		overflow: visible;
		position: absolute;
		width: 21.929px;
		height: 16.481px;
		left: 13.035px;
		top: 44.094px;
		transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_0_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_0_website_2 {
		overflow: visible;
		position: absolute;
		width: 21.433px;
		height: 16.334px;
		left: 28.521px;
		top: 70.956px;
		transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_9_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_9_website_2 {
		overflow: visible;
		position: absolute;
		width: 21.978px;
		height: 16.477px;
		left: 48.535px;
		top: 88.288px;
		transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_8_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_8_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.54px;
		height: 22.156px;
		left: 29.276px;
		top: 41.359px;
		transform: matrix(1,0,0,1,0,0);
	}
	#green_lead_website_2_3 {
		fill: rgba(56,211,159,1);
	}
	.green_lead_website_2_3 {
		overflow: visible;
		position: absolute;
		width: 16.495px;
		height: 22.053px;
		left: 69.847px;
		top: 84.143px;
		transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_7_website_2 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.red_leaf_7_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.332px;
		height: 21.547px;
		left: 51.986px;
		top: 60.218px;
		transform: matrix(1,0,0,1,0,0);
	}
	#green_leaf_3_website_2 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.green_leaf_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.495px;
		height: 22.053px;
		left: 69.847px;
		top: 84.143px;
		transform: matrix(1,0,0,1,0,0);
	}
	#green_stem_6_website_2 {
		fill: transparent;
		stroke: rgba(83,84,97,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.green_stem_6_website_2 {
		overflow: visible;
		position: absolute;
		width: 53.908px;
		height: 90.037px;
		left: 45.092px;
		top: 27.91px;
		transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_5_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_5_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.315px;
		height: 21.184px;
		left: 51.597px;
		top: 7.242px;
		transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_4_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_4_website_2 {
		overflow: visible;
		position: absolute;
		width: 20.069px;
		height: 17.527px;
		left: 44.041px;
		top: 24.938px;
		transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_1_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 22.119px;
		height: 16.516px;
		left: 47.697px;
		top: 55.698px;
		transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_2_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 22.045px;
		height: 16.571px;
		left: 58.655px;
		top: 79.516px;
		transform: matrix(1,0,0,1,0,0);
	}
	#red_leaf_3_website_2 {
		fill: rgba(243,123,123,1);
	}
	.red_leaf_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.374px;
		height: 21.071px;
		left: 60.38px;
		top: 29.536px;
		transform: matrix(1,0,0,1,0,0);
	}
	#green_leaf_2_website_2 {
		fill: rgba(56,211,159,1);
	}
	.green_leaf_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 18.451px;
		height: 19.394px;
		left: 71.235px;
		top: 55.392px;
		transform: matrix(1,0,0,1,0,0);
	}
	#green_leaf_1_website_2 {
		fill: rgba(56,211,159,1);
	}
	.green_leaf_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.898px;
		height: 20.389px;
		left: 79.567px;
		top: 84.939px;
		transform: matrix(1,0,0,1,0,0);
	}

	#design_4_off_website_2 {
		position: absolute;
		width: 410px;
		height: 458.336px;
		left: 691.927px;
		top: 4410.342px;
		overflow: visible;
	}
	#design_3_off_website_2 {
		position: absolute;
		width: 400px;
		height: 435.369px;
		left: 170px;
		top: 3853.658px;
		overflow: visible;
	}
	#design_2_off_website_2 {
		position: absolute;
		width: 410.53px;
		height: 446.829px;
		left: 691.397px;
		top: 3286px;
		overflow: visible;
	}
	#design_1_off_website_2 {
		position: absolute;
		width: 400px;
		height: 446.829px;
		left: 170px;
		top: 2718px;
		overflow: visible;
	}
	#My_Offerings_lbl_website_2 {
		left: 580px;
		top: 2568px;
		position: absolute;
		overflow: visible;
		width: 176px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(54,54,54,1);
	}
	#Mission_lbl_website_2 {
		left: 766px;
		top: 1822px;
		position: absolute;
		overflow: visible;
		width: 72px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(54,54,54,1);
	}
	#Scroll_mission_webste_2 {
		mix-blend-mode: normal;
		position: absolute;
		width: 459px;
		height: 255px;
		left: 754px;
		top: 1862px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#mission_description_website_2 {
		left: 12px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 448px;
		height: 226px;
		line-height: 32px;
		margin-top: -7px;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(54,54,54,1);
	}
	#mission_all_grp_website_2 {
		position: absolute;
		width: 443.51px;
		height: 227.818px;
		left: 133.965px;
		top: 1799.776px;
		overflow: visible;
	}
	#pc_stand_2_website_2 {
		fill: rgba(188,188,188,1);
	}
	.pc_stand_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 71.221px;
		height: 42.002px;
		left: 186.145px;
		top: 168.01px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pc_sfcreen_website_2 {
		fill: rgba(82,83,96,1);
	}
	.pc_sfcreen_website_2 {
		overflow: visible;
		position: absolute;
		width: 242.884px;
		height: 147.922px;
		left: 100.312px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pc_border_website_2 {
		fill: rgba(188,188,188,1);
	}
	.pc_border_website_2 {
		overflow: visible;
		position: absolute;
		width: 242.884px;
		height: 22.828px;
		left: 100.313px;
		top: 147.922px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pc_logo_website_2 {
		fill: rgba(82,83,96,1);
	}
	.pc_logo_website_2 {
		overflow: visible;
		position: absolute;
		width: 8.218px;
		height: 8.218px;
		left: 217.645px;
		top: 155.227px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pc_stand_down_website_2 {
		fill: rgba(188,188,188,1);
	}
	.pc_stand_down_website_2 {
		overflow: visible;
		position: absolute;
		width: 85.831px;
		height: 9.131px;
		left: 178.839px;
		top: 197.686px;
		transform: matrix(1,0,0,1,0,0);
	}
	#center_screen_white_website_2 {
		fill: rgba(255,255,255,1);
	}
	.center_screen_white_website_2 {
		overflow: visible;
		position: absolute;
		width: 146.799px;
		height: 83.987px;
		left: 148.355px;
		top: 31.73px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_box_1_website_2 {
		fill: rgba(63,117,225,1);
	}
	.screen_box_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.18px;
		height: 14.564px;
		left: 189.249px;
		top: 43.788px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_box_1_line_top_website_ {
		fill: rgba(223,223,223,1);
	}
	.screen_box_1_line_top_website_ {
		overflow: visible;
		position: absolute;
		width: 43.687px;
		height: 1.616px;
		left: 210.281px;
		top: 43.788px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_box_1_line_down_website {
		fill: rgba(223,223,223,1);
	}
	.screen_box_1_line_down_website {
		overflow: visible;
		position: absolute;
		width: 36.314px;
		height: 1.616px;
		left: 210.281px;
		top: 48.645px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_box_2_website_2 {
		fill: rgba(63,117,225,1);
	}
	.screen_box_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.18px;
		height: 14.564px;
		left: 189.249px;
		top: 66.441px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_box_line_top_2_website_ {
		fill: rgba(223,223,223,1);
	}
	.screen_box_line_top_2_website_ {
		overflow: visible;
		position: absolute;
		width: 43.687px;
		height: 1.616px;
		left: 210.281px;
		top: 66.441px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_box_line_2_website_2 {
		fill: rgba(223,223,223,1);
	}
	.screen_box_line_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 43.687px;
		height: 1.616px;
		left: 210.281px;
		top: 71.294px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_box_3_website_2 {
		fill: rgba(63,117,225,1);
	}
	.screen_box_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.18px;
		height: 14.564px;
		left: 189.249px;
		top: 89.095px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_section_2_line_website_ {
		fill: rgba(223,223,223,1);
	}
	.screen_section_2_line_website_ {
		overflow: visible;
		position: absolute;
		width: 43.687px;
		height: 1.616px;
		left: 210.281px;
		top: 89.095px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screeen_section_line_website_2 {
		fill: rgba(223,223,223,1);
	}
	.screeen_section_line_website_2 {
		overflow: visible;
		position: absolute;
		width: 43.979px;
		height: 1.616px;
		left: 210.282px;
		top: 93.949px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_dot_website_2 {
		fill: rgba(56,210,159,1);
	}
	.screen_dot_website_2 {
		overflow: visible;
		position: absolute;
		width: 3.652px;
		height: 3.652px;
		left: 219.928px;
		top: 32.872px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_top_dot_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.screen_top_dot_website_2 {
		overflow: visible;
		position: absolute;
		width: 4.652px;
		height: 4.652px;
		left: 219.928px;
		top: 17.805px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_top_line_2_website {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.screen_top_line_2_website {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 10.044px;
		left: 221.754px;
		top: 7.761px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_top_line_1_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.screen_top_line_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 13.181px;
		left: 221.754px;
		top: 21.517px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_top_down_dot_website_2 {
		fill: rgba(0,0,0,1);
	}
	.screen_top_down_dot_website_2 {
		overflow: visible;
		position: absolute;
		width: 3.652px;
		height: 3.652px;
		left: 219.928px;
		top: 32.872px;
		transform: matrix(1,0,0,1,0,0);
	}
	#scrren_top_dot_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.scrren_top_dot_website_2 {
		overflow: visible;
		position: absolute;
		width: 4.652px;
		height: 4.652px;
		left: 219.928px;
		top: 17.805px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_line_down_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.screen_line_down_website_2 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 22.018px;
		left: 221.754px;
		top: 113.224px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_dot_down_website_2 {
		fill: rgba(0,0,0,1);
	}
	.screen_dot_down_website_2 {
		overflow: visible;
		position: absolute;
		width: 3.652px;
		height: 3.652px;
		left: 219.928px;
		top: 111.398px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_line_left_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.screen_line_left_website_2 {
		overflow: visible;
		position: absolute;
		width: 22.594px;
		height: 2px;
		left: 129.536px;
		top: 75.559px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_dot_right_website_2 {
		fill: rgba(0,0,0,1);
	}
	.screen_dot_right_website_2 {
		overflow: visible;
		position: absolute;
		width: 3.652px;
		height: 3.652px;
		left: 150.304px;
		top: 73.733px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_mid_stem_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.pot_1_mid_stem_website_2 {
		overflow: visible;
		position: absolute;
		width: 15.359px;
		height: 87.627px;
		left: 44.938px;
		top: 89.484px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_website_2 {
		fill: rgba(241,90,90,1);
	}
	.pot_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 75.444px;
		height: 42.002px;
		left: 8.979px;
		top: 164.814px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_5_website_2 {
		fill: rgba(77,182,172,1);
	}
	.pot_1_leaf_5_website_2 {
		overflow: visible;
		position: absolute;
		width: 25.11px;
		height: 17.305px;
		left: 53.973px;
		top: 83.079px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_4_website_2 {
		fill: rgba(77,182,172,1);
	}
	.pot_1_leaf_4_website_2 {
		overflow: visible;
		position: absolute;
		width: 13.756px;
		height: 22.623px;
		left: 38.45px;
		top: 89.255px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_3_website_2 {
		fill: rgba(77,182,172,1);
	}
	.pot_1_leaf_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 29.187px;
		height: 25.282px;
		left: 46.7px;
		top: 104.321px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_2_website_2 {
		fill: rgba(77,182,172,1);
	}
	.pot_1_leaf_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 18.467px;
		height: 35.077px;
		left: 27.509px;
		top: 111.169px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_1_website_2 {
		fill: rgba(77,182,172,1);
	}
	.pot_1_leaf_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 18.28px;
		height: 30.927px;
		left: 45.28px;
		top: 132.627px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_stem5_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.pot_1_leaf_stem5_website_2 {
		overflow: visible;
		position: absolute;
		width: 25.106px;
		height: 16.397px;
		left: 54.201px;
		top: 83.548px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_stem_4_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.pot_1_leaf_stem_4_website_2 {
		overflow: visible;
		position: absolute;
		width: 29.742px;
		height: 25.927px;
		left: 46.668px;
		top: 104.321px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_stem_3_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.pot_1_leaf_stem_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 12.829px;
		height: 22.309px;
		left: 38.45px;
		top: 89.255px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_stem_2_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.pot_1_leaf_stem_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 13.115px;
		height: 35.662px;
		left: 32.515px;
		top: 111.17px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_stem_1_webstite_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.pot_1_leaf_stem_1_webstite_2 {
		overflow: visible;
		position: absolute;
		width: 18.793px;
		height: 31.712px;
		left: 45.298px;
		top: 132.627px;
		transform: matrix(1,0,0,1,0,0);
	}
	#tp_table_ {
		fill: rgba(223,223,223,1);
	}
	.tp_table_ {
		overflow: visible;
		position: absolute;
		width: 443.51px;
		height: 15.034px;
		left: 0px;
		top: 206.634px;
		transform: matrix(1,0,0,1,0,0);
	}
	#table_below_website_2 {
		fill: rgba(223,223,223,1);
	}
	.table_below_website_2 {
		overflow: visible;
		position: absolute;
		width: 405.922px;
		height: 6.15px;
		left: 18.792px;
		top: 221.668px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_line_right_website_2 {
		fill: rgba(0,0,0,0);
		stroke: rgba(241,90,90,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.screen_line_right_website_2 {
		overflow: visible;
		position: absolute;
		width: 20.49px;
		height: 2px;
		left: 290.921px;
		top: 75.559px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_dot_right_website_2_ed {
		fill: rgba(0,0,0,1);
	}
	.screen_dot_right_website_2_ed {
		overflow: visible;
		position: absolute;
		width: 3.652px;
		height: 3.652px;
		left: 289.095px;
		top: 73.733px;
		transform: matrix(1,0,0,1,0,0);
	}
	#screen_dot_website_2_ee {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.screen_dot_website_2_ee {
		overflow: visible;
		position: absolute;
		width: 4.652px;
		height: 4.652px;
		left: 311.466px;
		top: 73.733px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pen_3_website_2 {
		fill: rgba(82,83,96,1);
	}
	.pen_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 7.305px;
		height: 45.655px;
		left: 381.546px;
		top: 145.183px;
		transform: matrix(1,0,0,1,0,0);
	}
	#top_cap_2_website_2 {
		fill: rgba(57,210,159,1);
	}
	.top_cap_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 7.305px;
		height: 15.066px;
		left: 381.546px;
		top: 130.117px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pen_bottom_2_website_2 {
		fill: rgba(82,83,96,1);
	}
	.pen_bottom_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 7.305px;
		height: 45.655px;
		left: 391.59px;
		top: 148.835px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pen_2_tcap_website_2 {
		fill: rgba(77,137,239,1);
	}
	.pen_2_tcap_website_2 {
		overflow: visible;
		position: absolute;
		width: 7.305px;
		height: 15.066px;
		left: 391.59px;
		top: 133.769px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pen_1_bottom_website_2 {
		fill: rgba(82,83,96,1);
	}
	.pen_1_bottom_website_2 {
		overflow: visible;
		position: absolute;
		width: 7.305px;
		height: 45.655px;
		left: 403.917px;
		top: 156.596px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pen_1_website_2 {
		fill: rgba(245,95,68,1);
	}
	.pen_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 7.305px;
		height: 15.066px;
		left: 403.917px;
		top: 141.53px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pen_pot_website_2 {
		fill: rgba(241,90,90,1);
	}
	.pen_pot_website_2 {
		overflow: visible;
		position: absolute;
		width: 42.002px;
		height: 47.024px;
		left: 375.155px;
		top: 159.335px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Path_443 {
		fill: rgba(77,182,172,1);
	}
	.Path_443 {
		overflow: visible;
		position: absolute;
		width: 12.495px;
		height: 22.623px;
		left: 43.641px;
		top: 68.687px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_leaf_3_website_2_en {
		fill: rgba(0,0,0,0);
		stroke: rgba(82,83,96,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.pot_1_leaf_3_website_2_en {
		overflow: visible;
		position: absolute;
		width: 12.133px;
		height: 21.585px;
		left: 44.219px;
		top: 69.395px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Dotted_LIne2_princi_to_mision {
		fill: transparent;
		stroke: rgba(209,209,209,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 7;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Dotted_LIne2_princi_to_mision {
		overflow: visible;
		position: absolute;
		width: 588.013px;
		height: 202.611px;
		left: 365px;
		top: 1597px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_all_grp_websiet_2 {
		position: absolute;
		width: 1035.795px;
		height: 391.5px;
		left: 171px;
		top: 1319.5px;
		overflow: visible;
	}
	#principle_img_website_2_grp {
		position: absolute;
		width: 470.063px;
		height: 390.718px;
		left: 565.732px;
		top: 0px;
		overflow: visible;
	}
	#principle_img_website_2_bg_des {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_bg_des {
		overflow: visible;
		position: absolute;
		width: 377.025px;
		height: 252.367px;
		left: 30.875px;
		top: 25.741px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaves_principle_img_website_2 {
		opacity: 0.6;
		position: absolute;
		width: 80.203px;
		height: 100.975px;
		left: 79.886px;
		top: 43.098px;
		overflow: visible;
	}
	#principle_img_website_2_leaf_s {
		fill: transparent;
		stroke: rgba(83,84,97,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_leaf_s {
		overflow: visible;
		position: absolute;
		width: 66.569px;
		height: 62.099px;
		left: 15.416px;
		top: 39.778px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf__ {
		fill: rgba(239,174,171,1);
	}
	.principle_img_website_2_leaf__ {
		overflow: visible;
		position: absolute;
		width: 15.833px;
		height: 15.572px;
		left: 0px;
		top: 24.362px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_1 {
		fill: rgba(239,174,171,1);
	}
	.principle_img_website_2_leaf_1 {
		overflow: visible;
		position: absolute;
		width: 19.29px;
		height: 12.374px;
		left: 3.412px;
		top: 43.35px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_1_ew {
		fill: rgba(239,174,171,1);
	}
	.principle_img_website_2_leaf_1_ew {
		overflow: visible;
		position: absolute;
		width: 18.802px;
		height: 12.332px;
		left: 23.674px;
		top: 65.893px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_1_ex {
		fill: rgba(239,174,171,1);
	}
	.principle_img_website_2_leaf_1_ex {
		overflow: visible;
		position: absolute;
		width: 19.342px;
		height: 12.359px;
		left: 46.343px;
		top: 78.4px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_1_ey {
		fill: rgba(239,174,171,1);
	}
	.principle_img_website_2_leaf_1_ey {
		overflow: visible;
		position: absolute;
		width: 12.385px;
		height: 19.514px;
		left: 19.839px;
		top: 37.125px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_1_ez {
		fill: rgba(239,174,171,1);
	}
	.principle_img_website_2_leaf_1_ez {
		overflow: visible;
		position: absolute;
		width: 12.303px;
		height: 18.922px;
		left: 45.272px;
		top: 50.569px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_1_e {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.principle_img_website_2_leaf_1_e {
		overflow: visible;
		position: absolute;
		width: 15.833px;
		height: 15.572px;
		left: 0px;
		top: 24.362px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_1_fa {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.principle_img_website_2_leaf_1_fa {
		overflow: visible;
		position: absolute;
		width: 19.29px;
		height: 12.374px;
		left: 3.412px;
		top: 43.35px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_9 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.principle_img_website_2_leaf_9 {
		overflow: visible;
		position: absolute;
		width: 18.802px;
		height: 12.332px;
		left: 23.674px;
		top: 65.893px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_8 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.principle_img_website_2_leaf_8 {
		overflow: visible;
		position: absolute;
		width: 19.342px;
		height: 12.359px;
		left: 46.343px;
		top: 78.4px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_7 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.principle_img_website_2_leaf_7 {
		overflow: visible;
		position: absolute;
		width: 12.385px;
		height: 19.514px;
		left: 19.839px;
		top: 37.125px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_6 {
		opacity: 0.25;
		fill: rgba(0,0,0,1);
	}
	.principle_img_website_2_leaf_6 {
		overflow: visible;
		position: absolute;
		width: 12.303px;
		height: 18.922px;
		left: 45.272px;
		top: 50.569px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_stem {
		fill: transparent;
		stroke: rgba(83,84,97,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_stem {
		overflow: visible;
		position: absolute;
		width: 36.444px;
		height: 82.264px;
		left: 44.974px;
		top: 18.339px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_5 {
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_leaf_5 {
		overflow: visible;
		position: absolute;
		width: 12.366px;
		height: 18.547px;
		left: 34.055px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_4 {
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_leaf_4 {
		overflow: visible;
		position: absolute;
		width: 17.109px;
		height: 13.883px;
		left: 29.478px;
		top: 18.662px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_3 {
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_leaf_3 {
		overflow: visible;
		position: absolute;
		width: 19.48px;
		height: 12.368px;
		left: 38.919px;
		top: 47.402px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_2 {
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_leaf_2 {
		overflow: visible;
		position: absolute;
		width: 19.391px;
		height: 12.442px;
		left: 54.256px;
		top: 67.95px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_leaf_1_fb {
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_leaf_1_fb {
		overflow: visible;
		position: absolute;
		width: 12.452px;
		height: 18.416px;
		left: 46.96px;
		top: 19.542px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_card_1 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_card_1 {
		overflow: visible;
		position: absolute;
		width: 95.792px;
		height: 138.063px;
		left: 132.735px;
		top: 84.712px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_x_2 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_x_2 {
		overflow: visible;
		position: absolute;
		width: 22.479px;
		height: 29.086px;
		left: 169.167px;
		top: 96.062px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_x_1 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_x_1 {
		overflow: visible;
		position: absolute;
		width: 18.875px;
		height: 27.838px;
		left: 173.018px;
		top: 96.062px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_box_5 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_box_5 {
		overflow: visible;
		position: absolute;
		width: 60.323px;
		height: 15.571px;
		left: 150.329px;
		top: 140.97px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2__tick_ {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2__tick_ {
		overflow: visible;
		position: absolute;
		width: 45.232px;
		height: 15.571px;
		left: 157.875px;
		top: 187.284px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_box_4 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_box_4 {
		overflow: visible;
		position: absolute;
		width: 60.607px;
		height: 15.832px;
		left: 150.329px;
		top: 162.065px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2__tick2 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2__tick2 {
		overflow: visible;
		position: absolute;
		width: 9.603px;
		height: 8.867px;
		left: 174.683px;
		top: 189.625px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_design {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_design {
		overflow: visible;
		position: absolute;
		width: 95.987px;
		height: 136.014px;
		left: 272.538px;
		top: 87.371px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_box_3 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_box_3 {
		overflow: visible;
		position: absolute;
		width: 60.323px;
		height: 8.702px;
		left: 291.351px;
		top: 144.353px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_design_fl {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_design_fl {
		overflow: visible;
		position: absolute;
		width: 60.649px;
		height: 8.842px;
		left: 291.351px;
		top: 155.464px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_deisgn {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_deisgn {
		overflow: visible;
		position: absolute;
		width: 86.306px;
		height: 4.377px;
		left: 280.996px;
		top: 97.102px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_box_2 {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_box_2 {
		overflow: visible;
		position: absolute;
		width: 8.239px;
		height: 6.314px;
		left: 284.062px;
		top: 89.958px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_box {
		fill: rgba(255,255,255,1);
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_box {
		overflow: visible;
		position: absolute;
		width: 25.557px;
		height: 27.606px;
		left: 286.095px;
		top: 106.729px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_arrow {
		fill: transparent;
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_arrow {
		overflow: visible;
		position: absolute;
		width: 24.1px;
		height: 1.516px;
		left: 236.347px;
		top: 145.711px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_arrow_ {
		fill: transparent;
		stroke: rgba(51,49,63,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.principle_img_website_2_arrow_ {
		overflow: visible;
		position: absolute;
		width: 12.216px;
		height: 21.07px;
		left: 257.683px;
		top: 135.246px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_circle {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_circle {
		position: absolute;
		overflow: visible;
		width: 22.48px;
		height: 22.48px;
		left: 105.368px;
		top: 0px;
	}
	#principle_img_website_2_circle_fs {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_circle_fs {
		position: absolute;
		overflow: visible;
		width: 22.48px;
		height: 22.48px;
		left: 276.625px;
		top: 15.56px;
	}
	#principle_img_website_2_circle_ft {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_circle_ft {
		position: absolute;
		overflow: visible;
		width: 22.48px;
		height: 22.48px;
		left: 396.936px;
		top: 182.288px;
	}
	#principle_img_website_2_circle_fu {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_circle_fu {
		width: 37.779px;
		height: 37.675px;
		position: absolute;
		overflow: visible;
		transform: translate(-59px, 0px) matrix(1,0,0,1,488.5521,124.4961) rotate(-80.782deg);
		transform-origin: center;
	}
	#principle_img_website_2_cirlce {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_cirlce {
		position: absolute;
		overflow: visible;
		width: 37.779px;
		height: 37.779px;
		left: 389.72px;
		top: 26.819px;
	}
	#principle_img_website_2_circle_fw {
		opacity: 0.1;
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_circle_fw {
		position: absolute;
		overflow: visible;
		width: 37.779px;
		height: 37.779px;
		left: 0px;
		top: 150.293px;
	}
	#principle_img_website_2_body_s_fy {
		fill: url(#principle_img_website_2_body_s_fy);
	}
	.principle_img_website_2_body_s_fy {
		overflow: visible;
		position: absolute;
		width: 103.474px;
		height: 260.073px;
		left: 282.347px;
		top: 127.676px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_pen {
		fill: rgba(101,97,125,1);
	}
	.principle_img_website_2_pen {
		overflow: visible;
		position: absolute;
		width: 17.186px;
		height: 18.159px;
		left: 297.291px;
		top: 137.566px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_hand_2 {
		fill: rgba(251,190,190,1);
	}
	.principle_img_website_2_hand_2 {
		width: 7.792px;
		height: 22.264px;
		position: absolute;
		overflow: visible;
		transform: translate(-59px, 0px) matrix(0.9999,0,0,0.9999,364.6128,137.2293) rotate(-11.579deg);
		transform-origin: center;
	}
	#principle_img_website_2_shoe {
		fill: rgba(63,61,86,1);
	}
	.principle_img_website_2_shoe {
		overflow: visible;
		position: absolute;
		width: 23.575px;
		height: 11.029px;
		left: 279.877px;
		top: 378.758px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_face {
		fill: rgba(251,190,190,1);
	}
	.principle_img_website_2_face {
		position: absolute;
		overflow: visible;
		width: 28.804px;
		height: 28.804px;
		left: 336.741px;
		top: 130.052px;
	}
	#principle_img_website_2_hand_s {
		fill: rgba(63,61,86,1);
	}
	.principle_img_website_2_hand_s {
		overflow: visible;
		position: absolute;
		width: 15.016px;
		height: 35.227px;
		left: 364.445px;
		top: 224.723px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_hand_s_f {
		fill: rgba(63,61,86,1);
	}
	.principle_img_website_2_hand_s_f {
		overflow: visible;
		position: absolute;
		width: 15.831px;
		height: 19.854px;
		left: 305.64px;
		top: 146.099px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_shirt_ {
		fill: rgba(190,190,190,1);
	}
	.principle_img_website_2_shirt_ {
		overflow: visible;
		position: absolute;
		width: 50.162px;
		height: 79.006px;
		left: 324.564px;
		top: 161.135px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_top_sh {
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_top_sh {
		overflow: visible;
		position: absolute;
		width: 60.88px;
		height: 49.319px;
		left: 325.191px;
		top: 167.06px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_left_h {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.principle_img_website_2_left_h {
		overflow: visible;
		position: absolute;
		width: 9.569px;
		height: 8.326px;
		left: 311.902px;
		top: 157.628px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_left_a {
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_left_a {
		overflow: visible;
		position: absolute;
		width: 19.368px;
		height: 25.889px;
		left: 312.711px;
		top: 158.063px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_pant {
		fill: rgba(51,49,63,1);
	}
	.principle_img_website_2_pant {
		overflow: visible;
		position: absolute;
		width: 83.558px;
		height: 157.291px;
		left: 288.318px;
		top: 233.428px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2arm {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.principle_img_website_2arm {
		overflow: visible;
		position: absolute;
		width: 10.993px;
		height: 5.17px;
		left: 368.467px;
		top: 224.654px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_should {
		fill: rgba(243,123,123,1);
	}
	.principle_img_website_2_should {
		overflow: visible;
		position: absolute;
		width: 17.498px;
		height: 43.423px;
		left: 367.789px;
		top: 185.565px;
		transform: matrix(1,0,0,1,0,0);
	}
	#principle_img_website_2_hair {
		fill: rgba(51,49,63,1);
	}
	.principle_img_website_2_hair {
		overflow: visible;
		position: absolute;
		width: 50.187px;
		height: 79.096px;
		left: 330.827px;
		top: 124.422px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Principles_lbl_website_2 {
		left: 6px;
		top: 105.5px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(54,54,54,1);
	}
	#Scroll_princi_website_2 {
		mix-blend-mode: normal;
		position: absolute;
		width: 438px;
		height: 251px;
		left: 0px;
		top: 140.5px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#principle_desicrption_2 {
		left: 5px;
		top: 16px;
		position: absolute;
		overflow: visible;
		width: 422px;
		/* white-space: nowrap; */
		line-height: 32px;
		margin-top: -7px;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(54,54,54,1);
	}
	#Dotted_LIne1_vision_to_princi {
		fill: transparent;
		stroke: rgba(209,209,209,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 7;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Dotted_LIne1_vision_to_princi {
		overflow: visible;
		position: absolute;
		width: 556.016px;
		height: 211.021px;
		left: 395.997px;
		top: 1165px;
		transform: matrix(1,0,0,1,0,0);
	}
	#vision_all_grp_website_2 {
		position: absolute;
		width: 1075px;
		height: 309.932px;
		left: 157px;
		top: 945.068px;
		overflow: visible;
	}
	#Vision_lbl_website_2 {
		left: 616px;
		top: 40.932px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(54,54,54,1);
	}
	#scroll_vision_website_2 {
		mix-blend-mode: normal;
		position: absolute;
		width: 468px;
		height: 228px;
		left: 607px;
		top: 81.932px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#vision_description_website_2 {
		left: 7px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 100%;
		/* white-space: nowrap; */
		line-height: 32px;
		margin-top: -7px;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(54,54,54,1);
	}
	#visin_img_website_2 {
		position: absolute;
		width: 429.523px;
		height: 309.698px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#vision_img_website_2_bg_deisgn {
		opacity: 0.1;
		fill: rgba(239,122,120,1);
	}
	.vision_img_website_2_bg_deisgn {
		overflow: visible;
		position: absolute;
		width: 429.523px;
		height: 213.629px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#shadow_vision_img_website_2_ {
		opacity: 0.1;
		fill: rgba(56,211,159,1);
	}
	.shadow_vision_img_website_2_ {
		position: absolute;
		overflow: visible;
		width: 185.505px;
		height: 13.189px;
		left: 19.093px;
		top: 296.508px;
	}
	#vision_img_website_2_hand_left {
		fill: rgba(251,190,190,1);
	}
	.vision_img_website_2_hand_left {
		overflow: visible;
		position: absolute;
		width: 19.766px;
		height: 25.835px;
		left: 123.843px;
		top: 52.587px;
		transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_left_hand {
		fill: rgba(241,90,90,1);
	}
	.vision_img_website_2_left_hand {
		overflow: visible;
		position: absolute;
		width: 50.344px;
		height: 44.337px;
		left: 84.147px;
		top: 73.901px;
		transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_spin_cent {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_cent {
		overflow: visible;
		position: absolute;
		width: 73.011px;
		height: 73.011px;
		left: 200.641px;
		top: 102.578px;
		transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_spin_12 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_12 {
		position: absolute;
		overflow: visible;
		width: 8.552px;
		height: 9.02px;
		left: 231.743px;
		top: 94.026px;
	}
	#vision_img_website_2_spin_11 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_11 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,252.4276,108.9862) rotate(-59.003deg);
		transform-origin: center;
	}
	#vision_img_website_2_spin_0 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_0 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,267.4633,123.636) rotate(-29deg);
		transform-origin: center;
	}
	#vision_img_website_2_spin_9 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_9 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(0.9999,0,0,0.9999,267.8978,164.5281) rotate(-57.999deg);
		transform-origin: center;
	}
	#vision_img_website_2_spin_8 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_8 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,198.1,123.3897) rotate(-61deg);
		transform-origin: center;
	}
	#vision_img_website_2_spin_7 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_7 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(0.9999,0,0,0.9999,197.6771,164.7309) rotate(-32.001deg);
		transform-origin: center;
	}
	#vision_img_website_2_spin_6 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_6 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,213.1423,108.7995) rotate(-30.997deg);
		transform-origin: center;
	}
	#vision_img_website_2_spin_5 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_5 {
		position: absolute;
		overflow: visible;
		width: 8.552px;
		height: 9.02px;
		left: 231.743px;
		top: 175.164px;
	}
	#vision_img_website_2_spin_4 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_4 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,252.6638,179.4843) rotate(-30.997deg);
		transform-origin: center;
	}
	#vision_img_website_2_spin_3 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_3 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,212.8667,179.6854) rotate(-59.003deg);
		transform-origin: center;
	}
	#vision_img_website_2_spin_2 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_2 {
		position: absolute;
		overflow: visible;
		width: 10.381px;
		height: 7.659px;
		left: 191.196px;
		top: 135.51px;
	}
	#vision_img_website_2_spin_ {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spin_ {
		position: absolute;
		overflow: visible;
		width: 10.381px;
		height: 7.659px;
		left: 272.759px;
		top: 135.51px;
	}
	#vision_img_website_2_spi_cente {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_cente {
		overflow: visible;
		position: absolute;
		width: 73.011px;
		height: 73.011px;
		left: 268.674px;
		top: 49.862px;
		transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_spi_12 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_12 {
		position: absolute;
		overflow: visible;
		width: 8.552px;
		height: 9.02px;
		left: 299.776px;
		top: 41.311px;
	}
	#vision_img_website_2_spi11 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi11 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,320.4677,56.2764) rotate(-59.003deg);
		transform-origin: center;
	}
	#vision_img_website_2_spi_0 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_0 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,335.4995,70.9262) rotate(-29deg);
		transform-origin: center;
	}
	#vision_img_website_2_spi_9 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_9 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(0.9999,0,0,0.9999,335.9021,111.7984) rotate(-57.999deg);
		transform-origin: center;
	}
	#vision_img_website_2_spi_8 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_8 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,266.1355,70.681) rotate(-61deg);
		transform-origin: center;
	}
	#vision_img_website_2_spi_7 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_7 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(0.9999,0,0,0.9999,265.7093,112.0224) rotate(-32.001deg);
		transform-origin: center;
	}
	#vision_img_website_2_spi_6 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_6 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,281.1836,56.0881) rotate(-30.997deg);
		transform-origin: center;
	}
	#vision_img_website_2_spi_5 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_5 {
		position: absolute;
		overflow: visible;
		width: 8.552px;
		height: 9.02px;
		left: 299.776px;
		top: 122.448px;
	}
	#vision_img_website_2_spi_4 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_4 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,320.7036,126.7732) rotate(-30.997deg);
		transform-origin: center;
	}
	#vision_img_website_2_spi_3 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_3 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,280.9248,126.935) rotate(-59.003deg);
		transform-origin: center;
	}
	#vision_img_website_2_spi_2 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_2 {
		position: absolute;
		overflow: visible;
		width: 10.381px;
		height: 7.659px;
		left: 259.229px;
		top: 82.794px;
	}
	#vision_img_website_2_spi_1 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spi_1 {
		position: absolute;
		overflow: visible;
		width: 10.381px;
		height: 7.659px;
		left: 340.792px;
		top: 82.794px;
	}
	#vision_img_website_2_spiral_ce {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral_ce {
		overflow: visible;
		position: absolute;
		width: 73.054px;
		height: 73.011px;
		left: 132.523px;
		top: 47.607px;
		transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_spiral12 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral12 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,164.8825,48.8331) rotate(-88.01deg);
		transform-origin: center;
	}
	#vision_img_website_2_spiral11 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral11 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,185.6119,54.7445) rotate(-57.011deg);
		transform-origin: center;
	}
	#vision_img_website_2_spiral0 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral0 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,200.0959,69.8694) rotate(-27.008deg);
		transform-origin: center;
	}
	#vision_img_website_2_spiral_9 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral_9 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(0.9999,0,0,0.9999,199.1349,110.7294) rotate(-56.011deg);
		transform-origin: center;
	}
	#vision_img_website_2_spiral8 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral8 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,130.807,67.2176) rotate(-59.011deg);
		transform-origin: center;
	}
	#vision_img_website_2_spiral7 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral7 {
		width: 9.063px;
		height: 8.595px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,128.3039,109.2181) rotate(-29.888deg);
		transform-origin: center;
	}
	#vision_img_website_2_spiral6 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral6 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,146.3344,53.1297) rotate(-29.013deg);
		transform-origin: center;
	}
	#vision_img_website_2_spiral5 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral5 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,162.0675,129.8833) rotate(-88.01deg);
		transform-origin: center;
	}
	#vision_img_website_2_spiral4 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral4 {
		width: 8.552px;
		height: 9.02px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,183.3973,125.1601) rotate(-29.013deg);
		transform-origin: center;
	}
	#vision_img_website_2_spiral3 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral3 {
		width: 9.02px;
		height: 8.552px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,143.6654,124.0083) rotate(-57.011deg);
		transform-origin: center;
	}
	#vision_img_website_2_spiral_2 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral_2 {
		width: 7.658px;
		height: 10.382px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,124.5697,87.2717) rotate(-88.01deg);
		transform-origin: center;
	}
	#vision_img_website_2_spiral_1 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_spiral_1 {
		width: 7.658px;
		height: 10.382px;
		position: absolute;
		overflow: visible;
		transform: translate(-0.026px, -9.533px) matrix(1,0,0,1,206.0729,90.0859) rotate(-88.01deg);
		transform-origin: center;
	}
	#vision_img_website_2_shoe_2 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_shoe_2 {
		overflow: visible;
		position: absolute;
		width: 22.905px;
		height: 33.394px;
		left: 62.313px;
		top: 269.773px;
		transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_shoe {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_shoe {
		overflow: visible;
		position: absolute;
		width: 38.022px;
		height: 20.639px;
		left: 139.033px;
		top: 285.787px;
		transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_neck {
		fill: rgba(251,190,190,1);
	}
	.vision_img_website_2_neck {
		overflow: visible;
		position: absolute;
		width: 30.847px;
		height: 24.466px;
		left: 67.596px;
		top: 94.876px;
		transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_face {
		fill: rgba(251,190,190,1);
	}
	.vision_img_website_2_face {
		position: absolute;
		overflow: visible;
		width: 31.145px;
		height: 31.145px;
		left: 62.406px;
		top: 71.774px;
	}
	#vision_img_website_2_shirt {
		fill: rgba(241,90,90,1);
	}
	.vision_img_website_2_shirt {
		overflow: visible;
		position: absolute;
		width: 80.588px;
		height: 67.142px;
		left: 63.465px;
		top: 109.333px;
		transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_hand_2 {
		fill: rgba(251,190,190,1);
	}
	.vision_img_website_2_hand_2 {
		overflow: visible;
		position: absolute;
		width: 19.766px;
		height: 25.835px;
		left: 118.737px;
		top: 52.587px;
		transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_leg_1 {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_leg_1 {
		overflow: visible;
		position: absolute;
		width: 102.924px;
		height: 122.281px;
		left: 77.808px;
		top: 163.293px;
		transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_hand_1 {
		fill: rgba(241,90,90,1);
	}
	.vision_img_website_2_hand_1 {
		overflow: visible;
		position: absolute;
		width: 67.687px;
		height: 62.927px;
		left: 61.943px;
		top: 72.37px;
		transform: matrix(1,0,0,1,0,0);
	}
	#vision_img_website_2_hair {
		fill: rgba(63,61,86,1);
	}
	.vision_img_website_2_hair {
		overflow: visible;
		position: absolute;
		width: 40.75px;
		height: 80.38px;
		left: 45.259px;
		top: 65.213px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Our_Objectives_website_2 {
		left: 575px;
		top: 849px;
		position: absolute;
		overflow: visible;
		width: 199px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(54,54,54,1);
	}
	#Hello_Im__Mohammed_Affan_pc_we {
		left: 142px;
		top: 188px;
		position: absolute;
		overflow: visible;
		width: 418px;
		height: 97px;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(54,54,54,1);
	}
	#intro_scroll_websoite_21 {
		mix-blend-mode: normal;
		position: absolute;
		width: 425px;
		height: 145px;
		left: 135px;
		top: 285px;
		overflow-x: hidden;
		overflow-y: auto;
	}
	#pc_solgan__website_2 {
		left: 7px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 419px;
		height: 135px;
		line-height: 31px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Acumin Pro;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(54,54,54,1);
	}
	#Group_302 {
		position: absolute;
		width: 1033.599px;
		height: 1012.649px;
		left: 592px;
		top: -304.258px;
		overflow: visible;
	}
	#rec_outer_website_2 {
		fill: rgba(243,123,123,1);
	}
	.rec_outer_website_2 {
		overflow: visible;
		position: absolute;
		width: 832.848px;
		height: 876.211px;
		transform: translate(-564.666px, 273.258px) matrix(1,0,0,1,708.4976,-219.5662) rotate(-8deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#circle_outer_webstie_2 {
		fill: rgba(243,123,123,1);
	}
	.circle_outer_webstie_2 {
		width: 530px;
		height: 522px;
		position: absolute;
		overflow: visible;
		transform: translate(-564.666px, 273.258px) matrix(1,0,0,1,586.4053,195.288) rotate(-5deg);
		transform-origin: center;
	}
	#view_work_button_pc {
		position: absolute;
		width: 126px;
		height: 42px;
		left: 142px;
		top: 442px;
		overflow: visible;
	}
	#our_offering_btn_bdr_website_2 {
		fill: rgba(63,61,86,1);
	}
	.our_offering_btn_bdr_website_2 {
		position: absolute;
		overflow: visible;
		width: 126px;
		height: 42px;
		left: 0px;
		top: 0px;
	}
	#Our_Offerings_website_2 {
		left: 18px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#Illustration_grp_website_2 {
		opacity: 0.997;
		position: absolute;
		width: 536.042px;
		height: 361.41px;
		left: 663.292px;
		top: 232.493px;
		overflow: visible;
	}
	#floor_design_ {
		fill: rgba(63,61,86,1);
	}
	.floor_design_ {
		overflow: visible;
		position: absolute;
		width: 528.5px;
		height: 110.897px;
		left: 6.045px;
		top: 250.512px;
		transform: matrix(1,0,0,1,0,0);
	}
	#bg_grey_deisng {
		fill: rgba(0,0,0,1);
	}
	.bg_grey_deisng {
		overflow: visible;
		position: absolute;
		width: 535.994px;
		height: 298.635px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#bg_black_design__ig {
		fill: url(#bg_black_design__ig);
	}
	.bg_black_design__ig {
		overflow: visible;
		position: absolute;
		width: 535.994px;
		height: 298.635px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#top_right_rod_website_2 {
		fill: rgba(63,61,86,1);
	}
	.top_right_rod_website_2 {
		overflow: visible;
		position: absolute;
		width: 6.229px;
		height: 110.684px;
		left: 479.734px;
		top: 18.542px;
		transform: matrix(1,0,0,1,0,0);
	}
	#rod_top_hori_star_1_website_2 {
		fill: rgba(63,61,86,1);
	}
	.rod_top_hori_star_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 493.684px;
		height: 6.23px;
		left: 26.939px;
		top: 62.438px;
		transform: matrix(1,0,0,1,0,0);
	}
	#star_9_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_9_website_2 {
		position: absolute;
		overflow: visible;
		width: 3.67px;
		height: 3.67px;
		left: 114.797px;
		top: 32.836px;
	}
	#star_8_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_8_website_2 {
		position: absolute;
		overflow: visible;
		width: 3.67px;
		height: 3.67px;
		left: 56.799px;
		top: 29.214px;
	}
	#star_7_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_7_website_2 {
		position: absolute;
		overflow: visible;
		width: 1.545px;
		height: 1.545px;
		left: 136.287px;
		top: 33.898px;
	}
	#star_6_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_6_website_2 {
		position: absolute;
		overflow: visible;
		width: 1.545px;
		height: 1.545px;
		left: 78.047px;
		top: 32.111px;
	}
	#star_5_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_5_website_2 {
		position: absolute;
		overflow: visible;
		width: 1.545px;
		height: 1.545px;
		left: 77.275px;
		top: 86.391px;
	}
	#star_4_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_4_website_2 {
		position: absolute;
		overflow: visible;
		width: 1.545px;
		height: 1.545px;
		left: 435.886px;
		top: 45.343px;
	}
	#star_3_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_3_website_2 {
		position: absolute;
		overflow: visible;
		width: 1.545px;
		height: 1.545px;
		left: 172.553px;
		top: 46.068px;
	}
	#star_2_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_2_website_2 {
		position: absolute;
		overflow: visible;
		width: 1.545px;
		height: 1.545px;
		left: 397.542px;
		top: 44.522px;
	}
	#star_1_website_2 {
		fill: rgba(231,239,253,1);
	}
	.star_1_website_2 {
		position: absolute;
		overflow: visible;
		width: 1.545px;
		height: 1.545px;
		left: 495.429px;
		top: 46.84px;
	}
	#building_website_2 {
		fill: rgba(243,123,123,1);
	}
	.building_website_2 {
		overflow: visible;
		position: absolute;
		width: 508.459px;
		height: 157.379px;
		left: 27.583px;
		top: 141.54px;
		transform: matrix(1,0,0,1,0,0);
	}
	#building_shadow__website_2 {
		opacity: 0.3;
		fill: rgba(0,0,0,1);
	}
	.building_shadow__website_2 {
		overflow: visible;
		position: absolute;
		width: 508.459px;
		height: 157.379px;
		left: 27.583px;
		top: 141.54px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_win_0_website_2 {
		opacity: 0.42;
		fill: rgba(0,0,0,1);
	}
	.left_win_0_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 42.891px;
		top: 181.959px;
	}
	#left_window_0_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_0_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 65.009px;
		top: 181.959px;
	}
	#right_window_12_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_12_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 318.731px;
		top: 162.788px;
	}
	#right_window_11_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_11_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 356.012px;
		top: 162.788px;
	}
	#right_window_0_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_0_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 396.094px;
		top: 162.788px;
	}
	#right_window_9_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_9_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 318.731px;
		top: 191.279px;
	}
	#right_window_8_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_8_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 356.012px;
		top: 191.279px;
	}
	#right_window_7_website_2 {
		fill: rgba(255,255,255,1);
	}
	.right_window_7_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 396.094px;
		top: 191.279px;
	}
	#right_window_6_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_6_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 318.731px;
		top: 219.771px;
	}
	#right_window_5_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_5_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 356.012px;
		top: 219.771px;
	}
	#right_window_4_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_4_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 396.094px;
		top: 219.771px;
	}
	#right_window_3_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 9.852px;
		height: 9.32px;
		left: 444.723px;
		top: 162.836px;
		transform: matrix(1,0,0,1,0,0);
	}
	#right_window_2_website_2 {
		fill: rgba(255,255,255,1);
	}
	.right_window_2_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 444.772px;
		top: 191.279px;
	}
	#right_window_1_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.right_window_1_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 444.772px;
		top: 219.771px;
	}
	#left_window_9_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_9_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 119.53px;
		top: 151.922px;
	}
	#left_window_8_website_2 {
		fill: rgba(255,255,255,1);
	}
	.left_window_8_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 119.53px;
		top: 172.832px;
	}
	#left_window_7_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_7_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 119.53px;
		top: 193.694px;
	}
	#left_window_6_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_6_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 119.53px;
		top: 214.604px;
	}
	#left_window_5_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_5_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 119.53px;
		top: 235.514px;
	}
	#left_window_4_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_4_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 42.891px;
		top: 206.298px;
	}
	#left_window_3_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_3_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 65.009px;
		top: 206.298px;
	}
	#left_window_2_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_2_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 42.891px;
		top: 230.685px;
	}
	#left_window_1_website_2 {
		opacity: 0.4;
		fill: rgba(0,0,0,1);
	}
	.left_window_1_website_2 {
		position: absolute;
		overflow: visible;
		width: 13.812px;
		height: 9.32px;
		left: 65.009px;
		top: 230.685px;
	}
	#verticle_left_website_2 {
		fill: rgba(63,61,86,1);
	}
	.verticle_left_website_2 {
		overflow: visible;
		position: absolute;
		width: 6.229px;
		height: 291.969px;
		left: 94.466px;
		top: 0.046px;
		transform: matrix(1,0,0,1,0,0);
	}
	#veritical_right_website_2 {
		fill: rgba(63,61,86,1);
	}
	.veritical_right_website_2 {
		overflow: visible;
		position: absolute;
		width: 6.229px;
		height: 251.211px;
		left: 287.101px;
		top: 47.227px;
		transform: matrix(1,0,0,1,0,0);
	}
	#bar_down_hori_website_2 {
		fill: rgba(63,61,86,1);
	}
	.bar_down_hori_website_2 {
		overflow: visible;
		position: absolute;
		width: 527.582px;
		height: 6.23px;
		left: 0.202px;
		top: 227.111px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_leg_top_website_2 {
		fill: rgba(101,97,125,1);
	}
	.left_leg_top_website_2 {
		overflow: visible;
		position: absolute;
		width: 77.442px;
		height: 37.801px;
		left: 160.208px;
		top: 278.403px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_leg_down_website_2 {
		fill: rgba(251,190,190,1);
	}
	.left_leg_down_website_2 {
		overflow: visible;
		position: absolute;
		width: 77.116px;
		height: 32.709px;
		left: 198.781px;
		top: 308.482px;
		transform: matrix(1,0,0,1,0,0);
	}
	#neck_website_2 {
		fill: rgba(251,190,190,1);
	}
	.neck_website_2 {
		overflow: visible;
		position: absolute;
		width: 67.242px;
		height: 53.421px;
		left: 226.543px;
		top: 129.214px;
		transform: matrix(1,0,0,1,0,0);
	}
	#face_circle_website_2 {
		fill: rgba(251,190,190,1);
	}
	.face_circle_website_2 {
		overflow: visible;
		position: absolute;
		width: 49.74px;
		height: 49.74px;
		left: 232.483px;
		top: 101.023px;
		transform: matrix(1,0,0,1,0,0);
	}
	#short_website_2 {
		fill: rgba(243,123,123,1);
	}
	.short_website_2 {
		overflow: visible;
		position: absolute;
		width: 117.976px;
		height: 120.825px;
		left: 203.218px;
		top: 154.723px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_hand_behind_laptop_websit {
		fill: rgba(251,190,190,1);
	}
	.left_hand_behind_laptop_websit {
		overflow: visible;
		position: absolute;
		width: 25.208px;
		height: 23.518px;
		left: 200.176px;
		top: 244.11px;
		transform: matrix(1,0,0,1,0,0);
	}
	#right_leg_upper_leg_website_2 {
		fill: rgba(101,97,125,1);
	}
	.right_leg_upper_leg_website_2 {
		overflow: visible;
		position: absolute;
		width: 75.101px;
		height: 40.24px;
		left: 298.207px;
		top: 262.351px;
		transform: matrix(1,0,0,1,0,0);
	}
	#right_leg_top_website_2 {
		fill: rgba(101,97,125,1);
	}
	.right_leg_top_website_2 {
		overflow: visible;
		position: absolute;
		width: 92.666px;
		height: 29.675px;
		left: 268.031px;
		top: 293.657px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_leg_1_website_2 {
		fill: rgba(251,190,190,1);
	}
	.left_leg_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 85.963px;
		height: 21.095px;
		left: 258.367px;
		top: 320.186px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_leg_website_2 {
		fill: rgba(101,97,125,1);
	}
	.left_leg_website_2 {
		overflow: visible;
		position: absolute;
		width: 104.116px;
		height: 48.996px;
		left: 171.008px;
		top: 291.436px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_hand_website_2 {
		fill: rgba(243,123,123,1);
	}
	.left_hand_website_2 {
		overflow: visible;
		position: absolute;
		width: 31.06px;
		height: 84.027px;
		left: 189.833px;
		top: 179.544px;
		transform: matrix(1,0,0,1,0,0);
	}
	#right_hand_down_website_2 {
		fill: rgba(251,190,190,1);
	}
	.right_hand_down_website_2 {
		overflow: visible;
		position: absolute;
		width: 33.659px;
		height: 38.542px;
		left: 288.742px;
		top: 239.571px;
		transform: matrix(1,0,0,1,0,0);
	}
	#right_hand_website_2 {
		fill: rgba(243,123,123,1);
	}
	.right_hand_website_2 {
		overflow: visible;
		position: absolute;
		width: 26.533px;
		height: 72.629px;
		left: 298.302px;
		top: 182.443px;
		transform: matrix(1,0,0,1,0,0);
	}
	#laptop_website_2 {
		fill: rgba(63,61,86,1);
	}
	.laptop_website_2 {
		overflow: visible;
		position: absolute;
		width: 108.221px;
		height: 61.475px;
		left: 205.344px;
		top: 238.605px;
		transform: matrix(1,0,0,1,0,0);
	}
	#hair_website_2 {
		fill: rgba(63,61,86,1);
	}
	.hair_website_2 {
		overflow: visible;
		position: absolute;
		width: 84.881px;
		height: 159.123px;
		left: 210.462px;
		top: 73.42px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_leaf_1_website_2 {
		fill: rgba(56,211,159,1);
	}
	.left_leaf_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 12.534px;
		height: 17.095px;
		left: 399.062px;
		top: 271.009px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_leaf_2_website_2 {
		fill: rgba(56,211,159,1);
	}
	.left_leaf_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 12.654px;
		height: 23.759px;
		left: 403.625px;
		top: 263.33px;
		transform: matrix(1,0,0,1,0,0);
	}
	#left_leadf_3_website_2 {
		fill: rgba(56,211,159,1);
	}
	.left_leadf_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 9.028px;
		height: 30.522px;
		left: 409.345px;
		top: 257.68px;
		transform: matrix(1,0,0,1,0,0);
	}
	#right_leaf_website_2 {
		fill: rgba(56,211,159,1);
	}
	.right_leaf_website_2 {
		overflow: visible;
		position: absolute;
		width: 13.654px;
		height: 17.095px;
		left: 423.185px;
		top: 271.009px;
		transform: matrix(1,0,0,1,0,0);
	}
	#righ_leaf_2_website_2 {
		fill: rgba(56,211,159,1);
	}
	.righ_leaf_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 14.005px;
		height: 23.759px;
		left: 418.55px;
		top: 263.33px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leafe_3_left_website_2 {
		fill: rgba(56,211,159,1);
	}
	.leafe_3_left_website_2 {
		overflow: visible;
		position: absolute;
		width: 10.002px;
		height: 30.511px;
		left: 416.456px;
		top: 257.68px;
		transform: matrix(1,0,0,1,0,0);
	}
	#mid_leaf_left_website_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.mid_leaf_left_website_2 {
		overflow: visible;
		position: absolute;
		width: 6.04px;
		height: 21.683px;
		left: 413.713px;
		top: 266.179px;
		transform: matrix(1,0,0,1,0,0);
	}
	#mid_shadow_right_website_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.mid_shadow_right_website_2 {
		overflow: visible;
		position: absolute;
		width: 6.543px;
		height: 22.265px;
		left: 416.473px;
		top: 265.6px;
		transform: matrix(1,0,0,1,0,0);
	}
	#leaf_mid_website_2 {
		fill: rgba(56,211,159,1);
	}
	.leaf_mid_website_2 {
		overflow: visible;
		position: absolute;
		width: 7.958px;
		height: 40.138px;
		left: 414.353px;
		top: 247.684px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_behind_new_website_2 {
		fill: rgba(212,223,236,1);
	}
	.pot_behind_new_website_2 {
		overflow: visible;
		position: absolute;
		width: 34.866px;
		height: 17.626px;
		left: 399.281px;
		top: 285.737px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_2_behind_website_2 {
		opacity: 0.08;
		fill: rgba(0,0,0,1);
	}
	.pot_2_behind_website_2 {
		overflow: visible;
		position: absolute;
		width: 34.866px;
		height: 17.626px;
		left: 399.281px;
		top: 285.737px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_2_rec_bdr_mid_website_2 {
		fill: rgba(212,223,236,1);
	}
	.pot_2_rec_bdr_mid_website_2 {
		position: absolute;
		overflow: visible;
		width: 34.866px;
		height: 5.795px;
		left: 400.633px;
		top: 285.737px;
	}
	#over_all_half_cactus_ {
		fill: rgba(56,211,159,1);
	}
	.over_all_half_cactus_ {
		overflow: visible;
		position: absolute;
		width: 37.677px;
		height: 39.254px;
		left: 105.782px;
		top: 244.89px;
		transform: matrix(1,0,0,1,0,0);
	}
	#cactus_2_website_2 {
		fill: rgba(56,211,159,1);
	}
	.cactus_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 16.12px;
		height: 19.724px;
		left: 123.917px;
		top: 251.311px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_back_2_website2 {
		fill: rgba(212,223,236,1);
	}
	.pot_1_back_2_website2 {
		position: absolute;
		overflow: visible;
		width: 29.651px;
		height: 20.813px;
		left: 104.511px;
		top: 280.763px;
	}
	#pot_1_down_2_website_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.pot_1_down_2_website_2 {
		position: absolute;
		overflow: visible;
		width: 22.697px;
		height: 5.94px;
		left: 108.133px;
		top: 300.176px;
	}
	#pot_1_box_2_website_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.pot_1_box_2_website_2 {
		position: absolute;
		overflow: visible;
		width: 29.651px;
		height: 20.813px;
		left: 104.511px;
		top: 280.763px;
	}
	#pot_1_down_1_website_2 {
		fill: rgba(212,223,236,1);
	}
	.pot_1_down_1_website_2 {
		position: absolute;
		overflow: visible;
		width: 22.697px;
		height: 5.94px;
		left: 109.582px;
		top: 300.176px;
	}
	#pot_1_cactus_3_website_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.pot_1_cactus_3_website_2 {
		overflow: visible;
		position: absolute;
		width: 5.409px;
		height: 4.539px;
		left: 118.371px;
		top: 263.475px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_cactus_2_website_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.pot_1_cactus_2_website_2 {
		overflow: visible;
		position: absolute;
		width: 7.106px;
		height: 6.208px;
		left: 123.917px;
		top: 264.827px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot_1_cactus_1_website_2 {
		fill: rgba(56,211,159,1);
	}
	.pot_1_cactus_1_website_2 {
		overflow: visible;
		position: absolute;
		width: 19.326px;
		height: 20.046px;
		left: 111.707px;
		top: 264.098px;
		transform: matrix(1,0,0,1,0,0);
	}
	#pot1_box_down_website_2 {
		opacity: 0.1;
		fill: rgba(0,0,0,1);
	}
	.pot1_box_down_website_2 {
		position: absolute;
		overflow: visible;
		width: 22.697px;
		height: 2.173px;
		left: 109.582px;
		top: 300.176px;
	}
	#pot_1_box_1_website_2 {
		fill: rgba(212,223,236,1);
	}
	.pot_1_box_1_website_2 {
		position: absolute;
		overflow: visible;
		width: 29.651px;
		height: 20.813px;
		left: 106.008px;
		top: 280.763px;
	}
	#intro_grp_website_2 {
		position: absolute;
		width: 67px;
		height: 27px;
		left: 723px;
		top: 54px;
		overflow: visible;
	}
	#Intro {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#home-icon-silhouette {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#offerings_grp_website_2 {
		position: absolute;
		width: 106px;
		height: 27px;
		left: 823px;
		top: 54px;
		overflow: visible;
	}
	#Offerings {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#discount {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#partner_grp_website_2 {
		position: absolute;
		width: 97px;
		height: 27px;
		left: 962px;
		top: 54px;
		overflow: visible;
	}
	#Partners_lbl_partner {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#deal_img_ {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#copy_right_top_line_website_2_ {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.copy_right_top_line_website_2_ {
		overflow: visible;
		position: absolute;
		width: 1173px;
		height: 1px;
		left: 87.5px;
		top: 5724.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_Copyright_Operate_Live_OPC_P {
		left: 125px;
		top: 5771px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	/* #Title_1_lbl_website_2_layout {
		left: 830px;
		top: 2737px;
		position: absolute;
		overflow: visible;
		width: 79px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	} */
	/* #Description_lbl_1website_2_lay {
		left: 691px;
		top: 2816px;
		position: absolute;
		overflow: visible;
		width: 373px;
		height: 356px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#Line_1_divide_website_2_layout {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_1_divide_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 931px;
		height: 1px;
		left: 170.5px;
		top: 3225.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Line_2_divide_website_2_layout {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_2_divide_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 931px;
		height: 1px;
		left: 170.5px;
		top: 3793.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#line_3_divide_website_2_layout {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_3_divide_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 931px;
		height: 1px;
		left: 170.5px;
		top: 4349.5px;
		transform: matrix(1,0,0,1,0,0);
	} */
	/* #title_path_down1_website_2_lay {
		fill: transparent;
		stroke: rgba(245,149,149,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.title_path_down1_website_2_lay {
		overflow: visible;
		position: absolute;
		width: 335.083px;
		height: 26.866px;
		left: 718.654px;
		top: 2790px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Title_2_lbl_website_2_layout {
		left: 838px;
		top: 3854px;
		position: absolute;
		overflow: visible;
		width: 79px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#Description_lbl_website_2_layo {
		left: 699px;
		top: 3933px;
		position: absolute;
		overflow: visible;
		width: 373px;
		height: 356px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#title_underline_2website_2_lay {
		fill: transparent;
		stroke: rgba(245,149,149,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.title_underline_2website_2_lay {
		overflow: visible;
		position: absolute;
		width: 335.083px;
		height: 26.866px;
		left: 726.654px;
		top: 3907px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Title_lbl_website_2_layout {
		left: 323px;
		top: 3286px;
		position: absolute;
		overflow: visible;
		width: 79px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#Description_1_website_2_layout {
		left: 184px;
		top: 3365px;
		position: absolute;
		overflow: visible;
		width: 373px;
		height: 356px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#title_2_underline_website_2_la {
		fill: transparent;
		stroke: rgba(245,149,149,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.title_2_underline_website_2_la {
		overflow: visible;
		position: absolute;
		width: 335.083px;
		height: 26.866px;
		left: 211.654px;
		top: 3339px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Title_lbl_4ebsite_2_layout {
		left: 323px;
		top: 4422px;
		position: absolute;
		overflow: visible;
		width: 79px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#Description_lbl__website_2_lay {
		left: 184px;
		top: 4501px;
		position: absolute;
		overflow: visible;
		width: 373px;
		height: 356px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#title_3_under_linewebsite_2_la {
		fill: transparent;
		stroke: rgba(245,149,149,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.title_3_under_linewebsite_2_la {
		overflow: visible;
		position: absolute;
		width: 335.083px;
		height: 26.866px;
		left: 211.654px;
		top: 4475px;
		transform: matrix(1,0,0,1,0,0);
	} */

    #Comeplete_all_off_nameand_desc {
		position: absolute;
		width: 100%;
		height: 2404px;
		left: 170px;
		top: 2568px;
		overflow-x:hidden;
        overflow-y: auto;
	}
	#My_Offerings_lbl_website_2 {
		left: 410px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 176px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(54,54,54,1);
	}
	#offing_my_all_grp_4_cards {
		position: absolute;
		width: 931.927px;
		height: 2254px;
		left: 0px;
        text-align: center;
		top: 150px;
		overflow: visible;
	}
	#View_all_btn_grp_website_2 {
		position: relative;
		width: 126px;
		height: 42px;
        text-align: center;
        display: inline-block;
		/* left: 441px; */
		/* top: 2212px; */
		overflow: visible;
	}
	#view_all_bdr_website_2 {
		fill: rgba(63,61,86,1);
	}
	.view_all_bdr_website_2 {
		position: absolute;
		overflow: visible;
		width: 126px;
		height: 42px;
		left: 0px;
		top: 0px;
	}
	#View_All_website_2 {
		left: 37px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 53px;
		white-space: nowrap;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#off_card_45grp_desc {
		position: relative;
		width: 100%;
		height: 500px;
        margin-bottom: 10px;
        /* background-color: chartreuse ; */
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#off_card_2_grp_desc {
		position: relative;
		width: 100%;
		height: 500px;
        /* background-color: blueviolet; */
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#design_1_off_website_2 {
		position: absolute;
		width: 400px;
		height: 446.829px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Title_1_lbl_website_2_layout {
		left: 521px;
		top: 19px;
		position: absolute;
		overflow: visible;
		width: 373px;
		white-space: nowrap;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#Description_lbl_1website_2_lay {
		left: 521px;
		top: 98px;
		position: absolute;
		overflow: visible;
		width: 373px;
		height: 356px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#title_path_down1_website_2_lay {
		fill: transparent;
		stroke: rgba(245,149,149,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.title_path_down1_website_2_lay {
		overflow: visible;
		position: absolute;
		width: 335.083px;
		height: 26.866px;
		left: 548.654px;
		top: 72px;
		transform: matrix(1,0,0,1,0,0);
	}
	#off_card_32_grp_desc {
		position: absolute;
		width: 931.5px;
		height: 495.841px;
		left: 0px;
		top: 1135.658px;
		overflow: visible;
	}
	#design_3_off_website_2 {
		position: absolute;
		width: 400px;
		height: 435.369px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Title_2_lbl_website_2_layout {
		left: 668px;
		top: 0.342px;
		position: absolute;
		overflow: visible;
		width: 79px;
        left: 13px;
        width: 373px;
        text-align: center;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#Description_lbl_website_2_layo {
		left: 529px;
		top: 79.342px;
		position: absolute;
		overflow: visible;
		width: 373px;
		height: 356px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#title_underline_2website_2_lay {
		fill: transparent;
		stroke: rgba(245,149,149,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.title_underline_2website_2_lay {
		overflow: visible;
		position: absolute;
		width: 335.083px;
		height: 26.866px;
		left: 556.654px;
		top: 53.342px;
		transform: matrix(1,0,0,1,0,0);
	}
	#line_3_divide_website_2_layout {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_3_divide_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 931px;
		height: 1px;
		left: 0.5px;
		top: 495.842px;
		transform: matrix(1,0,0,1,0,0);
	}
	#design_2_off_website_2 {
		position: absolute;
		width: 410.53px;
		height: 446.829px;
		left: 520.897px;
		top: 0px;
		overflow: visible;
	}
	#Title_lbl_website_2_layout {
		top: 0px;
		position: absolute;
		overflow: visible;
        left: 13px;
        width: 373px;
        text-align: center;
		white-space: nowrap;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#Description_1_website_2_layout {
		left: 13.5px;
		top: 79px;
		position: absolute;
		overflow: visible;
		width: 373px;
		height: 356px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#title_2_underline_website_2_la {
		fill: transparent;
		stroke: rgba(245,149,149,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.title_2_underline_website_2_la {
		overflow: visible;
		position: absolute;
		width: 335.083px;
		height: 26.866px;
		left: 41.154px;
		top: 53px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Line_2_divide_website_2_layout {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_2_divide_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 931px;
		height: 1px;
		left: 0px;
		top: 507.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#off_card_1_desc_grp {
		position: absolute;
		width: 931.427px;
		height: 1643.178px;
		left: 0.5px;
		top: 507.5px;
		overflow: visible;
	}
	#design_4_off_website_2 {
		position: absolute;
		width: 410px;
		height: 458.336px;
		left: 521.427px;
		top: 1184.842px;
		overflow: visible;
	}
	#Title_lbl_4ebsite_2_layout {
		left: 152.5px;
		top: 1196.5px;
		position: absolute;
		overflow: visible;
		width: 79px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#Description_lbl__website_2_lay {
		left: 13.5px;
		top: 1275.5px;
		position: absolute;
		overflow: visible;
		width: 373px;
		height: 356px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#title_3_under_linewebsite_2_la {
		fill: transparent;
		stroke: rgba(245,149,149,1);
		stroke-width: 5px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.title_3_under_linewebsite_2_la {
		overflow: visible;
		position: absolute;
		width: 335.083px;
		height: 26.866px;
		left: 41.154px;
		top: 1249.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Line_1_divide_website_2_layout {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_1_divide_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 931px;
		height: 1px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}

	#our_success_partners_website_2 {
		left: 481px;
		top: 2196px;
		position: absolute;
		overflow: visible;
		width: 387px;
		height: 43px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		color: rgba(0,0,0,1);
	}
	#later {
		position: absolute;
		width: 1366px;
		height: 130px;
		left: 0px;
		top: 2269px;
		overflow: visible;
	}
	#Rectangle_480 {
		fill: transparent;
	}
	.Rectangle_480 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 130px;
		left: 0px;
		top: 0px;
		background:  rgba( 255, 255, 255, 0.5 );
		box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
		backdrop-filter:  blur( 10px );
		-webkit-backdrop-filter:  blur( 10px );
		border-radius:  10px;
		border:  1px solid rgba( 255, 255, 255, 0.18 );
	}
	#partner_lbls_grp {
		position: absolute;
		width: 1069px;
		height: 27px;
		left: 149px;
		display: inline-flex;
		top: 52px;
		overflow: visible;
	}
	#google_lbl_partner_website_2 {
		left: 30px;
		top: 0px;
		position: relative;
		overflow: visible;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#microsoft_lbl_partnw_website_2 {
		left: 210px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#microscope_lbl_partner_website {
		left: 390px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#google_1_website_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#microsoft_website_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 180px;
		top: 1px;
		overflow: visible;
	}
	#scope_website_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 360px;
		top: 1px;
		overflow: visible;
	}
	#googlewebsite_2 {
		left: 572px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#microsoft_1_website_2 {
		left: 752px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#micro_1_website_2 {
		left: 932px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 138px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(178,184,183,1);
	}
	#google_1_1_website_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 542px;
		top: 1px;
		overflow: visible;
	}
	#microsoft_1_website_2_lq {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 722px;
		top: 1px;
		overflow: visible;
	}
	#scope_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 902px;
		top: 1px;
		overflow: visible;
	}
	#Group_300 {
		position: absolute;
		width: 594px;
		height: 90px;
		left: 377px;
		top: 5552px;
		overflow: visible;
	}
	#Start_a_new_project {
		position: absolute;
		width: 179px;
		height: 42px;
		left: 415px;
		top: 3px;
		overflow: visible;
	}
	#Rectangle_89 {
		fill: rgba(63,61,86,1);
	}
	.Rectangle_89 {
		position: absolute;
		overflow: visible;
		width: 179px;
		height: 42px;
		left: 0px;
		top: 0px;
	}
	#Network_With_Us_ {
		left: 28px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 125px;
		white-space: nowrap;
		text-align: left;
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#Willing_to_Grow_by_Partnering_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 301px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 34px;
		color: rgba(54,54,54,1);
	}
	#linked_in_website_2_layout {
		position: absolute;
		width: 25px;
		height: 22.727px;
		left: 541.5px;
		top: 66.442px;
		overflow: visible;
	}
	#n_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.n_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 16.522px;
		height: 15.588px;
		left: 8.478px;
		top: 7.14px;
		transform: matrix(1,0,0,1,0,0);
	}
	#linked_in_i_down {
		fill: rgba(63,61,86,1);
	}
	.linked_in_i_down {
		overflow: visible;
		position: absolute;
		width: 4.873px;
		height: 15.44px;
		left: 0.631px;
		top: 7.287px;
		transform: matrix(1,0,0,1,0,0);
	}
	#top_dot_i_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.top_dot_i_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 5.915px;
		height: 5.357px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#instagram_website_2_layout {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 492.5px;
		top: 67px;
		overflow: visible;
	}
	#whatsapp_lbl_website_2_layout {
		position: absolute;
		width: 23px;
		height: 23px;
		left: 442.5px;
		top: 66.442px;
		overflow: visible;
	}
	#Contact_grp_website_2 {
		position: absolute;
		width: 93px;
		height: 27px;
		left: 1088px;
		top: 54px;
		overflow: visible;
	}
	#Contact {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#contact {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#contact_area_grp_website_2 {
		position: absolute;
		width: 777px;
		height: 306px;
		left: 286px;
		top: 5122px;
		overflow: visible;
	}
	#bdr_contact_page_website_2 {
		fill: rgba(255,255,255,1);
	}
	.bdr_contact_page_website_2 {
		filter: drop-shadow(0px 10px 2px rgba(135, 207, 172, 0.647));
		overflow: visible;
		position: absolute;
		width: 783px;
		height: 319px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_234567890_mobile_number_webs_ff_pc {
		left: 71px;
		top: 19px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#affanghanioperatelive_email_pc {
		left: 72px;
		top: 73px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#address_website_pc_ {
		left: 67px;
		top: 127px;
		position: absolute;
		overflow: visible;
		width: 373px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Connect_with_us_and_unlock_lim_pc {
		left: 499px;
		top: 238px;
		position: absolute;
		overflow: visible;
		width: 271px;
		height: 54px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#location_img_website_ff_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 23px;
		top: 127px;
		overflow: visible;
	}
	#email_address_website_ff_pc {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 23px;
		top: 73px;
		overflow: visible;
	}
	#phone_number_website_ff {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 23px;
		top: 19px;
		overflow: visible;
	}
	#quote_left_webstie_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 469px;
		top: 238px;
		overflow: visible;
	}
	#quote_right_website_2 {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 721px;
		top: 265px;
		overflow: visible;
	}
