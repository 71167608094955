#card_1_reqoffevent_req_PC {
    position: relative;
    width: 270px;
    height: 325px;
    /* left: 55px; */
    top: 24px;
    display: inline-flex;
    flex-direction: row;
    margin: 10px;
    overflow: visible;
}
#Base_reqoffevent_req_PC {
    fill: rgba(255,255,255,1);
}
.Base_reqoffevent_req_PC {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 288px;
    height: 288px;
    left: 0px;
    top: 0px;
}
#bdr_1_reqoffevent_req_PC {
    fill: rgba(255,79,4,0.09);
}
.bdr_1_reqoffevent_req_PC {
    position: absolute;
    overflow: visible;
    width: 80px;
    height: 31px;
    left: 189px;
    top: 285px;
}
#trohpie_bdr_small_4_trendingrereq_PC {
    fill: rgba(255,255,255,1);
}
.trohpie_bdr_small_4_trendingrereq_PC {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 37px;
    left: 0px;
    top: 0px;
}
#share_bdr_small_4_trending_reqreq_PC {
    fill: rgba(255,255,255,1);
}
.share_bdr_small_4_trending_reqreq_PC {
    position: absolute;
    overflow: visible;
    width: 41px;
    height: 38px;
    left: 227px;
    top: 1px;
}
#n_90_reqoffevent_req_PC {
    left: 190px;
    top: 291px;
    position: absolute;
    overflow: visible;
    width: 80px;
    white-space: nowrap;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#Single_Tour_reqoffevent_req_PC {
    left: 17px;
    top: 284px;
    position: absolute;
    overflow: visible;
    width: 75px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.3333333206176758px;
}
#date_reqoffevent_req_PC {
    left: 17px;
    top: 306px;
    position: absolute;
    overflow: visible;
    width: 107px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -8.5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(17,17,17,1);
}
#online-shopping_reqoffevent_req_PC {
    position: absolute;
    width: 268px;
    height: 268px;
    /* left: 47px;
    top: 47px; */
    overflow: visible;
}
#trophy_reqoffevent_req_PC {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#n_234_reqoffevent_req_PC {
    left: 42px;
    top: 12px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    line-height: 30px;
    margin-top: -7.5px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(17,17,17,1);
}
#share_1_reqoffevent_req_PC {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 235px;
    top: 8px;
    overflow: visible;
}