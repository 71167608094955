	.ReqqerMbmediaViewInfo {
		--web-view-name: request mob;
		--web-view-id: request_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: request_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ReqqerMbrequest_mob {
		position: absolute;
        width: 100%;
        height: calc(100% - 105px);
        left: 0px;
        top: 45px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: request mob;
		--web-view-id: request_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#ReqqerMbdesgin_con_page_new_pc {
		fill: rgba(248,248,248,1);
	}
	.ReqqerMbdesgin_con_page_new_pc {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 203px;
		left: 0px;
		top: 0px;
	}
	#ReqqerMbScroll_Group_9 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 100%;
		left: calc((100% - 375px)/2);
		top: 0px;
		display: flex;
        flex-wrap: wrap;
        flex-direction: row;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#ReqqerMbcard_1_req_page_pc_inichan {
		position: relative;
		width: 166px;
		height: 287px;
		/* left: 11px; */
		margin: 10px;
		top: 14px;
		overflow: visible;
	}
	#ReqqerMbover_all_card_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.ReqqerMbover_all_card_erq_page_new_pc {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 174px;
		height: 295px;
		left: 0px;
		top: 0px;
	}
	#ReqqerMbAnime_Edit_goku_thats_me {
		position: absolute;
		width: 135px;
		height: 128px;
		left: 16px;
		top: 31px;
		overflow: visible;
	}
	#ReqqerMbdesignation_grp_req_page_new {
		position: absolute;
		width: 153px;
		height: 23px;
		left: 11px;
		top: 163px;
		overflow: visible;
	}
	#ReqqerMbjob-seeker_img_erq_page_new_pc {
		position: absolute;
		width: 22px;
		height: 23px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ReqqerMberq_page_new_pc_job_name {
		left: 27px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 127px;
		height: 20px;
		
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: capitalize;
	}
	#ReqqerMbMohammed_Affan_goku_v {
		left: 1px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 166px;
		height: 20px;
		line-height: 49px;
		margin-top: -17px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
	}
	#ReqqerMbreject_erq_page_new_pc {
		position: absolute;
		width: 34px;
		height: 34px;
		left: 47px;
		top: 247px;
		overflow: visible;
	}
	#ReqqerMbreqj_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.ReqqerMbreqj_erq_page_new_pc {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#ReqqerMbreject_img_erq_page_new_pc {
		position: absolute;
		width: 22px;
		height: 22px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#ReqqerMbaccept_grp_erq_page_new_pc {
		position: absolute;
		width: 34px;
		height: 34px;
		left: 7px;
		top: 247px;
		overflow: visible;
	}
	#ReqqerMbaccept_bdr_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.ReqqerMbaccept_bdr_erq_page_new_pc {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#ReqqerMbaccept_img_erq_page_new_pc {
		position: absolute;
		width: 23px;
		height: 22px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#ReqqerMbignore_grp_erq_page_new_pc {
		position: absolute;
		width: 34px;
		height: 34px;
		left: 86px;
		top: 248px;
		overflow: visible;
	}
	#ReqqerMbbg_ignore_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.ReqqerMbbg_ignore_erq_page_new_pc {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#ReqqerMbignore_u_erq_page_new_pc {
		position: absolute;
		width: 23px;
		height: 22px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#ReqqerMbblock_grp_erq_page_new_pc {
		position: absolute;
		width: 34px;
		height: 34px;
		left: 126px;
		top: 248px;
		overflow: visible;
	}
	#ReqqerMbblock_bg_erq_page_new_pc {
		fill: rgba(255,255,255,1);
	}
	.ReqqerMbblock_bg_erq_page_new_pc {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#ReqqerMbblock_img_erq_page_new_pc {
		position: absolute;
		width: 22px;
		height: 22px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#ReqqerMbcompany_name_request_page_new_ {
		position: absolute;
		width: 153px;
		height: 23px;
		left: 11px;
		top: 190px;
		overflow: visible;
	}
	#ReqqerMboffice-building_req_page_new_p {
		position: absolute;
		width: 22px;
		height: 23px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ReqqerMbOP_conreqsug_erq_page_new_pc {
		left: 27px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 127px;
		height: 20px;
		
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		text-transform: capitalize;
	}
	#ReqqerMbmarquee_op_intro_person {
		left: 7px;
		top: 222px;
		position: absolute;
		overflow: visible;
		width: 154px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		text-transform: capitalize;
	}
