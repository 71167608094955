
.mediaViewInfo {
	--web-view-name: add event;
	--web-view-id: add_event;
	--web-scale-on-resize: true;
	--web-enable-deep-linking: true;
}
:root {
	--web-view-ids: add_event;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	border: none;
}
#add_event {
	position: absolute;
	width: 321px;
	height: 774px;
	top: 60%;
	left: 50%;
	transform: translate(-50%,-50%);
	border-radius: 15px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;
	--web-view-name: add event;
	--web-view-id: add_event;
	--web-scale-on-resize: true;
	--web-enable-deep-linking: true;
}
#over_all_bg_of_event_pop_up {
	position: absolute;
	width: 321px;
	height: 774px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#close_btn_add_event_details {
	position: absolute;
	width: 20px;
	height: 20px;
	left: 289px;
	top: 7px;
	cursor: pointer;
	overflow: visible;
}
#Enter_Event_Details_lbl_add_ev {
	left: 72px;
	top: 10px;
	position: absolute;
	overflow: visible;
	width: 178px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	color: rgba(0,0,0,1);
}
#add_btn_grp_add_event_details {
	position: absolute;
	width: 120px;
	height: 40px;
	left: 101px;
	top: 710px;
	overflow: visible;
}
#add_btn_bdr_add_event_details {
	fill: rgba(173,245,206,1);
}
.add_btn_bdr_add_event_details {
	position: absolute;
	overflow: visible;
	width: 120px;
	height: 40px;
	left: 0px;
	top: 0px;
}
#Add_btn_add_event_details {
	left: 44px;
	top: 9px;
	position: absolute;
	overflow: visible;
	width: 32px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#Scroll_add_event_pc {
	mix-blend-mode: normal;
	position: absolute;
	width: 304px;
	height: 628px;
	left: 17px;
	top: 65px;
	overflow-x: hidden;
	overflow-y: scroll;
}
#Scroll_add_event_pc::-webkit-scrollbar {
	display: none;
}
#all_details_fill_grp_event_add {
	position: absolute;
	width: 250px;
	height: 630px;
	left: 19px;
	top: 0px;
	overflow: visible;
}
#upload_banner_grp_add_event_de {
	position: absolute;
	width: 250px;
	height: 110px;
	left: 0px;
	top: 562px;
	overflow: visible;
}
#Upload_Banner_IMG_add_event_de {
	left: 0px;
	top: 2px;
	position: absolute;
	overflow: visible;
	width: 149px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#upload_img_add_event_details {
	position: absolute;
	width: 26px;
	height: 26px;
	left: 178px;
	top: 0px;
	overflow: visible;
}
#img_preview_section_event_deta {
	position: absolute;
	width: 250px;
	height: 70px;
	left: 0px;
	top: 40px;
	overflow: visible;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#events_loca_grp_add_event_deta {
	position: absolute;
	width: 250px;
	height: 62px;
	left: 0px;
	top: 488px;
	overflow: visible;
}
#event_location_bdr_add_event_d {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.event_location_bdr_add_event_d {
	position: absolute;
	overflow: visible;
	width: 250px;
	height: 40px;
	left: 0px;
	top: 22px;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#Event_Location_lbl_add_event_d {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 111px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#event_cost_grp_add_event_detai {
	position: absolute;
	width: 115px;
	height: 62px;
	left: 135px;
	top: 414px;
	overflow: visible;
}
#event_cost_bdr_add_event_detai {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.event_cost_bdr_add_event_detai {
	position: absolute;
	overflow: visible;
	width: 115px;
	height: 40px;
	left: 0px;
	top: 22px;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#Event_Cost_lbl_add_event_detai {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 80px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#event_time_grp_add_event_detai {
	position: absolute;
	width: 250px;
	height: 63px;
	left: 0px;
	top: 338px;
	overflow: visible;
}
#event_time_bdr_add_event_detai {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.event_time_bdr_add_event_detai {
	position: absolute;
	overflow: visible;
	width: 250px;
	height: 40px;
	left: 0px;
	top: 23px;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#Event_Time_lbl_add_event_detai {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 102px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#event_seats_grp_add_event_deta {
	position: absolute;
	width: 115px;
	height: 63px;
	left: 0px;
	top: 413px;
	overflow: visible;
}
#event_seats_bdr_add_event_deta {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.event_seats_bdr_add_event_deta {
	position: absolute;
	overflow: visible;
	width: 115px;
	height: 40px;
	left: 0px;
	top: 23px;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#Event_Seats_lbl_add_event_deta {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 86px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#event_date_grp_add_event_detai {
	position: absolute;
	width: 250px;
	height: 62px;
	left: 0px;
	top: 264px;
	overflow: visible;
}
#event_date_bdr_add_event_detai {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.event_date_bdr_add_event_detai {
	position: absolute;
	overflow: visible;
	width: 250px;
	height: 40px;
	left: 0px;
	top: 22px;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#Event_Date_lbl_add_event_detai {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 108px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#event_category_grp_add_event_d {
	position: absolute;
	width: 250px;
	height: 62px;
	left: 0px;
	top: 190px;
	overflow: visible;
}
#evnet_category_bdr_add_event_d {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.evnet_category_bdr_add_event_d {
	position: absolute;
	overflow: visible;
	width: 250px;
	height: 40px;
	left: 0px;
	top: 22px;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#Event_Category_lbl_add_event_d {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 115px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#event_description_grp_add_even {
	position: absolute;
	width: 250px;
	height: 103px;
	left: 0px;
	top: 75px;
	overflow: visible;
}
#event_descp_bdr_add_event_deta {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.event_descp_bdr_add_event_deta {
	position: absolute;
	overflow: visible;
	width: 250px;
	height: 80px;
	left: 0px;
	top: 23px;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#Event_Description_lbl_add_even {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 132px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
#event_name_grp_add_event_detai {
	position: absolute;
	width: 250px;
	height: 63px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#event_name_bdr_add_event_detai {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.event_name_bdr_add_event_detai {
	position: absolute;
	overflow: visible;
	width: 250px;
	height: 40px;
	left: 0px;
	top: 23px;
	border: 1px solid #ADF5CE;
	border-radius: 5px;
}
#Event_Name_lbl_add_event_detai {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 92px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	color: rgba(0,0,0,1);
}
