.mediaViewInfo {
    --web-view-name: meeting room pc;
    --web-view-id: meeting_room_pc;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: meeting_room_pc;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#meeting_room_pc {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 60px);
    top:55px;
    left:120px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: meeting room pc;
    --web-view-id: meeting_room_pc;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#meeting_room_pc_bg_ {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#grp_vid_cam_2 {
    position: relative;
    width: auto;
    height: 151px;
    top: 0px;
    padding-left:9px;
    display: inline-block;
    overflow: visible;
}
#vid_cam_2 {
    position: absolute;
    width: 188px;
    height: 128px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#vid_cam_lbl_1 {
    left: 10px;
    top: 10px;
    z-index: 1;
    position: relative;
    overflow: visible;
    width: 189px;
    height: 22px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(5,5,5,1);
}
#vid_cam_lbl_2 {
    left: 0px;
    top: 126px;
    position: absolute;
    overflow: visible;
    width: 189px;
    height: 22px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(5,5,5,1);
}
#grp_vid_cam_3 {
    position: absolute;
    width: 188px;
    height: 151px;
    left: 235px;
    top: 29px;
    overflow: visible;
}
#vid_cam_3 {
    position: absolute;
    width: 188px;
    height: 128px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#vid_cam_lbl_3 {
    left: 0px;
    top: 129px;
    position: absolute;
    overflow: visible;
    width: 189px;
    height: 22px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(5,5,5,1);
}
#grp_vid_cam_4 {
    position: absolute;
    width: 188px;
    height: 151px;
    left: 446px;
    top: 29px;
    overflow: visible;
}
#vid_cam_4 {
    position: absolute;
    width: 188px;
    height: 128px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#vid_cam_lbl_4 {
    left: 0px;
    top: 129px;
    position: absolute;
    overflow: visible;
    width: 189px;
    height: 22px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(5,5,5,1);
}
#grp_vid_cam_5 {
    position: absolute;
    width: 188px;
    height: 151px;
    left: 657px;
    top: 29px;
    overflow: visible;
}
#vid_cam_lbl_5 {
    left: 0px;
    top: 129px;
    position: absolute;
    overflow: visible;
    width: 189px;
    height: 22px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(5,5,5,1);
}
#vid_cam_5 {
    position: absolute;
    width: 188px;
    height: 128px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#grp_chat_1_pc {
    position: absolute;
    width: 310px;
    height: 350px;
    right: 50px;
    top: 228px;
    overflow: visible;
}
#chat_pc_bg {
    position: absolute;
    width: 310px;
    height: 350px;
    left: 0px;
    top: 0px;
    overflow: auto;
}
#chat_msg_input_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chat_msg_input_ {
    position: absolute;
    overflow: visible;
    width: 288px;
    height: 38px;
    left: 11px;
    top: 304px;
    font-size: 17px;
    border-radius: 50px;
    padding-left: 15px;
    font-style: Segeo UI;
    box-shadow: inset 10px 10px 20px #cacaca,inset -10px -10px 20px #ffffff;
    border: 1px solid;
}
#user_1_txt_grp {
    position: absolute;
    width: 250.123px;
    height: 83.328px;
    left: 12px;
    top: 12.336px;
    overflow: visible;
}
#chat_1_design_ {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chat_1_design_ {
    overflow: visible;
    position: absolute;
    width: 215.955px;
    height: 46.469px;
    left: 35.877px;
    top: 16.655px;
    transform: matrix(1,0,0,1,0,0);
}
#chat_1_time {
    left: 49.504px;
    top: 63.328px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    color: rgba(77,242,94,1);
}
#chat_1_username {
    left: 82.504px;
    top: 63.328px;
    position: absolute;
    overflow: visible;
    width: 118px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(77,242,94,1);
}
#chat_1_input {
    left: 51.504px;
    top: 29.328px;
    position: absolute;
    overflow: visible;
    width: 188px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#online_user_pf_1_img {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#user_2_text_grp {
    position: absolute;
    width: 255.133px;
    height: 76.25px;
    left: 42px;
    top: 91px;
    overflow: visible;
}
#chat_2_design {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chat_2_design {
    overflow: visible;
    position: absolute;
    width: 220.985px;
    height: 46.469px;
    left: 0px;
    top: 11.562px;
    transform: matrix(1,0,0,1,0,0);
}
#chat_2_time {
    left: 3.998px;
    top: 56.25px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    color: rgba(255,87,87,1);
}
#chat_2_username {
    left: 37.64px;
    top: 56.25px;
    position: absolute;
    overflow: visible;
    width: 121px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,87,87,1);
}
#chat_2_txt_input {
    left: 5.816px;
    top: 24.25px;
    position: absolute;
    overflow: visible;
    width: 193px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#online_user_pf_2_img {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 225.133px;
    top: 0px;
    overflow: visible;
}
#send-message {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 272px;
    top: 313px;
    overflow: visible;
}
#grp_cam_1_call_acc_dec {
    position: absolute;
    width: 821px;
    height: 414px;
    left: 24px;
    top: 200px;
    overflow: visible;
}
#cam_1_grp_with_btn {
    position: absolute;
    width: calc(100% - 450px);
    height: calc(100% - 300px);
    left: 45px;
    top: 160px;
    text-align: center;
    overflow: visible;
}
#cam_2_grp_with_btn {
    position: absolute;
    width: calc(100% - 450px);
    height: calc(100% - 300px);
    left: 45px;
    top: 160px;
    text-align: center;
    overflow: visible;
}
#vid_cam_1 {
    position: absolute;
    width: 100%; 
    height: 100%;
    left: 0px;
    top: 0px;
    padding: 10px;
    border: 2px solid;
    background-color: #ffffff;
    overflow: visible;
}
/* #group_mic_pc:hover {
    left: 20%;
    top: calc(100% - 62px);
} */
#group_mic_pc {
    position: relative;
    width: 60px;
    height: 40px;
    /* left: 20%;
    top: calc(100% - 60px); */
    overflow: hidden;
    border: 2px solid;
    transition: all .3s ease-out;
}
#mic_border_pc {
    fill: rgba(255,255,255,1);
}
.mic_border_pc {
    position: absolute;
    overflow: visible;
    width: 60px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#micro_on {
    position: absolute;
    width: 26px;
    height: 27px;
    left: 17px;
    top: 5px;
    overflow: visible;
}
/* #group_cam_pc:hover {
    left: 307px;
    top: 357px;
} */
#group_cam_pc {
    position: relative;
    width: 60px;
    height: 40px;
    /* left: 303px;
    top: 352px; */
    overflow: visible;
    transition: all .3s ease-out;
}
#cam_border_pc {
    fill: rgba(255,255,255,1);
}
.cam_border_pc {
    position: absolute;
    overflow: hidden;
    width: 60px;
    height: 40px;
    left: 0px;
    top: 0px;
    border: 2px solid;
}
#cam_img_pc {
    position: absolute;
    width: 23.451px;
    height: 23.451px;
    left: 18.398px;
    top: 8.246px;
    overflow: visible;
}
/* #grp_dis_con_pc:hover {
    left: 386px;
    top: 357px;
} */
#grp_dis_con_pc {
    position: relative;
    width: 60px;
    height: 40px;
    /* left: 381px;
    top: 352px; */
    overflow: visible;
    transition: all .3s ease-out;
}
#cutcall_bdr_pc {
    fill: rgba(255,255,255,1);
}
.cutcall_bdr_pc {
    position: absolute;
    overflow: hidden;
    width: 60px;
    height: 40px;
    left: 0px;
    top: 0px;
    border: 2px solid;
}
#cutcall_img_pc {
    position: absolute;
    width: 23.451px;
    height: 23.451px;
    left: 18.567px;
    top: 8.246px;
    background-color: red;
    overflow: visible;
}
/* #grp_rec_start_pc_1:hover {
    left: 461px;
    top: 357px;
} */
#grp_rec_start_pc_1 {
    position: relative;
    width: 60px;
    height: 40px;
    /* left: 456px;
    top: 352px; */
    overflow: hidden;
    border: 2px solid;
    transition: all .3s ease-out;
}
#rec_brd_pc_1 {
    fill: rgba(255,255,255,1);
}
.rec_brd_pc_1 {
    position: absolute;
    overflow: visible;
    width: 60px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#rec_img_pc_1 {
    position: absolute;
    width: 23.451px;
    height: 23.451px;
    left: 18.567px;
    top: 8.246px;
    overflow: visible;
}
/* #grp_rec_stop_grp1:hover {
    left: 536px;
    top: 357px;
} */
#grp_rec_stop_grp1 {
    position: relative;
    width: 60px;
    height: 40px;
    /* left: 531px;
    top: 352px; */
    overflow: hidden;
    border: 2px solid;
    transition: all .3s ease-out;
}
#rec_stop_img_1 {
    fill: rgba(255,255,255,1);
}
.rec_stop_img_1 {
    position: absolute;
    overflow: visible;
    width: 60px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#rec_stop_img_1_cq {
    position: absolute;
    width: 23.451px;
    height: 23.451px;
    left: 18.567px;
    top: 8.246px;
    overflow: visible;
}
/* #grp_call_and_dec_ {
    position: absolute;
    width: 331px;
    height: 42px;
    left: 245px;
    top: 10px;
    overflow: visible;
}
#bg_design_callpop {
    position: absolute;
    width: 331px;
    height: 42px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#lbl_name_callpop {
    left: 10px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#btn_grp_popcall {
    position: absolute;
    width: 53px;
    height: 25px;
    left: 192px;
    top: 9px;
    overflow: visible;
    border-radius:  10px;
}
#rec_accept_popcall {
    opacity: 0.5;
    fill: rgba(188,226,179,1);
}
.rec_accept_popcall {
    position: absolute;
    overflow: visible;
    width: 53px;
    height: 25px;
    left: 0px;
    top: 0px;
}
#accept_call_popcall {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 17px;
    top: 3px;
    overflow: visible;
}
#btn_grp_decline_popcall {
    position: absolute;
    width: 53px;
    height: 26px;
    left: 261px;
    top: 9px;
    overflow: visible;
    border-radius:  10px;
}
#decline_rec_popcall {
    opacity: 0.5;
    fill: rgba(188,226,179,1);
}
.decline_rec_popcall {
    position: absolute;
    overflow: visible;
    width: 53px;
    height: 25px;
    left: 0px;
    top: 1px;
}
#decline_img_popcall {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 14px;
    top: 0px;
    overflow: visible;
} */
::-webkit-scrollbar {
width: 10px;
}


::-webkit-scrollbar-track {
background: #888;
}


::-webkit-scrollbar-thumb {
background: #DBF2E7;
}


::-webkit-scrollbar-thumb:hover {
background: #DBF2E7;
}


#online_user_grp_card {
    position: absolute;
    width: 310px;
    height: 214px;
    right: 50px;
    top: 5px;
    overflow: visible;
}
#online_user_bg_pc_img {
    position: absolute;
    width: 310px;
    height: 214px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_online_user_border {
    position: absolute;
    width: 267px;
    height: 33px;
    left: 22px;
    top: 7px;
    overflow: visible;
}
#Online_user_bdr {
    fill: rgba(219,242,231,1);
}
.Online_user_bdr {
    position: absolute;
    overflow: visible;
    width: 267px;
    height: 33px;
    left: 0px;
    top: 0px;
}
#online_user_lbl_pc {
    left: 86px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 96px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(5,5,5,1);
}
#online_user_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 302px;
    height: 155px;
    left: 8px;
    top: 44px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#online_user_per_1_grp {
    position: absolute;
    width: 62px;
    height: 82px;
    left: 9px;
    top: 7px;
    cursor: pointer;
    overflow: visible;
}
#user_1_img_tag {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 6px;
    top: 0px;
    overflow: visible;
}
#btn_user_grp {
    position: absolute;
    width: 62px;
    height: 21px;
    left: 0px;
    top: 61px;
    overflow: visible;
    border-radius: 10px;
}
#user_bdr_btn_call {
    fill: rgba(219,242,231,1);
}
.user_bdr_btn_call {
    position: absolute;
    overflow: visible;
    width: 62px;
    height: 21px;
    left: 0px;
    top: 0px;
}
#user_lbl_call {
    left: 0px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    cursor: pointer;
    color: rgba(5,5,5,1);
}


/* Chats New css */
#online_user_extended_pc_new {
    position: absolute;
    width: 310px;
    height: calc(100% - 160px);
    right: 50px;
    top: 29px;
    overflow: visible;
}
#online_user_bg_pc_img_new {
    position: absolute;
    width: 310px;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Online_user_bdr_new {
    fill: rgba(219,242,231,1);
}
.Online_user_bdr_new {
    position: absolute;
    overflow: visible;
    width: 267px;
    height: 33px;
    left: 22px;
    top: 7px;
}
#online_user_lbl_pc_new {
    left: 108px;
    top: 13px;
    position: absolute;
    overflow: visible;
    width: 96px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(5,5,5,1);
}
#online_user_scroll_new {
    mix-blend-mode: normal;
    position: absolute;
    width: 302px;
    height: calc(100% - 50px);
    left: 8px;
    top: 44px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#person_1_bdr_user_new {
    position: relative;
    width: 183px;
    height: 50px;
    left: 47px;
    top: 19px;
    overflow: visible;
}
#user_1_img_tag_new {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 0px;
    top: 0px;
    white-space: nowrap;
    overflow: visible;
}
#btn_grp_user_online_new {
    position: relative;
    width: 62px;
    height: 21px;
    left: 88px;
    top: 29px;
    cursor: pointer;
    overflow: visible;
}
#user_bdr_btn_call_new {
    fill: rgba(219,242,231,1);
}
.user_bdr_btn_call_new {
    position: absolute;
    overflow: visible;
    width: 62px;
    height: 21px;
    left: 0px;
    top: 0px;
}
#user_lbl_call_new {
    left: 21px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    cursor: pointer;
    color: rgba(5,5,5,1);
}
#user_lbl_call_cv_new {
    left: -20px;
    top: -30px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}

/* Flying Meeting PopUp */

#meeting_fly_pop_up_grp {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 18px;
    top: calc(100% - 120px);
    overflow: visible;
    border-radius: 50px;
}
#meeting_fly_pop_up_rec {
    fill: rgba(219,242,231,1);
    stroke: rgba(135,130,255,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.meeting_fly_pop_up_rec {
    position: absolute;
    overflow: visible;
    width: 60px;
    height: 60px;
    left: 0px;
    top: 0px;
    border-radius:  30px;
    background:  #e0e0e0;
    box-shadow:   2px 2px 5px #9f9f9f,-2px -2px 5px #ffffff;
}
#meeting_fly_pop_up {
    position: absolute;
    width: 39px;
    height: 39px;
    left: 11px;
    top: 11px;
    overflow: visible;
}
#meetings_scroll_grp {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 84px;
    top: calc(100% - 120px);
    cursor: pointer;
    overflow-x: scroll;
    overflow-y: hidden;
}
#Group_indiv_meetings_pt {
    position: absolute;
    width: 165px;
    height: 113px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#meeting_border_om {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.meeting_border_om {
    position: absolute;
    overflow: visible;
    width: 165px;
    height: 113px;
    left: 0px;
    top: 0px;
}
#Web_Development_lbl_val_om {
    left: 21px;
    top: 9px;
    position: absolute;
    overflow: visible;
    width: 124px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
    text-decoration: underline;
}
#Cancelled_lbl_val_om {
    left: 52px;
    top: 36px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#date_lbl_val_om {
    left: 51px;
    top: 59px;
    position: absolute;
    overflow: visible;
    width: 94px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#tine_lbl_val_om {
    left: 52px;
    top: 84px;
    position: absolute;
    overflow: visible;
    width: 45px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#date_img_pop_om {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 21px;
    top: 59px;
    overflow: visible;
}
#status_img_pop_om {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 21px;
    top: 35px;
    overflow: visible;
}
#time_img_pop_om {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 21px;
    top: 83px;
    overflow: visible;
}