.mediaViewInfo {
    --web-view-name: requirement page;
    --web-view-id: my_requirement_page_mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: my_requirement_page_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#my_requirement_page_mob {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 185px);
    top: 45px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: requirement page;
    --web-view-id: my_requirement_page_mob;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#req__bg_img_mob {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 140px);
  
    left: 0px;
    top: 0px;
    overflow: visible;
}
#My_req_name_tag {
    left: 18px;
    top: 30px;
    position: absolute;
    overflow: visible;
    width: 156px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(4,4,4,1);
}
#requirement_mob_switch {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.requirement_mob_switch {
    position: absolute;
    overflow: visible;
    width: 34px;
    height: 13px;
    right: 80px;
    top: 20px;
}
#add_myreq_req_mob {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 329px;
    top: 15px;
    overflow: visible;
}