.mediaViewInfo {
		--web-view-name: Chaats Paradise Mob;
		--web-view-id: ChaatsParadiseMob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ChaatsParadiseMob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ChaatsParadiseMob {
		position: absolute;
		width: 320px;
		height: 3922px;
        left:calc((100vw - 320px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Chaats Paradise Mob;
		--web-view-id: ChaatsParadiseMob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#CHAAT_22_MOBpexels-vinit-jain-16171917 {
		position: absolute;
		width: 320px;
		height: 500px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBGroup_107 {
		position: absolute;
		width: 320px;
		height: 500px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBRectangle_78 {
		opacity: 0.26;
		fill: rgba(26,26,26,1);
	}
	.CHAAT_22_MOBRectangle_78 {
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 500px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_MOBTaste_the_Best_Right_Now {
		left: 61px;
		top: 129px;
		position: absolute;
		overflow: visible;
		width: 199px;
		height: 121px;
		text-align: center;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(255,255,255,1);
	}
	#CHAAT_22_MOBAny_succesfull_career_starts__ {
		left: 63px;
		top: 289px;
		position: absolute;
		overflow: visible;
		width: 195px;
		white-space: nowrap;
		line-height: 18px;
		margin-top: -2px;
		text-align: center;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#CHAAT_22_MOBlogo {
		position: absolute;
		width: 40px;
		height: 32px;
		left: 19px;
		top: 18px;
        border-radius: 50%;
		overflow: hidden;
	}
	#CHAAT_22_MOBName {
		left: 71px;
		top: 23px;
		position: absolute;
		overflow: visible;
		width: 47px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#CHAAT_22_MOBGroup_15066 {
		position: absolute;
		width: 320px;
		height: 2062px;
		left: 0px;
		top: 560px;
		overflow: visible;
	}
	#CHAAT_22_MOBRectangle_743 {
		fill: rgba(241,241,241,1);
	}
	.CHAAT_22_MOBRectangle_743 {
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 2062px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_MOBCard_01_website_14_web_pc {
		position: absolute;
		width: 301px;
		height: 614px;
		left: 10px;
		top: 45px;
		overflow: visible;
	}
	#CHAAT_22_MOBScroll_Group_10 {
		mix-blend-mode: normal;
		position: absolute;
		width: 301px;
		height: 267px;
		left: 0px;
		top: 347px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHAAT_22_MOBintro_w14_web_pc {
		left: 4px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 294px;
		height: 252px;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(51,51,51,1);
	}
	#CHAAT_22_MOBVision_14_web_pc {
		left: 115px;
		top: 272px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 27px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_MOBaarn-giri-3lGi014_web_pc {
		position: absolute;
		width: 246px;
		height: 252px;
		left: 28px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBCard_01_website_14_web_pc_z {
		position: absolute;
		width: 301px;
		height: 614px;
		left: 10px;
		top: 723px;
		overflow: visible;
	}
	#CHAAT_22_MOBScroll_Group_10_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 301px;
		height: 267px;
		left: 0px;
		top: 347px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHAAT_22_MOBintro_w14_web_pc_ {
		left: 4px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 294px;
		height: 252px;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(51,51,51,1);
	}
	#CHAAT_22_MOBVision_14_web_pc_ {
		left: 115px;
		top: 272px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 27px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_MOBaarn-giri-3lGi014_web_pc_ {
		position: absolute;
		width: 246px;
		height: 252px;
		left: 28px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBCard_01_website_14_web_pc_ {
		position: absolute;
		width: 301px;
		height: 614px;
		left: 10px;
		top: 1401px;
		overflow: visible;
	}
	#CHAAT_22_MOBScroll_Group_10_ba {
		mix-blend-mode: normal;
		position: absolute;
		width: 301px;
		height: 267px;
		left: 0px;
		top: 347px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHAAT_22_MOBintro_w14_web_pc_ba {
		left: 4px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 294px;
		height: 252px;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(51,51,51,1);
	}
	#CHAAT_22_MOBVision_14_web_pc_ba {
		left: 115px;
		top: 272px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 27px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_MOBaarn-giri-3lGi014_web_pc_ba {
		position: absolute;
		width: 246px;
		height: 252px;
		left: 28px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBOur_Chaats {
		left: 83px;
		top: 2679px;
		position: absolute;
		overflow: visible;
		width: 156px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_MOBScroll_Group_78 {
		mix-blend-mode: normal;
		position: absolute;
		width: 310px;
		height: 436px;
		left: 10px;
		top: 2719px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#CHAAT_22_MOBGroup_14872 {
		position: relative;
		width: 213px;
		height: 317px;
		left: 19px;
		top: 53px;
        margin-right: 30px;
		overflow: visible;
	}
	#CHAAT_22_MOBRectangle_688 {
		fill: rgba(255,255,255,1);
		stroke: rgba(255,255,255,0.522);
		stroke-width: 22px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHAAT_22_MOBRectangle_688 {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 273px;
		height: 377px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_MOBpexels-shahbaz-ansari-13063300 {
		position: absolute;
		width: 213px;
		height: 286px;
		left: 0px;
		top: 31px;
		overflow: visible;
	}
	#CHAAT_22_MOBPani_Puri {
		left: 3px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 211px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_MOBLine_27 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHAAT_22_MOBLine_27 {
		overflow: visible;
		position: absolute;
		width: 311px;
		height: 1px;
		left: 0px;
		top: 3218px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHAAT_22_MOBRectangle_744 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHAAT_22_MOBRectangle_744 {
		position: absolute;
		overflow: visible;
		width: 266px;
		height: 207px;
		left: 27px;
		top: 3281px;
		border-radius: 20px;
	}
	#CHAAT_22_MOBGroup_15065 {
		position: absolute;
		width: 507px;
		height: 372px;
		left: 10px;
		top: 3527px;
		overflow: visible;
	}
	#CHAAT_22_MOBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 57px;
		top: 232px;
		overflow: visible;
	}
	#CHAAT_22_MOBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CHAAT_22_MOBmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_MOBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#CHAAT_22_MOBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.CHAAT_22_MOBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.671px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHAAT_22_MOBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.CHAAT_22_MOBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHAAT_22_MOBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.CHAAT_22_MOBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHAAT_22_MOBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CHAAT_22_MOBshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_MOBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.003px;
		overflow: visible;
	}
	#CHAAT_22_MOBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBmed_shadow_space_website_7_bt {
		fill: rgba(255,255,255,1);
	}
	.CHAAT_22_MOBmed_shadow_space_website_7_bt {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_MOBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.986px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#CHAAT_22_MOBn_234567890_mobile_number_shad {
		left: 35px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_MOBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_MOBaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 80px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_MOBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#CHAAT_22_MOBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#CHAAT_22_MOBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_MOBn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 328px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 44px;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}