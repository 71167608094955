	.mediaViewInfo {
		--web-view-name: RealtyScape Mob;
		--web-view-id: RealtyScape_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: RealtyScape_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#RealtyScape_Mob {
		position: absolute;
		width: 375px;
		height: 4786px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: RealtyScape Mob;
		--web-view-id: RealtyScape_Mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#n19_Mob_RSRectangle_736_c {
		fill: url(#n19_Mob_RSRectangle_736_c);
	}
	.n19_Mob_RSRectangle_736_c {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 620px;
		left: 0px;
		top: 4168px;
	}
	#n19_Mob_RSGroup_15043 {
		position: absolute;
		width: 375px;
		height: 489px;
		left: 0px;
		top: 19px;
		overflow: visible;
	}
	#n19_Mob_RSjoel-filipe-RFDP7_80v5A-unspla {
		position: absolute;
		width: 375px;
		height: 249px;
		left: 0px;
		top: 95px;
		overflow: visible;
	}
	#n19_Mob_RSGroup_15044 {
		position: absolute;
		width: 333px;
		height: 154px;
		left: 21px;
		top: 335px;
		overflow: visible;
	}
	#n19_Mob_RSRectangle_102 {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSRectangle_102 {
		filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		position: absolute;
		overflow: visible;
		width: 414px;
		height: 235px;
		left: 0px;
		top: 0px;
	}
	#n19_Mob_RSLets_fullfill___yours_Dream {
		filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 56px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 223px;
		white-space: nowrap;
		text-align: left;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(34,137,255,1);
	}
	#n19_Mob_RSBuilding_your_dream_house {
		left: 69px;
		top: 115px;
		position: absolute;
		overflow: visible;
		width: 133px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: bold;
		font-size: 10px;
		color: rgba(48,48,48,1);
	}
	#n19_Mob_RSAnime_Edit {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 88px;
		top: 0px;
		border-radius: 50%;
		overflow: hidden;
	}
	#n19_Mob_RSOni_Chan_Haircut {
		left: 158px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 133px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#n19_Mob_RSWhat_do_you_want_to_your__Drea {
		filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 42px;
		top: 568.001px;
		position: absolute;
		overflow: visible;
		width: 292px;
		white-space: nowrap;
		text-align: center;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(20,57,91,1);
	}
	#n19_Mob_RSScroll_Group_61 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 327px;
		left: 0px;
		top: 635px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#n19_Mob_RSGroup_15045 {
		position: absolute;
		width: 1280px;
		height: 246px;
		left: 16px;
		top: 33px;
		overflow: visible;
	}
	#n19_Mob_RSGroup_9691 {
		position: absolute;
		width: 13.622px;
		height: 10.84px;
		left: 244.366px;
		top: 213.346px;
		overflow: visible;
	}
	#n19_Mob_RScheck {
		position: absolute;
		width: 13.622px;
		height: 10.84px;
		left: -0.001px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSPath_2923 {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSPath_2923 {
		overflow: visible;
		position: absolute;
		width: 13.622px;
		height: 10.84px;
		left: 0.001px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n19_Mob_RSGroup_15042 {
		position: absolute;
		width: 285px;
		height: 246px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSRectangle_105 {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSRectangle_105 {
		filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 336px;
		left: 0px;
		top: 0px;
	}
	#n19_Mob_RSGroup_9756 {
		position: absolute;
		width: 234px;
		height: 204px;
		left: 26px;
		top: 26px;
		overflow: visible;
	}
	#n19_Mob_RStop_ {
		position: absolute;
		width: 234px;
		height: 166px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSDeluxe_Portion {
		opacity: 0.79;
		left: 58px;
		top: 179px;
		position: absolute;
		overflow: visible;
		width: 118px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#n19_Mob_RSGroup_9689 {
		position: absolute;
		width: 285px;
		height: 246px;
		left: 332px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSRectangle_105_do {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSRectangle_105_do {
		filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 336px;
		left: 0px;
		top: 0px;
	}
	#n19_Mob_RSGroup_9756_dp {
		position: absolute;
		width: 234px;
		height: 204px;
		left: 26px;
		top: 26px;
		overflow: visible;
	}
	#n19_Mob_RSfrancesca-tosolini-tHkJAMcO3QE {
		position: absolute;
		width: 234px;
		height: 166px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSDouble_Height {
		opacity: 0.79;
		left: 60px;
		top: 179px;
		position: absolute;
		overflow: visible;
		width: 115px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#n19_Mob_RSGroup_9689_ds {
		position: absolute;
		width: 285px;
		height: 246px;
		left: 664px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSRectangle_105_dt {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSRectangle_105_dt {
		filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 336px;
		left: 0px;
		top: 0px;
	}
	#n19_Mob_RSGroup_9756_du {
		position: absolute;
		width: 234px;
		height: 204px;
		left: 26px;
		top: 26px;
		overflow: visible;
	}
	#n19_Mob_RSalice-kang-Bc_uN5PeErA-unsplas {
		position: absolute;
		width: 234px;
		height: 166px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSPenthouse {
		opacity: 0.79;
		left: 73px;
		top: 179px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#n19_Mob_RSGroup_9689_dx {
		position: absolute;
		width: 285px;
		height: 246px;
		left: 995px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSRectangle_105_dy {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSRectangle_105_dy {
		filter: drop-shadow(0px 3px 30px rgba(24, 106, 165, 0.102));
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 336px;
		left: 0px;
		top: 0px;
	}
	#n19_Mob_RSGroup_9756_dz {
		position: absolute;
		width: 234px;
		height: 204px;
		left: 25.999px;
		top: 26px;
		overflow: visible;
	}
	#n19_Mob_RSharry-cunningham-UWblg2i6Blw-u {
		position: absolute;
		width: 234px;
		height: 166px;
		left: 0.001px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSThe_Studio {
		opacity: 0.79;
		left: 72.001px;
		top: 179px;
		position: absolute;
		overflow: visible;
		width: 87px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(141,149,152,1);
	}
	#n19_Mob_RSGroup_15047 {
		position: absolute;
		width: 334.272px;
		height: 289.038px;
		left: 11px;
		top: 982.001px;
		overflow: visible;
	}
	#n19_Mob_RSUnion_2 {
		fill: rgba(34,137,255,1);
	}
	.n19_Mob_RSUnion_2 {
		overflow: visible;
		position: absolute;
		width: 207.932px;
		height: 207.932px;
		left: 11.412px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n19_Mob_RSGroup_15046 {
		position: absolute;
		width: 334.272px;
		height: 231.038px;
		left: 0px;
		top: 58px;
		overflow: visible;
	}
	#n19_Mob_RSavi-werde-hHz4yrvxwlA-unsplash {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 280.298px;
		height: 180.974px;
		left: 53.975px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSjason-briscoe-UV81E0oXXWQ-unsp {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 150.344px;
		height: 100.129px;
		left: 0px;
		top: 130.909px;
		overflow: visible;
	}
	#n19_Mob_RSGroup_9722 {
		position: absolute;
		width: 32.767px;
		height: 32.767px;
		left: 58.758px;
		top: 164.835px;
		overflow: visible;
	}
	#n19_Mob_RSEllipse_17 {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSEllipse_17 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 50.768px;
		height: 50.768px;
		left: 0px;
		top: 0px;
	}
	#n19_Mob_RSPath_2939 {
		fill: rgba(34,137,255,1);
	}
	.n19_Mob_RSPath_2939 {
		filter: drop-shadow(0px 3px 6px rgba(34, 137, 255, 0.341));
		overflow: visible;
		position: absolute;
		width: 24.479px;
		height: 27.576px;
		left: 13.066px;
		top: 11.702px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n19_Mob_RSBuy__Dream_Apartment_In_The_Be {
		filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 38px;
		top: 1328.001px;
		position: absolute;
		overflow: visible;
		width: 235px;
		white-space: nowrap;
		line-height: 44px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(20,57,91,1);
	}
	#n19_Mob_RSGroup_9721 {
		position: absolute;
		width: 218px;
		height: 63px;
		left: 38px;
		top: 1493px;
		overflow: visible;
	}
	#n19_Mob_RSRectangle_107 {
		fill: rgba(24,106,165,1);
	}
	.n19_Mob_RSRectangle_107 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 236px;
		height: 81px;
		left: 0px;
		top: 0px;
	}
	#n19_Mob_RSSee_All_Apartment {
		left: 39px;
		top: 19px;
		position: absolute;
		overflow: visible;
		width: 143px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#n19_Mob_RSGroup_15033 {
		position: absolute;
		width: 364px;
		height: 618px;
		left: 11px;
		top: 1702px;
		overflow: visible;
	}
	#n19_Mob_RSavi-werde-hHz4yrvxwlA-unsplash_ef {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 299px;
		height: 224px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSGroup_15038 {
		position: absolute;
		width: 364px;
		height: 343px;
		left: 0px;
		top: 275px;
		overflow: visible;
	}
	#n19_Mob_RSScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 364px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n19_Mob_RSIt_is_a_long_established_fact_ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#n19_Mob_RSVISION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#n19_Mob_RSGroup_15048 {
		position: absolute;
		width: 364px;
		height: 618px;
		left: 11px;
		top: 2360px;
		overflow: visible;
	}
	#n19_Mob_RSsean-pollock-PhYq704ffdA-unspl {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 299px;
		height: 224px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSGroup_15038_em {
		position: absolute;
		width: 364px;
		height: 343px;
		left: 0px;
		top: 275px;
		overflow: visible;
	}
	#n19_Mob_RSScroll_Group_28_en {
		mix-blend-mode: normal;
		position: absolute;
		width: 364px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n19_Mob_RSIt_is_a_long_established_fact__eo {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#n19_Mob_RSMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#n19_Mob_RSGroup_15049 {
		position: absolute;
		width: 364px;
		height: 618px;
		left: 11px;
		top: 3018px;
		overflow: visible;
	}
	#n19_Mob_RSwebaliser-_TPTXZd9mOo-unsplash {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 299px;
		height: 224px;
		left: 27px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSGroup_15038_es {
		position: absolute;
		width: 364px;
		height: 343px;
		left: 0px;
		top: 275px;
		overflow: visible;
	}
	#n19_Mob_RSScroll_Group_28_et {
		mix-blend-mode: normal;
		position: absolute;
		width: 364px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n19_Mob_RSIt_is_a_long_established_fact__eu {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 336px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#n19_Mob_RSPRINCIPLES {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#n19_Mob_RSFeatured_Apartment_ {
		filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 69px;
		top: 3696px;
		position: absolute;
		overflow: visible;
		width: 239px;
		white-space: nowrap;
		text-align: center;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(20,57,91,1);
	}
	#n19_Mob_RSScroll_Group_62 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 379px;
		left: 0px;
		top: 3729px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#n19_Mob_RSGroup_15031 {
		position: relative;
		width: 296px;
		height: 313px;
		left: 11px;
		top: 30px;
		margin-right: 30px;
		overflow: visible;
	}
	#n19_Mob_RSRectangle_112 {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSRectangle_112 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 314px;
		height: 331px;
		left: 0px;
		top: 0px;
	}
	#n19_Mob_RSMask_Group_4 {
		position: absolute;
		width: 272px;
		height: 164px;
		left: 12px;
		top: 12px;
		overflow: visible;
	}
	#n19_Mob_RSn_500 {
		left: 11px;
		top: 207px;
		position: absolute;
		overflow: visible;
		width: 45px;
		white-space: nowrap;
		text-align: left;
		font-family: Sen;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(30,98,232,1);
	}
	#n19_Mob_RSApartment_London {
		left: 11px;
		top: 228px;
		position: absolute;
		overflow: visible;
		width: 117px;
		white-space: nowrap;
		text-align: left;
		font-family: Sen;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#n19_Mob_RSScroll_Group_59 {
		mix-blend-mode: normal;
		position: absolute;
		width: 269px;
		height: 57px;
		left: 5px;
		top: 250px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n19_Mob_RSBeautiful_Huge_1_family_House_ {
		opacity: 0.5;
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 264px;
		height: 133px;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#n19_Mob_RSGroup_9694 {
		position: absolute;
		width: 218.774px;
		height: 19px;
		left: 11.226px;
		top: 184px;
		overflow: visible;
	}
	#n19_Mob_RSBangalore {
		opacity: 0.5;
		left: 14.774px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 205px;
		height: 19px;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#n19_Mob_RSgeo-alt {
		position: absolute;
		width: 12.521px;
		height: 17.482px;
		left: 0px;
		top: 1.518px;
		overflow: visible;
	}
	#n19_Mob_RSPath_2927 {
		fill: rgba(254,120,71,1);
	}
	.n19_Mob_RSPath_2927 {
		overflow: visible;
		position: absolute;
		width: 12.521px;
		height: 17.482px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n19_Mob_RSGroup_15040 {
		position: absolute;
		width: 375px;
		height: 250px;
		left: 0px;
		top: 4168px;
		overflow: visible;
	}
	#n19_Mob_RSkisspng-loft-window-office-roo {
		position: absolute;
		width: 375px;
		height: 250px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.n19_Mob_RSRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 58px;
		top: 35px;
		border-radius: 10px;
	}
	#n19_Mob_RSGroup_15050 {
		position: absolute;
		width: 358px;
		height: 316px;
		left: 17px;
		top: 4451px;
		overflow: visible;
	}
	#n19_Mob_RSsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 194px;
		overflow: visible;
	}
	#n19_Mob_RSlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.n19_Mob_RSmed_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n19_Mob_RSmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n19_Mob_RSmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n19_Mob_RSlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#n19_Mob_RSn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.n19_Mob_RSn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n19_Mob_RSlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.n19_Mob_RSlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.554px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n19_Mob_RStop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.n19_Mob_RStop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.526px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n19_Mob_RSInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.n19_Mob_RSshadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n19_Mob_RSshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n19_Mob_RSshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n19_Mob_RSinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#n19_Mob_RSwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSmed_shadow_space_website_7_fq:hover {
		fill: rgba(50,172,158,1);
	}
	.n19_Mob_RSmed_shadow_space_website_7_fq:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n19_Mob_RSmed_shadow_space_website_7_fq {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n19_Mob_RSmed_shadow_space_website_7_fq {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n19_Mob_RSwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#n19_Mob_RSn_234567890_mobile_number_shad {
		left: 48px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#n19_Mob_RSaffanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#n19_Mob_RSaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#n19_Mob_RSlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#n19_Mob_RSemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#n19_Mob_RSphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n19_Mob_RSn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}