.mediaViewInfo {
    --web-view-name: general tab;
    --web-view-id: general_tab;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: general_tab;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#general_tab {
    position: absolute;
    width: 100%;
    height: calc(100% - 185px);
    top: 120px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: general tab;
    --web-view-id: general_tab;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#gen_down_holder_edit_page {
    position: absolute;
    width: 100%;
    height: calc(100% - 35px);
    left: 0px;
    top: 38px;
    overflow: visible;
}
#gen_holder_mob_eit_page {
    position: absolute;
    width: 127.822px;
    height: 37.873px;
    left: 0px;
    top: 1px;
    overflow: visible;
}
#top_grp_heading_general {
    position: absolute;
    width: 322px;
    height: 20px;
    left: 20px;
    top: 13px;
    overflow: visible;
}
#Generallbl_profile_mob {
    left: 25px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#dossier_lbl_profile_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Location_lbl_profile_mob {
    left: 143px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#placeholder_lbl_profile_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 117px;
    top: 0px;
    overflow: visible;
}
#Payment_Details_lbl_profile_mo {
    left: 277px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 46px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#card_lbl_profile_mob {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 248px;
    top: 0px;
    overflow: visible;
}
#general_page_scroll_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: calc(100% - 110px);
    left: 0px;
    top: 44px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#name_input_lbl_profile_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.name_input_lbl_profile_mob {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 75px;
    top: 38px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Name_lbl_profile_mob {
    left: 75px;
    top: 18px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#number_lbl_profile_mobinput {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.number_lbl_profile_mobinput {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 75px;
    top: 108px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Mobile_Number_lbl_profile_mob {
    left: 75px;
    top: 88px;
    position: absolute;
    overflow: visible;
    width: 106px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#email_input_lbl_profile_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.email_input_lbl_profile_mob {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 75px;
    top: 248px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Email_lbl_profile_mob {
    left: 75px;
    top: 228px;
    position: absolute;
    overflow: visible;
    width: 36px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#DDOB_lbl_profile_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.DDOB_lbl_profile_mob {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 75px;
    top: 388px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#DOB_lbl_profile_mob {
    left: 75px;
    top: 368px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#profession_input_lbl_profile_m {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.profession_input_lbl_profile_m {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 75px;
    top: 318px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Profession_lbl_profile_mob {
    left: 75px;
    top: 298px;
    position: absolute;
    overflow: visible;
    width: 70px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#introduction_input_lbl_profile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.introduction_input_lbl_profile {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 80px;
    left: 75px;
    top: 458px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Introduction_lbl_profile_mob {
    left: 75px;
    top: 438px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Vision_bdr_bdr_input_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Vision_bdr_bdr_input_mob {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 80px;
    left: 75px;
    top: 568px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Vision_lbl_profile_mob {
    left: 75px;
    top: 548px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#mission_bdr_input_lbl_profile_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.mission_bdr_input_lbl_profile_ {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 80px;
    left: 75px;
    top: 678px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Mission_lbl_profile_mob {
    left: 75px;
    top: 658px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#principle_bdr_lbl_profile_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.principle_bdr_lbl_profile_mob {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 80px;
    left: 75px;
    top: 788px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Principles_lbl_profile_mob {

    position: absolute;
    overflow: visible;
    width: 64px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    left: 75px;
    top: 768px;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Gender_lbl_profile_mob {
  
    position: absolute;
    overflow: visible;
    width: 50px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    left: 163px;
    top: 158px;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Group_gender_mob {
    position: absolute;
    width: 146px;
    height: 40px;
    left: 115px;
    top: 181px;
    overflow: visible;
}
#men_btn_grp {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#btn_bdr {
    fill: rgba(163,233,200,1);
}
.btn_bdr {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#man_btn_img {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#women_btn_grp {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 54px;
    top: 0px;
    overflow: visible;
}
#btn_bdr_ {
    fill: rgba(163,233,200,1);
}
.btn_bdr_ {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#woman_btn_ {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#other_btn_gender {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 106px;
    top: 0px;
    overflow: visible;
}
#bdr_other_btn {
    fill: rgba(163,233,200,1);
}
.bdr_other_btn {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#gender-neutral_btn_img {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#add_btn_tax_authority_mob {
    position: absolute;
    width: 109px;
    height: 36px;
    left: 35%;
    top: calc(100% - 50px);
    overflow: visible;
}
#add_bg_Add_Tax_Authority {
    fill: rgba(163,233,200,1);
}
.add_bg_Add_Tax_Authority {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 127px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#Active_lbl_Add_Tax_Authority {
    left: 41px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 28px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}