* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#box_cr_ {
    position: relative;
    width: 359px;
    height: 153px;
    left: 70px;
    top: 110px;
    overflow: visible;
}
#border_cr_ {
    fill: rgba(255,255,255,1);
}
.border_cr_ {
    position: absolute;
    overflow: visible;
    width: 359px;
    height: 153px;
    left: 0px;
    top: 0px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
#profile_img_cr {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 18.5px;
    top: 16.5px;
    border-radius: 50%;
    overflow: hidden;
}
#name_lbl_border_cr {
    fill: rgba(219,242,231,1);
}
.name_lbl_border_cr {
    position: absolute;
    overflow: visible;
    width: 220px;
    height: 30px;
    left: 123px;
    top: 20px;
}
#name_lbl_value_cr {
    left: 134.237px;
    top: 23.5px;
    position: absolute;
    overflow: visible;
    width: 137px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#Profession_lbl_value_cr {
    left: 114px;
    top: 68.5px;
    position: absolute;
    overflow: visible;
    width: 77px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Connected_On_lbl_value_cr {
    left: 87px;
    top: 88.5px;
    position: absolute;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Software_Developer_lbl_value_cr {
    left: 205px;
    top: 68.5px;
    position: absolute;
    overflow: visible;
    width: 131px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#date_new_value_cr {
    left: 205px;
    top: 88.5px;
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#border_box_cr:hover {
    left: 22px;
    top: 123px;
}
#border_box_cr {
    position: absolute;
    width: 70px;
    height: 26px;
    left: 17px;
    top: 118.5px;
    overflow: visible;
    border-radius: 15px;
    transition: all .3s ease-out;
}
#block_border_cr {
    fill: rgba(219,242,231,1);
}
.block_border_cr {
    position: absolute;
    overflow: visible;
    width: 70px;
    height: 26px;
    left: 0px;
    top: 0px;
}
#Block_value_cr {
    left: 17px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 36px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#border_ignore_cr:hover {
    left: 133px;
    top: 123px;
}
#border_ignore_cr {
    position: absolute;
    width: 70px;
    height: 26px;
    left: 128px;
    top: 118.5px;
    overflow: visible;
    border-radius: 15px;
    transition: all .3s ease-out;
}
#block_border_ignore_cr {
    fill: rgba(219,242,231,1);
}
.block_border_ignore_cr {
    position: absolute;
    overflow: visible;
    width: 70px;
    height: 26px;
    left: 0px;
    top: 0px;
}
#Ignore_value_ig {
    left: 13px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#border_disconnect_cr:hover {
    left: 245px;
    top: 123px;
}
#border_disconnect_cr {
    position: absolute;
    width: 98px;
    height: 26px;
    left: 240px;
    top: 118.5px;
    overflow: visible;
    border-radius: 15px;
    transition: all .3s ease-out;
}
#discon_border_cr {
    fill: rgba(219,242,231,1);
}
.discon_border_cr {
    position: absolute;
    overflow: visible;
    width: 98px;
    height: 26px;
    left: 0px;
    top: 0px;
}
#Disconnected_bd_value_cr {
    left: 3px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 91px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}

@keyframes slideInRight {
    from {
      transform: translate3d(100%, 0, 0);
      visibility: visible;
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInRight {
    animation: slideInRight 1s;
  }