#with_edit_option_mob {
    position: absolute;
    width: calc(100% - 15px);
    height: calc(100% - 65px);
    left: 8px;
    top: 65px;
    overflow: visible;
}
#event_nane_offreqevent_req_PC {
    opacity: 0.92;
    left: 10px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -7px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#swicj_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.swicj_mob {
    position: absolute;
    overflow: visible;
    width: 30px;
    height: 12px;
    right: 85px;
    top: -7px;
}
