	.mediaViewInfo {
		--web-view-name: websie 5 ShadowScape;
		--web-view-id: websie_5_ShadowScape;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: websie_5_ShadowScape;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#Shadow_Space_PC_websie_5_ShadowScape {
		position: absolute;
		width: 1366px;
		height: 2701px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(0,0,0,0.89);
		overflow: hidden;
		--web-view-name: websie 5 ShadowScape;
		--web-view-id: websie_5_ShadowScape;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#Shadow_Space_PC_n_th_shaodwow_spave_web_5 {
		filter: blur(50px);
		fill: rgba(255,255,255,0.149);
	}
	.Shadow_Space_PC_n_th_shaodwow_spave_web_5 {
		overflow: visible;
		position: absolute;
		width: 1915.849px;
		height: 1373.621px;
		left: -81.175px;
		top: 1066px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_n_rd_shaodwow_spave_web_5 {
		filter: blur(50px);
		fill: rgba(255,255,255,0.149);
	}
	.Shadow_Space_PC_n_rd_shaodwow_spave_web_5 {
		overflow: visible;
		position: absolute;
		width: 1885.334px;
		height: 1403.135px;
		left: 229.69px;
		top: 1818.197px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_n_nshaodwow_spave_web_5 {
		filter: blur(50px);
		fill: rgba(255,255,255,0.149);
	}
	.Shadow_Space_PC_n_nshaodwow_spave_web_5 {
		overflow: visible;
		position: absolute;
		width: 1488.57px;
		height: 1258.381px;
		left: -63.82px;
		top: 395.246px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_first_shaodwow_spave_web_5 {
		filter: blur(50px);
		fill: rgba(255,255,255,0.149);
	}
	.Shadow_Space_PC_first_shaodwow_spave_web_5 {
		overflow: visible;
		position: absolute;
		width: 1075.541px;
		height: 929.563px;
		left: -92.541px;
		top: -106.562px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_Scroll_comp_intro_shaodwow_spa {
		mix-blend-mode: normal;
		position: absolute;
		width: 605px;
		height: 209px;
		left: 58px;
		top: 367px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Shadow_Space_PC_Scroll_comp_intro_shaodwow_spa::-webkit-scrollbar{
		display: none;
	}
	#Shadow_Space_PC_intro_lbl_shaodwow_spave_web_5 {
		left: 7px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 537px;
		height: 207px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.1px;
	}
	#Shadow_Space_PC_Heading_shaodwow_spave_web_5 {
		left: 65px;
		top: 198px;
		position: absolute;
		overflow: visible;
		width: 537px;
		height: 145px;
		line-height: 73px;
		margin-top: -9.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 54px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_profile_pic_logo_shadow_space_ {
		position: absolute;
		width: 100px;
		height: 100px;
		left: 65px;
		top: 28px;
        border-radius: 50%;
		overflow: hidden;
	}
	#Shadow_Space_PC_Partners_grp_shadow_space_webs {
		position: absolute;
		width: 614px;
		height: 104px;
		left: 58px;
		top: 646px;
		overflow: visible;
	}
	#Shadow_Space_PC_text_our_partnersshadow_space_ {
		left: 26px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 181px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.1px;
	}
	#Shadow_Space_PC_Scroll_partner_shadow_space_we {
		mix-blend-mode: normal;
		position: absolute;
		width: 100vw;
		height: 65px;
		left: 0px;
		top: 39px;
        display: inline-flex;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#Shadow_Space_PC_Scroll_partner_shadow_space_we::-webkit-scrollbar{
		display: none;
	}
	#Shadow_Space_PC_partner_lbls_shadow_space_webs {
		position: relative;
		width: 175px;
		height: 27px;
		left: 26px;
		top: 7px;
		overflow: visible;
	}
	#Shadow_Space_PC_google_lbl_partner_shadow_spac {
		left: 30px;
		top: 0px;
		position: absolute;
		overflow: hidden;
		width: 146px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_google_1_shadow_space_website_ {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 1px;
		overflow: visible;
	}
	#Shadow_Space_PC_top_right_imgs_shadow_space_we {
		position: absolute;
		width: 527px;
		height: 559px;
		left: 839px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_right_gold_shadow_space_websit {
		fill: rgba(227,168,87,1);
	}
	.Shadow_Space_PC_right_gold_shadow_space_websit {
		position: absolute;
		overflow: visible;
		width: 392px;
		height: 441px;
		left: 135px;
		top: 0px;
	}
	#Shadow_Space_PC_down_white_shadow_space_websit {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Shadow_Space_PC_down_white_shadow_space_websit {
		position: absolute;
		overflow: visible;
		width: 392px;
		height: 441px;
		left: 0px;
		top: 118px;
	}
	#Shadow_Space_PC_damon-shadow_space_website_5 {
		filter: blur(0px);
		position: absolute;
		width: 393px;
		height: 441px;
		left: 67px;
		top: 59px;
		overflow: visible;
	}
	#Shadow_Space_PC_what_we_stand_for_shadow_space {
		left: 501px;
		top: 913px;
		position: absolute;
		overflow: visible;
		width: 365px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_Scrollprinciple_shadow_space_w {
		mix-blend-mode: normal;
		position: absolute;
		width: 260px;
		height: 150px;
		left: 994.75px;
		top: 1090px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Shadow_Space_PC_Scrollprinciple_shadow_space_w::-webkit-scrollbar{
		display: none;
	}
	#Shadow_Space_PC_intro_lbl_website_3_ss_pc {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 148px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_principles_lbl_shadow_space_we {
		left: 1075.25px;
		top: 1041px;
		position: absolute;
		overflow: visible;
		width: 101px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_Fill_pink_shadow_space_website {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Shadow_Space_PC_Fill_pink_shadow_space_website {
		filter: drop-shadow(0px 1.852px 3.148px rgba(242, 184, 236, 0.016));
		position: absolute;
		overflow: visible;
		width: 82.444px;
		height: 82.445px;
		left: 905.25px;
		top: 1041.5px;
	}
	#Shadow_Space_PC_principle_2_shadow_space_websi_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 912.75px;
		top: 1052px;
		overflow: visible;
	}
	#Shadow_Space_PC_Scroll_mission_shadow_space_we {
		mix-blend-mode: normal;
		position: absolute;
		width: 260px;
		height: 154px;
		left: 170.75px;
		top: 1088px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Shadow_Space_PC_Scroll_mission_shadow_space_we::-webkit-scrollbar{
		display: none;
	}
	#Shadow_Space_PC_intro_2_shadow_space_website_5 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 150px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_mission_lbl_shadow_space_websi {
		left: 260.75px;
		top: 1039px;
		position: absolute;
		overflow: visible;
		width: 81px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_Icon_2_grpshadow_space_website {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 81.25px;
		top: 1040px;
		overflow: visible;
	}
	#Shadow_Space_PC_bg_blueshadow_space_website_5 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Shadow_Space_PC_bg_blueshadow_space_website_5 {
		filter: drop-shadow(0px 1.852px 3.148px rgba(77, 223, 253, 0.016));
		position: absolute;
		overflow: visible;
		width: 82.444px;
		height: 82.445px;
		left: 0px;
		top: 0px;
	}
	#Shadow_Space_PC_leadership_2_shadow_space_webs_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 91.75px;
		top: 1050.5px;
		overflow: visible;
	}
	#Shadow_Space_PC_Scroll_vision_shadow_space_web {
		mix-blend-mode: normal;
		position: absolute;
		width: 265px;
		height: 153px;
		left: 572.75px;
		top: 1090.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Shadow_Space_PC_Scroll_vision_shadow_space_web::-webkit-scrollbar{
		display: none;
	}
	#Shadow_Space_PC_introshadow_space_website_5 {
		left: 0px;
		top: 2px;
		position: absolute;
		overflow: visible;
		width: 266px;
		height: 152px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_Visionshadow_space_website_5 {
		left: 662.75px;
		top: 1040.5px;
		position: absolute;
		overflow: visible;
		width: 65px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_Icon_grp_shadow_space_website_ {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 488.75px;
		top: 1037.5px;
		overflow: visible;
	}
	#Shadow_Space_PC_Fill_11shadow_space_website_5 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Shadow_Space_PC_Fill_11shadow_space_website_5 {
		filter: drop-shadow(0px 1.852px 3.148px rgba(150, 114, 255, 0.016));
		position: absolute;
		overflow: visible;
		width: 82.444px;
		height: 82.445px;
		left: 0px;
		top: 0px;
	}
	#Shadow_Space_PC_target_1_shadow_space_website_ {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 500.25px;
		top: 1047px;
		overflow: visible;
	}
	#Shadow_Space_PC_offering_lbl_shadow_space_webs {
		left: 71.25px;
		top: 1336px;
		position: absolute;
		overflow: visible;
		width: 187px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_Scroll_offering_space_shaow_we {
		mix-blend-mode: normal;
		position: absolute;
		width: 1237.5px;
		height: 419.5px;
		left: 52.25px;
		top: 1399px;
        display: -webkit-box;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#Shadow_Space_PC_Scroll_offering_space_shaow_we::-webkit-scrollbar{
		display: none;
	}
	#Shadow_Space_PC_offering_card_1_shadow_space_w {
		position: relative;
		width: 230px;
		height: 325px;
		left: 19px;
		top: 20px;
        margin: 10px;
		overflow: visible;
	}
	#Shadow_Space_PC_card_bg_offeringshadow_space_w {
		fill: rgba(255,255,255,1);
	}
	.Shadow_Space_PC_card_bg_offeringshadow_space_w {
		filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 245.5px;
		height: 340.5px;
		left: 0px;
		top: 0px;
	}
	#Shadow_Space_PC_half_circle_shadow_space_websi {
		fill: rgba(46,46,46,1);
	}
	.Shadow_Space_PC_half_circle_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 18.386px;
		height: 35px;
		left: 19px;
		top: 15px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_circle_deign_shadow_space_webs {
		fill: rgba(46,46,46,1);
	}
	.Shadow_Space_PC_circle_deign_shadow_space_webs {
		position: absolute;
		overflow: visible;
		width: 7px;
		height: 7px;
		left: 8px;
		top: 29px;
	}
	#Shadow_Space_PC_oofering_lbl_med_shadow_space_pc {
	
		top: 292px;
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 22px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Shadow_Space_PC_Icon_awesome-angle-right_shado {
		fill: rgba(0,0,0,1);
	}
	.Shadow_Space_PC_Icon_awesome-angle-right_shado {
		overflow: visible;
		position: absolute;
		width: 14.526px;
		height: 22.508px;
		left: 198.192px;
		top: 292px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_markus-shadow_space_website_5 {
		position: absolute;
		width: 204.834px;
		height: 270.879px;
		left: 11.5px;
		top: 11.17px;
		overflow: visible;
	}
	#Shadow_Space_PC_n_234567890_mobile_number_shad {
		left: 85px;
		top: 2456px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_affanghanioperatelive_email_sh {
		left: 301px;
		top: 2453px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_address_med_shadow_space_websi {
		left: 81px;
		top: 2516px;
		position: absolute;
		overflow: visible;
		width: 373px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 37px;
		top: 2516px;
		overflow: visible;
	}
	#Shadow_Space_PC_email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 253px;
		top: 2453px;
		overflow: visible;
	}
	#Shadow_Space_PC_phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 37px;
		top: 2456px;
		overflow: visible;
	}
	#Shadow_Space_PC_quote_grp_shadow_space_website {
		position: absolute;
		width: 354px;
		height: 108px;
		left: 964px;
		top: 2448px;
		overflow: visible;
	}
	#Shadow_Space_PC_Connect_with_us_and_unlock_lim_ss_pc {
		left: 35px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 320px;
		height: 108px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_quote_left_med_shadow_space_we {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_quote_right_med_shadow_space_w {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 155px;
		top: 83px;
		overflow: visible;
	}
	#Shadow_Space_PC_n_Copyright_Operate_Live_med_s {
		left: 37px;
		top: 2627px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_socials_grp_shadow_space_websi {
		position: absolute;
		width: 255px;
		height: 65px;
		left: 1014px;
		top: 2585px;
		overflow: visible;
	}
	#Shadow_Space_PC_linked_in_grp_shadow_space_web {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 190px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_Ellipse_5_med_shadow_space_web:hover {
		fill: rgba(120,120,255,1);
	}
	.Shadow_Space_PC_Ellipse_5_med_shadow_space_web:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Shadow_Space_PC_Ellipse_5_med_shadow_space_web {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Shadow_Space_PC_Ellipse_5_med_shadow_space_web {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#Shadow_Space_PC_linked_in_shadow_space_website {
		position: absolute;
		width: 31.653px;
		height: 28.776px;
		left: 17.063px;
		top: 17.875px;
		overflow: visible;
	}
	#Shadow_Space_PC_n_website_5_layout_ {
		fill: rgba(63,61,86,1);
	}
	.Shadow_Space_PC_n_website_5_layout_ {
		overflow: visible;
		position: absolute;
		width: 20.919px;
		height: 19.736px;
		left: 10.734px;
		top: 9.039px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.Shadow_Space_PC_linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 6.169px;
		height: 19.549px;
		left: 0.798px;
		top: 9.227px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.Shadow_Space_PC_top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 7.489px;
		height: 6.783px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_Insta_grp_shadow_space_website {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 95px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_Ellipse_4_shadow_space_website:hover {
		fill: rgba(255,120,120,1);
	}
	.Shadow_Space_PC_Ellipse_4_shadow_space_website:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Shadow_Space_PC_Ellipse_4_shadow_space_website {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Shadow_Space_PC_Ellipse_4_shadow_space_website {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#Shadow_Space_PC_instagramshadow_space_website_ {
		position: absolute;
		width: 31.688px;
		height: 31.688px;
		left: 17.063px;
		top: 17.063px;
		overflow: visible;
	}
	#Shadow_Space_PC_whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_Ellipse_3_med_shadow_space_web:hover {
		fill: rgba(50,172,158,1);
	}
	.Shadow_Space_PC_Ellipse_3_med_shadow_space_web:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#Shadow_Space_PC_Ellipse_3_med_shadow_space_web {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.Shadow_Space_PC_Ellipse_3_med_shadow_space_web {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 74px;
		height: 74px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#Shadow_Space_PC_whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 32.5px;
		height: 32.5px;
		left: 16.25px;
		top: 16.25px;
		overflow: visible;
	}
	#Shadow_Space_PC_oofering_lbl_med_shadow_space__du {
		left: 71.25px;
		top: 1844px;
		position: absolute;
		overflow: visible;
		width: 145px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_Scroll_event_web_5_pc {
		mix-blend-mode: normal;
		position: absolute;
		width: 1263.5px;
		height: 333.5px;
		left: 60px;
		top: 1920px;
		display: -webkit-box;
		overflow-x: auto;
		overflow-y: hidden;
	}
	#Shadow_Space_PC_my_event_sectionshadow_space_w {
		position: relative;
		width: 560px;
		height: 286px;
		left: 11px;
		top: 24px;
		margin: 10px;
		overflow: visible;
	}
	#Shadow_Space_PC_comic_con_bg_shadow_space_webs {
		fill: rgba(255,255,255,1);
	}
	.Shadow_Space_PC_comic_con_bg_shadow_space_webs {
		filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 529.5px;
		height: 301.5px;
		left: 46px;
		top: 0px;
	}
	#Shadow_Space_PC_Group_banner_event_img_pc {
		position: absolute;
		width: 230px;
		height: 210px;
		left: 0px;
		top: 30px;
		overflow: visible;
	}
	#Shadow_Space_PC_Anime_Edit_shadow_space_websit {
		position: absolute;
		width: 230px;
		height: 210px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_oofering_lbl_med_shadow_space__ee {
		left: 230px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 331px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Shadow_Space_PC_Scroll_description_event_web_5 {
		mix-blend-mode: normal;
		position: absolute;
		width: 326px;
		height: 158px;
		left: 234px;
		top: 82px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Shadow_Space_PC_offering_lbl_med_web_5_pc {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 312px;
		height: 158px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Shadow_Space_PC_date_web_5_pc_event {
		position: absolute;
		width: 125px;
		height: 30px;
		left: 246px;
		top: 38px;
		overflow: visible;
	}
	#Shadow_Space_PC_date_shadow_space_website_5 {
		left: 35px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 91px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Shadow_Space_PC_calendar_2_shadow_space_websit {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_time_web_5_pc_event {
		position: absolute;
		width: 152px;
		height: 30px;
		left: 391px;
		top: 38px;
		overflow: visible;
	}
	#Shadow_Space_PC_date_shadow_space_website_5_el {
		left: 35px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 118px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Shadow_Space_PC_clock_1_shadow_space_website_5 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_person_web_5_pc_event {
		position: absolute;
		width: 213px;
		height: 30px;
		left: 68px;
		top: 248px;
		overflow: visible;
	}
	#Shadow_Space_PC_man_2_shadow_space_website_5 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_date_shadow_space_website_5_ep {
		left: 35px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 179px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Shadow_Space_PC_seats_web_5_pc_event {
		position: absolute;
		width: 128px;
		height: 30px;
		left: 303px;
		top: 248px;
		overflow: visible;
	}
	#Shadow_Space_PC_seats_shadow_space_website_5 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_dateshadow_space_website_5 {
		left: 35px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 94px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Shadow_Space_PC_price_web_5_pc_event {
		position: absolute;
		width: 119px;
		height: 30px;
		left: 437px;
		top: 248px;
		overflow: visible;
	}
	#Shadow_Space_PC_price-tag_shadow_space_website {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Shadow_Space_PC_date_shadow_space_website_5_ev {
		left: 35px;
		top: 4px;
		position: absolute;
		overflow: visible;
		width: 85px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Shadow_Space_PC_Line_shadow_space_fotter_line_ {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Shadow_Space_PC_Line_shadow_space_fotter_line_ {
		overflow: visible;
		position: absolute;
		width: 1280px;
		height: 3px;
		left: 43px;
		top: 2377px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Shadow_Space_PC_home_shadow_space_website_5 {
		position: absolute;
		width: 79px;
		height: 27px;
		left: 322px;
		top: 26px;
		overflow: visible;
	}
	#Shadow_Space_PC_Home_shadow_space_website_5_eg {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_home-icon-shadow_space_website {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#Shadow_Space_PC_offering_shadow_space_website_ {
		position: absolute;
		width: 106px;
		height: 27px;
		left: 448px;
		top: 26px;
		overflow: visible;
	}
	#Shadow_Space_PC_Offerings_shadow_space_website {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_discount_shadow_space_website_ {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#Shadow_Space_PC_event_grp_shadow_space_website_pc {
		position: absolute;
		width: 82px;
		height: 27px;
		left: 600px;
		top: 26px;
		overflow: visible;
	}
	#Shadow_Space_PC_Events_shadow_space_website_5 {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 58px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_election-event-on-a-calendar-s {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
	#Shadow_Space_PC_contact_grpshadow_space_websit {
		position: absolute;
		width: 93px;
		height: 27px;
		left: 727px;
		top: 26px;
		overflow: visible;
	}
	#Shadow_Space_PC_Contact_lbl_shadow_space_websi {
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#Shadow_Space_PC_contact_img_shadow_space_websi {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 0px;
		top: 4px;
		overflow: visible;
	}
