	.mediaViewInfo {
		--web-view-name: Suggestions page;
		--web-view-id: Suggestions_page;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Suggestions_page;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Suggestions_page {
		position: absolute;
		width: calc(100vw - 120px);
		height: calc(100vh - 60px);
        top: 60px;
        left: 120px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Suggestions page;
		--web-view-id: Suggestions_page;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#heading_grp_conreqsug_sug_page {
		position: absolute;
		width: 364px;
		height: 42px;
		left: calc((100% - 364px)/2);
		top: 22px;
		overflow: visible;
	}
	#conreqsug_sug_page_pc_new_requ {
		opacity: 0.92;
		left: 151px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 65px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(0,0,0,1);
	}
	#conreqsug_sug_page_pc_new_conn {
		opacity: 0.92;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 100px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(0,0,0,1);
	}
	#conreqsug_sug_page_pc_new_sug {
		opacity: 0.92;
		left: 267px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 98px;
		white-space: nowrap;
		line-height: 29px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
        cursor: pointer;
		color: rgba(255,79,4,1);
	}
	#underline_conreqsug_sug_page_p {
		fill: rgba(255,79,4,1);
	}
	.underline_conreqsug_sug_page_p {
		position: absolute;
		overflow: visible;
		width: 99px;
		height: 3px;
		left: 267px;
		top: 39px;
	}
	#desgin_3_sug_page_pc_new {
		fill: rgba(248,248,248,1);
	}
	.desgin_3_sug_page_pc_new {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 203px;
		left: 0px;
		top: 94px;
	}
	#Scroll_suggestion_new_layout_c {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 625px;
		/* left: 59px; */
		top: 116px;
        display: block;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Scroll_suggestion_new_layout_c::-webkit-scrollbar{
		display: none;
	}
	#card_1_suggestion_page_new {
		position: relative;
		width: 186px;
		height: 319px;
		left: 15px;
		top: 16px;
        margin: 10px;
        display: inline-block;
		overflow: visible;
	}
	#bdr_card_1_sug_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.bdr_card_1_sug_sug_page_pc_new {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 193px;
		height: 327px;
		left: 0px;
		top: 0px;
	}
	#Anime_Edit_sug_page_pc_new {
		position: absolute;
		width: 150px;
		height: 142px;
		left: 18px;
		top: 35px;
		overflow: visible;
	}
	#job_work_new_layout {
		position: absolute;
		width: 170px;
		height: 25px;
		left: 12px;
		top: 182px;
		overflow: visible;
	}
	#job-seeker_sug_page_pc_new {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#recently_affreqevent_sug_page_ {
		left: 30px;
		top: 3px;
		position: absolute;
		overflow: hidden;
		width: 141px;
		height: 20px;
		/* line-height: 49px;
		margin-top: -17px; */
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: capitalize;
	}
	#Mohammed_Affan_sug_page_pc_new {
       
        top: 5px;
        position: absolute;
        overflow: hidden;
        width: 185px;
        height: 20px;
        /* line-height: 49px; */
        /* margin-top: -17px; */
        text-align: center;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        color: rgba(17,17,17,1);
        letter-spacing: -0.4791666793823242px;
	}
	#follow_grp_sug_page_pc_new {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 51.999px;
		top: 274px;
        cursor: pointer;
		overflow: visible;
	}
	#bdr_gollow_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.bdr_gollow_sug_page_pc_new {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#folow_img_sug_page_pc_new {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#connect_grp_sug_page_pc_new {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 8px;
		top: 274px;
        cursor: pointer;
		overflow: visible;
	}
	#con_bdr_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.con_bdr_sug_page_pc_new {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#conn_n_sug_page_pc_new {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#ignore_grp_sug_page_pc_new {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 96px;
		top: 275px;
        cursor: pointer;
		overflow: visible;
	}
	#bfdr_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.bfdr_sug_page_pc_new {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#ignore_sig_sug_page_pc_new {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#block_grp_sug_page_pc_new {
		position: absolute;
		width: 38px;
		height: 38px;
		left: 140px;
		top: 275px;
        cursor: pointer;
		overflow: visible;
	}
	#bdr_block_sug_page_pc_new {
		fill: rgba(255,255,255,1);
	}
	.bdr_block_sug_page_pc_new {
		filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 44px;
		left: 0px;
		top: 0px;
	}
	#boock_img_sug_page_pc_new {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 7px;
		top: 7px;
		overflow: visible;
	}
	#scroll_and_intro_detail_new {
		position: absolute;
		width: 163px;
		height: 61px;
		left: 12px;
		top: 212px;
		overflow: visible;
	}
	#presentation_sug_page_pc_new {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Scroll_intro_pc_sug_page {
		mix-blend-mode: normal;
		position: absolute;
		width: 133px;
		height: 60px;
		left: 30px;
		top: 1px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
    #Scroll_intro_pc_sug_page::-webkit-scrollbar {
        display: none;
    }
	#recently_event_com_suggestion_p {
		
		position: absolute;
		
		width: 135px;
		height: 59px;
		/* line-height: 49px; */
		/* margin-top: -17px; */
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
        overflow: visible;
		color: rgba(17,17,17,1);
		text-transform: capitalize;
	}