.wrapper{
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    width: '100%';
}

/* .appBar{
    border-radius: 15;
      margin: '30px 100px';
       display: 'flex';
       flex-direction: 'row';
       justify-content: 'center';
       align-items: 'center';
       width: '600px';
       border: '2px solid black';
} */

.appBar{
    border-radius: 15px;
    margin: 30px 100px;
    border: 2px solid black;
    width: 100px;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
[class*="appBar"] {
    width: 100%;
  }

 