.mediaViewInfo {
    --web-view-name: profile edit pop;
    --web-view-id: profile_edit_pop;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: profile_edit_pop;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#profile_edit_pop {
    position: absolute;
    width: 712px;
    height: 630px;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    border-radius: 15px;    
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: profile edit pop;
    --web-view-id: profile_edit_pop;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#pop_bg_desig {
    opacity: 0.59;
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 718px;
    height: 636px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#user_pfp_pop_lbl_profile_pc {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 23px;
    top: 12px;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
}
#Edit_Profile_lbl_profile_pc {
    left: 74px;
    top: 22px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#cancel_btn_grp_profile:hover {
    left: 436px;
    top: 29px;
}
#cancel_btn_grp_profile {
    position: absolute;
    width: 109px;
    height: 36px;
    left: 432px;
    top: 24px;
    overflow: visible;
    cursor: pointer;
    transition: all .3s ease-out;
}
#cancel_bdr_btn_rpofile {
    fill: rgba(163,233,200,1);
}
.cancel_bdr_btn_rpofile {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 109px;
    height: 36px;
    left: 0px;
    top: 0px;
}
#cancel_lbl_profile {
    /* left: 36px; */
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#btn_save_changes_profile_edit_:hover {
    left: 562px;
    top: 29px;
}
#btn_save_changes_profile_edit_ {
    position: absolute;
    width: 109px;
    height: 36px;
    left: 558px;
    top: 24px;
    overflow: visible;
    cursor: pointer;
    transition: all .3s ease-out;
}
#save_update_bg_btn {
    fill: rgba(163,233,200,1);
}
.save_update_bg_btn {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 109px;
    height: 36px;
    left: 0px;
    top: 0px;
}
#save_updates_lbl_btn {
 
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#close2_edit_profile_page {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 10px;
    top: 9px;
    cursor: pointer;
    overflow: visible;
}
#scroll_profile_page_edit {
    mix-blend-mode: normal;
    position: absolute;
    width: 723px;
    height: 551px;
    left: 0px;
    top: 70px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#name_input_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.name_input_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 23px;
    top: 40px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Name_lbl_profile_pc {
    left: 23px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#number_lbl_profile_pc_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.number_lbl_profile_pc_input {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 252px;
    top: 40px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Mobile_Number_lbl_profile_pc {
    left: 252px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 106px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Gender_lbl_profile_pc {
    left: 553px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 50px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#country_drop_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.country_drop_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 23px;
    top: 387px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Country_lbl_profile_pc {
    left: 23px;
    top: 366px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#state_drop_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.state_drop_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 252px;
    top: 387px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#State_lbl_profile_pc {
    left: 252px;
    top: 366px;
    position: absolute;
    overflow: visible;
    width: 34px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#city_input_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.city_input_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 481px;
    top: 387px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#City_lbl_profile_pc {
    left: 481px;
    top: 366px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#address_input_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.address_input_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 60px;
    left: 23px;
    top: 460px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Address_lbl_profile_pc {
    left: 23px;
    top: 439px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#pincode_bdr_input_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.pincode_bdr_input_pc {
    position: absolute;
    overflow: visible;
    width: 141px;
    height: 40px;
    left: 252px;
    top: 460px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Pincode_lbl_lbl_profile_pc {
    left: 252px;
    top: 439px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
.map_lattitude_input_pc {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 40px;
    left: 405px;
    top: 460px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#map_lattitute_lbl_lbl_profile_pc {
    left: 405px;
    top: 439px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
.map_longitude_input_pc {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 40px;
    left: 555px;
    top: 460px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#map_longitude_lbl_profile_pc {
    left: 555px;
    top: 439px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#men_btn_grp_pc {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 511px;
    top: 40px;
    cursor: pointer;
    overflow: visible;
}
#btn_bdr_pop {
    fill: rgba(163,233,200,1);
}
.btn_bdr_pop {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#man_btn_img_pc {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#women_btn_grp_pc {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 565px;
    top: 40px;
    cursor: pointer;
    overflow: visible;
}
#btn_bdr_pc_pop {
    fill: rgba(163,233,200,1);
}
.btn_bdr_pc_pop {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#woman_btn_pc {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#other_btn_gender_pc {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 617px;
    top: 40px;
    cursor: pointer;
    overflow: visible;
}
#bdr_other_btn_pc {
    fill: rgba(163,233,200,1);
}
.bdr_other_btn_pc {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#gender-neutral_btn_img_pc {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#introduction_input_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.introduction_input_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 325px;
    height: 60px;
    left: 23px;
    top: 180px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Introduction_lbl_profile_pc {
    left: 23px;
    top: 160px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#email_input_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.email_input_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 23px;
    top: 110px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Email_lbl_profile_pc {
    left: 23px;
    top: 90px;
    position: absolute;
    overflow: visible;
    width: 36px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Generallbl_profile_pc {
    left: 48px;
    top: -2px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#Location_lbl_profile_pc {
    left: 48px;
    top: 340px;
    position: absolute;
    overflow: visible;
    width: 70px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#DDOB_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.DDOB_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 481px;
    top: 110px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#DOB_lbl_profile_pc {
    left: 481px;
    top: 90px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Payment_Details_lbl_profile_pc {
    left: 48px;
    top: 530px;
    position: absolute;
    overflow: visible;
    width: 128px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#HBR_input_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.HBR_input_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 252px;
    top: 712px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Hour_Billing_Rate_lbl_profile_pc {
    left: 252px;
    top: 692px;
    position: absolute;
    overflow: visible;
    width: 112px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#billing_currency_input_edit_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.billing_currency_input_edit_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 481px;
    top: 712px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Billing_Currency_lbl_profile_pop {
    left: 481px;
    top: 692px;
    position: absolute;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#profession_input_lbl_profile_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.profession_input_lbl_profile_pop {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 252px;
    top: 110px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Profession_lbl_profile_pc {
    left: 252px;
    top: 90px;
    position: absolute;
    overflow: visible;
    width: 70px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#dossier_lbl_profile_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 23px;
    top: 0px;
    overflow: visible;
}
#placeholder_lbl_profile_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 23px;
    top: 341px;
    overflow: visible;
}
#card_lbl_profile_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 23px;
    top: 531px;
    overflow: visible;
}
#Vision_bdr_bdr_input_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Vision_bdr_bdr_input_pc {
    position: absolute;
    overflow: visible;
    width: 325px;
    height: 60px;
    left: 370px;
    top: 180px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Vision_lbl_profile_pc {
    left: 370px;
    top: 160px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#mission_bdr_input_lbl_profile_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.mission_bdr_input_lbl_profile_pop {
    position: absolute;
    overflow: visible;
    width: 325px;
    height: 60px;
    left: 370px;
    top: 270px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Mission_lbl_profile_pc {
    left: 370px;
    top: 250px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#principle_bdr_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.principle_bdr_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 325px;
    height: 60px;
    left: 23px;
    top: 270px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Principles_lbl_profile_pc {
    left: 23px;
    top: 250px;
    position: absolute;
    overflow: visible;
    width: 64px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#UPI_input_lbl_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.UPI_input_lbl_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 481px;
    top: 642px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#UPI_ID_lbl_profile_pc {
    left: 481px;
    top: 622px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#business_name_input_edit_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.business_name_input_edit_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 23px;
    top: 572px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#business_name_lbl_edit_pc {
    left: 23px;
    top: 552px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#business_mobile_input_edit_pro {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.business_mobile_input_edit_pro {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 252px;
    top: 572px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#business_mobile_lbl_edit_profu {
    left: 252px;
    top: 552px;
    position: absolute;
    overflow: visible;
    width: 107px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#business_email_input_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.business_email_input_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 481px;
    top: 572px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#business_email_lbl_edit_profil {
    left: 481px;
    top: 552px;
    position: absolute;
    overflow: visible;
    width: 97px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#business_address_input_edit_pr {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.business_address_input_edit_pr {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 110px;
    left: 23px;
    top: 642px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#UPI_ID_lbl_profile_paymet_page_pc {
    left: 23px;
    top: 622px;
    position: absolute;
    overflow: visible;
    width: 114px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#web_bus_edit_profile_pc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.web_bus_edit_profile_pc {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 252px;
    top: 642px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#website_business_edit_profile_pc {
    left: 252px;
    top: 622px;
    position: absolute;
    overflow: visible;
    width: 114px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#socials_lbl_edit_profile {
    left: 48px;
    top: 762px;
    position: absolute;
    overflow: visible;
    width: 128px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#social_img_edit_profile_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 23px;
    top: 763px;
    overflow: visible;
}
#whatsapp_input_edit_profile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.whatsapp_input_edit_profile {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 23px;
    top: 807px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#whats_app_number_lbl_edit_pc {
    left: 23px;
    top: 787px;
    position: absolute;
    overflow: visible;
    width: 129px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#linked_in_edit_profile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.linked_in_edit_profile {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 252px;
    top: 807px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#linked_in_lbl_edit_profile {
    left: 252px;
    top: 787px;
    position: absolute;
    overflow: visible;
    width: 87px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#insta_url_box_edit_profile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.insta_url_box_edit_profile {
    position: absolute;
    overflow: visible;
    width: 214px;
    height: 40px;
    left: 481px;
    top: 808px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#insta_url_lbl_edit_profile {
    left: 481px;
    top: 788px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}