
	.mediaViewInfo {
		--web-view-name: market pc;
		--web-view-id: market_pc;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: market_pc;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#market_pc {
		position: absolute;
		width: 1237px;
		height: 2254px;
        top:60px;
        left:120px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: market pc;
		--web-view-id: market_pc;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#usama-akram-kP6knT7tjn4-unspla_ {
		fill: url(#usama-akram-kP6knT7tjn4-unspla_);
	}
	.usama-akram-kP6knT7tjn4-unspla_ {
		position: absolute;
		overflow: visible;
		width: 1137px;
		height: 285px;
		left: 50px;
		top: 408px;
	}
	#usama-akram-kP6knT7tjn4-unspla_ba {
		fill: rgba(225,187,64,1);
	}
	.usama-akram-kP6knT7tjn4-unspla_ba {
		position: absolute;
		overflow: visible;
		width: 1137px;
		height: 133px;
		left: 50px;
		top: 187px;
	}
	#Rectangle_2 {
		fill: rgba(255,255,255,1);
	}
	.Rectangle_2 {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 1145px;
		height: 125px;
		left: 50px;
		top: 40px;
	}
	#Group_6 {
		position: absolute;
		width: 80px;
		height: 73px;
		left: 110px;
		top: 66px;
		overflow: visible;
	}
	#Electronics {
		left: 0px;
		top: 51px;
		position: absolute;
		overflow: visible;
		width: 81px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#electronics {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 15px;
		top: 0px;
		overflow: visible;
	}
	#Group_5 {
		position: absolute;
		width: 58px;
		height: 73px;
		left: 324px;
		top: 66px;
		overflow: visible;
	}
	#Grocery {
		left: 0px;
		top: 51px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#shopping-bag {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 4px;
		top: 0px;
		overflow: visible;
	}
	#Group_4 {
		position: absolute;
		width: 50px;
		height: 73px;
		left: 514px;
		top: 66px;
		overflow: visible;
	}
	#Home {
		left: 2px;
		top: 51px;
		position: absolute;
		overflow: visible;
		width: 47px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#furniture {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_3 {
		position: absolute;
		width: 50px;
		height: 73px;
		left: 688px;
		top: 66px;
		overflow: visible;
	}
	#Toys {
		left: 8px;
		top: 51px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#blocks {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_2 {
		position: absolute;
		width: 52px;
		height: 73px;
		left: 863px;
		top: 66px;
		overflow: visible;
	}
	#Mobile {
		left: 0px;
		top: 51px;
		position: absolute;
		overflow: visible;
		width: 53px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#smartphone {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 1px;
		top: 0px;
		overflow: visible;
	}
	#Group_1 {
		position: absolute;
		width: 77px;
		height: 73px;
		left: 1049px;
		top: 66px;
		overflow: visible;
	}
	#Top_Offers {
		left: 0px;
		top: 51px;
		position: absolute;
		overflow: visible;
		width: 78px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#shopping-basket {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 14px;
		top: 0px;
		overflow: visible;
	}
	#Group_122 {
		position: absolute;
		width: 200px;
		height: 244px;
		left: 665px;
		top: 678px;
		overflow: visible;
	}
	#n_2188678_4907157 {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 244px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Limited_Offer {
		left: 53px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#c-d-x-PDX_a_82obo-unsplash {
		position: absolute;
		width: 162px;
		height: 80px;
		left: 19px;
		top: 34px;
		overflow: visible;
	}
	#rachit-tank-2cFZ_FB08UM-unspla {
		position: absolute;
		width: 160px;
		height: 80px;
		left: 21px;
		top: 139px;
		overflow: visible;
	}
	#Headphones_100_off {
		left: 21px;
		top: 116px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Apple_Watch_90_off {
		left: 21px;
		top: 221px;
		position: absolute;
		overflow: visible;
		width: 103px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Group_19 {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 165px;
		top: 118px;
		overflow: visible;
	}
	#previous_1 {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_20 {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 165px;
		top: 222px;
		overflow: visible;
	}
	#previous_1_by {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_121 {
		position: absolute;
		width: 200px;
		height: 244px;
		left: 384px;
		top: 678px;
		overflow: visible;
	}
	#n_2188678_4907157_b {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 244px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Top_Products {
		left: 54px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#eniko-kis-KsLPTsYaqIQ-unsplash {
		position: absolute;
		width: 162px;
		height: 80px;
		left: 19px;
		top: 34px;
		overflow: visible;
	}
	#martin-katler-7wCxlBfGMdk-unsp {
		position: absolute;
		width: 160px;
		height: 80px;
		left: 21px;
		top: 139px;
		overflow: visible;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#Camera {
		left: 21px;
		top: 115px;
		position: absolute;
		overflow: visible;
		width: 38px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#RTX_4090 {
		left: 21px;
		top: 220px;
		position: absolute;
		overflow: visible;
		width: 46px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Group_21 {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 165px;
		top: 118px;
		overflow: visible;
	}
	#previous_1_b {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_22 {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 165px;
		top: 222px;
		overflow: visible;
	}
	#previous_1_ca {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_123 {
		position: absolute;
		width: 200px;
		height: 244px;
		left: 945px;
		top: 678px;
		overflow: visible;
	}
	#n_2188678_4907157_cb {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 244px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#New_Launch {
		left: 57px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 87px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#tamara-bitter-JIrjoWIgFAs-unsp {
		position: absolute;
		width: 162px;
		height: 80px;
		left: 19px;
		top: 34px;
		overflow: visible;
	}
	#ales-nesetril-Im7lZjxeLhg-unsp {
		position: absolute;
		width: 160px;
		height: 80px;
		left: 21px;
		top: 139px;
		overflow: visible;
	}
	#PS_5_Delux_Edition {
		left: 21px;
		top: 114px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#Apple_MacBook {
		left: 21px;
		top: 219px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#Group_27 {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 165px;
		top: 118px;
		overflow: visible;
	}
	#previous_1_ci {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_28 {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 165px;
		top: 223px;
		overflow: visible;
	}
	#previous_1_ck {
		position: absolute;
		width: 13px;
		height: 13px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Group_120 {
		position: absolute;
		width: 200px;
		height: 244px;
		left: 103px;
		top: 678px;
		overflow: visible;
	}
	#n_2188678_4907157_cm {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 244px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#domino-164_6wVEHfI-unsplash {
		position: absolute;
		width: 72px;
		height: 80px;
		left: 21px;
		top: 34px;
		overflow: visible;
	}
	#martin-katler-7wCxlBfGMdk-unsp_co {
		position: absolute;
		width: 72px;
		height: 80px;
		left: 108px;
		top: 34px;
		overflow: visible;
	}
	#paul-gaudriault-a-QH9MAAVNI-un {
		position: absolute;
		width: 72px;
		height: 80px;
		left: 21px;
		top: 139px;
		overflow: visible;
	}
	#My_project {
		position: absolute;
		width: 72px;
		height: 80px;
		left: 108px;
		top: 139px;
		overflow: visible;
	}
	#Recent_Visits {
		left: 56px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 90px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#RTX_4090_cs {
		left: 108px;
		top: 115px;
		position: absolute;
		overflow: visible;
		width: 46px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Canvas {
		left: 21px;
		top: 220px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Construction {
		left: 108px;
		top: 220px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#Sport_Shoe {
		left: 21px;
		top: 115px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		color: rgba(0,0,0,1);
	}
	#previous_1_cw {
		position: absolute;
		width: 12px;
		height: 12px;
		left: 81px;
		top: 117px;
		overflow: visible;
	}
	#previous_1_cx {
		position: absolute;
		width: 12px;
		height: 12px;
		left: 170px;
		top: 117px;
		overflow: visible;
	}
	#previous_1_cy {
		position: absolute;
		width: 12px;
		height: 12px;
		left: 81px;
		top: 222px;
		overflow: visible;
	}
	#previous_1_cz {
		position: absolute;
		width: 12px;
		height: 12px;
		left: 170px;
		top: 222px;
		overflow: visible;
	}
	#AD_AREA {
		left: 403px;
		top: 187px;
		position: absolute;
		overflow: visible;
		width: 433px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 100px;
		color: rgba(255,255,255,1);
	}
	#My_project_3 {
		position: absolute;
		width: 405.092px;
		height: 437.322px;
		left: 454.437px;
		top: 293.548px;
		overflow: visible;
	}
	#Rectangle_7 {
		fill: rgba(0,0,0,1);
	}
	.Rectangle_7 {
		position: absolute;
		overflow: visible;
		width: 28px;
		height: 35px;
		left: 1137px;
		top: 436px;
	}
	#Rectangle_8 {
		fill: rgba(255,255,255,1);
	}
	.Rectangle_8 {
		position: absolute;
		overflow: visible;
		width: 28px;
		height: 35px;
		left: 1107px;
		top: 436px;
	}
	#Group_29 {
		position: absolute;
		width: 64px;
		height: 64px;
		left: 406px;
		top: 451.209px;
		overflow: visible;
	}
	#nike {
		position: absolute;
		width: 64px;
		height: 64px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SUP {
		transform: translate(-476px, -437.209px) matrix(1,0,0,1,495,446) rotate(-14deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 29px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Line_1 {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_1 {
		overflow: visible;
		position: absolute;
		width: 3px;
		height: 39px;
		left: 484.5px;
		top: 461.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Superro_Air {
		left: 499px;
		top: 469.5px;
		position: absolute;
		overflow: visible;
		width: 78px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#shoe {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 827px;
		top: 418px;
		overflow: visible;
	}
	#shoe_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 872px;
		top: 418px;
		overflow: visible;
	}
	#shoe_2 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 917px;
		top: 418px;
		overflow: visible;
	}
	#Group_30 {
		position: absolute;
		width: 85px;
		height: 33px;
		left: 414px;
		top: 540px;
		overflow: visible;
	}
	#Rectangle_9 {
		fill: rgba(255,255,255,1);
	}
	.Rectangle_9 {
		position: absolute;
		overflow: visible;
		width: 85px;
		height: 33px;
		left: 0px;
		top: 0px;
	}
	#Buy_Now {
		left: 11px;
		top: 6.5px;
		position: absolute;
		overflow: visible;
		width: 65px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Level_Up_Your_Style {
		left: 88px;
		top: 436px;
		position: absolute;
		overflow: visible;
		width: 237px;
		height: 134px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		color: rgba(0,0,0,1);
	}
	#right-arrow_3 {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 1141px;
		top: 444px;
		overflow: visible;
	}
	#right-arrow_2 {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 1111px;
		top: 444px;
		overflow: visible;
	}
	#We_provide_best_customer_exper {
		left: 103px;
		top: 982px;
		position: absolute;
		overflow: visible;
		width: 297px;
		height: 78px;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#Line_2 {
		fill: transparent;
		stroke: rgba(0,0,0,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Line_2 {
		overflow: visible;
		position: absolute;
		width: 4.038px;
		height: 81.038px;
		left: 734.5px;
		top: 982.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#We_ensure_our_customers_have_t {
		left: 749px;
		top: 998px;
		position: absolute;
		overflow: visible;
		width: 397px;
		height: 46px;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Card_03_market_pc {
		position: absolute;
		width: 260px;
		height: 236px;
		left: 905px;
		top: 1114px;
		overflow: visible;
	}
	#Moderate_children_at_of_outwei {
		left: 0px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 47px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#Track_Property {
		left: 65.5px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 131px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#Icon {
		position: absolute;
		width: 90px;
		height: 90px;
		left: 85px;
		top: 0px;
		overflow: visible;
	}
	#Fill {
		position: absolute;
		width: 90px;
		height: 90px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Fill_du {
		fill: rgba(0,0,0,0);
	}
	.Fill_du {
		filter: drop-shadow(0px 100px 80px rgba(242, 184, 236, 0.071));
		position: absolute;
		overflow: visible;
		width: 330px;
		height: 330px;
		left: 0px;
		top: 0px;
	}
	#Fill_du {
		fill: rgba(0,0,0,0);
	}
	.Fill_du {
		filter: drop-shadow(0px 100px 80px rgba(242, 184, 236, 0.071));
		position: absolute;
		overflow: visible;
		width: 330px;
		height: 330px;
		left: 0px;
		top: 0px;
	}
	#Fill_du {
		fill: rgba(0,0,0,0);
	}
	.Fill_du {
		filter: drop-shadow(0px 100px 80px rgba(242, 184, 236, 0.071));
		position: absolute;
		overflow: visible;
		width: 330px;
		height: 330px;
		left: 0px;
		top: 0px;
	}
	#Fill_du {
		fill: rgba(0,0,0,0);
	}
	.Fill_du {
		filter: drop-shadow(0px 100px 80px rgba(242, 184, 236, 0.071));
		position: absolute;
		overflow: visible;
		width: 330px;
		height: 330px;
		left: 0px;
		top: 0px;
	}
	#Fill_du {
		fill: rgba(0,0,0,0);
	}
	.Fill_du {
		filter: drop-shadow(0px 100px 80px rgba(242, 184, 236, 0.071));
		position: absolute;
		overflow: visible;
		width: 330px;
		height: 330px;
		left: 0px;
		top: 0px;
	}
	#Fill_dv {
		fill: rgba(242,184,236,1);
	}
	.Fill_dv {
		filter: drop-shadow(0px 1.852px 3.148px rgba(242, 184, 236, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.444px;
		left: 0px;
		top: 0px;
	}
	#Icon_dw {
		fill: rgba(255,255,255,1);
	}
	.Icon_dw {
		overflow: visible;
		position: absolute;
		width: 21px;
		height: 25px;
		left: 35px;
		top: 33px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Card_02_market_pc {
		position: absolute;
		width: 260px;
		height: 236px;
		left: 497px;
		top: 1114px;
		overflow: visible;
	}
	#Conveying_or_northward_offendi {
		left: 0px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 47px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#Instant_Valuation {
		left: 28px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 205px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#Fill_d {
		fill: rgba(77,223,253,1);
	}
	.Fill_d {
		filter: drop-shadow(0px 1.852px 3.148px rgba(77, 223, 253, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.444px;
		left: 85px;
		top: 0px;
	}
	#Icon_d {
		fill: rgba(255,255,255,1);
	}
	.Icon_d {
		overflow: visible;
		position: absolute;
		width: 25px;
		height: 12.324px;
		left: 118px;
		top: 45.675px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_ea {
		fill: rgba(255,255,255,1);
	}
	.Icon_ea {
		overflow: visible;
		position: absolute;
		width: 8.532px;
		height: 8.305px;
		left: 128.417px;
		top: 37.918px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_eb {
		fill: rgba(255,255,255,1);
	}
	.Icon_eb {
		overflow: visible;
		position: absolute;
		width: 6.241px;
		height: 6.204px;
		left: 123.026px;
		top: 34.464px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_ec {
		fill: rgba(255,255,255,1);
	}
	.Icon_ec {
		overflow: visible;
		position: absolute;
		width: 5.365px;
		height: 5.327px;
		left: 133.91px;
		top: 33px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Card_01_market_pc {
		position: absolute;
		width: 260px;
		height: 236px;
		left: 88px;
		top: 1114px;
		overflow: visible;
	}
	#Wonder_twenty_hunted_and_put_i {
		left: 0px;
		top: 189px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 47px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#Research_Suburbs_market_pc {
		left: 51px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 159px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#Fill_bdr_market_pc {
		fill: rgba(150,114,255,1);
	}
	.Fill_bdr_market_pc {
		filter: drop-shadow(0px 1.852px 3.148px rgba(150, 114, 255, 0.016));
		position: absolute;
		overflow: visible;
		width: 99.444px;
		height: 99.444px;
		left: 85px;
		top: 0px;
	}
	#Icon__2_market_pc {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.Icon__2_market_pc {
		overflow: visible;
		position: absolute;
		width: 8.742px;
		height: 8.742px;
		left: 135.672px;
		top: 50.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Icon_1_market_pc {
		fill: rgba(0,0,0,0);
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 0 0;
		stroke-dashoffset: 0;
		stroke-miterlimit: 10;
		shape-rendering: auto;
	}
	.Icon_1_market_pc {
		overflow: visible;
		position: absolute;
		width: 22.689px;
		height: 22.689px;
		left: 118px;
		top: 33px;
		transform: matrix(1,0,0,1,0,0);
	}
	#line_design_1_grp {
		position: absolute;
		width: 210px;
		height: 11px;
		left: 305px;
		top: 1155px;
		overflow: visible;
	}
	#line_centre_2_market_pc {
		opacity: 0.2;
		fill: rgba(0,0,0,0);
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5 10;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.line_centre_2_market_pc {
		overflow: visible;
		position: absolute;
		width: 191.5px;
		height: 3px;
		left: 9.237px;
		top: 5.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#line_bal2_market_pc {
		fill: rgba(77,223,253,1);
	}
	.line_bal2_market_pc {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 199px;
		top: 0px;
	}
	#line_bal1_market_pc {
		fill: rgba(150,114,255,1);
	}
	.line_bal1_market_pc {
		position: absolute;
		overflow: visible;
		width: 11px;
		height: 11px;
		left: 0px;
		top: 0px;
	}
	#line_design_2_grp {
		position: absolute;
		width: 210px;
		height: 11px;
		left: 722px;
		top: 1155px;
		overflow: visible;
	}
	#centre_line_2_market_pc {
		opacity: 0.2;
		fill: rgba(0,0,0,0);
		stroke: rgba(112,112,112,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-dasharray: 5 10;
		stroke-dashoffset: 0;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.centre_line_2_market_pc {
		overflow: visible;
		position: absolute;
		width: 191.5px;
		height: 1px;
		transform: translate(0.176px, 0px) matrix(1,0,0,1,9.5,5.5) rotate(180deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#circle_1_market_pc {
		fill: rgba(77,223,253,1);
	}
	.circle_1_market_pc {
		width: 11px;
		height: 11px;
		position: absolute;
		overflow: visible;
		transform: translate(0.176px, 0px) matrix(1,0,0,1,-0.1764,0) rotate(180deg);
		transform-origin: center;
	}
	#circle_2_market_pc {
		fill: rgba(150,114,255,1);
	}
	.circle_2_market_pc {
		width: 11px;
		height: 11px;
		position: absolute;
		overflow: visible;
		transform: translate(0.176px, 0px) matrix(1,0,0,1,198.8236,0) rotate(180deg);
		transform-origin: center;
	}
	#bdr_view_all_product_market_pc {
		fill: rgba(248,90,71,1);
	}
	.bdr_view_all_product_market_pc {
		filter: drop-shadow(0px 1.852px 3.148px rgba(248, 90, 71, 0.016));
		position: absolute;
		overflow: visible;
		width: 209.444px;
		height: 74.444px;
		left: 945px;
		top: 1432.5px;
	}
	#View_All_Products_market_pc {
		left: 980px;
		top: 1454.5px;
		position: absolute;
		overflow: visible;
		width: 131px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#Colonel_gravity_get_thought_fa {
		left: 103px;
		top: 1490.5px;
		position: absolute;
		overflow: visible;
		width: 471px;
		height: 21px;
		line-height: 24px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#Best_Deals_market_pc {
		left: 103px;
		top: 1432.5px;
		position: absolute;
		overflow: visible;
		width: 141px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#phone_grp_market_pc {
		position: absolute;
		width: 200px;
		height: 244px;
		left: 103px;
		top: 1550px;
		overflow: visible;
	}
	#anh-nhat-uCqMa_s-JDg-unsplash {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 244px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#shade_white_market_pc {
		filter: blur(30px);
		fill: rgba(255,255,255,1);
	}
	.shade_white_market_pc {
		position: absolute;
		overflow: visible;
		width: 269px;
		height: 222px;
		left: 99px;
		top: 190px;
	}
	#bdr_best_seller_market_pc {
		fill: rgba(255,255,255,1);
	}
	.bdr_best_seller_market_pc {
		position: absolute;
		overflow: visible;
		width: 85px;
		height: 33px;
		left: 101px;
		top: 194px;
	}
	#Best_Seller_market_pc {
		left: 110px;
		top: 200.5px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#clothes_grp_market_pc {
		position: absolute;
		width: 200px;
		height: 244px;
		left: 384px;
		top: 1550px;
		overflow: visible;
	}
	#mediamodifier-kJXGTOY1wLQ-unsp {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 244px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#shade_clothes_market_pc {
		filter: blur(50px);
		fill: rgba(0,0,0,1);
	}
	.shade_clothes_market_pc {
		position: absolute;
		overflow: visible;
		width: 389px;
		height: 342px;
		left: 99px;
		top: 190px;
	}
	#clothes_bdr_top_market_pc {
		fill: rgba(255,255,255,1);
	}
	.clothes_bdr_top_market_pc {
		position: absolute;
		overflow: visible;
		width: 85px;
		height: 33px;
		left: 101px;
		top: 194px;
	}
	#Top_Brands_shirt_market_pc {
		left: 107px;
		top: 200.5px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#shoes_grp_market_pc {
		position: absolute;
		width: 200px;
		height: 244px;
		left: 665px;
		top: 1550px;
		overflow: visible;
	}
	#shoes_img_market_pc {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 244px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#shade_shoes_market_pc {
		filter: blur(30px);
		fill: rgba(255,219,74,1);
	}
	.shade_shoes_market_pc {
		position: absolute;
		overflow: visible;
		width: 269px;
		height: 222px;
		left: 99px;
		top: 190px;
	}
	#top_brand_bdr_market_pc {
		fill: rgba(255,255,255,1);
	}
	.top_brand_bdr_market_pc {
		position: absolute;
		overflow: visible;
		width: 85px;
		height: 33px;
		left: 101px;
		top: 194px;
	}
	#Top_Brands_lbl_market_pc {
		left: 107px;
		top: 200.5px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#pips_grp_market_pc {
		position: absolute;
		width: 200px;
		height: 244px;
		left: 945px;
		top: 1550px;
		overflow: visible;
	}
	#img_pipe_market_pc {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 200px;
		height: 244px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#shade_blue_market_pc {
		filter: blur(30px);
		fill: rgba(79,183,222,1);
	}
	.shade_blue_market_pc {
		position: absolute;
		overflow: visible;
		width: 269px;
		height: 222px;
		left: 99px;
		top: 190px;
	}
	#bdr_best_quality_market_pc {
		fill: rgba(255,255,255,1);
	}
	.bdr_best_quality_market_pc {
		position: absolute;
		overflow: visible;
		width: 85px;
		height: 33px;
		left: 101px;
		top: 194px;
	}
	#Best_Quality_lbl_market_pc {
		left: 104px;
		top: 200.5px;
		position: absolute;
		overflow: visible;
		width: 80px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#best_deals_category_market_pc {
		position: absolute;
		width: 1041.474px;
		height: 320.81px;
		left: 103.149px;
		top: 1866.5px;
		overflow: visible;
	}
	#Image_01_market_pc {
		position: absolute;
		width: 329.474px;
		height: 267.141px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#bdr_add_cart_market_pc {
		fill: rgba(5,5,5,1);
	}
	.bdr_add_cart_market_pc {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 35px;
		left: 291.851px;
		top: 281.5px;
	}
	#add-to-cart_1_market_pc {
		position: absolute;
		width: 27px;
		height: 27px;
		left: 295.851px;
		top: 285.5px;
		overflow: visible;
	}
	#air_shoes_market_pc {
		left: 13.378px;
		top: 280.81px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#n_00_market_pc {
		left: 13.378px;
		top: 300.81px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Image_02_market_pc {
		position: absolute;
		width: 329.474px;
		height: 267.141px;
		left: 356px;
		top: 0px;
		overflow: visible;
	}
	#bdr_cart_market_pc {
		fill: rgba(5,5,5,1);
	}
	.bdr_cart_market_pc {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 35px;
		left: 647.851px;
		top: 281.5px;
	}
	#add-to-cart_img_market_pc {
		position: absolute;
		width: 27px;
		height: 27px;
		left: 651.851px;
		top: 285.5px;
		overflow: visible;
	}
	#Featured_name_market_pc {
		left: 369.378px;
		top: 280.81px;
		position: absolute;
		overflow: visible;
		width: 101px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Featured_1_market_pc {
		left: 369.378px;
		top: 300.81px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Image_03_add-to-cart_1_market_ {
		position: absolute;
		width: 329.474px;
		height: 267.141px;
		left: 712px;
		top: 0px;
		overflow: visible;
	}
	#add-to-cart_1_bdr__market_pc {
		fill: rgba(5,5,5,1);
	}
	.add-to-cart_1_bdr__market_pc {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 35px;
		left: 1003.851px;
		top: 281.5px;
	}
	#add-to-cart_1_market_pc_fk {
		position: absolute;
		width: 27px;
		height: 27px;
		left: 1007.851px;
		top: 285.5px;
		overflow: visible;
	}
	#Featured_456_market_pc {
		left: 725.378px;
		top: 280.81px;
		position: absolute;
		overflow: visible;
		width: 37px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#Featured_123_market_pc {
		left: 725.378px;
		top: 300.81px;
		position: absolute;
		overflow: visible;
		width: 33px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	.button_market {
  --bg: #F85A47;
  --hover-bg: #ffffff;
  --hover-text: #000000;
  color: #fff;
  border: 1px solid var(--bg);
  border-radius: 4px;
  padding: 0.8em 2em;
  background: var(--bg);
  transition: 0.2s;
  position: absolute;
    left: 79%;
    top: 64%;
    width: 163px;
    height: 58.292px;
}

.button_market:hover {
  color: var(--hover-text);
  transform: translate(-0.25rem,-0.25rem);
  background: var(--hover-bg);
  box-shadow: 0.25rem 0.25rem var(--bg);
}

.button_market:active {
  transform: translate(0);
  box-shadow: none;
}.button1 {
  --bg: #F85A47;
  --hover-bg: #ffffff;
  --hover-text: #000000;
  color: #fff;
  border: 1px solid var(--bg);
  border-radius: 4px;
  padding: 0.8em 2em;
  background: var(--bg);
  transition: 0.2s;
  position: absolute;
    left: 79%;
    top: 45.5%;
    width: 163px;
    height: 58.292px;
}

.button1:hover {
  color: var(--hover-text);
  transform: translate(-0.25rem,-0.25rem);
  background: var(--hover-bg);
  box-shadow: 0.25rem 0.25rem var(--bg);
}

.button1:active {
  transform: translate(0);
  box-shadow: none;
}
