.mediaViewInfo {
    --web-view-name: broadcast pc;
    --web-view-id: broadcast_pc;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: broadcast_pc;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
body::-webkit-scrollbar{
    display: none;
}
#broadcast_pc {
    position: absolute;
    width: 1237px;
    height: 2694px;
    top:60px;
    left:120px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: broadcast pc;
    --web-view-id: broadcast_pc;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#add_bdr_event_page_pc {
    position: absolute;
    width: 1137px;
    height: 118px;
    left: 51px;
    top: 542px;
    overflow: visible;
}
#bdr_ad_area_events_event_page_ {
    fill: rgba(225,64,64,0.89);
}
.bdr_ad_area_events_event_page_ {
    overflow: visible;
    position: absolute;
    width: 1137px;
    height: 116px;
    left: 0px;
    top: 2px;
    transform: matrix(1,0,0,1,0,0);
}
#AD_AREA_event_page_pc {
    left: 385px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 364px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 84px;
    color: rgba(255,255,255,1);
}
#find_the_ulti_lblevent_page_pc {
    left: 75px;
    top: 1274px;
    position: absolute;
    overflow: visible;
    width: 471px;
    height: 21px;
    line-height: 24px;
    margin-top: -4px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#recommeneded_events_lbl_event_ {
    left: 75px;
    top: 1224px;
    position: absolute;
    overflow: visible;
    width: 301px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(0,0,0,1);
}
#card_1_recomm_lbl_bdr_event_pa {
    fill: rgba(255,255,255,1);
}
.card_1_recomm_lbl_bdr_event_pa {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 73px;
    top: 1641px;
}
#card_1_recommened_lbl_event_pa {
    left: 101px;
    top: 1647px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_bdr_event_pa {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 59px;
    top: 1341px;
    overflow: visible;
}
#recommended_events_card_1_img_ {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 72px;
    top: 1352px;
    overflow: visible;
}
#top_event_category_lbl_categor {
    left: 75px;
    top: 1719px;
    position: absolute;
    overflow: visible;
    width: 287px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(0,0,0,1);
}
#product_zone_bdr_category_even_bd {
    fill: url(#product_zone_bdr_category_even_bd);
}
.product_zone_bdr_category_even_bd {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 208px;
    height: 253px;
    left: 59px;
    top: 1778px;
}
#music_zone_bdr_category_event_ {
    fill: rgba(142,183,255,1);
}
.music_zone_bdr_category_event_ {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 208px;
    height: 253px;
    left: 289px;
    top: 1778px;
}
#workshop_bdr_category_event_pa_bg {
    fill: url(#workshop_bdr_category_event_pa_bg);
}
.workshop_bdr_category_event_pa_bg {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 208px;
    height: 253px;
    left: 519px;
    top: 1778px;
}
#upskill_bdr_category_event_pag_bi {
    fill: url(#upskill_bdr_category_event_pag_bi);
}
.upskill_bdr_category_event_pag_bi {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 208px;
    height: 253px;
    left: 749px;
    top: 1778px;
}
#other_bdr_category_event_page_ {
    fill: rgba(255,146,241,1);
}
.other_bdr_category_event_page_ {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 208px;
    height: 253px;
    left: 979px;
    top: 1778px;
}
#product_zone_lbl_category_even {
    left: 74px;
    top: 1790px;
    position: absolute;
    overflow: visible;
    width: 169px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: rgba(255,255,255,1);
}
#n_0events_lbl_category_event_p {
    left: 74px;
    top: 1866px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#music_zone_lbl_category_event_ {
    left: 304px;
    top: 1790px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: rgba(255,255,255,1);
}
#n_0events_lbl_category_event_p_bn {
    left: 304px;
    top: 1866px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#workshop_zone_lbl_category_eve {
    left: 534px;
    top: 1790px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: rgba(255,255,255,1);
}
#n_0events_lbl_category_event_p_bp {
    left: 534px;
    top: 1866px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#upskill_lbl_category_event_pag {
    left: 763px;
    top: 1790px;
    position: absolute;
    overflow: visible;
    width: 159px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: rgba(255,255,255,1);
}
#n_0_events_upskill_category_ev {
    left: 765px;
    top: 1866px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#other_lbl_category_event_page_ {
    left: 1005px;
    top: 1808px;
    position: absolute;
    overflow: visible;
    width: 80px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: rgba(255,255,255,1);
}
#n_0_event_lbl_other_category_e {
    left: 1005px;
    top: 1859px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#product_img_category_event_pag {
    position: absolute;
    width: 130px;
    height: 130px;
    left: 94px;
    top: 1893px;
    overflow: visible;
}
#workshop_img_category_event_pa {
    position: absolute;
    width: 196px;
    height: 122.5px;
    left: 521px;
    top: 1900.5px;
    overflow: visible;
}
#upskill_img_category_event_pag {
    position: absolute;
    width: 152px;
    height: 152px;
    left: 773px;
    top: 1886px;
    overflow: visible;
}
#other_img_category_event_page_ {
    position: absolute;
    width: 139px;
    height: 139px;
    left: 322px;
    top: 1886px;
    overflow: visible;
}
#music_zone_img_category_event_ {
    position: absolute;
    width: 200px;
    height: 133.333px;
    left: 981px;
    top: 1903.333px;
    overflow: visible;
}
#streming_live_bdr_event_page_p {
    fill: rgba(255,192,168,1);
}
.streming_live_bdr_event_page_p {
    position: absolute;
    overflow: visible;
    width: 1282px;
    height: 491px;
    left: 0px;
    top: 703px;
}
#streaming_Live_now_lbl_event_p {
    left: 75px;
    top: 724px;
    position: absolute;
    overflow: visible;
    width: 247px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(0,0,0,1);
}
#enroll_now_bdr_event_page_pc {
    fill: rgba(255,255,255,1);
}
.enroll_now_bdr_event_page_pc {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 78px;
    left: 73px;
    top: 1088px;
}
#BiziBees_PVT_LTD_steraming_now {
    left: 101px;
    top: 1098px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Enroll_Now__lbl_streaming_live {
    left: 78px;
    top: 1120px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#streaming_live_now_bdr_event_p {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 59px;
    top: 788px;
    overflow: visible;
}
#streaming_live_now_img_card_1e {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 71px;
    top: 797px;
    overflow: visible;
}
#free_event_name_event_page_pc {
    fill: rgba(255,255,255,1);
}
.free_event_name_event_page_pc {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 73px;
    top: 2496px;
}
#BiziBees_PVT_LTD_free_events_e {
    left: 101px;
    top: 2502px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#free_events_card_1_bdr_event_p {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 59px;
    top: 2196px;
    overflow: visible;
}
#free_events_card_1_event_page_ {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 72px;
    top: 2207px;
    overflow: visible;
}
#Colonel_gravity_get_thought_fa {
    left: 75px;
    top: 2129px;
    position: absolute;
    overflow: visible;
    width: 363px;
    white-space: nowrap;
    line-height: 24px;
    margin-top: -4px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#Free_Events_lbl_event_page_pc {
    left: 75.5px;
    top: 2079px;
    position: absolute;
    overflow: visible;
    width: 155px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(0,0,0,1);
}
#event_top_banner_event_page_pc {
    position: absolute;
    width: 1332.512px;
    height: 627.306px;
    left: -48.895px;
    top: -31.312px;
    overflow: visible;
}
#Path_3 {
    fill: rgba(248,90,71,1);
}
.Path_3 {
    overflow: visible;
    position: absolute;
    width: 316.087px;
    height: 175px;
    transform: translate(48.895px, 31.312px) matrix(1,0,0,1,956.9565,58.5) rotate(50deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#Path_2 {
    fill: rgba(248,90,71,1);
}
.Path_2 {
    overflow: visible;
    position: absolute;
    width: 316.087px;
    height: 175px;
    transform: translate(48.895px, 31.312px) matrix(1,0,0,1,-38.3206,331.182) rotate(230deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#img_banner_1_event_pagge_pc {
    position: absolute;
    width: 808px;
    height: 356px;
    left: 137.895px;
    top: 136.312px;
    overflow: visible;
}
#img_banner_3_event_page_pc {
    position: absolute;
    width: 232px;
    height: 111px;
    left: 966.895px;
    top: 136.312px;
    overflow: visible;
}
#img_banner_3_event_page_pc_ch {
    position: absolute;
    width: 232px;
    height: 110px;
    left: 966.895px;
    top: 259.312px;
    overflow: visible;
}
#all_img_banner_2_event_page_pc {
    position: absolute;
    width: 232px;
    height: 111px;
    left: 966.895px;
    top: 381.312px;
    overflow: visible;
}
#Join_us_today_event_page_pc {
    left: 814.895px;
    top: 141.312px;
    position: absolute;
    overflow: visible;
    width: 91px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#Today_at_600pm_event_page_pc {
    left: 816.895px;
    top: 161.312px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#Sat_6pm_event_4_event_page_pc {
    left: 1138.895px;
    top: 136.312px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#bell_4_bdr_event_page_pc {
    fill: rgba(255,255,255,1);
}
.bell_4_bdr_event_page_pc {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 1167.895px;
    top: 220.312px;
}
#bell_4_img_event_page_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 1169.895px;
    top: 222.312px;
    overflow: visible;
}
#cal_bdr_4_event_page_pc {
    fill: rgba(255,255,255,1);
}
.cal_bdr_4_event_page_pc {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 1140.895px;
    top: 220.312px;
}
#calendar_4_img_event_page_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 1142.895px;
    top: 222.312px;
    overflow: visible;
}
#Sat_6pm_time_3_event_page_pc {
    left: 1138.895px;
    top: 260.312px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#bell_bdr_3_event_page_pc {
    fill: rgba(255,255,255,1);
}
.bell_bdr_3_event_page_pc {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 1167.895px;
    top: 344.312px;
}
#bell_img_3_event_page_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 1169.895px;
    top: 346.312px;
    overflow: visible;
}
#calender_bdr_3_event_page_pc {
    fill: rgba(255,255,255,1);
}
.calender_bdr_3_event_page_pc {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 1140.895px;
    top: 344.312px;
}
#calendar_img_3_event_page_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 1142.895px;
    top: 346.312px;
    overflow: visible;
}
#Sat_6pm_time_2_event_page_pc {
    left: 1138.895px;
    top: 381.312px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#bdr_bell_2_event_page_pc {
    fill: rgba(255,255,255,1);
}
.bdr_bell_2_event_page_pc {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 1167.895px;
    top: 465.312px;
}
#bell_2_img_event_page_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 1169.895px;
    top: 467.312px;
    overflow: visible;
}
#calender_bdr_2_event_page_pc {
    fill: rgba(255,255,255,1);
}
.calender_bdr_2_event_page_pc {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 1140.895px;
    top: 465.312px;
}
#calendar_img_2_event_page_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 1142.895px;
    top: 467.312px;
    overflow: visible;
}
#BiziBees_PVT_LTD_1_event_page_ {
    left: 161.895px;
    top: 141.312px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#BiziBees_PVT_LTD_4_event_page_ {
    left: 982.895px;
    top: 136.312px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#BiziBees_PVT_LTD_3_event_page_ {
    left: 982.895px;
    top: 260.312px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#BiziBees_PVT_LTD_2_event_page_ {
    left: 982.895px;
    top: 381.312px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#bell_bdr_1_event_page_pc {
    fill: rgba(255,255,255,1);
}
.bell_bdr_1_event_page_pc {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 904.895px;
    top: 430.312px;
}
#bell_img_1_event_page_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 906.895px;
    top: 432.312px;
    overflow: visible;
}
#calender_bdr_1_event_page_pc {
    fill: rgba(255,255,255,1);
}
.calender_bdr_1_event_page_pc {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 873.895px;
    top: 430.312px;
}
#calendar_img_1_event_page_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 875.895px;
    top: 432.312px;
    overflow: visible;
}
#enroll_1_bdr_event_page_pc_c {
    fill: url(#enroll_1_bdr_event_page_pc_c);
}
.enroll_1_bdr_event_page_pc_c {
    position: absolute;
    overflow: visible;
    width: 79px;
    height: 24px;
    left: 849.895px;
    top: 458.312px;
}
#Enroll_lbl_1_event_page_pc {
    left: 869.895px;
    top: 460.312px;
    position: absolute;
    overflow: visible;
    width: 40px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#enroll_now_bdr_event_page_pc_db {
    fill: rgba(255,255,255,1);
}
.enroll_now_bdr_event_page_pc_db {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 78px;
    left: 303px;
    top: 1088px;
}
#BiziBees_PVT_LTD_steraming_now_dc {
    left: 331px;
    top: 1098px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Enroll_Now__lbl_streaming_live_dd {
    left: 308px;
    top: 1120px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#streaming_live_now_bdr_event_p_de {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 289px;
    top: 788px;
    overflow: visible;
}
#streaming_live_now_img_card_1e_df {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 301px;
    top: 797px;
    overflow: visible;
}
#enroll_now_bdr_event_page_pc_dg {
    fill: rgba(255,255,255,1);
}
.enroll_now_bdr_event_page_pc_dg {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 78px;
    left: 533px;
    top: 1088px;
}
#BiziBees_PVT_LTD_steraming_now_dh {
    left: 561px;
    top: 1098px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Enroll_Now__lbl_streaming_live_di {
    left: 538px;
    top: 1120px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#streaming_live_now_bdr_event_p_dj {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 519px;
    top: 788px;
    overflow: visible;
}
#streaming_live_now_img_card_1e_dk {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 531px;
    top: 797px;
    overflow: visible;
}
#enroll_now_bdr_event_page_pc_dl {
    fill: rgba(255,255,255,1);
}
.enroll_now_bdr_event_page_pc_dl {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 78px;
    left: 763px;
    top: 1088px;
}
#BiziBees_PVT_LTD_steraming_now_dm {
    left: 791px;
    top: 1098px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Enroll_Now__lbl_streaming_live_dn {
    left: 768px;
    top: 1120px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#streaming_live_now_bdr_event_p_do {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 749px;
    top: 788px;
    overflow: visible;
}
#streaming_live_now_img_card_1e_dp {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 761px;
    top: 797px;
    overflow: visible;
}
#enroll_now_bdr_event_page_pc_dq {
    fill: rgba(255,255,255,1);
}
.enroll_now_bdr_event_page_pc_dq {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 78px;
    left: 993px;
    top: 1088px;
}
#BiziBees_PVT_LTD_steraming_now_dr {
    left: 1021px;
    top: 1098px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Enroll_Now__lbl_streaming_live_ds {
    left: 998px;
    top: 1120px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#streaming_live_now_bdr_event_p_dt {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 979px;
    top: 788px;
    overflow: visible;
}
#streaming_live_now_img_card_1e_du {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 991px;
    top: 797px;
    overflow: visible;
}
#card_1_recomm_lbl_bdr_event_pa_dv {
    fill: rgba(255,255,255,1);
}
.card_1_recomm_lbl_bdr_event_pa_dv {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 303px;
    top: 1641px;
}
#card_1_recommened_lbl_event_pa_dw {
    left: 331px;
    top: 1647px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_bdr_event_pa_dx {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 289px;
    top: 1341px;
    overflow: visible;
}
#recommended_events_card_1_img__dy {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 302px;
    top: 1352px;
    overflow: visible;
}
#card_1_recomm_lbl_bdr_event_pa_dz {
    fill: rgba(255,255,255,1);
}
.card_1_recomm_lbl_bdr_event_pa_dz {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 533px;
    top: 1641px;
}
#card_1_recommened_lbl_event_pa_d {
    left: 561px;
    top: 1647px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_bdr_event_pa_d {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 519px;
    top: 1341px;
    overflow: visible;
}
#recommended_events_card_1_img__d {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 532px;
    top: 1352px;
    overflow: visible;
}
#card_1_recomm_lbl_bdr_event_pa_d {
    fill: rgba(255,255,255,1);
}
.card_1_recomm_lbl_bdr_event_pa_d {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 763px;
    top: 1641px;
}
#card_1_recommened_lbl_event_pa_ea {
    left: 791px;
    top: 1647px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_bdr_event_pa_ea {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 749px;
    top: 1341px;
    overflow: visible;
}
#recommended_events_card_1_img__ea {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 762px;
    top: 1352px;
    overflow: visible;
}
#card_1_recomm_lbl_bdr_event_pa_ea {
    fill: rgba(255,255,255,1);
}
.card_1_recomm_lbl_bdr_event_pa_ea {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 993px;
    top: 1641px;
}
#card_1_recommened_lbl_event_pa_eb {
    left: 1021px;
    top: 1647px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_bdr_event_pa_eb {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 979px;
    top: 1341px;
    overflow: visible;
}
#recommended_events_card_1_img__eb {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 992px;
    top: 1352px;
    overflow: visible;
}
#free_event_name_event_page_pc_eb {
    fill: rgba(255,255,255,1);
}
.free_event_name_event_page_pc_eb {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 303px;
    top: 2496px;
}
#BiziBees_PVT_LTD_free_events_e_ec {
    left: 331px;
    top: 2502px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#free_events_card_1_bdr_event_p_ed {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 289px;
    top: 2196px;
    overflow: visible;
}
#free_events_card_1_event_page__ee {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 302px;
    top: 2207px;
    overflow: visible;
}
#free_event_name_event_page_pc_ef {
    fill: rgba(255,255,255,1);
}
.free_event_name_event_page_pc_ef {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 533px;
    top: 2496px;
}
#BiziBees_PVT_LTD_free_events_e_eg {
    left: 561px;
    top: 2502px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#free_events_card_1_bdr_event_p_eh {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 519px;
    top: 2196px;
    overflow: visible;
}
#free_events_card_1_event_page__ei {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 532px;
    top: 2207px;
    overflow: visible;
}
#free_event_name_event_page_pc_ej {
    fill: rgba(255,255,255,1);
}
.free_event_name_event_page_pc_ej {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 763px;
    top: 2496px;
}
#BiziBees_PVT_LTD_free_events_e_ek {
    left: 791px;
    top: 2502px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#free_events_card_1_bdr_event_p_el {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 749px;
    top: 2196px;
    overflow: visible;
}
#free_events_card_1_event_page__em {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 762px;
    top: 2207px;
    overflow: visible;
}
#Group_119 {
    position: absolute;
    width: 200px;
    height: 353px;
    left: 979px;
    top: 2196px;
    overflow: visible;
}
#free_event_name_event_page_pc_eo {
    fill: rgba(255,255,255,1);
}
.free_event_name_event_page_pc_eo {
    filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 190px;
    height: 71px;
    left: 14px;
    top: 300px;
}
#BiziBees_PVT_LTD_free_events_e_ep {
    left: 42px;
    top: 306px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#free_events_card_1_bdr_event_p_eq {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 200px;
    height: 300px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#free_events_card_1_event_page__er {
    position: absolute;
    width: 172px;
    height: 277px;
    left: 13px;
    top: 11px;
    overflow: visible;
}
#card_1_recommened_lbl_event_pa_es {
    left: 78px;
    top: 1669px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_et {
    left: 309px;
    top: 1669px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_eu {
    left: 538px;
    top: 1669px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_ev {
    left: 769px;
    top: 1669px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_ew {
    left: 998px;
    top: 1669px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_ex {
    left: 79px;
    top: 2522px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_ey {
    left: 308px;
    top: 2522px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_ez {
    left: 538px;
    top: 2522px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_e {
    left: 768px;
    top: 2522px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#card_1_recommened_lbl_event_pa_fa {
    left: 998px;
    top: 2522px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
.button {
--bg: #F85A47;
--hover-bg: #ffffff;
--hover-text: #000000;
color: #fff;
border: 1px solid var(--bg);
border-radius: 4px;
padding: 0.8em 2em;
background: var(--bg);
transition: 0.2s;
position: absolute;
left: 79%;
top: 77.2%;
width: 163px;
height: 58.292px;
}

.button:hover {
color: var(--hover-text);
transform: translate(-0.25rem,-0.25rem);
background: var(--hover-bg);
box-shadow: 0.25rem 0.25rem var(--bg);
}

.button:active {
transform: translate(0);
box-shadow: none;
}.button1 {
--bg: #F85A47;
--hover-bg: #ffffff;
--hover-text: #000000;
color: #fff;
border: 1px solid var(--bg);
border-radius: 4px;
padding: 0.8em 2em;
background: var(--bg);
transition: 0.2s;
position: absolute;
left: 79%;
top: 45.5%;
width: 163px;
height: 58.292px;
}

.button1:hover {
color: var(--hover-text);
transform: translate(-0.25rem,-0.25rem);
background: var(--hover-bg);
box-shadow: 0.25rem 0.25rem var(--bg);
}

.button1:active {
transform: translate(0);
box-shadow: none;
}