
	.mediaViewInfo {
		--web-view-name: PlantPlateDelights Mob;
		--web-view-id: PlantPlateDelights_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: PlantPlateDelights_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#PlantPlateDelights_Mob {
		position: absolute;
		width: 390px;
		height: 3455px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: PlantPlateDelights Mob;
		--web-view-id: PlantPlateDelights_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#PPD_MoB_26Group_15098 {
		position: absolute;
		width: 390px;
		height: 637px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Mask_Group_1 {
		position: absolute;
		width: 390px;
		height: 358px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Group_15092 {
		position: absolute;
		width: 127px;
		height: 35px;
		left: 24px;
		top: 602px;
		overflow: visible;
	}
	#PPD_MoB_26Rectangle_3 {
		fill: rgba(103,123,200,1);
	}
	.PPD_MoB_26Rectangle_3 {
		position: absolute;
		overflow: visible;
		width: 127px;
		height: 35px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Order_Now {
		left: 36px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 56px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 11px;
		color: rgba(255,255,255,1);
	}
	#PPD_MoB_26Have_no_time_to_prepare_food {
		left: 20px;
		top: 414px;
		position: absolute;
		overflow: visible;
		width: 283px;
		white-space: nowrap;
		line-height: 49px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 38px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Choose_one_of_our_plans_enter_ {
		opacity: 0.5;
		left: 24px;
		top: 539px;
		position: absolute;
		overflow: visible;
		width: 303px;
		white-space: nowrap;
		line-height: 21px;
		margin-top: -4px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26FOODCOURT {
		left: 20px;
		top: 358px;
		position: absolute;
		overflow: visible;
		width: 146px;
		white-space: nowrap;
		line-height: 37px;
		margin-top: -7px;
		text-align: left;
		font-family: Semplicità;
		font-style: normal;
		font-weight: bold;
		font-size: 23px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Group_15099 {
		position: absolute;
		width: 376.647px;
		height: 751.001px;
		left: 0px;
		top: 752px;
		overflow: visible;
	}
	#PPD_MoB_26Mask_Group_2 {
		position: absolute;
		width: 365.726px;
		height: 280.805px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Group_15093 {
		position: absolute;
		width: 363.647px;
		height: 433.001px;
		left: 13px;
		top: 318px;
		overflow: visible;
	}
	#PPD_MoB_26Group_4 {
		position: absolute;
		width: 151px;
		height: 42px;
		left: 0px;
		top: 391.002px;
		overflow: visible;
	}
	#PPD_MoB_26Rectangle_6 {
		fill: rgba(103,123,200,1);
	}
	.PPD_MoB_26Rectangle_6 {
		position: absolute;
		overflow: visible;
		width: 151px;
		height: 42px;
		left: 0px;
		top: -0.002px;
	}
	#PPD_MoB_26Order_Now_bi {
		left: 38px;
		top: 10.998px;
		position: absolute;
		overflow: visible;
		width: 66px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#PPD_MoB_26Group_12 {
		position: absolute;
		width: 225px;
		height: 75.048px;
		left: 2px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Line_2 {
		fill: transparent;
		stroke: rgba(103,123,200,1);
		stroke-width: 7px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Line_2 {
		overflow: visible;
		position: absolute;
		width: 171.373px;
		height: 7px;
		left: 3.09px;
		top: 75.047px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26OUR_FOOD {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 226px;
		white-space: nowrap;
		line-height: 59px;
		margin-top: -7px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 45px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Choose_one_of_our_plans_enter__bm {
		opacity: 0.5;
		left: 61.647px;
		top: 226.139px;
		position: absolute;
		overflow: visible;
		width: 303px;
		white-space: nowrap;
		line-height: 20px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Choose_one_of_our_plans_enter__bn {
		opacity: 0.5;
		left: 61.647px;
		top: 156.697px;
		position: absolute;
		overflow: visible;
		width: 258px;
		white-space: nowrap;
		line-height: 20px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Choose_one_of_our_plans_enter__bo {
		opacity: 0.5;
		left: 61.647px;
		top: 329.477px;
		position: absolute;
		overflow: visible;
		width: 258px;
		white-space: nowrap;
		line-height: 20px;
		margin-top: -3.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Group_9 {
		position: absolute;
		width: 158.411px;
		height: 37.727px;
		left: 1.502px;
		top: 118.424px;
		overflow: visible;
	}
	#PPD_MoB_26Appetizers {
		left: 57.411px;
		top: 4.375px;
		position: absolute;
		overflow: visible;
		width: 102px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Group_6 {
		position: absolute;
		width: 37.727px;
		height: 37.727px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Ellipse_2 {
		fill: rgba(103,123,200,1);
	}
	.PPD_MoB_26Ellipse_2 {
		position: absolute;
		overflow: visible;
		width: 37.727px;
		height: 37.727px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Group_5 {
		position: absolute;
		width: 14.103px;
		height: 14.103px;
		left: 11.755px;
		top: 11.756px;
		overflow: visible;
	}
	#PPD_MoB_26Line_3 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Line_3 {
		overflow: visible;
		position: absolute;
		width: 18.104px;
		height: 4px;
		left: 0px;
		top: 7.053px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26Line_4 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Line_4 {
		overflow: visible;
		position: absolute;
		width: 14.103px;
		height: 1px;
		transform: translate(0px, 0px) matrix(1,0,0,1,0,7.0517) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Group_10 {
		position: absolute;
		width: 188.145px;
		height: 37.727px;
		left: 1.502px;
		top: 187.863px;
		overflow: visible;
	}
	#PPD_MoB_26Main_Courses {
		left: 60.145px;
		top: 4.375px;
		position: absolute;
		overflow: visible;
		width: 129px;
		white-space: nowrap;
		line-height: 46px;
		margin-top: -13px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Group_7 {
		position: absolute;
		width: 37.727px;
		height: 37.727px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Ellipse_2_bz {
		fill: rgba(103,123,200,1);
	}
	.PPD_MoB_26Ellipse_2_bz {
		position: absolute;
		overflow: visible;
		width: 37.727px;
		height: 37.727px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Group_5_b {
		position: absolute;
		width: 14.103px;
		height: 14.103px;
		left: 11.755px;
		top: 11.756px;
		overflow: visible;
	}
	#PPD_MoB_26Line_3_b {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Line_3_b {
		overflow: visible;
		position: absolute;
		width: 18.104px;
		height: 4px;
		left: 0px;
		top: 7.053px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26Line_4_b {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Line_4_b {
		overflow: visible;
		position: absolute;
		width: 14.103px;
		height: 1px;
		transform: translate(0px, 0px) matrix(1,0,0,1,0,7.0517) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Group_11 {
		position: absolute;
		width: 141.145px;
		height: 37.727px;
		left: 1.502px;
		top: 279.721px;
		overflow: visible;
	}
	#PPD_MoB_26Desserts {
		left: 60.145px;
		top: 4.373px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Group_8 {
		position: absolute;
		width: 37.727px;
		height: 37.727px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Ellipse_2_b {
		fill: rgba(103,123,200,1);
	}
	.PPD_MoB_26Ellipse_2_b {
		position: absolute;
		overflow: visible;
		width: 37.727px;
		height: 37.727px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Group_5_ca {
		position: absolute;
		width: 14.103px;
		height: 14.103px;
		left: 11.755px;
		top: 11.756px;
		overflow: visible;
	}
	#PPD_MoB_26Line_3_ca {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Line_3_ca {
		overflow: visible;
		position: absolute;
		width: 18.104px;
		height: 4px;
		left: 0px;
		top: 7.051px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26Line_4_ca {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 4px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Line_4_ca {
		overflow: visible;
		position: absolute;
		width: 14.103px;
		height: 1px;
		transform: translate(0px, -0.001px) matrix(1,0,0,1,0,7.0517) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Group_15100 {
		position: absolute;
		width: 390.253px;
		height: 877.171px;
		left: 0px;
		top: 1645px;
		overflow: visible;
	}
	#PPD_MoB_26Group_15088 {
		position: absolute;
		width: 390.253px;
		height: 877.171px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Group_15094 {
		position: absolute;
		width: 390px;
		height: 171.516px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Path_7778 {
		fill: rgba(122,147,243,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Path_7778 {
		overflow: visible;
		position: absolute;
		width: 388.588px;
		height: 171.516px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26Mask_Group_7 {
		opacity: 0.1;
		position: absolute;
		width: 388.588px;
		height: 171.516px;
		left: 1.412px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Group_13 {
		position: absolute;
		width: 94.265px;
		height: 43.509px;
		left: 147.705px;
		top: 13.002px;
		overflow: visible;
	}
	#PPD_MoB_26Line_2_cg {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 7px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26Line_2_cg {
		overflow: visible;
		position: absolute;
		width: 100.939px;
		height: 7px;
		left: 0.326px;
		top: 43.508px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26PLANS {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Scroll_Group_87 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390.253px;
		height: 784.913px;
		left: 0px;
		top: 92.258px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#PPD_MoB_26Group_143 {
		position: absolute;
		width: 344.069px;
		height: 267.242px;
		left: 22.965px;
		top: 27px;
		overflow: visible;
	}
	#PPD_MoB_26Group_15095 {
		position: absolute;
		width: 344.069px;
		height: 267.242px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26Rectangle_8 {
		fill: rgba(255,255,255,1);
	}
	.PPD_MoB_26Rectangle_8 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 362.069px;
		height: 285.242px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Mask_Group_3 {
		position: absolute;
		width: 344.069px;
		height: 267.242px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26victoria-shes-4MEL9XS-3JQ-unsp {
		opacity: 0.2;
		fill: rgba(0,0,0,1);
	}
	.PPD_MoB_26victoria-shes-4MEL9XS-3JQ-unsp {
		position: absolute;
		overflow: visible;
		width: 344.069px;
		height: 267.242px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Order_1 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		left: 14.874px;
		top: 18.775px;
		position: absolute;
		overflow: visible;
		width: 64px;
		white-space: nowrap;
		line-height: 31px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Hobo Std;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#PPD_MoB_26Group_15097 {
		position: absolute;
		width: 390.252px;
		height: 974.211px;
		left: 0px;
		top: 2604.172px;
		overflow: visible;
	}
	#PPD_MoB_26VISIT_US_ {
		left: 127px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 137px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#PPD_MoB_26Text_Background {
		fill: rgba(255,255,255,1);
	}
	.PPD_MoB_26Text_Background {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 408.252px;
		height: 265.957px;
		left: 0px;
		top: 97.254px;
	}
	#PPD_MoB_26Group_15096 {
		position: absolute;
		width: 390.252px;
		height: 629px;
		left: 0px;
		top: 345.211px;
		overflow: visible;
	}
	#PPD_MoB_26sonny-mauricio-yhc4pSbl01A-uns {
		position: absolute;
		width: 390px;
		height: 629px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26sonny-mauricio-yhc4pSbl01A-uns_cu {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.PPD_MoB_26sonny-mauricio-yhc4pSbl01A-uns_cu {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 629px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26Border {
		fill: rgba(204,204,204,1);
	}
	.PPD_MoB_26Border {
		overflow: visible;
		position: absolute;
		width: 390.252px;
		height: 2.924px;
		left: 0px;
		top: 419.477px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26n_234567890_mobile_number_shad {
		left: 57px;
		top: 44px;
		position: absolute;
		overflow: visible;
		width: 71px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#PPD_MoB_26affanghanioperatelive_email_sh {
		left: 57px;
		top: 90px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 17px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#PPD_MoB_26address_med_shadow_space_websi {
		left: 57px;
		top: 140px;
		position: absolute;
		overflow: visible;
		width: 325px;
		height: 51px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}
	#PPD_MoB_26location_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 22px;
		top: 142px;
		overflow: visible;
	}
	#PPD_MoB_26email_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 22px;
		top: 89px;
		overflow: visible;
	}
	#PPD_MoB_26phone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 22px;
		top: 35px;
		overflow: visible;
	}
	#PPD_MoB_26Follow_Us {
		left: 149.126px;
		top: 266.43px;
		position: absolute;
		overflow: visible;
		width: 92px;
		white-space: nowrap;
		line-height: 25px;
		margin-top: -2.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#PPD_MoB_26socials_grp_shadow_space_websi {
		position: absolute;
		width: 189px;
		height: 48px;
		left: 100.5px;
		top: 315px;
		overflow: visible;
	}
	#PPD_MoB_26linked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 141px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26med_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PPD_MoB_26med_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26linked_in_shadow_space_website {
		position: absolute;
		width: 23.525px;
		height: 21.386px;
		left: 12.673px;
		top: 13.119px;
		overflow: visible;
	}
	#PPD_MoB_26n_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.PPD_MoB_26n_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.547px;
		height: 14.668px;
		left: 7.978px;
		top: 6.717px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26linked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.PPD_MoB_26linked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.585px;
		height: 14.529px;
		left: 0.594px;
		top: 6.857px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26top_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.PPD_MoB_26top_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.566px;
		height: 5.041px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PPD_MoB_26Insta_grp_shadow_space_website {
		position: absolute;
		width: 49px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.PPD_MoB_26shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 58px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26instagramshadow_space_website_ {
		position: absolute;
		width: 23.55px;
		height: 23.551px;
		left: 13.07px;
		top: 12.516px;
		overflow: visible;
	}
	#PPD_MoB_26whatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#PPD_MoB_26med_shadow_space_website_7_de {
		fill: rgba(255,255,255,1);
	}
	.PPD_MoB_26med_shadow_space_website_7_de {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#PPD_MoB_26whatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 24.154px;
		height: 24.154px;
		left: 11.863px;
		top: 11.91px;
		overflow: visible;
	}
	#PPD_MoB_26n_Copyright_Operate_Live_OPC_P {
		left: 8px;
		top: 444px;
		position: absolute;
		overflow: visible;
		width: 383px;
		height: 36px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
	}