	.mediaViewInfo {
		--web-view-name: add event;
		--web-view-id: add_event;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: add_event;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#add_calander_pop {
		position: absolute;
		width: 321px;
		height: 774px;
        top: 55%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 15px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: add event;
		--web-view-id: add_event;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#over_all_bg_of_add_calender_ev {
		position: absolute;
		width: 321px;
		height: 774px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#close_btn_add_calender_event {
		position: absolute;
		width: 22px;
		height: 22px;
		left: 289px;
		top: 7px;
        cursor: pointer;
        z-index: 1;
		overflow: visible;
	}
	#add_cal_lbl_add_calender_event {
		/* left: 97px; */
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		height: 30px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#add_btn_grp_add_event_details {
		position: absolute;
		width: 120px;
		height: 40px;
		left: 101px;
		top: 710px;
		overflow: visible;
		cursor: pointer;
	}
	#add_btn_bdr_add_calender_event {
		fill: rgba(173,245,206,1);
	}
	.add_btn_bdr_add_calender_event {
		position: absolute;
		overflow: visible;
		width: 120px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#Add_btn_add_calender_event {
		left: 44px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 32px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
		#add_calender_event_grp_add_eve {
        position: absolute;
        width: 250px;
        height: 63px;
        left: 37px;
        top: 65px;
        z-index: 1;
        overflow: visible;
    
	}
	#event_name_bdr_add_calender_ev {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.event_name_bdr_add_calender_ev {
        position: absolute;
        overflow: visible;
        width: 250px;
        height: 40px;
        left: 0px;
        top: 30px;
        border: 1px solid #ADF5CE;
        border-radius: 5px;
	}
	#Event_Name_lbl_add_calender_ev {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 70px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
