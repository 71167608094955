#alarm_grp_calender_pop_alrm_po {
    position: absolute;
    width: 250px;
    height: 400px;
    left: 36px;
    top: 65px;
    overflow: visible;
}
#start_at_grp_add_alrm_pop {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 75px;
    overflow: visible;
}
#start_at_bdr_add_alrm_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.start_at_bdr_add_alrm_pop {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#start_at_lbl_add_alrm_pop {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#description_grp_add_alrm_pop {

    position: absolute;
    width: 249px;
    height: 62px;
    left: 0px;
    top: 223px;
    overflow: visible;
}
#descp_bdr_add_alrm_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.descp_bdr_add_alrm_pop {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 80px;
    left: 0px;
    top: 23px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Description_lbl_add_alrm_pop {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 86px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}

#title_grp_add_alrm_pop {
    position: absolute;
    width: 250px;
    height: 62px;
    left: 0px;
    top: 149px;
    overflow: visible;
}
#title_bdr_add_alrm_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.title_bdr_add_alrm_pop {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#title_lbl_add_alrm_pop {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 33px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#snooze_grp_add_alrm_pop {
    position: absolute;
    width: 250px;
    height: 103px;
    left: 0px;
    top: 297px;
    overflow: visible;
}
#snooze_bdr_add_alrm_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.snooze_bdr_add_alrm_pop {
    position: absolute;
    overflow: visible;
    width: 169px;
    height: 40px;
    left: 0px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#snooze_timelbl_add_alrm_pop {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 112px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#button_snooze_bdr_add_alrm_pop {
    fill: rgba(166,210,170,1);
}
.button_snooze_bdr_add_alrm_pop {
    position: absolute;
    overflow: visible;
    width: 40px;
    height: 40px;
    left: 209px;
    top: 22px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#snooze_1 {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 217px;
    top: 30px;
    overflow: visible;
}