	.mediaViewInfo {
		--web-view-name: FeatheredScape;
		--web-view-id: FeatheredScape;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: FeatheredScape;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#FeatheredScape {
		position: absolute;
		width: 1366px;
		height: 3247px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: FeatheredScape;
		--web-view-id: FeatheredScape;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#n_14_web_pcGroup_14928 {
		position: absolute;
		width: 1179px;
		height: 675px;
		left: 94px;
		top: 971px;
		overflow: visible;
	}
	#n_14_web_pcmark-olsen-tjZPseTxe6k-unsplas {
		filter: drop-shadow(0px 47px 99px rgba(197, 201, 203, 1));
		position: absolute;
		width: 485px;
		height: 675px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcGroup_14949 {
		position: absolute;
		width: 586px;
		height: 335px;
		left: 593px;
		top: 171px;
		overflow: visible;
	}
	#n_14_web_pcEmbark_on_an_Avian_Adventure_E {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 587px;
		height: 175px;
		line-height: 46px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(12,54,83,1);
	}
	#n_14_web_pcRectangle_89 {
		fill: rgba(249,129,105,1);
	}
	.n_14_web_pcRectangle_89 {
		filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 342px;
		height: 157px;
		left: 0px;
		top: 265px;
	}
	#n_14_web_pcKnow_More_About_Us {
		left: 28px;
		top: 288px;
		position: absolute;
		overflow: visible;
		width: 190px;
		height: 23px;
		line-height: 71px;
		margin-top: -26.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#n_14_web_pcGroup_14927 {
		position: absolute;
		width: 1275px;
		height: 798px;
		left: 91px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcstyle_3 {
		position: absolute;
		width: 613px;
		height: 798px;
		left: 662px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcRectangle_84 {
		fill: rgba(249,129,105,1);
	}
	.n_14_web_pcRectangle_84 {
		filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 291px;
		height: 157px;
		left: 7px;
		top: 553px;
	}
	#n_14_web_pcDiscover_a_new_world_of_birdin {
		left: 7px;
		top: 232px;
		position: absolute;
		overflow: visible;
		width: 636px;
		height: 138px;
		line-height: 67px;
		margin-top: -5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: lighter;
		font-size: 57px;
		color: rgba(0,0,0,1);
	}
	#n_14_web_pcExplore_Now_ {
		left: 50px;
		top: 579px;
		position: absolute;
		overflow: visible;
		width: 119px;
		white-space: nowrap;
		line-height: 71px;
		margin-top: -26.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#n_14_web_pcScroll_Group_42 {
		mix-blend-mode: normal;
		position: absolute;
		width: 616px;
		height: 127px;
		left: 0px;
		top: 408px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n_14_web_pcScroll_Group_42::-webkit-scrollbar{
		display: none;
	}
	#n_14_web_pcintro_part {
		opacity: 0.71;
		left: 8px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 601px;
		height: 113px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#n_14_web_pczane-lee-bXbi1jJVDf0-unsplash_ {
		position: absolute;
		width: 75px;
		height: 75px;
		left: 7px;
		top: 41px;
		border-radius: 50%;
		overflow: hidden;
	}
	#n_14_web_pcOperate_Live {
		left: 108px;
		top: 59px;
		position: absolute;
		overflow: visible;
		width: 168px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#n_14_web_pcwe_stand_for_grp_14_web_pc {
		position: absolute;
		width: 1087px;
		height: 280px;
		left: 140px;
		top: 1855px;
		overflow: visible;
	}
	#n_14_web_pcCard_03_website_14_web_pc {
		position: absolute;
		width: 273px;
		height: 276px;
		left: 814px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcScroll_Group_12 {
		mix-blend-mode: normal;
		position: absolute;
		width: 273px;
		height: 120px;
		left: 0px;
		top: 156px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n_14_web_pcScroll_Group_12::-webkit-scrollbar{
		display: none;
	}
	#n_14_web_pcintro_lbl_14_web_pc {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 73px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#n_14_web_pcprinciples_lbl_14_web_pc {
		left: 84.5px;
		top: 107px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#n_14_web_pcIcon_top_14_web_pc {
		fill: rgba(255,255,255,1);
	}
	.n_14_web_pcIcon_top_14_web_pc {
		overflow: visible;
		position: absolute;
		width: 18.923px;
		height: 3.37px;
		left: 121.03px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_14_web_pckevin-mu14_web_pc {
		position: absolute;
		width: 234px;
		height: 73px;
		left: 20px;
		top: 24px;
		overflow: visible;
	}
	#n_14_web_pcCard_02_website_14_web_pc {
		position: absolute;
		width: 260px;
		height: 256px;
		left: 394px;
		top: 24px;
		overflow: visible;
	}
	#n_14_web_pcScroll_Group_11 {
		mix-blend-mode: normal;
		position: absolute;
		width: 260px;
		height: 120px;
		left: 0px;
		top: 136px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n_14_web_pcScroll_Group_11::-webkit-scrollbar{
		display: none;
	}
	#n_14_web_pcintro_2_14_web_pc {
		left: 0px;
		top: -4px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 73px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#n_14_web_pcmission_lbl_we14_web_pc {
		left: 93px;
		top: 83px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#n_14_web_pczden14_web_pc {
		position: absolute;
		width: 234px;
		height: 73px;
		left: 13px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcCard_01_website_14_web_pc {
		position: absolute;
		width: 234px;
		height: 256px;
		left: 0px;
		top: 24px;
		overflow: visible;
	}
	#n_14_web_pcScroll_Group_10 {
		mix-blend-mode: normal;
		position: absolute;
		width: 234px;
		height: 120px;
		left: 0px;
		top: 136px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#n_14_web_pcScroll_Group_10::-webkit-scrollbar{
		display: none;
	}
	#n_14_web_pcintro_w14_web_pc {
		left: -13px;
		top: -4px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 77px;
		line-height: 26px;
		margin-top: -5px;
		text-align: center;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#n_14_web_pcVision_14_web_pc {
		left: 88px;
		top: 83px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(0,0,0,1);
	}
	#n_14_web_pcaarn-giri-3lGi014_web_pc {
		position: absolute;
		width: 234px;
		height: 73px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcScroll_Group_48 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1285px;
		height: 393px;
		left: 56px;
		top: 2306px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#n_14_web_pcScroll_Group_48::-webkit-scrollbar{
		display: none;
	}
	#n_14_web_pcGroup_14950 {
		position: relative;
		width: 231px;
		height: 317px;
		left: 26px;
		top: 36px;
		margin-right: 30px;
		overflow: visible;
	}
	#n_14_web_pcRectangle_703 {
		fill: transparent;
	}
	.n_14_web_pcRectangle_703 {
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 317px;
		left: 0px;
		top: 0px;
		background:  rgba( 255, 255, 255, 0.05 );
		box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
		backdrop-filter:  blur( 4px );
		-webkit-backdrop-filter:  blur( 4px );
		border-radius:  10px;
		border:  1px solid rgba( 255, 255, 255, 0.18 );
	}
	#n_14_web_pckevin-mueller-aeNg4YA41P8-unsp {
		position: absolute;
		width: 210px;
		height: 253px;
		left: 10px;
		top: 54px;
		overflow: visible;
	}
	#n_14_web_pcParrot {
		left: 17px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 43px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#n_14_web_pcOur_Collection {
		left: 560px;
		top: 2243px;
		position: absolute;
		overflow: visible;
		width: 248px;
		white-space: nowrap;
		line-height: 46px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(12,54,83,1);
	}
	#n_14_web_pcfooter_grp_web_14_pc {
		position: absolute;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 2836px;
		overflow: visible;
	}
	#n_14_web_pcsocials_grp_med_web_14_pc {
		position: absolute;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcfooter_bg_web_14__pc {
		opacity: 0.93;
		fill: rgba(50,50,50,1);
	}
	.n_14_web_pcfooter_bg_web_14__pc {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 411px;
		left: 0px;
		top: 0px;
	}
	#n_14_web_pcn_Copyrighte_14_op_live_theme {
		left: 41.5px;
		top: 349.16px;
		position: absolute;
		overflow: visible;
		width: 510px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
	}
	#n_14_web_pcGroup_14952 {
		position: absolute;
		width: 210px;
		height: 50.001px;
		left: 42px;
		top: 261.999px;
		overflow: visible;
	}
	#n_14_web_pclinked_in_grp_med_web_14_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 160px;
		top: 0.001px;
		overflow: visible;
	}
	#n_14_web_pcEllipse_5_med_web_14__pc:hover {
		fill: rgba(120,120,255,1);
	}
	.n_14_web_pcEllipse_5_med_web_14__pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n_14_web_pcEllipse_5_med_web_14__pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n_14_web_pcEllipse_5_med_web_14__pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n_14_web_pclinked_in_website_14__layout {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#n_14_web_pcn_website_2_layout_web14__pc {
		fill: rgba(63,61,86,1);
	}
	.n_14_web_pcn_website_2_layout_web14__pc {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.954px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_14_web_pclinked_in_i_down_web14__pc {
		fill: rgba(63,61,86,1);
	}
	.n_14_web_pclinked_in_i_down_web14__pc {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.097px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_14_web_pctop_dot_i_website_14__layout {
		fill: rgba(63,61,86,1);
	}
	.n_14_web_pctop_dot_i_website_14__layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_14_web_pcInsta_grp_med_web_14_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 80px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcEllipse_4_med_web14__pc:hover {
		fill: rgba(255,120,120,1);
	}
	.n_14_web_pcEllipse_4_med_web14__pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n_14_web_pcEllipse_4_med_web14__pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n_14_web_pcEllipse_4_med_web14__pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n_14_web_pcinstagram_website_14__layout {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.126px;
		overflow: visible;
	}
	#n_14_web_pcwhatsapp_grp_med_web_14_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#n_14_web_pcEllipse_3_med_web_14_pc:hover {
		fill: rgba(50,172,158,1);
	}
	.n_14_web_pcEllipse_3_med_web_14_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#n_14_web_pcEllipse_3_med_web_14_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.n_14_web_pcEllipse_3_med_web_14_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#n_14_web_pcwhatsapp_lbl_website14__layout {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#n_14_web_pcFor_Queries_infoandroom_web_14 {
		left: 68.526px;
		top: 132px;
		position: absolute;
		overflow: visible;
		width: 219px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#n_14_web_pcFor_call__920012888_web_14_pc {
		left: 68.526px;
		top: 153px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#n_14_web_pctelephone_web_14_pc {
		fill: rgba(255,255,255,1);
	}
	.n_14_web_pctelephone_web_14_pc {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 42px;
		top: 157.525px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_14_web_pcsend_1_web_14_pc {
		fill: rgba(255,255,255,1);
	}
	.n_14_web_pcsend_1_web_14_pc {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 42px;
		top: 136.72px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_14_web_pcLine_web_14_pc {
		fill: transparent;
		stroke: rgba(86,86,86,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.n_14_web_pcLine_web_14_pc {
		overflow: visible;
		position: absolute;
		width: 1365.56px;
		height: 2px;
		left: 0.155px;
		top: 336.113px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_14_web_pcIcon_metro-locatio_web_14_pc_n {
		fill: rgba(255,255,255,1);
	}
	.n_14_web_pcIcon_metro-locatio_web_14_pc_n {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 18.208px;
		left: 42px;
		top: 178.331px;
		transform: matrix(1,0,0,1,0,0);
	}
	#n_14_web_pcaddress_lbl_web_14_pc {
		left: 68.526px;
		top: 177.539px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 62px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#n_14_web_pcGroup_14951 {
		position: absolute;
		width: 422px;
		height: 405.895px;
		left: 800px;
		top: 2836.506px;
		overflow: visible;
	}
	#n_14_web_pcMy_project {
		position: absolute;
		width: 374.661px;
		height: 405.895px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#n_14_web_pcdisplay_web_9_pc {
		fill: rgba(255,255,255,1);
	}
	.n_14_web_pcdisplay_web_9_pc {
		position: absolute;
		overflow: visible;
		width: 285px;
		height: 150px;
		left: 60px;
		top: 237.494px;
	}
	#n_14_web_pcVISIT_US {
		left: 268px;
		top: 25.494px;
		position: absolute;
		overflow: visible;
		width: 155px;
		white-space: nowrap;
		line-height: 46px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 33px;
		color: rgba(255,255,255,1);
	}
