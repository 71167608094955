	.mediaViewInfo {
		--web-view-name: Chefs Kitchen Mob;
		--web-view-id: Chefs_Kitchen_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Chefs_Kitchen_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Chefs_Kitchen_Mob {
		position: absolute;
		width: 390px;
		height: 5048px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: Chefs Kitchen Mob;
		--web-view-id: Chefs_Kitchen_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#chefs_13_moblcz9nxhoslo-brooke-lark {
		opacity: 0.2;
		position: absolute;
		width: 2500px;
		height: 1871px;
		left: -1124px;
		top: 312px;
		overflow: visible;
	}
	#chefs_13_mobGroup_14943 {
		position: absolute;
		width: 308px;
		height: 656px;
		left: 41px;
		top: 471px;
		overflow: visible;
	}
	#chefs_13_moblidye-1Shk_PkNkNw-unsplash {
		position: absolute;
		width: 268px;
		height: 373px;
		left: 20px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobFood_unites_cultures_evokes__m {
		left: 0px;
		top: 441px;
		position: absolute;
		overflow: visible;
		width: 309px;
		white-space: nowrap;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_mobGroup_14929 {
		position: absolute;
		width: 204px;
		height: 48px;
		left: 52px;
		top: 608px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_89 {
		fill: rgba(249,129,105,1);
	}
	.chefs_13_mobRectangle_89 {
		filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 291px;
		height: 135px;
		left: 0px;
		top: 0px;
	}
	#chefs_13_mobKnow_More_About_Us {
		left: 28px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 153px;
		white-space: nowrap;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobGroup_14944 {
		position: absolute;
		width: 330.323px;
		height: 569.547px;
		left: 29.838px;
		top: 1247.453px;
		overflow: visible;
	}
	#chefs_13_mobGroup_14931 {
		position: absolute;
		width: 330.323px;
		height: 308.77px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobbrooke-lark-08bOYnH_r_E-unspla {
		position: absolute;
		width: 124.812px;
		height: 101.253px;
		left: 170.927px;
		top: 150.876px;
		overflow: visible;
	}
	#chefs_13_mobo9kwrr26 {
		position: absolute;
		width: 124.812px;
		height: 103.759px;
		left: 37.593px;
		top: 66.666px;
		overflow: visible;
	}
	#chefs_13_mobjoseph-gonzalez-zcUgjyqEwe8-un {
		position: absolute;
		width: 165.914px;
		height: 133.834px;
		left: 0px;
		top: 174.937px;
		overflow: visible;
	}
	#chefs_13_mobjoseph-gonzalez-fdlZBWIP0aM-un {
		position: absolute;
		width: 164.41px;
		height: 143.357px;
		left: 165.913px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobFood_a_universal_language_a_ta {
		left: 11.162px;
		top: 376.547px;
		position: absolute;
		overflow: visible;
		width: 309px;
		height: 110px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_mobGroup_14932 {
		position: absolute;
		width: 204px;
		height: 48px;
		left: 63.162px;
		top: 521.547px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_89_cy {
		fill: rgba(249,129,105,1);
	}
	.chefs_13_mobRectangle_89_cy {
		filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 291px;
		height: 135px;
		left: 0px;
		top: 0px;
	}
	#chefs_13_mobKnow_More_About_Us_cz {
		left: 28px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 153px;
		white-space: nowrap;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobGroup_14934 {
		position: absolute;
		width: 322px;
		height: 433.428px;
		left: 29.598px;
		top: 1922.426px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_696 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_mobRectangle_696 {
		filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		width: 321.641px;
		height: 433.428px;
		position: absolute;
		overflow: visible;
		transform: translate(-29.598px, -1922.426px) matrix(1,0,0,1,29.838,1922.4258) rotate(180deg);
		transform-origin: center;
	}
	#chefs_13_mobeaters-collective-172257-unspl {
		position: absolute;
		width: 321.641px;
		height: 225.039px;
		left: 0.24px;
		top: 0.001px;
		overflow: visible;
	}
	#chefs_13_mobScroll_Group_44 {
		mix-blend-mode: normal;
		position: absolute;
		width: 322px;
		height: 158px;
		left: 0px;
		top: 274.574px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#chefs_13_mobScroll_Group_44::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_mobLorem_ipsum_dolor_sit_amet_con {
		left: 20px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 269.407px;
		height: 130px;
		line-height: 22px;
		margin-top: -4px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(174,178,180,1);
	}
	#chefs_13_mobVision {
		left: 20px;
		top: 253.574px;
		position: absolute;
		overflow: visible;
		width: 221px;
		height: 21px;
		line-height: 46px;
		margin-top: -14px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_mobGroup_14933 {
		position: absolute;
		width: 322.369px;
		height: 433.574px;
		left: 34.18px;
		top: 2386.426px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_698 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_mobRectangle_698 {
		filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		width: 321.641px;
		height: 433.428px;
		position: absolute;
		overflow: visible;
		transform: translate(-8928.707px, 11605.574px) matrix(1,0,0,1,8928.7071,-11605.5742) rotate(180deg);
		transform-origin: center;
	}
	#chefs_13_moban_vision-1188492-unsplash {
		position: absolute;
		width: 321.641px;
		height: 225.039px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#chefs_13_mobScroll_Group_45 {
		mix-blend-mode: normal;
		position: absolute;
		width: 322px;
		height: 134px;
		left: 0.369px;
		top: 299.574px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#chefs_13_mobScroll_Group_45::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_mobLorem_ipsum_dolor_sit_amet_con_da {
		left: 19px;
		top: -6px;
		position: absolute;
		overflow: visible;
		width: 271.407px;
		height: 125px;
		line-height: 22px;
		margin-top: -4px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(174,178,180,1);
	}
	#chefs_13_mobMission {
		left: 17.821px;
		top: 253.574px;
		position: absolute;
		overflow: visible;
		width: 221px;
		height: 21px;
		line-height: 46px;
		margin-top: -14px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_mobGroup_14935 {
		position: absolute;
		width: 321.881px;
		height: 433.428px;
		left: 33.939px;
		top: 2849.854px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_697 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_mobRectangle_697 {
		filter: drop-shadow(0px 17px 16px rgba(235, 241, 245, 0.549));
		width: 321.641px;
		height: 433.428px;
		position: absolute;
		overflow: visible;
		transform: translate(-9308.336px, 11605.574px) matrix(1,0,0,1,9308.5762,-11605.5742) rotate(180deg);
		transform-origin: center;
	}
	#chefs_13_mobn_es93lms {
		position: absolute;
		width: 321.641px;
		height: 225.039px;
		left: 0.24px;
		top: 0.001px;
		overflow: visible;
	}
	#chefs_13_mobScroll_Group_46 {
		mix-blend-mode: normal;
		position: absolute;
		width: 316px;
		height: 139px;
		left: 0px;
		top: 283.574px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#chefs_13_mobScroll_Group_46::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_mobLorem_ipsum_dolor_sit_amet_con_dg {
		left: 20px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 276.407px;
		height: 130px;
		line-height: 22px;
		margin-top: -4px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(174,178,180,1);
	}
	#chefs_13_mobPrinciples {
		left: 20px;
		top: 253.574px;
		position: absolute;
		overflow: visible;
		width: 221px;
		height: 21px;
		line-height: 46px;
		margin-top: -14px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_mobgood-soul-shop-DlO0yz4U67A-uns {
		position: absolute;
		width: 1366px;
		height: 755px;
		left: 0px;
		top: 3378px;
		overflow: visible;
	}
	#chefs_13_mobExplore_Our_Foods {
		left: 13px;
		top: 3405px;
		position: absolute;
		overflow: visible;
		width: 365px;
		height: 41px;
		line-height: 46px;
		margin-top: -5px;
		text-align: left;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		color: rgba(249,92,61,1);
	}
	#chefs_13_mobScroll_Group_41 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 604px;
		left: 13px;
		top: 3483px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#chefs_13_mobScroll_Group_41::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_mobGroup_14920 {
		position: relative;
		width: 307px;
		height: 307px;
		left: 29px;
		top: 37px;
		overflow: visible;
		-webkit-animation: spin 2s linear infinite;
  	animation: spin 5s linear infinite;
	}
	@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
	#chefs_13_mobGroup_14941 {
		position: absolute;
		width: 307px;
		height: 307px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobkisspng-plate-circle-platter-t {
		position: absolute;
		width: 307px;
		height: 307px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobanh-nguyen-kcA-c3f_3FE-unsplas {
		position: absolute;
		width: 205px;
		height: 205px;
		left: 51px;
		top: 47px;
		border-radius: 50%;
		overflow: hidden;
	}
	#chefs_13_mobSalad {
		left: 56px;
		top: 25px;
		position: relative;
		overflow: visible;
		width: 255px;
		height: 28px;
		line-height: 46px;
		margin-top: -10.5px;
		text-align: center;
		font-family: Georgia;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(12,54,83,1);
	}
	#chefs_13_mobGroup_14936 {
		position: absolute;
		width: 255px;
		height: 70px;
		left: 68px;
		top: 4102px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_107 {
		fill: rgba(249,129,105,1);
	}
	.chefs_13_mobRectangle_107 {
		filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 342px;
		height: 157px;
		left: 0px;
		top: 0px;
	}
	#chefs_13_mobView_More_-_ {
		left: 68px;
		top: 25px;
		position: absolute;
		overflow: visible;
		width: 120px;
		white-space: nowrap;
		line-height: 71px;
		margin-top: -26.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobGroup_14945 {
		position: absolute;
		width: 403px;
		height: 837px;
		left: 0px;
		top: 4335px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_678 {
		opacity: 0.93;
		fill: rgba(68,55,53,1);
	}
	.chefs_13_mobRectangle_678 {
		position: absolute;
		overflow: visible;
		width: 403px;
		height: 837px;
		left: 0px;
		top: 0px;
	}
	#chefs_13_mobFor_Queries_infoandroomedanet {
		left: 91.764px;
		top: 383px;
		position: absolute;
		overflow: visible;
		width: 219px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobFor_call__920012888 {
		left: 91.764px;
		top: 404px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobtelephone {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_mobtelephone {
		overflow: visible;
		position: absolute;
		width: 11.381px;
		height: 10.521px;
		left: 65.237px;
		top: 408.525px;
		transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_mobsend_1 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_mobsend_1 {
		overflow: visible;
		position: absolute;
		width: 11.38px;
		height: 10.522px;
		left: 65.237px;
		top: 387.719px;
		transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_mobsocials_grp_med_web_4_mob {
		position: absolute;
		width: 210px;
		height: 50.001px;
		left: 90px;
		top: 512.999px;
		overflow: visible;
	}
	#chefs_13_moblinked_in_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 160px;
		top: 0.001px;
		overflow: visible;
	}
	#chefs_13_mobEllipse_5_med_web_4_pc:hover {
		fill: rgba(120,120,255,1);
	}
	.chefs_13_mobEllipse_5_med_web_4_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#chefs_13_mobEllipse_5_med_web_4_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.chefs_13_mobEllipse_5_med_web_4_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#chefs_13_moblinked_in_website_4_layout {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#chefs_13_mobn_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.chefs_13_mobn_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.953px;
		transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_moblinked_in_i_down {
		fill: rgba(63,61,86,1);
	}
	.chefs_13_moblinked_in_i_down {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.098px;
		transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_mobtop_dot_i_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.chefs_13_mobtop_dot_i_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_mobInsta_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 80px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobEllipse_4_med_web_4_pc:hover {
		fill: rgba(255,120,120,1);
	}
	.chefs_13_mobEllipse_4_med_web_4_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#chefs_13_mobEllipse_4_med_web_4_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.chefs_13_mobEllipse_4_med_web_4_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#chefs_13_mobinstagram_website_4_layout {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.126px;
		overflow: visible;
	}
	#chefs_13_mobwhatsapp_grp_med_web_4_pc {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#chefs_13_mobEllipse_3_med_web_4_pc:hover {
		fill: rgba(50,172,158,1);
	}
	.chefs_13_mobEllipse_3_med_web_4_pc:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#chefs_13_mobEllipse_3_med_web_4_pc {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.chefs_13_mobEllipse_3_med_web_4_pc {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#chefs_13_mobwhatsapp_lbl_website4_layout {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#chefs_13_mobIcon_metro-location {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_mobIcon_metro-location {
		overflow: visible;
		position: absolute;
		width: 11.381px;
		height: 18.209px;
		left: 65.236px;
		top: 429.33px;
		transform: matrix(1,0,0,1,0,0);
	}
	#chefs_13_mobFor_call__920012888_eg {
		left: 91.764px;
		top: 428.539px;
		position: absolute;
		overflow: visible;
		width: 219px;
		height: 62px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobn_Copyright_Operate_Live_websi {
		left: 29px;
		top: 630.461px;
		position: absolute;
		overflow: visible;
		width: 318px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobGroup_14926 {
		position: absolute;
		width: 343px;
		height: 264.312px;
		left: 23.5px;
		top: 51.735px;
		overflow: visible;
	}
	#chefs_13_mobkisspng-tray-glass-plastic-pla {
		position: absolute;
		width: 343px;
		height: 264.312px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_701 {
		fill: rgba(255,255,255,1);
	}
	.chefs_13_mobRectangle_701 {
		position: absolute;
		overflow: visible;
		width: 267px;
		height: 172px;
		left: 38.5px;
		top: 46.265px;
	}
	#chefs_13_mobn_Copyright_Operate_website_9_ {
		left: 143.5px;
		top: 12.352px;
		position: absolute;
		overflow: visible;
		width: 104px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobGroup_14942 {
		position: absolute;
		width: 429px;
		height: 399px;
		left: -11.5px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobGroup_14927 {
		position: absolute;
		width: 429px;
		height: 398.5px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#chefs_13_mobstyle_3 {
		position: absolute;
		width: 429px;
		height: 278px;
		left: 0px;
		top: 120.5px;
		overflow: visible;
	}
	#chefs_13_mobRectangle_695_eq {
		fill: url(#chefs_13_mobRectangle_695_eq);
	}
	.chefs_13_mobRectangle_695_eq {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 228px;
		left: 11.5px;
		top: 0px;
	}
	#chefs_13_mobRectangle_84 {
		fill: rgba(249,129,105,1);
	}
	.chefs_13_mobRectangle_84 {
		filter: drop-shadow(0px 15px 29px rgba(249, 129, 105, 0.678));
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 107px;
		left: 39.5px;
		top: 185px;
	}
	#chefs_13_mobGood_food_choices_are_good_inv {
		left: 39.5px;
		top: 55px;
		position: absolute;
		overflow: visible;
		width: 294px;
		height: 50px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: lighter;
		font-size: 20px;
		color: rgba(249,249,249,0.886);
	}
	#chefs_13_mobExplore_Now_ {
		left: 51.5px;
		top: 192px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		line-height: 20px;
		margin-top: -7.5px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 10px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobScroll_Group_43 {
		mix-blend-mode: normal;
		position: absolute;
		width: 359px;
		height: 58px;
		left: 32.5px;
		top: 109px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#chefs_13_mobScroll_Group_43::-webkit-scrollbar{
		display: none;
	}
	#chefs_13_mobThere_is_a_powerful_need_for_s {
		opacity: 0.71;
		left: 7px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 48px;
		text-align: left;
		font-family: Muli;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(249,249,249,0.886);
	}
	#chefs_13_mobzane-lee-bXbi1jJVDf0-unsplash_ {
		position: absolute;
		width: 21px;
		height: 21px;
		left: 39.5px;
		top: 12px;
		overflow: hidden;
		border-radius: 50%;
	}
	#chefs_13_mobOperate_Live {
		left: 68.5px;
		top: 12px;
		position: absolute;
		overflow: visible;
		width: 85px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#chefs_13_mobRectangle_700 {
		opacity: 0.28;
		fill: rgba(0,0,0,1);
	}
	.chefs_13_mobRectangle_700 {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 177px;
		left: 11.5px;
		top: 222px;
	}