.mediaViewInfo {
    --web-view-name: req down;
    --web-view-id: req_down;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: req_down;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#req_down {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 275px);
    top: 0px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: req down;
    --web-view-id: req_down;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#background_green_down_pop_down_y {
    fill: url(#background_green_down_pop_down_y);
}
.background_green_down_pop_down_y {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}
#req_pop_up_bottom_scroll_down_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#req_card_req {
    position: relative;
    width: 180px;
    height: 49px;
    left: 0px;
    top: 70px;
    z-index: 10;
    overflow: visible;
}
#bdr_com_req__ {
    fill: url(#bdr_com_req__);
}
.bdr_com_req__ {
    filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 52px;
    left: 0px;
    top: 0px;
}
#SD_pfp_pic_com_req_mob {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 4px;
    top: 4px;
    overflow: visible;
}
#SD_lbl_com_req_ {
    left: 45.594px;
    top: 6.5px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#inr_and_com_req {
    left: 46.594px;
    top: 26px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#minuclose_com_req_mob {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 153px;
    top: 0px;
    overflow: visible;
}
#requirment_com_mob_pop {
    position: absolute;
    width: 139px;
    height: 40px;
    left: 15px;
    top: 15px;
    overflow: visible;
    border-radius: 50px;
}
#req_btncom_req {
    fill: rgba(255,255,255,1);
}
.req_btncom_req {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 157px;
    height: 58px;
    left: 0px;
    top: 0px;
}
#My_Requirs_lbl_btn_req {
    left: 4px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 109px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(37,37,37,1);
}
#plus_img_com_req {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 114px;
    top: 10px;
    overflow: visible;
}
#down-arrow_for_top_req {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    bottom: 10px;
    overflow: visible;
}