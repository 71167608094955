#footer_app_pc_candi {
    position: fixed;
    width: 100vw;
    height: 42px;
    left: 0px;
    /* top: 524px; */
    z-index: 10;
    bottom: 0px;
    overflow: visible;
}
#footer_vacan {
    fill: rgba(255,135,135,1);
}
.footer_vacan {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 42px;
    left: 0px;
    top: 0px;
}
#All_rights_reserved__2022_Oper {
    left: 126px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 464px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(255,255,255,1);
}
#linkedin_vacan {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 1070px;
    top: 7px;
    overflow: visible;
}
#whatapp_btn_img_vacan {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 1115px;
    top: 7px;
    overflow: visible;
}
#term_and_pol_grp {
    position: absolute;
    width: 294px;
    height: 24px;
    left: 655px;
    top: 10.184px;
    overflow: visible;
}
#Terms_and_Conditions_lbl_vac {
    left: 0px;
    top: 0.816px;
    position: absolute;
    overflow: visible;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(255,255,255,1);
}
#Private_Policy_lbl_vac {
    left: 195px;
    top: 0.816px;
    position: absolute;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(255,255,255,1);
}
#Line_2_vacan {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_2_vacan {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 24px;
    left: 178.239px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}