
	.mediaViewInfo {
		--web-view-name: SoleCrafted;
		--web-view-id: SoleCrafted;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: SoleCrafted;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#SoleCrafted {
		position: absolute;
		width: 1366px;
		height: 3478px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: SoleCrafted;
		--web-view-id: SoleCrafted;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#SC_32_PcGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 472.998px;
		left: 95.5px;
		top: 2998px;
		overflow: visible;
	}
	#SC_32_PcGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 99.997px;
		overflow: visible;
	}
	#SC_32_PcGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SC_32_PcRectangle_737 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SC_32_PcRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#SC_32_Pcsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#SC_32_Pclinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#SC_32_Pcmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.SC_32_Pcmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SC_32_Pclinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#SC_32_Pcn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.SC_32_Pcn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SC_32_Pclinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.SC_32_Pclinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SC_32_Pctop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.SC_32_Pctop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#SC_32_PcInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#SC_32_Pcshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.SC_32_Pcshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SC_32_Pcinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#SC_32_Pcwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#SC_32_Pcmed_shadow_space_website_7_bf {
		fill: rgba(255,255,255,1);
	}
	.SC_32_Pcmed_shadow_space_website_7_bf {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#SC_32_Pcwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#SC_32_Pcn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SC_32_Pcaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SC_32_Pcaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SC_32_Pclocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#SC_32_Pcemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#SC_32_Pcphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#SC_32_Pcn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#SC_32_PcContact_Us {
		left: 510.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#SC_32_PcGroup_15140 {
		position: absolute;
		width: 1336px;
		height: 684px;
		left: 30px;
		top: 0px;
		overflow: visible;
	}
	#SC_32_PcEllipse_44 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.SC_32_PcEllipse_44 {
		position: absolute;
		overflow: visible;
		width: 64px;
		height: 1px;
		left: 1123px;
		top: 286px;
	}
	#SC_32_PcYour_Name {
		left: 0px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 235px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SC_32_PcDiscover_Timeless__Craftsmansh {
		left: 0px;
		top: 180px;
		position: absolute;
		overflow: visible;
		width: 613px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		color: rgba(0,0,0,1);
	}
	#SC_32_Pcnoah-smith-R4xOiCIeOUs-unsplas {
		position: absolute;
		width: 341px;
		height: 342px;
		left: 995px;
		top: 0px;
		overflow: visible;
	}
	#SC_32_Pcalexandr-sadkov-BnG4KWAzt9c-un {
		position: absolute;
		width: 342px;
		height: 342px;
		left: 653px;
		top: 0px;
		overflow: visible;
	}
	#SC_32_Pclea-ochel-nsRBbE6-YLs-unsplash {
		position: absolute;
		width: 342px;
		height: 342px;
		left: 653px;
		top: 342px;
		overflow: visible;
	}
	#SC_32_Pcrajesh-rajput-NqZ2vFqChaw-unsp {
		position: absolute;
		width: 341px;
		height: 342px;
		left: 995px;
		top: 342px;
		overflow: visible;
	}
	#SC_32_PcGroup_15141 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 226px;
		top: 834px;
		overflow: visible;
	}
	#SC_32_PcRectangle_752 {
		fill: rgba(161,141,87,1);
	}
	.SC_32_PcRectangle_752 {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#SC_32_Pcty-feague-87V27nw0sS0-unsplash {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#SC_32_PcScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SC_32_PcScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#SC_32_PcIt_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SC_32_PcVISION {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SC_32_PcGroup_15142 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 226px;
		top: 1270px;
		overflow: visible;
	}
	#SC_32_PcRectangle_752_b {
		fill: rgba(161,141,87,1);
	}
	.SC_32_PcRectangle_752_b {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#SC_32_Pcprateek-chawla-87luO7iL1mM-uns {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 609px;
		top: 33px;
		overflow: visible;
	}
	#SC_32_PcScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 107px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SC_32_PcScroll_Group_28_b::-webkit-scrollbar{
		display: none;
	}
	#SC_32_PcIt_is_a_long_established_fact__b {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SC_32_PcMISSION {
		left: 109px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SC_32_PcGroup_15143 {
		position: absolute;
		width: 915px;
		height: 376px;
		left: 226px;
		top: 1706px;
		overflow: visible;
	}
	#SC_32_PcRectangle_752_ca {
		fill: rgba(161,141,87,1);
	}
	.SC_32_PcRectangle_752_ca {
		position: absolute;
		overflow: visible;
		width: 915px;
		height: 376px;
		left: 0px;
		top: 0px;
	}
	#SC_32_Pcnoah-smith-1z2hBTKHdWI-unsplas {
		position: absolute;
		width: 270px;
		height: 311px;
		left: 36px;
		top: 33px;
		overflow: visible;
	}
	#SC_32_PcScroll_Group_28_cc {
		mix-blend-mode: normal;
		position: absolute;
		width: 366px;
		height: 154px;
		left: 442px;
		top: 143.5px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#SC_32_PcScroll_Group_28_cc::-webkit-scrollbar{
		display: none;
	}
	#SC_32_PcIt_is_a_long_established_fact__cd {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 363px;
		height: 140px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#SC_32_PcPRINCIPLES {
		left: 444px;
		top: 78.5px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SC_32_PcNEW_EDITION {
		left: 105px;
		top: 2452px;
		position: absolute;
		overflow: visible;
		width: 244px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(0,0,0,1);
	}
	#SC_32_PcGroup_15145 {
		position: absolute;
		width: 185px;
		height: 61px;
		left: 1049px;
		top: 2441px;
		overflow: visible;
	}
	#SC_32_PcRectangle_753 {
		fill: rgba(161,141,87,1);
	}
	.SC_32_PcRectangle_753 {
		position: absolute;
		overflow: visible;
		width: 185px;
		height: 61px;
		left: 0px;
		top: 0px;
	}
	#SC_32_PcView_More {
		left: 46px;
		top: 20px;
		position: absolute;
		overflow: visible;
		width: 95px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#SC_32_PcScroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1286px;
		height: 396px;
		left: 40px;
		top: 2502px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#SC_32_PcScroll_Group_91::-webkit-scrollbar{
		display: none;
	}
	#SC_32_PcGroup_15144 {
		position: relative;
        margin-right: 30px;
		width: 243px;
		height: 331px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#SC_32_PcRectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.SC_32_PcRectangle_754 {
		filter: drop-shadow(5px 5px 1px rgba(150, 75, 0, 0.388));
		position: absolute;
		overflow: visible;
		width: 249.5px;
		height: 337.5px;
		left: 0px;
		top: 0px;
	}
	#SC_32_Pcmostafa-mahmoudi-3OZr-hLbsq0-u {
		position: absolute;
		width: 239px;
		height: 255px;
		left: 2px;
		top: 38px;
		overflow: visible;
	}
	#SC_32_PcTitle {
		left: 7px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#SC_32_Pcn_10000 {
		left: 7px;
		top: 299px;
		position: absolute;
		overflow: visible;
		width: 231px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}