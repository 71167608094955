.mediaViewInfo {
    --web-view-name: pop up-2 Req;
    --web-view-id: pop_up-2_Req;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pop_up-2_Req;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pop_up-2_Req_quo_model {
    position: absolute;
    width: 346px;
    height: 418px;
    top: 50%;
    left: 50%;
    border-radius: 15px;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: pop up-2 Req;
    --web-view-id: pop_up-2_Req;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#quotation_bg_img {
    position: absolute;
    width: 346px;
    height: 418px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Quotation_border_pop {
    fill: rgba(219,242,231,1);
}
.Quotation_border_pop {
    width: 118px;
    height: 346px;
    position: absolute;
    overflow: visible;
    transform: matrix(1,0,0,1,-56,-171) rotate(-90deg);
    transform-origin: center;
}
#Quotation_lbl_pop {
    left: 128px;
    top: 16px;
    position: absolute;
    overflow: visible;
    width: 91px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#estimated_quo_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.estimated_quo_pop {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 36px;
    left: 25px;
    top: 88px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#currency_quo_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.currency_quo_pop {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 36px;
    left: 183px;
    top: 88px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#estimated_time_quo_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.estimated_time_quo_pop {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 36px;
    left: 25px;
    top: 144px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#estimated_days_pop_quo {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.estimated_days_pop_quo {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 36px;
    left: 183px;
    top: 144px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#Group_check_can_be_delivered {
    position: absolute;
    width: 234px;
    height: 22px;
    left: 56px;
    top: 202px;
    overflow: visible;
}
#chck_pop_quo {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chck_pop_quo {
    position: absolute;
    overflow: visible;
    width: 15px;
    height: 15px;
    left: 0px;
    top: 4px;
}
#Can_be_Delivered_to_location_lbl_quo {
    left: 21px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 214px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#remarks_quo_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.remarks_quo_pop {
    position: absolute;
    overflow: visible;
    width: 296px;
    height: 69px;
    left: 25px;
    top: 241px;
    font-size: 13px;
    padding: 5px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#close_pop_quo {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 317.142px;
    top: 6.5px;
    cursor: pointer;
    overflow: visible;
}
#Group_btn_up_pop:hover {
    left: 58px;
    top: 347px;
}
#Group_btn_up_pop {
    position: absolute;
    width: 101px;
    height: 37px;
    left: 55px;
    top: 343px;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_btn_quo_ {
    fill: rgba(219,242,231,1);
}
.border_btn_quo_ {
    position: absolute;
    overflow: visible;
    width: 101px;
    height: 37px;
    left: 0px;
    top: 0px;
}
#Update_lbl_quo_ {
    left: 23px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 56px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Group_btn_dis_pop:hover {
    left: 192px;
    top: 347px;
    transition: all .3s ease-out;
}
#Group_btn_dis_pop {
    position: absolute;
    width: 101px;
    height: 37px;
    left: 189px;
    top: 343px;
    overflow: visible;
    transition: all .3s ease-out;
}
#border_btn_dis_ {
    fill: rgba(219,242,231,1);
}
.border_btn_dis_ {
    position: absolute;
    overflow: visible;
    width: 101px;
    height: 37px;
    left: 0px;
    top: 0px;
}
#Discard_lbl_pop {
    left: 23px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
