.mediaViewInfo {
    --web-view-name: index page;
    --web-view-id: index_page;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: index_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#index_page {
    position: absolute;
    width: 1366px;
    height: 700px;
    background-color: rgba(255,255,255,1);
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    --web-view-name: index page;
    --web-view-id: index_page;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Ellipse_1 {
    opacity: 0.75;
    fill: rgba(106,219,143,1);
}
.Ellipse_1 {
    position: absolute;
    overflow: visible;
    width: 497px;
    height: 930px;
    left: 870px;
    top: -164px;
}
#Ellipse_2 {
    fill: rgba(106,219,143,1);
}
.Ellipse_2 {
    position: absolute;
    overflow: visible;
    width: 320px;
    height: 930px;
    left: 1046px;
    top: -164px;
}
#Ellipse_3 {
    opacity: 0.5;
    fill: rgba(1,179,153,1);
}
.Ellipse_3 {
    position: absolute;
    overflow: visible;
    width: 658px;
    height: 930px;
    left: 710px;
    top: -164px;
}
#slazzer-edit-image1 {
    position: absolute;
    width: 758px;
    height: 758px;
    left: 640px;
    top: 10px;
    overflow: visible;
}
#Forgot_Password_login {
    left: 377px;
    top: 549px;
    position: absolute;
    overflow: visible;
    width: 133px;
    white-space: nowrap;
    line-height: 35px;
    margin-top: -10px;
    text-align: center;
    font-family: Poppins-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(133,146,155,1);
    cursor: pointer;
}
#Dont_have_an_Account {
    left: 350px;
    top: 572px;
    position: absolute;
    overflow: visible;
    width: 179px;
    white-space: nowrap;
    line-height: 35px;
    margin-top: -10px;
    text-align: center;
    font-family: Poppins-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(25,140,255,1);
    cursor: pointer;
}

#Login_button_PC:hover {
    width: 145px;
    height: 33px;
    left: 108px;
    top: 555px;
}
#Login_button_PC {
    position: absolute;
    width: 155px;
    height: 39px;
    left: 102px;
    top: 552px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Login_Color_high {
    fill: rgba(33,177,73,1);
}
.Login_Color_high {
    /* filter: drop-shadow(3.857px 4.596px 13px rgba(0, 0, 0, 0.141)); */
    position: absolute;
    overflow: visible;
    width: 155px;
    height: 40px;
    left: 0px;
    top: 0px;
    transition: all .3s ease-out;
}
#LOGIN_N {
    left: 47.857px;
    top: 9.735px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    line-height: 30.360000610351562px;
    margin-top: -6.680000305175781px;
    text-align: center;
    font-family: Poppins-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(255,255,255,1);
}
#imageloginLogo {
    position: absolute;
    width: 135px;
    height: 135px;
    left: 245px;
    top: 167px;
    overflow: visible;
}
#txtPassword_PC {
    fill: rgba(255,255,255,1);
}
.txtPassword_PC {
    filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 411px;
    height: 55px;
    left: 99px;
    top: 470px;
    font-size: 20px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius:  50px;
    /* border-shadow: 10px; */
}
#txtEmail_PC {
    fill: rgba(255,255,255,1);
}
.txtEmail_PC {
    filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 411px;
    height: 55px;
    left: 99px;
    top: 404px;
    font-size: 20px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius:  50px;
}
#OPERATE_LIVE_Login {
    left: 154px;
    top: 316px;
    position: absolute;
    overflow: visible;
    width: 319px;
    white-space: nowrap;
    line-height: 132px;
    margin-top: -46px;
    text-align: left;
    font-family: Cooper;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgba(33,177,73,1);
}