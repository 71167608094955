.mediaViewInfo {
    --web-view-name: pop up create;
    --web-view-id: pop_up_create;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pop_up_create;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pop_up_create {
    position: absolute;
    width: 265px;
    height: 324px;
    top: 50%;
    left: 50%;
    border-radius: 15px;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: pop up create;
    --web-view-id: pop_up_create;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Group_popup_cr_com {
    position: absolute;
    width: 265px;
    height: 386px;
    left: 0px;
    top: -82px;
    overflow: visible;
}
#Path_3_com {
    fill: rgba(219,242,231,1);
}
.Path_3_com {
    overflow: visible;
    position: absolute;
    width: 118px;
    height: 265px;
    transform: translate(0px, 82px) matrix(1,0,0,1,73.5,-155.5) rotate(-90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#Create_a_Community_lbl {
    left: 51px;
    top: 91px;
    position: absolute;
    overflow: visible;
    width: 164px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#profilebackgroundPC {
    position: absolute;
    width: 211px;
    height: 69px;
    left: 27px;
    top: 133px;
    overflow: visible;
}
#profileimgupload {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 103px;
    top: 165px;

    border-radius: 50%;
    overflow: hidden;
}
#communityName_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.communityName_pop {
    position: absolute;
    overflow: visible;
    width: 211px;
    height: 32px;
    left: 27px;
    top: 246px;
    font-size: 13px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#communityDescription_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.communityDescription_pop {
    position: absolute;
    overflow: visible;
    width: 211px;
    height: 49px;
    left: 27px;
    top: 293px;
    font-size: 13px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#Group_77 {
    position: absolute;
    width: 81px;
    height: 24px;
    left: 92px;
    top: 362px;
    overflow: visible;
}
#Rectangle_29 {
    fill: rgba(180,235,190,1);
}
.Rectangle_29 {
    position: absolute;
    overflow: visible;
    width: 81px;
    height: 24px;
    left: 0px;
    top: 0px;
}
#Submit {
    left: 21px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    cursor: pointer;
    color: rgba(37,37,37,1);
}
#cancelicon_pop {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 245px;
    top: 5px;
    cursor: pointer;
    overflow: visible;
}
