.mediaViewInfo {
    --web-view-name: off down;
    --web-view-id: off_down;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: off_down;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#off_down {
    position: absolute;
    width: 100vw;
    height:calc(100vh - 275px);
    /* top:calc(100% - 245px); */
    top:0px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: off down;
    --web-view-id: off_down;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#off_bhack_bg_y {
    fill: url(#off_bhack_bg_y);
}
.off_bhack_bg_y {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}
#Scroll_overall_off_down {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#off_card__req_ {
    position: relative;
    width: 180px;
    height: 49px;
    left: 0px;
    top: 70px;
    z-index: 1;
    overflow: visible;
}
#bdr_com_off_mob_ {
    fill: url(#bdr_com_off_mob_);
}
.bdr_com_off_mob_ {
    filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 52px;
    left: 0px;
    top: 0px;
}
#pfp_pic_cooff_mob {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 4px;
    top: 4px;
    overflow: visible;
}
#Mohammed_SD_lbl_com_off_mob {
    left: 46.594px;
    top: 6.5px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#inr_and_date_comoffmob {
    left: 46.594px;
    top: 26px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
}
#minus_2_close_comoff_mob {
    position: absolute;
    width: 15px;
    height: 15px;
    left: 153px;
    top: 0px;
    overflow: visible;
}
#my_offbtn_com_req_mob {
    position: absolute;
    width: 139px;
    height: 40px;
    left: 15px;
    top: 5px;
    overflow: visible;
    border-radius: 50px;
}
#off_btn_com_req_mob {
    fill: rgba(255,255,255,1);
}
.off_btn_com_req_mob {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 157px;
    height: 58px;
    left: 0px;
    top: 0px;
}
#My_OFF_lbl_btn_com_req_mob {
    left: 17px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(37,37,37,1);
}
#plus_img_btn_comoff_mob {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 102px;
    top: 10px;
    overflow: visible;
}
#down-arrow_for_top_off {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    bottom: 10px;
    transform: rotate(180deg);
    overflow: visible;
}