
	.mediaViewInfo {
		--web-view-name: ColorFusion Mob;
		--web-view-id: ColorFusion_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ColorFusion_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ColorFusion_Mob {
		position: absolute;
		width: 375px;
		height: 3674px;
        left:calc((100vw - 375px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: ColorFusion Mob;
		--web-view-id: ColorFusion_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#CFM_35_MobGroup_15132 {
		position: absolute;
		width: 358px;
		height: 600.096px;
		left: 8px;
		top: 3064px;
		overflow: visible;
	}
	#CFM_35_MobGroup_15050 {
		position: absolute;
		width: 358px;
		height: 600.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_Mobsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 67px;
		top: 479.096px;
		overflow: visible;
	}
	#CFM_35_Moblinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_Mobmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CFM_35_Mobmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_Moblinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#CFM_35_Mobn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.CFM_35_Mobn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CFM_35_Moblinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.CFM_35_Moblinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CFM_35_Mobtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.CFM_35_Mobtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CFM_35_MobInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_Mobshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CFM_35_Mobshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_Mobinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#CFM_35_Mobwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_Mobmed_shadow_space_website_7_ {
		fill: rgba(255,255,255,1);
	}
	.CFM_35_Mobmed_shadow_space_website_7_ {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_Mobwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#CFM_35_Mobn_234567890_mobile_number_shad {
		left: 35px;
		top: 292.096px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#CFM_35_Mobaffanghanioperatelive_email_sh {
		left: 35px;
		top: 339.096px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#CFM_35_Mobaddress_med_shadow_space_websi {
		left: 35px;
		top: 389.096px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#CFM_35_Moblocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 391.096px;
		overflow: visible;
	}
	#CFM_35_Mobemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 338.096px;
		overflow: visible;
	}
	#CFM_35_Mobphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 285.096px;
		overflow: visible;
	}
	#CFM_35_Mobn_Copyright_Operate_Live_OPC_P {
		left: 17px;
		top: 564.096px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#CFM_35_MobContact_Us {
		left: 117.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: left;
		font-family: Microsoft PhagsPa;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(36,36,36,1);
	}
	#CFM_35_MobRectangle_757 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CFM_35_MobRectangle_757 {
		position: absolute;
		overflow: visible;
		width: 274px;
		height: 183px;
		left: 41.5px;
		top: 56px;
	}
	#CFM_35_MobGroup_15117 {
		position: absolute;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_Mobtheme-photos-Cl-OpYWFFm0-unspl {
		position: absolute;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_MobRectangle_748 {
		opacity: 0.23;
		fill: rgba(0,0,0,1);
	}
	.CFM_35_MobRectangle_748 {
		position: absolute;
		overflow: visible;
		width: 375px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_MobAnime_Edit {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 20px;
		top: 38px;
		overflow: hidden;
        border-radius: 50%;
	}
	#CFM_35_MobYour_Business_Name {
		left: 91px;
		top: 55px;
		position: absolute;
		overflow: visible;
		width: 285px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#CFM_35_MobDiscover_a_World_of_Possibilit {
		left: 17px;
		top: 214px;
		position: absolute;
		overflow: visible;
		width: 343px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#CFM_35_MobGroup_15113 {
		position: absolute;
		width: 177px;
		height: 49px;
		left: 20px;
		top: 439px;
		overflow: visible;
	}
	#CFM_35_MobRectangle_3 {
		fill: rgba(105,139,153,1);
	}
	.CFM_35_MobRectangle_3 {
		position: absolute;
		overflow: visible;
		width: 177px;
		height: 49px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_MobAvailable_Now {
		left: 37px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		color: rgba(255,255,255,1);
	}
	#CFM_35_Mobdavid-pisnoy-46juD4zY1XA-unspl {
		opacity: 0.5;
		position: absolute;
		width: 375px;
		height: 1779px;
		left: 0px;
		top: 763px;
		overflow: visible;
	}
	#CFM_35_MobGroup_15174 {
		position: absolute;
		width: 332px;
		height: 1706px;
		left: 22px;
		top: 800px;
		overflow: visible;
	}
	#CFM_35_MobGroup_15141 {
		position: absolute;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_MobRectangle_752 {
		fill: rgba(252,141,133,1);
	}
	.CFM_35_MobRectangle_752 {
		position: absolute;
		overflow: visible;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_Mobyoann-siloine-LiDVw7tZPfY-unsp {
		position: absolute;
		width: 270px;
		height: 270px;
		left: 31px;
		top: 33px;
		overflow: visible;
	}
	#CFM_35_MobScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 332px;
		height: 130px;
		left: 0px;
		top: 401px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CFM_35_MobIt_is_a_long_established_fact_ {
		left: 13px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 316px;
		height: 119px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#CFM_35_MobVISION {
		left: 15px;
		top: 333px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#CFM_35_MobGroup_15172 {
		position: absolute;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 580px;
		overflow: visible;
	}
	#CFM_35_MobRectangle_752_bt {
		fill: rgba(137,196,224,1);
	}
	.CFM_35_MobRectangle_752_bt {
		position: absolute;
		overflow: visible;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_Mobtaelynn-christopher-pfSNx3Z12K {
		position: absolute;
		width: 270px;
		height: 270px;
		left: 31px;
		top: 33px;
		overflow: visible;
	}
	#CFM_35_MobScroll_Group_28_bv {
		mix-blend-mode: normal;
		position: absolute;
		width: 332px;
		height: 130px;
		left: 0px;
		top: 401px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CFM_35_MobIt_is_a_long_established_fact__bw {
		left: 13px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 316px;
		height: 119px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#CFM_35_MobMISSION {
		left: 15px;
		top: 333px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#CFM_35_MobGroup_15173 {
		position: absolute;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 1160px;
		overflow: visible;
	}
	#CFM_35_MobRectangle_752_bz {
		fill: rgba(215,234,152,1);
	}
	.CFM_35_MobRectangle_752_bz {
		position: absolute;
		overflow: visible;
		width: 332px;
		height: 546px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_Mobdavid-pisnoy-46juD4zY1XA-unspl_b {
		position: absolute;
		width: 270px;
		height: 270px;
		left: 31px;
		top: 33px;
		overflow: visible;
	}
	#CFM_35_MobScroll_Group_28_b {
		mix-blend-mode: normal;
		position: absolute;
		width: 332px;
		height: 130px;
		left: 0px;
		top: 401px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CFM_35_MobIt_is_a_long_established_fact__b {
		left: 13px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 316px;
		height: 119px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#CFM_35_MobPRINCIPLES {
		left: 15px;
		top: 333px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#CFM_35_MobGroup_15170 {
		position: absolute;
		width: 375px;
		height: 349px;
		left: 0px;
		top: 2615px;
		overflow: visible;
	}
	#CFM_35_MobScroll_Group_91 {
		mix-blend-mode: normal;
		position: absolute;
		width: 375px;
		height: 311px;
		left: 0px;
		top: 38px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#CFM_35_MobGroup_15144 {
		position: relative;
        margin-right: 30px;
		width: 187px;
		height: 270px;
		left: 16px;
		top: 22px;
		overflow: visible;
	}
	#CFM_35_MobRectangle_754 {
		fill: rgba(255,255,255,1);
	}
	.CFM_35_MobRectangle_754 {
		filter: drop-shadow(5px 5px 1px rgba(233, 221, 223, 1));
		position: absolute;
		overflow: visible;
		width: 193.5px;
		height: 276.5px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_Mobanna-kolosyuk-D5nh6mCW52c-unsp {
		position: absolute;
		width: 184px;
		height: 241px;
		left: 2px;
		top: 29px;
		overflow: visible;
	}
	#CFM_35_MobTitle {
		left: 6px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 181px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#CFM_35_MobGroup_15149 {
		position: absolute;
		width: 336px;
		height: 38px;
		left: 20px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_MobOur_Colours {
		left: 0px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CFM_35_MobGroup_15145 {
		position: absolute;
		width: 145px;
		height: 38px;
		left: 191px;
		top: 0px;
		overflow: visible;
	}
	#CFM_35_MobRectangle_753 {
		fill: rgba(183,177,186,1);
	}
	.CFM_35_MobRectangle_753 {
		position: absolute;
		overflow: visible;
		width: 145px;
		height: 38px;
		left: 0px;
		top: 0px;
	}
	#CFM_35_MobView_More {
		left: 38px;
		top: 11px;
		position: absolute;
		overflow: visible;
		width: 71px;
		white-space: nowrap;
		text-align: left;
		font-family: Times New Roman;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}