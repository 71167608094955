	.mediaViewInfo {
		--web-view-name: SweetTreat;
		--web-view-id: SweetTreat;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: SweetTreat;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#SweetTreat {
		position: absolute;
		width: 1366px;
		height: 3277px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: SweetTreat;
		--web-view-id: SweetTreat;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#STCGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 487.998px;
		left: 95.5px;
		top: 2768.904px;
		overflow: visible;
	}
	#STCGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 114.997px;
		overflow: visible;
	}
	#STCGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STCRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.STCRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#STCsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#STClinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#STCmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.STCmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#STClinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#STCn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.STCn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#STClinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.STClinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#STCtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.STCtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#STCInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#STCshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.STCshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#STCinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#STCwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STCmed_shadow_space_website_7_bl {
		fill: rgba(255,255,255,1);
	}
	.STCmed_shadow_space_website_7_bl {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#STCwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#STCn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STCaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STCaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STClocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#STCemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#STCphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#STCn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#STCContact_Us {
		left: 510.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#STCEllipse_44 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.STCEllipse_44 {
		position: absolute;
		overflow: visible;
		width: 64px;
		height: 1px;
		left: 1153px;
		top: 286px;
	}
	#STCGroup_15135 {
		position: absolute;
		width: 1078px;
		height: 1370.174px;
		left: 144px;
		top: 770.922px;
		overflow: visible;
	}
	#STCGroup_15126 {
		position: absolute;
		width: 1078px;
		height: 423.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STCshree-iyer-8vzLN4oHcGQ-unsplas {
		position: absolute;
		width: 423.096px;
		height: 423.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STCScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 485px;
		top: 149.078px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#STCIt_is_a_long_established_fact_ {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#STCVISION {
		left: 487px;
		top: 84.078px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STCGroup_15125 {
		position: absolute;
		width: 1078px;
		height: 423.096px;
		left: 0px;
		top: 474px;
		overflow: visible;
	}
	#STCaditya-joshi-uWI48cpw7Vo-unspl {
		position: absolute;
		width: 423.096px;
		height: 423.096px;
		left: 654.904px;
		top: 0px;
		overflow: visible;
	}
	#STCScroll_Group_88 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 0px;
		top: 149.078px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#STCIt_is_a_long_established_fact__b {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#STCMISSION {
		left: 5px;
		top: 84.078px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STCGroup_15127 {
		position: absolute;
		width: 1078px;
		height: 423.096px;
		left: 0px;
		top: 947.078px;
		overflow: visible;
	}
	#STCsanju-m-gurung-W1qp7p_laj0-uns {
		position: absolute;
		width: 423.096px;
		height: 423.096px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STCScroll_Group_89 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 485px;
		top: 149.078px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#STCIt_is_a_long_established_fact__ca {
		left: 4px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#STCPRINCIPLES {
		left: 490px;
		top: 84.078px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STCGroup_15156 {
		position: absolute;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STCstefano-zocca-1p-Mx-SaLT0-unsp {
		position: absolute;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#STCRectangle_755 {
		opacity: 0.29;
		fill: rgba(0,0,0,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.STCRectangle_755 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 663px;
		left: 0px;
		top: 0px;
	}
	#STCIndulge_in_a_world_of_sweetnes {
		left: 295px;
		top: 201px;
		position: absolute;
		overflow: visible;
		width: 778px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 49px;
		color: rgba(255,255,255,1);
	}
	#STCAnime_Edit {
		position: absolute;
		width: 80px;
		height: 80px;
		left: 41px;
		top: 44px;
		overflow: visible;
	}
	#STCYour_Business_Name {
		left: 131px;
		top: 70px;
		position: absolute;
		overflow: visible;
		width: 298px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#STCGroup_15157 {
		position: absolute;
		width: 51.122px;
		height: 67.188px;
		left: 657px;
		top: 542.094px;
		overflow: visible;
	}
	#STCPath_7785 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.STCPath_7785 {
		overflow: visible;
		position: absolute;
		width: 32.202px;
		height: 62.657px;
		left: 21.92px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#STCPath_7786 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 3px;
		stroke-linejoin: miter;
		stroke-linecap: round;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.STCPath_7786 {
		overflow: visible;
		position: absolute;
		width: 32.202px;
		height: 60.011px;
		left: 0px;
		top: 10.178px;
		transform: matrix(1,0,0,1,0,0);
	}
	#STCIcon_awesome-arrow-down {
		fill: rgba(255,255,255,1);
	}
	.STCIcon_awesome-arrow-down {
		overflow: visible;
		position: absolute;
		width: 27.916px;
		height: 28.642px;
		left: 11.964px;
		top: 19.654px;
		transform: matrix(1,0,0,1,0,0);
	}
	#STCOur_Greatest_Drinks {
		left: 590px;
		top: 2241px;
		position: absolute;
		overflow: visible;
		width: 187px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#STCScroll_Group_90 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1286px;
		height: 380px;
		left: 40px;
		top: 2289px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#STCGroup_15128 {
		position: absolute;
		width: 253px;
		height: 317px;
		left: 50px;
		top: 29px;
		overflow: visible;
	}
	#STCRectangle_750_cq {
		fill: url(#STCRectangle_750_cq);
	}
	.STCRectangle_750_cq {
		filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 258px;
		height: 322px;
		left: 0px;
		top: 0px;
	}
	#STCprchi-palwe-wwgulilpmlg-unspla {
		position: absolute;
		width: 231px;
		height: 262px;
		left: 11px;
		top: 11px;
		overflow: visible;
	}
	#STCJuice_1 {
		left: 11px;
		top: 281px;
		position: absolute;
		overflow: visible;
		width: 232px;
		height: 27px;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	body::-webkit-scrollbar{
		display: none;	
	}
	#STCScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#STCScroll_Group_88::-webkit-scrollbar{
		display: none;
	}
	#STCScroll_Group_89::-webkit-scrollbar{
		display: none;
	}
	#STCScroll_Group_90::-webkit-scrollbar{
		display: none;
	}
