.mediaViewInfo {
    --web-view-name: sign up;
    --web-view-id: sign_up;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: sign_up;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#sign_up {
    position: absolute;
    width: 1366px;
    height: 712px;
    background-color: rgba(215,241,222,1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* transform: scale(1.0); */
    overflow: hidden;
    --web-view-name: sign up;
    --web-view-id: sign_up;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Path_331 {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Path_331 {
    overflow: visible;
    position: absolute;
    width: 1245.816px;
    height: 660.617px;
    left: 53px;
    top: 25.415px;
    transform: matrix(1,0,0,1,0,0);
}
#right-arrow {
    position: absolute;
    width: 36px;
    height: 39px;
    background-color: white;
    border-radius: 18px;
    overflow: visible;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: index_page.html;
    cursor: pointer;
}
#Sign_Up_q {
    left: 228.29px;
    top: 78.747px;
    position: absolute;
    overflow: visible;
    width: 112px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI Symbol;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    color: rgba(5,5,5,1);
}
#contract {
    position: absolute;
    width: 52.611px;
    height: 52.611px;
    left: 357.87px;
    top: 80px;
    overflow: visible;
}
#txtMobile_No {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtMobile_No {
    filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 178.018px;
    height: 48px;
    left: 400.792px;
    top: 346.128px;
    font-size: 16px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtEmail_signUp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtEmail_signUp {
    filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 178.018px;
    height: 48px;
    left: 186px;
    top: 346.438px;
    font-size: 16px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtEnter_Password {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtEnter_Password {
    filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 178.018px;
    height: 48px;
    left: 186px;
    top: 428.939px;
    font-size: 16px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtConfirm_Password {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtConfirm_Password {
    filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 178.018px;
    height: 48px;
    left: 400.792px;
    top: 428.939px;
    font-size: 16px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtFirst_Name {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtFirst_Name {
    filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 178.018px;
    height: 48px;
    left: 186px;
    top: 263.774px;
    font-size: 18px;
    padding: 15px;
    border: 2px solid darkcyan;
}
#txtNick_Name {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.txtNick_Name {
    filter: drop-shadow(10px 8px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 178.018px;
    height: 48px;
    left: 400.792px;
    top: 263.774px;
    font-size: 18px;
    padding: 15px;
    border: 2px solid darkcyan;
}

.nickNamelbl {
   
    position: absolute;
    overflow: visible;
    width: 385px;
    height: 25px;
    left: 186px;
    white-space: nowrap;
    text-align: right;
    top: 316.774px;
    font-size: 16px;

    
}
#profile_Pic_SignUp {
    position: absolute;
    width: 80.611px;
    height: 80.611px;
    left: 350.181px;
    top: 160.808px;
    overflow: visible;
    cursor: pointer;
}
#Already_a_Member_Sign_in {
    left: 288.745px;
    top: 580.842px;
    position: absolute;
    overflow: visible;
    width: 187px;
    white-space: nowrap;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: index_page.html;
    cursor: pointer;
    line-height: 46px;
    margin-top: -16px;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#Login_button:hover {
    width: 141.307px;
    height: 31.591px;
    left: 308.092px;
    top: 522.453px;
}
#Login_button {
    position: absolute;
    width: 153.462px;
    height: 35.382px;
    left: 302.014px;
    top: 520.601px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Rectangle_2_ {
    fill: rgba(33,177,73,1);
}
.Rectangle_2_ {
    filter: drop-shadow(3.857px 4.596px 13px rgba(0, 0, 0, 0.141));
    position: absolute;
    overflow: visible;
    width: 153.463px;
    height: 35.382px;
    left: 0px;
    top: 0px;
}
#Sign_Up_ba {
    left: 46.265px;
    top: 9.793px;
    position: absolute;
    overflow: visible;
    width: 59px;
    white-space: nowrap;
    line-height: 18px;
    margin-top: -1.5px;
    text-align: center;
    font-family: Poppins-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255,255,255,1);
}
#Path_328 {
    fill: rgba(51,209,155,1);
}
.Path_328 {
    overflow: visible;
    position: absolute;
    width: 562.609px;
    height: 85.903px;
    left: 720.197px;
    top: 25.415px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_329 {
    fill: rgba(106,219,143,1);
}
.Path_329 {
    overflow: visible;
    position: absolute;
    width: 526.456px;
    height: 639.169px;
    left: 785.361px;
    top: 25.415px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_330 {
    fill: rgba(107,222,194,1);
}
.Path_330 {
    overflow: visible;
    position: absolute;
    width: 608.803px;
    height: 184.473px;
    left: 702.437px;
    top: 501.56px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_332 {
    fill: rgba(84,209,126,0.69);
}
.Path_332 {
    overflow: visible;
    position: absolute;
    width: 613.107px;
    height: 389.203px;
    left: 698.274px;
    top: 200.508px;
    transform: matrix(1,0,0,1,0,0);
}
#Group_15 {
    position: absolute;
    width: 161px;
    height: 161px;
    left: 966px;
    top: 211px;
    overflow: visible;
}
#Ellipse_4 {
    fill: rgba(255,255,255,1);
}
.Ellipse_4 {
    position: absolute;
    overflow: visible;
    width: 161px;
    height: 161px;
    left: 0px;
    top: 0px;
}
#image_SignUp {
    position: absolute;
    width: 135px;
    height: 135px;
    left: 13px;
    top: 13px;
    overflow: visible;
}
#Group_14 {
    position: absolute;
    width: 341px;
    height: 50px;
    left: 878px;
    top: 399px;
    overflow: visible;
}
#Rectangle_5 {
    fill: rgba(255,255,255,1);
}
.Rectangle_5 {
    position: absolute;
    overflow: visible;
    width: 341px;
    height: 50px;
    left: 0px;
    top: 0px;
}
#OPERATE_LIVE_SignUp {
    left: 12px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 319px;
    white-space: nowrap;
    line-height: 132px;
    margin-top: -46px;
    text-align: left;
    font-family: Cooper;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgba(33,177,73,1);
}