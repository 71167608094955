#all_grp_top_to_down_comp_reqof {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 180px);
    left: 0px;
    top: 114px;
    overflow-y: auto;
    overflow-x: hidden;
}
#desgin_3_offreqevent_com_req_p {
    fill: rgba(248,248,248,1);
}
.desgin_3_offreqevent_com_req_p {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 255px;
    left: 0px;
    top: 0px;
}
#desgin_2_offreqevent_com_req_p {
    position: absolute;
    width: 100%;
    height: 227px;
    left: 0px;
    top: 528px;
    overflow: visible;
}
#design_1_offreqevent_com_req_p {
    fill: rgba(0,0,0,0.569);
}
.design_1_offreqevent_com_req_p {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 227px;
    left: 0px;
    top: 528px;
}
#recently_affreqevent_com_req_p {
    /* left: 497px; */
    top: 65px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: pre;
    line-height: 49px;
  
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#explore_offreqevent_com_req_pc {
    /* left: 519px; */
    top: 47px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#And_offreqevent_com_req_pc {
    /* left: 66px; */
    top: 594px;
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 104px;
    line-height: 56.630001068115234px;
    
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: rgba(255,255,255,1);
    letter-spacing: -0.46875px;
}
#trending_this_week_offreqevent {
    /* left: 486px; */
    top: 833px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    line-height: 50px;
    /* margin-top: -11.5px; */
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    color: rgba(17,17,17,1);
    letter-spacing: -0.4791666793823242px;
    text-transform: uppercase;
}
#top_product_reqevent_com_req_p {
    /* left: 574.606px; */
    top: 809.533px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(48,47,47,1);
    letter-spacing: -0.2222222137451172px;
    text-transform: uppercase;
}
#Scroll_recently_added_com_req_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 356px;
    /* left: 40px; */
    top: 112px;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}
#Scroll_recently_added_com_req_::-webkit-scrollbar {
display: none;
}
#Scroll_trending_this_week_com_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 772px;
    left: 0px;
    top: 909px;
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
}
#all_items_grp_trend_off_PC {
    position: relative;
    width: 1110px;
    height: 680px;
    top: 34px;
    left: calc((100% - 1110px)/2);
    display: inline-block;
    overflow: visible;
}