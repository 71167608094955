.mediaViewInfo {
    --web-view-name: my connection-2;
    --web-view-id: my_connection-and-requests;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: my_connection-and-requests;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#my_connection-and-requests {
    position: absolute;
    width: calc(100vw - 120px);
    height: calc(100vh - 60px);
    background-color: rgba(255,255,255,1);
    top:60px;
    left:120px;
    overflow: hidden;
    --web-view-name: my connection-2;
    --web-view-id: my_connection-and-requests;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#ConnectionAndRequestBg {
    opacity: 0.77;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#My_Connections_lbl {
    left: 83px;
    top: 40px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#Line_1_border_cr {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_1_border_cr {
    overflow: visible;
    position: absolute;
    width: 308px;
    height: 1px;
    left: 83.5px;
    top: 69.5px;
    transform: matrix(1,0,0,1,0,0);
}

#My_Request_lbl_ {
    left: 296px;
    top: 40px;
    position: absolute;
    overflow: visible;
    width: 89px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
}

#switch_cr_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.switch_cr_ {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 17px;
    left: 224px;
    top: 35px;
}