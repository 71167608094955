
	.mediaViewInfo {
		--web-view-name: pop up requirement;
		--web-view-id: pop_up_requirement;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: pop_up_requirement;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#pop_up_requirement {
		position: absolute;
		width: 308px;
		height: 639px;
        top: 60%;
        left: 50%;
        border-radius: 15px;
        transform: translate(-50%, -50%); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: pop up requirement;
		--web-view-id: pop_up_requirement;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#low_poly_banner_design_pop_req_i {
		fill: url(#low_poly_banner_design_pop_req_i);
	}
	.low_poly_banner_design_pop_req_i {
		mix-blend-mode: multiply;
		position: absolute;
		overflow: visible;
		width: 307px;
		height: 77px;
		left: 0px;
		top: 0px;
	}
	#serv_pro_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.serv_pro_pop_requu {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 17px;
		top: 177px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#bud_amt_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.bud_amt_pop_requu {
		position: absolute;
		overflow: visible;
		width: 148px;
		height: 41px;
		left: 20px;
		top: 228px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#idk_drop_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.idk_drop_pop_requu {
		position: absolute;
		overflow: visible;
		width: 109px;
		height: 41px;
		left: 179px;
		top: 228px;
		border: 2px solid darkcyan;
	}
	#Dl_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Dl_pop_requu {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 20px;
		top: 338px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#cat_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.cat_pop_requu {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 20px;
		top: 393px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#desc_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.desc_pop_requu {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 63px;
		left: 20px;
		top: 498px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#Requirements_lbl_pop_requu {
		left: 93px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 122px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(37,37,37,1);
	}
	#submit_grp_pop_requu:hover {
		left: 110px;
		top: 586px;
	}
	#submit_grp_pop_requu {
		position: absolute;
		width: 97px;
		height: 32px;
		left: 107px;
		top: 581px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#bdr_sub_pop_requu {
		fill: rgba(219,242,231,1);
	}
	.bdr_sub_pop_requu {
		position: absolute;
		overflow: visible;
		width: 97px;
		height: 32px;
		left: 0px;
		top: 0px;
	}
	#Submit_pop_requu {
		left: 17px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 64px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(37,37,37,1);
	}
	#Enter_your_name_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Enter_your_name_pop_requu {
		position: absolute;
		overflow: visible;
		width: 134px;
		height: 36px;
		left: 154px;
		top: 283px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#Delivery_Deadline_lbl_pop_requ {
		left: 20px;
		top: 290px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(37,37,37,1);
	}
	#grp_req_upload_pop_requu {
		position: absolute;
		width: 167px;
		height: 94.284px;
		left: 72px;
		top: 38px;
		overflow: visible;
	}
	#profile__3_pop_requu {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 47px;
		top: 0px;
		overflow: visible;
	}
	#profile_2_pop_requu {
		position: absolute;
		width: 42px;
		height: 42px;
		left: 125px;
		top: 14px;
		overflow: visible;
	}
	#profile_1_pop_requu {
		position: absolute;
		width: 42px;
		height: 42px;
		left: 0px;
		top: 14px;
		overflow: visible;
	}
	#add_more_pop_requu {
		position: absolute;
		width: 28.284px;
		height: 28.284px;
		left: 97px;
		top: 66px;
		overflow: visible;
	}
	#close_pop_requu_ {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 47.142px;
		top: 70px;
		overflow: visible;
	}
	#accept_pop_requu {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 74.142px;
		top: 70.142px;
		overflow: visible;
	}
	#close2_pop_requu {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 280.142px;
		top: 6.5px;
		overflow: visible;
	}
	#Group_radio_pop_requu {
		position: absolute;
		width: 193px;
		height: 22px;
		left: 49px;
		top: 142px;
		overflow: visible;
	}
	#rad_pro_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.rad_pro_pop_requu {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 0px;
		top: 5px;
	}
	#Product_pop_requu {
		left: 20px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 60px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#rad_ser_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.rad_ser_pop_requu {
		position: absolute;
		overflow: visible;
		width: 15px;
		height: 15px;
		left: 120px;
		top: 5px;
	}
	#Service_pop_requu {
		left: 140px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 54px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
	}
	#req_quantity_pop_requu {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.req_quantity_pop_requu {
		position: absolute;
		overflow: visible;
		width: 268px;
		height: 41px;
		left: 20px;
		top: 446px;
		font-size: 15px;
		padding: 15px;
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
