	.mediaViewInfo {
		--web-view-name: CaptureSphere Mob;
		--web-view-id: CaptureSphere_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: CaptureSphere_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#CaptureSphere_Mob {
		position: absolute;
		width: 390px;
		height: 4311px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: CaptureSphere Mob;
		--web-view-id: CaptureSphere_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#capTure_25_MobGroup_15085 {
		position: absolute;
		width: 390px;
		height: 495px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_MobRectangle_749 {
		fill: rgba(255,255,255,1);
		stroke: rgba(255,255,255,1);
		stroke-width: 10px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.capTure_25_MobRectangle_749 {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 495px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_MobWe_are_Experienced {
		left: 10px;
		top: 91px;
		position: absolute;
		overflow: visible;
		width: 279px;
		white-space: nowrap;
		text-align: left;
		font-family: Museo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(0,0,0,1);
		letter-spacing: 0.35px;
	}
	#capTure_25_MobPhotographers {
		left: 10px;
		top: 131px;
		position: absolute;
		overflow: visible;
		width: 262px;
		white-space: nowrap;
		line-height: 100px;
		margin-top: -30px;
		text-align: left;
		font-family: Baskerville;
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_MobSubtraction_8 {
		fill: rgba(0,0,0,1);
	}
	.capTure_25_MobSubtraction_8 {
        border-radius: 50%;
		overflow: hidden;
		position: absolute;
		width: 30px;
		height: 30.01px;
		left: 31px;
		top: 25px;
		transform: matrix(1,0,0,1,0,0);
	}
	#capTure_25_MobOperate_Live {
		left: 76px;
		top: 27px;
		position: absolute;
		overflow: visible;
		width: 120px;
		white-space: nowrap;
		text-align: left;
		font-family: Museo;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
		letter-spacing: 0.35px;
	}
	#capTure_25_MobGroup_15077 {
		position: absolute;
		width: 390px;
		height: 336.009px;
		left: 0px;
		top: 153px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_83 {
		position: absolute;
		width: 83.208px;
		height: 142.279px;
		left: 306.792px;
		top: 79.08px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_84 {
		position: absolute;
		width: 214.69px;
		height: 109.568px;
		left: 175.309px;
		top: 226.441px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_85 {
		position: absolute;
		width: 82.573px;
		height: 84.479px;
		left: 219.137px;
		top: 38.016px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_86 {
		position: absolute;
		width: 82.573px;
		height: 90.58px;
		left: 0px;
		top: 245.43px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_87 {
		position: absolute;
		width: 126.401px;
		height: 93.885px;
		left: 175.309px;
		top: 127.475px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_88 {
		position: absolute;
		width: 82.573px;
		height: 153.396px;
		left: 87.655px;
		top: 182.613px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_89 {
		position: absolute;
		width: 83.208px;
		height: 73.998px;
		left: 306.792px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_MobGroup_15084 {
		position: absolute;
		width: 390px;
		height: 1096.896px;
		left: 0px;
		top: 595px;
		overflow: visible;
	}
	#capTure_25_MobRectangle_391 {
		fill: rgba(247,249,251,1);
	}
	.capTure_25_MobRectangle_391 {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 1096.896px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_MobGroup_57 {
		position: absolute;
		width: 321.213px;
		height: 182px;
		left: 22px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_MobWe_are__Professional {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 267px;
		white-space: nowrap;
		text-align: left;
		font-family: Baskerville;
		font-style: italic;
		font-weight: normal;
		font-size: 52px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_Mobphotographers {
		left: 98.213px;
		top: 136px;
		position: absolute;
		overflow: visible;
		width: 224px;
		white-space: nowrap;
		text-align: left;
		font-family: Baskerville;
		font-style: normal;
		font-weight: normal;
		font-size: 34px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_MobScroll_Group_85 {
		mix-blend-mode: normal;
		position: absolute;
		width: 390px;
		height: 308px;
		left: 0px;
		top: 239px;
		overflow: hidden;
	}
	#capTure_25_MobLorem_Ipsum_is_simply_dummy_te {
		opacity: 0.8;
		left: 10px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 381px;
		height: 308px;
		line-height: 24px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Aktiv Grotesk;
		font-style: normal;
		font-weight: lighter;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_MobScroll_Group_86 {
		mix-blend-mode: normal;
		position: absolute;
		width: 380.982px;
		height: 451.246px;
		left: 0.259px;
		top: 585px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#capTure_25_MobGroup_15078 {
		position: absolute;
		width: 935.482px;
		height: 397.746px;
		left: 18px;
		top: 19px;
		overflow: visible;
	}
	#capTure_25_Moboffering_card_1_shadow_space_w {
		position: absolute;
		width: 281.482px;
		height: 397.746px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_Mobcard_bg_offeringshadow_space_w {
		fill: rgba(255,255,255,1);
	}
	.capTure_25_Mobcard_bg_offeringshadow_space_w {
		filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 296.982px;
		height: 413.246px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_Mobhalf_circle_shadow_space_websi {
		fill: rgba(46,46,46,1);
	}
	.capTure_25_Mobhalf_circle_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 22px;
		height: 42px;
		left: 23px;
		top: 18px;
		transform: matrix(1,0,0,1,0,0);
	}
	#capTure_25_Mobcircle_deign_shadow_space_webs {
		fill: rgba(46,46,46,1);
	}
	.capTure_25_Mobcircle_deign_shadow_space_webs {
		position: absolute;
		overflow: visible;
		width: 8.567px;
		height: 8.566px;
		left: 9.791px;
		top: 35.492px;
	}
	#capTure_25_Moboofering_lbl_med_shadow_space_ {
		left: 55px;
		top: 28px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_MobScroll_Group_82 {
		mix-blend-mode: normal;
		position: absolute;
		width: 270px;
		height: 316px;
		left: 6px;
		top: 75px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#capTure_25_Mobintro_lbl_14_web_pc {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 316px;
		line-height: 26px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(51,51,51,1);
	}
	#capTure_25_Moboffering_card_1_shadow_space_w_bz {
		position: absolute;
		width: 281.482px;
		height: 397.746px;
		left: 327px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_Mobcard_bg_offeringshadow_space_w_b {
		fill: rgba(255,255,255,1);
	}
	.capTure_25_Mobcard_bg_offeringshadow_space_w_b {
		filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 296.982px;
		height: 413.246px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_Mobhalf_circle_shadow_space_websi_b {
		fill: rgba(46,46,46,1);
	}
	.capTure_25_Mobhalf_circle_shadow_space_websi_b {
		overflow: visible;
		position: absolute;
		width: 22px;
		height: 42px;
		left: 23px;
		top: 18px;
		transform: matrix(1,0,0,1,0,0);
	}
	#capTure_25_Mobcircle_deign_shadow_space_webs_b {
		fill: rgba(46,46,46,1);
	}
	.capTure_25_Mobcircle_deign_shadow_space_webs_b {
		position: absolute;
		overflow: visible;
		width: 8.567px;
		height: 8.566px;
		left: 9.791px;
		top: 35.492px;
	}
	#capTure_25_Moboofering_lbl_med_shadow_space__b {
		left: 55px;
		top: 28px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_MobScroll_Group_83 {
		mix-blend-mode: normal;
		position: absolute;
		width: 270px;
		height: 316px;
		left: 5px;
		top: 75px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#capTure_25_Mobintro_lbl_14_web_pc_b {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 316px;
		line-height: 26px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(51,51,51,1);
	}
	#capTure_25_Moboffering_card_1_shadow_space_w_b {
		position: absolute;
		width: 281.482px;
		height: 397.746px;
		left: 654px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_Mobcard_bg_offeringshadow_space_w_ca {
		fill: rgba(255,255,255,1);
	}
	.capTure_25_Mobcard_bg_offeringshadow_space_w_ca {
		filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
		position: absolute;
		overflow: visible;
		width: 296.982px;
		height: 413.246px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_Mobhalf_circle_shadow_space_websi_ca {
		fill: rgba(46,46,46,1);
	}
	.capTure_25_Mobhalf_circle_shadow_space_websi_ca {
		overflow: visible;
		position: absolute;
		width: 22px;
		height: 42px;
		left: 23px;
		top: 18px;
		transform: matrix(1,0,0,1,0,0);
	}
	#capTure_25_Mobcircle_deign_shadow_space_webs_ca {
		fill: rgba(46,46,46,1);
	}
	.capTure_25_Mobcircle_deign_shadow_space_webs_ca {
		position: absolute;
		overflow: visible;
		width: 8.567px;
		height: 8.566px;
		left: 9.791px;
		top: 35.492px;
	}
	#capTure_25_Moboofering_lbl_med_shadow_space__ca {
		left: 55px;
		top: 28px;
		position: absolute;
		overflow: visible;
		width: 203px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_MobScroll_Group_84 {
		mix-blend-mode: normal;
		position: absolute;
		width: 270px;
		height: 316px;
		left: 6px;
		top: 75px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#capTure_25_Mobintro_lbl_14_web_pc_cc {
		left: 5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 261px;
		height: 316px;
		line-height: 26px;
		margin-top: -5.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(51,51,51,1);
	}
	#capTure_25_MobGroup_15083 {
		position: absolute;
		width: 390px;
		height: 1754.229px;
		left: 0px;
		top: 1792.5px;
		overflow: visible;
	}
	#capTure_25_MobRectangle_489 {
		fill: rgba(247,249,251,1);
	}
	.capTure_25_MobRectangle_489 {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 1754.229px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_MobEvents_we_Cover {
		left: 55px;
		top: 61.064px;
		position: absolute;
		overflow: visible;
		width: 282px;
		white-space: nowrap;
		text-align: left;
		font-family: Baskerville;
		font-style: italic;
		font-weight: normal;
		font-size: 40px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_MobGroup_15079 {
		position: absolute;
		width: 331.291px;
		height: 1395.615px;
		left: 32px;
		top: 204.043px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_106 {
		position: absolute;
		width: 307.353px;
		height: 272.07px;
		left: 9.323px;
		top: 528.053px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_108 {
		position: absolute;
		width: 307.353px;
		height: 181.902px;
		left: 9.323px;
		top: 837.561px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_111 {
		position: absolute;
		width: 163px;
		height: 266.172px;
		left: 0px;
		top: 252.402px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_112 {
		position: absolute;
		width: 235.219px;
		height: 338.715px;
		left: 9.323px;
		top: 1056.9px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_115 {
		position: absolute;
		width: 122.314px;
		height: 235.219px;
		left: 208.977px;
		top: 258.402px;
		overflow: visible;
	}
	#capTure_25_MobMask_Group_116 {
		position: absolute;
		width: 235.219px;
		height: 217.971px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_MobGroup_60 {
		position: absolute;
		width: 239px;
		height: 50px;
		left: 76px;
		top: 1660.5px;
		overflow: visible;
	}
	#capTure_25_MobRectangle_196 {
		fill: rgba(0,0,0,1);
	}
	.capTure_25_MobRectangle_196 {
		filter: drop-shadow(0px 15px 20px rgba(0, 0, 0, 0.078));
		position: absolute;
		overflow: visible;
		width: 299px;
		height: 110px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_MobGroup_15080 {
		position: absolute;
		width: 211.049px;
		height: 22px;
		left: 14px;
		top: 14px;
		overflow: visible;
	}
	#capTure_25_MobLoad_more_Images {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 144px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Baskerville;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(255,255,255,1);
		letter-spacing: 0.15px;
	}
	#capTure_25_MobGroup_13 {
		position: absolute;
		width: 20px;
		height: 13.098px;
		left: 191.049px;
		top: 4.471px;
		overflow: visible;
	}
	#capTure_25_MobPath_13 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.capTure_25_MobPath_13 {
		overflow: visible;
		position: absolute;
		width: 13.098px;
		height: 6.549px;
		transform: translate(0px, 0px) matrix(1,0,0,1,10.1763,3.2744) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#capTure_25_MobLine_14 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.capTure_25_MobLine_14 {
		overflow: visible;
		position: absolute;
		width: 19.451px;
		height: 1px;
		left: 0px;
		top: 6.549px;
		transform: matrix(1,0,0,1,0,0);
	}
	#capTure_25_MobGroup_15082 {
		position: absolute;
		width: 390px;
		height: 664px;
		left: 0px;
		top: 3647px;
		overflow: visible;
	}
	#capTure_25_MobRectangle_747 {
		fill: rgba(255,255,255,1);
		stroke: rgba(255,255,255,1);
		stroke-width: 10px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.capTure_25_MobRectangle_747 {
		position: absolute;
		overflow: visible;
		width: 390px;
		height: 664px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_MobGroup_15081 {
		position: absolute;
		width: 390px;
		height: 279px;
		left: 0px;
		top: 33px;
		overflow: visible;
	}
	#capTure_25_Mobkisspng-paper-area-rectangle-s {
		position: absolute;
		width: 390px;
		height: 279px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_MobRectangle_748 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.capTure_25_MobRectangle_748 {
		position: absolute;
		overflow: visible;
		width: 314px;
		height: 209px;
		left: 38px;
		top: 32px;
	}
	#capTure_25_MobGroup_15023 {
		position: absolute;
		width: 347px;
		height: 342px;
		left: 22px;
		top: 312px;
		overflow: visible;
	}
	#capTure_25_Mobsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 80px;
		top: 213px;
		overflow: visible;
	}
	#capTure_25_Moblinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_Mobmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.capTure_25_Mobmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_Moblinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.191px;
		overflow: visible;
	}
	#capTure_25_Mobn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.capTure_25_Mobn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#capTure_25_Moblinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.capTure_25_Moblinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#capTure_25_Mobtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.capTure_25_Mobtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#capTure_25_MobInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_Mobshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.capTure_25_Mobshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_Mobinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#capTure_25_Mobwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_Mobmed_shadow_space_website_7_db {
		fill: rgba(255,255,255,1);
	}
	.capTure_25_Mobmed_shadow_space_website_7_db {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#capTure_25_Mobwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.986px;
		left: 11.993px;
		top: 11.992px;
		overflow: visible;
	}
	#capTure_25_Mobn_234567890_mobile_number_shad {
		left: 48px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_Mobaffanghanioperatelive_email_sh {
		left: 40px;
		top: 61px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_Mobaddress_med_shadow_space_websi {
		left: 35px;
		top: 120px;
		position: absolute;
		overflow: visible;
		width: 313px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#capTure_25_Moblocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 120px;
		overflow: visible;
	}
	#capTure_25_Mobemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 60px;
		overflow: visible;
	}
	#capTure_25_Mobphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#capTure_25_Mobn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 298px;
		position: absolute;
		overflow: visible;
		width: 348px;
		height: 44px;
		line-height: 26px;
		margin-top: -6.5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(173,173,173,1);
	}
	#capTure_25_MobVisit_Us_ {
		left: 159px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		line-height: 26px;
		margin-top: -4.5px;
		text-align: left;
		font-family: Baskerville;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
		letter-spacing: 0.15px;
	}