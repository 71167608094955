	.mediaViewInfo {
		--web-view-name: True Vision;
		--web-view-id: True_Vision;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: True_Vision;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#True_Vision {
		position: absolute;
		width: 1366px;
		height: 3496px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: True Vision;
		--web-view-id: True_Vision;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#TVRectangle_760 {
		fill: rgba(242,209,192,1);
	}
	.TVRectangle_760 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 40px;
		left: 0px;
		top: 2121px;
	}
	#TVRectangle_761 {
		fill: rgba(242,209,192,1);
	}
	.TVRectangle_761 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 40px;
		left: 0px;
		top: 2179px;
	}
	#TVRectangle_756 {
		fill: rgba(242,209,192,1);
	}
	.TVRectangle_756 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 40px;
		left: 0px;
		top: 1123px;
	}
	#TVRectangle_759 {
		fill: rgba(242,209,192,1);
	}
	.TVRectangle_759 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 40px;
		left: 0px;
		top: 1622px;
	}
	#TVRectangle_757 {
		fill: rgba(242,209,192,1);
	}
	.TVRectangle_757 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 40px;
		left: 0px;
		top: 1181px;
	}
	#TVRectangle_758 {
		fill: rgba(242,209,192,1);
	}
	.TVRectangle_758 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 40px;
		left: 0px;
		top: 1680px;
	}
	#TVGroup_15167 {
		position: absolute;
		width: 1366px;
		height: 911px;
		left: 0px;
		top: -216px;
		overflow: visible;
	}
	#TVvolodymyr-hryshchenko-NQQ_WKbj {
		position: absolute;
		width: 1366px;
		height: 911px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVAnime_Edit {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 61px;
		top: 261px;
		overflow: visible;
	}
	#TVYour_Business_Name {
		left: 146px;
		top: 278px;
		position: absolute;
		overflow: visible;
		width: 184px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#TVSee_the_world_through_a_cleare {
		left: 61px;
		top: 381px;
		position: absolute;
		overflow: visible;
		width: 1066px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 56px;
		color: rgba(255,255,255,1);
	}
	#TVGroup_15118 {
		position: absolute;
		width: 1055px;
		height: 1400px;
		left: 156px;
		top: 820px;
		overflow: visible;
	}
	#TVGroup_15114 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVjoshua-newton-g4_IymCiD-k-unsp {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 462px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TVIt_is_a_long_established_fact_ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#TVVISION {
		left: 462px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVGroup_15115 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 500px;
		overflow: visible;
	}
	#TVfelipepelaquim-0q5Y7OFmMhM-uns {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 655px;
		top: 0px;
		overflow: visible;
	}
	#TVScroll_Group_28_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 0px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TVIt_is_a_long_established_fact__ {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#TVMISSION {
		left: 0px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVGroup_15116 {
		position: absolute;
		width: 1055px;
		height: 400px;
		left: 0px;
		top: 1000px;
		overflow: visible;
	}
	#TVdmitry-ratushny-wpi3sDUrSEk-un {
		position: absolute;
		width: 400px;
		height: 400px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVScroll_Group_28_bb {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 152px;
		left: 462px;
		top: 157px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#TVIt_is_a_long_established_fact__bc {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#TVPRINCIPLES {
		left: 462px;
		top: 92px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVGroup_15119 {
		position: absolute;
		width: 1341px;
		height: 463px;
		left: 13px;
		top: 2369px;
		overflow: visible;
	}
	#TVScroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1341px;
		height: 386px;
		left: 0px;
		top: 77px;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#TVn_ {
		position: absolute;
		width: 307px;
		height: 342px;
		left: 25px;
		top: 21px;
		overflow: visible;
	}
	#TVMask_Group_22 {
		position: absolute;
		width: 307px;
		height: 311px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVRectangle_664 {
		fill: rgba(0,0,0,1);
	}
	.TVRectangle_664 {
		position: absolute;
		overflow: visible;
		width: 307px;
		height: 46px;
		left: 0px;
		top: 296px;
	}
	#TVCOSMOS {
		left: 9px;
		top: 309px;
		position: absolute;
		overflow: visible;
		width: 293px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(255,255,255,1);
		text-transform: uppercase;
	}
	#TVGroup_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 131px;
		top: 268px;
		overflow: visible;
	}
	#TVEllipse_38 {
		fill: rgba(255,255,255,1);
	}
	.TVEllipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#TVEllipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.TVEllipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#TVEllipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.TVEllipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#TVEllipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.TVEllipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#TVEllipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.TVEllipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#TVWIDE_RANGE_OF_DESIGN {
		left: 553px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 236px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVGroup_15101 {
		position: absolute;
		width: 1175px;
		height: 487.998px;
		left: 95.5px;
		top: 2981px;
		overflow: visible;
	}
	#TVGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 0px;
		top: 114.997px;
		overflow: visible;
	}
	#TVGroup_15040 {
		position: absolute;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.TVRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 538px;
		height: 302px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#TVsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#TVlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#TVmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TVmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TVlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#TVn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.TVn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TVlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.TVlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TVtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.TVtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TVInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#TVshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.TVshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TVinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#TVwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#TVmed_shadow_space_website_7_b {
		fill: rgba(255,255,255,1);
	}
	.TVmed_shadow_space_website_7_b {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#TVwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#TVn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#TVlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#TVemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#TVphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#TVn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#TVContact_Us {
		left: 510.5px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 154px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
    