
.mediaViewInfo {
    --web-view-name: web 5 mob ShadowScape;
    --web-view-id: web_5_mob_ShadowScape;
    --web-scale-on-resize: true;
    --web-center-horizontally: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: web_5_mob_ShadowScape;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#web_5_mob_ShadowScape {
    position: absolute;
    width: 375px;
    height: 3174px;
    left:calc((100vw - 375px)/2);
    background-color: rgba(25,25,25,1);
    overflow: hidden;
    --web-view-name: web 5 mob ShadowScape;
    --web-view-id: web_5_mob_ShadowScape;
    --web-scale-on-resize: true;
    --web-center-horizontally: true;
    --web-enable-deep-linking: true;
}
#shadow_n_th_shadow_space_website_5_mo {
    filter: blur(50px);
    fill: rgba(255,255,255,0.149);
}
.shadow_n_th_shadow_space_website_5_mo {
    overflow: visible;
    position: absolute;
    width: 1885.334px;
    height: 1403.135px;
    left: -188.548px;
    top: 2097.434px;
    transform: matrix(1,0,0,1,0,0);
}
#shadow_n_rd_shadow_space_website_5_mo {
    filter: blur(50px);
    fill: rgba(255,255,255,0.149);
}
.shadow_n_rd_shadow_space_website_5_mo {
    overflow: visible;
    position: absolute;
    width: 1377.635px;
    height: 915.344px;
    transform: matrix(1,0,0,1,-148.6987,733.6591) rotate(-10deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#shadow_n_nd_shadow_space_website_5_mo {
    filter: blur(50px);
    fill: rgba(255,255,255,0.149);
}
.shadow_n_nd_shadow_space_website_5_mo {
    overflow: visible;
    position: absolute;
    width: 1120.058px;
    height: 961.238px;
    left: -72.725px;
    top: 441.268px;
    transform: matrix(1,0,0,1,0,0);
}
#shadow_top_design_shadow_space_websit {
    filter: blur(50px);
    fill: rgba(255,255,255,0.149);
}
.shadow_top_design_shadow_space_websit {
    overflow: visible;
    position: absolute;
    width: 835.086px;
    height: 734.371px;
    left: -92.541px;
    top: -106.562px;
    transform: matrix(1,0,0,1,0,0);
}
#shadow_top_deafult_pic_img {
    position: absolute;
    width: 221px;
    height: 234px;
    left: 154px;
    top: 44px;
    overflow: visible;
}
#shadow_top_gold_shadow_space_website_ {
    fill: rgba(227,168,87,1);
}
.shadow_top_gold_shadow_space_website_ {
    position: absolute;
    overflow: visible;
    width: 164px;
    height: 184px;
    left: 57px;
    top: 0px;
}
#shadow_down_white_shadow_space_websit {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.shadow_down_white_shadow_space_websit {
    position: absolute;
    overflow: visible;
    width: 165px;
    height: 185px;
    left: 0px;
    top: 49px;
}
#shadow_damon-shadow_space_website_5_m {
    filter: blur(0px);
    position: absolute;
    width: 164px;
    height: 185px;
    left: 29px;
    top: 25px;
    overflow: visible;
}
#shadow_profileshadow_space_website_5_ {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 16px;
    top: 78px;
    border-radius: 50%;
    overflow: hidden;
}
#shadow_Heading_shadow_space_website_5 {
    left: 8px;
    top: 172px;
    position: absolute;
    overflow: visible;
    width: 147px;
    height: 114px;
    line-height: 35px;
    margin-top: -7.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#shadow_Scroll_shadow_space_website_5_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 605px;
    height: 152px;
    left: 0px;
    top: 301px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#shadow_intro_lbl_website_3 {
    left: 7px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 361px;
    height: 137px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
    letter-spacing: 0.1px;
}
#shadow_what_weshadow_space_website_5_ {
    left: 97px;
    top: 646px;
    position: absolute;
    overflow: visible;
    width: 183px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#shadow_Scroll_mission_shadow_space_we {
    mix-blend-mode: normal;
    position: absolute;
    width: 646px;
    height: 127px;
    left: 102.25px;
    top: 770px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#shadow_intro_2_website_3 {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 261px;
    height: 127px;
    line-height: 26px;
    margin-top: -5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255,255,255,1);
}
#shadow_mission_lbl_shadow_space_websi {
    left: 192.25px;
    top: 721px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: rgba(255,255,255,1);
}
#shadow_Icon_2_grp_shadow_space_websit {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 21.75px;
    top: 722px;
    overflow: visible;
}
#shadow_bg_blue_design {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.shadow_bg_blue_design {
    filter: drop-shadow(0px 1.852px 3.148px rgba(77, 223, 253, 0.016));
    position: absolute;
    overflow: visible;
    width: 82.444px;
    height: 82.445px;
    left: 0px;
    top: 0px;
}
#shadow_leadership_2_shadow_space_webs {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 32.25px;
    top: 732.5px;
    overflow: visible;
}
#shadow_Scroll_vision_shadow_space_web {
    mix-blend-mode: normal;
    position: absolute;
    width: 697px;
    height: 153px;
    left: 97px;
    top: 1010px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#shadow_intro_website_3 {
    left: 0px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 266px;
    height: 152px;
    line-height: 26px;
    margin-top: -5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255,255,255,1);
}
#shadow_Vision_shadow_space_website_5_ {
    left: 187px;
    top: 960px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: rgba(255,255,255,1);
}
#shadow_Icon_grp_shadow_space_website_ {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 22px;
    top: 957px;
    overflow: visible;
}
#shadow_Fill_11_website_3 {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.shadow_Fill_11_website_3 {
    filter: drop-shadow(0px 1.852px 3.148px rgba(150, 114, 255, 0.016));
    position: absolute;
    overflow: visible;
    width: 82.444px;
    height: 82.445px;
    left: 0px;
    top: 0px;
}
#shadow_target_1_shadow_space_website_ {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 33.5px;
    top: 966.5px;
    overflow: visible;
}
#shadow_Scroll_princi_shadow_space_web {
    mix-blend-mode: normal;
    position: absolute;
    width: 675px;
    height: 150px;
    left: 102.75px;
    top: 1272px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#shadow_intro_lbl_website_3_cj {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 261px;
    height: 148px;
    line-height: 26px;
    margin-top: -5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255,255,255,1);
}
#shadow_principles_lbl_shadow_space_we {
    left: 183.25px;
    top: 1223px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: rgba(255,255,255,1);
}
#shadow_Fill_shadow_space_website_5_mo {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.shadow_Fill_shadow_space_website_5_mo {
    filter: drop-shadow(0px 1.852px 3.148px rgba(242, 184, 236, 0.016));
    position: absolute;
    overflow: visible;
    width: 82.444px;
    height: 82.445px;
    left: 13.25px;
    top: 1223.5px;
}
#shadow_principle_2_shadow_space_websi {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 20.75px;
    top: 1234px;
    overflow: visible;
}
#shadow_oofering_lbl_shadow_space_webs {
    left: 95px;
    top: 1482px;
    position: absolute;
    overflow: visible;
    width: 187px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(255,255,255,1);
}
#shadow_Scroll_offering_space_mob_5 {
    mix-blend-mode: normal;
    position: absolute;
    width: 341.57px;
    height: 315.077px;
    left: 21.68px;
    top: 1540.17px;
    display: -webkit-box;
    overflow-y: hidden;
    overflow-x: auto;
}
#shadow_space_grp_web_mob_5 {
    position: relative;
    width: 190.07px;
    height: 268.577px;
    
    top: 13px;
    margin: 10px;
    overflow: visible;
}
#shadow_bdr_offering_mob {
    fill: rgba(255,255,255,1);
}
.shadow_bdr_offering_mob {
    filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
    position: absolute;
    overflow: visible;
    width: 205.57px;
    height: 284.076px;
    left: 0px;
    top: 0px;
}
#shadow_half_sun_spave_mob {
    fill: rgba(46,46,46,1);
}
.shadow_half_sun_spave_mob {
    overflow: visible;
    position: absolute;
    width: 15.194px;
    height: 28.924px;
    left: 15.701px;
    top: 12.396px;
    transform: matrix(1,0,0,1,0,0);
}
#shadow_dot_design_carrd_offering {
    fill: rgba(46,46,46,1);
}
.shadow_dot_design_carrd_offering {
    position: absolute;
    overflow: visible;
    width: 5.785px;
    height: 5.785px;
    left: 6.611px;
    top: 23.965px;
}
#shadow_oofering_lbl_med_web_4_pc {
    left: 9.503px;
    top: 241.307px;
    position: absolute;
    overflow: visible;
    width: 170px;
    height: 22px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#shadow_markus-img_offerings {
    position: absolute;
    width: 169.272px;
    height: 223.857px;
    left: 9.503px;
    top: 9.23px;
    overflow: visible;
}
#shadow_oofering_lbl_shadow_space_webs_cv {
    left: 116px;
    top: 1914px;
    position: absolute;
    overflow: visible;
    width: 145px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(255,255,255,1);
}
#shadow_Scroll_event_vgrp_mob_web_5 {
    mix-blend-mode: normal;
    position: absolute;
    width: 356.5px;
    height: 554.5px;
    left: 10px;
    top: 1996px;
    display: -webkit-box;
    overflow-x: auto;
    overflow-y: hidden;
}
#shadow_event_grp_shadow_space_website {
    position: relative;
    width: 287px;
    height: 507px;
    left: 6px;
    top: 8px;
    margin: 10px;
    overflow: visible;
}
#shadow_event_bg_card_1_shadow_space_w {
    fill: rgba(255,255,255,1);
}
.shadow_event_bg_card_1_shadow_space_w {
    filter: drop-shadow(8px 8px 5px rgba(255, 255, 255, 0.729));
    width: 442px;
    height: 286px;
    position: absolute;
    overflow: visible;
    transform: translate(-43px, -1974px) matrix(1,0,0,1,-35,2117) rotate(90deg);
    transform-origin: center;
}
#shadow_Anime_Edit_shadow_space_websit {
    position: absolute;
    width: 230px;
    height: 210px;
    left: 28px;
    top: 0px;
    overflow: visible;
}
#shadow_oofering_lblshadow_space_websi {
    left: 0px;
    top: 217px;
    position: absolute;
    overflow: visible;
    width: 287px;
    height: 22px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#shadow_Scrolldesc_shadow_space_websit {
    mix-blend-mode: normal;
    position: absolute;
    width: 279px;
    height: 139px;
    left: 7px;
    top: 368px;
    overflow: hidden;
}
#shadow_oofering_lbl_shadow_space_webs_dh {
    left: 4px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 275px;
    height: 158px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#shadow_date_grp_mob_web_5 {
    position: absolute;
    width: 119px;
    height: 24px;
    left: 28px;
    top: 293px;
    overflow: visible;
}
#shadow_dateshadow_space_website_5_mob {
    left: 29px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 91px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#shadow_calendar_2_shadow_space_websit {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#shadow_time_grp_mob_web_5 {
    position: absolute;
    width: 145px;
    height: 24px;
    left: 142px;
    top: 293px;
    overflow: visible;
}
#shadow_date_shadow_space_website_5_mo {
    left: 28px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 118px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#shadow_clock_1_shadow_space_website_5 {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#shadow_name_grp_mob_web_5 {
    position: absolute;
    width: 207px;
    height: 24px;
    left: 28px;
    top: 252px;
    overflow: visible;
}
#shadow_man_2_shadow_space_website_5_m {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#shadow_date_shadow_space_website_5_mo_dq {
    left: 29px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 179px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#shadow_seats_grp_mob_web_5 {
    position: absolute;
    width: 123px;
    height: 24px;
    left: 28px;
    top: 327px;
    overflow: visible;
}
#shadow_seats_shadow_space_website_5_m {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#shadow_date_shadow_space_website_5_mo_dt {
    left: 30px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 94px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#shadow_price_grp_mob_web_5 {
    position: absolute;
    width: 112px;
    height: 24px;
    left: 142px;
    top: 327px;
    overflow: visible;
}
#shadow_price-tag_1_shadow_space_websi {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#shadow_date_shadow_space_website_5_mo_dw {
    left: 28px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 85px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#shadow_fotter_line_shadow_space_websi {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.shadow_fotter_line_shadow_space_websi {
    overflow: visible;
    position: absolute;
    width: 323px;
    height: 3px;
    left: 26.5px;
    top: 2649px;
    transform: matrix(1,0,0,1,0,0);
}
#shadow_n_234567890_shadow_space_websi {
    left: 60px;
    top: 2694px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#shadow_affanghanioperatelive_shadow_s {
    left: 60px;
    top: 2748px;
    position: absolute;
    overflow: visible;
    width: 167px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#shadow_address_shadow_space_website_5 {
    left: 60px;
    top: 2802px;
    position: absolute;
    overflow: visible;
    width: 280px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#shadow_location_img_shadow_space_webs {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 16px;
    top: 2796px;
    overflow: visible;
}
#shadow_email_address_shadow_space_web {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 16px;
    top: 2742px;
    overflow: visible;
}
#shadow_phone_number_shadow_space_webs {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 16px;
    top: 2688px;
    overflow: visible;
}
#shadow_Connect_with_us_and_unlock_lim {
    left: 53px;
    top: 2988px;
    position: absolute;
    overflow: visible;
    width: 70%;
    height: 60px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #fff;
}
#shadow_socials_grp_shadow_space_websi {
    position: absolute;
    width: 210px;
    height: 50.001px;
    left: 76px;
    top: 2896px;
    overflow: visible;
}
#shadow_linked_in_grp_shadow_space_web {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 160px;
    top: 0px;
    overflow: visible;
}
#shadow_Ellipse_5shadow_space_website_:hover {
    fill: rgba(120,120,255,1);
}
.shadow_Ellipse_5shadow_space_website_:hover {
    filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
    width: 98px;
    height: 98px;
}
#shadow_Ellipse_5shadow_space_website_ {
    fill: rgba(255,255,255,1);
    transition: all .3s ease-out;
}
.shadow_Ellipse_5shadow_space_website_ {
    filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 59px;
    height: 59px;
    left: 0px;
    top: 0px;
    transition: all .3s ease-out;
}
#shadow_linked_in_shadow_space_website {
    position: absolute;
    width: 24.349px;
    height: 22.135px;
    left: 13.125px;
    top: 13.75px;
    overflow: visible;
}
#shadow_n_website_2_layout {
    fill: rgba(63,61,86,1);
}
.shadow_n_website_2_layout {
    overflow: visible;
    position: absolute;
    width: 16.092px;
    height: 15.182px;
    left: 8.257px;
    top: 6.953px;
    transform: matrix(1,0,0,1,0,0);
}
#shadow_linked_in_i_down {
    fill: rgba(63,61,86,1);
}
.shadow_linked_in_i_down {
    overflow: visible;
    position: absolute;
    width: 4.746px;
    height: 15.037px;
    left: 0.614px;
    top: 7.098px;
    transform: matrix(1,0,0,1,0,0);
}
#shadow_top_dot_i_website_2_layout {
    fill: rgba(63,61,86,1);
}
.shadow_top_dot_i_website_2_layout {
    overflow: visible;
    position: absolute;
    width: 5.761px;
    height: 5.217px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#shadow_Insta_grp_med_shadow_space_web {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 80px;
    top: 0px;
    overflow: visible;
}
#shadow_Ellipseshadow_space_website_5_:hover {
    fill: rgba(255,120,120,1);
}
.shadow_Ellipseshadow_space_website_5_:hover {
    filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
    width: 98px;
    height: 98px;
}
#shadow_Ellipseshadow_space_website_5_ {
    fill: rgba(255,255,255,1);
    transition: all .3s ease-out;
}
.shadow_Ellipseshadow_space_website_5_ {
    filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 59px;
    height: 59px;
    left: 0px;
    top: 0px;
    transition: all .3s ease-out;
}
#shadow_instagram_shadow_space_website {
    position: absolute;
    width: 24.375px;
    height: 24.375px;
    left: 13.125px;
    top: 13.125px;
    overflow: visible;
}
#shadow_whatsapp_grpshadow_space_websi {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#shadow_Ellipse_3_shadow_space_website:hover {
    fill: rgba(50,172,158,1);
}
.shadow_Ellipse_3_shadow_space_website:hover {
    filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
    width: 98px;
    height: 98px;
}
#shadow_Ellipse_3_shadow_space_website {
    fill: rgba(255,255,255,1);
    transition: all .3s ease-out;
}
.shadow_Ellipse_3_shadow_space_website {
    filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 59px;
    height: 59px;
    left: 0px;
    top: 0px;
    transition: all .3s ease-out;
}
#shadow_whatsapp_lbl_shadow_space_webs {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 12.5px;
    top: 12.5px;
    overflow: visible;
}
#shadow_quote_left_shadow_space_websit {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 15px;
    top: 2987px;
    overflow: visible;
}
#shadow_n_Copyright_Operate_Live_shado {
    left: 16px;
    top: 3093.25px;
    position: absolute;
    overflow: visible;
    width: 318px;
    white-space: nowrap;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#shadow_quote_right_med_shadow_space_w_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    right: 35px;
    top: 3020px;
    overflow: visible;
}
#shadow_Partners_grp_shadow_space_webs {
    position: absolute;
    width: 338px;
    height: 118px;
    left: 12px;
    top: 496px;
    overflow: visible;
}
#shadow_text_our_partnersshadow_space_ {
    left: 12px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 181px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(255,255,255,1);
    letter-spacing: 0.1px;
}
#shadow_Scroll_partner_shadow_space_we_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 338px;
    height: 79px;
    left: 0px;
    top: 39px;
    display: inline-flex;
    overflow-x: auto;
    overflow-y: hidden;
}
#shadow_Scroll_partner_shadow_space_we_mob::-webkit-scrollbar{
    display: none;
}
#shadow_partner_lbls_shadow_space_webs_mob {
    position: relative;
    width: 175px;
    height: 27px;
    left: 12px;
    top: 7px;
    overflow: visible;
}
#shadow_google_lbl_partner_shadow_spac_mob {
    left: 30px;
    top: 0px;
    position: absolute;
    overflow: hidden;
    width: 146px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#shadow_google_1_shadow_space_website_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 0px;
    top: 1px;
    overflow: visible;
}
 
 /* Menu */
 #menuToggle
 {
 display: block;
 position: fixed;
 width: 40px;
 height: 40px;
 left: 34px;
 top: 25px;
 
 z-index: 1;
 
 -webkit-user-select: none;
 user-select: none;
 }
 
 #menuToggle li
 {
 text-decoration: none;
 color: #232323;
 
 transition: color 0.3s ease;
 }
 
 #menuToggle li:hover
 {
 color: #ff4f4f;
 }
 
 
 #menuToggle input
 {
 display: block;
 width: 40px;
 height: 32px;
 position: absolute;
 top: -7px;
 left: -5px;
 
 cursor: pointer;
 
 opacity: 0; /* hide this */
 z-index: 2; /* and place it over the hamburger */
 
 -webkit-touch-callout: none;
 }
 
 /*
 * Just a quick hamburger
 */
 #menuToggle span
 {
 display: block;
 width: 33px;
 height: 4px;
 margin-bottom: 5px;
 position: relative;
 
 background: #6495ED;
 border-radius: 3px;
 
 z-index: 1;
 
 transform-origin: 4px 0px;
 
 transition: transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
 background: 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
 opacity: 0.55s ease;
 }
 
 #menuToggle span:first-child
 {
 transform-origin: 0% 0%;
 }
 
 #menuToggle span:nth-last-child(2)
 {
 transform-origin: 0% 100%;
 }
 
 /* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
 #menuToggle input:checked ~ span
 {
 opacity: 1;
 transform: rotate(45deg) translate(-2px, -1px);
 background: rgba(255,75,83,1);
 }
 
 /*
 * But let's hide the middle one.
 */
 #menuToggle input:checked ~ span:nth-last-child(3)
 {
 opacity: 0;
 transform: rotate(0deg) scale(0.2, 0.2);
 }
 
 /*
 * Ohyeah and the last one should go the other direction
 */
 #menuToggle input:checked ~ span:nth-last-child(2)
 {
 transform: rotate(-45deg) translate(0, -1px);
 }
 
 /*
 * Make this absolute positioned
 * at the top left of the screen
 */
 #menu
 {
 position: absolute;
 width: 230px;
 margin: -100px 0 0 -50px;
 padding: 50px;
 padding-top: 125px;
 height: 100vh;
 background: #ededed;
 list-style-type: none;
 -webkit-font-smoothing: antialiased;
 /* to stop flickering of text in safari */
 transform-origin: 0% 0%;
 transform: translate(-100%, 0);
 transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
 background: rgba( 255, 255, 255, 0.25 );
 box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
 backdrop-filter: blur( 10px );
 -webkit-backdrop-filter: blur( 10px );
 border-radius: 10px;
 border: 1px solid rgba( 255, 255, 255, 0.18 );
 }
 
 #menu li
 {
 padding: 10px 0;
 font-size: 22px;
 }
 #menuToggle input:checked ~ ul
 {
 transform: none;
 }
 