.mediaViewInfo {
    --web-view-name: req feeed;
    --web-view-id: req_feeed;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: req_feeed;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#req_feeed {
    position: relative;
    width: 100%;
    height: 257px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: req feeed;
    --web-view-id: req_feeed;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#req_feeed_grp {
    position: absolute;
    width: 100%;
    height: 251.209px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#req_feed_bdr {
    fill: rgba(255,255,255,1);
}
.req_feed_bdr {
    filter: drop-shadow(5px 5px 2px rgba(135, 207, 172, 0.651));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 259px;
    left: 0px;
    top: 0px;
}
#Requirements_Feed_lbl {
    left: 20px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 136px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(37,46,72,1);
}
#req_feed_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 250.778px;
    left: 0px;
    top: 0.431px;
    display: inline-flex;
    overflow-x: scroll;
    white-space: nowrap;
    overflow-y: hidden;
}
#card_1_grp_profile_req {
    position: relative;
    width: 187px;
    height: 166px;
    left: 17px;
    top: 47.569px;
    overflow: visible;
}
#req_feed_1_card {
    position: absolute;
    width: 187px;
    height: 125.35px;
    left: 0px;
    top: 19.879px;
    object-fit: contain;
    overflow: hidden;
}
#Offering_1_lbl {
    left: 58px;
    top: 149px;
    position: absolute;
    overflow: visible;
    width: 59px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,46,72,1);
}
#Mohammed_Affan_lbl {
    left: 36px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 105px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,46,72,1);
}