
	.mediaViewInfo {
		--web-view-name: side menu bar pc;
		--web-view-id: side_menu_bar_pc;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: side_menu_bar_pc;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#side_menu_bar_pc {
		position: fixed;
		width: 246px;
		height: 100vh;
         z-index: 100;
        background-color: rgba(219,242,231,1);
       
        overflow-y: auto;
        overflow-x: hidden;
    
		--web-view-name: side menu bar pc;
		--web-view-id: side_menu_bar_pc;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}

    #side_menu_bar_pc::-webkit-scrollbar {
        display: none;
    }
	#side_nav_bar_border {
		fill: rgba(219,242,231,1);
	}
	.side_nav_bar_border {
		position: absolute;
		overflow: visible;
		width: 246px;
		height: 100%;
		left: 0px;
		top: 0px;
	}
	#side_logo_icon_expand {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 88px;
		top: 28px;
        cursor: pointer;
		overflow: visible;
	}
	#dash_bdr_grp_expand {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 26px;
		top: 113px;
        cursor: pointer;
		overflow: visible;
	}
	#dash_pc {
		fill: rgba(255,255,255,1);
	}
	.dash_pc {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Dashboard_side_lbl_pc {
		left: 51px;
		top: 6px;
		position: absolute;
		overflow: visible;
		width: 57px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#dashboard_Icon_I_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#metting_room_grp_expand {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 26px;
		top: 341px;
        cursor: pointer;
		overflow: visible;
	}
	#meet_pc {
		fill: rgba(255,255,255,1);
	}
	.meet_pc {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Meeting_Room_side_lbl_pc {
		left: 51px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#meeting_room_icon_I_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#portfolio_grp_expand {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 26px;
		top: 170px;
        cursor: pointer;
		overflow: visible;
	}
	#portfolio_pc {
		fill: rgba(255,255,255,1);
	}
	.portfolio_pc {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#portfolio_side_lbl_pc {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 76px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#portfolio_icon_I_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#connection_grp_expand {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 26px;
		top: 227px;
        cursor: pointer;
		overflow: visible;
	}
	#con_pc {
		fill: rgba(255,255,255,1);
	}
	.con_pc {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#My_Connection_side_lbl_pc {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 61px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#my_connections_icon_I_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#market_grpp_expand {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 26px;
		top: 397px;
        cursor: pointer;
		overflow: visible;
	}
	#market_pc_ui {
		fill: rgba(255,255,255,1);
	}
	.market_pc_ui {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Market_side_lbl_pc {
		left: 51px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#market_icon_I_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#event_grp_expand {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 26px;
		top: 454px;
        cursor: pointer;
		overflow: visible;
	}
	#event_pc {
		fill: rgba(255,255,255,1);
	}
	.event_pc {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Events_side_lbl_pc {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 58px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#events_icon_I_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#logout_grp_expand {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 26px;
		top: 511px;
        cursor: pointer;
		overflow: visible;
	}
	#log_pc {
		fill: rgba(255,255,255,1);
	}
	.log_pc {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Logout_side_lbl_pc {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 64px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(4,4,4,1);
	}
	#logout_icon_I_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 6px;
		top: 6px;
		overflow: visible;
	}
	#com_grp_expand {
		position: absolute;
		width: 195px;
		height: 43px;
		left: 26px;
		top: 284px;
        cursor: pointer;
		overflow: visible;
	}
	#com_pc {
		fill: rgba(255,255,255,1);
	}
	.com_pc {
		position: absolute;
		overflow: visible;
		width: 195px;
		height: 43px;
		left: 0px;
		top: 0px;
	}
	#Communities_side_lbl_pc {
		left: 51px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 118px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#community_icon_I_1 {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 7px;
		top: 6px;
		overflow: visible;
	}

    .slide-right{
        animation:slide-right 0.4s linear both
    }
    
    @keyframes slide-right{
        0% {
            transform:translateX(-100px)
        }
        100% {
            transform:translateX(0px)
        }
    }
    