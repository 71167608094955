.mediaViewInfo {
    --web-view-name: pop up intro;
    --web-view-id: pop_up_intro_suggestion;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pop_up_intro;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pop_up_intro_suggestion {
    position: absolute;
    width: 265px;
    height: 324px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    background-color: rgba(255,255,255,1);
    border-radius: 20px;
    overflow: hidden;
    --web-view-name: pop up intro;
    --web-view-id: pop_up_intro_suggestion;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#suggestionBgImgPop {
    position: absolute;
    width: 265px;
    height: 324px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Rectangle_29 {
    fill: rgba(187,248,219,1);
}
.Rectangle_29 {
    position: absolute;
    overflow: visible;
    width: 100px;
    height: 33px;
    left: 83px;
    top: 226px;
}
#suggestions_pfp_name {
    left: 110px;
    top: 233px;
    position: absolute;
    overflow: visible;
    width: 48px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#sug_border_title {
    fill: rgba(219,242,231,1);
}
.sug_border_title {
    width: 118px;
    height: 265px;
    position: absolute;
    overflow: visible;
    transform: matrix(1,0,0,1,73.5,-142.5) rotate(-90deg);
    transform-origin: center;
}
#suggestions_pfp_name_pop {
    left: 59px;
    top: 12px;
    position: absolute;
    overflow: visible;
    width: 148px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(37,37,37,1);
}
#intro_suggestion_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.intro_suggestion_pop {
    position: absolute;
    overflow: visible;
    width: 211px;
    height: 120px;
    left: 27px;
    top: 78px;
    font-size: 13px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}
#mycommunities_pop_sug {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.mycommunities_pop_sug {
    position: absolute;
    overflow: visible;
   
    height: 33px;
    left: 27px;
    top: 162px;
    font-size: 13px;
    padding: 15px;
    border: 2px solid darkcyan;
    border-radius:  5px;
}

.cssbuttons-io-button {
background: #71CBA0;
color: white;
font-family: inherit;
padding: 0.35em;
padding-left: 1.2em;
font-size: 17px;
font-weight: 500;
border-radius: 0.9em;
border: none;
letter-spacing: 0.05em;
display: flex;
align-items: center;
box-shadow: inset 0 0 1.6em -0.6em #71CBA0;
overflow: hidden;
position: relative;
height: 2.8em;
padding-right: 3.3em;
width: 110px;
height: 33px;
left: 73px;
top: 226px;
cursor: pointer;
}
/* 
.cssbuttons-io-button .icon {
background: white;
margin-left: 1em;
position: absolute;
display: flex;
align-items: center;
justify-content: center;
height: 1.5em;
width: 1.5em;
border-radius: 0.7em;
box-shadow: 0.1em 0.1em 0.6em 0.2em #71CBA0;
right: 0.3em;
transition: all 0.3s;
}

.cssbuttons-io-button:hover .icon {
width: calc(100% - 0.6em);
}

.cssbuttons-io-button .icon svg {
width: 1.1em;
transition: transform 0.3s;
color: #71CBA0;
}

.cssbuttons-io-button:hover .icon svg {
transform: translateX(0.1em);
}

.cssbuttons-io-button:active .icon {
transform: scale(0.95);
} */



#close_sugesstion_PopUp_pc {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 88%;
    top:0%;
    margin: 7px;
    cursor: pointer;
    overflow: visible
}