.mediaViewInfo {
    --web-view-name: view more pop up;
    --web-view-id: view_more_pop_up_off;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: view_more_pop_up_off;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#view_more_pop_up_off {
    position: absolute;
    width: 255px;
    height: 353px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 20px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: view more pop up;
    --web-view-id: view_more_pop_up_off;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Group_pop_off_vm {
    position: absolute;
    width: 201px;
    height: 288px;
    left: 30px;
    top: 48px;
    overflow: visible;
}
#bg_vm_pop_pff {
    position: absolute;
    width: 253px;
    height: 351px;
    left: -28px;
    top: -47px;
    overflow: visible;
}
#border_des_vm_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(182,222,134,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.border_des_vm_pop {
    position: absolute;
    overflow: visible;
    width: 167px;
    height: 52px;
    left: 0px;
    top: 224px;
}
#Description_viewPop_off_ {
    left: 2px;
    top: 93px;
    position: absolute;
    overflow: visible;
    width: 82px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: italic;
    /* font-weight: bold; */
    font-size: 15px;
    text-decoration: underline;
    color: rgba(37,37,37,1);
   
}
#border_viewpop_off_ {
    fill: rgba(219,242,231,1);
}
.border_viewpop_off_ {
    position: absolute;
    overflow: visible;
    width: 172px;
    height: 30px;
    left: 16px;
    top: 47px;
}
#Website_Development_title_off_vm {
    left: 24px;
    top: 53px;
    position: absolute;
    overflow: visible;
    width: 157px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    /* text-decoration: underline; */
    color: rgba(37,37,37,1);
}
#Scroll_description_vm_pop_ {
    mix-blend-mode: normal;
    position: absolute;
    width: 200px;
    height: 145px;
    left: 0px;
    top: 114px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#des_a_website_off_vm {
    left: 2px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#My_Community_title_off_vm {
    left: 57px;
    top: 200px;
    position: absolute;
    overflow: visible;
    width: 89px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(37,37,37,1);
    text-decoration: underline;
}
#img_view_pop_off {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 96px;
    top: 13px;
    overflow: visible;
}


#close_Offerings_view_pc {
    position: relative;
    width: 20px;
    height: 20px;
    float: right;
    margin: 7px;
    cursor: pointer;
    overflow: visible;
}