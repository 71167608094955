.mediaViewInfo {
    --web-view-name: payment tab;
    --web-view-id: payment_tab;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: payment_tab;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#payment_tab {
    position: absolute;
    width: 100%;
    height: calc(100% - 185px);
    top: 120px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: payment tab;
    --web-view-id: payment_tab;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#billing_down_holder_mob_eit_pa {
    position: absolute;
    width: 100%;
    height: calc(100% - 35px);
    left: 0px;
    top: 37.671px;
    overflow: visible;
}
#billing_top_holder_mob_eit_pag {
    position: absolute;
    width: 122.703px;
    height: 37.671px;
    left: 252.297px;
    top: 0px;
    overflow: visible;
}
#top_grp_heading_payment {
    position: absolute;
    width: 333px;
    height: 20px;
    left: 20px;
    top: 11.615px;
    overflow: visible;
}
#Generallbl_profile_mob_payment {
    left: 25px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#dossier_lbl_profile_mob_paymen {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Location_lbl_profile_mob_payme {
    left: 143px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#placeholder_lbl_profile_mob_pa {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 117px;
    top: 0px;
    overflow: visible;
}
#Payment_Details_lbl_profile_mo {
    left: 273px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#card_lbl_profile_mob_payment {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 248px;
    top: 0px;
    overflow: visible;
}
#finish_btn_payment_paymet_page {
    position: absolute;
    width: 109px;
    height: 36px;
    left: 35%;
    top: calc(100% - 50px);
    overflow: visible;
}
#finish_bdr_paymet_page_mob {
    fill: rgba(163,233,200,1);
}
.finish_bdr_paymet_page_mob {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 127px;
    height: 54px;
    left: 0px;
    top: 0px;
}
#finish_lbkl_paymet_page_mob {
    left: 38px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 34px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Scroll_payment_tab {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 50px);
    height: calc(100% - 110px);
    left: 45px;
    top: 46px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#payment_grp_all {
    position: absolute;
    width: 252px;
    height: 200px;
    left: 20px;
    top: 380px;
    overflow: visible;
}
#UPI_ID_grp_payment {
    position: absolute;
    width: 252px;
    height: 60px;
    left: 0px;
    top: 140px;
    overflow: visible;
}
#UPI_input_lbl_profile_paymet_p {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.UPI_input_lbl_profile_paymet_p {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 1px;
    top: 20px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#UPI_ID_lbl_profile_paymet_page {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#BC_grp_payment {
    position: absolute;
    width: 252px;
    height: 60px;
    left: 0px;
    top: 70px;
    overflow: visible;
}
#nilling_currency_input_paymet_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.nilling_currency_input_paymet_ {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 1px;
    top: 20px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Billing_Currency_lbl_profile__ {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#HBR_grp_paymen {
    position: absolute;
    width: 252px;
    height: 60px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#HBR_input_lbl_profile_paymet_p {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.HBR_input_lbl_profile_paymet_p {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 1px;
    top: 20px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#Hour_Billing_Rate_lbl_profile_ {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 112px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#business_grp_details {
    position: absolute;
    width: 251px;
    height: 370px;
    left: 21px;
    top: 0px;
    overflow: visible;
}
#UPI_ID_grp_payment_cb {
    position: absolute;
    width: 251px;
    height: 60px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#UPI_input_lbl_profile_paymet_p_cc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.UPI_input_lbl_profile_paymet_p_cc {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 0px;
    top: 20px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#UPI_ID_lbl_profile_paymet_page_cd {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#UPI_ID_grp_payment_ce {
    position: absolute;
    width: 251px;
    height: 90px;
    left: 0px;
    top: 70px;
    overflow: visible;
}
#UPI_input_lbl_profile_paymet_p_cf {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.UPI_input_lbl_profile_paymet_p_cf {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 70px;
    left: 0px;
    top: 20px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#UPI_ID_lbl_profile_paymet_page_cg {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 114px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#UPI_ID_grp_payment_ch {
    position: absolute;
    width: 251px;
    height: 60px;
    left: 0px;
    top: 170px;
    overflow: visible;
}
#UPI_input_lbl_profile_paymet_p_ci {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.UPI_input_lbl_profile_paymet_p_ci {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 0px;
    top: 20px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#UPI_ID_lbl_profile_paymet_page_cj {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 107px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#UPI_ID_grp_payment_ck {
    position: absolute;
    width: 251px;
    height: 60px;
    left: 0px;
    top: 240px;
    overflow: visible;
}
#UPI_input_lbl_profile_paymet_p_cl {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.UPI_input_lbl_profile_paymet_p_cl {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 0px;
    top: 20px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#UPI_ID_lbl_profile_paymet_page_cm {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 97px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#UPI_ID_grp_payment_cn {
    position: absolute;
    width: 251px;
    height: 60px;
    left: 0px;
    top: 310px;
    overflow: visible;
}
#UPI_input_lbl_profile_paymet_p_co {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.UPI_input_lbl_profile_paymet_p_co {
    position: absolute;
    overflow: visible;
    width: 251px;
    height: 40px;
    left: 0px;
    top: 20px;
    border-radius: 5px;
    padding-left: 7px;
    border: solid 1px #A3E9C8;
}
#UPI_ID_lbl_profile_paymet_page_cp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 114px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
