.mediaViewInfo {
    --web-view-name: events page pc new;
    --web-view-id: events_page_pc_new;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-center-horizontally: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: events_page_pc_new;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#events_page_pc_new {
    position: absolute;
    width: calc(100vw - 120px);
    height: 100vh;
    top:60px;
    left:120px;
    justify-content: center;
	background-color: rgba(255,255,255,1);
    overflow-x: hidden;
    overflow-y: scroll;
    --web-view-name: events page pc new;
    --web-view-id: events_page_pc_new;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-center-horizontally: true;
    --web-enable-deep-linking: true;
}

#top_grp_event_pc_page {
    position: absolute;
    width: 100%;
    height: 115px;
    left: calc((100% - 1000px)/2);
    top: 22px;
    overflow: visible;
}
#details_events_new_events_page {
    position: absolute;
    width: 824px;
    height: 116px;
    left: 53px;
    top: 120px;
    overflow: visible;
}
#bdr_of_details {
    fill: rgba(255,255,255,1);
    stroke: rgba(173,243,195,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.bdr_of_details {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 842px;
    height: 134px;
    left: 0px;
    top: 0px;
}
#grp_of_details {
    position: absolute;
    width: 663px;
    height: 64px;
    left: 58px;
    top: 26px;
    overflow: visible;
}
#Events_lbl_new_events_page_pc {
    left: 602px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    line-height: 25px;
    margin-top: -2.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#n_023_new_events_page_pc {
    left: 52px;
    top: 37px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    line-height: 61px;
    margin-top: -20.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#btw_2_new_events_page_pc {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.btw_2_new_events_page_pc {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 64px;
    left: 206px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Completed_Events_new_events_pa {
    left: 270px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 170px;
    white-space: nowrap;
    line-height: 25px;
    margin-top: -2.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#n_323_new_events_page_pc {
    left: 333px;
    top: 37px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    line-height: 61px;
    margin-top: -20.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#btw_1_new_events_page_pc {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.btw_1_new_events_page_pc {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 64px;
    left: 502px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Ongoing_Events_new_events_page {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 149px;
    white-space: nowrap;
    line-height: 25px;
    margin-top: -2.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#n_023_new_events_page_pc_l {
    left: 611px;
    top: 37px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    line-height: 61px;
    margin-top: -20.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4,4,4,1);
}
#event_slecctor_new_events_page {
    position: absolute;
    width: 318px;
    height: 42px;
    left: 345px;
    top: 0px;
    overflow: visible;
}
#req_lbl_new_events_page_pc {
    opacity: 0.92;
    left: 115px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 110px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
    color: rgba(48,47,47,1);
}
#offering_lbl_new_events_page_p {
    opacity: 0.92;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 74px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
    color: rgba(4,4,4,1);
}
#event_llbl_new_events_page_pc {
    opacity: 0.92;
    left: 267px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -5.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
    color: rgba(255,79,4,1);
}
#event_underline_new_events_pag {
    fill: rgba(255,79,4,1);
}
.event_underline_new_events_pag {
    position: absolute;
    overflow: visible;
    width: 51px;
    height: 3px;
    left: 267px;
    top: 39px;
}
#filters_area_new_events_page_mc {
    position: absolute;
    width: 555px;
    height: 30px;
    left: 306px;
    top: 59px;
    overflow: visible;
}
#bdr_new_events_page_pc_md {
    fill: rgba(173,245,206,1);
}
.bdr_new_events_page_pc_md {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 30px;
    left: 425px;
    top: 0px;
}
#Find_Event_new_events_page_pc_me {
    left: 451px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 79px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -6px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(4,4,4,1);
}
#category_grp_pc_mf {
    position: absolute;
    width: 209px;
    height: 30px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#category_input_new_events_page_mg {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.category_input_new_events_page_mg {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 30px;
    left: 79px;
    top: 0px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Category_new_events_page_pc_mh {
    left: 0px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 70px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -3.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#time_cate_gory_pc_mi {
    position: absolute;
    width: 172px;
    height: 30px;
    left: 227px;
    top: 0px;
    overflow: visible;
}
#time_bdr_filter_new_events_pag_mj {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.time_bdr_filter_new_events_pag_mj {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 30px;
    left: 42px;
    top: 0px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#Time_new_events_page_mk {
    left: 0px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 38px;
    white-space: nowrap;
    line-height: 23px;
    margin-top: -3.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#serch_grp_event_new_pc {
    position: absolute;
    width: 713px;
    height: 30px;
    left: 148px;
    top: 124px;
    overflow: visible;
}
#search_space_new_events_page {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.search_space_new_events_page {
    position: absolute;
    overflow: visible;
    width: 563px;
    height: 30px;
    left: 0px;
    top: 0px;
    border: 1px solid #ADF5CE;
    border-radius: 5px;
}
#search_btn_grp_new_events {
    position: absolute;
    width: 130px;
    height: 30px;
    left: 583px;
    top: 0px;
    overflow: visible;
    border-radius: 2px;
}
#find_event_bdr_new_events_page {
    fill: rgba(173,245,206,1);
}
.find_event_bdr_new_events_page {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 30px;
    left: 0px;
    top: 0px;
}
#Find_Event_new_events_page {
    left: 40px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 51px;
    white-space: nowrap;
    line-height: 29px;
    margin-top: -6px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(4,4,4,1);
}
#swtitch_grp_event_mq {
    position: absolute;
    width: 277px;
    height: 21px;
    left: 0px;
    top: 63px;
    overflow: visible;
}
#Myevents_PC_mr {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 73px;
    white-space: nowrap;
    line-height: 27px;
    margin-top: -5.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(17,17,17,1);
}
#Community_eventsPC_ms {
    left: 145px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 133px;
    white-space: nowrap;
    line-height: 27px;
    margin-top: -5.5px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(17,17,17,1);
}
#switch_eent_off_PC_mt {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.switch_eent_off_PC_mt {
    position: absolute;
    overflow: visible;
    width: 43px;
    height: 17px;
    left: 87px;
    top: -10px;
}