	.mediaViewInfo {
		--web-view-name: GamersHub;
		--web-view-id: GamersHub;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: GamersHub;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#GamersHub {
		position: absolute;
		width: 1366px;
		height: 3199px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: GamersHub;
		--web-view-id: GamersHub;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#GamersHub_16_pcGroup_14979 {
		position: absolute;
		width: 1126.71px;
		height: 1199.296px;
		left: 137.506px;
		top: 961.188px;
		overflow: visible;
	}
	#GamersHub_16_pcGroup_14978 {
		position: absolute;
		width: 1093.71px;
		height: 376.812px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcGroup_14972 {
		position: absolute;
		width: 1093.71px;
		height: 376.812px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcScroll_Group_51 {
		mix-blend-mode: normal;
		position: absolute;
		width: 698px;
		height: 269px;
		left: 395.71px;
		top: 107.812px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#GamersHub_16_pcScroll_Group_51::-webkit-scrollbar{
		display: none;
	}
	#GamersHub_16_pcElevate_Your_Space_with_Timele {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 677px;
		height: 249px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcVision {
		left: 406.71px;
		top: 40.812px;
		position: absolute;
		overflow: visible;
		width: 88px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcPath_6955 {
		fill: rgba(92,35,255,1);
	}
	.GamersHub_16_pcPath_6955 {
		overflow: visible;
		position: absolute;
		width: 358.421px;
		height: 124.099px;
		left: 0px;
		top: 146.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pckisspng-twisted-metal-black-pl {
		position: absolute;
		width: 293px;
		height: 293px;
		left: 32.71px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcGroup_14974 {
		position: absolute;
		width: 1056.421px;
		height: 359.756px;
		left: 7.494px;
		top: 438.791px;
		overflow: visible;
	}
	#GamersHub_16_pcGroup_14973 {
		position: absolute;
		width: 698px;
		height: 336px;
		left: 0px;
		top: 23.756px;
		overflow: visible;
	}
	#GamersHub_16_pcScroll_Group_51_c {
		mix-blend-mode: normal;
		position: absolute;
		width: 698px;
		height: 269px;
		left: 0px;
		top: 67px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#GamersHub_16_pcScroll_Group_51_c::-webkit-scrollbar{
		display: none;
	}
	#GamersHub_16_pcElevate_Your_Space_with_Timele_c {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 677px;
		height: 226px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcMission {
		left: 11px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 110px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcPath_6957 {
		fill: rgba(78,187,87,1);
	}
	.GamersHub_16_pcPath_6957 {
		overflow: visible;
		position: absolute;
		width: 358.421px;
		height: 124.099px;
		left: 698px;
		top: 110.443px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pckisspng-computer-keyboard-cors {
		position: absolute;
		width: 305px;
		height: 220.886px;
		left: 739.217px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcGroup_14975 {
		position: absolute;
		width: 1095.71px;
		height: 336px;
		left: 31px;
		top: 863.296px;
		overflow: visible;
	}
	#GamersHub_16_pcScroll_Group_51_dd {
		mix-blend-mode: normal;
		position: absolute;
		width: 698px;
		height: 269px;
		left: 397.71px;
		top: 67px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#GamersHub_16_pcScroll_Group_51_dd::-webkit-scrollbar{
		display: none;
	}
	#GamersHub_16_pcElevate_Your_Space_with_Timele_de {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 677px;
		height: 249px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcPrinciples {
		left: 408.71px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 138px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcPath_6956 {
		fill: rgba(78,187,87,1);
	}
	.GamersHub_16_pcPath_6956 {
		overflow: visible;
		position: absolute;
		width: 358.421px;
		height: 124.099px;
		left: 0px;
		top: 112.194px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcMy_project {
		position: absolute;
		width: 305px;
		height: 171.25px;
		left: 26.71px;
		top: 30.25px;
		overflow: visible;
	}
	#GamersHub_16_pcEllipse_63 {
		filter: blur(9px);
		fill: rgba(0,0,0,1);
	}
	.GamersHub_16_pcEllipse_63 {
		position: absolute;
		overflow: visible;
		width: 349px;
		height: 66px;
		left: 23.494px;
		top: 174.243px;
	}
	#GamersHub_16_pcGroup_14984 {
		position: absolute;
		width: 176.66px;
		height: 79.148px;
		left: 608px;
		top: 2195.97px;
		overflow: visible;
	}
	#GamersHub_16_pcprinci_design_img_website_7_pc {
		fill: rgba(247,33,38,1);
	}
	.GamersHub_16_pcprinci_design_img_website_7_pc {
		overflow: visible;
		position: absolute;
		width: 175.359px;
		height: 76.1px;
		transform: translate(-608px, -2195.97px) matrix(1,0,0,1,608.6506,2197.4942) rotate(1deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_pcOur_Services_lbl_website_6_pc {
		left: 31px;
		top: 23.03px;
		position: absolute;
		overflow: visible;
		width: 115px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 25px;
		color: rgba(255,255,255,0.89);
	}
	#GamersHub_16_pcGroup_14985 {
		position: absolute;
		width: 1269px;
		height: 451.5px;
		left: 48.5px;
		top: 2676.5px;
		overflow: visible;
	}
	#GamersHub_16_pcLine_28 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_28 {
		overflow: visible;
		position: absolute;
		width: 1269px;
		height: 2px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 742.5px;
		top: 344.5px;
		overflow: visible;
	}
	#GamersHub_16_pclinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.GamersHub_16_pcmed_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#GamersHub_16_pcmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.GamersHub_16_pcmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#GamersHub_16_pclinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.192px;
		overflow: visible;
	}
	#GamersHub_16_pcn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.GamersHub_16_pcn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pclinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.GamersHub_16_pclinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pctop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.GamersHub_16_pctop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.GamersHub_16_pcshadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#GamersHub_16_pcshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.GamersHub_16_pcshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#GamersHub_16_pcinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 12.592px;
		overflow: visible;
	}
	#GamersHub_16_pcwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcmed_shadow_space_website_7_d:hover {
		fill: rgba(50,172,158,1);
	}
	.GamersHub_16_pcmed_shadow_space_website_7_d:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#GamersHub_16_pcmed_shadow_space_website_7_d {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.GamersHub_16_pcmed_shadow_space_website_7_d {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#GamersHub_16_pcwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 11.993px;
		overflow: visible;
	}
	#GamersHub_16_pcn_234567890_mobile_number_shad {
		left: 777.5px;
		top: 85.5px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcaffanghanioperatelive_email_sh {
		left: 782.5px;
		top: 145.5px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcaddress_med_shadow_space_websi {
		left: 777.5px;
		top: 204.5px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pclocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 742.5px;
		top: 204.5px;
		overflow: visible;
	}
	#GamersHub_16_pcemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 742.5px;
		top: 144.5px;
		overflow: visible;
	}
	#GamersHub_16_pcphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 742.5px;
		top: 84.5px;
		overflow: visible;
	}
	#GamersHub_16_pcn_Copyright_Operate_Live_OPC_P {
		left: 742.5px;
		top: 429.5px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(173,173,173,1);
	}
	#GamersHub_16_pcGroup_14976 {
		position: absolute;
		width: 717.556px;
		height: 403.625px;
		left: 2.278px;
		top: 36.875px;
		overflow: visible;
	}
	#GamersHub_16_pcMy_project_ec {
		position: absolute;
		width: 717.556px;
		height: 403.625px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcRectangle_717 {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_pcRectangle_717 {
		position: absolute;
		overflow: visible;
		width: 357.517px;
		height: 199.755px;
		left: 175.921px;
		top: 96.473px;
	}
	#GamersHub_16_pcScroll_Group_55 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1330.5px;
		height: 270.998px;
		left: 10.5px;
		top: 2315.002px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#GamersHub_16_pcScroll_Group_55::-webkit-scrollbar{
		display: none;
	}
	#GamersHub_16_pcGroup_14977 {
		position: relative;
		margin-right: 30px;
		width: 323.5px;
		height: 230.998px;
		left: 38px;
		top: 20px;
		overflow: visible;
	}
	#GamersHub_16_pcPath_448 {
		fill: rgba(0,0,0,1);
	}
	.GamersHub_16_pcPath_448 {
		overflow: visible;
		position: absolute;
		width: 323.11px;
		height: 195.998px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcn_71177 {
		position: absolute;
		width: 313.597px;
		height: 173.951px;
		left: 4.943px;
		top: 4.992px;
		overflow: visible;
	}
	#GamersHub_16_pcMinecraft {
		left: 1.5px;
		top: 203.998px;
		position: absolute;
		overflow: visible;
		width: 323px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcGroup_14983 {
		position: absolute;
		width: 1304px;
		height: 937px;
		left: 0px;
		top: -46.5px;
		overflow: visible;
	}
	#GamersHub_16_pcMask_Group_1 {
		position: absolute;
		width: 210px;
		height: 210px;
		left: 0px;
		top: 46.5px;
		overflow: visible;
	}
	#GamersHub_16_pcPLAY_GAME_EVERYDAY_ALWAYS_BE_W {
		opacity: 1;
		left: 141px;
		top: 269.5px;
		position: absolute;
		overflow: visible;
		width: 476px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcGroup_14969 {
		position: absolute;
		width: 199.75px;
		height: 419.5px;
		left: 818.25px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcRectangle_718 {
		filter: blur(50px);
		fill: rgba(255,68,87,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcRectangle_718 {
		position: absolute;
		overflow: visible;
		width: 497px;
		height: 544px;
		left: 2.75px;
		top: 175.5px;
	}
	#GamersHub_16_pcRectangle_713 {
		fill: rgba(255,68,87,1);
	}
	.GamersHub_16_pcRectangle_713 {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 35px;
		left: 164.75px;
		top: 101.5px;
	}
	#GamersHub_16_pcConsole {
		left: 18.75px;
		top: 105.5px;
		position: absolute;
		overflow: visible;
		width: 75px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(236,58,74,1);
	}
	#GamersHub_16_pcLine_30 {
		fill: transparent;
		stroke: rgba(255,36,36,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_30 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 351px;
		left: 0.5px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcLine_31 {
		fill: transparent;
		stroke: rgba(255,36,36,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_31 {
		overflow: visible;
		position: absolute;
		width: 4px;
		height: 237.017px;
		left: 0.5px;
		top: 113.688px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcpexels-anthony--139038 {
		position: absolute;
		width: 199.5px;
		height: 280px;
		left: 0px;
		top: 139px;
		overflow: visible;
	}
	#GamersHub_16_pcGroup_14968 {
		position: absolute;
		width: 200px;
		height: 419.5px;
		left: 1104px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcRectangle_719 {
		filter: blur(17px);
		fill: rgba(19,16,27,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcRectangle_719 {
		position: absolute;
		overflow: visible;
		width: 302px;
		height: 375px;
		left: 0px;
		top: 146.5px;
	}
	#GamersHub_16_pcSwitch {
		left: 21px;
		top: 105.5px;
		position: absolute;
		overflow: visible;
		width: 63px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(33,27,33,1);
	}
	#GamersHub_16_pcRectangle_714 {
		fill: rgba(25,22,33,1);
	}
	.GamersHub_16_pcRectangle_714 {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 35px;
		left: 165px;
		top: 101.5px;
	}
	#GamersHub_16_pcLine_32 {
		fill: transparent;
		stroke: rgba(36,24,34,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_32 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 351px;
		left: 0.5px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcLine_33 {
		fill: transparent;
		stroke: rgba(55,114,54,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_33 {
		overflow: visible;
		position: absolute;
		width: 4px;
		height: 237.017px;
		left: 0.5px;
		top: 114px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcpexels-pixabay-371924 {
		position: absolute;
		width: 200px;
		height: 280px;
		left: 0px;
		top: 139.5px;
		overflow: visible;
	}
	#GamersHub_16_pcefeb0ad6-be8c-4667-ab83-307500 {
		position: absolute;
		width: 60px;
		height: 60px;
		left: 126px;
		top: 75.5px;
		border-radius: 50%;
		overflow: hidden;
	}
	#GamersHub_16_pcCompany_Name {
		left: 201px;
		top: 89.5px;
		position: absolute;
		overflow: visible;
		width: 179px;
		white-space: nowrap;
		text-align: left;
		font-family: Headliner No 45;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcCreate_your_own_game_platform_ {
		transform: translate(0px, 46.5px) matrix(1,0,0,1,-61.5,622.5) rotate(-90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 263px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#GamersHub_16_pcLine_38 {
		fill: transparent;
		stroke: rgba(92,35,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_38 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 86px;
		left: 52.5px;
		top: 508px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcLine_39 {
		fill: transparent;
		stroke: rgba(255,12,12,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_39 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 141px;
		left: 52.5px;
		top: 669px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcflorian-olivo-Mf23RF8xArY-unsp {
		position: absolute;
		width: 542px;
		height: 354px;
		left: 141px;
		top: 456.5px;
		overflow: visible;
	}
	#GamersHub_16_pcIcon_ionic-ios-arrow-back {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_pcIcon_ionic-ios-arrow-back {
		overflow: visible;
		position: absolute;
		width: 9.378px;
		height: 16.405px;
		left: 995.811px;
		top: 110.798px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcIcon_ionic-ios-arrow-back_e {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_pcIcon_ionic-ios-arrow-back_e {
		overflow: visible;
		position: absolute;
		width: 9.378px;
		height: 16.405px;
		left: 1281.812px;
		top: 110.798px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcGroup_14981 {
		position: absolute;
		width: 201.25px;
		height: 455.5px;
		left: 816.75px;
		top: 481.5px;
		overflow: visible;
	}
	#GamersHub_16_pcGroup_14970 {
		position: absolute;
		width: 2px;
		height: 351px;
		left: 0px;
		top: 104.5px;
		overflow: visible;
	}
	#GamersHub_16_pcLine_34 {
		fill: transparent;
		stroke: rgba(202,222,228,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_34 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 351px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcLine_35 {
		fill: transparent;
		stroke: rgba(202,222,228,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_35 {
		overflow: visible;
		position: absolute;
		width: 4px;
		height: 237.017px;
		left: 0px;
		top: 114px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcRectangle_720 {
		filter: blur(30px);
		fill: rgba(4,104,118,1);
	}
	.GamersHub_16_pcRectangle_720 {
		position: absolute;
		overflow: visible;
		width: 381px;
		height: 448px;
		left: 0.25px;
		top: 12px;
	}
	#GamersHub_16_pcpexels-harsch-shivam-2007647 {
		position: absolute;
		width: 200px;
		height: 280px;
		left: 1px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcVR {
		left: 13.25px;
		top: 288px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(4,104,118,1);
	}
	#GamersHub_16_pcRectangle_715 {
		fill: rgba(4,104,118,1);
	}
	.GamersHub_16_pcRectangle_715 {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 35px;
		left: 166.25px;
		top: 284px;
	}
	#GamersHub_16_pcIcon_ionic-ios-arrow-back_fg {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_pcIcon_ionic-ios-arrow-back_fg {
		overflow: visible;
		position: absolute;
		width: 9.378px;
		height: 16.405px;
		left: 179.061px;
		top: 293.298px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcGroup_14982 {
		position: absolute;
		width: 200.5px;
		height: 455.5px;
		left: 1103.5px;
		top: 481.5px;
		overflow: visible;
	}
	#GamersHub_16_pcGroup_14971 {
		position: absolute;
		width: 2px;
		height: 351px;
		left: 0px;
		top: 104.5px;
		overflow: visible;
	}
	#GamersHub_16_pcLine_36 {
		fill: transparent;
		stroke: rgba(149,179,48,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_36 {
		overflow: visible;
		position: absolute;
		width: 2px;
		height: 351px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcLine_37 {
		fill: transparent;
		stroke: rgba(149,179,48,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_37 {
		overflow: visible;
		position: absolute;
		width: 4px;
		height: 237.017px;
		left: 0px;
		top: 114px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcRectangle_721 {
		filter: blur(42px);
		fill: rgba(110,134,41,1);
	}
	.GamersHub_16_pcRectangle_721 {
		position: absolute;
		overflow: visible;
		width: 452px;
		height: 520px;
		left: 0.5px;
		top: 12px;
	}
	#GamersHub_16_pcpexels-karol-d-841228 {
		position: absolute;
		width: 200px;
		height: 280px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#GamersHub_16_pcKeybaord {
		left: 13.5px;
		top: 288px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(110,134,41,1);
	}
	#GamersHub_16_pcRectangle_716 {
		fill: rgba(110,134,41,1);
	}
	.GamersHub_16_pcRectangle_716 {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 35px;
		left: 165.5px;
		top: 284px;
	}
	#GamersHub_16_pcIcon_ionic-ios-arrow-back_fp {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_pcIcon_ionic-ios-arrow-back_fp {
		overflow: visible;
		position: absolute;
		width: 9.378px;
		height: 16.405px;
		left: 178.312px;
		top: 293.298px;
		transform: matrix(1,0,0,1,0,0);
	}
	#GamersHub_16_pcPath_6953 {
		fill: rgba(0,0,0,0);
		stroke: rgba(92,35,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcPath_6953 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 86px;
		transform: translate(0px, 46.5px) matrix(1,0,0,1,619.5,340.5) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_pcLine_41 {
		fill: transparent;
		stroke: rgba(255,12,12,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.GamersHub_16_pcLine_41 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 141px;
		transform: translate(0px, 46.5px) matrix(1,0,0,1,463.5,313) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_pcGroup_14980 {
		position: absolute;
		width: 54px;
		height: 54px;
		left: 703px;
		top: 756.5px;
		overflow: visible;
	}
	#GamersHub_16_pcEllipse_2 {
		fill: rgba(35,33,38,1);
	}
	.GamersHub_16_pcEllipse_2 {
		position: absolute;
		overflow: visible;
		width: 54px;
		height: 54px;
		left: 0px;
		top: 0px;
	}
	#GamersHub_16_pcPolygon_1 {
		fill: rgba(255,255,255,1);
	}
	.GamersHub_16_pcPolygon_1 {
		overflow: visible;
		position: absolute;
		width: 12.801px;
		height: 10.972px;
		transform: translate(1666.247px, -297.754px) matrix(1,0,0,1,-1645.8578,319.0566) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}