	.mediaViewInfo {
		--web-view-name: MobileStock;
		--web-view-id: MobileStock;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: MobileStock;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#MobileStock {
		position: absolute;
		width: 1366px;
		height: 4235.064px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(249,249,255,1);
		overflow: hidden;
		--web-view-name: MobileStock;
		--web-view-id: MobileStock;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#theme11_stock_pcstyle {
		position: absolute;
		width: 508.51px;
		height: 508.613px;
		left: 0px;
		top: 3089.662px;
		overflow: visible;
	}
	#theme11_stock_pcPath_28 {
		fill: rgba(13,19,215,1);
	}
	.theme11_stock_pcPath_28 {
		overflow: visible;
		position: absolute;
		width: 504.926px;
		height: 508.612px;
		left: 0px;
		top: 0.001px;
		transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pcEllipse_6 {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_6 {
		position: absolute;
		overflow: visible;
		width: 423.161px;
		height: 423.16px;
		left: 85.349px;
		top: 52.068px;
	}
	#theme11_stock_pcEllipse_5 {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_5 {
		position: absolute;
		overflow: visible;
		width: 240.986px;
		height: 240.987px;
		left: 176.437px;
		top: 143.155px;
	}
	#theme11_stock_pcGroup_14880 {
		position: absolute;
		width: 1264px;
		height: 559.17px;
		left: 0px;
		top: 2175.053px;
		overflow: visible;
	}
	#theme11_stock_pcstyle_ci {
		position: absolute;
		width: 508.51px;
		height: 508.613px;
		left: 0px;
		top: 24.894px;
		overflow: visible;
	}
	#theme11_stock_pcPath_28_cj {
		fill: rgba(13,19,215,1);
	}
	.theme11_stock_pcPath_28_cj {
		overflow: visible;
		position: absolute;
		width: 504.926px;
		height: 508.612px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pcEllipse_6_ck {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_6_ck {
		position: absolute;
		overflow: visible;
		width: 423.161px;
		height: 423.16px;
		left: 85.349px;
		top: 52.067px;
	}
	#theme11_stock_pcEllipse_5_cl {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_5_cl {
		position: absolute;
		overflow: visible;
		width: 240.986px;
		height: 240.986px;
		left: 176.437px;
		top: 143.155px;
	}
	#theme11_stock_pcjoel-rohland-MCL2xxjZJvs-unspl {
		position: absolute;
		width: 347.971px;
		height: 559.17px;
		left: 215.167px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcScroll_Group_26 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 291px;
		left: 671px;
		top: 176.208px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#theme11_stock_pcScroll_Group_26::-webkit-scrollbar{
		display: none;
	}
	#theme11_stock_pcIt_is_a_long_established_fact_ {
		left: 12px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#theme11_stock_pcPRINCIPLES {
		left: 683px;
		top: 124.208px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_pcButtom {
		position: absolute;
		width: 1377px;
		height: 400px;
		left: 0px;
		top: 3847px;
		overflow: visible;
	}
	#theme11_stock_pcRectangle_53 {
		fill: rgba(235,235,248,1);
	}
	.theme11_stock_pcRectangle_53 {
		position: absolute;
		overflow: visible;
		width: 1377px;
		height: 400px;
		left: 0px;
		top: 0px;
	}
	#theme11_stock_pcour_Services {
		left: 584px;
		top: 2892px;
		position: absolute;
		overflow: visible;
		width: 199px;
		white-space: nowrap;
		line-height: 43px;
		margin-top: -4px;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#theme11_stock_pcBanner_Slider {
		position: absolute;
		width: 1377.065px;
		height: 718.641px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcPath_2 {
		fill: rgba(60,65,223,1);
	}
	.theme11_stock_pcPath_2 {
		overflow: visible;
		position: absolute;
		width: 1377.064px;
		height: 718.641px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pcdugba-cauley-hushie-6MNmDi1hc_ {
		position: absolute;
		width: 1377.064px;
		height: 672.275px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcPath_1527 {
		opacity: 0.21;
		fill: rgba(38,43,219,1);
	}
	.theme11_stock_pcPath_1527 {
		overflow: visible;
		position: absolute;
		width: 1377.064px;
		height: 672.275px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pcYour_Mobile_Paradise__Where_Dr {
		left: 290px;
		top: 255px;
		position: absolute;
		overflow: visible;
		width: 491px;
		white-space: nowrap;
		text-align: left;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 39px;
		color: rgba(255,255,255,1);
		text-transform: capitalize;
	}
	#theme11_stock_pcBtg {
		position: absolute;
		width: 179px;
		height: 63px;
		left: 290px;
		top: 412.001px;
		overflow: visible;
	}
	#theme11_stock_pcRectangle_77 {
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcRectangle_77 {
		position: absolute;
		overflow: visible;
		width: 179px;
		height: 63px;
		left: 0px;
		top: -0.001px;
	}
	#theme11_stock_pcExplore_More {
		left: 40px;
		top: 19.999px;
		position: absolute;
		overflow: visible;
		width: 101px;
		white-space: nowrap;
		text-align: center;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#theme11_stock_pcGroup_14881 {
		position: absolute;
		width: 1276px;
		height: 559.17px;
		left: 90px;
		top: 1508.888px;
		overflow: visible;
	}
	#theme11_stock_pcstyle_c {
		position: absolute;
		width: 508.51px;
		height: 508.613px;
		left: 767.49px;
		top: 24.894px;
		overflow: visible;
	}
	#theme11_stock_pcPath_28_c {
		fill: rgba(13,19,215,1);
	}
	.theme11_stock_pcPath_28_c {
		overflow: visible;
		position: absolute;
		width: 504.926px;
		height: 508.613px;
		left: 3.584px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pcEllipse_6_c {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_6_c {
		position: absolute;
		overflow: visible;
		width: 423.161px;
		height: 423.16px;
		left: 0px;
		top: 52.068px;
	}
	#theme11_stock_pcEllipse_5_c {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_5_c {
		position: absolute;
		overflow: visible;
		width: 240.986px;
		height: 240.986px;
		left: 91.087px;
		top: 143.155px;
	}
	#theme11_stock_pcneil-soni-6wdRuK7bVTE-unsplash {
		position: absolute;
		width: 347.971px;
		height: 559.17px;
		left: 646.28px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcScroll_Group_27 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 189.208px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#theme11_stock_pcScroll_Group_27::-webkit-scrollbar{
		display: none;
	}
	#theme11_stock_pcIt_is_a_long_established_fact__c {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#theme11_stock_pcMISSION {
		left: 18px;
		top: 124.208px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_pcGroup_14882 {
		position: absolute;
		width: 1264px;
		height: 559.165px;
		left: 0px;
		top: 842.84px;
		overflow: visible;
	}
	#theme11_stock_pcstyle_db {
		position: absolute;
		width: 508.51px;
		height: 508.613px;
		left: 0px;
		top: 24.776px;
		overflow: visible;
	}
	#theme11_stock_pcPath_28_dc {
		fill: rgba(13,19,215,1);
	}
	.theme11_stock_pcPath_28_dc {
		overflow: visible;
		position: absolute;
		width: 504.926px;
		height: 508.612px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pcEllipse_6_dd {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_6_dd {
		position: absolute;
		overflow: visible;
		width: 423.161px;
		height: 423.16px;
		left: 85.349px;
		top: 52.067px;
	}
	#theme11_stock_pcEllipse_5_de {
		opacity: 0.1;
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_5_de {
		position: absolute;
		overflow: visible;
		width: 240.986px;
		height: 240.986px;
		left: 176.437px;
		top: 143.155px;
	}
	#theme11_stock_pcdaniel-korpai-H0vKZjt2k1E-unsp {
		position: absolute;
		width: 347.554px;
		height: 559.165px;
		left: 238.167px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 671px;
		top: 189.091px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#theme11_stock_pcScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#theme11_stock_pcIt_is_a_long_established_fact__dh {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#theme11_stock_pcVISION {
		left: 689px;
		top: 124.091px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_pcsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 821px;
		top: 4112px;
		overflow: visible;
	}
	#theme11_stock_pclinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcmed_shadow_space_website_7:hover {
		fill: rgba(120,120,255,1);
	}
	.theme11_stock_pcmed_shadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#theme11_stock_pcmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.theme11_stock_pcmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#theme11_stock_pclinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#theme11_stock_pcn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.theme11_stock_pcn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.438px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pclinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.theme11_stock_pclinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.809px;
		transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pctop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.theme11_stock_pctop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#theme11_stock_pcInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcshadow_space_website_7:hover {
		fill: rgba(255,120,120,1);
	}
	.theme11_stock_pcshadow_space_website_7:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#theme11_stock_pcshadow_space_website_7 {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.theme11_stock_pcshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#theme11_stock_pcinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#theme11_stock_pcwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcmed_shadow_space_website_7_dw:hover {
		fill: rgba(50,172,158,1);
	}
	.theme11_stock_pcmed_shadow_space_website_7_dw:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#theme11_stock_pcmed_shadow_space_website_7_dw {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.theme11_stock_pcmed_shadow_space_website_7_dw {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#theme11_stock_pcwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.985px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#theme11_stock_pcn_234567890_mobile_number_shad {
		left: 856px;
		top: 3881px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_pcaffanghanioperatelive_email_sh {
		left: 861px;
		top: 3934px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_pcaddress_med_shadow_space_websi {
		left: 856px;
		top: 3984px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_pclocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 821px;
		top: 3986px;
		overflow: visible;
	}
	#theme11_stock_pcemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 821px;
		top: 3933px;
		overflow: visible;
	}
	#theme11_stock_pcphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 821px;
		top: 3880px;
		overflow: visible;
	}
	#theme11_stock_pcn_Copyright_Operate_Live_OPC_P {
		left: 821px;
		top: 4188px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(173,173,173,1);
	}
	#theme11_stock_pcTitle {
		left: 722px;
		top: 3186px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_pcScroll_Group_29 {
		mix-blend-mode: normal;
		position: absolute;
		width: 553px;
		height: 260px;
		left: 711px;
		top: 3229px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#theme11_stock_pcScroll_Group_29::-webkit-scrollbar{
		display: none
	}
	#theme11_stock_pcDescription {
		left: 10px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 520px;
		height: 235px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#theme11_stock_pckisspng-laptop-macbook-pro-mac {
		position: absolute;
		width: 568px;
		height: 348px;
		left: 115px;
		top: 3873px;
		overflow: visible;
	}
	#theme11_stock_pcRectangle_690 {
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcRectangle_690 {
		position: absolute;
		overflow: visible;
		width: 349px;
		height: 225px;
		left: 184px;
		top: 3931px;
	}
	#theme11_stock_pcEllipse_62 {
		fill: rgba(255,255,255,1);
	}
	.theme11_stock_pcEllipse_62 {
		position: absolute;
		overflow: visible;
		width: 35px;
		height: 78px;
		left: 10px;
		top: 7px;
	}
	#theme11_stock_pcjakob-rosen-FsBbavP9YA4-unspla {
		position: absolute;
		width: 88px;
		height: 88px;
		left: 19px;
		top: 2px;
        border-radius: 50%;
		overflow: hidden;
	}
	#theme11_stock_pcGroup_14894 {
		position: absolute;
		width: 685.665px;
		height: 738.866px;
		left: 25.225px;
		top: 2974.536px;
		overflow: visible;
	}
	#theme11_stock_pcGroup_14893 {
		position: absolute;
		width: 685.665px;
		height: 738.866px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcGroup_14885 {
		opacity: 0.74;
		position: absolute;
		width: 412.39px;
		height: 662.688px;
		left: 273.275px;
		top: 47.179px;
		overflow: visible;
	}
	#theme11_stock_pciPhone_X_-_Light2x {
		position: absolute;
		width: 412.39px;
		height: 662.688px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcdariusz-sankowski-3OiYMgDKJ6k- {
		position: absolute;
		width: 230.106px;
		height: 410.461px;
		left: 91.165px;
		top: 127.857px;
		overflow: visible;
	}
	#theme11_stock_pcGroup_14884 {
		opacity: 0.74;
		position: absolute;
		width: 412.39px;
		height: 662.688px;
		left: 0px;
		top: 47.179px;
		overflow: visible;
	}
	#theme11_stock_pciPhone_X_-_Light2x_ej {
		position: absolute;
		width: 412.39px;
		height: 662.688px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcdariusz-sankowski-3OiYMgDKJ6k-_ek {
		position: absolute;
		width: 230.106px;
		height: 410.461px;
		left: 91.165px;
		top: 127.857px;
		overflow: visible;
	}
	#theme11_stock_pcGroup_14883 {
		position: absolute;
		width: 459.795px;
		height: 738.866px;
		left: 119.594px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pciPhone_X_-_Light2x_em {
		position: absolute;
		width: 459.795px;
		height: 738.866px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#theme11_stock_pcdariusz-sankowski-3OiYMgDKJ6k-_en {
		position: absolute;
		width: 256.558px;
		height: 457.644px;
		left: 101.645px;
		top: 142.555px;
		overflow: visible;
	}
	#theme11_stock_pcPolygon_1 {
		fill: rgba(0,0,0,0.886);
	}
	.theme11_stock_pcPolygon_1 {
		overflow: visible;
		position: absolute;
		width: 40px;
		height: 37.6px;
		transform: translate(-25.225px, -2974.536px) matrix(1,0,0,1,35.3,3325.2) rotate(-90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}
	#theme11_stock_pcPolygon_2 {
		fill: rgba(0,0,0,0.886);
	}
	.theme11_stock_pcPolygon_2 {
		overflow: visible;
		position: absolute;
		width: 40px;
		height: 37.6px;
		transform: translate(-25.225px, -2974.536px) matrix(1,0,0,1,654.7,3325.2) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
	}