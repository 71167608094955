.OFFM_small_4_trending_com_of {
    position: relative;
    width: 160px;
    height: 197px;
    left: 0px;
    top: 0px;
    margin-top: 5px;
    overflow: visible;
}
#OFFM_all_bdr_small_4_trending_com {
    fill: rgba(245,245,245,1);
}
.OFFM_all_bdr_small_4_trending_com {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 178px;
    height: 215px;
    left: 0px;
    top: 0px;
}
#OFFM_Image_small_4_trendin {
    position: absolute;
    width: 160px;
    height: 150px;
    /* left: 12px;
    top: 23px; */
    overflow: visible;
}
#OFFM_bdr_amount_small_4_trending_co {
    fill: rgba(255,255,255,1);
}
.OFFM_bdr_amount_small_4_trending_co {
    position: absolute;
    overflow: visible;
    width: 45px;
    height: 21px;
    left: 116px;
    top: 160px;
}
#OFFM_n_9_small_4_trending_com {
    left: 115px;
    top: 160px;
    position: absolute;
    overflow: visible;
    width: 45px;
    white-space: pre-line;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    color: rgba(255,79,4,1);
    letter-spacing: -0.2222222137451172px;
}
#OFFM_NikeCourt_Air_small_4_trending {
    left: 2.986px;
    top: 158.311px;
    position: absolute;
    overflow: visible;
    width: 110px;
    height: 14px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#OFFM_Single_Tour_small_4_trending {
    left: 3px;
    top: 175px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    /* line-height: 14px; */
    /* margin-top: -2px; */
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(17,17,17,1);
}
#OFFM_number_small_4_trending {
    fill: rgba(255,255,255,1);
}
.OFFM_number_small_4_trending {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 13px;
    left: 26px;
    top: 6px;
}
#OFFM_trohpie_bdr_small_4_trendi {
    fill: rgba(255,255,255,1);
}
.OFFM_trohpie_bdr_small_4_trendi {
    position: absolute;
    overflow: visible;
    width: 24px;
    height: 24px;
    left: 1px;
    top: 1px;
}
#OFFM_trophy_small_4_trending_com_of {
    position: absolute;
    width: 19px;
    height: 19px;
    left: 3px;
    top: 3px;
    overflow: visible;
}
#OFFM_n_234_small_4_trending_com_off {
    left: 28px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 19px;
    white-space: nowrap;
    line-height: 16px;
    margin-top: -4px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    color: rgba(17,17,17,1);
}
#OFFM_share_bdr_small_4_trending_com {
    fill: rgba(255,255,255,1);
}
.OFFM_share_bdr_small_4_trending_com {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 25px;
    left: 135px;
    top: 1px;
}
#OFFM_share_2_com_of {
    position: absolute;
    width: 15px;
    height: 14px;
    left: 140px;
    top: 6px;
    overflow: visible;
}
