	.mediaViewInfo {
		--web-view-name: FurniGem;
		--web-view-id: FurniGem;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: FurniGem;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	body::-webkit-scrollbar{
		display: none;
	}
	#FurniGem {
		position: absolute;
		width: 1366px;
		height: 3999px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: FurniGem;
		--web-view-id: FurniGem;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#thm15FG_pcGroup_14956 {
		position: absolute;
		width: 208px;
		height: 59px;
		left: 1102px;
		top: 2779px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_705 {
		fill: rgba(0,0,0,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.thm15FG_pcRectangle_705 {
		position: absolute;
		overflow: visible;
		width: 208px;
		height: 59px;
		left: 0px;
		top: 0px;
	}
	#thm15FG_pcGroup_14954 {
		position: absolute;
		width: 155.504px;
		height: 30.705px;
		left: 27px;
		top: 14px;
		overflow: visible;
	}
	#thm15FG_pcLearn_More {
		left: 0px;
		top: 2px;
		position: absolute;
		overflow: visible;
		width: 102px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_pcIcon_awesome-arrow-right {
		fill: rgba(255,153,0,1);
	}
	.thm15FG_pcIcon_awesome-arrow-right {
		overflow: visible;
		position: absolute;
		width: 31.504px;
		height: 30.705px;
		left: 124px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_pcGroup_14966 {
		position: absolute;
		width: 1347px;
		height: 804px;
		left: 19px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_704 {
		fill: rgba(232,232,232,1);
	}
	.thm15FG_pcRectangle_704 {
		position: absolute;
		overflow: visible;
		width: 406px;
		height: 480px;
		left: 941px;
		top: 0px;
	}
	#thm15FG_pcdi_an_h-g_8MrEZAvyE-unsplash {
		position: absolute;
		width: 406px;
		height: 566px;
		left: 625px;
		top: 238px;
		overflow: visible;
	}
	#thm15FG_pcAnime_Edit {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 1211px;
		top: 81px;
        border-radius: 50%;
		overflow: hidden;
	}
	#thm15FG_pcTransform__Your_Space {
		left: 21px;
		top: 99px;
		position: absolute;
		overflow: visible;
		width: 504px;
		white-space: nowrap;
		line-height: 118px;
		margin-top: -9px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 100px;
		color: rgba(0,0,0,1);
		letter-spacing: 0.2px;
	}
	#thm15FG_pcTransform_Your_Space_With_Our {
		left: 951px;
		top: 211px;
		position: absolute;
		overflow: visible;
		width: 277px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(12,54,83,1);
	}
	#thm15FG_pcGroup_14955 {
		position: absolute;
		width: 160px;
		height: 46px;
		left: 12px;
		top: 570px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_705_c {
		fill: rgba(0,0,0,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.thm15FG_pcRectangle_705_c {
		position: absolute;
		overflow: visible;
		width: 160px;
		height: 46px;
		left: 0px;
		top: 0px;
	}
	#thm15FG_pcGroup_14954_c {
		position: absolute;
		width: 119.812px;
		height: 23.691px;
		left: 21px;
		top: 10.802px;
		overflow: visible;
	}
	#thm15FG_pcLearn_More_c {
		left: 0px;
		top: 1.198px;
		position: absolute;
		overflow: visible;
		width: 77px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_pcIcon_awesome-arrow-right_c {
		fill: rgba(255,153,0,1);
	}
	.thm15FG_pcIcon_awesome-arrow-right_c {
		overflow: visible;
		position: absolute;
		width: 24.307px;
		height: 23.691px;
		left: 95.505px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_pcScroll_Group_49 {
		mix-blend-mode: normal;
		position: absolute;
		width: 521px;
		height: 183px;
		left: 12px;
		top: 376px;
		overflow: hidden;
	}
	#thm15FG_pcIntroduction {
		left: 14px;
		top: 17px;
		position: absolute;
		overflow: visible;
		width: 494px;
		height: 152px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcGroup_14958 {
		position: absolute;
		width: 44px;
		height: 77px;
		left: 1269px;
		top: 727px;
		overflow: hidden;
	}
	#thm15FG_pcRectangle_706 {
		fill: rgba(255,141,12,1);
	}
	.thm15FG_pcRectangle_706 {
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 77px;
		left: 0px;
		top: 0px;
	}
	#thm15FG_pcIcon_awesome-arrow-down {
		fill: rgba(255,255,255,1);
	}
	.thm15FG_pcIcon_awesome-arrow-down {
		overflow: visible;
		position: absolute;
		width: 30.705px;
		height: 31.503px;
		left: 6.647px;
		top: 22.748px;
		transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_pcGroup_14963 {
		position: absolute;
		width: 606px;
		height: 120px;
		left: 0px;
		top: 684px;
		overflow: visible;
	}
	#thm15FG_pcguzman-barquin-FkKClUPUURU-uns {
		position: absolute;
		width: 186px;
		height: 120px;
		left: 420px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_pcspacejoy-YqFz7UMm8qE-unsplash {
		position: absolute;
		width: 186px;
		height: 120px;
		left: 210px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_pcmyxa69-nZnVdtlJvbw-unsplash {
		position: absolute;
		width: 186px;
		height: 120px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_pcCompany_Name {
		left: 951px;
		top: 102px;
		position: absolute;
		overflow: visible;
		width: 251px;
		height: 27px;
		text-align: right;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(12,54,83,1);
	}
	#thm15FG_pcStunning_Furniture_Collection {
		left: 1034px;
		top: 238px;
		position: absolute;
		overflow: visible;
		width: 258px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(12,54,83,1);
	}
	#thm15FG_pcProduct {
		left: 623px;
		top: 2785px;
		position: absolute;
		overflow: visible;
		width: 122px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcScroll_Group_50 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1290px;
		height: 428px;
		left: 38px;
		top: 2869px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#thm15FG_pcScroll_Group_50::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_pcGroup_14957 {
		position: relative;
		width: 283px;
		height: 362px;
		left: 24px;
		top: 33px;
        margin-right: 30px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_708 {
		fill: rgba(255,255,255,1);
	}
	.thm15FG_pcRectangle_708 {
		position: absolute;
		overflow: visible;
		width: 283px;
		height: 362px;
		left: 0px;
		top: 0px;
	}
	#thm15FG_pcavery-klein-JaXs8Tk5Iww-unspla {
		position: absolute;
		width: 247px;
		height: 298px;
		left: 18px;
		top: 18px;
		overflow: visible;
	}
	#thm15FG_pcChair {
		left: 18px;
		top: 328px;
		position: absolute;
		overflow: visible;
		width: 248px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcGroup_14961 {
		position: absolute;
		width: 1220px;
		height: 473px;
		left: 146px;
		top: 973px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_707 {
		fill: rgba(232,232,232,1);
	}
	.thm15FG_pcRectangle_707 {
		position: absolute;
		overflow: visible;
		width: 876px;
		height: 473px;
		left: 344px;
		top: 0px;
	}
	#thm15FG_pckisspng-furniture-poster-couch {
		position: absolute;
		width: 397px;
		height: 366px;
		left: 0px;
		top: 54px;
		overflow: visible;
	}
	#thm15FG_pcScroll_Group_51 {
		mix-blend-mode: normal;
		position: absolute;
		width: 698px;
		height: 269px;
		left: 465px;
		top: 141px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#thm15FG_pcScroll_Group_51::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_pcElevate_Your_Space_with_Timele {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 677px;
		height: 249px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcVision {
		left: 476px;
		top: 74px;
		position: absolute;
		overflow: visible;
		width: 88px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcGroup_14960 {
		position: absolute;
		width: 1220px;
		height: 473px;
		left: 0px;
		top: 1552px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_709 {
		fill: rgba(232,232,232,1);
	}
	.thm15FG_pcRectangle_709 {
		position: absolute;
		overflow: visible;
		width: 876px;
		height: 473px;
		left: 0px;
		top: 0px;
	}
	#thm15FG_pckisspng-window-living-room-cur {
		position: absolute;
		width: 397px;
		height: 366px;
		left: 823px;
		top: 54px;
		overflow: visible;
	}
	#thm15FG_pcScroll_Group_52 {
		mix-blend-mode: normal;
		position: absolute;
		width: 698px;
		height: 269px;
		left: 57px;
		top: 141px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#thm15FG_pcScroll_Group_52::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_pcElevate_Your_Space_with_Timele_d {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 677px;
		height: 249px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcMission {
		left: 57px;
		top: 74px;
		position: absolute;
		overflow: visible;
		width: 110px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcGroup_14959 {
		position: absolute;
		width: 1220px;
		height: 473px;
		left: 146px;
		top: 2131px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_710 {
		fill: rgba(232,232,232,1);
	}
	.thm15FG_pcRectangle_710 {
		position: absolute;
		overflow: visible;
		width: 876px;
		height: 473px;
		left: 344px;
		top: 0px;
	}
	#thm15FG_pcsuchit-poojari-ljRiZl00n18-uns {
		position: absolute;
		width: 397px;
		height: 366px;
		left: 0px;
		top: 54px;
		overflow: visible;
	}
	#thm15FG_pcScroll_Group_53 {
		mix-blend-mode: normal;
		position: absolute;
		width: 698px;
		height: 269px;
		left: 465px;
		top: 141px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#thm15FG_pcScroll_Group_53::-webkit-scrollbar{
		display: none;
	}
	#thm15FG_pcElevate_Your_Space_with_Timele_ea {
		left: 11px;
		top: 10px;
		position: absolute;
		overflow: visible;
		width: 677px;
		height: 249px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcPrinciples {
		left: 476px;
		top: 74px;
		position: absolute;
		overflow: visible;
		width: 138px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#thm15FG_pcGroup_14962 {
		position: absolute;
		width: 1366px;
		height: 442px;
		left: 0px;
		top: 3562px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_711 {
		fill: rgba(59,59,59,1);
	}
	.thm15FG_pcRectangle_711 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 442px;
		left: 0px;
		top: 0px;
	}
	#thm15FG_pcLine_29 {
		fill: transparent;
		stroke: rgba(255,255,255,1);
		stroke-width: 2px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.thm15FG_pcLine_29 {
		overflow: visible;
		position: absolute;
		width: 1290px;
		height: 2px;
		left: 38.5px;
		top: 336.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_pcsocials_grp_shadow_space_websi {
		position: absolute;
		width: 210px;
		height: 50.001px;
		left: 578px;
		top: 363.999px;
		overflow: visible;
	}
	#thm15FG_pclinked_in_grp_shadow_space_web {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 160px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_pcEllipse_5shadow_space_website_:hover {
		fill: rgba(120,120,255,1);
	}
	.thm15FG_pcEllipse_5shadow_space_website_:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#thm15FG_pcEllipse_5shadow_space_website_ {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.thm15FG_pcEllipse_5shadow_space_website_ {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#thm15FG_pclinked_in_shadow_space_website {
		position: absolute;
		width: 24.349px;
		height: 22.135px;
		left: 13.125px;
		top: 13.75px;
		overflow: visible;
	}
	#thm15FG_pcn_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.thm15FG_pcn_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 16.092px;
		height: 15.182px;
		left: 8.257px;
		top: 6.954px;
		transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_pclinked_in_i_down {
		fill: rgba(63,61,86,1);
	}
	.thm15FG_pclinked_in_i_down {
		overflow: visible;
		position: absolute;
		width: 4.746px;
		height: 15.038px;
		left: 0.614px;
		top: 7.097px;
		transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_pctop_dot_i_website_2_layout {
		fill: rgba(63,61,86,1);
	}
	.thm15FG_pctop_dot_i_website_2_layout {
		overflow: visible;
		position: absolute;
		width: 5.761px;
		height: 5.218px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#thm15FG_pcInsta_grp_med_shadow_space_web {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 80px;
		top: 0px;
		overflow: visible;
	}
	#thm15FG_pcEllipseshadow_space_website_5_:hover {
		fill: rgba(255,120,120,1);
	}
	.thm15FG_pcEllipseshadow_space_website_5_:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#thm15FG_pcEllipseshadow_space_website_5_ {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.thm15FG_pcEllipseshadow_space_website_5_ {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#thm15FG_pcinstagram_shadow_space_website {
		position: absolute;
		width: 24.375px;
		height: 24.375px;
		left: 13.125px;
		top: 13.126px;
		overflow: visible;
	}
	#thm15FG_pcwhatsapp_grpshadow_space_websi {
		position: absolute;
		width: 50px;
		height: 50px;
		left: 0px;
		top: 0.001px;
		overflow: visible;
	}
	#thm15FG_pcEllipse_3_shadow_space_website:hover {
		fill: rgba(50,172,158,1);
	}
	.thm15FG_pcEllipse_3_shadow_space_website:hover {
		filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.161));
		width: 98px;
		height: 98px;
	}
	#thm15FG_pcEllipse_3_shadow_space_website {
		fill: rgba(255,255,255,1);
		transition: all .3s ease-out;
	}
	.thm15FG_pcEllipse_3_shadow_space_website {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 59px;
		height: 59px;
		left: 0px;
		top: 0px;
		transition: all .3s ease-out;
	}
	#thm15FG_pcwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 12.5px;
		top: 12.5px;
		overflow: visible;
	}
	#thm15FG_pcRectangle_712 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.thm15FG_pcRectangle_712 {
		position: absolute;
		overflow: visible;
		width: 547px;
		height: 244px;
		left: 748px;
		top: 52px;
	}
	#thm15FG_pcn_234567890_mobile_number_shad {
		left: 74px;
		top: 43px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_pcaffanghanioperatelive_email_sh {
		left: 74px;
		top: 103px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_pcaddress_med_shadow_space_websi {
		left: 74px;
		top: 162px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#thm15FG_pclocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 39px;
		top: 162px;
		overflow: visible;
	}
	#thm15FG_pcemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 39px;
		top: 102px;
		overflow: visible;
	}
	#thm15FG_pcphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 39px;
		top: 42px;
		overflow: visible;
	}
	#thm15FG_pcVisit_Us {
		left: 748px;
		top: 13px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}