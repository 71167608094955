	.mediaViewInfo {
		--web-view-name: ElectroTech Solutions Mob;
		--web-view-id: ElectroTech_Solutions_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ElectroTech_Solutions_Mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ElectroTech_Solutions_Mob {
		position: absolute;
		width: 390px;
		height: 3663px;
        left:calc((100vw - 390px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: ElectroTech Solutions Mob;
		--web-view-id: ElectroTech_Solutions_Mob;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#ETS_27_MOBGroup_15110 {
		position: absolute;
		width: 429.281px;
		height: 646.021px;
		left: -39.281px;
		top: -39.021px;
		overflow: visible;
	}
	#ETS_27_MOBcopy-space-colorful-sewing-thr {
		position: absolute;
		width: 274.562px;
		height: 172.021px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBGroup_15102 {
		position: absolute;
		width: 292px;
		height: 292px;
		left: 137.281px;
		top: 128.021px;
		overflow: visible;
	}
	#ETS_27_MOBRectangle_747 {
		fill: rgba(237,149,75,1);
	}
	.ETS_27_MOBRectangle_747 {
		position: absolute;
		overflow: visible;
		width: 186px;
		height: 186px;
		left: 106px;
		top: 0px;
	}
	#ETS_27_MOBelectrical-shielded-cable-with {
		position: absolute;
		width: 252px;
		height: 252px;
		left: 0px;
		top: 40px;
		overflow: visible;
	}
	#ETS_27_MOBPowering_Your_World__Expert_El {
		left: 61.281px;
		top: 466.021px;
		position: absolute;
		overflow: visible;
		width: 348px;
		white-space: nowrap;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#ETS_27_MOBJoin_the_Spark_of_Excellence__ {
		opacity: 0.5;
		left: 61.281px;
		top: 606.021px;
		position: absolute;
		overflow: visible;
		width: 232px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(36,36,36,1);
	}
	#ETS_27_MOBGroup_15112 {
		position: absolute;
		width: 325px;
		height: 40px;
		left: 96.281px;
		top: 51.021px;
		overflow: visible;
	}
	#ETS_27_MOBAnime_Edit {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
        border-radius: 50%;
		overflow: hidden;
	}
	#ETS_27_MOBElectician {
		left: 52px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 274px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBGroup_15109 {
		position: absolute;
		width: 379px;
		height: 1707px;
		left: 11px;
		top: 708px;
		overflow: visible;
	}
	#ETS_27_MOBGroup_15105 {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBmika-baumeister-dp9AxaKHdVg-un {
		filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBGroup_15104 {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#ETS_27_MOBScroll_Group_28_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#ETS_27_MOBIt_is_a_long_established_fact__ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#ETS_27_MOBVISION_ {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBGroup_15106 {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBmika-baumeister-dp9AxaKHdVg-un_ {
		filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBGroup_15104_ {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#ETS_27_MOBScroll_Group_28_ {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#ETS_27_MOBIt_is_a_long_established_fact__ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#ETS_27_MOBVISION_ {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBGroup_15106_ba {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 592px;
		overflow: visible;
	}
	#ETS_27_MOBpatrick-tomasso-1NTFSnV-KLs-un {
		filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBGroup_15104_bc {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#ETS_27_MOBScroll_Group_28_bd {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#ETS_27_MOBIt_is_a_long_established_fact__be {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#ETS_27_MOBMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBGroup_15106_bg {
		position: absolute;
		width: 379px;
		height: 523px;
		left: 0px;
		top: 1184px;
		overflow: visible;
	}
	#ETS_27_MOBframes-for-your-heart-iOLHAIax {
		filter: drop-shadow(0px 3px 20px rgba(235, 161, 99, 1));
		position: absolute;
		width: 294px;
		height: 220px;
		left: 36px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBGroup_15104_bi {
		position: absolute;
		width: 379px;
		height: 257px;
		left: 0px;
		top: 266px;
		overflow: visible;
	}
	#ETS_27_MOBScroll_Group_28_bj {
		mix-blend-mode: normal;
		position: absolute;
		width: 379px;
		height: 192px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#ETS_27_MOBIt_is_a_long_established_fact__bk {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 359px;
		height: 192px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#ETS_27_MOBPRINCIPLES {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBGroup_15107 {
		position: absolute;
		width: 390px;
		height: 403.998px;
		left: 0px;
		top: 2526px;
		overflow: visible;
	}
	#ETS_27_MOBOur_Services {
		left: 113px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 165px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}
	#ETS_27_MOBsteve-johnson-hokONTrHIAQ-unsp {
		position: absolute;
		width: 390px;
		height: 324px;
		left: 0px;
		top: 79.998px;
		overflow: visible;
	}
	#ETS_27_MOBScroll_Group_77 {
		mix-blend-mode: normal;
		position: absolute;
		width: 377px;
		height: 324px;
		left: 13px;
		top: 79.998px;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
	}
	#ETS_27_MOBn_ {
		position: relative;
        margin-right: 30px;
		width: 247px;
		height: 275px;
		left: 25px;
		top: 21.002px;
		overflow: visible;
	}
	#ETS_27_MOBMask_Group_22 {
		position: absolute;
		width: 247px;
		height: 229px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBRectangle_664 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBRectangle_664 {
		position: absolute;
		overflow: visible;
		width: 247px;
		height: 46px;
		left: 0px;
		top: 229px;
	}
	#ETS_27_MOBSomething {
		left: 9px;
		top: 242px;
		position: absolute;
		overflow: visible;
		width: 233px;
		height: 21px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
		text-transform: uppercase;
	}
	#ETS_27_MOBGroup_10773 {
		position: absolute;
		width: 46px;
		height: 5px;
		left: 101px;
		top: 214px;
		overflow: visible;
	}
	#ETS_27_MOBEllipse_38 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBEllipse_38 {
		position: absolute;
		overflow: visible;
		width: 5px;
		height: 5px;
		left: 0px;
		top: 0px;
	}
	#ETS_27_MOBEllipse_39 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBEllipse_39 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 10px;
		top: 0px;
	}
	#ETS_27_MOBEllipse_40 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBEllipse_40 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 20px;
		top: 0px;
	}
	#ETS_27_MOBEllipse_41 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBEllipse_41 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 29px;
		top: 0px;
	}
	#ETS_27_MOBEllipse_42 {
		opacity: 0.4;
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBEllipse_42 {
		position: absolute;
		overflow: visible;
		width: 6px;
		height: 5px;
		left: 40px;
		top: 0px;
	}
	#ETS_27_MOBGroup_15108 {
		position: absolute;
		width: 358px;
		height: 590.002px;
		left: 16px;
		top: 3062.998px;
		overflow: visible;
	}
	#ETS_27_MOBGroup_15040 {
		position: absolute;
		width: 281px;
		height: 158px;
		left: 30px;
		top: 61.002px;
		overflow: visible;
	}
	#ETS_27_MOBRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 281px;
		height: 158px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#ETS_27_MOBGroup_15050 {
		position: absolute;
		width: 358px;
		height: 316px;
		left: 0px;
		top: 274.002px;
		overflow: visible;
	}
	#ETS_27_MOBsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 77px;
		top: 194px;
		overflow: visible;
	}
	#ETS_27_MOBlinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#ETS_27_MOBlinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#ETS_27_MOBn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.ETS_27_MOBn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#ETS_27_MOBlinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.ETS_27_MOBlinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.811px;
		transform: matrix(1,0,0,1,0,0);
	}
	#ETS_27_MOBtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.ETS_27_MOBtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#ETS_27_MOBInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#ETS_27_MOBinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.387px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#ETS_27_MOBwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBmed_shadow_space_website_7_cf {
		fill: rgba(255,255,255,1);
	}
	.ETS_27_MOBmed_shadow_space_website_7_cf {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#ETS_27_MOBwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.986px;
		left: 11.993px;
		top: 12.402px;
		overflow: visible;
	}
	#ETS_27_MOBn_234567890_mobile_number_shad {
		left: 48px;
		top: 7px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBaffanghanioperatelive_email_sh {
		left: 35px;
		top: 54px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 20px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBaddress_med_shadow_space_websi {
		left: 35px;
		top: 104px;
		position: absolute;
		overflow: visible;
		width: 324px;
		height: 60px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBlocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 106px;
		overflow: visible;
	}
	#ETS_27_MOBemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 53px;
		overflow: visible;
	}
	#ETS_27_MOBphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#ETS_27_MOBn_Copyright_Operate_Live_OPC_P {
		left: 0px;
		top: 280px;
		position: absolute;
		overflow: visible;
		width: 289px;
		white-space: nowrap;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#ETS_27_MOBVisit_Us_ {
		left: 118px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 124px;
		white-space: nowrap;
		line-height: 65px;
		margin-top: -17.5px;
		text-align: left;
		font-family: Baloo;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(36,36,36,1);
	}