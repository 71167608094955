.mediaViewInfo {
		--web-view-name: Clinic;
		--web-view-id: Clinic;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Clinic;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Clinic {
		position: absolute;
		width: 1366px;
		height: 4811px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(242,242,242,1);
		overflow: hidden;
		--web-view-name: Clinic;
		--web-view-id: Clinic;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#Clinic_23_pcGroup_9691 {
		position: absolute;
		width: 13.622px;
		height: 10.84px;
		left: 287.366px;
		top: 1279.346px;
		overflow: visible;
	}
	#Clinic_23_pccheck {
		position: absolute;
		width: 13.622px;
		height: 10.84px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcPath_2923 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_pcPath_2923 {
		overflow: visible;
		position: absolute;
		width: 13.622px;
		height: 10.84px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_pcGroup_15032 {
		position: absolute;
		width: 1300px;
		height: 660px;
		left: 33px;
		top: 3632.999px;
		overflow: visible;
	}
	#Clinic_23_pcScroll_Group_60 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1300px;
		height: 572.001px;
		left: 0px;
		top: 87.999px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#Clinic_23_pcScroll_Group_60::-webkit-scrollbar{
		display: none;
	}
	#Clinic_23_pcGroup_15031 {
		position: relative;
		width: 501px;
		height: 394px;
		left: 20px;
		top: 18px;
        margin-right: 30px;
		overflow: visible;
	}
	#Clinic_23_pcRectangle_112 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_pcRectangle_112 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 519px;
		height: 412px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_pcMask_Group_4 {
		position: absolute;
		width: 460px;
		height: 277px;
		left: 21px;
		top: 21px;
		overflow: visible;
	}
	#Clinic_23_pcDental_Clinic {
		left: 19px;
		top: 352px;
		position: absolute;
		overflow: visible;
		width: 132px;
		white-space: nowrap;
		text-align: left;
		font-family: Sen;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#Clinic_23_pcGroup_9694 {
		position: absolute;
		width: 370px;
		height: 26px;
		left: 19px;
		top: 312px;
		overflow: visible;
	}
	#Clinic_23_pcBangalore {
		opacity: 0.5;
		left: 25px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 346px;
		height: 26px;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
		text-transform: capitalize;
	}
	#Clinic_23_pcgeo-alt {
		position: absolute;
		width: 17px;
		height: 22.667px;
		left: 0px;
		top: 2px;
		overflow: visible;
	}
	#Clinic_23_pcPath_2927 {
		fill: rgba(254,120,71,1);
	}
	.Clinic_23_pcPath_2927 {
		overflow: visible;
		position: absolute;
		width: 17px;
		height: 22.667px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_pcOur_Treatments {
		filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 525px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 259px;
		white-space: nowrap;
		text-align: center;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(215,113,108,1);
	}
	#Clinic_23_pcGroup_15030 {
		position: absolute;
		width: 1200px;
		height: 605px;
		left: 83px;
		top: 977.001px;
		overflow: visible;
	}
	#Clinic_23_pcUnion_2 {
		fill: rgba(215,113,108,1);
	}
	.Clinic_23_pcUnion_2 {
		overflow: visible;
		position: absolute;
		width: 207.932px;
		height: 207.932px;
		left: 387.412px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_pcGet_The_Best__Treatments_Done_ {
		filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 0px;
		top: 115px;
		position: absolute;
		overflow: visible;
		width: 348px;
		white-space: nowrap;
		line-height: 79px;
		margin-top: -18.5px;
		text-align: left;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 42px;
		color: rgba(20,57,91,1);
	}
	#Clinic_23_pcGroup_9721 {
		position: absolute;
		width: 241px;
		height: 77px;
		left: 0px;
		top: 383px;
		overflow: visible;
	}
	#Clinic_23_pcRectangle_107 {
		fill: rgba(215,113,108,1);
	}
	.Clinic_23_pcRectangle_107 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 259px;
		height: 95px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_pcBook_An_Appointment {
		left: 23px;
		top: 24px;
		position: absolute;
		overflow: visible;
		width: 196px;
		white-space: nowrap;
		text-align: left;
		font-family: Noto Sans CJK KR;
		font-style: normal;
		font-weight: normal;
		font-size: 19px;
		color: rgba(255,255,255,1);
	}
	#Clinic_23_pcn_8706987_TaeAugust07 {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 759px;
		height: 568px;
		left: 441px;
		top: 37px;
		overflow: visible;
	}
	#Clinic_23_pcGroup_15039 {
		position: absolute;
		width: 1193px;
		height: 1695px;
		left: 87px;
		top: 1779px;
		overflow: visible;
	}
	#Clinic_23_pcGroup_15033 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcclose-up-doctor-writing {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcGroup_15038 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 600px;
		top: 38px;
		overflow: visible;
	}
	#Clinic_23_pcScroll_Group_28 {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Clinic_23_pcScroll_Group_28::-webkit-scrollbar{
		display: none;
	}
	#Clinic_23_pcIt_is_a_long_established_fact_ {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#Clinic_23_pcVISION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 69px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_pcGroup_15034 {
		position: absolute;
		width: 1193px;
		height: 419.078px;
		left: 0px;
		top: 638px;
		overflow: visible;
	}
	#Clinic_23_pcclose-up-businessman-businessw {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 560px;
		height: 419.078px;
		left: 633px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcGroup_15037 {
		position: absolute;
		width: 593px;
		height: 343px;
		left: 0px;
		top: 39px;
		overflow: visible;
	}
	#Clinic_23_pcScroll_Group_28_bu {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 0px;
		top: 65px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Clinic_23_pcScroll_Group_28_bu::-webkit-scrollbar{
		display: none;
	}
	#Clinic_23_pcIt_is_a_long_established_fact__bv {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#Clinic_23_pcMISSION {
		left: 18px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_pcGroup_15035 {
		position: absolute;
		width: 1193px;
		height: 419px;
		left: 0px;
		top: 1276px;
		overflow: visible;
	}
	#Clinic_23_pcdoctor-patient {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 560px;
		height: 419px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcScroll_Group_28_bz {
		mix-blend-mode: normal;
		position: absolute;
		width: 593px;
		height: 278px;
		left: 600px;
		top: 103px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#Clinic_23_pcScroll_Group_28_bz::-webkit-scrollbar{
		display: none;
	}
	#Clinic_23_pcIt_is_a_long_established_fact__b {
		left: 12px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 582px;
		height: 293px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(112,112,112,1);
		text-transform: capitalize;
	}
	#Clinic_23_pcPRINCIPLES {
		left: 618px;
		top: 38px;
		position: absolute;
		overflow: visible;
		width: 112px;
		white-space: nowrap;
		line-height: 41px;
		margin-top: -10.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_pcGroup_15043 {
		position: absolute;
		width: 1491px;
		height: 789px;
		left: -125px;
		top: 22px;
		overflow: visible;
	}
	#Clinic_23_pcn_0173304_8507 {
		position: absolute;
		width: 1041px;
		height: 690px;
		left: 450px;
		top: 99px;
		overflow: visible;
	}
	#Clinic_23_pcRectangle_745 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_pcRectangle_745 {
		filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		position: absolute;
		overflow: visible;
		width: 471px;
		height: 433px;
		left: 125px;
		top: 252px;
	}
	#Clinic_23_pcRectangle_102 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_pcRectangle_102 {
		filter: drop-shadow(0px 3px 27px rgba(0, 0, 0, 0.141));
		position: absolute;
		overflow: visible;
		width: 550px;
		height: 532px;
		left: 0px;
		top: 202px;
	}
	#Clinic_23_pcHealing__Starts_Here_ {
		filter: drop-shadow(0px 3px 6px rgba(255, 255, 255, 0.161));
		left: 167px;
		top: 299px;
		position: absolute;
		overflow: visible;
		width: 249px;
		white-space: nowrap;
		text-align: left;
		font-family: Playfair Display;
		font-style: normal;
		font-weight: bold;
		font-size: 65px;
		color: rgba(215,113,108,1);
	}
	#Clinic_23_pcAnime_Edit {
		position: absolute;
		width: 70px;
		height: 70px;
		left: 469px;
		top: 0px;
        border-radius: 50%;
		overflow: hidden;
	}
	#Clinic_23_pcOni_Chan_Clinic {
		left: 564px;
		top: 21px;
		position: absolute;
		overflow: visible;
		width: 139px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_pcGroup_15041 {
		position: absolute;
		width: 1175px;
		height: 373.001px;
		left: 96px;
		top: 4392.999px;
		overflow: visible;
	}
	#Clinic_23_pcGroup_15040 {
		position: absolute;
		width: 492px;
		height: 278px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcRectangle_737 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_pcRectangle_737 {
		position: absolute;
		overflow: visible;
		width: 492px;
		height: 278px;
		left: 0px;
		top: 0px;
		border-radius: 10px;
	}
	#Clinic_23_pcsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 668px;
		top: 255.001px;
		overflow: visible;
	}
	#Clinic_23_pclinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_pcmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_pclinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#Clinic_23_pcn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.Clinic_23_pcn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.565px;
		left: 7.922px;
		top: 6.672px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_pclinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.Clinic_23_pclinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.428px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_pctop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.Clinic_23_pctop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#Clinic_23_pcInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_pcshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_pcinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.002px;
		overflow: visible;
	}
	#Clinic_23_pcwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#Clinic_23_pcmed_shadow_space_website_7_cn {
		fill: rgba(255,255,255,1);
	}
	.Clinic_23_pcmed_shadow_space_website_7_cn {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#Clinic_23_pcwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#Clinic_23_pcn_234567890_mobile_number_shad {
		left: 703px;
		top: 24.001px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_pcaffanghanioperatelive_email_sh {
		left: 708px;
		top: 77.001px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_pcaddress_med_shadow_space_websi {
		left: 703px;
		top: 127.001px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#Clinic_23_pclocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 129.001px;
		overflow: visible;
	}
	#Clinic_23_pcemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 76.001px;
		overflow: visible;
	}
	#Clinic_23_pcphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 668px;
		top: 23.001px;
		overflow: visible;
	}
	#Clinic_23_pcn_Copyright_Operate_Live_OPC_P {
		left: 668px;
		top: 351.001px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}