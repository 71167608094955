	.OFFM_mediaViewInfo {
		--web-view-name: off mob;
		--web-view-id: off_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: off_mob;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
    #add_off_mob {
        position: absolute;
        width: 30px;
        height: 30px;
        right: 20px;
        top: 70px;
        overflow: visible;
    }
	#OFFM_off_mob {
		position: absolute;
        width: 100%;
        height: calc(100% - 185px);
        left: 0px;
        top: 120px;
		background-color: rgba(255,255,255,1);
		overflow-x: hidden;
		overflow-x: auto;
		--web-view-name: off mob;
		--web-view-id: off_mob;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
    #OFFM_off_mob::-webkit-scrollbar {
      display: none;
    }

	#OFFM_Group_128 {
		position: absolute;
		width: 100%;
		height: 302px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#OFFM_desgin_3_com_off {
		fill: rgba(248,248,248,1);
	}
	.OFFM_desgin_3_com_off {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 207px;
		left: 0px;
		top: 0px;
	}
	#OFFM_recently_added_com_off {
		/* left: 98px; */
		top: 36px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: uppercase;
	}
	#OFFM_explore_aws_com_off {
		/* left: 105px; */
		top: 18px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(48,47,47,1);
		letter-spacing: -0.2222222137451172px;
		text-transform: uppercase;
	}
	#OFFM_Scroll_Group_6 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 231px;
		left: 0px;
		top: 71px;
        display: flex;
		overflow-x: scroll;
		overflow-y: hidden;
	}
    #OFFM_Scroll_Group_6::-webkit-scrollbar {
        display: none;
    }


	#OFFM_Group_127 {
		position: absolute;
		width: 100%;
		height: 99px;
		left: 0px;
		top: 331px;
		overflow: visible;
	}
	#OFFM_desgin_2_ocom_off {
		position: absolute;
		width: 100%;
		height: 99px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#OFFM_design_1_co_off {
		fill: rgba(0,0,0,0.569);
	}
	.OFFM_design_1_co_off {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 99px;
		left: 0px;
		top: 0px;
	}
	#OFFM_And_then_there_was_offreq {
		/* left: 54px; */
		top: 36px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
		letter-spacing: -0.46875px;
	}
	#OFFM_trwnding_this_week_offreqevent {
		/* left: 113px; */
		top: 467px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
	
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		color: rgba(17,17,17,1);
		letter-spacing: -0.4791666793823242px;
		text-transform: uppercase;
	}
	#OFFM_top_product_offreqevent {
		/* left: 147px; */
		top: 449px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		color: rgba(48,47,47,1);
		letter-spacing: -0.2222222137451172px;
		text-transform: uppercase;
	}
	#OFFM_Scroll_Group_7 {
		mix-blend-mode: normal;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 499px;
        display: block;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#OFFM_n__card_grp_com_off_off {
		position: relative;
		width: 339px;
		height: 888px;
		left: calc(50% - 170px);
		top: 7px;
        display: inline-block;
		overflow: visible;
	}
	
