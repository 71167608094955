.mediaViewInfo {
    --web-view-name: general;
    --web-view-id: general_mob_;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: general_mob_;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#general_mob_ {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 140px);
    top: 75px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: general;
    --web-view-id: general_mob_;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#edit_profile_general_mob {
    position: absolute;
    width: 109px;
    height: 25px;
    left: 31px;
    top: 6px;
    overflow: visible;
}
#user_pfp_pop_lbl_profile_mob_1 {
    position: absolute;
    width: 35px;
    height: 35px;
    left: 0px;
    top: 0px;
    overflow: hidden;
}
#user_back_profile_mob_ {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    top: 0px;
    overflow: hidden;
  
}
#Edit_Profile_lbl_profile_mob_1 {
    left: 50px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 79px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}