	.mediaViewInfo {
		--web-view-name: ChaatsParadise;
		--web-view-id: ChaatsParadise;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: ChaatsParadise;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#ChaatsParadise {
		position: absolute;
		width: 1366px;
		height: 3470px;
        left:calc((100vw - 1366px)/2); 
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: ChaatsParadise;
		--web-view-id: ChaatsParadise;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	#CHAAT_22_PCRectangle_743 {
		fill: rgba(241,241,241,1);
	}
	.CHAAT_22_PCRectangle_743 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 1400px;
		left: 0px;
		top: 850px;
	}
	#CHAAT_22_PCGroup_108 {
		position: absolute;
		width: 1366px;
		height: 700px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_PCRectangle_1 {
		fill: rgba(26,26,26,1);
	}
	.CHAAT_22_PCRectangle_1 {
		position: absolute;
		overflow: visible;
		width: 1366px;
		height: 700px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_PCpexels-vinit-jain-16171917 {
		opacity: 0.7;
		position: absolute;
		width: 1366px;
		height: 700px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_PCTaste_the_Best_Right_Now {
		left: 251px;
		top: 271px;
		position: absolute;
		overflow: visible;
		width: 866px;
		white-space: nowrap;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 72px;
		color: rgba(255,255,255,1);
	}
	#CHAAT_22_PCAny_succesfull_career_starts_w {
		left: 414px;
		top: 377px;
		position: absolute;
		overflow: visible;
		width: 540px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -4px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		color: rgba(255,255,255,1);
	}
	#CHAAT_22_PClogo {
		position: absolute;
		width: 40px;
		height: 32px;
		left: 135px;
		top: 48px;
        border-radius: 50%;
		overflow: hidden;
	}
	#CHAAT_22_PCName {
		left: 192px;
		top: 53px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		line-height: 32px;
		margin-top: -6px;
		text-align: left;
		font-family: Nunito Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#CHAAT_22_PCGroup_15064 {
		position: absolute;
		width: 712px;
		height: 1150px;
		left: 327px;
		top: 975px;
		overflow: visible;
	}
	#CHAAT_22_PCCard_03_website_14_web_pc {
		position: absolute;
		width: 712px;
		height: 299.362px;
		left: 0px;
		top: 850.638px;
		overflow: visible;
	}
	#CHAAT_22_PCScroll_Group_12 {
		mix-blend-mode: normal;
		position: absolute;
		width: 305px;
		height: 250px;
		left: 407px;
		top: 49.362px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHAAT_22_PCintro_lbl_14_web_pc {
		left: 2px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 306px;
		height: 246px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#CHAAT_22_PCprinciples_lbl_14_web_pc {
		left: 505px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 115px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 27px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PCIcon_top_14_web_pc {
		fill: rgba(255,255,255,1);
	}
	.CHAAT_22_PCIcon_top_14_web_pc {
		overflow: visible;
		position: absolute;
		width: 23.555px;
		height: 4.195px;
		left: 129.491px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHAAT_22_PCkevin-mu14_web_pc {
		position: absolute;
		width: 305px;
		height: 299px;
		left: 0px;
		top: 0.362px;
		overflow: visible;
	}
	#CHAAT_22_PCCard_02_website_14_web_pc {
		position: absolute;
		width: 712px;
		height: 307.82px;
		left: 0px;
		top: 440.058px;
		overflow: visible;
	}
	#CHAAT_22_PCScroll_Group_11 {
		mix-blend-mode: normal;
		position: absolute;
		width: 347.97px;
		height: 252.947px;
		left: 364.03px;
		top: 54.872px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHAAT_22_PCintro_2_14_web_pc {
		left: 10.97px;
		top: -4px;
		position: absolute;
		overflow: visible;
		width: 340px;
		height: 248px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#CHAAT_22_PCmission_lbl_we14_web_pc {
		left: 499px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 27px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PCzden14_web_pc {
		position: absolute;
		width: 330.572px;
		height: 307.82px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_PCCard_01_website_14_web_pc {
		position: absolute;
		width: 712px;
		height: 342px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_PCScroll_Group_10 {
		mix-blend-mode: normal;
		position: absolute;
		width: 301px;
		height: 267px;
		left: 411px;
		top: 75px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	#CHAAT_22_PCintro_w14_web_pc {
		left: 4px;
		top: 3px;
		position: absolute;
		overflow: visible;
		width: 294px;
		height: 307px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Roboto-Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(51,51,51,1);
	}
	#CHAAT_22_PCVision_14_web_pc {
		left: 526px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 73px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 27px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PCaarn-giri-3lGi014_web_pc {
		position: absolute;
		width: 316px;
		height: 342px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_PCOur_Chaats {
		left: 606px;
		top: 2400px;
		position: absolute;
		overflow: visible;
		width: 156px;
		white-space: nowrap;
		line-height: 47px;
		margin-top: -8.5px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PCScroll_Group_78 {
		mix-blend-mode: normal;
		position: absolute;
		width: 1302px;
		height: 436px;
		left: 43px;
		top: 2440px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		overflow-x: scroll;
		overflow-y: hidden;
	}
	#CHAAT_22_PCGroup_14872 {
		position: relative;
		width: 220px;
		height: 328px;
		left: 29px;
		top: 51px;
        margin-right: 30px;
		overflow: visible;
	}
	#CHAAT_22_PCRectangle_688 {
		fill: rgba(255,255,255,1);
		stroke: rgba(255,255,255,0.522);
		stroke-width: 22px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHAAT_22_PCRectangle_688 {
		filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 280px;
		height: 388px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_PCpexels-shahbaz-ansari-13063300 {
		position: absolute;
		width: 220px;
		height: 296px;
		left: 0px;
		top: 32px;
		overflow: visible;
	}
	#CHAAT_22_PCPani_Puri {
		left: 3px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 218px;
		height: 22px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PCsocials_grp_shadow_space_websi {
		position: absolute;
		width: 188px;
		height: 48px;
		left: 786px;
		top: 3316px;
		overflow: visible;
	}
	#CHAAT_22_PClinked_in_grp_shadow_space_web {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 140px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_PCmed_shadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CHAAT_22_PCmed_shadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_PClinked_in_shadow_space_website {
		position: absolute;
		width: 23.361px;
		height: 21.237px;
		left: 12.815px;
		top: 13.602px;
		overflow: visible;
	}
	#CHAAT_22_PCn_website_7_layout_ {
		fill: rgba(63,61,86,1);
	}
	.CHAAT_22_PCn_website_7_layout_ {
		overflow: visible;
		position: absolute;
		width: 15.439px;
		height: 14.566px;
		left: 7.922px;
		top: 6.671px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHAAT_22_PClinked_in_i_shadow_space_websi {
		fill: rgba(63,61,86,1);
	}
	.CHAAT_22_PClinked_in_i_shadow_space_websi {
		overflow: visible;
		position: absolute;
		width: 4.553px;
		height: 14.427px;
		left: 0.589px;
		top: 6.81px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHAAT_22_PCtop_dot_i_shadow_space_website {
		fill: rgba(63,61,86,1);
	}
	.CHAAT_22_PCtop_dot_i_shadow_space_website {
		overflow: visible;
		position: absolute;
		width: 5.527px;
		height: 5.006px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#CHAAT_22_PCInsta_grp_shadow_space_website {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_PCshadow_space_website_7 {
		fill: rgba(255,255,255,1);
	}
	.CHAAT_22_PCshadow_space_website_7 {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_PCinstagramshadow_space_website_ {
		position: absolute;
		width: 23.386px;
		height: 23.386px;
		left: 12.704px;
		top: 13.003px;
		overflow: visible;
	}
	#CHAAT_22_PCwhatsapp_grp_shadow_space_webs {
		position: absolute;
		width: 48px;
		height: 48px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#CHAAT_22_PCmed_shadow_space_website_7_br {
		fill: rgba(255,255,255,1);
	}
	.CHAAT_22_PCmed_shadow_space_website_7_br {
		filter: drop-shadow(6px 6px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 57px;
		height: 57px;
		left: 0px;
		top: 0px;
	}
	#CHAAT_22_PCwhatsapp_lbl_shadow_space_webs {
		position: absolute;
		width: 23.986px;
		height: 23.985px;
		left: 11.993px;
		top: 12.403px;
		overflow: visible;
	}
	#CHAAT_22_PCn_234567890_mobile_number_shad {
		left: 821px;
		top: 3085px;
		position: absolute;
		overflow: visible;
		width: 109px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PCaffanghanioperatelive_email_sh {
		left: 826px;
		top: 3138px;
		position: absolute;
		overflow: visible;
		width: 225px;
		height: 27px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PCaddress_med_shadow_space_websi {
		left: 821px;
		top: 3188px;
		position: absolute;
		overflow: visible;
		width: 445px;
		height: 81px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PClocation_img_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 786px;
		top: 3190px;
		overflow: visible;
	}
	#CHAAT_22_PCemail_address_shadow_space_web {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 786px;
		top: 3137px;
		overflow: visible;
	}
	#CHAAT_22_PCphone_number_shadow_space_webs {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 786px;
		top: 3084px;
		overflow: visible;
	}
	#CHAAT_22_PCn_Copyright_Operate_Live_OPC_P {
		left: 786px;
		top: 3412px;
		position: absolute;
		overflow: visible;
		width: 508px;
		height: 22px;
		line-height: 26px;
		margin-top: -5px;
		text-align: left;
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#CHAAT_22_PCRectangle_744 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHAAT_22_PCRectangle_744 {
		position: absolute;
		overflow: visible;
		width: 480px;
		height: 270px;
		left: 149px;
		top: 3094px;
		border-radius: 20px;
	}
	#CHAAT_22_PCLine_27 {
		fill: transparent;
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.CHAAT_22_PCLine_27 {
		overflow: visible;
		position: absolute;
		width: 1281px;
		height: 1px;
		left: 30.5px;
		top: 3033.5px;
		transform: matrix(1,0,0,1,0,0);
	}